// @flow
import React from 'react';
import Button from 'antd/lib/button';
import {TypesOfRepairsApi} from '../../lib/api';
import {getListInitialState, navigate} from '../../lib/helpers';
import type {ListState, UserAccess} from '../../lib/types';
import {accessTypeEnum} from '../../lib/enum';
import Table from '../../components/ui/Table';
import {Header, Section} from '../../components/layout';
import {withUserAccess} from './../withUserAccess';
import {RepairKind} from '../../lib/types/typesOfRepairs';

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.editingWorkDict,
];

type Props = {
  userAccess: UserAccess[],
};

type State = ListState<RepairKind>;

export class TypesOfRepairsList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
  };
  
  columns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Вид ремонта',
      dataIndex: 'name',
    },
    {
      title: 'Код вида ремонта',
      dataIndex: 'code',
    },
  ];
  
  componentDidMount = async () => {
    await this.fetch(this.state.page);
  };
  
  fetch = async (page: number) => {
    const {pageSize} = this.state;
    this.setState({loading: true});
    const data = await TypesOfRepairsApi.fetch({page, pageSize});
    this.setState({...data, loading: false, page});
  };
  
  canAdd = () => this.props.userAccess.some(access => addAccess.includes(access));
  
  render() {
    const {data, totalCount, pageSize, page, loading} = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Виды ремонта</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/types-of-repairs/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetch}
            onRow={(row: RepairKind) => ({onClick: () => navigate(`/admin/types-of-repairs/${row.id}`)})}
            loading={loading}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(TypesOfRepairsList);
