// @flow
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type {EntityStatusType, ExpenseDirectionType, ServiceType} from '../../../../lib/types';
import {formatDateTimeToISOString} from '../../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from '../../../../components';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import Field from '../../../../components/card/Field';

const {
  EntityStatusSelect,
  ContractorSelect,
  ExpenseDirectionTypeSelect
} = Selects;

const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ActFilterParams = {
  'stage.contract.services'?: ServiceType,
  expenseDirectionType?: ExpenseDirectionType,
  status?: EntityStatusType,
  'stage.contract.contractorId'?: number,
  startDate?: string,
  endDate?: string,
  'stage.contract.nodeId'?: number
};

type Props = {
  filter: ActFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<ActFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionTypeSelect
                  size="small"
                  onChange={value => changeValue('expenseDirectionType', value)}
                  value={values['expenseDirectionType']}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  placeholder="Статус"
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value =>
                    changeValue('stage.contract.contractorId', value)
                  }
                  value={values['stage.contract.contractorId']}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onlyBranches
                  placeholder="Филиал"
                  onChange={value =>
                    changeValue('stage.contract.nodeId', value)
                  }
                  value={values['stage.contract.nodeId']}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
