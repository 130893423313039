// @flow
import type {VehicleCategory} from '../types';
import CommonApi from '../commonApi';

const baseUrl = 'VehicleCategory';
const vehicleCategories = new CommonApi<VehicleCategory>(
  baseUrl,
  'Категории ТО ГИБДД'
);

export default vehicleCategories;
