// @flow
import React from 'react';

import styled from 'styled-components';
import AntIcon from 'antd/lib/icon';
import isEmpty from 'lodash/isEmpty';

import { Section } from '../../../../components/layout';
import { Icon } from '../../../../components/ui';
import type { Vehicle } from '../../../../lib/types';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import { formatDate } from '../../lib';
import { insuranceClasses } from '../../../../lib/enum';
import SectionTitle from '../SectionTitle';
import { Element } from 'react-scroll/modules';
import OsagoList from '../../containers/OsagoList';
import {
  applyMaskToValue,
  convertContractorToString,
  navigate
} from '../../../../lib/helpers';
import { formatOsagoMask } from '../../../../components/masked-inputs/OsagoInput';

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  canHandleMaintenanceServices: boolean
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
`;

export default class OsagoTab extends React.Component<Props> {
  onEdit = () => {
    const { id: vehicleId, osago } = this.props.vehicle;
    navigate(`/vehicles/${vehicleId}/osagos/${osago ? osago.id || '' : ''}`, {
      replace: true
    });
  };

  render() {
    const { vehicle, editable, canHandleMaintenanceServices } = this.props;

    const { osago } = vehicle;

    return (
      <>
        {!isEmpty(osago) ? (
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon onClick={this.onEdit} type="edit" size={16} />
                )
              }
            >
              ОСАГО
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Номер полиса ОСАГО">
                    {applyMaskToValue(osago.osagoNumber, formatOsagoMask)}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Наименование страховщика">
                    {convertContractorToString(osago.contractor)}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Класс на начало годового срока страхования">
                    {osago.insuranceClass &&
                      insuranceClasses[osago.insuranceClass]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата начала действия полиса">
                    {formatDate(osago.osagoStartedDate)}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания действия полиса">
                    {formatDate(osago.osagoFinishedDate)}
                  </Field>
                </GridItem>

                {osago.diagnosticCardNumber && (
                  <GridItem>
                    <Field label="Диагностическая карта">
                      {osago.diagnosticCardNumber}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Кол-во страховых случаев, по которым выплачена страховка">
                    {osago.insuranceCasesCount}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Сумма оплаты">
                    {(osago.paymentAmount || 0) + ' руб.'}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>
        ) : null}
        <Element name="osago">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable &&
                canHandleMaintenanceServices && (
                  <StyledAntIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/osagos/new`)
                    }
                    type="plus"
                    size={16}
                  />
                )
              }
            >
              Архив полисов
            </SectionTitle>
            <OsagoList
              vehicleId={vehicle.id}
              enableDelete={editable && canHandleMaintenanceServices}
            />
          </Section>
        </Element>
      </>
    );
  }
}
