// @flow
import React, {useEffect, useState} from 'react';
import Button from 'antd/lib/button';

import type {AssignmentLimit, AssignmentLimitGroup} from './../../../../../../../lib/types';
import {Row, Spoiler} from './../../../../../../../components/ui';

import AssignmentLimitRow from './AssignmentLimitRow';

type Props = {
  setChanges: Function,
  // Редактируемая ли группа
  editable: boolean,
  // Группа лимитов
  group: AssignmentLimitGroup,
  // Индекс текущей группы
  index: number,
  // Функция для изменения значения группы по ключу в массиве
  onChange: (
    index: number,
    name: $Keys<AssignmentLimitGroup>,
    value: any
  ) => void,
  // Функция для сохранения группы на бэке
  onSave: (group: AssignmentLimitGroup, index: number) => Promise<void>,
  selectedVehiclesIds: number[]
};

/**
 * Компонент строки для групп лимитов в закреплении
 */
export default ({
  group,
  index,
  onChange,
  onSave,
  editable,
  setChanges,
  selectedVehiclesIds
}: Props) => {
  const [hasChanges, setHasChanges] = useState(false);

  const handleChangeLimit = (
    limitIndex: number,
    name: $Keys<AssignmentLimit>,
    value: any
  ) => {
    const { assignmentLimit } = group;
    const copy = [...assignmentLimit];
    copy[limitIndex][name] = value;

    onChange(index, 'assignmentLimit', copy);
    setHasChanges(true);
  };

  useEffect(() => {
    setChanges(hasChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanges]);

  const handleSave = async () => {
    await onSave(group, index);
    setHasChanges(false);
  };

  const labelSpoiler = `${group.regulationLimitGroup.name} ${
    group.regulationLimitGroup?.expenseDirection?.name
      ? `(${group.regulationLimitGroup.expenseDirection.name})`
      : ``
  }`;
  return (
    <>
      <Spoiler
        label={labelSpoiler}
        labelStyles={{
          minWidth: '1980px',
          background: '#F0F4F8',
          color: '#838D96',
          height: '45px'
        }}
        defaultExpanded
        iconPlacement="left"
      >
        {group.assignmentLimit.map((limit, index, assignmentLimits) => (
          <AssignmentLimitRow
            editable={editable}
            onChange={handleChangeLimit}
            limit={limit}
            assignmentLimits={assignmentLimits}
            index={index}
            key={index}
            selectedVehiclesIds={selectedVehiclesIds}
          />
        ))}
        {editable && hasChanges && (
          <Row style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleSave} size="small" type="primary">
              Сохранить изменения
            </Button>
          </Row>
        )}
      </Spoiler>
    </>
  );
};
