// @flow
import type {VehicleSubcategory} from '../types';
import CommonApi from '../commonApi';

const baseUrl = 'VehicleSubCategory';
const vehicleSubcategories = new CommonApi<VehicleSubcategory>(
  baseUrl,
  'Подкатегории ТО ГИБДД',
);

export default vehicleSubcategories;
