// @flow
import type { BranchBudgetSummaryLineItem } from '../types';
import CommonApi from '../commonApi';

const baseUrl = 'branchBudgetSummaryLineItem';
const branchBudgetSummaryLineItem = new CommonApi<BranchBudgetSummaryLineItem>(
  baseUrl,
  'Направление статей расходов в сводах'
);

export default branchBudgetSummaryLineItem;
