// @flow
import {Link} from '@reach/router';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import qs from 'query-string';
import React, {Component} from 'react';
import {connect} from 'react-redux';

import Header from '../../../components/layout/Header';
import {ListSection, PageListSection} from '../../../components/layout/Section';
import {notificationLoading} from '../../../components/Notifications';
import {Dropdown, Table} from '../../../components/ui';

import type {PersistFilterPayload} from '../../../ducks/persistFilters';
import {setFilter} from '../../../ducks/persistFilters';
import type {AppState} from '../../../ducks/redux';

import {type FetchListParams, washingApi} from '../../../lib/api';
import {washingStatusEnum, washingStatusTypes, washingTypes} from '../../../lib/enum';
import {
  convertVehicleToString,
  formatDateTimeToString,
  getListInitialState,
  navigate,
  setQueryParams,
  toLocalStringRu,
} from '../../../lib/helpers';
import type {ListState, UserAccess, Vehicle, Washing} from '../../../lib/types';

import {withUserAccess} from '../../withUserAccess';

import {ACT_COLUMNS, canHandleService} from '../lib';

import DriverFactPopover from './DriverFactPopover';
import type {WashingsFilterParams} from './Filter';
import WashingsFilter from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};
type State = ListState<Washing> & {
  filterPath: string
};

export const COLUMNS = [
  {
    title: '№',
    dataIndex: 'id',
    width: 40
  },
  {
    title: 'Планируемая дата',
    dataIndex: 'date',
    width: 120,
    render: (date: ?string) => {
      const monthYear = moment.utc(date).format('MMMM YYYY года');
      return monthYear.charAt(0).toUpperCase() + monthYear.slice(1);
    }
  },
  {
    title: 'Дата выполнения',
    key: 'factDate',
    dataIndex: 'factDate',
    width: 100,
    render: (value: ?string) =>
      value ? formatDateTimeToString(value, 'DD.MM.YYYY') : null
  },
  {
    title: 'Вид мойки',
    dataIndex: 'type',
    width: 100,
    breakByWidth: true,
    render: (type: string) => washingTypes[type]
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    width: 300,
    breakByWidth: true,
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle)
  },
  {
    title: 'Статус',
    dataIndex: 'state',
    width: 80,
    render: (state: string) => washingStatusTypes[state]
  },
  {
    title: 'Стоимость',
    dataIndex: 'paymentAmount',
    width: 80,
    render: (paymentAmount: number) => toLocalStringRu(paymentAmount || 0)
  }
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };
  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };
  columns = [...COLUMNS, ...ACT_COLUMNS];

  setPersistFilter = async (values: WashingsFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async (filter: WashingsFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchWashingList();
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchWashingList();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '30px',
        onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
        render: (record: Washing) => {
          return (
            <Dropdown
              overlay={
                <Menu>
                  {record.state === washingStatusEnum.scheduled && (
                    <Menu.Item>
                      <DriverFactPopover
                        washing={record}
                        defaultDriverId={
                          record.driverId ||
                          (record.vehicle && record.vehicle.driverId)
                        }
                        onServerUpdate={washing => {
                          const records = [...this.state.data];
                          const index = records.findIndex(
                            w => w.id === washing.id
                          );
                          records[index] = washing;
                          this.setState({ data: records });
                        }}
                      />
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    <Popconfirm
                      title="Вы действительно хотите удалить талон?"
                      okText="Да"
                      cancelText="Нет"
                      placement="bottomRight"
                      onConfirm={() => {
                        if (record && record.id) {
                          this.deleteWashing(record.id);
                        }
                      }}
                    >
                      Удалить
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <Icon
                style={{ fontSize: 16, color: '#2770FF' }}
                type="ellipsis"
              />
            </Dropdown>
          );
        }
      });
    }
    await this.fetchWashingList(page);
  }

  fetchWashingList = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    const { data, totalCount } = await washingApi.fetchAllWashings({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  deleteWashing = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await washingApi.deleteWashing(id);
      const records = [...this.state.data.filter(r => r.id !== id)];
      this.setState({ data: records });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  handleRowClick = (id: number) => navigate(`/services/washings/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <PageListSection>
        <Header
          left={<h1>Мойки</h1>}
          right={
            this.canAdd() && (
              <Link to="/services/washings/new">
                <Button type="primary" data-cy="add">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <ListSection>
          <WashingsFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchWashingList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            loading={loading}
            columns={this.columns}
            data={data.map(item => ({
              ...item,
              key: item.id
            }))}
            scroll={{
              x: 'auto',
              y: 'calc(100vh - 280px)'
            }}
          />
        </ListSection>
      </PageListSection>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(List));
