// @flow

import React, { Component } from 'react';
import { inspectionGtnCalculationApi } from '../../../../lib/api';
import { plus, toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import { COLUMNS, MONTH } from '../lib';
import Common from './components/Common';
import type { InspectionGtnCalculation } from '../../../../lib/types';
import { calculationStatusEnum } from '../../../../lib/enum';
import { VehiclePlan } from '../../../../lib/types';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';
import moment from 'moment/moment';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = {
  monthData: ?InspectionGtnCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

export default class extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };
  
  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }
  
  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null
    });
  };
  
  onCell = (monthData, monthIndex) => ({
    onClick: () => {
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (monthData.id && vehiclePlan?.inspectionGtnCalculationStatus === calculationStatusEnum.draft &&
        openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({ monthData, monthIndex });
      }
    }
  });
  
  columns = [
    ...COLUMNS,
    ...MONTH.map((month, index) => ({
      title: month.title,
      width: '110px',
      dataIndex: `months[${index}].cost`,
      render: (cost: number) =>
        toLocalStringRu(cost, { minimumFractionDigits: 2 }),
      onCell: monthData => this.onCell(monthData, index)
    })),
    {
      title: 'Пошлина',
      dataIndex: 'taxSum',
      render: (taxCost: number) =>
        toLocalStringRu(taxCost, { minimumFractionDigits: 2 })
    },
    {
      title: 'Работа',
      dataIndex: 'workSum',
      render: (workCost: number) =>
        toLocalStringRu(workCost, { minimumFractionDigits: 2 })
    },
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, { minimumFractionDigits: 2 })
    }
  ];

  itogCalculation = (data: any): any => {
    const itog = {
      months: Array(12)
        .fill()
        .map(() => ({ cost: 0 })),
      sum: 0,
      taxSum: 0,
      workSum: 0
    };
    data.forEach((item: any) => {
      itog.sum = plus(itog.sum, item.sum);
      itog.taxSum = plus(itog.taxSum, item.taxSum);
      itog.workSum = plus(itog.workSum, item.workSum);
      item.months.forEach((month: any, index) => {
        if (index < 12) {
          itog.months[index].cost = plus(itog.months[index].cost, month.cost);
        }
      });
    });
    return itog;
  };

  render() {
    const { location, vehiclePlanId } = this.props;
    const { monthIndex, monthData } = this.state;
    return (
      <Common
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={inspectionGtnCalculationApi}
        calcScrollY={430}
        scrollX={2800}
        statusField={'inspectionGtnCalculationStatus'}
        title={'Планирование ТО Гостехнадзор'}
        columns={this.columns}
        itogCalculation={this.itogCalculation}
        handlePrint={filter => {
          printNotification(async () => {
            await inspectionGtnCalculationApi.print(vehiclePlanId, filter);
          });
        }}
        editingModalData={{
          monthData,
          monthIndex,
          handleCancel: this.handleCancel,
          setVehiclePlan: vehiclePlan => this.setState({ vehiclePlan })
        }}
        linksToDictionaries={{
          'Стоимость ТО Гостехнадзор': '/admin/inspection-gtn-cost/',
          'Тех. осмотры': '/services/inspections-gtn/'
        }}
        additionalText={' (необходимо в ИК СТС проверить отметку о включении ТС в бюджет ГТН)'}
      />
    );
  }
}
