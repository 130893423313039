// @flow

import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { stouApi } from '../../lib/api';
import type { Stou } from '../../lib/types';
import type { StouFilterParams } from '../../containers/Stou/Filter';
import AutocompleteSelect from './AutocompleteSelect';
import styled from 'styled-components';

const OptionContent = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OptionName = styled.span`
  white-space: nowrap;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

type Props = SelectProps & { filter: StouFilterParams };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await stouApi.fetchStouList({
        ...filter,
        ...params
      });
    }}
    placeholder="Выберите запись СТОУ"
    refetchParams={{ filter }}
    fetchSingle={stouApi.fetchStouList}
    notFoundText="Элементы СТОУ не найдены"
    renderOption={(stou: Stou, Option, index) => (
      <Option key={index} value={stou.mnemonic} stou={stou}>
        <OptionContent>
          <OptionName>{stou.mnemonic}</OptionName>
        </OptionContent>
      </Option>
    )}
    {...selectProps}
  />
);
