// @flow
import React, { Component } from 'react';
import type { ContractVehicle } from '../../../../../../../lib/types';
import type { ContractVehicleTypeData } from '../Form';
import type { FixedVehicleListFilterParams } from './FilterVehicleList';
import Filter from './FilterVehicleList';
import InnerTable from './InnerTable';
import isEqual from 'lodash/isEqual';

type Props = {
  // тс согласно ИК
  data: ContractVehicle[],
  loading: boolean,
  contractVehiclePlanType: ContractVehicleTypeData,
  onChange: Function,
  applyFilter: Function,
  cleanFilter: Function,
  initialSelectedRows?: ContractVehicle[]
};
type State = {
  loading: boolean,
  // выбранные тс согласно ИК
  selectedRowKeys: number[],
  filter: FixedVehicleListFilterParams
};

export default class extends Component<Props, State> {
  state = {
    loading: false,
    data: [],
    selectedRowKeys: [],
    filter: {}
  };

  // заполняем массив выбранных элементов, если в компонент передан массив ТС
  async componentDidUpdate(prevProps: Props, prevState: State) {
    const { initialSelectedRows, data } = this.props;
    if (
      !isEqual(prevProps.data, this.props.data) &&
      initialSelectedRows?.length &&
      data?.length
    ) {
      const keys = [];
      data.forEach((vehicle, index) => {
        if (initialSelectedRows.find(item => item.id === vehicle.id)) {
          keys.push(index);
        }
      });
      this.setState({ selectedRowKeys: keys });
    }
  }

  onSelect = (
    record: ContractVehicle,
    selected: boolean,
    selectedRows: ContractVehicle[]
  ) => {
    const { data } = this.props;
    let { selectedRowKeys } = this.state;
    const index = data.findIndex((element: ContractVehicle) => {
      return element.id === record.id;
    });
    if (selected) {
      selectedRowKeys = [...selectedRowKeys, index];
    } else {
      selectedRowKeys = selectedRowKeys.filter(
        selectedRowKey => selectedRowKey !== index
      );
    }
    this.setState({ selectedRowKeys }, () =>
      this.props.onChange(this.props.contractVehiclePlanType, selectedRows)
    );
  };

  onSelectAll = (selected: boolean, selectedRows: ContractVehicle[]) => {
    const { data } = this.props;
    const length = data ? data.length : 0;
    this.setState(
      {
        selectedRowKeys: selected
          ? Array(length)
              .fill()
              .map((item, index) => index)
          : []
      },
      () =>
        this.props.onChange(this.props.contractVehiclePlanType, selectedRows)
    );
  };

  applyFilter = (filter: FixedVehicleListFilterParams) => {
    this.setState({ filter, selectedRowKeys: [] }, () =>
      this.props.applyFilter(this.props.contractVehiclePlanType, filter)
    );
  };

  cleanFilter = () => {
    this.setState({ filter: {}, selectedRowKeys: [] }, () =>
      this.props.cleanFilter(this.props.contractVehiclePlanType)
    );
  };

  render() {
    const { data, loading } = this.props;
    const { selectedRowKeys, filter } = this.state;
    return (
      <>
        <Filter
          applyFilter={this.applyFilter}
          cleanFilter={this.cleanFilter}
          filter={filter}
        />
        <InnerTable
          data={data}
          selectedRowKeys={selectedRowKeys}
          onSelect={this.onSelect}
          onSelectAll={this.onSelectAll}
          loading={loading}
        />
      </>
    );
  }
}
