// @flow
import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';

type Props = ButtonProps & {
  // Означает, что оригинальный объект был изменен
  dirty: boolean
};

/**
 * Компонент кнопки Отмены
 *
 * Предупреждает о наличии несохраненных данных,
 * если dirty = true
 *
 */
const CancelButton = ({ dirty, ...buttonProps }: Props) => {
  return dirty ? (
    <Popconfirm
      onConfirm={buttonProps.onClick}
      disabled={!dirty}
      title={
        <>
          <p>Имеются несохраненные данные.</p>
          <p>Вы уверены, что хотите выйти?</p>
        </>
      }
    >
      <Button {...buttonProps} onClick={null} />
    </Popconfirm>
  ) : (
    <Button {...buttonProps} />
  );
};

export default CancelButton;
