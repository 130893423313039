// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';

import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type {Driver} from './../../lib/types';
import type {AppState} from '../../ducks/redux';
import {Panel} from './../../components/layout';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {driverApi} from './../../lib/api';
import {notificationLoading} from './../../components/Notifications';
import InnerForm from './components/InnerForm';
import { DriverType } from '../../lib/enum/driverType';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  currentDriverType: ?DriverType,
  driverId: ?number,
  employeeBranchOrgUnitId: number
};

type State = {
  driver?: Driver
};

class DriverForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const { driverId } = this.props;
    try {
      if (driverId) {
        const driver = await driverApi.fetchDriver(driverId);
        this.setState({
          driver
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      navigate(this.getLocationBasedOnDriverType(), true);
    }
  }

  onSubmit = async (driver: Driver) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (driver.id) {
        await driverApi.updateDriver(driver);
      } else {
        const fullDriver = {
          ...driver,
          driverType: this.props.currentDriverType,
          snils: driver.snils || 11111111111,
        }
        await driverApi.addDriver(fullDriver);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(this.getLocationBasedOnDriverType(driver), true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  getTitle = (driver: Driver, driverTypeArg: string) => {    
    let title;
    if(driverTypeArg === DriverType.driver) {
      title = driver ? `Водитель №${driver.id}` : 'Новый водитель';
    } else if (driverTypeArg === DriverType.EVUser) {
      title = driver ? `Пользователь электрокара №${driver.id}` : 'Новый пользователь электрокара';
    }

    return title;
  }

  getDriverType = (driver?: Driver) => this.props.currentDriverType ?? driver?.driverType;

  getLocationBasedOnDriverType = (driver?: Driver) => {
    const driverTypeFuncVal = this.getDriverType(driver);
    let location = '/admin/drivers/';

    (driverTypeFuncVal === DriverType.driver)
      ? location += 'nonElectricVehiclesDrivers'
      : location += 'electricVehiclesDrivers';

    return location;
  }

  render() {
    const { employeeBranchOrgUnitId } = this.props;
    const { driver } = this.state;
    const driverTypeFuncVal = this.getDriverType(driver);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(this.getLocationBasedOnDriverType(driver))}>
                {driverTypeFuncVal === DriverType.driver ? 'Водители' : 'Пользователи электрокаров'}
              </Crumb>
              {driver ? (
                <Crumb to={`/admin/drivers/${driver.id}`}>
                  {driverTypeFuncVal === DriverType.driver ? 'Водитель' : 'Пользователь электрокара'} №{driver.id}
                </Crumb>
              ) : (
                <Crumb to="/admin/drivers/new">
                  Новый {driverTypeFuncVal === DriverType.driver ? 'водитель' : 'пользователь электрокара'}
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{this.getTitle(driver, driverTypeFuncVal)}</h1>
        </StyledPanel>
        <InnerForm
          onSubmit={this.onSubmit}
          onCancel={() => navigate(getPathWithHistoryParams(this.getLocationBasedOnDriverType(driver)), true)}
          driver={driver}
          currentDriverType={driverTypeFuncVal}
          employeeBranchOrgUnitId={employeeBranchOrgUnitId}
        />
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  driverId: parseInt(ownProps.driverId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(DriverForm);
