// @flow

import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {maintenanceWorkTypeApi} from './../../lib/api';
import {getPathWithHistoryParams} from './../../lib/helpers';
import type {MaintenanceWorkType} from '../../lib/types';
import {maintenanceTypeOfWork} from '../../lib/enum';

import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {WorkKinds} from '../../lib/types/maintenanceWorkType';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const {Field} = Card;

type Props = {
  maintenanceWorkTypeId: string
};

type State = {
  maintenanceTypeWork: ?MaintenanceWorkType
};

class MaintenanceWorkTypeForm extends Component<Props, State> {
  state = {
    maintenanceTypeWork: {},
  };
  
  async componentDidMount() {
    let id = parseInt(this.props.maintenanceWorkTypeId, 10);
    if (id) {
      try {
        const maintenanceTypeWork = await maintenanceWorkTypeApi.getMaintenanceTypeWork(
          id,
        );
        this.setState({maintenanceTypeWork});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  }
  
  render() {
    const {maintenanceTypeWork} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/maintenance-work-type')}
              >
                Справочник видов работ по обслуживанию ТС
              </Crumb>
              <Crumb>Вид работ №{maintenanceTypeWork?.id || ''}</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{`Виды работ №${maintenanceTypeWork?.id || ''}`}</h1>
        </StyledPanel>
        <Section>
          {maintenanceTypeWork && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Наименование">{maintenanceTypeWork.name}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Тип работ">
                    {maintenanceTypeOfWork[maintenanceTypeWork.workType]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Вид работ">
                    {WorkKinds[maintenanceTypeWork.workKind]}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default MaintenanceWorkTypeForm;
