// @flow
import type { moment } from 'moment';
import type {
  MonthlyWashingPlan,
  WashingPlanBalance,
  WashingPlanVehicle
} from './../types';

import type { FetchListParams, ListResponse } from './index';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { MonthlyWashingPlanFilterParams } from '../../containers/budget/monthlyWashingPlans/Filter';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import type { MounthlyWashingPlansFilterParams } from '../../containers/budget/monthlyWashingPlans/components/Filter';

const convertDataFromServer = (monthlyWashingPlan: MonthlyWashingPlan) => ({
  ...monthlyWashingPlan,
  incomeSchedule: monthlyWashingPlan?.incomeSchedule
    ? {
        ...monthlyWashingPlan.incomeSchedule,
        sum: convertFromKopToRub(monthlyWashingPlan?.incomeSchedule?.sum ?? 0),
        sumWithTax: convertFromKopToRub(
          monthlyWashingPlan?.incomeSchedule?.sumWithTax ?? 0
        )
      }
    : null,
  price: convertFromKopToRub(monthlyWashingPlan.price),

  bodyBalance: convertFromKopToRub(monthlyWashingPlan.bodyBalance),
  bodyFact: convertFromKopToRub(monthlyWashingPlan.bodyFact),
  bodyResidue: convertFromKopToRub(monthlyWashingPlan.bodyResidue),

  interiorBalance: convertFromKopToRub(monthlyWashingPlan.interiorBalance),
  interiorFact: convertFromKopToRub(monthlyWashingPlan.interiorFact),
  interiorResidue: convertFromKopToRub(monthlyWashingPlan.interiorResidue),

  engineBalance: convertFromKopToRub(monthlyWashingPlan.engineBalance),
  engineFact: convertFromKopToRub(monthlyWashingPlan.engineFact),
  engineResidue: convertFromKopToRub(monthlyWashingPlan.engineResidue)
});

const convertDataToServer = ({
  washingPlanVehicles,
  ...monthlyWashingPlan
}: MonthlyWashingPlan) => ({
  ...monthlyWashingPlan,
  price: convertFromRubToKop(monthlyWashingPlan.price),

  bodyBalance: convertFromRubToKop(monthlyWashingPlan.bodyBalance),
  bodyFact: convertFromRubToKop(monthlyWashingPlan.bodyFact),
  bodyResidue: convertFromRubToKop(monthlyWashingPlan.bodyResidue),

  interiorBalance: convertFromRubToKop(monthlyWashingPlan.interiorBalance),
  interiorFact: convertFromRubToKop(monthlyWashingPlan.interiorFact),
  interiorResidue: convertFromRubToKop(monthlyWashingPlan.interiorResidue),

  engineBalance: convertFromRubToKop(monthlyWashingPlan.engineBalance),
  engineFact: convertFromRubToKop(monthlyWashingPlan.engineFact),
  engineResidue: convertFromRubToKop(monthlyWashingPlan.engineResidue)
});

const convertWashingPlanVehicleFromServer = (
  washingPlanVehicle: WashingPlanVehicle
) => ({
  ...washingPlanVehicle,
  interiorPrice: convertFromKopToRub(washingPlanVehicle.interiorPrice),
  bodyPrice: convertFromKopToRub(washingPlanVehicle.bodyPrice),
  enginePrice: convertFromKopToRub(washingPlanVehicle.enginePrice),
  monthlyWashingPlan: washingPlanVehicle.monthlyWashingPlan
    ? convertDataFromServer(washingPlanVehicle.monthlyWashingPlan)
    : null
});

const convertWashingPlanVehicleToServer = (
  washingPlanVehicle: WashingPlanVehicle
) => ({
  ...washingPlanVehicle,
  interiorPrice: convertFromRubToKop(washingPlanVehicle.interiorPrice),
  bodyPrice: convertFromRubToKop(washingPlanVehicle.bodyPrice),
  enginePrice: convertFromRubToKop(washingPlanVehicle.enginePrice)
});

const convertWashingPlanBalanceFromServer = (
  washingPlanBalance: WashingPlanBalance
) => ({
  ...washingPlanBalance,
  vehicleInteriorPrice: convertFromKopToRub(
    washingPlanBalance.vehicleInteriorPrice
  ),
  vehicleBodyPrice: convertFromKopToRub(washingPlanBalance.vehicleBodyPrice),
  vehicleEnginePrice: convertFromKopToRub(
    washingPlanBalance.vehicleEnginePrice
  ),
  planSum: convertFromKopToRub(washingPlanBalance.planSum),
  factSum: convertFromKopToRub(washingPlanBalance.factSum),
  vehiclePlanSum: convertFromKopToRub(washingPlanBalance.vehiclePlanSum),
  vehicleFactSum: convertFromKopToRub(washingPlanBalance.vehicleFactSum)
});

export const addMonthlyWashingPlan = async (
  monthlyWashingPlan: MonthlyWashingPlan
): Promise<MonthlyWashingPlan> => {
  const added = await fetchRequest.post(
    '/monthlyWashingPlan',
    convertDataToServer(monthlyWashingPlan)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать план моек');
};

export const updateMonthlyWashingPlan = async (
  monthlyWashingPlan: MonthlyWashingPlan
): Promise<MonthlyWashingPlan> => {
  const updated = await fetchRequest.put(
    '/monthlyWashingPlan',
    convertDataToServer(monthlyWashingPlan)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить план моек');
};

export const fetchMonthlyWashingPlans = async (
  params: FetchListParams<MonthlyWashingPlanFilterParams> = initialFetchListParams
): Promise<ListResponse<MonthlyWashingPlan>> => {
  const washingPlans = await fetchRequest.get('/monthlyWashingPlan', {
    ...initialFetchListParams,
    ...params
  });
  if (washingPlans) {
    return {
      ...washingPlans,
      data: washingPlans.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список планов моек');
};

export const fetchMonthlyWashingPlan = async (
  id: number,
  params: any = {}
): Promise<MonthlyWashingPlan> => {
  const monthlyWashingPlan = await fetchRequest.get(
    `/monthlyWashingPlan/${id}`,
    params
  );
  if (monthlyWashingPlan) return convertDataFromServer(monthlyWashingPlan);
  throw new Error('Не удалось загрузить план моек');
};

export const deleteMonthlyWashingPlan = async (
  id: number
): Promise<MonthlyWashingPlan> => {
  const deleted = await fetchRequest.delete(`/monthlyWashingPlan/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить план моек');
};

export const setWashingPlanVehicles = async (
  washingVehicles: WashingPlanVehicle[],
  washingPlanId: number
): Promise<WashingPlanVehicle[]> => {
  const washingPlanVehicles = await fetchRequest.post(
    `/monthlyWashingPlan/${washingPlanId}/vehicles`,
    washingVehicles.map(convertWashingPlanVehicleToServer)
  );
  if (washingPlanVehicles)
    return washingPlanVehicles.map(convertWashingPlanVehicleFromServer);
  throw new Error('Не удалось указать ТС для плана моек');
};

export const fetchWashingPlanVehicles = async (
  washingPlanId: number
): Promise<WashingPlanVehicle[]> => {
  const washingPlanVehicles = await fetchRequest.get(
    `/monthlyWashingPlan/${washingPlanId}/vehicles`
  );
  if (washingPlanVehicles)
    return washingPlanVehicles.map(convertWashingPlanVehicleFromServer);
  throw new Error('Не удалось загрузить ТС для плана моек');
};

export const fetchAllWashingPlanVehicles = async (
  params: FetchListParams<WashingPlanVehicle> = initialFetchListParams
): Promise<ListResponse<WashingPlanVehicle>> => {
  const washingPlanVehicles = await fetchRequest.get('/washingPlanVehicle', {
    ...initialFetchListParams,
    ...params
  });
  if (washingPlanVehicles) {
    return {
      ...washingPlanVehicles,
      data: washingPlanVehicles.data.map(convertWashingPlanVehicleFromServer)
    };
  }
  throw new Error('Не удалось получить список ТС плана моек');
};

export const fetchWashingPlanBalance = async (
  vehicleId: number,
  date: string | moment
): Promise<?WashingPlanBalance> => {
  const washingPlanVehicle = await fetchRequest.get(
    `/washingPlanVehicle/plan?vehicleId=${vehicleId}&date=${date}`
  );
  if (washingPlanVehicle)
    return convertWashingPlanBalanceFromServer(washingPlanVehicle);
  return null;
  //throw new Error('Не удалось загрузить план моек для текущего ТС');
};

export const print = async (monthlyWashingPlanId: number): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/washingPlanVehicle/${monthlyWashingPlanId}/print`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const printVehiclesWashings = async (
  monthlyWashingPlanId: number,
  filters: MounthlyWashingPlansFilterParams
): Promise<boolean> => {
  const printed = await downloadRequestWithToken(
    `/washingPlanVehicle/${monthlyWashingPlanId}/printReport`,
    {
      ...filters,
      washingPlanId: monthlyWashingPlanId
    }
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  addMonthlyWashingPlan,
  updateMonthlyWashingPlan,
  fetchMonthlyWashingPlans,
  deleteMonthlyWashingPlan,
  fetchMonthlyWashingPlan,
  setWashingPlanVehicles,
  fetchWashingPlanVehicles,
  fetchAllWashingPlanVehicles,
  fetchWashingPlanBalance,
  print,
  printVehiclesWashings
};
