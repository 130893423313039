// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import {FormikProps} from 'formik';
import React from 'react';
import styled from 'styled-components';
import {Form, OrgUnitSelect, Selects} from '../../../components';
import CancelButton from '../../../components/CancelButton';
import type {FormFieldType} from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import YesNoSelect from '../../../components/selects/YesNoSelect';
import {orgUnitTypes} from '../../../lib/enum';
import type {OrgUnitNode} from '../../../lib/types';
import {OrgUnitTypesSelect} from '../../../components/selects/AutoGeneratedEnumSelects';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment/moment';
import InputNumber from 'antd/lib/input-number';

const {CompanySelect} = Selects;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  orgUnit: OrgUnitNode,
  onSubmit: Function,
  onCancel: Function,
  isAdmin: boolean,
};

export default ({orgUnit, onSubmit, onCancel, isAdmin}: Props) => (
  <Form initialValues={orgUnit} onSubmit={onSubmit}>
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        isSubmitting,
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px" cols={3}>
                <GridItem>
                  <FormField label="Rn" name="rn">
                    {({value, name}) => isAdmin
                      ? (
                        <InputNumber
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          min={0}
                          style={{width: '100%'}}
                        />
                      )
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип" name="departmentType">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Филиал" name="brunch">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Мнемокод" name="mnemonic" required>
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Код ФНС" name="fns">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Вид" name="type" required>
                    {({value, name}) => isAdmin
                      ? <OrgUnitTypesSelect value={value} onChange={type => setFieldValue(name, type)} />
                      : value ? orgUnitTypes[value] : '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Наименование" name="name" required>
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="КПП" name="kpp">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Штатное подразделение" name="isStaffDivision">
                    {({value, name}) => isAdmin
                      ? <YesNoSelect onChange={value => setFieldValue(name,value)} />
                      : value !== undefined ? value ? 'Да' : 'Нет' : '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Краткое наименование" name="shortName">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="ОКПО" name="okpo">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Юридическое лицо" name="companyId">
                    {({value, name}) => (
                      <CompanySelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        onBlur={() => handleBlur({target: {name}})}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Код ОКАТО" name="okato">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Адрес" name="address">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Получать данные по договорам" name="receiveContractsData">
                    {field => (
                      <YesNoSelect
                        {...field}
                        onChange={value => setFieldValue(field.name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Код ОКТМО" name="oktmo">
                    {({value, name}) => isAdmin
                      ? <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Родитель" name='parentId'>
                    {({value, name}) => isAdmin
                      ? (
                        <OrgUnitSelect
                          value={value}
                          onChange={id => setFieldValue(name, id)}
                        />
                      )
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Код статьи для ИСУ Бюджетирование" name="cognosCode">
                    {({name, value}) => (
                      <Input
                        value={value}
                        onChange={e => {
                          setFieldValue(name, e.target.value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Действует с" name="validDateStart">
                    {({value, name}) => isAdmin
                      ? (
                        <DatePicker
                          format='DD.MM.YYYY'
                          value={value ? moment(value).utc() : null}
                          onChange={(date) => setFieldValue(name, date.utc().startOf('day').toISOString())}
                        />
                      )
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Действует по" name="validDateEnd">
                    {({value, name}) => isAdmin
                      ? (
                        <DatePicker
                          format='DD.MM.YYYY'
                          value={value ? moment(value).utc() : null}
                          onChange={(date) => setFieldValue(name, date.utc().endOf('day').toISOString())}
                        />
                      )
                      : value || '-'
                    }
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              data-cy="save"
              htmlType="submit"
              className="login-form-button"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);
