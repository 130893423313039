// @flow

import React, {Component} from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';

import type {ListState, Location as LocationType, OrgUnitNode, UserAccess} from './../../../lib/types';
import {accessTypeEnum} from './../../../lib/enum';
import {ButtonsRow, Icon, Operations, Table} from './../../../components/ui';
import {Section} from './../../../components/layout';
import Filter, {type LocationFilterParams} from './Filter';
import {withUserAccess} from './../../withUserAccess';
import {getListInitialState, navigate, setQueryParams} from './../../../lib/helpers';
import {type FetchListParams, locationApi} from './../../../lib/api';
import {notificationLoading} from './../../../components/Notifications';
import {Profile} from '../../../ducks/auth';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

const DeleteButton = styled(Button)`
  margin: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSection = styled(Section)`
  margin-top: 0;
  padding: 4px 0 2px 4px;
  border-radius: 0 0 3px 3px;
`;

export const canHandleLocationAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingLocation
];

type Props = {
  userAccess: UserAccess[],
  profile: Profile,
  location: Location & { state: { page: number } }
};

type State = ListState<LocationType> & {
  filter: LocationFilterParams
};

class LocationList extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.inputSearchRef = React.createRef();
  }

  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filter: {},
    selectedRowKeys: []
  };

  fetchLocations = async (
    page: number = 1,
    params: FetchListParams<LocationFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await locationApi.fetchLocations({
        ...filter,
        ...{source: "uat"},
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  async deleteLocationBaseFunc(id: number | number[], func: Function) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      const { page, pageSize } = this.state;
      await func(id);
      await this.fetchLocations(page, { pageSize });
      this.setState({selectedRowKeys: []})
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  deleteEntries() {
    const { selectedRowKeys } = this.state;
    this.deleteLocationBaseFunc(selectedRowKeys, locationApi.deleteLocations.bind(locationApi))
  }

  canHandle = () => {
    const userAccessIncluded = this.props.userAccess.some(access =>
      canHandleLocationAccess.includes(access)
    );

    const roleIncluded = this.props.userProfile.roles.some(role =>
      role.roleId === 23 // Руководитель ТГ
    );

    return userAccessIncluded || roleIncluded;
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 25
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      sorter: true
    },
    {
      title: 'Является стоянкой',
      dataIndex: 'isDefault',
      sorter: true,
      render: (text: string, record: LocationType) =>
        record.isDefault ? 'Да' : 'Нет'
    },
    {
      title: 'Наличие дублей',
      dataIndex: 'hasDuplicate',
      sorter: true,
      render: (text: string, record: any) =>
        record.hasDuplicate ? 'Да' : 'Нет'
    },
    {
      title: ' ',
      dataIndex: 'delete',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: OrgUnitNode): any => (
        <Operations>
          {this.canHandle() && (
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => 
                await this.deleteLocationBaseFunc(record.id, locationApi.deleteLocation.bind(locationApi))}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )}
        </Operations>
      )
    }
  ];

  handleRowClick = (id: number) => navigate(`/admin/locations/form/${id}`);

  cleanFilter = () => {
    this.setState({ filter: {} }, this.fetchLocations);
    if (this.inputSearchRef.current) {
      this.inputSearchRef.current.state.value = '';
    }
  }

  applyFilter = (filter: LocationFilterParams) =>
    this.setState({ filter }, this.fetchLocations);

  onSelect = (record: any, selected: boolean) => {
    if (!selected) {
      const allExceptUnselected = [...this.state.selectedRowKeys].filter((key) => key !== record['id']);
      this.setState({selectedRowKeys: allExceptUnselected});
    } else {
      this.setState({selectedRowKeys: [...this.state.selectedRowKeys, record['id']]});
    }
  };

  hasDuplicates = (id: number) => {
    const {data} = this.state;
    
    const hasDuplicateBool = data.find((record) => record.id === id).hasDuplicate;

    if (hasDuplicateBool) return true;

    return false;
  }

  onSelectAll = (selected: boolean, selectedRecord: any[]) => {
    const recordsIds = selectedRecord.map((record: any) => record['id']);

    if (!selected) {
      this.setState({selectedRowKeys: []});
    } else {
      this.setState({selectedRowKeys: recordsIds});
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, page, pageSize, loading, filter, selectedRowKeys } = this.state;
    const canHandle = this.canHandle();

    const rightsWithAccess = [
      accessTypeEnum.removingManyLocations,
      accessTypeEnum.admin
    ];
  
    const canDeleteManyLocations = this.props.userAccess.some(access =>
      rightsWithAccess.includes(access),
    );
    
    const rowSelection = canDeleteManyLocations ? {
        selectedRowKeys: this.state.selectedRowKeys,
        onSelect: this.onSelect,
        onSelectAll: this.onSelectAll
      } : null;

    return (
        <>
          <Section style={{marginBottom: '0'}}>
            <Filter
              selectedRowKeys={this.state.selectedRowKeys}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              inputSearchRef={this.inputSearchRef}
            />
            <Table
              rowSelection={rowSelection}
              columns={this.columns}
              data={data}
              loading={loading}
              rowKey="id"
              onRow={
                canHandle
                  ? record => ({
                      onClick: () => this.handleRowClick(record.id)
                    })
                  : null
              }
              fetch={this.fetchLocations}
              fetchOnMount
              pagination={{
                page,
                pageSize,
                totalCount,
                location
              }}
              rowClassName={record => this.hasDuplicates(record.id)
                ? 'table-row-error'
                : null
              }
            />
          </Section>

          {(selectedRowKeys.length > 0 && canDeleteManyLocations) &&
            <StyledSection>
              <Wrapper>
                <ButtonsRow>
                  <DeleteButton type="primary" onClick={this.deleteEntries.bind(this)}>
                    Удалить
                  </DeleteButton>
                </ButtonsRow>
              </Wrapper>
            </StyledSection>
          }
        </>
    );
  }
}

export default withUserAccess(LocationList);
