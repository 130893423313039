// @flow

import Column from 'antd';
import React, {Component} from 'react';
import styled from 'styled-components';

import AntIcon from 'antd/lib/icon';
import AntTable from 'antd/lib/table';

import type {BranchBudgetSummary, BranchBudgetSummaryLineItem, ListState} from '../../../../lib/types';
import {getListInitialState, minus, toLocalStringRu} from '../../../../lib/helpers';

import {Section, SectionTitle} from './../../../../components/layout';

type Props = {
  loading: boolean,
  budgetAnalysisId: number,
  data: any[],
  defaultTab: string,
  branchBudgetSummary: ?BranchBudgetSummary
};

type BranchBudgetSummaryLineItemWithDiff = BranchBudgetSummaryLineItem & {
  diff: number
};

type State = ListState<BranchBudgetSummaryLineItemWithDiff> & {
  columns: Column[]
};

const StyledTable = styled(AntTable)`
  // Фикс для хрома, для отображениия текста в ячейке, в которой есть стрелка
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-overflow: initial;
  }
  // Да простят меня боги, но без этого в дьявольском хроме все летит к херам
  .ant-table-scroll > div.ant-table-header > table,
  .ant-table-scroll > div.ant-table-body > table {
    width: 100% !important;
  }
`;

const NAME_MONTH_SUM = [
  {
    name: 'itog',
    title: 'Итог',
    id: 13
  },
  {
    name: 'january',
    title: 'Январь',
    id: 1
  },
  {
    name: 'february',
    title: 'Февраль',
    id: 2
  },
  {
    name: 'march',
    title: 'Март',
    id: 3
  },
  {
    name: 'april',
    title: 'Апрель',
    id: 4
  },
  {
    name: 'may',
    title: 'Май',
    id: 5
  },
  {
    name: 'june',
    title: 'Июнь',
    id: 6
  },
  {
    name: 'july',
    title: 'Июль',
    id: 7
  },
  {
    name: 'august',
    title: 'Август',
    id: 8
  },
  {
    name: 'september',
    title: 'Сентябрь',
    id: 9
  },
  {
    name: 'october',
    title: 'Октябрь',
    id: 10
  },
  {
    name: 'november',
    title: 'Ноябрь',
    id: 11
  },
  {
    name: 'december',
    title: 'Декабрь',
    id: 12
  }
];

export default class extends Component<Props, State> {
  whiteSpaceWrapColumn = (data: string | number, href?: string) => {
    return (
      <div style={{ whiteSpace: 'pre-line', overflow: 'auto' }}>
        {href ? (
          <a href={href} target="_blank" rel="noopener noreferrer">
            {toLocalStringRu(data)}
          </a>
        ) : (
          toLocalStringRu(data)
        )}
      </div>
    );
  };

  state = {
    ...getListInitialState(),
    columns: [
      {
        dataIndex: 'budgetLineItemName',
        title: 'Статья бюджетной формы',
        key: 'budgetLineItemName',
        width: 250,
        fixed: true,
        render: data => this.whiteSpaceWrapColumn(data)
      },
      {
        dataIndex: 'expenseDirectionName',
        title: 'Направление расходов',
        key: 'expenseDirectionName',
        width: 250,
        fixed: true,
        render: (expenseDirectionName: string) =>
          this.whiteSpaceWrapColumn(expenseDirectionName)
      },
      ...NAME_MONTH_SUM.map((col, index) => ({
        title: col.title,
        children: [
          {
            dataIndex: 'months',
            title: 'План',
            width: 120,
            render: months => {
              const plan =
                months.find(month => month.month === col.id)?.plan ?? '-';
              return plan !== '-' ? this.whiteSpaceWrapColumn(plan) : plan;
            }
          },
          {
            dataIndex: 'months',
            title: 'Факт',
            width: 120,
            render: (months, data) => {
              const fact =
                months.find(month => month.month === col.id)?.fact ?? '-';
              return fact !== '-'
                ? this.whiteSpaceWrapColumn(
                    fact,
                    !!data.expenseDirectionId && index !== 0
                      ? `/budget/budget-analysis/${this.props.budgetAnalysisId}/${data.id}/${index}`
                      : undefined
                  )
                : fact;
            }
          },
          {
            dataIndex: 'months',
            title: 'Отклонения',
            width: 120,
            render: months => {
              const diff =
                months.find(month => month.month === col.id)?.diff ?? '-';
              return diff !== '-' ? (
                <div
                  style={{
                    color: diff > 0 ? '#2770FF' : diff === 0 ? '' : '#E9180A'
                  }}
                >
                  {this.whiteSpaceWrapColumn(Math.abs(diff))}
                </div>
              ) : (
                diff
              );
            }
          }
        ]
      }))
    ]
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.data !== this.props.data) this.validateData();
  }

  validateData = () => {
    let data = this.props.data.map((budget: BranchBudgetSummaryLineItem) => {
      let months = budget.months.map(month => ({
        ...month,
        diff: minus(month.plan, month.fact) ?? 0
      }));

      months.push({
        plan: budget.sum,
        fact: budget.factSum,
        diff: minus(budget.sum, budget.factSum) ?? 0,
        month: 13
      });

      return {
        ...budget,
        months,
        expenseDirections: budget.expenseDirections.map(expenseDirection => {
          let months = expenseDirection.months.map(month => ({
            ...month,
            diff: minus(month.plan, month.fact)
          }));

          months.push({
            plan: expenseDirection.sum,
            fact: expenseDirection.factSum,
            diff: minus(expenseDirection.sum, expenseDirection.factSum) ?? 0,
            month: 13
          });

          return {
            ...expenseDirection,
            months
          };
        }),
        expenseDirectionName: 'Итого'
      };
    });
    this.setState({ data: [...data] });
  };

  render() {
    const { loading, branchBudgetSummary } = this.props;
    const { data } = this.state;
    return (
      <>
        <Section>
          {branchBudgetSummary && (
            <SectionTitle>
              <span>Филиал: {branchBudgetSummary?.orgUnitName}</span>
              <span style={{ marginLeft: '50px' }}>
                {`Версия бюджета`}
                <a
                  href={`/budget/summary/${branchBudgetSummary.budgetSummaryId}/${branchBudgetSummary.orgUnitId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {` ${branchBudgetSummary?.name}`}
                </a>
              </span>
            </SectionTitle>
          )}
          <StyledTable
            loading={loading}
            dataSource={data}
            columns={this.state.columns}
            childrenColumnName="expenseDirections"
            expandRowByClick
            expandIconAsCell={false}
            expandIconColumnIndex={1}
            expandIcon={row =>
              !!row.record.expenseDirections && (
                <AntIcon
                  type={row.expanded ? 'up' : 'down'}
                  style={{
                    fontSize: '12px',
                    float: 'right'
                  }}
                />
              )
            }
            bordered
            rowKey="id"
            style={{
              overflow: 'auto'
            }}
            scroll={{
              y: 'calc(100vh - 210px)',
              x: 'auto'
            }}
            pagination={false}
          />
        </Section>
      </>
    );
  }
}
