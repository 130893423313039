// @flow
import React from 'react';
import type { SelectProps } from 'antd/lib/select';
import {
  contractVehiclePlanStatus,
  contractVehiclePlanStatusEnum
} from '../../lib/enum';
import type { EntityStatusType } from '../../lib/types';
import AutoGeneratedEnumSelect from './AutoGeneratedEnumSelect';

type Props = {
  exclude?: EntityStatusType[]
} & SelectProps;
export default ({ exclude, ...props }: Props) => {
  let autoType = {};
  if (Array.isArray(exclude)) {
    Object.keys(contractVehiclePlanStatusEnum).forEach(status => {
      if (!exclude.includes(status)) {
        autoType[status] = contractVehiclePlanStatus[status];
      }
    });
  } else {
    autoType = { ...contractVehiclePlanStatus };
  }
  const Select = AutoGeneratedEnumSelect({
    placeholder: 'Выберите статус',
    autoType
  });
  return <Select {...props} />;
};
