// @flow
import * as yup from 'yup';
import vinDecoder from 'vin-decode';

/**
 * Допустимые буквы латинского алфавита
 * записанные кириллическими буквами
 */
export const cyrLetter = 'авекмнорстухАВЕКМНОРСТУХ';

/**
 * Валидация СТС номеров
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const stsValidate = yup
.string()
.matches(new RegExp(`^(\\d{10}|\\d{2}[${cyrLetter}]{2}\\d{6}|[a-zA-Z${cyrLetter}]{2}\\d{6})$`), {
  message: 'Серия и номер СТС не соответствуют формату',
});

/**
 * Валидация ПТС номеров
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const ptsValidate = yup
.string()
.matches(
  new RegExp(`^(\\d{15}|\\d{10}|\\d{2}[a-zA-Z${cyrLetter}]{2}\\d{6}|[a-zA-Z${cyrLetter}]{4}\\d{6}|[a-zA-Z${cyrLetter}]{2}\\d{6})$`),
  {
    message: 'Серия и номер ПТС не соответствуют формату',
  },
);

/**
 * Валидация VIN номеров
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const vinValidate = yup
.string()
.test('VIN', 'Указан неверный формат VIN номера', vin => {
  return vin ? vinDecoder(vin) : true;
});

/**
 * Валидация гос. номеров номеров
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const licensePlateValidate = yup
.string()
.matches(new RegExp(`^([${cyrLetter}]\\d{3}[${cyrLetter}]{2}|[${cyrLetter}]{2}\\d{4}|\\d{4}[${cyrLetter}]{2})\\d{2,3}$`),
  {
    message: 'Указан неверный формат гос. номера',
  },
);

/**
 * Валидация серии и номера полиса ОСАГО
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const osagoValidate = yup
.string()
.matches(new RegExp(`^[${cyrLetter}]{3}\\d{10}$`), {
  message: 'Указан неверный формат серии и номера ОСАГО',
});

/**
 * Валидация email адреса
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const emailValidate = yup
.string()
.matches(new RegExp(
  '^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$',
  'i',
), {
  message: 'Неверно указан email-адрес',
});

/**
 * Валидация водительского удостоверения
 * @param value
 * @return Сообщение об ошибке в случае ошибки
 */
export const licenseNumberValidate = yup
.string()
.matches(new RegExp(`^\\d{2}(\\d{2}|[${cyrLetter}]{2})\\d{6}$`), {
  message: 'Указан неверный формат серии и номера водительского удостоверения',
});
