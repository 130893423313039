// @flow

import React, {Component} from 'react';
import notification from 'antd/lib/notification';
import {FormikErrors} from 'formik';
import {connect} from 'react-redux';

import styled from 'styled-components';

import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import type {UserAccess, VehicleModel} from '../../lib/types';
import type {AppState} from '../../ducks/redux';
import {addVehicleModel, cleanVehicleModel, fetchVehicleModel, updateVehicleModel} from '../../ducks/vehicleModel';
import {Panel} from './../../components/layout';
import InnerForm from './components/InnerForm';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../components/Notifications';
import {accessTypeEnum} from '../../lib/enum';
import {VehicleOwnerTypesEnum} from '../../lib/types/vehicleModel';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  submit: (values: VehicleModel) => Promise<FormikErrors<VehicleModel> | null>,
  model: ?VehicleModel,
  cleanVehicleModel: Function,
  fetchVehicleModel: Function,
  vehicleModelId: number,
  updateVehicleModel: Function,
  addVehicleModel: Function,
  userAccess: UserAccess[],
  type: $Keys<VehicleOwnerTypesEnum>,
};

class VehicleModelForm extends Component<Props> {
  async componentDidMount() {
    const {vehicleModelId} = this.props;
    await this.props.cleanVehicleModel();
    if (vehicleModelId) {
      try {
        await this.props.fetchVehicleModel(this.props.vehicleModelId);
      } catch (error) {
        navigate('/vehicle-models', true);
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  }

  handleSubmit = async (values: VehicleModel) => {
    const {model, updateVehicleModel, addVehicleModel, type} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (model) {
        await updateVehicleModel({
          ...values,
          id: model.id,
        });
      } else {
        values.vehicleOwnerType = type;
        await addVehicleModel(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate(`/vehicle-models/${type}`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const {model, userAccess, type} = this.props;

    const canEdit = userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.handlingVehicleModel].includes(access));

    const canEditMaitenanceNormative = userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.editingMaitenanceNormative].includes(access));

    if (!userAccess.includes(accessTypeEnum.admin) &&
      ((type === VehicleOwnerTypesEnum.self && !userAccess.includes(accessTypeEnum.viewingVehicleModel)) || (
      type === VehicleOwnerTypesEnum.contract && !userAccess.includes(accessTypeEnum.viewingContractVehicleModel)
    ))) {
      return 'Страница не найдена';
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(`/vehicle-models/${type}`)}>
                Модели ТС
              </Crumb>
              {model ? (
                <Crumb to={`/vehicle-models/${type}/${model.id}`}>
                  Модель ТС №{model.id}
                </Crumb>
              ) : (
                <Crumb to={`/vehicle-models/${type}/new`}>Новая модель ТС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{model ? `Модель ТС №${model.id}` : 'Новая модель ТС'}</h1>
        </StyledPanel>
        <InnerForm
          vehicleModel={model}
          onSubmit={this.handleSubmit}
          onCancel={() => navigate(`/vehicle-models/${type}`, true)}
          canEdit={canEdit}
          canEditMaitenanceNormative={canEditMaitenanceNormative}
          isSelfVehicleModel={type === VehicleOwnerTypesEnum.self}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    model: state.vehicleModel,
    vehicleModelId: parseInt(ownProps.vehicleModelId, 10),
    userAccess: state.auth.profile.access,
  }),
  {
    fetchVehicleModel,
    cleanVehicleModel,
    updateVehicleModel,
    addVehicleModel,
  },
)(VehicleModelForm);
