import React, {useState} from 'react';
import styled from 'styled-components';
import Grid, {GridItem} from '../../../components/layout/Grid';
import {InputNumber} from './VerificationForm.elements';
import {Card} from '../../../components';
import type {TripAttachedEquipment} from '../../../lib/types/trip';

const Title = styled.p`
  font-weight: 500;
  margin: 0 0 6px;
`;

const { Field } = Card;

type Props = {
  equipment: TripAttachedEquipment,
  onBlur?: () => void,
  editMode?: boolean
};

const EquipmentRow: React.FC<Props> = ({
  equipment,
  onBlur,
  editMode = true
}) => {
  const { fuelConsumption } = equipment.attachedEquipment;
  const [value, setValue] = useState(0);
  const machineHours = !editMode && equipment.machineHours;
  return (
    <GridItem fullWidth>
      <Title>{equipment.attachedEquipment.name}</Title>
      <Grid cols={editMode ? 3 : undefined} gutter="16px">
        <GridItem>
          <Field label="Время работы навесного оборудования, ч. мин">
            {editMode ? (
              <InputNumber
                decimalSeparator=","
                onBlur={onBlur}
                onChange={value => {
                  setValue(value);
                  equipment.machineHours = value;
                  equipment.fuelConsumption =
                    Math.round(value * fuelConsumption * 100) / 100;
                }}
                min={0}
              />
            ) : (
              machineHours
            )}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Расход топлива навесного оборудования, л">
            {editMode ? (
              <InputNumber
                decimalSeparator=","
                value={Math.round(value * fuelConsumption * 100) / 100}
                disabled
              />
            ) : (
              Math.round(
                equipment.attachedEquipment.fuelConsumption * machineHours * 100
              ) / 100
            )}
          </Field>
        </GridItem>
        {editMode && (
          <GridItem>
            <Field label="Норма расхода топлива навесного оборудования, л/маш. час">
              <InputNumber
                decimalSeparator=","
                defaultValue={fuelConsumption}
                disabled
              />
            </Field>
          </GridItem>
        )}
      </Grid>
    </GridItem>
  );
};

export default EquipmentRow;
