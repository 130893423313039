// @flow
import moment from 'moment';

export const getDisabledEqipmentInstallRemoveDate = (currentDate: moment) => {
  return !moment(currentDate).isBetween(
    moment
      .utc()
      .add(-10, 'day')
      .startOf('day'), // по треованиям не более 10 дней назад (текущий день не учитывать)
    moment().endOf('day')
  );
};
