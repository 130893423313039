// @flow
import React from 'react';

import { InputProps } from 'antd/lib/input';
import MaskInput from '../MaskInput';
import { cyrLetter } from '../../lib/validation';

export const formatOsagoMask = () => 'aaa 9999999999';

/**
 * Маска удостоверения осаго
 */
export default (props: InputProps) => (
  <MaskInput
    mask="aaa 9999999999"
    upperCase
    formatChars={{
      a: `[${cyrLetter}]`
    }}
    convertOnSave={(value: string) => value.replace(/\s+/g, '')}
    {...props}
  />
);
