/* ContractTripCard.js | Реестр ПЛ НТС */
// @flow
import * as React from 'react';
import notification from 'antd/lib/notification';
import {ContractTripApi} from '../../../lib/api';
import {ContractTrip, ContractTripMonth} from '../../../lib/types/contractTrips';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {getListInitialState, getPathWithHistoryParams} from '../../../lib/helpers';
import {Grid, GridItem, Panel, Section} from '../../../components/layout';
import styled from 'styled-components';
import {TabItem, Table, Tabs} from '../../../components/ui';
import type {ListState, UserAccess} from '../../../lib/types';
import moment from 'moment';
import Icon from 'antd/lib/icon';
import {Button, Popover} from 'antd';
import {
  accessTypeEnum,
  fuelTypes,
  monthsNamesTranslitEnum,
  newContractTripStatusEnum,
  newContractTripStatuses,
  cognosSendingStatuses,
} from '../../../lib/enum';
import qs from 'query-string';
import ContractTripVehiclesFilter from './components/ContractTripVehiclesFilter';
import {SectionContent} from '../../../components/hoc/common/components/elements';
import Field from '../../../components/card/Field';
import {FormField} from '../../../components/Form';
import TripEditingModal from './components/TripEditingModal';
import {withUserAccess} from '../../withUserAccess';
import {notificationLoading} from '../../../components/Notifications';
import {connect} from 'react-redux';
import type {AppState} from '../../../ducks/redux';
import type {PersistFilterPayload} from '../../../ducks/persistFilters';
import {setFilter} from '../../../ducks/persistFilters';
import type {OrderFilterParams} from "../../Orders/Filter";
import TripSplittingModal from "./components/TripSplittingModal";

const StyledPanel = styled(Panel)`
  padding-top: 0;
  padding-bottom: 0;
`;

const TableTitle = styled.p`
  white-space: break-spaces;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0;
`;

type Props = {
  id: number,
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void,
};

type State = ListState<ContractTrip> & {
  filter: {},
  columns: any[],
  tripMonth: ?ContractTripMonth,
  selectedTrip: ?ContractTrip,
  filterPath: string,
  totalValues: any,
  isTripEditingModalVisible: boolean,
  isTripSplittingModalVisible: boolean,
};

class ContractTripCard extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    pageSize: 50,
    filter: {},
    columns: [
      {
        align: 'center',
        title: 'Статус',
        dataIndex: 'errorDescription',
        render: error => {
          return error ? (
            error === 'qwertyytrewq1234554321' ? ( // костыль для отображения итоговой строки в таблице
              'Итого по ТС:'
            ) : (
              // <Popover content={<p>{error}</p>} title="Ошибка">
              <Popover content={<p style={{ whiteSpace: 'pre-line' }}>{error}</p>} title="Ошибка">
                <Icon
                  type="warning"
                  style={{ color: '#ff1a4a', fontSize: '16px' }}
                />
              </Popover>
            )
          ) : (
            <Icon type="check" style={{ color: 'green', fontSize: '16px' }} />
          );
        },
        width: 55,
        fixed: true,
      },
      {
        title: '№',
        dataIndex: 'number',
        width: 45,
        fixed: true,
      },
      {
        title: 'Модель ТС',
        dataIndex: 'vehicleModel',
        width: 120,
        fixed: true,
      },
      {
        title: 'Марка ТС',
        dataIndex: 'vehicleBrand',
        width: 120,
        fixed: true,
      },
      {
        title: <TableTitle>Государственный регистрационный знак</TableTitle>,
        dataIndex: 'licensePlate',
        width: 110,
        fixed: true,
      },
      {
        title: <TableTitle>Дата путевого листа, начало</TableTitle>,
        dataIndex: 'startDate',
        render: date => date ? moment.utc(date).format('DD.MM.YYYY') : undefined,
        width: 90,
        fixed: true,
      },
      {
        title: <TableTitle>Дата путевого листа, окончание</TableTitle>,
        dataIndex: 'endDate',
        render: date => date ? moment.utc(date).format('DD.MM.YYYY') : undefined,
        width: 100,
      },
      {
        title: <TableTitle>Время работы всего</TableTitle>,
        dataIndex: 'totalWorkTime',
        width: 80,
      },
      {
        title: <TableTitle>Время в движении</TableTitle>,
        dataIndex: 'movementTime',
        width: 60,
      },
      {
        title: <TableTitle>Тариф в движении</TableTitle>,
        dataIndex: 'movementPrice',
        width: 60
      },
      {
        title: <TableTitle>Время ожидания</TableTitle>,
        dataIndex: 'waitingTime',
        width: 60,
      },
      {
        title: <TableTitle>Тариф ожидания</TableTitle>,
        dataIndex: 'waitingPrice',
        width: 60,
      },
      {
        title: <TableTitle>Время простоя с включенным двигателем</TableTitle>,
        dataIndex: 'waitingWithRunningEngineTime',
        width: 90,
      },
      {
        title: <TableTitle>Тариф простоя с включенным двигателем</TableTitle>,
        dataIndex: 'waitingWithRunningEnginePrice',
        width: 90
      },
      {
        title: <TableTitle>Время отопителя</TableTitle>,
        dataIndex: 'heaterTime',
        width: 60,
      },
      {
        title: <TableTitle>Тариф отопителя</TableTitle>,
        dataIndex: 'heaterPrice',
        width: 60,
      },
      {
        title: <TableTitle>Время подогревания</TableTitle>,
        dataIndex: 'preheaterTime',
        width: 80,
      },
      {
        title: <TableTitle>Тариф подогревания</TableTitle>,
        dataIndex: 'preheaterPrice',
        width: 80,
      },
      {
        title: <TableTitle>Время силовой генератор</TableTitle>,
        dataIndex: 'powerGeneratorTime',
        width: 80,
      },
      {
        title: <TableTitle>Тариф силовой генератор</TableTitle>,
        dataIndex: 'powerGeneratorPrice',
        width: 80,
      },
      {
        title: <TableTitle>Время лебедка</TableTitle>,
        dataIndex: 'winchTime',
        width: 80,
      },
      {
        title: <TableTitle>Тариф лебедка</TableTitle>,
        dataIndex: 'winchPrice',
        width: 80,
      },
      {
        title: 'Пробег',
        dataIndex: 'distance',
        width: 60
      },
      {
        title: 'Тариф, км',
        dataIndex: 'distancePrice',
        width: 70
      },
      {
        title: <TableTitle>Пробег с прицепом</TableTitle>,
        dataIndex: 'distanceWithTrailer',
        width: 70,
      },
      {
        title: <TableTitle>Тариф, км. с прицепом</TableTitle>,
        dataIndex: 'distanceWithTrailerPrice',
        width: 70,
      },
      {
        title: <TableTitle>Сумма без НДС</TableTitle>,
        dataIndex: 'sum',
        width: 60,
      },
      {
        title: 'Направление расходов',
        dataIndex: 'expenseDirectionName',
        width: 470
      },
      {
        title: 'Подменяемое ТС',
        dataIndex: 'originalContractVehicleName',
        width: 280
      },
      {
        title: 'Тип ТС',
        dataIndex: 'vehicleType',
        width: 230
      },
      {
        title: 'Год выпуска',
        dataIndex: 'vehicleProductionYear',
        width: 55
      },
      {
        title: 'Вид топлива',
        dataIndex: 'fuelType',
        render: type => fuelTypes[type] || type,
        width: 90
      },
    ],
    tripMonth: null,
    selectedTrip: null,
    filterPath: window.location.pathname,
    totalValues: null,
    isTripEditingModalVisible: false,
    isTripSplittingModalVisible: false,
  };

  componentDidMount() {
    if (this.props.id) {
      this.fetchMonth(this.props.id);
      const { page } = qs.parse(window.location.search);
      const { filterPath } = this.state;
      const filter = this.props.persistFilters[filterPath] || {};
      this.setState(
        {
          page: page || 1,
          filter: { ...filter }
        },
        this.fetch
      );
    }
  }

  fetchMonth = async monthId => {
    try {
      const tripMonth = await ContractTripApi.fetchTripMonthById(monthId);
      this.setState({ tripMonth });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title
      });
    }
  };

  fetch = async params => {
    try {
      const { filter, pageSize, page } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await ContractTripApi.fetchTrips({
        page,
        pageSize,
        ...filter,
        ...params,
        contractTripMonthId: this.props.id
      });
      const totalValues = await ContractTripApi.getSum({
        ...filter,
        ...params,
        contractTripMonthId: this.props.id
      })
      this.setState({
        data,
        totalCount,
        loading: false,
        page: params?.page || page,
        totalValues,
      });
      // await this.onCheckingErrorNotification();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title
      });
    }
  };

  getTotalRow = () => {
    let totalData = {
      errorDescription: 'qwertyytrewq1234554321',
      id: Math.random(),
      totalWorkTime: 0,
      movementTime: 0,
      waitingTime: 0,
      waitingWithRunningEngineTime: 0,
      heaterTime: 0,
      preheaterTime: 0,
      powerGeneratorTime: 0,
      winchTime: 0,
      distance: 0,
      distanceWithTrailer: 0,
      sum: 0,
      error: 'notError'
    };
    if (this.state.totalValues) {
      const {totalValues} = this.state;
      totalData = {
        ...totalData,
        totalWorkTime: totalValues.totalWorkTime,
        movementTime: totalValues.movementTime,
        waitingTime: totalValues.waitingTime,
        waitingWithRunningEngineTime: totalValues.waitingWithRunningEngineTime,
        heaterTime: totalValues.heaterTime,
        preheaterTime: totalValues.preheaterTime,
        powerGeneratorTime: totalValues.powerGeneratorTime,
        winchTime: totalValues.winchTime,
        distance: totalValues.distance,
        distanceWithTrailer: totalValues.distanceWithTrailer,
        sum: totalValues.sum,
      };
    }
    return totalData;
  };
  
  setPersistFilter = async (values: OrderFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async filter => {
    this.setState({ filter, page: 1 }, this.fetch);
    await this.setPersistFilter(filter);
  };

  cleanFilter = async () => {
    this.setState({ filter: {} }, this.fetch);
    await this.setPersistFilter({});
  };

  canEdit = () =>
    [accessTypeEnum.admin, accessTypeEnum.handlingContractTrip].some(access =>
      this.props.userAccess.includes(access)
    );

    updateTariffs = async () => {
      try {
        notificationLoading({ message: 'Обновление', key: 'saving' });
        const tripMonth = await ContractTripApi.updateTariffsInTripMonth(
          this.state.tripMonth.id
        );
        this.setState({ tripMonth },  this.fetch);
        notification.success({message: 'Обновлено'});
      } catch (e) {
        notification.error({
          message: 'Ошибка Сохранения данных',
          description: e.title || e.message
        });
      } finally {
        notification.close('saving');
      }
    };

  handleAprove = async () => {
    notification.close('checkingResult');
    try {
      notificationLoading({ message: 'Сохранение данных', key: 'saving' });
      const tripMonth = await ContractTripApi.changeStatus(
        this.state.tripMonth.id,
        newContractTripStatusEnum.aproved
      );
      this.setState({ tripMonth });
    } catch (e) {
      notification.error({
        message: 'Ошибка Сохранения данных',
        description: e.title || e.message
      });
    } finally {
      notification.close('saving');
    }
  };

  // Проверка наличия ошибок в реестре ПЛ
  onCheckingErrorNotification = async () => {
    try {
      if (!this.state.tripMonth) return;
      const result = await ContractTripApi.checkErrors(this.state.tripMonth.id);
      if (result) {
        notification.warn({
          message: 'Внимание, в реестре ПЛ обнаружены следующие проблемы:',
          description: (
            <div>
              {result.split(',').map(error => (
                <p key={Math.random()}>{error}</p>
              ))}
            </div>
          ),
          duration: 5,
          key: 'errorCheckingNotification'
        });
      }
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message
      });
    }
  }

  checkingError = async () => {
    try {
      notificationLoading({
        message: 'Осуществляется проверка реестра ПЛ на наличие ошибок',
        key: 'errorChecking'
      });
      const result = await ContractTripApi.checkErrors(this.state.tripMonth.id);
      if (result) {
        notification.error({
          message: 'Внимание, в реестре ПЛ обнаружены следующие проблемы:',
          description: (
            <div>
              {result.split(',').map(error => (
                <p key={Math.random()}>{error}</p>
              ))}
              <div>
                Продолжить подтверждение реестра?
                <Button
                  size="small"
                  style={{ marginRight: '10px' }}
                  type="primary"
                  onClick={this.handleAprove}
                >
                  Да
                </Button>
                <Button
                  size="small"
                  onClick={() => notification.close('checkingResult')}
                >
                  Нет
                </Button>
              </div>
            </div>
          ),
          duration: null,
          key: 'checkingResult'
        });
      } else {
        notification.warning({
          message: 'Уверены, что хотите подтвердить реестр ПЛ?',
          description: (
            <div>
              <Button
                size="small"
                style={{ marginRight: '10px' }}
                type="primary"
                onClick={this.handleAprove}
              >
                Да
              </Button>
              <Button
                size="small"
                onClick={() => notification.close('checkingResult')}
              >
                Нет
              </Button>
            </div>
          ),
          duration: null,
          key: 'checkingResult'
        });
      }
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message
      });
    } finally {
      notification.close('errorChecking');
    }
  };

  showTabs = () =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.viewingContractTripVehicleLayout
    ].some(access => this.props.userAccess.includes(access));

  // Открыть модальное окно "Редактирование ПЛ НТС".
  openEditModal = (trip) => {
    this.setState({ selectedTrip: trip, isTripEditingModalVisible: true });
  };

  // Закрыть модальное окно "Редактирование ПЛ НТС".
  closeEditModal = () => {
    this.setState({ selectedTrip: null, isTripEditingModalVisible: false });
  };

  // Открыть модальное окно "Декомпозиця ПЛ НТС".
  openSplitModal = async (trip) => {
    this.setState({ selectedTrip: trip, isTripSplittingModalVisible: true });
    if (this.tripSplittingModalRef) {
      await this.tripSplittingModalRef.loadSplitTrips(trip);
    }
  };

  // Закрыть модальное окно "Декомпозиця ПЛ НТС".
  closeSplitModal = () => {
    this.setState({ selectedTrip: null, isTripSplittingModalVisible: false });
    if (this.tripSplittingModalRef) {
      this.tripSplittingModalRef.setState({ isLoadComplete: false });
    }
  };

  render() {
    const { id } = this.props;
    const {
      data,
      columns,
      page,
      pageSize,
      loading,
      totalCount,
      filter,
      tripMonth,
      selectedTrip,
      isTripEditingModalVisible,
      isTripSplittingModalVisible
    } = this.state;
    if (!id) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/trips/contract')}>
                Наемные ТС
              </Crumb>
              <Crumb>Реестр №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
              <Button type="primary" onClick={this.updateTariffs}>
                Обновить сравнение тарифов
              </Button>           
          }
        />
        <StyledPanel>
          <h1 style={{ marginBottom: '14px' }}>{`Реестр ПЛ НТС №${id ||
            ''}`}</h1>
          {this.showTabs() && (
              <Tabs withRouter>
                <TabItem
                  url={`/trips/contract/${id}`}
                  label="Реестр ПЛ НТС"
                  style={{ marginLeft: 0 }}
                />
                <TabItem
                  url={`/trips/contract/layout/${id}`}
                  label="Макет фактических транспортных расходов"
                />
              </Tabs>
            )}
        </StyledPanel>
        <Section>
          {/* Инфо */}
          {tripMonth && (
            <>
            <SectionContent>
              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <Field label="Филиал" valueStyle={{ marginBottom: 0 }}>
                    {tripMonth.orgUnitName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Месяц" valueStyle={{ marginBottom: 0 }}>
                    {monthsNamesTranslitEnum[tripMonth.month]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Год" valueStyle={{ marginBottom: 0 }}>
                    {tripMonth.year}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Статус" valueStyle={{ marginBottom: 0 }}>
                    {newContractTripStatuses[tripMonth.status]}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
            <SectionContent>
            <Title>Статусы отправки в ИСУ Бюджетирование</Title>
                <Grid  gutter="16px" cols={5}>
                  <GridItem>
                  <Field label="Фактический тарификатор" >
                    {cognosSendingStatuses[tripMonth.cognosVehicleTariffsStatus]}
                  </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Макет фактических транспортных расходов" >
                      {cognosSendingStatuses[tripMonth.cognosVehicleLayoutStatus]}
                    </Field>
                  </GridItem>
                </Grid>  
              </SectionContent>
            </>
          )}
          {/* Фильтры */}
          <ContractTripVehiclesFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            monthId={id}
          />
          {/* Таблица */}
          <Table
            columns={columns}
            loading={loading}
            fetch={page => this.fetch({ page })}
            pagination={{
              page,
              pageSize,
              totalCount,
              loading
            }}
            data={[...data, this.getTotalRow()]}
            rowKey="id"
            onRow={selectedTrip => {
              return (
                this.canEdit() &&
                (tripMonth?.status === newContractTripStatusEnum.created || tripMonth?.status === newContractTripStatusEnum.declined) &&
                selectedTrip.id >= 1 && {
                  onClick: () =>
                    this.setState({ selectedTrip, isTripEditingModalVisible: true })
                }
              );
            }}
            rowClassName={item => {
              if (tripMonth?.status !== newContractTripStatusEnum.created 
                  && tripMonth?.status !== newContractTripStatusEnum.declined) {
                return item.parentId > 0 ? 'table-row-split' : '';
              }
              switch (item.error) {
                case 'vehicleNotFound':
                  return item.parentId > 0 ? '.table-row-error-split' : 'table-row-error';
                case item.parentId > 0 ? 'tariffNotFound' : 'tariffNotFound':
                case item.parentId > 0 ? 'tariffNotMatch' : 'tariffNotMatch':
                  return item.parentId > 0 ? 'table-row-warning-split' : 'table-row-warning';
                default:
                  return item.parentId > 0 ? 'table-row-split' : '';
              }
            }}
            scroll={{
              x: 'auto',
              y: 600,
            }}
          />
          {/* Модальное окно "Редактирование ПЛ НТС" */}
          <TripEditingModal
            ref={(ref) => (this.tripEditingModalRef = ref)}
            trip={selectedTrip}
            visible={isTripEditingModalVisible}
            updateTripList={() => this.fetch({ page: 1 })}
            openSplitModal={(trip) => this.openSplitModal(trip)}
            onClose={this.closeEditModal}
          />
          {/* Модальное окно "Декомпозиция ПЛ НТС" */}
          <TripSplittingModal
            ref={(ref) => (this.tripSplittingModalRef = ref)}
            parentTrip={selectedTrip}
            visible={isTripSplittingModalVisible}
            updateTripList={() => this.fetch({ page: 1 })}
            openEditModal={(parentTrip) => this.openEditModal(parentTrip)}
            onClose={this.closeSplitModal}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(ContractTripCard));
