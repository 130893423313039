// @flow
import Icon from 'antd/lib/icon';
import React from 'react';
import { calculationStatusEnum } from '../../../../../lib/enum';
import type { CalculationStatus, VehiclePlan } from '../../../../../lib/types';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number,
  vehiclePlan?: VehiclePlan
};

const IconDone = () => (
  <Icon type="check-circle" style={{ color: 'green', margin: '0 0 2px 3px' }} />
);

const IconWarn = () => (
  <Icon type="warning" style={{ color: 'red', margin: '0 0 2px 3px' }} />
);

export const getLabel = (name: string, status?: Array<?CalculationStatus>) => {
  return (
    <>
      {name}
      {status === undefined || status[0] === undefined ? null : status.every(
          (status?: ?CalculationStatus) =>
            status === calculationStatusEnum.calculationDone
        ) ? (
        <IconDone />
      ) : (
        <IconWarn />
      )}
    </>
  );
};

export default ({ vehiclePlanId, vehiclePlan }: Props) => {
  let activeUrlPass = null;
  if (vehiclePlanId) {
    activeUrlPass = [
      `/budget/vehicle/${vehiclePlanId}/spring-pass`,
      `/budget/vehicle/${vehiclePlanId}/cargo-pass`
    ];
  }

  return (
    <Tabs withRouter style={{ overflow: 'auto', background: 'white' }}>
      <TabItem
        label={getLabel('АКБ', [vehiclePlan?.batteryCalculationStatus])}
        url={`/budget/vehicle/${vehiclePlanId}/batteries`}
      />
      <TabItem
        label={getLabel('ТО ПБ ГПМ', [
          vehiclePlan?.gpmMaintenanceCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/gpm-maintenance`}
      />
      <TabItem
        label={getLabel('GPS', [
          vehiclePlan?.vehicleMonitoringCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/gps`}
      />
      <TabItem
        label={getLabel('Тарировка', [
          vehiclePlan?.measuringDeviceCertificationCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/measuring-device-certifications`}
      />
      <TabItem
        label={getLabel('Гостехнадзор', [
          vehiclePlan?.inspectionGtnCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/inspections-gtn`}
      />
      <TabItem
        label={getLabel('ТО ГИБДД', [
          vehiclePlan?.inspectionGibddCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/inspections-gibdd`}
      />
      <TabItem
        label={getLabel('Замена гос.номера', [
          vehiclePlan?.licensePlateChangeCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/license-plate-change`}
      />
      <TabItem
        label={getLabel('Материалы', [vehiclePlan?.materialCalculationStatus])}
        url={`/budget/vehicle/${vehiclePlanId}/material`}
      />
      <TabItem
        label={getLabel('Мойки', [vehiclePlan?.washingCalculationStatus])}
        url={`/budget/vehicle/${vehiclePlanId}/washing`}
      />
      <TabItem
        label={getLabel('Осмотры водителей', [
          vehiclePlan?.driverInspectionCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/driver-inspection`}
      />
      <TabItem
        label={getLabel('Федеральные трассы', [
          vehiclePlan?.federalHighwayCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/federal-highway`}
      />
      <TabItem
        activeUrl={activeUrlPass}
        label={getLabel('Пропуска', [
          vehiclePlan?.springPassCalculationStatus,
          vehiclePlan?.cargoPassCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/spring-pass`}
      />
    </Tabs>
  );
};
