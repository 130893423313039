// @flow
import Input from 'antd/lib/input';
import React from 'react';
import { FormField } from '../../../../components/Form';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { BudgetLineItemBudgetTypeSelect } from '../../../../components/selects/AutoGeneratedEnumSelects';
import type { BudgetLineItem } from '../../../../lib/types';

export default (props: CommonFormProps<$Shape<BudgetLineItem>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Наименование статьи бюджетной формы"
                  name="name"
                  required
                >
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Идентификатор статьи бюджетной формы"
                  name="mnemocode"
                  required
                >
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="budgetType" label="Тип бюджета" required>
                  {({ name, value }) => (
                    <BudgetLineItemBudgetTypeSelect
                      value={value}
                      onChange={data => {
                        setFieldValue(name, data);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Код статьи для ИСУ Бюджетирование"
                  name="cognosCode"
                >
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Куратор" name="curator">
                  {({ name, value }) => (
                    <Input
                      value={value}
                      onChange={e => {
                        setFieldValue(name, e.target.value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
