//@flow
import React from 'react';
import styled from 'styled-components';
import {Icon, Tooltip} from 'antd';

import {VehicleModelStatusEnum} from '../../../lib/types/vehicleTariff';

const StyledIcon = styled(Icon)`
  padding-left: 10px;
  font-size: 16px;
`;

type VehicleTariffStatusIconProps = {
  modelStatus: VehicleModelStatusEnum,
};

export const VehicleTariffStatusIcon = ({modelStatus}: VehicleTariffStatusIconProps) => {
  const tooltipOverlayStyle = {width: '300px', maxWidth: 'unset'};

  switch (modelStatus) {
    case VehicleModelStatusEnum.Ok:
      return <StyledIcon type="check-circle" style={{color: 'green'}} />
    case VehicleModelStatusEnum.NoModel:
      return (
        <Tooltip
          placement="right"
          title="Не удалось сопоставить модель, проверьте наличие
            указанной марки и модели в соответствующих справочниках"
          overlayStyle={tooltipOverlayStyle}
        >
          <StyledIcon type="exclamation-circle" theme="twoTone" twoToneColor="red" />
        </Tooltip>
      );
    case VehicleModelStatusEnum.NoPlanTariff:
      return (
        <Tooltip
          placement="right"
          title="Не удалось сопоставить плановый тариф"
          overlayStyle={tooltipOverlayStyle}
        >
          <StyledIcon type="exclamation-circle" theme="twoTone" twoToneColor="orange" />
        </Tooltip>
      )
    default:
      return null;
  }
};
