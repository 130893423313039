// @flow

import React, { Component, type Node } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import { Card } from './../../../../components';
import { Divider } from './../../../../components/layout';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import {
  fuelAndOilGroup,
  fuelAndOilSubType,
  fuelAndOilSubView,
  fuelAndOilType,
  fuelAndOilView
} from './../../../../lib/enum';
import type { VehicleFuelAndOil } from './../../../../lib/types';
import { vehicleFuelAndOilApi } from './../../../../lib/api';

const { Field } = Card;
const FuelAndOilDivider = styled(GridItem)`
  margin-bottom: 15px;
  margin-top: 15px;
`;

type Props = {
  canEdit: boolean,
  vehicleId: number
};

type State = {
  vehicleFuelsAndOils: VehicleFuelAndOil[],
  isLoading: boolean
};

/**
 * Компонент отображает содержимое списка ГСМ
 */
export default class FuelsAndOilsList extends Component<Props, State> {
  state = {
    vehicleFuelsAndOils: [],
    isLoading: false
  };

  async componentDidMount() {
    await this.fetchVehicleFuelsAndOils();
  }

  fetchVehicleFuelsAndOils = async () => {
    try {
      this.setState({
        isLoading: true
      });
      const vehicleFuelsAndOils = await vehicleFuelAndOilApi.fetchVehicleFuelsAndOils(
        {
          pageSize: 0,
          vehicleId: this.props.vehicleId
        }
      );
      this.setState({
        vehicleFuelsAndOils: vehicleFuelsAndOils.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err ? err.message : ''
      });
    } finally {
      this.setState({
        isLoading: false
      });
    }
  };

  onDelete = (id: ?number) => {};

  render() {
    const { canEdit } = this.props;
    const { vehicleFuelsAndOils } = this.state;
    return vehicleFuelsAndOils.map<Node>(
      ({
        name,
        view,
        subView,
        id,
        type,
        subType,
        group,
        viscosity,
        factConsumption,
        normConsumption
      }: VehicleFuelAndOil) => (
        <div key={id}>
          <GridItem fullWidth>
            <Grid gutter="16px" cols={10}>
              <GridItem span={canEdit ? 9 : 10}>
                <Grid gutter="16px" cols={4}>
                  <GridItem>
                    <Field label="Наименование">{name}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Вид">{fuelAndOilView[view]}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Подвид">{fuelAndOilSubView[subView]}</Field>
                  </GridItem>
                  {type && (
                    <GridItem>
                      <Field label="Тип">{fuelAndOilType[type]}</Field>
                    </GridItem>
                  )}
                  {subType && (
                    <GridItem>
                      <Field label="Подтип">{fuelAndOilSubType[subType]}</Field>
                    </GridItem>
                  )}
                  {group && (
                    <GridItem>
                      <Field label="Группа">{fuelAndOilGroup[group]}</Field>
                    </GridItem>
                  )}
                  {viscosity && (
                    <GridItem>
                      <Field label="Вязкость">{viscosity}</Field>
                    </GridItem>
                  )}
                </Grid>
              </GridItem>
              {canEdit && (
                <GridItem span={1}>
                  <Button
                    icon="delete"
                    onClick={canEdit ? () => this.onDelete(id) : null}
                  />
                </GridItem>
              )}
            </Grid>
          </GridItem>
          <FuelAndOilDivider fullWidth>
            <Divider />
          </FuelAndOilDivider>
        </div>
      )
    );
  }
}
