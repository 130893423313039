// @flow
import React, {Component, ComponentType} from 'react';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import {debounce} from 'lodash';
import {Icon} from 'antd';
import type {Vehicle, VehicleForSelection} from '../../lib/types';
import {TabItem, Tabs} from '../ui';
import Grid, {GridItem} from '../layout/Grid';
import {connect} from 'react-redux';

import {
  Header,
  SearchInput,
  SpoilerContent,
  Status,
  TabWrapper,
  VehicleCard,
  VehicleCardWrapper,
  VehicleInfo,
  Wrapper,
} from './VehicleSelection.elements';
import Spinner from '../Spinner';
import Spoiler from '../ui/Spoiler';
import {applyMaskToValue, mapLatToCyr} from '../../lib/helpers';
import {formatLicensePlateMask} from '../masked-inputs/LicensePlateInput';
import {ownerTypes} from '../../lib/enum';
import Input from 'antd/lib/input';
import {Form} from './../../../src/components/index';
import type {FormFieldProps} from '../Form';
import type {AppState} from '../../ducks/redux';
import moment from 'moment';

type Props = {
  // Список ТС
  vehicles: VehicleForSelection[],
  // Только свои или наемные ТС
  onlyType?: $Keys<ownerTypes>,
  // id выбранной ТС
  selected: ?number,
  // Функция выбора ТС
  onSelect: (vehicle: VehicleForSelection) => void,
  editable: boolean,
  loading: boolean,
  // Функция записи измененных данных водителя в стейт родительского компонента
  handleContractDriverData: (driverData: string, dataType: 'name' | 'phone') => void,
  contractDriverPhoneNumber: string,
  contractDriverFullName: string
};

type State = {
  tabKey: $Keys<ownerTypes>,
  searchString: string,
  filteredVehicles: VehicleForSelection[]
};

/**
 * Компонент рисует сетку из машин, которые доступны для выбора
 * Используется при назначении свободного ТС на заявку
 */
export class VehicleSelection extends Component<Props, State> {
  static defaultProps = {
    vehicles: [],
    editable: false,
  };

  state = {
    tabKey: this.props.onlyType ? this.props.onlyType : ownerTypes.self,
    searchString: '',
    filteredVehicles: this.props.vehicles,
    telKey: this.props.onlyType === ownerTypes.contract,
  };

  getDefaultTab = () => {
    const {vehicles, selected: id, onlyType} = this.props;

    if (onlyType) {
      return onlyType;
    }

    const finded: Vehicle = find(vehicles, {id});

    if (finded) {
      switch (finded.ownerType) {
        case ownerTypes.contract:
          return ownerTypes.contract;
        default:
          return ownerTypes.self;
      }
    }
    return ownerTypes.self;
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.vehicles, this.props.vehicles)) {
      this.debounceFilterVehicles(this.state.searchString);
      const currentTab = this.getDefaultTab();
      this.setState({tabKey: currentTab});
      this.handleTabChange(currentTab);
    }
  }

  renderCard = (vehicle: VehicleForSelection) => {
    const {
      editable,
      selected,
      handleContractDriverData,
      onSelect,
    } = this.props;
    const canEdit = editable && !vehicle.disabled;

    return (
      <GridItem key={vehicle.id}>
        <VehicleCard
          className={`vehicle-${vehicle.id}`}
          key={vehicle.id}
          isActive={vehicle.id === selected}
          onClick={canEdit
            ? () => {
              if (!!handleContractDriverData) {
                handleContractDriverData(
                  vehicle.contractDriverFullName
                    ? vehicle.contractDriverFullName
                    : '',
                  'name',
                );
                handleContractDriverData(
                  vehicle.contractDriverPhoneNumber
                    ? vehicle.contractDriverPhoneNumber
                    : '',
                  'phone',
                );
              }
              onSelect(vehicle);
            }
            : null
          }
          editable={canEdit}
          disabled={vehicle.disabled}
        >
          <VehicleInfo>
            <Status
              status={vehicle.isBusy === 'busy' ? 'busy' : vehicle.status}
              showLabel={false}
            />
            <div className="vehicle-info">
              {vehicle.vehicleModel && (
                <p>
                  {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
                  {vehicle.trailers && vehicle.trailers.length > 0 && (
                    <>
                      <br />
                      (с прицепом)
                    </>
                  )}
                  {parseInt(vehicle.drivingVehicleId, 10) > 0 && (
                    <>
                      <br /> (закреплен за ТС)
                    </>
                  )}
                </p>
              )}
              <p>
                {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
              </p>
              {vehicle.isBusy === 'busy' && vehicle.freeDate && (
                <div style={{margin: '7px 0 0 0'}}>
                  <p>Освободится после</p>
                  <p>{moment.utc(vehicle.freeDate).format('DD.MM.YYYY HH:mm')}</p>
                </div>
              )}
            </div>
          </VehicleInfo>
        </VehicleCard>
      </GridItem>
    );
  };

  handleTabChange = (tabKey: ownerTypes.self | ownerTypes.contract) => {
    this.setState({tabKey});
    if (tabKey === ownerTypes.contract) {
      this.setState({telKey: true});
    } else {
      this.setState({telKey: false});
    }
  };

  /**
   * Фильтрация ТС по гос. номеру
   */
  filterVehicles = async (searchString: string) => {
    let filteredVehicles = this.props.vehicles;
    if (searchString) {
      searchString = mapLatToCyr(searchString).toLowerCase();
      filteredVehicles = filteredVehicles.filter(
        ({licensePlate}: Vehicle) =>
          licensePlate && licensePlate.toLowerCase().includes(searchString),
      );
    }
    this.setState({
      filteredVehicles,
    });
  };

  debounceFilterVehicles = debounce(this.filterVehicles, 500);

  onSearchVehicle = (e: any) => {
    const searchString = e.target.value;
    this.setState(
      {
        searchString,
      },
      () => this.debounceFilterVehicles(this.state.searchString),
    );
  };

  render() {
    const {
      onlyType,
      loading,
      contractDriverFullName,
      contractDriverPhoneNumber,
      handleContractDriverData,
    } = this.props;
    const {searchString, filteredVehicles, tabKey} = this.state;
    const {free, busy} = groupBy(
      filteredVehicles.filter(vehicle => {
        
        return vehicle.isLease === (tabKey === ownerTypes.leased)
          && (tabKey === ownerTypes.leased || vehicle.ownerType === tabKey)
/* 
        if (tabKey === ownerTypes.leased) {
          filtered = vehicle.isLease
        } else if (tabKey === ownerTypes.self) {
          filtered = !vehicle.isLease
        } else {
          filtered = vehicle.ownerType === tabKey
        }

        return filtered; */
      }),
      'isBusy',
    );

    const hasBusy = busy && busy.length;
    const hasFree = free && free.length;

    return (
      <Wrapper>
        <Header>
          <TabWrapper>
            <div>
              <p>Транспортные средства</p>
              <Tabs
                type="buttons"
                background="transparent"
                defaultTab={tabKey}
                onChange={this.handleTabChange}
              >
                {(!onlyType || onlyType === ownerTypes.self) && <TabItem tabKey="self" label="Собственные" />}
                {(!onlyType || onlyType === ownerTypes.contract) && <TabItem tabKey="contract" label="Наемные" />}
                {(!onlyType || onlyType === ownerTypes.leased) && <TabItem tabKey="leased" label="Арендованные" />}
              </Tabs>
            </div>
            {this.state.telKey && (
              <Form initialValues={{}}>
                {(FormField: ComponentType<FormFieldProps>) => {
                  return !!handleContractDriverData &&
                  contractDriverFullName !== undefined &&
                  contractDriverPhoneNumber !== undefined ? (
                    <div style={{display: 'flex', alignItems: 'start'}}>
                      <FormField
                        label="Водитель"
                        name="driverId"
                        style={{width: 270, marginRight: 10}}
                        required
                      >
                        {() => (
                          <Input //1 input
                            size="small"
                            placeholder="Водитель"
                            value={contractDriverFullName}
                            onChange={event => {
                              this.props.handleContractDriverData(
                                event.target.value,
                                'name',
                              );
                            }}
                            prefix={
                              <Icon
                                type="user"
                                style={{color: 'rgba(0,0,0,.25)'}}
                              />
                            }
                          />
                        )}
                      </FormField>
                      <FormField
                        label="Номер телефона"
                        style={{width: 270}}
                        required
                      >
                        {() => (
                          <Input //1 input
                            size="small"
                            required
                            placeholder="Телефон водителя"
                            value={contractDriverPhoneNumber?.replace(/[^0-9]/g, '')}
                            maxLength={12}
                            onChange={event => {
                              this.props.handleContractDriverData(
                                event.target.value,
                                'phone',
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </div>
                  ) : null;
                }}
              </Form>
            )}
            <SearchInput
              withtabs={(!onlyType).toString()}
              onChange={this.onSearchVehicle}
              value={searchString}
              placeholder="Поиск по гос. номеру"
              size="small"
            />
          </TabWrapper>
        </Header>
        <Spinner isLoading={loading}>
          {!hasFree && !hasBusy && (
            <p
              style={{
                margin: '10px',
                opacity: '0.4',
              }}
            >
              Подходящие ТС не найдены
            </p>
          )}
          {hasBusy && hasFree && (
            <Spoiler defaultExpanded label="Свободные">
              <SpoilerContent>
                <Grid
                  gutter="8px"
                  rowGutter="8px"
                  cols={2}
                  media={[
                    {
                      size: 'md',
                      cols: 3,
                    },
                    {
                      size: 'lg',
                      cols: 5,
                    },
                  ]}
                >
                  {free.map(this.renderCard)}
                </Grid>
              </SpoilerContent>
            </Spoiler>
          )}

          {!hasBusy && hasFree && (
            <VehicleCardWrapper>
              <Grid
                gutter="8px"
                rowGutter="8px"
                cols={2}
                media={[
                  {
                    size: 'md',
                    cols: 3,
                  },
                  {
                    size: 'lg',
                    cols: 5,
                  },
                ]}
              >
                {free.map(this.renderCard)}
              </Grid>
            </VehicleCardWrapper>
          )}

          {hasBusy && (
            <Spoiler defaultExpanded label="Занятые">
              <SpoilerContent>
                <Grid
                  gutter="8px"
                  rowGutter="8px"
                  cols={2}
                  media={[
                    {
                      size: 'md',
                      cols: 3,
                    },
                    {
                      size: 'lg',
                      cols: 5,
                    },
                  ]}
                >
                  {busy.map(this.renderCard)}
                </Grid>
              </SpoilerContent>
            </Spoiler>
          )}
        </Spinner>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state: AppState): Object => ({
  order: state.order,
});

export default connect(mapStateToProps)(VehicleSelection);
