// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';

import type {EntityStatusType} from './../../../../lib/types';
import {formatDateTimeToISOString} from './../../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../components';
import type {FilterChildrenParams} from './../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';

const { VehiclePlanStatusSelect, BudgetVersionSelect } = Selects;
const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type RegistrBudgetFilterParams = {
  status?: EntityStatusType,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  nodeId?: number,
  id?: number,
  budgetVersionId?: number
};

type Props = {
  filter: RegistrBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<RegistrBudgetFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  size="small"
                  placeholder="Номер бюджета"
                  onChange={e => changeValue('id', e.target.value)}
                  value={values.id}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <BudgetVersionSelect
                  size="small"
                  placeholder="Версия бюджета"
                  onChange={value => changeValue('budgetVersionId', value)}
                  value={values.budgetVersionId}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onlyBranches
                  placeholder="Филиал"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY HH:mm"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehiclePlanStatusSelect
                  placeholder="Выберите статус"
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                  exclude={['processing', 'editing']}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);
