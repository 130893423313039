// @flow
import React, {Component, type ComponentType} from 'react';
import {connect} from 'react-redux';

import type {User, UserAccess} from '../../lib/types';

type Props = {
  userAccess: UserAccess[],
  userProfile: User,
  employeeBranchOrgUnitId: number
};

const withUserAccess = (
  WrappedComponent: ComponentType<any>
): ComponentType<any> => {
  class WithUserAccess extends Component<Props> {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          userAccess={this.props.userAccess}
          employeeBranchOrgUnitId={this.props.employeeBranchOrgUnitId}
        />
      );
    }
  }

  return connect(state => ({
    userAccess: state.auth.profile.access,
    userProfile: state.auth.profile,
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }))(WithUserAccess);
};

export default withUserAccess;
