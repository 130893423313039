// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import {actApi} from '../../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import type {Act} from '../../../lib/types';

import {Header, Panel} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../../components/Notifications';

import {InnerForm} from './components';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  actId: number
};
type State = {
  act: ?Act
};

export class ActForm extends React.Component<Props, State> {
  state = {
    act: null
  };

  componentDidMount = async () => {
    const { actId } = this.props;
    try {
      if (actId) {
        const act = await actApi.getAct(actId);
        this.setState({ act });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  handleSubmit = async (values: Act) => {
    let act = null;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        act = await actApi.updateAct(values);
      } else {
        act = await actApi.addAct(values);
      }
      await navigate(`/admin/act/${act.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/act/', true);

  render() {
    const { act } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/act/')}>
                Реестр первичных документов
              </Crumb>
              {act ? <Crumb>Акт №{act.id}</Crumb> : <Crumb>Новый акт</Crumb>}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Новый акт</h1>
        </StyledPanel>
        <InnerForm
          act={act}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default ActForm;
