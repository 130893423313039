// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Input from 'antd/lib/input/Input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';

import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import type {OptionalEquipment} from '../../../lib/types';
import {Form, OrgUnitSelect, Selects} from '../../../components';
import type {AppState} from '../../../ducks/redux';
import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import {withUserAccess} from './../../withUserAccess';
import type {Profile} from './../../../ducks/auth';
import {optionalEquipmentApi} from '../../../lib/api';
import {convertDateToString} from './../../../containers/Vehicles/lib';
import {getDisabledEqipmentInstallRemoveDate} from './../lib';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Content = styled.div`
  padding: 16px;
`;

const {OptionalsTypeSelect, BrandsSelect} = Selects;

type FormProps = {
  optionalEquipment: $Shape<OptionalEquipment>,
  onSubmit: Function,
  onCancel: Function,
  employeeOrgUnitId: number
};

const InnerForm = ({
  onSubmit,
  onCancel,
  optionalEquipment,
  employeeOrgUnitId,
}: FormProps) => (
  <Form onSubmit={onSubmit} initialValues={optionalEquipment}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        isSubmitting,
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Подразделение"
                    required
                    name="orgUnitId"
                  >
                    {({value, name}) => (
                      <OrgUnitSelect
                        value={value || ''}
                        onChange={(orgUnitId: number) =>
                          setFieldValue(name, parseInt(orgUnitId, 10))
                        }
                        filter={{
                          nodeId: employeeOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                        data-cy="orgUnitSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Наименование" required name="name" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Инвентарный номер"
                    name="inventoryNumber"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип" required name="type" fast>
                    {({value, name}) => (
                      <OptionalsTypeSelect
                        value={value}
                        onBlur={() => handleBlur({target: {name}})}
                        onChange={(value: string) => setFieldValue(name, value)}
                        data-cy="typeSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка" required name="brandId" fast>
                    {({value, name}) => (
                      <BrandsSelect
                        value={value}
                        onBlur={() => handleBlur({target: {name}})}
                        onChange={(value: number) => setFieldValue(name, value)}
                        data-cy="brandSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Мнемокод" required name="code" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата выдачи" name="installDate" fast>
                    {({name, value}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString),
                          )
                        }
                        disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата снятия" name="removeDate" fast>
                    {({name, value}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString),
                          )
                        }
                        disabledDate={getDisabledEqipmentInstallRemoveDate}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  optionalId: number,
  profile: Profile
};

type State = {
  optionalEquipment?: OptionalEquipment
};

class OptionalForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const {optionalId} = this.props;

    if (optionalId) {
      try {
        const optionalEquipment = await optionalEquipmentApi.fetchOptionalEquipment(
          optionalId,
        );
        this.setState({
          optionalEquipment,
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
        navigate('/equipment/optional', true);
      }
    }
  }

  onCancel = () => {
    if (this.props.optionalId) {
      navigate(`/equipment/optional/${this.props.optionalId}`);
    } else {
      navigate('/equipment/optional', true);
    }
  };

  onSubmit = async (optionalEquipment: OptionalEquipment) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      let optionalEquipmentId = optionalEquipment.id;
      if (optionalEquipment.id) {
        await optionalEquipmentApi.updateOptionalEquipment(optionalEquipment);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены',
        });
      } else {
        const addedOptionalEquipment = await optionalEquipmentApi.addOptionalEquipment(
          optionalEquipment,
        );
        optionalEquipmentId = addedOptionalEquipment.id;
        notification.success({
          message: 'Успешное добавление',
          description: `Успешно добавлено доп. оборудование`,
        });
      }
      navigate(`/equipment/optional/${optionalEquipmentId}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const {profile} = this.props;
    const {optionalEquipment} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/equipment/optional')}>
                Приборы и бортовые устройства
              </Crumb>
              {optionalEquipment ? (
                <>
                  <Crumb to={`/equipment/optional/${optionalEquipment.id}`}>
                    {optionalEquipment.brandName} {optionalEquipment.name}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новые приборы и бортовые устройства</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {optionalEquipment
              ? `Приборы и бортовые устройства №${optionalEquipment.id}`
              : 'Новые приборы и бортовые устройства'}
          </h1>
        </StyledPanel>
        <InnerForm
          optionalEquipment={
            optionalEquipment || {orgUnitId: profile.employeeOrgUnitId}
          }
          employeeOrgUnitId={profile.employeeOrgUnitId}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  optionalId: parseInt(props.optionalId, 10),
  profile: state.auth.profile,
}))(withUserAccess(OptionalForm));
