// @flow
import React, {Component} from 'react';

import styled from 'styled-components';
import moment from 'moment';
import {FormikProps} from 'formik';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';

import type {Platon} from './../../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import {platonApi} from './../../../lib/api';

import {Form, Selects} from './../../../components';
import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect } = Selects;
const { RangePicker } = DatePicker;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  platon: Platon,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  platon,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={platon} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        values
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    required
                    label="Дата оказания услуги"
                    name="maintenanceDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="startDate" required label="Период оказания">
                    {({ name, value }) => (
                      <RangePicker
                        format="DD.MM.YYYY"
                        placeholder={['Начало', 'Конец']}
                        value={[
                          values.startDate
                            ? moment.utc(values.startDate)
                            : null,
                          values.endDate ? moment.utc(values.endDate) : null
                        ]}
                        onChange={(value, dateString) => {
                          const [startDate, endDate] = value;
                          const [startDateString, endDateString] = dateString;
                          setFieldValue(
                            'startDate',
                            formatDateTimeToISOString(
                              startDate,
                              startDateString
                            )
                          );
                          setFieldValue(
                            'endDate',
                            formatDateTimeToISOString(endDate, endDateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  platonId: ?number,
  vehicleId: ?number
};

type State = {
  platon: $Shape<Platon>
};

class PlatonForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const platonId = parseInt(this.props.platonId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (platonId) {
        const platon = await platonApi.fetchPlaton(platonId);
        this.setState({
          platon
        });
      } else if (vehicleId) {
        this.setState({
          platon: {
            vehicleId
          }
        });
      } else {
        this.setState({
          platon: {}
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.platonId) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/maintenance/platons`);
    } else {
      if (id) {
        navigate(`/services/platons/${id}`);
      } else {
        navigate(`/services/platons`, true);
      }
    }
  };

  onSubmit = async (platon: Platon) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const platonId = parseInt(this.props.platonId, 10);
      if (platonId) {
        await platonApi.updatePlaton(platon);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(platonId);
      } else {
        const added = await platonApi.addPlaton(platon);

        notification.success({
          message: 'Успешное добавление',
          description: `Платон успешно добавлен`
        });

        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const platonId = parseInt(this.props.platonId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { platon } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId > 0 ? (
                <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>
              ) : (
                <Crumb to={getPathWithHistoryParams('/services/platons')}>
                  Список актов "Платон"
                </Crumb>
              )}
              {platonId ? (
                <Crumb>Редактирование акта</Crumb>
              ) : (
                <Crumb>Новый акт</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {platonId ? `Акт "Платон" №${platonId}` : 'Новый акт "Платон"'}
          </h1>
        </StyledPanel>
        <InnerForm
          platon={{
            paymentAmount: 0,
            ...platon
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect(platon.id || platonId)}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default PlatonForm;
