// @flow
import * as React from 'react';
import {MaintenanceEstimatesApi} from '../../../../lib/api';
import {Header, Section} from '../../../../components/layout';
import {navigate} from '../../../../lib/helpers';
import Button from 'antd/lib/button';
import {Tabs} from '../../index';
import {Tabs as InternalTabs} from '../components';
import {handlingInternalActs} from '../../accessRight';
import withUserAccess from '../../../withUserAccess/withUserAccess';
import type {UserAccess} from '../../../../lib/types';
import {Icon, Table} from '../../../../components/ui';
import {MaintenanceEstimate} from '../../../../lib/types/maintenanceEstimate';
import moment from 'moment/moment';
import {Link} from '@reach/router';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';
import {notificationLoading} from '../../../../components/Notifications';

const StyledLink = styled(Link)`
  margin-right: 8px;
`;

type Props = {
  userAccess: UserAccess[],
};

type State = {
  data: MaintenanceEstimate[],
  page: number,
  totalCount: number,
  loading: boolean,
  pageSize: number,
};

class EstimatesList extends React.Component<Props, State> {
  state = {
    data: [],
    page: 1,
    totalCount: 0,
    loading: false,
    pageSize: 20,
  };
  async componentDidMount() {
    this.fetch(this.state.page);
  }
  fetch = async (page) => {
    try {
      this.setState({loading: true});
      const {data, totalCount} = await MaintenanceEstimatesApi.fetch({page, pageSize: this.state.pageSize});
      this.setState({data, totalCount, page, loading: false}, this.fetchCommissioners);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  deleteEstimate = async (id) => {
    try {
      notificationLoading({message: 'Удаление сметы...', key: 'deleting'});
      await MaintenanceEstimatesApi.delete(id);
      this.fetch(this.state.page);
      notification.success({message: 'Смета удалена'});
    } catch (e) {
      notification.error({
        message: 'Ошибка при попытке удаления сметы',
        description: e.title || e.message,
      });
    }
    finally {
      notification.close('deleting');
    }
  };
  canAdd = () => this.props.userAccess.some(access => handlingInternalActs.includes(access));
  columns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Дата сметы',
      dataIndex: 'created',
      render: date => moment(date).format('DD.MM.YYYY'),
    },
    {
      stopPropagation: true,
      title: 'Номера карточек ремонта',
      dataIndex: 'maintenanceEstimateMaintenanceLinks',
      render: links => (
        <>
          {links.map(link => (
            <StyledLink key={link.id} to={`/maintenances/${link.maintenanceId}`}>
              {link.maintenanceId}
            </StyledLink>
          ))}
        </>
      ),
    },
    {
      stopPropagation: true,
      dataIndex: 'id',
      key: 'delete',
      render: id => {
        if (!this.canAdd()) {
          return null;
        }
        return (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            okText="Ок"
            cancelText="Отмена"
            onConfirm={() => this.deleteEstimate(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        );
      },
    },
  ];
  render() {
    const {data, totalCount, page, loading, pageSize} = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр первичных документов</h1>}
          right={this.canAdd() && (
            <Button type="primary" onClick={() => navigate('/admin/act/internal/estimates/new')}>
              Создать
            </Button>
          )}
        />
        <Tabs />
        <InternalTabs />
        <Section>
          <Table
            data={data}
            columns={this.columns}
            loading={loading}
            pagination={{
              page,
              totalCount,
              pageSize,
            }}
            rowKey="id"
            onRow={record => ({
              onClick: () => navigate(`/admin/act/internal/estimates/${record.id}`),
            })}
          />
        </Section>
      </>
    );
  };
}

export default withUserAccess(EstimatesList);
