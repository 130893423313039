import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import Field from '../../../../components/card/Field';

type Props = {
  visible: true,
  handleOk: ({ declineReason?: string }) => any,
  handleCancel: () => any
};
export default (props: Props) => {
  const { visible } = props;

  const [declineReason, setDeclineReason] = useState(undefined);

  return (
    <Modal
      title="Причина отклонения"
      visible={visible}
      closable={false}
      maskClosable
      centered
      destroyOnClose
      onCancel={props.handleCancel}
      footer={[
        <Button key="back" onClick={() => props.handleCancel()}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!declineReason}
          onClick={() => props.handleOk({ declineReason })}
        >
          Сохранить
        </Button>
      ]}
    >
      <Field required label="Причина отклонения">
        <Input
          value={declineReason}
          onChange={e => setDeclineReason(e.target.value)}
        />
      </Field>
    </Modal>
  );
};
