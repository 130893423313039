//@flow
import {Button, DatePicker, Modal} from 'antd';
import {FormikProps} from 'formik';
import React, {useState} from 'react';
import {Form} from '../../../components';
import type {FormFieldType} from '../../../components/Form';
import {GridItem} from '../../../components/layout';
import Grid from '../../../components/layout/Grid';
import {apiUrl, fetchRequest} from '../../../lib/api';
import moment from 'moment';
import {notificationLoading} from '../../../components/Notifications';
import notification from 'antd/lib/notification';
import Upload from 'antd/lib/upload';
import tokenManager from '../../../lib/tokenManager';
import {navigate} from '../../../lib/helpers';
import SecuringContractorSelect from '../../../components/selects/SecuringContractorSelect';

type Props = {
  currentVehicleTariff: string,
  visible: boolean,
  onClose: () => any,
};

export const UploadFileModal = ({onClose, visible, currentVehicleTariff}: Props) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const checkDoubles = async (values) => {
    if (!values.contractorId || !values.startDate || !values.endDate) {
      return;
    }
    try {
      await fetchRequest.get('/vehicleTariff/preImportCheck', values);
      setDisableSubmitButton(false);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
      setDisableSubmitButton(true);
    } finally {
      notification.close('doubleChecking');
    }
  };

  return (
    <Modal
      title="Загрузка тарификатора НТС"
      centered
      footer={false}
      destroyOnClose
      visible={visible}
      onCancel={onClose}
      width={600}
    >
      <Form initialValues={{}} onSubmit={false} validate={checkDoubles}>
        {(FormField: FormFieldType, {setFieldValue, handleSubmit, values, errors, dirty}: FormikProps) => {

          if(!values.version)  setFieldValue('version', currentVehicleTariff);

          return (
            <form onSubmit={handleSubmit}>
              <Grid cols={1} gutter={'16px'}>
                <GridItem>
                  <FormField required label="Подрядчик" name="contractorId">
                    {({name, value}) => (
                      <SecuringContractorSelect
                        value={value}
                        onChange={id => setFieldValue(name, id)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField required label="Дата начала действия тарифа" name="startDate">
                    {({name, value}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value, 'YYYY-MM-DD') : undefined}
                        onChange={date => setFieldValue(name, date ? date.format('YYYY-MM-DD') : undefined)}
                        disabledDate={values.endDate
                          ? (current) =>
                            current && current.endOf('days').isAfter(moment(values.endDate).startOf('days'))
                          : undefined
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField required label="Дата окончания действия тарифа" name="endDate">
                    {({name, value}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value, 'YYYY-MM-DD') : undefined}
                        onChange={date => setFieldValue(name, date ? date.format('YYYY-MM-DD') : undefined)}
                        disabledDate={values.startDate
                          ? (current) =>
                            current && current.startOf('days').isBefore(moment(values.startDate).endOf('days'))
                          : undefined
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
              <Grid cols={2} gutter={'16px'} style={{marginTop: '15px'}}>
                <GridItem>
                  <Upload
                    accept=".xlsx"
                    showUploadList={false}
                    action={`${apiUrl}/vehicleTariff/import`}
                    data={values}
                    headers={{Authorization: `Bearer ${tokenManager.getToken()}`}}
                    beforeUpload={() => {
                      setDisableSubmitButton(true);
                      notificationLoading({
                        message: 'Сохранение данных',
                        key: 'sending',
                      });
                    }}
                    onChange={({file}) => {
                      const {status, response} = file;
                      switch (status) {
                        case 'done':
                          notification.close('sending');
                          notification.success({
                            message: 'Данные сохранены',
                            duration: 5,
                          });
                          setDisableSubmitButton(false);
                          onClose();
                          navigate(`tariff/${currentVehicleTariff}/${response.id}`);
                          break;
                        case 'error':
                          notification.close('sending');
                          notification.error({
                            message: 'Ошибка',
                            description: response.title || response.message,
                          });
                          setDisableSubmitButton(false);
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <Button
                      disabled={Object.keys(errors).length || !dirty || disableSubmitButton}
                      type="primary"
                      onClick={async ev => {
                        if (await checkDoubles(values)) {
                          ev.stopPropagation();
                        }
                      }}
                    >
                      Выбрать файл и отправить данные
                    </Button>
                  </Upload>
                </GridItem>
                <GridItem customStyles={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button onClick={onClose}>
                    Отмена
                  </Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
