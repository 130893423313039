// @flow

import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Form from 'antd/lib/form';

import {Card} from './../../../components';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import type {RepairOrder, RepairOrderOperation, RepairOrderVehicle, UserAccess} from './../../../lib/types';
import {clear, fetchRepairOrder, updateRepairOrder} from '../../../ducks/repairOrder';
import type {AppState} from '../../../ducks/redux';
import {Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {withUserAccess} from './../../withUserAccess';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Icon} from '../../../components/ui';

const { Field } = Card;

const StyledIcon = styled(Icon)`
  padding: 0 10px;
  color: #1890ff;
  cursor: pointer;
`;

const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`;

const Content = styled.div`
  padding: 16px;
`;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const FormItem = Form.Item;

type Props = {
  repairOrder: RepairOrder,
  fetchRepairOrder: Function,
  repairOrderId: number,
  clear: () => void,
  userAccess: UserAccess[],
  updateRepairOrder: Function
};

type State = {
  showActForm: boolean,
  amountPayment: number,
  actNumber: string
};

export class RepairOrderCard extends Component<Props, State> {
  state = {
    showActForm: false,
    amountPayment: 0,
    actNumber: ''
  };

  async componentDidMount() {
    const { repairOrderId } = this.props;
    if (repairOrderId) {
      try {
        await this.props.fetchRepairOrder(repairOrderId);
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      }
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  toggleActForm = () =>
    this.setState(prevState => ({ showActForm: !prevState.showActForm }));

  onUpdate = () => {
    const { actNumber, amountPayment } = this.state;
    this.props.updateRepairOrder({
      ...this.props.repairOrder,
      actNumber,
      amountPayment
    });
  };

  render() {
    const { repairOrder } = this.props;
    const { showActForm, amountPayment } = this.state;
    if (!repairOrder) {
      return null;
    }

    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/services')}>
                Обслуживание
              </Crumb>
              <Crumb>Заявка на ремонт №{repairOrder.id}</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            Заявка на ремонт №{repairOrder.id}
            <StyledIcon
              onClick={() =>
                navigate(`/services/repair-order/edit/${repairOrder.id}`)
              }
              type="edit"
              size={16}
            />
          </h1>
        </StyledPanel>
        <Section>
          <Content>
            <Grid cols={2} gutter="16">
              {repairOrder.contractor && (
                <GridItem>
                  <Field label="Подрядчик">
                    {repairOrder.contractor.company
                      ? repairOrder.contractor.company.name
                      : repairOrder.contractor.name}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="№ договора">{repairOrder.contractNumber}</Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>
        {repairOrder.vehicles.map((repairOrderVehicle: RepairOrderVehicle) => (
          <Section key={repairOrderVehicle.vehicleId}>
            <SectionTitle divider>
              {repairOrderVehicle.vehicle ? (
                <Fragment>
                  <span>
                    {repairOrderVehicle.vehicle.vehicleModel &&
                      `${repairOrderVehicle.vehicle.vehicleModel.brandName} ${repairOrderVehicle.vehicle.vehicleModel.name} `}
                  </span>
                  <span>{repairOrderVehicle.vehicle.licensePlate}</span>
                </Fragment>
              ) : (
                ''
              )}
            </SectionTitle>
            <Content>
              <Grid>
                <GridItem>
                  <Field label="Виды работ">
                    {repairOrderVehicle.operations.map(
                      (repairOrderOperation: RepairOrderOperation) => (
                        <p key={repairOrderOperation.operationId}>
                          {repairOrderOperation.operation.name} (
                          {repairOrderOperation.count} шт.)
                        </p>
                      )
                    )}
                  </Field>
                </GridItem>
              </Grid>
            </Content>
          </Section>
        ))}

        <Footer>
          <Button type="primary" onClick={this.toggleActForm}>
            Акт
          </Button>
        </Footer>
        <Modal
          title="Акт работ"
          visible={showActForm}
          onOk={this.onUpdate}
          onCancel={this.toggleActForm}
        >
          <Grid>
            <GridItem>
              <FormItem label="Номер акта">
                <Input
                  onChange={e =>
                    this.setState({
                      actNumber: e.target.value
                    })
                  }
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem label="Сумма, руб">
                <InputNumber
                  value={amountPayment}
                  step={0.01}
                  onChange={(amountPayment: number) =>
                    this.setState({
                      amountPayment
                    })
                  }
                />
              </FormItem>
            </GridItem>
          </Grid>
        </Modal>
      </Fragment>
    );
  }
}

export default connect(
  (state: AppState) => ({
    repairOrder: state.repairOrder
  }),
  {
    fetchRepairOrder,
    updateRepairOrder,
    clear
  }
)(withUserAccess(RepairOrderCard));
