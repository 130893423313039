// @flow
import React from 'react';
import styled from 'styled-components';

import Popconfirm from 'antd/lib/popconfirm';

import type {
  ExpensesDistribution,
  DisplayExpenseDirection
} from '../../../../lib/types';
import {
  withEmptyRow,
  isEmptyValue as isEmpty
} from './../../../../lib/helpers';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import { Selects } from '../../../../components';
import { Icon, ListTable } from '../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';

const {
  ExpenseDirectionSelect,
  ExpenseDirectionDisplayRuleTypeSelect
} = Selects;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
const StyledFormField = styled(FormField)`
  margin: 0;
`;

export default (props: CommonFormProps<$Shape<ExpensesDistribution>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        const data = withEmptyRow(values?.displayExpenseDirections ?? []);
        const onDelete = (index: number) => {
          setFieldValue(
            'displayExpenseDirections',
            values.displayExpenseDirections.filter(
              (value, ind) => index !== ind
            )
          );
        };
        const columns = [
          {
            title: 'Направление расходов отображение',
            key: 'expenseDirectionId',
            dataIndex: 'expenseDirectionId',
            render: (
              expenseDirectionId: number,
              displayExpenseDirection: DisplayExpenseDirection,
              index: number
            ) => {
              return (
                <StyledFormField
                  style={{}}
                  required={!isEmpty(displayExpenseDirection)}
                  name={`displayExpenseDirections[${index}].expenseDirectionId`}
                >
                  {({ name, value }) => (
                    <ExpenseDirectionSelect
                      name={name}
                      size="small"
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </StyledFormField>
              );
            }
          },
          {
            title: 'Правило отображения',
            key: 'displayRule',
            dataIndex: 'displayRule',
            render: (
              displayRule: string,
              displayExpenseDirection: DisplayExpenseDirection,
              index: number
            ) => {
              return (
                <StyledFormField
                  style={{}}
                  required={!isEmpty(displayExpenseDirection)}
                  name={`displayExpenseDirections[${index}].displayRule`}
                >
                  {({ name, value }) => (
                    <ExpenseDirectionDisplayRuleTypeSelect
                      name={name}
                      size="small"
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </StyledFormField>
              );
            }
          },
          {
            width: '20px',
            renderRecord: (
              displayExpenseDirection: DisplayExpenseDirection,
              index: number
            ) => {
              return !isEmpty(displayExpenseDirection) ? (
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={() => onDelete(index)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              ) : null;
            }
          }
        ];
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Расчетное направление расходов"
                  name="estimateExpenseDirectionId"
                  required
                >
                  {({ name, value }) => (
                    <ExpenseDirectionSelect
                      value={value}
                      onChange={estimateExpenseDirectionId => {
                        setFieldValue(name, estimateExpenseDirectionId);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <ListTable columns={columns} data={data} />
          </>
        );
      }}
    </CommonForm>
  );
};
