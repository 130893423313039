// @flow
import React from 'react';

import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {maintenanceOperationPriceApi} from './../../lib/api';
import type {MaintenanceOperationPrice} from './../../lib/types';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';

import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {notificationLoading} from './../../components/Notifications';

type Props = {
  maintenanceOperationPriceId: ?number
};
type State = {
  maintenanceOperationPrice: ?MaintenanceOperationPrice
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

export default class extends React.Component<Props, State> {
  state = {
    maintenanceOperationPrice: null
  };

  async componentDidMount() {
    const { maintenanceOperationPriceId } = this.props;
    if (maintenanceOperationPriceId) {
      const maintenanceOperationPrice = await maintenanceOperationPriceApi.getMaintenanceOperationPrice(
        maintenanceOperationPriceId
      );
      this.setState({ maintenanceOperationPrice });
    }
  }

  handleSubmit = async (
    maintenanceOperationPrice: MaintenanceOperationPrice
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (maintenanceOperationPrice.id) {
        await maintenanceOperationPriceApi.updateMaintenanceOperationPrice(
          maintenanceOperationPrice
        );
      } else {
        await maintenanceOperationPriceApi.addMaintenanceOperationPrice(
          maintenanceOperationPrice
        );
      }
      navigate(`/admin/maintenance-operation-price`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/maintenance-operation-price', true);

  render() {
    const { maintenanceOperationPrice } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams(
                  '/admin/maintenance-operation-price'
                )}
              >
                Справочник прейскурантов стоимости нормо-часа
              </Crumb>
              {maintenanceOperationPrice ? (
                <Crumb>
                  Прейскурант стоимости нормо-часа №
                  {maintenanceOperationPrice.id}
                </Crumb>
              ) : (
                <Crumb>Новый прейскурант стоимости нормо-часа</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceOperationPrice
              ? `Деталь №${maintenanceOperationPrice.id}`
              : 'Новый прейскурант стоимости нормо-часа'}
          </h1>
        </StyledPanel>
        <InnerForm
          maintenanceOperationPrice={maintenanceOperationPrice}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}
