import React, { useState } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';

import { orderApi } from './../../../lib/api';
import { navigate } from './../../../lib/helpers';
import { OrgUnitSelect } from './../../../components';
import { notificationLoading } from './../../../components/Notifications';
import { accessTypeEnum } from '../../../lib/enum';

export default ({ order }) => {
  const [orgUnitId, setOrgUnitId] = useState(order.dispatcherNodeId || order.orgUnitId);
  return (
    <Popconfirm
      overlayStyle={{
        minWidth: '400px',
        zIndex: 2000
      }}
      placement="left"
      title={
        <>
          <p>Выберите подразделение</p>
          <OrgUnitSelect
            dropdownStyle={{ zIndex: '2001' }}
            value={orgUnitId}
            onChange={(orgUnitId: number) => {
              setOrgUnitId(orgUnitId);
            }}
            filter={{accessType: accessTypeEnum.changingOrderDispatcherNodeToAllBranches}}
          />
        </>
      }
      onConfirm={async () => {
        try {
          notificationLoading({
            message: 'Перенаправление заявки...',
            key: 'upadeting'
          });
          await orderApi.changeDispatcherNode({
            id: order.id,
            dispatcherNodeId: orgUnitId
          });
        } catch (error) {
          notification.error({
            message: 'Ошибка при загрузке заявки',
            description: error.message || 'Заявка не найдена'
          });
        } finally {
          notification.close('upadeting');
          navigate('/orders', true);
        }
      }}
    >
      Перенаправить заявку
    </Popconfirm>
  );
};
