// @flow
import React from 'react';
import {expertiseServiceGpmCostApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {approveTypes} from '../../lib/enum';
import {handlingAccessRight} from './accessRight';

const entryPointPath = '/admin/expertise-service-gpm-cost/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status]
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => handlingAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={expertiseServiceGpmCostApi}
        pageHeaderProps={{
          mainHeader: 'Стоимость ТО ГПМ и промышленной экспертизы',
          access: {
            add: canHandling() ? () => navigate(`${entryPointPath}new`) : null
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
