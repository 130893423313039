// @flow
import Modal from 'antd/lib/modal';
import React from 'react';
import { Form } from '../../../../../components';
import Button from 'antd/lib/button';
import { Grid, GridItem } from '../../../../../components/layout';
import {
  monthsNamesTranslitEnum,
  monthsNumbersEnum
} from '../../../../../lib/enum';
import styled from 'styled-components';
import type { BatteriesDetails } from '../../../../../lib/types/batteryCalculation';
import Checkbox from 'antd/lib/checkbox';
import InputNumber from 'antd/lib/input-number';

const Title = styled.p`
  color: #3d4042;
  margin: 15px 0 5px;
  font-weight: bold;
  width: 100%;
`;

type Props = {
  visible: boolean,
  handleCancel: () => void,
  handleSave: () => void,
  data: any,
  monthIndex: number,
  type: string
};

export const EditingModal = ({
  visible,
  handleCancel,
  handleSave,
  data,
  monthIndex,
  type
}: Props) => {
  const selectedMonth = data?.months[monthIndex];
  const initialValues = {
    month: selectedMonth,
    monthName: monthsNamesTranslitEnum[monthsNumbersEnum[selectedMonth?.month]],
    vehicleData: `${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.brandName}
			${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.name}`
  };
  const getContent = (FormField, setFieldValue) => {
    switch (type) {
      case 'batteries':
        return selectedMonth.details.map(
          (detail: BatteriesDetails, index: number) => {
            if (detail.batteryPrice === 0) {
              return null;
            }
            return (
              <Grid gutter="16px" key={index}>
                <GridItem fullWidth>
                  <Title>{detail.name}</Title>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Мнемокод"
                    name={`month.details[${index}].code`}
                  >
                    {({ value }) => value}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Дата установки на ТС"
                    name={`month.details[${index}].installDate`}
                  >
                    {({ value }) => value}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Стоимость"
                    name={`month.details[${index}].batteryPrice`}
                  >
                    {({ value }) => value}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Требует замены"
                    name={`month.details[${index}].isReplaceNeeded`}
                  >
                    {({ value, name }) => (
                      <Checkbox
                        checked={value}
                        onChange={e => setFieldValue(name, e.target.checked)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            );
          }
        );
      case 'gpm-maintenance':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость экспертизы">
                {() => data.expertiseCost}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Стоимость ТО">
                {() => data.maintenanceCost}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Требуется экспертиза"
                name="month.expertiseCost"
              >
                {({ value, name }) => (
                  <Checkbox
                    checked={value !== 0}
                    onChange={e =>
                      setFieldValue(
                        name,
                        e.target.checked ? data.expertiseCost : 0
                      )
                    }
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Требуется ТО" name="month.maintenanceCost">
                {({ value, name }) => (
                  <Checkbox
                    checked={value !== 0}
                    onChange={e =>
                      setFieldValue(
                        name,
                        e.target.checked ? data.maintenanceCost : 0
                      )
                    }
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'gps':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость обслуживания GPS" name="month.price">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Требуется обслуживание" name="month.cost">
                {({ value, name }) => (
                  <Checkbox
                    checked={value !== 0}
                    onChange={e =>
                      setFieldValue(
                        name,
                        e.target.checked ? selectedMonth.price : 0
                      )
                    }
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'measuring-device-certifications':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость одной тарировки" name="month.price">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Кол-во тарировок в течение месяца"
                name="month.count"
              >
                {({ value, name }) => (
                  <InputNumber
                    value={value}
                    onChange={value => setFieldValue(name, value)}
                    min={0}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'inspections-gtn':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость ТО" name="month.workPrice">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Пошлина за ТО" name="month.taxPrice">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Требуется ТО" name="month.needInspection">
                {({ value, name }) => (
                  <Checkbox
                    checked={value}
                    onChange={e => setFieldValue(name, e.target.checked)}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'inspections-gibdd':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость ТО" name="month.price">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Требуется ТО" name="month.needInspection">
                {({ value, name }) => (
                  <Checkbox
                    checked={value}
                    onChange={e => setFieldValue(name, e.target.checked)}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'driver-inspection':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Стоимость одного осмотра" name="month.price">
                {({ value }) => value}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Кол-во осмотров в течение месяца"
                name="month.count"
              >
                {({ value, name }) => (
                  <InputNumber
                    value={value}
                    onChange={value => setFieldValue(name, value)}
                    min={0}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      case 'passes':
        return (
          <Grid gutter="16px">
            <GridItem>
              <FormField
                label="Необходимы пропуска"
                name="month.isCalculationNeeded"
              >
                {({ value, name }) => (
                  <Checkbox
                    checked={value}
                    onChange={e => setFieldValue(name, e.target.checked)}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Планируемый пробег, км в день"
                name="month.correctedKilometrage"
              >
                {({ value, name }) => (
                  <InputNumber
                    value={value}
                    onChange={value => setFieldValue(name, value)}
                    min={0}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField
                label="Планируемое кол-во рабочих дней в месяц"
                name="month.correctedWorkDaysCount"
              >
                {({ value, name }) => (
                  <InputNumber
                    value={value}
                    onChange={value => setFieldValue(name, value)}
                    min={0}
                  />
                )}
              </FormField>
            </GridItem>
          </Grid>
        );
      default:
        return null;
    }
  };
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Редактирование"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        initialValues={initialValues}
        onSubmit={editedData => {
          if (type === 'passes') {
            data.months[monthIndex] = editedData.month;
            handleSave(data);
          } else {
            handleSave(editedData.month);
          }
        }}
      >
        {(FormField, formikProps) => {
          const { handleSubmit, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Месяц" name="monthName">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка и модель ТС" name="vehicleData">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
              </Grid>
              {getContent(FormField, setFieldValue)}
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
