// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import type { FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';

import { Checkbox } from 'antd';
import Select from 'antd/lib/select';
import { Form, Selects } from '../../../components';
import CancelButton from '../../../components/CancelButton';
import { GridItem } from '../../../components/layout';
import { ModelMatchesApi } from '../../../lib/api';
import { driverQualificationEnum, engineTypeEnum, fuelTypeEnum, selfVehiclePlanVehicleTypeEnum, vehicleGroupEnum } from '../../../lib/enum';
import { isVehicleTypeElectric } from '../../../lib/helpers';
import type { VehicleModel } from '../../../lib/types';
import { WorkAccountingTypes } from '../../../lib/types/vehicleModel';
import { canShowEngineWorkHoursData } from '../../Vehicles/lib';
import {
  canShowAxleLoad,
  canShowAxlesNumber,
  canShowCarrying,
  canShowChassisConfiguration,
  canShowDrillingData,
  canShowEcologicClass,
  canShowFuelData,
  canShowGpmData,
  canShowGrossWeight,
  canShowOsagoNeeded,
  canShowPassengerSeatsNumber,
  canShowSecondaryFuel,
  canShowVehicleCategory,
  getVehicleGroup,
  isRequiredAddPrimaryEquipmentFuelConsumption,
  isRequiredAddPrimaryFuelConsumption,
  isRequiredAddPrimaryFuelType,
  isRequiredPassengerSeatsNumber,
} from '../lib';
import { Section, SectionTitle } from './../../../components/layout';
import { Content, Footer, StyledGrid, StyledInputNumber } from './InnerForm.elements';

const {Option} = Select;

const {
  BrandsSelect,
  VehicleTypeSelect,
  DriverLicenseCategorySelect,
  ChassisConfigurationSelect,
  YesNoSelect,
  EngineTypeSelect,
  VehicleCategorySelect,
  FuelTypeSelect,
  DriverQualificationSelect,
} = Selects;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  vehicleModel: ?VehicleModel,
  canEdit: boolean,
  canEditMaitenanceNormative: boolean,
  isSelfVehicleModel: boolean,
};

const InnerForm = ({
  onSubmit,
  onCancel,
  vehicleModel,
  canEdit,
  isSelfVehicleModel,
  canEditMaitenanceNormative,
}: FormProps) => {
  const [modelMatches, setModelMatches] = useState([]);

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    const {data} = await ModelMatchesApi.fetch();
    setModelMatches(data);
  };

  return (
    <Form initialValues={vehicleModel} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          values,
          dirty,
          isSubmitting,
        } = formikProps;

        const isElectricVehicle = isVehicleTypeElectric(values.type);

        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <SectionTitle>Основное</SectionTitle>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Наименование модели"
                      required
                      name="name"
                      fast
                    >
                      {fieldProps => (
                        <Input
                          {...fieldProps}
                          disabled={!canEdit}
                          style={!canEdit ? {color: '#3d4042'} : undefined}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="brandId" label="Марка" required fast>
                      {({name, value}) => (
                        <BrandsSelect
                          disabled={!canEdit}
                          name={name}
                          value={value}
                          onChange={(brandId: number) =>
                            setFieldValue(name, brandId)
                          }
                          onBlur={() => handleBlur({target: {name}})}
                          data-cy="brandSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тип ТС" required fast name="type">
                      {({name, value}) => (
                        <VehicleTypeSelect
                          disabled={!canEdit}
                          name={name}
                          value={value}
                          onChange={(type: string) => {
                            setFieldValue(name, type);
                            if (getVehicleGroup(type) === vehicleGroupEnum.trailers) {
                              setFieldValue('engineType', engineTypeEnum.none);
                            }
                            if (getVehicleGroup(type) === vehicleGroupEnum.electricVehicles) {
                              setFieldValue('workAccountingType', WorkAccountingTypes.kilometrage);
                              setFieldValue('primaryFuelType', fuelTypeEnum.electrofuel);
                              setFieldValue('engineType', engineTypeEnum.electrical);
                            }
                          }}
                          onBlur={() => handleBlur({target: {name}})}
                          data-cy="typeSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Мнемокод ТС по МТР"
                      name="mnemonicMdm"
                      fast
                      required={isSelfVehicleModel}
                    >
                      {fieldProps => (
                        <Input
                          {...fieldProps}
                          disabled={!canEdit}
                          style={!canEdit ? {color: '#3d4042'} : undefined}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тип ТС 1С.МДМ" name="typeMdm" fast>
                      {fieldProps => (
                        <Input
                          {...fieldProps}
                          disabled={!canEdit}
                          style={!canEdit ? {color: '#3d4042'} : undefined}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required={isSelfVehicleModel}
                      label="Необходимая квалификация водителя"
                      name="driverQualification"
                      fast
                    >
                      {({value, name}) => (
                        <DriverQualificationSelect
                          disabled={!canEdit}
                          exclude={[
                            driverQualificationEnum.bkmMachinistLicense,
                            driverQualificationEnum.dopogDriverLicense,
                            driverQualificationEnum.skziDriverMap,
                          ]}
                          onChange={(value: string) => setFieldValue(name, value)}
                          onBlur={() => handleBlur({target: {name}})}
                          value={value}
                          name={name}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {[
                    driverQualificationEnum.driverLicense,
                    driverQualificationEnum.tractorMachinistLicense,
                  ].includes(values.driverQualification) && (
                    <GridItem>
                      <FormField
                        label="Категория прав для ТС"
                        name="rightsCategory"
                        required={isSelfVehicleModel}
                      >
                        {({name, value}) => (
                          <DriverLicenseCategorySelect
                            disabled={!canEdit}
                            driverQualification={values.driverQualification}
                            name={name}
                            value={value}
                            multiple
                            onChange={(rightsCategory: string) =>
                              setFieldValue(name, rightsCategory)
                            }
                            placeholder={canEdit && 'Выберите категорию прав'}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField
                      label="Параметр учета работы"
                      name="workAccountingType"
                      required={isSelfVehicleModel}
                      hasFeedback={false}
                    >
                      {({name, value}) => (
                        <>
                          <Checkbox
                            disabled={!canEdit || isElectricVehicle}
                            checked={
                              value === WorkAccountingTypes.kilometrage ||
                              value === WorkAccountingTypes.all
                            }
                            onChange={({target}) => {
                              let selectedType;
                              if (target.checked) {
                                selectedType =
                                  value === WorkAccountingTypes.workHours
                                    ? WorkAccountingTypes.all
                                    : WorkAccountingTypes.kilometrage;
                              } else {
                                selectedType =
                                  value === WorkAccountingTypes.all
                                    ? WorkAccountingTypes.workHours
                                    : null;
                              }
                              setFieldValue(name, selectedType);
                            }}
                          >
                            Пробег (км)
                          </Checkbox>
                          <Checkbox
                            disabled={!canEdit || isElectricVehicle}
                            checked={
                              value === WorkAccountingTypes.workHours ||
                              value === WorkAccountingTypes.all
                            }
                            onChange={({target}) => {
                              let selectedType;
                              if (target.checked) {
                                selectedType =
                                  value === WorkAccountingTypes.kilometrage
                                    ? WorkAccountingTypes.all
                                    : WorkAccountingTypes.workHours;
                              } else {
                                selectedType =
                                  value === WorkAccountingTypes.all
                                    ? WorkAccountingTypes.kilometrage
                                    : null;
                              }
                              setFieldValue(name, selectedType);
                            }}
                          >
                            Маш.часы
                          </Checkbox>
                        </>
                      )}
                    </FormField>
                  </GridItem>
                </StyledGrid>
                {canShowFuelData(values.type) && (
                  <>
                    <SectionTitle>Основное топливо</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Вид топлива"
                          required={isSelfVehicleModel && isRequiredAddPrimaryFuelType(values.type)}
                          name="primaryFuelType"
                        >
                          {({value, name}) => (
                            <FuelTypeSelect
                              disabled={!canEdit || isElectricVehicle}
                              name={name}
                              value={value}
                              onChange={(value: string) =>
                                setFieldValue(name, value)
                              }
                              onBlur={() => handleBlur({target: {name}})}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      {(values.workAccountingType === WorkAccountingTypes.all ||
                        values.workAccountingType ===
                        WorkAccountingTypes.kilometrage) && (
                        <GridItem>
                          <FormField
                            label={"Нормативный расход, " + (isElectricVehicle ? "кВт·ч/100 км" : "л/100 км")}
                            required={isSelfVehicleModel && isRequiredAddPrimaryFuelConsumption(values.type) && !isElectricVehicle}
                            name="primaryFuelConsumption"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                max={99}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      )}
                      {(values.workAccountingType === WorkAccountingTypes.all ||
                        values.workAccountingType ===
                        WorkAccountingTypes.workHours) && (
                        <GridItem>
                          <FormField
                            label={"Нормативный расход " + (isElectricVehicle ? ", кВт·ч/маш.час" : ", л/маш.час") + " работы оборудования"}
                            name="primaryEquipmentFuelConsumption"
                            required={isSelfVehicleModel && isRequiredAddPrimaryEquipmentFuelConsumption(values.type)}
                            hasFeedback={false}
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                max={99}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      )}
                      {!isElectricVehicle && (
                        <GridItem>
                          <FormField
                            label="Объем топливного бака, л"
                            required={isSelfVehicleModel}
                            hasFeedback={false}
                            name="primaryTankVolume"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                max={999}
                                decimalSeparator=","
                                step={0.1}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      )}
                    </StyledGrid>
                    {canShowSecondaryFuel(values.type) && (
                      <>
                        <SectionTitle>Дополнительное топливо</SectionTitle>
                        <StyledGrid gutter="16px">
                          <GridItem>
                            <FormField
                              label="Вид топлива"
                              name="secondaryFuelType"
                            >
                              {({value, name}) => (
                                <FuelTypeSelect
                                  disabled={!canEdit}
                                  name={name}
                                  value={value}
                                  onChange={(value: string) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() => handleBlur({target: {name}})}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label={"Нормативный расход, " + (isElectricVehicle ? "кВт·ч/100 км" : "л/100 км")}
                              name="secondaryFuelConsumption"
                              fast
                            >
                              {({value, name}) => (
                                <InputNumber
                                  disabled={!canEdit}
                                  min={0}
                                  max={99}
                                  decimalSeparator=","
                                  step={0.1}
                                  value={value}
                                  name={name}
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          {canShowEngineWorkHoursData(values.type) && (
                            <GridItem>
                              <FormField
                                label={"Нормативный расход " + (isElectricVehicle ? ", кВт·ч/маш.час" : ", л/маш.час") + " работы оборудования"}
                                name="secondaryEquipmentFuelConsumption"
                                fast
                              >
                                {({value, name}) => (
                                  <InputNumber
                                    disabled={!canEdit}
                                    min={0}
                                    max={99}
                                    decimalSeparator=","
                                    step={0.1}
                                    value={value}
                                    name={name}
                                    onChange={(value: number) =>
                                      setFieldValue(name, value)
                                    }
                                    onBlur={handleBlur}
                                  />
                                )}
                              </FormField>
                            </GridItem>
                          )}
                          {!isElectricVehicle && (
                            <GridItem>
                              <FormField
                                label="Объем топливного бака, л"
                                fast
                                name="secondaryTankVolume"
                              >
                                {({value, name}) => (
                                  <InputNumber
                                    disabled={!canEdit}
                                    min={0}
                                    max={999}
                                    decimalSeparator=","
                                    step={0.1}
                                    value={value}
                                    name={name}
                                    onChange={(value: number) =>
                                      setFieldValue(name, value)
                                    }
                                    onBlur={handleBlur}
                                  />
                                )}
                              </FormField>
                            </GridItem>
                          )}
                        </StyledGrid>
                      </>
                    )}
                  </>
                )}
                <SectionTitle>Шины</SectionTitle>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Количество автошин, шт"
                      name="wheelCount"
                      fast
                    >
                      {({name, value}) => (
                        <StyledInputNumber
                          disabled={!canEdit}
                          name={name}
                          min={0}
                          step={1}
                          precision={0}
                          value={value}
                          onChange={(wheelCount: number) =>
                            setFieldValue(name, wheelCount)
                          }
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </StyledGrid>
                <SectionTitle>Первичные данные</SectionTitle>
                <StyledGrid gutter="16px">
                  {canShowChassisConfiguration(values.type) && (
                    <GridItem>
                      <FormField
                        label="Колесная формула"
                        fast
                        name="chassisConfiguration"
                        required={isSelfVehicleModel && getVehicleGroup(values.type) !== vehicleGroupEnum.atvs}
                      >
                        {({name, value}) => (
                          <ChassisConfigurationSelect
                            disabled={!canEdit}
                            name={name}
                            value={value}
                            chassisConfiguration
                            onChange={(chassisConfiguration: string) =>
                              setFieldValue(name, chassisConfiguration)
                            }
                            data-cy="сhassisСonfigurationSelect"
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField
                      label="Масса ТС без нагрузки, т"
                      name="unladenMass"
                      fast
                      validate={(value: number) => {
                        if (
                          canShowGrossWeight(values.type) &&
                          value > values.grossWeight
                        ) {
                          return 'Масса ТС без нагрузки не может быть больше полной массы ТС';
                        }
                      }}
                    >
                      {({value, name}) => (
                        <StyledInputNumber
                          disabled={!canEdit}
                          min={0}
                          max={50}
                          decimalSeparator=","
                          name={name}
                          placeholder="Введите массу ТС без нагрузки"
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {canShowGrossWeight(values.type) && (
                    <GridItem>
                      <FormField
                        label="Полная масса ТС, т"
                        validate={(value: number) => {
                          if (value < values.unladenMass) {
                            return 'Полная масса ТС не может быть меньше массы ТС без нагрузки';
                          }
                        }}
                        fast
                        name="grossWeight"
                        required={isSelfVehicleModel}
                      >
                        {({value, name}) => (
                          <StyledInputNumber
                            disabled={!canEdit}
                            name={name}
                            min={0}
                            decimalSeparator=","
                            max={80}
                            placeholder="Введите полную массу ТС"
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {canShowAxlesNumber(values.type) && (
                    <GridItem>
                      <FormField
                        fast
                        label="Количество осей, шт"
                        name="axlesNumber"
                        required={isSelfVehicleModel && getVehicleGroup(values.type) !== vehicleGroupEnum.atvs}
                      >
                        {({value, name}) => (
                          <StyledInputNumber
                            disabled={!canEdit}
                            placeholder="Введите количество осей"
                            name={name}
                            decimalSeparator=","
                            min={0}
                            precision={0}
                            max={10}
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {canShowAxleLoad(values.type) && (
                    <>
                      <GridItem>
                        <FormField
                          label="Нагрузка на переднюю ось, тонн"
                          name="frontAxleLoad"
                          fast
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              decimalSeparator=","
                              precision={1}
                              min={0}
                              step={0.1}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Нагрузка на заднюю ось, тонн"
                          name="backAxleLoad"
                          fast
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              decimalSeparator=","
                              precision={1}
                              min={0}
                              step={0.1}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  {canShowCarrying(values.type) && (
                    <GridItem>
                      <FormField
                        label="Грузоподъемность, т"
                        name="carrying"
                        fast
                        required={isSelfVehicleModel}
                      >
                        {({value, name}) => (
                          <StyledInputNumber
                            disabled={!canEdit}
                            min={0}
                            max={40}
                            value={value}
                            decimalSeparator=","
                            step={0.1}
                            precision={1}
                            name={name}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField label="Габариты ТС, м" name="dimensions" fast>
                      {fieldProps => (
                        <Input
                          {...fieldProps}
                          placeholder="23.8x23.6x18.9"
                          disabled={!canEdit}
                          style={!canEdit ? {color: '#3d4042'} : undefined}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Разрешенная максимальная масса по ПТС, кг"
                      name="maximumAuthorizedMass"
                      fast
                      required={isSelfVehicleModel}
                    >
                      {({value, name}) => (
                        <StyledInputNumber
                          disabled={!canEdit}
                          name={name}
                          value={value}
                          decimalSeparator=","
                          precision={0}
                          min={0}
                          max={99999}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {values.type && (
                    <>
                      <GridItem>
                        <FormField
                          label="Тип двигателя"
                          name="engineType"
                          required={isSelfVehicleModel && getVehicleGroup(values.type) !== vehicleGroupEnum.floatingTransport}
                        >
                          {({name, value}) => (
                            <EngineTypeSelect
                              disabled={!canEdit || (!canShowFuelData(values.type)) || isElectricVehicle}
                              name={name}
                              value={value}
                              onChange={(type: string) =>
                                setFieldValue(name, type)
                              }
                              onBlur={() => handleBlur({target: {name}})}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          required={(isSelfVehicleModel && (getVehicleGroup(values.type) !==
                            vehicleGroupEnum.floatingTransport)) || isElectricVehicle
                          }
                          label={isElectricVehicle ? "Емкость батареи, кВт·ч" : "Рабочий объем двигателя, куб. см"}
                          name="engineCapacity"
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              min={0}
                              max={20000}
                              value={value}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Мощность двигателя, л.с."
                          name="enginePower"
                          required={isSelfVehicleModel && getVehicleGroup(values.type) !==
                            vehicleGroupEnum.floatingTransport
                          }
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              decimalSeparator=","
                              min={0}
                              max={999}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          fast
                          label="Максимальная скорость (км/ч)"
                          name="maxSpeed"
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              min={0}
                              max={250}
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  {canShowPassengerSeatsNumber(values.type) && (
                    <GridItem>
                      <FormField
                        label="Кол-во пассажирских мест, шт"
                        name="passengerSeatsNumber"
                        required={isSelfVehicleModel && isRequiredPassengerSeatsNumber(values.type)}
                      >
                        {({value, name}) => (
                          <StyledInputNumber
                            disabled={!canEdit}
                            name={name}
                            value={value}
                            precision={0}
                            min={0}
                            max={70}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {canShowEcologicClass(values.type) && (
                    <GridItem>
                      <FormField
                        label="Экологический класс ТС"
                        name="ecologicClass"
                        fast
                        required={isSelfVehicleModel && !isElectricVehicle}
                      >
                        {({value, name}) => (
                          <StyledInputNumber
                            disabled={!canEdit}
                            name={name}
                            value={value}
                            precision={0}
                            min={2}
                            max={5}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {canShowVehicleCategory(values.type) && (
                    <GridItem>
                      <FormField
                        label="Категория ТС"
                        name="categoryId"
                        fast
                        required={isSelfVehicleModel}
                      >
                        {({name, value}) => (
                          <VehicleCategorySelect
                            disabled={!canEdit}
                            name={name}
                            value={value}
                            onChange={(type: string) => setFieldValue(name, type)}
                            onBlur={() => handleBlur({target: {name}})}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                </StyledGrid>

                {canShowOsagoNeeded(values.type) && (
                  <>
                    <SectionTitle>Признаки ТС</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField label="Подлежит ОСАГО" name="osagoNeeded" fast>
                          {({value, name}) => (
                            <YesNoSelect
                              disabled={!canEdit}
                              value={value}
                              name={name}
                              onChange={(value: boolean) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </StyledGrid>
                  </>
                )}
                {canShowGpmData(values.type) && (
                  <>
                    <SectionTitle>ГПМ</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Грузоподъемность ГПМ"
                          required={isSelfVehicleModel}
                          name="gpmCarrying"
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              precision={1}
                              min={0}
                              max={999}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Высота подъема"
                          name="liftHeight"
                          required={isSelfVehicleModel}
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              precision={1}
                              min={0}
                              max={99}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          name="gpmHandlingRadius"
                          label="Вылет стрелы"
                          required={isSelfVehicleModel}
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              precision={1}
                              min={0}
                              max={99}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </StyledGrid>
                  </>
                )}
                {canShowDrillingData(values.type) && (
                  <>
                    <SectionTitle>Бурильная установка</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Глубина бурения (до мм)"
                          required={isSelfVehicleModel}
                          name="drillingDeep"
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              precision={0}
                              min={0}
                              max={99999}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Диаметр бурения (до мм)"
                          name="drillingDiameter"
                          required={isSelfVehicleModel}
                        >
                          {({value, name}) => (
                            <StyledInputNumber
                              disabled={!canEdit}
                              name={name}
                              value={value}
                              min={0}
                              max={9999}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </StyledGrid>
                  </>
                )}
                <SectionTitle>Тариф</SectionTitle>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <FormField
                      fast
                      label="Постоянная часть тарифа, руб."
                      name="fixedRatePart"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEdit}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      fast
                      label="Постоянная часть тарифа (в пути), руб"
                      name="fixedRateInTransit"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEdit}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      fast
                      label="Переменная часть тарифа, руб"
                      name="fixedRateInDowntime"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEdit}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      fast
                      label="Стоимость прогрева, руб"
                      name="warmingUpCost"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEdit}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </StyledGrid>

                {canShowFuelData(values.type) &&
                  canShowSecondaryFuel(values.type) && (
                    <>
                      <SectionTitle>Тарифы для доп. топлива</SectionTitle>
                      <StyledGrid gutter="16px">
                        <GridItem>
                          <FormField
                            fast
                            label="Постоянная часть тарифа, руб."
                            name="secondaryFixedRatePart"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Постоянная часть тарифа (в пути), руб"
                            name="secondaryFixedRateInTransit"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Переменная часть тарифа, руб"
                            name="secondaryFixedRateInDowntime"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Стоимость прогрева, руб"
                            name="secondaryWarmingUpCost"
                          >
                            {({value, name}) => (
                              <InputNumber
                                disabled={!canEdit}
                                min={0}
                                value={value}
                                name={name}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                                onBlur={handleBlur}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </StyledGrid>
                    </>
                  )}
                <SectionTitle>Техобслуживание</SectionTitle>
                <StyledGrid gutter="16px">
                  <GridItem>
                    <FormField
                      fast
                      label="Трудоёмкость одного ТО (чел/час)"
                      name="workHours"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEditMaitenanceNormative}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      fast
                      label="Средние затраты на материалы ТО, руб"
                      name="materialsCost"
                    >
                      {({value, name}) => (
                        <InputNumber
                          disabled={!canEditMaitenanceNormative}
                          min={0}
                          value={value}
                          name={name}
                          onChange={(value: number) => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {!!values.workAccountingType &&
                    (values.workAccountingType === selfVehiclePlanVehicleTypeEnum.kilometrage || values.workAccountingType ===
                      selfVehiclePlanVehicleTypeEnum.all) && (
                      <GridItem>
                        <FormField
                          fast
                          label="Интервал проведения технического обслуживания (км)"
                          name="kilometrageInterval"
                        >
                          {({value, name}) => (
                            <InputNumber
                              disabled={!canEditMaitenanceNormative}
                              min={0}
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                  {!!values.workAccountingType &&
                    (values.workAccountingType === selfVehiclePlanVehicleTypeEnum.workHours || values.workAccountingType ===
                      selfVehiclePlanVehicleTypeEnum.all) && (
                      <GridItem>
                        <FormField
                          fast
                          label="Интервал проведения технического обслуживания (моточас)"
                          name="engineWorkInterval"
                        >
                          {({value, name}) => (
                            <InputNumber
                              disabled={!canEditMaitenanceNormative}
                              min={0}
                              value={value}
                              name={name}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                  <GridItem>
                    <FormField label="Сопоставимая модель для проведения ТО" name="vehicleModelMatchTOId">
                      {({value, name}) => (
                        <Select
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                          disabled={!canEditMaitenanceNormative}
                        >
                          {modelMatches.map(match => (
                            <Option key={match.id} value={match.id}>
                              {`${match.modelName} ${match.brandName}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Сопоставимая модель для проведения ТР/КР" name="vehicleModelMatchRepairId">
                      {({value, name}) => (
                        <Select
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                          disabled={!canEditMaitenanceNormative}
                        >
                          {modelMatches.map(match => (
                            <Option key={match.id} value={match.id}>
                              {`${match.modelName} ${match.brandName}`}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </FormField>
                  </GridItem>
                </StyledGrid>
              </Content>
            </Section>
            {canEdit && (
              <Footer>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                  data-cy="save"
                >
                  Сохранить
                </Button>
                <CancelButton dirty={dirty} onClick={onCancel}>
                  Отменить
                </CancelButton>
              </Footer>
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default InnerForm;
