//@flow
import React from 'react';
import { Link } from '@reach/router';

import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import {branchBudgetSummaryCognosStatus, branchBudgetSummaryCognosTypeEnum, entityStatus, entityStatusEnum} from '../../../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToString,
  vehiclePlanInfoString,
  contractVehiclePlanInfoString
} from '../../../../lib/helpers';
import { SectionContent } from '../../../../components/hoc/common/components/elements';
import type { BranchBudgetSummary } from '../../../../lib/types';
import moment from 'moment';
import styled from 'styled-components';

const Block = styled(Grid)`
  margin-bottom: 20px;
  border-bottom: 1px solid #adb8c3;
`;

type Props = {
  branchBudgetSummary?: BranchBudgetSummary,
  proposal: boolean,
  source?: $Keys<branchBudgetSummaryCognosTypeEnum>,
};

export default (props: Props) => {
  return (
    <>
      <SectionContent>
        {props.source === branchBudgetSummaryCognosTypeEnum.cognos && (
          <Block gutter="16px">
            <GridItem>
              <Field label="Дата загрузки данных">
                {props.branchBudgetSummary.cognosLoadDate
                  ? moment(props.branchBudgetSummary.cognosLoadDate).format('DD.MM.YYYY')
                  : '-'
                }
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Год">
                {props.branchBudgetSummary.cognosYear || '-'}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Версия">
                {props.branchBudgetSummary.cognosVersion || '-'}
              </Field>
            </GridItem>
          </Block>
        )}
        <Grid gutter="16px">
          {props.proposal && (
            <>
              <GridItem fullWidth>
                {!!props.branchBudgetSummary?.proposalStatus && (
                  <Field label="Статус">
                    {entityStatus[props.branchBudgetSummary?.proposalStatus]}
                  </Field>
                )}
              </GridItem>
              {!!props.branchBudgetSummary?.declineReason &&
                props.branchBudgetSummary?.status !==
                  entityStatusEnum.approved && (
                  <GridItem fullWidth>
                    <Field label="Причина отклонения" name="declineReason">
                      {props.branchBudgetSummary?.declineReason}
                    </Field>
                  </GridItem>
                )}
            </>
          )}
          <GridItem>
            <Field label="Наименование">
              {props.branchBudgetSummary?.name
                ? props.branchBudgetSummary.name
                : '-'
              }
            </Field>
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.cognosStatus && (
              <Field label={`Статус${
                props.source !== branchBudgetSummaryCognosTypeEnum.cognos ? ' отправки в ИСУ «Бюджетирование»' : ''
              }`}>
                {branchBudgetSummaryCognosStatus[props.branchBudgetSummary.cognosStatus]}
              </Field>
            )}
          </GridItem>
          <GridItem>
            <Field label="Наименование бюджета СТС">
              {props.branchBudgetSummary?.selfVehiclePlan
                ? (
                  <Link to={`/budget/vehicle/${props.branchBudgetSummary.selfVehiclePlanId}/card`}>
                    {vehiclePlanInfoString(props.branchBudgetSummary.selfVehiclePlan, true)}
                  </Link>
                )
                : '-'
              }
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Наименование бюджета НТС">
              {!!props.branchBudgetSummary?.contractVehiclePlan
                ? (
                  <Link to={`/budget/contract-vehicle/fixed/${
                    props.source === branchBudgetSummaryCognosTypeEnum.primary ? 'before' : 'after'
                  }Cognos/${props.branchBudgetSummary.contractVehiclePlanId}/card`}>
                    {contractVehiclePlanInfoString(props.branchBudgetSummary.contractVehiclePlan, true)}
                  </Link>
                )
                : '-'
              }
            </Field>
          </GridItem>
          <GridItem>
            {props?.branchBudgetSummary?.selfVehiclePlan && (
              <Field label="Период формирования">
                {`${formatDateRangeString(
                  props.branchBudgetSummary.selfVehiclePlan.startDate,
                  props.branchBudgetSummary.selfVehiclePlan.endDate,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.contractVehiclePlan && (
              <Field label="Период формирования">
                {`${formatDateRangeString(
                  props.branchBudgetSummary.contractVehiclePlan.startDate,
                  props.branchBudgetSummary.contractVehiclePlan.endDate,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.selfVehiclePlan && (
              <Field label="Дата формирования">
                {`${formatDateTimeToString(
                  props.branchBudgetSummary.selfVehiclePlan.date,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
          <GridItem>
            {props.branchBudgetSummary?.contractVehiclePlan && (
              <Field label="Дата формирования">
                {`${formatDateTimeToString(
                  props.branchBudgetSummary.contractVehiclePlan.date,
                  'DD.MM.YYYY'
                )}`}
              </Field>
            )}
          </GridItem>
        </Grid>
      </SectionContent>
    </>
  );
};
