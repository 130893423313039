import List from 'antd/lib/list';
import React from 'react';
import styled from 'styled-components';

import { Collapse } from 'antd';
import Header from '../../components/layout/Header';
import { Section } from './../../components/layout';

const StyledSection = styled(Section)`
  padding: 16px;
`;

type InstructionLink = {
  link: string,
  label: string
};

const links = [
  {
    header: 'Инструкции по ролям-доступа',
    key: 'roles_accesses',
    items: [
      {
        link: '/instructions/roles_accesses/Руководство роли Главный инженер.docx',
        label: 'Руководство роли Главный инженер',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Диспетчер СМиА.docx',
        label: 'Руководство роли Диспетчер СМиА',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Заявитель.docx',
        label: 'Руководство роли Заявитель',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Механик СМиА.docx',
        label: 'Руководство роли Механик СМиА',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Начальник СМиА.docx',
        label: 'Руководство роли Начальник СМиА',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Руководитель ТГ.docx',
        label: 'Руководство роли Руководитель ТГ',
      },
      {
        link: '/instructions/roles_accesses/Руководство роли Специалист СМиА.docx',
        label: 'Руководство роли Специалист СМиА',
      },
    ],
  },
  {
    header: 'Инструкции в рамках развития системы',
    key: 'evolution',
    items: [
      {
        link: '/instructions/evolution/1. Руководство пользователя. Доработки СТС.pdf',
        label: 'Руководство пользователя. Доработки СТС',
      },
      {
        link: '/instructions/evolution/2. Руководство пользователя. Интеграция ИСУ Бюджет.pdf',
        label: 'Руководство пользователя. Интеграция ИСУ Бюджет',
      },
      {
        link: '/instructions/evolution/3. Руководство пользователя. Копии основного бюджета.pdf',
        label: 'Руководство пользователя. Копии основного бюджета',
      },
      {
        link: '/instructions/evolution/4. Руководство пользователя. Доработки бюджета СТС_НТС.pdf',
        label: 'Руководство пользователя. Доработки бюджета СТС НТС',
      },
      {
        link: '/instructions/evolution/5. Руководство пользователя. Интеграция ИК Парус.pdf',
        label: 'Руководство пользователя. Интеграция ИК Парус',
      },
      {
        link: '/instructions/evolution/6. Руководство пользователя. Доработки ПЛ НТС.pdf',
        label: 'Руководство пользователя. Доработки ПЛ НТС',
      },
      {
        link: '/instructions/evolution/7. Руководство пользователя. Доработки СТС (ТОиР).pdf',
        label: 'Руководство пользователя. Доработки СТС (ТОиР)',
      },
      {
        link: '/instructions/evolution/М1. Рук-во. Сверка СТС. 8794.pdf',
        label: 'М1. Рук-во. Сверка СТС. 8794',
      },
      {
        link: '/instructions/evolution/М2. Рук-во. Уточн бюджет. 9044.pdf',
        label: 'М2. Рук-во. Уточн бюджет. 9044',
      },
      {
        link: '/instructions/evolution/М3. Рук-во. Бюджет СТС. 9072.pdf',
        label: 'М3. Рук-во. Бюджет СТС. 9072',
      },
      {
        link: '/instructions/evolution/М4. Рук-во. Заявки НТС. 9359.pdf',
        label: 'М4. Рук-во. Заявки НТС. 9359',
      },
      {
        link: '/instructions/evolution/М5. Рук-во. Тарификатор НТС. 9255.pdf',
        label: 'М5. Рук-во. Тарификатор НТС. 9255',
      },
      {
        link: '/instructions/evolution/М6. Рук-во. МДМ. 9362.pdf',
        label: 'М6. Рук-во. МДМ. 9362',
      },
      {
        link: '/instructions/evolution/М7. Рук-во. Спидометры. 9875.pdf',
        label: 'М7. Рук-во. Спидометры. 9875',
      },
      {
        link: '/instructions/evolution/М8. Рук-во. Бюждет СТС. 9769.pdf',
        label: 'М8. Рук-во. Бюждет СТС. 9769',
      },
      {
        link: '/instructions/evolution/10150. Рук-во. Акт списания ГСМ.pdf',
        label: 'Рук-во. Акт списания ГСМ',
      },
      {
        link: '/instructions/evolution/Руководство пользователя. Доработка функционала эксплуатации НТС.docx',
        label: 'Руководство пользователя. Доработка функционала эксплуатации НТС',
      },
      {
        link: '/instructions/evolution/Руководство пользователя. Разработка инструмента импорта фактических тарифов на НТС.docx',
        label: 'Руководство пользователя. Разработка инструмента импорта фактических тарифов на НТС',
      },
      {
        link: '/instructions/evolution/Руководство пользователя. Интеграция АСУ ТОиР со смежной системой АС «Транспорт».docx',
        label: 'Руководство пользователя. Интеграция АСУ ТОиР со смежной системой АС «Транспорт»',
      },
      {
        link: '/instructions/evolution/Руководство пользователя. Доработка подсистемы “Эксплуатация ТС” для арендованных ТС и электрокаров.docx',
        label: 'Руководство пользователя. Доработка подсистемы “Эксплуатация ТС” для арендованных ТС и электрокаров',
      },
    ],
  },
  {
    header: 'Пояснения-инструкции по доработкам сопровождения',
    key: 'escort',
    items: [
      {
        link: '/instructions/escort/УАТ. Пояснения по доработкам 2022 ч.1.pdf',
        label: 'УАТ. Пояснения по доработкам 2022 ч.1',
      },
      {
        link: '/instructions/escort/УАТ. Пояснения по доработкам 2022 ч.2.pdf',
        label: 'УАТ. Пояснения по доработкам 2022 ч.2',
      },
      {
        link: '/instructions/escort/УАТ. Пояснения по доработкам 2022 ч.3.pdf',
        label: 'УАТ. Пояснения по доработкам 2022 ч.3',
      },
    ],
  },
  {
    header: 'Инструкции Архив',
    key: 'archive',
    items: [
      {
        link: '/instructions/archive/Инструкция для очистки кэша браузера.docx',
        label: 'Инструкция для очистки кэша браузера.',
      },
      {
        link:
          '/instructions/archive/Инструкция по работе с объектом _Инвентарная карточка ТС_+ОБЪЕКТЫ+Водители.docx',
        label: 'Инструкция по работе с инвентарной карточкой ТС.',
      },
      {
        link: '/instructions/archive/Руководство_по_взаимодействию_систем.docx',
        label: 'Руководство по взаимодействию систем',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Специалист ТГ.docx',
        label: 'Руководство пользователя. Специалист ТГ.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Специалист СМиА.docx',
        label: 'Руководство пользователя. Специалист СМиА.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Руководитель ТГ.docx',
        label: 'Руководство пользователя. Руководитель ТГ.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Начальник СМиА.docx',
        label: 'Руководство пользователя. Начальник СМиА.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Начальник РЭС.docx',
        label: 'Руководство пользователя. Начальник РЭС.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Инженер ПП.docx',
        label: 'Руководство пользователя. Инженер ПП.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Заявитель.docx',
        label: 'Руководство пользователя. Заявитель.',
      },
      {
        link:
          '/instructions/archive/Руководство пользователя_Зам.директора по общим вопросам.docx',
        label: 'Руководство пользователя. Зам. директора по общим вопросам.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Диспетчер СМиА.docx',
        label: 'Руководство пользователя. Диспетчер СМиА.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Главный инженер.docx',
        label: 'Руководство пользователя. Главный инженер.',
      },
      {
        link: '/instructions/archive/Инструкция Специалист ОПКиОТ.docx',
        label: 'Руководство пользователя. Специалист ОПКиОТ.',
      },
      {
        link:
          '/instructions/archive/Приложение 2 - Регламент учета и эксплуатации транспортных средств.docx',
        label: 'Регламент учета и эксплуатации транспортных средств.',
      },

      {
        link: '/instructions/archive/Руководство пользователя Администратор АС.docx',
        label: 'Руководство пользователя Администратор АС.',
      },
      {
        link: '/instructions/archive/Руководство пользователя Оператор АС.docx',
        label: 'Руководство пользователя Оператор АС.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Механик СМиА.docx',
        label: 'Руководство пользователя Механик СМиА.',
      },
      {
        link: '/instructions/archive/Руководство пользователя_Специалист ОФПиА.docx',
        label: 'Руководство пользователя Специалист ОФПиА.',
      },
      {
        link: '/instructions/archive/Руководство_пользователя_Зам_дира_по_общим.docx',
        label: 'Руководство пользователя Зам.дир. по общим.',
      },
      {
        link: '/instructions/archive/Руководство_пользователя_ИнженерПП.docx',
        label: 'Руководство пользователя ИнженерПП.',
      },
      {
        link: '/instructions/archive/Руководство_пользователя_Сокуратор_направления.docx',
        label: 'Руководство пользователя Сокуратор направления.',
      },
      {
        link: '/instructions/archive/Руководство_пользователя_Специалист_Бухгалтерии.docx',
        label: 'Руководство пользователя Специалист Бухгалтерии.',
      },
      {
        link:
          '/instructions/archive/Руководство_пользователя_Специалист_Бухгалтерии_АУ_.docx',
        label: 'Руководство пользователя Специалист Бухгалтерии АУ.',
      },
    ],
  },
];

const sortFunc = (a: InstructionLink, b: InstructionLink) => a.label.localeCompare(b.label);

const renderItem = item => (
  <List.Item>
    <a target="_blank" rel="noopener noreferrer" href={item.link}>
      {item.label}
    </a>
  </List.Item>
);

export default () => (
  <div>
    <Header left={<h1>Инструкции</h1>} />
    <StyledSection>
      <Collapse>
        {links.map(link => (
          <Collapse.Panel header={link.header} key={link.key}>
            <List bordered dataSource={link.items.sort(sortFunc)} renderItem={renderItem} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </StyledSection>
  </div>
);
