// @flow
import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import {fuelsActApi, maintenanceDocumentApi, oilsActApi, outsourcingActApi} from './../../../lib/api';
import {formatDateTimeToString, getListInitialState, navigate} from '../../../lib/helpers';
import type {ListState, MaintenanceDocumentType, UserAccess} from '../../../lib/types';
import {accessTypeEnum, entityStatus, maintenanceDocumentTypeEnum} from '../../../lib/enum';

import Table from './../../../components/ui/Table';
import {Icon} from './../../../components/ui';
import {Header, Section} from './../../../components/layout';
import {notificationLoading} from '../../../components/Notifications';

import {withUserAccess} from './../../withUserAccess';
import {handlingInternalActs} from '../accessRight';

import {Tabs} from './../index';
import type {InternalActFilterParams} from './components';
import {Filter, Tabs as InternalTabs} from './components';

type Props = {
  actType: string,
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<any> & {
  filter: any,
  type: MaintenanceDocumentType,
  columns: any[]
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const MAINTENANCE_COLUMNS = [
  {
    title: '№ карточки работ',
    key: 'maintenanceId',
    dataIndex: 'maintenance.id',
    render: (id: number) => (
      <a href={`/maintenances/${id}`} target="_blank" rel="noopener noreferrer">
        {id}
      </a>
    )
  },
  {
    title: 'Вид  работ',
    key: 'work',
    dataIndex: 'maintenance.maintenanceWorkType.name'
  },
  {
    title: 'Дата документа',
    key: 'created',
    dataIndex: 'maintenance.created',
    width: '150',
    render: (created: string) =>
      `${formatDateTimeToString(created, 'DD.MM.YYYY')}`
  },
  {
    title: 'Сумма',
    key: 'totalSum',
    dataIndex: 'maintenance.totalSum',
    render: totalSum => totalSum && totalSum.toLocaleString('ru-RU')
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    render: status => entityStatus[status]
  },
  {
    title: '',
    key: 'file',
    dataIndex: 'file',
    render: file => {
      return file && file.url ? (
        <Button
          type="primary"
          icon="download"
          size="small"
          href={`${file.url}?isInline=false`}
          target="_blank"
        />
      ) : null;
    }
  }
];

const ACT_COLUMNS = [
  {
    title: '№ документа',
    key: 'documentNumber',
    dataIndex: 'documentNumber'
  },
  {
    title: 'Период оказанных услуг',
    width: '150',
    render: record => {
      return `${formatDateTimeToString(
        record.startDate,
        'DD.MM.YYYY HH:mm'
      )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY HH:mm')}`;
    }
  },
  {
    title: 'Дата документа',
    key: '',
    dataIndex: 'documentDate',
    width: '150',
    render: (actDate: string) =>
      `${formatDateTimeToString(actDate, 'DD.MM.YYYY')}`
  },
  {
    title: 'Сумма',
    key: 'sum',
    dataIndex: 'sum',
    render: sum => (sum ? sum.toLocaleString('ru-RU') : 0)
  },
];

const OUTSOURCING_ACT = [
  {
    title: '№ договора',
    key: 'contractNumber',
    dataIndex: 'contractNumber'
  },
  {
    title: 'Дата договора',
    key: 'contractDate',
    dataIndex: 'contractDate',
    render: date => formatDateTimeToString(date, 'DD.MM.YYYY')
  }
];

export class ActList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: MAINTENANCE_COLUMNS
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.setColumns()
    );
  }

  setColumns() {
    const { actType } = this.props;
    let columns = [];
    switch (actType) {
      case 'outsourcing-act':
        columns = [...OUTSOURCING_ACT, ...ACT_COLUMNS];
        this.canAdd() && columns.push(this.getDeleteColumn());
        break;
      case 'oils-act':
      case 'fuels-act':
        columns = [
          {
            title: '№',
            key: 'id',
            dataIndex: 'id',
          },
          ...ACT_COLUMNS
        ];
        this.canAdd() && columns.push(this.getDeleteColumn());
        break;
      case undefined:
      case 'defective-statement':
        const MAINTENANCE_COLUMNS_WITHOUT_PRINT = MAINTENANCE_COLUMNS.filter(item => item.dataIndex !== 'file');
        columns = this.canDelete()
          ? [...MAINTENANCE_COLUMNS_WITHOUT_PRINT, this.getDeleteColumn()]
          : MAINTENANCE_COLUMNS_WITHOUT_PRINT;
        break;
      default:
        columns = MAINTENANCE_COLUMNS;
    }
    this.setState({ columns }, () => this.fetch());
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.actType !== this.props.actType) {
      this.setColumns();
    }
  }

  getType() {
    const { actType } = this.props;
    let type = undefined;
    switch (actType) {
      case 'defective-statement':
        type = maintenanceDocumentTypeEnum.defectiveStatement;
        break;
      case 'os3':
        type = maintenanceDocumentTypeEnum.os3;
        break;
      case undefined:
        type = maintenanceDocumentTypeEnum.detailedDefectiveStatement;
        break;
      default:
    }
    return type;
  }

  getDeleteColumn = () => {
    return {
      stopPropagation: true,
      key: 'delete',
      dataIndex: 'id',
      render: (id: number) => {
        return (
          <Popconfirm
            placement="topRight"
            title={`Вы уверены, что хотите удалить ${this.props.actType === 'fuels-act'
              ? 'акт списания топлива'
              : 'карточку работы'}?`}
            okText="Ок"
            cancelText="Отмена"
            onConfirm={() => this.deleteAct(id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        );
      }
    };
  };

  deleteAct = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление',
        key: 'deleting'
      });
      const { actType } = this.props;
      switch (actType) {
        case 'outsourcing-act':
          await outsourcingActApi.deleteOutsourcingAct(id);
          break;
        case 'oils-act':
          await oilsActApi.delete(id);
          break;
        case undefined:
        case 'defective-statement':
          await maintenanceDocumentApi.deleteMaintenanceDocument(id);
          break;
        default:
          await fuelsActApi.delete(id);
      }
      this.fetch();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetch = async (page: number = this.state.page) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    let data = {};
    switch (this.props.actType) {
      case 'outsourcing-act':
        data = await outsourcingActApi.fetchOutsourcingAct({ page, ...filter });
        break;
      case 'oils-act':
        data = await oilsActApi.fetch({
          page,
          ...filter
        });
        break;
      case 'fuels-act':
        data = await fuelsActApi.fetch({
          page,
          ...filter
        });
        break;
      default:
        data = await maintenanceDocumentApi.fetchMaintenanceDocument({
          page,
          ...{ ...filter, type: this.getType() }
        });
    }
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: InternalActFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetch(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetch(page));
  };

  canAdd = () =>
    this.props.userAccess.some(access => handlingInternalActs.includes(access));
  
  canDelete = () =>
    this.props.userAccess.some(access => [accessTypeEnum.admin, accessTypeEnum.removingMaintenanceInternalAct].includes(access));

  canHandlingFuelAct = () => this.props.userAccess.some(i => [accessTypeEnum.admin, accessTypeEnum.handlingFuelsAct].includes(i));
  
  handleOnRow = (id) => {
    switch (this.props.actType) {
      case undefined:
        return {
          onClick: () => navigate(`/admin/act/internal/completed-works-card/${id}`),
        };
      case 'defective-statement':
        return {
          onClick: () => navigate(`/admin/act/internal/defective-statement/${id}`),
        };
      case 'fuels-act':
        return {
          onClick: () => navigate(`/admin/act/internal/write-off-fuels-act/${id}`),
        };
      default:
        return;
    }
  }

  render() {
    const { location, actType } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр первичных документов</h1>}
          right={
            this.canAdd() && (
              <>
                {actType === 'outsourcing-act' && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate('/admin/act/internal/outsourcing-act/new')
                    }
                  >
                    Создать
                  </Button>
                )}
                {actType === 'fuels-act' && this.canHandlingFuelAct() && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate('/admin/act/internal/fuels-act/new')
                    }
                  >
                    Создать
                  </Button>
                )}
                {actType === 'oils-act' && (
                  <Button
                    type="primary"
                    onClick={() => navigate('/admin/act/internal/oils-act/new')}
                  >
                    Создать
                  </Button>
                )}
              </>
            )
          }
        />
        <Tabs />
        <InternalTabs />
        <Section>
          {!['outsourcing-act', 'fuels-act', 'oils-act'].includes(actType) && (
            <Filter
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          )}
          <Table
            columns={this.state.columns}
            data={data}
            fetch={this.fetch}
            fetchOnMount
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            onRow={record => this.handleOnRow(record.id)}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(ActList);
