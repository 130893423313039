// @flow
import {Button} from 'antd';
import moment from 'moment';
import React from 'react';

import Page from '../../components/hoc/common/handbook/PageContext';
import {
  budgetSummary,
  budgetSummaryEnum,
  cognosLogStatus,
  cognosLogStatusEnum,
  cognosSendingStatusEnum,
} from '../../lib/enum';
import {navigate} from '../../lib/helpers';
import {anyNotificationWithFeedback} from '../../lib/notificationWrapper';
import type {CognosLog, CognosLogStatusType} from '../../lib/types';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import {branchBudgetSummaryApi, cognosLogApi} from './../../lib/api';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {Link} from '@reach/router';

const entryPointPath = '/admin/cognos-log/';

const columns = [
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Создано',
    dataIndex: 'created',
  },
  {
    title: 'Год',
    dataIndex: 'branchBudgetSummary.budgetSummary.startDate',
    render: (date: string) => {
      return moment(date).year();
    },
  },
  {
    title: 'Тип бюджета',
    dataIndex: 'branchBudgetSummary.budgetSummary.type',
    render: type => budgetSummary[type],
  },
  {
    title: 'Тип бюджета филиала',
    dataIndex: 'branchBudgetSummary.type',
    render: type => {
      switch (type) {
        case 'primary':
          return 'Первичный вариант';
        case 'corrected':
          return 'Скорректированный вариант';
        case 'cognos':
          return 'Когнос';
        default:
          return '-';
      }
    },
  },
  {
    title: 'Версия бюджета',
    dataIndex: 'branchBudgetSummary.budgetSummary.budgetVersion.name',
  },
  {
    title: 'Наименование бюджета',
    dataIndex: 'branchBudgetSummary.name',
    render: (name, item) => {
      const {budgetSummaryId, orgUnitId, type, budgetSummary} = item.branchBudgetSummary;
      return (
        <Link to={`/budget/${
          budgetSummary.type === budgetSummaryEnum.main
            ? 'summary'
            : 'details'
        }/${budgetSummaryId}/${orgUnitId}/${type}`}>
          {name}
        </Link>
      );
    },
    onCell: () => ({
      onClick: e => e.stopPropagation(),
    }),
  },
  {
    title: 'Статус передачи',
    dataIndex: 'status',
    render: (status: CognosLogStatusType) => cognosLogStatus[status],
    key: 'status',
  },
  {
    title: '',
    dataIndex: 'status',
    stopPropagation: true,
    render: (status: CognosLogStatusType, row: CognosLog) => {
      const {branchBudgetSummary} = row;
      const cognosSendingStatuses = [
        branchBudgetSummary.cognosContractBudgetDetailsStatus,
        branchBudgetSummary.cognosRegulationsStatus,
        branchBudgetSummary.cognosVehicleTariffsStatus,
        branchBudgetSummary.cognosPlanFuelConsumptionStatus,
      ];
      const showButton =
        cognosSendingStatuses.includes(cognosSendingStatusEnum.error) ||
        status === cognosLogStatusEnum.error;
      return showButton ? (
        <Button
          onClick={() => {
            anyNotificationWithFeedback(
              async () => {
                return await branchBudgetSummaryApi.exportToCognos(
                  row.branchBudgetSummaryId,
                );
              },
              {
                message: 'Отправка данных в ИСУ Бюджетирование...',
                duration: 0,
              },
            );
          }}
        >
          Отправить повторно
        </Button>
      ) : null;
    },
    key: 'sendAgainButton',
  },
  {
    title: '',
    dataIndex: 'id',
    width: '50px',
    stopPropagation: true,
    render: id => (
      <Button
        icon="arrow-right"
        size="small"
        href={`/admin/cognos-log/${id}`}
        target="_blank"
      />
    ),
  },
];

const access = {};

export default withUserAccess(({userAccess}) => {
  return (
    <>
      <Page>
        <CommonListPage
          crud={cognosLogApi}
          pageHeaderProps={{
            mainHeader: 'Отправка в ИСУ Бюджетирование',
            access,
          }}
          tableListProps={{
            columns,
            onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          }}
          filter={Filter}
        />
      </Page>
    </>
  );
});
