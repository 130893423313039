// @flow

import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input/Input';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import { FormikProps } from 'formik';
import InputNumber from 'antd/lib/input-number';
import moment from 'moment';
import isNil from 'lodash/isNil';

import type { Tire, Vehicle } from '../../../../lib/types';
import { Form, MdmInput, OrgUnitSelect } from './../../../../components';
import Grid, { GridItem } from './../../../../components/layout/Grid';
import { Section } from './../../../../components/layout';
import type { MdmNode } from '../../../../lib/types';
import { convertDateToString } from '../../../Vehicles/lib';
import CancelButton from '../../../../components/CancelButton';
import { isVehicleTractor } from '../../../../lib/helpers';
import MdmSelect from '../../../../components/selects/MdmSelect';

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

type FormProps = {
  tire: $Shape<Tire>,
  onCancel: Function,
  onSubmit: Function,
  canEditOrgUnit: boolean,
  vehicle: ?Vehicle,
  isAdmin: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  tire,
  canEditOrgUnit,
  vehicle,
  isAdmin
}: FormProps) => (
  <Form initialValues={tire} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps<Tire>) => {
      const {
        handleSubmit,
        setFieldValue,
        values,
        dirty,
        isSubmitting,
        handleBlur
      } = formikProps;
      const isTractor = isVehicleTractor(vehicle);
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {canEditOrgUnit && (
                  <GridItem>
                    <FormField
                      fast
                      label="Подразделение"
                      required
                      name="orgUnitId"
                    >
                      {({ value, name }) => (
                        <OrgUnitSelect
                          value={value || ''}
                          onChange={(orgUnitId: number) =>
                            setFieldValue(name, parseInt(orgUnitId, 10))
                          }
                          onBlur={() => handleBlur({ target: { name } })}
                          data-cy="orgUnitSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField label="Наименование" required name="name" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Примечание" name="notation" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Номер партии" name="batchNumber" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Заводской номер" name="factoryNumber" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="ГОСТ или ТУ" name="gost" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка" name="brand" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Мнемокод" name="code" hasFeedback={false}>
                    {field => (
                      <MdmInput
                        {...field}
                        onSelect={(mdmNode: MdmNode) => {
                          setFieldValue('name', mdmNode.name);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {/* Сделал специально, чтобы инпут на весь экран не рисовался */}
                <GridItem />
                <GridItem>
                  <FormField
                    label="Высота протектора, мм"
                    name="treadDepth"
                    defaultValue={0}
                    hasFeedback={false}
                    fast
                  >
                    {({ value, name }) => (
                      <InputNumber
                        onChange={(value: number) => setFieldValue(name, value)}
                        value={value}
                        name={name}
                        onBlur={handleBlur}
                        disabled={!!values.id}
                      />
                    )}
                  </FormField>
                </GridItem>
                {tire.vehicleId && (
                  <GridItem>
                    <FormField
                      label="Дата установки"
                      name="installDate"
                      fast
                      required
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment(value) : null}
                          onBlur={handleBlur}
                          onChange={(value: Object, dateString: string) =>
                            setFieldValue(
                              name,
                              convertDateToString(value, dateString)
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {isNil(vehicle) && (
                  <>
                    <GridItem>
                      <FormField
                        label="Норматив километража"
                        name="normKilometrage"
                        fast
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            min={2}
                            decimalSeparator=","
                            step={0.01}
                            onBlur={handleBlur}
                            onChange={(normKilometrage: number) =>
                              setFieldValue(name, normKilometrage)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Норматив моточасов"
                        name="normWorkHours"
                        fast
                      >
                        {({ value, name }) => (
                          <InputNumber
                            value={value}
                            name={name}
                            min={2}
                            decimalSeparator=","
                            step={0.01}
                            onBlur={handleBlur}
                            onChange={(normWorkHours: number) =>
                              setFieldValue(name, normWorkHours)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Начальный пробег"
                        name="initialKilometrage"
                        fast
                      >
                        {({ value, name }) => (
                          <InputNumber
                            name={name}
                            value={value}
                            min={0}
                            decimalSeparator=","
                            onBlur={handleBlur}
                            onChange={(kilometrage: number) => {
                              setFieldValue(name, kilometrage);
                              if (!values.id) {
                                setFieldValue('actualKilometrage', kilometrage);
                              }
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Начальные моточасы"
                        name="initialWorkHours"
                        fast
                      >
                        {({ value, name }) => (
                          <InputNumber
                            name={name}
                            value={value}
                            min={0}
                            decimalSeparator=","
                            onBlur={handleBlur}
                            onChange={(workHours: number) => {
                              setFieldValue(name, workHours);
                              if (!values.id) {
                                setFieldValue('workHours', workHours);
                              }
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}
                {vehicle && (
                  <>
                    {!isTractor ? (
                      <GridItem>
                        <FormField
                          label="Норматив километража"
                          name="normKilometrage"
                          fast
                          hasFeedback={false}
                          required
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onBlur={handleBlur}
                              onChange={(normKilometrage: number) =>
                                setFieldValue(name, normKilometrage)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    ) : (
                      <GridItem>
                        <FormField
                          label="Норматив моточасов"
                          name="normWorkHours"
                          fast
                          hasFeedback={false}
                          required
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              name={name}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onBlur={handleBlur}
                              onChange={(normWorkHours: number) =>
                                setFieldValue(name, normWorkHours)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    {!isTractor && (
                      <GridItem>
                        <FormField
                          label="Фактический пробег"
                          name="actualKilometrage"
                          fast
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onBlur={handleBlur}
                              onChange={(actualKilometrage: number) =>
                                setFieldValue(name, actualKilometrage)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    {isTractor && (
                      <GridItem>
                        <FormField label="Моточасы" name="workHours" fast>
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              min={2}
                              decimalSeparator=","
                              step={0.01}
                              onBlur={handleBlur}
                              onChange={(workHours: number) =>
                                setFieldValue(name, workHours)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                  </>
                )}
                <GridItem>
                  <FormField
                    label="Дата выдачи"
                    required
                    name="issuedDate"
                    fast
                  >
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onBlur={handleBlur}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Аналог шины для расчета бюджета"
                    name="otherTireMtrId"
                  >
                    {({ name, value }) => (
                      <MdmSelect
                        value={
                          values?.otherTireMtr
                            ? `${values.otherTireMtr?.mnemocode} ${values.otherTireMtr?.name}`
                            : null
                        }
                        filter={{}}
                        name={name}
                        onChange={(value: number, option: any) => {
                          setFieldValue(name, option?.props?.mdm?.id);
                          setFieldValue('otherTireMtr', option?.props?.mdm);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {tire.decomissionDate && isAdmin && (
                  <GridItem>
                    <FormField
                      label="Дата списания"
                      name="decomissionDate"
                      fast
                    >
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment(value) : null}
                          onBlur={handleBlur}
                          onChange={(value: Object, dateString: string) =>
                            setFieldValue(
                              name,
                              convertDateToString(value, dateString)
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default InnerForm;
