import React, { memo } from 'react';
import { fetchRequest } from '../../../lib/api';
import moment from 'moment';
import {
  cognosSendingStatusEnum,
  cognosSendingStatuses,
  fuelTypes,
  monthsNumbersEnum,
  monthsNamesTranslitEnum
} from '../../../lib/enum';
import { notificationLoading } from '../../../components/Notifications';
import notification from 'antd/lib/notification';
import { Section, SectionTitle } from '../../../components/layout';
import { Button } from 'antd';
import { AntTable } from '../../../components/ui';
import styled from 'styled-components';
import SendButton from './SendButton';

const Content = styled.div`
  padding: 16px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 7px;
`;

const InnerTable = ({ data, setData }) => {
  if (!data.factFuelConsumptions) {
    return null;
  }
  const columns = [
    {
      title: 'Тип топлива',
      dataIndex: 'fuelType',
      render: type => fuelTypes[type]
    },
    {
      title: 'Цена топлива',
      dataIndex: 'fuelPrice',
      render: price => price / 100
    },
    {
      title: 'Количество топлива',
      dataIndex: 'fuelCount',
      render: count => Math.round(count * 100) / 100
    },
    {
      title: 'Стоимость топлива',
      render: item =>
        Math.round(
          (Math.round(item.fuelCount * 100) / 100) *
            (item.fuelPrice / 100) *
            100
        ) / 100,
      key: 'cost'
    }
  ];
  const recalculation = async () => {
    try {
      notificationLoading({
        message: 'Выполняется перерасчет',
        key: 'recalculation'
      });
      setData(await fetchRequest.put(`/factfuelconsumption/${data.id}`));
      notification.success({
        message: 'Перерасчёт выполнен',
        duration: 2
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      notification.close('recalculation');
    }
  };
  const sendToCognos = async () => {
    try {
      notificationLoading({
        message: 'Выполняется отправка',
        key: 'sending'
      });
      const result = await fetchRequest.put(
        `/factfuelconsumption/exportToCognos/${data.id}`
      );
      notification.success({
        message: 'Отправлено',
        description: result,
        duration: 6
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      notification.close('sending');
    }
  };

  return (
    <Section>
      <SectionTitle
        divider
        suffix={
          data.cognosSendStatus !== cognosSendingStatusEnum.send && (
            <>
              <Button
                type="primary"
                onClick={recalculation}
                style={{ marginRight: '10px' }}
              >
                Перерасчет
              </Button>
              <SendButton
                recalculation={recalculation}
                sendToCognos={sendToCognos}
              />
            </>
          )
        }
      >
        <Title>Филиал: {data.orgUnitName}</Title>
        <Title>
          Дата отправки:{' '}
          {!!data.cognosSendDate
            ? moment(data.cognosSendDate).format('DD.MM.YYYY')
            : '-'}
        </Title>
        <Title>Статус: {cognosSendingStatuses[data.cognosSendStatus]}</Title>
        <Title>
          Период:{' '}
          {`${monthsNamesTranslitEnum[monthsNumbersEnum[data.month]]} ${
            data.year
          }г.`}
        </Title>
      </SectionTitle>
      <Content>
        <AntTable
          bordered
          data={data.factFuelConsumptions}
          columns={columns}
          pagination={false}
          style={{ borderBottom: '1px solid #e8e8e8' }}
        />
      </Content>
    </Section>
  );
};

export default memo(InnerTable);
