// @flow
import React, { useState } from 'react';

import type {
  ContractVehicle,
  ContractVehicleType,
  Vehicle
} from '../../../../../../../lib/types';
import {
  vehicleTypes,
  contractVehicleType,
  vehicleStatuses
} from '../../../../../../../lib/enum';
import { formatDateTimeToString } from '../../../../../../../lib/helpers';
import { Table } from './../../../../../../../components/ui';
import Filter from './FilterVehicleList';

type Props = {
  data: ContractVehicle[],
  onSelect?: Function,
  onSelectAll?: Function,
  selectedRowKeys?: number[],
  loading?: boolean
};

const COLUMNS = [
  {
    title: 'Тип ТС',
    width: 200,
    dataIndex: 'vehicle',
    key: 'modelType',
    render: (vehicle: Vehicle, record: ContractVehicle) => {
      if (vehicle) {
        return vehicleTypes[vehicle.vehicleModel.type];
      } else {
        return record.vehicleTypeTariff;
      }
    }
  },
  {
    title: 'Maрка и модель',
    width: 150,
    dataIndex: 'vehicle',
    key: 'model',
    render: (vehicle: Vehicle) => {
      if (vehicle) {
        return `${vehicle.vehicleModel.brandName} ${vehicle.vehicleModel.name}`;
      }
    }
  },
  {
    title: 'Госномер',
    width: 150,
    dataIndex: 'vehicle',
    key: 'licensePlate',
    render: (vehicle: Vehicle) => {
      if (vehicle) {
        return vehicle.licensePlate;
      }
    }
  },
  {
    title: 'Год выпуска',
    width: 100,
    dataIndex: 'vehicle',
    key: 'yearIssued',
    render: (vehicle: Vehicle, record: ContractVehicle) => {
      if (vehicle) {
        return vehicle.yearIssued;
      } else {
        return record.yearIssued;
      }
    }
  },
  {
    title: 'Тип НТС',
    width: 150,
    dataIndex: 'type',
    render: (type: ContractVehicleType) => contractVehicleType[type]
  },
  {
    title: 'Дата начала',
    width: 150,
    dataIndex: 'startDateStatus',
    render: (startDateStatus: string) =>
      formatDateTimeToString(startDateStatus, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    width: 150,
    dataIndex: 'endDateStatus',
    render: (endDateStatus: string) =>
      formatDateTimeToString(endDateStatus, 'DD.MM.YYYY')
  },
  {
    title: 'Текущий статус',
    width: 100,
    dataIndex: 'vehicle.status',
    render: status => status && vehicleStatuses[status]
  }
];

export default (props: Props) => {
  const { data, loading, selectedRowKeys, onSelect, onSelectAll } = props;
  const [filter, setFilter] = useState({});
  const rowSelection =
    onSelect && onSelectAll
      ? {
          selectedRowKeys,
          onSelect,
          onSelectAll
        }
      : undefined;
  return (
    <div style={{ overflow: 'auto' }}>
      {rowSelection === undefined && (
        <Filter
          addition={false}
          filter={filter}
          applyFilter={(filter: any) => {
            setFilter(filter);
          }}
          cleanFilter={() => {
            setFilter({});
          }}
        />
      )}
      <Table
        style={{ minWidth: '1490px' }}
        rowSelection={rowSelection}
        columns={COLUMNS}
        data={data
          .filter(item => {
            return filter['vehicle.vehicleModel.id']
              ? item.vehicle.vehicleModelId ===
                  filter['vehicle.vehicleModel.id']
              : true;
          })
          .filter(item => {
            return filter['vehicle.id']
              ? item.vehicle.id === filter['vehicle.id']
              : true;
          })
          .filter(item => {
            return filter['vehicle.yearIssued']
              ? item.vehicle.yearIssued === filter['vehicle.yearIssued']
              : true;
          })}
        loading={loading}
        scroll={{ y: 240 }}
      />
      <div style={{ margin: '10px 0px' }}>
        {rowSelection
          ? `Выбрано ТС: ${selectedRowKeys?.length ?? 0}`
          : `Итого ТС: ${data.length ?? 0}`}
      </div>
    </div>
  );
};
