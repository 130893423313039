// @flow
import React from 'react';

import qs from 'query-string';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';

import { maintenanceOperationPriceApi } from '../../lib/api';
import {
  formatDateTimeToString,
  getListInitialState,
  navigate
} from './../../lib/helpers';
import type {
  ListState,
  MaintenanceOperationPrice,
  UserAccess
} from '../../lib/types';
import { Icon } from '../../components/ui';
import Table from '../../components/ui/Table';
import { Header, Section } from '../../components/layout';
import { notificationLoading } from './../../components/Notifications';

import type { MaintenanceOperationPriceFilterParams } from './components/Filter';
import Filter from './components/Filter';

import { withUserAccess } from './../withUserAccess';
import canAddMaintenanceOperationPriceAccess from './accessRight';
import { workHourPriceTypes } from '../../lib/enum';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<MaintenanceOperationPrice> & {
  filter: MaintenanceOperationPriceFilterParams
};

export class MaintenanceOperationPriceList extends React.Component<
  Props,
  State
> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Стоимость н/ч',
      dataIndex: 'workingHourCost'
    },
    {
      title: 'Дата начала',
      dataIndex: 'startDate',
      render: (startDate: string) =>
        `${formatDateTimeToString(startDate, 'DD.MM.YYYY')}`
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endDate',
      render: (endDate: string) =>
        `${formatDateTimeToString(endDate, 'DD.MM.YYYY')}`
    },
    {
      title: 'Вид нормо-часа',
      dataIndex: 'workingHourPriceType',
      render: (workingHourPriceType: string) =>
        workHourPriceTypes[workingHourPriceType]
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access =>
      canAddMaintenanceOperationPriceAccess.includes(access)
    );

  componentDidMount = async () => {
    const { page, ...filter } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteMaintenanceOperationPrice(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        )
      });
    }
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchMaintenanceOperationPrice(page)
    );
  };

  deleteMaintenanceOperationPrice = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await maintenanceOperationPriceApi.deleteMaintenanceOperationPrice(id);
      await this.fetchMaintenanceOperationPrice(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchMaintenanceOperationPrice = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await maintenanceOperationPriceApi.fetchMaintenanceOperationPrice(
      {
        ...filter,
        page
      }
    );
    this.setState({ ...data, page, loading: false });
  };

  applyFilter = (filter: MaintenanceOperationPriceFilterParams) =>
    this.setState({ filter }, () =>
      this.fetchMaintenanceOperationPrice(this.state.page)
    );

  cleanFilter = () =>
    this.setState({ filter: {} }, () =>
      this.fetchMaintenanceOperationPrice(this.state.page)
    );

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Прейскурант стоимости нормо-часа</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() =>
                  navigate('/admin/maintenance-operation-price/new')
                }
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchMaintenanceOperationPrice}
            onRow={(row: MaintenanceOperationPrice) => ({
              onClick: () =>
                canAdd
                  ? navigate(
                      `/admin/maintenance-operation-price/${row.id}/edit`
                    )
                  : navigate(`/admin/maintenance-operation-price/${row.id}`)
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceOperationPriceList);
