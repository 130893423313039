// @flow
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Datepicker from 'antd/lib/date-picker';
import Button from 'antd/lib/button/button';

import {formatDateTimeToISOString} from './../../../../lib/helpers';

import {Filter, Selects} from './../../../../components';
import type {FilterChildrenParams} from './../../../../components/hoc/Filter';
import Spoiler from '../../../../components/ui/Spoiler';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const {
  VehicleSelect,
  BrandsSelect,
  MaintenanceTypeOfWorkSelect,
  VehicleTypeSelect
} = Selects;
const { RangePicker } = Datepicker;

export type ScheduleMaintenanceFilter = {
  orgUnitId?: number,
  vehicleId?: number,
  brandId?: number,
  workType?: string,
  startDate?: string,
  endDate?: string,
  vehicleType?: string
};
type Props = {
  filter: any,
  onExport: Function,
  cleanFilter: Function,
  applyFilter: Function
};

/**
 * Фильтр с параметрами для отчета о таксировках
 */
export default ({ filter, onExport, cleanFilter, applyFilter }: Props) => (
  <Spoiler defaultExpanded label="Параметры">
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<ScheduleMaintenanceFilter>) => (
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
            <GridItem>
              <Field label="Подразделение">
                <OrgUnitSelect
                  onlyBranches
                  value={values.orgUnitId}
                  onChange={value => changeValue('orgUnitId', value)}
                  isFilter={true}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип ТС">
                <VehicleTypeSelect
                  value={values.vehicleType}
                  onChange={value => changeValue('vehicleType', value)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Транспортное средство">
                <VehicleSelect
                  value={values.vehicleId}
                  onChange={value => changeValue('vehicleId', value)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Марка ТС">
                <BrandsSelect
                  value={values.brandId}
                  onChange={value => changeValue('brandId', value)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип работ">
                <MaintenanceTypeOfWorkSelect
                  value={values.workType}
                  onChange={value => changeValue('workType', value)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Отчетный период">
                <RangePicker
                  format="DD.MM.YYYY HH:mm"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </GridItem>

            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={applyFilter}>
                Применить
              </Button>
              <Button type="secondary" onClick={cleanFilter}>
                Очистить
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      )}
    </Filter>
  </Spoiler>
);
