/* TripSplittingModal.js | Декомпозиция ПЛ НТС */
import './../../../../assets/styles/modal.css';
import React from 'react';
import Modal from 'antd/lib/modal';
import { Grid, GridItem } from '../../../../components/layout';
import { Table, Button, Input, InputNumber } from 'antd';
import type { ContractTrip } from '../../../../lib/types/contractTrips';
import notification from 'antd/lib/notification';
import { notificationLoading } from '../../../../components/Notifications';
import { ContractTripApi } from '../../../../lib/api';
import AntIcon from 'antd/lib/icon';
import styled from 'styled-components';
import Tooltip from "antd/lib/tooltip";
import { showConfirmation, formatNumber } from "../../../../lib/helpers";

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  font-weight: bold;
`;

type Props = {
  visible: boolean,
  onClose: () => void,
  updateTripList: Function,
  openEditModal: (parentTrip: ContractTrip) => void,
};

type State = {
  isPlNtsExpanded: boolean,
  isSplittedTripExpanded: boolean,
  isSummaryTripExpanded: boolean,
  isLoadComplete: boolean,
  editingKey: string | null; // Ключ для отслеживания редактируемой строки
  editedRows: { [key: string]: any }; // Объект для хранения измененных строк
  parentTrip: ContractTrip, // Родительский ПЛ НТС.
  parentTrips: ContractTrip[], // Массив ПЛ НТС.
  splitTrips: ContractTrip[], // Декомпозированные ПЛ НТС.
  summaryTrip: ContractTrip, // Свод декомпозированных ПЛ НТС.
  summaryTrips: ContractTrip[], // Массив свода декомпозированных ПЛ НТС.
  isLockedForAddTrip: boolean, // Заблокировано для добавления ПЛ НТС.
};

class TripSplittingModal extends React.Component<Props, State> {
  state: State = {
    isParentTripExpanded: false,
    isSplittedTripExpanded: false,
    isSummaryTripExpandedisSummaryTripExpanded: false,
    isLoadComplete: false,
    parentTrips: [],
    splitTrips: [],
    splitNumber: 0,
    summaryTrip: null,
    summaryTrips: [],
    editingKey: null,
    editedRows: {},
    isLockedForAddTrip: false,
  };

  renderInputNumber = (dataIndex: string) => {
    return (value, record) => (
      <InputNumber
        className="table-trip-cell"
        value={value}
        min={0}
        onChange={val => this.handleCellValueChange(record.number, dataIndex, val)}
      />
    );
  };

  // Общие основные колонки таблиц.
  columnsSharedBase = [
    {
      title: (<span><Tooltip className="table-trip-header" title="Время в движении, ч.">Время в<br/>движении</Tooltip></span>),
      align: 'center',
      dataIndex: 'movementTime',
      key: 'movementTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header" title="Время ожидания, ч.">Время<br/>ожидания</Tooltip></span>),
      align: 'center',
      dataIndex: 'waitingTime',
      key: 'waitingTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время простоя с включенным двигателем, ч.">Время простоя<br/>с вкл. двиг.</Tooltip></span>),
      align: 'center',
      dataIndex: 'waitingWithRunningEngineTime',
      key: 'waitingWithRunningEngineTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время отопителя, ч.">Время<br/>отопителя</Tooltip></span>),
      align: 'center',
      dataIndex: 'heaterTime',
      key: 'heaterTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время подогревания, ч.">Время<br/>подогрев.</Tooltip></span>),
      align: 'center',
      dataIndex: 'preheaterTime',
      key: 'preheaterTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время силовой генератор, ч.">Время<br/>сил. генер.</Tooltip></span>),
      align: 'center',
      dataIndex: 'powerGeneratorTime',
      key: 'powerGeneratorTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время лебедка, ч.">Время<br/>лебедка</Tooltip></span>),
      align: 'center',
      dataIndex: 'winchTime',
      key: 'winchTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Пробег, км.">Пробег</Tooltip></span>),
      align: 'center',
      dataIndex: 'distance',
      key: 'distance',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Пробег с прицепом, км.">Пробег<br/>с прицепом</Tooltip></span>),
      align: 'center',
      dataIndex: 'distanceWithTrailer',
      key: 'distanceWithTrailer',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
  ];
  // Общие финальные колонки таблиц.
  columnsSharedFinal = [
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время работы всего, ч.">Время<br/>раб. всего</Tooltip></span>),
      align: 'center',
      dataIndex: 'totalWorkTime',
      key: 'totalWorkTime',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Сумма без НДС, руб.">Сумма<br/>без НДС</Tooltip></span>),
      align: 'center',
      dataIndex: 'sum',
      key: 'sum',
      ellipsis: true,
      width: "10%",
      render: (value) => { return ( <Input className="table-trip-cell disabled" readOnly={true} value={value} /> ); },
    },
  ];
  // Колонки таблицы "ПЛ НТС".
  columnsOriginalTrips = [
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Государственный номер">Гос.<br/>номер</Tooltip></span>),
      align: 'center',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      ellipsis: true,
      width: "13%",
      render: (value, record) => {
        return (
          <Input className="table-trip-cell disabled" readOnly={true}
                 value={value}
                 onChange={(e) => this.handleCellValueChange(record.number, 'licensePlate', e.target.value)}
          />
        );
      },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Номер ПЛ НТС">№<br />ПЛ НТС</Tooltip></span>),
      align: 'center',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: "10%",
      render: (value, record) => {
        return (
          <Input className="table-trip-cell disabled" readOnly={true}
                 value={value}
                 onChange={(e) => this.handleCellValueChange(record.number, 'number', e.target.value)}
          />
        );
      },
    },
    ...this.columnsSharedBase,
    ...this.columnsSharedFinal
  ];
  // Колонки таблицы "Декомпозированные ПЛ НТС".
  columnsSplitTrips = [
    {
      title: (<span><Tooltip title="Действия"></Tooltip></span>),
      align: 'center',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: "10%",
      render: (text, record, index) => {
        const isLastRow = index === this.splitTrips.length - 1;
        return (
          <span>
            {isLastRow && !this.state.isLockedForAddTrip && (
              <StyledAntIcon className="table-trip-icon"
                             onClick={() => this.addSplitTrip()}
                             type="plus"
                             size={16}
                             title="Добавить декомпозированный ПЛ НТС"
              />
            )}
            {/*{!isLastRow && !this.state.isLockedForAddTrip && (*/}
            {/*  <StyledAntIcon className="table-trip-icon"*/}
            {/*                 onClick={() => this.removeSplitTrip()}*/}
            {/*                 type="minus"*/}
            {/*                 size={16}*/}
            {/*                 title="Удалить декомпозированный ПЛ НТС"*/}
            {/*  />*/}
            {/*)}*/}
            </span>
        );
      },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Номер ПЛ НТС">№<br />ПЛ НТС</Tooltip></span>),
      align: 'center',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: "10%",
      render: (value, record) => {
        return (
          <Input className="table-trip-cell"
                 value={value}
                 onChange={(e) => this.handleCellValueChange(record.number, 'number', e.target.value)}
          />
        );
      },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время в движении, ч.">Время в<br/>движении</Tooltip></span>),
      align: 'center',
      dataIndex: 'movementTime',
      key: 'movementTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('movementTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время ожидания, ч.">Время<br/>ожидания</Tooltip></span>),
      align: 'center',
      dataIndex: 'waitingTime',
      key: 'waitingTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('waitingTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время простоя с включенным двигателем, ч.">Время простоя<br/>с вкл. двиг.</Tooltip></span>),
      align: 'center',
      dataIndex: 'waitingWithRunningEngineTime',
      key: 'waitingWithRunningEngineTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('waitingWithRunningEngineTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время отопителя, ч.">Время<br/>отопителя</Tooltip></span>),
      align: 'center',
      dataIndex: 'heaterTime',
      key: 'heaterTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('heaterTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время подогревания, ч.">Время<br/>подогрев.</Tooltip></span>),
      align: 'center',
      dataIndex: 'preheaterTime',
      key: 'preheaterTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('preheaterTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время силовой генератор, ч.">Время<br/>сил. генер.</Tooltip></span>),
      align: 'center',
      dataIndex: 'powerGeneratorTime',
      key: 'powerGeneratorTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('powerGeneratorTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время лебедка, ч.">Время<br/>лебедка</Tooltip></span>),
      align: 'center',
      dataIndex: 'winchTime',
      key: 'winchTime',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('winchTime'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Пробег, км.">Пробег</Tooltip></span>),
      align: 'center',
      dataIndex: 'distance',
      key: 'distance',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('distance'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Пробег с прицепом, км.">Пробег<br/>с прицепом</Tooltip></span>),
      align: 'center',
      dataIndex: 'distanceWithTrailer',
      key: 'distanceWithTrailer',
      ellipsis: true,
      width: "10%",
      render: this.renderInputNumber('distanceWithTrailer'),
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Время работы всего, ч.">Время<br/>раб. всего</Tooltip></span>),
      align: 'center',
      dataIndex: 'totalWorkTime',
      key: 'totalWorkTime',
      ellipsis: true,
      width: "10%",
      render: (value, record) => {
        return (
          <Input className="table-trip-cell disabled" readOnly={true}
                 value={value}
                 onChange={(e) => this.handleCellValueChange(record.number, 'totalWorkTime', e.target.value)}
          />
        );
      },
    },
    {
      title: (<span><Tooltip className="table-trip-header"
                             title="Сумма без НДС, руб.">Сумма<br/>без НДС</Tooltip></span>),
      align: 'center',
      dataIndex: 'sum',
      key: 'sum',
      ellipsis: true,
      width: "10%",
      render: (value, record) => {
        return (
          <Input className="table-trip-cell disabled" readOnly={true}
                 value={value}
                 onChange={(e) => this.handleCellValueChange(record.number, 'sum', e.target.value)}
          />
        );
      },
    },
  ];
  // Колонки таблицы "Свод декомпозированных ПЛ НТС".
  columnsSummaryTrips = [
    {
      title: (<span><Tooltip className="table-trip-header"></Tooltip></span>),
      align: 'center',
      dataIndex: 'licensePlate',
      key: 'licensePlate',
      ellipsis: true,
      width: "10%",
      render: () => { return (<label className="table-trip-cell disabled" content='' /> ); },
    },
    {
      title: (<span><Tooltip className="table-trip-header"></Tooltip></span>),
      align: 'center',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
      width: "10%",
      render: () => { return (<label className="table-trip-cell disabled" content='' /> ); },
    },
    ...this.columnsSharedBase,
    ...this.columnsSharedFinal
  ];

  // Жизненный цикл.
  componentDidMount() {
    this.resetSplitTrips();
    const { parentTrip } = this.state;
    this.setState( { parentTrips: [ parentTrip ] });

    let summaryTrip = { ...parentTrip };
    this.setState({ summaryTrip: summaryTrip });

    // Обновить массив свода декомпозированных ПЛ НТС.
    this.setState( { summaryTrips: [ summaryTrip ] });
  }

  // Фолдинг блока "ПЛ НТС".
  foldParentTrip = async () => {
    this.setState((prevState) => ({ isParentTripExpanded: !prevState.isParentTripExpanded }));
  };
  // Фолдинг блока "Декомпозированные ПЛ НТС".
  foldSplittedTrip = async () => {
    this.setState((prevState) => ({ isSplittedTripExpanded: !prevState.isSplittedTripExpanded }));
  };
  // Фолдинг блока "Свод декомпозированных ПЛ НТС".
  foldSummaryTrip = async () => {
    this.setState((prevState) => ({ isSummaryTripExpanded: !prevState.isSummaryTripExpanded }));
  };

  // Сохранить декомпозицию ПЛ НТС.
  onSubmit = async () => {
    const { onClose, updateTripList } = this.props;
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    const { splitTrips } = this.state;
    if (splitTrips === undefined || splitTrips === null) return;
    try {
      notificationLoading({ message: 'Декомпозиция ПЛ НТС', key: 'splitting' });
      // API.
      await ContractTripApi.splitTrip(parentTrip.id, this.splitTrips);
      notification.success({ message: 'ПЛ НТС успешно декомпозирован' })
      onClose();
      updateTripList();
    } catch (e) {
      notification.error({ message: 'Не удалось декомпозировать ПЛ НТС', description: e.message || e.title });
      console.error('Не удалось декомпозировать ПЛ НТС | ', e);
    } finally {
      notification.close('splitting');
    }
  };

  // Декомпозировать ПЛ НТС.
  onSubmitConfirmation = async () => {
    // Проверить свод декомпозированных ПЛ НТС и исходный ПЛ НТС.
    if (!this.checkSummaryTrip()) return;
    showConfirmation('Сохранить декомпозицию ПЛ НТС', this.onSubmit);
  }

  // Объединить декомпозированные ПЛ НТС.
  onMergeTrip = async () => {
    const { onClose, updateTripList } = this.props;
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    try {
      notificationLoading({ message: 'Объединение декомпозированных ПЛ НТС', key: 'merging' });
      // API.
      await ContractTripApi.mergeTrip(parentTrip.id, this.splitTrips);
      notification.success({ message: 'Декомпозированные ПЛ НТС успешно объединены' })
      onClose();
      updateTripList();
      // openEditModal(parentTrip);
    } catch (e) {
      notification.error({ message: 'Не удалось объединить декомпозированные ПЛ НТС', description: e.message || e.title });
      console.error('Не удалось объединить декомпозированные ПЛ НТС | ', e);
    } finally {
      notification.close('merging');
    }
  };

  // Объединить декомпозированные ПЛ НТС.
  onMergeTripConfirmation = () => {
    const { splitTrips } = this.state;
    if (splitTrips === undefined || splitTrips === null) return;
    notificationLoading({ message: 'Объединение декомпозированных ПЛ НТС', key: 'merging' });
    try {
      if (splitTrips.length < 1 || this.splitNumber < 1) {
        notification.warn({ message: 'Кол-во декомпозированных ПЛ НТС должно быть более 0' });
        return;
      }
      let numbersTrips = '';
      for (const splitTrip of splitTrips) {
        numbersTrips += '№ ' + splitTrip.number + ' и ';
      }
      numbersTrips = numbersTrips.substring(0, numbersTrips.length - 3);
      showConfirmation('Вы действительно хотите объединить ПЛ НТС ' + numbersTrips + '?', this.onMergeTrip);
    } catch (e) {
      notification.error({ message: 'Не удалось сопоставить свод декомпозированных ПЛ НТС', description: e.message || e.title });
      console.error('Не удалось сопоставить свод декомпозированных ПЛ НТС | ', e);
    } finally {
      notification.close('merging');
    }
  }

  // Проверить свод декомпозированных ПЛ НТС и исходный ПЛ НТС.
  checkSummaryTrip() {
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    const { summaryTrip } = this.state;
    if (summaryTrip === undefined || summaryTrip === null) return;
    notificationLoading({ message: 'Проверка свода декомпозированных ПЛ НТС', key: 'checking' });
    try {
      const fields = [ 'number', 'licensePlate',
        'movementTime', 'waitingTime', 'waitingWithRunningEngineTime', 'heaterTime', 'preheaterTime',
        'powerGeneratorTime', 'winchTime', 'distance', 'distanceWithTrailer', 'totalWorkTime', 'sum' ];
      for (const field of fields) {
        if (summaryTrip[field] !== parentTrip[field]) return this.checkSummaryTripReturnFalse(field);
      }
    } catch (e) {
      notification.error({ message: 'Не удалось проверить свод декомпозированных ПЛ НТС', description: e.message || e.title });
      console.error('Не удалось проверить свод декомпозированных ПЛ НТС | ', e);
    } finally {
      notification.close('checking');
    }
    return true;
  }

  // Сообщение об ошибке при проверке свода декомпозированных ПЛ НТС.
  checkSummaryTripReturnFalse(field) {
    notification.warning({ message: 'Итоговые значения декомпозированных ПЛ НТС отличны от значений первоначального ПЛ НТС»'});
    console.warn('Итоговые значения декомпозированных ПЛ НТС отличны от значений первоначального ПЛ НТС». Поле: ', field);
    return false;
  }

  // Сброс декомпозированных ПЛ НТС.
  resetSplitTrips = () => {
    this.splitTrips = [];
    this.splitNumber = 0;
  };

  // Определить родительский ПЛ НТС.
  setupParentTrip = async (trip: ContractTrip) => {
    if (trip === undefined || trip === null) return trip;
    if (trip.parentId === 0 || !trip.parentId) {
      this.setState({ parentTrip: trip});
      return trip;
    }
    // API.
    const parentTrip= await ContractTripApi.getTrip(trip.parentId);
    // Иначе взять родительский ПЛ НТС.
    this.setState({ parentTrip: parentTrip});
    return parentTrip;
  }

  // Загрузить декомпозированные ПЛ НТС.
  loadSplitTrips = async (trip: ContractTrip) => {
    this.setState({ isLoadComplete: false });
    this.setState({ isLockedForAddTrip: false });
    // Определить родительский ПЛ НТС.
    trip = await this.setupParentTrip(trip);
    // Загрузить массив для родительского ПЛ НТС.
    this.setState( { parentTrips: [ trip ] });
    // API.
    const getTrips = await ContractTripApi.getSplitTrips(trip.id);
    if (getTrips && Array.isArray(getTrips) && getTrips.length > 0) {
      // Обновить массив декомпозированных ПЛ НТС.
      this.splitTrips = [];
      for (const getTrip of getTrips) {
        this.splitTrips = [...this.splitTrips, getTrip];
      }
      this.setState({ splitTrips: this.splitTrips });
      // Обновить последний номер.
      this.splitNumber = this.splitTrips.length;
      // Рассчитать свод декомпозированных ПЛ НТС.
      await this.recalculateSummaryTrip();
      // Заблокировать добавление ПЛ НТС.
      this.setState({isLockedForAddTrip: true});
    } else {
      await this.addSplitTrip(true);
    }
    this.setState({ isLoadComplete: true });

    // Фолдлинг.
    const { isParentTripExpanded, isSplittedTripExpanded, isSummaryTripExpanded } = this.state;
    if (!isParentTripExpanded)
      await this.foldParentTrip();
    if (!isSplittedTripExpanded)
      await this.foldSplittedTrip();
    if (!isSummaryTripExpanded)
      await this.foldSummaryTrip();
  }

  // Удалить декомпозированный ПЛ НТС.
  // removeSplitTrip = async (isReset = false) => {
  //   console.log('removeSplitTrip | ');
  // }

  // Добавить декомпозированный ПЛ НТС.
  addSplitTrip = async (isReset = false) => {
    if (isReset) {
      this.resetSplitTrips();
    }
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    this.setState( { parentTrips: [ parentTrip ] });
    this.splitNumber = this.splitNumber + 1;
    // Создать новый декомпозированный ПЛ НТС.
    const newTrip = {
      number: `${parentTrip.number}.${this.splitNumber}`, // № Декомпозированного ПЛ НТС
      licensePlate: parentTrip.licensePlate, // Гос. номер
      movementTime: 0, // Расчетное время в движении, ч.
      movementPrice: this.movementPrice, // Тариф в движении, руб
      waitingTime: 0, // Время ожидания, ч.
      waitingPrice: this.waitingPrice, // Тариф ожидания, руб
      waitingWithRunningEngineTime: 0, // Время простоя с включенным двигателем, ч.
      waitingWithRunningEnginePrice: this.waitingWithRunningEnginePrice, // Тариф ожидания с вкл. двигателем, руб
      heaterTime: 0, // Время отопителя, ч.
      heaterPrice: this.heaterPrice, // Тариф отопителя, руб
      preheaterTime: 0, // Время подогревания, ч.
      preheaterPrice: this.preheaterPrice, // Тариф подогревателя, руб
      powerGeneratorTime: 0, // Время силовой генератор, ч.
      powerGeneratorPrice: this.powerGeneratorPrice, // Тариф силовой генератор
      winchTime: 0, // Время лебедка, ч.
      winchPrice: this.winchPrice, // Тариф  лебедка
      distance: 0, // Пробег, км.
      distancePrice: this.distancePrice, // Тариф за км
      distanceWithTrailer: 0, // Пробег с прицепом, км.
      distanceWithTrailerPrice: this.distanceWithTrailerPrice, // Тариф за км с прицепом
    };
    // Добавить в массив декомпозированных ПЛ НТС.
    this.splitTrips = [...this.splitTrips, newTrip];
    // Рассчитать значения для всех ПЛ НТС.
    await this.recalculateSplitTrips();
    // Рассчитать итоги значения для всех ПЛ НТС.
    await this.recalculateSplitTripsFinal();
    // Пересчитать последний декомпозированный ПЛ НТС.
    await this.recalculateSplitTripsLastRow();
    // Рассчитать свод декомпозированных ПЛ НТС.
    await this.recalculateSummaryTrip();
  };

  // Рассчитать значения для всех ПЛ НТС.
  recalculateSplitTrips = async () => {
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    // Расчётные значения.
    let movementTime = parentTrip.movementTime / this.splitNumber; // Расчетное время в движении, ч.
    let waitingTime = parentTrip.waitingTime / this.splitNumber; // Время ожидания, ч.
    let waitingWithRunningEngineTime = parentTrip.waitingWithRunningEngineTime / this.splitNumber; // Время простоя с включенным двигателем, ч.
    let heaterTime = parentTrip.heaterTime / this.splitNumber; // Время отопителя, ч.
    let preheaterTime = parentTrip.preheaterTime / this.splitNumber; // Время подогревания, ч.
    let powerGeneratorTime = parentTrip.powerGeneratorTime / this.splitNumber; // Время силовой генератор, ч.
    let winchTime = parentTrip.winchTime / this.splitNumber; // Время лебедка, ч.
    let distance = parentTrip.distance / this.splitNumber; // Пробег, км.
    let distanceWithTrailer = parentTrip.distanceWithTrailer / this.splitNumber; // Пробег с прицепом, км.
    // Перебор всех ПЛ НТС.
    for (const splitTrip of this.splitTrips) {
      splitTrip.movementTime = Number(movementTime.toFixed(2)); // Расчетное время в движении, ч.
      splitTrip.movementPrice = parentTrip.movementPrice;
      splitTrip.waitingTime = Number(waitingTime.toFixed(2)); // Время ожидания, ч.
      splitTrip.waitingPrice = parentTrip.waitingPrice;
      splitTrip.waitingWithRunningEngineTime = Number(waitingWithRunningEngineTime.toFixed(2)); // Время простоя с включенным двигателем, ч.
      splitTrip.waitingWithRunningEnginePrice = parentTrip.waitingWithRunningEnginePrice;
      splitTrip.heaterTime = Number(heaterTime.toFixed(2)); // Время отопителя, ч.
      splitTrip.heaterPrice = parentTrip.heaterPrice;
      splitTrip.preheaterTime = Number(preheaterTime.toFixed(2)); // Время подогревания, ч.
      splitTrip.preheaterPrice = parentTrip.preheaterPrice;
      splitTrip.powerGeneratorTime = Number(powerGeneratorTime.toFixed(2)); // Время силовой генератор, ч.
      splitTrip.powerGeneratorPrice = parentTrip.powerGeneratorPrice;
      splitTrip.winchTime = Number(winchTime.toFixed(2)); // Время лебедка, ч.
      splitTrip.winchPrice = parentTrip.winchPrice;
      splitTrip.distance = Number(distance.toFixed(2)); // Пробег, км.
      splitTrip.distancePrice = parentTrip.distancePrice;
      splitTrip.distanceWithTrailer = Number(distanceWithTrailer.toFixed(2)); // Пробег с прицепом, км.
      splitTrip.distanceWithTrailerPrice = parentTrip.distanceWithTrailerPrice;
    }
  }

  // Рассчитать итоги значения для всех ПЛ НТС.
  recalculateSplitTripsFinal = async (recordKey) => {
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    // Расчётные значения.
    let totalWorkTime = parentTrip.totalWorkTime / this.splitNumber; // Время работы всего, ч.
    let sum = parentTrip.sum  / this.splitNumber; // Сумма без НДС, руб.
    // Перебор всех ПЛ НТС.
    for (const splitTrip of this.splitTrips)
    {
      // Всем итоги одинаковые.
      if (!recordKey)
      {
        splitTrip.totalWorkTime = Number(totalWorkTime.toFixed(2)); // Время работы всего, ч.
        splitTrip.sum = Number(sum.toFixed(2)); // Сумма без НДС, руб.
        // Индивидуальные итоги.
      } else if (splitTrip.number === recordKey) {
        // Итоги.
        splitTrip.totalWorkTime = Number((Number(splitTrip.movementTime) + Number(splitTrip.waitingTime)).toFixed(2)); // Время работы всего, ч.
        let sum =
          (splitTrip.movementTime * splitTrip.movementPrice) +
          (splitTrip.waitingTime * splitTrip.waitingPrice) +
          (splitTrip.waitingWithRunningEngineTime * splitTrip.waitingWithRunningEnginePrice) +
          (splitTrip.heaterTime * splitTrip.heaterPrice) +
          (splitTrip.preheaterTime * splitTrip.preheaterPrice) +
          (splitTrip.powerGeneratorTime * splitTrip.powerGeneratorPrice) +
          (splitTrip.winchTime * splitTrip.winchPrice) +
          (splitTrip.distance * splitTrip.distancePrice) +
          (splitTrip.distanceWithTrailer * splitTrip.distanceWithTrailerPrice)
        ;
        splitTrip.sum = Number((Math.round(sum * 100) / 100).toFixed(2)); // Сумма без НДС, руб.
      }
    }
    // Обновить массив декомпозированных ПЛ НТС.
    this.setState({ splitTrips: this.splitTrips });
  }

  // Пересчитать последний декомпозированный ПЛ НТС.
  recalculateSplitTripsLastRow = async () => {
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    if (this.splitTrips.length > 1) {
      const numericFields = [ 'movementTime', 'waitingTime', 'waitingWithRunningEngineTime', 'heaterTime',
        'preheaterTime', 'powerGeneratorTime', 'winchTime', 'distance', 'distanceWithTrailer', 'totalWorkTime', 'sum' ];
      // Пересчитать поле в последней строке.
      for (const field of numericFields)
        this.recalculateSplitTripsLastRowField(parentTrip, field);
    }
  }

  // Пересчитать поле в последней строке.
  recalculateSplitTripsLastRowField = (parentTrip, field) => {
    let summmary = 0;
    let previous = 0;
    let i = 0;
    for (const splitTrip of this.splitTrips) {
      summmary += splitTrip[field];
      if (i < this.splitTrips.length - 1)
      {
        previous += splitTrip[field];
        i += 1;
      }
    }
    if (parentTrip[field] !== summmary)
      this.splitTrips[this.splitTrips.length - 1][field] = Number((parentTrip[field] - previous).toFixed(2));
  }

  // Привести дробное число в правильный вид.
  getFormatNumberForField(field, value) {
    switch (field) {
      case "movementTime":
      case "waitingTime":
      case "waitingWithRunningEngineTime":
      case "heaterTime":
      case "preheaterTime":
      case "powerGeneratorTime":
      case "winchTime":
      case "distance":
      case "distanceWithTrailer":
        return formatNumber(value);
      default:
        return value;
    }
  }

  // Изменить элемент массива.
  handleCellValueChange = async (recordKey, dataIndex, value) => {
    // if (value !== "") {
    //   // Проверить на число.
    //   if (!isNumberExt(value)) return;
    //   console.log("handleCellValueChange ", getDefaultLocale());
    //   // Локаль.
    //   switch (getDefaultLocale()) {
    //     case "ru-RU":
    //       if (!value.endsWith(",") && !value.endsWith("0"))
    //         value = this.getFormatNumberForField(dataIndex, value);
    //       break;
    //     case "en-US":
    //     default:
    //       if (!value.endsWith(".") && !value.endsWith("0"))
    //         value = this.getFormatNumberForField(dataIndex, value);
    //       break;
    //   }
    // } else {
    //   value = 0;
    // }

    this.setState((prevState) => ({
      editedRows: {
        ...prevState.editedRows,
        [recordKey]: {
          ...prevState.editedRows[recordKey],
          [dataIndex]: value,
        },
      },
    }));

    const numValue = value ? parseFloat(value) : 0;
    if (isNaN(numValue)) return;

    // Создать копию массива декомпозированных ПЛ НТС.
    const updatedTrips = [...this.splitTrips];
    const index = updatedTrips.findIndex((item) => item.number === recordKey);
    // Обновить элемент массива.
    if (index !== -1) {
      updatedTrips[index] = { ...updatedTrips[index], [dataIndex]: numValue };
    }
    // Обновить массив декомпозированных ПЛ НТС.
    this.splitTrips = updatedTrips;
    this.setState({ splitTrips: this.splitTrips });

    // Рассчитать итоги значения для всех ПЛ НТС.
    await this.recalculateSplitTripsFinal(recordKey);
    // Рассчитать свод декомпозированных ПЛ НТС.
    await this.recalculateSummaryTrip();
  };

  // Рассчитать свод декомпозированных ПЛ НТС.
  recalculateSummaryTrip = async () => {
    const { parentTrip } = this.state;
    if (parentTrip === undefined || parentTrip === null) return;
    const { summaryTrip } = this.state;
    if (summaryTrip === undefined || summaryTrip === null) return;
    const { splitTrips } = this.state;
    if (splitTrips === undefined || splitTrips === null) return;
    // Начальные значения.
    summaryTrip.number = parentTrip.number;
    summaryTrip.licensePlate = parentTrip.licensePlate;
    const numericFields = [
      'movementTime', 'waitingTime', 'waitingWithRunningEngineTime', 'heaterTime', 'preheaterTime', 'powerGeneratorTime',
      'winchTime', 'distance', 'distanceWithTrailer', 'totalWorkTime', 'sum' ];
    for (const field of numericFields) {
      summaryTrip[field] = 0;
    }
    // Перебор всех декомпозированных ПЛ НТС.
    for (const splitTrip of splitTrips) {
      for (const field of numericFields) {
        summaryTrip[field] += Number(splitTrip[field]);
      }
    }
    // Округлить до двух знаков.
    for (const field of [...numericFields]) {
      summaryTrip[field] = +Number((Math.round(summaryTrip[field] * 100) / 100).toFixed(2));
    }
    // Обновить состояние компонента, чтобы вызвать перерендеринг.
    this.setState({ summaryTrip: summaryTrip });
    // Обновить массив свода декомпозированных ПЛ НТС.
    this.setState( { summaryTrips: [ summaryTrip ] });
  }

  render() {
    const { isLoadComplete } = this.state;
    const { onClose, visible } = this.props;
    const { parentTrips, splitTrips, summaryTrips } = this.state;
    const { isParentTripExpanded, isSplittedTripExpanded, isSummaryTripExpanded } = this.state;
    // Результат.
    return (
      <Modal onCancel={onClose} visible={visible} destroyOnClose width="95%" title="Декомпозиция ПЛ НТС" footer={false} >
        {/* "ПЛ НТС" */}
        <section>
          <div className="modal-div" >
            <h2 className="h2-click" onClick={this.foldParentTrip}>ПЛ НТС</h2>
            {isParentTripExpanded && (
              <Table className="table-trip"
                     dataSource={isLoadComplete ? (parentTrips || []) : []}
                     columns={this.columnsOriginalTrips}
                     rowKey={Math.random()}
                     pagination={false}
                     bordered
              />
            )}
          </div>
        </section>
        {/* "Декомпозированные ПЛ НТС" */}
        <section>
          <div className="modal-div modal-div-next">
            <h2 className="h2-click" onClick={this.foldSplittedTrip}>Декомпозированные ПЛ НТС</h2>
            {isSplittedTripExpanded && (
              <Table className="table-trip"
                     dataSource={isLoadComplete ? (splitTrips || []) : []}
                     columns={this.columnsSplitTrips}
                     // rowKey={(record) => record.number}
                     rowKey={Math.random()}
                     pagination={false}
                     bordered
              />
            )}
          </div>
        </section>
        {/* "Свод декомпозированных ПЛ НТС" */}
        <section>
          <div className="modal-div modal-div-next">
            <h2 className="h2-click" onClick={this.foldSummaryTrip}>Свод декомпозированных ПЛ НТС</h2>
            {isSummaryTripExpanded && (
              <Table className="table-trip"
                     dataSource={isLoadComplete ? (summaryTrips || []) : []}
                     columns={this.columnsSummaryTrips}
                     rowKey={Math.random()}
                     pagination={false}
                     bordered
              />
            )}
        </div>
        </section>
        {/* Кнопки */}
        <div className="modal-section-buttons">
          <Grid>
            <GridItem fullWidth customStyles={{display: 'flex', justifyContent: 'space-between' }} >
              {this.state.isLockedForAddTrip && (
                <Button type="primary" onClick={this.onMergeTripConfirmation}>Объединить</Button>
              )}
              <Button type="primary" onClick={this.onSubmitConfirmation} htmlType="submit">Ок</Button>
              <Button onClick={onClose}>Отмена</Button>
            </GridItem>
          </Grid>
        </div>
      </Modal>
    );
  }
}

export default TripSplittingModal;
