// @flow
import React from 'react';
import type { VehiclePlan } from '../../../../../lib/types';
import { getLabel } from '../../Budget/components/Tab';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number,
  vehiclePlan?: VehiclePlan
};

export default ({ vehiclePlanId, vehiclePlan }: Props) => (
  <Tabs withRouter>
    <TabItem
      label={getLabel('ГСМ', [vehiclePlan?.fuelAndOilCalculationStatus])}
      url={`/budget/vehicle/${vehiclePlanId}/fuels-and-oils`}
    />
    <TabItem
      label={getLabel('Средства малой механизации', [
        vehiclePlan?.fuelAndOilMechanismCalculationStatus
      ])}
      url={`/budget/vehicle/${vehiclePlanId}/mechanisms`}
    />
    <TabItem
      label={getLabel('Автошины', [vehiclePlan?.tireCalculationStatus])}
      url={`/budget/vehicle/${vehiclePlanId}/tire`}
    />
    <TabItem
      label={getLabel('Техобслуживание', [
        vehiclePlan?.maintenanceOperationContractCalculationStatus
      ])}
      url={`/budget/vehicle/${vehiclePlanId}/maintenance`}
    />
  </Tabs>
);
