// @flow
import React from 'react';

import { TabItem, Tabs } from './../../../../components/ui';
import { entityStatusEnum } from '../../../../lib/enum';

type Props = {
  orgUnitId: number,
  id: number,
  calculationEconomyStatus?: string,
  distributionEconomyStatus?: string,
  proposalEconomyStatus?: string,
  showChildItems?: boolean,
};

export default ({
  id,
  orgUnitId,
  calculationEconomyStatus,
  distributionEconomyStatus,
  proposalEconomyStatus,
  showChildItems = true
}: Props) => {
  return (
    <Tabs
      withRouter
      type="buttons"
      size="small"
      defaultTab={`/budget/details/${id}/${orgUnitId}/primary`}
    >
      <TabItem label="АС «Транспорт»" url={`/budget/details/${id}/${orgUnitId}/primary`}>
        {showChildItems && (
          <Tabs withRouter defaultTab={`/budget/details/${id}/${orgUnitId}/primary`}>
            <TabItem
              label={'Расчет экономии'}
              key='1'
              url={`/budget/details/${id}/${orgUnitId}/primary`}
              style={{marginLeft: 0}}
            />
            {calculationEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Распределение экономии'}
                key='2'
                url={`/budget/details/${id}/${orgUnitId}/primary/distribution-economy`}
              />
            )}
            {distributionEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Предложение'}
                key='3'
                url={`/budget/details/${id}/${orgUnitId}/primary/proposal-economy`}
              />
            )}
            {proposalEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Договоры'}
                key='4'
                url={`/budget/details/${id}/${orgUnitId}/primary/contracts`}
              />
            )}
          </Tabs>
        )}
      </TabItem>
      <TabItem label="ИСУ «Бюджетирование»" url={`/budget/details/${id}/${orgUnitId}/cognos`}>
        {showChildItems && (
          <Tabs withRouter defaultTab={`/budget/details/${id}/${orgUnitId}/cognos`}>
            <TabItem
              label={'Предложение'}
              key='1'
              url={`/budget/details/${id}/${orgUnitId}/cognos`}
              style={{marginLeft: 0}}
            />
          </Tabs>
        )}
      </TabItem>
      <TabItem label="Скорректированный бюджет" url={`/budget/details/${id}/${orgUnitId}/corrected`}>
        {showChildItems && (
          <Tabs withRouter defaultTab={`/budget/details/${id}/${orgUnitId}/corrected`}>
            <TabItem
              label={'Расчет экономии'}
              url={`/budget/details/${id}/${orgUnitId}/corrected`}
              style={{marginLeft: 0}}
            />
            {calculationEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Распределение экономии'}
                url={`/budget/details/${id}/${orgUnitId}/corrected/distribution-economy`}
              />
            )}
            {distributionEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Предложение'}
                url={`/budget/details/${id}/${orgUnitId}/corrected/proposal-economy`}
              />
            )}
            {proposalEconomyStatus === entityStatusEnum.approved && (
              <TabItem
                label={'Договоры'}
                url={`/budget/details/${id}/${orgUnitId}/corrected/contracts`}
              />
            )}
          </Tabs>
        )}
      </TabItem>
    </Tabs>
  );
};
