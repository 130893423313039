// @flow
import React, {Component} from 'react';

import styled from 'styled-components';
import moment from 'moment';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';

import type {MeasuringDeviceCertification} from './../../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import {measuringDeviceCertificationApi} from './../../../lib/api';

import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Form, Selects} from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  measuringDeviceCertification: MeasuringDeviceCertification,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  measuringDeviceCertification,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={measuringDeviceCertification} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const { handleSubmit, handleBlur, setFieldValue, dirty } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    label="Дата сертификации"
                    name="maintenanceDate"
                    required
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="След. дата сертификации"
                    name="nextActDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  measuringDeviceCertificationId: ?number,
  vehicleId: ?number
};

type State = {
  measuringDeviceCertification: $Shape<MeasuringDeviceCertification>
};

class MeasuringDeviceCertificationForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const measuringDeviceCertificationId = parseInt(
        this.props.measuringDeviceCertificationId,
        10
      );
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (measuringDeviceCertificationId) {
        const measuringDeviceCertification = await measuringDeviceCertificationApi.fetchMeasuringDeviceCertification(
          measuringDeviceCertificationId
        );
        this.setState({
          measuringDeviceCertification
        });
      } else if (vehicleId) {
        this.setState({
          measuringDeviceCertification: {
            vehicleId
          }
        });
      } else {
        this.setState({
          measuringDeviceCertification: {}
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.measuringDeviceCertificationId) => {
    if (this.props.vehicleId) {
      // Делаем именно переход, т.к. нам нужен скролл до блока сертификаций средств измерений
      navigate(
        `/vehicles/${this.props.vehicleId}/maintenance/measuring-device-certifications`
      );
    } else {
      if (id) {
        navigate(`/services/measuring-device-certifications/${id}`);
      } else {
        navigate(`/services/measuring-device-certifications`, true);
      }
    }
  };

  onSubmit = async (
    measuringDeviceCertification: MeasuringDeviceCertification
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const measuringDeviceCertificationId = parseInt(
        this.props.measuringDeviceCertificationId,
        10
      );
      if (measuringDeviceCertificationId) {
        await measuringDeviceCertificationApi.updateMeasuringDeviceCertification(
          measuringDeviceCertification
        );
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(measuringDeviceCertificationId);
      } else {
        const added = await measuringDeviceCertificationApi.addMeasuringDeviceCertification(
          measuringDeviceCertification
        );

        notification.success({
          message: 'Успешное добавление',
          description: `Cертификация средств измерений успешно добавлен`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const measuringDeviceCertificationId = parseInt(
      this.props.measuringDeviceCertificationId,
      10
    );
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { measuringDeviceCertification } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId > 0 ? (
                <Crumb
                  to={`/vehicles/${vehicleId}/maintenance/measuring-device-certification`}
                >
                  ТС
                </Crumb>
              ) : (
                <Crumb
                  to={getPathWithHistoryParams(
                    '/services/measuring-device-certifications'
                  )}
                >
                  Список сертификаций средств измерений
                </Crumb>
              )}
              {measuringDeviceCertificationId ? (
                <Crumb>Редактирование сертификаций средств измерений</Crumb>
              ) : (
                <Crumb>Новая сертификация средств измерений</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {measuringDeviceCertificationId
              ? `Сертификация средств измерений №${measuringDeviceCertificationId}`
              : 'Тарировка цистерн'}
          </h1>
        </StyledPanel>
        <InnerForm
          measuringDeviceCertification={measuringDeviceCertification}
          onSubmit={this.onSubmit}
          onCancel={() =>
            this.redirect(
              measuringDeviceCertification.id || measuringDeviceCertificationId
            )
          }
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default MeasuringDeviceCertificationForm;
