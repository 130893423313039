import React from 'react';
import styled from 'styled-components';
import Divider from '../../../../components/layout/Divider';
import {Link} from '@reach/router';

const Container = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`;

const Title = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
`;

const StyledLink = styled(Link)`
  margin: 0 8px;
`;

type Props = {
  links: any
};

export default ({ links, additionalText = '' }: Props) => {
  if (!links || !Object.keys(links).length) {
    return null;
  }
  return (
    <>
      <Container>
        <Title>
          {`Для корректного расчета затрат необходимо заполнить данные в
						справочник${Object.keys(links).length > 1 ? 'ах' : 'е'}${additionalText}:`}
        </Title>
        {Object.keys(links).map(key => (
          <StyledLink to={links[key]} key={key}>{key}</StyledLink>
        ))}
      </Container>
      <Divider type="solid" color="light" />
    </>
  );
};
