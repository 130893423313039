// @flow

import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import {Link} from '@reach/router';
import {connect} from 'react-redux';

import type {ListState, OptionalEquipment, UserAccess} from '../../../lib/types';
import type {AppState} from '../../../ducks/redux';

import {Table} from './../../../components/ui';
import {Panel, Section} from './../../../components/layout';
import Filter, {type OptionalEquipmentFilterParams} from './Filter';
import Header from '../../../components/layout/Header';
import NavigationTabs from '../components/NavigationTabs';
import {withUserAccess} from './../../withUserAccess';
import {type FetchListParams, optionalEquipmentApi} from './../../../lib/api';
import {accessTypeEnum} from './../../../lib/enum';
import {getListInitialState, navigate, setQueryParams} from './../../../lib/helpers';
import qs from 'query-string';

const StyledPanel = styled(Panel)`
  padding-bottom: 0;
  padding-top: 0;
`;
const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const canAddOptionalEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment,
];

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = ListState<OptionalEquipment> & {
  filter: OptionalEquipmentFilterParams
};

/** Вывод списка шин */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };
  state = {
    ...getListInitialState(),
    filter: {},
  };
  columns = [
    {title: '№', dataIndex: 'id', sorter: true, width: 25},
    {title: 'Мнемокод', dataIndex: 'code', sorter: true},
    {title: 'Наименование', dataIndex: 'name', sorter: true},
    {
      title: 'Марка',
      dataIndex: 'brandName',
      sorter: true,
      sorterKey: 'brand.name',
    },
  ];
  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some(access =>
      [accessTypeEnum.viewingAllEquipments].includes(access),
    );
    const defaultFilter = canViewAll
      ? {}
      : ({
        orgUnitId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren',
      }: OptionalEquipmentFilterParams);
    return defaultFilter;
  };
  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({filter: filter || this.getDefaultFilter()}, () => this.fetchOptionalEquipments(page || 1));
  }
  fetchOptionalEquipments = async (
    page: number = 1,
    params: FetchListParams<OptionalEquipmentFilterParams> = {},
  ) => {
    const {filter} = this.state;
    this.setState({loading: true});
    const {
      data,
      totalCount,
    } = await optionalEquipmentApi.fetchOptionalEquipments({
      ...filter,
      page,
      ...params,
      // $FlowFixMe
      ...this.getDefaultFilter(), // фильтр по оргюнитам в зависимости от клэймов
    });
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };
  handleRowClick = (id: number) => navigate(`/equipment/optional/${id}`);

  cleanFilter = () =>
    this.setState({filter: {}}, this.fetchOptionalEquipments);

  applyFilter = (filter: OptionalEquipmentFilterParams) =>
    this.setState({filter}, this.fetchOptionalEquipments);

  render() {
    const {location, userAccess} = this.props;
    const {data, totalCount, page, pageSize, loading, filter} = this.state;

    const canAdd = userAccess.some(access =>
      canAddOptionalEquipmentAccess.includes(access),
    );

    return (
      <Fragment>
        <Header
          left={
            <PanelTitle>
              <h1>Приборы и бортовые устройства</h1>
            </PanelTitle>
          }
          right={canAdd && (
            <Link to="/equipment/optional/new">
              <Button data-cy="addOptional" type="primary">
                Создать
              </Button>
            </Link>
          )}
        />
        <StyledPanel>
          <NavigationTabs defaultTab="optional" />
        </StyledPanel>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            loading={loading}
            data={data.map(optional => ({
              ...optional,
              key: optional.id,
            }))}
            fetch={this.fetchOptionalEquipments}
            columns={this.columns}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </Fragment>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(withUserAccess(List));
