// @flow
import React, { useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import AntIcon from 'antd/lib/icon';
import AntCard from 'antd/lib/card';

import { Element, scroller } from 'react-scroll';

import type { Vehicle } from '../../../../lib/types';
import { Section } from '../../../../components/layout/index';
import { Card } from '../../../../components';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { Icon } from '../../../../components/ui';
import { WashingList } from './../../../Washings';
import InspectionGibddList from './../../containers/InspectionGibddList';
import InspectionGtnList from './../../containers/InspectionGtnList';
import MeasuringDeviceCertificationList from './../../containers/MeasuringDeviceCertificationList';
import ExpertiseServiceGpmList from './../../containers/ExpertiseServiceGpmList';
import PlatonList from './../../containers/PlatonList';
import LicensePlateChangeList from './../../containers/LicensePlateChangeList';
import SectionTitle from '../SectionTitle';
import { isDevelopment, navigate } from '../../../../lib/helpers';

const { Field } = Card;

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
`;
const Placeholder = styled.div`
  text-align: center;
  padding: 16px;
  color: #838d96;
`;
const ACard = props => (
  <AntCard {...props} headStyle={{ background: '#f0f4f8' }} />
);

const formatDate = value => {
  return value ? moment(value).format('DD.MM.YYYY') : value;
};

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  canHandleMaintenanceServices: boolean,
  anchor: ?string
};

const MaintenanceTab = ({
  vehicle,
  editable,
  anchor,
  canHandleMaintenanceServices
}: Props) => {
  useEffect(() => {
    if (anchor) {
      scroller.scrollTo(anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  });
  return (
    <>
      <Element name="overhaul">
        <Section>
          <SectionTitle
            divider
            suffix={
              editable && (
                <StyledIcon
                  onClick={() =>
                    navigate(`/vehicles/${vehicle.id}/edit/overhaul`)
                  }
                  type="edit"
                  size={16}
                />
              )
            }
          >
            Капитальный ремонт
          </SectionTitle>
          <SectionContent>
            {!vehicle.overhaulDate &&
              !vehicle.nextOverhaulPlannedDate &&
              !vehicle.overhaulCosts && (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            <Grid gutter="16px">
              {vehicle.overhaulDate && (
                <GridItem>
                  <Field label="Дата проведения кап.ремонта">
                    {formatDate(vehicle.overhaulDate)}
                  </Field>
                </GridItem>
              )}
              {vehicle.nextOverhaulPlannedDate && (
                <GridItem>
                  <Field label="Планируемая дата очередного кап.ремонта">
                    {formatDate(vehicle.nextOverhaulPlannedDate)}
                  </Field>
                </GridItem>
              )}
              {vehicle.overhaulCosts ? (
                <GridItem>
                  <Field label="Затраты на капитальный ремонт">
                    {vehicle.overhaulCosts} ₽
                  </Field>
                </GridItem>
              ) : null}
              {vehicle.totalRepairCosts ? (
                <GridItem>
                  <Field label="Суммарные затраты на ремонт ">
                    {vehicle.totalRepairCosts} ₽
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      <Element name="planed-maintenance">
        <Section>
          <SectionTitle divider>Техническое обслуживание</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <ACard title="ТО-2">
                <Field label="Дата последнего технического обслуживания">
                  {formatDate(vehicle.lastTo2Date) || ''}
                </Field>
                <Field label="Дата следующего технического обслуживания">
                  {formatDate(vehicle.nextTo2PlannedDate) || ''}
                </Field>
              </ACard>
              <ACard title="Сезонное ТО">
                <Field label="Дата последнего технического обслуживания">
                  {formatDate(vehicle.lastSeasonMaintenanceDate) || ''}
                </Field>
                <Field label="Дата следующего технического обслуживания">
                  {formatDate(vehicle.nextSeasonMaintenancePlannedDate) || ''}
                </Field>
              </ACard>
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      <Element name="maintenance">
        <Section>
          <SectionTitle
            divider
            suffix={
              editable && (
                <StyledIcon
                  onClick={() =>
                    navigate(`/vehicles/${vehicle.id}/edit/maintenance`)
                  }
                  type="edit"
                  size={16}
                />
              )
            }
          >
            Текущий ремонт
          </SectionTitle>
          <SectionContent>
            {!vehicle.partOfCostsMaintenanceFromInitialCost &&
              !vehicle.maintenanceCosts && (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            <Grid gutter="16px">
              {vehicle.partOfCostsMaintenanceFromInitialCost ? (
                <GridItem>
                  <Field label="Доля затрат на текущий ремонт от первоначальной стоимости">
                    {vehicle.partOfCostsMaintenanceFromInitialCost} %
                  </Field>
                </GridItem>
              ) : null}
              {vehicle.maintenanceCosts ? (
                <GridItem>
                  <Field label="Затраты на текущий ремонт">
                    {vehicle.maintenanceCosts} ₽
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      {isDevelopment && (
        <>
          <Element name="washings">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(
                          `/services/washings/new/vehicles/${vehicle.id}`
                        )
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Мойки
              </SectionTitle>
              <WashingList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="inspections-gibdd">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(
                          `/vehicles/${vehicle.id}/inspections-gibdd/new`
                        )
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Технический осмотр ГИБДД
              </SectionTitle>
              <InspectionGibddList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="inspections-gtn">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/inspections-gtn/new`)
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Технический осмотр ГТН
              </SectionTitle>
              <InspectionGtnList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="expertise-service-gpm">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(
                          `/vehicles/${vehicle.id}/expertises-services-gpm/new`
                        )
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Экспертиза ПС/ТО приборов безопасности ПС
              </SectionTitle>
              <ExpertiseServiceGpmList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="measuring-device-certification">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(
                          `/vehicles/${vehicle.id}/measuring-device-certifications/new`
                        )
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Сертификация средств измерений
              </SectionTitle>
              <MeasuringDeviceCertificationList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="license-plate-changes">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(
                          `/vehicles/${vehicle.id}/license-plate-changes/new`
                        )
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Замена гос. номеров
              </SectionTitle>
              <LicensePlateChangeList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
          <Element name="platons">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable &&
                  canHandleMaintenanceServices && (
                    <StyledAntIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/platons/new`)
                      }
                      type="plus"
                      size={16}
                    />
                  )
                }
              >
                Платон
              </SectionTitle>
              <PlatonList
                vehicleId={vehicle.id}
                enableDelete={editable && canHandleMaintenanceServices}
              />
            </Section>
          </Element>
        </>
      )}
    </>
  );
};

MaintenanceTab.defaultProps = {
  washings: []
};

export default MaintenanceTab;
