// @flow
import { accessTypeEnum } from '../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingStocks,
  accessTypeEnum.handlingAllStocks
];

export const editAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingStocks,
  accessTypeEnum.handlingAllStocks
];

export default addAccessRight;
