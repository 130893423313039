//@flow
import React, { useCallback } from 'react';

import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';
import YearSelect from '../../../../components/selects/YearSelect';

export type BusinessCalendarForBudgetParamsFilter = {
  year?: string
};

export const BusinessCalendarForBudgetFilter = (
  props: FilterProps<BusinessCalendarForBudgetParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<BusinessCalendarForBudgetParamsFilter>>) =>
      CommonFilter<BusinessCalendarForBudgetParamsFilter>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <YearSelect
                size="small"
                value={values.year}
                onChange={(value, dateString) => {
                  changeValue('year', value);
                }}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default BusinessCalendarForBudgetFilter;
