// @flow

import React, { Component } from 'react';
import moment from 'moment';
import range from 'lodash/range';
import AutoComplete from 'antd/lib/auto-complete';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';

const initialTimeRange = [];

range(24).forEach(hour => {
  initialTimeRange.push(`${hour}:00`);
  initialTimeRange.push(`${hour}:30`);
});

type Props = {
  value?: moment,
  onChangeDisabled?: boolean,
  disabledTime?: (dateTime: string) => boolean,
  onChange: Function
};

type State = {
  value: ?moment,
  timeRange: string[]
};

const convertData = (datetime: ?moment) =>
  datetime ? moment.utc(datetime, 'HH:mm').format('HH:mm') : '';

export default class DateTimePicker extends Component<Props, State> {
  state = {
    value: convertData(this.props.value),
    timeRange: initialTimeRange
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.setState({
        value: convertData(this.props.value)
      });
    }
  }

  onSelect = (value: any) => {
    this.setState(
      prevState => ({
        value,
        timeRange: orderBy(prevState.timeRange, time =>
          this.toValidTime(time).valueOf()
        )
      }),
      this.applyChange
    );
  };

  toValidTime = (timeValue: ?string): moment => {
    let time = moment.utc(timeValue, 'HH:mm', true);
    if (!time.isValid()) {
      time = moment.utc(timeValue, 'H:mm', true);
    }
    return time;
  };

  handleSearch = (value: string) => {
    if (
      moment.utc(value, 'HH:mm', true).isValid() ||
      moment.utc(value, 'H:mm', true).isValid()
    )
      this.setState({
        timeRange: !value ? initialTimeRange : [value, ...initialTimeRange]
      });
  };

  applyChange = () => {
    const { value: timeValue } = this.state;
    const { value: dateValue } = this.props;
    let time = this.toValidTime(timeValue);
    if (time.isValid()) {
      const fullDateTime = moment
        .utc(dateValue)
        .startOf('day')
        .add(time.hour(), 'hour')
        .add(time.minute(), 'minute');
      this.props.onChange(fullDateTime);
    } else {
      this.setState({
        value: convertData(dateValue)
      });
    }
  };

  render() {
    const { value, timeRange } = this.state;
    const { disabledTime, onChangeDisabled } = this.props;

    return (
      <AutoComplete
        disabled={isNil(value)}
        placeholder="Время"
        value={value}
        dataSource={timeRange}
        style={{ width: 100 }}
        onSearch={this.handleSearch}
        onChange={(value: string) => {
          if (onChangeDisabled !== true) {
            this.setState({
              value
            });
          }
        }}
        onSelect={this.onSelect}
      >
        {timeRange.map((value, key) => (
          <AutoComplete.Option
            disabled={(() => {
              if (disabledTime) {
                let time = moment.utc(value, 'HH:mm', true);
                if (!time.isValid()) time = moment.utc(value, 'H:mm', true);
                return disabledTime(time);
              }
              return false;
            })()}
            value={value}
            key={key}
          >
            {convertData(value)}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    );
  }
}
