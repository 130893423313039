// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {registrationPlaceApi} from './../../lib/api';

import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {addAccessRight, editAccessRight} from './accessRight';

const entryPointPath = '/admin/registration-place/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Наименование',
    dataIndex: 'name'
  }
];

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some(access => addAccessRight.includes(access));
  const canEdit = () =>
    userAccess.some(access => editAccessRight.includes(access));

  const access = {
    add: canAdd() ? () => navigate(`${entryPointPath}new`) : null
  };

  return (
    <Page>
      <CommonListPage
        crud={registrationPlaceApi}
        pageHeaderProps={{
          mainHeader: 'Место регистрации ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canEdit()
        }}
        filter={Filter}
        filterMixin={{orderBy: 'id'}}
      />
    </Page>
  );
});
