// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {FuelPrice} from './../../../lib/types';
import {fuelPriceApi} from './../../../lib/api';
import {getPathWithHistoryParams, navigate} from './../../../lib/helpers';

import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {Header, Panel} from './../../../components/layout';
import InnerForm from './components/InnerForm';
import {notificationLoading} from './../../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  fuelPriceId: number,
  location: any,
  viewMode?: boolean
};

type State = {
  fuelPrice: ?FuelPrice,
  isClone: boolean
};

export class FuelPriceForm extends React.Component<Props, State> {
  state = {
    fuelPrice: null,
    isClone: false
  };

  async componentDidMount() {
    const { fuelPriceId, location } = this.props;
    try {
      if (fuelPriceId) {
        const fuelPrice = await fuelPriceApi.getFuelPrice(fuelPriceId);
        const isClone =
          location.pathname.split('/').findIndex(path => path === 'clone') !==
          -1;
        this.setState({
          fuelPrice: isClone
            ? {
                ...fuelPrice,
                startDate: undefined,
                endDate: undefined
              }
            : fuelPrice,
          isClone
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: FuelPrice) => {
    const { isClone } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        isClone
          ? await fuelPriceApi.addFuelPrice({ ...values, id: undefined })
          : await fuelPriceApi.updateFuelPrice(values);
      } else {
        await fuelPriceApi.addFuelPrice(values);
      }
      navigate(`/admin/fuel-price`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/fuel-price', true);

  render() {
    const { viewMode } = this.props;
    const { fuelPrice, isClone } = this.state;

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/fuel-price')}>
                Перечень прейскурантов ГСМ
              </Crumb>
              {fuelPrice ? (
                <Crumb>
                  {isClone
                    ? `Копируем прейскурант ГСМ №${fuelPrice.id}`
                    : `Прейскурант ГСМ №${fuelPrice.id}`}
                </Crumb>
              ) : (
                <Crumb>Новый прейскурант ГСМ</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {fuelPrice
              ? isClone
                ? `Копируем прейскурант ГСМ №${fuelPrice.id}`
                : `Прейскурант ГСМ №${fuelPrice.id}`
              : 'Новый прейскурант ГСМ'}
          </h1>
        </StyledPanel>
        <InnerForm
          fuelPrice={fuelPrice}
          viewMode={viewMode}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default FuelPriceForm;

export const FuelPriceFormViewMode = (props) => <FuelPriceForm {...props} viewMode />;
