// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {reasonsForRejectinfOfOrderApi} from './../../lib/api';

import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';
import type {UserAccess} from '../../lib/types';
import {accessTypeEnum} from '../../lib/enum';

const entryPointPath = '/admin/reasons-for-rejection-of-order/';

type Props = {
  userAccess: UserAccess[]
};

const ReasonsForRejectingOfOrder = ({ userAccess }: Props) => {
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30
    },
    {
      title: 'Причина отклонения',
      dataIndex: 'title'
    }
  ];
  const canEdit = userAccess.includes(
    accessTypeEnum.handlingOrderToReworkReasons
  );

  return (
    <Page>
      <CommonListPage
        crud={reasonsForRejectinfOfOrderApi}
        pageHeaderProps={{
          mainHeader: 'Причины отклонения заявки на ГПМ/ ТС',
          access: {
            add: () => {
              navigate(`${entryPointPath}new`);
            }
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canEdit,
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default React.memo(withUserAccess(ReasonsForRejectingOfOrder));
