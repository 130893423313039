// @flow

import React, {useEffect, useState} from 'react';
import {SelectProps} from 'antd/lib/select';
import TreeSelect from '../ui/TreeSelect';
import {expenseDirectionApi} from '../../lib/api';
import type {ExpenseDirection} from '../../lib/types';
import notification from 'antd/lib/notification';

type Props = SelectProps & { filter?: any, optionKey?: string };

export default (props: Props) => {
  const [data, setData] = useState({});
  const [value] = useState();

  const fetchData = async () => {
    try {
      const data = await expenseDirectionApi.fetchExpenseDirection({pageSize: 10000});

      const buildTree = (data: ExpenseDirection[]) => {
        return data.reduce((result, expense: ExpenseDirection) => {
          let index = result.findIndex(el => el.key === expense.budgetLineItemName);

          if (index === -1) {
            index =
              result.push({
                key: expense.budgetLineItemName,
                value: expense.id,
                title: expense.budgetLineItemName,
                selectable: false,
                children: []
              }) - 1;
          }

          result[index].children.push({
            key: expense.id,
            value: expense.id,
            title: expense.name,
            isLeaf: true
          });

          return result;
        }, []);
      };

      setData(buildTree(data.data));
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TreeSelect
      value={value}
      placeholder="Выберите направление расходов"
      treeData={data}
      treeNodeFilterProp="title"
      showSearch
      allowClear
      {...props}
    />
  );
};
