// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {StockPart} from '../../lib/types';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';
import {stockPartApi} from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  stockPartId: number
};

type State = {
  stockPart: ?StockPart
};

export class StockPartForm extends React.Component<Props, State> {
  state = {
    stockPart: null
  };

  async componentDidMount() {
    const { stockPartId } = this.props;
    try {
      if (stockPartId) {
        const stockPart = await stockPartApi.getStockPart(stockPartId);
        this.setState({ stockPart });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: StockPart) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await stockPartApi.updateStockPart(values);
      } else {
        await stockPartApi.addStockPart(values);
      }
      navigate(`/admin/stock-part`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/stock-part', true);

  render() {
    const { stockPart } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/stock-part')}>
                Перечень материалов для проведения работ хоз.способом
              </Crumb>
              {stockPart ? (
                <Crumb>Деталь №{stockPart.id}</Crumb>
              ) : (
                <Crumb>Новая деталь</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{stockPart ? `Деталь №${stockPart.id}` : 'Новая деталь'}</h1>
        </StyledPanel>
        <InnerForm
          stockPart={stockPart}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default StockPartForm;
