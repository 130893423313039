// @flow
import React, {PureComponent} from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';

import {getListInitialState, navigate} from '../../lib/helpers';
import type {UserAccess, VehicleCategory, VehicleSubcategory} from '../../lib/types';
import {Icon, Operations, TabItem, Table, Tabs} from './../../components/ui';
import {Section} from './../../components/layout';
import Header from '../../components/layout/Header';
import {accessTypeEnum} from '../../lib/enum';
import {notificationLoading} from '../../components/Notifications';
import {withUserAccess} from '../withUserAccess';
import {VehicleCategoriesApi, VehicleSubcategoriesApi} from '../../lib/api';
import {Router} from '@reach/router';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

const Some = styled.div`
  max-width: 45vw;
  white-space: break-spaces;
`;

export const vehicleCategoriesTypeEnum = {
  categories: 'categories',
  subcategories: 'subcategories',
};

type Props = {
  userAccess: UserAccess[],
  type: $Keys<vehicleCategoriesTypeEnum>,
};

type State = {
  data: VehicleCategory[] | VehicleSubcategory[],
  page: number,
  pageSize: number,
  totalCount: number,
}

class VehicleCategoriesList extends PureComponent<Props, State> {
  state = {
    type: vehicleCategoriesTypeEnum.categories,
    ...getListInitialState(),
  };

  async componentDidUpdate(prevProps: Props) {
    if (this.props.type !== prevProps.type) {
      this.setState({page: 1}, this.fetch);
    }
  }

  fetch = async (page: number = 1) => {
    const {pageSize} = this.state;
    const request = this.props.type === vehicleCategoriesTypeEnum.categories
      ? VehicleCategoriesApi.fetch
      : VehicleSubcategoriesApi.fetch;
    const {data, totalCount} = await request({page, pageSize, orderBy: 'id', byDescending: true});
    this.setState({data, totalCount, page});
  };

  async delete(id: number) {
    try {
      notificationLoading({
        message: 'Удаление записи',
        key: 'deleting',
      });
      const deleteRequest = this.props.type === vehicleCategoriesTypeEnum.categories
        ? VehicleCategoriesApi.delete
        : VehicleSubcategoriesApi.delete;
      await deleteRequest(id);
      await this.fetch(this.state.page);
      notification.success({
        message: 'Запись удалена',
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  handleRowClick = (id: number) => navigate(`${this.props.type}/${id}`);

  canEdit = () => this.props.userAccess.some(access =>
    [accessTypeEnum.admin, accessTypeEnum.handlingVehicleCategory].includes(access),
  );

  categoryColumns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Категория ТС',
      dataIndex: 'name',
    },
    {
      title: '',
      width: 30,
      key: 'delete',
      dataIndex: 'id',
      render: (id) => {
        return this.canEdit()
          ? (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => this.delete(id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
          : null;
      },
    },
  ];

  subcategoryColumns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Категория ТС',
      dataIndex: 'vehicleCategory.name',
    },
    {
      title: 'Подкатегория ТС',
      dataIndex: 'name',
    },
    {
      title: 'Описание подкатегории',
      dataIndex: 'description',
      render: desc => <Some>{desc}</Some>,
    },
    {
      title: '',
      width: 30,
      key: 'delete',
      dataIndex: 'id',
      render: (id) => {
        return this.canEdit()
          ? (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить запись?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => this.delete(id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
          : null;
      },
    },
  ];

  render() {
    const {data, page, pageSize, totalCount} = this.state;
    const {type} = this.props;
    const commonUrl = '/admin/vehicle-categories/';
    return (
      <>
        <Header
          left={<h1>Категории ТО ГИБДД</h1>}
          right={this.canEdit() && (
            <Button type="primary" onClick={() => navigate(`${type}/new`)}>Создать</Button>
          )}
        />
        <Tabs withRouter>
          <TabItem
            label="Категории"
            url={`${commonUrl}`}
          />
          <TabItem
            label="Подкатегории"
            url={`${commonUrl}${vehicleCategoriesTypeEnum.subcategories}`}
          />
        </Tabs>
        <Section>
          <Table
            className="cell-content-to-top"
            fetch={this.fetch}
            fetchOnMount
            onRow={record => ({onClick: () => this.handleRowClick(record.id)})}
            columns={type === vehicleCategoriesTypeEnum.categories ? this.categoryColumns : this.subcategoryColumns}
            data={data}
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
          />
        </Section>
      </>
    );
  }
}

const Routes = (props) => (
  <Router>
    <VehicleCategoriesList {...props} path="/" type={vehicleCategoriesTypeEnum.categories} />
    <VehicleCategoriesList
      {...props}
      path={`/${vehicleCategoriesTypeEnum.subcategories}`}
      type={vehicleCategoriesTypeEnum.subcategories}
    />
  </Router>
);

export default withUserAccess(Routes);
