// @flow
import type {AssignmentLimit} from './../types';
import CommonApi from './../commonApi';

import {convertFromKopToRub, convertFromMToKm, convertFromSecondToHour} from './../helpers';
import {downloadRequestWithToken} from './index';

export const convertDataFromServer = (assignmentLimit: AssignmentLimit) => ({
  ...assignmentLimit,
  sum: convertFromKopToRub(assignmentLimit.sum),
  assignmentMonths: [
    ...assignmentLimit.assignmentMonths.map(assignmentMonth => {
      return {
        ...assignmentMonth,
        sum: convertFromKopToRub(assignmentMonth.sum),
        correctedHours: convertFromSecondToHour(assignmentMonth.correctedHours),
        correctedKilometrage: convertFromMToKm(assignmentMonth.correctedKilometrage),
        correctedWeekendKilometrage: convertFromMToKm(assignmentMonth.correctedWeekendKilometrage),
        correctedWeekendHours: convertFromSecondToHour(assignmentMonth.correctedWeekendHours),
      };
    }),
  ],
});

const print = async (id: number) => {
  const printed = await downloadRequestWithToken(
    `/assignmentLimit/${id}/print`,
  );
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const assignmentLimit = new CommonApi<AssignmentLimit>(
  'assignmentLimit',
  'лимит закрепления',
  undefined,
  convertDataFromServer,
);

export default {...assignmentLimit, print};
