// @flow
import type {VehicleTariff, VehicleTariffTypesAndModels} from '../types';
import {VehicleModelStatusEnum} from '../types/vehicleTariff';

import {convertFromKopToRub, convertFromRubToKop, getRoundedNumber, isTariffFact} from '../helpers';
import CommonApi from './../commonApi';
import {downloadRequestWithToken, fetchRequest} from './index';

const convertDataToServer = (vehicleTariff: VehicleTariff) => ({
  ...vehicleTariff,
  ai95Price: convertFromRubToKop(vehicleTariff.ai95Price),
  ai92Price: convertFromRubToKop(vehicleTariff.ai92Price),
  ai98Price: convertFromRubToKop(vehicleTariff.ai98Price),
  dieselPrice: convertFromRubToKop(vehicleTariff.dieselPrice),
  gasPrice: convertFromRubToKop(vehicleTariff.gasPrice),
  electricityPrice: convertFromRubToKop(vehicleTariff.electricityPrice),
  optionalEquipmentAi92Price: convertFromRubToKop(vehicleTariff.optionalEquipmentAi92Price),
  optionalEquipmentAi95Price: convertFromRubToKop(vehicleTariff.optionalEquipmentAi95Price),
  optionalEquipmentAi98Price: convertFromRubToKop(vehicleTariff.optionalEquipmentAi98Price),
  optionalEquipmentDieselPrice: convertFromRubToKop(vehicleTariff.optionalEquipmentDieselPrice),
  optionalEquipmentElectricityPrice: convertFromRubToKop(vehicleTariff.optionalEquipmentElectricityPrice),
  optionalEquipmentGasPrice: convertFromRubToKop(vehicleTariff.optionalEquipmentGasPrice),
  typesAndModels: vehicleTariff.typesAndModels.map(model => convertVehicleModelToServer(model)),
});

const convertVehicleModelToServer = (model: VehicleTariffTypesAndModels) => ({
  ...model,
  tariffFixed: convertFromRubToKop(model.tariffFixed),
  tariffFixedDeviation: convertFromRubToKop(model.tariffFixedDeviation),
  tariffFixedPlan: convertFromRubToKop(model.tariffFixedPlan),
  tariffFixedRealization: model.tariffFixedRealization,
  tariffDynamic: convertFromRubToKop(model.tariffDynamic),
  tariffDynamicDeviation: convertFromRubToKop(model.tariffDynamicDeviation),
  tariffDynamicPlan: convertFromRubToKop(model.tariffDynamicPlan),
  tariffDynamicRealization: model.tariffDynamicRealization,
  airConditionerTariff: convertFromRubToKop(model.airConditionerTariff),
  generatorTariff: convertFromRubToKop(model.generatorTariff),
  heaterTariff: convertFromRubToKop(model.heaterTariff),
  preHeaterTariff: convertFromRubToKop(model.preHeaterTariff),
  trailerTariff: convertFromRubToKop(model.trailerTariff),
  warmingTariff: convertFromRubToKop(model.warmingTariff),
  winchTariff: convertFromRubToKop(model.winchTariff),
  modelStatus: undefined,
});

const convertDataFromServer = (vehicleTariff: VehicleTariff) => ({
  ...vehicleTariff,
  ai95Price: convertFromKopToRub(vehicleTariff.ai95Price),
  ai92Price: convertFromKopToRub(vehicleTariff.ai92Price),
  ai98Price: convertFromKopToRub(vehicleTariff.ai98Price),
  dieselPrice: convertFromKopToRub(vehicleTariff.dieselPrice),
  gasPrice: convertFromKopToRub(vehicleTariff.gasPrice),
  electricityPrice: convertFromKopToRub(vehicleTariff.electricityPrice),
  optionalEquipmentAi92Price: convertFromKopToRub(vehicleTariff.optionalEquipmentAi92Price),
  optionalEquipmentAi95Price: convertFromKopToRub(vehicleTariff.optionalEquipmentAi95Price),
  optionalEquipmentAi98Price: convertFromKopToRub(vehicleTariff.optionalEquipmentAi98Price),
  optionalEquipmentDieselPrice: convertFromKopToRub(vehicleTariff.optionalEquipmentDieselPrice),
  optionalEquipmentElectricityPrice: convertFromKopToRub(vehicleTariff.optionalEquipmentElectricityPrice),
  optionalEquipmentGasPrice: convertFromKopToRub(vehicleTariff.optionalEquipmentGasPrice),
  typesAndModels: vehicleTariff.typesAndModels.map(
    model => convertVehicleModelFromServer(model, isTariffFact(vehicleTariff.version)),
  ),
});

const convertVehicleModelFromServer = (model: VehicleTariffTypesAndModels, checkTariffFact: boolean) => ({
  ...model,
  tariffFixed: convertFromKopToRub(model.tariffFixed),
  tariffFixedDeviation: convertFromKopToRub(model.tariffFixedDeviation),
  tariffFixedPlan: convertFromKopToRub(model.tariffFixedPlan),
  tariffFixedRealization: getRoundedNumber(model.tariffFixedRealization),
  tariffDynamic: convertFromKopToRub(model.tariffDynamic),
  tariffDynamicDeviation: convertFromKopToRub(model.tariffDynamicDeviation),
  tariffDynamicPlan: convertFromKopToRub(model.tariffDynamicPlan),
  tariffDynamicRealization: getRoundedNumber(model.tariffDynamicRealization),
  airConditionerTariff: convertFromKopToRub(model.airConditionerTariff),
  generatorTariff: convertFromKopToRub(model.generatorTariff),
  heaterTariff: convertFromKopToRub(model.heaterTariff),
  preHeaterTariff: convertFromKopToRub(model.preHeaterTariff),
  trailerTariff: convertFromKopToRub(model.trailerTariff),
  warmingTariff: convertFromKopToRub(model.warmingTariff),
  winchTariff: convertFromKopToRub(model.winchTariff),
  modelStatus: getModelStatus(model, checkTariffFact),
});

const getModelStatus = (model: VehicleTariffTypesAndModels, checkTariffFact: boolean): VehicleModelStatusEnum => {
  const {vehicleModelId, tariffFixedPlan} = model;

  if (!vehicleModelId) {
    return VehicleModelStatusEnum.NoModel;
  }

  if (checkTariffFact && typeof tariffFixedPlan !== 'number') {
    return VehicleModelStatusEnum.NoPlanTariff;
  }

  return VehicleModelStatusEnum.Ok;
};

const createVehicleTariffApi = (url, suffix, apiKey) => {
  return new CommonApi<VehicleTariff>(
    `${url}${suffix}`,
    apiKey,
    convertDataToServer,
    convertDataFromServer
  );
}

const url = 'vehicleTariff/version/';

const vehicleTariff = createVehicleTariffApi('', 'vehicleTariff', 'тарифы на НТС');

const plannedVehicleTariff = createVehicleTariffApi(url, 'planned', 'плановые тарифы на НТС');

const factVehicleTariff = createVehicleTariffApi(url, 'fact', 'фактические тарифы на НТС');

const printHandling = async (url) => {
  const printed = await downloadRequestWithToken(url);
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
}

const print = (version) => printHandling(`/vehicleTariff/print/${version}`);

const printTariff = (id) => printHandling(`/vehicleTariff/${id}/print`);

const matchingModels = async (tariffId) => {
  const vehicleTariff = await fetchRequest.put(`/vehicleTariff/${tariffId}/matchingVehicleModels`);
  if (vehicleTariff) {
    return convertDataFromServer(vehicleTariff);
  }
}

const calculateVehicleModelCommonApi = (model: VehicleTariffTypesAndModels) => {
  return new CommonApi<VehicleTariffTypesAndModels>(
    'vehicleTariff/calculateVehicleModel',
    'расчёт плановых значений модели ТС фактического тарифа на НТС',
    convertVehicleModelToServer,
    (model) => convertVehicleModelFromServer(model, true),
  );
};

export default {
  matchingModels,
  print,
  printTariff,
  // Common API
  addVehicleTariff: vehicleTariff.add,
  updateVehicleTariff: vehicleTariff.update,
  deleteVehicleTariff: vehicleTariff.delete,
  getVehicleTariff: vehicleTariff.get,
  // Planned Vehicle Tariff Fetch
  fetchPlannedVehicleTariff: plannedVehicleTariff.fetch,
  // Fact Vehicle Tariff Fetch
  fetchFactVehicleTariff: factVehicleTariff.fetch,
  // VehicleTariffVehicleModel methods
  calculateVehicleModel: calculateVehicleModelCommonApi().add,
};
