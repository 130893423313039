import isNil from 'lodash/isNil';
import moment from 'moment';

import type {RegulationLimit, UserAccess} from '../../../../lib/types';
import {declineNumber} from '../../../../lib/helpers';
import {accessTypeEnum, entityStatusEnum, operationLimitGroupStatusEnum} from '../../../../lib/enum';
import {budgetVersionApi, contractVehiclePlanApi, vehiclePlanApi} from '../../../../lib/api';
import {MONTH} from '../../summary/components/Contracts/InnerForm';

/**
 * Функция, для склонения часов
 * @param value Часы
 * @returns {string}
 */
export const declineHours = (value: number) => isNil(value)
  ? ''
  : `${value} ${declineNumber(Math.round(value), 'час', 'часа', 'часов')}`;

/**
 * Функция форматирования значения по ее типу (часы или км)
 *
 * @param key
 * @param value
 * @returns {string}
 */
export const formatLimitValue = (
  key: $Keys<RegulationLimit>,
  value: any
): string => {
  const hours = ['hours', 'hoursWeekend'];
  const distance = ['distance', 'distanceWeekend'];
  if (!value) {
    return '';
  }
  if (hours.includes(key)) {
    return declineHours(value);
  } else if (distance.includes(key)) {
    return `${value} км`;
  }
  return value;
};

export const canEdit = (status?: entityStatusEnum) => {
  return status !== entityStatusEnum.approved;
};

/**
 * Проверка на возможность
 * утверждения регламента пользователем
 */
export const canApprove = (
  userAccess: UserAccess[],
  status?: entityStatusEnum
) => {
  if (!status) return false;

  return (
    (status === entityStatusEnum.approvement ||
      status === operationLimitGroupStatusEnum.onAgreeing) &&
    userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingRegulation
      ].includes(access)
    )
  );
};

/**
 * Проверка на возможность
 * создания/редактирования регламента пользователем
 */
export const canHandling = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingRegulation
    ].includes(access)
  );
};

/**
 * Проверка на возможность отправки
 * на согласование регламента пользователем
 */
export const canSendToAgreeing = (
  userAccess: UserAccess[],
  status?: entityStatusEnum
) => {
  if (!status) return false;

  return (
    canHandling(userAccess) &&
    (status === entityStatusEnum.created ||
      status === entityStatusEnum.declined ||
      status === operationLimitGroupStatusEnum.draft)
  );
};

/**
 * Получение открытого периода, в течение которого допустимо редактирование записей бюджета НТС
 * @param vehiclePlanId
 * @param isContractVehiclePlan
 * @returns {Promise<{start: string, end: string}>|null}
 */
export const getOpenPeriod = async (
  vehiclePlanId: number,
  isContractVehiclePlan: boolean,
  ): Promise<{start: string, end: string}> | null => {
  // необходимо определить открытый период, в течении которого допустимо редактирование определенных
  // записей таблицы в пределах этого периода. Для бюджетов тестовых версий
  // (если vehiclePlan.budgetVersion.periodStart === null) открытый период = весь год.
  // Для бюджетов версий БК-index начало периода фиксируется в поле periodStart в данных версии бюджета. окончание
  // периода придется определять.
  // это либо декабрь, как конец года, для "БК-3", либо предыдущий месяц перед "БК-(index + 1)".periodStart для "БК-index"
  // пример: у нас версия бюджета БК-2. название месяца начала периода хранится в
  // contractVehiclePlan.budgetVersion.periodStart. чтобы вычислить конец периода смотрим начало периода БК-3 = ноябрь,
  // следовательно, конец периода для БК-2 = октябрь
  const promise = isContractVehiclePlan ? contractVehiclePlanApi.get : vehiclePlanApi.get;
  const vehiclePlan = await promise(vehiclePlanId);

  if (!vehiclePlan.budgetVersion.periodStart) {
    return {
      start: moment.utc().startOf('year').format(),
      end: moment.utc().endOf('year').format(),
    }
  }

  const re = /БК.+\d/i
  const currentBudgetVersionNameIndex = vehiclePlan.budgetVersion.name.match(re)?.[0].match(/\d/)?.[0];
  
  if (!currentBudgetVersionNameIndex) {
    return;
  }
  
  const year = new Date().getFullYear();
  const months = MONTH.map(month => month.name);
  const openPeriod = {
    start: moment(`01 ${vehiclePlan.budgetVersion.periodStart} ${year}`).format(),
    end: ''
  }
  if (+currentBudgetVersionNameIndex === 3) {
    openPeriod.end = moment().endOf('year').format();
  } else {
    const budgetVersions = await budgetVersionApi.fetch();
    let nextBudgetVersion;
    budgetVersions.data.forEach(version => {
      if (re.test(version.name) && +version.name.match(re)[0].match(/\d/)[0] === +currentBudgetVersionNameIndex + 1) {
        nextBudgetVersion = version
      }
    })
    const indexOfMonth = months.findIndex(month => month === nextBudgetVersion.periodStart);
    openPeriod.end = moment(`01 ${indexOfMonth} ${year}`, 'DD MM YYYY').endOf('month').format()
  }
  return openPeriod;
}

/**
 * Проверяет, входит ли переданная дата в открытый период
 * @param openPeriod: {start: string, end: string}
 * @param date: string
 * @returns {boolean}
 */
export const dateIsIncludeInOpenPeriod = (openPeriod: any, date: string): boolean =>
  moment(date).isSameOrAfter(openPeriod.start) && moment(date).isSameOrBefore(openPeriod.end);

