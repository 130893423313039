// @flow
import React, {useState} from 'react';
import moment from 'moment';

import {navigate} from '../../../lib/helpers';
import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import {businessCalendarForBudgetApi} from '../../../lib/api';

import {Filter} from './components';
import YearsSelectModal from './components/YearsSelectModal';

const entryPointPath = '/admin/the-business-calendar-for-budget/';

const columns = [
  {
    title: 'Год',
    dataIndex: 'year'
  },
  {
    title: 'Месяц',
    dataIndex: 'month',
    render: month => moment(month + 1, 'M').format('MMMM')
  },
  {
    title: 'Количество дней в месяце',
    dataIndex: 'daysInMonth'
  },
  {
    title: 'Количество рабочих дней в месяце',
    dataIndex: 'workingDaysInMonth'
  },
  {
    title: 'Количество рабочих часов в месяце',
    dataIndex: 'workingHoursInMonth'
  },
  {
    title: 'Количество выходных дней в месяце',
    dataIndex: 'dayOffsInMonth'
  }
];

export default () => {
  const [showModal, setShowModal] = useState(false);
  const canAdd = () => true;
  const access = {
    add: () => navigate(`${entryPointPath}new`),
    custom: {
      title: 'Обновить из производственного календаря',
      action: () => setShowModal(true)
    }
  };

  return (
    <Page>
      <YearsSelectModal
        onClose={() => setShowModal(false)}
        visible={showModal}
      />
      <CommonListPage
        crud={businessCalendarForBudgetApi}
        pageHeaderProps={{
          mainHeader: 'Производственный календарь для бюджета',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
        filter={Filter}
      />
    </Page>
  );
};
