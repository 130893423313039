// @flow

import {SelectProps} from 'antd/lib/select';
import React from 'react';

import {maintenanceOperationApi} from '../../lib/api';
import type {MaintenanceOperation} from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  filter?: any,
};
export default ({
  filter = {},
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    placeholder="Выберите виды работ"
    fetch={async params =>
      await maintenanceOperationApi.fetchMaintenanceOperations({
        ...params,
        ...filter,
      })
    }
    fetchSingle={maintenanceOperationApi.fetchMaintenanceOperation}
    notFoundText="Виды работ не найдены"
    renderOption={(maintenanceOperation: MaintenanceOperation, Option) => (
      <Option
        key={maintenanceOperation.id}
        value={maintenanceOperation.id}
        maintenanceoperation={maintenanceOperation}
      >
        {`${maintenanceOperation?.code || ''} ${maintenanceOperation?.name || ''}`}
      </Option>
    )}
    {...selectProps}
  />
);
