// @flow
import React from 'react';
import styled from 'styled-components';
import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, OrgUnitSelect} from './../../../../../components';
import type {FilterChildrenParams} from '../../../../../components/hoc/Filter';
import Field from './../../../../../components/card/Field';
import {ContractVehiclePlanStatus} from '../../../../../lib/types';
import ContractVehiclePlanStatusSelect from '../../../../../components/selects/ContractVehiclePlanStatusSelect';
import {CognosType} from '../../ContractVehicleBudget';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type RegistrBudgetFilterParams = {
  status?: ContractVehiclePlanStatus,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  nodeId?: number,
  contractorId?: number
};

type Props = {
  filter: RegistrBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  cognosType?: $Keys<CognosType>
};

export default ({ filter, cleanFilter, applyFilter, cognosType }: Props) => {
  const excludedStatuses =
    cognosType === CognosType.beforeCognos
      ? ['approvementAfterCognos', 'approvedAfterCognos']
      : [
          'created',
          'approvement',
          'approved',
          'declined',
          'editing',
          'processing',
          'closed'
        ];
  return (
    <SpoilerContent>
      <FilterContainer>
        <Filter
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
          initialValues={filter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter
          }: FilterChildrenParams<RegistrBudgetFilterParams>) => (
            <>
              <FilterItem>
                <Field mBottomNone>
                  <OrgUnitSelect
                    size="small"
                    placeholder="Подразделение"
                    onChange={value => changeValue('nodeId', value)}
                    value={values.nodeId}
                    isFilter={true}
                  />
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <ContractVehiclePlanStatusSelect
                    size="small"
                    onChange={value => changeValue('status', value)}
                    value={values.status}
                    exclude={excludedStatuses}
                  />
                </Field>
              </FilterItem>

              <FilterButtonsContainer
                applyFilter={applyFilter}
                cleanFilter={cleanFilter}
              />
            </>
          )}
        </Filter>
      </FilterContainer>
    </SpoilerContent>
  );
};
