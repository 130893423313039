// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';

import type {EntityStatusType} from './../../../../../lib/types';
import {formatDateTimeToISOString} from './../../../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../../components';
import type {FilterChildrenParams} from './../../../../../components/hoc/Filter';
import Field from './../../../../../components/card/Field';

const { EntityStatusSelect } = Selects;
const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type WorkPlansFilterParams = {
  status?: EntityStatusType,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  orgUnitId?: number,
  contractorId?: number
};

type Props = {
  filter: WorkPlansFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<WorkPlansFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  onlyBranches
                  size="small"
                  placeholder="Подразделение"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY HH:mm"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);
