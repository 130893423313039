// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { employeeApi } from '../../lib/api';
import type { Employee, EmployeeFilter } from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';
import styled from 'styled-components';
import { convertEmployeeToString } from '../../lib/helpers';
import Popover from '../ui/Popover';
import LinkIcon from './components/LinkIcon';
import { OptionContent, OptionLeft, OptionRight } from './components/Option';
import Icon from 'antd/lib/icon';

const OptionOrgUnit = styled.p.attrs(props => ({
  className: 'orgUnit'
}))`
  color: #838d96;
  font-size: 10px;
  line-height: 1.7;
`;
const OptionName = styled.p.attrs(() => ({
  className: 'name'
}))`
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
const Select = styled(AutocompleteSelect)`
  .ant-select-selection-selected-value .orgUnit,
  .ant-select-selection__choice__content .orgUnit {
    display: none;
  }
  .ant-select-selection-selected-value .name,
  .ant-select-selection__choice__content .name {
    max-width: 100%;
  }
  .optionContent {
    height: initial;
  }
`;

type Props = SelectProps & {
  filter?: EmployeeFilter,
  showLink?: boolean,
  warning?: (employee: Employee) => string,
  disabledEmployee?: (employee: Employee) => boolean
};

export default class extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const {
      filter = {},
      showLink = false,
      disabledEmployee,
      warning,
      ...selectProps
    } = this.props;
    return (
      <Select
        fetch={async params => {
          return await employeeApi.fetchEmployees({
            ...filter,
            ...params
          });
        }}
        placeholder="Выберите сотрудника"
        refetchParams={{ filter }}
        fetchSingle={employeeApi.fetchEmployee}
        notFoundText="Сотрудники не найдены"
        renderOption={(employee: Employee, Option) => {
          const warningText = warning && warning(employee);
          return (
            <Option
              disabled={disabledEmployee && disabledEmployee(employee)}
              key={employee.id}
              value={employee.id}
              employee={employee}
            >
              <OptionContent>
                <OptionLeft>
                  <OptionName>
                    {convertEmployeeToString(employee)}
                    {warningText && (
                      <Popover width={150} zIndex={3000} content={warningText}>
                        <Icon type="warning" style={{ color: '#eccd0e' }} />
                      </Popover>
                    )}
                  </OptionName>
                  <OptionOrgUnit>{employee.orgUnitName}</OptionOrgUnit>
                </OptionLeft>
                {showLink && (
                  <OptionRight width="30px">
                    <Popover
                      zIndex={2000}
                      content="Перейти в карточку сотрудника"
                    >
                      <LinkIcon
                        onClick={() =>
                          window.open(`/admin/employees/${employee.id}`)
                        }
                      />
                    </Popover>
                  </OptionRight>
                )}
              </OptionContent>
            </Option>
          );
        }}
        {...selectProps}
      />
    );
  }
}
