// @flow

import type { AppState, Dispatch } from 'redux';
import { employeeApi } from '../lib/api';

import type { EmployeeBranchPosition } from './../lib/types';
import type { Action } from './redux';

export const FETCH_BRANCH_EMPLOYEES_POSITIONS =
  'branchEmployeesPositions/fetch';
export const FETCHING = 'branchEmployeesPositions/fetching';

export type EmployeeBranchPositionState = {
  data: ?(EmployeeBranchPosition[]),
  fetching: boolean
};

const initialState: EmployeeBranchPositionState = { data: [], fetching: false };

const reducer = (
  state: EmployeeBranchPositionState = initialState,
  { type, payload }: Action
): EmployeeBranchPositionState => {
  switch (type) {
    case FETCH_BRANCH_EMPLOYEES_POSITIONS:
      return { ...payload };
    case FETCHING:
      return { ...payload };
    // case CLEAN_EMPLOYEE:
    //   return null;
    default:
      return state;
  }
};
//
// export const cleanEmployee = () => (dispatch: Dispatch) =>
//   dispatch({
//     type: CLEAN_EMPLOYEE
//   });

export const fetchBranchEmployeesPositions = (branchId: number) => async (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  if (
    getState().branchEmployeesPositions.data?.length > 0 ||
    getState().branchEmployeesPositions.fetching
  )
    return;

  dispatch({ type: FETCHING, payload: { fetching: true } });

  let d = [];
  try {
    d = await employeeApi.fetchBranchEmployeesPositions(branchId);
  } finally {
    dispatch({ type: FETCHING, payload: { fetching: false } });
  }

  let dataSet = new Map();
  let indexMap = new Map();

  d.forEach((employeeBranchPosition: EmployeeBranchPosition, index: number) => {
    if ((employeeBranchPosition.positionTitle || '') === '') return;

    // родительские элементы хранятся с отрицательным индексом
    if (!dataSet.has(employeeBranchPosition.positionTitle)) {
      dataSet.set(employeeBranchPosition.positionTitle, {
        id: -index - 1,
        pId: 0,
        value: employeeBranchPosition.positionTitle,
        title: employeeBranchPosition.positionTitle || '',
        selectable: false
      });
      indexMap.set(employeeBranchPosition.positionTitle, -index - 1);
    }
    const pId = indexMap.get(employeeBranchPosition.positionTitle);

    const title =
      (employeeBranchPosition.positionTitle || '') +
      '(' +
      employeeBranchPosition.orgUnitName +
      ')';
    dataSet.set(title, {
      id: index + 1,
      pId: pId,
      value: employeeBranchPosition,
      title,
      isLeaf: true
    });
  });

  const data = Array.from(dataSet.values());
  dispatch({
    type: FETCH_BRANCH_EMPLOYEES_POSITIONS,
    payload: { data }
  });
};

export default reducer;
