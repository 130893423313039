/* @flow */

import React from 'react';
import { Router } from '@reach/router';
import { Provider } from 'react-redux';
import LocaleProvider from 'antd/lib/locale-provider';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import 'antd/dist/antd.css';
import './assets/styles/index.css';
import './assets/styles/common.css';

import AppLayout from './AppLayout';
import { initStore } from './ducks/redux';
import { Login } from './containers/Login';
import { Logout } from './components';

const { store, persistor } = initStore();

const App = () => (
  <Provider store={store}>
    <LocaleProvider locale={ruRU}>
      <Router>
        <AppLayout default path="/" />
        <Login path="/login" />
        <Logout path="/logout" persistor={persistor} />
      </Router>
    </LocaleProvider>
  </Provider>
);

export default App;
