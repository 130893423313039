import React, {useState} from 'react';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

type Props = {
  onConfirm: Function,
}

export default (props: Props) => {
  const [reason, setReason] = useState(null);
  const [visible, setVisible] = useState(false);
  const onConfirm = e => {
    if (reason === null) {
      notification.warning({
        message: 'Укажите причину отклонения',
      });
      setVisible(true);
    } else {
      props.onConfirm(reason);
      setReason(null);
    }
  };
  return (
    <Popconfirm
      zIndex={0}
      onConfirm={onConfirm}
      visible={visible}
      onVisibleChange={setVisible}
      title={
        <>
          <Input
            placeholder="Причина отклонения"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </>
      }
    >
      <Button>Отклонить</Button>
    </Popconfirm>
  );
}