// @flow
import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {UserAccess, VehicleMonitoring} from './../../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import {monitoringPriceApi, vehicleMonitoringApi} from './../../../lib/api';
import {accessTypeEnum} from './../../../lib/enum';

import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Form} from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import type {AppState} from '../../../ducks/redux';

const { RangePicker } = DatePicker;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  vehicleMonitoring: VehicleMonitoring,
  userAccess: UserAccess[]
};

const InnerForm = ({
  onSubmit,
  onCancel,
  vehicleMonitoring,
  userAccess
}: FormProps) => {
  const getAmountPaymentMonitoring = async (value): Promise<number> => {
    try {
      if (value) {
        const {
          amountPayment
        } = await monitoringPriceApi.getMonitoringPriceByPeriod(value);
        return amountPayment;
      } else {
        return 0;
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      return 0;
    }
  };
  const [amountPaymentMonitoring, setAmountPaymentMonitoring] = useState(
    undefined
  );

  useEffect(() => {
    if (amountPaymentMonitoring === undefined) {
      if (
        vehicleMonitoring &&
        vehicleMonitoring.maintenanceDate &&
        userAccess.includes(accessTypeEnum.viewingPriceLists)
      ) {
        getAmountPaymentMonitoring(vehicleMonitoring.maintenanceDate).then(
          amount => {
            setAmountPaymentMonitoring(amount);
          }
        );
      }
    }
  });

  return (
    <Form initialValues={vehicleMonitoring} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps) => {
        const { handleSubmit, setFieldValue, dirty, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Кол-во ТС" required name="countVehicle">
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          value={value}
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      fast
                      label="Дата оказания"
                      required
                      name="maintenanceDate"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment(value) : null}
                          onChange={async (
                            value: Object,
                            dateString: string
                          ) => {
                            const amount = await getAmountPaymentMonitoring(
                              value
                            );
                            setAmountPaymentMonitoring(amount);
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="startDate"
                      required
                      label="Период оказания"
                    >
                      {({ name, value }) => (
                        <RangePicker
                          format="DD.MM.YYYY"
                          placeholder={['Начало', 'Конец']}
                          value={[
                            values.startDate
                              ? moment.utc(values.startDate)
                              : null,
                            values.endDate ? moment.utc(values.endDate) : null
                          ]}
                          onChange={(value, dateString) => {
                            const [startDate, endDate] = value;
                            const [startDateString, endDateString] = dateString;
                            setFieldValue(
                              'startDate',
                              formatDateTimeToISOString(
                                startDate,
                                startDateString
                              )
                            );
                            setFieldValue(
                              'endDate',
                              formatDateTimeToISOString(endDate, endDateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Сумма" fast required name="paymentAmount">
                      {({ value, name }) => (
                        <InputNumber
                          value={value}
                          min={0}
                          onChange={(value: number) => {
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                    {values.countVehicle && (
                      <span
                        style={{
                          position: 'relative',
                          top: '-18px',
                          fontSize: '10px'
                        }}
                      >
                        Ожидаемая сумма:{' '}
                        {(amountPaymentMonitoring
                          ? amountPaymentMonitoring
                          : 0) * values.countVehicle}
                      </span>
                    )}
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};

type Props = {
  vehicleMonitoringId: ?number,
  vehicleId: ?number,
  userAccess: UserAccess[]
};

type State = {
  vehicleMonitoring: $Shape<VehicleMonitoring>,
  countVehicle: number
};

class VehicleMonitoringForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const vehicleMonitoringId = parseInt(this.props.vehicleMonitoringId, 10);

      if (vehicleMonitoringId) {
        const vehicleMonitoring = await vehicleMonitoringApi.fetchVehicleMonitoring(
          vehicleMonitoringId
        );
        this.setState({
          vehicleMonitoring
        });
      } else {
        const countVehicle = await vehicleMonitoringApi.getVehicleCount();
        this.setState({
          vehicleMonitoring: {},
          countVehicle
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.vehicleMonitoringId) => {
    if (this.props.vehicleId) {
      navigate(
        `/vehicles/${this.props.vehicleId}/maintenance/vehicles-monitoring`
      );
    } else {
      if (id) {
        navigate(`/services/vehicles-monitoring/${id}`);
      } else {
        navigate(`/services/vehicles-monitoring/`, true);
      }
    }
  };

  onSubmit = async (vehicleMonitoring: VehicleMonitoring) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const vehicleMonitoringId = parseInt(this.props.vehicleMonitoringId, 10);
      if (vehicleMonitoringId) {
        await vehicleMonitoringApi.updateVehicleMonitoring(vehicleMonitoring);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        await vehicleMonitoringApi.addVehicleMonitoring(vehicleMonitoring);

        notification.success({
          message: 'Успешное добавление',
          description: `Услуга по мониторингу ТС успешно добавлена`
        });
      }
      this.redirect();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const vehicleMonitoringId = parseInt(this.props.vehicleMonitoringId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { vehicleMonitoring, countVehicle } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId > 0 ? (
                <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>
              ) : (
                <Crumb
                  to={getPathWithHistoryParams('/services/vehicles-monitoring')}
                >
                  Список актов по мониторингу ТС
                </Crumb>
              )}
              {vehicleMonitoringId ? (
                <Crumb>Редактирование акта по мониторингу ТС</Crumb>
              ) : (
                <Crumb>Новый акт по мониторингу ТС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {vehicleMonitoringId
              ? `Акт по мониторингу ТС №${vehicleMonitoringId || ''}`
              : 'Новый акт по мониторингу ТС'}
          </h1>
        </StyledPanel>
        <InnerForm
          vehicleMonitoring={{
            paymentAmount: 0,
            countVehicle,
            ...vehicleMonitoring
          }}
          userAccess={this.props.userAccess}
          onSubmit={this.onSubmit}
          onCancel={() =>
            this.redirect(vehicleMonitoring.id || vehicleMonitoringId)
          }
        />
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access
}))(VehicleMonitoringForm);
