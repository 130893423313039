// @flow
import React, { Component } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import moment from 'moment';
import qs from 'query-string';

import { Button, Popconfirm, DatePicker } from 'antd';
import notification from 'antd/lib/notification';

import { osagoMultiplier } from '../../../lib/api';
import type { FetchListParams } from '../../../lib/api';
import {
  getListInitialState,
  setQueryParams,
  formatDateTimeToString,
  formatDateTimeToISOString,
  navigate
} from '../../../lib/helpers';
import type {
  OsagoPowerMultiplier,
  ListState,
  UserAccess
} from './../../../lib/types';

import { Header, Section } from '../../../components/layout';
import { notificationLoading } from '../../../components/Notifications';
import { Icon, Operations, Table } from '../../../components/ui';

import { HeaderTabs } from '../components';
import { withUserAccess } from './../../withUserAccess';
import canAddOsagoMultiplier from './../accessRight';

import Filter, { type PowerFilterParams } from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[]
};
type State = ListState<OsagoPowerMultiplier> & {
  // Хранится значение для проверки пересечения до создания документа
  startDate: moment,
  filter: PowerFilterParams
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class OsagoPowerMultiplierList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    startDate: moment.utc(new Date()).startOf('day'),
    filter: {}
  };

  fetchOsagoPowerMultiplier = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    this.setState({ loading: true });
    const {
      data,
      totalCount
    } = await osagoMultiplier.fetchOsagoPowerMultiplier({
      page,
      ...params,
      // $FlowFixMe
      ...this.state.filter
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  async componentDidMount() {
    try {
      const { page, ...filter } = qs.parse(window.location.search);

      this.setState(
        {
          filter: { ...filter },
          page
        },
        () => this.fetchOsagoPowerMultiplier(page)
      );

      if (this.canAdd()) {
        this.columns.push({
          title: '',
          align: 'right',
          stopPropagation: true,
          width: 50,
          render: record => (
            <Operations>
              <Popconfirm
                overlayStyle={{
                  zIndex: 2000
                }}
                placement="left"
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () =>
                  await this.deletePowerMultiplier(record.id)
                }
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  deletePowerMultiplier = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await osagoMultiplier.deleteOsagoPowerMultiplier(id);
      await this.fetchOsagoPowerMultiplier(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Начало',
      key: 'startDate',
      width: '130px',
      render: (record: any) =>
        formatDateTimeToString(record.startDate, 'DD.MM.YYYY')
    },
    {
      title: 'Окончание',
      key: 'endDate',
      width: '130px',
      render: (record: any) =>
        formatDateTimeToString(record.endDate, 'DD.MM.YYYY')
    },
    {
      title: 'До 50 л.с включительно',
      key: 'lessThan50',
      dataIndex: 'lessThan50'
    },
    {
      title: '50 - 70 л.с  включительно',
      key: 'from50To70',
      dataIndex: 'from50To70'
    },
    {
      title: '70 - 100 л.с включительно',
      key: 'from70To100',
      dataIndex: 'from70To100'
    },
    {
      title: '100 - 120 л.с включительно',
      key: 'from100To120',
      dataIndex: 'from100To120'
    },
    {
      title: '120 - 150 л.с включительно',
      key: 'from120To150',
      dataIndex: 'from120To150'
    },
    {
      title: 'Свыше 150 л.',
      key: 'greaterThan150',
      dataIndex: 'greaterThan150'
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access =>
      canAddOsagoMultiplier.includes(access)
    );

  onConfirmCheck = async () => {
    try {
      const fetchParams = {
        startDate: formatDateTimeToISOString({}, moment(this.state.startDate))
      };
      const res = await osagoMultiplier.fetchDateIntersectionMultiplier(
        'power',
        fetchParams
      );
      if (!res) {
        navigate(`/admin/osago-multiplier/power/new`, false, {
          state: fetchParams
        });
      } else {
        notification.warning({
          message: 'Ошибка',
          description: 'Выбранная дата уже входит в существующий диапазон!'
        });
      }
    } catch (error) {
      notification.warning({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: PowerFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetchOsagoPowerMultiplier(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetchOsagoPowerMultiplier(page));
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      startDate,
      filter
    } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Коэффициенты ОСАГО мощностей</h1>}
          right={
            canAdd && (
              <Popconfirm
                title={
                  <>
                    <p>Дата начала действия</p>
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={moment.utc(startDate)}
                      onChange={(value: moment, dateString: string) => {
                        this.setState({
                          startDate: value
                        });
                      }}
                    />
                  </>
                }
                onConfirm={this.onConfirmCheck}
              >
                <Link to="/admin/osago-multiplier/power/new">
                  <Button type="primary">Создать</Button>
                </Link>
              </Popconfirm>
            )
          }
        />
        <HeaderTabs selectedTab="power" />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/osago-multiplier/power/edit/${record.id}`)
                  : null
            })}
            columns={this.columns}
            fetch={this.fetchOsagoPowerMultiplier}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(OsagoPowerMultiplierList);
