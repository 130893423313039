// @flow
import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, {Component, useEffect, useState} from 'react';
import {Grid, GridItem, Section} from '../../../../components/layout';
import {notificationLoading} from '../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../components/ui';
import {federalHighwayCalculationApi, federalHighwayCalculationMonthApi, vehiclePlanApi} from '../../../../lib/api';
import {
  accessTypeEnum,
  calculationStatusEnum,
  monthsNamesTranslitEnum,
  monthsNumbersEnum,
  vehiclePlanStatusEnum,
} from '../../../../lib/enum';
import {getListInitialState, toLocalStringRu} from '../../../../lib/helpers';
import type {CalculationStatus, FederalHighwayCalculation, ListState, UserAccess, VehiclePlan} from '../../../../lib/types';
import {withUserAccess} from '../../../withUserAccess';
import {canEditBudget} from '../accessRight';
import type {VehicleListFilterParams} from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';
import {COGNOS_DELTA, COLUMNS, filteredData, itogCalculation, MONTH} from '../lib';
import {headerPanel} from './components/Common';
import styled from 'styled-components';
import TotalBlock from './components/TotalBlock';
import Field from '../../../../components/card/Field';
import InfoBlock from '../components/InfoBlock';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<FederalHighwayCalculation> & {
  vehiclePlan: VehiclePlan,
  columns: any,
  visible: boolean,
  rowIndex?: number,
  monthIndex?: number,
  filter: VehicleListFilterParams,
  federalHighwayCalculation: ?FederalHighwayCalculation,
  cognosData: ?number,
  openPeriod?: {
    start: string,
    end: string,
  }
};

const FederalHighway = class extends Component<Props, State> {
  async componentDidMount() {
    this.getData();
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }
  isRangeMonths = (monthIndex: number) => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };
  // дата после списания
  afterPlannedWriteoffDate = (
    monthIndex: number,
    plannedWriteoffDate?: ?string,
  ) => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(
        plannedWriteoffDate,
        'month',
      );
    }
    return false;
  };
  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };
  fetch = async () => {
    const {vehiclePlanId} = this.props;
    this.setState({loading: true});
    try {
      let {data} = await federalHighwayCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      data.forEach(item => {
        item.totalPlannedKilometrage = item.months?.reduce((sum, month) => sum + month.plannedKilometrage, 0);
      });
      this.setState({data});
    } finally {
      this.setState({loading: false});
    }
  };
  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };
  handleSave = async (federalHighwayCalculation: FederalHighwayCalculation) => {
    const {data, monthIndex = 0} = this.state;
    this.setState({loading: true});
    const findedRowIndex = data.findIndex(
      item => item.id === federalHighwayCalculation.id,
    );
    try {
      if (federalHighwayCalculation && findedRowIndex !== -1) {
        await federalHighwayCalculationMonthApi.updateCalculations(
          federalHighwayCalculation.months[monthIndex],
        );
        data[findedRowIndex] = await federalHighwayCalculationApi.get(
          federalHighwayCalculation.id,
        );
        this.setState({data});
      }
    } catch (error) {
      notification.warning({message: 'Не удалось обновить данные' + error});
    } finally {
      this.setState({
        loading: false,
        visible: false,
        rowIndex: undefined,
        federalHighwayCalculation: null,
        monthIndex: undefined,
      });
    }
  };
  handleCancel = () => {
    this.setState({
      visible: false,
      rowIndex: undefined,
      monthIndex: undefined,
      federalHighwayCalculation: null,
    });
  };
  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await federalHighwayCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  calculate = async () => {
    const {vehiclePlanId} = this.props;
    const {data} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      if (data.length === 0) {
        await federalHighwayCalculationApi.calculate(vehiclePlanId);
      } else {
        await federalHighwayCalculationApi.updateCalculations(vehiclePlanId);
      }
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  print = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'print',
      });
      await federalHighwayCalculationApi.print(vehiclePlanId);
    } catch (error) {
      notification.error({message: 'Не удалось выполнить'});
    } finally {
      notification.close('print');
    }
  };
  isDraft = () => this.state.vehiclePlan?.federalHighwayCalculationStatus === calculationStatusEnum.draft;
  applyFilter = (filter: VehicleListFilterParams) => this.setState({filter});
  cleanFilter = () => this.setState({filter: {}});
  canEdit = () => this.props.userAccess.some(access =>
    [
      accessTypeEnum.editingBudgetCopy,
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
    ].includes(access));
  state = {
    vehiclePlan: null,
    ...getListInitialState(),
    columns: [
      ...COLUMNS,
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'План. километраж',
            dataIndex: `months[${monthIndex}].plannedKilometrage`,
            onCell: (
              federalHighwayCalculation: FederalHighwayCalculation,
              rowIndex: number,
            ) => {
              const {openPeriod} = this.state;
              const date = moment().month(monthIndex).format();
              return {
                onClick:
                  this.isDraft() &&
                  this.isRangeMonths(monthIndex) &&
                  this.canEdit() &&
                  !this.afterPlannedWriteoffDate(
                    monthIndex,
                    federalHighwayCalculation.selfVehiclePlanVehicle?.vehicle
                      ?.plannedWriteoffDate ||
                    federalHighwayCalculation.selfVehiclePlanVehicle
                      ?.plannedWriteoffDate,
                  ) &&
                  openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)
                    ? () => {
                      this.setState({
                        rowIndex,
                        monthIndex,
                        visible: true,
                        federalHighwayCalculation: {
                          ...federalHighwayCalculation,
                        },
                      });
                    }
                    : undefined,
              };
            },
            render: (
              plannedKilometrage: number,
              federalHighwayCalculation: FederalHighwayCalculation,
            ) => (
              <span
                className={
                  this.isDraft() &&
                  this.isRangeMonths(monthIndex) &&
                  !this.afterPlannedWriteoffDate(
                    monthIndex,
                    federalHighwayCalculation?.selfVehiclePlanVehicle?.vehicle
                      ?.plannedWriteoffDate ||
                    federalHighwayCalculation?.selfVehiclePlanVehicle
                      ?.plannedWriteoffDate,
                  )
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(plannedKilometrage)}
              </span>
            ),
          },
          {
            title: 'Стоимость',
            className: 'primary-background',
            dataIndex: `months[${monthIndex}].cost`,
            render: cost => toLocalStringRu(cost, {minimumFractionDigits: 2}),
          },
        ],
      })),
      {
        title: 'Итого План. километраж',
        width: 200,
        dataIndex: 'totalPlannedKilometrage',
        render: sum => toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
      {
        title: 'Итого стоимость',
        width: 200,
        key: 'sum',
        dataIndex: 'sum',
        render: sum => toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
    ],
    filter: {},
    visible: false,
    rowIndex: undefined,
    monthIndex: undefined,
    federalHighwayCalculation: null,
    openPeriod: null,
  };
  render() {
    const {vehiclePlanId} = this.props;
    const {
      monthIndex = 0,
      columns,
      visible,
      loading,
      filter,
      data,
      federalHighwayCalculation,
      vehiclePlan,
      cognosData,
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);

    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : Math.abs(itog?.sum - cognosData) > COGNOS_DELTA;
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'Планирование потребности проезда по федеральным трассам',
        })}
        <Section>
          <InfoBlock
            links={{'Стоимость пропусков': '/admin/federal-highway-fare/'}}
          />
          <div style={{padding: '16px 16px 0px'}}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              vehiclePlanId={vehiclePlanId}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            scroll={{
              x: 4000,
              y: 'calc(100vh - 520px)',
            }}
            columns={columns}
            pagination={{pageSize: 50}}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <ModalFederalHighWay
            visible={visible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            monthIndex={monthIndex || 0}
            federalHighwayCalculation={federalHighwayCalculation}
          />
        </Section>
        {
          <Section style={{padding: '16px'}}>
            <Wrapper>
              {isCognosStatus && (
                <TotalBlock
                  filteredDataTotalSum={itog?.sum}
                  controllerName="federalHighwayCalculation"
                  vehiclePlanId={vehiclePlanId}
                  setCognosData={cognosData => this.setState({cognosData})}
                />
              )}
              <ButtonsRow>
                {this.isDraft() && canEditBudget(this.props.userAccess) && (
                  <>
                    {!isCognosStatus && !loading && (
                      <Button type="primary" onClick={this.calculate}>
                        {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                      </Button>
                    )}
                    <Button
                      style={{marginLeft: '16px'}}
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.calculationDone)
                      }
                      disabled={approveButtonIsDisabled}
                      title={
                        approveButtonIsDisabled
                          ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                          : undefined
                      }
                    >
                      Утвердить
                    </Button>
                  </>
                )}
                {!planApproved &&
                  canEditBudget(this.props.userAccess) &&
                  !this.isDraft() &&
                  vehiclePlan?.status !==
                  vehiclePlanStatusEnum.approvedAfterCognos && (
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.draft)
                      }
                    >
                      Редактировать
                    </Button>
                  )}
                <Button style={{marginLeft: '16px'}} onClick={this.print}>
                  Печать
                </Button>
              </ButtonsRow>
            </Wrapper>
          </Section>
        }
      </>
    );
  }
};

type ModalFederalHighwayProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  monthIndex: number,
  federalHighwayCalculation: ?FederalHighwayCalculation
};

const ModalFederalHighWay = (props: ModalFederalHighwayProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    monthIndex,
    federalHighwayCalculation,
  } = props;
  const [localFederalHighwayCalculation, setFederalHighway] = useState(
    cloneDeep(federalHighwayCalculation),
  );
  const onOk = () => {
    handleSave(localFederalHighwayCalculation);
  };
  const onCancel = () => {
    handleCancel();
  };

  useEffect(() => {
    if (federalHighwayCalculation) {
      setFederalHighway(cloneDeep(federalHighwayCalculation));
    }
  }, [federalHighwayCalculation]);

  return (
    <Modal
      forceRender
      destroyOnClose
      width={600}
      title="Редактирование"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
    >
      <Grid gutter="16px">
        <GridItem>
          <Field label="Месяц">
            {monthsNamesTranslitEnum[monthsNumbersEnum[monthIndex + 1]]}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Марка и модель ТС">
            {`${federalHighwayCalculation?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.brandName}
            ${federalHighwayCalculation?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.name}`}
          </Field>
        </GridItem>
        <GridItem>
          Планируемый пробег в месяц по трассам федерального значения
          <InputNumber
            defaultValue={
              federalHighwayCalculation?.months[monthIndex]
                .plannedKilometrage ?? 0
            }
            min={0}
            style={{width: '100%'}}
            onChange={kilometrage => {
              if (localFederalHighwayCalculation) {
                localFederalHighwayCalculation.months[
                  monthIndex
                  ].plannedKilometrage = +kilometrage;
                setFederalHighway(localFederalHighwayCalculation);
              }
            }}
            onPressEnter={onOk}
          />
        </GridItem>
      </Grid>
    </Modal>
  );
};

export default withUserAccess(FederalHighway);
