// @flow
import React, { useState, useCallback } from 'react';

import { measuringDeviceCertificationCostApi } from '../../lib/api';
import type {
  UserAccess,
  MeasuringDeviceCertificationCost
} from '../../lib/types';
import { formatDateTimeToString, navigate } from '../../lib/helpers';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { withUserAccess } from './../withUserAccess';

import { addAccessRight } from './accessRight';
import { approveTypes } from '../../lib/enum';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';

const { Field } = Card;

type InnerProps = {
  data: MeasuringDeviceCertificationCost
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/measuring-device-certification-cost/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Филиал">{props.data.orgUnitName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Стоимость за одну тарировку без НДС, руб">
            {props.data.cost}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата начала">
            {formatDateTimeToString(props.data.startDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата окончания">
            {formatDateTimeToString(props.data.endDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Статус">{approveTypes[props.data.status]}</Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: MeasuringDeviceCertificationCost, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await measuringDeviceCertificationCostApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await measuringDeviceCertificationCostApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость тарировки цистерн',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
