// @flow
import React, { useCallback, useState } from 'react';
import { navigate } from '../../lib/helpers';
import { passTariffApi } from '../../lib/api';
import { type PassTariff } from '../../lib/types';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import { InnerForm } from './components';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/pass-tariff';

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

export default (props: PageProps) => {
  const [data: PassTariff, setData] = useState({
    id: props.id
  });

  const onFetch = useCallback(async () => {
    !!props.id && setData(await passTariffApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: PassTariff) => {
    let id = payload.id;
    if (id) {
      await passTariffApi.update(payload);
    } else {
      let data = await passTariffApi.add(payload);
      id = data.id;
    }
    await navigate(`${entryPointPath}/${id}`);
  };

  const onCancel = async () => {
    if (data.id) {
      await navigate(`${entryPointPath}/${data.id}`);
    } else {
      await navigate(`${entryPointPath}`, true);
    }
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          ...breadCrumbs,
          {
            name: `${props.id || 'Новая запись'}`,
            parent: 'Тарифы на пропуска'
          }
        ],
        mainHeader: 'Тарифы на пропуска'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
