import styled from 'styled-components';
import Input from 'antd/lib/input';
import VehicleStatus from '../VehicleStatus';

export const TabWrapperCalculation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
`;

export const VehicleCalculationCard = styled.div`
  width: 100%;
  height: 100%;
  min-height: 56px;
  background: ${({ isActive, disabled }) =>
    isActive && !disabled ? '#bed8f3' : '#F0F4F8'};
  border-radius: 3px;
  padding: 13px 8px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  box-shadow: ${({ isActive, disabled }) =>
    isActive && !disabled ? '0px 2px 4px #C0CCD7' : 'none'};

  ${({ editable }) =>
    editable &&
    `
    &:hover {
      box-shadow: 0px 2px 4px #C0CCD7;
      cursor: pointer;
    }`};

  ${({ disabled }) =>
    disabled &&
    `
  pointer-events: none;
    opacity: 0.4;
    `}

  > p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: black;
  }
`;

export const WrapperCalculation = styled.div`
  border: 1px solid #c0ccd7;
  display: flex;
  border-radius: 3px;
  flex-direction: column;
  overflow: hidden;
`;

export const HeaderCalculation = styled.div`
  width: 100%;
  min-height: 30px;
  padding: 8px 8px 0;
  box-sizing: border-box;
  font-size: 14px;
  background: #f0f4f8;
`;

export const VehicleCalculationCardWrapper = styled.div`
  padding: 8px;
`;

export const SpoilerCalculationContent = styled.div`
  padding: 8px;
`;

export const VehicleCalculationInfo = styled.div`
  display: flex;
  & .vehicle-info {
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 12px;
    padding: 2px 0;
  }
`;

export const StatusCalculation = styled(VehicleStatus)`
  align-items: flex-start;
`;

export const SearchInputCalculation = styled(Input)`
  max-width: 200px;
  ${({ withtabs }) => withtabs === 'true' && 'margin-left: 20px;'}
`;
