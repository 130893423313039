// @flow

import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from '@reach/router';
import uniq from 'lodash/uniq';
import qs from 'query-string';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import type {
  ShiftListItem,
  OrgUnitNode,
  ListState,
  Shift
} from './../../lib/types';
import { Section } from './../../components/layout';
import {
  formatDateTimeToString,
  getListInitialState,
  setQueryParams,
  navigate
} from './../../lib/helpers';
import { Table } from './../../components/ui';
import Filter from './Filter';
import Header from '../../components/layout/Header';
import type { FetchListParams } from '../../lib/api';
import { shiftApi } from '../../lib/api';
import type { ShiftFilterParams } from './Filter';
import type { AppState } from '../../ducks/redux';
import { setFilter } from '../../ducks/persistFilters';
import type { PersistFilterPayload } from '../../ducks/persistFilters';

moment.locale('ru');

type Props = {
  location: Location & { state: { page: number } },
  filter: ShiftFilterParams,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Shift>;

const filterPath = window.location.pathname;

class ShiftList extends Component<Props, State> {
  state = {
    ...getListInitialState()
  };

  static defaultProps = {
    location: {}
  };

  columns: Array<Object> = [
    {
      title: 'Дата',
      dataIndex: 'date',
      sorterKey: 'date',
      sorter: true,
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (date: string, record: Shift) => (
        <Link to={`/shifts/${record.id}`}>
          {formatDateTimeToString(date, 'DD MMMM YYYY')}
        </Link>
      )
    },
    {
      title: 'Служба',
      render: (record: ShiftListItem): ?string =>
        record.orgUnits &&
        uniq(record.orgUnits.map((orgUnit: OrgUnitNode) => orgUnit.name)).join(
          ', '
        )
    },
    {
      title: 'Входящие заявки',
      dataIndex: 'ordersCount',
      sorterKey: 'ordersCount',
      sorter: true
    },
    {
      title: 'Назначено ТС',
      sorter: true,
      dataIndex: 'tripsCount',
      sorterKey: 'tripsCount'
    },
    {
      title: 'Выписано ПЛ',
      dataIndex: 'tripsOpenCount',
      sorterKey: 'tripsOpenCount',
      sorter: true
    }
  ];

  handleRowClick = (id: number) => navigate(`/shifts/${id}`);

  fetchShifts = async (
    page: number = 1,
    params: FetchListParams<ShiftFilterParams> = {}
  ) => {
    const { filter } = this.props;
    this.setState({ loading: true });
    const { data, totalCount } = await shiftApi.fetchShifts({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: ShiftFilterParams) => {
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchShifts();
  };

  applyFilter = async (filter: ShiftFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchShifts();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchShifts(page);
  }

  render() {
    const { location, filter }: Props = this.props;
    const { data, pageSize, totalCount, page, loading } = this.state;
    return (
      <>
        <Header left={<h1>Наряды</h1>} />
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            rowKey="id"
            fetch={this.fetchShifts}
            loading={loading}
            columns={this.columns}
            data={data}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filter: state.persistFilters[filterPath] || {}
  }),
  {
    setFilter
  }
)(ShiftList);
