// @flow
import {Link} from '@reach/router';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React, {Component} from 'react';
import styled from 'styled-components';

import {Section} from '../../../../../components/layout';
import {notificationLoading} from '../../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../../components/ui';
import Popover from '../../../../../components/ui/Popover';
import {TableHeader} from '../../../../../components/ui/Table';

import {tireCalculationApi, vehiclePlanApi} from '../../../../../lib/api';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum} from '../../../../../lib/enum';
import {getListInitialState, plus, toLocalStringRu} from '../../../../../lib/helpers';
import {printNotification} from '../../../../../lib/notificationWrapper';
import type {CalculationStatus, ListState, TireCalculation, UserAccess, VehiclePlan} from '../../../../../lib/types';
import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget} from '../../accessRight';

import {headerPanel} from '../../components/Common';

import {COGNOS_DELTA, COLUMNS, MONTH} from '../../lib';

import type {ParamsFilter} from '../components/Filter';
import Filter from '../../components/FilterVehicleList';
import TotalBlock from '../../Budget/components/TotalBlock';
import {TiresEditingModal} from './TiresEditingModal';
import InfoBlock from '../../components/InfoBlock';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../../contractVehicle/fixed/lib';
import moment from 'moment/moment';
import isEmpty from 'lodash/isEmpty';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<TireCalculation> & {
  columns: any,
  tireCalculation: ?TireCalculation,
  filter: ParamsFilter,
  vehiclePlan: VehiclePlan,
  dataRow?: ?TireCalculation,
  rowIndex?: ?number,
  monthIndex?: ?number,
  modalVisible: boolean,
  cognosData: ?number,
  openPeriod?: {
    start: string,
    end: string,
  }
};

const StyledIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -4px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  padding: 15px 15px 0;
`;

const List = class extends Component<Props, State> {
  originalData: MaintenanceOperationContractCalculation;

  state = {
    ...getListInitialState(),
    columns: [
      ...COLUMNS.map(column =>
        column.key === 'licensePlate'
          ? {
            ...column,
            render: (licensePlate: string, record: TireCalculation) => {
              return record.hasNoCost
                ? licensePlate && (
                <Popover
                  placement="right"
                  width={150}
                  zIndex={3000}
                  content={
                    'Необходимо проверить наличие аналога шины или стоимости в справочнике МТРиО'
                  }
                >
                  <Link
                    to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                  >
                    {licensePlate}{' '}
                  </Link>
                  <StyledIcon
                    type="warning"
                    style={{color: '#eccd0e'}}
                  />
                </Popover>
              )
                : licensePlate && (
                <Link
                  to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                >
                  {licensePlate}
                </Link>
              );
            },
          }
          : {...column},
      ),
      ...MONTH.map((month, index) => ({
        title: month.title,
        children: [
          {
            title: (
              <TableHeader width="120px">
                Кол-во автошин подлежащих замене
              </TableHeader>
            ),
            dataIndex: `months[${index}].count`,
            onCell: (data: TireCalculation, rowIndex: number) =>
              this.onCell(data, rowIndex, index),
          },
          {
            title: (
              <TableHeader width="120px">
                Стоимость замены всех автошин
              </TableHeader>
            ),
            dataIndex: `months[${index}].cost`,
            render: (cost: any) => {
              return toLocalStringRu(cost, {minimumFractionDigits: 2}) ?? 0;
            },
            onCell: (data: TireCalculation, rowIndex: number) =>
              this.onCell(data, rowIndex, index),
          },
        ],
      })),
      {
        title: 'Итог, руб.',
        dataIndex: 'sum',
        render: sum => toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
    ],
    filter: {},
    vehiclePlan: null,
    springPassCalculation: null,
    modalVisible: false,
    openPeriod: null,
  };

  onCell = (data: TireCalculation, rowIndex: number, monthIndex: number) => ({
    onClick: () => {
      if (!this.canEdit()) {
        return;
      }
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (
        data.id && vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos &&
          vehiclePlan?.tireCalculationStatus === calculationStatusEnum.draft &&
          dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({
          dataRow: data,
          rowIndex,
          monthIndex,
          modalVisible: true,
        });
      } else {
        return;
      }
    },
  });

  onCancel = () =>
    this.setState({
      rowIndex: null,
      monthIndex: null,
      dataRow: null,
      modalVisible: false,
    });

  onSave = async (monthData: TireCalculation) => {
    const {rowIndex = 0} = this.state;
    this.setState({loading: true});
    try {
      if (monthData && Number.isInteger(rowIndex)) {
        await tireCalculationApi.updateMonth(monthData);
        await this.fetch();
      }
    } catch (error) {
      notification.warning({
        message: 'Не удалось обновить данные',
        description: error.message,
      });
    } finally {
      this.setState({
        loading: false,
        modalVisible: false,
        rowIndex: null,
        dataRow: null,
      });
    }
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    let openPeriod = null;
    if (this.props.vehiclePlanId) {
      openPeriod = await getOpenPeriod(this.props.vehiclePlanId);
    }
    this.setState({filter, openPeriod}, () => this.getData());
  }

  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };

  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };

  fetch = async () => {
    const {vehiclePlanId} = this.props;

    const itogCalculation = (data: any) => {
      const itog = {
        months: Array(12)
        .fill()
        .map(() => ({cost: 0})),
        sum: 0,
      };
      data.forEach((item: any) => {
        itog.sum = plus(item.sum || 0, itog.sum);
        item.months.forEach((month: any, index) => {
          itog.months[index].cost = plus(
            itog.months[index].cost,
            month.cost || 0,
          );
        });
      });
      return itog;
    };

    try {
      let {data} = await tireCalculationApi.fetch({
        'selfVehiclePlanVehicle.vehicle.vehicleModel.type': this.state.filter.type,
        'selfVehiclePlanVehicle.vehicle.vehicleModel.id': this.state.filter.vehicleModelId,
        'selfVehiclePlanVehicle.vehicle.id': this.state.filter.vehicleId,
        'selfVehiclePlanVehicle.vehicle.yearIssued': this.state.filter.yearIssued,
        'selfVehiclePlanVehicle.vehicle.hideEmpty': this.state.filter.hideEmpty,
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      if (data.length) {
        data.forEach((details, index) => {
          data[index].months = details.months.sort((a, b) => a.month - b.month);
        });
        data = [...data, itogCalculation(data)];
      }

      this.setState({data});
      this.originalData = this.state.data;
    } catch (e) {
      notification.error({message: 'Не удалось запросить данные'});
    }
  };

  applyFilter = (filter: any) => {
    let noFilter = false;
    Object.values(filter).forEach(value => {
      if (value === undefined) noFilter = true;
    });

    if (noFilter) {
      this.cleanFilter();
      return;
    }
    const filtered = this.originalData
    .filter(selfVehicle =>
      !!filter.vehicleId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle.vehicleId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.vehicleModelId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleModelId ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModelId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.type && selfVehicle.selfVehiclePlanVehicle
        ? filter.type ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModel.type
        : true,
    )
    .filter(selfVehicle =>
      !!filter.yearIssued && selfVehicle.selfVehiclePlanVehicle
        ? filter.yearIssued ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.yearIssued
        : true,
    )
    .filter(selfVehicle => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });

    if (filtered.length !== 0) {
      this.setState({data: [...filtered]});
    } else {
      this.cleanFilter();
    }
  };

  cleanFilter = async () => {
    this.setState({filter: {}}, this.getData);
  };

  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await tireCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await tireCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.tireCalculationStatus ===
    calculationStatusEnum.draft;

  handlePrint = () => {
    printNotification(async () => {
      await tireCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter,
      );
    });
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  render() {
    const {vehiclePlanId} = this.props;
    const {
      columns,
      loading,
      data,
      vehiclePlan,
      modalVisible,
      monthIndex = 0,
      dataRow,
      cognosData,
    } = this.state;
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : Math.abs((data[data.length - 1]?.sum || 0) - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'Автошины',
          passTab: true,
        })}
        <Section>
          <InfoBlock links={{МТРиО: '/admin/mdm'}} />
          <FilterWrapper>
            <Filter
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              filter={this.state.filter}
              vehiclePlanId={vehiclePlanId}
              vehicleType
            />
          </FilterWrapper>
          <AntTable
            loading={loading}
            data={[...data]}
            columns={columns}
            bordered
            scroll={{
              x: 5000,
              y: 'calc(100vh - 560px)',
            }}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <TiresEditingModal
            visible={modalVisible}
            data={dataRow}
            monthIndex={monthIndex}
            handleCancel={this.onCancel}
            handleSave={this.onSave}
          />
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={
                  data.length ? data[data.length - 1]?.sum : 0
                }
                controllerName="tireCalculation"
                vehiclePlanId={vehiclePlanId}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            <ButtonsRow>
              {canEditBudget(this.props.userAccess) && (
                <>
                  {this.isDraft() && (
                    <>
                      {!isCognosStatus && !loading && (
                        <Button type="primary" onClick={this.calculate}>
                          {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!planApproved &&
                    !this.isDraft() &&
                    vehiclePlan?.status !==
                    vehiclePlanStatusEnum.approvedAfterCognos && (
                      <>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.changeStatus(calculationStatusEnum.draft)
                          }
                        >
                          Редактировать
                        </Button>
                      </>
                    )}
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Wrapper>
        </Section>
      </>
    );
  }
};

export default withUserAccess(List);
