/* OrderCard.js | Page | Заявка */
// @flow
import Button from 'antd/lib/button';
import ButtonGroup from 'antd/lib/button/button-group';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { notificationLoading } from '../../components/Notifications';
import type { DropdownType } from '../../components/ui/Dropdown';
import ListTable from '../../components/ui/ListTable';
import type { OrderStatusOptions } from '../../ducks/order';
import { changeOrderState, changeStatus, cleanOrder, fetchOrder, updateOrder } from '../../ducks/order';
import type { AppState } from '../../ducks/redux';
import { reasonsForRejectinfOfOrderApi } from '../../lib/api';
import {
  accessTypeEnum,
  orderObjectives,
  orderStatusEnum,
  orderStatuses,
  orderTypes,
  ownerTypes,
  trailerVehicleTypes,
  vehicleGroups,
  vehicleTypes,
} from '../../lib/enum';
import { convertEmployeeToString, formatDateRangeString, getPathWithHistoryParams, navigate } from '../../lib/helpers';
import type { ContractVehicleOrder, Employee, Order, UserAccess, Vehicle, VehicleForSelection } from '../../lib/types';
import { AccessDenied, Card as CardComponent, Form, VehicleSelection, WaypointsViewer } from './../../components';
import { Header, Panel, Section, SectionTitle } from './../../components/layout';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import Grid, { GridItem } from './../../components/layout/Grid';
import { ButtonOperations, Dropdown, Select } from './../../components/ui';
import { contractVehicleOrderApi, orderApi, orderGpmApi } from './../../lib/api';
import { ContractVehicleOrderCard } from './../ContractVehicleOrder';
import { withUserAccess } from './../withUserAccess';
import HistorySection from './components/HistorySection';
import OrderCardActionsDropdown from './components/OrderCardActionsDropdown';
import { canCopyOrder, getSelectionVehicles } from './lib';

const SectionContent = styled.div`
  padding: 16px;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
`;

const BusinessTrip = styled.span`
  line-height: 1.8;
  background: #838d96;
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: normal;
  padding: 3px 5px;
`;

const Operations = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const {Field} = CardComponent;
const {TextArea} = Input;
const {Option} = Select;

const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];

// Список доступов, у которых есть разрешение на просмотр информации в карточке
const containerAccess = [
  ...adminAccess,
  accessTypeEnum.creatingOrder,
  accessTypeEnum.approvingGpmOrder,
  accessTypeEnum.handlingOrder,
  accessTypeEnum.viewingOrdersBranch,
  accessTypeEnum.viewingAllOrders,
  accessTypeEnum.viewingOrdersServiceOnly,
];

type Props = {
  fetchOrder: Function,
  orderId: number,
  employeeId: number,
  order: Order,
  updateOrder: (order: Order) => void,
  changeStatus: (id: number, options: OrderStatusOptions) => void,
  cleanOrder: Function,
  userAccess: UserAccess[],
  currentEmployeeId: number,
  vehicles: VehicleForSelection[],
  changeOrderState: (orderData: any) => void
};

type State = {
  touched: boolean,
  vehicleId?: number,
  order: ?Order,
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  showApproveByContractVehicleOrder: boolean,
  contractVehicleOrder: ?ContractVehicleOrder,
  copyOrderDate: ?string,
  vehiclesLoading: boolean,
  loading: boolean,
  contractDriverPhoneNumber: number,
  contractDriverFullName: string,
  keyContractDriverFullName: boolean,
  keyContractDriverPhoneNumber: boolean,
  showReworkReasonModal: boolean,
  typeOfModal: 'cancel' | 'rework',
  reworkReasons: []
};

export class OrderCard extends Component<Props, State> {
  static defaultProps = {
    contractVehicleOrder: {},
  };
  dropdown: ?DropdownType;
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      order: this.props.order,
      freeVehicles: [],
      freeTrailers: [],
      showApproveByContractVehicleOrder: false,
      contractVehicleOrder: null,
      copyOrderDate: null,
      vehiclesLoading: false,
      loading: false,
      filteredVehicles: this.props.vehicles,
      keyContractDriverPhoneNumber: false,
      keyContractDriverFullName: false,
      contractDriverPhoneNumber: '',
      contractDriverFullName: '',
      showReworkReasonModal: false,
      typeOfModal: 'cancel',
      reworkReasons: [],
    };
  }
  componentDidMount() {
    const {orderId} = this.props;
    this.props.cleanOrder();
    reasonsForRejectinfOfOrderApi
    .fetch()
    .then(({data}) => this.setState({reworkReasons: data}));
    if (!this.hasAccess()) {
      return;
    }
    if (orderId) {
      this.fetchOrder(orderId);
    }
  }

  fetchOrder = async (orderId: number) => {
    try {
      const order = await this.props.fetchOrder(orderId);
      if (order && order.contractVehicleOrderId) {
        const contractVehicleOrder = await contractVehicleOrderApi.fetchContractVehicleOrder(
          order.contractVehicleOrderId,
        );
        this.setState({
          contractVehicleOrder,
        });
      }
      if (
        order &&
        ((!order.isGpm &&
            [
              orderStatusEnum.created,
              orderStatusEnum.approvedByMainEngineer,
            ].includes(order.status)) ||
          (order.isGpm &&
            order.status === orderStatusEnum.approvedByMainEngineer))
      ) {
        await this.fetchFreeVehicles();
      }
      this.setState({order});
    } catch (error) {
      notification.error({
        message: 'Ошибка при загрузке заявки',
        description: error.message || 'Заявка не найдена',
      });
      await navigate('/orders', true);
    }
  };

  componentDidUpdate(prevState: State, prevProps: Props) {
    if (!isEqual(prevProps.order, this.props.order)) {
      if (!isEqual(this.props.order, this.state.order)) {
        this.setState({order: this.props.order});
      }
      if (
        !isEqual(
          prevState.contractDriverPhoneNumber,
          this.state.contractDriverPhoneNumber,
        )
      ) {
        this.setState({
          contractDriverPhoneNumber: this.state.contractDriverPhoneNumber,
        });
      }
      if (
        !isEqual(
          prevState.contractDriverFullName,
          this.state.contractDriverFullName,
        )
      ) {
        this.setState({
          contractDriverFullName: this.state.contractDriverFullName,
        });
      }
    }
  }

  fetchFreeVehicles = async () => {
    const {order, orderId} = this.props;
    this.setState({vehiclesLoading: true});
    const freeAllVehicles: VehicleForSelection[] = await orderApi.fetchOrderFreeVehicles(orderId);
    const freeVehicles = [];
    const freeTrailers = [];
    const withTrailer = order && order.withTrailer;
    freeAllVehicles.forEach((vehicle: VehicleForSelection) => {
      if (
        withTrailer &&
        trailerVehicleTypes.includes(vehicle.vehicleModel.type)
      ) {
        freeTrailers.push({
          ...vehicle,
          disabled: false,
        });
      } else {
        freeVehicles.push(vehicle);
        /**
         * Если у нас заявка на ТС с прицепом и к ТС прикреплены прицепы,
         * то мы их добавляем, как прицепы, которые можно выбрать, но ставим отметку,
         * что они заблокированы для выбора.
         * Блокируем мы их потому, что прицеп можно выбрать только в связке ТС + прицеп.
         * Выбрать прикрепленный прицеп к другому ТС нельзя, для этого нужно произвести
         * манипуляции по откреплению прицепа в инвентарной карточке
         */
        if (withTrailer) {
          const vehicleTrailers = vehicle.trailers.map<Vehicle>(
            (item: Vehicle) => ({
              ...item,
              disabled: true,
            }),
          );
          freeTrailers.push(...vehicleTrailers);
        }
      }
    });
    const {trailers, vehicles} = getSelectionVehicles({
      selectedTrailer: order.trailer,
      selectedVehicle: order.vehicle,
      trailers: freeTrailers,
      vehicles: freeVehicles,
      withTrailer: order.withTrailer,
    });
    this.setState({
      vehiclesLoading: false,
      freeVehicles: vehicles,
      freeTrailers: trailers,
    });
  };

  deleteOrder = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await orderApi.deleteOrder(this.props.orderId);
      await navigate('/orders', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  vehicleChange = (vehicle: Vehicle): Promise<void> => {
    if (this.props.order) {
      let vehicleId = vehicle.id;
      const contractDriverFullName = vehicle.contractDriverFullName || '';
      const contractDriverPhoneNumber = vehicle.contractDriverPhoneNumber || '';
      if (vehicleId === this.props.order.vehicleId) {
        vehicleId = null;
      }
      /**
       * Если у выбранного ТС есть только один прицеп, то автоматом выбираем его.
       * Если прицепов нет, то оставляем тот, который был выбран до этого.
       * Если ТС не выбран, то и прицеп нам не нужен
       */
      let trailerId, trailer;
      if (vehicleId) {
        trailer =
          vehicle.trailers.length > 0
            ? vehicle.trailers[0]
            : this.props.order.trailer;
        trailerId = trailer && trailer.id;
      }
      this.setState({
        contractDriverFullName,
        contractDriverPhoneNumber,
      });
      this.props.changeOrderState({
        vehicleId,
        vehicle,
        trailerId,
        trailer,
      });
    }
  };

  trailerChange = async (trailer: Vehicle): Promise<void> => {
    this.setState(prevState => {
      if (prevState.order) {
        let trailerId = trailer.id;
        if (trailerId === prevState.order.trailerId) {
          trailerId = null;
        }
        return {
          touched: true, // $FlowFixMe Вверху уже стоит проверка на пустоту
          order: {
            ...prevState.order,
            trailerId,
            trailer,
          },
        };
      }
    });
  };

  onApprove = async () => {
    const {
      order,
      contractDriverFullName,
      contractDriverPhoneNumber,
    } = this.state;
    if (order) {
      order.contractDriverFullName = contractDriverFullName;
      order.contractDriverPhoneNumber = contractDriverPhoneNumber;
      this.setState({loading: true});
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        if (!order.vehicleId) {
          notification.error({
            message: 'Для подтверждения заявки необходимо указать ТС',
          });
          return;
        }
        if (order.withTrailer && !order.trailerId) {
          notification.error({
            message: 'Для подтверждения заявки необходимо указать прицеп',
          });
          return;
        }
        await this.props.updateOrder(order);
        this.setState({touched: false});
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approved,
          vehicleId: parseInt(order.vehicleId, 10),
        });
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно подтверждена',
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      } finally {
        notification.close('saving');
        this.setState({loading: false});
      }
    }
  };

  onApproveByContractVehicleOrder = async () => {
    const {order, contractVehicleOrder} = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        // Обновляем заявку на доп. найм ТС с указанием гос. номера и водителя
        if (contractVehicleOrder) {
          await contractVehicleOrderApi.updateContractVehicleOrder(
            contractVehicleOrder,
          );
        }
        this.setState({touched: false});
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approvedByContractVehicleOrder,
        });
        this.toggleApproveByContractVehicleOrder();
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно подтверждена',
        });
      } catch (error) {
        notification.error({
          message: 'Подтверждение заявки завершилось неудачно',
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hasAccess = () =>
    this.props.userAccess.some(access => containerAccess.includes(access));

  onEngineerApprove = async () => {
    const {order} = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        this.setState({touched: false});
        // Меняем статус
        await this.props.changeStatus(order.id, {
          status: orderStatusEnum.approvedByMainEngineer,
        });
        await this.fetchFreeVehicles();
        notification.success({
          message: 'Заявка успешно утверждена',
        });
      } catch (error) {
        notification.error({
          message: 'Утверждение заявки завершилось неудачно',
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  canUserRestoreOrder = (order: Order): boolean => {
    const {userAccess} = this.props;
    return (
      order.status === orderStatusEnum.cancelled &&
      userAccess.some(access =>
        [...adminAccess, accessTypeEnum.handlingOrder].includes(access),
      )
    );
  };

  /**
   * Отмена обычной заявки.
   *
   * Отменить можно только неотмененную заявку
   * Отменить неподтвержденную заявку может только заявитель
   * Отменить подтвержденную заявку может только диспетчер
   */
  canCancelOrder = (order: Order): boolean => {
    const {userAccess, employeeId} = this.props;
    const canHandleAccess = [...adminAccess, accessTypeEnum.handlingOrder];
    if (order.status === orderStatusEnum.cancelled) {
      return false;
    }

    if (order.isIntegration){
      canHandleAccess.push(accessTypeEnum.canCancelIntegrationOrder);
    }

    // Статусы заявок при которых нельзя отменять обычному заявителю
    const statuses = [
      orderStatusEnum.cancelled,
      orderStatusEnum.approved,
      orderStatusEnum.approvedByMainEngineer,
    ];
    // Может ли пользователь обрабатывать заявку
    const canHandleOrder = userAccess.some(access => canHandleAccess.includes(access)) || this.isDeclarant();
    // Если пользователь заявитель
    if (order.claimantId === employeeId && !statuses.includes(order.status)) {
      return true;
    }
    return canHandleOrder;
  };
  /**
   * Отмена заявки-ГПМ
   * Если заявка не подтверждена "Главным инженером", то отменить может только доступ "Потверждение заявки на ГПМ"
   * Если заявка подтверждена "Главным механиком", то отменить может доступ "Обработка заявки" или "Отмена заявки на ГПМ"
   * Если заявка "На подтверждении", то отменить может доступ "Отмена заявки на ГПМ"
   */
  canCancelGpmOrder = (order: Order): boolean => {
    const {userAccess} = this.props;
    let accessList = [];
    if (order.status === orderStatusEnum.approvedByMainEngineer) {
      accessList = [...adminAccess, accessTypeEnum.handlingOrder];
    } else if (order.status === orderStatusEnum.onReworkGPM) {
      accessList = [...adminAccess, accessTypeEnum.canCancelGpmOrder];
    } else if (order.status === orderStatusEnum.approvingByMainEngineer) {
      accessList = [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingGpmOrder,
        accessTypeEnum.canCancelGpmOrder,
      ];
    }

    if (order.isIntegration){
      accessList.push(accessTypeEnum.canCancelIntegrationOrder);
    }
    return userAccess.some(access => accessList.includes(access));
  };
  /**
   * Возможность подтверждения заявки ГПМ
   * 1) Заявка должна быть ГПМ
   * 2) Заявка должна быть в статусе "На подтверждении у главного инженера"
   * 3) Подтверждение доступно только с доступом "Подтверждение заявки на ГПМ"
   */
  canApproveGpmOrder = (order: Order): boolean => {
    const {userAccess} = this.props;
    return (
      order.isGpm &&
      order.status === orderStatusEnum.approvingByMainEngineer &&
      userAccess.some(access =>
        [...adminAccess, accessTypeEnum.approvingGpmOrder].includes(access),
      )
    );
  };
  /**
   * Возможность подтверждения любой заявки
   * 1) Если обычная заявка, то она должна быть в статусе "Создана"
   * 2) Если заявка ГПМ, то должна быть в статусе "Утверждено главным инженером"
   * 3) Подтверждение доступно только с доступом "Обработка заявки"
   */
  canApproveOrder = (order: Order): boolean => {
    const {userAccess} = this.props;
    return (
      ((!order.isGpm && [orderStatusEnum.created, orderStatusEnum.approvedByMainEngineer].includes(order.status)) ||
        (order.isGpm && order.status === orderStatusEnum.approvedByMainEngineer)) &&
      (userAccess.some(access => [...adminAccess, accessTypeEnum.handlingOrder].includes(access)) || this.isDeclarant())
    );
  };
  /**
   * Возможность отредактировать заявку
   */
  canEditOrder = () => {
    const {order} = this.state;
    const {userAccess} = this.props;
    const isAdmin = userAccess.includes(accessTypeEnum.admin);
    if (isAdmin) {
      return true;
    }

    if (order.isIntegration 
        && (order.status === orderStatusEnum.created || order.status === orderStatusEnum.approvingByMainEngineer )){
      return false;
    }

    const canCreatingOrder = userAccess.includes(accessTypeEnum.creatingOrder);
    const canHandlingOrder = userAccess.includes(accessTypeEnum.handlingOrder) || this.isDeclarant();
    const orderIsCanceled = order.status === orderStatusEnum.cancelled;
    const orderOnRework = order.status === orderStatusEnum.onReworkGPM ||
      order.status === orderStatusEnum.onReworkVehicle;
    const orderApprovedOrApproving = order.status === orderStatusEnum.approvedByMainEngineer ||
      order.status === orderStatusEnum.approvingByMainEngineer;

    return (
      (canCreatingOrder && !canHandlingOrder && orderOnRework) || (canHandlingOrder && (orderOnRework ||
        order.status === orderStatusEnum.created || orderApprovedOrApproving)) || (orderIsCanceled &&
        userAccess.includes(accessTypeEnum.editOrderBeyondRework))
    );
  };

  /**
   * Возможность перенаправить заявку
   */
  canRedirectOrder = (): boolean =>
    this.props.userAccess.some(access =>
      [...adminAccess, accessTypeEnum.handlingOrder].includes(access),
    );
  onReworkOrCancel = async data => {
    const {order} = this.state;
    if (order) {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        let status;
        if (this.state.typeOfModal === 'cancel') {
          status = orderStatusEnum.cancelled;
        } else if (order.status === orderStatusEnum.approvingByMainEngineer) {
          status = orderStatusEnum.onReworkGPM;
        } else if (
          order.status === orderStatusEnum.approvedByMainEngineer ||
          order.status === orderStatusEnum.created
        ) {
          status = orderStatusEnum.onReworkVehicle;
        }
        const newOrder = await orderApi.changeStatus({
          id: order.id,
          status,
          ...data,
        });
        this.setState({
          showReworkReasonModal: false,
          order: newOrder,
        });
        this.props.changeOrderState(newOrder);
        notification.success({
          message: `Заявка ${
            this.state.typeOfModal === 'cancel'
              ? 'отменена'
              : 'отправлена на доработку'
          }`,
        });
      } catch (error) {
        notification.error({
          message: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };
  changeContractVehicleOrder = (field: string, value: any) =>
    this.setState(prevState => ({
      // $FlowFixMe не хватает параметров
      contractVehicleOrder: {
        ...prevState.contractVehicleOrder,
        [field]: value,
      },
    }));
  toggleApproveByContractVehicleOrder = () =>
    this.setState(prevState => ({
      showApproveByContractVehicleOrder: !prevState.showApproveByContractVehicleOrder,
    }));
  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await orderGpmApi.printGpmOrder(this.props.orderId);
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };
  handleContractVehicleOrderPrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await contractVehicleOrderApi.printContractVehicleOrder(
        parseInt(this.props.order.contractVehicleOrderId, 10),
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };
  // Сохранение введенных пользователем данных водителя в локальный стейт
  handleContractDriverData = (data: string, type: 'name' | 'phone') => {
    switch (type) {
      case 'name':
        this.setState({contractDriverFullName: data});
        break;
      case 'phone':
        this.setState({contractDriverPhoneNumber: data});
        break;
      default:
        break;
    }
  };
  deleteContractVehicleOrder = async () => {
    const {order} = this.props;
    const contractVehicleOrderId = parseInt(order.contractVehicleOrderId, 10);
    if (contractVehicleOrderId > 0) {
      await contractVehicleOrderApi.deleteContractVehicleOrder(
        contractVehicleOrderId,
      );
      await this.props.fetchOrder(order.id);
    }
  };
  // Восстановление заявки
  restoreOrder = async () => {
    try {
      notificationLoading({
        message: 'Восстановление...',
        key: 'restoring',
      });
      await this.props.changeStatus(this.props.orderId, {
        status: this.props.order.isGpm
          ? orderStatusEnum.approvingByMainEngineer
          : orderStatusEnum.created,
      });
      notification.success({
        message: 'Успешно',
        description: 'Заявка восстановлена',
      });
      await this.fetchOrder(this.props.orderId);
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('restoring');
    }
  };
  // Пока необходмой кнопки аппрува
  renderApproveButtons = () => {
    const {order, loading} = this.state;
    if (!order) {
      return null;
    }
    const contractVehicleOrderId = parseInt(order.contractVehicleOrderId, 10);
    const vehicleId = parseInt(order.vehicleId, 10);
    const trailerId = parseInt(order.trailerId, 10);
    const canApproveByContractVehicleOrder =
      contractVehicleOrderId > 0 &&
      (order.withTrailer ? !trailerId : true) &&
      !vehicleId;
    // Если у нас имеется заявка на доп. найм ТС и не выбран собственный/наемный ТС
    if (canApproveByContractVehicleOrder) {
      return (
        <Button
          loading={loading}
          disabled={loading}
          type="primary"
          onClick={this.toggleApproveByContractVehicleOrder}
        >
          Подтвердить
        </Button>
      );
    }
    return (
      <Button
        className="orderApproveBtn"
        type="primary"
        loading={loading}
        disabled={loading}
        onClick={this.onApprove}
      >
        Подтвердить
      </Button>
    );
  };
  renderReworkButton = () => {
    const accesses = [accessTypeEnum.admin, accessTypeEnum.toReworkOrder];
    const {order} = this.state;
    const {status} = order;
    const hasAccess = this.props.userAccess.some(access =>
      accesses.includes(access),
    );
    const showReworkButton =
      ((order.isGpm && status === orderStatusEnum.approvingByMainEngineer) ||
        (!order.isGpm && status === orderStatusEnum.created)) &&
      hasAccess;
    if (showReworkButton) {
      return (
        <Button
          type="primary"
          style={{marginLeft: 15}}
          onClick={() =>
            this.setState({
              showReworkReasonModal: true,
              typeOfModal: 'rework',
            })
          }
        >
          Отправить на доработку
        </Button>
      );
    } else {
      return null;
    }
  };

  // Заявитель должен иметь возможность закреплять за созданной им заявкой ТС и работать с ней (утвердить, отклонить
  // и т.д.). Поле declarantCanHandling в заявке показывает, что заявка создана текущим пользователем. Если это не
  // админ и у него нет прав на редактирование заявки, но declarantCanHandling = true, то даем пользователю
  // возможность работы с заявкой.
  isDeclarant = () => !this.props.userAccess.includes(accessTypeEnum.admin) &&
    !this.props.userAccess.includes(accessTypeEnum.handlingOrder) && this.props.order?.declarantCanHandling;

  render() {
    if (!this.hasAccess()) {
      return <AccessDenied />;
    }
    const {
      order,
      freeTrailers,
      freeVehicles,
      vehiclesLoading,
      showReworkReasonModal,
      typeOfModal,
      reworkReasons,
    }: State = this.state;
    if (!order) {
      return null;
    }
    const canCancel = order && order.isGpm ? this.canCancelGpmOrder(order) : this.canCancelOrder(order);
    const canApproveGsmOrder = order && this.canApproveGpmOrder(order);
    const canApproveOrder = order && this.canApproveOrder(order);
    const canRestore = this.canUserRestoreOrder(order);
    const canCopy = canCopyOrder(this.props.userAccess);
    let trailer,
      vehicle,
      withTrailer = false;
    if (order) {
      trailer = order.trailer;
      vehicle = order.vehicle;
      withTrailer = order.withTrailer;
    }
    const {trailers, vehicles} = getSelectionVehicles({
      selectedTrailer: trailer,
      selectedVehicle: vehicle,
      trailers: freeTrailers,
      vehicles: freeVehicles,
      withTrailer,
    });
    const clickUpdatePhoneTrue = () => {
      this.setState({
        keyContractDriverPhoneNumber: true,
        contractDriverPhoneNumber: order.contractDriverPhoneNumber,
      });
    };
    const clickUpdateNameTrue = () => {
      this.setState({
        keyContractDriverFullName: true,
        contractDriverFullName: order.contractDriverFullName,
      });
    };
    return (
      <>
        <Modal
          title={`Причина ${
            typeOfModal === 'cancel' ? 'отмены заявки' : 'отправки на доработку'
          }`}
          visible={showReworkReasonModal}
          footer={null}
          onCancel={() => this.setState({showReworkReasonModal: false})}
        >
          <ModalContent>
            <Form initialValues={{}} onSubmit={this.onReworkOrCancel}>
              {(FormField, formikProps) => {
                const {handleSubmit, setFieldValue} = formikProps;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid gutter="16px" cols={3}>
                      <GridItem fullWidth>
                        <FormField
                          label="Причина отклонения"
                          required
                          name="toReworkReasonId"
                        >
                          {({value, name}) => (
                            <Select
                              placeholder="Выберите причину"
                              onChange={value => setFieldValue(name, value)}
                              value={value}
                            >
                              {reworkReasons.map(reason => (
                                <Option key={reason.id} value={reason.id}>
                                  {reason.title}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem fullWidth>
                        <FormField name="toReworkComment" label="Комментарий">
                          {({name, value}) => (
                            <TextArea
                              style={{resize: 'none'}}
                              value={value}
                              onChange={e =>
                                setFieldValue(name, e.target.value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <ButtonGroup>
                      <Button type="primary" htmlType="submit" data-cy="save">
                        Сохранить
                      </Button>
                    </ButtonGroup>
                  </form>
                );
              }}
            </Form>
          </ModalContent>
        </Modal>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/orders')}>Заявки</Crumb>
              <Crumb>Заявка №{order.id}</Crumb>
            </Breadcrumbs>
          }
          right={
            <ButtonOperations>
              {order.isGpm && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
              {
                <>
                  <ButtonGroup>
                    {canApproveGsmOrder && (
                      <Button
                        className="engineerApproveBtn"
                        type="primary"
                        onClick={this.onEngineerApprove}
                      >
                        Утвердить
                      </Button>
                    )}
                    {canApproveOrder && this.renderApproveButtons()}
                    {this.renderReworkButton()}
                  </ButtonGroup>
                  <OrderCardActionsDropdown
                    order={order}
                    canRedirectOrder={this.canRedirectOrder()}
                    canApprove={canApproveOrder}
                    canCancel={canCancel}
                    canRestore={canRestore}
                    canEdit={this.canEditOrder()}
                    canCopy={canCopy}
                    onCancel={() => this.setState({
                      showReworkReasonModal: true,
                      typeOfModal: 'cancel',
                    })}
                    onRestore={this.restoreOrder}
                    onDelete={this.deleteOrder}
                  />
                </>
              }
            </ButtonOperations>
          }
        />
        <StyledPanel>
          <h1>Заявка №{order.id}</h1>
        </StyledPanel>
        <Section>
          <SectionTitle
            divider
            suffix={order.isBusinessTrip && <BusinessTrip>Командировка</BusinessTrip>}
          >
            Назначение
          </SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Период выделения ТС">
                  {formatDateRangeString(order.startDate, order.endDate)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">{orderTypes[order.type]}</Field>
              </GridItem>
              <GridItem>
                <Field label="Цель поездки">
                  {orderObjectives[order.objective]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="С прицепом">
                  {order.withTrailer ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
              {order.notation && (
                <GridItem>
                  <Field label="Примечание">{order.notation}</Field>
                </GridItem>
              )}
              {order.driverId && (
                <GridItem>
                  <Field label="Водитель">
                    {order.driver.employee.lastname}{' '}
                    {order.driver.employee.firstname}{' '}
                    {order.driver.employee.middlename}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="В распоряжение службы">{order.orgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение (ФИО)">
                  {convertEmployeeToString(order.employee)}
                </Field>
              </GridItem>
              {order.claimantId && (
                <GridItem>
                    <Field label="Заявитель">
                      {convertEmployeeToString(order.claimant)}
                      {order.claimant && order.claimant.orgUnitName ? `, ${order.claimant.orgUnitName}` : ''}
                    </Field>
                </GridItem>
              )}
              {order.dispatcherNodeName && (
                <GridItem>
                  <Field label='Подразделение диспетчера'>{order.dispatcherNodeName}</Field>
                </GridItem>
              )}
              {order.businessTripOrderNumber && (
                <GridItem>
                  <Field label="Номер заявки на командировку">
                    {order.businessTripOrderNumber}
                  </Field>
                </GridItem>
              )}
              {order.businessTripDecreeNumber && (
                <GridItem>
                  <Field label="Номер приказа на командировку">
                    {order.businessTripDecreeNumber}
                  </Field>
                </GridItem>
              )}
              {order.isGpm && (
                <>
                  <GridItem>
                    <Field label="Главный инженер">
                      {convertEmployeeToString(order.mainEngineer)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Владелец ГПМ">
                      {convertEmployeeToString(order.gpmOwner)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Характер работы">{order.natureOfWork}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Объект (№ цеха, участка)">
                      {order.object}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Лица, ответственные за безопасное проведение работ">
                      {(order.safetyResponsibles || [])
                      .map((employee: Employee) => convertEmployeeToString(employee))
                      .join(', ')}
                    </Field>
                  </GridItem>
                  {!!order.riggers?.length && (
                    <GridItem>
                      <Field label="Рабочие стропальщики">
                        {(order.riggers)
                        .map((employee: Employee) => convertEmployeeToString(employee))
                        .join(', ')}
                      </Field>
                    </GridItem>
                  )}
                  {!!order.secondaryRiggers?.length && (
                    <GridItem>
                      <Field label="Рабочие люльки">
                        {(order.secondaryRiggers)
                        .map((employee: Employee) => convertEmployeeToString(employee))
                        .join(', ')}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Наличие проекта производства работ">
                      {order.project}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field
                      label="Сведения о линиях электропередач, воздушной электросети, контактных проводах городского транспорта и др.">
                      {order.powerLinesInfo}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Напряжение (В)">{order.voltage}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Расстояние по горизонтали и вертикали (м)">
                      {order.vhDistance}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения об охранной зоне">
                      {order.safetyZone}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения о наличие разрешения организации, эксплатирующей линию электропередачи">
                      {order.permitInfo}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Cведения о наличии наряда-допуска">
                      {order.admission}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Технологическая карта">
                      {order.technologyMap}
                    </Field>
                  </GridItem>
                </>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Статус</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Статус заявки">
                  {orderStatuses[order.status]}
                </Field>
              </GridItem>
              {[
                orderStatusEnum.cancelled,
                orderStatusEnum.onReworkVehicle,
                orderStatusEnum.onReworkGPM,
              ].includes(order.status) && (
                <>
                  <GridItem>
                    <Field
                      label={`Причина ${
                        order.status === orderStatusEnum.cancelled
                          ? 'отмены'
                          : 'отклонения'
                      } заявки`}
                    >
                      {order.toReworkReasonTitle}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field
                      label={`Комментарий ${
                        order.status === orderStatusEnum.cancelled
                          ? 'отмены'
                          : 'отклонения'
                      } заявки`}
                    >
                      {order.toReworkComment}
                    </Field>
                  </GridItem>
                </>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Тип ТС">
                  {vehicleTypes[order.vehicleType] || vehicleGroups[order.vehicleGroup]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Количество пассажиров">
                  {order.workersCount}
                </Field>
              </GridItem>
              {canApproveOrder
                ? (
                  <>
                    <GridItem fullWidth>
                      <VehicleSelection
                        vehicles={vehicles}
                        selected={order.vehicleId}
                        onSelect={this.vehicleChange}
                        editable
                        loading={vehiclesLoading}
                        handleContractDriverData={this.handleContractDriverData}
                        contractDriverPhoneNumber={this.state.contractDriverPhoneNumber}
                        contractDriverFullName={this.state.contractDriverFullName}
                        onlyType={this.isDeclarant() ? 'contract' : undefined}
                      />
                    </GridItem>
                  </>
                )
                : (order.vehicle && (
                  <div>
                    <GridItem>
                      <Field label="ТС">{order.vehicle.licensePlate}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Водитель">
                        {order.contractDriverFullName}
                        {!this.state.keyContractDriverFullName && (
                          <Button
                            type="primary"
                            size={'small'}
                            style={{marginLeft: 10, marginBottom: 5}}
                            onClick={clickUpdateNameTrue}
                          >
                            Редактировать
                          </Button>
                        )}
                        <div style={{width: 300}}>
                          {this.state.keyContractDriverFullName && (
                            <div>
                              <Button
                                type="primary"
                                size={'small'}
                                style={{
                                  marginLeft: 10,
                                  marginBottom: 5,
                                  marginTop: 5,
                                }}
                                onClick={() => {
                                  this.props.updateOrder(this.props.order);
                                  this.setState({keyContractDriverFullName: false});
                                }}
                              >
                                Сохранить
                              </Button>
                              <Input // инпут 2 ========= инпут 2
                                size="small"
                                placeholder="Водитель"
                                value={this.state.contractDriverFullName}
                                onChange={e => {
                                  this.props.order.contractDriverFullName = e.target.value;
                                  this.setState({contractDriverFullName: e.target.value});
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Номер телефона">
                        {order.contractDriverPhoneNumber}
                        {!this.state.keyContractDriverPhoneNumber && (
                          <Button
                            type="primary"
                            size={'small'}
                            style={{marginLeft: 10, marginBottom: 5}}
                            onClick={clickUpdatePhoneTrue}
                          >
                            Редактировать
                          </Button>
                        )}
                        <div style={{width: 300}}>
                          {this.state.keyContractDriverPhoneNumber && (
                            <div>
                              <Button
                                type="primary"
                                size={'small'}
                                style={{marginLeft: 10, marginBottom: 5}}
                                onClick={e => {
                                  this.props.updateOrder(this.props.order);
                                  this.setState({keyContractDriverPhoneNumber: false});
                                }}
                              >
                                Сохранить
                              </Button>
                              <Input // инпут 2
                                maxLength={13}
                                size="small"
                                placeholder="Телефон"
                                value={this.state.contractDriverPhoneNumber}
                                onChange={e => {
                                  this.props.order.contractDriverPhoneNumber = e.target.value;
                                  this.setState({contractDriverPhoneNumber: e.target.value});
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </Field>
                    </GridItem>
                  </div>
                ))
              }
            </Grid>
          </SectionContent>
        </Section>
        {order.withTrailer && (
          <Section>
            <SectionTitle divider>Прицеп</SectionTitle>
            <SectionContent>
              <Grid>
                {canApproveOrder
                  ? (
                    <GridItem fullWidth>
                      <VehicleSelection
                        onlyType={ownerTypes.self}
                        vehicles={trailers}
                        selected={order.trailerId}
                        onSelect={this.trailerChange}
                        loading={vehiclesLoading}
                        editable
                      />
                    </GridItem>
                  )
                  : (!!order.trailer && (
                    <>
                      <GridItem>
                        <Field label="Тип прицепа">
                          {vehicleTypes[order.trailer.vehicleModel.type]}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Марка">
                          {order.trailer.vehicleModel.brandName}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Модель">
                          {order.trailer.vehicleModel.name}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Гос. номер">
                          {order.trailer.licensePlate}
                        </Field>
                      </GridItem>
                    </>
                  ))
                }
              </Grid>
            </SectionContent>
          </Section>
        )}
        {order.hasCargos && order.cargos.length
          ? (
            <Section>
              <SectionTitle divider>Грузы</SectionTitle>
              <SectionContent>
                <ListTable
                  columns={[
                    {
                      title: 'Наименование',
                      key: 'name',
                    },
                    {
                      title: 'Количество мест, общ',
                      key: 'placeNumber',
                      width: '90px',
                    },
                    {
                      title: 'Общий вес, кг',
                      key: 'totalWeight',
                      width: '90px',
                    },
                    {
                      title: 'Вид упаковки',
                      key: 'packageType',
                    },
                  ]}
                  data={order.cargos}
                />
              </SectionContent>
            </Section>
          )
          : null
        }
        {order.hasCargos && order.loadCargos.length
          ? (
            <Section>
              <SectionTitle divider>Погрузка</SectionTitle>
              <SectionContent>
                <ListTable
                  columns={[
                    {
                      title: 'Адрес',
                      key: 'address',
                    },
                    {
                      title: 'Начало',
                      key: 'startDateTime',
                      width: '210px',
                      render: (date: string) =>
                        moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                    },
                    {
                      title: 'Конец',
                      key: 'endDateTime',
                      width: '210px',
                      render: (date: string) =>
                        moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                    },
                    {
                      title: 'Контактные лица',
                      key: 'contactEmployees',
                    },
                  ]}
                  data={order.loadCargos}
                />
              </SectionContent>
            </Section>
          )
          : null
        }
        {order.hasCargos && order.unloadCargos.length
          ? (
            <Section>
              <SectionTitle divider>Выгрузка</SectionTitle>
              <SectionContent>
                <ListTable
                  columns={[
                    {
                      title: 'Адрес',
                      key: 'address',
                    },
                    {
                      title: 'Начало',
                      key: 'startDateTime',
                      width: '210px',
                      render: (date: string) =>
                        moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                    },
                    {
                      title: 'Конец',
                      key: 'endDateTime',
                      width: '210px',
                      render: (date: string) =>
                        moment.utc(date).format('DD.MM.YYYY, HH:mm'),
                    },
                    {
                      title: 'Контактные лица',
                      key: 'contactEmployees',
                    },
                  ]}
                  data={order.unloadCargos}
                />
              </SectionContent>
            </Section>
          )
          : null
        }
        {parseInt(order.contractVehicleOrderId, 10) > 0 && (
          <Section>
            <SectionTitle
              divider={this.canEditOrder()}
              suffix={
                <Operations>
                  {canApproveOrder && (
                    <Button onClick={this.handleContractVehicleOrderPrint}>
                      Печать
                    </Button>
                  )}
                  {this.canEditOrder() && (
                    <Dropdown
                      overlay={
                        <Menu>
                          <Menu.Item
                            onClick={() => navigate(`/orders/${order.id}/contract-vehicle-order/${order.contractVehicleOrderId ||
                            ''}`)}>
                            Редактировать
                          </Menu.Item>
                          <Menu.Item>
                            <Popconfirm
                              overlayStyle={{
                                zIndex: 2000,
                              }}
                              placement="left"
                              title="Вы действительно хотите удалить?"
                              okText="Да"
                              cancelText="Нет"
                              onConfirm={this.deleteContractVehicleOrder}
                            >
                              Удалить
                            </Popconfirm>
                          </Menu.Item>
                        </Menu>
                      }
                    >
                      <Button style={{marginLeft: 16}}>
                        <Icon
                          style={{fontSize: 16, color: '#2770FF'}}
                          type="ellipsis"
                        />
                      </Button>
                    </Dropdown>
                  )}
                </Operations>
              }
            >
              Заявка на доп. найм ТС
            </SectionTitle>
            <SectionContent>
              <ContractVehicleOrderCard
                contractVehicleOrderId={order.contractVehicleOrderId}
              />
            </SectionContent>
          </Section>
        )}
        <Section>
          <SectionTitle divider>Маршрут</SectionTitle>
          <SectionContent>
            <WaypointsViewer waypoints={order.route && order.route.waypoints} />
          </SectionContent>
        </Section>
        {this.state.contractVehicleOrder && (
          <Modal
            visible={this.state.showApproveByContractVehicleOrder}
            title="Подтверждение заявки"
            onCancel={this.toggleApproveByContractVehicleOrder}
            footer={[
              <Button
                key="back"
                onClick={this.toggleApproveByContractVehicleOrder}
              >
                Отмена
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={this.onApproveByContractVehicleOrder}
              >
                Подтвердить
              </Button>,
            ]}
          >
            <Grid cols={2} gutter="16px">
              <GridItem>
                <Field label="Гос. номер">
                  <Input
                    value={this.state.contractVehicleOrder.licensePlate}
                    onChange={e => {
                      const value = e.target.value;
                      this.changeContractVehicleOrder('licensePlate', value);
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Водитель">
                  <Input
                    value={this.state.contractVehicleOrder.driver}
                    onChange={e => {
                      const value = e.target.value;
                      this.changeContractVehicleOrder('driver', value);
                    }}
                  />
                </Field>
              </GridItem>
            </Grid>
          </Modal>
        )}
        {!!order.history?.length && <HistorySection history={order.history} />}
      </>
    );
  }
}
const mapStateToProps = (state: AppState, props: Props): Object => ({
  order: state.order,
  employeeId: state.auth.profile.employeeId,
  orderId: props.orderId,
  contractVehicleOrder: state.contractVehicleOrder,
  currentEmployeeId: state.auth.profile.employeeId,
});
export default connect(mapStateToProps, {
  fetchOrder,
  changeStatus,
  updateOrder,
  cleanOrder,
  changeOrderState,
})(withUserAccess(OrderCard));
