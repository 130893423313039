// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import {Link} from '@reach/router';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import type moment from 'moment';
import isNil from 'lodash/isNil';
import Menu from 'antd/lib/menu';

import type {Employee, Tire, TireMeasurement, UserAccess, Vehicle} from '../../../lib/types';
import type {DropdownType} from '../../../components/ui/Dropdown';
import {tireApi, tireMeasurementApi, vehicleApi} from '../../../lib/api';
import {
  convertEmployeeToString,
  convertVehicleToString,
  formatDateTimeToString,
  getPathWithHistoryParams,
  isVehicleTractor,
  navigate,
} from '../../../lib/helpers';
import {Card} from '../../../components';
import {ButtonOperations, Dropdown, ListTable, Spoiler} from '../../../components/ui';
import {Panel, Section, SectionTitle} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import HistoryTable from './../HistoryTable';
import Popconfirm from 'antd/lib/popconfirm';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import {withUserAccess} from './../../withUserAccess';
import {accessTypeEnum} from '../../../lib/enum';
import AttachVehicleButton, {type AdditionalData} from './components/AttachVehicleButton';
import TireMeasurementForm from './../../Vehicles/components/TireMeasurementForm';
import RemoveEquipmentButton from './../../Vehicles/components/RemoveEquipmentButton';
import CopyTireButton from './components/CopyTireButton';
import {notificationLoading} from '../../../components/Notifications';
import {DecomissedStatuses, getDecomissionStatusName} from '../../../lib/api/tire';
import WorkCardModal, {TireUsingTypes} from './components/WorkCardModal';
import type {TireWorkCard} from '../../../lib/types/tireWorkCard';

const SectionContent = styled.div`
  padding: 16px;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const {Field} = Card;

export const canEditTireAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.decomissingEquipment,
];

export const canAddMeasurementAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.gager,
];

type Props = {
  tireId: number,
  userAccess: UserAccess[]
};

type State = {
  tireMeasurements: TireMeasurement[],
  tire: ?Tire,
  vehicle: ?Vehicle,
  needUpdateHistoryList: boolean,
};

/** Карточка шины */
export class TireCard extends Component<Props, State> {
  state = {
    tireMeasurements: [],
    tire: null,
    vehicle: null,
    hasHistory: false,
    showWorkCardModal: false,
    isUpdatingWorkCard: false,
    needUpdateHistoryList: false,
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    await this.fetchTire();
    await tireApi
    .fetchHistory({}, this.props.tireId)
    .then(({data}) => this.setState({hasHistory: !!data.length}));
  }

  fetchTire = async () => {
    try {
      const tire: Tire = await tireApi.fetchTire(this.props.tireId);
      const tireMeasurements = await tireMeasurementApi.fetchTireMeasurementsByTireId(
        this.props.tireId,
      );
      this.setState(
        {
          tireMeasurements,
          tire,
        },
        async () => {
          const {tire} = this.state;
          if (tire && tire.vehicleId) {
            this.setState({
              vehicle: await vehicleApi.fetchVehicle(tire.vehicleId),
            });
          }
        },
      );
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };

  deleteTire = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await tireApi.deleteTire(parseInt(this.props.tireId, 10));
      await navigate('/equipment/tires/', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  writeOffTire = async tireData => {
    try {
      notificationLoading({
        message: 'Списание...',
        key: 'writingOff',
      });
      if (this.state.tire) {
        await tireApi.writeOffTire(tireData);
        notification.success({
          message: 'Успешно',
          description: 'Шина успешно списана',
        });
        this.setState({showWorkCardModal: false, needUpdateHistoryList: true});
        await this.fetchTire();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('writingOff');
    }
  };

  updateWorkCard = async tireData => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'updatingWorkCard',
      });
      if (tireData) {
        await tireApi.updateTireWorkCard(tireData);
        notification.success({
          message: 'Успешно',
          description: 'Данные карточки учета шины сохранены',
        });
        this.setState({
          showWorkCardModal: false,
          isUpdatingWorkCard: false,
          needUpdateHistoryList: true,
        });
        await this.fetchTire();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('updatingWorkCard');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    additionalData: AdditionalData,
    removeDate?: string,
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const {tire} = this.state;
      const {installDate, normKilometrage} = additionalData;
      if (!tire) {
        return;
      }
      let updatedTire;
      if (vehicleId) {
        updatedTire = await tireApi.setTireVehicle(
          this.props.tireId,
          vehicleId,
          installDate,
        );
        updatedTire = await tireApi.updateTireVehicle({
          ...updatedTire,
          normKilometrage,
        });
      } else {
        updatedTire = await tireApi.updateTire({
          ...tire,
          vehicleId,
          installDate,
          removeDate,
        });
      }
      this.setState({
        tire: updatedTire,
        needUpdateHistoryList: true,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('saving');
    }
  };

  canAddMeasurement = () =>
    this.props.userAccess.some(access =>
      canAddMeasurementAccess.includes(access),
    );

  getUsingTypeName = type => {
    switch (type) {
      case TireUsingTypes.new:
        return 'Новая';
      case TireUsingTypes.rebuilt:
        return 'Восстановленная';
      case TireUsingTypes.secondUsing:
        return 'Бывшая в эксплуатации';
      default:
        return '-';
    }
  };

  printDocuments = async (workCardId: number, documentType: 'xls' | 'pdf') => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      await tireApi.printWorkCard(workCardId, documentType);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const {userAccess} = this.props;
    const {tire, vehicle, hasHistory, needUpdateHistoryList} = this.state;
    const canEdit = userAccess.some(access =>
      canEditTireAccess.includes(access),
    );
    const {tireMeasurements} = this.state;
    if (!tire) {
      return null;
    }
    const tireId = parseInt(tire.id);
    const canUnfixVehicle = parseInt(tire.vehicleId, 10) > 0;
    const canAddMeasurement = this.canAddMeasurement();
    const {tireWorkCard}: TireWorkCard = tire;
    const isTractor =
      !!tireWorkCard?.vehicle && isVehicleTractor(tireWorkCard.vehicle);
    return (
      <>
        <WorkCardModal
          visible={this.state.showWorkCardModal}
          onClose={() => this.setState({showWorkCardModal: false})}
          onSave={formData => {
            formData.tireId = tireId;
            this.state.isUpdatingWorkCard
              ? this.updateWorkCard(formData)
              : this.writeOffTire(formData);
          }}
          cardValues={tire.tireWorkCard || undefined}
          isAdmin={userAccess.includes(accessTypeEnum.admin)}
          isDecomissed={tire.decomissionStatus === DecomissedStatuses.decomissed}
          tireValues={{factoryNumber: tire.factoryNumber}}
        />
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/equipment/tires')}>
                Шины
              </Crumb>
              <Crumb>
                {tire.brand} {tire.name}
              </Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {this.state.tire.decomissionStatus !== DecomissedStatuses.decomissed && hasHistory
                  ? (
                    <Button
                      type="primary"
                      onClick={() => this.setState({showWorkCardModal: true})}
                    >
                      Списать
                    </Button>
                  )
                  : null
                }
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(
                        null,
                        {
                          installDate: tire.installDate,
                          normKilometrage: tire.normKilometrage,
                        },
                        removeDate,
                      )
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                {tire.decomissionStatus !== DecomissedStatuses.decomissed && (
                  <AttachVehicleButton
                    tire={tire}
                    onConfirm={this.setVehicle}
                    vehicleId={tire.vehicleId}
                    text={`Закрепить ${tire.vehicleId ? 'другое' : 'за'} ТС`}
                  />
                )}
                <Dropdown
                  ref={dropdown => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <CopyTireButton
                          tire={tire}
                          afterSubmit={() => {
                            if (this.dropdown) {
                              this.dropdown.onVisibleChange(false);
                            }
                          }}
                        />
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        onClick={() =>
                          navigate(`/equipment/tires/edit/${tireId}`)
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteTire}
                          onVisibleChange={flag =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon="ellipsis"
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {tire.brand} {tire.name}
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{tire.name}</Field>
              </GridItem>
              {!isNil(tire.notation) && (
                <GridItem>
                  <Field label="Примечание">{tire.notation}</Field>
                </GridItem>
              )}
              {!isNil(tire.brand) && (
                <GridItem>
                  <Field label="Марка">{tire.brand}</Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="ГОСТ или ТУ">
                  {!!tire.gost ? tire.gost : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Фактический пробег">
                  {tire.actualKilometrage
                    ? `${tire.actualKilometrage} км`
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Моточасы">
                  {tire.workHours ? `${tire.workHours} ч` : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Начальный пробег">
                  {tire.initialKilometrage
                    ? `${tire.initialKilometrage} км`
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Начальные моточасы">
                  {tire.initialWorkHours ? `${tire.initialWorkHours} ч` : '-'}
                </Field>
              </GridItem>
              {!isNil(tire.code) && (
                <GridItem>
                  <Field label="Мнемокод">{tire.code}</Field>
                </GridItem>
              )}
              {!isNil(tire.issuedDate) && (
                <GridItem>
                  <Field label="Дата выдачи">
                    {formatDateTimeToString(tire.issuedDate, 'DD.MM.YYYY')}
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.replaceDate) && (
                <GridItem>
                  <Field label="Расчетная дата замены">
                    {formatDateTimeToString(tire.replaceDate, 'DD.MM.YYYY')}
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.installDate) && (
                <GridItem>
                  <Field label="Дата установки">
                    {formatDateTimeToString(tire.installDate, 'DD.MM.YYYY')}
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.removeDate) && (
                <GridItem>
                  <Field label="Дата снятия">
                    {formatDateTimeToString(tire.removeDate, 'DD.MM.YYYY')}
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.normKilometrage) && (
                <GridItem>
                  <Field label="Норматив километража">
                    {tire.normKilometrage} км
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.normWorkHours) && (
                <GridItem>
                  <Field label="Норматив моточасов">
                    {tire.normWorkHours} ч
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.batchNumber) && (
                <Field label="Номер партии">{tire.batchNumber}</Field>
              )}
              {!isNil(tire.factoryNumber) && (
                <GridItem>
                  <Field label="Заводской номер">{tire.factoryNumber}</Field>
                </GridItem>
              )}
              {!isNil(tire.treadDepth) && (
                <GridItem>
                  <Field label="Высота протектора">{tire.treadDepth} мм</Field>
                </GridItem>
              )}
              {vehicle && (
                <GridItem>
                  <Field label="Прикрепленное ТС">
                    <Link to={`/vehicles/${vehicle.id}`}>
                      {convertVehicleToString(vehicle)}
                    </Link>
                  </Field>
                </GridItem>
              )}
              {!isNil(tire.otherTireMtr) && (
                <GridItem>
                  <Field label="Аналог шины для расчета бюджета">
                    {tire.otherTireMtr?.mnemocode} {tire.otherTireMtr?.name}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Расчётная дата списания">
                  {!!tire.calculateDecomissionDate
                    ? formatDateTimeToString(
                      tire.calculateDecomissionDate,
                      'DD.MM.YYYY',
                    )
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Планируемая дата списания">
                  {!!tire.planeDecomissionDate
                    ? formatDateTimeToString(
                      tire.planeDecomissionDate,
                      'DD.MM.YYYY',
                    )
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Статус">
                  {getDecomissionStatusName(tire.decomissionStatus)}
                </Field>
              </GridItem>
              {tire.decomissionStatus === DecomissedStatuses.decomissed ? (
                <GridItem>
                  <Field label="Дата списания">
                    {!!tire.decomissionDate
                      ? formatDateTimeToString(
                        tire.decomissionDate,
                        'DD.MM.YYYY',
                      )
                      : '-'}
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
        {tireWorkCard ? (
          <Section>
            <Spoiler showDivider={false} label="Карточка учета работы">
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Тип автомобильной шины">
                      {this.getUsingTypeName(tireWorkCard.usingType)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Входит в комплектацию приобретенного ТС">
                      {tireWorkCard.purchasedWithVehicle ? 'Да' : 'Нет'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Серийный номер шины">
                      {!!tireWorkCard.serialNumber
                        ? tireWorkCard.serialNumber
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Образование (размер) шины">
                      {!!tireWorkCard.size ? tireWorkCard.size : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Завод-изготовитель">
                      {!!tireWorkCard.manufacturer
                        ? tireWorkCard.manufacturer
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Наименование и адрес хозяйства">
                      {`${tireWorkCard.vehicle.location.name} ${tireWorkCard.vehicle.location.address}`}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Номер ТС, марка, модель">
                      {`${tireWorkCard.vehicle.licensePlate} ${tireWorkCard.vehicle.vehicleModel.name} ${tireWorkCard.vehicle.vehicleModel.brandName}`}
                    </Field>
                  </GridItem>
                  {isTractor ? (
                    <GridItem>
                      <Field label="Количество месяцев в эксплуатации">
                        {tireWorkCard.mounthsInUsing}
                      </Field>
                    </GridItem>
                  ) : (
                    <GridItem>
                      <Field label="Пробег шины, (с точностью до 0,1 км) за месяц">
                        {(
                          tireWorkCard.averageKilometrageOnMounth / 1000
                        ).toFixed(1)}
                      </Field>
                    </GridItem>
                  )}
                  {isTractor && (
                    <GridItem>
                      <Field label="Моточасы шины за месяц">
                        {tireWorkCard.averageWorkHoursOnMounth}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Техническое состояние шины">
                      {tireWorkCard.condition}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Причина снятия шины">
                      {tireWorkCard.removingReason}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Ответственный за учет работы шины">
                      {tireWorkCard.responsibleEmployee
                        ? `${tireWorkCard.responsibleEmployee.lastname} ${tireWorkCard.responsibleEmployee.firstname} ${tireWorkCard.responsibleEmployee.middlename}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Заключение по списанию шины">
                      {tireWorkCard.conclusion}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Председатель комиссии">
                      {tireWorkCard.commissionChairmanEmployee
                        ? `${tireWorkCard.commissionChairmanEmployee.lastname} ${tireWorkCard.commissionChairmanEmployee.firstname} ${tireWorkCard.commissionChairmanEmployee.middlename}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Члены комиссии">
                      {tireWorkCard.commissionMemberEmployees
                        ? tireWorkCard.commissionMemberEmployees.map(
                          (employee: Employee) => {
                            if (!employee) {
                              return <p key={Math.random()}>-</p>;
                            }
                            return (
                              <p
                                key={employee.id}
                              >{`${employee.lastname} ${employee.firstname} ${employee.middlename}`}</p>
                            );
                          },
                        )
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата">
                      {formatDateTimeToString(tireWorkCard.date, 'DD.MM.YYYY')}
                    </Field>
                  </GridItem>
                </Grid>
                <Grid rows={1} cols={24}>
                  <GridItem
                    span={6}
                    customStyles={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      maxWidth: 200,
                    }}
                  >
                    <Button
                      style={{marginTop: 15}}
                      onClick={() =>
                        this.printDocuments(tireWorkCard.id, 'xls')
                      }
                    >
                      печать xls
                    </Button>
                    <Button
                      style={{marginTop: 15}}
                      onClick={() =>
                        this.printDocuments(tireWorkCard.id, 'pdf')
                      }
                    >
                      печать pdf
                    </Button>
                  </GridItem>
                  {canEdit && (
                    <GridItem
                      span={18}
                      customStyles={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        style={{marginTop: 15}}
                        onClick={() =>
                          this.setState({
                            showWorkCardModal: true,
                            isUpdatingWorkCard: true,
                          })
                        }
                      >
                        Редактировать
                      </Button>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Spoiler>
          </Section>
        ) : null}
        <Section>
          <SectionTitle
            divider
            suffix={
              canAddMeasurement && (
                <GridItem>
                  <TireMeasurementForm
                    tire={tire}
                    vehicleId={tire.vehicleId}
                    onSuccessSubmit={this.fetchTire}
                  />
                </GridItem>
              )
            }
          >
            Контрольные замеры высоты протектора
          </SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <ListTable
                  rowKey="id"
                  columns={[
                    {
                      title: 'Дата',
                      key: 'date',
                      render: (date: string | moment) =>
                        formatDateTimeToString(date, 'DD.MM.YYYY'),
                    },
                    {
                      title: 'Предыдущее значение, мм',
                      key: 'oldTreadDepthValue',
                    },
                    {
                      title: 'Новое значение, мм',
                      key: 'newTreadDepthValue',
                    },
                    {
                      title: 'Документ-основание',
                      key: 'document',
                    },
                    {
                      title: 'Сотрудник',
                      key: 'gauger',
                      render: (gauger: Employee) =>
                        convertEmployeeToString(gauger),
                    },
                  ]}
                  data={tireMeasurements}
                />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>История</SectionTitle>
          {tireId > 0 && (
            <HistoryTable
              needUpdateList={needUpdateHistoryList}
              equipmentId={tireId}
              fetchHistory={tireApi.fetchHistory}
            />
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(TireCard);
