// @flow
import React, { Component } from 'react';

import { gpmMaintenanceCalculationApi } from '../../../../lib/api';
import { toLocalStringRu } from '../../../../lib/helpers';
import { printNotification } from '../../../../lib/notificationWrapper';
import { MONTH, COLUMNS } from '../lib';

import Common from './components/Common';
import type { GpmMaintenanceCalculation } from '../../../../lib/types';
import { VehiclePlan } from '../../../../lib/types';
import { calculationStatusEnum } from '../../../../lib/enum';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';
import moment from 'moment/moment';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = {
  monthData: ?GpmMaintenanceCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

export default class extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };
  
  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }

  onCell = (monthData, monthIndex) => ({
    onClick: () => {
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (monthData.id &&
          vehiclePlan?.gpmMaintenanceCalculationStatus === calculationStatusEnum.draft &&
          openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({ monthData, monthIndex });
      }
    }
  });

  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null
    });
  };

  columns = [
    ...COLUMNS,
    ...MONTH.map((month: any, monthIndex: number) => ({
      title: month.title,
      key: `${monthIndex}`,
      children: [
        {
          title: 'Экспертиза',
          key: `months[${monthIndex}].expertiseCost`,
          dataIndex: `months[${monthIndex}].expertiseCost`,
          render: (cost: number) =>
            toLocalStringRu(cost, { minimumFractionDigits: 2 }),
          onCell: monthData => this.onCell(monthData, monthIndex)
        },
        {
          title: 'ТО',
          key: `months[${monthIndex}].maintenanceCost`,
          dataIndex: `months[${monthIndex}].maintenanceCost`,
          render: (cost: number) =>
            toLocalStringRu(cost, { minimumFractionDigits: 2 }),
          onCell: monthData => this.onCell(monthData, monthIndex)
        },
        {
          title: 'Общая',
          className: 'primary-background',
          key: `months[${monthIndex}].cost`,
          dataIndex: `months[${monthIndex}].cost`,
          render: (cost: number) =>
            toLocalStringRu(cost, { minimumFractionDigits: 2 }),
          onCell: monthData => this.onCell(monthData, monthIndex)
        }
      ]
    })),
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, { minimumFractionDigits: 2 })
    }
  ];

  render() {
    const { location, vehiclePlanId } = this.props;
    const { monthIndex, monthData } = this.state;
    return (
      <>
        <Common
          location={location}
          vehiclePlanId={vehiclePlanId}
          columns={this.columns}
          scrollX={5000}
          calcScrollY={460}
          crud={gpmMaintenanceCalculationApi}
          statusField={'gpmMaintenanceCalculationStatus'}
          title={'План расходов на экспертизу и ТО ПБ ГПМ'}
          handlePrint={filter => {
            printNotification(async () => {
              await gpmMaintenanceCalculationApi.print(vehiclePlanId, filter);
            });
          }}
          editingModalData={{
            monthData,
            monthIndex,
            handleCancel: this.handleCancel,
            setVehiclePlan: vehiclePlan => this.setState({ vehiclePlan })
          }}
          linksToDictionaries={{
            'Стоимость ТО ГПМ': '/admin/expertise-service-gpm-cost/',
            'Экспертиза ПС/ТО приборов безопасности ПС': '/services/expertises-services-gpm?pag',
          }}
          additionalText={' (необходимо в ИК СТС проверить отметку о включении ТС в бюджет ТО ПБ ГПМ)'}
        />
      </>
    );
  }
}
