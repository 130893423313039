// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from 'antd/lib/modal';
import styled from 'styled-components';
import moment from 'moment';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import DatePicker from 'antd/lib/date-picker';
import notification from 'antd/lib/notification';
import isEqual from 'lodash/isEqual';
import Button from 'antd/lib/button';
import {RequiredFieldMessage} from '../../../components/Form';

import Grid, {GridItem} from '../../../components/layout/Grid';
import {Form} from './../../../components';
import {formatDateTimeToISOString, isVehicleTypeElectric} from '../../../lib/helpers';
import type {FormErrors, FuelMeasurement as FuelMeasurementType, Vehicle} from './../../../lib/types';
import {fuelMeasurementApi} from '../../../lib/api';
import {fetchVehicle} from '../../../ducks/vehicle';
import type {AppState} from './../../../ducks/redux';
import {notificationLoading} from './../../../components/Notifications';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`;

const ButtonGroup = styled.div`
  width: 100%;
  text-align: right;
  padding-top: 8px;
  button {
    &:first-child {
      margin-right: 8px;
    }
  }
`;

const { TextArea } = Input;

type Props = {
  vehicle: Vehicle,
  fetchVehicle: Function,
  // Флаг для отображения модалки
  visible: boolean,
  // Функция, для закрытия модалки
  hideModal: Function
};

type State = {
  fuelMeasurement: FuelMeasurementType,
  errors: Object
};

class FuelMeasurementForm extends Component<Props, State> {
  state = {
    modalVisible: false,
    fuelMeasurement: {
      date: moment().toISOString(),
      newPrimaryFuelLevel:
        (this.props.vehicle && this.props.vehicle.primaryFuelLevel) || 0,
      document: ''
    },
    errors: {}
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.vehicle, this.props.vehicle)) {
      this.setState(prevState => ({
        fuelMeasurement: {
          ...prevState.fuelMeasurement,
          newPrimaryFuelLevel: this.props.vehicle.primaryFuelLevel || 0
        }
      }));
    }
  }

  onSubmit = async values => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await fuelMeasurementApi.addFuelMeasurement({
        ...values,
        vehicleId: this.props.vehicle.id
      });
      await this.props.fetchVehicle(this.props.vehicle.id);
      notification.success({
        message: 'Контрольный замер сохранен'
      });
      this.props.hideModal();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('saving');
    }
  };

  disabledDate = (value: string | moment) => moment().isBefore(moment(value));

  onChange = (key: string, value: any) =>
    this.setState(prevState => ({
      fuelMeasurement: {
        ...prevState.fuelMeasurement,
        [key]: value
      }
    }));

  render() {
    const { fuelMeasurement } = this.state;
    const { hideModal, visible } = this.props;
    return (
      <Modal
        title="Контрольный замер уровня топлива"
        visible={visible}
        footer={null}
        onCancel={hideModal}
      >
        <ModalContent>
          <Form
            initialValues={fuelMeasurement}
            onSubmit={this.onSubmit}
            validate={(values: FuelMeasurementType) => {
              let errors: FormErrors<FuelMeasurementType> = {};
              const { vehicle } = this.props;
              const newPrimaryFuelLevel =
                parseFloat(values.newPrimaryFuelLevel) || 0;
              if (!vehicle) {
                errors.newPrimaryFuelLevel =
                  'Отсутствует информация об установленном баке на ТС';
              } else if (
                newPrimaryFuelLevel >
                parseFloat(vehicle.vehicleModel.primaryTankVolume)
              ) {
                errors.newPrimaryFuelLevel = `Указанный уровень топлива не может быть больше объема топливного бака (${vehicle
                  .vehicleModel.primaryTankVolume || 0} л)`;
              } else if (!values.document) {
                errors.document = new RequiredFieldMessage(
                  'Обязательно для заполнения'
                );
              }
              return errors;
            }}
          >
            {(FormField, formikProps) => {
              const { handleSubmit, setFieldValue, isSubmitting } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <Grid gutter="16px" cols={3}>
                    <GridItem>
                      <FormField
                        label= { isVehicleTypeElectric(this.props.vehicle.vehicleModel.type) ? "Уровень заряда %" : "Уровень топлива, л" }
                        required
                        name="newPrimaryFuelLevel"
                      >
                        {({ value, name }) => (
                          <InputNumber
                            onChange={newPrimaryFuelLevel =>
                              setFieldValue(name, newPrimaryFuelLevel)
                            }
                            value={value}
                            min={0}
                            precision={2}
                            step={0.1}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField name="date" required label="Дата замера">
                        {({ name, value }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            disabledDate={this.disabledDate}
                            value={value ? moment.utc(value) : value}
                            onChange={(date: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem fullWidth>
                      <FormField
                        name="document"
                        required
                        label="Документ-основание"
                        hasFeedback={false}
                      >
                        {({ name, value }) => (
                          <TextArea
                            onChange={e => setFieldValue(name, e.target.value)}
                            value={value}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                  <ButtonGroup>
                    <Button onClick={hideModal}>Отменить</Button>
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      htmlType="submit"
                      data-cy="save"
                    >
                      Сохранить
                    </Button>
                  </ButtonGroup>
                </form>
              );
            }}
          </Form>
        </ModalContent>
      </Modal>
    );
  }
}
export default connect(
  (state: AppState) => ({
    vehicle: state.vehicle
  }),
  {
    fetchVehicle
  }
)(FuelMeasurementForm);
