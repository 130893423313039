// @flow
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Card, FilterButtonsContainer} from '../../../../components';
import Select from '../../../../components/ui/Select';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import {Filter} from '../../../../components/hoc';
import FilterContainer from '../../../../components/hoc/FilterContainer';
import FilterItem from '../../../../components/hoc/FilterItem';
import moment from 'moment';
import {formatDateTimeToISOString} from '../../../../lib/helpers';
import Datepicker from 'antd/lib/date-picker';
import {ContractTripApi} from '../../../../lib/api';
import notification from 'antd/lib/notification';
import {ContractTripError} from '../../../../lib/types/contractTrips';

const { Option } = Select;
const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;

export type ContractTripVehiclesFilterParams = {
  licensePlate?: string,
  brandName?: string,
  startDate?: string,
  endDate?: string,
  number?: number,
  error?: $Keys<ContractTripError>
};

type Props = {
  filter: ContractTripVehiclesFilterParams,
  applyFilter: Function,
  cleanFilter: Function,
  monthId: number
};

const ContractTripVehiclesFilter = ({
  filter,
  applyFilter,
  cleanFilter,
  monthId
}: Props) => {
  const [licensePlates, setLicensePlates] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tripNumbers, setTripNumbers] = useState([]);
  const getFilterOptions = async () => {
    try {
      const {
        licensePlates,
        brands,
        tripNumbers
      } = await ContractTripApi.getFilterOptions(monthId);
      setLicensePlates(licensePlates);
      setBrands(brands);
      setTripNumbers(tripNumbers);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title
      });
    }
  };
  useEffect(() => {
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      filter['contractVehicle.vehicle.vehicleModel.brandId'] &&
      typeof filter['contractVehicle.vehicle.vehicleModel.brandId'] === 'string'
    ) {
      filter['contractVehicle.vehicle.vehicleModel.brandId'] = parseInt(
        filter['contractVehicle.vehicle.vehicleModel.brandId'],
        10
      );
    }
  }, [filter]);
  return (
    <Content>
      <FilterContainer>
        <Filter
          initialValues={filter}
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
          additionalFilter
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter,
            showAdditional,
            handleAdditional
          }: FilterChildrenParams<ContractTripVehiclesFilterParams>) => (
            <>
              <FilterItem>
                <Field mBottomNone>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Гос. номер"
                    value={values.licensePlate}
                    size="small"
                    onChange={(value: string) =>
                      changeValue('licensePlate', value)
                    }
                  >
                    {licensePlates.map((key: string) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    placeholder="Марка ТС"
                    value={
                      values['contractVehicle.vehicle.vehicleModel.brandId']
                    }
                    size="small"
                    onChange={(value: string) =>
                      changeValue(
                        'contractVehicle.vehicle.vehicleModel.brandId',
                        value
                      )
                    }
                  >
                    {brands.map(value => (
                      <Option key={value.item2} value={value.item2}>
                        {value.item1}
                      </Option>
                    ))}
                  </Select>
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <Datepicker
                    size="small"
                    value={
                      values.startDate ? moment(values.startDate) : undefined
                    }
                    format={'DD.MM.YYYY'}
                    onChange={(value, dateString) => {
                      changeValue(
                        'startDate',
                        formatDateTimeToISOString(value, dateString)
                      );
                      changeValue(
                        'endDate',
                        formatDateTimeToISOString(value, dateString)
                      ); // нужна для корректной работы фильтра на
                      // беке
                    }}
                  />
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <Select
                    showSearch
                    allowClear
                    placeholder="Номер ПЛ"
                    value={values.number}
                    size="small"
                    onChange={(value: string) => changeValue('number', value)}
                  >
                    {tripNumbers.map((key: string) => (
                      <Option key={key} value={key}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <Select
                    allowClear
                    placeholder="Ошибки"
                    value={values.error}
                    size="small"
                    onChange={(value: string) => changeValue('error', value)}
                  >
                    {Object.keys(ContractTripError).map((key: string) => (
                      <Option key={key} value={key}>
                        {ContractTripError[key]}
                      </Option>
                    ))}
                  </Select>
                </Field>
              </FilterItem>

              <FilterButtonsContainer
                applyFilter={applyFilter}
                cleanFilter={cleanFilter}
                handleAdditional={handleAdditional}
                showAdditional={showAdditional}
              />
            </>
          )}
        </Filter>
      </FilterContainer>
    </Content>
  );
};

export default ContractTripVehiclesFilter;
