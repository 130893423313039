// @flow
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import moment from 'moment';
import React, {Component} from 'react';
import {Form} from '../../../../components';
import {StyledInputNumber} from '../../../../components/hoc/common/components/elements';

import {Grid, GridItem, Section} from '../../../../components/layout';
import {notificationLoading} from '../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../components/ui';
import {TableHeader} from '../../../../components/ui/Table';

import {cargoPassCalculationApi, springPassCalculationApi, vehiclePlanApi} from '../../../../lib/api';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum} from '../../../../lib/enum';
import {getListInitialState, plus, toLocalStringRu} from '../../../../lib/helpers';
import {printNotification} from '../../../../lib/notificationWrapper';
import type {CalculationStatus, CargoPassCalculation, ListState, UserAccess, VehiclePlan} from '../../../../lib/types';
import {withUserAccess} from '../../../withUserAccess';
import {canEditBudget} from '../accessRight';

import type {VehicleListFilterParams} from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';

import {COGNOS_DELTA, COLUMNS, filteredData, MONTH} from '../lib';

import {headerPanel} from './components/Common';
import styled from 'styled-components';
import TotalBlock from './components/TotalBlock';
import {EditingModal} from './components/EditingModal';
import InfoBlock from '../components/InfoBlock';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<CargoPassCalculation> & {
  vehiclePlan: VehiclePlan,
  columns: any,
  filter: VehicleListFilterParams,
  visible: boolean,
  cargoPassCalculation: ?CargoPassCalculation,
  springPassTotalValue: ?number,
  editingModalVisible: boolean,
  rowData: ?CargoPassCalculation,
  monthIndex: number,
  cognosData: ?number,
  openPeriod?: {
    start: string,
    end: string,
  },
  hasUnsavedValues: boolean,
};

const CargoPass = class extends Component<Props, State> {
  onRow = (cargoPassCalculation: CargoPassCalculation) => {
    return {
      onClick: () => {
        this.isDraft() &&
        this.setState({
          visible: true,
          cargoPassCalculation: {...cargoPassCalculation},
        });
      },
    };
  };

  onCell = (data: CargoPassCalculation, monthIndex: number) => ({
    onClick: e => {
      if (!this.canEdit()) {
        return;
      }
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos &&
          vehiclePlan?.cargoPassCalculationStatus === calculationStatusEnum.draft && data.id &&
          dateIsIncludeInOpenPeriod(openPeriod, date)) {
        e.stopPropagation();
        this.setState({
          rowData: data,
          monthIndex,
          editingModalVisible: true,
        });
      }
    },
  });

  isRangeMonths = (monthIndex: number) => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  // дата после списания
  afterPlannedWriteoffDate = (
    monthIndex: number,
    plannedWriteoffDate?: ?string,
  ) => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(
        plannedWriteoffDate,
        'month',
      );
    }
    return false;
  };

  isDraft = () =>
    this.state?.vehiclePlan?.cargoPassCalculationStatus ===
    calculationStatusEnum.draft;

  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    filter: {},
    columns: [
      ...COLUMNS,
      {
        title: (
          <TableHeader width="120px">
            Тариф за превышение допустимой нормы
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'excessRate',
        render: excessRate =>
          toLocalStringRu(excessRate, {minimumFractionDigits: 2}),
      },
      {
        title: <TableHeader width="120px">Тариф госпошлины</TableHeader>,
        dataIndex: 'taxRate',
        width: 152,
        render: taxRate =>
          toLocalStringRu(taxRate, {minimumFractionDigits: 2}),
      },
      {
        title: <TableHeader width="120px">Тариф согласования</TableHeader>,
        dataIndex: 'agreementRate',
        width: 152,
        render: excessRate =>
          toLocalStringRu(excessRate, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="120px">Планируемый пробег, км в день</TableHeader>
        ),
        width: 152,
        dataIndex: 'plannedKilometrage',
        render: plannedKilometrage => toLocalStringRu(plannedKilometrage),
      },
      {
        title: (
          <TableHeader width="120px">
            Планируемое кол-во рабочих дней в месяц
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'workDaysCount',
        render: workDaysCount => toLocalStringRu(workDaysCount),
      },
      ...MONTH.map((month, index) => ({
        title: month.title,
        dataIndex: 'month.isCalculationNeeded',
        key: index,
        children: [
          {
            title: 'Пропуск',
            dataIndex: `months[${index}].cost`,
            render: cost => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(cost, {minimumFractionDigits: 2})}
              </span>
            ),
            onCell: monthData => this.onCell(monthData, index),
          },
          {
            title: 'Гос. пошлина',
            dataIndex: `months[${index}].tax`,
            render: tax => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(tax, {minimumFractionDigits: 2})}
              </span>
            ),
            onCell: monthData => this.onCell(monthData, index),
          },
          {
            title: <TableHeader width="80px">Стоимость соглашения</TableHeader>,
            dataIndex: `months[${index}].agreementCost`,
            render: agreementCost => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(agreementCost, {minimumFractionDigits: 2})}
              </span>
            ),
            onCell: monthData => this.onCell(monthData, index),
          },
          {
            title: <TableHeader width="80px">Стоимость бланка</TableHeader>,
            dataIndex: `months[${index}].blankPrice`,
            render: blankPrice => (
              <span
                className={
                  this.isDraft() && this.isRangeMonths(index)
                    ? 'link-text'
                    : null
                }
              >
                {toLocalStringRu(blankPrice, {minimumFractionDigits: 2})}
              </span>
            ),
            onCell: monthData => this.onCell(monthData, index),
          },
          {
            title: 'Итого',
            dataIndex: `months[${index}].tax`,
            key: `totalTax${index}`,
            className: 'primary-background',
            render: (tax, row: CargoPassCalculation) => {
              const sum = plus(
                plus(tax, row.months[index].cost),
                plus(
                  row.months[index].agreementCost,
                  row.months[index].blankPrice,
                ),
              );
              return (
                <span
                  className={
                    this.isDraft() && this.isRangeMonths(index)
                      ? 'link-text'
                      : null
                  }
                >
                  {toLocalStringRu(sum, {minimumFractionDigits: 2})}
                </span>
              );
            },
          },
        ],
      })),
      {
        title: (
          <TableHeader width="120px">
            Итого годовая сумма в руб на пропуска. без НДС
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'passSum',
        render: passSum =>
          toLocalStringRu(passSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб на пошлину руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'taxSum',
        render: taxSum => toLocalStringRu(taxSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб на согласование руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'agreementSum',
        render: agreementSum =>
          toLocalStringRu(agreementSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="130px">
            Итого годовая сумма в руб. на бланки, руб. без НДС
          </TableHeader>
        ),
        width: 162,
        dataIndex: 'blankSum',
        render: blankSum =>
          toLocalStringRu(blankSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="120px">
            Итого годовая сумма в руб на ТС
          </TableHeader>
        ),
        width: 152,
        dataIndex: 'sum',
        render: sum => toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
    ],
    visible: false,
    rowIndex: undefined,
    cargoPassCalculation: null,
    springPassTotalValue: null,
    hasUnsavedValues: false,
  };

  async componentDidMount() {
    this.getData();
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }

  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };

  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };

  fetch = async () => {
    const {vehiclePlanId} = this.props;
    try {
      let {data} = await cargoPassCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      let {data: springData} = await springPassCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      this.setState({
        springPassTotalValue: this.itogCalculation(springData).sum,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      this.setState({data});
    } catch (error) {
      notification.error({message: error.message});
    }
  };

  handleSave = async (cargoPassCalculation: CargoPassCalculation) => {
    const {data} = this.state;
    this.setState({loading: true});
    const rowIndex = data.findIndex(
      item => item.id === cargoPassCalculation.id,
    );
    try {
      if (cargoPassCalculation && rowIndex !== -1) {
        data[rowIndex] = await cargoPassCalculationApi.calculation.update(
          cargoPassCalculation,
        );
        this.setState({ data, hasUnsavedValues: true });
      }
    } catch (error) {
      notification.warning({message: 'Не удалось обновить данные' + error});
    } finally {
      this.setState({
        loading: false,
        visible: false,
        cargoPassCalculation: null,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      cargoPassCalculation: null,
      editingModalVisible: false,
      rowData: null,
      monthIndex: null,
    });
  };

  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await cargoPassCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const {vehiclePlanId} = this.props;
    const {data} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      if (data.length === 0) {
        await cargoPassCalculationApi.calculate(vehiclePlanId);
      } else {
        await cargoPassCalculationApi.updateCalculations(vehiclePlanId);
      }
      this.getData();
      this.setState({hasUnsavedValues: false});
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  itogCalculation = (data: any) => {
    const itog = {
      // $FlowFixMe
      months: Array(12)
      .fill()
      .map(() => ({
        cost: 0,
        tax: 0,
        blankPrice: 0,
        agreementCost: 0,
        itog: 0,
        taxSum: 0,
        passSum: 0,
        agreementSum: 0,
      })),
      sum: 0,
      blankSum: 0,
      agreementSum: 0,
      taxSum: 0,
      passSum: 0,
    };
    data.forEach((item: any) => {
      itog.sum = plus(itog.sum, item.sum);
      itog.blankSum = plus(itog.blankSum, item.blankSum);
      itog.agreementSum = plus(itog.agreementSum, item.agreementSum);
      itog.taxSum = plus(itog.taxSum, item.taxSum);
      itog.passSum = plus(itog.passSum, item.passSum);
      item.months.forEach((month: any, index) => {
        itog.months[index].cost = plus(
          itog.months[index].cost,
          month.cost ?? 0,
        );
        itog.months[index].tax = plus(itog.months[index].tax, month.tax ?? 0);
        itog.months[index].taxSum = plus(
          itog.months[index].taxSum,
          month.taxSum ?? 0,
        );
        itog.months[index].blankPrice = plus(
          itog.months[index].blankPrice,
          month.blankPrice ?? 0,
        );
        itog.months[index].passSum = plus(
          itog.months[index].passSum,
          month.passSum ?? 0,
        );
        itog.months[index].agreementSum = plus(
          itog.months[index].agreementSum,
          month.agreementSum ?? 0,
        );
        itog.months[index].agreementCost = plus(
          itog.months[index].agreementCost,
          month.agreementCost ?? 0,
        );
        itog.months[index].itog = plus(
          itog.months[index].tax,
          plus(
            itog.months[index].cost,
            plus(
              itog.months[index].agreementCost,
              itog.months[index].blankPrice ?? 0,
            ),
          ),
        );
      });
    });
    return itog;
  };

  applyFilter = (filter: VehicleListFilterParams) => this.setState({filter});

  cleanFilter = () => this.setState({filter: {}});

  handlePrint = () => {
    printNotification(async () => {
      await cargoPassCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter,
      );
    });
  };

  handleEditingDataSave = async data => {
    try {
      notificationLoading({message: 'Сохранение данных', key: 'saving'});
      await cargoPassCalculationApi.calculation.update(data);
      await this.fetch();
      this.handleCancel();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  render() {
    const {vehiclePlanId} = this.props;
    const {
      columns,
      visible,
      loading,
      data,
      filter,
      cargoPassCalculation,
      vehiclePlan,
      editingModalVisible,
      rowData,
      monthIndex,
      cognosData,
      hasUnsavedValues,
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = this.itogCalculation(filtered);
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus || this.props.userAccess.includes(accessTypeEnum.admin))
        ? false
        : Math.abs(itog?.sum + this.state.springPassTotalValue - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'Пропуска негабарит',
          passTab: true,
        })}
        <Section>
          <InfoBlock links={{'Тарифы на пропуска': '/admin/pass-tariff'}} />
          <div style={{padding: '16px 16px 0px'}}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              vehiclePlanId={vehiclePlanId}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={columns}
            onRow={!isCognosStatus && this.onRow}
            bordered
            scroll={{
              x: 8000,
              y: 'calc(100vh - 570px)',
            }}
            pagination={{pageSize: 50}}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <ModalCargoPass
            visible={visible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            isRangeMonths={this.isRangeMonths}
            cargoPassCalculation={cargoPassCalculation}
            afterPlannedWriteoffDate={this.afterPlannedWriteoffDate}
          />
          {rowData && (
            <EditingModal
              visible={editingModalVisible}
              handleCancel={this.handleCancel}
              handleSave={this.handleEditingDataSave}
              data={rowData}
              monthIndex={monthIndex}
              type="passes"
            />
          )}
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={itog?.sum}
                controllerName="cargoPassCalculation"
                vehiclePlanId={vehiclePlanId}
                additionalData={{
                  springPassTotal: this.state.springPassTotalValue,
                }}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            <ButtonsRow>
              {canEditBudget(this.props.userAccess) && (
                <>
                  {this.isDraft() && (
                    <>
                      {!isCognosStatus && !loading && (
                        <Button type="primary" onClick={this.calculate}>
                          {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled || hasUnsavedValues}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : hasUnsavedValues
                              ? 'Для утверждения формы затрат необходимо произвести расчет'
                              : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!planApproved &&
                    !this.isDraft() &&
                    vehiclePlan?.status !==
                    vehiclePlanStatusEnum.approvedAfterCognos && (
                      <>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.changeStatus(calculationStatusEnum.draft)
                          }
                        >
                          Редактировать
                        </Button>
                      </>
                    )}
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Wrapper>
        </Section>
      </>
    );
  }
};

type ModalCargoPassProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  isRangeMonths: Function,
  cargoPassCalculation: ?CargoPassCalculation,
  afterPlannedWriteoffDate: Function
};

const ModalCargoPass = (props: ModalCargoPassProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    cargoPassCalculation,
    isRangeMonths,
    afterPlannedWriteoffDate,
  } = props;
  const onOk = data => {
    handleSave(data);
  };
  const onCancel = () => {
    handleCancel();
  };

  return (
    <Modal
      forceRender
      destroyOnClose
      width={400}
      title="Редактирование"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Отмена
        </Button>,
        <Button key="submit" type="primary" form="modal-form" htmlType="submit">
          Сохранить
        </Button>,
      ]}
    >
      <Form initialValues={cargoPassCalculation} onSubmit={onOk}>
        {(FormField, formikProps: FormikProps) => {
          const {setFieldValue} = formikProps;
          return (
            <form onSubmit={formikProps.handleSubmit} id="modal-form">
              <Grid gutter="16px">
                <GridItem fullWidth>
                  <FormField
                    label="Планируемый пробег, км в день"
                    fast
                    name="plannedKilometrage"
                  >
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem fullWidth>
                  <FormField
                    label="Планируемое кол-во рабочих дней в месяц"
                    fast
                    name="workDaysCount"
                  >
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>

                {
                  <FormField label="Необходимы пропуска:" fast name="months">
                    {({value}) => {
                      return value
                        ? value.map((val, index) => {
                          return (
                            <GridItem fullWidth key={index}>
                              <Checkbox
                                name={`months[${index}].isCalculationNeeded`}
                                checked={val.isCalculationNeeded}
                                disabled={
                                  !isRangeMonths(index) ||
                                  afterPlannedWriteoffDate(
                                    index,
                                    cargoPassCalculation
                                      ?.selfVehiclePlanVehicle?.vehicle
                                      ?.plannedWriteoffDate ||
                                    cargoPassCalculation
                                      ?.selfVehiclePlanVehicle
                                      ?.plannedWriteoffDate,
                                  )
                                }
                                onChange={e =>
                                  setFieldValue(
                                    `months[${index}].isCalculationNeeded`,
                                    e.target.checked,
                                  )
                                }
                              >
                                {MONTH[index].title}
                              </Checkbox>
                            </GridItem>
                          );
                        })
                        : null;
                    }}
                  </FormField>
                }
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default withUserAccess(CargoPass);
