// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { stockApi } from './../../lib/api';
import type { Stock } from '../../lib/types';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await stockApi.fetch({
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={stockApi.get}
    notFoundText="Склады не найдены"
    placeholder="Выберите склад"
    renderOption={(stock: Stock, Option) => (
      <Option key={stock.id} value={stock.id} stock={stock}>
        {`Склад ${stock.mnemocode}`}
      </Option>
    )}
    {...selectProps}
  />
);
