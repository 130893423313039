// @flow

import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import notification from 'antd/lib/notification';
import CloneDeep from 'lodash/cloneDeep';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, {Component} from 'react';
import styled from 'styled-components';
import {notificationLoading} from '../../../../components/Notifications';
import {AntTable, ButtonsRow, Icon} from '../../../../components/ui';

import {materialCalculationApi, vehiclePlanApi} from '../../../../lib/api';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum, materialsMtoStatusEnum, materialsMtoStatus} from '../../../../lib/enum';
import {getListInitialState, multipliedBy, plus, toLocalStringRu} from '../../../../lib/helpers';
import {printNotification} from '../../../../lib/notificationWrapper';
import type {
  CalculationStatus,
  ListState,
  MaterialCalculation,
  MaterialCalculationMonth,
  MdmNode,
  UserAccess,
  VehiclePlan,
} from '../../../../lib/types';
import {withUserAccess} from '../../../withUserAccess';
import {canEditBudget} from '../accessRight';

import {COGNOS_DELTA, itogCalculation, MONTH} from '../lib';

import {Selects, Card as CardComponent} from './../../../../components';
import {Section} from './../../../../components/layout';
import {headerPanel} from './components/Common';
import TotalBlock from './components/TotalBlock';
import InfoBlock from '../components/InfoBlock';
import Grid, {GridItem} from './../../../../components/layout/Grid';
import { size } from 'lodash';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = ListState<MaterialCalculation> & {
  vehiclePlan: VehiclePlan,
  oldData: MaterialCalculation[],
  deletedIds: number[],
  columns: any,
  touched: boolean,
  cognosData: ?number
};

const { MdmSelect, BudgetLineItemSelect, StockSelect } = Selects;
const StyledMdmSelect = styled(MdmSelect)`
  position: relative;
  top: 6px;
  max-width: 267px !important;
`;
const Ellipsis = styled.div`
  width: 267px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SectionContent = styled.div`
  padding: 16px;
  padding-bottom: 0px;
`;

const {Field} = CardComponent;

const Material = class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    touched: false,
    deletedIds: [],
    columns: [
      {
        title: 'Maтериал',
        key: 'mtr',
        dataIndex: 'mtr',
        fixed: 'left',
        width: 300,
        render: (mtr: ?MdmNode, row: MaterialCalculation & { itog?: boolean }) => {
          return !!row.itog
            ? 'Итого:'
            : this.isDraft()
              ? (
                <StyledMdmSelect
                  size="small"
                  value={mtr?.name}
                  disableNonePrice
                  allowClear={false}
                  excludeIds={this.state.data.map(d => d.mtrId)}
                  onChange={(value, option) => {
                    const { vehiclePlanId } = this.props;
                    let { data } = this.state;
                    if (mtr) {
                      const index = data.findIndex(i => i.id === row.id)
                      data[index].mtr = option?.props?.mdm;
                      data[index].mtrId = option?.props?.mdm.id;
                    } else {
                      data = [
                        ...data,
                        {
                          mtr: option?.props?.mdm,
                          mtrId: option?.props?.mdm.id,
                          sum: 0,
                          vehiclePlanId,
                          months: this.emptyMonths()
                        }
                      ];
                    }
                    this.setState({ data, touched: true });
                  }}
                />
              )
              : (
                <Ellipsis> {mtr?.name} </Ellipsis>
              );
        }
      },
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'Кол-во',
            key: `months[${monthIndex}].count`,
            dataIndex: `months[${monthIndex}].count`,
            width: 123,
            render: (count: number, row: MaterialCalculation,) => count || count === 0
              ? (
                this.isDraft()
                  ? (
                    <InputNumber
                      size="small"
                      value={count}
                      min={0}
                      step={0.1}
                      disabled={!this.isRangeMonths(monthIndex) || !this.canEdit()}
                      onChange={value => {
                        let { data } = this.state;
                        const count = isNaN(+value) ? 0 : +value;
                        const cost = multipliedBy(count, +row.mtr.currentCost);
                        row.months[monthIndex].cost = cost;
                        row.months[monthIndex].count = count;
                        row.sum = row.months.reduce(
                          (sum, month) => {
                            return plus(sum, month.cost);
                          },
                          0
                        );
                        this.setState({ data, touched: true });
                      }}
                    />
                  )
                  : count
              )
              : null
          },
          {
            title: 'Стоимость',
            key: `months[${monthIndex}].cost`,
            dataIndex: `months[${monthIndex}].cost`,
            width: 100,
            render: cost =>
              cost ? toLocalStringRu(cost, { minimumFractionDigits: 2 }) : null
          }
        ]
      })),
      {
        title: 'Итог',
        key: 'sum',
        dataIndex: 'sum',
        width: 100,
        render: sum =>
          sum ? toLocalStringRu(sum, { minimumFractionDigits: 2 }) : null
      },
      {
        title: '',
        key: 'del',
        width: 50,
        render: (
          val,
          row: MaterialCalculation & { itog?: boolean },
          rowIndex
        ) => {
          return isEmpty(val) ||
            !!row.itog !== false ? null : this.isDraft() ? (
            <Icon type="x" onClick={() => this.removeRow(rowIndex)} />
          ) : null;
        }
      }
    ]
  };

  isRangeMonths = (monthIndex: number) => {
    const { vehiclePlan } = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  removeRow = (index: number) => {
    let { data, deletedIds } = this.state;
    if (data[index].id) {
      deletedIds = [...deletedIds, +data[index].id];
    }
    data.splice(index, 1);
    this.setState({ data, deletedIds, touched: true });
  };

  emptyMonths = (): MaterialCalculationMonth[] =>
    Array(12)
      .fill()
      .map((u, index) => ({ cost: 0, count: 0, month: ++index }));

  componentDidMount() {
    this.getData();
  }

  fetch = async () => {
    const { vehiclePlanId } = this.props;
    try {
      let { data } = await materialCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
        orderBy: 'id',
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data, oldData: CloneDeep(data), touched: false });
    } catch (error) {
      notification.error({ message: 'Не удалось запросить данные' });
    }
  };

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehiclePlan = async () => {
    const { vehiclePlanId } = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({ vehiclePlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  deleteMaterialCalculation = async (id: number) => {
    try {
      await materialCalculationApi.delete(id);
    } catch {
      notification.warning({ message: `Не удалось удалить запись ${id}` });
    }
  };

  handleSave = async () => {
    const { data, deletedIds } = this.state;
    this.setState({ loading: true });
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await materialCalculationApi.addOrUpdate(data);
      let deleting = [];
      deletedIds.forEach(id => {
        deleting.push(this.deleteMaterialCalculation(id));
      });
      await Promise.all(deleting);
      this.getData();
    } catch (error) {
      notification.warning({ message: 'Не удалось обновить данные' });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    const { oldData } = this.state;
    this.setState({ data: CloneDeep(oldData), touched: false, deletedIds: [] });
  };

  changeStatus = async (status: CalculationStatus) => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await materialCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      notification.close('saving');
    }
  };

  sendToMto = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Отправка данных в МТО',
        key: 'saving'
      });
      await materialCalculationApi.sendToMto(vehiclePlanId);
      notification.success({ message: 'Данные в МТО успешно отправлены'});
      this.getData();
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      notification.close('saving');
    }
  };

  isDraft = () =>
    this.state.vehiclePlan?.materialCalculationStatus ===
    calculationStatusEnum.draft;

  isCopy = () =>
    this.state.vehiclePlan?.status ==  vehiclePlanStatusEnum.approvedAfterCognos
    || this.state.vehiclePlan?.status ==  vehiclePlanStatusEnum.approvementAfterCognos;

  renderFooter = () => {
    return (
      <>
        <Button
          type="primary"
          size="small"
          onClick={this.handleSave}
          style={{ marginRight: '16px' }}
        >
          Сохранить
        </Button>
        <Button size="small" onClick={this.handleCancel}>
          Очистить все
        </Button>
      </>
    );
  };

  handlePrint = () => {
    printNotification(async () => {
      await materialCalculationApi.print(this.props.vehiclePlanId, {});
    });
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch
      ].includes(access)
    );

  canSendToMto = () =>
    !this.isCopy()
    && this.props.userAccess.some(access =>
      [
        accessTypeEnum.adminBranch,
        accessTypeEnum.admin,
        accessTypeEnum.sendingMaterialsBudgetToMto
      ].includes(access)
    && this.state.vehiclePlan?.materialCalculationStatus ===  calculationStatusEnum.calculationDone
    && [
        materialsMtoStatusEnum.notSend,
        materialsMtoStatusEnum.неСогласован,
        materialsMtoStatusEnum.error
      ].includes(this.state.vehiclePlan?.materialsMtoStatus)
  );

  render() {
    let { data, touched, vehiclePlan, cognosData } = this.state;
    const { vehiclePlanId } = this.props;
    const { columns, loading } = this.state;
    data = this.isDraft()
      ? [...data, {}, { ...itogCalculation(data), itog: true }]
      : [...data, { ...itogCalculation(data), itog: true }];

    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
        ? false
        : Math.abs((data[data.length - 1]?.sum || 0) - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title:
            'Расчет стоимости и потребности в материалах и инструментах (РЭН)'
        })}
        <Section>
          <InfoBlock links={{ МТРиО: '/admin/mdm' }} />
          {!this.isCopy() && <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Статус АС «МТО»">
                  {materialsMtoStatus[vehiclePlan?.materialsMtoStatus]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Комментарий">
                  {vehiclePlan?.materialsMtoComment}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Статья БЗИЗ">
                  {this.isDraft() 
                  ? <BudgetLineItemSelect
                    onChange={async (lineId, option) => {
                      try {
                        await materialCalculationApi.setBzizBudgetLine(vehiclePlan.id, lineId);
                      } catch (error) {
                        notification.error({ message: error.message });
                      }     
                      
                      vehiclePlan.materialsBzizBudgetLineId = lineId;
                      this.setState(vehiclePlan);
                    }}
                    value={vehiclePlan?.materialsBzizBudgetLineId}
                    style={{width: '90%'}}
                    />
                  : vehiclePlan?.materialsBzizBudgetLine?.name}

                </Field>
              </GridItem>
              <GridItem>
                <Field label="Склад">
                  {this.isDraft() 
                  ? <StockSelect
                    onChange={async (stockId, option)  => {
                      try {
                        await materialCalculationApi.setStock(vehiclePlan.id, stockId);
                      } catch (error) {
                        notification.error({ message: error.message });
                      }              
                      vehiclePlan.materialsStockId = stockId;
                      this.setState(vehiclePlan);
                    }}
                    value={vehiclePlan?.materialsStockId}
                    style={{width: '90%'}}
                   />
                  : vehiclePlan?.materialsStock?.mnemocode}                   
                  </Field>
              </GridItem>
            </Grid>
          </SectionContent>}
          <AntTable
            rowKey="uid"
            loading={loading}
            data={data}
            columns={columns}
            bordered
            footer={touched && this.renderFooter}
            scroll={{
              x: 2700,
              y: 'calc(100vh - 450px)'
            }}
            pagination={{ pageSize: 50 }}
          />
        </Section>
        {
          <Section style={{ padding: '16px' }}>
            <Wrapper>
              {isCognosStatus && (
                <TotalBlock
                  filteredDataTotalSum={
                    data.length ? data[data.length - 1]?.sum : 0
                  }
                  controllerName="materialCalculation"
                  vehiclePlanId={vehiclePlanId}
                  setCognosData={cognosData => this.setState({ cognosData })}
                />
              )}
              <ButtonsRow>
                {this.canSendToMto() && 
                  <Button
                    type="primary"
                    onClick={() => this.sendToMto()}
                    disabled={planApproved}
                  >
                    Отправить в АС «МТО»
                  </Button>
                }
                {canEditBudget(this.props.userAccess) && (
                  <>
                    {this.isDraft() && (
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    )}
                    {!planApproved &&
                      !this.isDraft() &&
                      vehiclePlan?.status !==
                        vehiclePlanStatusEnum.approvedAfterCognos && (
                        <Button
                          type="primary"
                          onClick={() =>
                            this.changeStatus(calculationStatusEnum.draft)
                          }
                        >
                          Редактировать
                        </Button>
                      )}
                  </>
                )}
                {!touched && this.state.data.length > 0 && (
                  <Button onClick={this.handlePrint}>Печать</Button>
                )}
              </ButtonsRow>
            </Wrapper>
          </Section>
        }
      </>
    );
  }
};

export default withUserAccess(Material);
