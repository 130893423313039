// @flow
import React from 'react';
import styled from 'styled-components';
import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';
import notification from 'antd/lib/notification';
import {downloadRequestWithToken, maintenanceDocumentApi} from '../../../../lib/api';
import type {DetailedDefectiveStatement, Maintenance} from '../../../../lib/types';
import {getActualDetailedDefectiveStatement} from '../../../../lib/api/detailedDefectiveStatement';
import {SectionContent} from '../../../../components/hoc/common/components/elements';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import moment from 'moment';
import {Spin} from 'antd';
import {vehicleTypes} from '../../../../lib/enum';
import {fetchEmployee} from '../../../../lib/api/employee';
import Button from 'antd/lib/button';
import {notificationLoading} from '../../../../components/Notifications';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const BlockTitle = styled.h2`
  margin: 0 0 10px;
`;

const entryPointPath = '/admin/act/internal';

type Props = {
  id: number,
};

type State = {
  maintenance: Maintenance,
  defectiveStatement: DetailedDefectiveStatement,
  commissioners: any,
  chief: any,
};

export class CompletedWorksCard extends React.Component<Props, State> {
  state = {
    maintenance: null,
    defectiveStatement: null,
    commissioners: {},
    chief: null,
  };
  
  componentDidMount() {
    this.fetch();
  }
  
  fetchCommissioners = async () => {
    const {maintenance} = this.state;
    const {firstCommissioner, secondCommissioner, thirdCommissioner, fourthCommissioner, chief} = maintenance;
    const commissioners = {};
    let chiefData = null;
    if (firstCommissioner) {
      commissioners.firstCommissioner = await fetchEmployee(firstCommissioner.personId);
    }
    ;
    if (secondCommissioner) {
      commissioners.secondCommissioner = await fetchEmployee(secondCommissioner.personId);
    }
    ;
    if (thirdCommissioner) {
      commissioners.thirdCommissioner = await fetchEmployee(thirdCommissioner.personId);
    }
    ;
    if (fourthCommissioner) {
      commissioners.fourthCommissioner = await fetchEmployee(fourthCommissioner.personId);
    }
    ;
    if (chief && chief.personId !== firstCommissioner?.personId && chief.personId !== secondCommissioner?.personId
      && chief.personId !== thirdCommissioner?.personId && chief.personId !== fourthCommissioner?.personId) {
      chiefData = await fetchEmployee(chief.personId);
    } else {
      chiefData = chief ? Object.values(commissioners).find(item => item.personId === chief.personId) : null;
    }
    this.setState({commissioners, chief: chiefData});
  };
  
  fetch = async () => {
    try {
      const maintenanceDocument = await maintenanceDocumentApi.getMaintenanceDocument(this.props.id);
      const {maintenanceId, maintenance} = maintenanceDocument;
      const defectiveStatement = await getActualDetailedDefectiveStatement(maintenanceId);
      this.setState({maintenance, defectiveStatement}, this.fetchCommissioners);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  getFullName = (commissioner) => {
    if (commissioner) {
      return <p style={{margin: 0}}>{`${commissioner.lastname} ${commissioner.firstname} ${commissioner.middlename}`}</p>;
    }
    return '';
  };
  
  getCommissionerNames = () => {
    const {maintenance} = this.state;
    const {firstCommissioner, secondCommissioner, thirdCommissioner, fourthCommissioner} = maintenance;
    if (!firstCommissioner && !secondCommissioner && !thirdCommissioner && !fourthCommissioner) {
      return '-';
    }
    return (
      <>
        {this.getFullName(firstCommissioner)}
        {this.getFullName(secondCommissioner)}
        {this.getFullName(thirdCommissioner)}
        {this.getFullName(fourthCommissioner)}
      </>
    );
  };
  
  getCommissionerPositions = () => {
    const {commissioners} = this.state;
    const keys = Object.keys(commissioners);
    if (!keys.length) {
      return '-';
    }
    return (
      <>
        {keys.map(key => <p key={commissioners[key].id} style={{margin: 0}}>{commissioners[key].positionTitle || '-'}</p>)}
      </>
    );
  };
  
  handlePrint = async () => {
    try {
      notificationLoading({message: 'Формирование печатной формы', key: 'printing'});
      await downloadRequestWithToken(`/DetailedDefectiveStatement/${this.state.defectiveStatement.id}/print`);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    } finally {
      notification.close('printing');
    }
  }
  
  render() {
    const {id} = this.props;
    const {maintenance, defectiveStatement, chief} = this.state;
    const breadCrumbs = [
      {to: entryPointPath, name: 'Реестр первичных документов'},
      {
        to: `${entryPointPath}/completed-works-card/${id}`,
        name: `Акт выполненных работ хоз. способом № ${id}`,
      },
    ];
    
    if (!maintenance || !defectiveStatement) {
      return (
        <Preloader>
          <Spin size={'large'} />
        </Preloader>
      );
    }
    
    return (
      <CommonCardPage
        pageHeaderProps={{
          breadCrumbs,
          mainHeader: `Акт выполненных работ хоз. способом №${id}`,
          rightHeader: (
            <Button type='primary' onClick={this.handlePrint}>Печать</Button>
          )
        }}
      >
        <SectionContent>
          <BlockTitle>Основная информация</BlockTitle>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Наименование карточки работ">{`Карточка работ №${maintenance.id}`}</Field>
            </GridItem>
            <GridItem>
              <Field label="Вид работ">{maintenance.maintenanceWorkType.name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Дата документа">{moment(maintenance.created).format('DD.MM.YYYY')}</Field>
            </GridItem>
          </Grid>
        </SectionContent>
        <SectionContent>
          <BlockTitle>Транспортное средство</BlockTitle>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Марка">{maintenance.vehicle.vehicleModel.brandName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Модель">{maintenance.vehicle.vehicleModel.name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Государственный регистрационный знак">{maintenance.vehicle.licensePlate}</Field>
            </GridItem>
            <GridItem>
              <Field label="Тип">{vehicleTypes[maintenance.vehicle.vehicleModel.type]}</Field>
            </GridItem>
            <GridItem>
              <Field label="Инвентарный номер">{maintenance.vehicle.inventoryNumber || '-'}</Field>
            </GridItem>
            <GridItem>
              <Field label="Филиал">{maintenance.vehicle.parentOrgUnitName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Подразделение">{maintenance.vehicle.orgUnitName}</Field>
            </GridItem>
          </Grid>
        </SectionContent>
        <SectionContent>
          <BlockTitle>Ответственные лица</BlockTitle>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Члены комиссии - ФИО (Дефектный акт)">{this.getCommissionerNames()}</Field>
            </GridItem>
            <GridItem>
              <Field label="Члены комиссии - Должность (Дефектный акт)">{this.getCommissionerPositions()}</Field>
            </GridItem>
            <GridItem>
              <Field label="Руководитель - ФИО (Дефектный акт)">{
                chief
                  ? this.getFullName(chief)
                  : '-'
              }</Field>
            </GridItem>
            <GridItem>
              <Field label="Руководитель - Должность (Дефектный акт)">{
                chief
                  ? chief.positionTitle || '-'
                  : '-'
              }</Field>
            </GridItem>
          </Grid>
        </SectionContent>
        <SectionContent>
          <BlockTitle>Описание работ по устранению дефектов</BlockTitle>
          <Grid gutter="16px">
            {defectiveStatement.operations.map(item => (
              <GridItem fullWidth key={item.id}>
                <Grid gutter='16px' cols={6}>
                  <GridItem>
                    <Field label="Код">{item.code}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Наименование работы">{item.name}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Ед. изм.">Нормо-час</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Количество">{item.count}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Нормо-часы">{item.hours}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Нормо-часы всего">{item.hours * item.count}</Field>
                  </GridItem>
                </Grid>
              </GridItem>
            ))}
          </Grid>
        </SectionContent>
        <SectionContent>
          <BlockTitle>Расходники и запчасти</BlockTitle>
          <Grid gutter="16px">
            {defectiveStatement.parts.map(item => (
              <GridItem fullWidth key={item.id}>
                <Grid gutter='16px' cols={6}>
                  <GridItem>
                    <Field label="Код МТР">{item.stockPart.mtr.mnemocode}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="МТР">{item.stockPart.mtr.name}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Единица измерения">{item.stockPart.mtr.measure}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Количество">{item.count}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Стоимость ед. (руб. без НДС)">{item.stockPart.cost}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Сумма">{Math.round(item.stockPart.cost * item.count * 100) / 100}</Field>
                  </GridItem>
                </Grid>
              </GridItem>
            ))}
          </Grid>
        </SectionContent>
      </CommonCardPage>
    );
  };
};
