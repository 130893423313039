// @flow

import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {monitoringPriceApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';

import {Panel} from './../../components/layout';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../components/Notifications';

import InnerForm from './components/InnerForm';
import type {MonitoringPrice} from '../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id: ?string
};

type State = {
  monitoringPrice: ?MonitoringPrice
};

class MonitoringPriceForm extends Component<Props, State> {
  state = {
    monitoringPrice: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.id, 10);
    if (id) {
      try {
        const wash = await monitoringPriceApi.getMonitoringPrice(id);
        this.setState({ monitoringPrice: wash });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  onSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.id) {
        await monitoringPriceApi.updateMonitoringPrice(values);
      } else {
        await monitoringPriceApi.addMonitoringPrice(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/monitoring-price', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => navigate('/admin/monitoring-price', true);

  render() {
    const { monitoringPrice } = this.state;
    const { id } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/monitoring-price')}>
                Список прайсов мониторинга
              </Crumb>
              {id ? (
                <Crumb to={`/admin/monitoring-price/${id}`}>
                  Прайс мониторинга №{id}
                </Crumb>
              ) : (
                <Crumb to={`/admin/monitoring-price/new`}>
                  Новый прайс для мониторинга
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {id ? `Прайс мониторинга №${id}` : `Новый прайс для мониторинга`}
          </h1>
        </StyledPanel>
        <InnerForm
          monitoringPrice={monitoringPrice}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default MonitoringPriceForm;
