// @flow
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {BranchTabs, InfoBranch} from '.';
import Section, {SectionTitle} from '../../../../components/layout/Section';
import {AntTable} from '../../../../components/ui';
import {MONTH, printRequest, toLocalStringRu, tooltipFullText} from '../../../../lib/helpers';
import type {BranchBudgetSummary, UserAccess} from '../../../../lib/types';
import {sumBudgetLineItemBudget} from '../lib';
import {
  accessTypeEnum,
  branchBudgetSummaryCognosStatusEnum,
  branchBudgetSummaryCognosTypeEnum,
  contractVehiclePlanStatusEnum,
  vehiclePlanStatusEnum,
} from '../../../../lib/enum';
import Button from 'antd/lib/button';
import {notificationLoading} from '../../../../components/Notifications';
import {fetchRequest} from '../../../../lib/api';
import notification from 'antd/lib/notification';
import AntIcon from 'antd/lib/icon';
import isNumber from 'lodash/isNumber'

const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  userAccess: UserAccess[],
  fetchBranchData: Function,
  primaryBranchId?: number,
};

export default (props: Props) => {
  const {id, orgUnitId, branchBudgetSummary, userAccess, fetchBranchData} = props;
  const [localData, setData] = useState(branchBudgetSummary);
  
  const COLUMNS = [
    {
      title: 'Статья бюджетной формы',
      dataIndex: 'budgetLineItemName',
      width: 250,
      fixed: true,
      render: budgetLineItemName => <WhiteSpace style={{width: '95%'}}>{tooltipFullText(budgetLineItemName, 35)}</WhiteSpace>,
    },
    {
      title: 'Направление расходов',
      dataIndex: 'expenseDirectionName',
      width: 200,
      fixed: true,
      render: expenseDirectionName => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 35)}</WhiteSpace>
      )
    },
    {
      title: 'Затраты',
      dataIndex: 'sum',
      width: 120,
      fixed: true,
      render: sum => sum > 0 ? toLocalStringRu(sum) : 0
    },
    {
      title: (
        <>
          <p>Выделено за счёт</p>
          <p>экономии по</p>
          <p>закрытым периодам</p>
        </>
      ),
      dataIndex: 'cognosClosePeriodEconomy',
      width: 150,
      fixed: true,
      render: value => isNumber(value) ? toLocalStringRu(value) : 0
    },
    {
      title: (
        <>
          <p>Выделено за счёт</p>
          <p>экономии по</p>
          <p>открытым периодам</p>
        </>
      ),
      dataIndex: 'cognosOpenPeriodEconomy',
      width: 150,
      fixed: true,
      render: value => isNumber(value) ? toLocalStringRu(value) : 0
    },
    {
      title: (
        <>
          <p>Выделено</p>
          <p>дополнительно</p>
        </>
      ),
      dataIndex: 'cognosFundsAllocation',
      width: 150,
      fixed: true,
      render: value => isNumber(value) ? toLocalStringRu(value) : 0
    },
    ...MONTH.map((month, index) => ({
      title: month,
      children: [
        {
          title: 'Затраты',
          dataIndex: `months[${index}].plan`,
          width: 150,
          render: value => isNumber(value) ? toLocalStringRu(value) : 0
        },
        {
          title: (
            <>
              <p>Выделено за счёт</p>
              <p>экономии по</p>
              <p>закрытым периодам</p>
            </>
          ),
          dataIndex: `months[${index}].cognosClosePeriodEconomy`,
          width: 150,
          render: value => isNumber(value) ? toLocalStringRu(value) : 0
        },
        {
          title: (
            <>
              <p>Выделено за счёт</p>
              <p>экономии по</p>
              <p>открытым периодам</p>
            </>
          ),
          dataIndex: `months[${index}].cognosOpenPeriodEconomy`,
          width: 150,
          render: value => isNumber(value) ? toLocalStringRu(value) : 0
        },
        {
          title: (
            <>
              <p>Выделено</p>
              <p>дополнительно</p>
            </>
          ),
          dataIndex: `months[${index}].cognosFundsAllocation`,
          width: 150,
          render: value => isNumber(value) ? toLocalStringRu(value) : 0
        },
      ],
    })),
    {
      title: 'Источник покрытия',
      dataIndex: 'source',
      width: 200,
    },
  ];
  
  useEffect(() => {
    if (branchBudgetSummary) {
      const {budgetSummaryLineItems} = branchBudgetSummary;
      setData({
        ...branchBudgetSummary,
        budgetSummaryLineItems:
          sumBudgetLineItemBudget(budgetSummaryLineItems) ?? [],
      });
    }
  }, [branchBudgetSummary]);
  
  const loadBudgetFromCognos = async () => {
    notificationLoading({
      message: 'Выполняется загрузка данных из ИСУ',
      key: 'loading'
    });
    try {
      await fetchRequest.put(`/BranchBudgetSummary/loadFromCognos/${props.primaryBranchId}`)
      notification.success({
        message: 'Данные успешно получены'
      });
      fetchBranchData();
    } catch (e) {
      notification.error({
        message: 'Не удалось загрузить данные',
        description: e.message
      });
    } finally {
      notification.close('loading');
    }
  }
  
  // проверка, что все условия для отображения кнопки для обычных пользователей (не админов) выполнены
  const showDownloadButton = branchBudgetSummary &&
    branchBudgetSummary.cognosStatus === branchBudgetSummaryCognosStatusEnum.approved &&
    (branchBudgetSummary.selfVehiclePlan?.status !== vehiclePlanStatusEnum.approvedAfterCognos ||
      branchBudgetSummary.contractVehiclePlan?.status !== contractVehiclePlanStatusEnum.approvedAfterCognos);
  
  const isAdmin = userAccess.includes(accessTypeEnum.admin);
  
  return (
    <>
      <SectionTitle
        divider
        noWrapMe
        suffix={
          <>
            {orgUnitId > 0 &&
                userAccess.some(access => [accessTypeEnum.loadingBudgetFromCognos, accessTypeEnum.admin].includes(access)) && (
              <Button
                type="primary"
                onClick={loadBudgetFromCognos}
                disabled={!(showDownloadButton || isAdmin)}
                style={{marginRight: '10px'}}
              >
                Загрузить
              </Button>
            )}
            {props.branchBudgetSummary.id && (
              <Button onClick={() => printRequest(`/branchBudgetSummary/printDetailsISU/${props.branchBudgetSummary.id}`)}>
                Печать
              </Button>
            )}
          </>
        }
      >
        <BranchTabs id={id} orgUnitId={orgUnitId} />
      </SectionTitle>
      {!!branchBudgetSummary.id && branchBudgetSummary.type === branchBudgetSummaryCognosTypeEnum.cognos && (
        <InfoBranch source="cognos" branchBudgetSummary={localData} />
      )}
      <Section>
        <AntTable
          columns={COLUMNS}
          data={localData?.budgetSummaryLineItems ?? []}
          rowClassName={record => record.rowId < 0 ? 'ant-table-row-color-light-grey' : null}
          bordered
          rowKey="id"
          style={{overflow: 'auto'}}
          scroll={{
            y: '500px',
            x: '2000',
          }}
          pagination={false}
          childrenColumnName="expenseDirections"
          expandRowByClick
          expandIconAsCell={false}
          expandIconColumnIndex={1}
          expandIcon={row =>
            !!row.record.expenseDirections && (
              <AntIcon
                type={row.expanded ? 'up' : 'down'}
                style={{
                  fontSize: '12px',
                  float: 'right'
                }}
              />
            )
          }
        />
      </Section>
    </>
  );
};

