// @flow
import type {
  CalculationStatus,
  MaintenanceOperationContractCalculation,
  MaintenanceOperationContractCalculationMonth,
} from './../types';

import CommonApi from '../commonApi';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour,
} from '../helpers';
import {downloadRequestWithToken, fetchRequest} from './index';

export const convertDataToServer = (
  maintenanceOperationContractCalculation: MaintenanceOperationContractCalculation,
): MaintenanceOperationContractCalculation => ({
  ...maintenanceOperationContractCalculation,
  sum: convertFromRubToKop(maintenanceOperationContractCalculation.sum),
  materialSum: convertFromRubToKop(maintenanceOperationContractCalculation.materialSum),
  materialLessSum: convertFromRubToKop(maintenanceOperationContractCalculation.materialLessSum),
  months: maintenanceOperationContractCalculation.months.map(convertDataToServerMonth),
  machineHoursSinceLastMaintenance: convertFromHourToSecond(
    maintenanceOperationContractCalculation.machineHoursSinceLastMaintenance,
  ),
  milageSinceLastMaintenance: convertFromKmToM(maintenanceOperationContractCalculation.milageSinceLastMaintenance),
});

export const convertDataFromServer = (
  maintenanceOperationContractCalculation: MaintenanceOperationContractCalculation,
): MaintenanceOperationContractCalculation => ({
  ...maintenanceOperationContractCalculation,
  sum: convertFromKopToRub(maintenanceOperationContractCalculation.sum),
  materialSum: convertFromKopToRub(maintenanceOperationContractCalculation.materialSum),
  materialLessSum: convertFromKopToRub(maintenanceOperationContractCalculation.materialLessSum),
  months: maintenanceOperationContractCalculation.months.map(convertDataFromServerMonth),
  machineHoursSinceLastMaintenance: convertFromSecondToHour(
    maintenanceOperationContractCalculation.machineHoursSinceLastMaintenance,
  ),
  milageSinceLastMaintenance: convertFromMToKm(maintenanceOperationContractCalculation.milageSinceLastMaintenance),
});

export const convertDataToServerMonth = (
  maintenanceOperationContractCalculationMonth: MaintenanceOperationContractCalculationMonth,
): MaintenanceOperationContractCalculationMonth => ({
  ...maintenanceOperationContractCalculationMonth,
  cost: convertFromRubToKop(maintenanceOperationContractCalculationMonth.cost),
  materialSum: convertFromRubToKop(maintenanceOperationContractCalculationMonth.materialSum),
  correctedKilometrage: convertFromKmToM(maintenanceOperationContractCalculationMonth.correctedKilometrage),
  correctedWorkHours: convertFromHourToSecond(maintenanceOperationContractCalculationMonth.correctedWorkHours),
});

export const convertDataFromServerMonth = (
  maintenanceOperationContractCalculationMonth: MaintenanceOperationContractCalculationMonth,
): MaintenanceOperationContractCalculationMonth => ({
  ...maintenanceOperationContractCalculationMonth,
  cost: convertFromKopToRub(maintenanceOperationContractCalculationMonth.cost),
  materialSum: convertFromKopToRub(maintenanceOperationContractCalculationMonth.materialSum),
  correctedKilometrage: convertFromMToKm(maintenanceOperationContractCalculationMonth.correctedKilometrage),
  correctedWorkHours: convertFromSecondToHour(maintenanceOperationContractCalculationMonth.correctedWorkHours),
});

const basedUrl = 'maintenanceOperationContractCalculation';
const maintenanceOperationContractCalculation = new CommonApi<MaintenanceOperationContractCalculation>(
  basedUrl,
  'расчет техобслуживания',
  convertDataToServer,
  convertDataFromServer,
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`,
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (vehiclePlanId: number, status: CalculationStatus) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status,
  });
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number) => {
  const printed = await downloadRequestWithToken(
    `/${basedUrl}/print?vehiclePlanId=${vehiclePlanId}`,
  );
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async monthData => {
  const updated = await fetchRequest.put(`/${basedUrl}/correct`, convertDataToServerMonth(monthData));
  if (!updated) {
    throw new Error(`Не удалось сменить статус расчет`);
  }
};

export default {
  ...maintenanceOperationContractCalculation,
  calculate,
  print,
  changeStatus,
  updateMonth
};
