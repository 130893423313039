// @flow
import CommonApi from './../commonApi';
import { DebetCreditAccountItem } from '../types/debetCreditAccounts';

const debetCreditAccounts = new CommonApi<DebetCreditAccountItem>(
  'debitcreditaccount',
  'Дебет/кредит счетов'
);

export default {
  ...debetCreditAccounts
};
