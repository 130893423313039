// @flow
import React from 'react';

import notification from 'antd/lib/notification';

import { platonApi } from '../../../../../lib/api';
import type { Platon } from '../../../../../lib/types';

import Filter from '../../../../services/platon/Filter';
import { PLATON_COLUMNS } from '../../../../services/platon';

import { ListTable } from '../../../lib';

export default class extends ListTable<Platon> {
  fetch = async (page: number = 1) => {
    const params = super.fetch(page);
    try {
      const data = await platonApi.fetchPlatons(params);
      const totalSum = await platonApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(PLATON_COLUMNS)}
      </>
    );
  }
}
