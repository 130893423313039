// @flow
import type moment from 'moment';

import type {FuelMultiplier} from './fuelMultiplier';
import type {
  AttachedEquipment,
  AttachedEquipmentWorkHour,
  Driver,
  Employee,
  OrderObjective,
  Route,
  TripStatus,
  Vehicle,
  VehicleGroup,
  VehicleType,
} from './';
import {contractTripStatusEnum, entityTypesEnum} from '../enum';

// Топливные коэффициенты на участке
export type TripRange = {
  id?: number,
  // Путевой лист
  tripId: number,
  // Количество км
  amount: number,
  // Суммарный коэффициент расхода топлива по участку
  sumFuelMultiplier: number,
  // Список топливных коэффициентов
  fuelMultipliers?: FuelMultiplier[]
};

export const TripHistoryTypes = {
  changeStatus: 'Изменение статуса путевого листа',
  recalculating: 'Пересчёт полей в зависимом путевом листе'
};

export type TripHistoryItem = {
  changes: any,
  created: string,
  employee: Employee,
  entityId: number,
  entityType: entityTypesEnum<key>,
  id: number,
  message: string,
  type: TripHistoryTypes<key>,
  userName: string
};

export type ContractTripStatus = $Values<typeof contractTripStatusEnum>;

export type TripAttachedEquipment = {
  attachedEquipment: AttachedEquipment,
  attachedEquipmentId: number,
  fuelConsumption: number,
  machineHours: number,
  trip: any,
  tripId: number
};

export type Trip = {
  id: number,
  idNumber: ?number,
  contractIdNumber: ?number,
  vehicleType: VehicleType,
  vehicleGroup: VehicleGroup,
  objective: OrderObjective,
  status: TripStatus,
  expectedRouteId: number,
  expectedRoute: Route,
  workersCount: number,
  vehicleId: number,
  vehicle: Vehicle,
  ordersId: number[],
  employeeId: ?number,
  employee: ?Employee,
  orgUnitName: string,
  driverId: number,
  driver?: Driver,
  odometer: number,
  odometerAtStart: number,
  odometerAtEnd: number,
  odometerReaderEmployeeId: number,
  odometerReaderEmployee: Employee,
  fuelAtStart: number,
  fuelAtEnd: number,
  startMedicCheckupDate: Date,
  endMedicCheckupDate: Date,
  startMedicId: number,
  startMedic: Employee,
  endMedicId: number,
  endMedic: Employee,
  monitoringNotation?: ?string,
  startMedicFullName: string, // фио наемного мед. работника предрейсового осмотра
  endMedicFullName: string, // фио наемного мед. работника послерейсового осмотра
  startTechCheckupDate: Date,
  endTechCheckupDate: Date,
  startMechanicId: number,
  startMechanic: Employee,
  endMechanicId: number,
  endMechanic: Employee,
  startGpmControllerCheckupDate: Date,
  endGpmControllerCheckupDate: Date,
  startGpmControllerId: number,
  startGpmController: Employee,
  endGpmControllerId: number,
  endGpmController: Employee,
  engineerId: number,
  engineer: Employee,
  actualRouteId?: number,
  actualRoute?: Route,
  distanceAtStart: number,
  distanceAtEnd: number,
  expectedFuelConsumption: number, // расход топлива по нормативу
  distanceFuelConsumption: number, // фактический расход топлива,
  engineWorkFuelConsumption: number, // фактический расход топлива,
  issuedFuel: number, // выданное количество топлива,
  isGpm: boolean,
  // Отрезки рейса с итоговыми коэффициентами
  tripRanges: TripRange[],
  // часы по навесному оборудованию
  attachedEquipmentsWorkHour: AttachedEquipmentWorkHour[],
  // машчасы ТС
  vehicleMachineHoursAtStart?: number,
  vehicleMachineHoursAtEnd?: number,
  sumFuelMultiplier: ?number,
  isBusinessTrip: boolean,
  businessTripOrderNumber: string,
  // Суммарное количество часов работы доп. оборудования
  attachedEquipmentMachineHours: ?number,
  // ПЛ на сторону
  toOtherSide: boolean,
  // Дата и время выезда
  startDate: moment | string,
  // Дата и время прибытия
  endDate: moment | string,
  trailerId: ?number,
  trailer: ?Vehicle,
  withTrailer: boolean,
  // Id наемного ТС
  contractVehicleId?: number,

  // Поля относящиеся к НТС
  // Дата формирования
  formationDate?: string,
  // Время работы всего
  totalWorkingTime?: number,
  // Время в движении
  movementTime?: number,
  // Тариф в движении
  movementPrice?: number,
  // Время простоя
  idleTime?: number,
  // Тариф простоя
  idlePrice?: number,
  // Время в ожидании
  onHoldTime?: number,
  // Тариф в ожидании
  onHoldPrice?: number,
  // Тариф км
  pricePerKm: number,
  // Сумма без НДС
  totalPrice: number,
  // Время работы воздушного отопителя
  airHeaterTime: number,
  // Тариф для воздушного отопителя
  airHeaterRate: number,
  // Время работы жидкостного подогревателя
  liquidHeaterTime: number,
  // Тариф для жидкостного подогревателя
  liquidHeaterRate: number,
  // Время работы силового отопителя
  powerGeneratorTime: number,
  // Тариф для силового генератора
  powerGeneratorRate: number,
  // Время работы лебедки
  winchTime: number,
  // Тариф для лебедки
  winchRate: number,
  // Пробег с прицепом
  trailerKilometrage: number,
  // Тариф с прицепом
  trailerRate: number,
  // ПЛ создан через СУА
  createdByFirstBit: boolean,
  // Норма расхода, л / м/ч
  machineHoursFuelConsumption: number,
  // Норма расхода с навесным и дополнительным оборудованием, л / м/ч
  attachedEquipmentFuelConsumption: number,
  // Статус путевого листа НТС
  contractTripStatus: ContractTripStatus,
  // Пробег согласно регламенту
  regulationsDistance: number,
  // Пробег от подрядчика
  distanceByContractor: number,
  // Пробег из автографа
  autographDistance: number,
  // Гос. номер от подрядчика
  licensePlate: string,
  dispatcher: ?Employee,
  dispatcherId: number,
  // собственное ТС используется или наемное
  isContract: boolean,
  // История изменения ПЛ
  history: ?(TripHistoryItem[]),
  // Дата, с наступлением которой редактирование оттаксированного ПЛ запрещена
  dateBeforeCanEdit: ?string,
  // Дата таксирования ПЛ
  taxingDate: ?string,
  // Навесное оборудование
  tripAttachedEquipmentLinks: TripAttachedEquipment[],
  totalFuelConsumpton: number, // Общий расход топлива ТС + навесное оборудование
  currentOdometerAtEnd: ?number,
  currentOdometerAtStart: ?number,
  currentVehicleMachineHoursAtEnd: ?number,
  currentVehicleMachineHoursAtStart: ?number,
};
