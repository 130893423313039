// @flow
import React from 'react';

import {vehicleTaxRateApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {addAccessRight} from './accessRight';

const entryPointPath = '/admin/vehicle-tax-rate/';

const columns = [
  {
    title: 'Наименование объекта налогообложения',
    dataIndex: 'name'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));

  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}new`) : undefined
  };
  return (
    <Page>
      <CommonListPage
        crud={vehicleTaxRateApi}
        pageHeaderProps={{
          mainHeader: 'Налоговые коэффициенты для ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
        filter={Filter}
      />
    </Page>
  );
});
