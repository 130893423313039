// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';
import type { RegistrationPlace } from '../../lib/types';

import { registrationPlaceApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
type Props = SelectProps & { filter: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await registrationPlaceApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={registrationPlaceApi.get}
    notFoundText="Места регистрации ТС не найдены"
    placeholder="Выберите место регистрации ТС"
    renderOption={(place: RegistrationPlace, Option) => (
      <Option key={place.id} value={place.id} place={place}>
        {place.name}
      </Option>
    )}
    {...selectProps}
  />
);
