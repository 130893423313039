/* FilterCalculation.js | Filter | Расчёт стоимости ТС */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import InputNumber from 'antd/lib/input-number';
import { gpmVehicleTypes } from '../../lib/enum';
import { Card, Filter, FilterButtonsContainer, FilterContainer, FilterItem, RangePicker } from './../../components';
import type {VehicleGroupAndType} from '../../components/selects/VehicleTypeSelect';
import { OrgUnitSelect, Selects } from './../../components/';
import { formatDateTimeToISOString } from '../../lib/helpers';
import type { FilterChildrenParams } from '../../components/hoc/Filter';

const { Field } = Card;
const { OrderTypeDispatcherSelect, VehicleTypeSelect, OrderObjectiveSelect } = Selects;
const StyledContent = styled.div`
  padding: 16px;
`;

export type FilterCalculationParams = {
  id?: number,
  orgUnitId?: number,
};

export type OrdersType = 'calculation';

type Props = {
  filter: FilterCalculationParams,
  applyFilter: Function,
  cleanFilter: Function,
  mode: OrdersType,
  handlingCalculation: boolean
};

export const FilterCalculation = ({
  filter,
  applyFilter,
  cleanFilter,
  mode,
  handlingCalculation
}: Props) => (
  <StyledContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter={true}
      >
        {({
          values,
          showAdditional,
          changeValue,
          applyFilter,
          cleanFilter,
          handleAdditional,
        }: FilterChildrenParams<FilterCalculationParams>) => (
          <>
            {handlingCalculation && (
              <FilterItem>
                <Field mBottomNone>
                  <OrderTypeDispatcherSelect
                    size="small"
                    value={!!values?.getBranch ? 'all' : 'self'}
                    onChange={(value: string) =>
                      changeValue('getBranch', value === 'all')
                    }
                  />
                </Field>
              </FilterItem>
            )}
            <FilterItem>
              <Field mBottomNone>
                <InputNumber
                  style={{ width: '100%' }}
                  size="small"
                  placeholder="Номер расчёта"
                  onChange={value => changeValue('id', value)}
                  value={values?.id}
                  min={1}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm')
                    ]
                  }}
                  placeholder={['Начало', 'Конец']}
                  value={[
                    !!values?.startDate ? moment.utc(values.startDate) : null,
                    !!values?.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Все службы"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values?.orgUnitId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values?.vehicleGroup || values?.vehicleType}
                  selectableGroup
                  resultAsObject
                  onlyTypes={values?.isGpm && gpmVehicleTypes}
                  onChange={(value: VehicleGroupAndType) => {
                    if (value.group) {
                      changeValue('vehicleType', undefined);
                      changeValue('vehicleGroup', value.group);
                    } else {
                      changeValue('vehicleGroup', undefined);
                      changeValue('vehicleType', value.type);
                    }
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderObjectiveSelect
                  size="small"
                  placeholder="Цель поездки"
                  value={values?.objective}
                  onChange={value => changeValue('objective', value)}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </StyledContent>
);

export default FilterCalculation;
