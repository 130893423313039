// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import notification from 'antd/lib/notification';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import Filter from './components/Filter';
import { applyMaskToValue, declineNumber } from './../../../lib/helpers';
import type {
  ReportArchiveVehicle,
  ReportArchiveVehicleParams
} from '../../../lib/types/reportArchiveVehicle';
import { notificationLoading } from '../../../components/Notifications';
import { reportArchiveVehiclesApi } from '../../../lib/api';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialParams: ReportArchiveVehicleParams = {};

type State = {
  params: ReportArchiveVehicleParams,
  data: ReportArchiveVehicle[],
  loading: boolean
};

/**
 * Отчет о рекомендуемых к списанию/замене ТС
 */
export class ArchiveVehiclesList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      }
    }));
  };

  async componentDidMount() {
    await this.fetchReportArchiveVehicles();
  }

  fetchReportArchiveVehicles = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportArchiveVehiclesApi.fetchReportArchiveVehicles(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyParams = this.fetchReportArchiveVehicles;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print'
      });
      await reportArchiveVehiclesApi.printReportArchiveVehicles(
        this.state.params
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
      render: (type: string) => type
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName'
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Возраст',
      dataIndex: 'vehicleAge',
      render: (vehicleAge: number) =>
        `${vehicleAge} ${declineNumber(vehicleAge, 'год', 'года', 'лет')}`
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`
    },
    {
      title: 'Количество маш/часов',
      dataIndex: 'engineWorkHours',
      render: (workTime: number) => `${workTime} ч.`
    }
  ];

  render() {
    const { loading, data } = this.state;
    return (
      <>
        <Header left={<h1>Отчет о рекомендуемых к списанию/замене ТС</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            rowKey="vehicleId"
            pagination={false}
            dataSource={data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default ArchiveVehiclesList;
