// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import moment from 'moment';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import Button from 'antd/lib/button';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import { vehicleTypes, fuelTypes } from '../../../lib/enum';
import Filter from './components/Filter';
import { applyMaskToValue } from './../../../lib/helpers';
import type { FuelType, VehicleType } from '../../../lib/types';
import type {
  ReportFuelConsumption,
  ReportFuelConsumptionParams
} from '../../../lib/types/reportFuelConsumption';
import { reportFuelConsumptionApi } from '../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialFilter: any = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  filter: ReportFuelConsumptionParams,
  data: ReportFuelConsumption[],
  loading: boolean
};

/**
 * Отчет о расходе ГСМ
 */
export class FuelConsumptionList extends React.Component<null, State> {
  state = {
    filter: initialFilter,
    data: [],
    loading: false
  };

  async componentDidMount() {
    const { ...filter } = qs.parse(window.location.search);
    this.setState(
      { filter: isEmpty(filter) ? initialFilter : filter },
      this.fetchReportFuelConsumption
    );
  }

  fetchReportFuelConsumption = async () => {
    const { filter } = this.state;
    try {
      this.setState({ loading: true });
      const data = await reportFuelConsumptionApi.fetchReportFuelConsumption(
        filter
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла отчета...',
        key: 'print'
      });
      await reportFuelConsumptionApi.printReportFuelConsumption(
        this.state.filter
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  applyFilter = async (filter: ReportFuelConsumptionParams) =>
    this.setState({ filter }, this.fetchReportFuelConsumption);

  cleanFilter = () =>
    this.setState({ filter: initialFilter }, this.fetchReportFuelConsumption);

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла отчета...',
        key: 'print'
      });
      await reportFuelConsumptionApi.printReportFuelConsumption(
        this.state.filter
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      fixed: true,
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
      render: (type: VehicleType) => vehicleTypes[type]
    },

    {
      title: 'Служба',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`
    },
    {
      title: 'Вид топлива',
      dataIndex: 'fuelType',
      render: (fuelType: FuelType) => fuelTypes[fuelType] || '-'
    },
    {
      title: 'Марка',
      dataIndex: 'brandName'
    },
    {
      title: 'Количество маш/часов',
      dataIndex: 'machineHours',
      render: (machineHours: number) => `${machineHours} ч.`
    },
    {
      title: 'Выдано топлива по ПЛ',
      dataIndex: 'issuedFuel',
      render: (value: number) => `${value} л.`
    },
    {
      title: 'Истрачено топлива по ПЛ',
      dataIndex: 'consumedFuel',
      render: (value: number) => `${value} л.`
    },
    {
      title: 'Кол-во выписанных ПЛ',
      dataIndex: 'tripsCount',
      render: (count: number) => `${count} шт.`
    },
    {
      title: 'Остаток топлива на начало периода',
      dataIndex: 'fuelAtBeginning',
      render: (value: number) => `${value} л.`
    },
    {
      title: 'Остаток топлива на конец периода',
      dataIndex: 'fuelAtEnd',
      render: (value: number) => `${value} л.`
    }
  ];

  render() {
    const { filter, data, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Отчет о расходе ГСМ</h1>}
          right={
            <Button type="primary" onClick={this.handleExport}>
              Экспорт
            </Button>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={data}
            columns={this.columns}
            rowKey="vehicleId"
          />
        </Section>
      </>
    );
  }
}

export default FuelConsumptionList;
