// @flow
import React, { Component } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import { Selects, Card } from './../../../components';
import { ownerTypes } from './../../../lib/enum';
import { getDisabledEqipmentInstallRemoveDate } from './../../../containers/Equipment/lib';
import { convertDateToString } from './../../../containers/Vehicles/lib';

const { VehicleSelect } = Selects;
const { Field } = Card;
const getMomentValue = (value: ?moment) => (value ? moment.utc(value) : value);

const ErrorText = styled.p`
  color: red;
  margin: 5px 0;
`;

type PopconfirmTitleProps = {
  onChange: (key: string, value: any) => void,
  vehicleId: ?number,
  installDate?: string | moment,
  error?: string,
  disableDate?: boolean
};

const PopconfirmTitle = ({
  onChange,
  vehicleId,
  error,
  installDate,
  disableDate
}: PopconfirmTitleProps) => (
  <div>
    <Field label="ТС закрепления">
      <VehicleSelect
        value={vehicleId}
        filter={{
          ownerType: ownerTypes.self
        }}
        onChange={value => onChange('vehicleId', value)}
      />
    </Field>
    <Field label="Дата установки">
      <DatePicker
        format="DD.MM.YYYY"
        allowClear={false}
        value={getMomentValue(installDate)}
        onChange={(value: Object, dateString: string) =>
          onChange('installDate', convertDateToString(value, dateString))
        }
        disabledDate={disableDate && getDisabledEqipmentInstallRemoveDate}
      />
    </Field>
    {error && <ErrorText>{error}</ErrorText>}
    <p>Вы подтверждаете закрепление оборудования за выбранным ТС?</p>
  </div>
);

type Props = {
  onConfirm: (
    vehicleId: number,
    installDate?: string | moment
  ) => Promise<void>,
  vehicleId: ?number,
  disableDate?: boolean
};

type State = {
  vehicleId: ?number,
  installDate: Date,
  visible: boolean,
  errors: Object
};

export default class AttachVehicleButton extends Component<Props, State> {
  initialState = {
    vehicleId: this.props.vehicleId,
    installDate: moment().toISOString(),
    visible: false,
    errors: {}
  };

  state = this.initialState;

  static defaultProps = {
    disableDate: true
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps.vehicleId !== this.props.vehicleId) {
      this.setState({
        vehicleId: this.props.vehicleId
      });
    }
  }

  confirm = () => {
    const vehicleId = parseInt(this.state.vehicleId, 10);
    if (vehicleId) {
      this.props.onConfirm(vehicleId, this.state.installDate);
      this.setState(this.initialState);
    } else {
      this.setState({
        errors: {
          vehicleId: 'Не выбрано транспортное средство'
        }
      });
    }
  };

  showPopover = () =>
    this.setState({
      visible: true
    });

  hidePopover = () => this.setState(this.initialState);

  render() {
    const { vehicleId, visible, errors, installDate } = this.state;
    const { vehicleId: currentVehicleId, disableDate } = this.props;
    return (
      <Popconfirm
        title={
          <PopconfirmTitle
            onChange={(key: string, value: any) =>
              this.setState({
                [key]: value
              })
            }
            disableDate={disableDate}
            vehicleId={vehicleId}
            installDate={installDate}
            error={errors.vehicleId}
          />
        }
        visible={visible}
        okText="Да"
        placement="bottomLeft"
        cancelText="Нет"
        onConfirm={this.confirm}
        onCancel={this.hidePopover}
        icon={null}
      >
        <Button onClick={this.showPopover} type="primary">
          {`Закрепить ${currentVehicleId ? 'другое' : 'за'} ТС`}
        </Button>
      </Popconfirm>
    );
  }
}
