// @flow
import React, { type Element } from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Icon from 'antd/lib/icon';

import { ListTable } from '../../../components/ui';
import type {
  OperationLimitGroup,
  OperationLimitType,
  UserAccess
} from '../../../lib/types';
import OperationLimitGroupRow from './components/OperationLimitGroupRow';
import {
  accessTypeEnum,
  operationLimitGroupStatusEnum,
  operationLimitTypeEnum
} from '../../../lib/enum';
import {
  AddButton,
  Buttons,
  ListTableHeader,
  ListTableOvbHeader
} from './components/elements';
import { operationLimitGroupApi } from '../../../lib/api';
import Spinner from '../../../components/Spinner';
import GroupModal from './components/GroupModal';
import { Row, Cell } from '../elements';
import { notificationLoading } from './../../../components/Notifications';
import type { OperationLimitGroupStatus } from '../../../lib/types/operationLimitGroup';
import { getTimeLimitStatus } from '../lib';
import type { AppState } from '../../../ducks/redux';
import Popover from '../../../components/ui/Popover';

type Props = {
  type: OperationLimitType,
  userAccess: UserAccess[],
  orgUnitId: number
};

type State = {
  groups: OperationLimitGroup[],
  newGroup: $Shape<OperationLimitGroup>,
  modalVisible: boolean,
  loading: boolean
};

class OperationLimitGroups extends React.Component<Props, State> {
  state = {
    groups: [],
    newGroup: {
      type: this.props.type,
      orgUnitId: this.props.orgUnitId
    },
    modalVisible: false,
    loading: false
  };

  async componentDidMount() {
    await this.fetchGroups();
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.orgUnitId !== prevProps.orgUnitId) {
      await this.fetchGroups();
      this.setState(({ newGroup }) => ({
        newGroup: {
          ...newGroup,
          orgUnitId: this.props.orgUnitId
        }
      }));
    }
  }

  handleSaveOnlyGroup = async (group: OperationLimitGroup) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (group.id) {
        await operationLimitGroupApi.updateOperationLimitGroup(group);
      } else {
        await operationLimitGroupApi.addOperationLimitGroup(group);
      }
      this.setState({ modalVisible: false });
      await this.fetchGroups();
      await this.changeGroupsStatus(
        operationLimitGroupStatusEnum.draft,
        operationLimitGroupStatusEnum.draft
      );
      notification.success({
        message: 'Группа была успешно сохранена.'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  changeGroupsStatus = async (
    status: OperationLimitGroupStatus,
    assignmentLimitStatus?: OperationLimitGroupStatus
  ) => {
    const { groups } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const updatedGroups = groups.map<OperationLimitGroup>(group => ({
        ...group,
        timeLimitStatus: status,
        assignmentLimitStatus:
          assignmentLimitStatus || group.assignmentLimitStatus
      }));
      await Promise.all(
        updatedGroups.map(
          async group =>
            await operationLimitGroupApi.updateOperationLimitGroup(group)
        )
      );
      this.setState({ groups: updatedGroups });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  getTypeForNotification = () => {
    switch (this.props.type) {
      case 'employee':
        return 'должностных групп';
      case 'orgUnit':
        return 'служб';
      case 'ovb':
      default:
        return 'ОВБ';
    }
  };

  handleApprove = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.approved);
    notification.success({
      message: `Регламент ${this.getTypeForNotification()} был утвержден`
    });
  };

  handleSendToAgreeing = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.onAgreeing);
    notification.success({
      message: `Регламент ${this.getTypeForNotification()} был отправлен на согласование`
    });
  };

  toggleModalVisible = () =>
    this.setState((prevState: State) => ({
      modalVisible: !prevState.modalVisible
    }));

  fetchGroups = async () => {
    try {
      const { type, orgUnitId } = this.props;
      this.setState({ loading: true });
      const {
        data: groups
      } = await operationLimitGroupApi.fetchOperationLimitGroups({
        type,
        orgUnitId,
        pageSize: 0,
        includeOperationLimit: true
      });
      this.setState({ groups, loading: false });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  setGroup = (index: number, group: OperationLimitGroup) => {
    const groups = [...this.state.groups];
    groups.splice(index, 1, group);
    this.setState({ groups });
  };

  handleDeleteGroup = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await operationLimitGroupApi.deleteOperationLimitGroup(id);
      await this.fetchGroups();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  /**
   * Проверка на возможность
   * утверждения регламента пользователем
   */
  canApprove = () => {
    const status = getTimeLimitStatus(this.state.groups, 'timeLimitStatus');
    return (
      status === operationLimitGroupStatusEnum.onAgreeing &&
      this.props.userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.approvingTimeLimits
        ].includes(access)
      )
    );
  };

  /**
   * Проверка на возможность
   * создания/редактирования регламента пользователем
   */
  canHandling = () => {
    return this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingTimeLimits
      ].includes(access)
    );
  };

  /**
   * Проверка на возможность отправки
   * на согласование регламента пользователем
   */
  canSendToAgreeing = () => {
    const status = getTimeLimitStatus(this.state.groups, 'timeLimitStatus');
    return this.canHandling() && status === operationLimitGroupStatusEnum.draft;
  };

  /**
   * Проверка на наличие незаполненных полей в лимитах групп
   */
  hasEmptyGroups = () => {
    const { groups } = this.state;
    return groups.some(group => {
      return isEmpty(group.operationLimits);
    });
  };

  wrapButtonWithWarning = (button: Element<any>) => {
    const hasGroupsEmptyFields = this.hasEmptyGroups();
    return hasGroupsEmptyFields ? (
      <Popover
        title="Внимание!"
        width={160}
        content="Имеются пустые группы лимитов"
      >
        {React.cloneElement(button, {
          disabled: true,
          children: (
            <>
              <Icon type="warning" style={{ marginRight: 8 }} />
              {button.props.children}
            </>
          )
        })}
      </Popover>
    ) : (
      button
    );
  };

  render() {
    const { type, orgUnitId } = this.props;
    const { loading, groups, modalVisible, newGroup } = this.state;
    const canApprove = this.canApprove();
    const canHandling = this.canHandling();
    const canSendToAgreeing = this.canSendToAgreeing();

    return (
      <Spinner isLoading={loading}>
        <ListTable
          data={groups}
          renderRow={(group: OperationLimitGroup, index: number) => {
            return (
              <OperationLimitGroupRow
                group={group}
                setGroup={this.setGroup}
                type={type}
                key={group.id}
                index={index}
                editable={canHandling}
                saveGroup={this.handleSaveOnlyGroup}
                orgUnitId={orgUnitId}
                onDelete={this.handleDeleteGroup}
              />
            );
          }}
          header={
            type === operationLimitTypeEnum.ovb ? (
              <ListTableOvbHeader />
            ) : (
              <ListTableHeader type={type} />
            )
          }
        >
          {({ content }) => (
            <>
              {content}
              {(canApprove || canHandling || canSendToAgreeing) && (
                <Row
                  style={{
                    borderTop: '1px solid #E4EBF2'
                  }}
                >
                  <Cell>
                    {canHandling && (
                      <AddButton onClick={this.toggleModalVisible}>
                        Добавить новую группу
                      </AddButton>
                    )}
                  </Cell>
                  <Cell>
                    <Buttons>
                      {canSendToAgreeing &&
                        this.wrapButtonWithWarning(
                          <Button
                            size="small"
                            onClick={this.handleSendToAgreeing}
                            type="primary"
                          >
                            На утверждение
                          </Button>
                        )}
                      {canApprove &&
                        this.wrapButtonWithWarning(
                          <Button
                            size="small"
                            type="primary"
                            onClick={this.handleApprove}
                          >
                            Утвердить
                          </Button>
                        )}
                    </Buttons>
                  </Cell>
                </Row>
              )}
            </>
          )}
        </ListTable>
        <GroupModal
          group={newGroup}
          afterClose={() => {
            this.setState({
              newGroup: {
                type,
                orgUnitId
              }
            });
          }}
          toggleModal={this.toggleModalVisible}
          visible={modalVisible}
          saveGroup={this.handleSaveOnlyGroup}
        />
      </Spinner>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
  userAccess: state.auth.profile.access
}))(OperationLimitGroups);
