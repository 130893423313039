import React, {useState} from 'react';

import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';

import {getValueObject} from '../../../../../lib/helpers';
import {
  vehicleStatusEnum,
  ownerTypes,
  STSEnum,
} from '../../../../../lib/enum';

import {Selects} from './../../../../../components';
import {Grid} from './../../../../../components/layout';
import {GridItem} from '../../../../../components/layout';

const {VehicleSelect} = Selects;

type Props = {
  visible: boolean,
  orgUnitId: number,
  onCancel: Function,
  addVehicle: Function,
  isListEditing?: boolean,
};
export default (props: Props) => {
  const {visible, onCancel, orgUnitId, addVehicle, isListEditing} = props;
  const [vehicle, setVehicle] = useState(undefined);
  return (
    <Modal
      destroyOnClose
      width={800}
      title="Добавление нового ТС"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Grid gutter="16px">
        <GridItem>
          <VehicleSelect
            value={vehicle?.id}
            filter={{
              excludeBranchId: isListEditing ? undefined : orgUnitId,
              ownerType: ownerTypes.self,
              stsType: STSEnum.exploited,
              status: [
                vehicleStatusEnum.draft,
                vehicleStatusEnum.onAgreeing,
                vehicleStatusEnum.working,
                vehicleStatusEnum.onAgreeing,
              ],
            }}
            onChange={(contractVehicle, option) => {
              const vehicle = getValueObject(option, 'props.vehicle');
              setVehicle(vehicle);
            }}
          />
        </GridItem>
        <GridItem>
          <Button
            type="primary"
            disabled={!vehicle}
            onClick={() => {
              addVehicle(vehicle);
              setVehicle(undefined);
            }}
          >
            Добавить
          </Button>
        </GridItem>
      </Grid>
    </Modal>
  );
};
