// @flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import { Card, Spinner } from './../../../../components';
import type { OptionalEquipment } from './../../../../lib/types';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { Icon } from '../../../../components/ui';
import Select from './../../../../components/ui/Select';
import InstallEquipmentButton from './../InstallEquipmentButton';

const { Field } = Card;
const { Option } = Select;

const Wrapper = styled.div`
  padding: 16px;
`;
const AddButton = styled.span`
  cursor: pointer;
  color: #2770ff;
  display: flex;
  & .icon {
    margin-right: 5px;
  }
`;

type Props = {
  onSubmit: (optionalEquipment: OptionalEquipment) => void,
  availableEquipments: OptionalEquipment[],
  handleSearch: (serachString: string) => void,
  fetchData: () => void,
  isLoading: boolean
};

type State = {
  currentOptionalEquipmentId?: ?number,
  visibleForm: boolean
};

class OptionalEquipmentForm extends Component<Props, State> {
  static defaultProps = {
    availableEquipments: []
  };
  state = {
    currentOptionalEquipmentId: null,
    visibleForm: false
  };

  onOptionalEquipmentSelect = (currentOptionalEquipmentId: number) => {
    this.setState({
      currentOptionalEquipmentId
    });
  };

  onAddClick = async (installDate: Date) => {
    const { currentOptionalEquipmentId } = this.state;
    const { availableEquipments } = this.props;
    const optionalEquipment = availableEquipments.find(
      item => item.id === currentOptionalEquipmentId
    );
    if (optionalEquipment) {
      await this.props.onSubmit({ ...optionalEquipment, installDate });
      this.setState({
        currentOptionalEquipmentId: null,
        visibleForm: false
      });
    } else {
      notification.error({
        message: 'Необходимо выбрать доп. оборудование из списка'
      });
    }
  };

  handleAddButtonClick = () => this.setState({ visibleForm: true });

  render() {
    const { currentOptionalEquipmentId, visibleForm } = this.state;
    const {
      availableEquipments,
      handleSearch,
      fetchData,
      isLoading
    } = this.props;
    return (
      <Wrapper>
        {visibleForm ? (
          <Grid gutter="16px">
            <GridItem>
              <Field label="Оборудование">
                <Select
                  placeholder="Выберите оборудование"
                  value={currentOptionalEquipmentId}
                  onChange={this.onOptionalEquipmentSelect}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onBlur={() => fetchData()}
                  notFoundContent={
                    <Spinner
                      isLoading={isLoading}
                      notFoundText="Оборудование не найдено"
                    />
                  }
                >
                  {availableEquipments.map((equipment: OptionalEquipment) => (
                    <Option key={equipment.id} value={equipment.id}>{`${
                      equipment.name
                    } ${equipment.brandName}`}</Option>
                  ))}
                </Select>
              </Field>
            </GridItem>
            <GridItem fullWidth>
              <InstallEquipmentButton
                onConfirm={this.onAddClick}
                disabled={!currentOptionalEquipmentId}
              />
            </GridItem>
          </Grid>
        ) : (
          <AddButton onClick={this.handleAddButtonClick}>
            <Icon type="plus" />
            Добавить доп. оборудование
          </AddButton>
        )}
      </Wrapper>
    );
  }
}

export default OptionalEquipmentForm;
