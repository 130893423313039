// @flow

import React from 'react';
import {SelectProps} from 'antd/lib/select';
import {VehicleSubcategoriesApi} from '../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = {
  filter?: any,
}

// Выпадающий список подкатегорий ТС
export default ({filter, ...props}: SelectProps & Props) => (
  <AutocompleteSelect
    value={props.value}
    fetch={() => VehicleSubcategoriesApi.fetch({pageSize: undefined, ...filter})}
    fetchSingle={VehicleSubcategoriesApi.get}
    refetchParams={{filter}}
    placeholder="Выберите подкатегорию"
    notFoundText="Подкатегория не найдена"
    onChange={props.onChange}
    renderOption={(item, Option) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    )}
    {...props}
  />
);
