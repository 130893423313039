// @flow

import React, { useState } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import DatePicker from 'antd/lib/date-picker';
import notification from 'antd/lib/notification';

import { washingStatusEnum } from './../../../lib/enum';
import moment from 'moment';
import { formatDateTimeToISOString } from './../../../lib/helpers';
import washingApi from './../../../lib/api/washing';
import type { Washing } from './../../../lib/types';
import { notificationLoading } from './../../../components/Notifications';
import Selects from '../../../components/selects';

const { DriverSelect } = Selects;

type Props = {
  washing: Washing,
  defaultDriverId: ?number,
  onServerUpdate: (washing: Washing) => void
};

const updateWashingInfo = async (washing, data): Promise<?Washing> => {
  try {
    notificationLoading({
      message: 'Обновление данных',
      key: 'saving'
    });
    return await washingApi.updateWashing({
      ...washing,
      ...data,
      state: washingStatusEnum.done
    });
  } catch (err) {
    notification.error({
      message: 'Ошибка',
      description: err.message
    });
  } finally {
    notification.close('saving');
  }
};

export default (props: Props) => {
  const [factDate, setWashingDate] = useState(null);
  const [driverId, setDriver] = useState(props.defaultDriverId);
  return (
    <Popconfirm
      overlayStyle={{
        zIndex: 2000
      }}
      placement="left"
      title={
        <>
          <p>Укажите дату мойки</p>
          <DatePicker
            popupStyle={{
              zIndex: 2001
            }}
            format="DD.MM.YYYY"
            disabledDate={(currentDate: moment) =>
              moment.utc(currentDate).month() !==
              moment.utc(props.washing.date).month()
            }
            onChange={(value: Object, dateString: string) => {
              const dateTime = formatDateTimeToISOString(
                value,
                dateString,
                'DD.MM.YYYY'
              );
              setWashingDate(dateTime);
            }}
          />
          <p>Укажите водителя</p>
          <DriverSelect
            value={driverId}
            dropdownStyle={{ zIndex: '2001' }}
            onChange={(id: number) => {
              setDriver(id);
            }}
          />
        </>
      }
      onConfirm={async () => {
        if (factDate === null) {
          return;
        }
        const updated = await updateWashingInfo(props.washing, {
          factDate,
          driverId
        });
        if (updated) {
          props.onServerUpdate(updated);
        }
      }}
    >
      Факт от водителя
    </Popconfirm>
  );
};
