// @flow
import React from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import AntIcon from 'antd/lib/icon';
import Popover from 'antd/lib/popover';
import { TableHeader } from '../../../../components/ui/Table';

import type {
  VehicleTaxPlanVehicle,
  VehicleModel,
  VehicleType
} from '../../../../lib/types';
import { vehicleTypes } from '../../../../lib/enum';
import { toLocalStringRu, formatDate, plus } from '../../../../lib/helpers';

import { Table } from '../../../../components/ui';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Model = styled.div`
  width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const LicensePlate = styled.div`
  flex: 1;
`;
const IconWarn = styled(AntIcon)`
  font-size: 13px;
  position: relative;
  margin-left: 10px;
  top: 4px;
`;

type Props = {
  data: VehicleTaxPlanVehicle[],
  loading: boolean
};
export default (props: Props) => {
  const { data, loading } = props;
  const columns = [
    {
      title: 'Марка и модель',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.vehicleModel',
      width: 300,
      key: 'vehicleModel',
      render: (vehicleModel: VehicleModel) => {
        return vehicleModel ? (
          <Model>{`${vehicleModel.brandName} ${vehicleModel.name}`}</Model>
        ) : (
          'Итого: '
        );
      }
    },
    {
      title: 'Гос. номер',
      width: 200,
      key: 'licensePlate',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.licensePlate',
      render: (licensePlate: string, record: any) => {
        return (
          licensePlate && (
            <Container>
              <LicensePlate>
                <Link
                  to={`/vehicles/${record.vehicleTaxPlanVehicle.vehicleId}`}
                >
                  {licensePlate}
                </Link>
              </LicensePlate>
              {record?.plannedWriteoffDate && (
                <Popover
                  content={
                    <>
                      Планируемая дата списания:{' '}
                      {formatDate(
                        record.vehicleTaxPlanVehicle.plannedWriteoffDate
                      )}
                    </>
                  }
                  placement="right"
                >
                  <IconWarn
                    type="exclamation-circle"
                    theme="twoTone"
                    twoToneColor="#faad14"
                  />
                </Popover>
              )}
            </Container>
          )
        );
      }
    },
    {
      title: 'Тип ТС',
      width: 300,
      key: 'type',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.vehicleModel.type',
      render: (type: VehicleType) => type && vehicleTypes[type]
    },
    {
      title: 'Год выпуска',
      width: 110,
      key: 'yearIssued',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.yearIssued'
    },
    {
      title: (
        <TableHeader width="120px">Планируемая дата приобретения</TableHeader>
      ),
      key: 'commissioningDate',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.plannedPurchaseDate',
      render: date => formatDate(date)
    },
    {
      title: <TableHeader width="120px">Планируемая дата списания</TableHeader>,
      key: 'plannedWriteoffDate',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.plannedWriteoffDate',
      render: date => formatDate(date)
    },
    {
      title: 'Налоговая ставка',
      dataIndex: 'taxRate',
      render: tax => toLocalStringRu(tax)
    },
    {
      title: 'Мощность ТС',
      dataIndex: 'vehicleTaxPlanVehicle.vehicle.vehicleModel.enginePower',
      render: enginePower => toLocalStringRu(enginePower)
    },
    {
      title: (
        <TableHeader width="120px">Коэффициент периода владения</TableHeader>
      ),
      dataIndex: 'ownershipPeriodRatio',
      render: ownershipPeriodRatio => toLocalStringRu(ownershipPeriodRatio)
    },
    {
      title: 'Сумма налога',
      dataIndex: 'tax',
      width: 120,
      render: tax => toLocalStringRu(tax)
    }
  ];

  let sumTax = 0;
  data.forEach(({ tax }: VehicleTaxPlanVehicle) => {
    sumTax = plus(sumTax, tax);
  });

  return (
    <Table
      rowKey="id"
      loading={loading}
      data={data}
      columns={columns}
      footer={() => <>Итого: {toLocalStringRu(sumTax)}</>}
      pagination={false}
      scroll={{
        x: 2000,
        y: 'calc(100vh - 280px)'
      }}
    />
  );
};
