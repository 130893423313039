import type {
  WashingPlanVehicleSummary,
  WashingPlanVehicle
} from './../../../lib/type';
import { multipliedBy, plus } from './../../../lib/helpers';

const reducer = (
  accumulator: WashingPlanVehicleSummary,
  currentValue: WashingPlanVehicleSummary
) => ({
  bodyCount: plus(accumulator.bodyCount, currentValue.bodyCount || 0),
  bodyPrice: plus(accumulator.bodyPrice, currentValue.bodyPrice || 0),
  interiorCount: plus(
    accumulator.interiorCount,
    currentValue.interiorCount || 0
  ),
  interiorPrice: plus(
    accumulator.interiorPrice,
    currentValue.interiorPrice || 0
  ),
  engineCount: plus(accumulator.engineCount, currentValue.engineCount || 0),
  enginePrice: plus(accumulator.enginePrice, currentValue.enginePrice || 0),
  totalPrice: plus(
    accumulator.totalPrice,
    plus(
      parseFloat(
        multipliedBy(currentValue.bodyCount, currentValue.bodyPrice)
      ) || 0,
      plus(
        parseFloat(
          multipliedBy(currentValue.interiorCount, currentValue.interiorPrice)
        ) || 0,
        parseFloat(
          multipliedBy(currentValue.engineCount, currentValue.enginePrice)
        ) || 0
      )
    )
  )
});

export const calculateSummaryPlan = (
  washingPlaneVehicles: WashingPlanVehicle[]
): WashingPlanVehicleSummary => {
  const data = [
    {
      bodyCount: 0,
      bodyPrice: 0,
      interiorCount: 0,
      interiorPrice: 0,
      engineCount: 0,
      enginePrice: 0,
      totalPrice: 0
    },
    ...washingPlaneVehicles
  ];
  const washingPlanVehicleSummary = data.map(
    ({
      bodyCount,
      bodyPrice,
      interiorCount,
      interiorPrice,
      engineCount,
      enginePrice
    }: WashingPlanVehicle) =>
      ({
        bodyCount,
        bodyPrice,
        interiorCount,
        interiorPrice,
        engineCount,
        enginePrice,
        totalPrice: 0
      }: WashingPlanVehicleSummary)
  );

  return washingPlanVehicleSummary.reduce(reducer);
};
