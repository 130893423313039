// @flow
import React from 'react';
import styled from 'styled-components';

import {Card, Filter, FilterButtonsContainer, FilterContainer, FilterItem} from './../../../components';
import OrgUnitSelect from './../../../components/OrgUnitSelect';
import type {FilterChildrenParams} from './../../../components/hoc/Filter';
import type {JobTitlesFilter} from './../../../ducks/jobTitles';

const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: JobTitlesFilter
};

const JobTitleFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Content>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<JobTitlesFilter>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onlyBranches
                  onChange={value => changeValue('nodeId', value)}
                  value={values['nodeId']}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default JobTitleFilter;
