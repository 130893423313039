// @flow
import * as React from 'react';
import Modal from 'antd/lib/modal';
import type {ComponentType} from 'react';
import type {FormFieldProps} from '../../../../../components/Form';
import {FormikProps} from 'formik';
import {Form} from '../../../../../components';
import Grid, {GridItem} from '../../../../../components/layout/Grid';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import styled from 'styled-components';
import {Fragment} from 'react';

const OperationName = styled.h2`
  margin: 0 0 15px;
  color: #444;
`;

type Props = {
  initialValues: any,
  visible: boolean,
  onCancel: Function,
  onSubmit: Function,
};

export class EditingModal extends React.Component<Props> {
  render() {
    const {initialValues, visible, onCancel, onSubmit} = this.props;
    return (
      <Modal
        visible={visible}
        footer={null}
        width={600}
        title='Редактирование записи'
        onCancel={onCancel}
      >
        <Form onSubmit={onSubmit} initialValues={initialValues}>
          {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
            const {handleSubmit, setFieldValue, values} = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Grid gutter='16px'>
                  <GridItem>
                    <FormField name='documentBase' label='Основание'>
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name='equipmentType' label='Вид оборудования'>
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  {values.operations.map((operation, index) => (
                    <Fragment key={index}>
                      <GridItem fullWidth>
                        <OperationName>{operation.name}</OperationName>
                      </GridItem>
                      <GridItem>
                        <FormField name={`operations[${index}].isEquivalent`} label='Возможность применения эквивалента'>
                          {({name, value}) => <Checkbox checked={value} onChange={({target}) => setFieldValue(name, target.checked)} />}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField name={`operations[${index}].isSpecialCondition`} label='Требуется особое условие'>
                          {({name, value}) => <Checkbox checked={value} onChange={({target}) => setFieldValue(name, target.checked)} />}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField name={`operations[${index}].conditionType`} label='Вид особых условий'>
                          {field => <Input {...field} />}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField name={`operations[${index}].conditionName`} label='Наименование'>
                          {field => <Input {...field} />}
                        </FormField>
                      </GridItem>
                    </Fragment>
                  ))}
                  <GridItem fullWidth>
                    <>
                      <Button type='primary' style={{marginRight: '10px'}} htmlType='submit'>Сохранить</Button>
                      <Button onClick={onCancel}>Отменить</Button>
                    </>
                  </GridItem>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal>
    );
  };
};