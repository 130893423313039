// @flow

import type {Dispatch} from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type {FuelMeasurement, Vehicle} from './../lib/types';
import {
  attachedEquipmentApi,
  batteryApi,
  fuelCardApi,
  fuelMeasurementApi,
  fuelMultiplierApi,
  locationApi,
  optionalEquipmentApi,
  osagoApi,
  tireApi,
  vehicleApi,
} from './../lib/api';
import {vehicleStatusEnum} from './../lib/enum';
import type {Action, AppState} from './redux';

export const FETCH_VEHICLE = 'vehicles/vehicle/fetch';
export const UPDATE_VEHICLE = 'vehicles/vehicles/update';
export const CLEAN_VEHICLE = 'vehicles/vehicle/clean';

export type VehicleState = ?Vehicle;

const initialState: VehicleState = null;

const reducer = (
  state: VehicleState = initialState,
  { type, payload }: Action
): VehicleState => {
  switch (type) {
    case FETCH_VEHICLE:
    case UPDATE_VEHICLE:
      return { ...payload };
    case CLEAN_VEHICLE:
      return null;
    default:
      return state;
  }
};

export const addVehicle = (vehicle: Vehicle) => async (
  dispatch: Dispatch
): Promise<void> => {
  const added = await vehicleApi.addVehicle(vehicle);
  await osagoApi.addOsago({
    ...vehicle.osago,
    vehicleId: added.id
  });
  dispatch({
    type: UPDATE_VEHICLE,
    payload: added
  });
};

export const updateVehicle: Function = (vehicle: Vehicle, showMsg = false): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updated = await vehicleApi.updateVehicle(vehicle);
  if (!isEqual(vehicle.osago, updated.osago)) {
    await osagoApi.updateOsago(vehicle.osago);
  }
  //$FlowFixMe
  const { status: prevStatus } = getState().vehicle;
  /**
   * Сбрасываем статус на Черновик, только если не поменялся статус,
   * а другие поля поменялись
   * TODO: Продумать логику с тем, что если мы поменяем и статус и какие-то поля
   * иначе это вводит в конфуз
   */
  if (updated && updated.status === prevStatus) {
    dispatch(resetVehicleStatus(updated.id));
  } else {
    if (showMsg) {
      notification.success({
        message: 'Статус успешно обновлен',
        description: updated.status === vehicleStatusEnum.sendingToParus
          ? 'Запущена отправка карточки ТС в ИСУ «Парус». Передача данных займёт до 15-ти минут.'
          : undefined,
      });
    }
    dispatch(fetchVehicle(vehicle.id));
  }
};

// Загрузить ТС по id
export const fetchVehicle: Function = (id: number): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const vehicle: Vehicle = await vehicleApi.fetchVehicle(id);
  if (vehicle) {
    // местоположение
    if (parseInt(vehicle.locationId) > 0) {
      vehicle.location = await locationApi.fetchLocation(vehicle.locationId);
    }
    vehicle.fuelMeasurements = await fetchVehicleFuelMeasurements(vehicle.id);
    const oldVehicle = cloneDeep(getState().vehicle);
    /**
     * Чтобы не стирать уже загруженные данные
     * (чтобы страничка с оборудованием, например, не моргала при контрольном замере)
     */
    if (!isEmpty(oldVehicle)) {
      vehicle.equipments = oldVehicle.equipments;
      vehicle.fuelCard = oldVehicle.fuelCard;
      vehicle.fuelMultipliers = oldVehicle.fuelMultipliers;
      vehicle.fuelsAndOils = oldVehicle.fuelsAndOils;
    }

    dispatch({
      type: FETCH_VEHICLE,
      payload: vehicle
    });
    dispatch(setVehicleBatteries(id));
    dispatch(setVehicleTires(id));
    dispatch(setVehicleOptionalEquipments(id));
    dispatch(setVehicleAttachedEquipments(id));
    dispatch(setVehicleFuelCard(id));
    dispatch(setVehicleFuelMultipliers(id));
  } else {
    return Promise.reject(new Error(`ТС с идентификатором ${id} не найдено`));
  }
};

// Очищаем текущее ТС
export const cleanVehicle: Function = (): Function => (dispatch: Dispatch) => {
  dispatch({
    type: CLEAN_VEHICLE
  });
};

export const setVehicleFromQuery: Function = (query): Function => (dispatch: Dispatch) => {

  const params = new URLSearchParams(query);
  let otherDocumentSerialNumber = params.get('invoice_number');
  let otherDocumentKind = params.get('invoice_prefix');
  let otherDocumentIssuedDate = params.get('invoice_date');

  dispatch({
    type: UPDATE_VEHICLE,
    payload: { otherDocumentSerialNumber, otherDocumentKind, otherDocumentIssuedDate }
  });
};

export const setVehicleBatteries: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const batteries = await batteryApi.fetchVehicleBatteries(vehicleId);
  if (batteries) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      /**
       * Может быть так, что vehicle.equipments === null
       * Метод set автоматически создаст объект equipments, если него нет
       */
      set(vehicle, 'equipments.batteries', batteries);
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

export const setVehicleTires: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const tires = await tireApi.fetchVehicleTires(vehicleId);
  if (tires) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      /**
       * Может быть так, что vehicle.equipments === null
       * Метод set автоматически создаст объект equipments, если него нет
       */
      set(vehicle, 'equipments.tires', tires);
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

const fetchVehicleFuelMeasurements = async (
  vehicleId: number
): Promise<Array<FuelMeasurement>> => {
  try {
    return await fuelMeasurementApi.fetchVehicleFuelMeasurements(vehicleId);
  } catch (err) {
    notification.error({
      message: 'Ошибка',
      description: err.message
    });
    return [];
  }
};

export const setVehicleOptionalEquipments: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const optionalEquipment = await optionalEquipmentApi.fetchVehicleOptionalEquipments(
    vehicleId
  );
  if (optionalEquipment) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      /**
       * Может быть так, что vehicle.equipments === null
       * Метод set автоматически создаст объект equipments, если него нет
       */
      set(vehicle, 'equipments.optional', optionalEquipment);
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

export const setVehicleAttachedEquipments: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const attachedEquipment = await attachedEquipmentApi.fetchVehicleAttachedEquipments(
    vehicleId
  );
  if (attachedEquipment) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      /**
       * Может быть так, что vehicle.equipments === null
       * Метод set автоматически создаст объект equipments, если него нет
       */
      set(vehicle, 'equipments.attached', attachedEquipment);
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

export const setVehicleFuelCard: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const fuelCards = await fuelCardApi.fetchVehicleFuelCards(vehicleId);
  if (fuelCards) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      vehicle.fuelCard = fuelCards[0];
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

export const resetVehicleStatus = (vehicleId: ?number) => async (
  dispatch: Dispatch,
  getState: () => AppState
) => {
  try {
    const parsedVehicleId = parseInt(vehicleId, 10);
    if (parsedVehicleId > 0) {
      const vehicle = await vehicleApi.fetchVehicle(parsedVehicleId);
      if (vehicle.status !== vehicleStatusEnum.draft) {
        await vehicleApi.updateVehicle({
          ...vehicle,
          status: vehicleStatusEnum.draft
        });
        const clonedVehicle = cloneDeep(getState().vehicle);
        if (vehicle && vehicle.id === vehicleId) {
          dispatch({
            type: FETCH_VEHICLE,
            payload: {
              ...clonedVehicle,
              status: vehicleStatusEnum.draft
            }
          });
        }
      }
    }
  } catch (err) {
    notification.error({
      message: 'Произошла ошибка при смене статуса ТС',
      description: err.message
    });
  }
};

export const setVehicleFuelMultipliers: Function = (
  vehicleId: number
): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const fuelMultipliers = await fuelMultiplierApi.fetchVehicleFuelMultipliers(
    vehicleId
  );
  if (fuelMultipliers) {
    const vehicle = cloneDeep(getState().vehicle);
    if (vehicle && vehicle.id === vehicleId) {
      vehicle.fuelMultipliers = fuelMultipliers;
      dispatch({
        type: FETCH_VEHICLE,
        payload: vehicle
      });
    }
  }
};

export default reducer;
