// @flow
import React, { useState, useCallback } from 'react';

import { vehicleTaxMapApi } from '../../lib/api';
import type { UserAccess, VehicleTaxMap } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { withUserAccess } from './../withUserAccess';

import { addAccessRight } from './accessRight';
import { vehicleTypes } from '../../lib/enum';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';
import { navigate } from '../../lib/helpers';

const { Field } = Card;

type InnerProps = {
  data: VehicleTaxMap
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/vehicle-tax-map/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Наименование объекта налогообложения">
            {props.data.name}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Тип ТС">{vehicleTypes[props.data.vehicleType]}</Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: VehicleTaxMap, setData] = useState({
    id: props.id,
    name: '',
    isAvailableForSelect: false
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await vehicleTaxMapApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await vehicleTaxMapApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Соответствие объекта налогообложения и категории ТС',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
