import React, {Component} from 'react';

import styled from 'styled-components';
import moment from 'moment';

import {Button, InputNumber} from 'antd';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';

import {
  disabledDateMin,
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  navigate,
} from '../../../lib/helpers';
import {osagoMultiplier} from '../../../lib/api';
import type {OsagoBranchMultiplier} from './../../../lib/types';

import {Form, OrgUnitSelect, Selects} from '../../../components';
import Grid, {GridItem} from '../../../components/layout/Grid';
import {Footer, Header, Panel, Section} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { OsagoTransportTypeSelect } = Selects;

type Props = {
  osagoBranchMultiplierId: ?string
};
type State = {
  osagoBranchMultiplier: ?OsagoBranchMultiplier
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

export default class OsagoBranchMultiplierForm extends Component<Props, State> {
  state = {
    osagoBranchMultiplier: null
  };

  async componentDidMount() {
    let osagoBranchMultiplierId = parseInt(
      this.props.osagoBranchMultiplierId,
      10
    );
    if (osagoBranchMultiplierId) {
      try {
        const Multiplier = await osagoMultiplier.getOsagoBranchMultiplier(
          osagoBranchMultiplierId
        );
        this.setState({ osagoBranchMultiplier: Multiplier });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    } else {
      let osagoBranchMultiplier = {};
      osagoBranchMultiplier.startDate = getValueObject(
        this.props,
        'location.state.startDate'
      );
      osagoBranchMultiplier.osagoType = getValueObject(
        this.props,
        'location.state.osagoType'
      );
      osagoBranchMultiplier.orgUnitId = getValueObject(
        this.props,
        'location.state.orgUnitId'
      );
      this.setState({ osagoBranchMultiplier });
    }
  }

  onSubmit = async (values: OsagoBranchMultiplier) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.osagoBranchMultiplierId) {
        await osagoMultiplier.updateOsagoBranchMultiplier(values);
      } else {
        await osagoMultiplier.addOsagoBranchMultiplier(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/osago-multiplier/branch', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => navigate('/admin/osago-multiplier/branch', true);

  render() {
    const { osagoBranchMultiplier } = this.state;
    const { osagoBranchMultiplierId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/osago-multiplier/branch')}
              >
                Коэффициенты ОСАГО для Филиалов
              </Crumb>
              <Crumb to={`/admin/osago-multiplier/branch/new`}>
                {osagoBranchMultiplierId ? 'Редактируем' : 'Новый'} коэффициент
                ОСАГО для Филиала
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoBranchMultiplierId
              ? `Редактируем запись №${osagoBranchMultiplierId}`
              : `Новая запись`}
          </h1>
        </StyledPanel>
        <Form initialValues={osagoBranchMultiplier} onSubmit={this.onSubmit}>
          {(FormField: FormFieldType, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, dirty, values } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <SectionContent>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          fast
                          label="Филиалы"
                          required
                          name="orgUnitId"
                        >
                          {({ value, name }) => (
                            <OrgUnitSelect
                              filter={{ onlyBranch: true }}
                              value={value}
                              onChange={value => setFieldValue(name, value)}
                              data-cy="orgUnitSelect"
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="КТ"
                          name="kt"
                          required
                          hasFeedback={false}
                        >
                          {({ value, name }) => (
                            <InputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Тип ТС"
                          name="osagoType"
                          required
                          hasFeedback={false}
                        >
                          {({ value, name }) => (
                            <OsagoTransportTypeSelect
                              value={value}
                              name={name}
                              onSelect={(value: string) => {
                                setFieldValue(name, value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          fast
                          label="Дата начала"
                          name="startDate"
                          required
                        >
                          {({ value, name }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                                setFieldValue('endDate', undefined);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Дата окончания" name="endDate">
                          {({ value, name }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              disabled={values.startDate ? false : true}
                              disabledDate={(currentDate: moment) =>
                                disabledDateMin(
                                  currentDate,
                                  moment(values.startDate)
                                )
                              }
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </Section>
                <Footer>
                  <Button type="primary" htmlType="submit" data-cy="save">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.onCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
