// @flow

import React, {Component} from 'react';
import notification from 'antd/lib/notification';

import type {Location as LocationType} from './../../../lib/types';
import {accessTypeEnum} from './../../../lib/enum';
import {Table} from './../../../components/ui';
import {Section} from './../../../components/layout';
import Filter, {type LocationFilterParams} from './Filter';
import {withUserAccess} from './../../withUserAccess';
import {getListInitialState, setQueryParams} from './../../../lib/helpers';
import {type FetchListParams, locationApi} from './../../../lib/api';
import {notificationLoading} from './../../../components/Notifications';
import {Profile} from '../../../ducks/auth';
import { navigate } from '@reach/router';

export const canHandleLocationAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingLocation
];

type Props = {
  userAccess: UserAccess[],
  profile: Profile,
  location: Location & { state: { page: number } }
};

class LocationList extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filter: {},
    selectedRowKeys: []
  };

  fetchLocations = async (
    page: number = 1,
    params: FetchListParams<LocationFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await locationApi.fetchLocations({
        ...filter,
        ...{source: "toir"},
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  async deleteLocation(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      const { page, pageSize } = this.state;
      await locationApi.deleteLocation(id);
      await this.fetchLocations(page, { pageSize });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  canHandle = () =>
    this.props.userAccess.some(access =>
      canHandleLocationAccess.includes(access)
    );

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 25
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      sorter: true
    },
    {
      title: 'Является стоянкой',
      dataIndex: 'isDefault',
      sorter: true,
      render: (text: string, record: LocationType) =>
        record.isDefault ? 'Да' : 'Нет'
    },
  ];

  cleanFilter = () => this.setState({ filter: {} }, this.fetchLocations);

  applyFilter = (filter: LocationFilterParams) =>
    this.setState({ filter }, this.fetchLocations);

  handleRowClick = (id: number) => navigate(`/admin/locations/form/view/${id}`);

  render() {
    const { location } = this.props;
    const { data, totalCount, page, pageSize, loading, filter } = this.state;
    return (
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            loading={loading}
            rowKey="id"
            onRow={
              record => ({
                onClick: () => this.handleRowClick(record.id)
              })
            }
            fetch={this.fetchLocations}
            fetchOnMount
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
    );
  }
}

export default withUserAccess(LocationList);
