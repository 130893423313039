// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { Select } from './../ui';

export const vehicleOwnerTypes = {
  self: 'Собственный',
  contract: 'Наемный',
  all: 'Все'
};

export const vehicleOwnerTypeEnum = {
  self: 'self',
  contract: 'contract',
  all: undefined
};

export default (props: SelectProps) => (
  <Select allowClear {...props}>
    {Object.keys(vehicleOwnerTypeEnum).map(ownerType => (
      <Select.Option key={ownerType} value={vehicleOwnerTypeEnum[ownerType]}>
        {vehicleOwnerTypes[ownerType]}
      </Select.Option>
    ))}
  </Select>
);
