import React from 'react';
import moment from 'moment';

import {Spoiler} from '../../../components/ui';
import {Section} from '../../../components/layout';
import Table from 'antd/lib/table';
import type {Employee} from '../../../lib/types';

type Item = {
  id: number,
  employee: Employee,
  created: string,
  name: string,
  prevValue: any
};

type Props = {
  history: Item[]
};

const getFieldNameTranslate = (fieldName: string): string => {
  switch (fieldName) {
    case 'OdometerAtStart':
      return 'Пробег при выезде';
    case 'OdometerAtEnd':
      return 'Пробег при возвращении в гараж';
    case 'VehicleMachineHoursAtStart':
      return 'Маш. часы при выезде';
    case 'VehicleMachineHoursAtEnd':
      return 'Маш. часы при возвращении в гараж';
    case 'FuelAtStart':
      return 'Топливо при выезде';
    case 'FuelAtEnd':
      return 'Топливо при заезде в гараж';
    default:
      return '-';
  }
};

const HistorySection = ({ history }: Props) => {
  const columns = [
    {
      title: 'Номер перерасчета',
      dataIndex: 'index'
    },
    {
      title: 'Дата изменения',
      dataIndex: 'date'
    },
    {
      title: 'ФИО пользователя, инициировавшего перерасчет',
      dataIndex: 'employee'
    },
    {
      title: 'Подразделение пользователя, инициировавшего перерасчет',
      dataIndex: 'orgUnit'
    },
    {
      title: 'Наименование поля перерасчета',
      dataIndex: 'name'
    },
    {
      title: 'Предыдущее значение поля',
      dataIndex: 'prevValue'
    }
  ];

  const dataSource = history
    .sort((a: Item, b: Item) => (moment(a.created).isAfter(b.created) ? -1 : 1))
    .map((item: Item, index) => ({
      key: index,
      index: item.id,
      date: moment(item.created).format('DD.MM.YYYY HH:mm'),
      employee: `${item.employee.lastname} ${item.employee.firstname} ${item.employee.middlename}`,
      orgUnit: item.employee.orgUnitName,
      name: getFieldNameTranslate(item.name),
      prevValue:
        typeof item?.prevValue === 'number'
          ? Math.round(item.prevValue * 100) / 100
          : '-'
    }));

  return (
    <Section>
      <Spoiler showDivider={false} label="История перерасчета">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={dataSource.length > 5 ? { pageSize: 5 } : false}
        />
      </Spoiler>
    </Section>
  );
};

export default React.memo(HistorySection);
