// @flow
import React from 'react';
import {connect} from 'react-redux';
import notification from 'antd/lib/notification';

import {canHandleService} from '../lib';
import type {InspectionGibdd, UserAccess} from '../../../lib/types';
import {inspectionGibddApi} from '../../../lib/api';
import {convertVehicleToString, formatDateTimeToString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';

import {Header} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import type {AppState} from '../../../ducks/redux';
import {Icon} from '../../../components/ui';
import {Panel, SectionContent} from '../elements';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, {GridItem} from '../../../components/layout/Grid';

import {ActInfo} from './../../Act';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  inspectionGibdd: InspectionGibdd
};

class InspectionsGibddCard extends React.Component<Props, State> {
  state = {
    inspectionGibdd: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const inspectionGibdd = await inspectionGibddApi.fetchInspectionGibdd(
        parseInt(id, 10)
      );
      this.setState({ inspectionGibdd });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { inspectionGibdd } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/services/inspections-gibdd')}
              >
                Список технических осмотров ГИБДД
              </Crumb>
              <Crumb>Технический осмотр ГИБДД №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() =>
                  navigate(`/services/inspections-gibdd/${id}/edit`)
                }
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Технический осмотр ГИБДД №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              {inspectionGibdd.vehicle !== null ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(inspectionGibdd.vehicle)}
                  </Field>
                </GridItem>
              ) : null}

              {inspectionGibdd.maintenanceDate !== null ? (
                <GridItem>
                  <Field label="Дата обслуживания">
                    {formatDateTimeToString(
                      inspectionGibdd.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              ) : null}

              {inspectionGibdd.nextActDate !== null ? (
                <GridItem>
                  <Field label="Следующая дата обслуживания">
                    {formatDateTimeToString(
                      inspectionGibdd.nextActDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              ) : null}

              <GridItem>
                <Field label="Сумма оплаты, руб.">
                  {(inspectionGibdd.paymentAmount || 0).toLocaleString(
                    'ru-RU',
                    {
                      style: 'currency',
                      currency: 'RUB'
                    }
                  )}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {inspectionGibdd && inspectionGibdd.act && (
          <ActInfo act={inspectionGibdd.act} />
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(InspectionsGibddCard);
