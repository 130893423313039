// @flow

import queryString from 'query-string';
import fileSaver from 'file-saver';
import 'whatwg-fetch';

import tokenManager from './../tokenManager';

export const tokenWrapper = tokenManager;

export const apiUrl = `${process.env.REACT_APP_API_URL || ''}/api`;

export {default as writeOffActApi} from './writeOffAct';
export {default as contractVehiclePlanVehicleApi} from './contractVehiclePlanVehicle';
export {default as contractVehiclePlanApi} from './contractVehiclePlan';
export {default as fuelPriceApi} from './fuelPrice';
export {default as vehicleTariffApi} from './vehicleTariff';
export {default as reportScheduleMaintenanceApi} from './reportScheduleMaintenance';
export {default as outsourcingActApi} from './outsourcingAct';
export {default as maintenanceWorkTypeApi} from './maintenanceWorkType';
export {default as maintenanceDocumentApi} from './maintenanceDocument';
export {default as compareExpenseDirectionApi} from './compareExpenseDirection';
export {default as expenseDirectionApi} from './expenseDirection';
export {default as stockPartApi} from './stockPart';
export {default as fileApi} from './file';
export {default as vehicleIssueApi} from './vehicleIssue';
export {default as osagoMultiplier} from './osagoMultiplier';
export {default as washingPriceListApi} from './washingPriceList';
export {default as userApi} from './user';
export {default as authApi} from './auth';
export {default as orgUnitApi} from './orgUnit';
export {default as employeeApi} from './employee';
export {default as locationApi} from './location';
export {default as vehicleApi} from './vehicle';
export {default as orderApi} from './order';
export {default as routeApi} from './route';
export {default as shiftApi} from './shift';
export {default as tripApi} from './trip';
export {default as driverApi} from './driver';
export {default as brandApi} from './brands';
export {default as batteryApi} from './battery';
export {default as tireApi} from './tire';
export {default as optionalEquipmentApi} from './optional';
export {default as attachedEquipmentApi} from './attached';
export {default as vehicleModelApi} from './vehicleModel';
export {default as orderGpmApi} from './order-gpm';
export {default as fuelAndOilApi} from './fuelAndOil';
export {default as journalApi} from './journal';
export {default as companyApi} from './company';
export {default as contractorApi} from './contractor';
export {default as contractVehicleApi} from './contractVehicle';
export {default as maintenanceOperationApi} from './maintenanceOperation';
export {default as maintenanceApi} from './maintenance';
export {default as roleApi} from './role';
export {default as washingApi} from './washing';
export {default as contractVehicleOrderApi} from './contractVehicleOrder';
export {default as reportVehicleWorksApi} from './reportVehicleWorks';
export {default as reportTaxedTripsApi} from './reportTaxedTrips';
export {default as reportTaxedTripsElectricsApi} from './reportTaxedTripsElectrics';
export {default as fuelCardApi} from './fuelCard';
export {default as mdmApi} from './mdm';
export {default as fuelMultiplierApi} from './fuelMultiplier';
export {default as reportFuelCardsApi} from './reportFuelCards';
export {default as passApi} from './pass';
export {default as tripRangeApi} from './tripRange';
export {default as employeeDocumentApi} from './employeeDocument';
export {default as jobTitleApi} from './jobTitle';
export {default as reportDriversApi} from './reportDrivers';
export {default as reportFuelConsumptionApi} from './reportFuelConsumption';
export {default as fuelMeasurementApi} from './fuelMeasurement';
export {default as tireMeasurementApi} from './tireMeasurement';
export {default as businessDayApi} from './businessDay';
export {default as businessCalendarForBudgetApi} from './businessCalendarForBudget';
export {default as reportArchiveVehiclesApi} from './reportArchiveVehicles';
export {default as reportGpmOrderApi} from './reportGpmOrder';
export {default as reportVehicleOutputRatioApi} from './reportVehicleOutputRatio';
export {default as reportBusinessTripsApi} from './reportBusinessTrips';
export {default as reportWeekendOrdersApi} from './reportWeekendOrders';
export {default as osagoApi} from './osago';
export {default as inspectionGibddApi} from './inspectionGibdd';
export {default as inspectionGtnApi} from './inspectionGtn';
export {default as expertiseServiceGpmApi} from './expertiseServiceGpm';
export {default as measuringDeviceCertificationApi} from './measuringDeviceCertification';
export {default as driverInspectionApi} from './driverInspection';
export {default as platonApi} from './platon';
export {default as vehicleMonitoringApi} from './vehicleMonitoring';
export {default as licensePlateChangeApi} from './licensePlateChange';
export {default as vehicleFuelAndOilApi} from './vehicleFuelAndOil';
export {default as operationLimitApi} from './operationLimit';
export {default as operationLimitGroupApi} from './operationLimitGroup';
export {default as contractVehicleDailyBudgetApi} from './contractVehicleDailyBudget';
export {default as stouApi} from './stou';
export {default as osagoCalculationApi} from './osagoCalculation';
export {default as monthlyWashingPlanApi} from './monthlyWashingPlan';
export {default as cargoApi} from './cargo';
export {default as loadUnloadCargoApi} from './loadUnloadCargo';
export {default as monitoringPriceApi} from './monitoringPrice';
export {default as vehicleIssueGroupApi} from './vehicleIssueGroup';
export {default as osagoCalculationYearApi} from './osagoCalculationYear';
export {default as detailedDefectiveStatementApi} from './detailedDefectiveStatement';
export {default as maintenanceOperationPriceApi} from './maintenanceOperationPrice';
export {default as maintenanceMatrixApi} from './maintenanceMatrix';
export {default as contractApi} from './contract';
export {default as stageApi} from './stage';
export {default as offerApi} from './offer';
export {default as incomeScheduleApi} from './incomeSchedule';
export {default as actApi} from './act';
export {default as budgetVersionApi} from './budgetVersion';
export {default as regulationApi} from './regulation';
export {default as regulationLimitApi} from './regulationLimit';
export {default as regulationLimitGroupApi} from './regulationLimitGroup';
export {default as assignmentApi} from './assignment';
export {default as assignmentLimitApi} from './assignmentLimit';
export {default as assignmentLimitGroupApi} from './assignmentLimitGroup';
export {default as contractVehicleBudgetApi} from './contractVehicleBudget';
export {default as okeiApi} from './okei';
export {default as vehiclePlanApi} from './vehiclePlan';
export {default as federalHighwayFareApi} from './federalHighwayFare';
export {default as gpsSubscriptionServiceCostApi} from './gpsSubscriptionServiceCost';
export {default as measuringDeviceCertificationCostApi} from './measuringDeviceCertificationCost';
export {default as inspectionGibddCostApi} from './inspectionGibddCost';
export {default as inspectionGtnCostApi} from './inspectionGtnCost';
export {default as licensePlateChangeCostApi} from './licensePlateChangeCost';
export {default as stockApi} from './stock';
export {default as expertiseServiceGpmCostApi} from './expertiseServiceGpmCost';
export {default as batteryCalculationApi} from './batteryCalculation';
export {default as gpmMaintenanceCalculationApi} from './gpmMaintenanceCalculation';
export {default as measuringDeviceCertificationCalculationApi} from './measuringDeviceCertificationCalculation';
export {default as vehicleMonitoringCalculationApi} from './vehicleMonitoringCalculation';
export {default as inspectionGtnCalculationApi} from './inspectionGtnCalculation';
export {default as inspectionGibddCalculationApi} from './inspectionGibddCalculation';
export {default as driverInspectionCalculationApi} from './driverInspectionCalculation';
export {default as washingCalculationApi} from './washingCalculation';
export {default as federalHighwayCalculationApi} from './federalHighwayCalculation';
export {default as federalHighwayCalculationMonthApi} from './federalHighwayCalculationMonth';
export {default as tripInspectionCostApi} from './tripInspectionCost';
export {default as tripInspectionFrequencyApi} from './tripInspectionFrequency';
export {default as overhaulTypeApi} from './overhaulType';
export {default as currentMaintenanceCostApi} from './currentMaintenanceCost';
export {default as maintenanceCostAllocationTRApi} from './maintenanceCostAllocationTR';
export {default as maintenanceCostAllocationApi} from './maintenanceCostAllocation';
export {default as vehicleTaxRateApi} from './vehicleTaxRate';
export {default as vehicleTaxMapApi} from './vehicleTaxMap';
export {default as budgetsForAnalysisApi} from './budgetsForAnalysis';
export {default as materialCalculationApi} from './materialCalculation';
export {default as licensePlateChangeCalculationApi} from './licensePlateChangeCalculation';
export {default as passTariffApi} from './passTariff';
export {default as springPassCalculationApi} from './springPassCalculation';
export {default as cargoPassCalculationApi} from './cargoPassCalculation';
export {default as fuelsAndOilsCalculationApi} from './fuelAndOilCalculation';
export {default as fuelsAndOilsMechanismApi} from './fuelAndOilMechanism';
export {default as maintenanceOperationContractCalculationApi} from './maintenanceOperationContractCalculation';
export {default as tireCalculationApi} from './tireCalculation';
export {default as currentRepairCalculationApi} from './currentRepairCalculation';
export {default as overhaulCalculationApi} from './overhaulCalculation';
export {default as overhaulCalculationMonthApi} from './overhaulCalculationMonth';
export {default as repairBudgetApi} from './repairBudget';
export {default as vehicleTaxCalculationApi} from './vehicleTaxCalculation';
export {default as budgetLineItemApi} from './budgetLineItem';
export {default as vehiclePlanBudgetApi} from './vehiclePlanBudget';
export {default as placeApi} from './place';
export {default as budgetSummaryApi} from './budgetSummary';
export {default as branchBudgetSummaryApi} from './branchBudgetSummary';
export {default as fuelsActApi} from './fuelsAct';
export {default as oilsActApi} from './oilsAct';
export {default as budgetAnalysisApi} from './budgetAnalysis';
export {default as additionalCalculationApi} from './additionalCalculation';
export {default as smallScaleMechanizationToolApi} from './smallScaleMechanizationTool';
export {default as vehicleTaxPlanApi} from './vehicleTaxPlan';
export {default as registrationPlaceApi} from './registrationPlace';
export {default as reasonsForRejectinfOfOrderApi} from './reasonsForRejectingOfOrder';
export {default as standardOfTireOperationApi} from './standardOfTireOperation';
export {default as branchBudgetSummaryMonthApi} from './branchBudgetSummaryMonth';
export {default as expensesDistributionApi} from './expensesDistribution';
export {default as branchBudgetSummaryMonthRedistributionApi} from './branchBudgetSummaryMonthRedistribution';
export {default as branchBudgetSummaryExpenseDirectionApi} from './branchBudgetSummaryExpenseDirection';
export {default as branchBudgetSummaryLineItemApi} from './branchBudgetSummaryLineItem';
export {default as displayExpenseDirectionApi} from './displayExpenseDirection';
export {default as ISUBudgetingDataApi} from './ISUBudgetingData';
export {default as budgetSummaryContractApi} from './budgetSummaryContracts';
export {default as cognosLogApi} from './cognosLog';
export {default as debetCreditAccountsApi} from './debetCreditAccounts';
export {default as deadlinesForSendingDataToCognosApi} from './deadlinesForSendingDataToCognos';
export {default as typesOfOilsApi} from './typesOfOils';
export {default as OilConsumptionApi} from './oilConsumption';
export {default as TypesOfRepairsApi} from './repairKind';
export {default as MaintenanceWorkApi} from './maintenanceWork';
export {default as ModelMatchesApi} from './modelMatches';
export {default as MaintenanceEstimatesApi} from './maintenanceEstimates';
export {default as ContractTripApi} from './contractTrip';
export {default as ContractorBranchLinkApi} from './contractorBranchLink';
export {default as compareDatesApi} from './compareDates';
export {default as inventoryCardCompareApi} from './inventoryCardCompare';
export {default as calendarOfBKApi} from './calendarOfBudgetCommittee';
export {default as VehicleCategoriesApi} from './vehicleCategories';
export {default as VehicleSubcategoriesApi} from './vehicleSubcategories';
export {default as VehicleWorkMeterApi} from './vehicleWorkMeter';

/*###API_INDEX*/

export type FetchListParams<T = {}> = $Shape<{
  where: any,
  search: string,
  page: number,
  pageSize: number,
  returnDeleted: boolean,
  orderBy: string,
  byDescending: boolean
} & T>;

export const initialFetchListParams = {
  page: 1,
  pageSize: 20,
};

export type ListResponse<Type> = {
  data: Type[],
  totalCount: number,
  pageSize: number,
  totalPages: number
};

export async function fetchTokenRequest(
  url: string,
  params: any,
): Promise<any> {
  const response = await window.fetch(`${apiUrl}${url}`, {
    ...params,
    headers: {
      ...params.headers,
      Authorization: `Bearer ${tokenManager.getToken()}`,
    },
  });
  if (response.ok) {
    if (response.status === 204) {
      return null;
    }
    return await response.json();
  } else {
    if (response.status === 503) {
      throw new Error('Server is under maintenance');
    } else {
      if (response.status >= 400) {
        let resp;
        try {
          resp = await response.text();
          // строго говоря вообще сервер не обязан отдавать какие-то описания в json
          // поэтому сильно и не расчитываем на это
          resp = JSON.parse(resp);
        } finally {
          throw new Error(
            resp
              ? resp.title || resp
              : `Unhadled error. Server status code ${response.status}`,
          );
        }
      }
    }
  }
}

// Скачивает файл с отправкой токена авторизации
export async function downloadRequestWithToken(
  url: string,
  params?: any,
  fileName?: string,
  withoutApiPrefix = false,
) {
  const response = await window.fetch(
    `${withoutApiPrefix ? '' : apiUrl}${url}${
      params ? `?${queryString.stringify(params, {encode: false})}` : ''
    }`,
    {
      headers: {
        method: 'GET',
        Authorization: `Bearer ${tokenManager.getToken()}`,
      },
    },
  );
  if (response.ok) {
    if (response.status === 204) {
      throw new Error('No Content');
    }
    const blob = await response.blob();
    // извлекаем название файла из заголовка Content-Disposition
    const name = decodeURIComponent(response.headers.get('content-disposition')?.split('\'\'')[1] || 'unknown_file');
    fileSaver.saveAs(blob, fileName || name);
    return true;
  } else {
    // данный блок else нужен для передачи ошибки в компонент для дальнейшей обработки/отображения
    throw await response.json();
  }
}

export class fetchRequest {
  static async get(path: string, data?: any, options: any = {}) {
    return await fetchTokenRequest(
      `${path}${data ? `?${queryString.stringify(data)}` : ''}`,
      options,
    );
  }

  static async post(path: string, data?: any, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });
  }

  static async put(path: string, data: any, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      ...options,
    });
  }

  static async delete(path: string, options: any = {}) {
    return await fetchTokenRequest(path, {
      method: 'DELETE',
      ...options,
    });
  }
}
