// @flow
import React from 'react';

import type { BranchBudgetSummary, BudgetSummary } from '../../../../lib/types';

import Contracts from './Contracts/Form';
import CostEstimation from './CostEstimation/Form';
import { branchBudgetSummaryCognosTypeEnum } from '../../../../lib/enum';

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  budgetSummary: ?BudgetSummary,
  type?: string,
  onSubmit: Function,
  source: $Keys<branchBudgetSummaryCognosTypeEnum>,
  setBranchData: () => void
};

export default (props: Props) => {
  const {
    id,
    orgUnitId,
    branchBudgetSummary,
    budgetSummary,
    onSubmit,
    source,
    setBranchData
  } = props;

  const getComponents = () => {
    switch (props.type) {
      case 'contracts':
        return (
          <Contracts
            id={id}
            orgUnitId={orgUnitId}
            budgetSummary={budgetSummary}
            branchBudgetSummary={branchBudgetSummary}
            source={source}
          />
        );
      default:
        return (
          <CostEstimation
            id={id}
            orgUnitId={orgUnitId}
            budgetSummary={budgetSummary}
            branchBudgetSummary={branchBudgetSummary}
            onSubmit={onSubmit}
            source={source}
            setBranchData={setBranchData}
          />
        );
    }
  };

  return getComponents();
};
