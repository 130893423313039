// @flow
import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import type {FormFieldType} from '../../../components/Form';
import Form from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import type {RepairKind} from '../../../lib/types/typesOfRepairs';
import InputNumber from 'antd/lib/input-number';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  typeOfRepair: ?RepairKind,
  onCancel: Function,
  onSubmit: Function
};

export default ({typeOfRepair, onCancel, onSubmit}: Props) => (
  <Form initialValues={typeOfRepair} onSubmit={onSubmit}>
    {(
      FormField: FormFieldType,
      {
        handleSubmit,
        dirty,
        isSubmitting,
        setFieldValue,
        values,
      }: FormikProps,
    ) => (
      <form onSubmit={handleSubmit}>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Вид ремонта" fast name="name" required hasFeedback={false}>
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
            {!!values.id && (
              <GridItem>
                <FormField label="Код вида ремонта" fast name="code" hasFeedback={false}>
                  {({name, value}) => (
                    <InputNumber
                      value={value}
                      onChange={newValue => setFieldValue(name, newValue)}
                      disabled
                    />
                  )}
                </FormField>
              </GridItem>
            )}
          </Grid>
        </StyledSection>
        <Footer>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            Сохранить
          </Button>
          <CancelButton dirty={dirty} onClick={onCancel}>
            Отменить
          </CancelButton>
        </Footer>
      </form>
    )}
  </Form>
);
