// @flow
import CommonApi from './../commonApi';
import { TypesOfOilsItem } from '../types/typesOfOils';

const typesOfOils = new CommonApi<TypesOfOilsItem>(
  'UatToParusOilType',
  'Типы масел для учета расхода'
);

export default { ...typesOfOils };
