// @flow
import {downloadRequestWithToken, fetchRequest} from './index';
import type {SelfVehiclePlanVehicle, SelfVehiclePlanVehicleMonth, VehiclePlan, VehiclePlanStatus} from './../types';
import CommonApi from '../commonApi';
import {convertFromHourToSecond, convertFromKmToM, convertFromMToKm, convertFromSecondToHour} from '../helpers';
import {notificationError} from '../../components/Notifications';

export const convertDataToServer = (vehiclePlan: VehiclePlan): VehiclePlan => ({
  ...vehiclePlan,
  selfVehicles: vehiclePlan.selfVehicles
    ? vehiclePlan.selfVehicles.map(convertSelfVehiclesToServer)
    : [],
  plannedVehicles: vehiclePlan.plannedVehicles
    ? vehiclePlan.plannedVehicles.map(convertSelfVehiclesToServer)
    : []
});

export const convertDataFromServer = (vehiclePlan: VehiclePlan): VehiclePlan => ({
  ...vehiclePlan,
  selfVehicles: vehiclePlan.selfVehicles
    ? vehiclePlan.selfVehicles.map(convertSelfVehiclesFromServer)
    : [],
  plannedVehicles: vehiclePlan.extPlannedVehicles
    ? vehiclePlan.extPlannedVehicles.map(convertSelfVehiclesFromServer)
    : vehiclePlan.plannedVehicles
    ? vehiclePlan.plannedVehicles.map(convertSelfVehiclesToServer)
    : []
});

const convertSelfVehiclesToServer = (selfVehicle: SelfVehiclePlanVehicle) => ({
  ...selfVehicle,
  months: selfVehicle.months
    ? selfVehicle.months.map((month: SelfVehiclePlanVehicleMonth) => ({
        ...month,
        kilometrage: convertFromKmToM(month.kilometrage),
        workHours: convertFromHourToSecond(month.workHours)
      }))
    : []
});

const convertSelfVehiclesFromServer = (selfVehicle: SelfVehiclePlanVehicle) => ({
  ...selfVehicle,
  months: selfVehicle.months
    ? selfVehicle.months.map((month: SelfVehiclePlanVehicleMonth) => ({
        ...month,
        kilometrage: convertFromMToKm(month.kilometrage),
        workHours: convertFromSecondToHour(month.workHours)
      }))
    : []
});

const basedUrl = 'vehiclePlan';
const vehiclePlan = new CommonApi<VehiclePlan>(
  basedUrl,
  'план CТС',
  convertDataToServer,
  convertDataFromServer
);

export const changeStatus = async (data: {
  id: number,
  status: VehiclePlanStatus
}): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, data);
  if (updated) return convertDataFromServer(updated);
  throw new Error(`Не удалось сменить статус`);
};

export const createBudget = async (data: {
  id: number,
  name: string
}): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(`/${basedUrl}/createBudget`, data);
  if (updated) return convertDataFromServer(updated);
  throw new Error(`Не удалось сформировать бюджет`);
};

export const updateVehicles = async (data: SelfVehiclePlanVehicle[]): Promise<SelfVehiclePlanVehicle[]> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateVehicles`,
    data.map(convertSelfVehiclesToServer)
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

export const calculate = async (id: number): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(`/${basedUrl}/calculate/${id}`);
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

export const print = async (id: number) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/${id}/print`);
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const addVehicleToPlan = async (vehicleData) => {
  try {
    const addedVehicle = await fetchRequest.post('/SelfVehiclePlanVehicle', vehicleData);
    return convertSelfVehiclesFromServer(addedVehicle);
  } catch (e) {
    notificationError(e);
  }
}

export default {
  ...vehiclePlan,
  print,
  changeStatus,
  calculate,
  createBudget,
  updateVehicles,
  addVehicleToPlan,
};
