// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import type { Maintenance } from '../../../lib/types';
import { Selects } from '../../../components';
import Grid, { GridItem } from '../../../components/layout/Grid';

const { EmployeeSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;

const StyledGridItem = styled(GridItem)`
  overflow: auto;
`;

type Props = {
  maintenance: Maintenance,
  handleSubmit: Function,
  onChange: (value: any) => void
};

type State = {
  touched: boolean
};

/**
 * Работы
 */
export default class MaintenanceEmployee extends React.Component<Props, State> {
  state = {
    touched: false
  };

  onChange = (name: string, value: number) => {
    const { maintenance, onChange } = this.props;
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    onChange({
      ...maintenance,
      [name]: value
    });
  };

  handleSubmit = () => {
    this.setState({ touched: false }, this.props.handleSubmit);
  };

  render() {
    const { maintenance } = this.props;
    const { touched } = this.state;
    return (
      <Content>
        <Grid cols={4} gutter="16px" style={{ marginBottom: '10px' }}>
          <GridItem fullWidth style={{ paddingBottom: '10px' }}>
            Согласующие (Дефектная ведомость)
          </GridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.firstEmployeeId}
              onChange={employeeId => {
                this.onChange('firstEmployeeId', employeeId);
              }}
            />
          </StyledGridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.secondEmployeeId}
              onChange={employeeId => {
                this.onChange('secondEmployeeId', employeeId);
              }}
            />
          </StyledGridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.thirdEmployeeId}
              onChange={employeeId => {
                this.onChange('thirdEmployeeId', employeeId);
              }}
            />
          </StyledGridItem>
        </Grid>

        <Grid cols={4} gutter="16px" style={{ margin: '10px 0px' }}>
          <GridItem fullWidth style={{ paddingBottom: '10px' }}>
            Члены комиссии (Дефектный акт)
          </GridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.firstCommissionerId}
              onChange={employeeId => {
                this.onChange('firstCommissionerId', employeeId);
              }}
            />
          </StyledGridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.secondCommissionerId}
              onChange={employeeId => {
                this.onChange('secondCommissionerId', employeeId);
              }}
            />
          </StyledGridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.thirdCommissionerId}
              onChange={employeeId => {
                this.onChange('thirdCommissionerId', employeeId);
              }}
            />
          </StyledGridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.fourthCommissionerId}
              onChange={employeeId => {
                this.onChange('fourthCommissionerId', employeeId);
              }}
            />
          </StyledGridItem>
        </Grid>

        <Grid cols={4} gutter="16px" style={{ margin: '10px 0px' }}>
          <GridItem fullWidth style={{ paddingBottom: '10px' }}>
            Руководитель (Дефектный акт)
          </GridItem>
          <StyledGridItem>
            <EmployeeSelect
              value={maintenance.chiefId}
              onChange={chiefId => {
                this.onChange('chiefId', chiefId);
              }}
            />
          </StyledGridItem>
        </Grid>

        {touched && (
          <Grid style={{ marginTop: '10px' }}>
            <GridItem fullWidth>
              <Button type="primary" onClick={this.handleSubmit}>
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        )}
      </Content>
    );
  }
}
