// @flow
import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { TripInspectionFrequency } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import VehicleTypeSelect from '../../../components/selects/VehicleTypeSelect';
import type { VehicleGroupAndType } from '../../../components/selects/VehicleTypeSelect';

export default (props: CommonFormProps<$Shape<TripInspectionFrequency>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Тип ТС" fast required name="vehicleType">
                  {({ name, value }) => (
                    <VehicleTypeSelect
                      resultAsObject
                      onChange={({ type }: VehicleGroupAndType) => {
                        setFieldValue(name, type);
                      }}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество осмотров в рабочий день"
                  fast
                  required
                  name="inspectionCount"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата начала" name="startDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата окончания" name="endDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
