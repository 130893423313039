// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';

import InputNumber from 'antd/lib/input-number';

import {Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import type {OsagoCalculation} from '../../../lib/types';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import {Form, Selects} from '../../../components';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {osagoCalculationApi} from '../../../lib/api';
import type {VehicleGroupAndType} from './../../../components/selects/VehicleTypeSelect';
import type {AppState} from '../../../ducks/redux';
import CancelButton from '../../../components/CancelButton';

const {
  VehicleTypeSelect,
  YesNoSelect,
  YearIssueSelect,
  VehicleModelSelect,
  VehicleSelect
} = Selects;

const Content = styled.div`
  padding: 16px 0;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  osagoCalculation: $Shape<OsagoCalculation>
};

const InnerForm = ({ onSubmit, onCancel, osagoCalculation }: FormProps) => {
  return (
    <Form initialValues={osagoCalculation} onSubmit={onSubmit}>
      {(FormField, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleBlur,
          handleFocus,
          setFieldValue,
          values,
          dirty
        } = formikProps;
        const requiredSelectType =
          !values.vehicleType &&
          !values.vehicleGroup &&
          !values.vehicleModelId &&
          !values.vehicleId;
        const enableSetAdditionalFields =
          values.vehicleGroup || values.vehicleType;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <StyledGrid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="ТС"
                      name="vehicleId"
                      required={requiredSelectType}
                    >
                      {({ value, name }) => (
                        <VehicleSelect
                          disabled={
                            !!values.vehicleType ||
                            !!values.vehicleGroup ||
                            !!values.vehicleModelId
                          }
                          value={value}
                          name={name}
                          onFocus={handleFocus}
                          onChange={async (value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Модель ТС"
                      name="vehicleModelId"
                      required={requiredSelectType}
                    >
                      {({ value, name }) => (
                        <VehicleModelSelect
                          disabled={
                            !!values.vehicleType ||
                            !!values.vehicleGroup ||
                            !!values.vehicleId
                          }
                          value={value}
                          name={name}
                          onFocus={handleFocus}
                          data-cy="modelSelect"
                          onChange={async (value: number) =>
                            setFieldValue(name, value)
                          }
                          placeholder="Выберите модель"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тип ТС"
                      name="vehicleType"
                      required={requiredSelectType}
                    >
                      {({ name, value }) => (
                        <VehicleTypeSelect
                          // Если выбрали модель, то не должны заполнять тип ТС, он уже есть
                          disabled={
                            !!values.vehicleModelId || !!values.vehicleId
                          }
                          resultAsObject
                          onChange={(vehicleType: VehicleGroupAndType) => {
                            const { group, type } = vehicleType;
                            setFieldValue(name, type);
                            setFieldValue('vehicleGroup', group);
                          }}
                          selectableGroup
                          onBlur={() =>
                            handleBlur({
                              target: { name }
                            })
                          }
                          value={value || values.vehicleGroup}
                          data-cy="vehicleTypeSelect"
                          name={name}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {!values.vehicleId && (
                    <GridItem>
                      <FormField
                        fast
                        label="Год выпуска"
                        name="yearIssued"
                        required
                      >
                        {({ value, name }) => (
                          <YearIssueSelect
                            id="yearIssued"
                            value={value}
                            onChange={(value: number) =>
                              setFieldValue(name, value)
                            }
                            onBlur={() => handleBlur({ target: { name } })}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {enableSetAdditionalFields && (
                    <>
                      <GridItem>
                        <FormField
                          label="Мощность двигателя, л.с."
                          name="enginePower"
                        >
                          {({ value, name }) => (
                            <InputNumber
                              name={name}
                              value={value}
                              decimalSeparator=","
                              min={0}
                              max={999}
                              onChange={value => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Наличие прицепа"
                          name="withTrailer"
                          defaultValue={false}
                          fast
                        >
                          {({ value, name }) => (
                            <YesNoSelect
                              value={value}
                              name={name}
                              onChange={(value: boolean) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                </StyledGrid>
                <SectionTitle>Коэффициенты</SectionTitle>
                <StyledGrid cols={4}>
                  <GridItem span={2}>
                    <FormField
                      label="Базовая ставка (мин)"
                      name="minBaseRate"
                      fast
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          max={999999}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem span={2}>
                    <FormField
                      label="Базовая ставка (макс)"
                      name="maxBaseRate"
                      fast
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          max={999999}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КТ"
                      name="kt"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КБМ"
                      name="kbm"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КО"
                      name="ko"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КВС"
                      name="kvs"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>

                  <GridItem>
                    <FormField
                      label="КС"
                      name="kc"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КП"
                      name="kp"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КМ"
                      name="km"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="КПР"
                      name="kpr"
                      fast
                      required
                      defaultValue={1}
                    >
                      {({ value, name }) => (
                        <InputNumber
                          disabled
                          name={name}
                          value={value}
                          decimalSeparator=","
                          min={0}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </StyledGrid>
              </Content>
            </Section>
            <Footer>
              {/* */}
              {/* <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                data-cy="save"
              >
                Сохранить
              </Button> */}
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};

type Props = {
  osagoCalculationId: number,
  employeeBranchOrgUnitId: number
};

type State = {
  osagoCalculation?: OsagoCalculation
};

class OsagoCalculationForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { osagoCalculationId } = this.props;
    if (osagoCalculationId) {
      try {
        const osagoCalculation = await osagoCalculationApi.fetchOsagoCalculation(
          osagoCalculationId
        );
        this.setState({
          osagoCalculation
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/budget/osago-calculations', true);
      }
    }
  }

  onCancel = () => {
    window.history.back();
  };

  onSubmit = async (osagoCalculation: OsagoCalculation) => {
    // Сабмит формы закоменчен тк сказали запретить возможность пользователям редактировать/
    // коэффициенты Кнопка не рендериться, но можно засабмитить форму энтером. Это кусок закометил, а не удалил
    // тк есть ощущение что может потом потребовать вернуть эту возможность для админа
    return;
    // try {
    //   notificationLoading({
    //     message: 'Сохранение данных...',
    //     key: 'saving'
    //   });
    //   if (osagoCalculation.id) {
    //     await osagoCalculationApi.updateOsagoCalculation(osagoCalculation);
    //     notification.success({
    //       message: 'Успешное обновление',
    //       description: 'Данные были успешно обновлены'
    //     });
    //   } else {
    //     await osagoCalculationApi.addOsagoCalculation(osagoCalculation);
    //     notification.success({
    //       message: 'Успешное добавление',
    //       description: `Успешно добавлено навесное оборудование`
    //     });
    //   }
    //   navigate('/budget/osago-calculations', true);
    // } catch (error) {
    //   notification.error({
    //     message: 'Ошибка',
    //     description: error.message
    //   });
    // } finally {
    //   notification.close('saving');
    // }
  };

  render() {
    const { osagoCalculation } = this.state;
    const { osagoCalculationId, employeeBranchOrgUnitId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/budget/osago-calculations')}
              >
                Список расчетов стоимости ОСАГО
              </Crumb>
              {osagoCalculationId ? (
                <Crumb>Редактирование</Crumb>
              ) : (
                <Crumb>Новый расчет стоимости ОСАГО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoCalculation
              ? 'Расчет стоимости ОСАГО'
              : 'Новый расчет стоимости ОСАГО'}
          </h1>
        </StyledPanel>
        <InnerForm
          osagoCalculation={
            osagoCalculation || { orgUnitId: employeeBranchOrgUnitId }
          }
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  osagoCalculationId: parseInt(props.osagoCalculationId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(OsagoCalculationForm);
