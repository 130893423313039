// @flow
import type { StandardOfTireOperation } from '../types';
import CommonApi from './../commonApi';

const standardOfTireOperation = new CommonApi<StandardOfTireOperation>(
  'tireusinglimit',
  'Норматив эксплуатации шин'
);

export default {
  ...standardOfTireOperation
};
