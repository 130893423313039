// @flow
import type {
  CalculationStatus,
  CurrentRepairCalculation,
  CurrentRepairCalculationMonth
} from './../types';
import CommonApi from './../commonApi';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour
} from './../helpers';
import { fetchRequest } from './index';

export const convertDataFromServer = (repair: CurrentRepairCalculation) => ({
  ...repair,
  sum: convertFromKopToRub(repair.sum),
  contractMaterialsSum: convertFromKopToRub(repair.contractMaterialsSum),
  internalMaterialsSum: convertFromKopToRub(repair.internalMaterialsSum),
  fullMaterialsSum: convertFromKopToRub(repair.fullMaterialsSum),
  contractOperationsSum: convertFromKopToRub(repair.contractOperationsSum),
  internalOperationsSum: convertFromKopToRub(repair.internalOperationsSum),
  fullOperationsSum: convertFromKopToRub(repair.fullOperationsSum),
  months: repair.months.map(month => convertDataFromServerMonth(month))
});

export const convertDataToServer = (repair: CurrentRepairCalculation) => ({
  ...repair,
  sum: convertFromRubToKop(repair.sum),
  contractMaterialsSum: convertFromRubToKop(repair.contractMaterialsSum),
  internalMaterialsSum: convertFromRubToKop(repair.internalMaterialsSum),
  fullMaterialsSum: convertFromRubToKop(repair.fullMaterialsSum),
  contractOperationsSum: convertFromRubToKop(repair.contractOperationsSum),
  internalOperationsSum: convertFromRubToKop(repair.internalOperationsSum),
  fullOperationsSum: convertFromRubToKop(repair.fullOperationsSum),
  months: repair.months.map(month => convertDataToServerMonth(month))
});

const convertDataFromServerMonth = (
  monthData: CurrentRepairCalculationMonth
) => ({
  ...monthData,
  cost: convertFromKopToRub(monthData.cost),
  correctedKilometrage: monthData.correctedKilometrage
    ? convertFromMToKm(monthData.correctedKilometrage)
    : null,
  correctedWorkHours: monthData.correctedWorkHours
    ? convertFromSecondToHour(monthData.correctedWorkHours)
    : null
});

const convertDataToServerMonth = (
  monthData: CurrentRepairCalculationMonth
) => ({
  ...monthData,
  cost: convertFromRubToKop(monthData.cost),
  correctedKilometrage: monthData.correctedKilometrage
    ? convertFromKmToM(monthData.correctedKilometrage)
    : 0,
  correctedWorkHours: monthData.correctedWorkHours
    ? convertFromHourToSecond(monthData.correctedWorkHours)
    : 0
});

const basedUrl = 'currentRepairCalculation';
const currentRepairCalculation = new CommonApi<CurrentRepairCalculation>(
  basedUrl,
  'Расчет плана текущего ремонта',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const updateMonth = async (monthData: CurrentRepairCalculationMonth) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/correct`,
    convertDataToServerMonth(monthData)
  );
  if (updated) {
    return convertDataFromServerMonth(updated);
  }
  throw new Error(`Не удалось обновить данные`);
};

export default {
  ...currentRepairCalculation,
  changeStatus,
  calculate,
  updateMonth
};
