// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';

import type { ExpertiseServiceGpm } from './../../../lib/types';
import { expertiseServiceGpmApi } from '../../../lib/api';

import { Icon, ListTable } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

import { COMMON_MAINTENANCE_COLUMNS } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  expertiseServiceGpmList: ExpertiseServiceGpm[]
};

class ExpertiseList extends Component<Props, State> {
  state = {
    loading: false,
    expertiseServiceGpmList: []
  };

  async componentDidMount() {
    this.fetchExpertiseServiceGpmList();
  }

  fetchExpertiseServiceGpmList = async () => {
    try {
      this.setState({
        loading: true
      });
      const expertiseServiceGpmList = await expertiseServiceGpmApi.fetchVehicleExpertiseServiceGpmList(
        this.props.vehicleId
      );
      this.setState({
        expertiseServiceGpmList: expertiseServiceGpmList.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  onDelete = async (expertiseServiceGpm: ExpertiseServiceGpm) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (expertiseServiceGpm.id) {
        await expertiseServiceGpmApi.deleteExpertiseServiceGpm(
          expertiseServiceGpm.id
        );
        notification.success({
          message: 'Экспертиза ПС/ТО приборов безопасности ПС успешно удалено'
        });
        await this.fetchExpertiseServiceGpmList();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete, vehicleId } = this.props;
    const { expertiseServiceGpmList, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          ...COMMON_MAINTENANCE_COLUMNS,
          {
            width: '20px',
            renderRecord: (record: ExpertiseServiceGpm) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${vehicleId}/expertises-services-gpm/${parseInt(
                      record.id
                    )}`}
                  >
                    <StyledAntIcon type="edit" />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null
          }
        ]}
        data={expertiseServiceGpmList}
      />
    );
  }
}

export default ExpertiseList;
