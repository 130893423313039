// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Card,
  Filter,
  Selects,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import { ownerTypes } from './../../../lib/enum';
import type { VehicleType, Vehicle } from './../../../lib/types';

const { Field } = Card;
const { VehicleSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type OsagoCalculationFilterParams = {
  // year?: string,
  osagoCalculationYearId?: number,
  vehicleGroup?: string,
  vehicleType?: string,
  vehicleId?: Number,
  'vehicleModel.type'?: VehicleType,
  'vehicleModel.id'?: number,
  'vehicleModel.brand.id'?: number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: OsagoCalculationFilterParams
};

const OsagoCalculationFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<OsagoCalculationFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  filter={{
                    ownerType: ownerTypes.self,
                    'vehicleModel.type': values['vehicleModel.type']
                  }}
                  onChange={value => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default OsagoCalculationFilter;
