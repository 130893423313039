// @flow
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';

import {Filter, FilterContainer, FilterItem, Selects} from './../../../../components';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';
import {notificationError} from '../../../../components/Notifications';
import {fetchRequest} from '../../../../lib/api';
import AutocompleteSelect from '../../../../components/selects/AutocompleteSelect';

const {
  YearSelect,
  VehicleTypeSelect
} = Selects;

const StyleContainer = styled.div`
  min-width: 275px;
  flex: 1 auto;
  text-align: right;
  & .ant-btn {
    margin: 0px;
  }
`;

export type VehicleListFilterParams = {
  vehicleModelId?: number,
  vehicleId?: number,
  yearIssued?: number,
  type?: string,
  hideEmpty?: boolean
};

type Props = {
  filter: VehicleListFilterParams,
  showHideEmpty?: boolean,
  vehicleType?: boolean,
  cleanFilter: Function,
  applyFilter: Function,
  vehiclePlanId?: number,
};

export default ({
  showHideEmpty = true,
  filter,
  cleanFilter,
  applyFilter,
  vehicleType,
  vehiclePlanId,
}: Props) => {
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    if (vehiclePlanId) {
      fetchVehiclesData()
    }
  }, [vehiclePlanId]);

  const fetchVehiclesData = async () => {
    try {
      const {vehicleModels, vehicles} = await fetchRequest.get(`/vehiclePlan/${vehiclePlanId}/getFilterOptions`);
      setVehicleModels(vehicleModels);
      setVehicles(vehicles);
    } catch (e) {
      notificationError(e);
    }
  }

  return (
    <FilterContainer style={{ marginBottom: '16px' }}>
      <Filter initialValues={filter}>
        {({
          values,
          changeValue
        }: FilterChildrenParams<VehicleListFilterParams>) => (
          <>
            <FilterItem maxWidth="105px">
              <Field mBottomNone>
                <AutocompleteSelect
                  renderOption={(item, Option) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )}
                  refetchParams={vehicleModels}
                  fetch={({search}) => {
                    if (search?.trim()) {
                      return {data: vehicleModels.filter(i => i.name.toLowerCase().includes(search.toLowerCase().trim()))}
                    }
                    return {data: vehicleModels};
                  }}
                  fetchSingle={id => vehicleModels.find(i => i.id === id)}
                  placeholder="Все модели ТС"
                  notFoundText="Модели ТС не найдены"
                  size="small"
                  value={values.vehicleModelId}
                  onChange={value => changeValue('vehicleModelId', value)}
                />
              </Field>
            </FilterItem>
            {vehicleType && (
              <FilterItem maxWidth="105px">
                <Field mBottomNone>
                  <VehicleTypeSelect
                    placeholder="Все типы ТС"
                    size="small"
                    value={values.type}
                    onChange={value => changeValue('type', value)}
                  />
                </Field>
              </FilterItem>
            )}
            <FilterItem maxWidth="105px">
              <Field mBottomNone>
                <AutocompleteSelect
                  renderOption={(item, Option) => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )}
                  refetchParams={vehicles}
                  fetch={({search}) => {
                    if (search?.trim()) {
                      return {data: vehicles.filter(i => i.name.toLowerCase().includes(search.toLowerCase().trim()))}
                    }
                    return {data: vehicles};
                  }}
                  fetchSingle={id => vehicles.find(i => i.id === id)}
                  notFoundText="ТС не найдены"
                  placeholder="Все ТС"
                  size="small"
                  value={values.vehicleId}
                  onChange={value => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem maxWidth="80px">
              <Field mBottomNone>
                <YearSelect
                  placeholder="Год"
                  size="small"
                  value={values.yearIssued}
                  onChange={value => changeValue('yearIssued', value)}
                />
              </Field>
            </FilterItem>
            {showHideEmpty && (
              <FilterItem style={{ minWidth: '165px' }}>
                <Field mBottomNone>
                  <Checkbox
                    style={{ position: 'relative', top: '5px' }}
                    size="small"
                    checked={values.hideEmpty}
                    onChange={e => {
                      changeValue('hideEmpty', e.target.checked);
                    }}
                  >
                    Скрыть пустые
                  </Checkbox>
                </Field>
              </FilterItem>
            )}

            <StyleContainer style={{ minWidth: '150px' }}>
              <Button
                type="primary"
                size="small"
                onClick={() => applyFilter(values)}
              >
                Применить
              </Button>
              <Button type="plain" size="small" onClick={() => cleanFilter()}>
                Очистить
              </Button>
            </StyleContainer>
          </>
        )}
      </Filter>
    </FilterContainer>
  );
}
