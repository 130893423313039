/* Filter.js | Filter | Заявки на ТС */
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import InputNumber from 'antd/lib/input-number';
import { gpmVehicleTypes, orderStatusesForFilters } from '../../lib/enum';
import { Card, Filter, FilterButtonsContainer, FilterContainer, FilterItem, RangePicker } from './../../components';
import Select from './../../components/ui/Select';
import type { VehicleGroupAndType } from '../../components/selects/VehicleTypeSelect';
import { OrgUnitSelect, Selects } from './../../components/';
import { formatDateTimeToISOString } from '../../lib/helpers';
import type { FilterChildrenParams } from '../../components/hoc/Filter';
import type { OrderObjective, OrderStatus, OwnerType, VehicleGroup, VehicleType } from '../../lib/types';

const { Option } = Select;
const { Field } = Card;
const {
  OrderTypeDispatcherSelect,
  VehicleTypeSelect,
  OrderObjectiveSelect,
  YesNoSelect,
  VehicleSelect,
  VehicleOwnerTypeSelect,
  EmployeeSelect
} = Selects;

const StyledContent = styled.div`
  padding: 16px;
`;

export type OrderFilterParams = {
  id?: number,
  status?: OrderStatus,
  isArchive?: boolean,
  vehicleType?: VehicleType[],
  vehicleGroup?: VehicleGroup,
  objective?: OrderObjective,
  orgUnitId?: number,
  startDate?: string,
  endDate?: string,
  isGpm?: boolean,
  getBranch?: boolean,
  isBusinessTrip?: boolean,
  'vehicle.id'?: number,
  'vehicle.ownerType'?: OwnerType
};

export type OrdersType = 'income' | 'archive';

type Props = {
  filter: OrderFilterParams,
  applyFilter: Function,
  cleanFilter: Function,
  mode: OrdersType,
  handlingOrder: boolean,
};

const getStatuses = (mode: OrdersType) => {
  switch (mode) {
    case 'income':
      return [
        'created',
        'approvingByMainEngineer',
        'approvedByMainEngineer',
        'onReworkGPM',
        'onReworkVehicle'
      ];
    case 'archive':
      return ['approved', 'cancelled'];
    default:
      return [
        'created',
        'approvingByMainEngineer',
        'onReworkGPM',
        'approvedByMainEngineer',
        'onReworkVehicle',
        'approved',
        'cancelled'
      ];
  }
};

export const OrderFilter = ({
  filter,
  applyFilter,
  cleanFilter,
  mode,
  handlingOrder
}: Props) => (
  <StyledContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter={true}
      >
        {({
          values,
          showAdditional,
          changeValue,
          applyFilter,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<OrderFilterParams>) => (
          <>
            {handlingOrder && (
              <FilterItem>
                <Field mBottomNone>
                  <OrderTypeDispatcherSelect
                    size="small"
                    value={!!values?.getBranch ? 'all' : 'self'}
                    onChange={(value: string) =>
                      changeValue('getBranch', value === 'all')
                    }
                  />
                </Field>
              </FilterItem>
            )}
            <FilterItem>
              <Field mBottomNone>
                <InputNumber
                  style={{ width: '100%' }}
                  size="small"
                  placeholder="Номер заявки"
                  onChange={value => changeValue('id', value)}
                  value={values?.id}
                  min={1}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm')
                    ]
                  }}
                  placeholder={['Начало', 'Конец']}
                  value={[
                    !!values?.startDate ? moment.utc(values.startDate) : null,
                    !!values?.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Все службы"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values?.orgUnitId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  size="small"
                  placeholder="Все статусы"
                  value={values?.status}
                  onChange={(value: string) => changeValue('status', value)}
                >
                  {getStatuses(mode).map((key: string) => (
                    <Option key={key} value={key}>
                      {orderStatusesForFilters[key]}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="Все ТС"
                  size="small"
                  value={values?.['vehicle.id']}
                  onChange={(id: number) => changeValue('vehicle.id', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleOwnerTypeSelect
                  placeholder="Собственные и наемные ТС"
                  size="small"
                  value={values?.['vehicle.ownerType']}
                  onChange={(id: number) =>
                    changeValue('vehicle.ownerType', id)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values?.vehicleGroup || values?.vehicleType}
                  selectableGroup
                  resultAsObject
                  onlyTypes={values?.isGpm && gpmVehicleTypes}
                  onChange={(value: VehicleGroupAndType) => {
                    if (value.group) {
                      changeValue('vehicleType', undefined);
                      changeValue('vehicleGroup', value.group);
                    } else {
                      changeValue('vehicleGroup', undefined);
                      changeValue('vehicleType', value.type);
                    }
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderObjectiveSelect
                  size="small"
                  placeholder="Цель поездки"
                  value={values?.objective}
                  onChange={value => changeValue('objective', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Укажите командировку"
                  optionText="Командировка"
                  size="small"
                  reset
                  fullWidth
                  value={values?.isBusinessTrip}
                  onChange={(value: boolean) =>
                    changeValue('isBusinessTrip', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  size="small"
                  placeholder="Укажите ГПМ"
                  optionText="ГПМ"
                  reset
                  fullWidth
                  value={values?.isGpm}
                  onChange={(value: boolean) => changeValue('isGpm', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EmployeeSelect
                  size="small"
                  placeholder="Выберите инженера"
                  value={values?.['gpm.mainEngineerId']}
                  filter={{ positions: ['engineer'] }}
                  onChange={id => changeValue('gpm.mainEngineerId', id)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EmployeeSelect
                  size="small"
                  placeholder="Выберите заявителя"
                  value={values?.['claimantId']}
                  filter={{ systemUsersRequired: true }}
                  onChange={id => changeValue('claimantId', id)}
                  
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </StyledContent>
);

export default OrderFilter;
