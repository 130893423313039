// @flow

import React, {Component} from 'react';

import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type {ExpenseDirection, UserAccess} from '../../../lib/types';
import {expenseDirectionApi} from '../../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';

import {Panel} from '../../../components/layout';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../../components/Notifications';
import {withUserAccess} from '../../withUserAccess';

import InnerForm from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  userAccess: UserAccess[],
  expenseDirectionId: ?string
};

type State = {
  expenseDirection: ?ExpenseDirection
};

class ExpenseDirectionForm extends Component<Props, State> {
  state = {
    // $FlowFixMe Отключил, так как требует пропись всех не null свойств, что не совсем возможно
    expenseDirection: {
      isAdditionalNeed: false
    }
  };

  async componentDidMount() {
    let id = parseInt(this.props.expenseDirectionId, 10);
    if (id) {
      try {
        const expenseDirection = await expenseDirectionApi.getExpenseDirection(
          id
        );
        this.setState({ expenseDirection });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  onSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.expenseDirectionId) {
        await expenseDirectionApi.updateExpenseDirection(values);
      } else {
        await expenseDirectionApi.addExpenseDirection(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/expense-direction/', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => navigate('/admin/expense-direction/', true);

  render() {
    const { expenseDirection } = this.state;
    const { expenseDirectionId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/expense-direction/')}
              >
                Список раcходов
              </Crumb>
              {expenseDirectionId ? (
                <Crumb
                  to={`/admin/expense-direction/${expenseDirectionId}/edit`}
                >
                  Наименование расхода №{expenseDirectionId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/expense-direction/new`}>
                  Новое наименование расхода
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {expenseDirectionId
              ? `Наименование расхода №${expenseDirectionId}`
              : `Новое наименование расхода`}
          </h1>
        </StyledPanel>
        <InnerForm
          expenseDirection={expenseDirection}
          userAccess={this.props.userAccess}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default withUserAccess(ExpenseDirectionForm);
