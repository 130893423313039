// @flow

import React, {Component} from 'react';
import {Link} from '@reach/router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';

import {ownerTypes, tripStatusEnum, tripStatuses, tripTypeEnum, vehicleGroups, vehicleTypes} from '../../../lib/enum';
import {applyMaskToValue, formatDateRangeString, getListInitialState, navigate, setQueryParams} from '../../../lib/helpers';
import type {TripFilterParams} from './Filter';
import Filter from './Filter';
import {Table} from './../../../components/ui';
import type {ListState, Trip, TripType, Vehicle} from '../../../lib/types';
import type {FetchListParams} from '../../../lib/api';
import {downloadRequestWithToken, tripApi} from './../../../lib/api';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';
import type {AppState} from '../../../ducks/redux';
import type {PersistFilterPayload} from '../../../ducks/persistFilters';
import {setFilter} from '../../../ducks/persistFilters';
import styled from 'styled-components';
import {Button} from 'antd';
import {notificationLoading} from '../../../components/Notifications';
import notification from 'antd/lib/notification';

const StyledButton = styled(Button)`
  position: absolute;
  top: 45px;
  right: 20px;
`;

type Props = {
  location: any,
  tripType: TripType,
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Trip> & {
  filterPath: string
};

class TripListByType extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };

  getColumns = () => {
    const columns = [
      {
        title: '№',
        dataIndex: 'idNumber',
        sorter: true,
        width: 25
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        sorter: true,
        // Нужно для того, чтобы не отрабатывал onRow
        onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
        render: (status: string, record: Trip) => (
          <Link to={`/trips/self/${record.id}/card`}>
            {tripStatuses[status]}
          </Link>
        )
      },
      {
        title: 'Тип',
        dataIndex: 'vehicleType',
        sorter: true,
        render: (text: string, record: Trip): ?string =>
          vehicleTypes[text] || vehicleGroups[record.vehicleGroup]
      },
      {
        title: 'Служба',
        sorter: true,
        sorterKey: 'node.name',
        dataIndex: 'orgUnitName'
      },
      {
        title: 'Период выделения',
        dataIndex: 'startDate',
        sorterKey: 'expectedRoute.startDate',
        sorter: true,
        render: (
          text: string, // eslint-disable-line no-unused-vars
          record: Trip
        ): string => formatDateRangeString(record.startDate, record.endDate)
      },
      {
        title: 'ТС',
        dataIndex: 'vehicle',
        sorter: true,
        // Нужно для того, чтобы не отрабатывал onRow
        onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
        render: (vehicle: Vehicle, trip: Trip) => {
          if (vehicle) {
            const licensePlate =
              vehicle.licensePlate &&
              applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
            if (vehicle.isDeleted) return licensePlate;
            return vehicle.ownerType === ownerTypes.self ? (
              <Link to={`/vehicles/${vehicle.id}`}>{licensePlate}</Link>
            ) : (
              // $FlowFixMe
              <Link to={`/vehicles/contract/${trip.contractVehicleId}`}>
                {licensePlate}
              </Link>
            );
          }
          return null;
        }
      }
    ];
    if (this.props.tripType === tripTypeEnum.archive) {
      columns.splice(
        columns.length,
        0,
        {
          title: 'Модель',
          dataIndex: 'vehicle',
          sorter: true,
          render: (vehicle: Vehicle) => vehicle.vehicleModel.name,
          key: 'model'
        },
        {
          title: 'Марка',
          dataIndex: 'vehicle',
          sorter: true,
          render: (vehicle: Vehicle) => vehicle.vehicleModel.brandName,
          key: 'brand'
        },
        {
          title: 'Расход ГСМ',
          dataIndex: 'totalFuelConsumpton',
          sorter: true,
          key: 'fuel',
          render: value => parseFloat(value).toFixed(2)
        },
        {
          title: 'Пробег',
          dataIndex: 'odometerAtEnd',
          sorter: true,
          render: (value, trip) =>
            !value || trip.status === tripStatusEnum.canceled
              ? 0
              : Math.round((value - trip.odometerAtStart) * 100) / 100,
          key: 'kilometrage'
        },
        {
          title: 'Маш. часы',
          dataIndex: 'vehicleMachineHoursAtEnd',
          sorter: true,
          key: 'hours',
          render: (value, trip) =>
            !value || trip.status === tripStatusEnum.canceled
              ? 0
              : Math.round((value - trip.vehicleMachineHoursAtStart) * 100) /
                100
        }
      );
    }
    return [...columns];
  };

  handleRowClick = (id: number) => navigate(`/trips/self/${id}/card`);

  fetchTrips = async (
    page: number = 1,
    params: FetchListParams<TripFilterParams> = {}
  ) => {
    const { tripType } = this.props;
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    if (isEmpty(params)) {
      params = { orderBy: 'expectedRoute.startDate', byDescending: true };
    }
    const { data, totalCount } = await tripApi.fetchTrips({
      ...filter,
      page,
      ...params,
      'vehicle.ownerType': ownerTypes.self,
      isArchive: tripType === tripTypeEnum.archive
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  setPersistFilter = async (values: TripFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchTrips();
  };

  applyFilter = async (filter: TripFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchTrips();
  };

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchTrips(page);
  }

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];
    const showPrintButton = /archive/i.test(window.location.href);
    return (
      <>
        {showPrintButton && (
          <StyledButton
            type="primary"
            onClick={async () => {
              try {
                notificationLoading({
                  message: 'Формирование файла...',
                  key: 'printing'
                });
                await downloadRequestWithToken(`/trip/print`, {
                  ...filter,
                  'vehicle.ownerType': 'self',
                  isArchive: true,
                  byDescending: true,
                  orderBy: 'expectedRoute.startDate'
                });
              } catch (error) {
                notification.error({
                  message: 'Ошибка',
                  description: error.message
                });
              } finally {
                notification.close('printing');
              }
            }}
          >
            Печать
          </StyledButton>
        )}
        <Filter
          filter={filter}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
          type={this.props.tripType}
        />
        <Table
          columns={this.getColumns()}
          onRow={record => ({
            onClick: () => this.handleRowClick(record.id)
          })}
          defaultOrderBy={'expectedRoute.startDate'}
          fetch={this.fetchTrips}
          loading={loading}
          pagination={{
            page,
            pageSize,
            totalCount,
            location
          }}
          data={data.map(item => ({ ...item, key: item.id }))}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(TripListByType);
