// @flow
import React from 'react';
import type { VehiclePlan } from '../../../../../lib/types';
import { TabItem, Tabs } from './../../../../../components/ui';
import { getLabel } from './Tab';

type Props = {
  vehiclePlanId: number,
  vehiclePlan?: VehiclePlan
};

export default ({ vehiclePlanId, vehiclePlan }: Props) => (
  <Tabs withRouter>
    <TabItem
      label={getLabel('Пропуска весенний период', [
        vehiclePlan?.springPassCalculationStatus
      ])}
      url={`/budget/vehicle/${vehiclePlanId}/spring-pass`}
    />
    <TabItem
      label={getLabel('Пропуска негабарит', [
        vehiclePlan?.cargoPassCalculationStatus
      ])}
      url={`/budget/vehicle/${vehiclePlanId}/cargo-pass`}
    />
  </Tabs>
);
