// @flow

import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import Switch from 'antd/lib/switch';
import { type FormikProps } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import CancelButton from '../../../components/CancelButton';
import type { FormFieldType } from '../../../components/Form';
import Form from '../../../components/Form';
import Divider from '../../../components/layout/Divider';
import YesNoSelect, { yesNoValue } from '../../../components/selects/YesNoSelect';
import Popover from '../../../components/ui/Popover';
import { medicOwnerEnum, positionEnum, vehicleGroups, vehicleTypes, waypointTypeEnum } from '../../../lib/enum';
import {
  calculateDateRangeString,
  convertFromMToKm,
  formatDateTimeToISOString,
  formatDateTimeToString,
  isVehicleTypeElectric
} from '../../../lib/helpers';
import type { KilometrageInfo, Trip, TripRange, WayPoint } from '../../../lib/types';
import type { TripAttachedEquipment } from '../../../lib/types/trip';
import { WorkAccountingTypes } from '../../../lib/types/vehicleModel';
import { canShowEngineWorkHoursData } from '../../Vehicles/lib';
import TripRangeForm from '../TripRangeForm';
import { getMinMaxWaypointDateTime } from '../lib';
import { Card, Selects, TripMap, WaypointsForm } from './../../../components';
import { Section, SectionTitle } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import EquipmentRow from './EquipmentRow';
import {
  ButtonWrapper,
  CarInfo,
  DatePicker,
  FormFooter,
  FormTripMapWrapper,
  InputNumber,
  InspectionGridItem,
  InspectionTitle,
  SectionContent,
  WarningIcon,
  WaypointsWrapper,
} from './VerificationForm.elements';

moment.locale('ru');

const {Field} = Card;
const {EmployeeSelect, MedicOwnerTypeSelect} = Selects;

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 15px;
`;

const TripMapViewer = ({geoJSON, distance, waypoints}) => (
  <FormTripMapWrapper>
    <TripMap geoJSON={geoJSON} waypoints={waypoints} />
    &nbsp;
    <p>Расчетный пробег: {convertFromMToKm(distance || 0).toFixed(2)} км.</p>
  </FormTripMapWrapper>
);

type FormProps = {
  // Путевой лист
  trip: $Shape<Trip>,
  // Функция для получения пробега из АвтоГрафа
  getKilometrage: () => Promise<?KilometrageInfo>,
  calculateRoute: Function,
  actualRouteGeometry: any,
  shiftEmployees: any,
  calculateWorkHoursFuelConsumption: (trip: Trip) => number,
  calculateDistanceFuelConsumption: (trip: Trip) => number,
  calculateFuelAtEnd: (trip: Trip) => number,
  calculateFuelConsumption: (trip: Trip) => Trip,
  onCancel: Function,
  onSubmit: Function,
  employeeId: number
};

const InnerForm = ({
  calculateRoute,
  actualRouteGeometry,
  calculateFuelConsumption,
  getKilometrage,
  onCancel,
  trip,
  onSubmit,
  employeeId,
  calculateWorkHoursFuelConsumption,
  calculateDistanceFuelConsumption,
  calculateFuelAtEnd,
}: FormProps) => {
  const [appliedKilometrageFromAutograf, setAppliedKilometrageFromAutograf,] = useState(null);
  const [startSelectedMedicalType, setStartMedicalType] = useState(medicOwnerEnum.hired,);
  const [endSelectedMedicalType, setEndMedicalType] = useState(medicOwnerEnum.hired,);
  const [kilometragePopconfirmVisible, setKilometragePopconfirmVisible,] = useState(false);
  const [monitoringDistance, setMonitoringDistance] = useState(0);
  const [monitoringMachineHours, setMonitoringMachineHours] = useState(0);
  const [sumFuelMultiplier, setSumFuelMultiplier] = useState(null);
  // Если есть точки маршрута предзаполняем даты мед/тех осмотров если не заполнены
  if (trip.actualRoute && !isEmpty(trip.actualRoute.waypoints)) {
    const date = (name: string, value: ?Date | ?string) => {
      trip[`${name}MedicCheckupDate`] = isNil(trip[`${name}MedicCheckupDate`])
        ? value
        : trip[`${name}MedicCheckupDate`];
      trip[`${name}TechCheckupDate`] = isNil(trip[`${name}TechCheckupDate`])
        ? value
        : trip[`${name}TechCheckupDate`];
      if (trip.isGpm) {
        trip[`${name}GpmControllerCheckupDate`] = isNil(
          trip[`${name}GpmControllerCheckupDate`],
        )
          ? value
          : trip[`${name}GpmControllerCheckupDate`];
      }
    };
    trip.actualRoute.waypoints.forEach(point => {
      if (point.type === waypointTypeEnum['start']) {
        date('start', point.departureDateTime);
      }
      if (point.type === waypointTypeEnum['end']) {
        date('end', point.arrivedDateTime);
      }
    });
  }

  const workAccountingType = trip?.vehicle?.vehicleModel?.workAccountingType;

  const isElectricVehicle = isVehicleTypeElectric(trip?.vehicle?.vehicleModel?.type);

  if(isElectricVehicle){
    trip.fuelAtStart = 0;
    trip.fuelAtEnd = 0;
    trip.distanceFuelConsumption = 0;
  }

  return (
    <Form
      initialValues={{
        ...trip,
        sumFuelMultiplier,
        kilometragePerTrip: null,
        workHoursPerTrip: null,
      }}
      onSubmit={values => onSubmit(values)}
    >
      {(FormField: FormFieldType, formikProps: FormikProps<Trip>) => {
        const {
          values,
          setFieldValue,
          handleBlur,
          handleSubmit,
          dirty,
          isSubmitting,
        } = formikProps;

        // устнававливаем даты мед/тех осмотров
        const setDate = (waypoints: WayPoint[]) => {
          const setCheckupDate = (name: string, value: ?Date | ?string) => {
            setFieldValue(`${name}MedicCheckupDate`, value);
            setFieldValue(`${name}TechCheckupDate`, value);
            if (values.isGpm) {
              setFieldValue(`${name}GpmControllerCheckupDate`, value);
            }
          };
          waypoints.forEach((point: WayPoint) => {
            if (point.type === waypointTypeEnum.start) {
              setCheckupDate(
                'start',
                isEmpty(point.departureDateTime)
                  ? null
                  : point.departureDateTime,
              );
            }
            if (point.type === waypointTypeEnum.end) {
              setCheckupDate(
                'end',
                isEmpty(point.arrivedDateTime) ? null : point.arrivedDateTime,
              );
            }
          });
        };

        const canVerify = () => {
          const {actualRoute} = values;
          // Если пробег при заезде в гараж не введен
          if (
            trip?.vehicle?.vehicleModel?.workAccountingType ===
            WorkAccountingTypes.kilometrage &&
            !values.odometerAtEnd
          ) {
            return false;
          }
          if (actualRoute) {
            const endWaypoint = actualRoute.waypoints.filter(
              waypoint => waypoint.type === waypointTypeEnum.end,
            )[0];
            // Откидываем UTC таймзону для проверки времени
            const ISOWithoutUTC = 'YYYY-MM-DDTHH:mm:ss.SSSS';
            return moment(
              formatDateTimeToString(endWaypoint.arrivedDateTime, ISOWithoutUTC),
            ).isBefore(
              formatDateTimeToString(new Date(), ISOWithoutUTC, false),
            );
          }
        };

        let allWaypoints = [];
        let startWaypoint: ?WayPoint = null;
        if (values && values.actualRoute && values.actualRoute.waypoints) {
          allWaypoints = values.actualRoute.waypoints;

          startWaypoint = allWaypoints.find(
            (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.start,
          );
        }
        const routeMinMaxDates = getMinMaxWaypointDateTime(values && values.actualRoute && values.actualRoute.waypoints);

        const distance = values.odometerAtEnd ? values.odometerAtEnd - values.odometerAtStart : 0;

        const canVerifyTrip = !canVerify();
        const canShowEngineHours = values.vehicle && values.vehicle?.vehicleModel &&
          canShowEngineWorkHoursData(values.vehicle?.vehicleModel?.type);
        const canShowAttachedEquipment = values.attachedEquipmentFuelConsumption > 0;
        return (
          <form>
            <Section>
              <SectionTitle divider>Транспортное средство</SectionTitle>
              <CarInfo>
                <Grid cols={4} media={[{size: 'lg', cols: 5}]}>
                  <GridItem>
                    <Field label="Тип ТС">
                      {vehicleTypes[values.vehicleType] ||
                        vehicleGroups[values.vehicleGroup]}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Марка ТС">
                      {values.vehicle &&
                        values.vehicle?.vehicleModel &&
                        `${values.vehicle?.vehicleModel?.brandName} ${values.vehicle?.vehicleModel?.name}`}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Государственный регистрационный знак ТС">
                      {values.vehicle && values.vehicle.licensePlate}
                    </Field>
                  </GridItem>
                  {values.vehicle && values.vehicle.orgUnitName && (
                    <GridItem>
                      <Field label="Собственник машины">
                        {values.vehicle.orgUnitName}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="ФИО водителя">
                      {values.driver &&
                        values.driver.employee &&
                        `${values.driver.employee.lastname} ${values.driver.employee.firstname} ${values.driver.employee.middlename}`}
                    </Field>
                  </GridItem>
                </Grid>
              </CarInfo>
              <SectionTitle>Гараж</SectionTitle>
              <SectionContent>
                <Grid cols={2} gutter="16px">
                  {!!startWaypoint && (
                    <GridItem fullWidth>
                      <Field label="Отправление">
                        {formatDateTimeToString(
                          startWaypoint.departureDateTime,
                          'D MMMM YYYY, HH:mm',
                        )}
                        , {startWaypoint.name}, {startWaypoint.address}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
              <Divider />
              {!!trip.tripAttachedEquipmentLinks?.length && (
                <>
                  <SectionTitle>Навесное оборудование</SectionTitle>
                  <SectionContent>
                    <Grid>
                      {trip.tripAttachedEquipmentLinks.map(
                        (equipment: TripAttachedEquipment, index) => (
                          <EquipmentRow
                            equipment={equipment}
                            onBlur={() => {
                              setFieldValue(
                                'fuelAtEnd',
                                calculateFuelAtEnd(values),
                              );
                            }}
                            Field={Field}
                            key={index}
                          />
                        ),
                      )}
                      <GridItem customStyles={{marginTop: '15px'}}>
                        <Field label="Время работы навесного оборудования ИТОГО, ч. мин">
                          <InputNumber
                            decimalSeparator=","
                            defaultValue={0}
                            disabled
                            value={
                              Math.round(
                                values.tripAttachedEquipmentLinks?.reduce(
                                  (sum, link) => sum + link.machineHours,
                                  0,
                                ) * 100,
                              ) / 100
                            }
                            style={{width: '200px'}}
                          />
                        </Field>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                  <Divider />
                </>
              )}
              <SectionTitle
                suffix={
                  <Button type="primary" onClick={() => calculateRoute(values)}>
                    Построить маршрут
                  </Button>
                }
              >
                Маршрут
              </SectionTitle>
              <WaypointsWrapper>
                <Grid>
                  <GridItem>
                    <Field label="Суммарное время движения">
                      {calculateDateRangeString(
                        routeMinMaxDates.min,
                        routeMinMaxDates.max,
                      )}
                    </Field>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField name="actualRoute.waypoints">
                      {({value: waypoints}) => (
                        <WaypointsForm
                          waypoints={allWaypoints}
                          editTypes={[
                            waypointTypeEnum.transit,
                            waypointTypeEnum.end,
                          ]}
                          onChange={(waypoints: WayPoint[]) => {
                            setDate(waypoints);
                            setFieldValue('actualRoute.waypoints', waypoints);
                          }}
                          disableDates={false}
                          appendRows={[
                            actualRouteGeometry && (
                              <TripMapViewer
                                geoJSON={actualRouteGeometry.geoJSON}
                                waypoints={waypoints}
                                distance={actualRouteGeometry.distance}
                              />
                            ),
                          ]}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </WaypointsWrapper>
              <Divider />
              <Container>
                {workAccountingType &&
                  (workAccountingType === WorkAccountingTypes.kilometrage || workAccountingType === WorkAccountingTypes.all) && (
                    <Popconfirm
                      visible={kilometragePopconfirmVisible}
                      title={
                        <p>
                          Получен пробег по ПЛ: &nbsp;
                          {!isNaN(monitoringDistance) &&
                            parseFloat(monitoringDistance)
                            .toFixed(2)
                            .toLocaleString()}
                          &nbsp; км. <br />
                          {parseFloat(values.odometerAtEnd) > 0 && (
                            <>
                              Текущий пробег при заезде в гараж: &nbsp;
                              {parseFloat(values.odometerAtEnd)
                              .toFixed(2)
                              .toLocaleString()}
                              &nbsp;км.
                              <br />
                            </>
                          )}
                          {canShowEngineHours && !isNaN(monitoringMachineHours) && (
                            <>
                              Получены маш. часы по ПЛ: &nbsp;
                              {parseFloat(monitoringMachineHours)
                              .toFixed(2)
                              .toLocaleString()}
                              &nbsp; ч. <br />
                              {parseFloat(values.vehicleMachineHoursAtEnd) > 0 && (
                                <>
                                  Текущий пробег при заезде в гараж&nbsp;
                                  {parseFloat(values.vehicleMachineHoursAtEnd)
                                  .toFixed(2)
                                  .toLocaleString()}
                                  &nbsp;ч.
                                  <br />
                                </>
                              )}
                            </>
                          )}
                          Вы хотите применить данные?
                        </p>
                      }
                      okText="Да"
                      cancelText="Нет"
                      onCancel={() => {
                        setKilometragePopconfirmVisible(false);
                        setFieldValue('odometerReaderEmployeeId', employeeId);
                        setAppliedKilometrageFromAutograf(false);
                      }}
                      onConfirm={() => {
                        const odometerAtEnd = parseFloat(values.odometerAtStart + monitoringDistance).toFixed(2);
                        const currentOdometerAtEnd = parseFloat(values.currentOdometerAtStart + monitoringDistance).toFixed(2);
                        let vehicleMachineHoursAtEnd = values.vehicleMachineHoursAtEnd;
                        let currentVehicleMachineHoursAtEnd = values.currentVehicleMachineHoursAtEnd;
                        if (canShowEngineHours) {
                          vehicleMachineHoursAtEnd = parseFloat(values.vehicleMachineHoursAtStart + monitoringMachineHours).toFixed(2);
                          currentVehicleMachineHoursAtEnd = parseFloat(values.currentVehicleMachineHoursAtStart + monitoringMachineHours).toFixed(2);
                          if (!isNaN(vehicleMachineHoursAtEnd)) {
                            setFieldValue('vehicleMachineHoursAtEnd', vehicleMachineHoursAtEnd);
                          }
                          if (!isNaN(currentVehicleMachineHoursAtEnd)) {
                            setFieldValue('currentVehicleMachineHoursAtEnd', currentVehicleMachineHoursAtEnd);
                          }
                        }
                        if (!isNaN(odometerAtEnd)) {
                          setFieldValue('odometerAtEnd', odometerAtEnd);
                        }
                        if (!isNaN(currentOdometerAtEnd)) {
                          setFieldValue('currentOdometerAtEnd', currentOdometerAtEnd);
                        }
                        if (!isNaN(vehicleMachineHoursAtEnd)) {
                          setFieldValue('vehicleMachineHoursAtEnd', vehicleMachineHoursAtEnd);
                        }
                        if (!isNaN(currentVehicleMachineHoursAtEnd)) {
                          setFieldValue('currentVehicleMachineHoursAtEnd', currentVehicleMachineHoursAtEnd);
                        }
                        const updatedTrip = calculateFuelConsumption({
                          ...values,
                          odometerAtEnd,
                          currentOdometerAtEnd,
                          vehicleMachineHoursAtEnd,
                          currentVehicleMachineHoursAtEnd,
                        });
                        const {engineWorkFuelConsumption, distanceFuelConsumption, fuelAtEnd,} = updatedTrip;
                        if (!isElectricVehicle) setFieldValue('distanceFuelConsumption', distanceFuelConsumption);
                        setAppliedKilometrageFromAutograf(true);
                        setFieldValue('engineWorkFuelConsumption', engineWorkFuelConsumption);
                        setFieldValue('fuelAtEnd', fuelAtEnd);
                        setKilometragePopconfirmVisible(false);
                      }}
                    >
                      <Popover content="Запросить пробег по GPS">
                        <StyledButton
                          type="primary"
                          onClick={async () => {
                            const kilometrage = await getKilometrage();
                            // Если пришел пробег
                            if (kilometrage) {
                              setMonitoringDistance(kilometrage.monitoringDistance);
                              setMonitoringMachineHours(kilometrage.monitoringMachineHours);
                              setKilometragePopconfirmVisible(true);
                              setFieldValue('odometerAtEnd', values.odometerAtStart + kilometrage.monitoringDistance);
                              setFieldValue('currentOdometerAtEnd', values.currentOdometerAtStart + kilometrage.monitoringDistance);
                              setFieldValue('kilometragePerTrip', kilometrage.monitoringDistance);
                              setFieldValue(
                                'vehicleMachineHoursAtEnd',
                                values.vehicleMachineHoursAtStart + kilometrage.monitoringMachineHours,
                              );
                              setFieldValue(
                                'currentVehicleMachineHoursAtEnd',
                                values.currentVehicleMachineHoursAtStart + kilometrage.monitoringMachineHours,
                              );
                              setFieldValue('workHoursPerTrip', kilometrage.monitoringMachineHours);
                            } else {
                              notification.warning({message: 'Нет данных о пробеге по GPS. Введите пробег при заезде в гараж'});
                              setAppliedKilometrageFromAutograf(false);
                              setFieldValue('odometerReaderEmployeeId', employeeId);
                            }
                          }}
                        >
                          Запросить пробег
                        </StyledButton>
                      </Popover>
                    </Popconfirm>
                  )
                }
                {workAccountingType &&
                  (workAccountingType === WorkAccountingTypes.kilometrage ||
                    workAccountingType === WorkAccountingTypes.all) && (
                    <>
                      <SectionTitle>Пробег</SectionTitle>
                      <SectionContent>
                        <Grid gutter="16px">
                          <GridItem>
                            <FormField label="При выезде из гаража" name="currentOdometerAtStart">
                              {({value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  disabled
                                  value={Math.round(value * 100) / 100}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField name="currentOdometerAtEnd" label="При заезде в гараж">
                              {({name, value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  name={name}
                                  value={Math.round(value * 100) / 100}
                                  disabled
                                  placeholder="Пробег при заезде в гараж"
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              name="odometerReaderEmployeeId"
                              label="Работник, снявший показания"
                              required={appliedKilometrageFromAutograf === false}
                            >
                              {({name, value}) => (
                                <EmployeeSelect
                                  onChange={(value: number) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() =>
                                    handleBlur({target: {name}})
                                  }
                                  value={value}
                                  placeholder="ФИО"
                                  disabled={
                                    appliedKilometrageFromAutograf !== false
                                  }
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Примечание"
                              required={appliedKilometrageFromAutograf === false}
                              name="monitoringNotation"
                              hasFeedback={false}
                            >
                              {field => (
                                <Input
                                  {...field}
                                  disabled={
                                    appliedKilometrageFromAutograf !== false
                                  }
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField label="Пробег за рейс" name="kilometragePerTrip" hasFeedback={false} required>
                              {({name, value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  name={name}
                                  value={value}
                                  min={0}
                                  disabled={appliedKilometrageFromAutograf !== false}
                                  onChange={value => {
                                    if (typeof value === 'string') {
                                      value = parseInt(value.split('.')[0], 10);
                                    }
                                    setFieldValue('odometerAtEnd', value + values.odometerAtStart);
                                    setFieldValue('currentOdometerAtEnd', value + values.currentOdometerAtStart);
                                    setFieldValue(name, value);
                                  }}
                                  onBlur={e => {
                                    if(!isElectricVehicle){
                                      const updatedTrip = calculateFuelConsumption(values);
                                      const {engineWorkFuelConsumption, distanceFuelConsumption, fuelAtEnd} = updatedTrip;
                                      if (!isElectricVehicle) setFieldValue('distanceFuelConsumption', distanceFuelConsumption);
                                      setFieldValue('engineWorkFuelConsumption', engineWorkFuelConsumption);
                                      setFieldValue('fuelAtEnd', fuelAtEnd);
                                    }
                                    handleBlur(e);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </SectionContent>
                    </>
                  )}
                {workAccountingType &&
                  (workAccountingType === WorkAccountingTypes.workHours ||
                    workAccountingType === WorkAccountingTypes.all) && (
                    <>
                      <Divider />
                      <SectionTitle>Маш. часы</SectionTitle>
                      <SectionContent>
                        <Grid cols={3} gutter="16px">
                          <GridItem>
                            <FormField name="currentVehicleMachineHoursAtStart" label="При выезде из гаража">
                              {({name, value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  disabled
                                  name={name}
                                  onBlur={handleBlur}
                                  value={Math.round(value * 100) / 100}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField label="При заезде в гараж" name="currentVehicleMachineHoursAtEnd">
                              {({name, value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  name={name}
                                  disabled
                                  value={Math.round(value * 100) / 100}
                                  placeholder="Показания счетчика маш. часов при заезде в гараж"
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Количество машино-часов за рейс"
                              name="workHoursPerTrip"
                              required
                              hasFeedback={false}
                            >
                              {({name, value}) => (
                                <InputNumber
                                  decimalSeparator=","
                                  name={name}
                                  value={value}
                                  min={0}
                                  disabled={
                                    workAccountingType === WorkAccountingTypes.all && appliedKilometrageFromAutograf !==
                                    false
                                  }
                                  onChange={value => {
                                    if (typeof value === 'string') {
                                      value = parseInt(value.split('.')[0], 10);
                                    }
                                    setFieldValue('vehicleMachineHoursAtEnd', value + values.vehicleMachineHoursAtStart);
                                    setFieldValue('currentVehicleMachineHoursAtEnd', value + values.currentVehicleMachineHoursAtStart);
                                    setFieldValue(name, value);
                                  }}
                                  onBlur={e => {
                                    const {fuelAtEnd, engineWorkFuelConsumption} = calculateFuelConsumption(values);
                                    setFieldValue('fuelAtEnd', fuelAtEnd);
                                    setFieldValue('engineWorkFuelConsumption', engineWorkFuelConsumption);
                                    handleBlur(e);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </SectionContent>
                    </>
                  )}
              </Container>
              {canShowAttachedEquipment && (
                <>
                  <Divider />
                  <SectionTitle>Дополнительное оборудование часы</SectionTitle>
                  <SectionContent>
                    <Grid cols={3}>
                      <GridItem>
                        <FormField
                          label="Работа доп. оборудования"
                          required
                          name="attachedEquipmentMachineHours"
                        >
                          {({name, value}) => (
                            <InputNumber
                              decimalSeparator=","
                              name={name}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                              value={value}
                              onBlur={e => {
                                const {
                                  fuelAtEnd,
                                  engineWorkFuelConsumption,
                                } = calculateFuelConsumption(values);
                                setFieldValue('fuelAtEnd', fuelAtEnd);
                                setFieldValue(
                                  'engineWorkFuelConsumption',
                                  engineWorkFuelConsumption,
                                );
                                handleBlur(e);
                              }}
                              placeholder="Введите показания работы доп. оборудования"
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </>
              )}
              <Divider />
              <SectionTitle>Расход ГСМ</SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  {values.vehicleId && !isElectricVehicle && (
                    <GridItem fullWidth>
                      <Field>
                        <TripRangeForm
                          vehicleId={values.vehicleId}
                          tripId={values.id}
                          onChange={(
                            sumFuelMultiplier: ?number,
                            tripRanges: TripRange[],
                          ) => {
                            // Записывам итоговый коэффициент в трип
                            setSumFuelMultiplier(sumFuelMultiplier);
                            setFieldValue(
                              'sumFuelMultiplier',
                              sumFuelMultiplier,
                            );
                            setFieldValue('tripRanges', tripRanges);
                            const {
                              fuelAtEnd,
                              distanceFuelConsumption,
                            } = calculateFuelConsumption({
                              ...values,
                              tripRanges,
                              sumFuelMultiplier,
                            });
                            setFieldValue(
                              'distanceFuelConsumption',
                              distanceFuelConsumption,
                            );
                            setFieldValue('fuelAtEnd', fuelAtEnd);
                          }}
                          distance={distance}
                          tripStartDate={values.startDate}
                        />
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField
                      name="fuelAtStart"
                      label={isElectricVehicle ? "Уровень % зарядки при выезде" : "Уровень топлива при выезде, л"}
                      defaultValue={0}
                    >
                      {({name, value}) => (
                        <InputNumber
                          min={isElectricVehicle ? 0 : undefined}
                          max={isElectricVehicle ? 100 : undefined}
                          decimalSeparator=","
                          disabled={!isElectricVehicle}
                          onBlur={handleBlur}
                          name={name}
                          onChange={value => setFieldValue(name, value)}
                          value={isElectricVehicle ? value || 0 : (value && (Math.round(value * 100) / 100))}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {!isElectricVehicle && (
                    <GridItem>
                      <FormField
                        label="Выдано топлива, л"
                        required
                        name="issuedFuel"
                        defaultValue={0}
                      >
                        {({name, value}) => (
                          <InputNumber
                            decimalSeparator=","
                            value={value}
                            name={name}
                            min={0}
                            onChange={value => setFieldValue(name, value)}
                            onBlur={e => {
                              const {fuelAtEnd} = calculateFuelConsumption(
                                values,
                              );
                              setFieldValue('fuelAtEnd', fuelAtEnd);
                              handleBlur(e);
                            }}
                            placeholder="Выдано топлива, л"
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField
                      name="fuelAtEnd"
                      label={isElectricVehicle ? "Уровень % зарядки при заезде" : "Уровень топлива при заезде, л"}
                      required={!isElectricVehicle}
                      validate={(value: string) => {
                        if(isElectricVehicle) return;
                        const fuelAtEnd = parseFloat(value);
                        if (fuelAtEnd < 0 || !fuelAtEnd) {
                          return "Неверное количество топлива";
                        }
                      }}
                    >
                      {({name, value}) => (
                        <InputNumber
                          min={isElectricVehicle ? 0 : undefined}
                          max={isElectricVehicle ? 100 : undefined}
                          decimalSeparator=","
                          disabled={!isElectricVehicle}
                          onChange={value => setFieldValue(name, value)}
                          value={isElectricVehicle ? value || 0: (value && (Math.round(value * 100) / 100))}
                          name={name}
                          placeholder={!isElectricVehicle ? "Показания ГСМ при заезде в гараж" : ''}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="distanceFuelConsumption"
                      label={isElectricVehicle ? "Фактический расход зарядки, %" : "Фактический расход топлива (общий), л"}
                    >
                      {({name, value}) => (
                        <InputNumber
                          min={isElectricVehicle ? 0 : undefined}
                          decimalSeparator=","
                          disabled={!isElectricVehicle}
                          onChange={value => setFieldValue(name, value)}
                          name={name}
                          value={isElectricVehicle ? value || 0 : 
                            Math.round(
                              (values.tripAttachedEquipmentLinks?.reduce(
                                  (sum, link) => {
                                    return (
                                      sum +
                                      link.machineHours *
                                      link.attachedEquipment.fuelConsumption
                                    );
                                  },
                                  0,
                                ) +
                                calculateWorkHoursFuelConsumption(values) +
                                calculateDistanceFuelConsumption(values)) *
                              100,
                            ) / 100
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
              <Divider />
              <SectionTitle>Иное</SectionTitle>
              <SectionContent>
                <Grid cols={2} gutter="16px">
                  <GridItem fullWidth>
                    <FormField
                      label="На сторону"
                      name="toOtherSide"
                      defaultValue={yesNoValue.no}
                    >
                      {({name, value}) => (
                        <YesNoSelect
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({target: {name}})}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
              <Divider />

              <SectionTitle>Медосмотр</SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <InspectionGridItem>
                    <InspectionTitle>Предрейсовый медосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField label="Вид мед. работника">
                          {({value}) => (
                            <MedicOwnerTypeSelect
                              value={startSelectedMedicalType}
                              onChange={value => {
                                setStartMedicalType(value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {startSelectedMedicalType === medicOwnerEnum.own ? (
                          <FormField
                            label="Мед. работник"
                            required
                            name="startMedicId"
                            fast
                          >
                            {({name, value}) => (
                              <EmployeeSelect
                                placeholder="ФИО"
                                onChange={(value: string) =>
                                  setFieldValue(name, value)
                                }
                                name={name}
                                value={value}
                                onBlur={() => handleBlur({target: {name}})}
                                filter={{
                                  positions: [positionEnum.medic],
                                }}
                              />
                            )}
                          </FormField>
                        ) : (
                          <FormField
                            label="Мед. работник"
                            name="startMedicFullName"
                          >
                            {({name, value}) => (
                              <Input
                                name={name}
                                value={value}
                                onChange={e =>
                                  setFieldValue(name, e.target.value)
                                }
                              />
                            )}
                          </FormField>
                        )}
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="startMedicCheckupDate"
                        >
                          {({name, value}) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY',
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                  <InspectionGridItem>
                    <InspectionTitle>Послерейсовый медосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField label="Вид мед. работника">
                          {({value}) => (
                            <MedicOwnerTypeSelect
                              value={endSelectedMedicalType}
                              onChange={value => {
                                setEndMedicalType(value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {endSelectedMedicalType === medicOwnerEnum.own ? (
                          <FormField
                            label="Мед. работник"
                            required
                            fast
                            name="endMedicId"
                          >
                            {({name, value}) => (
                              <EmployeeSelect
                                placeholder="ФИО"
                                onChange={(value: string) =>
                                  setFieldValue(name, value)
                                }
                                name={name}
                                value={value}
                                filter={{
                                  positions: [positionEnum.medic],
                                }}
                              />
                            )}
                          </FormField>
                        ) : (
                          <FormField
                            label="Мед. работник"
                            name="endMedicFullName"
                          >
                            {({name, value}) => (
                              <Input
                                name={name}
                                value={value}
                                onChange={e =>
                                  setFieldValue(name, e.target.value)
                                }
                              />
                            )}
                          </FormField>
                        )}
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="endMedicCheckupDate"
                        >
                          {({name, value}) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY',
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                </Grid>
              </SectionContent>
              <Divider />
              <SectionTitle>
                Контроль технического состояния машины
              </SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <InspectionGridItem>
                    <InspectionTitle>Предрейсовый техосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField
                          label="Механик"
                          required
                          name="startMechanicId"
                          fast
                        >
                          {({name, value}) => (
                            <EmployeeSelect
                              placeholder="ФИО"
                              onChange={(value: string) =>
                                setFieldValue(name, value)
                              }
                              onBlur={() => handleBlur({target: {name}})}
                              name={name}
                              value={value}
                              filter={{
                                positions: [positionEnum.mechanic],
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="startTechCheckupDate"
                        >
                          {({name, value}) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY',
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                  <InspectionGridItem>
                    <InspectionTitle>Послерейсовый техосмотр</InspectionTitle>
                    <Grid cols={1}>
                      <GridItem>
                        <FormField
                          label="Механик"
                          required
                          name="endMechanicId"
                          fast
                        >
                          {({name, value}) => (
                            <EmployeeSelect
                              placeholder="ФИО"
                              onChange={(value: string) =>
                                setFieldValue(name, value)
                              }
                              onBlur={() => handleBlur({target: {name}})}
                              name={name}
                              value={value}
                              filter={{
                                positions: [positionEnum.mechanic],
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Состояние ТС"
                          defaultValue={true}
                          name="isExploitableVehicle"
                        >
                          {({name, value}) => (
                            <Switch
                              checkedChildren="Исправен"
                              unCheckedChildren="Неисправен"
                              defaultChecked
                              name={name}
                              onChange={(checked: boolean) =>
                                setFieldValue(name, checked)
                              }
                              checked={value}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата"
                          required
                          name="endTechCheckupDate"
                        >
                          {({name, value}) => (
                            <DatePicker
                              format="D MMMM YYYY"
                              value={value ? moment.utc(value) : value}
                              name={name}
                              onChange={(value: Object, dateString: string) => {
                                const dateTime = formatDateTimeToISOString(
                                  value,
                                  dateString,
                                  'D MMMM YYYY',
                                );
                                setFieldValue(name, dateTime);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </InspectionGridItem>
                </Grid>
              </SectionContent>
              {values.isGpm && (
                <>
                  <Divider />
                  <SectionTitle>Контроль ГПМ</SectionTitle>
                  <SectionContent>
                    <Grid gutter="16px">
                      <InspectionGridItem>
                        <InspectionTitle>Предрейсовый осмотр</InspectionTitle>
                        <Grid cols={1}>
                          <GridItem>
                            <FormField
                              label="Контролёр ГПМ"
                              required
                              fast
                              name="startGpmControllerId"
                            >
                              {({name, value}) => (
                                <EmployeeSelect
                                  placeholder="ФИО"
                                  onChange={(value: string) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() =>
                                    handleBlur({target: {name}})
                                  }
                                  name={name}
                                  value={value}
                                  filter={{
                                    positions: [positionEnum.gpmController],
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Дата"
                              required
                              name="startGpmControllerCheckupDate"
                            >
                              {({name, value}) => (
                                <DatePicker
                                  format="D MMMM YYYY"
                                  value={value ? moment.utc(value) : value}
                                  onChange={(
                                    value: Object,
                                    dateString: string,
                                  ) => {
                                    const dateTime = formatDateTimeToISOString(
                                      value,
                                      dateString,
                                      'D MMMM YYYY',
                                    );
                                    setFieldValue(name, dateTime);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </InspectionGridItem>
                      <InspectionGridItem>
                        <InspectionTitle>
                          Послерейсовый техосмотр
                        </InspectionTitle>
                        <Grid cols={1}>
                          <GridItem>
                            <FormField
                              label="Контролёр ГПМ"
                              required
                              fast
                              name="endGpmControllerId"
                            >
                              {({name, value}) => (
                                <EmployeeSelect
                                  placeholder="ФИО"
                                  onChange={(value: string) =>
                                    setFieldValue(name, value)
                                  }
                                  onBlur={() =>
                                    handleBlur({target: {name}})
                                  }
                                  name={name}
                                  value={value}
                                  filter={{
                                    positions: [positionEnum.gpmController],
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="Дата"
                              required
                              name="endGpmControllerCheckupDate"
                            >
                              {({name, value}) => (
                                <DatePicker
                                  format="D MMMM YYYY"
                                  value={value ? moment.utc(value) : value}
                                  onChange={(
                                    value: Object,
                                    dateString: string,
                                  ) => {
                                    const dateTime = formatDateTimeToISOString(
                                      value,
                                      dateString,
                                      'D MMMM YYYY',
                                    );
                                    setFieldValue(name, dateTime);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </InspectionGridItem>
                    </Grid>
                  </SectionContent>
                </>
              )}
            </Section>
            <FormFooter>
              <ButtonWrapper>
                <Button
                  disabled={canVerifyTrip || isSubmitting}
                  loading={isSubmitting}
                  onClick={handleSubmit}
                  type="primary"
                >
                  Таксировать
                </Button>
                {canVerifyTrip && (
                  <Popover
                    width={200}
                    content={
                      !values.odometerAtEnd
                        ? 'Нельзя таксировать путевой лист без показаний пробега при заезде в гараж'
                        : 'Нельзя таксировать путевой лист раньше времени окончания маршрутного задания.'
                    }
                  >
                    <WarningIcon type="warning" />
                  </Popover>
                )}
              </ButtonWrapper>
              <CancelButton dirty={dirty} type="plain" onClick={onCancel}>
                Отменить
              </CancelButton>
            </FormFooter>
          </form>
        );
      }}
    </Form>
  );
};

export default InnerForm;
