// @flow
import React from 'react';
import { InputProps } from 'antd/lib/input';

import MaskInput from '../MaskInput';

/**
 * Маска СНИЛС
 */
export default (props: InputProps) => (
  <MaskInput
    mask="999-999-999 99"
    placeholder="000-000-000 00"
    convertOnSave={(value: string) => value.replace(/[\s-]+/g, '')}
    {...props}
  />
);
