// @flow
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import type { FormikProps } from 'formik';
import type { Moment } from 'moment';
import * as yup from 'yup';

import notification from 'antd/lib/notification';
import { CancelButton, Card, Form, OrgUnitSelect, Selects } from '../../../components';
import { Section, SectionTitle } from '../../../components/layout';
import { LicensePlateInput, STSInput } from '../../../components/masked-inputs';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';
import VehicleSubCategorySelect from '../../../components/selects/VehicleSubCategorySelect';
import { employeeApi, mdmApi } from '../../../lib/api';
import { formatDateTimeToISOString, isVehicleTractor, isVehicleTypeElectric } from '../../../lib/helpers';
import type { Vehicle } from '../../../lib/types';
import { licensePlateValidate, osagoValidate, stsValidate } from '../../../lib/validation';
import { canShowDriverData, canShowGpmData, canShowOsagoData, canShowWithTrailer, getDisabledDate } from '../lib';
import Grid, { GridItem } from './../../../components/layout/Grid';
import VehicleDriverSelect from './VehicleDriverSelect';

const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const {Field} = Card;

// Required Asterisk Issue Workaround
const RequiredFormFieldFix = styled.div`
  ${({ required }) => !required && css`
    .ant-form-item .ant-form-item-required::after {
      display: none;
    }
  `}
`;

const {
  PlaceSelect,
  RegistrationPlaceSelect,
  VehicleModelSelect,
  LocationSelect,
  YearIssueSelect,
  YesNoSelect,
  StouSelect,
  STSTypeSelect,
  EmployeeSelect,
  DriverLicenseCategorySelect,
  LeasersSelect,
} = Selects;

type Props = {
  vehicle: Vehicle,
  onSubmit: Function,
  onCancel: Function,
  changeDate: Function,
  formatDate: Function,
  employeeBranchOrgUnitId: number,
  sectionStyle?: any,
  hideFields?: string[],
  employeeError: boolean,
  removeEmployeeError: () => void,
  canEditVehicleWorkMeter: boolean,
};

export default ({
  onSubmit,
  vehicle,
  onCancel,
  changeDate,
  formatDate,
  sectionStyle = {},
  hideFields = [],
  employeeBranchOrgUnitId,
  employeeError,
  removeEmployeeError,
  canEditVehicleWorkMeter,
}: Props) => {
  const [personNumberError, setPersonNumberError] = useState(false);
  const [personNumber, setPersonNumber] = useState<number | null>(null);
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const [vehicleModelType, setVehicleModelType] = useState();
  useEffect(() => {
    // данная проверка нужна, потому как после открытия уже существующей карточки ТС, если открыть форму создания
    // новой карточки ТС, на короткий срок в пропсах приходит значение пропса vehicle из ранее открытой карточки.
    // т.е., это определенно костыль, но пока так.
    if (vehicle && /vehicles\/new$/.test(window.location.href)) {
      return;
    }
    setEmployeeId(vehicle?.financiallyResponsibleEmployeeId || null);
  }, [vehicle]);
  useEffect(() => {
    if (employeeId) {
      (async function() {
        try {
          const employee = await employeeApi.fetchEmployee(employeeId);
          if (!!employee.personNumber) {
            setPersonNumber(employee.personNumber);
            setPersonNumberError(false);
          } else {
            setPersonNumber(null);
            setPersonNumberError(true);
          }
        } catch (e) {
          setPersonNumber(null);
        }
      })()
    } else {
      setPersonNumber(null);
    }
  }, [employeeId]);

    return (
    <Form
      onSubmit={values => {
        if (personNumberError || !personNumber) {
          notification.error({
            message: 'Ошибка',
            description: `Необходимо назначить материально ответственное лицо с
              заполненным в справочнике «Сотрудники» табельным номером`,
            duration: 10,
          });
          return;
        }
        onSubmit(values);
      }}
      initialValues={vehicle}
      validationSchema={yup.object().shape({
        licensePlate: licensePlateValidate.nullable(),
        'osago.osagoNumber': yup
        .string()
        .when('vehicleModel.type', (vehicleType, schema) =>
          canShowOsagoData(vehicleType) ? osagoValidate.nullable() : schema,
        ),
        sts: stsValidate.nullable(),
      })}
    >
      {(FormField, formikProps: FormikProps) => {
        let {
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          handleFocus,
          values,
          isSubmitting,
          dirty,
        } = formikProps;

        const isElectricVehicle = isVehicleTypeElectric(vehicleModelType || vehicle?.vehicleModel?.type);
        
        return (
          <form onSubmit={handleSubmit}>
            <Section style={sectionStyle}>
              <SectionTitle>Первичные данные</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem fullWidth={!values.isLease}>
                  <FormField label="Является арендованным ТС"
                             name="isLease"
                             hasFeedback={false}
                             defaultValue={false}>
                    {({name, value}) => (
                      <YesNoSelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.isLease 
                  ? (<GridItem>
                      <FormField label="Арендодатель"
                                name="leaser"
                                hasFeedback={false}
                                defaultValue={undefined}
                                required>
                        {({name, value}) => (
                          <LeasersSelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                          />
                        )}
                      </FormField>
                    </GridItem>) 
                  : null
                }
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField fast label="Модель ТС" name="vehicleModelId" required>
                    {({value, name}) => (
                      <VehicleModelSelect
                        value={value}
                        name={name}
                        onFocus={handleFocus}
                        data-cy="modelSelect"
                        onChange={async (value: number, option: Object) => {
                          setVehicleModelType(option.props.vehicleModel.type)
                          setFieldValue(name, value);
                          if (option) {
                            setFieldValue(
                              'vehicleModel',
                              option.props.vehicleModel,
                            );
                          } else {
                            setFieldValue('vehicleModel', null);
                          }
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Год выпуска" name="yearIssued" required>
                    {({value, name}) => (
                      <YearIssueSelect
                        id="yearIssued"
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={() => handleBlur({target: {name}})}
                        data-cy="yearIssued"
                      />
                    )}
                  </FormField>
                </GridItem>
              </StyledGrid>
              <SectionTitle>Номера</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem fullWidth>
                  <FormField label="Нерегистрируемое" name="unregistered" defaultValue={false}>
                    {({value, name}) => (
                      <YesNoSelect
                        value={value}
                        name={name}
                        onChange={(value: boolean) =>
                          setFieldValue(name, value)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Гос. номер" name="licensePlate" required={!values.unregistered}>
                    {({value, name}) => (
                      <LicensePlateInput
                        name={name}
                        value={value}
                        vehicleType={
                          values.vehicleModel && values.vehicleModel.type
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="VIN" name="vin" required={!values.serialNumber && !values.unregistered}>
                    {({value, name}) => (
                      <Input
                        name={name}
                        placeholder="1B6HL26Y0RW143905"
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={17}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Серийный номер" name="serialNumber" required={!values.unregistered && !values.vin}>
                    {field => (
                      <Input {...field} placeholder="Введите серийный номер" />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Инвентарный номер" name="inventoryNumber" required={values.isLease}>
                    {({value, name}) => (
                      <Input
                        placeholder="Введите инвентарный номер"
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canShowGpmData(values.vehicleModel && values.vehicleModel.type) && (
                  <>
                    <GridItem>
                      <FormField
                        label="Регистрационный номер подъемного сооружения"
                        required={!values.unregistered}
                        name="gpmRegistrationNumber"
                      >
                        {field => <Input {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Заводской номер ГПМ" name="gpmFactoryNumber" required={!values.unregistered}>
                        {field => <Input {...field} />}
                      </FormField>
                    </GridItem>
                  </>
                )}
                {!hideFields.includes('stsType') && (
                  <GridItem>
                    <FormField label={values.isLease ? "Тип АТС" : "Тип СТС"} name="stsType" required={!values.unregistered}>
                      {({value, name}) => (
                        <STSTypeSelect
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField label={values.isLease ? "Серия и номер АТС" : "Серия и номер СТС"} name="sts" required={!values.unregistered}>
                    {({value, name}) => (
                      <STSInput
                        name={name}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label={values.isLease ? "Дата выдачи АТС" : "Дата выдачи СТС"} name="stsDate" required={!values.unregistered}>
                    {({value, name}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="stsDate"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: Moment) =>
                          getDisabledDate(currentDate)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Серия и номер ПТС" name="pts" required={!values.unregistered}>
                    {({value, name}) => (
                      <Input
                        value={value}
                        name={name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={15}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Дата выдачи ПТС" name="ptsDate" required={!values.unregistered}>
                    {({value, name}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        data-cy="ptsDate"
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(name, changeDate(value, dateString));
                        }}
                        disabledDate={(currentDate: Moment) =>
                          getDisabledDate(currentDate)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цвет кузова кабины" name="cabColor" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem style={{overflow: 'hidden'}}>
                  <RequiredFormFieldFix required={!values.isLease}>
                    <FormField label="Мнемокод ТС по СТОУ" name="mnemonicStou" required={!values.isLease} fast>
                      {({value, name}) => (
                        <StouSelect
                          value={value}
                          name={name}
                          onChange={(value: boolean, option: any) => {
                            setFieldValue(name, value);
                            if (option) {
                              setFieldValue('typeStou', option.props.stou.name);
                            }
                          }}
                        />
                      )}
                    </FormField>
                  </RequiredFormFieldFix>
                </GridItem>
                <GridItem>
                  <FormField label="Тип ТС по СТОУ" name="typeStou">
                    {fieldProps => <Input disabled {...fieldProps} />}
                  </FormField>
                </GridItem>
                {!values.isLease && (
                <>
                  <GridItem>
                    <FormField fast label="Префикс накладной" name="otherDocumentKind" required>
                      {fieldProps => <Input {...fieldProps} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField fast label="Номер накладной" name="otherDocumentSerialNumber" required>
                      {fieldProps => <Input {...fieldProps} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField fast label="Дата накладной" name="otherDocumentIssuedDate" required>
                      {({value, name}) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={formatDate(value)}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(name, changeDate(value, dateString));
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField fast label="Мнемокод номенклатуры" name="nomenclatureNumber" required>
                      {({name, value}) => (
                        <AutocompleteSelect
                          fetch={mdmApi.fetchMdmList}
                          fetchSingle={() => null}
                          notFoundText="Код не найден"
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          renderOption={(item, Option) => (
                            <Option key={item.mnemocode} value={item.mnemocode}>
                              {item.mnemocode}
                            </Option>
                          )}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </>
                )}
              </StyledGrid>
              {canShowWithTrailer(values.vehicleModel && values.vehicleModel.type) && (
                <>
                  <SectionTitle>Признаки ТС</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField label="ТС с прицепом" name="withTrailer">
                        {({value, name}) => (
                          <YesNoSelect
                            value={value}
                            name={name}
                            onChange={(value: boolean) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </StyledGrid>
                </>
              )}
              <SectionTitle>Прикрепление</SectionTitle>
              <StyledGrid gutter="16px">
                {!hideFields.includes('stsType') && (
                  <GridItem style={{overflow: 'hidden'}}>
                    <FormField fast label="Служба закрепления/РЭС" required name="orgUnitId">
                      {({value, name}) => (
                        <OrgUnitSelect
                          value={`${value || ''}`}
                          onChange={(value: number) => {
                            const id = parseInt(value, 10);
                            setFieldValue(name, id);
                          }}
                          data-cy="orgUnitSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {!hideFields.includes('stsType') && (
                  <GridItem style={{overflow: 'hidden'}}>
                    <FormField fast label="Служба использования/РЭС" required name="usingNodeId">
                      {({value, name}) => (
                        <OrgUnitSelect
                          value={`${value || ''}`}
                          onChange={(value: number) => {
                            const id = parseInt(value, 10);
                            setFieldValue(name, id);
                          }}
                          data-cy="orgUnitSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField fast label="Населенный пункт базирования ТС" required name="locationId">
                    {({value, name}) => (
                      <LocationSelect
                        placeholder="Выберите место стоянки"
                        onChange={(value: string) => setFieldValue(name, value)}
                        onFocus={handleFocus}
                        geolocationApi={false}
                        name={name}
                        filter={{
                          isDefault: true,
                          nodeId: employeeBranchOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                        value={value}
                        data-cy="locationSelect"
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.vehicleModel && canShowDriverData(values.vehicleModel.type) && (
                    <GridItem style={{overflow: 'hidden'}}>
                      <FormField fast name="driverId" label="Водитель" required>
                        {({value, name}) => (
                          <VehicleDriverSelect
                            onChange={(value: string) =>
                              setFieldValue(name, value)
                            }
                            vehicle={values}
                            value={value}
                            name={name}
                            data-cy="driverSelect"
                            filter={{
                              type: (!isElectricVehicle && !values.isLease) ? "Driver" : undefined,
                              'employee.nodeId': employeeBranchOrgUnitId,
                              nodeFilterType: 'branchAndChildren',
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                <GridItem>
                  <FormField fast label="Место нахождения ТС" name="parusRegistrationPlaceId" required>
                    {({value, name}) => (
                      <PlaceSelect
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        filter={{
                          nodeId: employeeBranchOrgUnitId,
                          nodeFilterType: 'branchAndChildren',
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField fast label="Место регистрации ТС" name="registrationPlaceId" required>
                    {({value, name}) => (
                      <RegistrationPlaceSelect
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField
                    label="Материально ответственное лицо"
                    name="financiallyResponsibleEmployeeId"
                    required
                    validateStatus={employeeError ? 'error' : undefined}
                  >
                    {({value, name}) => (
                      <EmployeeSelect
                        placeholder="Выберите сотрудника"
                        onChange={(value: string) => {
                          removeEmployeeError();
                          setFieldValue(name, value);
                          setEmployeeId(value || null);
                        }}
                        onFocus={handleFocus}
                        name={name}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem style={{overflow: 'hidden'}}>
                  <Field
                    label="Табельный номер сотрудника"
                    labelStyle={{
                      color: personNumberError ? '#f5222d' : '#3d4042',
                    }}
                  >
                    <Input
                      disabled
                      value={personNumber || '-'}
                      style={
                        personNumberError
                          ? {border: '1px solid #f5222d'}
                          : undefined
                      }
                    />
                  </Field>
                </GridItem>
              </StyledGrid>

              {values?.vehicleModel?.osagoNeeded &&
                canShowOsagoData(values.vehicleModel && values.vehicleModel.type) && (
                  <>
                    <SectionTitle>ОСАГО</SectionTitle>
                    <StyledGrid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Категория ТС для ОСАГО"
                          required
                          name="osagoCategory"
                        >
                          {({value, name}) => (
                            <DriverLicenseCategorySelect
                              showAllCategories
                              name={name}
                              value={value}
                              onChange={(id: number) => setFieldValue(name, id)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </StyledGrid>
                  </>
                )}

              <SectionTitle>Пробег с начала эксплуатации ТС</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField fast label="Текущий пробег, км" defaultValue={0} name="kilometrage">
                    {({value, name}) => (
                      <InputNumber
                        min={0}
                        decimalSeparator=","
                        step={1}
                        name={name}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(name, value ? value : 0)
                          if (!values.id) {
                            setFieldValue('kilometrageByOdometr', value ? value : 0)
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                {!isElectricVehicle && (
                  <GridItem>
                    <FormField
                      label="Показания счётчика моточасов, ч"
                      name="engineWorkHours"
                      defaultValue={0}
                      required={isVehicleTractor(values)}
                    >
                      {({value, name}) => (
                        <InputNumber
                          min={0}
                          decimalSeparator=","
                          step={1}
                          value={value}
                          name={name}
                          onChange={(value: number) => {
                            setFieldValue(name, value ? value : 0)
                            if (!values.id) {
                              setFieldValue('engineWorkHoursByMeter', value ? value : 0);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>

              {values.id && (
                <>
                  <SectionTitle>Пробег по прибору учета</SectionTitle>
                  <StyledGrid gutter="16px">
                    <GridItem>
                      <FormField fast label="Пробег по прибору учёта, км" defaultValue={0} name="kilometrageByOdometr">
                        {({value, name}) => (
                          <InputNumber
                            min={0}
                            decimalSeparator=","
                            step={1}
                            name={name}
                            value={value}
                            onChange={(value: number) => setFieldValue(name, value ? value : 0)}
                            onBlur={handleBlur}
                            disabled={!canEditVehicleWorkMeter}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {!isElectricVehicle && (
                      <GridItem>
                        <FormField label="Показания счётчика моточасов, ч" name="engineWorkHoursByMeter" defaultValue={0}>
                          {({value, name}) => (
                            <InputNumber
                              min={0}
                              decimalSeparator=","
                              step={1}
                              value={value}
                              name={name}
                              onChange={(value: number) => setFieldValue(name, value ? value : 0)}
                              onBlur={handleBlur}
                              disabled={!canEditVehicleWorkMeter}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                  </StyledGrid>
                </>
              )}

              <SectionTitle>Бюджет</SectionTitle>
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Планируемая дата приобретения"
                    name="plannedPurchaseDate"
                  >
                    {({value, name}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Планируемая дата списания"
                    name="plannedWriteoffDate"
                  >
                    {({value, name}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={formatDate(value)}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.vehicleModel && (
                  <GridItem>
                    <FormField
                      label="Подкатегория ТС"
                      name="vehicleSubCategoryId"
                    >
                      {({value, name}) => (
                        <VehicleSubCategorySelect
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          filter={{vehicleCategoryId: values.vehicleModel.categoryId}}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </StyledGrid>
            </Section>
            <Footer>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                data-cy="save"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
