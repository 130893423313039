// @flow
import React from 'react';
import styled from 'styled-components';

import type {
  Vehicle,
  VehicleModel,
  VehicleStatus as VehicleStatusType
} from '../../../../lib/types';
import { navigate } from '../../../../lib/helpers';
import { vehicleTypes } from '../../../../lib/enum';
import { Section } from '../../../../components/layout/index';
import { VehicleStatus } from '../../../../components';
import { Icon, ListTable } from '../../../../components/ui';
import SectionTitle from '../SectionTitle';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Placeholder = styled.div`
  text-align: center;
  padding: 16px;
  color: #838d96;
`;

type Props = {
  vehicle: Vehicle,
  editable: boolean
};

const TrailersTab = ({ vehicle, editable }: Props) => (
  <Section>
    <SectionTitle
      divider
      suffix={
        editable && (
          <StyledIcon
            onClick={() => navigate(`/vehicles/${vehicle.id}/edit/trailers`)}
            type="edit"
            size={16}
          />
        )
      }
    >
      Прицепы
    </SectionTitle>
    <SectionContent>
      {vehicle.trailers && vehicle.trailers.length ? (
        <ListTable
          columns={[
            {
              title: 'Статус',
              key: 'status',
              render: (status: VehicleStatusType, record: any) => (
                <VehicleStatus status={status} />
              )
            },
            {
              title: 'Гос. номер',
              key: 'licensePlate'
            },
            {
              title: 'Год выпуска',
              key: 'yearIssued'
            },
            {
              title: 'Модель',
              key: 'vehicleModel',
              render: (vehicleModel: ?VehicleModel): ?string =>
                vehicleModel &&
                `${vehicleModel.brandName} ${vehicleModel.name}, ${
                  vehicleTypes[vehicleModel.type]
                }`
            },
            {
              title: 'Подразделение',
              key: 'orgUnitName'
            }
          ]}
          data={vehicle.trailers}
        />
      ) : (
        <Placeholder>Отсутствуют прикрепленные прицепы</Placeholder>
      )}
    </SectionContent>
  </Section>
);

export default TrailersTab;
