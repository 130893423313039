// @flow
import React from 'react';

import { calculationStatusEnum } from '../../../../lib/enum';
import type { VehiclePlan } from '../../../../lib/types';
import { getLabel } from '../Budget/components/Tab';

import { TabItem, Tabs } from './../../../../components/ui';

type Props = {
  vehiclePlanId: ?number,
  vehiclePlan?: VehiclePlan,
  showAdditional?: boolean
};

export default ({ vehiclePlanId, vehiclePlan, showAdditional }: Props) => {
  let activeUrl = null;
  let activeOperationBudgetUrl = null;
  let activeUrlRepair = null;

  if (vehiclePlanId) {
    activeUrl = [
      `/budget/vehicle/${vehiclePlanId}/measuring-device-certifications`,
      `/budget/vehicle/${vehiclePlanId}/license-plate-change`,
      `/budget/vehicle/${vehiclePlanId}/driver-inspection`,
      `/budget/vehicle/${vehiclePlanId}/inspections-gibdd`,
      `/budget/vehicle/${vehiclePlanId}/inspections-gtn`,
      `/budget/vehicle/${vehiclePlanId}/federal-highway`,
      `/budget/vehicle/${vehiclePlanId}/gpm-maintenance`,
      `/budget/vehicle/${vehiclePlanId}/spring-pass`,
      `/budget/vehicle/${vehiclePlanId}/cargo-pass`,
      `/budget/vehicle/${vehiclePlanId}/batteries`,
      `/budget/vehicle/${vehiclePlanId}/material`,
      `/budget/vehicle/${vehiclePlanId}/washing`,
      `/budget/vehicle/${vehiclePlanId}/pass`,
      `/budget/vehicle/${vehiclePlanId}/gps`
    ];
    activeOperationBudgetUrl = [
      `/budget/vehicle/${vehiclePlanId}/fuels-and-oils`,
      `/budget/vehicle/${vehiclePlanId}/mechanisms`,
      `/budget/vehicle/${vehiclePlanId}/tire`,
      `/budget/vehicle/${vehiclePlanId}/maintenance`
    ];
    activeUrlRepair = [
      `/budget/vehicle/${vehiclePlanId}/current-repair`,
      `/budget/vehicle/${vehiclePlanId}/overhaul-repair`,
      `/budget/vehicle/${vehiclePlanId}/budget-repair`
    ];
  }

  return (
    <Tabs withRouter>
      {!!vehiclePlanId && (
        <TabItem
          label="Перечень CТС"
          url={`/budget/vehicle/${vehiclePlanId}/card`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeUrl}
          label={getLabel('Независящие от режима эксплуатации ТС', [
            vehiclePlan?.batteryCalculationStatus,
            vehiclePlan?.gpmMaintenanceCalculationStatus,
            vehiclePlan?.vehicleMonitoringCalculationStatus,
            vehiclePlan?.measuringDeviceCertificationCalculationStatus,
            vehiclePlan?.inspectionGtnCalculationStatus,
            vehiclePlan?.inspectionGibddCalculationStatus,
            vehiclePlan?.licensePlateChangeCalculationStatus,
            vehiclePlan?.materialCalculationStatus,
            vehiclePlan?.washingCalculationStatus,
            vehiclePlan?.driverInspectionCalculationStatus,
            vehiclePlan?.federalHighwayCalculationStatus,
            vehiclePlan?.springPassCalculationStatus,
            vehiclePlan?.cargoPassCalculationStatus
          ])}
          url={`/budget/vehicle/${vehiclePlanId}/batteries`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeOperationBudgetUrl}
          label={getLabel('Зависящие от режима эксплуатации ТС', [
            vehiclePlan?.fuelAndOilCalculationStatus,
            vehiclePlan?.fuelAndOilMechanismCalculationStatus,
            vehiclePlan?.tireCalculationStatus,
            vehiclePlan?.maintenanceOperationContractCalculationStatus
          ])}
          url={`/budget/vehicle/${vehiclePlanId}/fuels-and-oils`}
        />
      )}
      {!!vehiclePlanId && (
        <TabItem
          activeUrl={activeUrlRepair}
          label={getLabel('Ремонт', [
            vehiclePlan?.currentRepairCalculationStatus,
            vehiclePlan?.overhaulCalculationStatus
          ])}
          url={`/budget/vehicle/${vehiclePlanId}/current-repair`}
        />
      )}
      {!!vehiclePlanId && showAdditional && (
        <TabItem
          label={getLabel('Доп. потребности', [
            vehiclePlan?.additionalCalculationStatus
          ])}
          url={`/budget/vehicle/${vehiclePlanId}/additional`}
        />
      )}
      {!!vehiclePlanId &&
        vehiclePlan?.budgetStatus === calculationStatusEnum.calculationDone && (
          <TabItem
            label={getLabel('Бюджет', [vehiclePlan?.budgetStatus])}
            url={`/budget/vehicle/${vehiclePlanId}/itog`}
          />
        )}
    </Tabs>
  );
};
