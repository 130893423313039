// @flow

import React from 'react';
import moment from 'moment';

import {
  Card,
  Filter,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer
} from './../../../components';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import { SectionContent } from '../../../components/hoc/common/components/elements';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import Datepicker from 'antd/lib/date-picker';
const { Field } = Card;

export type GenerelFilterParams = {
  startDate?: string,
  endDate?: string
};

type Props = {
  filter: GenerelFilterParams,
  applyFilter: Function,
  cleanFilter: Function
};

export const GenerelFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<GenerelFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата начала"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(value, dateString) || undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата окончания"
                  value={values.endDate ? moment(values.endDate) : undefined}
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(value, dateString) || undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default GenerelFilter;
