// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { driverApi } from '../../lib/api';
import type { Employee, EmployeeFilter } from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';
import styled from 'styled-components';
import { convertDriverToString } from '../../lib/helpers';
import Popover from '../ui/Popover';
import { OptionContent, OptionLeft } from './components/Option';
import Icon from 'antd/lib/icon';

const OptionName = styled.p.attrs(() => ({
  className: 'name'
}))`
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
`;
const Select = styled(AutocompleteSelect)`
  .ant-select-selection-selected-value .orgUnit,
  .ant-select-selection__choice__content .orgUnit {
    display: none;
  }
  .ant-select-selection-selected-value .name,
  .ant-select-selection__choice__content .name {
    max-width: 100%;
  }
  .optionContent {
    height: initial;
  }
`;

type Props = SelectProps & {
  filter?: EmployeeFilter,
  showLink?: boolean,
  warning?: (employee: Employee) => string,
  disabledEmployees?: (employee: Employee) => boolean
};

export default class extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return nextProps.value !== this.props.value;
  }

  render() {
    const {
      filter = {},
      disabledEmployees,
      warning,
      ...selectProps
    } = this.props;
    return (
      <Select
        fetch={async params => {
          return await driverApi.fetchDrivers({
            ...filter,
            ...params
          });
        }}
        placeholder="Выберите сотрудника"
        refetchParams={{ filter }}
        fetchSingle={driverApi.fetchDriver}
        notFoundText="Сотрудники не найдены"
        renderOption={(employee: Employee, Option) => {
          const warningText = warning && warning(employee);
          return (
            <Option
              disabled={disabledEmployees && disabledEmployees(employee)}
              key={employee.id}
              value={employee.id}
              employee={employee}
            >
              <OptionContent>
                <OptionLeft>
                  <OptionName>
                    {convertDriverToString(employee)}
                    {warningText && (
                      <Popover width={150} zIndex={3000} content={warningText}>
                        <Icon type="warning" style={{ color: '#eccd0e' }} />
                      </Popover>
                    )}
                  </OptionName>
                </OptionLeft>
              </OptionContent>
            </Option>
          );
        }}
        {...selectProps}
      />
    );
  }
}
