// @flow
import React, { useState, useCallback } from 'react';

import { navigate } from '../../lib/helpers';
import { registrationPlaceApi } from '../../lib/api';
import { type RegistrationPlace } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {
  type CommonFormProps
} from '../../components/hoc/common/handbook/CommonForm';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

import Grid, { GridItem } from '../../components/layout/Grid';
import { FormField } from '../../components/Form';
import { StyledInput } from '../../components/hoc/common/components/elements';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/registration-place/';

const InnerForm = (props: CommonFormProps<$Shape<RegistrationPlace>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label={`Наименование`} fast required name="name">
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: RegistrationPlace, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await registrationPlaceApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: RegistrationPlace) => {
    let id = payload.id;
    if (id) {
      await registrationPlaceApi.update(payload);
    } else {
      await registrationPlaceApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async (payload: RegistrationPlace) => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Место регистрации ТС'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
