// @flow
import type {
  CalculationStatus,
  GpmMaintenanceCalculation,
  GpmMaintenanceCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  gpmMaintenanceCalculation: GpmMaintenanceCalculation
): GpmMaintenanceCalculation => ({
  ...gpmMaintenanceCalculation,
  sum: convertFromRubToKop(gpmMaintenanceCalculation.sum),
  months: gpmMaintenanceCalculation.months.map(
    convertDataToServerGpmMaintenanceCalculationMonth
  ),
  expertiseCost: convertFromRubToKop(gpmMaintenanceCalculation.expertiseCost),
  expertiseSum: convertFromRubToKop(gpmMaintenanceCalculation.expertiseSum),
  maintenanceCost: convertFromRubToKop(
    gpmMaintenanceCalculation.maintenanceCost
  ),
  maintenanceSum: convertFromRubToKop(gpmMaintenanceCalculation.maintenanceSum)
});

export const convertDataFromServer = (
  gpmMaintenanceCalculation: GpmMaintenanceCalculation
): GpmMaintenanceCalculation => ({
  ...gpmMaintenanceCalculation,
  sum: convertFromKopToRub(gpmMaintenanceCalculation.sum),
  months: gpmMaintenanceCalculation.months.map(
    convertDataFromServerGpmMaintenanceCalculationMonth
  ),
  expertiseCost: convertFromKopToRub(gpmMaintenanceCalculation.expertiseCost),
  expertiseSum: convertFromKopToRub(gpmMaintenanceCalculation.expertiseSum),
  maintenanceCost: convertFromKopToRub(
    gpmMaintenanceCalculation.maintenanceCost
  ),
  maintenanceSum: convertFromKopToRub(gpmMaintenanceCalculation.maintenanceSum)
});

export const convertDataToServerGpmMaintenanceCalculationMonth = (
  gpmMaintenanceCalculationMonth: GpmMaintenanceCalculationMonth
): GpmMaintenanceCalculationMonth => ({
  ...gpmMaintenanceCalculationMonth,
  maintenanceCost: convertFromRubToKop(
    gpmMaintenanceCalculationMonth.maintenanceCost
  ),
  expertiseCost: convertFromRubToKop(
    gpmMaintenanceCalculationMonth.expertiseCost
  ),
  cost: convertFromRubToKop(gpmMaintenanceCalculationMonth.cost)
});

export const convertDataFromServerGpmMaintenanceCalculationMonth = (
  gpmMaintenanceCalculationMonth: GpmMaintenanceCalculationMonth
): GpmMaintenanceCalculationMonth => ({
  ...gpmMaintenanceCalculationMonth,
  maintenanceCost: convertFromKopToRub(
    gpmMaintenanceCalculationMonth.maintenanceCost
  ),
  expertiseCost: convertFromKopToRub(
    gpmMaintenanceCalculationMonth.expertiseCost
  ),
  cost: convertFromKopToRub(gpmMaintenanceCalculationMonth.cost)
});

const basedUrl = 'gpmMaintenanceCalculation';
const gpmMaintenanceCalculation = new CommonApi<GpmMaintenanceCalculation>(
  basedUrl,
  'расчет стоимости экспертизы ТО и ГПМ',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async monthData => {
  const updated = await fetchRequest.put(
    `/GpmMaintenanceCalculation/correct`,
    convertDataToServerGpmMaintenanceCalculationMonth(monthData)
  );
  if (!updated) {
    throw new Error(`Не удалось сменить статус расчет`);
  }
};

export default {
  ...gpmMaintenanceCalculation,
  calculate,
  changeStatus,
  print,
  updateMonth
};
