// @flow
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { Card } from '../../components';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';

import { cognosLogApi } from '../../lib/api';

import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';
import { cognosLogStatus } from '../../lib/enum';
import type { CognosLog, Place, UserAccess } from '../../lib/types';

import { withUserAccess } from './../withUserAccess';
import { Link } from '@reach/router';

const { Field } = Card;

type InnerProps = {
  data: CognosLog
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/cognos-log/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px" cols={4}>
        <GridItem>
          <Field label="Версия бюджета">
            {props.data.branchBudgetSummary?.budgetSummary.budgetVersion.name}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Год">
            {moment(
              props.data.branchBudgetSummary?.budgetSummary.startDate
            ).year()}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Наименование бюджета">
            <Link
              to={`/budget/summary/${props.data.branchBudgetSummary?.budgetSummaryId}/${props.data.branchBudgetSummary?.orgUnitId}/${props.data.branchBudgetSummary?.type}`}
            >
              {props.data.branchBudgetSummary?.name}
            </Link>
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Филиал">{props.data.orgUnitName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Статус">{cognosLogStatus[props.data.status]}</Field>
        </GridItem>
        <GridItem fullWidth>
          <Field label="Информация об ошибке">{props.data.description}</Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: Place, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const access = {};

  const onFetch = useCallback(async () => {
    setData(await cognosLogApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Отправка в ИСУ Бюджетирование',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
