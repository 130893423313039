// @flow
import CommonApi from './../commonApi';
import type {ModelMatches} from '../types';

const basedUrl = 'VehicleModelMatch';
const typesOfRepairs = new CommonApi<ModelMatches>(basedUrl, 'Модели для сопоставления');

export default {
  ...typesOfRepairs,
};
