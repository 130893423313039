// @flow
import React, { useState } from 'react';
import moment from 'moment';
import Button, { ButtonProps } from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import { Bold } from '../../elements';
import InputNumber from 'antd/lib/input-number';
import Icon from 'antd/lib/icon';

type Props = {
  onClick?: Function
} & ButtonProps;

export default ({ onClick, disabled, ...props }: Props) => {
  const [year, setYear] = useState(moment.utc().year());

  return disabled ? (
    <Button type="primary" disabled {...props}>
      <Icon type="warning" />
      Создать годовой план
    </Button>
  ) : (
    <Popconfirm
      overlayStyle={{ width: 150 }}
      onConfirm={() => onClick && onClick(year)}
      title={
        <>
          <Bold style={{ marginBottom: 16 }}>Укажите год</Bold>
          <InputNumber
            value={year}
            onChange={(year: number) => setYear(year)}
            min={2018}
          />
        </>
      }
    >
      <Button type="primary" {...props}>
        Создать годовой план
      </Button>
    </Popconfirm>
  );
};
