/* commonApi.js | API */
// @flow
import type { FetchListParams, ListResponse } from './api';
import { fetchRequest, initialFetchListParams } from './api';

export type CommonApiMethod = {
  add: Function,
  update: Function,
  delete: Function,
  get: Function,
  fetch: Function
};

/**
 * Общий класс для выполнения оперций CRUD
 * @param {string} url Строка запроса
 * @param {string} name Наименование сущностей для вывода ошибок
 * @param {Function} convertDataToServer Функция конфертации данных на сервер
 * @param {Function} convertDataFromServer Функция конфертации данных c сервера
 */
export default class CommonApi<T, F = {}, Fetch = T> {
  url = '';
  name = '';
  convertDataToServer = undefined;
  convertDataFromServer = undefined;

  constructor(
    url: string,
    name: string,
    convertDataToServer?: Function,
    convertDataFromServer?: Function
  ): CommonApiMethod {
    this.url = url;
    this.name = name;
    this.convertDataToServer = convertDataToServer;
    this.convertDataFromServer = convertDataFromServer;
    return {
      add: this.add,
      update: this.update,
      delete: this.delete,
      get: this.get,
      fetch: this.fetch
    };
  }

  add = async (data: T): Promise<T> => {
    const added = await fetchRequest.post(
      `/${this.url}`,
      this.convertDataToServer ? this.convertDataToServer(data) : data
    );
    if (added)
      return this.convertDataFromServer
        ? this.convertDataFromServer(added)
        : added;
    throw new Error(`Не удалось создать ${this.name}`);
  };

  update = async (data: T): Promise<T> => {
    const updated = await fetchRequest.put(
      `/${this.url}`,
      this.convertDataToServer ? this.convertDataToServer(data) : data
    );
    if (updated)
      return this.convertDataFromServer
        ? this.convertDataFromServer(updated)
        : updated;
    throw new Error(`Не удалось обновить ${this.name}`);
  };

  delete = async (id: number): Promise<T> => {
    const deleted = await fetchRequest.delete(`/${this.url}/${id}`);
    if (deleted)
      return this.convertDataFromServer
        ? this.convertDataFromServer(deleted)
        : deleted;
    throw new Error(`Не удалось удалить ${this.name}`);
  };

  get = async (id: number, params: F): Promise<T> => {
    const data = await fetchRequest.get(`/${this.url}/${id}`, params);
    if (data)
      return this.convertDataFromServer
        ? this.convertDataFromServer(data)
        : data;
    throw new Error(`Не удалось получить ${this.name}`);
  };

  fetch = async (
    params: FetchListParams<F> = initialFetchListParams
  ): Promise<ListResponse<Fetch>> => {
    const findData = await fetchRequest.get(`/${this.url}`, {
      ...initialFetchListParams,
      ...params
    });
    if (findData) {
      return this.convertDataFromServer
        ? { ...findData, data: findData.data.map(this.convertDataFromServer) }
        : findData;
    }
    throw new Error(`Не удалось получить ${this.name}`);
  };
}
