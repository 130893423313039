// @flow
import React, { Component } from 'react';
import { vehicleMonitoringCalculationApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';
import Common from './components/Common';
import type { VehicleMonitoringCalculation } from '../../../../lib/types';
import { COLUMNS, MONTH } from '../lib';
import { toLocalStringRu } from '../../../../lib/helpers';
import { VehiclePlan } from '../../../../lib/types';
import { calculationStatusEnum } from '../../../../lib/enum';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';
import moment from 'moment/moment';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = {
  monthData: ?VehicleMonitoringCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

export default class extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };
  
  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }

  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null
    });
  };

  onCell = (monthData, monthIndex) => ({
    onClick: () => {
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (monthData.id && vehiclePlan?.vehicleMonitoringCalculationStatus === calculationStatusEnum.draft &&
          openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({ monthData, monthIndex });
      }
    }
  });

  columns = [
    ...COLUMNS,
    ...MONTH.map((month, index) => ({
      title: month.title,
      width: '150',
      dataIndex: `months[${index}].cost`,
      render: (cost: number) =>
        toLocalStringRu(cost, { minimumFractionDigits: 2 }),
      onCell: monthData => this.onCell(monthData, index)
    })),
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, { minimumFractionDigits: 2 })
    }
  ];

  render() {
    const { location, vehiclePlanId } = this.props;
    const { monthData, monthIndex } = this.state;
    return (
      <Common
        columns={this.columns}
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={vehicleMonitoringCalculationApi}
        title={'Обслуживание GPS'}
        calcScrollY={430}
        statusField={'vehicleMonitoringCalculationStatus'}
        handlePrint={filter => {
          printNotification(async () => {
            await vehicleMonitoringCalculationApi.print(vehiclePlanId, filter);
          });
        }}
        editingModalData={{
          monthData,
          monthIndex,
          handleCancel: this.handleCancel,
          setVehiclePlan: vehiclePlan => this.setState({ vehiclePlan })
        }}
        linksToDictionaries={{
          'Стоимость GPS': '/admin/gps-subscription-service-cost/'
        }}
      />
    );
  }
}
