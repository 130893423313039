// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import {ModelMatchesApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import Popconfirm from 'antd/lib/popconfirm';
import {Button} from 'antd';
import type {ModelMatches} from '../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const ControlButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;
const {Field} = Card;

type Props = {
  id: string,
};

type State = {
  modelMatches: ?ModelMatches
};

class ModelMatchesCard extends Component<Props, State> {
  state = {
    modelMatches: {},
  };
  
  async componentDidMount() {
    const {id} = this.props;
    if (id) {
      try {
        const modelMatches = await ModelMatchesApi.get(id);
        this.setState({modelMatches});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message || error.title,
        });
      }
    }
  }
  
  deleteRecord = async () => {
    try {
      await ModelMatchesApi.delete(this.props.id);
      navigate('/admin/model-matches/', true);
      notification.success({
        message: 'Запись удалена',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  render() {
    const {modelMatches} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/model-matches/')}>
                Модели для сопоставления
              </Crumb>
              <Crumb>Модель №{modelMatches?.id || ''}</Crumb>
            </Breadcrumbs>
          }
          right={
            <ControlButtonsBlock>
              <Button
                onClick={() => navigate(`/admin/model-matches/${modelMatches.id}/edit`)}
                style={{marginRight: '10px'}}
                type="primary"
              >
                Редактировать
              </Button>
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={this.deleteRecord}
              >
                <Button>Удалить</Button>
              </Popconfirm>
            </ControlButtonsBlock>
          }
        />
        <StyledPanel>
          <h1>{`Модель №${modelMatches?.id || ''}`}</h1>
        </StyledPanel>
        <Section>
          {modelMatches.id && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Марка">{modelMatches.brandName}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Модель">
                    {modelMatches.modelName}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default ModelMatchesCard;
