import React, { useState } from 'react';
import Button from 'antd/lib/button';
import { Popover } from 'antd';
import styled from 'styled-components';

import { fetchRequest } from '../../../lib/api';

const String = styled.p`
  margin: 0;
  color: black;
  line-height: 15px;

  &:nth-child(3) {
    margin-bottom: 15px;
  }
`;

const Title = styled.b`
  margin: 8px;
  font-size: 16px;
  color: #d21a3e;
`;

export default ({ onSubmit, tripId }) => {
  const [showPopover, setShowPopover] = useState(false);
  const [dependentOrders, setDependensOrders] = useState([]);
  const content = (
    <>
      <String>
        Обнаружено следующие количество зависимых ПЛ:{' '}
        <span style={{ fontWeight: '600' }}>{dependentOrders.length}</span>.
      </String>
      <String>Значение полей в зависимых ПЛ будет пересчитано.</String>
      <String>Продолжить?</String>
      <Button
        style={{ marginRight: '15px' }}
        onClick={() => {
          onSubmit();
          setShowPopover(false);
        }}
      >
        Да
      </Button>
      <Button onClick={() => setShowPopover(false)}>Нет</Button>
    </>
  );
  return (
    <Popover
      title={<Title>Внимание!</Title>}
      content={content}
      trigger="click"
      visible={showPopover}
      onVisibleChange={visible => setShowPopover(visible)}
    >
      <Button
        type="primary"
        onClick={async () => {
          setDependensOrders(
            await fetchRequest.get(`/trip/${tripId}/getdependettrips`)
          );
        }}
        style={{ marginRight: '20px' }}
      >
        Сохранить
      </Button>
    </Popover>
  );
};
