import isEmpty from 'lodash/isEmpty';

import type {OperationLimitGroupStatus} from '../../../lib/types/operationLimitGroup';
import type {DailyPlanning} from '../../../lib/types/contractVehicleDailyBudget';
import type {UserAccess} from '../../../lib/types';
import {accessTypeEnum, vehicleTypes} from '../../../lib/enum';
import React from 'react';

export const getStatus = (
  budgets: DailyPlanning[],
  type: 'monthStatus' | 'yearStatus',
): ?OperationLimitGroupStatus => {
  const budget = budgets.filter(budget => !isEmpty(budget.dateTimes))[0];
  if (budget) {
    const dateTime = budget.dateTimes[Object.keys(budget.dateTimes)[0]];
    return dateTime && dateTime[type];
  }
  return null;
};

/**
 * Проверка на возможность просмотра годового плана пользователем
 * @param userAccess Пользовательские доступы
 */
export const showYearPlan = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBranchYearLimitsPlan,
      accessTypeEnum.viewingYearLimitsPlan,
    ].includes(access),
  );
};

/**
 * Проверка на возможность просмотра месячного плана пользователем
 * @param userAccess Пользовательские доступы
 */
export const showMonthPlan = (userAccess: UserAccess[]) => {
  return userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.viewingBranchMonthLimitsPlan,
      accessTypeEnum.viewingMonthLimitsPlan,
    ].includes(access),
  );
};

export const columns = [
  {
    header: {
      title: 'ТС',
    },
    cell: {
      keyPath: 'vehicle.licensePlate',
      formatValue: (licensePlate: string, row: any) => (
        <p>
          {licensePlate} <br />
          {vehicleTypes[row.vehicle.type]}
        </p>
      ),
    },
    width: 180,
  },
  {
    header: {
      title: 'Группа',
    },
    cell: {
      keyPath: 'groupName',
    },
    width: 115,
  },
  {
    header: {
      title: 'Закрепление',
    },
    columns: [
      {
        header: {
          title: 'Служба',
        },
        cell: {
          keyPath: 'timeLimit.orgUnitName',
          height: 50,
        },
        width: 210,
      },
      {
        header: {
          title: 'Назначение',
        },
        cell: {
          keyPath: 'timeLimit.targetName',
          formatValue: (value: any, rowValue: DailyPlanning) =>
            rowValue.timeLimit.type !== 'orgUnit' && value,
        },
        width: 175,
      },
    ],
  },
  {
    header: {
      title: 'Регламент',
    },
    columns: [
      {
        header: {
          title: 'Будние',
        },
        columns: [
          {
            header: {
              title: 'Часы',
            },
            cell: {
              keyPath: 'timeLimit.hours',
            },
            width: 60,
          },
          {
            header: {
              title: 'Пробег',
            },
            cell: {
              keyPath: 'timeLimit.distance',
            },
            width: 60,
          },
        ],
      },
      {
        header: {
          title: 'Выходные',
        },
        columns: [
          {
            header: {title: 'Часы'},
            cell: {keyPath: 'timeLimit.hoursWeekend'},
            width: 60,
          },
          {
            header: {
              title: 'Пробег',
            },
            cell: {
              keyPath: 'timeLimit.distanceWeekend',
            },
            width: 60,
          },
        ],
      },
    ],
  },
];

export const totalValuesColumn = {
  header: {title: 'Итого за месяц'},
  columns: [
    {
      header: {title: 'Часы'},
      cell: {keyPath: 'totalHours'},
      width: 60,
    },
    {
      header: {title: 'Пробег'},
      cell: {keyPath: 'totalDistance'},
      width: 60,
    },
    {
      header: {title: 'Сумма затрат'},
      cell: {keyPath: 'totalSum'},
      width: 60,
    },
  ],
};
