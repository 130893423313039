// @flow
import Menu from 'antd/lib/menu';
import React, {Component} from 'react';
import qs from 'query-string';
import moment from 'moment';

import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';
import Icon from 'antd/lib/icon';

import type {EntityStatusType, ListState, UserAccess} from '../../../../../lib/types';
import {entityStatus, entityStatusEnum} from '../../../../../lib/enum';
import {formatDateTimeToString, getListInitialState, navigate} from '../../../../../lib/helpers';
import {regulationApi} from '../../../../../lib/api';

import {Header, Section} from './../../../../../components/layout';
import {notificationLoading} from '../../../../../components/Notifications';
import {Dropdown} from './../../../../../components/ui';
import Table from './../../../../../components/ui/Table';

import {withUserAccess} from './../../../../withUserAccess';

import type {WorkPlansFilterParams} from '../components';
import {FilterWorkPlans, MainTabs} from './../components';

import {addAccessRightWorkPlans} from '../accessRight';
import {connect} from 'react-redux';
import type {AppState} from '../../../../../ducks/redux';
import {setFilter} from '../../../../../ducks/persistFilters';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
  persistFilters: any,
  setFilter: Function,
};
type State = ListState<any> & {
  filter: WorkPlansFilterParams,
  columns: any[],
  filtersPath: string,
};

class RegistrBudgetList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: '№',
        dataIndex: 'id'
      },
      {
        title: 'Филиал',
        dataIndex: 'orgUnitName'
      },
      {
        title: 'Дата формирования',
        dataIndex: 'created',
        width: '150',
        render: (created: string) =>
          `${formatDateTimeToString(created, 'DD.MM.YYYY')}`
      },
      {
        title: 'Версия регламента',
        dataIndex: 'budgetVersion.name'
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        render: (status: EntityStatusType) =>
          status ? entityStatus[status] : '-'
      },
      {
        title: 'Примечание',
        dataIndex: 'notation'
      }
    ],
    filtersPath: window.location.pathname,
  };

  componentDidMount() {
    const {page} = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.setState(({ columns }) => ({
        columns: [
          ...columns,
          {
            stopPropagation: true,
            title: '',
            dataIndex: 'id',
            key: 'operations',
            render: (id: number, record) => {
              return (
                <Dropdown
                  key={id}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={async () => {
                          try {
                            const regulation = await regulationApi.clone(
                              record.id
                            );
                            navigate(
                              `/budget/contract-vehicle/work-plans/${regulation.id}/card`
                            );
                          } catch {
                            notification.warning({
                              message: `Не удалось клонировать запись №${record.id}`
                            });
                          }
                        }}
                      >
                        Копировать перечень
                      </Menu.Item>
                      {record.status === entityStatusEnum.created && (
                        <Menu.Item>
                          <Popconfirm
                            overlayStyle={{
                              zIndex: 2000
                            }}
                            placement="left"
                            title={<>Вы действительно хотите удалить?</>}
                            onConfirm={() => {
                              this.onDelete(id);
                            }}
                          >
                            Удалить
                          </Popconfirm>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Icon
                    style={{ fontSize: 16, color: '#2770FF' }}
                    type="ellipsis"
                  />
                </Dropdown>
              );
            }
          }
        ]
      }));
    }
    this.setState(
      {
        filter: this.props.persistFilters[this.state.filtersPath] || {},
        page
      },
      () => this.fetchWorkPlans(page)
    );
  }

  onDelete = async (id: number) => {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление записи...',
        key: 'delete'
      });
      await regulationApi.delete(id);
      this.fetchWorkPlans(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('delete');
    }
  };

  fetchWorkPlans = async (page: number = 1) => {
    const { filter } = this.state;
    try {
      const data = await regulationApi.fetch({
        page,
        // $FlowFixMe
        ...filter,
        nodeId: filter.orgUnitId,
        created: [
          filter.startDate
            ? `>${moment(filter.startDate)
                .subtract(1, 'day')
                .toISOString()}`
            : undefined,
          filter.endDate ? `<${filter.endDate}` : undefined
        ]
      });
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  applyFilter = (filter: WorkPlansFilterParams) => {
    const { page } = this.state;
    this.props.setFilter({path: this.state.filtersPath, values: filter});
    this.setState({ filter, loading: true }, () => this.fetchWorkPlans(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.props.setFilter({path: this.state.filtersPath, values: {}});
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchWorkPlans(page)
    );
  };

  canAdd = () =>
    this.props.userAccess.some(access =>
      addAccessRightWorkPlans.includes(access)
    );

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filter,
      columns
    } = this.state;
    return (
      <>
        <Header
          left={<h1>Закрепленные НТС</h1>}
          right={
            this.canAdd() && (
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/budget/contract-vehicle/work-plans/new`)
                }
              >
                Создать
              </Button>
            )
          }
        />
        <MainTabs />
        <Section>
          <FilterWorkPlans
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            columns={columns}
            data={data}
            fetch={this.fetchWorkPlans}
            fetchOnMount
            onRow={(row: any) => ({
              onClick: () =>
                navigate(`/budget/contract-vehicle/work-plans/${row.id}/card`)
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters,
  }),
  {setFilter}
)(withUserAccess(RegistrBudgetList));
