// @flow
import React, { useState, useCallback } from 'react';

import { placeApi } from '../../lib/api';
import type { UserAccess, Place } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { withUserAccess } from './../withUserAccess';

import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';

const { Field } = Card;

type InnerProps = {
  data: Place
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/place/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Наименование филиала">{props.data.orgUnitName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Rn">{props.data.parusId}</Field>
        </GridItem>
        <GridItem>
          <Field label="Краткое наименование объекта">
            {props.data.shortName}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Полное наименование объекта">
            {props.data.fullName}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: Place, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const access = {};

  const onFetch = useCallback(async () => {
    setData(await placeApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Местоположения',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
