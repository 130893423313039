// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportTaxedTripElectrics,
  ReportTaxedTripsElectricsParams
} from '../types/reportTaxedTripsElectrics';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportTaxedTripElectrics) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportTaxedTripsElectrics = async (
  params: ReportTaxedTripsElectricsParams
): Promise<ReportTaxedTripElectrics[]> => {
  const response = await fetchRequest.get('/report/taxed-trips/electric', {
    ...params
  });
  return {
    data: response.data.map(convertFromServer),
    totalCount: response.totalCount
  };
};

export const printReportTaxedTripsElectrics = async (
  params: ReportTaxedTripsElectricsParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/taxed-trips/electric/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportTaxedTripsElectrics,
  printReportTaxedTripsElectrics
};
