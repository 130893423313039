// @flow
import type {
  CalculationStatus,
  DriverInspectionCalculation,
  DriverInspectionCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  driverInspectionCalculation: DriverInspectionCalculation
): DriverInspectionCalculation => ({
  ...driverInspectionCalculation,
  sum: convertFromRubToKop(driverInspectionCalculation.sum),
  months: driverInspectionCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  driverInspectionCalculation: DriverInspectionCalculation
): DriverInspectionCalculation => ({
  ...driverInspectionCalculation,
  sum: convertFromKopToRub(driverInspectionCalculation.sum),
  months: driverInspectionCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  driverInspectionCalculationMonth: DriverInspectionCalculationMonth
): DriverInspectionCalculationMonth => ({
  ...driverInspectionCalculationMonth,
  cost: convertFromRubToKop(driverInspectionCalculationMonth.cost),
  price: convertFromRubToKop(driverInspectionCalculationMonth.price)
});

export const convertDataFromServerMonth = (
  driverInspectionCalculationMonth: DriverInspectionCalculationMonth
): DriverInspectionCalculationMonth => ({
  ...driverInspectionCalculationMonth,
  cost: convertFromKopToRub(driverInspectionCalculationMonth.cost),
  price: convertFromKopToRub(driverInspectionCalculationMonth.price)
});

const basedUrl = 'driverInspectionCalculation';
const driverInspectionCalculation = new CommonApi<DriverInspectionCalculation>(
  basedUrl,
  'расчет потребности в предрейсовых/послерейсовых осмотрах водителей',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async monthData => {
  const updated = await fetchRequest.put(
    `/DriverInspectionCalculation/correct`,
    convertDataToServerMonth(monthData)
  );
  if (!updated) {
    throw new Error(`Не удалось сменить статус расчет`);
  }
};

export default {
  ...driverInspectionCalculation,
  calculate,
  changeStatus,
  print,
  updateMonth
};
