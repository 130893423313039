// @flow
import React from 'react';
import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {ContractorBranchLinkApi} from '../../lib/api';

const entryPointPath = '/admin/securing-contractors/';

const SecuringContractors = () => {
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30
    },
    {
      title: 'Филиал',
      dataIndex: 'branchName'
    },
    {
      title: 'Подрядчик',
      dataIndex: 'contractorName'
    }
  ];

  return (
    <Page>
      <CommonListPage
        crud={ContractorBranchLinkApi}
        pageHeaderProps={{mainHeader: 'Закрепление подрядчиков'}}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default SecuringContractors;
