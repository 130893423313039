// @flow
import React, {memo} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';

import type {EntityStatusType} from '../../../../../lib/types';
import {formatDateTimeToISOString} from '../../../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../../components';
import type {FilterChildrenParams} from '../../../../../components/hoc/Filter';
import Field from './../../../../../components/card/Field';
import {connect} from 'react-redux';
import {setFilter} from '../../../../../ducks/persistFilters';

const { VehiclePlanStatusSelect, BudgetVersionSelect } = Selects;
const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type RegistrBudgetFilterParams = {
  status?: EntityStatusType,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  nodeId?: number,
  id?: number,
  budgetVersionId?: number,
  number?: number
};

type Props = {
  filter: RegistrBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  // на разных вкладках фильтры должны работать по-разному, потому передаем сюда этот пропс
  isBeforeCognosTab: boolean,
  setFilter: Function,
};

const InnerFilter = ({
  filter,
  cleanFilter,
  applyFilter,
  isBeforeCognosTab,
  setFilter
}: Props) => {
  return (
    <SpoilerContent>
      <FilterContainer>
        <Filter
          cleanFilter={() => {
            setFilter({
              path: `${window.location.pathname}/${isBeforeCognosTab ? 'beforeCognos' : 'afterCognos'}`,
              values: {},
            });
            cleanFilter();
          }}
          applyFilter={(params) => {
            setFilter({
              path: `${window.location.pathname}/${isBeforeCognosTab ? 'beforeCognos' : 'afterCognos'}`,
              values: {...params},
            });
            applyFilter(params);
          }}
          initialValues={filter}
          additionalFilter
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter,
            showAdditional,
            handleAdditional
          }: FilterChildrenParams<RegistrBudgetFilterParams>) => {
            const excludedStatuses = isBeforeCognosTab
              ? [
                  'approvementAfterCognos',
                  'approvedAfterCognos',
                  'processing',
                  'editing'
                ]
              : [
                  'created',
                  'approvement',
                  'listApproved',
                  'declined',
                  'budgetCreated',
                  'approved',
                  'processing',
                  'editing'
                ];
            return (
              <>
                <FilterItem>
                  <Field mBottomNone>
                    <Input
                      size="small"
                      placeholder="Номер бюджета"
                      onChange={e =>
                        changeValue(
                          isBeforeCognosTab ? 'id' : 'number',
                          e.target.value
                        )
                      }
                      value={isBeforeCognosTab ? values.id : values.number}
                    />
                  </Field>
                </FilterItem>
                <FilterItem>
                  <Field mBottomNone>
                    <BudgetVersionSelect
                      size="small"
                      placeholder="Версия бюджета"
                      onChange={value => changeValue('budgetVersionId', value)}
                      value={values.budgetVersionId}
                    />
                  </Field>
                </FilterItem>
                <FilterItem>
                  <Field mBottomNone>
                    <OrgUnitSelect
                      size="small"
                      onlyBranches
                      placeholder="Филиал"
                      onChange={value => changeValue('nodeId', value)}
                      value={values.nodeId}
                      isFilter={true}
                    />
                  </Field>
                </FilterItem>
                <FilterItem>
                  <Field mBottomNone>
                    <RangePicker
                      size="small"
                      format="DD.MM.YYYY HH:mm"
                      placeholder={['Начало', 'Конец']}
                      value={[
                        values.startDate ? moment.utc(values.startDate) : null,
                        values.endDate ? moment.utc(values.endDate) : null
                      ]}
                      onChange={(value, dateString) => {
                        const [startDate, endDate] = value;
                        const [startDateString, endDateString] = dateString;
                        changeValue(
                          'startDate',
                          formatDateTimeToISOString(
                            startDate,
                            startDateString.split(' ')[0]
                          )
                        );
                        changeValue(
                          'endDate',
                          formatDateTimeToISOString(
                            endDate,
                            endDateString.split(' ')[0]
                          )
                        );
                      }}
                    />
                  </Field>
                </FilterItem>
                <FilterItem>
                  <Field mBottomNone>
                    <VehiclePlanStatusSelect
                      placeholder="Выберите статус"
                      size="small"
                      onChange={value => changeValue('status', value)}
                      value={values.status}
                      exclude={excludedStatuses}
                    />
                  </Field>
                </FilterItem>
                <FilterButtonsContainer
                  applyFilter={applyFilter}
                  cleanFilter={cleanFilter}
                  handleAdditional={handleAdditional}
                  showAdditional={showAdditional}
                />
              </>
            );
          }}
        </Filter>
      </FilterContainer>
    </SpoilerContent>
  );
};

export default connect(null, {setFilter})(memo(InnerFilter));
