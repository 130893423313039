// @flow
import Modal from 'antd/lib/modal';

import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import React, { useEffect, useState } from 'react';

import { AntTable } from '../../../../../components/ui';
import { fuelTypes } from '../../../../../lib/enum';
import { plus, toLocalStringRu } from '../../../../../lib/helpers';

import type { FuelAndOilCalculation } from '../../../../../lib/types';

import type { ModalProps } from '../../components/Common';

export const ModalFuelAndOilCalculation = (
  props: ModalProps<FuelAndOilCalculation> & {
    // данные для модального окна итога
    itogData?: FuelAndOilCalculation[]
  }
) => {
  const { visible, data, itogData, monthIndex = 0, handleCancel } = props;
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name'
    },
    {
      title: 'Количество, л',
      dataIndex: 'count'
    },
    {
      title: 'Стоимость',
      dataIndex: 'cost',
      render: cost => toLocalStringRu(cost, { minimumFractionDigits: 2 })
    }
  ];
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const findAndAdd = (sum, { name, count = 0, cost = 0 }) => {
      if (!name) return;

      let obj = find(sum, ['name', name]);
      if (obj) {
        obj.count = plus(obj.count, count);
        obj.cost = plus(obj.cost, cost);
      } else {
        sum.push({
          name: name,
          count: count,
          cost: cost
        });
      }
    };

    if (itogData) {
      const listData = itogData.reduce(
        (sum, record?: FuelAndOilCalculation) => {
          if (record?.months[monthIndex]?.fuel?.type)
            findAndAdd(sum, {
              name: fuelTypes[record.months[monthIndex].fuel.type],
              count: record.months[monthIndex].fuel.count,
              cost: record.months[monthIndex].fuel.cost
            });
          // eslint-disable-next-line no-unused-expressions
          record?.months[monthIndex]?.oils?.forEach(el => {
            findAndAdd(sum, {
              name: fuelTypes[el.type],
              count: el.count,
              cost: el.cost
            });
          });
          return sum;
        },
        []
      );
      setTableData(
        sortBy(listData, el => Object.values(fuelTypes).indexOf(el.name))
      );
    } else if (data) {
      setTableData([
        {
          name: data.months[monthIndex]?.fuel?.type
            ? fuelTypes[data.months[monthIndex].fuel.type]
            : '-',
          count: data.months[monthIndex]?.fuel?.count || 0,
          cost: data.months[monthIndex]?.fuel?.cost || 0
        },
        ...(data.months[monthIndex]?.oils?.map(el => {
          return {
            name: fuelTypes[el.type],
            count: el.count,
            cost: el.cost
          };
        }) ?? [])
      ]);
    }
  }, [data, itogData, monthIndex]);

  return (
    <Modal
      destroyOnClose
      width={600}
      title="Полное описание"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <AntTable columns={columns} data={tableData} pagination={false} />
    </Modal>
  );
};
