// @flow

import React, {Component} from 'react';
import Button from 'antd/lib/button';
import {Link} from '@reach/router';

import type {ListState, Location as LocationType, UserAccess} from './../../lib/types';
import {TabItem, Tabs} from './../../components/ui';
import {type LocationFilterParams} from './Filter';
import Header from '../../components/layout/Header';
import {withUserAccess} from './../withUserAccess';
import { Router } from '@reach/router';
import ASVehicles, { canHandleLocationAccess } from './ASVehicles/ASVehicles';
import ASUToir from './ASUToir/ASUToir';
import { accessTypeEnum } from '../../lib/enum';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<LocationType> & {
  filter: LocationFilterParams
};

class LocationList extends Component<Props, State> {
  canHandle = () =>
  this.props.userAccess.some(access =>
    canHandleLocationAccess.includes(access)
  );

  checkLocation = (locationName: string): boolean => {
    return this.props.location.pathname.includes(locationName);
  }

  render() {
    const {userProfile} = this.props;
    const canHandle = this.canHandle();

    const rightsWithAccess = [
      accessTypeEnum.viewingBranchLocations,
      accessTypeEnum.viewingAllLocations,
      accessTypeEnum.admin
    ];

    const viewingBranchLocationsToir = this.props.userAccess.some(access =>
      rightsWithAccess.includes(access),
    );

    return (
      <>
        <Header
          left={<h1>Объекты</h1>}
          right={
            (canHandle && this.checkLocation('uat')) && (
              <Link to={`/admin/locations/form/new`}>
                <Button type="primary" data-cy="addLocation">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Tabs withRouter>
          <TabItem
            label='АС "Транспорт"'
            url="/admin/locations/uat"
          />
          {viewingBranchLocationsToir && <TabItem
            label='АСУ "ТОиР"'
            url="/admin/locations/toir"
          />}
        </Tabs>
        <Router>
          <ASVehicles profile={userProfile} path="uat" />
          <ASUToir profile={userProfile} path="toir" />
        </Router>
      </>
    );
  }
}

export default withUserAccess(LocationList);
