// @flow
import React from 'react';
import { TabItem, Tabs } from './../../../../../../components/ui';
import { CognosType } from '../../../ContractVehicleBudget';

type Props = {
  contractVehiclePlanId?: number,
  assignmentApproved?: boolean,
  cognosType: $Keys<CognosType>
};

export default ({
  contractVehiclePlanId,
  assignmentApproved = false,
  cognosType
}: Props) => {
  const url = contractVehiclePlanId
    ? `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/card`
    : '/budget/contract-vehicle/fixed/new';
  return (
    <Tabs withRouter>
      <TabItem label="Перечень ТС" url={url} />
      {!!contractVehiclePlanId && (
        <TabItem
          label="Закрепление"
          url={`/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/assignments`}
        />
      )}
      {!!contractVehiclePlanId && assignmentApproved && (
        <TabItem
          label="Бюджет"
          url={`/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget`}
        />
      )}
    </Tabs>
  );
};
