// @flow
import minBy from 'lodash/minBy';
import maxBy from 'lodash/maxBy';
import moment from 'moment';

import type { WayPoint } from './../../lib/types';

export const getMinMaxWaypointDateTime = (waypoints: WayPoint[] = []) => {
  let dateTimeList = [];
  waypoints.forEach((waypoint: WayPoint) => {
    if (waypoint.arrivedDateTime) {
      dateTimeList.push(waypoint.arrivedDateTime);
    }
    if (waypoint.departureDateTime) {
      dateTimeList.push(waypoint.departureDateTime);
    }
  });
  return {
    min: minBy(dateTimeList, dateTime => moment.utc(dateTime)),
    max: maxBy(dateTimeList, dateTime => moment.utc(dateTime))
  };
};
