// @flow
import React from 'react';
import {InputProps} from 'antd/lib/input';

import MaskInput from '../MaskInput';
import {cyrLetter} from '../../lib/validation';

export const formatPTSMask = (value: string) => {
  const startWithNumber = new RegExp(`^\\d`),
    startWithLetter = new RegExp(`^[a-zA-Z${cyrLetter}]`),
    lettersRegexp = new RegExp(`^\\d{2}[a-zA-Z${cyrLetter}]`),
    fourLetters = new RegExp(`^[a-zA-Z${cyrLetter}]{4}`),
    lettersNumbersRegexp = new RegExp(`^[a-zA-Z${cyrLetter}]{2}\\d{2}`),
    numbersRegexp = new RegExp(`^\\d{2}\\d`);
  if (startWithNumber.test(value)) {
    if (numbersRegexp.test(value)) {
      return '9999 99999999999';
    } else {
      if (lettersRegexp.test(value)) {
        return '99 aa 999999';
      }
    }
    return '99 ** ******';
  } else {
    if (startWithLetter.test(value)) {
      if (lettersNumbersRegexp.test(value)) {
        return 'aa 999999';
      } else {
        if (fourLetters.test(value)) {
          return 'aa aa 999999';
        }
      }
      return 'aa ** ******';
    }
  }
  return '** ** ******';
};

/**
 * Маска ПТС
 */
export default (props: InputProps) => (
  <MaskInput
    mask="** ** ******"
    placeholder="11 11 111111"
    formatChars={{
      a: `[a-zA-Z${cyrLetter}]`,
      '*': `[0-9a-zA-Z${cyrLetter}]`,
    }}
    formatMask={formatPTSMask}
    convertOnSave={(value: string) => value.replace(/\s+/g, '')}
    upperCase
    {...props}
  />
);
