// @flow
import React from 'react';

import {FormikProps} from 'formik';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type {FormFieldType} from '../../../../components/Form';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import {Form} from '../../../../components';
import Select from '../../../../components/ui/Select';
import YesNoSelect from '../../../../components/selects/YesNoSelect';
import EmployeeSelect from '../../../../components/selects/EmployeeSelect';

import {convertDateToString} from '../../../Vehicles/lib';
import type {TireWorkCard} from '../../../../lib/types/tireWorkCard';
import {withUserAccess} from '../../../withUserAccess';

// Тип автомобильной шины (новая или б/у)
export const TireUsingTypes = {
  rebuilt: 'rebuilt',
  new: 'new',
  secondUsing: 'secondUsing',
};

type TireValues = {
  factoryNumber: number
};

type Props = {
  visible: boolean,
  cardValues?: TireWorkCard,
  onClose: () => void,
  onSave: () => void,
  employeeBranchOrgUnitId: number,
  isAdmin: boolean,
  isDecomissed: boolean,
  tireValues: ?TireValues
};

type FormProps = {
  onSubmit: () => void,
  initialValues: TireWorkCard,
  employeeBranchOrgUnitId: number,
  isAdmin: boolean,
  isDecomissed: boolean,
  tireValues: ?TireValues
};

const {Option} = Select;

const InnerForm = ({
  onSubmit,
  initialValues = {},
  employeeBranchOrgUnitId,
  isAdmin,
  isDecomissed,
  tireValues,
}: FormProps) => {
  if (tireValues?.factoryNumber) {
    initialValues.serialNumber = tireValues.factoryNumber;
  }
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const {handleSubmit, setFieldValue, values} = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Тип автомобильной шины"
                  required
                  name="usingType"
                >
                  {({name, value}) => (
                    <Select
                      placeholder="Выберите тип автомобильной шины"
                      onChange={value => setFieldValue(name, value)}
                      value={value}
                    >
                      <Option value={TireUsingTypes.new}>Новая</Option>
                      <Option value={TireUsingTypes.rebuilt}>
                        Восстановленная
                      </Option>
                      <Option value={TireUsingTypes.secondUsing}>
                        Бывшая в эксплуатации
                      </Option>
                    </Select>
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Входит в комплектацию приобретенного ТС"
                  required
                  name="purchasedWithVehicle"
                >
                  {({name, value}) => (
                    <YesNoSelect
                      fullWidth
                      placeholder={null}
                      value={value}
                      name={name}
                      onChange={(value: boolean) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Серийный номер шины" name="serialNumber">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Образование (размер) шины" name="size">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
            </Grid>
            <FormField label="Завод-изготовитель" name="manufacturer">
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Техническое состояние шины"
              required
              name="condition"
            >
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Причина снятия шины"
              required
              name="removingReason"
            >
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Ответственный за учет работы шины"
              required
              name="responsibleEmployeeId"
            >
              {({name, value}) => (
                <EmployeeSelect
                  onChange={value => setFieldValue(name, value)}
                  value={value}
                  filter={{nodeId: employeeBranchOrgUnitId}}
                />
              )}
            </FormField>
            <FormField
              label="Заключение по списанию шины"
              required
              name="conclusion"
            >
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Председатель комиссии"
              required
              name="commissionChairmanEmployeeId"
            >
              {({name, value}) => (
                <EmployeeSelect
                  onChange={value => setFieldValue(name, value)}
                  value={value}
                  filter={{nodeId: employeeBranchOrgUnitId}}
                />
              )}
            </FormField>
            <FormField
              label="Члены комиссии"
              required
              name="commissionMemberEmployeeIds"
            >
              {({name}) => (
                <EmployeeSelect
                  mode="multiple"
                  allowClear={false}
                  onChange={value => setFieldValue(name, value)}
                  value={values[name]}
                  filter={{nodeId: employeeBranchOrgUnitId}}
                />
              )}
            </FormField>
            <FormField label="Дата" required name="date">
              {({name, value}) => (
                <DatePicker
                  format="DD.MM.YYYY"
                  value={value ? moment(value) : null}
                  onChange={(value, dateString) => {
                    setFieldValue(name, convertDateToString(value, dateString));
                  }}
                  disabled={!isAdmin && isDecomissed}
                />
              )}
            </FormField>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

const WorkCardModal = ({
  visible,
  onSave,
  onClose,
  cardValues,
  employeeBranchOrgUnitId,
  isAdmin,
  isDecomissed,
  tireValues,
}: Props) => {
  return (
    <Modal
      destroyOnClose
      title={'Карточка учета работы автомобильной шины'}
      visible={visible}
      footer={false}
      width={700}
      onCancel={onClose}
    >
      <InnerForm
        initialValues={cardValues}
        onSubmit={onSave}
        employeeBranchOrgUnitId={employeeBranchOrgUnitId}
        isAdmin={isAdmin}
        isDecomissed={isDecomissed}
        tireValues={tireValues}
      />
    </Modal>
  );
};

export default withUserAccess(WorkCardModal);
