// @flow
import CommonApi from './../commonApi';
import { CalendarOfBK } from '../types';

const calendarOfBK = new CommonApi<CalendarOfBK>(
  'CalendarBK',
  'Календарь проведения бюджетного комитета'
);

const fetch = async () => {
  const result = await calendarOfBK.fetch({orderBy: 'startMonth', byDescending: false});
  result.data.sort((a, b) => {
    if (a.startMonth === b.startMonth) {
      return a.startDay - b.startDay;
    } else {
      return 0;
    }
  })
  return result;
}

export default { ...calendarOfBK, fetch };
