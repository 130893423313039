// @flow

import React, {Component} from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import type {Stou} from '../../lib/types';
import {stouApi} from '../../lib/api';
import {Card} from './../../components';
import {Icon} from './../../components/ui';
import {Header, Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../components/Notifications';

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;
  & img {
    width: 100%;
  }
`;
const Operations = styled.div`
  display: inline-flex;
  align-items: center;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const { Field } = Card;

type Props = {
  stouId: number
};

type State = {
  stou: ?Stou
};

/** Карточка СТОУ */
export class StouCard extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { stouId } = this.props;
    try {
      const stou = await stouApi.fetchStou(stouId);
      this.setState({
        stou
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    }
  }

  deleteStou = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await stouApi.deleteStou(parseInt(this.props.stouId, 10));
      await navigate('/admin/stou', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { stou } = this.state;

    if (!stou) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/stou')}>СТОУ</Crumb>
              <Crumb>{stou.name}</Crumb>
            </Breadcrumbs>
          }
          right={
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={this.deleteStou}
              >
                <OperationIcon type="trash" size={16} />
              </Popconfirm>
            </Operations>
          }
        />
        <StyledPanel>
          <h1>
            {stou.name}
            <OperationIcon
              onClick={() => navigate(`/admin/stou/edit/${stou.id}`)}
              type="edit"
              size={16}
            />
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{stou.name}</Field>
              </GridItem>

              <GridItem>
                <Field label="Мнемокод">{stou.mnemonic}</Field>
              </GridItem>

              <GridItem>
                <Field label="Код ОКОФ">{stou.okof}</Field>
              </GridItem>

              <GridItem>
                <Field label="Техническая характеристика">
                  {stou.specification}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Код ОКОФ по ОК 013-1994">
                  {stou.okofByOk0131994}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Статус записи СТОУ">{stou.status}</Field>
              </GridItem>

              <GridItem>
                <Field label="БУ.СД.24 Класс уровня напряжения">
                  {stou.class}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Льгота по налогу (налог на имущество)">
                  {stou.concession}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Признак недвижимости (налог на имущество)">
                  {stou.propertyFlag}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Префикс инвентарных объектов">
                  {stou.prefix}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      </>
    );
  }
}

export default StouCard;
