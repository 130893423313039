// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';

import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';
import {minus, multipliedBy, plus} from '../../../lib/helpers';

import {Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import type {MonthlyWashingPlan, Vehicle, WashingPlanVehicle} from '../../../lib/types';
import {Form, Selects} from '../../../components';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {monthlyWashingPlanApi} from '../../../lib/api';
import type {AppState} from '../../../ducks/redux';
import {monthlyWashingPlanStatusEnum, serviceTypeEnum} from './../../../lib/enum';
import VehicleWashingList from './components/VehicleWashingList';
import {formatDateTimeToString, formatRub, getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const {
  ContractorSelect,
  ContractSelect,
  StageSelect,
  IncomeScheduleSelect
} = Selects;

const Content = styled.div`
  padding: 16px 0;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  monthlyWashingPlan: $Shape<MonthlyWashingPlan>
};

const calculateTotal = (planVehicles: WashingPlanVehicle[] = []) => {
  let total = 0;
  total = planVehicles.reduce((sum, current) => {
    if (current.bodyPrice) {
      if (current.bodyCount) {
        sum = plus(sum, multipliedBy(current.bodyCount, current.bodyPrice));
      }
    }

    if (current.enginePrice) {
      if (current.engineCount) {
        sum = plus(sum, multipliedBy(current.engineCount, current.enginePrice));
      }
    }

    if (current.interiorCount) {
      if (current.interiorPrice) {
        sum = plus(
          sum,
          multipliedBy(current.interiorPrice, current.interiorCount)
        );
      }
    }
    return sum;
  }, 0);

  return total;
};

const calcuateTotalByType = (
  planVehicles: WashingPlanVehicle[] = [],
  type = ''
) => {
  let total = 0;

  total = planVehicles.reduce((sum, current) => {
    if (current[`${type}Count`]) {
      if (current[`${type}Price`]) {
        sum = plus(
          sum,
          multipliedBy(current[`${type}Count`], current[`${type}Price`])
        );
      }
    }
    return sum;
  }, 0);

  return total.toLocaleString('ru-RU', {
    style: 'currency',
    currency: 'RUB'
  });
};

const InnerForm = ({ onSubmit, onCancel, monthlyWashingPlan }: FormProps) => (
  <Form initialValues={monthlyWashingPlan} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        setFieldValue,
        dirty,
        isSubmitting,
        values
      } = formikProps;
      const resetFields = async (field: string) => {
        switch (field) {
          case 'contractorId':
            setFieldValue('contractId', undefined);
          // eslint-disable-next-line
          case 'contractId':
            setFieldValue('stageId', undefined);
          // eslint-disable-next-line
          case 'stageId':
            setFieldValue('incomeScheduleId', undefined);
            setFieldValue('incomeSchedule', undefined);
            break;
          default:
            break;
        }
      };
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <SectionTitle divider>Договор и дата</SectionTitle>
            <Content>
              <StyledGrid gutter="16px" cols={3}>
                <GridItem>
                  <FormField label="Планируемый срок моек" name="date" required>
                    {({ value, name }) => (
                      <Datepicker.MonthPicker
                        format="MMMM YYYY"
                        value={value ? moment.utc(value) : value}
                        onChange={(value: Object) => {
                          setFieldValue(name, moment(value).toISOString());
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Подрядчик" name="contractorId">
                    {({ value, name }) => (
                      <ContractorSelect
                        value={value}
                        onChange={async (contractorId: number) => {
                          setFieldValue(name, contractorId);
                          resetFields(name);
                        }}
                        filter={{
                          services: [serviceTypeEnum.vehicleWash]
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Номер договора" name="contractId">
                    {({ value, name }) => (
                      <ContractSelect
                        disabled={!values.contractorId}
                        name={name}
                        value={value}
                        filter={{ contractorId: values.contractorId }}
                        onChange={contractId => {
                          setFieldValue(name, contractId);
                          resetFields(name);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField label="Этап" name="stageId">
                    {({ value, name }) => (
                      <StageSelect
                        disabled={!values.contractId}
                        name={name}
                        value={value}
                        filter={{ contractId: values.contractId }}
                        onChange={stageId => {
                          setFieldValue(name, stageId);
                          resetFields(name);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField label="Точка графика" name="incomeScheduleId">
                    {({ value, name }) => (
                      <IncomeScheduleSelect
                        disabled={!values.stageId}
                        name={name}
                        value={value}
                        filter={{ stageId: values.stageId }}
                        onChange={(incomeScheduleId, option) => {
                          setFieldValue(
                            'incomeSchedule',
                            option?.props?.incomeschedule
                          );
                          setFieldValue(name, incomeScheduleId);
                        }}
                        selectByDate={values.date}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem />
                <GridItem>
                  <div>
                    Сумма по договору:{' '}
                    {formatRub(values?.incomeSchedule?.sum ?? 0)}
                  </div>
                </GridItem>
                <GridItem>
                  <FormField name="washingPlanVehicles">
                    {({ value, name }) => {
                      const total = calculateTotal(value);
                      const color =
                        minus(values?.incomeSchedule?.sum ?? 0, total) < 0
                          ? 'rgba(255,0,0,0.65)'
                          : 'rgba(0,0,0,0.65)';
                      return (
                        <>
                          Сумма по плану{' '}
                          <div style={{ color, display: 'inline' }}>
                            {total.toLocaleString('ru-RU', {
                              style: 'currency',
                              currency: 'RUB'
                            })}
                          </div>
                        </>
                      );
                    }}
                  </FormField>
                </GridItem>
                <GridItem>&nbsp;</GridItem>
                <GridItem>
                  <FormField name="washingPlanVehicles">
                    {({ value, name }) =>
                      `Сумма наружных ${calcuateTotalByType(value, 'body')}`
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="washingPlanVehicles">
                    {({ value, name }) =>
                      `Сумма салонов ${calcuateTotalByType(value, 'interior')}`
                    }
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="washingPlanVehicles">
                    {({ value, name }) =>
                      `Сумма ДВС ${calcuateTotalByType(value, 'engine')}`
                    }
                  </FormField>
                </GridItem>
              </StyledGrid>
            </Content>
            <FormField label="" name="washingPlanVehicles">
              {({ value, name }) => (
                <VehicleWashingList
                  planDate={monthlyWashingPlan.date || values.date}
                  vehicleWashings={value}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                />
              )}
            </FormField>
          </Section>
          <Section />
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  monthlyWashingPlanId: number,
  employeeBranchOrgUnitId: number
};

type State = {
  monthlyWashingPlan?: MonthlyWashingPlan,
  vehicles: Vehicle[]
};

class MonthlyWashingPlanForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { monthlyWashingPlanId } = this.props;

    if (monthlyWashingPlanId) {
      try {
        const monthlyWashingPlan = await monthlyWashingPlanApi.fetchMonthlyWashingPlan(
          monthlyWashingPlanId
        );
        const washingPlanVehicles = await monthlyWashingPlanApi.fetchWashingPlanVehicles(
          monthlyWashingPlanId
        );
        monthlyWashingPlan.washingPlanVehicles = washingPlanVehicles;

        this.setState({
          monthlyWashingPlan
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        this.onCancel();
      }
    }
  }

  onCancel = () => navigate('/budget/monthly-washing-plans', true);

  onSubmit = async ({
    washingPlanVehicles = [],
    ...monthlyWashingPlan
  }: MonthlyWashingPlan) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      /**
       * Такой финт нужен для того, чтобы flow не ругался,
       * что в модели MonthlyWashingPlan не хватает свойства washingPlanVehicles.
       * На сервер отправлять washingPlanVehicles сместе с monthlyWashingPlan
       * нам не нужно, для них есть отдельная ручка
       *  */

      const washingPlan = {
        ...monthlyWashingPlan,
        washingPlanVehicles: []
      };
      let monthlyWashingPlanId = monthlyWashingPlan.id;
      if (monthlyWashingPlanId) {
        await monthlyWashingPlanApi.updateMonthlyWashingPlan(washingPlan);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        const added = await monthlyWashingPlanApi.addMonthlyWashingPlan({
          ...washingPlan,
          status: monthlyWashingPlanStatusEnum.draft
        });
        if (added && added.id) {
          monthlyWashingPlanId = added.id;
          notification.success({
            message: 'Успешное добавление',
            description: `План моек успешно добавлен`
          });
        }
      }

      await monthlyWashingPlanApi.setWashingPlanVehicles(
        washingPlanVehicles
          .filter(
            (washingPlanVehicle: WashingPlanVehicle) =>
              washingPlanVehicle.bodyCount ||
              washingPlanVehicle.engineCount ||
              washingPlanVehicle.interiorCount
          )
          .map((washingPlanVehicle: WashingPlanVehicle) => {
            return {
              ...washingPlanVehicle,
              // $FlowFixMe undefined
              washingPlanId: monthlyWashingPlanId
            };
          }),
        parseInt(monthlyWashingPlanId, 10)
      );

      monthlyWashingPlanId
        ? navigate(`/budget/monthly-washing-plans/${monthlyWashingPlanId}`)
        : navigate('/budget/monthly-washing-plans', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { monthlyWashingPlan } = this.state;
    const { monthlyWashingPlanId, employeeBranchOrgUnitId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/budget/monthly-washing-plans')}
              >
                Список планов моек
              </Crumb>
              {monthlyWashingPlanId ? (
                <Crumb>Редактирование</Crumb>
              ) : (
                <Crumb>Новый план моек</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {monthlyWashingPlan
              ? `План моек на ${formatDateTimeToString(
                  moment(monthlyWashingPlan.date),
                  'MMMM YYYY'
                )}`
              : 'Новый план моек'}
          </h1>
        </StyledPanel>
        <InnerForm
          monthlyWashingPlan={
            monthlyWashingPlan || {
              orgUnitId: employeeBranchOrgUnitId,
              washingPlanVehicles: []
            }
          }
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  monthlyWashingPlanId: parseInt(props.monthlyWashingPlanId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(MonthlyWashingPlanForm);
