// @flow
import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import YNSelect from '../../../components/selects/YesNoSelect';

const SpoilerContent = styled.div`
  padding: 16px;
`;

const YesNoSelect = styled(YNSelect)`
  min-width: 200px;
`;

export type VehicleIssueGroupParams = {
  search?: string,
  isArchive?: boolean
};

type Props = {
  filter: VehicleIssueGroupParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<VehicleIssueGroupParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Наименование"
                  size="small"
                  onChange={e =>
                    changeValue('search', e.target.value || undefined)
                  }
                  value={values['search']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Признак архива"
                  size="small"
                  onChange={value => changeValue('isArchive', value)}
                  value={values['isArchive']}
                  reset
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default StockPartFilter;
