// @flow
import React, {useState} from 'react';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import {budgetSummaryApi} from '../../../lib/api';
import {accessTypeEnum, budgetSummaryEnum, budgetSummaryStatus} from '../../../lib/enum';

import {formatDateTimeToString, navigate} from '../../../lib/helpers';
import type {BudgetSummaryStatusType, BudgetVersion} from '../../../lib/types';
import {withUserAccess} from '../../withUserAccess';
import {addDetailedBudgetAccessRight, approveDetailedBudgetAccessRight} from './accessRight';

import {Filter} from './components/';
import Popconfirm from 'antd/lib/popconfirm';
import {Icon} from '../../../components/ui';
import {notificationLoading} from '../../../components/Notifications';
import notification from 'antd/lib/notification';

const entryPointPath = '/budget/details/';

export default withUserAccess(({userAccess, employeeBranchOrgUnitId}) => {
  const [reFetch, setReFetch] = useState(false);
  
  const canAdd = () =>
    userAccess.some(access => addDetailedBudgetAccessRight.includes(access));
  
  const canApprove = () =>
    userAccess.some(access =>
      approveDetailedBudgetAccessRight.includes(access),
    );
  
  const deleteRecord = async (id) => {
    try {
      notificationLoading({message: 'Удаление записи', key: 'deleting'});
      await budgetSummaryApi.delete(id);
      setReFetch(true);
      notification.success({message: 'Запись удалена'});
    } catch (e) {
      notification.error({
        message: 'Ошибка при удалении записи',
        description: e.title || e.message,
      });
    }
    finally {
      notification.close('deleting');
      setReFetch(false);
    }
  };
  
  const columns = [
    {
      title: '№',
      dataIndex: 'id'
    },
    {
      title: 'Версия бюджета',
      dataIndex: 'budgetVersion',
      render: (budgetVersion?: BudgetVersion) => budgetVersion?.name ?? '-',
    },
    {
      title: 'Дата формирования',
      dataIndex: 'date',
      render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY'),
    },
    {
      title: 'Планируемый период',
      key: 'period',
      render: (record) => `${formatDateTimeToString(record.startDate, 'DD.MM.YYYY')} -
      ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: BudgetSummaryStatusType) => budgetSummaryStatus[status],
    },
    {
      stopPropagation: true,
      width: 20,
      dataIndex: 'id',
      key: 'delete',
      render: (id: number) => canAdd()
        ? (
          <Popconfirm
            title="Вы действительно хотите удалить запись?"
            onConfirm={async () => await deleteRecord(id)}
            cancelText="Нет"
            okText="Да"
          >
            <Icon type="x" />
          </Popconfirm>
        )
        : null,
    },
  ];
  
  return (
    <Page>
      <CommonListPage
        crud={budgetSummaryApi}
        pageHeaderProps={{
          mainHeader: 'Уточнения в бюджет',
          access: {
            add: canAdd() && (() => navigate(`${entryPointPath}new`)),
          },
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) =>
            navigate(
              canApprove() || userAccess.includes(accessTypeEnum.viewingDetailedBudget)
                ? `${entryPointPath}${id}/-1/primary`
                : `${entryPointPath}${id}/${employeeBranchOrgUnitId}/primary`,
            ),
        }}
        filter={Filter}
        filterMixin={{type: budgetSummaryEnum.clarification}}
        reFetch={reFetch}
      />
    </Page>
  );
});
