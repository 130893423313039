// @flow

import React from 'react';
import {SelectProps} from 'antd/lib/select';
import {VehicleCategoriesApi} from '../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

// Выпадающий список категорий
export default (props: SelectProps) => (
  <AutocompleteSelect
    value={props.value}
    fetch={() => VehicleCategoriesApi.fetch({pageSize: undefined})}
    fetchSingle={VehicleCategoriesApi.get}
    placeholder="Выберите категорию"
    notFoundText="Категория не найдена"
    onChange={props.onChange}
    renderOption={(item, Option) => (
      <Option key={item.id} value={item.id}>
        {item.name}
      </Option>
    )}
    {...props}
  />
);
