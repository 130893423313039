// @flow
import React from 'react';
import { OilConsumptionApi } from '../../../../lib/api';
import CommonListPage from '../../../../components/hoc/common/handbook/ListPage';
import Page from '../../../../components/hoc/common/handbook/PageContext';
import {
  monthsNamesTranslitEnum,
  oilConsumptionStatuses
} from '../../../../lib/enum';
import moment from 'moment';
import { Tabs } from '../../index';
import { Tabs as InternalTabs } from '../components';
import { navigate } from '../../../../lib/helpers';
import ListFilter from './components/ListFilter';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName',
    cy: 'orgUnitId'
  },
  {
    title: 'Месяц',
    dataIndex: 'month',
    render: monthName => monthsNamesTranslitEnum[monthName]
  },
  {
    title: 'Дата документа',
    dataIndex: 'created',
    render: dateString => moment(dateString).format('DD.MM.YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: statusString => oilConsumptionStatuses[statusString]
  }
];

const entryPointPath = '/admin/act/internal/oil-consumption-accounting';

const List = () => {
  return (
    <Page>
      <CommonListPage
        crud={OilConsumptionApi}
        pageHeaderProps={{ mainHeader: 'Реестр учёта расхода масел' }}
        tableListProps={{
          columns,
          onSelect: id => navigate(`${entryPointPath}/${id}`)
        }}
        subHeader={
          <>
            <Tabs />
            <InternalTabs />
          </>
        }
        filter={ListFilter}
      />
    </Page>
  );
};

export default List;
