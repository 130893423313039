// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';

import Spoiler from '../../../../components/ui/Spoiler';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import type {ReportGpmOrderParams} from '../../../../lib/types/reportGpmOrder';
import VehicleTypeSelect from '../../../../components/selects/VehicleTypeSelect';
import {formatDateTimeToISOString} from '../../../../lib/helpers';
import {gpmVehicleTypes} from '../../../../lib/enum';

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  onChangeFilter: (key: $Keys<ReportGpmOrderParams>, value: any) => void,
  params: ReportGpmOrderParams,
  cleanParams: () => void,
  applyParams: (params: ReportGpmOrderParams) => Promise<any>,
  onExport: Function
};

/**
 * Фильтр с параметрами
 */
export default class Filter extends React.Component<Props> {
  render() {
    const { params, onExport } = this.props;
    return (
      <Spoiler defaultExpanded label="Параметры">
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
            <GridItem>
              <Field label="Дата">
                <Datepicker
                  value={params.date ? moment(params.date) : undefined}
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    this.props.onChangeFilter(
                      'date',
                      formatDateTimeToISOString(value, dateString)
                    );
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Подразделение">
                <OrgUnitSelect
                  value={params.orgUnitId}
                  onChange={id => this.props.onChangeFilter('orgUnitId', id)}
                  isFilter={true}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Тип ГПМ">
                <VehicleTypeSelect
                  placeholder="Выберите тип ГПМ"
                  value={params.type}
                  selectableGroup
                  onlyTypes={gpmVehicleTypes}
                  onChange={types => this.props.onChangeFilter('type', types)}
                />
              </Field>
            </GridItem>
            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={this.props.applyParams}>
                Применить
              </Button>
              <Button type="secondary" onClick={this.props.cleanParams}>
                Очистить
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}
