// @flow
import React, {useEffect, useState} from 'react';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm from '../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import Button from 'antd/lib/button';
import {InputNumber, notification} from 'antd';
import {fetchRequest} from '../../lib/api';

const InnerForm = props => (
  <CommonForm {...props}>
    {({ setFieldValue }) => {
      return (
        <>
          <Grid>
            <GridItem>
              <FormField
                label="Дата окончания (число месяца, следующего за отчетным)"
                fast
                required
                name="dayOfMonth"
                hasFeedback={false}
              >
                {({ name, value }) => (
                  <InputNumber
                    name={name}
                    value={value}
                    onChange={value => setFieldValue(name, value)}
                    min={1}
                    max={31}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem fullWidth>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        </>
      );
    }}
  </CommonForm>
);

export default () => {
  const [data, setData] = useState();
  useEffect(() => {
    fetchRequest.get('/tripeditlimit').then(response => setData(response));
  }, []);

  const onSubmit = async payload => {
    if (payload.id) {
      setData(payload);
      fetchRequest.put('/tripeditlimit', payload).catch(() => {
        notification.error({
          message: 'Ошибка сохранения данных',
          description: 'Попробуйте повторить попытку позже'
        });
      });
    }
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        mainHeader: 'Сроки внесения изменений в оттаксированный ПЛ'
      }}
      onSubmit={onSubmit}
    >
      <InnerForm data={data} noWrapMe />
    </CommonCardPage>
  );
};
