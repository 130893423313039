/* helpers.js | Помощники */
import * as router from '@reach/router';
import notification from 'antd/lib/notification';
import Tooltip from 'antd/lib/tooltip';
import Bignumber from 'bignumber.js';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isInteger from 'lodash/isInteger';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import qs from 'query-string';
import React from 'react';
import fileIcon from '../assets/images/file-icon.svg';
import {formatLicensePlateMask} from '../components/masked-inputs/LicensePlateInput';
import {getWaypoints} from '../components/Waypoints/lib';
import {getVehicleGroup} from '../containers/Vehicles/lib';
import {fileTagEnum, positionEnum, vehicleGroupEnum, vehicleTypeEnum, rateTypeEnum} from './enum';

import type {
  BudgetSummary,
  ContractVehicle,
  ContractVehiclePlan,
  Driver,
  Employee,
  ListState,
  OrgUnitNode,
  Vehicle,
  VehiclePlan,
  VehicleType,
  WayPoint,
} from './types';
import type {JobTitle} from './types/jobTitle';
import {notificationLoading} from '../components/Notifications';
import {downloadRequestWithToken} from './api';
import Modal from "antd/lib/modal";

export const tooltipFullText = (
  text: string,
  maxLength: number = 20,
  node: any = null
) => {
  return text?.length > maxLength ? (
    <Tooltip title={node || text}>
      <span className="pointer">{text.substr(0, maxLength - 4)}...</span>
    </Tooltip>
  ) : (
    text
  );
};

export const budgetSummaryInfoString = (budgetSummary?: BudgetSummary) => {
  return budgetSummary
    ? `${budgetSummary.id ? `№ “${budgetSummary.id}”` : null} от ${formatDate(
        budgetSummary.date
      )}, версия: ${budgetSummary?.budgetVersion?.name ?? '-'}`
    : null;
};

export const vehiclePlanInfoString = (
  vehiclePlan: VehiclePlan,
  useDocumentNumber = false
) => {
  return `${
    vehiclePlan?.id
      ? `№ “${
          useDocumentNumber && vehiclePlan.number
            ? vehiclePlan.number
            : vehiclePlan.id
        }”`
      : null
  } ${vehiclePlan?.name ?? ''}. ${
    vehiclePlan.orgUnitName ? `Филиал: ${vehiclePlan.orgUnitName}` : ``
  } от ${formatDate(vehiclePlan.date)}, версия: ${vehiclePlan?.budgetVersion
    ?.name ?? '-'}`;
};

export const contractVehiclePlanInfoString = (
  contractVehiclePlan: ContractVehiclePlan,
  useDocumentNumber = false
) => {
  return `${
    contractVehiclePlan.id
      ? `№ “${
          useDocumentNumber && contractVehiclePlan.number
            ? contractVehiclePlan.number
            : contractVehiclePlan.id
        }”`
      : null
  } ${contractVehiclePlan.name ? contractVehiclePlan.name : ''}. ${
    contractVehiclePlan.orgUnitName
      ? `Филиал: ${contractVehiclePlan.orgUnitName}`
      : ``
  } от ${formatDate(contractVehiclePlan.date)}, версия: ${contractVehiclePlan
    ?.budgetVersion?.name ?? '-'}`;
};

// вычитаем a на b
export const minus = (a: number, b: number, count: number = 2) => {
  const num = new Bignumber(a).minus(b).toNumber();
  return toFixed(num, count);
};

export const multiplePlus = (numbers: number[], count: 2) => {
  let sum = 0;
  numbers.forEach(number => {
    sum = new Bignumber(sum).plus(number).toNumber();
  });
  return toFixed(sum, count);
};

// складываем a на b
export const plus = (a: number, b: number, count: number = 2) => {
  const num = new Bignumber(a).plus(b).toNumber();
  return toFixed(num, count);
};

// делим a на b
export const dividedBy = (a: number, b: number, count: number = 2) => {
  const num = new Bignumber(a).dividedBy(b).toNumber();
  return toFixed(num, count);
};

// умножаем a на b
export const multipliedBy = (a: number, b: number, count: number = 2) => {
  const num = new Bignumber(a).multipliedBy(b).toNumber();
  return toFixed(num, count);
};

// округляем до 2-х знаков после запятой
export const toFixed = (num: number, count: number = 2) => {
  return isInteger(num)
    ? num
    : isAllNumber(num)
    ? new Bignumber(new Bignumber(num).toFixed(count)).toNumber()
    : null;
};

// проверяем является ли число целым млм вещественным
export const isAllNumber = num => /^[-]?[0-9]*[.,]?[0-9]+$/.test(num);

// проверяем является значение числом
export const isNumberValue = num => /^\+?(0|[1-9]\d*)$/.test(num);

// получим значение обекта по переданному полю
export const getValueObject = (object, field) => {
  return field.split('.').reduce((item, current) => {
    return item == null ? undefined : item[current];
  }, object);
};

export const isEmptyValue = value => {
  return !!(isEmpty(value) && !isNumber(value));
};

// проверим сотрудник является диспетчером
export const isDispatcher = (employee: Employee) => {
  if (employee && employee.positions) {
    return employee.positions.includes(positionEnum.dispatcher);
  }
  return false;
};

export const findOrgUnitInTree = (node: OrgUnitNode, orgUnitId: number) => {
  if (node.id === orgUnitId) {
    return node;
  } else if (node.children) {
    let result;
    for (let i = 0; result == null && i < node.children.length; i += 1) {
      result = findOrgUnitInTree(node.children[i], orgUnitId);
    }
    return result;
  }
};

/**
 * Функция для подготовки данных для антовского Upload
 */
export const prepareUploadItem = (item: Object) =>
  item.uid
    ? item
    : {
        ...item,
        name: item.name || item.fullName,
        thumbUrl: isImage(item.contentType) ? item.thumbUrl : fileIcon,
        uid: item.id
      };

/**
 * Функция конвертирует диапазон дат в строку с определенным форматом
 * @param {?Date} startDate Начальная дата
 * @param {?Date} endDate Конечная дата
 * @param {?String} format Формат данных
 */
export const formatDateRangeString = function(
  startDate: ?Date | ?string,
  endDate: ?Date | ?string,
  format: string = 'D MMMM YYYY, HH:mm'
): string {
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  if (startDate && endDate) {
    if (start.isBefore(end, 'day')) {
      return `${start.format(format)} - ${end.format(format)}`;
    }
    if (start.isSame(end)) {
      return `${start.format(`D MMMM YYYY`)} - ${end.format('HH:mm')}`;
    } else if (start.date() === end.date()) {
      return `${start.format(`D MMMM YYYY, HH:mm`)} - ${end.format('HH:mm')}`;
    }
  } else if (startDate) {
    return start.format(format);
  } else if (endDate) {
    return end.format(format);
  }
  return '';
};

/*
 * Возвращает true/false больше ли текущая дата заданной минимумом
 */
export const disabledDateMin = function(currentDate: moment, minDate: moment) {
  return moment(currentDate).isBefore(moment(minDate).add(1, 'days'));
};

/**
 * Функция конвертирует диапазон дат в строку с определенным форматом
 * @param {?Date} startDate Начальная дата
 * @param {?Date} endDate Конечная дата
 */
export const calculateDateRangeString = function(
  startDate: ?Date | ?string,
  endDate: ?Date | ?string
): string {
  const start = moment.utc(startDate);
  const end = moment.utc(endDate);
  if (startDate && start.isValid && endDate && end.isValid) {
    if (start.isAfter(end)) {
      return 'Неверный диапазон дат';
    }
    const duration = moment.duration(end.diff(start));
    const minutes = moment.utc(duration.asMilliseconds()).format('m');
    return `${Math.floor(duration.asHours())}ч. ${minutes}м.`;
  }
  return '';
};

/**
 * Конвертирует дату в строку с определенным форматом
 * @param {?Date} dateTime дата и время
 * @param {string} format формат, в который переводить
 * @param {string} fromUtc конвертировать из utc
 */
export const formatDateTimeToString = function(
  dateTime: ?Date | ?string,
  format: string = 'DD.MM.YYYY HH:mm',
  fromUtc: boolean = true
) {
  if (dateTime) {
    return fromUtc
      ? moment.utc(dateTime).format(format)
      : moment(dateTime).format(format);
  }
  return '';
};

/**
 *
 * @param {*} columns
 * подсчет общей ширины талицы
 */
export const sumWidthColumns = (columns: any) => {
  return (
    columns?.reduce((sumWidth, column: any) => {
      let width = column?.width ?? 0;
      return (
        sumWidth +
        width +
        (column?.children ? sumWidthColumns(column.children) : 0)
      );
    }, 0) ?? 0
  );
};

/**
 * Возвращает moment значение в UTC режиме
 */
export const getMomentValue = function(value: ?moment) {
  return value ? moment.utc(value) : value;
};

/**
 * Конвертирует дату в ISO строку
 * @param {?moment} value дата и время в moment объекте
 * @param {string} dateString - дата и время строкой
 * @param {string} format - формат, который необходимо парсить
 */
export const formatDateTimeToISOString = function(
  value: ?moment,
  dateString: string,
  format: string = 'DD.MM.YYYY HH:mm'
): ?string {
  if (value) {
    return moment.utc(dateString, format).toISOString();
  }
  return undefined;
};

/**
 * Заменяет query параметр или добавляет новый в адресную строку
 * Нужен для фильтров и пагинации
 */
export const setQueryParams = (query: { [key: string]: string | number }) => {
  const { search, origin, pathname } = window.location,
    queryObject = { ...qs.parse(search), ...query },
    updatedQuery = qs.stringify(queryObject);
  const location = `${origin}${pathname}${
    updatedQuery.length ? `?${updatedQuery}` : ''
  }`;
  // обновляем стейт в location, чтобы отлавливать изменение query параметров
  // при нажатии кнопок назад и вперед в браузере
  navigate(location, false, { state: queryObject });
  window.localStorage.setItem(
    pathname.endsWith('/') ? pathname.slice(0, -1) : pathname,
    updatedQuery
  );
};

/**
 * Конвертация километров в метры
 */
export const convertFromKmToM = (value: ?number, count: number = 3) => {
  const km = parseFloat(value);
  if (km >= 0) {
    return multipliedBy(km, 1000, count);
  }
  return value;
};

/**
 * Конвертация метров в километры
 */
export const convertFromMToKm = (value: ?number, count: number = 3) => {
  const m = parseInt(value);
  if (m >= 0) {
    return dividedBy(m, 1000, count);
  }
  return value;
};

/**
 * Конвертация копеек в рубли
 */
export const convertFromKopToRub = (value: ?number) => {
  const kop = Math.abs(parseFloat(value));
  const znak = Math.sign(value);
  if (kop >= 0) {
    return multipliedBy(dividedBy(kop, 100), znak);
  }
  return value;
};

/**
 * Конвертация рублей в копейки
 */
export const convertFromRubToKop = (value: ?number) => {
  const rub = Math.abs(parseFloat(value));
  const znak = Math.sign(value);
  if (rub >= 0) {
    return multipliedBy(multipliedBy(rub, 100), znak);
  }
  return value;
};

/**
 * Конвертация сотрудника в строку
 */
export const convertEmployeeToString = (
  employee: ?Employee,
  options: { initials?: boolean } = {},
  emptyText: string = '-'
) => {
  const { initials } = options;
  if (employee) {
    if (initials) {
      return [
        employee.lastname,
        `${employee.firstname[0]}.`,
        `${employee.middlename[0]}.`
      ]
        .join(' ')
        .trim();
    }
    return [employee.lastname, employee.firstname, employee.middlename]
      .join(' ')
      .trim();
  }
  return emptyText;
};

/**
 * Конвертация водителя в строку
 * @param driver Водитель
 * @param emptyText Текст, если водитель пуст
 * @returns {string}
 */
export const convertDriverToString = (
  driver: ?Driver,
  emptyText: string = ''
) => {
  if (driver && driver.employee) {
    return convertEmployeeToString(driver.employee);
  }
  return emptyText;
};

/**
 * Метод для склонения числительных
 * @param number Число для склонения
 * @param one Форма существительного для одного предмета, например, рубль
 * @param two Форма существительного для двух предметов, например, рубля
 * @param five Форма существительного для пяти предметов, например, рублей
 * @returns {string} Соответсвующее существительное
 */
export const declineNumber = (
  number: number,
  one: string,
  two: string,
  five: string
) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const titles = [one, two, five];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

// багфикс
export const checkDate = (date: ?Date) => {
  return formatDate(date) !== '01.01.0001' && date !== null
    ? formatDate(date)
    : '-';
};

export const formatDate = function(
  date: Date,
  formatString: string = 'DD.MM.YYYY'
): string {
  const momentDate = getMomentValue(date);
  if (momentDate) {
    return momentDate.format(formatString);
  }
  return '';
};

/**
 * Конвертация секунд в часы
 */
export const convertFromSecondToHour = (value: ?number, count?: number) => {
  const second = parseInt(value);
  if (second >= 0) {
    return dividedBy(second, 3600, count);
  }
  return value;
};

/**
 * Конвертация часов в секунды
 */
export const convertFromHourToSecond = (value: ?number) => {
  const hour = parseFloat(value);
  if (hour >= 0) {
    return multipliedBy(hour, 3600);
  }
  return value;
};

/**
 * Конвертация должности в строку
 */
export const convertJobTitleToString = (
  jobTitle: ?JobTitle,
  emptyText: string = ''
) => {
  if (jobTitle) {
    return [jobTitle.name, jobTitle.orgUnitName].join(' ').trim();
  }
  return emptyText;
};

/**
 * Конвертация ТС в строку
 * @param vehicle ТС
 * @param emptyValue Пустое значение
 */
export const convertVehicleToString = (
  vehicle: ?Vehicle,
  emptyValue: string = ''
) => {
  if (vehicle) {
    const { vehicleModel } = vehicle;
    const licensePlate =
      vehicle.licensePlate &&
      applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
    if (vehicleModel) {
      return [
        vehicleModel.brandName,
        vehicleModel.name,
        licensePlate && `(${licensePlate})`
      ]
        .join(' ')
        .trim();
    }
    return licensePlate;
  }
  return emptyValue;
};

/**
 * Конвертация Наемного ТС к строке
 * @param contractVehicle Наемное ТС
 * @param emptyValue Пустое значение
 */
export const convertContractVehicleToString = (
  contractVehicle: ?ContractVehicle,
  emptyValue: string = ''
) => {
  if (contractVehicle)
    return convertVehicleToString(contractVehicle.vehicle, emptyValue);
};

/**
 * Конвертация Подрядчика к строке
 * @param contractor Подрядчик
 * @param emptyValue Пустое значение
 */
export const convertContractorToString = (
  contractor: ?Contractor,
  emptyValue: string = ''
) => {
  if (contractor) {
    if (contractor.company) {
      return [contractor.company.name, contractor.name].join(' ').trim();
    }
    return contractor.name;
  }
  return emptyValue;
};

export const getPathWithHistoryParams = (route: string): string => {
  const query = route
    ? window.localStorage.getItem(
        route.endsWith('/') ? route.slice(0, -1) : route
      ) ?? ''
    : '';
  return route ? `${route}${query?.length ? `?${query}` : ''}` : route;
};

/**
 * Роутер для перехода по страницам с учетом состояния
 * @param route роут на который идет перход
 * @param useLastHistoryQuery Использовать или нет последние параметры
 * @param option доп параметры для navigate
 */
export const navigate = async (
  route: string,
  useLastHistoryQuery: boolean = false,
  option: any = undefined
) => {
  if (useLastHistoryQuery) {
    const { origin } = window.location;
    await router.navigate(
      `${origin}${getPathWithHistoryParams(route)}`,
      option
    );
  } else {
    await router.navigate(route, option);
  }
};

const defaultPageSize =
  parseInt(process.env.REACT_APP_DEFAULT_PAGE_SIZE, 10) || 20;
/**
 * Состояние по-умолчанию для контейнеров списков
 * @param pageSize Размерность одной страницы
 */
export const getListInitialState: ListState<T> = (
  pageSize: number = defaultPageSize
) => ({
  data: [],
  page: 1,
  pageSize,
  totalCount: 0,
  loading: false
});

/**
 * Флаг, означающий что приложение в development окружении
 * @type {boolean}
 */
export const isDevelopment = process.env.REACT_APP_IS_DEV === 'true';

/**
 * Функция для добавления пустой строки к массиву
 *
 * @param values Массив
 * @param options
 */
export const withEmptyRow = (
  values = [],
  options: {
    emptyRow: any,
    ignoredEmptyRowKeys: string[],
    maxLength: number
  } = {}
) => {
  const defaultOptions = {
    emptyRow: {},
    ignoredEmptyRowKeys: []
  };
  options = {
    ...defaultOptions,
    ...options
  };
  const { emptyRow, ignoredEmptyRowKeys, maxLength } = options;
  const hasEmptyRow = (values: T[]) => {
    if (!values.length) {
      return false;
    }
    const lastValue = omit(values[values.length - 1], ...ignoredEmptyRowKeys);
    const withoutKeysEmptyRow = omit(emptyRow, ...ignoredEmptyRowKeys);
    return isEqual(lastValue, withoutKeysEmptyRow);
  };
  if (maxLength && values.length >= maxLength) return values;
  if (hasEmptyRow(values)) return values;
  return [...values, emptyRow];
};

/**
 * Удаляет пустые элементы из массива
 *
 * @param values Массив
 * @param emptyRow Пустая строка
 * @param ignoredEmptyRowKeys Игнорируемые ключи при проверке на пустую строку
 * @returns {*} Массив без пустой строки
 */
export const withoutEmptyRow = (
  values,
  emptyRow = {},
  ignoredEmptyRowKeys = []
) => {
  return values.filter(value => {
    const valueWithoutKeys = omit(value, ...ignoredEmptyRowKeys);
    const emptyRowWithoutKeys = omit(emptyRow, ...ignoredEmptyRowKeys);
    return !isEqual(valueWithoutKeys, emptyRowWithoutKeys);
  });
};

/**
 * Маппит латинские буквы строки в кириллические буквы
 *
 * Если нет соответствия в словаре, то оставляет ту же букву
 *
 * @param text Строка для преобразования
 * @returns {string} Преобразованная строка
 */
export const mapLatToCyr = (text: string) => {
  /**
   * Ключ - латинская буквы
   * Значение - кириллическая буква
   */
  const map = {
    A: 'А',
    B: 'В',
    C: 'С',
    E: 'Е',
    H: 'Н',
    K: 'К',
    M: 'М',
    O: 'О',
    P: 'Р',
    T: 'Т',
    X: 'Х',
    Y: 'У'
  };
  const isUpper = (letter: string) => letter === letter.toUpperCase();
  return text
    .split('')
    .map(letter => {
      const result = map[letter.toUpperCase()] || letter;
      // Сохраняем тот же регистр букв
      if (isUpper(letter)) {
        return result;
      } else return result.toLowerCase();
    })
    .join('');
};

/**
 * Применяет для строкового значения маску
 *
 * @param value Значение
 * @param format Функция возвращающая маску по формату значения
 * @returns {string} Значение, соответствующее маске
 */
export const applyMaskToValue = (
  value: string,
  format: ((value: string) => string) | (() => string)
): string => {
  if (!value) return null;

  const mask = format(value);

  // Берем индексы непробельных символов маски
  const indices = mask
    .split('')
    .map((letter, index) => ({ letter, index }))
    .filter(({ letter }) => letter !== ' ')
    .map(({ index }) => index);

  let lastIndex = null;

  // Подставляем символы из значения в маску по индексам
  let result = value.split('').reduce((mask, letter, index) => {
    const masked = mask.split('');
    masked.splice(indices[index], 1, letter);
    lastIndex = indices[index];
    return masked.join('');
  }, mask);

  // Оставшиеся символы заменяем пробелами
  indices
    .slice(indices.findIndex(value => value === lastIndex) + 1)
    .forEach(index => {
      const letters = result.split('');
      letters.splice(index, 1, ' ');
      result = letters.join('');
    });

  return result;
};

/**
 * Проверяет является ли ТС трактором или экскаватором
 * @param vehicleType
 * @returns {boolean}
 */
export const isVehicleTypeTractor = (vehicleType: VehicleType): boolean => {
  if (
    [
      vehicleTypeEnum.bgmNaTraktore,
      vehicleTypeEnum.bkmNaTraktore,
      vehicleTypeEnum.bkmRpnNaTraktore
    ].includes(vehicleType)
  )
    return true;
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup === vehicleGroupEnum.tractorsExcavators;
};

/**
 * Проверяет является ли ТС трактором или экскаватором
 * @param vehicle ТС
 */
export const isVehicleTractor = (vehicle: ?Vehicle): boolean => {
  if (!isEmpty(vehicle) && vehicle?.vehicleModel?.type) {
    const {
      vehicleModel: { type }
    } = vehicle;
    return isVehicleTypeTractor(type);
  }
  return false;
};

/**
  Форматируют рубли в строку для отображеия в интерфейсе
*/
export const formatRub = (rubli: number, currency: string = 'RUB') => {
  return toLocalStringRu(rubli || 0, {
    style: 'currency',
    currency
  });
};

export const toLocalStringRu = (value: any, option: any = {}) => {
  return value != null ? value.toLocaleString('ru-RU', option) : value;
};

/**
 * Функция для проверки того, что целевое число больше базового
 * на заданный разрешенный процент.
 *
 * Например, число целовое число 105 больше базового 100 на 5%
 *
 *
 * @param base Базовое значение
 * @param target Целевое значение
 * @param deviationPercent Заданный процент
 * @returns {boolean}
 */
export const isMoreThanByPercent = (
  target: number,
  base: number,
  deviationPercent: number
) => {
  if (target > base) {
    const difference = Math.abs(base - target);
    const differencePercent = dividedBy(multipliedBy(difference, 100), base);
    return differencePercent <= deviationPercent;
  }
  return true;
};

export const isImage = function(contentType: string) {
  return (
    contentType === 'image/jpeg' ||
    contentType === 'image/gif' ||
    contentType === 'image/png' ||
    contentType === 'image/webp'
  );
};

/**
 * Валидация файла на основе тега
 * @param contentType Mime тип изображения
 * @param tag Тег изображения
 */
export const validateFile = (contentType: string, tag: FileTag) => {
  switch (tag) {
    case fileTagEnum.album:
      return isImage(contentType) ? null : 'Загружать можно только изображения';
    default:
      return isImage(contentType) || contentType === 'application/pdf'
        ? null
        : 'Загружать можно только изображения и PDF!';
  }
};

/**
 * Валидация порядка следования маршрутных точек
 * @param waypoints Маршрутные точки
 * @returns {string}
 */
export const validateWaypointsOrder = (waypoints: WayPoint[]) => {
  // Так как данные могут прийти в любом порядке, то нормализуем данные
  let wayp = cloneDeep(waypoints); // нужно работать только с копией
  let { start, transit, end } = getWaypoints(wayp);
  if (start) start.arrivedDateTime = start.departureDateTime;
  if (end) end.departureDateTime = end.arrivedDateTime;

  let verifiedWaypoints = transit;
  if (start && end) verifiedWaypoints = [start, ...verifiedWaypoints, end];

  const sorted = orderBy(verifiedWaypoints, [
    'arrivedDateTime',
    'departureDateTime'
  ]);
  if (!isEqual(sorted, verifiedWaypoints)) {
    return 'Неправильный порядок точек маршрута';
  }
};

// Полное наименование месяцев
export const MONTH = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

// список месяцев
export const SHORT_MONTH = [
  'Янв',
  'Фев',
  'Мар',
  'Апр',
  'Май',
  'Июн',
  'Июл',
  'Авг',
  'Сен',
  'Окт',
  'Ноя',
  'Дек'
];

export const printRequest = async (url) => {
  try {
    notificationLoading({message: 'Формирование печатной формы...', key: 'printing'});
    const result = await downloadRequestWithToken(url)
    notification.success({message: 'Файл успешно сформирован', description: 'Загрузка завершена'})
    return result;
  } catch (e) {
    notification.error({
      message: 'Ошибка формирования печатной формы',
      description: e.title || e.message,
    })
  } finally {
    notification.close('printing');
  }
}

export const isVehicleTypeElectric = (vehicleType: VehicleType): boolean => {
  const vehicleGroup = getVehicleGroup(vehicleType);
  return vehicleGroup === vehicleGroupEnum.electricVehicles;
};

export const showConfirmation = (question, onOkAction) => {
  Modal.confirm({
    title: 'Подтверждение',
    content: question,
    onOk: onOkAction,
  });
}

export const isTariffFact = (version: rateTypeEnum): boolean => {
  return version === rateTypeEnum.fact;
};

export const getRoundedNumber = (value?: number, maxDigits = 3) => {
  return typeof value === 'number' ? Number(value.toFixed(maxDigits)) : value;
};

export function formatNumber(number) {
  return new Intl.NumberFormat().format(parseFloat(number));
}

export function getClientLocale() {
  try {
    return Intl.NumberFormat().resolvedOptions().locale;
  } catch (err) {
    console.error("Cannot get locale from Intl")
  }
}

export function getDefaultLocale() {
  try {
    return new Intl.NumberFormat().resolvedOptions().locale;
  } catch (err) {
    console.error("Cannot get locale from Intl")
  }
}

export function isNumberExt(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}
