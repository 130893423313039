/* OrderList.js | Заявки на транспортные средства | Списки */
import React from 'react';
import { Router } from '@reach/router';
import Orders, { orderPageType } from './components/Orders';

const IncomeOrders = props => <Orders pageType={orderPageType.income} {...props} />;
const ArchiveOrders = props => <Orders pageType={orderPageType.archive} {...props} />;
const CalculationOrders = props => <Orders pageType={orderPageType.calculation} {...props} />;

export default () => (
  <Router>
    <IncomeOrders path="/" />
    <ArchiveOrders path="archive" />
    <CalculationOrders path="calculation" />
  </Router>
);
