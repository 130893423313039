// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

type Props = {
  children: Node
};

const Wrapper = styled.div`
  font-size: 22px;
  margin: 20px 0;
`;

export default ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};
