import React from 'react';
import {Router} from '@reach/router';
import ExpenseDirectionList from './ExpenseDirection/ExpenseDirectionList';
import ExpenseDirectionForm from './ExpenseDirection/ExpenseDirectionForm';
import BudgetLineItemList from './BudgetLineItem/List';
import BudgetLineItemForm from './BudgetLineItem/Form';
import ExpensesDistributionList from './ExpensesDistribution/List';
import ExpensesDistributionForm from './ExpensesDistribution/Form';

export default () => (
  <Router>
    <ExpenseDirectionList path="/" />
    <ExpenseDirectionForm path="/new" />
    <ExpenseDirectionForm path="/:expenseDirectionId/edit" />

    <BudgetLineItemList path="/budget-line-item" />
    <BudgetLineItemForm path="/budget-line-item/new" />
    <BudgetLineItemForm path="/budget-line-item/:id/edit" />

    <ExpensesDistributionList path="/distribution" />
    <ExpensesDistributionForm path="/distribution/new" />
    <ExpensesDistributionForm path="/distribution/:id/edit" />
  </Router>
);
