// @flow
import * as React from 'react';
import {SectionContent} from '../../../../../components/hoc/common/components/elements';
import Grid, {GridItem} from '../../../../../components/layout/Grid';
import Field from '../../../../../components/card/Field';
import Input from 'antd/lib/input';
import Section from '../../../../../components/layout/Section';
import SectionTitle from '../../../../Vehicles/components/SectionTitle';
import type {maintenanceEstimateMaintenanceLink} from '../../../../../lib/types/maintenanceEstimate';
import InputNumber from 'antd/lib/input-number';
import {Table} from '../../../../../components/ui';
import styled from 'styled-components';

const TableBlock = styled.div`
  margin-bottom: 35px;
`;

const TableTitle = styled.h2`
  margin: 0 0 15px;
`;

const colunmsWorks = [
  {
    title: 'Код',
    dataIndex: 'code',
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
  },
  {
    title: 'Количество работы',
    dataIndex: 'count',
  },
  {
    title: 'Нормо-часы',
    dataIndex: 'hours',
  },
  {
    title: 'Объем работы',
    dataIndex: null,
    key: 'value',
    render: value => value.hours * value.count,
  },
  {
    title: 'Цена',
    dataIndex: 'price',
    render: value => value / 100,
  },
  {
    title: 'Сумма',
    dataIndex: 'sum',
    render: (value, record) => Math.round((record.hours * record.count * (record.price / 100)) * 100) / 100,
  },
];

const colunmsParts = [
  {
    title: 'Код МТР',
    dataIndex: 'stockPart.mtr.mnemocode',
  },
  {
    title: 'МТР',
    dataIndex: 'stockPart.mtr.name',
  },
  {
    title: 'Ед. измерения материала',
    dataIndex: 'stockPart.mtr.measure',
  },
  {
    title: 'Стоимость ед. (руб. без НДС) материала',
    dataIndex: 'stockPart.cost',
    render: value => value / 100,
  },
  {
    title: 'Количество материалов',
    dataIndex: 'count',
  },
  {
    title: 'Сумма стоимости материалов',
    dataIndex: 'stockPart',
    render: (stockPart, record) => Math.round(((stockPart.cost / 100) * record.count) * 100) / 100,
  },
];

type Props = {
  maintenanceLink: maintenanceEstimateMaintenanceLink,
  onChange: Function,
  editMode: boolean,
};

type State = {
  justification: string,
  fare: number,
  totalWorkValue: number,
  totalWorkCost: number,
  totalPartCost: number,
  totalCost: number,
};

export class DefectCard extends React.Component<Props, State> {
  state = {
    justification: '',
    fare: 0,
    totalWorkValue: 0,
    totalWorkCost: 0,
    totalPartCost: 0,
    totalCost: 0,
  };
  
  componentDidMount() {
    let totalWorkValue = 0;
    let totalWorkCost = 0;
    let totalPartCost = 0;
    if (this.props.maintenanceLink?.maintenance?.detailedDefectiveStatements?.operations) {
      this.props.maintenanceLink.maintenance.detailedDefectiveStatements.operations.forEach(item => {
        totalWorkValue += item.hours * item.count;
        totalWorkCost += item.hours * item.count * (item.price / 100);
      });
    }
    if (this.props.maintenanceLink?.maintenance?.detailedDefectiveStatements?.parts) {
      this.props.maintenanceLink.maintenance.detailedDefectiveStatements.parts.forEach(item => {
        totalPartCost += (item.stockPart.cost / 100) * item.count;
      });
    }
    this.setState({
      justification: this.props.maintenanceLink.justification,
      fare: this.props.maintenanceLink.fare,
      totalCost: Math.round((totalWorkCost + totalPartCost) * 100) / 100,
      totalPartCost: Math.round(totalPartCost * 100) / 100,
      totalWorkCost: Math.round(totalWorkCost * 100) / 100,
      totalWorkValue: Math.round(totalWorkValue * 100) / 100,
    });
  }
  
  render() {
    const {maintenanceLink: link, onChange, editMode} = this.props;
    const {justification, fare, totalCost, totalPartCost, totalWorkCost, totalWorkValue} = this.state;
    return (
      <Section>
        <SectionTitle divider>Дефектно-ресурсная ведомость №{link.maintenanceId}</SectionTitle>
        <SectionContent>
          <Grid gutter="16px" cols={7}>
            <GridItem>
              <Field label="Наименование карточки работ">
                Карточка работ №{link.maintenance.detailedDefectiveStatements?.id}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Вид работ">
                {link.maintenance.maintenanceWorkType.name}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Марка">
                {link.maintenance.vehicle.vehicleModel.brandName}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Модель">
                {link.maintenance.vehicle.vehicleModel.name}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Государственный регистрационный знак">
                {link.maintenance.vehicle.licensePlate}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Обоснование">
                <Input
                  value={justification}
                  onChange={({target}) => {
                    this.setState({justification: target.value});
                    onChange({fare, justification: target.value});
                  }}
                  disabled={!editMode}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Транспортные расходы">
                <InputNumber
                  value={fare}
                  onChange={value => {
                    this.setState({fare: value});
                    onChange({justification, fare: value});
                  }}
                  min={0}
                  disabled={!editMode}
                />
              </Field>
            </GridItem>
          </Grid>
          {!!link.maintenance?.detailedDefectiveStatements?.operations?.length && (
            <TableBlock>
              <TableTitle>Работы</TableTitle>
              <Table
                data={link.maintenance.detailedDefectiveStatements.operations}
                columns={colunmsWorks}
                rowKey="id"
              />
            </TableBlock>
          )}
          {!!link.maintenance?.detailedDefectiveStatements?.parts?.length && (
            <TableBlock>
              <TableTitle>Расходники и запчасти</TableTitle>
              <Table
                data={link.maintenance.detailedDefectiveStatements.parts}
                columns={colunmsParts}
                rowKey="id"
              />
            </TableBlock>
          )}
          <Grid gutter="16px" cols={4}>
            <GridItem>
              <Field labelStyle={{fontWeight: 'bold', color: '#444'}} label="Объем работы итого">{totalWorkValue}</Field>
            </GridItem>
            <GridItem>
              <Field labelStyle={{fontWeight: 'bold', color: '#444'}} label="Стоимость работы итого">{totalWorkCost}</Field>
            </GridItem>
            <GridItem>
              <Field labelStyle={{fontWeight: 'bold', color: '#444'}} label="Стоимость МТР итого">{totalPartCost}</Field>
            </GridItem>
            <GridItem>
              <Field labelStyle={{fontWeight: 'bold', color: '#444'}} label="Прямые затраты итого">{totalCost}</Field>
            </GridItem>
          </Grid>
        </SectionContent>
      </Section>
    );
  };
}
