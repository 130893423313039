// @flow
import uniq from 'lodash/uniq';

import { vehicleGroupEnum, vehicleTypeEnum } from './vehicleTypes';
import { drivingLicenseCategoryEnum } from './index';
import type { DrivingLicenseCategory, VehicleGroup } from '../types';
import { vehicleTypesPlainTree } from '../vehicleTypesTree';

/**
 * Енумка соответствия типов ТС к категории прав ТС
 * @type {
 *   [key: VehicleType]: DrivingLicenseCategory
 * }
 */
export const vehicleTypesRightCategoryEnum = {
  ...vehicleTypesPlainTree[vehicleGroupEnum.specialBuses].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.d
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.passengerBuses].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.d
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.carLifts].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.truckCranes].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  [vehicleTypeEnum.etlNaShassyUaz]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyGaz4x4]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyGaz4x2]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyGazel4x4]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyGazel4x2]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyKamAz6x6]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyKamAz4x4]: drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.etlNaShassyFordTransit4x4]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.etlNaShassyFordTransit4x2]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.bkmNaAvtomobyle]: drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.bkmRpnNaAvtomobyle]: drivingLicenseCategoryEnum.c,
  ...vehicleTypesPlainTree[vehicleGroupEnum.tippers].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.flatbedTrucks].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  [vehicleTypeEnum.gruzovoiFurgonKlassaN1Do3Tn]: drivingLicenseCategoryEnum.b,
  [vehicleTypeEnum.gruzovoiFurgonKlassaN2Do7Tn]: drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.gruzovoiFurgonKlassaN3Svyshe7Tn]:
    drivingLicenseCategoryEnum.c,
  ...vehicleTypesPlainTree[vehicleGroupEnum.otherTrucks].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.tankTrucks].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.c
    }),
    {}
  ),
  ...vehicleTypesPlainTree[vehicleGroupEnum.utilityVehicles].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.b
    }),
    {}
  ),
  [vehicleTypeEnum.kamAzFermerSGruzovoiPloshchadkoi]:
    drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.kamAzFurgonTselnometallycheskyi]:
    drivingLicenseCategoryEnum.c,
  [vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu]:
    drivingLicenseCategoryEnum.c,
  ...vehicleTypesPlainTree[vehicleGroupEnum.cars].reduce(
    (obj, type) => ({
      ...obj,
      [type]: drivingLicenseCategoryEnum.b
    }),
    {}
  )
};

/**
 * Возвращает список категорий прав ТС для данной группы ТС
 *
 * Берет все категории прав типов ТС, относящихся к этой группе
 * и возвращает все уникальные категории прав
 *
 * @param vehicleGroup
 */
export const getRightCategoryByVehicleGroup = (
  vehicleGroup: VehicleGroup
): DrivingLicenseCategory[] => {
  const vehicleTypes = vehicleTypesPlainTree[vehicleGroup];
  const rightCategories = vehicleTypes.map(
    type => vehicleTypesRightCategoryEnum[type]
  );
  return uniq(rightCategories);
};
