// @flow

import React from 'react';
import {Router} from '@reach/router';

import {ExternalActCard, ExternalActForm, ExternalActList} from './external';
import {InternalActForm, InternalActList, MpzActForm, MpzActList, OilConsumptionAccountingList} from './internal';
import EstimatesList from './internal/estimates/List';
import EstimatesCard from './internal/estimates/Card';

export {ActInfo} from './external';
export {default as Tabs} from './components/Tabs';
export type {ActFilterParams} from './external/components';
export {CompletedWorksCard} from './internal/completedWorks/Card';
export {DefectiveResourceStatementCard} from './internal/defectiveResourceStatement/Card';

export default () => (
  <>
    <Router>
      <ExternalActList path="/" />
      <ExternalActForm path="/new" />
      <ExternalActForm path="/:actId/edit" />
      <ExternalActCard path="/:actId" />

      <MpzActList path="/internal/mpz" />
      <MpzActForm path="/internal/mpz/new/:action" />
      <InternalActList path="/internal" />
      <InternalActList path="/internal/:actType" />
      <InternalActForm path="/internal/outsourcing-act/new" type="outsourcing-act" />
      <InternalActForm path="/internal/outsourcing-act/:id/edit" type="outsourcing-act" />
      <InternalActForm path="/internal/fuels-act/new" type="fuels-act" />
      <InternalActForm path="/internal/oils-act/new" type="oils-act" />
      <InternalActForm path="/internal/fuels-act/:id/edit" type="fuels-act" />
      <InternalActForm path="/internal/fuels-act/:id/edit" type="oils-act" />
      <OilConsumptionAccountingList path="/internal/oil-consumption-accounting" />
      <EstimatesList path="/internal/estimates" />
      <EstimatesCard path="/internal/estimates/:id" />
      <EstimatesCard path="/internal/estimates/new" />
    </Router>
  </>
);
