// @flow
import Icon from 'antd/lib/icon';
import React from 'react';
import {branchBudgetSummaryCognosTypeEnum, budgetSummaryStatusEnum} from '../../../../lib/enum';

import {TabItem, Tabs} from './../../../../components/ui';
import type {BranchBudgetSummary} from '../../../../lib/types';

type Props = {
  branchData: BranchBudgetSummary[],
  id: number
};

export default (props: Props) => {
  return (
    <Tabs
      withRouter
      defaultTab={`/budget/summary/${props.id}/${props.branchData[0]?.orgUnitId}`}
    >
      {props.branchData.map(el => {
        if (el.type === branchBudgetSummaryCognosTypeEnum.primary) {
          const StatusIcon =
            el.status === budgetSummaryStatusEnum.approvement ? (
              <Icon
                type="warning"
                style={{ color: 'red', margin: '0 0 2px 3px' }}
              />
            ) : el.status === budgetSummaryStatusEnum.approved ? (
              <Icon
                type="check-circle"
                style={{ color: 'green', margin: '0 0 2px 3px' }}
              />
            ) : null;
          return (
            <TabItem
              label={
                <>
                  {el.orgUnitName} {StatusIcon}
                </>
              }
              key={el.orgUnitId}
              url={`/budget/summary/${props.id}/${el.orgUnitId}/primary`}
              activeUrl={[
                `/budget/summary/${props.id}/${el.orgUnitId}/corrected`,
                `/budget/summary/${props.id}/${el.orgUnitId}/corrected/contracts`,
                `/budget/summary/${props.id}/${el.orgUnitId}/cognos`,
                `/budget/summary/${props.id}/${el.orgUnitId}/cognos/contracts`,
                `/budget/summary/${props.id}/${el.orgUnitId}/primary`,
                `/budget/summary/${props.id}/${el.orgUnitId}/primary/contracts`
              ]}
            />
          );
        }
        return null;
      })}
    </Tabs>
  );
};
