// @flow

import React from 'react';

import notification from 'antd/lib/notification';

import { licensePlateChangeApi } from '../../../../../lib/api';
import type { LicensePlateChange } from '../../../../../lib/types';

import Filter from '../../../../services/licensePlateChange/Filter';
import { LICENSE_PLATE_CHANGE_COLUMNS } from '../../../../services/licensePlateChange';

import { ListTable } from '../../../lib';

export default class extends ListTable<LicensePlateChange> {
  fetch = async (page: number = 1) => {
    const params = super.fetch(page);
    try {
      const data = await licensePlateChangeApi.fetchLicensePlateChanges(params);
      const totalSum = await licensePlateChangeApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(LICENSE_PLATE_CHANGE_COLUMNS)}
      </>
    );
  }
}
