// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Location } from '../../lib/types';
import { locationApi } from '../../lib/api';
import { getYmapPlaces } from '../../lib/gis';
import AutocompleteSelect from './AutocompleteSelect';
import type { LocationFilterParams } from '../../containers/Locations/ASVehicles/Filter';

type Props = SelectProps & {
  filter?: LocationFilterParams,
  // Наименование поля, в котором лежит значение
  valueField?: string,
  // Выводить результаты из geolocation api
  geolocationApi?: boolean
};

export default ({
  valueField = 'id',
  geolocationApi = true,
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    filters={selectProps.filter}
    fetch={async (params = {}) => {
      let locationsList = await locationApi.fetchLocations({
        ...selectProps.filter,
        ...params
      });
      if (geolocationApi && params.search) {
        try {
          const locationsFromYandex = await getYmapPlaces(params.search);
          locationsList.data = [...locationsFromYandex, ...locationsList.data];
        } catch (error) {
          console.error(error);
        }
      }
      return locationsList; //Лист наших объектов
    }}
    fetchSingle={locationApi.fetchLocation}
    notFoundText="Объекты не найдены"
    renderOption={(location: Location, Option) => {
      return (
        <Option
          value={location[valueField] || location.name}
          key={location.id || location.name}
          location={location}
        >
          {location.name}
        </Option>
      );
    }}
    {...selectProps}
    onChange={(value: string, option: any) => {
      const { onChange } = selectProps;
      if (onChange) onChange(value, option);
    }}
  />
);
