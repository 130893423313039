// @flow
import queryString from 'query-string';
import type { DriverInspection, ExecutionBudgetGroupedList } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';
import { convertDataFromServer as convertDataFromServerGroupedList } from './executionBudget';
import type { DriverInspectionFilterParams } from '../../containers/services/driverInspections/Filter';

export const convertDataFromServer = (driverInspection: DriverInspection) => ({
  ...driverInspection,
  paymentAmount: convertFromKopToRub(driverInspection.paymentAmount),
  act: driverInspection.act
    ? actConvertDataFromServer(driverInspection.act)
    : undefined
});

export const convertDataToServer = (driverInspection: DriverInspection) => ({
  ...driverInspection,
  paymentAmount: convertFromRubToKop(driverInspection.paymentAmount)
});

export const addDriverInspection = async (
  driverInspection: DriverInspection
): Promise<DriverInspection> => {
  const added = await fetchRequest.post(
    '/driverInspection',
    convertDataToServer(driverInspection)
  );
  if (added) return convertDataFromServer(driverInspection);
  throw new Error('Не удалось создать осмотр водителей');
};

export const updateDriverInspection = async (
  driverInspection: DriverInspection
): Promise<DriverInspection> => {
  const updated = await fetchRequest.put(
    '/driverInspection',
    convertDataToServer(driverInspection)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить осмотр водителей');
};
export const deleteDriverInspection = async (
  id: number
): Promise<DriverInspection> => {
  const deleted = await fetchRequest.delete(`/driverInspection/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить осмотр водителей');
};

export const fetchDriverInspection = async (
  id: number,
  params: any = {}
): Promise<DriverInspection> => {
  const driverInspection = await fetchRequest.get(
    `/driverInspection/${id}`,
    params
  );
  if (driverInspection) return convertDataFromServer(driverInspection);
  throw new Error('Не удалось получить список осмотра водителей');
};

export const fetchVehicleDriverInspections = async (
  vehicleId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<DriverInspection>> => {
  const driverInspections = await fetchRequest.get(
    `/driverInspection/vehicle/${vehicleId}`,
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (driverInspections) {
    return {
      ...driverInspections,
      data: driverInspections.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список осмотра водителей');
};

export const fetchDriverInspections = async (
  params: FetchListParams<DriverInspection> = initialFetchListParams
): Promise<ListResponse<DriverInspection>> => {
  const driverInspections = await fetchRequest.get('/driverInspection', {
    ...initialFetchListParams,
    ...params
  });
  if (driverInspections) {
    return {
      ...driverInspections,
      data: driverInspections.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список осмотра водителей');
};

export const groupedList = async (
  params: any = {}
): Promise<ListResponse<ExecutionBudgetGroupedList>> => {
  const groupedList = await fetchRequest.get(
    `/driverInspection/groupedList`,
    params
  );
  if (groupedList)
    return {
      ...groupedList,
      data: groupedList.data.map(convertDataFromServerGroupedList)
    };
  throw new Error(
    'Не удалось получить сгруппированный список для исполнения бюджета'
  );
};

export const printReport = async (
  params: DriverInspectionFilterParams,
  fileName?: string
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/driverInspection/print',
    params,
    fileName
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<DriverInspection>> => {
  const driverInspection = await fetchRequest.put(
    `/driverInspection/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (driverInspection) {
    return {
      ...driverInspection,
      data: driverInspection.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/driverInspection/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export default {
  getTotalSum,
  updateAct,
  groupedList,
  addDriverInspection,
  updateDriverInspection,
  deleteDriverInspection,
  fetchDriverInspection,
  fetchVehicleDriverInspections,
  fetchDriverInspections,
  printReport
};
