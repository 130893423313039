// @flow
import type { AssignmentLimitGroup } from './../types';
import CommonApi from './../commonApi';
import { downloadRequestWithToken } from './index';

const assignmentLimitGroup = new CommonApi<AssignmentLimitGroup>(
  'assignmentLimitGroup',
  'группы закрепления'
);

const print = async (assignmentId: number) => {
  const printed = await downloadRequestWithToken(
    `/assignmentLimitGroup/print`,
    {
      assignmentId
    }
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default { ...assignmentLimitGroup, print };
