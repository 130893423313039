// @flow
import type { Regulation } from './../types';
import CommonApi, { type CommonApiMethod } from './../commonApi';
import { downloadRequestWithToken, fetchRequest } from './index';

type regulationApiMethod = $Shape<
  CommonApiMethod & {
    changeStatus: CommonApiMethod,
    print: number => any,
    clone: number => any
  }
>;

const regulation: regulationApiMethod = new CommonApi<Regulation>(
  'regulation',
  'регламент НТС'
);

regulation.changeStatus = new CommonApi<Regulation>(
  'regulation/changeStatus',
  'смена статуса регламента НТС'
);

regulation.print = async (id: number) => {
  const printed = await downloadRequestWithToken(`/regulation/${id}/print`);
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

regulation.clone = async (id: number) => {
  const cloned = await fetchRequest.get(`/regulation/${id}/clone`);
  if (cloned) return cloned;
  throw new Error('Не удалось клонировать регламент');
};

export default regulation;
