// @flow

import React from 'react';
import Table from 'antd/lib/table';

type Props = {
  pagination?: boolean,
  data: any[],
  loading: Boolean,
  columns: any[]
};

export default (props: Props) => {
  return <Table {...props} rowKey="id" dataSource={props.data} />;
};
