// @flow
import React from 'react';

import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';

import {budgetLineItemApi} from '../../../lib/api';
import {accessTypeEnum} from '../../../lib/enum';
import {getListInitialState, navigate} from '../../../lib/helpers';
import type {UserAccess} from '../../../lib/types';

import {withUserAccess} from '../../withUserAccess';

import Tab from '../components/Tab';

import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingExpenseDirections
];

export class BudgetLineItemList extends React.Component<Props> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: '№',
      dataIndex: 'id'
    },
    {
      title: 'Наименование',
      dataIndex: 'name'
    },
    {
      title: 'Идентификатор статьи',
      dataIndex: 'mnemocode'
    },
    {
      title: 'Код статьи для ИСУ Бюджетирование',
      dataIndex: 'cognosCode'
    },
    {
      title: 'Куратор',
      dataIndex: 'curator'
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access => addAccess.includes(access));

  render() {
    const canAdd = this.canAdd();
    return (
      <>
        <Page>
          <CommonListPage
            pageHeaderProps={{
              mainHeader: 'Статья бюджетной формы расходов',
              access: {
                add: canAdd
                  ? () =>
                      navigate(`/admin/expense-direction/budget-line-item/new`)
                  : undefined
              }
            }}
            filter={Filter}
            subHeader={<Tab />}
            crud={budgetLineItemApi}
            tableListProps={{
              columns: this.columns,
              canDelete: canAdd,
              onSelect: (id: number) =>
                navigate(`/admin/expense-direction/budget-line-item/${id}/edit`)
            }}
          />
        </Page>
      </>
    );
  }
}

export default withUserAccess(BudgetLineItemList);
