// @flow
import React, {Component, Fragment} from 'react';
import Button from 'antd/lib/button';
import {Link} from '@reach/router';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';

import type {ListState, Tire, UserAccess, Vehicle} from '../../../lib/types';
import type {AppState} from '../../../ducks/redux';
import {Dropdown, Table} from './../../../components/ui';
import {Panel, Section} from './../../../components/layout';
import Filter, {type TireFilterParams} from './Filter';
import Header from '../../../components/layout/Header';
import NavigationTabs from '../components/NavigationTabs';
import {accessTypeEnum} from '../../../lib/enum';
import {
  applyMaskToValue,
  formatDateTimeToString,
  getListInitialState,
  navigate,
  setQueryParams,
} from '../../../lib/helpers';
import {type FetchListParams, tireApi} from './../../../lib/api';
import {withUserAccess} from './../../withUserAccess';
import CopyTireButton from './components/CopyTireButton';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';
import {DecomissedStatusCode, getDecomissionStatusName} from '../../../lib/api/tire';
import qs from 'query-string';

const StyledPanel = styled(Panel)`
  padding-bottom: 0;
  padding-top: 0;
`;

const PanelTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const canAddTireAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment
];

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = ListState<Tire> & {
  filter: TireFilterParams
};

/** Вывод списка шин */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  getDefaultFilter = () => {
    const canViewAll = this.props.userAccess.some(access =>
      [accessTypeEnum.viewingAllEquipments].includes(access)
    );
    const defaultFilter = canViewAll
      ? {}
      : ({
          orgUnitId: this.props.employeeBranchOrgUnitId,
          nodeFilterType: 'branchAndChildren'
        }: TireFilterParams);
    return defaultFilter;
  };

  state = {
    ...getListInitialState(),
    filter: {},
  };
  
  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({filter: filter || this.getDefaultFilter()}, () => this.fetchTires(page || 1));
  }

  dropdown = {};

  fetchTires = async (
    page: number = 1,
    params: FetchListParams<TireFilterParams> = {}
  ) => {
    const { filter } = this.state;
    const { decomissionStatus, ...otherFilters } = filter;
    this.setState({ loading: true });
    const fetchParams = {
      ...otherFilters,
      page,
      ...params,
      ...this.getDefaultFilter() // фильтр по оргюнитам в зависимости от клэймов
    };
    if (!!decomissionStatus) {
      fetchParams.decomissionStatus = DecomissedStatusCode[decomissionStatus];
    }
    const { data, totalCount } = await tireApi.fetchTires(fetchParams);
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  renderOverlay = (tire: Tire) => (
    <Menu>
      <Menu.Item>
        <CopyTireButton
          tire={tire}
          afterSubmit={() => {
            this.fetchTires(this.state.page);
            // $FlowFixMe тут id будет всегда
            if (this.dropdown[tire.id]) {
              this.dropdown[tire.id].onVisibleChange(false);
            }
          }}
        />
      </Menu.Item>
    </Menu>
  );

  getColumns = () => [
    { title: '№', dataIndex: 'id', sorter: true, width: 25 },
    {
      title: 'Дата установки ',
      dataIndex: 'installDateByHistory',
      sorter: true,
      render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY')
    },
    {
      title: 'Дата снятия ',
      dataIndex: 'removeDateByHistory',
      sorter: true,
      render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY')
    },
    { title: 'Мнемокод', dataIndex: 'code', sorter: true },
    { title: 'Наименование', dataIndex: 'name', sorter: true },
    {
      title: 'Марка',
      dataIndex: 'brand',
      sorter: true,
      sorterKey: 'brand'
    },
    {
      title: 'Гос. номер (текущий)',
      dataIndex: 'vehicle',
      stopPropagation: true,
      render: (vehicle?: Vehicle) =>
        vehicle?.licensePlate ? (
          <Link to={`/vehicles/${vehicle.id}`}>
            {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          '-'
        )
    },
    {
      title: 'Гос. номер (при снятии)',
      dataIndex: 'wasInstalledOnVehicleLicensePlate',
      stopPropagation: true,
      render: (wasInstalledOnVehicleLicensePlate?: String, record) =>
      wasInstalledOnVehicleLicensePlate ? (
          <Link to={`/vehicles/${record.wasInstalledOnVehicleId}`}>
           {applyMaskToValue(wasInstalledOnVehicleLicensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          '-'
        ),
      sorter: true,
      sorterKey: 'wasInstalledOnVehicleLicensePlate'
    },
    { title: 'Заводской номер', dataIndex: 'factoryNumber', sorter: true },
    {
      title: 'Статус',
      dataIndex: 'decomissionStatus',
      sorter: true,
      render: (decomissionStatus: string) =>
        getDecomissionStatusName(decomissionStatus)
    },
    {
      title: '',
      width: '30px',
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (record: Tire) => (
        <Dropdown
          // $FlowFixMe тут id будет всегда
          ref={dropdown => (this.dropdown[record.id] = dropdown)}
          overlay={this.renderOverlay(record)}
        >
          <Icon style={{ fontSize: 16, color: '#2770FF' }} type="ellipsis" />
        </Dropdown>
      )
    }
  ];

  handleRowClick = (id: number) => navigate(`/equipment/tires/${id}`);

  cleanFilter = () => this.setState({ filter: {} }, this.fetchTires);

  applyFilter = (filter: TireFilterParams) =>
    this.setState({ filter }, this.fetchTires);

  render() {
    const { location, userAccess } = this.props;

    const { data, totalCount, page, pageSize, loading, filter } = this.state;

    const canAdd = userAccess.some(access => canAddTireAccess.includes(access));

    return (
      <Fragment>
        <Header
          left={
            <PanelTitle>
              <h1>Шины</h1>
            </PanelTitle>
          }
          right={
            canAdd && (
              <Link to="/equipment/tires/new">
                <Button type="primary" data-cy="addTire">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <StyledPanel>
          <NavigationTabs defaultTab="tires" />
        </StyledPanel>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            data={data.map(tire => ({
              ...tire,
              key: tire.id
            }))}
            loading={loading}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchTires}
            columns={this.getColumns()}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </Fragment>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(withUserAccess(List));
