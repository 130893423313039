// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import { Link } from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import type {
  Pass,
  ListState,
  UserAccess,
  Vehicle
} from './../../../lib/types';
import {
  getListInitialState,
  setQueryParams,
  convertVehicleToString,
  formatDateTimeToString,
  toLocalStringRu,
  navigate,
  sumWidthColumns
} from './../../../lib/helpers';
import { passApi, type FetchListParams } from './../../../lib/api';

import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';
import { setFilter } from '../../../ducks/persistFilters';

import { Section } from './../../../components/layout';
import { Table, Icon, ButtonsRow } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';
import Header from './../../../components/layout/Header';
import { withUserAccess } from './../../withUserAccess';

import { ACT_COLUMNS, TAX_ACT_COLUMNS, canHandleService } from '../lib';

import PassFilter from './Filter';
import type { PassFilterParams } from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Pass> & {
  filterPath: string
};

export const COLUMNS = [
  {
    title: 'Марка',
    sorter: true,
    width: 400,
    breakByWidth: true,
    dataIndex: 'vehicle',
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle)
  },
  {
    title: 'Наименование',
    width: 500,
    breakByWidth: true,
    dataIndex: 'name'
  },
  {
    title: 'Номер',
    width: 110,
    breakByWidth: true,
    dataIndex: 'number'
  },
  {
    title: 'Дата выдачи',
    dataIndex: 'maintenanceDate',
    width: 150,
    breakByWidth: true,
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY')
  },
  {
    title: 'Период действия',
    width: 180,
    breakByWidth: true,
    render: (record: Pass) => {
      return `${formatDateTimeToString(
        record.startDate,
        'DD.MM.YYYY'
      )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY')}`;
    }
  },
  {
    title: 'Стоимость, руб. без НДС',
    dataIndex: 'paymentAmountPass',
    width: 200,
    breakByWidth: true,
    render: (paymentAmountPass: number) =>
      toLocalStringRu(paymentAmountPass || 0)
  },
  {
    title: 'Стоимость госпошлины, руб. без НДС',
    dataIndex: 'paymentAmountTax',
    width: 200,
    breakByWidth: true,
    render: (paymentAmountTax: number) => toLocalStringRu(paymentAmountTax || 0)
  },
  {
    title: 'Стоимость итого, руб. без НДС',
    dataIndex: 'paymentAmount',
    width: 200,
    breakByWidth: true,
    render: (paymentAmount: number) => toLocalStringRu(paymentAmount || 0)
  }
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };

  columns = [
    ...COLUMNS,
    {
      title: 'Выполнения работ',
      width: 450,
      children: [...ACT_COLUMNS]
    },
    {
      title: 'Пошлина',
      width: 450,
      children: [...TAX_ACT_COLUMNS]
    }
  ];

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 40,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deletePass(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        )
      });
    }
    await this.fetchPassList(page);
  }

  deletePass = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await passApi.deletePass(id);
      await this.fetchPassList(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchPassList = async (page: number = 1, params: FetchListParams<> = {}) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({ loading: true });
    const { data, totalCount } = await passApi.fetchPasses({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  handleRowClick = (id: number) => navigate(`/services/pass/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  setPersistFilter = async (values: PassFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async (filter: PassFilterParams) => {
    await this.setPersistFilter(filter);
    await this.fetchPassList();
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchPassList();
  };

  handlePrint = async () => {
    try {
      const filter = this.props.persistFilters[this.state.filterPath];
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print'
      });
      await passApi.printReport(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <>
        <Header
          left={<h1>Пропуска</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAdd() && (
                <Link to="/services/pass/new">
                  <Button type="primary" data-cy="add">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <PassFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            bordered
            fetch={this.fetchPassList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            scroll={{
              x: sumWidthColumns(this.columns),
              y: 'calc(100vh - 300px)'
            }}
            loading={loading}
            columns={this.columns}
            data={data.map(item => ({
              ...item,
              key: item.id
            }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(List));
