// @flow
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import Popover from 'antd/lib/popover';
import AntIcon from 'antd/lib/icon';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import type {SelfVehiclePlanVehicle, VehicleModel, VehicleType} from '../../../../../lib/types';
import {monthsNumbersEnum, selfVehiclePlanVehicleTypeEnum, vehicleStatuses, vehicleTypes} from '../../../../../lib/enum';
import {checkDate, formatDate, toFixed} from '../../../../../lib/helpers';

import {AntTable} from './../../../../../components/ui';

import Filter from './../../components/FilterVehicleList';

import type {VehicleTypeData} from '../../lib';
import {MONTH} from '../../lib';

import ModalPlannedVehicle from './ModalPlannedVehicle';
import Checkbox from 'antd/lib/checkbox';
import Popconfirm from 'antd/lib/popconfirm';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const LicensePlate = styled.div`
  flex: 1;
`;
const IconWarn = styled(AntIcon)`
  font-size: 13px;
  position: relative;
  margin-left: 10px;
  top: 4px;
`;

type Props = {
  data: SelfVehiclePlanVehicle[],
  loading: boolean,
  readonly: boolean,
  isListEditing: boolean,
  date?: string | Date,
  vehiclePlanType?: VehicleTypeData,
  handleSubmit: Function,
  deleteSelectedVehicle: Function,
};

export default (props: Props) => {
  const {data, loading, readonly, vehiclePlanType, date, isListEditing, deleteSelectedVehicle} = props;
  const [touched, setTouched] = useState(false);
  const [localData, setData] = useState([]);
  const [filter, setFilter] = useState({});
  const [editSelfVehiclePlanVehicle, setEditVehicle] = useState(null);
  const [filteredMonths, setFilteredMonths] = useState([...MONTH]);

  // дата после списания
  const afterPlannedPurchaseDate = (monthIndex: number, plannedPurchaseDate?: ?string) => {
    if (date && plannedPurchaseDate && vehiclePlanType === 'plannedVehicles') {
      const year = moment(date).get('year');
      const plannedYear = moment(plannedPurchaseDate).get('year');
      if (plannedYear > year) {
        return true;
      }
      return moment(`${year}-${monthIndex + 1}-01`).isBefore(
        plannedPurchaseDate,
        'month',
      );
    }
    return false;
  };

  const onChange = (field: 'kilometrage' | 'workHours', selfVehiclePlanVehicleId: number, monthIndex: number, value: number) => {
    const rowIndex = localData.findIndex(selfVehiclePlanVehicle => {
      return selfVehiclePlanVehicle.id === selfVehiclePlanVehicleId;
    });
    if (rowIndex !== -1) {
      localData[rowIndex].months[monthIndex][field] = value;
      if (isListEditing) {
        localData[rowIndex].changed = true;
      }
      setData(cloneDeep(localData));
    }
  };

  const changeDate = (plannedVehicles: any) => {
    const rowIndex = localData.findIndex(selfVehiclePlanVehicle => {
      return selfVehiclePlanVehicle.id === editSelfVehiclePlanVehicle?.id;
    });
    if (rowIndex !== -1) {
      if (plannedVehicles.inspectionsGibddNextAct !== undefined) {
        localData[rowIndex].lastInspectionGibdd.nextActDate =
          plannedVehicles.inspectionsGibddNextAct;
      }

      if (plannedVehicles.inspectionsGtnNextAct !== undefined) {
        localData[rowIndex].lastInspectionGtn.nextActDate =
          plannedVehicles.inspectionsGtnNextAct;
      }
      setData(cloneDeep(localData));
    }
  };

  const showGibddGtnDate = (record: SelfVehiclePlanVehicle) => {
    if (record.lastInspectionGibdd?.nextActDate) {
      return checkDate(record.lastInspectionGibdd.nextActDate);
    }
    if (record.lastInspectionGtn?.nextActDate) {
      return checkDate(record.lastInspectionGtn.nextActDate);
    }
    return null;
  };

  const columns = [
    {
      title: 'Тип ТС',
      width: 300,
      fixed: 'left',
      dataIndex: 'vehicle.vehicleModel.type',
      render: (type: VehicleType) => vehicleTypes[type],
      sorter: (a: any, b: any) => {
        const first = a?.vehicle?.vehicleModel.type ?? false;
        const second = b?.vehicle?.vehicleModel.type ?? false;
        return first && second
          ? vehicleTypes[first].localeCompare(vehicleTypes[second])
          : false;
      },
    },
    {
      title: 'Maрка и модель',
      fixed: 'left',
      width: 350,
      dataIndex: 'vehicle.vehicleModel',
      render: (vehicleModel: VehicleModel, record: SelfVehiclePlanVehicle) => vehiclePlanType === 'plannedVehicles'
        ? (
          <span
            className="link-color pointer"
            onClick={() => {
              setEditVehicle(record);
            }}
          >
            {`${vehicleModel.brandName} ${vehicleModel.name}`}
          </span>
        )
        : `${vehicleModel.brandName} ${vehicleModel.name}`,
      sorter: (a: any, b: any) => {
        const first = a?.vehicle?.vehicleModel ?? false;
        const second = b?.vehicle?.vehicleModel ?? false;
        return first && second
          ? `${first.brandName} ${first.name}`.localeCompare(
            `${second.brandName} ${second.name}`,
          )
          : false;
      },
    },
    {
      title: 'Госномер',
      fixed: 'left',
      width: 125,
      dataIndex: 'vehicle.licensePlate',
      render: (licensePlate: string, record: SelfVehiclePlanVehicle) => (
        <Container>
          <LicensePlate>{licensePlate}</LicensePlate>
          {record.plannedWriteoffDate && (
            <Popover
              content={
                <>
                  Планируемая дата списания:{' '}
                  {formatDate(record.plannedWriteoffDate)}
                </>
              }
              placement="right"
            >
              <IconWarn
                type="exclamation-circle"
                theme="twoTone"
                twoToneColor="#faad14"
              />
            </Popover>
          )}
        </Container>
      ),
      sorter: (a: any, b: any) => {
        const first = a?.vehicle?.licensePlate ?? '0';
        const second = b?.vehicle?.licensePlate ?? '0';
        return a.vehicle !== undefined ? first.localeCompare(second) : false;
      },
    },
    {
      title: 'План. дата приобретения',
      fixed: 'left',
      width: 200,
      dataIndex: 'plannedPurchaseDate',
      render: (plannedPurchaseDate: string) => formatDate(plannedPurchaseDate),
    },
    {
      title: 'Год выпуска',
      width: 100,
      dataIndex: 'vehicle.yearIssued',
    },
    {
      title: () =>
        vehiclePlanType === 'plannedVehicles'
          ? 'ТО ГИБДД/Гостехнадзор'
          : 'Текущий статус',
      width: 160,
      dataIndex: 'vehicle.status',
      render: (status: string, record: any) =>
        vehiclePlanType === 'plannedVehicles'
          ? showGibddGtnDate(record)
          : vehicleStatuses[status],
    },
    ...filteredMonths.map((month, monthIndex) => ({
      title: month.title,
      width: 300,
      children: [
        {
          title: 'Километраж',
          dataIndex: `months`,
          key: 'k' + monthIndex,
          render: (
            months: any,
            selfVehiclePlanVehicle: SelfVehiclePlanVehicle,
          ) => {
            const kilometrage = months.sort((a, b) => a.month - b.month)[monthIndex].kilometrage;
            const canShow = [
              selfVehiclePlanVehicleTypeEnum.all,
              selfVehiclePlanVehicleTypeEnum.kilometrage,
            ].includes(selfVehiclePlanVehicle.type);
            if (readonly) {
              return canShow ? kilometrage : '-';
            }
            return canShow
              ? (
                <InputNumber
                  size="small"
                  min={0}
                  value={toFixed(kilometrage)}
                  disabled={afterPlannedPurchaseDate(
                    monthIndex,
                    selfVehiclePlanVehicle.plannedPurchaseDate,
                  )}
                  onChange={kilometrage => {
                    setTouched(true);
                    onChange(
                      'kilometrage',
                      selfVehiclePlanVehicle.id,
                      monthIndex,
                      +kilometrage || 0,
                    );
                  }}
                />
              )
              : '-';
          },
          width: 150,
        },
        {
          title: 'Моточасы',
          dataIndex: `months`,
          key: 'h' +  monthIndex,
          render: (
            months: any,
            selfVehiclePlanVehicle: SelfVehiclePlanVehicle,
          ) => {
            const workHours = months.sort((a, b) => a.month - b.month)[monthIndex].workHours;
            const canShow = [
              selfVehiclePlanVehicleTypeEnum.all,
              selfVehiclePlanVehicleTypeEnum.workHours,
            ].includes(selfVehiclePlanVehicle.type);
            if (readonly) {
              return canShow ? workHours : '-';
            }
            return canShow ? (
              <InputNumber
                size="small"
                min={0}
                value={toFixed(workHours)}
                disabled={afterPlannedPurchaseDate(
                  monthIndex,
                  selfVehiclePlanVehicle.plannedPurchaseDate,
                )}
                onChange={workHours => {
                  setTouched(true);
                  onChange(
                    'workHours',
                    selfVehiclePlanVehicle.id,
                    monthIndex,
                    +workHours || 0,
                  );
                }}
              />
            ) : (
              '-'
            );
          },
          width: 150,
        },
      ],
    })),
  ];

  if (isListEditing) {
    columns.unshift({
      width: 40,
      fixed: 'left',
      dataIndex: 'id',
      key: 'deleteIcon',
      render: (id) => (
        <Popconfirm
          okText="Да"
          cancelText="Нет"
          title="Вы действительно хотите удалить ТС?"
          onConfirm={() => deleteSelectedVehicle(id)}
        >
          <Checkbox checked={true} />
        </Popconfirm>
      ),
    });
  }

  const handleSubmit = () => {
    setTouched(false);
    props.handleSubmit(localData);
  };

  const handleCancel = () => {
    setTouched(false);
    setData(cloneDeep(data));
  };

  const applyFilter = filter => setFilter(filter);

  const cleanFilter = () => setFilter({});

  useEffect(() => {
    const newData = cloneDeep(data);

    // этот блок нужен при добавлении нового тс в список или при удалении тс из списка
    // добавляем отредактированные локальные значения в список тс, чтобы введенные пользователем значения
    // не сбрасывались при добавлении-удалении тс из списка.
    if (isListEditing && data.length > 0 && localData.length > 0 && data.length !== localData.length) {
      newData.forEach((vehicle, i) => {
        const index = localData.findIndex(item => vehicle.id === item.id);
        if (index !== -1) {
          newData[i].months = localData[index].months;
        }
      });
    }

    setData(newData);

    // если бюджет сформирован не на весь год, о чем можно узнать по количеству записей в массиве months любого ТС
    // из массива ТС, фильтруем месяцы, чтобы лишние не выводились в таблицу.
    if (data[0]?.months?.length && data[0].months.length < 12) {
      const months = data[0].months.reduce((arr, {month: monthIndex}) => {
        arr.push(monthsNumbersEnum[monthIndex]);
        return arr;
      }, []);
      setFilteredMonths(MONTH.filter(month => months.includes(month.name)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <ModalPlannedVehicle
        visible={editSelfVehiclePlanVehicle !== null}
        onCancel={() => {
          setEditVehicle(null);
        }}
        addVehicle={(vehicle, plannedVehicles) => {
          changeDate(plannedVehicles);
          setEditVehicle(null);
        }}
        vehicle={editSelfVehiclePlanVehicle?.vehicle}
      />
      <Filter
        filter={filter}
        showHideEmpty={false}
        vehicleType={true}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        vehiclePlanId={localData[0]?.vehiclePlanId}
      />
      <AntTable
        columns={
          vehiclePlanType === 'selfVehicles'
            ? columns.filter(
              (column: any) => column.dataIndex !== 'plannedPurchaseDate',
            )
            : columns
        }
        data={localData
        .filter(selfVehicle =>
          filter.vehicleId ? filter.vehicleId === selfVehicle.vehicleId : true,
        )
        .filter(selfVehicle =>
          filter.type
            ? filter.type === selfVehicle.vehicle.vehicleModel.type
            : true,
        )
        .filter(selfVehicle =>
          filter.vehicleModelId
            ? filter.vehicleModelId === selfVehicle.vehicle.vehicleModelId
            : true,
        )
        .filter(selfVehicle =>
          filter.yearIssued
            ? filter.yearIssued === selfVehicle.vehicle.yearIssued
            : true,
        )}
        loading={loading}
        footer={
          touched &&
          (() => (
            <>
              <Button type="primary" size="small" onClick={handleSubmit}>
                Сохранить
              </Button>
              <Button
                style={{marginLeft: '10px'}}
                size="small"
                onClick={handleCancel}
              >
                Отмена
              </Button>
            </>
          ))
        }
      />
    </>
  );
};
