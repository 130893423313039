// @flow

import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {compareExpenseDirectionApi} from './../../lib/api';
import {formatDateTimeToString} from './../../lib/helpers';
import type {CompareExpenseDirection} from '../../lib/types';
import {orderContractorTypes, repairPlanTypes} from '../../lib/enum';

import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const { Field } = Card;

type Props = {
  compareExpenseDirectionId: string
};

type State = {
  compareExpenseDirection: ?CompareExpenseDirection
};

class ExpenseDirectionForm extends Component<Props, State> {
  state = {
    compareExpenseDirection: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.compareExpenseDirectionId, 10);
    if (id) {
      try {
        const compareExpenseDirection = await compareExpenseDirectionApi.getCompareExpenseDirection(
          id
        );
        this.setState({ compareExpenseDirection });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  render() {
    const { compareExpenseDirection } = this.state;
    const { compareExpenseDirectionId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/admin/compare-expense-direction">
                Список сопоставлений расходов и видов работ
              </Crumb>
              <Crumb
                to={`/admin/compare-expense-direction/${compareExpenseDirectionId}/edit`}
              >
                Сопоставление расхода и вида работ №{compareExpenseDirectionId}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{`Сопоставление расходов №${compareExpenseDirectionId}`}</h1>
        </StyledPanel>
        <Section>
          {compareExpenseDirection && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Вид работы">
                    {compareExpenseDirection.maintenanceWorkType.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Способ выполнения">
                    {
                      orderContractorTypes[
                        compareExpenseDirection.orderContractor
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="График работ">
                    {repairPlanTypes[compareExpenseDirection.repairPlan]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Наименование направления расхода">
                    {compareExpenseDirection.expenseDirectionName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата начала">
                    {formatDateTimeToString(
                      compareExpenseDirection.startDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания">
                    {compareExpenseDirection.endDate
                      ? formatDateTimeToString(
                          compareExpenseDirection.endDate,
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default ExpenseDirectionForm;
