// @flow
import React from 'react';
import {currentMaintenanceCostApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {TableHeader} from '../../components/ui/Table';
import {addAccessRight} from './accessRight';

const entryPointPath = '/admin/current-maintenance-cost/';

const columns = [
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModelName'
  },
  {
    title: (
      <TableHeader>Трудоёмкость работ на 1000 км. пробега (н/ч)</TableHeader>
    ),
    dataIndex: 'workEffort'
  },
  {
    title: (
      <TableHeader>Норма затрат на материалы на 1000 км. пробега</TableHeader>
    ),
    dataIndex: 'materialExpenseRate'
  },
  {
    title: (
      <TableHeader>
        Норма затрат на материалы на 1000 км. пробега с учётом коэффициента
      </TableHeader>
    ),
    dataIndex: 'materialExpenseRateWithCoefficient'
  },
  {
    title: 'Трудоемкость на 1000 м/ч (н/ч)',
    dataIndex: 'effort'
  },
  {
    title: (
      <TableHeader>
        Доля затрат на материалы от первоначальной стоимости ТС (для самоходных
        машин и прицепов
      </TableHeader>
    ),
    dataIndex: 'materialsToTotalVehiclePriceRatio'
  },
  {
    title: <TableHeader>Коэффициент увеличения цены на материалы</TableHeader>,
    dataIndex: 'costMagnificationFactor'
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));

  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}new`) : undefined
  };

  return (
    <Page>
      <CommonListPage
        crud={currentMaintenanceCostApi}
        pageHeaderProps={{
          mainHeader: 'Стоимость текущего ремонта',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
        filter={Filter}
      />
    </Page>
  );
});
