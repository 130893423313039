// @flow
import React, {useEffect, useState} from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import {Field} from 'formik';
import styled from 'styled-components';

import {Card, Selects, TripMap, VehicleSelection, WaypointsForm} from './../../../components';
import {
  driverQualificationEnum,
  gpmVehicleTypes,
  medicOwnerEnum,
  orderObjectives,
  ownerTypes,
  tripStatusEnum,
  vehicleTypeEnum,
} from '../../../lib/enum';
import type {FormErrors, FormTouched, Trip, Vehicle, WayPoint} from '../../../lib/types';
import {DriverQualificationDocument} from '../../../lib/types';
import type {Direction} from '../../../lib/gis';
import Select from './../../../components/ui/Select';
import {Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {convertFromMToKm, isVehicleTypeTractor, isVehicleTypeElectric} from '../../../lib/helpers';
import {getSelectionVehicles} from '../../Orders/lib';
import type {VehicleGroupAndType} from '../../../components/selects/VehicleTypeSelect';
import CancelButton from '../../../components/CancelButton';
import moment from 'moment';
import {driverApi} from '../../../lib/api';

const FormItem = Form.Item;
const { Option } = Select;
const CustomField = Card.Field;
const {
  DriverSelect,
  EmployeeSelect,
  VehicleTypeSelect,
  MedicOwnerTypeSelect
} = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

type FormProps = {
  dirty: boolean,
  isSubmitting: boolean,
  handleSubmit: Function,
  values: Trip,
  handleChange: Function,
  handleBlur: Function,
  touched: FormTouched<Trip> & {
    expectedWaypoints: $Shape<WayPoint>,
    expectedDistance: string,
    distance: string
  },
  errors: FormErrors<Trip> & {
    expectedWaypoints: $Shape<WayPoint>,
    expectedDistance: string,
    distance: string
  },
  tripGeometry: any,
  onCalculateGeometry: (values: any) => Promise<?Direction>,
  setFieldValue: Function,
  handleFocus: Function,
  setTouched: Function,
  onVehicleChange: (
    vehicle: Vehicle,
    waypoints: Array<WayPoint>
  ) => Promise<{
    driverId?: ?number,
    expectedWaypoint: Array<WayPoint>
  }>,
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  employeeBranchOrgUnitId: number,
  onCancel: Function,
  setError: Function
};

const changeExpectedRouteDistance = function(
  value: number,
  values: Trip,
  setFieldValueFn: Function
) {
  setFieldValueFn('expectedRoute.distance', value);
  // Значение дистанции в метрах, а расхходит считается на км, поэтому приводим к этому виду
  setFieldValueFn(
    'expectedFuelConsumption',
    (
      convertFromMToKm(value) *
      values.vehicle.vehicleModel.primaryFuelConsumption
    ).toFixed(2)
  );
};

const InnerForm = ({
  handleSubmit,
  values,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  handleFocus,
  setTouched,
  onVehicleChange,
  freeVehicles,
  freeTrailers,
  tripGeometry,
  onCalculateGeometry,
  employeeBranchOrgUnitId,
  dirty,
  isSubmitting,
  onCancel,
  setFieldError
}: FormProps) => {
  useEffect(() => {
    if (!values.driverId) {
      return;
    }
    let driver = values.driver;

    if (values.driverId !== driver?.id) {
      driverApi
        .fetchDriver(values.driverId)
        .then(data => setFieldValue('driver', data));
    }
    const drivingLicense = driver?.qualificationDocuments.find(
      (doc: DriverQualificationDocument) => {
        return doc?.qualification === driverQualificationEnum.driverLicense;
      }
    );
    const drivingLicenseIsEnd =
      !!drivingLicense &&
      moment().isSameOrAfter(drivingLicense?.documentEndDate, 'day');
    if (drivingLicenseIsEnd) {
      setFieldError(
        'driverId',
        'У выбранного водителя истек срок действия водительского удостоверения'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.driverId]);

  let trailer,
    vehicle,
    withTrailer = false;
  if (values) {
    trailer = values.trailer;
    vehicle = values.vehicle;
    withTrailer = values.withTrailer;
  }
  const { trailers, vehicles } = getSelectionVehicles({
    selectedTrailer: trailer,
    selectedVehicle: vehicle,
    trailers: freeTrailers,
    vehicles: freeVehicles,
    withTrailer
  });
  const waypointsType = values.isBusinessTrip ? 'business' : 'standart';
  const [medicType, setMedicType] = useState(
    values.startMedicFullName ? medicOwnerEnum.hired : medicOwnerEnum.own
  );
  const isTractor = isVehicleTypeTractor(values.vehicleType);
  const isElectricVehicle = isVehicleTypeElectric(values.vehicleType);

  return (
    <form onSubmit={handleSubmit}>
      <Section>
        <Content>
          <Grid gutter="16px">
            <GridItem>
              <FormItem
                label="Цель поездки"
                hasFeedback
                required
                validateStatus={
                  touched.objective && errors.objective && 'error'
                }
                help={errors.objective}
              >
                <Select
                  onChange={(value: string) =>
                    setFieldValue('objective', value)
                  }
                  onFocus={handleFocus}
                  onBlur={() => handleBlur({ target: { name: 'objective' } })}
                  name="objective"
                  value={values.objective}
                >
                  {Object.keys(orderObjectives).map(key => (
                    <Option key={key} value={key}>
                      {orderObjectives[key]}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Тип ТС"
                hasFeedback
                required
                validateStatus={
                  touched.vehicleType && errors.vehicleType && 'error'
                }
                help={errors.vehicleType}
              >
                <VehicleTypeSelect
                  placeholder="Выберите тип ТС"
                  resultAsObject
                  onChange={(vehicleType: VehicleGroupAndType) => {
                    const { group, type } = vehicleType;
                    setFieldValue('vehicleType', type);
                    setFieldValue('vehicleGroup', group);
                    // Если подходящий тип ТС, то проставляем флаг, что заявка ГПМ
                    setFieldValue('isGpm', gpmVehicleTypes.includes(type));
                  }}
                  selectableGroup
                  onFocus={handleFocus}
                  onBlur={() => handleBlur({ target: { name: 'vehicleType' } })}
                  name="vehicleType"
                  value={values.vehicleType || values.vehicleGroup}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Количество пассажиров"
                required
                validateStatus={
                  touched.workersCount && errors.workersCount && 'error'
                }
                help={errors.workersCount}
              >
                <InputNumber
                  name="workersCount"
                  min={0}
                  value={values.workersCount}
                  onChange={(value: number) =>
                    setFieldValue('workersCount', value)
                  }
                  onBlur={handleBlur}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="В распоряжение (ФИО)"
                hasFeedback
                required
                validateStatus={errors.employeeId && 'error'}
                help={errors.employeeId}
              >
                <EmployeeSelect
                  name="employeeId"
                  onChange={(value: string) =>
                    setFieldValue('employeeId', value)
                  }
                  onFocus={handleFocus}
                  value={values.employeeId}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Диспетчер"
                hasFeedback
                validateStatus={errors.dispatcherId && 'error'}
                help={errors.dispatcherId}
              >
                <EmployeeSelect
                  name="dispatcherId"
                  onChange={(value: number) =>
                    setFieldValue('dispatcherId', value)
                  }
                  onFocus={handleFocus}
                  value={values.dispatcherId}
                />
              </FormItem>
            </GridItem>
            <GridItem fullWidth>
              <FormItem
                label="Транспортное средство"
                hasFeedback
                required
                validateStatus={
                  touched.vehicleId && errors.vehicleId && 'error'
                }
                help={errors.vehicleId}
              >
                <VehicleSelection
                  vehicles={vehicles}
                  selected={values.vehicleId}
                  onSelect={async (vehicle: Vehicle) => {
                    setFieldValue('vehicleId', vehicle.id);
                    setFieldValue('vehicle', vehicle);
                    // В зависимости от того, какое ТС проставлено, меняем поля формы
                    const data = await onVehicleChange(
                      vehicle,
                      values.expectedRoute.waypoints || []
                    );
                    Object.keys(data).forEach(key => {
                      setFieldValue(key, data[key]);
                    });
                  }}
                  editable
                />
              </FormItem>
            </GridItem>
            {values.withTrailer && (
              <GridItem fullWidth>
                <FormItem
                  label="Прицеп"
                  hasFeedback
                  required
                  validateStatus={
                    touched.trailerId && errors.trailerId && 'error'
                  }
                  help={errors.trailerId}
                >
                  <VehicleSelection
                    onlyType={ownerTypes.self}
                    selected={values.trailerId}
                    vehicles={trailers}
                    onSelect={async (trailer: Vehicle) =>
                      setFieldValue('trailerId', trailer.id)
                    }
                    editable
                  />
                </FormItem>
              </GridItem>
            )}
            <GridItem fullWidth>
              <Grid gutter="16px">
                <GridItem>
                  <CustomField label="Тип мед. работника">
                    <MedicOwnerTypeSelect
                      onChange={type => setMedicType(type)}
                      value={medicType}
                    />
                  </CustomField>
                </GridItem>
                <GridItem>
                  {medicType === medicOwnerEnum.own ? (
                    <FormItem
                      label="Мед. работник при выезде"
                      hasFeedback
                      validateStatus={
                        touched.startMedicId && errors.startMedicId && 'error'
                      }
                      help={errors.startMedicId}
                    >
                      <EmployeeSelect
                        name="startMedicId"
                        onChange={(value: number) => {
                          setFieldValue(
                            'startMedicId',
                            value ? parseInt(value, 10) : undefined
                          );
                          setFieldValue('startMedicFullName', null);
                        }}
                        onFocus={handleFocus}
                        value={values.startMedicId}
                        onBlur={() =>
                          handleBlur({ target: { name: 'startMedicId' } })
                        }
                      />
                    </FormItem>
                  ) : (
                    <FormItem label="Мед. работник при выезде">
                      <Input
                        name="startMedicFullName"
                        value={values.startMedicFullName}
                        onChange={e => {
                          setFieldValue('startMedicFullName', e.target.value);
                          setFieldValue('startMedicId', null);
                        }}
                      />
                    </FormItem>
                  )}
                </GridItem>
              </Grid>
            </GridItem>
            <GridItem>
              <FormItem
                label="Водитель"
                hasFeedback
                required
                validateStatus={errors.driverId && 'error'}
                help={errors.driverId}
              >
                <DriverSelect
                  name="driverId"
                  onChange={(value: number) =>
                    setFieldValue('driverId', parseInt(value, 10) || undefined)
                  }
                  onFocus={handleFocus}
                  value={values.driverId}
                  filter={{
                    'employee.nodeId': employeeBranchOrgUnitId,
                    nodeFilterType: 'branchAndChildren'
                  }}
                  filterWithoutLicense={true}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Контролер технического обслуживания при выезде"
                hasFeedback
                validateStatus={
                  touched.startMechanicId && errors.startMechanicId && 'error'
                }
                help={errors.startMechanicId}
              >
                <EmployeeSelect
                  name="startMechanicId"
                  onChange={(value: number) =>
                    setFieldValue('startMechanicId', parseInt(value, 10))
                  }
                  onFocus={handleFocus}
                  value={values.startMechanicId}
                  onBlur={() =>
                    handleBlur({ target: { name: 'startMechanicId' } })
                  }
                />
              </FormItem>
            </GridItem>
            {values.isGpm && (
              <GridItem>
                <FormItem
                  label="Специалист, ответственный за содержание ПС в работоспособном состоянии при выезде"
                  hasFeedback
                  validateStatus={
                    touched.startGpmControllerId &&
                    errors.startGpmControllerId &&
                    'error'
                  }
                  help={errors.startGpmControllerId}
                >
                  <EmployeeSelect
                    name="startGpmControllerId"
                    onChange={(value: number) =>
                      setFieldValue('startGpmControllerId', parseInt(value, 10))
                    }
                    onFocus={handleFocus}
                    value={values.startGpmControllerId}
                    onBlur={() =>
                      handleBlur({ target: { name: 'startGpmControllerId' } })
                    }
                  />
                </FormItem>
              </GridItem>
            )}
            <GridItem fullWidth>
              <FormItem
                label="Задание"
                hasFeedback
                required
                validateStatus={errors.expectedWaypoints && 'error'}
                help={errors.expectedWaypoints}
              >
                <Field
                  name="expectedRoute.waypoints"
                  render={({ form: { setFieldValue } }) => (
                    <WaypointsForm
                      type={waypointsType}
                      waypoints={
                        values &&
                        values.expectedRoute &&
                        values.expectedRoute.waypoints
                      }
                      onChange={(waypoints: Array<WayPoint>) => {
                        setFieldValue('expectedRoute.waypoints', waypoints);
                        setTouched(['expectedRoute.waypoints']);
                      }}
                    />
                  )}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Нулевой пробег от гаража до объекта"
                validateStatus={errors.distanceAtStart && 'error'}
              >
                <InputNumber
                  name="distanceAtStart"
                  min={0}
                  decimalSeparator=","
                  step={0.01}
                  value={values.distanceAtStart}
                  onChange={(value: number) =>
                    setFieldValue('distanceAtStart', value)
                  }
                  onBlur={handleBlur}
                />
              </FormItem>
            </GridItem>
            <GridItem fullWidth>
              <FormItem
                label="Нулевой пробег от объекта до гаража"
                validateStatus={errors.distanceAtEnd && 'error'}
              >
                <InputNumber
                  name="distanceAtEnd"
                  min={0}
                  decimalSeparator=","
                  step={0.01}
                  value={values.distanceAtEnd}
                  onChange={(value: number) =>
                    setFieldValue('distanceAtEnd', value)
                  }
                  onBlur={handleBlur}
                />
              </FormItem>
            </GridItem>
            {isTractor ? (
              <GridItem>
                <FormItem
                  label="Маш. часы при выезде"
                  hasFeedback
                  validateStatus={errors.vehicleMachineHoursAtStart && 'error'}
                >
                  <InputNumber
                    name="vehicleMachineHoursAtStart"
                    value={values.vehicleMachineHoursAtStart}
                    disabled={values.status === tripStatusEnum.draft}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      setFieldValue('vehicleMachineHoursAtStart', value)
                    }
                    onBlur={handleBlur}
                  />
                </FormItem>
              </GridItem>
            ) : (
              <GridItem>
                <FormItem
                  label="Пробег при выезде"
                  hasFeedback
                  validateStatus={errors.currentOdometerAtStart && 'error'}
                  help={errors.currentOdometerAtStart}
                >
                  <InputNumber
                    name="currentOdometerAtStart"
                    value={values.currentOdometerAtStart}
                    disabled={values.status !== tripStatusEnum.verified}
                    decimalSeparator=","
                    step={0.01}
                    onChange={(value: number) =>
                      setFieldValue('currentOdometerAtStart', value)
                    }
                    onBlur={handleBlur}
                  />
                </FormItem>
              </GridItem>
            )}
            <GridItem>
              <FormItem
                label={isElectricVehicle ? "Расход электроэнергии по нормативу, кВт·ч/100 км" : "Расход топлива по нормативу, л"}
                hasFeedback
                validateStatus={errors.expectedFuelConsumption && 'error'}
                help={errors.expectedFuelConsumption}
              >
                <InputNumber
                  name="expectedFuelConsumption"
                  decimalSeparator=","
                  disabled={values.status === tripStatusEnum.draft}
                  step={0.1}
                  value={values.expectedFuelConsumption}
                  onChange={(value: number) =>
                    setFieldValue('expectedFuelConsumption', value)
                  }
                  onBlur={handleBlur}
                />
              </FormItem>
            </GridItem>
            <GridItem>
              <FormItem
                label="Предполагаемый пробег, км"
                validateStatus={errors.expectedDistance && 'error'}
              >
                <InputNumber
                  name="expectedRoute.distance"
                  decimalSeparator=","
                  step={0.01}
                  min={0}
                  value={values.expectedRoute && values.expectedRoute.distance}
                  onChange={(value: number) =>
                    changeExpectedRouteDistance(value, values, setFieldValue)
                  }
                  onBlur={handleBlur}
                />
              </FormItem>
              {/* Считаем маршрут и показываем на карте */}
              <div>
                {tripGeometry && (
                  <TripMap
                    geoJSON={tripGeometry.geoJSON}
                    waypoints={values.expectedRoute.waypoints}
                  />
                )}
                <Button
                  onClick={async () => {
                    const tripGeometry = await onCalculateGeometry(values);
                    if (tripGeometry) {
                      changeExpectedRouteDistance(
                        convertFromMToKm(tripGeometry.distance),
                        values,
                        setFieldValue
                      );
                      const distanceAtStart = convertFromMToKm(
                        tripGeometry.distanceAtStart
                      ).toFixed(2);
                      const distanceAtEnd = convertFromMToKm(
                        tripGeometry.distanceAtEnd
                      ).toFixed(2);
                      setFieldValue('distanceAtStart', distanceAtStart);
                      setFieldValue('distanceAtEnd', distanceAtEnd);
                    }
                  }}
                >
                  Построить маршрут
                </Button>
              </div>
            </GridItem>
          </Grid>
        </Content>
      </Section>
      <Footer>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Сохранить
        </Button>
        <CancelButton dirty={dirty} onClick={onCancel}>
          Отменить
        </CancelButton>
      </Footer>
    </form>
  );
};

export default InnerForm;
