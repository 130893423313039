// @flow

import React, {type Node, useCallback, useEffect} from 'react';
import notification from 'antd/lib/notification';

import PageHeader, {type PageHeaderProps} from './PageHeader';
import type {CommonFormProps} from './CommonForm';

import {Section} from '../../../layout';
import {SectionContent} from '../components/elements';
import {notificationLoading} from '../../../Notifications';

/**
 *  Пропсы обобщенной страницы карточек
 */
type CardPageProps<T> = {
  // Запрос данных
  onFetch?: () => any,
  // Отправка данных
  onSubmit?: T => any,
  // Отменить измененный данные
  onCancel?: T => any,
  // пропсы заголовка страницы
  pageHeaderProps: PageHeaderProps,
  //
  children: ((props: CommonFormProps<T>) => Node) | Node
};

/**
 *  Карточка страницы
 */
export const CardWrapper = (props: { children: Node }) => {
  return (
    <Section>
      <SectionContent>{props.children}</SectionContent>
    </Section>
  );
};

export default <T>({ onFetch, ...props }: $Exact<CardPageProps<T>>) => {
  // Метод запроса данных с сервера
  const handleFetch = useCallback(async () => {
    try {
      onFetch && (await onFetch());
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }, [onFetch]);

  // Загрузка данных при первой инициализации
  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  // Метод Отправки данных на сервер
  const onSubmit = useCallback(
    async (payload: T) => {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving'
        });
        await props.onSubmit?.(payload);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      } finally {
        notification.close('saving');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onSubmit]
  );

  // Метод отмены внесенных изменений
  const onCancel = useCallback(
    async (payload: T) => {
      try {
        await props.onCancel?.(payload);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onCancel]
  );

  /**
   *  Обертка для children. Обертывает каждый элемент в "карточки"
   *  В случае, если встречает объект со свойством noWrapMe, то не оборачивает его
   */
  const Content = useCallback(() => {
    let content;

    const resolveChildren = (
      children: ((props: any) => React$Element<any>) | React$Element<any>
    ): React$Element<any> => {
      if (React.isValidElement(children) && typeof children !== 'function') {
        return React.cloneElement(children, { onSubmit, onCancel });
      } else if (typeof children === 'function') {
        return children({ onSubmit, onCancel });
      } else {
        return children;
      }
    };

    content = React.Children.map(props.children, (child: any) => {
      const c = resolveChildren(child);
      if (c.props?.noWrapMe) {
        return c;
      } else return <CardWrapper>{c || null}</CardWrapper>;
    });
    return content;
  }, [onCancel, onSubmit, props.children]);

  return (
    <>
      <PageHeader {...props.pageHeaderProps} />
      <Content />
    </>
  );
};
