// @flow
import React from 'react';

import {inspectionGtnCostApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {approveTypes, vehicleTypes} from '../../lib/enum';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {handlingAccessRight} from './accessRight';

const entryPointPath = '/admin/inspection-gtn-cost/';

const columns = [
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Стоимость за один осмотр (работа)',
    dataIndex: 'cost'
  },
  {
    title: 'Стоимость за один осмотр (пошлина)',
    dataIndex: 'tax'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleType',
    render: (vehicleType: string) => {
      return vehicleTypes[vehicleType];
    }
  },
  {
    title: 'Периодичность прохождения ТО (мес.)',
    dataIndex: 'maintenanceIntervals'
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status]
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => handlingAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={inspectionGtnCostApi}
        pageHeaderProps={{
          mainHeader: 'Стоимость и периодичность ТО Гостехнадзор',
          access: {
            add: canHandling() ? () => navigate(`${entryPointPath}new`) : null
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
