// @flow
import React from 'react';

import {inspectionGibddCostApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {approveTypes} from '../../lib/enum';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {handlingAccessRight} from './accessRight';

const entryPointPath = '/admin/inspection-gibdd-cost/';

const columns = [
  {
    title: '№',
    dataIndex: 'id',
  },
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Стоимость за один осмотр (работа)',
    dataIndex: 'cost',
  },
  {
    title: 'Подкатегория ТС',
    dataIndex: 'subCategory.name',
    render: name => name || '-',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status],
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY'),
  },
];

export default withUserAccess(({userAccess}) => {
  const canHandling = () =>
    userAccess.some(access => handlingAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={inspectionGibddCostApi}
        pageHeaderProps={{
          mainHeader: 'Стоимость ТО ГИБДД',
          access: {
            add: canHandling() ? () => navigate(`${entryPointPath}new`) : null,
          },
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
        }}
        filter={Filter}
      />
    </Page>
  );
});
