// @flow
import React from 'react';
import {budgetsForAnalysisApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {approveTypes, pseudoNameTypes} from '../../lib/enum';
import {handlingAccessRight} from './accessRight';

const entryPointPath = '/admin/budgets-for-analysis/';

const columns = [
  {
    title: 'Филиал',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Наименование бюджета',
    dataIndex: 'budgetVersion.name'
  },
  {
    title: 'Псевдо имя',
    dataIndex: 'pseudoName',
    render: (pseudoName: string) => {
      return pseudoNameTypes[pseudoName];
    }
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    render: status => approveTypes[status]
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => handlingAccessRight.includes(access));
  return (
    <Page>
      <CommonListPage
        crud={budgetsForAnalysisApi}
        pageHeaderProps={{
          mainHeader: 'Бюджеты для анализа',
          access: {
            add: canHandling() ? () => navigate(`${entryPointPath}new`) : null
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
        filter={Filter}
      />
    </Page>
  );
});
