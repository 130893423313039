// @flow
import {entityStatusEnum} from '../enum';
import type {ModelMatches} from './modelMAtches';

export const MaintenanceTypesEnum = {
  car: 'car',
  truckKD: 'truckKD',
  truckDD: 'truckDD',
  busKD: 'busKD',
  busDD: 'busDD',
};

export const MaintenanceTypes = {
  car: 'ТО легкового автомобиля',
  truckKD: 'ТО грузового автомобиля с КД',
  truckDD: 'ТО грузового автомобиля с ДД',
  busKD: 'ТО автобуса с КД',
  busDD: 'ТО автобуса с ДД',
};

export const MaintenanceTypeCodes = {
  car: '1',
  truckKD: '2',
  truckDD: '3',
  busKD: '4',
  busDD: '5',
};

export type MaintenanceWork = {
  code: string,
  hoursNorm: number,
  id: number,
  maintenanceType: $Keys<MaintenanceTypesEnum>,
  maintenanceTypeCode: $Values<MaintenanceTypeCodes>,
  name: string,
  number: string,
  status: $Keys<entityStatusEnum>,
  vehicleModelMatch: ModelMatches,
  vehicleModelMatchId: number,
};
