// @flow
import React from 'react';
import styled from 'styled-components';

import {
  expensesDistributionApi,
  displayExpenseDirectionApi
} from '../../../lib/api';
import {
  accessTypeEnum,
  expenseDirectionDisplayRuleType
} from '../../../lib/enum';
import {
  getListInitialState,
  navigate,
  tooltipFullText
} from '../../../lib/helpers';
import type { UserAccess } from '../../../lib/types';

import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';

import { withUserAccess } from '../../withUserAccess';

import Tab from '../components/Tab';

import Filter from './components/Filter';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingExpenseDirections
];

const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;

export class BudgetLineItemList extends React.Component<Props> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: 'Расчётное направление расходов ',
      dataIndex: 'estimateExpenseDirection.name',
      render: name => <WhiteSpace>{tooltipFullText(name, 35)}</WhiteSpace>
    },
    {
      title: 'Расчётная статья бюджетной формы',
      dataIndex: 'estimateExpenseDirection.budgetLineItemName',
      render: budgetLineItemName => (
        <WhiteSpace>{tooltipFullText(budgetLineItemName, 35)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'expenseDirection.name',
      title: 'Направление расходов отображения',
      key: 'expenseDirection.name',
      render: expenseDirectionName => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 35)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'expenseDirection.budgetLineItemName',
      title: 'Статья бюджетной формы отображения',
      key: 'expenseDirection.budgetLineItemName',
      render: expenseDirectionName => (
        <WhiteSpace>{tooltipFullText(expenseDirectionName, 35)}</WhiteSpace>
      )
    },
    {
      dataIndex: 'displayRule',
      title: 'Правило отображения',
      key: 'displayRule',
      render: displayRule => expenseDirectionDisplayRuleType[displayRule]
    }
  ];

  canAdd = () =>
    this.props.userAccess.some(access => addAccess.includes(access));

  onDelete = async (id: number, record: any) => {
    if (record.expensesDistributionId) {
      await displayExpenseDirectionApi.delete(id);
    } else {
      await expensesDistributionApi.delete(id);
    }
  };

  render() {
    const canAdd = this.canAdd();
    return (
      <>
        <Page>
          <CommonListPage
            pageHeaderProps={{
              mainHeader: 'Распределение затрат',
              access: {
                add: canAdd
                  ? () => navigate(`/admin/expense-direction/distribution/new`)
                  : undefined
              }
            }}
            filter={Filter}
            subHeader={<Tab />}
            crud={expensesDistributionApi}
            oldCrud={{ onDelete: this.onDelete }}
            tableListProps={{
              columns: this.columns,
              params: {
                childrenColumnName: 'displayExpenseDirections',
                expandRowByClick: true,
                expandIconAsCell: false,
                expandIconColumnIndex: 0,
                bordered: true
              },
              canDelete: canAdd,
              onSelect: (id: number) =>
                navigate(`/admin/expense-direction/distribution/${id}/edit`)
            }}
          />
        </Page>
      </>
    );
  }
}

export default withUserAccess(BudgetLineItemList);
