// @flow
import React, {useEffect, useState} from 'react';

import Popconfirm from 'antd/lib/popconfirm';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';

import {vehiclePlanApi} from './../../../../lib/api';
import type {VehiclePlanStatus} from '../../../../lib/types';
import {VehiclePlan} from '../../../../lib/types';
import {vehiclePlanStatus, vehiclePlanStatusEnum} from '../../../../lib/enum';
import {formatDateRangeString, formatDateTimeToString, navigate, setQueryParams} from '../../../../lib/helpers';

import Page from './../../../../components/hoc/common/handbook/PageContext';
import CommonListPage from './../../../../components/hoc/common/handbook/ListPage';
import {Dropdown, TabItem, Tabs} from './../../../../components/ui';
import {withUserAccess} from './../../../withUserAccess';
import {canAdd} from '../accessRight';

import Filter from './components/Filter';
import {connect} from 'react-redux';
import type {AppState} from '../../../../ducks/redux';

const columns = [
  {
    title: 'Филиал',
    key: 'orgUnitName',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Планируемый период',
    key: 'startDate',
    dataIndex: 'startDate',
    render: (text: string, record: any): ?string =>
      formatDateRangeString(record.startDate, record.endDate, 'D MMMM YYYY'),
  },
  {
    title: 'Версия бюджета',
    key: 'budgetVersionName',
    dataIndex: 'budgetVersion.name',
  },
  {
    title: 'Дата формирования',
    key: 'date',
    dataIndex: 'date',
    width: '150',
    render: (created: string) =>
      `${formatDateTimeToString(created, 'DD.MM.YYYY')}`,
  },
  {
    title: 'Наименование',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Статус',
    key: 'status',
    dataIndex: 'status',
    render: (status: VehiclePlanStatus) =>
      status ? vehiclePlanStatus[status] : '-',
  },
];

const access = {
  add: () => navigate(`/budget/vehicle/new`),
};

const List = ({userAccess, persistFilters}) => {
  const [currentTab, setCurrentTab] = useState(+window.localStorage.getItem('vehiclePlanTab') || 1,);
  const [reFetch, setReFetch] = useState(false);

  useEffect(() => {
    // костыльное решение проброса параметров фильтра из стора. сначала очищаем все возможные параметры в строке запроса,
    // затем, также в строку запроса проставляем все параметры из "постоянных фильтров" из стора
    setQueryParams({
      status: undefined,
      startDate: undefined,
      endDate: undefined,
      branchId: undefined,
      nodeId: undefined,
      id: undefined,
      budgetVersionId: undefined,
      number: undefined,
      page: undefined,
      ...persistFilters[`${window.location.pathname}/${currentTab === 1 ? 'beforeCognos' : 'afterCognos'}`]
    });
  }, [currentTab]);

  const toggleTab = (tabIndex: number) => {
    if (tabIndex !== currentTab) {
      window.localStorage.setItem('vehiclePlanTab', tabIndex);
      setCurrentTab(tabIndex);
    }
  };

  const onDelete = async (id: number) => {
    try {
      await vehiclePlanApi.delete(id);
      await setReFetch(true);
    } catch (error) {
    } finally {
      setReFetch(false);
    }
  };

  const columnsTable =
    canAdd(userAccess) && currentTab === 1
      ? [
        ...columns,
        {
          stopPropagation: true,
          title: '',
          dataIndex: 'id',
          render: (id: number) => {
            return (
              <Dropdown
                key={id}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() =>
                        navigate(`/budget/vehicle/${id}/card/clone`)
                      }
                    >
                      Копировать перечень
                    </Menu.Item>
                    <Menu.Item>
                      <Popconfirm
                        overlayStyle={{
                          zIndex: 2000,
                        }}
                        placement="left"
                        title={<>Вы действительно хотите удалить?</>}
                        onConfirm={() => {
                          onDelete(id);
                        }}
                      >
                        Удалить
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Icon
                  style={{fontSize: 16, color: '#2770FF'}}
                  type="ellipsis"
                />
              </Dropdown>
            );
          },
        },
      ]
      : [...columns];
  return (
    <Page>
      <CommonListPage
        crud={{
          ...vehiclePlanApi,
          fetch: params =>
            vehiclePlanApi.fetch({...params, iscognos: currentTab !== 1}),
        }}
        reFetch={reFetch}
        pageHeaderProps={{
          mainHeader: 'Бюджет СТС',
          access: canAdd(userAccess) ? access : undefined,
        }}
        tableListProps={{
          columns: [
            {
              title: '№',
              key: 'idBudgetVehicle',
              dataIndex: currentTab === 1 ? 'id' : 'number',
            },
            ...columnsTable,
          ],
          onSelect: (id: number, vehiclePlan: VehiclePlan) => {
            if (vehiclePlan?.status === vehiclePlanStatusEnum.listEditing) {
              navigate(`/budget/vehicle/${id}/card/${vehiclePlanStatusEnum.listEditing}`);
            } else {
              navigate(`/budget/vehicle/${id}/card`);
            }
          },
        }}
        filter={props => (
          <Filter {...props} isBeforeCognosTab={currentTab === 1} />
        )}
        subHeader={
          <Tabs defaultTab={currentTab}>
            <TabItem
              tabKey={1}
              label="До рассмотрения в ИСУ"
              onClick={() => toggleTab(1)}
            />
            <TabItem
              tabKey={2}
              label="После рассмотрения в ИСУ"
              onClick={() => toggleTab(2)}
            />
          </Tabs>
        }
      />
    </Page>
  );
};

export default connect((state: AppState) => ({persistFilters: state.persistFilters}))(withUserAccess(List));
