// @flow
import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { CurrentMaintenanceCost } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';

export default (props: CommonFormProps<$Shape<CurrentMaintenanceCost>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  required
                  fast
                  label="Модель ТС"
                  name="vehicleModelId"
                >
                  {({ value, name }) => (
                    <VehicleModelSelect
                      value={value}
                      name={name}
                      filter={{
                        returnDeleted: false
                      }}
                      onChange={async (value: number) =>
                        setFieldValue(name, value)
                      }
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Трудоёмкость работ на 1000 км. пробега (н/ч)"
                  fast
                  required
                  name="workEffort"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Норма затрат на материалы на 1000 км. пробега (руб.)"
                  fast
                  required
                  name="materialExpenseRate"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => {
                        setFieldValue(
                          'materialExpenseRateWithCoefficient',
                          (values.costMagnificationFactor ?? 0) * value
                        );
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Норма затрат на материалы на 1000 км. пробега с учётом коэффициента (руб.)"
                  fast
                  required
                  name="materialExpenseRateWithCoefficient"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      disabled
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Трудоемкость на 1000 м/ч (н/ч)"
                  fast
                  required
                  name="effort"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Доля затрат на материалы от первоначальной стоимости ТС (для самоходных машин и прицепов)"
                  fast
                  required
                  name="materialsToTotalVehiclePriceRatio"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Коэффициент увеличения цены на материалы"
                  fast
                  required
                  name="costMagnificationFactor"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      onChange={value => {
                        setFieldValue(
                          'materialExpenseRateWithCoefficient',
                          (values.materialExpenseRate ?? 0) * value
                        );
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата начала" name="startDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата окончания" name="endDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
