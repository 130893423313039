// @flow
import React from 'react';
import Input from 'antd/lib/input';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem
} from '../../../../components';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import Field from '../../../../components/card/Field';
import { SectionContent } from '../../../../components/hoc/common/components/elements';

export type BudgetLineItemFilterParams = {
  name?: string,
  mnemocode?: string
};

type Props = {
  filter: BudgetLineItemFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<BudgetLineItemFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Наименование"
                  size="small"
                  onChange={e =>
                    changeValue('name', e.target.value || undefined)
                  }
                  value={values.name}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Идентификатор статьи"
                  size="small"
                  onChange={e =>
                    changeValue('mnemocode', e.target.value || undefined)
                  }
                  value={values.mnemocode}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);
