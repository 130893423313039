// @flow

import React, {Component} from 'react';

import isEqual from 'lodash/isEqual';
import notification from 'antd/lib/notification';

import type {SelfVehiclePlanVehicle, VehiclePlanStatus} from '../../../../../lib/types';
import {vehiclePlanStatusEnum} from '../../../../../lib/enum';
import {vehiclePlanApi} from './../../../../../lib/api';

import {notificationLoading} from '../../../../../components/Notifications';

import type {VehicleTypeData} from '../../lib';
import InnerTable from './InnerTable';
import InnerTableReadOnly from './InnerTableReadOnly';

type Props = {
  // тс согласно ИК
  data: SelfVehiclePlanVehicle[],
  date?: string | Date,
  loading: boolean,
  vehiclePlanType?: VehicleTypeData,
  readonly?: boolean,
  readonlyFields?: boolean,
  isListEditing?: boolean,
  vehiclePlanStatus?: VehiclePlanStatus,
  changeEditVehiclePlanVehicle?: Function,
  onChangeData?: Function,
  deleteSelectedVehicle?: Function,
  selectedVehicle?: ?(SelfVehiclePlanVehicle[])
};
type State = {
  localLoading: boolean,
  localData: SelfVehiclePlanVehicle[]
};

export default class extends Component<Props, State> {
  state = {
    localLoading: false,
    localData: [],
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.data, this.props.data)) {
      this.setState({
        localData: this.props.data,
      });
    }
  }

  handleSubmit = async (data: SelfVehiclePlanVehicle[]) => {
    this.setState({localLoading: true});
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await vehiclePlanApi.updateVehicles(data);
      this.setState({localData: data});
      if (this.props.onChangeData) {
        this.props.onChangeData(data);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
      this.setState({localLoading: false});
    }
  };

  render() {
    const {
      data,
      loading,
      vehiclePlanType,
      readonly,
      vehiclePlanStatus,
      selectedVehicle,
      changeEditVehiclePlanVehicle,
      date,
      readonlyFields,
      deleteSelectedVehicle,
      isListEditing,
    } = this.props;
    const {localLoading, localData} = this.state;
    return (readonly || isListEditing)
      ? (
        <InnerTableReadOnly
          data={localData}
          date={date}
          loading={loading || localLoading}
          vehiclePlanType={vehiclePlanType}
          readonly={[
            vehiclePlanStatusEnum.approvement,
            vehiclePlanStatusEnum.approved,
            vehiclePlanStatusEnum.declined,
            vehiclePlanStatusEnum.listApproved,
          ].includes(vehiclePlanStatus) || !!readonlyFields}
          handleSubmit={this.handleSubmit}
          isListEditing={isListEditing}
          deleteSelectedVehicle={deleteSelectedVehicle}
        />
      )
      : (
        <>
          <InnerTable
            vehiclePlanType={vehiclePlanType}
            data={data}
            loading={loading}
            changeEditVehiclePlanVehicle={changeEditVehiclePlanVehicle}
            selectedVehicle={selectedVehicle}
          />
        </>
      );
  }
}
