// @flow

import React, { Component } from 'react';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import Popconfirm from 'antd/lib/popconfirm';

import type { LoadUnloadCargo } from '../../../lib/types';
import { Icon, ListTable } from '../../../components/ui';
import { DateTimePicker } from '../../../components';
import { withEmptyRow } from '../../../lib/helpers';
import { loadUnloadCargoApi } from '../../../lib/api';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const { TextArea } = Input;

type Props = {
  loadUnloads: LoadUnloadCargo[],
  onChange: Function
};

/**
 * Компонент отображает список прикрепленных к заявке по доп. найму ТС погрузок/разгрузок
 */
class LoadUnloadCargosForm extends Component<Props> {
  static defaultProps = {
    loadUnloads: []
  };

  onChange(index: number, key: $Keys<LoadUnloadCargo>, value: any) {
    let loadUnloads = withEmptyRow([...this.props.loadUnloads]);
    loadUnloads.splice(index, 1, {
      ...loadUnloads[index],
      [(key: string)]: value
    });
    this.props.onChange(loadUnloads);
  }

  onDelete(index: number) {
    this.props.onChange(
      this.props.loadUnloads.filter((value, id) => id !== index)
    );
  }

  render() {
    const { loadUnloads } = this.props;
    return (
      <ListTable
        columns={[
          {
            title: 'Адрес',
            key: 'address',
            render: (
              address: string,
              record: LoadUnloadCargo,
              index: number
            ) => (
              <Input
                onChange={e => this.onChange(index, 'address', e.target.value)}
                value={address}
                name="name"
                placeholder="Введите адрес"
              />
            )
          },
          {
            title: 'Начало',
            key: 'startDateTime',
            width: '210px',
            render: (
              startDateTime: string,
              record: LoadUnloadCargo,
              index: number
            ) => (
              <DateTimePicker
                onChange={(dateTime: string) =>
                  this.onChange(index, 'startDateTime', dateTime)
                }
                value={startDateTime}
                name="startDateTime"
              />
            )
          },
          {
            title: 'Конец',
            key: 'endDateTime',
            width: '210px',
            render: (
              endDateTime: string,
              record: LoadUnloadCargo,
              index: number
            ) => (
              <DateTimePicker
                onChange={(dateTime: string) =>
                  this.onChange(index, 'endDateTime', dateTime)
                }
                value={endDateTime}
                name="endDateTime"
              />
            )
          },
          {
            title: 'Контактные лица',
            key: 'contactEmployees',
            render: (
              contactEmployees: string,
              record: LoadUnloadCargo,
              index: number
            ) => (
              <TextArea
                onChange={e =>
                  this.onChange(index, 'contactEmployees', e.target.value)
                }
                value={contactEmployees}
                name="name"
                autosize={{ minRows: 1 }}
              />
            )
          },
          {
            width: '20px',
            renderRecord: (record: LoadUnloadCargo, index: number) =>
              !isEmpty(record) ? (
                <Operations>
                  {record.id ? (
                    <Popconfirm
                      title="Вы уверены, что хотите удалить?"
                      onConfirm={async () => {
                        if (record.id)
                          await loadUnloadCargoApi.deleteLoadUnloadCargo(
                            record.id
                          );
                        this.onDelete(index);
                      }}
                    >
                      <StyledIcon type="x" />
                    </Popconfirm>
                  ) : (
                    <StyledIcon onClick={() => this.onDelete(index)} type="x" />
                  )}
                </Operations>
              ) : null
          }
        ]}
        data={withEmptyRow(loadUnloads)}
      />
    );
  }
}

export default LoadUnloadCargosForm;
