// @flow

import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import CloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, {Component, Fragment, useEffect, useState} from 'react';
import Field from '../../../../components/card/Field';
import {Grid, GridItem, Section} from '../../../../components/layout';
import {notificationLoading} from '../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../components/ui';
import {vehiclePlanApi, washingCalculationApi} from '../../../../lib/api';
import {
  accessTypeEnum,
  calculationStatusEnum,
  monthsNamesTranslitEnum,
  monthsNumbersEnum,
  vehiclePlanStatusEnum,
} from '../../../../lib/enum';
import {applyMaskToValue, getListInitialState, toLocalStringRu} from '../../../../lib/helpers';
import {printNotification} from '../../../../lib/notificationWrapper';
import type {CalculationStatus, ListState, UserAccess, VehiclePlan, WashingCalculation} from '../../../../lib/types';
import {WashingCalculationMonth} from '../../../../lib/types';
import {withUserAccess} from '../../../withUserAccess';
import {canEditBudget} from '../accessRight';
import type {VehicleListFilterParams} from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';
import {COGNOS_DELTA, COLUMNS, filteredData, itogCalculation, MONTH} from '../lib';
import {headerPanel} from './components/Common';
import TotalBlock from './components/TotalBlock';
import styled from 'styled-components';
import InfoBlock from '../components/InfoBlock';
import {formatLicensePlateMask} from '../../../../components/masked-inputs/LicensePlateInput';
import Checkbox from 'antd/lib/checkbox';
import InputNumber from 'antd/lib/input-number';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<WashingCalculation> & {
  vehiclePlan: VehiclePlan,
  filter: VehicleListFilterParams,
  visible: boolean,
  monthIndex: ?number,
  washingCalculation: ?WashingCalculation,
  cognosData: ?number
};

const Washing = class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    vehiclePlan: null,
    visible: false,
    monthIndex: null,
    washingCalculation: null,
  };

  onCell = (monthIndex: number) => (washingCalculation: WashingCalculation) => {
    const canEdit = () => this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

    if (!canEdit()) {
      return;
    }

    return {
      onClick:
        this.isDraft() &&
        this.isRangeMonths(monthIndex) &&
        !this.afterPlannedWriteoffDate(
          monthIndex,
          washingCalculation?.selfVehiclePlanVehicle?.vehicle
            ?.plannedWriteoffDate ||
          washingCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate,
        )
          ? () => {
            this.setState({
              washingCalculation: CloneDeep(washingCalculation),
              monthIndex,
              visible: true,
            });
          }
          : undefined,
    };
  };

  isRangeMonths = (monthIndex: number): boolean => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  // дата после списания
  afterPlannedWriteoffDate = (monthIndex: number, plannedWriteoffDate?: ?string): boolean => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan && plannedWriteoffDate) {
      const year = moment(vehiclePlan.date).get('year');
      return moment(`${year}-${monthIndex + 1}-01`).isAfter(plannedWriteoffDate, 'month');
    }
    return false;
  };

  checkLink = (monthIndex: number, washingCalculation: WashingCalculation): boolean => {
    return (
      this.isRangeMonths(monthIndex) &&
      this.isDraft() &&
      !this.afterPlannedWriteoffDate(
        monthIndex,
        washingCalculation?.selfVehiclePlanVehicle?.vehicle?.plannedWriteoffDate ||
        washingCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate,
      )
    );
  };

  columns = [
    ...COLUMNS,
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      children: [
        {
          title: 'Наружная',
          dataIndex: `months[${monthIndex}].bodyCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          ),
        },
        {
          title: 'Салон',
          dataIndex: `months[${monthIndex}].interiorCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          ),
        },
        {
          title: 'Двигатель',
          dataIndex: `months[${monthIndex}].engineCount`,
          widht: 100,
          onCell: this.onCell(monthIndex),
          render: (count: number, washingCalculation: WashingCalculation) => (
            <span
              className={
                this.checkLink(monthIndex, washingCalculation)
                  ? 'link-text'
                  : null
              }
            >
              {count}
            </span>
          ),
        },
        {
          title: 'Стоимость',
          dataIndex: `months[${monthIndex}].cost`,
          widht: 100,
          className: 'primary-background',
          render: (cost: number) => toLocalStringRu(cost, {minimumFractionDigits: 2}),
        },
      ],
    })),
    {
      title: 'Итог',
      width: 100,
      key: 'sum',
      dataIndex: 'sum',
      render: (sum: number) => toLocalStringRu(sum, {minimumFractionDigits: 2}),
    },
  ];

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };

  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };

  fetch = async () => {
    const {vehiclePlanId} = this.props;
    try {
      let {data} = await washingCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      this.setState({data});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };

  handleCancel = () => {
    this.setState({
      washingCalculation: null,
      visible: false,
      monthIndex: null,
    });
  };

  handleSubmit = async (washingCalculation: WashingCalculation) => {
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      this.setState({visible: false});
      await washingCalculationApi.update(washingCalculation);
      await this.calculate();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
      this.handleCancel();
    }
  };

  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await washingCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const {vehiclePlanId} = this.props;
    const {data} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      if (data.length === 0) {
        await washingCalculationApi.calculate(vehiclePlanId);
      } else {
        await washingCalculationApi.updateCalculations(vehiclePlanId);
      }
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  isDraft = (): boolean => this.state.vehiclePlan?.washingCalculationStatus === calculationStatusEnum.draft;

  applyFilter = (filter: VehicleListFilterParams) => this.setState({filter});

  cleanFilter = () => this.setState({filter: {}});

  handlePrint = () => {
    printNotification(async () => {
      await washingCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter,
      );
    });
  };

  render() {
    const {vehiclePlanId} = this.props;
    const {
      data,
      loading,
      filter,
      visible,
      washingCalculation,
      vehiclePlan,
      cognosData,
    } = this.state;

    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);

    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus = vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : Math.abs(itog?.sum - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({
          title: 'Расчёт потребности в мойке ТС',
          vehiclePlan,
          vehiclePlanId,
        })}
        <Section>
          <InfoBlock links={{'Мойки': '/admin/washing-price-list'}} />
          <div style={{padding: '16px 16px 0px'}}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              vehiclePlanId={vehiclePlanId}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={this.columns}
            bordered
            scroll={{
              x: 5700,
              y: 'calc(100vh - 510px)',
            }}
            pagination={{pageSize: 50}}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={itog?.sum}
                controllerName="washingCalculation"
                vehiclePlanId={vehiclePlanId}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            <ButtonsRow>
              {canEditBudget(this.props.userAccess) && (
                <>
                  {this.isDraft() && (
                    <>
                      {!isCognosStatus && !loading && (
                        <Button type="primary" onClick={this.calculate}>
                          {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                        </Button>
                      )}
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!planApproved &&
                    !this.isDraft() &&
                    vehiclePlan?.status !==
                    vehiclePlanStatusEnum.approvedAfterCognos && (
                      <>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.changeStatus(calculationStatusEnum.draft)
                          }
                        >
                          Редактировать
                        </Button>
                      </>
                    )}
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Wrapper>
        </Section>

        <ModalWashing
          visible={visible}
          washingCalculation={washingCalculation}
          handleSave={this.handleSubmit}
          handleCancel={this.handleCancel}
          isRangeMonths={this.isRangeMonths}
        />
      </>
    );
  }
};

type ModalWashingProps = {
  visible: boolean,
  handleSave: Function,
  handleCancel: Function,
  isRangeMonths: Function,
  washingCalculation: ?WashingCalculation
};
const ModalWashing = (props: ModalWashingProps) => {
  const {
    visible,
    handleSave,
    handleCancel,
    washingCalculation,
    isRangeMonths,
  } = props;
  const [localWashingCalculation, setLocalWashingCalculation] = useState(washingCalculation);

  const onOk = () => handleSave(washingCalculation);

  const onCancel = () => handleCancel();

  useEffect(() => {
    if (washingCalculation) {
      setLocalWashingCalculation({...washingCalculation});
    }
  }, [washingCalculation]);

  return (
    <Modal
      destroyOnClose
      width={600}
      title="Планирование моек"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText='Сохранить'
      cancelText='Отменить'
    >
      <Grid gutter="16px">
        <GridItem>
          <Field label="Марка и модель ТС">
            {`${washingCalculation?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.brandName}
            ${washingCalculation?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.name}`}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Гос. номер">
            {washingCalculation?.selfVehiclePlanVehicle?.vehicle?.licensePlate &&
              applyMaskToValue(washingCalculation.selfVehiclePlanVehicle.vehicle.licensePlate, formatLicensePlateMask)}
          </Field>
        </GridItem>
      </Grid>
      <Grid gutter='16px' cols={4}>
        {localWashingCalculation?.months?.length &&
          localWashingCalculation.months.map((month: WashingCalculationMonth, monthIndex) => {
            const isMonthOutOfRange = !isRangeMonths(monthIndex);
            return (
              <Fragment key={month.id}>
                <GridItem customStyles={{paddingTop: '25px'}}>
                  <Checkbox
                    checked={month.needCalculate}
                    onChange={({target}) => {
                      localWashingCalculation.months[monthIndex].needCalculate = target.checked;
                      if (!target.checked) {
                        localWashingCalculation.months[monthIndex].bodyCount = 0;
                        localWashingCalculation.months[monthIndex].interiorCount = 0;
                        localWashingCalculation.months[monthIndex].engineCount = 0;
                      }
                      setLocalWashingCalculation({...localWashingCalculation});
                    }}
                    disabled={isMonthOutOfRange}
                    style={{marginRight: '10px'}}
                  />
                  <span>{monthsNamesTranslitEnum[monthsNumbersEnum[month.month]]}</span>
                </GridItem>
                <GridItem>
                  <Field label="Наружная">
                    <InputNumber
                      min={0}
                      value={localWashingCalculation?.months[monthIndex].bodyCount || 0}
                      onChange={count => {
                        if (localWashingCalculation) {
                          localWashingCalculation.months[monthIndex].bodyCount = Math.round(count);
                          setLocalWashingCalculation({...localWashingCalculation});
                        }
                      }}
                      disabled={!localWashingCalculation.months[monthIndex].needCalculate || isMonthOutOfRange}
                      size='small'
                    />
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Салон">
                    <InputNumber
                      min={0}
                      value={localWashingCalculation?.months[monthIndex].interiorCount || 0}
                      onChange={count => {
                        if (localWashingCalculation) {
                          localWashingCalculation.months[monthIndex].interiorCount = Math.round(count);
                          setLocalWashingCalculation({...localWashingCalculation});
                        }
                      }}
                      disabled={!localWashingCalculation.months[monthIndex].needCalculate || isMonthOutOfRange}
                      size='small'
                    />
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Двигатель">
                    <InputNumber
                      min={0}
                      value={localWashingCalculation?.months[monthIndex].engineCount || 0}
                      onChange={count => {
                        if (localWashingCalculation) {
                          localWashingCalculation.months[monthIndex].engineCount = Math.round(count);
                          setLocalWashingCalculation({...localWashingCalculation});
                        }
                      }}
                      disabled={!localWashingCalculation.months[monthIndex].needCalculate || isMonthOutOfRange}
                      size='small'
                    />
                  </Field>
                </GridItem>
              </Fragment>
            )
          })
        }
      </Grid>
    </Modal>
  );
};

export default withUserAccess(Washing);
