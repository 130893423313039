// @flow
import type { ModelMatches } from './modelMAtches';
import { RepairKind } from './typesOfRepairs';
import type {EntityStatusType} from './index';


export const RepairTypes = {
  repairLA: 'Ремонт ЛА',
  repairGA: 'Ремонт ГА с карбюратором',
  repairGADiesel: 'Ремонт ГА с дизелем',
  repairSelfЗropelled: ' Ремонт самоходных машин (трактора, снегоходы и т.д.)',
  repairGPM: 'Ремонт ГПМ',
  repairEquipment: 'Ремонт навесного оборудования'
};

const WorkSubTypesEnum = {
  aggregate: 'aggregate',
  electrics: 'electrics',
  powerSystemDevices: 'powerSystemDevices',
  machine: 'machine',
  cuprum: 'cuprum',
  tin: 'tin',
  smith: 'smith',
  armature: 'armature',
  tire: 'tire',
  wallpaper: 'wallpaper',
  paint: 'paint',
  body: 'body'
};

export const WorkSubTypes = {
  aggregate: 'Слесарные работы по ремонту агрегатов и узлов',
  electrics: 'Слесарные работы по ремонту электрооборудования',
  powerSystemDevices: 'Слесарные работы по ремонту приборов системы питания',
  machine: 'Станочные работы',
  cuprum: 'Медницкие работы',
  tin: 'Жестяницкие работы',
  smith: 'Кузнечно-рессорные работы',
  armature: 'Арматурно-слесарные работы',
  tire: 'Шиномонтажные работы',
  wallpaper: 'Обойные работы',
  paint: 'Малярные работы',
  body: 'Столярно-кузовные работы'
};

export const WorkTypesEnum = {
  post: 'post',
  workshop: 'workshop'
};

export const WorkTypes = {
  post: 'Постовые работы',
  workshop: 'Цеховые работы'
};

// Группа вид работ
export type MaintenanceOperation = {
  code: string,
  hours: number,
  id: number,
  name: string,
  number: string,
  repairKind: RepairKind,
  repairKindId: number,
  status: EntityStatusType,
  unit: string,
  vehicleModelMatch: ModelMatches,
  vehicleModelMatchId: number,
  workSubType: ?$Keys<WorkSubTypesEnum>,
  workSubTypeCode: ?number,
  workType: $Keys<WorkTypesEnum>,
  workTypeCode: number,
};
