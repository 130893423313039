// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import DatePicker from 'antd/lib/date-picker';

import { offerApi } from './../../../lib/api';
import type { Offer, MdmNode } from './../../../lib/types';
import {
  withEmptyRow,
  withoutEmptyRow,
  isEmptyValue as isEmpty,
  checkDate,
  formatRub
} from './../../../lib/helpers';

import { Selects } from './../../../components';
import { ListTable, Icon } from '../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

const { MdmSelect } = Selects;
const FormItem = Form.Item;
type Props = {
  contractId: number,
  offers: Offer[],
  readOnly: boolean,
  fetchOffers: Function
};
type State = {
  touched: boolean,
  handleSubmitting: boolean,
  offers: Offer[],
  deleteOffers: Offer[]
};

const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Footer = styled.div`
  margin-top: 10px;
  .ant-btn {
    margin-right: 10px;
  }
`;

export default class extends React.Component<Props, State> {
  state = {
    touched: false,
    handleSubmitting: false,
    offers: this.props.offers,
    deleteOffers: []
  };

  async onChange(index: number, key: $Keys<Offer>, value: any) {
    let offers = withEmptyRow([...this.state.offers]);
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    offers.splice(index, 1, {
      ...offers[index],
      [(key: string)]: value
    });
    await this.setState({ offers });
  }

  onDelete(index: number) {
    const { offers, deleteOffers } = this.state;
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    this.setState({
      offers: offers.filter((value, id) => id !== index),
      deleteOffers: [...deleteOffers, offers[index]]
    });
  }

  saveUpdateOffer = async (offer: Offer) => {
    try {
      if (offer.id) {
        return await offerApi.updateOffer(offer);
      } else {
        return await offerApi.addOffer(offer);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      return undefined;
    }
  };

  deleteOffers = async () => {
    const { deleteOffers } = this.state;
    try {
      await deleteOffers.forEach(async offer => {
        if (offer.id) {
          await offerApi.deleteOffer(offer.id);
        }
      });
      this.setState({ deleteOffers: [] });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  // проверим заполнены ли все обязательные поля
  validate = (offers: Offer[]) => {
    offers.forEach((offer, index) => {
      ['mtrId', 'lastPrice', 'startDate', 'endDate'].forEach(key => {
        if (isEmpty(offer[key])) {
          throw new Error('Заполните все поля в журналах предложений');
        }
      });
    });
  };

  handleCancel = () => {
    this.setState({
      offers: this.props.offers,
      touched: false,
      handleSubmitting: false
    });
  };

  handleSubmit = async () => {
    const { contractId } = this.props;
    const offers = withoutEmptyRow(this.state.offers);
    let promises = [];
    this.setState({ handleSubmitting: true });
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      this.validate(offers);
      // сохраним этапы
      offers.forEach(offer => {
        promises.push(this.saveUpdateOffer({ ...offer, contractId }));
      });
      const hasError = await Promise.all(promises).then(offers => {
        return offers.includes(undefined);
      });
      await this.deleteOffers();

      this.props.fetchOffers();
      this.setState({
        touched: hasError,
        handleSubmitting: hasError
      });
      if (hasError) {
        notification.error({
          message: 'Ошибка',
          description: 'Не удалось сохранить все этапы'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  validateRequired(field: string, index: number) {
    const { handleSubmitting, offers } = this.state;
    if (handleSubmitting && !isEmpty(offers[index])) {
      return isEmpty(offers[index][field]) ? 'error' : 'validating';
    }
    return 'validating';
  }

  columns = [
    {
      title: 'Мнемокод',
      width: '140px',
      key: 'mtr',
      dataIndex: 'mtr',
      render: (mtr: MdmNode, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        const mnemocode = mtr && mtr.mnemocode;
        return readOnly ? (
          mnemocode
        ) : (
          <Input disabled placeholder="Мнемокод" value={mnemocode} />
        );
      }
    },
    {
      title: 'Номенклатура',
      style: {
        overflow: 'hidden'
      },
      key: 'mtrId',
      dataIndex: 'mtrId',
      render: (mtrId: string, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        const name = offer && offer.mtr && offer.mtr.name;
        return readOnly ? (
          name
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('mtrId', index)}
          >
            <MdmSelect
              placeholder="Номенклатура"
              onChange={async (mtrId, option) => {
                await this.onChange(index, 'mtrId', option.props.mdm.id);
                this.onChange(index, 'mtr', option.props.mdm);
              }}
              value={name}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Последняя цена без НДС',
      key: 'lastPrice',
      width: '115px',
      dataIndex: 'lastPrice',
      render: (lastPrice: number, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatRub(lastPrice)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('lastPrice', index)}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Последняя цена"
              value={lastPrice}
              onChange={price => this.onChange(index, 'lastPrice', price)}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Ед. измерения',
      width: '140px',
      key: 'measure',
      dataIndex: 'mtr',
      render: (mtr: MdmNode, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        const measure = mtr && mtr.measure;
        return readOnly ? (
          measure
        ) : (
          <Input disabled placeholder="Ед. измерения" value={measure} />
        );
      }
    },
    {
      title: 'Дата начала',
      width: '115px',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (startDate: string, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          checkDate(startDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('startDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              value={startDate ? moment(startDate) : startDate}
              onChange={async value => {
                await this.onChange(
                  index,
                  'startDate',
                  moment
                    .utc(value)
                    .startOf('day')
                    .toISOString()
                );
                this.onChange(index, 'endDate', undefined);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Дата окончания',
      width: '115px',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (endDate: string, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          checkDate(endDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('endDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              disabled={offer.startDate ? false : true}
              disabledDate={(date: string) =>
                offer.startDate
                  ? moment
                      .utc(date)
                      .startOf('day')
                      .isSameOrBefore(moment.utc(offer.startDate))
                  : false
              }
              value={endDate ? moment(endDate) : endDate}
              onChange={value =>
                this.onChange(
                  index,
                  'endDate',
                  moment
                    .utc(value)
                    .startOf('day')
                    .toISOString()
                )
              }
            />
          </StyledFormItem>
        );
      }
    },
    {
      width: '20px',
      renderRecord: (record: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? null : !isEmpty(record) ? (
          <Operations>
            <StyledIcon onClick={() => this.onDelete(index)} type="x" />
          </Operations>
        ) : null;
      }
    }
  ];

  render() {
    const { readOnly } = this.props;
    const { offers, touched } = this.state;
    const data = readOnly ? withoutEmptyRow(offers) : withEmptyRow(offers);
    return (
      <>
        <ListTable columns={this.columns} data={data} />
        {touched && (
          <Footer>
            <Button onClick={this.handleSubmit} type="primary">
              Сохранить
            </Button>
            <Button onClick={this.handleCancel}>Отменить</Button>
          </Footer>
        )}
      </>
    );
  }
}
