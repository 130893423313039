// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input/Input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';

import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import type {Stou} from '../../lib/types';
import {Form} from '../../components';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import type {Profile} from '../../ducks/auth';
import {stouApi} from '../../lib/api';
import {notificationLoading} from './../../components/Notifications';
import CancelButton from '../../components/CancelButton';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  stou: $Shape<Stou>
};

const InnerForm = ({ onSubmit, onCancel, stou }: FormProps) => (
  <Form initialValues={stou} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const { handleSubmit, dirty, isSubmitting } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Наименование" required name="name" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Мнемокод" required name="mnemonic" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField label="Код ОКОФ" required name="okof" fast>
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Техническая характеристика"
                    required
                    name="specification"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Код ОКОФ по ОК 013-1994"
                    required
                    name="okofByOk0131994"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Статус записи СТОУ"
                    required
                    name="status"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="БУ.СД.24 Класс уровня напряжения"
                    required
                    name="class"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Льгота по налогу (налог на имущество)"
                    required
                    name="concession"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Признак недвижимости (налог на имущество)"
                    required
                    name="propertyFlag"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    label="Префикс инвентарных объектов"
                    required
                    name="prefix"
                    fast
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  stouId: number,
  profile: Profile
};

type State = {
  stou: $Shape<Stou>
};

class StouForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const { stouId } = this.props;
    if (stouId) {
      try {
        const stou = await stouApi.fetchStou(stouId);
        this.setState({
          stou
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/admin/stou', true);
      }
    }
  }

  onCancel = () => {
    if (this.props.stouId) {
      navigate(`/admin/stou/${this.props.stouId}`);
    } else navigate('/admin/stou', true);
  };

  onSubmit = async (stou: Stou) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      let stouId = stou.id;
      if (stouId) {
        await stouApi.updateStou(stou);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        const addedStou = await stouApi.addStou(stou);
        stouId = addedStou.id;
        notification.success({
          message: 'Успешное добавление',
          description: `Успешно добавлено СТОУ`
        });
      }
      await navigate(`/admin/stou/${stouId}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { stou } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/stou')}>СТОУ</Crumb>
              {stou ? (
                <>
                  <Crumb to={`/admin/stou/${stou.id}`}>{stou.name}</Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новая запись СТОУ</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{stou ? `СТОУ ${stou.id}` : 'СТОУ'}</h1>
        </StyledPanel>
        <InnerForm
          stou={stou}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default StouForm;
