// @flow

import React, {Component} from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {Link} from '@reach/router';
import qs from 'query-string';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';

import type {ListState, OrgUnitNode, UserAccess, Vehicle, VehicleModel, VehicleStatus as VehicleStatusType} from './../../lib/types';
import {accessTypeEnum, vehicleTypes} from '../../lib/enum';
import {Section} from './../../components/layout';
import VehicleStatus from '../../components/VehicleStatus';
import Filter, {type VehicleFilterParams} from './Filter';
import {withUserAccess} from './../withUserAccess';
import Header from '../../components/layout/Header';
import {TabItem, Table, Tabs} from '../../components/ui';
import {applyMaskToValue, getListInitialState, navigate, setQueryParams} from '../../lib/helpers';
import type {FetchListParams} from '../../lib/api';
import {fetchRequest, vehicleApi} from '../../lib/api';
import {formatLicensePlateMask} from '../../components/masked-inputs/LicensePlateInput';
import {notificationLoading} from '../../components/Notifications';
import type {AppState} from '../../ducks/redux';
import type {PersistFilterPayload} from '../../ducks/persistFilters';
import {setFilter} from '../../ducks/persistFilters';
import CompareParametersModal from './components/CompareParametersModal';

const Buttons = styled.div`
  display: flex;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  userAccess: UserAccess[],
  orgUnitId: number,
  currentOrgUnit: OrgUnitNode,
  location: Location & { state: { page: number } },
  filter: VehicleFilterParams,
  setFilter: (payload: PersistFilterPayload) => void
};

export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.creatingVehicle,
];

type State = ListState<Vehicle> & {modalVisible: boolean};

const filterPath = window.location.pathname;

export class VehicleList extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };
  state = {
    ...getListInitialState(),
    modalVisible: false,
  };
  columns = [
    {
      title: 'Статус',
      dataIndex: 'status',
      sorter: true,
      render: (status: VehicleStatusType) => <VehicleStatus status={status} />,
    },
    {
      title: 'Гос. номер',
      sorter: true,
      dataIndex: 'licensePlate',
      // Нужно для того, чтобы не отрабатывал onRow
      stopPropagation: true,
      render: (licensePlate: ?string, record: Vehicle) =>
        licensePlate ? (
          <Link to={`/vehicles/${record.id}`}>
            {applyMaskToValue(licensePlate, formatLicensePlateMask)}
          </Link>
        ) : (
          licensePlate
        ),
    },
    {
      title: 'Год выпуска',
      sorter: true,
      dataIndex: 'yearIssued',
    },
    {
      title: 'Марка',
      sorter: true,
      sorterKey: 'vehicleModel.brand.name',
      dataIndex: 'vehicleModel.brandName',
    },
    {
      title: 'Модель',
      sorter: true,
      dataIndex: 'vehicleModel.name',
    },
    {
      title: 'Тип',
      dataIndex: 'vehicleModel',
      sorter: true,
      render: (vehicleModel: ?VehicleModel): ?string =>
        vehicleModel && vehicleTypes[vehicleModel.type],
    },
    {
      title: 'Подразделение',
      sorter: true,
      dataIndex: 'orgUnitName',
      sorterKey: 'node.name',
    },
  ];
  canAddVehicles = () =>
    this.props.userAccess.some(role => canAddAccess.includes(role));
  handleRowClick = (id: number) => navigate(`/vehicles/${id}`);
  handlePrint = async () => {
    const {filter} = this.props;
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing',
      });
      await vehicleApi.print({
        ...filter,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    }
    finally {
      notification.close('printing');
    }
  };
  fetchVehicles = async (
    page: number = 1,
    params: FetchListParams<VehicleFilterParams> = {},
  ) => {
    const {filter} = this.props;
    this.setState({loading: true});
    if (isEmpty(params)) { params = { orderBy: 'id', byDescending: true }; }
    
    const {data, totalCount} = await vehicleApi.fetchVehicles({
      ownerType: 'self',
      ...filter,
      page,
      ...params,
    });
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };
  
  setPersistFilter = async (values: VehicleFilterParams) => {
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };
  
  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchVehicles();
  };
  
  applyFilter = async (values: VehicleFilterParams) => {
    await this.setPersistFilter(values);
    await this.fetchVehicles();
  };
  
  async componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchVehicles(page);
  }
  
  canCompare = () => [
    accessTypeEnum.admin,
    accessTypeEnum.compareAllInventoryCard,
  ].some(access => this.props.userAccess.includes(access));
  
  handleCompare = async (values) => {
    this.setState({modalVisible: false});
    try {
      notificationLoading({message: 'Отправка данных', key: 'comparing'});
      const result = await fetchRequest.put('/vehicle/compareInventoryCard', values);
      notification.success({message: result});
    } catch (e) {
      notification.error({
        message: 'Ошибка отправки данных',
        description: e.title || e.message,
      });
    } finally {
      notification.close('comparing');
    }
  }
  
  render() {
    const {location, filter} = this.props;
    const {data, totalCount, page, pageSize, loading, modalVisible} = this.state;
    return (
      <>
        <Header
          left={<h1>Транспорт</h1>}
          right={
            <Buttons>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canCompare() && (
                <Button onClick={() => this.setState({modalVisible: true})} type='primary'>Провести сверку</Button>
              )}
              {this.canAddVehicles() && (
                <Link to="/vehicles/new">
                  <Button type="primary" data-cy="addVehicle">
                    Создать
                  </Button>
                </Link>
              )}
            </Buttons>
          }
        />
        <Tabs>
          <TabItem tabKey="own" label="Собственные" />
          <TabItem
            tabKey="contract"
            label="Наемные"
            onClick={() => navigate('/vehicles/contract', true)}
          />
        </Tabs>
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            defaultOrderBy={'id'}
            columns={this.columns}
            fetch={this.fetchVehicles}
            data={data.map(item => ({...item, key: item.id}))}
            loading={loading}
            pagination={{
              page,
              totalCount,
              pageSize,
              location,
            }}
          />
        </Section>
        <CompareParametersModal
          visible={modalVisible}
          onClose={() => this.setState({modalVisible: false})}
          onSave={this.handleCompare}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filter: state.persistFilters[filterPath] || {},
  }),
  {
    setFilter,
  },
)(withUserAccess(VehicleList));
