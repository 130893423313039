import moment from 'moment';
import {contractVehicleTypeEnum} from '../../lib/enum';

const formatString = 'DD.MM.YYYY';

export const formatDate = function(date: Date): string {
  const momentDate = getMomentValue(date);
  if (momentDate) {
    return momentDate.format(formatString);
  }
  return '';
};

export const getMomentValue = function(value: ?moment) {
  return value ? moment.utc(value) : value;
};

// тип НТС для которых отображать даты действия
export const CONTRACT_VEHICLE_TYPE_DATE = [
  contractVehicleTypeEnum.fixed,
  contractVehicleTypeEnum.oneOff,
  contractVehicleTypeEnum.substitution,
  contractVehicleTypeEnum.byOrder,
];
// Обязательные поля
export const REQUIRED_CONTRACT_VEHICLE_TYPE = [
  contractVehicleTypeEnum.fixedPlan,
  contractVehicleTypeEnum.oneOffPlan,
];
