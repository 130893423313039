// @flow
import React from 'react';
import isEqual from 'lodash/isEqual';
import { SelectProps } from 'antd/lib/select';

import { stockPartApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { StockPart } from '../../lib/types';

type Props = SelectProps & {
  fullInfo: boolean
};

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.filter, nextProps.filter) &&
  prevProps.value === nextProps.value;

export default React.memo<Props>(
  ({ fullInfo = false, ...selectProps }: Props) => (
    <AutocompleteSelect
      placeholder="Выберите деталь"
      fetch={stockPartApi.fetchStockPart}
      fetchSingle={stockPartApi.getStockPart}
      notFoundText="Детали не найдены"
      renderOption={(stockPart: StockPart, Option) => (
        <Option value={stockPart.id} key={stockPart.id} part={stockPart}>
          {fullInfo ? (
            <>
              {stockPart.mtr.name} цена за ед.&nbsp;
              <span style={{ color: '#2770ff' }}>
                {stockPart.cost} руб. без НДС
              </span>
              &nbsp;кол-во {stockPart.count} (Партия № {stockPart.batchNumber}).
              &nbsp;
              {stockPart.stock ? (
                <>
                  Мнемокод склада:&nbsp;
                  <span style={{ color: '#2770ff' }}>
                    {stockPart.stock.mnemocode}
                  </span>
                </>
              ) : null}
            </>
          ) : (
            stockPart.mtr.name
          )}
        </Option>
      )}
      {...selectProps}
    />
  ),
  areEqual
);
