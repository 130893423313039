// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import type { Battery as BatteryType, UserAccess } from './../../../lib/types';
import { Batteries as BatteriesComponents } from './../components';
import {
  resetVehicleStatus,
  setVehicleBatteries
} from '../../../ducks/vehicle';
import { batteryApi } from './../../../lib/api';
import type { AppState } from '../../../ducks/redux';
import { accessTypeEnum } from '../../../lib/enum';
import isEqual from 'lodash/isEqual';
import { notificationLoading } from '../../../components/Notifications';
import notification from 'antd/lib/notification';
import WorkCardModal from '../../Equipment/Batteries/components/WorkCardModal';

const { BatteryForm, EditableItem } = BatteriesComponents;

const canWriteOffAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.decomissingEquipment
];

type Props = {
  items: Array<BatteryType>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleBatteries: (vehicleId: number) => void,
  vehicleId: number,
  editable: boolean,
  employeeBranchOrgUnitId: number,
  userAccess: UserAccess[]
};

type State = {
  availableBatteries: BatteryType[],
  isLoading: boolean,
  showWorkCardModal: boolean,
  writtenOffId: ?number,
  batteries: BatteryType[]
};

export class Battery extends Component<Props, State> {
  state = {
    availableBatteries: [],
    isLoading: false,
    showWorkCardModal: false,
    writtenOffId: null,
    batteries: []
  };

  async componentDidMount() {
    this.setState({ batteries: this.props.items ? this.props.items : [] });
    await this.fetchAvailableBatteries();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.setState({ batteries: this.props.items ? this.props.items : [] });
    }
  }

  fetchAvailableBatteries = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true
      });
      let availableBatteries = [];
      const result = await batteryApi.fetchBatteries({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        nodeId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren'
      });

      if (result) {
        availableBatteries = result.data;
      }
      this.setState({
        availableBatteries,
        isLoading: false
      });
    }
  };

  onAdd = async (battery: BatteryType) => {
    await batteryApi.updateBattery({
      ...battery,
      vehicleId: this.props.vehicleId
    }, battery.installDate);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
    await this.fetchAvailableBatteries();
  };

  onEdit = async (battery: BatteryType) => {
    await batteryApi.updateBattery(battery);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
  };

  onRemove = async (battery: BatteryType) => {
    await batteryApi.updateBattery({ ...battery, vehicleId: null }, battery.removeDate);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleBatteries(this.props.vehicleId);
    await this.fetchAvailableBatteries();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableBatteries(value),
    500
  );

  writeOffBattery = async batteryData => {
    try {
      notificationLoading({
        message: 'Списание...',
        key: 'writingOff'
      });
      if (batteryData) {
        await batteryApi.writeOffBattery(batteryData);
        notification.success({
          message: 'Успешно',
          description: 'АКБ успешно списан'
        });
        this.setState({
          showWorkCardModal: false,
          writtenOffId: null
        });
        batteryApi
          .fetchVehicleBatteries(this.props.vehicleId)
          .then(data => this.setState({ batteries: data }));
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('writingOff');
    }
  };

  render() {
    const { editable }: Props = this.props;
    const {
      availableBatteries,
      isLoading,
      batteries,
      showWorkCardModal,
      writtenOffId
    } = this.state;
    const canWriteOff = this.props.userAccess.some(access =>
      canWriteOffAccess.includes(access)
    );
    return (
      <>
        <WorkCardModal
          visible={showWorkCardModal}
          onClose={() => this.setState({ showWorkCardModal: false })}
          onSave={formData =>
            this.writeOffBattery({ ...formData, batteryId: writtenOffId })
          }
        />
        {batteries.map((item: BatteryType) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              battery={item}
              onChange={this.onEdit}
              onRemove={editable ? this.onRemove : null}
              editable={false} // TODO: нужны требования, но пока не можем редактировать АКБ в инвентарной карточке ТС
              writeOff={
                canWriteOff
                  ? () =>
                      this.setState({
                        showWorkCardModal: true,
                        writtenOffId: parseInt(item.id, 10)
                      })
                  : undefined
              }
            />
          );
        })}
        {editable && (
          <BatteryForm
            onSubmit={this.onAdd}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableBatteries}
            isLoading={isLoading}
            availableBatteries={availableBatteries}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
    userAccess: state.auth.profile.access
  }),
  {
    resetVehicleStatus,
    setVehicleBatteries
  }
)(Battery);
