// @flow
import CommonApi from './../commonApi';
import {RepairKind} from '../types/typesOfRepairs';

const basedUrl = 'RepairKind';
const repairKinds = new CommonApi<RepairKind>(basedUrl, 'Виды ремонта');

export default {
  ...repairKinds,
};
