// @flow
import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';

import type { AppState } from '../../ducks/redux';
import type { JobTitle } from '../../lib/types/jobTitle';
import { Icon } from '../../components/ui';
import { Header, Section } from '../../components/layout';
import Table from '../../components/ui/Table';
import type { sortOrder } from '../../components/ui/Table';
import { fetchJobTitles } from '../../ducks/jobTitles';
import { jobTitleApi } from '../../lib/api';
import type { UserAccess, ListState } from '../../lib/types';
import { getListInitialState, navigate } from './../../lib/helpers';

import type { JobTitlesFilter } from './../../ducks/jobTitles';

import Filter from './components/Filter';
import canAddJobTitles from './accessRight';

type Props = {
  userAccess: UserAccess[],
  jobTitles: JobTitle[],
  page: number,
  pageSize: number,
  totalCount: number,
  fetchJobTitles: Function,
  location: Location & { state: { page: number } }
};

type State = ListState<JobTitle> & {
  filter: JobTitlesFilter
};

export class JobTitleList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: 'Название',
      dataIndex: 'name'
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName'
    }
  ];

  componentDidMount = () => {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, async () => {
      this.fetchJobTitles(page);
    });
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить должность?"
            onConfirm={() =>
              jobTitleApi
                .deleteJobTitle(id)
                .then(() => this.fetchJobTitles(page))
            }
          >
            <Icon type="trash" />
          </Popconfirm>
        )
      });
    }
  };

  fetchJobTitles = async (
    page: number,
    orderBy?: string,
    order?: sortOrder
  ) => {
    const { filter } = this.state;
    await this.props.fetchJobTitles(page, {
      ...filter,
      orderBy,
      byDescending: order === 'descend'
    });
  };

  canAdd = () =>
    this.props.userAccess.some(access => canAddJobTitles.includes(access));

  cleanFilter = () =>
    this.setState({ filter: {} }, async () => {
      this.fetchJobTitles(this.state.page);
    });

  applyFilter = (filter: JobTitlesFilter) =>
    this.setState({ filter: { ...filter } }, async () => {
      this.fetchJobTitles(this.state.page);
    });

  render() {
    const { jobTitles, page, pageSize, totalCount, location } = this.props;
    const { filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Должности</h1>}
          right={
            canAdd && (
              <Button
                type="primary"
                onClick={() => navigate('/admin/job-titles/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            columns={this.columns}
            data={jobTitles}
            fetch={this.props.fetchJobTitles}
            fetchOnMount
            onRow={(row: JobTitle) => ({
              onClick: () =>
                canAdd ? navigate(`/admin/job-titles/${row.id}`) : null
            })}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
    jobTitles: state.jobTitles.data,
    page: state.jobTitles.page,
    pageSize: state.jobTitles.pageSize,
    totalCount: state.jobTitles.totalCount
  }),
  {
    fetchJobTitles
  }
)(JobTitleList);
