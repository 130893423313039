// @flow
import type { ContractVehicleBudget, ExpenseDirectionType } from './../types';
import CommonApi from './../commonApi';
import { downloadRequestWithToken, fetchRequest } from './index';

import { convertFromKopToRub, convertFromRubToKop } from './../helpers';
import { convertDataFromServer as convertDataFromServerAssignment } from './assignmentLimit';

export const convertDataToServer = (
  contractVehicleBudget: ContractVehicleBudget
) => ({
  ...contractVehicleBudget,
  carServicesCost: convertFromRubToKop(contractVehicleBudget.carServicesCost),
  otherVehicleServicesCost: convertFromRubToKop(
    contractVehicleBudget.otherVehicleServicesCost
  ),
  acsVehicleServicesCost: convertFromRubToKop(
    contractVehicleBudget.acsVehicleServicesCost
  ),
  cargoServicesCost: convertFromRubToKop(
    contractVehicleBudget.cargoServicesCost
  ),
  mechanismServicesCost: convertFromRubToKop(
    contractVehicleBudget.mechanismServicesCost
  ),
  vehicleServicesFromProfitCost: convertFromRubToKop(
    contractVehicleBudget.vehicleServicesFromProfitCost
  ),
  assignmentLimits: contractVehicleBudget.assignmentLimits
    ? [
        ...contractVehicleBudget.assignmentLimits.map(assignmentLimit =>
          convertDataFromServerAssignment(assignmentLimit)
        )
      ]
    : undefined,
  cognosAcsVehicleServicesCost: convertFromRubToKop(
    contractVehicleBudget.cognosAcsVehicleServicesCost
  ),
  cognosCarServicesCost: convertFromRubToKop(
    contractVehicleBudget.cognosCarServicesCost
  ),
  cognosCargoServicesCost: convertFromRubToKop(
    contractVehicleBudget.cognosCargoServicesCost
  ),
  cognosMechanismServicesCost: convertFromRubToKop(
    contractVehicleBudget.cognosMechanismServicesCost
  ),
  cognosOtherVehicleServicesCost: convertFromRubToKop(
    contractVehicleBudget.cognosOtherVehicleServicesCost
  ),
  cognosVehicleServicesFromProfitCost: convertFromRubToKop(
    contractVehicleBudget.cognosVehicleServicesFromProfitCost
  )
});

export const convertDataFromServer = (
  contractVehicleBudget: ContractVehicleBudget
) => ({
  ...contractVehicleBudget,
  carServicesCost: convertFromKopToRub(contractVehicleBudget.carServicesCost),
  otherVehicleServicesCost: convertFromKopToRub(
    contractVehicleBudget.otherVehicleServicesCost
  ),
  acsVehicleServicesCost: convertFromKopToRub(
    contractVehicleBudget.acsVehicleServicesCost
  ),
  cargoServicesCost: convertFromKopToRub(
    contractVehicleBudget.cargoServicesCost
  ),
  mechanismServicesCost: convertFromKopToRub(
    contractVehicleBudget.mechanismServicesCost
  ),
  vehicleServicesFromProfitCost: convertFromKopToRub(
    contractVehicleBudget.vehicleServicesFromProfitCost
  ),
  cognosAcsVehicleServicesCost: convertFromKopToRub(
    contractVehicleBudget.cognosAcsVehicleServicesCost
  ),
  cognosCarServicesCost: convertFromKopToRub(
    contractVehicleBudget.cognosCarServicesCost
  ),
  cognosCargoServicesCost: convertFromKopToRub(
    contractVehicleBudget.cognosCargoServicesCost
  ),
  cognosMechanismServicesCost: convertFromKopToRub(
    contractVehicleBudget.cognosMechanismServicesCost
  ),
  cognosOtherVehicleServicesCost: convertFromKopToRub(
    contractVehicleBudget.cognosOtherVehicleServicesCost
  ),
  cognosVehicleServicesFromProfitCost: convertFromKopToRub(
    contractVehicleBudget.cognosVehicleServicesFromProfitCost
  )
});

export const getExpenseDirectionBudget = async (
  contractVehicleBudgetId: number,
  type: ExpenseDirectionType
): Promise<any> => {
  const data = await fetchRequest.get(
    `/${basedUrl}/getExpenseDirectionBudget/${contractVehicleBudgetId}/${type}`
  );
  if (data) {
    return data;
  }
  throw new Error(
    `Не удалось получить расшифровки бюджету по типа направления расходов`
  );
};

export const getByContractVehiclePlan = async (
  id: number
): Promise<ContractVehicleBudget> => {
  const contractVehicleBudget = await fetchRequest.get(
    `/${basedUrl}/getByContractVehiclePlan/${id}`
  );
  if (contractVehicleBudget) {
    return convertDataFromServer(contractVehicleBudget);
  }
  throw new Error(`Не удалось получить данные`);
};

export const changeStatus = async (
  id: number,
  status: string
): Promise<ContractVehicleBudget> => {
  const contractVehicleBudget = await fetchRequest.put(
    `/${basedUrl}/changeStatus`,
    { id, status }
  );
  if (contractVehicleBudget) {
    return convertDataFromServer(contractVehicleBudget);
  }
  throw new Error(`Не удалось сменить статус`);
};

const print = async (id: number) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/${id}/print`);
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const detailedPrint = async (id: number) => {
  const printed = await downloadRequestWithToken(
    `/${basedUrl}/${id}/detailedPrint`
  );
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const basedUrl = 'contractVehicleBudget';
const contractVehicleBudget = new CommonApi<ContractVehicleBudget>(
  basedUrl,
  'бюджет НТС',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...contractVehicleBudget,
  getByContractVehiclePlan,
  getExpenseDirectionBudget,
  changeStatus,
  print,
  detailedPrint
};
