import React from 'react';
import styled from 'styled-components';
import Spin from 'antd/lib/spin';

/*
  Эта штука открывает iframe с адвантумом, где трафик идет через наш сервер
*/

// $FlowFixMe
export const proxyUrl = `${process.env.REACT_APP_API_URL}/api/monitoring/`; // слэш в конце обязательно

class AdvantumHack extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = React.createRef();

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.onMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onMessage);
  }

  onMessage = () => {
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Container>
        <Header>
          <Main>Учёт пробегов</Main>
        </Header>
        {this.state.isLoading ? (
          <Overlay>
            <Spin size="large" />
          </Overlay>
        ) : null}
        <Frame id="monitoring-frame" src={proxyUrl} ref={this.iframeRef} />
      </Container>
    );
  }
}

const Main = styled.h1`
  font-size: 24;
  padding: 16px 26px 16px 66px;
`;

const Header = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 12;
  border-bottom: 16px solid #e4ebf2
  box-shadow: 20px 0 15px -30px #000 inset
  `;
// box-shadow: 6px 0px 10px rgb(0 0 0 / 7%);

const Frame = styled.iframe`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 10px 16px 16px 16px;
`;

const Overlay = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 100;
`;
// border-top: 16px solid #c7def3
const Container = styled.div`
  position: relative;
`;

export default AdvantumHack;
