// @flow
import CommonApi from './../commonApi';
import {fetchRequest} from './index';
import type {MaintenanceWork} from '../types';


const basedUrl = 'maintenancework';
const maintenanceWork = new CommonApi<MaintenanceWork>(
  basedUrl,
  'Работы по ТО',
);

const approvementMaintenance = (id) => fetchRequest.get(`/maintenancework/approve/${id}`);

const approveMaintenance = (id) => fetchRequest.get(`/maintenancework/approved/${id}`);

const declinedMaintenance = (id, reason) => fetchRequest.put(`/maintenancework/declined/${id}`, {reason});


export default {
  ...maintenanceWork,
  approvementMaintenance,
  approveMaintenance,
  declinedMaintenance,
};
