// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';

import type { OptionalEquipment as OptionalEquipmentType } from './../../../lib/types';
import { OptionalEquipment as OptionalEquipmentComponents } from './../components';
import { optionalEquipmentApi } from './../../../lib/api';
import type { AppState } from '../../../ducks/redux';
import {
  resetVehicleStatus,
  setVehicleOptionalEquipments
} from './../../../ducks/vehicle';

const { EditableItem, OptionalEquipmentForm } = OptionalEquipmentComponents;

type Props = {
  items: Array<OptionalEquipmentType>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleOptionalEquipments: (vehicleId: number) => void,
  vehicleId: number,
  editable: boolean,
  employeeBranchOrgUnitId: number
};

type State = {
  availableOptionalEquipments: OptionalEquipmentType[],
  isLoading: boolean
};

export class OptionalEquipment extends Component<Props, State> {
  state = {
    availableOptionalEquipments: [],
    isLoading: false
  };

  async componentDidMount() {
    await this.fetchAvailableOptionalEquipments();
  }

  fetchAvailableOptionalEquipments = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true
      });
      let availableOptionalEquipments = [];
      const result = await optionalEquipmentApi.fetchOptionalEquipments({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        nodeId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren'
      });

      if (result) {
        availableOptionalEquipments = result.data;
      }
      this.setState({
        availableOptionalEquipments,
        isLoading: false
      });
    }
  };

  onAdd = async (optionalEquipment: OptionalEquipmentType) => {
    await optionalEquipmentApi.updateOptionalEquipmentVehicle({
      ...optionalEquipment,
      vehicleId: this.props.vehicleId
    });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleOptionalEquipments(this.props.vehicleId);
    await this.fetchAvailableOptionalEquipments();
  };

  onEdit = async (optionalEquipment: OptionalEquipmentType) => {
    await optionalEquipmentApi.updateOptionalEquipmentVehicle(
      optionalEquipment
    );
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleOptionalEquipments(this.props.vehicleId);
  };

  onRemove = async (optionalEquipment: OptionalEquipmentType) => {
    await optionalEquipmentApi.updateOptionalEquipmentVehicle({
      ...optionalEquipment,
      vehicleId: null
    });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleOptionalEquipments(this.props.vehicleId);
    await this.fetchAvailableOptionalEquipments();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableOptionalEquipments(value),
    500
  );

  render() {
    const { items = [], editable }: Props = this.props;
    const { availableOptionalEquipments, isLoading } = this.state;
    return (
      <>
        {items.map((item: OptionalEquipmentType) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              optionalEquipment={item}
              onChange={this.onEdit}
              onRemove={this.onRemove}
              editable={editable}
            />
          );
        })}
        {editable && (
          <OptionalEquipmentForm
            onSubmit={this.onAdd}
            availableEquipments={availableOptionalEquipments}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableOptionalEquipments}
            isLoading={isLoading}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    resetVehicleStatus,
    setVehicleOptionalEquipments
  }
)(OptionalEquipment);
