// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Form, Selects } from '../../../components';
import type { FormFieldType } from '../../../components/Form';
import { Section, SectionTitle } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';

import { maintenanceApi, maintenanceMatrixApi } from '../../../lib/api';
import {
  maintenanceMatrixValidActionEnum,
  orderContractorTypeEnum,
  ownerTypes,
  repairPlanEnum,
  serviceTypeEnum,
  vehicleStatusEnum
} from '../../../lib/enum';
import type {
  Maintenance,
  OrderContractorType,
  RepairPlan
} from '../../../lib/types';
import {navigate} from '../../../lib/helpers';

const {
  VehicleSelect,
  RepairePlanSelect,
  OrderContractorTypeSelect,
  MaintenanceWorkTypeSelect,
  ContractorSelect
} = Selects;
const TextArea = Input.TextArea;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function
};

type Params = {
  maintenanceWorkTypeId: ?number,
  orderContractorType: ?OrderContractorType,
  repairPlan: ?RepairPlan
};

export default ({ onSubmit }: FormProps) => {
  const [maintenanceMatrix, setMaintenanceMatrix] = useState(null);
  // отображать или нет подрядчика
  const showContractor = (maintenance: Maintenance) => {
    if (
      maintenanceMatrix &&
      maintenanceMatrix.contractorRequired !==
        maintenanceMatrixValidActionEnum.notAllowed
    ) {
      return true;
    }
    return (
      maintenance.orderContractorType === orderContractorTypeEnum.contractor
    );
  };
  // отображать или нет поле неисправности
  const showDescription = (maintenance: Maintenance) => {
    if (
      maintenanceMatrix &&
      maintenanceMatrix.descriptionRequired !==
        maintenanceMatrixValidActionEnum.notAllowed
    ) {
      return true;
    }
    return maintenance.repairPlan === repairPlanEnum.unplanned;
  };
  // выводим предупреждение если нет направления расхода
  const checkExpenseDirection = async (params: Params) => {
    if (
      !!params.maintenanceWorkTypeId &&
      params.orderContractorType &&
      params.repairPlan
    ) {
      try {
        await maintenanceApi.getMaintenanceExpenseDirection(params);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  };
  // подтянем правила для валидации из матрицы ремонтов
  const getMaintenanceMatrix = async (params: Params) => {
    if (
      !!params.maintenanceWorkTypeId &&
      params.orderContractorType &&
      params.repairPlan
    ) {
      try {
        const maintenanceMatrix = await maintenanceMatrixApi.getMaintenanceMatrixByParams(
          {
            workTypeId: params.maintenanceWorkTypeId,
            orderContractorType: params.orderContractorType,
            repairPlan: params.repairPlan
          }
        );
        setMaintenanceMatrix(maintenanceMatrix);
      } catch (error) {
        setMaintenanceMatrix(null);
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    } else {
      setMaintenanceMatrix(null);
    }
  };
  return (
    <Form enableReinitialize onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const {
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values
        } = formikProps;
        // очищаем значения для пошагового заполнения первичных данных
        if (values.vehicleId === undefined) {
          Object.keys(values).forEach(key => setFieldValue(key, undefined));
        }
        if (
          values.maintenanceWorkTypeId === undefined &&
          values.repairPlan !== undefined
        ) {
          setFieldValue('description', undefined);
          setFieldValue('repairPlan', undefined);
        }
        if (
          values.repairPlan === undefined &&
          values.orderContractorType !== undefined
        ) {
          setFieldValue('orderContractorType', undefined);
          setFieldValue('contractNumber', undefined);
          setFieldValue('contractorId', undefined);
          setFieldValue('executor', undefined);
          setFieldValue('executorId', undefined);
        }
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Первичные данные</SectionTitle>
              <Content>
                <Grid gutter="16px" cols={3}>
                  <GridItem>
                    <FormField
                      label="Транспортное средство"
                      required
                      fast
                      name="vehicleId"
                    >
                      {({ value, name }) => (
                        <VehicleSelect
                          disabled={values.id}
                          value={value}
                          filter={{
                            ownerType: ownerTypes.self,
                            status: vehicleStatusEnum.working
                          }}
                          onChange={(vehicleId: number) =>
                            setFieldValue(name, vehicleId)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {values.vehicleId && (
                    <GridItem>
                      <FormField
                        label="Вид работ"
                        required
                        fast
                        name="maintenanceWorkTypeId"
                      >
                        {({ value, name }) => (
                          <MaintenanceWorkTypeSelect
                            value={value}
                            withoutArchive={true}
                            onChange={(id: number) => {
                              setFieldValue(name, id);
                              const params = {
                                maintenanceWorkTypeId: id,
                                orderContractorType: values.orderContractorType,
                                repairPlan: values.repairPlan
                              };
                              getMaintenanceMatrix(params);
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {values.maintenanceWorkTypeId && (
                    <GridItem>
                      <FormField
                        label="График работ"
                        required
                        fast
                        name="repairPlan"
                      >
                        {({ value, name }) => (
                          <RepairePlanSelect
                            value={value}
                            onChange={(type: string) => {
                              setFieldValue(name, type);
                              const params = {
                                maintenanceWorkTypeId:
                                  values.maintenanceWorkTypeId,
                                orderContractorType: values.orderContractorType,
                                repairPlan: value
                              };
                              getMaintenanceMatrix(params);
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {values.repairPlan && (
                    <GridItem>
                      <FormField
                        label="Способ выполнения"
                        required
                        name="orderContractorType"
                      >
                        {({ value, name }) => (
                          <OrderContractorTypeSelect
                            name={name}
                            value={value}
                            onChange={value => {
                              setFieldValue(name, value);
                              const params = {
                                maintenanceWorkTypeId:
                                  values.maintenanceWorkTypeId,
                                orderContractorType: value,
                                repairPlan: values.repairPlan
                              };
                              getMaintenanceMatrix(params);
                              checkExpenseDirection(params);
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {showContractor(values) && (
                    <>
                      <GridItem>
                        <FormField
                          label="Подрядчик"
                          required={
                            maintenanceMatrix !== null &&
                            maintenanceMatrix.contractorRequired ===
                              maintenanceMatrixValidActionEnum.required
                          }
                          name="contractorId"
                        >
                          {({ value, name }) => (
                            <ContractorSelect
                              name={name}
                              value={value}
                              filter={{
                                services: serviceTypeEnum.maintenance
                              }}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Договор"
                          required={
                            maintenanceMatrix !== null &&
                            maintenanceMatrix.contractorRequired ===
                              maintenanceMatrixValidActionEnum.required
                          }
                          name="contractNumber"
                        >
                          {({ value, name }) => (
                            <Input
                              name={name}
                              value={value}
                              onChange={e => {
                                setFieldValue(name, e.target.value);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  {showDescription(values) && (
                    <GridItem>
                      <FormField
                        label="Неисправности"
                        required={
                          maintenanceMatrix !== null &&
                          maintenanceMatrix.descriptionRequired ===
                            maintenanceMatrixValidActionEnum.required
                        }
                        fast
                        name="description"
                      >
                        {field => <TextArea {...field} />}
                      </FormField>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </Section>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                Сохранить
              </Button>
              <Button onClick={() => navigate('/maintenances')}>
                Отменить
              </Button>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
