// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { branchBudgetSummaryExpenseDirectionApi } from '../../lib/api';
import type { BranchBudgetSummaryExpenseDirection } from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any, optionKey?: string };

export default class extends React.Component<Props> {
  render() {
    const { filter, optionKey, ...selectProps } = this.props;
    return (
      <AutocompleteSelect
        fetch={async params => {
          return await branchBudgetSummaryExpenseDirectionApi.fetch({
            ...filter,
            ...params
          });
        }}
        refetchParams={{ filter }}
        fetchSingle={branchBudgetSummaryExpenseDirectionApi.get}
        notFoundText="Наименование не найдено"
        placeholder="Выберите наименование расхода"
        renderOption={(
          branchBudgetSummaryExpenseDirection: BranchBudgetSummaryExpenseDirection,
          Option
        ) => (
          <Option
            key={`${optionKey}${branchBudgetSummaryExpenseDirection.id}`}
            value={branchBudgetSummaryExpenseDirection.id}
            branchBudgetSummaryExpenseDirection={
              branchBudgetSummaryExpenseDirection
            }
          >
            {branchBudgetSummaryExpenseDirection?.expenseDirectionName}
          </Option>
        )}
        {...selectProps}
      />
    );
  }
}
