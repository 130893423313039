// @flow

import React from 'react';
import Select, { SelectProps } from 'antd/lib/select';
import { attachedEquipmentTypes } from '../../../lib/enum';

const { Option } = Select;

// Список типов навесного оборудования
export default (props: SelectProps) => (
  <Select {...props}>
    {Object.keys(attachedEquipmentTypes).map(key => (
      <Option value={key} key={key}>
        {attachedEquipmentTypes[key]}
      </Option>
    ))}
  </Select>
);
