// @flow
import React, {useEffect} from 'react';
import styled from 'styled-components';
import moment from 'moment';

import {Element, scroller} from 'react-scroll';
import Button from 'antd/lib/button';

import AttachedEquipment from '../../containers/AttachedEquipment';
import OptionalEquipment from '../../containers/OptionalEquipment';
import Tires from '../../containers/Tires';
import Battery from '../../containers/Battery';
import type {Vehicle} from '../../../../lib/types/index';
import {Section} from '../../../../components/layout/index';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import {Icon} from '../../../../components/ui';
import Field from '../../../../components/card/Field';
import {chassisConfigurations} from '../../../../lib/enum';
import {SectionTitle} from '../../../../components/layout';
import {canShowBatteryData, canShowChangeToolsData, canShowTiresData} from '../../lib';
import {navigate} from '../../../../lib/helpers';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  canAddFuelMeasurement: boolean,
  canHandleEquipment: boolean,
  anchor: ?string
};

const formatDate = value => {
  return value ? moment(value).format('DD.MM.YYYY') : value;
};

export default ({
  vehicle,
  editable,
  canAddFuelMeasurement,
  canHandleEquipment,
  anchor,
}: Props) => {
  if (vehicle.equipments) {
    useEffect(() => {
      if (anchor) {
        scroller.scrollTo(anchor, {
          duration: 800,
          delay: 0,
          smooth: 'easeInOutQuart',
        });
      }
    });
    return (
      <>
        <Section>
          <SectionTitle divider dividerType="shadow">
            Навесное и доп. оборудование
          </SectionTitle>
          <AttachedEquipment
            items={vehicle.equipments.attached}
            vehicleId={vehicle.id}
            editable={editable}
          />
        </Section>
        <Section>
          <SectionTitle divider dividerType="shadow">
            Приборы и бортовые устройства
          </SectionTitle>
          <OptionalEquipment
            items={vehicle.equipments.optional}
            vehicleId={vehicle.id}
            editable={editable}
          />
        </Section>
        {canShowTiresData(
          vehicle.vehicleModel && vehicle.vehicleModel.type,
        ) && (
          <Section>
            <SectionTitle
              divider
              dividerType="shadow"
              suffix={
                canHandleEquipment && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate(`/equipment/tires/new/vehicle/${vehicle.id}`)
                    }
                  >
                    Создать
                  </Button>
                )
              }
            >
              Шины
            </SectionTitle>
            {vehicle &&
            vehicle.vehicleModel &&
            (parseInt(vehicle.vehicleModel.wheelCount) > 0 ||
              vehicle.vehicleModel.chassisConfiguration) ? (
              <SectionContent style={{paddingBottom: 0}}>
                <Grid gutter="16px">
                  {vehicle.vehicleModel.wheelCount !== null && (
                    <GridItem>
                      <Field label="Общее количество автошин">
                        {vehicle.vehicleModel.wheelCount} шт.
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.vehicleModel.chassisConfiguration && (
                    <GridItem>
                      <Field label="Колесная формула">
                        {
                          chassisConfigurations[
                            vehicle.vehicleModel.chassisConfiguration
                            ]
                        }
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            ) : null}
            <Tires
              items={vehicle.equipments.tires}
              vehicleId={vehicle.id}
              vehicle={vehicle}
              editable={editable}
              canAddFuelMeasurement={canAddFuelMeasurement}
            />
          </Section>
        )}
        {canShowBatteryData(
          vehicle.vehicleModel && vehicle.vehicleModel.type,
        ) && (
          <Section>
            <SectionTitle
              divider
              dividerType="shadow"
              suffix={
                canHandleEquipment && (
                  <Button
                    type="primary"
                    onClick={() =>
                      navigate(`/equipment/batteries/new/vehicle/${vehicle.id}`)
                    }
                  >
                    Создать
                  </Button>
                )
              }
            >
              АКБ
            </SectionTitle>
            <Battery
              items={vehicle.equipments.batteries}
              vehicleId={vehicle.id}
              editable={editable}
            />
          </Section>
        )}
        {canShowChangeToolsData(
          vehicle.vehicleModel && vehicle.vehicleModel.type,
        ) && (
          <Element name="change-tools">
            <Section>
              <SectionTitle
                divider
                dividerType="shadow"
                suffix={
                  editable && (
                    <StyledIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/edit/changeTools`)
                      }
                      type="edit"
                      size={16}
                    />
                  )
                }
              >
                Средства измерений
              </SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  {vehicle.certificationChangeToolsLastDate && (
                    <GridItem>
                      <Field label="Дата последней сертификации">
                        {formatDate(vehicle.certificationChangeToolsLastDate)}
                      </Field>
                    </GridItem>
                  )}
                  {vehicle.certificationChangeToolsPlannedDate && (
                    <GridItem>
                      <Field label="Планируемая дата сертификации">
                        {formatDate(
                          vehicle.certificationChangeToolsPlannedDate,
                        )}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Section>
          </Element>
        )}
      </>
    );
  }
  return null;
};
