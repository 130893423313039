// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {TypesOfRepairsApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {accessTypeEnum} from '../../lib/enum';

import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {RepairKind} from '../../lib/types/typesOfRepairs';
import {withUserAccess} from '../withUserAccess';
import type {UserAccess} from '../../lib/types';
import Popconfirm from 'antd/lib/popconfirm';
import {Button} from 'antd';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const SectionContent = styled.div`
  padding: 16px;
`;

const ControlButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const {Field} = Card;

type Props = {
  id: string,
  userAccess: UserAccess[],
};

type State = {
  typeOfRepairs: ?RepairKind
};

class TypesOfRepairsForm extends Component<Props, State> {
  state = {
    typeOfRepairs: {},
  };
  
  async componentDidMount() {
    const {id} = this.props;
    if (id) {
      try {
        const typeOfRepairs = await TypesOfRepairsApi.get(id);
        this.setState({typeOfRepairs});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message || error.title,
        });
      }
    }
  }
  
  deleteRecord = async () => {
    try {
      await TypesOfRepairsApi.delete(this.props.id);
      navigate('/admin/types-of-repairs/', true);
      notification.success({
        message: 'Запись удалена',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  canEdit = () =>
    [accessTypeEnum.admin, accessTypeEnum.editingWorkDict].some(access => this.props.userAccess.includes(access));
  
  render() {
    const {typeOfRepairs} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/types-of-repairs/')}>
                Виды ремонта
              </Crumb>
              <Crumb>Вид ремонта №{typeOfRepairs?.id || ''}</Crumb>
            </Breadcrumbs>
          }
          right={this.canEdit() && (
            <ControlButtonsBlock>
              <Button
                onClick={() => navigate(`/admin/types-of-repairs/${typeOfRepairs.id}/edit`)}
                style={{marginRight: '10px'}}
                type="primary"
              >
                Редактировать
              </Button>
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={this.deleteRecord}
              >
                <Button>Удалить</Button>
              </Popconfirm>
            </ControlButtonsBlock>
          )}
        />
        <StyledPanel>
          <h1>{`Вид ремонта №${typeOfRepairs?.id || ''}`}</h1>
        </StyledPanel>
        <Section>
          {typeOfRepairs.id && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Вид ремонта">{typeOfRepairs.name}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Код вида ремонта">
                    {typeOfRepairs.code}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(TypesOfRepairsForm);
