// @flow
import React from 'react';
import {TabItem, Tabs} from './../../../../../components/ui';
import {setQueryParams} from '../../../../../lib/helpers';

type Props = {
  disableAllTabs: boolean,
}

export default ({disableAllTabs}: Props) => {
  // костыль для сброса всех возможных параметров фильтра в адресной строке
  const resetQyeryParams = () => {
    if (/\?.*=/.test(window.location.href)) {
      setQueryParams({
        status: undefined,
        branchId: undefined,
        nodeId: undefined,
        id: undefined,
        page: undefined,
      });
    }
  };
  return (
    <Tabs withRouter disabled={disableAllTabs}>
      <TabItem
        label="Реестр бюджетов"
        url={'/budget/contract-vehicle/fixed/beforeCognos'}
        activeUrl={[
          '/budget/contract-vehicle/fixed/beforeCognos',
          '/budget/contract-vehicle/fixed/afterCognos',
        ]}
      >
        <Tabs
          withRouter
          defaultTab="/budget/contract-vehicle/fixed/beforeCognos"
          disabled={disableAllTabs}
        >
          <TabItem
            label="До рассмотрения в ИСУ"
            url={'/budget/contract-vehicle/fixed/beforeCognos'}
            onClick={resetQyeryParams}
          />
          <TabItem
            label="После рассмотрения в ИСУ"
            url={'/budget/contract-vehicle/fixed/afterCognos'}
            onClick={resetQyeryParams}
          />
        </Tabs>
      </TabItem>
      <TabItem label="Регламенты" url={'/budget/contract-vehicle/work-plans'} />
    </Tabs>
  );
};
