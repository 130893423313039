/* VehicleCalculationSelection.js | Component | ТС для расчёта стоимости */
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import groupBy from 'lodash/groupBy';
import find from 'lodash/find';
import { debounce} from 'lodash';
import type { Vehicle, VehicleForSelection } from '../../lib/types';
import { TabItem, Tabs } from '../ui';
import Grid, { GridItem } from '../layout/Grid';
import { connect } from 'react-redux';

import {
  HeaderCalculation,
  SearchInputCalculation,
  SpoilerCalculationContent,
  StatusCalculation,
  TabWrapperCalculation,
  VehicleCalculationCard,
  VehicleCalculationCardWrapper,
  VehicleCalculationInfo,
  WrapperCalculation,
} from './VehicleCalculationSelection.elements';
import Spinner from '../Spinner';
import Spoiler from '../ui/Spoiler';
import {applyMaskToValue, mapLatToCyr} from '../../lib/helpers';
import {formatLicensePlateMask} from '../masked-inputs/LicensePlateInput';
import {ownerTypes} from '../../lib/enum';
import type {AppState} from '../../ducks/redux';
import moment from 'moment';

type Props = {
  // Список ТС
  vehicles: VehicleForSelection[],
  // Только свои или наемные ТС
  onlyType?: $Keys<ownerTypes>,
  loading: boolean,
  caption: string,
};

type State = {
  tabKey: $Keys<ownerTypes>,
  searchString: string,
  filteredVehicles: VehicleForSelection[]
};

/**
 * Компонент рисует сетку из машин, которые доступны для выбора
 * Используется при назначении свободного ТС на заявку
 */
export class VehicleCalculationSelection extends Component<Props, State> {
  static defaultProps = { vehicles: [] };

  state = {
    tabKey: this.props.onlyType ? this.props.onlyType : ownerTypes.self,
    searchString: '',
    filteredVehicles: this.props.vehicles,
    telKey: this.props.onlyType === ownerTypes.contract,
  };

  getDefaultTab = () => {
    const {vehicles, selected: id, onlyType} = this.props;

    if (onlyType) {
      return onlyType;
    }

    const finded: Vehicle = find(vehicles, {id});

    if (finded) {
      switch (finded.ownerType) {
        case ownerTypes.contract:
          return ownerTypes.contract;
        default:
          return ownerTypes.self;
      }
    }
    return ownerTypes.self;
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.vehicles, this.props.vehicles)) {
      this.debounceFilterVehicles(this.state.searchString);
      const currentTab = this.getDefaultTab();
      this.setState({tabKey: currentTab});
      this.handleTabChange(currentTab);
    }
  }

  renderCardCalculation = (vehicle: VehicleForSelection) => {
    return (
      <GridItem key={vehicle.id}>
        <VehicleCalculationCard
          className={`vehicle-${vehicle.id}`}
          key={vehicle.id}
          // isActive={vehicle.id === selected}
          disabled={vehicle.disabled}
        >
          <VehicleCalculationInfo>
            <StatusCalculation
              status={vehicle.isBusy === 'busy' ? 'busy' : vehicle.status}
              showLabel={false}
            />
            {/* Марка ТС и модель */}
            <div className="vehicle-info">
              {vehicle.vehicleModel && (
                <p>
                  {vehicle.vehicleModel.brandName} {vehicle.vehicleModel.name}
                  {vehicle.trailers && vehicle.trailers.length > 0 && (<><br/>(с прицепом)</>)}
                  {parseInt(vehicle.drivingVehicleId, 10) > 0 && (<><br/> (закреплен за ТС)</>)}
                </p>
              )}
              {/* Гос номер */}
              <div>
                <p>
                  {applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
                </p>
              </div>
              {vehicle.isBusy === 'busy' && vehicle.freeDate && (
                <div style={{margin: '7px 0 0 0'}}>
                  <p>Освободится после</p>
                  <p>{moment.utc(vehicle.freeDate).format('DD.MM.YYYY HH:mm')}</p>
                </div>
              )}
              {/* Водитель для НТС */}
              {(vehicle.ownerType === ownerTypes.contract && (
                <div>
                  <p>
                    {vehicle.contractDriverFullName ? vehicle.contractDriverFullName : 'Водитель не найден'}
                  </p>
                </div>
              ))}
              {/* Номер телефона для НТС */}
              {(vehicle.ownerType === ownerTypes.contract && (
                <div>
                  <p>
                    {vehicle.contractDriverPhoneNumber ? vehicle.contractDriverPhoneNumber : 'Телефон не найден'}
                  </p>
                </div>
              ))}
            </div>
          </VehicleCalculationInfo>
        </VehicleCalculationCard>
      </GridItem>
    );
  };

  handleTabChange = (tabKey: ownerTypes.self | ownerTypes.contract) => {
    this.setState({tabKey});
    if (tabKey === ownerTypes.contract) {
      this.setState({telKey: true});
    } else {
      this.setState({telKey: false});
    }
  };

  // Фильтрация ТС по гос. номеру
  filterVehicles = async (searchString: string) => {
    let filteredVehicles = this.props.vehicles;
    if (searchString) {
      searchString = mapLatToCyr(searchString).toLowerCase();
      filteredVehicles = filteredVehicles.filter(
        ({licensePlate}: Vehicle) =>
          licensePlate && licensePlate.toLowerCase().includes(searchString),
      );
    }
    this.setState({
      filteredVehicles,
    });
  };

  debounceFilterVehicles = debounce(this.filterVehicles, 500);

  onSearchVehicle = (e: any) => {
    const searchString = e.target.value;
    this.setState(
      {
        searchString,
      },
      () => this.debounceFilterVehicles(this.state.searchString),
    );
  };

  render() {
    const { onlyType, loading } = this.props;
    const { searchString, filteredVehicles, tabKey} = this.state;
    const { free, busy} = groupBy(
      filteredVehicles.filter(vehicle => {
        return vehicle.ownerType === tabKey
        // При необходимости, вклбючить фильтрацию "В аренде" (лизинг).
        // return vehicle.isLease === (tabKey === ownerTypes.leased) && (tabKey === ownerTypes.leased || vehicle.ownerType === tabKey)
      }),
      'isBusy',
    );
    const hasBusy = busy && busy.length;
    const hasFree = free && free.length;

    return (
      <WrapperCalculation>
        <HeaderCalculation>
          <TabWrapperCalculation>
            <div>
              <p>{this.props.caption}</p>
              <Tabs
                type="buttons"
                defaultTab={tabKey}
                onChange={this.handleTabChange}
              >
                {(!onlyType || onlyType === ownerTypes.self) && <TabItem tabKey="self" label="Собственные" />}
                {(!onlyType || onlyType === ownerTypes.contract) && <TabItem tabKey="contract" label="Наемные" />}
              </Tabs>
            </div>
            <SearchInputCalculation
              withtabs={(!onlyType).toString()}
              onChange={this.onSearchVehicle}
              value={searchString}
              placeholder="Поиск по гос. номеру"
              size="small"
            />
          </TabWrapperCalculation>
        </HeaderCalculation>
        <Spinner isLoading={loading}>
          {!hasBusy && !hasFree && (<p style={{margin: '10px', opacity: '0.4'}} >Подходящие ТС не найдены</p>)}
          {hasBusy && hasFree && (
            <Spoiler defaultExpanded label="Свободные">
              <SpoilerCalculationContent>
                <Grid gutter="8px" rowGutter="8px" cols={2} media={ [ { size: 'md', cols: 3 }, { size: 'lg', cols: 5 } ]}
                >{free.map(this.renderCardCalculation)}</Grid>
              </SpoilerCalculationContent>
            </Spoiler>
          )}

          {!hasBusy && hasFree && (
            <VehicleCalculationCardWrapper>
              <Grid gutter="8px" rowGutter="8px" cols={2} media={ [ { size: 'md', cols: 3 }, { size: 'lg', cols: 5 } ] }
              >{free.map(this.renderCardCalculation)}</Grid>
            </VehicleCalculationCardWrapper>
          )}

          {hasBusy && (
            <Spoiler defaultExpanded label="Занятые">
              <SpoilerCalculationContent>
                <Grid gutter="8px" rowGutter="8px" cols={2}
                      media={ [ { size: 'md', cols: 3 }, { size: 'lg', cols: 5 } ] }
                >{busy.map(this.renderCardCalculation)}</Grid>
              </SpoilerCalculationContent>
            </Spoiler>
          )}
        </Spinner>
      </WrapperCalculation>
    );
  }
}

const mapStateToPropsCalculation = (state: AppState): Object => ({
  orderCalculation: state.orderCalculation,
});

export default connect(mapStateToPropsCalculation)(VehicleCalculationSelection);
