// @flow

import React, {Component} from 'react';

import {connect} from 'react-redux';
import notification from 'antd/lib/notification';
import Form, {RequiredFieldMessage} from '../../components/Form';

import type {Trip, Vehicle, WayPoint} from '../../lib/types';
import {DriverQualificationDocument} from '../../lib/types';
import {selectTrip} from '../../ducks/selectors';
import {cleanTrip, fetchTrip, updateTrip} from '../../ducks/trip';
import {tripApi} from './../../lib/api';
import InnerForm from './components/InnerForm';
import {driverQualificationEnum, trailerVehicleTypes, tripStatusEnum, waypointTypeEnum} from '../../lib/enum';
import type {AppState} from '../../ducks/redux';
import type {Direction} from '../../lib/gis';
import {directions} from '../../lib/gis';
import {Panel} from './../../components/layout';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {getSelectionVehicles} from '../Orders/lib';
import {notificationLoading} from '../../components/Notifications';
import moment from 'moment';

type Props = {
  trip: Trip,
  updateTrip: Function,
  orgUnitId: ?number,
  tripId: number,
  fetchTrip: Function,
  cleanTrip: () => void,
  employeeBranchOrgUnitId: number
};

type State = {
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  geometry: ?Direction,
  isSubmitting: boolean
};

class TripForm extends Component<Props, State> {
  state = {
    freeVehicles: [],
    freeTrailers: [],
    geometry: null,
    isSubmitting: false
  };
  async componentDidMount() {
    const { tripId } = this.props;
    await this.props.cleanTrip();
    try {
      const trip = await this.props.fetchTrip(tripId);
      if (trip) {
        const withTrailer = trip.withTrailer;
        const freeAllVehicles = await tripApi.fetchTripFreeVehicles(trip.id);
        let freeVehicles = [];
        let freeTrailers = [];
        freeAllVehicles.forEach((vehicle: Vehicle) => {
          if (
            trip.withTrailer &&
            trailerVehicleTypes.includes(vehicle.vehicleModel.type)
          ) {
            freeTrailers.push({
              ...vehicle,
              disabled: false
            });
          } else {
            freeVehicles.push(vehicle);

            /**
             * Если у нас заявка на ТС с прицепом и к ТС прикреплены прицепы,
             * то мы их добавляем, как прицепы, которые можно выбрать, но ставим отметку,
             * что они заблокированы для выбора.
             * Блокируем мы их потому, что прицеп можно выбрать только в связке ТС + прицеп.
             * Выбрать прикрепленный прицеп к другому ТС нельзя, для этого нужно произвести
             * манипуляции по откреплению прицепа в инвентарной карточке
             */
            if (withTrailer) {
              const vehicleTrailers = vehicle.trailers.map<Vehicle>(
                (item: Vehicle) => ({
                  ...item,
                  disabled: true
                })
              );
              freeTrailers.push(...vehicleTrailers);
            }
          }
        });

        const { trailers, vehicles } = getSelectionVehicles({
          selectedTrailer: trip.trailer,
          selectedVehicle: trip.vehicle,
          trailers: freeTrailers,
          vehicles: freeVehicles,
          withTrailer: trip.withTrailer
        });

        this.setState({
          freeVehicles: vehicles,
          freeTrailers: trailers
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  // Проставляем новые точки стоянки взамен старых, если такие присутствуют в ТС
  vehicleChanged = async (
    vehicle: Vehicle,
    expectedWaypoints: Array<WayPoint>
  ): Promise<{
    driverId?: ?number,
    expectedWaypoints: Array<WayPoint>
  }> => {
    const result = {};
    if (parseInt(vehicle.driverId) > 0) {
      result.driverId = vehicle.driverId;
    }
    const transitWaypoints = expectedWaypoints.filter(
      (waypoint: WayPoint) => waypoint.type === waypointTypeEnum.transit
    );
    if (vehicle.location) {
      const { latitude, longitude, name, radius, address } = vehicle.location;
      const waypoint = { latitude, longitude, name, radius, address };
      result.expectedWaypoints = [
        { ...waypoint, type: waypointTypeEnum.start },
        ...transitWaypoints,
        { ...waypoint, type: waypointTypeEnum.end }
      ];
    } else {
      result.expectedWaypoints = transitWaypoints;
    }
    if (vehicle.trailers && vehicle.trailers.length > 0) {
      result.trailer = vehicle.trailers[0];
      result.trailerId = result.trailer.id;
    } else {
      result.trailer = null;
      result.trailerId = null;
    }
    return result;
  };

  render() {
    const { trip, employeeBranchOrgUnitId } = this.props;
    const { freeVehicles, freeTrailers, isSubmitting } = this.state;
    if (!trip) {
      return null;
    }

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/trips')}>
                Путевые листы
              </Crumb>
              <Crumb>
                {trip.status === tripStatusEnum.draft
                  ? 'Заявка'
                  : `Путевой лист №${parseInt(trip.idNumber) || ''}`}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <Panel>
          <h1>Путевой лист №{trip.idNumber}</h1>
        </Panel>
        <Form
          initialValues={{
            ...trip,
            // Если у нас имеется авто, но нет пробега на старте, то записываем это значение
            odometerAtStart:
              parseFloat(trip.vehicleId) > 0 && !trip.odometerAtStart
                ? trip.vehicle.kilometrage
                : trip.odometerAtStart
          }}
          enableReinitialize
          validate={(values: Trip) => {
            let errors = {};
            if (!values.objective) {
              errors.name = new RequiredFieldMessage(
                'Обязательно для заполнения'
              );
            }
            if (!values.vehicleType && !values.vehicleGroup) {
              errors.vehicleType = new RequiredFieldMessage(
                'Обязательно для заполнения'
              );
            }
            if (isNaN(parseFloat(values.workersCount))) {
              errors.workersCount = new RequiredFieldMessage(
                'Обязательно для заполнения'
              );
            } else if (values.workersCount < 0) {
              errors.workersCount = 'Неверное количество работников';
            }
            if (!parseInt(values.employeeId)) {
              errors.employeeId = new RequiredFieldMessage(
                'Обязательно для заполнения'
              );
            }
            if (
              !(
                values.expectedRoute.waypoints &&
                values.expectedRoute.waypoints.length
              )
            ) {
              errors.expectedWaypoints = new RequiredFieldMessage(
                'Маршрут обязателен для заполнения'
              );
            }
            if (values.expectedRoute.distance < 0) {
              errors.expectedDistance =
                'Неверное значение предполагаемого пробега';
            }
            if (values.odometerAtStart < values.vehicle.kilometrage) {
              errors.odometerAtStart = `Пробег при выезде не может быть меньше фактического пробега (текущее значение ${values.vehicle.kilometrage} км.)`;
            }
            if (!values.driverId) {
              errors.driverId = new RequiredFieldMessage(
                'Обязательно для заполнения'
              );
            } else {
              const drivingLicense = values.driver.qualificationDocuments?.find(
                (doc: DriverQualificationDocument) => {
                  return (
                    doc?.qualification === driverQualificationEnum.driverLicense
                  );
                }
              );
              const drivingLicenseIsEnd =
                !!drivingLicense &&
                moment().isSameOrAfter(drivingLicense?.documentEndDate, 'day');
              if (drivingLicenseIsEnd) {
                errors.driverId =
                  'У выбранного водителя истек срок действия водительского удостоверения';
                errors.driver = new RequiredFieldMessage(
                  'Водительское удостоверение недействительно. Выберите другого водителя',
                  true
                );
              }
            }
            return errors;
          }}
          onSubmit={async (values: Trip) => {
            this.setState({ isSubmitting: true });
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              await this.props.updateTrip({
                ...values,
                odometerAtEnd:
                  values.odometerAtStart + values.expectedRoute.distance
              });
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены'
              });
              navigate(`/trips/self/${this.props.tripId}/card`);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
              this.setState({ isSubmitting: false });
            }
          }}
        >
          {(FormField, formikProps) => (
            <InnerForm
              {...formikProps}
              onCancel={() =>
                navigate(
                  `/trips/${trip.isContract ? 'contract' : 'self'}/${
                    trip.id
                  }/card`,
                  true
                )
              }
              onVehicleChange={this.vehicleChanged}
              freeVehicles={freeVehicles}
              freeTrailers={freeTrailers}
              onCalculateGeometry={async formTrip => {
                const directionsResponse = await directions(
                  formTrip.expectedRoute.waypoints
                );
                this.setState({ geometry: directionsResponse });
                return directionsResponse;
              }}
              isSubmitting={isSubmitting}
              tripGeometry={this.state.geometry}
              employeeBranchOrgUnitId={employeeBranchOrgUnitId}
            />
          )}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    trip: selectTrip(state),
    orgUnitId: parseInt(ownProps.orgUnitId, 10),
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
  }),
  {
    updateTrip,
    fetchTrip,
    cleanTrip
  }
)(TripForm);
