// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';

import type {VehicleModelsFilter} from '../../ducks/vehicleModels';
import {applyFilters, cleanFilters} from '../../ducks/vehicleModels';
import type {AppState} from '../../ducks/redux';
import {Card, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from './../../components';
import {VehicleOwnerTypesEnum} from '../../lib/types/vehicleModel';

const {BrandsSelect, VehicleTypeSelect} = Selects;
const {Field} = Card;

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  cleanFilters: Function,
  applyFilters: Function,
  filters: VehicleModelsFilter,
  type: $Keys<VehicleOwnerTypesEnum>,
};

type State = {
  filters: VehicleModelsFilter
};

const initialState: State = {
  filters: {},
};

class Filter extends Component<Props, State> {
  state = initialState;

  applyFilters = async () => {
    try {
      await this.props.applyFilters(this.state.filters, this.props.type);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  cleanFilters = async () => {
    try {
      this.setState(initialState);
      await this.props.cleanFilters(this.props.type);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  changeFilter = (key: $Keys<VehicleModelsFilter>, value: any) => {
    this.setState(prev => ({
      filters: {
        ...prev.filters,
        [(key: string)]: value,
      },
    }));
  };

  componentDidMount() {
    this.setState({filters: this.props.filters[this.props.type] || {}});
  }

  componentDidUpdate(prev: Props) {
    if (!isEqual(this.props.filters[this.props.type], prev.filters[this.props.type])) {
      this.setState({filters: this.props.filters[this.props.type]});
    }
  }

  render() {
    const {filters} = this.state;
    return (
      <Content>
        <FilterContainer>
          <FilterItem>
            <Field mBottomNone>
              <BrandsSelect
                size="small"
                onChange={value => this.changeFilter('brand.id', value)}
                value={filters['brand.id']}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Input
                placeholder="Наименование"
                size="small"
                onChange={e => this.changeFilter('search', e.target.value)}
                value={filters['search']}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleTypeSelect
                selectableGroup
                size="small"
                onChange={types => this.changeFilter('type', types)}
                value={filters['type']}
              />
            </Field>
          </FilterItem>
          <FilterButtonsContainer
            applyFilter={this.applyFilters}
            cleanFilter={this.cleanFilters}
          />
        </FilterContainer>
      </Content>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filters: state.vehicleModels.filters,
  }),
  {
    cleanFilters,
    applyFilters,
  },
)(Filter);
