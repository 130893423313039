// @flow

import {Router} from '@reach/router';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import moment from 'moment';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {VehicleStatus} from '../../components';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {formatLicensePlateMask} from '../../components/masked-inputs/LicensePlateInput';
import {notificationLoading} from '../../components/Notifications';
import type {DropdownType} from '../../components/ui/Dropdown';
import type {AppState} from '../../ducks/redux';
import {Moment} from 'moment/moment';

import {cleanVehicle, fetchVehicle, updateVehicle} from '../../ducks/vehicle';
import {fetchRequest, vehicleApi} from '../../lib/api';
import { accessTypeEnum, fileTagEnum, vehicleStatusEnum, vehicleStatuses, vehicleTypes, vehicleTypeEnumvehicleTypeEnum, vehicleTypeEnum } from '../../lib/enum';
import {applyMaskToValue, formatDateTimeToISOString, getPathWithHistoryParams, navigate, isVehicleTypeElectric} from '../../lib/helpers';
import type {FileTag, UserAccess, Vehicle} from '../../lib/types';
import {Card} from './../../components';
import {Panel} from './../../components/layout';
import {Dropdown, TabItem, Tabs} from './../../components/ui';
import {withUserAccess} from './../withUserAccess';
import FuelMeasurementForm from './components/FuelMeasurementForm';
import * as VehicleTabs from './components/tabs';
import WriteOffVehicleForm from './components/WriteOffVehicleForm';
import {canShowOsagoData} from './lib';
import {WorkAccountingTypes} from '../../lib/types/vehicleModel';
import {ModalBranchSelect} from './components/ModalBranchSelect';
import GlobalPreloader from '../../components/layout/GlobalPreloader';
import {ModalChangeWorkMeter} from './components/ModalChangeWorkMeter';

const {
  EquipmentTab,
  MainTab,
  FuelTab,
  OsagoTab,
  KilometrageTab,
  MaintenanceTab,
  FilesTab,
  TrailersTab,
} = VehicleTabs;

type Props = {
  vehicleId: number,
  vehicle: Vehicle,
  fetchVehicle: (id: number) => Promise<void>,
  cleanVehicle: Function,
  updateVehicle: (vehicle: Vehicle) => void,
  userAccess: UserAccess[],
  location: Location & { state: { tab: string } },
  anchor?: string,
  tabName: string
};

const CardWrapper = styled.div``;
const CardPanel = styled(Panel)`
  display: flex;
  padding-top: 0;
  justify-content: space-between;
`;
const CarInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const CarInfo = styled.div`
  display: flex;
  flex-direction: column;

  & > h1 {
    margin-bottom: 12px;
  }
`;
const CardKilometrage = styled.div`
  display: flex;

  & > * {
    margin-right: 50px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const Operations = styled.div`
  display: inline-flex;
  align-items: center;

  & > * {
    margin-right: 16px;
  }

  & > *:last-child {
    margin-right: 0;
  }
`;
const StyledTabs = styled(Tabs)`
  & .tabs__item {
    font-weight: bold;
  }
`;

const {Field} = Card;

export const canEditVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.creatingVehicle,
];

export const canApproveVehicleAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingVehicle,
];

export const canAddMeasurementAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.gager,
];

export const canHandleEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingEquipment,
];

type TabsProps = {
  vehicleId: number,
  filesTag?: FileTag,
  tabName?:
    | 'documentation'
    | 'osago'
    | 'kilometrage'
    | 'fuel-and-oils'
    | 'equipment'
    | 'maintenance'
    | 'files',
  vehicle: Vehicle
};

const HeaderTabs = ({vehicleId, tabName, filesTag, vehicle}: TabsProps) => (
  <StyledTabs
    defaultTab={
      filesTag
        ? filesTag === fileTagEnum.all
          ? 'files'
          : 'documentation'
        : tabName
    }
    background="#F0F4F8"
  >
    <TabItem
      tabKey="documentation"
      label="Документация"
      onClick={() => navigate(`/vehicles/${vehicleId}/documentation`)}
    />
    {vehicle?.vehicleModel?.osagoNeeded &&
      canShowOsagoData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <TabItem
          tabKey="osago"
          label="ОСАГО"
          onClick={() => navigate(`/vehicles/${vehicleId}/osago`)}
        />
      )}
    <TabItem
      tabKey="kilometrage"
      label="Пробег"
      onClick={() => navigate(`/vehicles/${vehicleId}/kilometrage`)}
    />
    <TabItem
      tabKey="fuel-and-oils"
      label="ГСМ"
      onClick={() => navigate(`/vehicles/${vehicleId}/fuel-and-oils`)}
    />
    <TabItem
      tabKey="equipment"
      label="Оборудование"
      onClick={() => navigate(`/vehicles/${vehicleId}/equipment`)}
    />
    <TabItem
      tabKey="maintenance"
      label="Обслуживание"
      onClick={() => navigate(`/vehicles/${vehicleId}/maintenance`)}
    />
    <TabItem
      tabKey="files"
      label="Файлы"
      onClick={() => navigate(`/vehicles/${vehicleId}/files/all`)}
    />
    {vehicle.withTrailer && (
      <TabItem
        tabKey="trailers"
        label="Прицепы"
        onClick={() => navigate(`/vehicles/${vehicleId}/trailers`)}
      />
    )}
  </StyledTabs>
);

type State = {
  fuelMeasurementFormVisible: boolean,
  writeOffVehicleFormVisible: boolean,
  showPreloader: boolean,
  branchSelectModalVisible: boolean,
  workMeterChangeModalVisible: boolean,
  canEditVehicleWorkMeter: boolean,
};

export class VehicleCard extends Component<Props, State> {
  static defaultProps = {
    tabName: 'documentation',
  };
  state = {
    fuelMeasurementFormVisible: false,
    writeOffVehicleFormVisible: false,
    writeOffError: null,
    showPreloader: false,
    branchSelectModalVisible: false,
    workMeterChangeModalVisible: false,
    canEditVehicleWorkMeter: [accessTypeEnum.admin, accessTypeEnum.handlingVehicleWorkMeter]
      .some(access =>  this.props.userAccess.includes(access)),
  };
  dropdown: ?DropdownType;

  async componentDidMount() {
    await this.fetchVehicle();
    const {vehicle} = this.props;
    if (vehicle.orgUnitIsDeleted) {
      notification.warn({
        message: 'Внимание!',
        description: 'Данное ТС связанно с удалённой службой закрепления',
      });
    }
    if ( vehicle.usingNodeIsDelete) {
      notification.warn({
        message: 'Внимание!',
        description: 'Данное ТС связанно с удалённой службой использования',
      });
    }
    if (vehicle.financiallyResponsibleEmployeeIsDeleted) {
      notification.warn({
        message: 'Внимание!',
        description: 'Данное ТС связанно с удаленным сотрудником МОЛ',
      })
    }
  }

  fetchVehicle = async () => {
    const {vehicleId} = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
      navigate('/vehicles', true);
    }
  }

  approveVehicle = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle(
        {
          ...this.props.vehicle,
          yearIssued: this.props.vehicle.yearIssued ?? moment().year(),
          status: vehicleStatusEnum.working,
        },
        true,
      );
      // отключена отправка в парус с фронта, т.к. отправка осуществляется через бек.
      // возможно, со временем придется вернуть отправку с фронта
      // await this.parus();
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при изменении статуса',
        description: e.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  parus = async () => {
    const {vehicleId} = this.props;
    notification.close('saving');
    try {
      notificationLoading({
        message: 'Отправка информации в ИСУ “Парус”',
        key: 'parus',
      });
      const {status} = await vehicleApi.parus(vehicleId);
      notification.success({
        message: status,
      });
    } catch (e) {
      notification.warning({
        message: 'Данные не обработаны, поскольку ИСУ “Парус” не отвечает',
      });
    } finally {
      notification.close('parus');
    }
  };

  /**
   * Отправление на согласование
   */
  sendToAgreeing = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.onAgreeing,
      });
      notification.success({
        message: 'Успешно отправлено на согласование',
      });
    } catch (e) {
      notification.error({
        message: 'Произошла ошибка при отправлении на согласование',
      });
    } finally {
      notification.close('saving');
    }
  };

    /**
   * Изменение статуса заблокированного ТС
   */
    changeBlokedStatus = async (status: string) => {
      try {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        await vehicleApi.changeBlockedStatus(this.props.vehicle.id, status);
        await this.props.fetchVehicle(this.props.vehicleId);
        notification.success({
          message: 'Успешно',
        });
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message,
        });
      } finally {
        notification.close('saving');
      }
    };

  /**
   * Списание ТС
   */
  writeOfVehicle = async (data: {
    reasonNote?: string,
    decommissioningDate: Moment
  }) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle({
        ...this.props.vehicle,
        status: vehicleStatusEnum.isArchive,
        descommissionReason: data.reasonNote,
        decommissioningDate: formatDateTimeToISOString(
          data.decommissioningDate,
          data.decommissioningDate.format('DD.MM.YYYY HH:mm'),
        ),
      });
      this.setState({writeOffVehicleFormVisible: false});
      notification.success({
        message: 'Успешно списано',
      });
    } catch (e) {
      this.setState({
        writeOffVehicleFormVisible: true,
        updatingError: e.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  canEditVehicle = () =>
    this.props.userAccess.some(access => canEditVehicleAccess.includes(access)) &&
    this.props.vehicle.status !== vehicleStatusEnum.sendingToParus;

  canApproveVehicle = () =>
    this.props.userAccess.some(access =>
      canApproveVehicleAccess.includes(access),
    ) && this.props.vehicle.status === vehicleStatusEnum.onAgreeing;

  canAddMeasurement = () =>
    this.props.userAccess.some(access =>
      canAddMeasurementAccess.includes(access),
    );

  canHandleEquipment = () =>
    this.props.userAccess.some(access =>
      canHandleEquipmentAccess.includes(access),
    );

  deleteVehicle = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await vehicleApi.deleteVehicle(this.props.vehicleId);
      navigate('/vehicles', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  canHandleMaintenanceServices = () => {
    return this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ].includes(access),
    );
  };

  canViewingSystemJournal = () => {
    return this.props.userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal].includes(
        access,
      ),
    );
  };

  canCopyVehicleToAnotherBranch = () => {
    return this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.copyVehicleToAnotherBranch,
      ].includes(access),
    );
  };

  handleCompare = async () => {
    try {
      this.setState({showPreloader: true});
      document.body.style.overflow = 'hidden';
      await fetchRequest.put(`/vehicle/${this.props.vehicleId}/compareInventoryCard`);
      await this.props.fetchVehicle(this.props.vehicleId);
      notification.success({
        message: 'Сверка завершена. Данные ТС обновлены',
      });
    } catch (e) {
      notification.error({
        message: 'Не удалось отправить данные',
        description: e.title || e.message,
      });
    } finally {
      document.body.style.overflow = '';
      this.setState({showPreloader: false});
    }
  };

  render() {
    const {vehicle, vehicleId, userAccess} = this.props;
    const {showPreloader, canEditVehicleWorkMeter, branchSelectModalVisible, workMeterChangeModalVisible} = this.state;
    if (!vehicle) {
      return null;
    }
    const canEdit = this.canEditVehicle();
    const canApprove = this.canApproveVehicle();
    const enableAddMeasurement = this.canAddMeasurement();
    const canHandleEquipment = this.canHandleEquipment();
    const canHandleMaintenanceServices = this.canHandleMaintenanceServices();
    const canViewingSystemJournal = this.canViewingSystemJournal();
    const {fuelsAndOils, vehicleModel} = vehicle;
    const statusNotIsSendingAndBlocked = vehicle.status !== vehicleStatusEnum.sendingToParus &&
      vehicle.status !== vehicleStatusEnum.blocked;
    const showCompareButton = [
      accessTypeEnum.admin,
      accessTypeEnum.compareOneInventoryCard,
      accessTypeEnum.compareAllInventoryCard,
    ].some(access => userAccess.includes(access)) && vehicle.status !== vehicleStatusEnum.isArchive 
          && !vehicle.isLease;

    const isElectricVehicle = isVehicleTypeElectric(vehicle?.vehicleModel?.type);

      const showChangingStatusBlockedVehicleButton = [
          accessTypeEnum.admin,
          accessTypeEnum.changingStatusBlockedVehicles,
      ].some(access => userAccess.includes(access)) && vehicle.status == vehicleStatusEnum.blocked;

    return (
      <>
        {showPreloader && (
          <GlobalPreloader>
            <p>Осуществляется сверка.</p>
            <p>Пожалуйста, подождите.</p>
          </GlobalPreloader>
        )}
        <ModalBranchSelect
          visible={branchSelectModalVisible}
          closeModal={() => this.setState({branchSelectModalVisible: false})}
          vehicleId={vehicleId}
        />
        <ModalChangeWorkMeter
          visible={workMeterChangeModalVisible}
          closeModal={() => this.setState({workMeterChangeModalVisible: false})}
          workAccountingType={vehicleModel?.workAccountingType}
          vehicleId={vehicleId}
          updateVehicle={this.fetchVehicle}
        />
        <CardWrapper>
          <Header
            left={
              <Breadcrumbs>
                <Crumb to={getPathWithHistoryParams('/vehicles')}>
                  Собственные ТС
                </Crumb>
                <Crumb to={`/vehicles/${vehicle.id}`}>ТС</Crumb>
              </Breadcrumbs>
            }
            right={
              <Operations>
                {canApprove && (
                  <Button
                    type="primary"
                    onClick={this.approveVehicle}
                    data-cy="approve"
                  >
                    Принять в работу
                  </Button>
                )}
                {vehicleStatusEnum.draft === vehicle.status && canEdit && (
                  <Button type="primary" onClick={this.sendToAgreeing}>
                    На согласование
                  </Button>
                )}
                {showChangingStatusBlockedVehicleButton && (
                  <>
                  <Button onClick={() => this.changeBlokedStatus(vehicleStatusEnum.draft)} type="primary">Черновик</Button>
                  <Button onClick={() => this.changeBlokedStatus(vehicleStatusEnum.working)} type="primary">В работу</Button>
                  </>
                )}
                {showCompareButton && (
                  <Button onClick={this.handleCompare} type="primary">Провести сверку</Button>
                )}
                <Dropdown
                  ref={dropdown => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      {enableAddMeasurement && statusNotIsSendingAndBlocked && (
                        <Menu.Item
                          onClick={() => {
                            this.dropdown && this.dropdown.onVisibleChange(false);
                            this.setState({fuelMeasurementFormVisible: true});
                          }}
                        >
                          Контрольный замер топлива
                        </Menu.Item>
                      )}
                      {enableAddMeasurement && statusNotIsSendingAndBlocked && canEdit && <Menu.Divider />}
                      {canEditVehicleWorkMeter && (
                        <Menu.Item onClick={() => {
                          this.dropdown && this.dropdown.onVisibleChange(false);
                          this.setState({workMeterChangeModalVisible: true})
                        }}>
                          Замена прибора учета
                        </Menu.Item>
                      )}
                      {canViewingSystemJournal && (
                        <Menu.Item
                          onClick={() =>
                            navigate(
                              `/admin/journals?entityType=vehicle&entityId=${vehicleId}`,
                            )
                          }
                        >
                          История изменений
                        </Menu.Item>
                      )}
                      {canEdit && vehicle.status !== vehicleStatusEnum.isArchive && statusNotIsSendingAndBlocked && (
                        <Menu.Item>
                          <Popconfirm
                            overlayStyle={{zIndex: 2000}}
                            title="Вы действительно хотите списать ТС?"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={() => this.setState({writeOffVehicleFormVisible: true})}
                            placement="left"
                            onVisibleChange={flag => this.dropdown && this.dropdown.onVisibleChange(flag)}
                          >
                            Списание ТС
                          </Popconfirm>
                        </Menu.Item>
                      )}
                      {canEdit && vehicle.status !== vehicleStatusEnum.isArchive && statusNotIsSendingAndBlocked &&
                        this.canCopyVehicleToAnotherBranch() && (
                          <Menu.Item onClick={() => this.setState({branchSelectModalVisible: true})}>
                            Переместить
                          </Menu.Item>
                        )}
                      {canEdit && vehicle.status !== vehicleStatusEnum.sendingToParus && (
                        <Menu.Item
                          onClick={() => navigate(`/vehicles/edit/${vehicleId}`)}
                        >
                          Редактировать
                        </Menu.Item>
                      )}
                      {canEdit && statusNotIsSendingAndBlocked && (
                        <Menu.Item>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            okText="Да"
                            cancelText="Нет"
                            onConfirm={this.deleteVehicle}
                            placement="bottomRight"
                            onVisibleChange={flag =>
                              this.dropdown && this.dropdown.onVisibleChange(flag)
                            }
                          >
                            Удалить
                          </Popconfirm>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button type="primary" icon="ellipsis" />
                </Dropdown>
              </Operations>
            }
          />
          <CardPanel>
            <CarInfoWrapper>
              <CarInfo>
                <h1>
                  {vehicle.vehicleModel &&
                    `${vehicleTypes[vehicle.vehicleModel.type]} ${
                      vehicle.vehicleModel.brandName
                    } ${vehicle.vehicleModel.name}`}
                </h1>
                <CardKilometrage>
                  <Field label="Статус">
                    <VehicleStatus status={vehicle.status} />
                  </Field>
                  {vehicle.licensePlate && (
                    <Field label="Гос. номер">
                      {applyMaskToValue(
                        vehicle.licensePlate,
                        formatLicensePlateMask,
                      )}
                    </Field>
                  )}
                  <Field label="Год выпуска">{vehicle.yearIssued}</Field>
                  {vehicleModel?.workAccountingType &&
                    (vehicleModel?.workAccountingType ===
                      WorkAccountingTypes.kilometrage ||
                      vehicleModel?.workAccountingType ===
                      WorkAccountingTypes.all) && (
                      <Field label="Пробег с начала эксплуатации ТС">
                        {vehicle.kilometrage ? `${vehicle.kilometrage} км` : '-'}
                      </Field>
                    )}
                  {!isElectricVehicle && vehicleModel?.workAccountingType &&
                    (vehicleModel?.workAccountingType === WorkAccountingTypes.workHours ||
                      vehicleModel?.workAccountingType === WorkAccountingTypes.all) && (
                      <Field label="Маш. часы с начала эксплуатации ТС">
                        {vehicle.engineWorkHours ? `${vehicle.engineWorkHours} ч` : '-'}
                      </Field>
                    )}
                  {vehicleModel?.workAccountingType &&
                    (vehicleModel?.workAccountingType ===
                      WorkAccountingTypes.kilometrage ||
                      vehicleModel?.workAccountingType ===
                      WorkAccountingTypes.all) && (
                      <Field label="Пробег по прибору учета">
                        {vehicle.kilometrageByOdometr ? `${vehicle.kilometrageByOdometr} км` : '-'}
                      </Field>
                    )}
                  {!isElectricVehicle && vehicleModel?.workAccountingType &&
                    (vehicleModel?.workAccountingType === WorkAccountingTypes.workHours ||
                      vehicleModel?.workAccountingType === WorkAccountingTypes.all) && (
                      <Field label="Показания счетчика маш. часов">
                        {vehicle.engineWorkHoursByMeter ? `${vehicle.engineWorkHoursByMeter} ч` : '-'}
                      </Field>
                    )}
                </CardKilometrage>
              </CarInfo>
            </CarInfoWrapper>
          </CardPanel>
          <Router primary={false}>
            <HeaderTabs
              path="files/:filesTag"
              vehicleId={vehicle.id}
              vehicle={vehicle}
            />
            <HeaderTabs
              path=":tabName"
              vehicleId={vehicle.id}
              vehicle={vehicle}
            />
            <HeaderTabs
              path=":tabName/:anchor"
              vehicleId={vehicle.id}
              vehicle={vehicle}
            />
            <HeaderTabs
              path="/"
              vehicleId={vehicle.id}
              tabName="documentation"
              vehicle={vehicle}
            />
          </Router>
          <Router primary={false}>
            <MainTab
              path="documentation"
              default
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
              anchor={this.props.anchor}
            />
            <MainTab
              path="documentation/:anchor"
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
              anchor={this.props.anchor}
            />
            <OsagoTab
              path="osago"
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
            />
            <OsagoTab
              path="osago/:anchor"
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
            />
            <KilometrageTab
              path="kilometrage"
              vehicle={vehicle}
              editable={canEdit}
            />
            <KilometrageTab
              path="kilometrage/:anchor"
              vehicle={vehicle}
              editable={canEdit}
            />
            <FuelTab
              path="fuel-and-oils"
              vehicle={vehicle}
              fuelsAndOils={fuelsAndOils}
              editable={canEdit}
              canAddFuelMeasurement={enableAddMeasurement}
            />
            <FuelTab
              path="fuel-and-oils/:anchor"
              vehicle={vehicle}
              fuelsAndOils={fuelsAndOils}
              editable={canEdit}
              canAddFuelMeasurement={enableAddMeasurement}
            />
            <EquipmentTab
              path="equipment"
              vehicle={vehicle}
              editable={canEdit}
              canAddFuelMeasurement={enableAddMeasurement}
              canHandleEquipment={canHandleEquipment}
            />
            <EquipmentTab
              path="equipment/:anchor"
              vehicle={vehicle}
              editable={canEdit}
              canAddFuelMeasurement={enableAddMeasurement}
              canHandleEquipment={canHandleEquipment}
            />
            <MaintenanceTab
              path="maintenance"
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
            />
            <MaintenanceTab
              path="maintenance/:anchor"
              vehicle={vehicle}
              editable={canEdit}
              canHandleMaintenanceServices={canHandleMaintenanceServices}
            />
            <FilesTab path="files/all" vehicle={vehicle} editable={canEdit} />
            <TrailersTab path="trailers" vehicle={vehicle} editable={canEdit} />
          </Router>
          <FuelMeasurementForm
            visible={this.state.fuelMeasurementFormVisible}
            hideModal={() => this.setState({fuelMeasurementFormVisible: false})}
          />
          <WriteOffVehicleForm
            visible={this.state.writeOffVehicleFormVisible}
            handleOk={async data => {
              this.setState({updatingError: null});
              await this.writeOfVehicle(data);
            }}
            onModalClose={() =>
              this.setState({writeOffVehicleFormVisible: false})
            }
            error={this.state.updatingError}
          />
        </CardWrapper>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
  },
)(withUserAccess(VehicleCard));
