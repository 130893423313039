import styled from 'styled-components';
import { Section } from '../../../components/layout';
import { Icon, ListTable } from '../../../components/ui';

export const Content = styled.div`
  padding: 16px;
`;
export const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
export const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
export const StyledListTable = styled(ListTable)`
  .ant-row.ant-form-item {
    margin-bottom: 0;
  }
`;
