// @flow

import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table';

import {washingPriceListApi} from './../../lib/api';
import {formatDateTimeToString, getPathWithHistoryParams} from './../../lib/helpers';
import type {WashingPriceList} from '../../lib/types';

import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  washingPriceListId: string
};

type State = {
  washingPriceList: ?WashingPriceList
};

class WashingPriceListCard extends Component<Props, State> {
  state = {
    washingPriceList: null
  };

  async componentDidMount() {
    let washingPriceListId = parseInt(this.props.washingPriceListId, 10);
    if (washingPriceListId) {
      try {
        const wash = await washingPriceListApi.fetchWashingPrice(
          washingPriceListId
        );
        this.setState({ washingPriceList: wash });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  render() {
    const { washingPriceList } = this.state;
    const { washingPriceListId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/washing-price-list')}>
                Список прейскурантов на мойки
              </Crumb>
              <Crumb to={`/admin/washing-price-list/${washingPriceListId}`}>
                Просмотр прайса на мойку №{washingPriceListId}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{`Прайс на мойку №${washingPriceListId}`}</h1>
        </StyledPanel>
        <Section>
          {washingPriceList && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Подрядчик">
                    {washingPriceList && washingPriceList.contractor
                      ? washingPriceList.contractor.company.name
                      : null}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Номер договора">
                    {washingPriceList.contractNumber}
                  </Field>
                </GridItem>

                <GridItem>
                  <Field label="Дата начала действия прейскуранта">
                    {formatDateTimeToString(
                      washingPriceList.startDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания действия прейскуранта">
                    {washingPriceList.endDate
                      ? formatDateTimeToString(
                          washingPriceList.endDate,
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem fullWidth>
                  {
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: 'Тип ТС',
                          dataIndex: 'washingVehicleType',
                          width: 350
                        },
                        {
                          title: 'Кузов',
                          dataIndex: 'bodyPrice',
                          render: (bodyPrice: number) =>
                            bodyPrice.toLocaleString('ru-RU', {
                              style: 'currency',
                              currency: 'RUB'
                            })
                        },
                        {
                          title: 'Салон',
                          dataIndex: 'interiorPrice',
                          render: (interiorPrice: number) =>
                            interiorPrice.toLocaleString('ru-RU', {
                              style: 'currency',
                              currency: 'RUB'
                            })
                        },
                        {
                          title: 'ДВС',
                          dataIndex: 'enginePrice',
                          render: (enginePrice: number) =>
                            enginePrice.toLocaleString('ru-RU', {
                              style: 'currency',
                              currency: 'RUB'
                            })
                        }
                      ]}
                      dataSource={
                        washingPriceList ? washingPriceList.washingPrices : []
                      }
                    />
                  }
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default WashingPriceListCard;
