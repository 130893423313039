// @flow
import React from 'react';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import moment from 'moment';

import Input from 'antd/lib/input/Input';
import DatePicker from 'antd/lib/date-picker';
import Button from 'antd/lib/button/button';

import type { FuelCard } from '../../../lib/types/fuelCard';
import { formatDateTimeToISOString } from './../../../lib/helpers';

import Form from '../../../components/Form';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { Selects } from './../../../components';
import Section from '../../../components/layout/Section';
import CancelButton from '../../../components/CancelButton';

const { FuelGroupSelect, VehicleSelect } = Selects;
const Footer = styled(Section)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;
const StyledSection = styled(Section)`
  padding: 16px;
`;

type Props = {
  fuelCard: FuelCard,
  onSubmit: (values: FuelCard) => Promise<void>,
  onCancel: Function
};

export default ({ onSubmit, fuelCard, onCancel }: Props) => (
  <Form initialValues={fuelCard} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        isSubmitting
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="ТС" name="vehicleId">
                  {({ name, value }) => (
                    <VehicleSelect
                      name={name}
                      value={value}
                      onBlur={() => handleBlur({ target: { name } })}
                      onChange={(value: string) => setFieldValue(name, value)}
                      data-cy="vehicleSelect"
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="cardNumber" label="Номер карты" required>
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="fuelGroup" label="Вид топлива" required>
                  {({ name, value }) => (
                    <FuelGroupSelect
                      value={value}
                      onBlur={() => handleBlur({ target: { name } })}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="limit" label="Лимит" required>
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="balance" label="Остаток">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата топливной карты"
                  required
                  name="installDate"
                >
                  {({ value, name }) => (
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={value ? moment(value) : undefined}
                      onChange={(value: Object, dateString: string) => {
                        setFieldValue(
                          name,
                          formatDateTimeToISOString(value, dateString)
                        );
                      }}
                      name={name}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);
