// @flow
import React, {Component} from 'react';
import type {FormikProps} from 'formik';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {connect} from 'react-redux';
import isNumber from 'lodash/isNumber';
import styled from 'styled-components';

import {Form, OrgUnitSelect, Selects, WaypointPicker} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import type {Location, UserAccess, WayPoint} from '../../lib/types';
import {accessTypeEnum} from '../../lib/enum';
import type {AppState} from '../../ducks/redux';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {locationApi} from './../../lib/api';
import {withUserAccess} from './../withUserAccess';
import {notificationLoading} from '../../components/Notifications';
import CancelButton from '../../components/CancelButton';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import LocationSelect from '../../components/selects/LocationSelect';
import {getYmapPlace} from '../../lib/gis';

const {YesNoSelect} = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  location: $Shape<Location>,
  employeeBranchOrgUnitId: number,
  showOrgUnitSelect: boolean
};

const InnerForm = ({
  onSubmit,
  location,
  employeeBranchOrgUnitId,
  showOrgUnitSelect,
  onCancel,
}: FormProps) => (
  <Form
    initialValues={location}
    onSubmit={onSubmit}
    validate={(values: Location) => {
      let errors = {};

      if (
        !isNumber(values.latitude) ||
        values.latitude < -90 ||
        values.latitude > 90
      ) {
        errors.latitude = 'Корректное значение широты от -90 до 90';
      }

      if (
        !isNumber(values.longitude) ||
        values.longitude < -180 ||
        values.longitude > 180
      ) {
        errors.longitude = 'Корректное значение долготы от -180 до 180';
      }

      if (!isNumber(values.radius) || values.radius < 0) {
        errors.radius = 'Радиус должен быть положительным числом';
      }
      return errors;
    }}
  >
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        setFieldValue,
        handleBlur,
        dirty,
        isSubmitting,
        values,
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Наименование" required fast name="name">
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                {showOrgUnitSelect && (
                  <GridItem>
                    <FormField label="Подразделение" required fast name="orgUnitId">
                      {({value, name}) => (
                        <OrgUnitSelect
                          data-cy="orgUnitSelect"
                          value={value}
                          filter={{
                            nodeId: employeeBranchOrgUnitId,
                            nodeFilterType: 'branchAndChildren',
                            onlyBranch: true,
                          }}
                          onBlur={() => handleBlur({target: {name}})}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem fullWidth style={{marginBottom: 10}}>
                  <FormField label="Адрес" required fast name="address">
                    {({value, name}) => (
                      <LocationSelect
                        showArrow={false}
                        onSelect={async (text, options) => {
                          const {address, name: locationName} = options.props.location;
                          setFieldValue(name, address);
                          setFieldValue('name', locationName);
                          const {lat, lng} = await getYmapPlace(address);
                          setFieldValue('longitude', lng);
                          setFieldValue('latitude', lat);
                        }}
                        value={value}
                        placeholder="Введите адрес объекта"
                      />
                    )}
                  </FormField>
                  <WaypointPicker
                    location={values}
                    onPickWaypoint={(waypoint: WayPoint) => {
                      setFieldValue('longitude', waypoint.longitude);
                      setFieldValue('latitude', waypoint.latitude);
                      setFieldValue('address', waypoint.address);
                      setFieldValue('name', waypoint.name);
                    }}
                  />
                </GridItem>
                <GridItem>
                  <FormField label="Долгота" required fast name="longitude" hasFeedback={false}>
                    {({value, name}) => (
                      <InputNumber
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Широта" required fast name="latitude" hasFeedback={false}>
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        name={name}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Радиус геозоны, метры" required fast name="radius" hasFeedback={false}>
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        name={name}
                        min={0}
                        onChange={(value: number) => setFieldValue(name, value)}
                        onBlur={handleBlur}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="isDefault" label="Точка постоянного местоположения ТС" defaultValue={true} fast>
                    {({value, name}) => (
                      <YesNoSelect
                        value={value}
                        name={name}
                        disabled
                        data-cy="isParking"
                        onBlur={() => handleBlur({target: {name}})}
                        onChange={(value: boolean) =>
                          setFieldValue(name, value)
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  locationId: ?number,
  employeeBranchOrgUnitId: number,
  userAccess: UserAccess[]
};

type State = {
  location?: Location
};

class LocationForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    const {locationId} = this.props;
    if (locationId) {
      try {
        const location = await locationApi.fetchLocation(locationId);
        this.setState({
          location,
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
        navigate('/admin/locations/uat', true);
      }
    }
  }

  onSubmit = async (location: Location) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (location.id) {
        await locationApi.updateLocation(location);
      } else {
        await locationApi.addLocation(location);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate('/admin/locations/uat', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const {employeeBranchOrgUnitId, userAccess} = this.props;
    const {location} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/locations/uat')}>
                Объекты
              </Crumb>
              {location ? (
                <Crumb to={`/admin/locations/form/${location.id}`}>
                  Объект №{location.id}
                </Crumb>
              ) : (
                <Crumb to={`/admin/locations/form/new`}>Новый объект</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{location ? `Объект №${location.id}` : 'Новый объект'}</h1>
        </StyledPanel>
        <InnerForm
          location={location || {orgUnitId: employeeBranchOrgUnitId}}
          showOrgUnitSelect={userAccess.some(access =>
            [accessTypeEnum.admin].includes(access),
          )}
          employeeBranchOrgUnitId={employeeBranchOrgUnitId}
          onSubmit={this.onSubmit}
          onCancel={() => navigate('/admin/locations/uat', true)}
        />
      </>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  locationId: parseInt(props.locationId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(withUserAccess(LocationForm));
