// @flow
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem} from '../../../components';
import Field from '../../../components/card/Field';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import {VehicleTariffStatusSelect, VehicleTariffStatusWithoutDeactivatedSelect} from '../../../components/selects/AutoGeneratedEnumSelects';

import {formatDateTimeToISOString} from '../../../lib/helpers';
import SecuringContractorSelect from '../../../components/selects/SecuringContractorSelect';

const {RangePicker} = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type VehicleTariffFilterParams = {
  contractorId?: number,
  startDate?: string,
  endDate?: string,
};

type Props = {
  filter: VehicleTariffFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  version: RateType
};

export const VehicleTariffFilter = ({
  filter,
  cleanFilter,
  applyFilter,
  version,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams<VehicleTariffFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <SecuringContractorSelect
                  size="small"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null,
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString) ||
                      undefined,
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString) ||
                      undefined,
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                {version === 'fact' ? (
                  <VehicleTariffStatusSelect
                    size="small"
                    onChange={value => changeValue('status', value)}
                    value={values.status}
                  />
                ) : (
                  <VehicleTariffStatusWithoutDeactivatedSelect
                    size="small"
                    onChange={value => changeValue('status', value)}
                    value={values.status}
                  />
                )}
              </Field>
            </FilterItem>
            
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default VehicleTariffFilter;
