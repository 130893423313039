// @flow
import notification from 'antd/lib/notification';
import React from 'react';
// import moment from 'moment';
import moment from 'moment';
import { notificationLoading } from '../../../components/Notifications';
import { Section } from '../../../components/layout';
import Header from '../../../components/layout/Header';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';
import { Table } from '../../../components/ui';
import { reportTaxedTripsApi } from '../../../lib/api';
import { applyMaskToValue } from '../../../lib/helpers';
import type { ReportTaxedTrip, ReportTaxedTripsParams } from '../../../lib/types/reportTaxedTrips';
import Filter from './components/Filter';

const rowsPerPage = 100;

const initialParams: ReportTaxedTripsParams = {
  //Раскомментировать, если нужны по умолчанию даты текущего дня
  // startDate: moment
  //   .utc()
  //   .startOf('month')
  //   .toISOString(),
  // endDate: moment
  //   .utc()
  //   .endOf('month')
  //   .toISOString(),
  pageSize: rowsPerPage,
};

type State = {
  params: ReportTaxedTripsParams,
  data: ReportTaxedTrip[],
  loading: boolean,
  page: number,
  totalCount: number,
  pageSize: number
};

/**
 * Отчет о таксировках
 */
export class TaxedTripsList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    page: 1,
    pageSize: rowsPerPage,
    totalCount: 0
  };

  async componentDidMount() {
    await this.fetchReportTaxedTrips();
  }

  fetchReportTaxedTrips = async (page = 1) => {
    const params = this.state.params;
    try {
      this.setState({ loading: true, page });
      const response = await reportTaxedTripsApi.fetchReportTaxedTrips({
        ...params,
        page
      });
      this.setState({ data: response.data, totalCount: response.totalCount });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<ReportTaxedTripsParams>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      },
      page: 1
    }));
  };

  applyParams = () => this.fetchReportTaxedTrips(this.state.page);

  cleanParams = async () => {
    await this.setState({params: initialParams, page: 1});
    await this.fetchReportTaxedTrips(this.state.page);
  }

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing'
      });
      await reportTaxedTripsApi.printReportTaxedTrips(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Дата',
      dataIndex: 'startDate',
      render: date => date ? moment(date).utc().format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Номер ПЛ',
      dataIndex: 'tripNumber',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Марка/модель ТС',
      dataIndex: 'vehicleName'
    },
    {
      title: 'Водитель',
      dataIndex: 'driverName'
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`
    },
    {
      title: 'Моточасы ТС',
      dataIndex: 'vehicleMachineHours',
      render: (vehicleMachineHours: number) => vehicleMachineHours ? `${vehicleMachineHours} ч.` : '-',
    },
    {
      title: 'Вид топлива',
      dataIndex: 'fuelType',
    },
    {
      title: 'ГСМ в баке при выезде',
      dataIndex: 'fuelAtStart',
      render: (fuelAtStart: number) => `${fuelAtStart.toFixed(2)} л.`
    },
    {
      title: 'Выдано топлива по ПЛ',
      dataIndex: 'issuedFuel',
      render: (fuelAtEnd: number) => `${fuelAtEnd.toFixed(2)} л.`
    },
    {
      title: 'Расход ГСМ',
      dataIndex: 'fuelConsumption',
      render: (fuelConsumption: number) => `${fuelConsumption.toFixed(2)}, л`
    },
    {
      title: 'ГСМ в баке при заезде',
      dataIndex: 'fuelAtEnd',
      render: (fuelAtEnd: number) => `${fuelAtEnd.toFixed(2)} л.`
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
    }
  ];

  render() {
    const { data, loading, page, pageSize, totalCount } = this.state;
    return (
      <>
        <Header left={<h1>Отчет о таксированных путевых листах</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onChangeFilter={this.handleChangeFilter}
            onExport={this.handleExport}
          />
          <Table
            // Высчитываем высоту таблицы. 290 - примерное значение высоты остальных элементов страницы - хедера, фильтров и т.д.
            bodyStyle={{
              height: data.length ? `${document.documentElement.clientHeight - 290}px` : "auto"
            }}
            pagination={{
              page,
              pageSize,
              totalCount
            }}
            loading={loading}
            data={data}
            columns={this.columns}
            fetch={this.fetchReportTaxedTrips}
            rowKey='tripNumber'
          />
        </Section>
      </>
    );
  }
}

export default TaxedTripsList;
