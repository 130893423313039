// @flow
import React, {useCallback} from 'react';
import {Button} from 'antd';
import notification from 'antd/lib/notification';
import {accessTypeEnum} from '../../lib/enum';

import {navigate} from '../../lib/helpers';
import {placeApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {notificationLoading} from '../../components/Notifications';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';

const entryPointPath = '/admin/place/';

const columns = [
  {
    title: 'Наименование филиала',
    dataIndex: 'orgUnitName',
  },
  {
    title: 'Rn',
    dataIndex: 'parusId',
  },
  {
    title: 'Краткое наименование объекта',
    dataIndex: 'shortName',
  },
  {
    title: 'Полное наименование объекта',
    dataIndex: 'fullName',
  },
];

const access = {};

export default withUserAccess(({userAccess}) => {
  const camUpdateParus = () =>
    userAccess.some(access => access === accessTypeEnum.requestingPlaces);
  
  const fetchParus = useCallback(async () => {
    try {
      try {
        notificationLoading({
          message: 'Запрос данных',
          key: 'request',
        });
        const data = await placeApi.updateParus();
        if (data) {
          notification.success({message: data.status});
        } else {
          notification.error('Ошибка запроса');
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message,
        });
      }
    }
    finally {
      notification.close('request');
    }
  }, []);
  
  return (
    <Page>
      <CommonListPage
        crud={placeApi}
        pageHeaderProps={{
          mainHeader: 'Местоположения',
          access,
          rightHeader: (
            <>
              {camUpdateParus() && (
                <Button onClick={fetchParus}>Запросить данные</Button>
              )}
            </>
          ),
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
        }}
        filter={Filter}
      />
    </Page>
  );
});
