// @flow
import React from 'react';
import {TabItem, Tabs} from '../../../components/ui';
import {VehicleOwnerTypesEnum} from '../../../lib/types/vehicleModel';

const commonUrl = '/vehicle-models/';

type Props = {
  selfTabAccess: boolean,
  contractTabAccess: boolean,
}

export const HeaderTabs = ({contractTabAccess, selfTabAccess}: Props) => {
  return (
    <Tabs withRouter>
      {selfTabAccess && (
        <TabItem
          url={`${commonUrl}${VehicleOwnerTypesEnum.self}`}
          label="Собственные"
          activeUrl={[`${commonUrl}${VehicleOwnerTypesEnum.self}`]}
        />
      )}
      {contractTabAccess && (
        <TabItem
          url={`${commonUrl}${VehicleOwnerTypesEnum.contract}`}
          label="Наемные"
          activeUrl={[`${commonUrl}${VehicleOwnerTypesEnum.contract}`]}
        />
      )}
    </Tabs>
  );
};
