// @flow
import React from 'react';
import type { FormikProps } from 'formik';
import styled from 'styled-components';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type { OsagoBaseRateMultiplier } from './../../../lib/types';
import {
  formatDateTimeToISOString,
  disabledDateMin
} from './../../../lib/helpers';

import { Form } from '../../../components';
import { Section, Footer } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import type { FormFieldType } from './../../../components/Form';
import CancelButton from '../../../components/CancelButton';

const SectionContent = styled.div`
  padding: 16px;
`;

const Label = styled.div`
  font-size: 14px;
  padding-top: 5px;
`;
const Required = styled.span`
  color: red;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 12px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const StyledGridItem = styled(GridItem)`
  padding: 0px 10px;
`;

type FormProps = {
  osagoBaseRateMultiplier: ?OsagoBaseRateMultiplier,
  onSubmit: Function,
  onCancel: () => void
};

export default ({ osagoBaseRateMultiplier, onSubmit, onCancel }: FormProps) => {
  return (
    <Form initialValues={osagoBaseRateMultiplier} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, setFieldValue, dirty, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionContent>
                <Grid
                  gutter="16px"
                  cols={2}
                  style={{
                    fontWeight: '500',
                    marginBottom: '20px'
                  }}
                >
                  <GridItem>Наименование</GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>Текущее (руб.)</StyledGridItem>
                      <StyledGridItem>Минимальное (руб.)</StyledGridItem>
                      <StyledGridItem>Максимальное (руб.)</StyledGridItem>
                    </Grid>
                  </GridItem>
                </Grid>

                <Grid gutter="16px" cols={2}>
                  <GridItem>
                    <Label>
                      Транспортные средства категории "B", "BE"
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="bCur" required>
                          {({ value, name }) => {
                            return (
                              <StyledInputNumber
                                min={0.01}
                                decimalSeparator=","
                                step={0.01}
                                name={name}
                                value={value}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                              />
                            );
                          }}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="bMin" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="bMax" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <Label>
                      Транспортные средства категорий "C" и "CE" с разрешенной
                      максимальной массой 16 тонн и менее
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="cMax16Cur" required>
                          {({ value, name }) => {
                            return (
                              <StyledInputNumber
                                min={0.01}
                                decimalSeparator=","
                                step={0.01}
                                name={name}
                                value={value}
                                onChange={(value: number) =>
                                  setFieldValue(name, value)
                                }
                              />
                            );
                          }}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="cMax16Min" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="cMax16Max" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <Label>
                      Транспортные средства категорий "C" и "CE" с разрешенной
                      максимальной массой более 16 тонн
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="cMin16Cur" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="cMin16Min" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="cMin16Max" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <Label>
                      Транспортные средства категорий "D" и "DE" с числом
                      пассажирских мест до 16 включительно
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="dMax16Cur" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="dMax16Min" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="dMax16Max" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <Label>
                      Транспортные средства категорий "D" и "DE" с числом
                      пассажирских мест более 16 мест
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="dMin16Cur" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="dMin16Min" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="dMin16Max" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <Label>
                      Тракторы,самоходные дорожностроительные и иные машины
                      <Required>*</Required>
                    </Label>
                  </GridItem>
                  <GridItem>
                    <Grid cols={3}>
                      <StyledGridItem>
                        <FormField fast name="otherCur" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="otherMin" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                      <StyledGridItem>
                        <FormField fast name="otherMax" required>
                          {({ value, name }) => (
                            <StyledInputNumber
                              min={0.01}
                              decimalSeparator=","
                              step={0.01}
                              name={name}
                              value={value}
                              onChange={(value: number) =>
                                setFieldValue(name, value)
                              }
                            />
                          )}
                        </FormField>
                      </StyledGridItem>
                    </Grid>
                  </GridItem>

                  <GridItem>
                    <FormField
                      fast
                      label="Дата начала действия"
                      name="startDate"
                      required
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                            setFieldValue('endDate', undefined);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата начала окончания" name="endDate">
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          disabled={values.startDate ? false : true}
                          disabledDate={(currentDate: moment) =>
                            disabledDateMin(
                              currentDate,
                              moment(values.startDate)
                            )
                          }
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
