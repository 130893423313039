// @flow
import React from 'react';
import qs from 'query-string';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';

import {actApi} from '../../../lib/api';
import {formatDateTimeToString, getListInitialState, navigate, toLocalStringRu} from '../../../lib/helpers';
import type {Act, ListState, UserAccess} from '../../../lib/types';
import {actStatus, actStatusEnum, expenseDirectionTypes} from '../../../lib/enum';

import {Icon} from '../../../components/ui';
import Table from '../../../components/ui/Table';
import {Header, Section} from '../../../components/layout';
import {notificationLoading} from '../../../components/Notifications';

import {withUserAccess} from '../../withUserAccess';
import {handlingExternalActs} from '../accessRight';

import Tabs from '../components/Tabs';
import type {ActFilterParams} from './components';
import {Filter} from './components';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<Act> & {
  filter: any
};

export class ActList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: '№ акта',
      key: 'actNumber',
      dataIndex: 'actNumber'
    },
    {
      title: 'Тип направления',
      key: 'expenseDirectionType',
      dataIndex: 'expenseDirectionType',
      render: type => expenseDirectionTypes[type]
    },
    {
      title: 'Дата акта',
      key: 'actDate',
      dataIndex: 'actDate',
      width: '150',
      render: (actDate: string) =>
        `${formatDateTimeToString(actDate, 'DD.MM.YYYY')}`
    },
    {
      title: 'Сумма',
      key: 'sum',
      dataIndex: 'sum',
      render: sum => (sum ? toLocalStringRu(sum) : null)
    },
    {
      title: 'Сумма графика',
      key: 'incomeSchedule.sum',
      dataIndex: 'incomeSchedule.sum',
      render: sum => (sum ? toLocalStringRu(sum) : null)
    },
    {
      title: 'Подрядчик',
      key: 'company',
      dataIndex: 'contract.contractor.company.name'
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: 'status',
      render: status => actStatus[status]
    }
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number, record: Act) =>
          ![actStatusEnum.workedOut, actStatusEnum.approved].includes(
            record.status
          ) && (
            <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={async () => this.deleteAct(id)}
            >
              <Icon type="x" />
            </Popconfirm>
          )
      });
    }
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchAct(page)
    );
  }

  canAdd = () =>
    this.props.userAccess.some(access => handlingExternalActs.includes(access));

  deleteAct = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await actApi.deleteAct(id);
      await this.fetchAct(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchAct = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await actApi.fetchActs({
      page,
      ...filter
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: ActFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetchAct(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetchAct(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр первичных документов</h1>}
          right={
            this.canAdd() && (
              <Button type="primary" onClick={() => navigate('/admin/act/new')}>
                Создать
              </Button>
            )
          }
        />
        <Tabs />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchAct}
            fetchOnMount
            onRow={(row: Act) => ({
              onClick: () => navigate(`/admin/act/${row.id}`)
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(ActList);
