// @flow
import React from 'react';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import {FormikProps} from 'formik';
import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';

import type {RegulationLimitGroup, RegulationLimitType} from '../../../../../../../lib/types';
import {Form} from './../../../../../../../components';
import type {FormFieldType} from '../../../../../../../components/Form';
import {SectionTitle} from './GroupModal.elements';
import Grid, {GridItem} from './../../../../../../../components/layout/Grid';
import {StyledInputNumber} from './elements';
import {regulationLimitTypeEnum} from '../../../../../../../lib/enum';
import YesNoSelect from './../../../../../../../components/selects/YesNoSelect';
import ExpenseDirectionTreeSelect from '../../../../../../../components/selects/ExpenseDirectionTreeSelect';

type FormProps = {
  group: $Shape<RegulationLimitGroup>,
  type: RegulationLimitType,
  onSubmit: (group: $Shape<RegulationLimitGroup>) => Promise<void>
};

const InnerForm = ({ group, type, onSubmit }: FormProps) => {
  return (
    <Form initialValues={group} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, setFieldValue, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <FormField label="Название группы" required name="name">
              {field => <Input {...field} />}
            </FormField>
            <GridItem>
              <FormField
                label="Наименование расхода"
                name="expenseDirectionId"
                required
                fast
              >
                {({ value, name }) => (
                  <div id="ExpenseDirectionTreeSelectArea">
                    <ExpenseDirectionTreeSelect
                      value={value}
                      onChange={(type: number) => setFieldValue(name, type)}
                      // Необходим, чтобы список всегда отображался в пределах окна
                      // т.е отвязываем от body, возможно луче привязать к modal
                      getPopupContainer={() =>
                        document.getElementById(
                          'ExpenseDirectionTreeSelectArea'
                        )
                      }
                    />
                  </div>
                )}
              </FormField>
            </GridItem>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  name="usedInWeekends"
                  label="Используется в выходные дни"
                >
                  {({ name, value }) => (
                    <YesNoSelect
                      name={name}
                      value={value}
                      onChange={(value: boolean) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              {values.usedInWeekends && (
                <GridItem>
                  <FormField name="weekendDays" label="Количество выходных дней в месяц">
                    {field => (
                      <InputNumber
                        {...field}
                        placeholder="Всегда"
                        onChange={value => setFieldValue(field.name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
            </Grid>
            {type !== regulationLimitTypeEnum.ovb && (
              <>
                <SectionTitle>
                  Максимальное суточное время использования (часы)
                </SectionTitle>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Будние дни" required name="hours">
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          max={24}
                          min={1}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Выходные дни"
                      required={values.usedInWeekends}
                      name="hoursWeekend"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          min={1}
                          max={24}
                          disabled={!values.usedInWeekends}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
                <SectionTitle>Суточный пробег (км)</SectionTitle>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Будние дни" required name="distance">
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          min={1}
                          value={value}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Выходные дни"
                      required={values.usedInWeekends}
                      name="distanceWeekend"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          disabled={!values.usedInWeekends}
                          min={1}
                          value={value}
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </>
            )}
            {type === regulationLimitTypeEnum.ovb && (
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Время использования (часы в день)"
                    required
                    name="hours"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        max={24}
                        min={1}
                        onChange={(value: number) => {
                          setFieldValue(name, value);
                          setFieldValue('hoursWeekend', value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Суточный пробег (км)"
                    required
                    name="distance"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        name={name}
                        min={1}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(name, value);
                          setFieldValue('distanceWeekend', value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            )}
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Коэффициент простоя"
                  required
                  name="downtimeRatio"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      value={value}
                      min={0}
                      step={0.1}
                      onChange={(value: number) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Средняя скорость"
                  required
                  name="averageSpeed"
                >
                  {({ name, value }) => (
                    <StyledInputNumber
                      name={name}
                      min={0}
                      value={value}
                      onChange={(value: number) => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

type Props = {
  // Видимость модалки
  visible: boolean,
  // Функция сохранения группы
  saveGroup: (group: RegulationLimitGroup) => Promise<void>,
  // Группа
  group: $Shape<RegulationLimitGroup>,
  // Функция закрытия/открытия модалки
  toggleModal: Function,
  // Функция вызывается при закрытии модалки
  afterClose?: Function
};

export default class GroupModal extends React.Component<Props> {
  render() {
    const { visible, group, saveGroup, toggleModal, afterClose } = this.props;
    return (
      <Modal
        destroyOnClose
        title={group.name || 'Новая группа'}
        visible={visible}
        footer={false}
        width={600}
        afterClose={afterClose}
        onCancel={toggleModal}
      >
        <InnerForm type={group.type} group={group} onSubmit={saveGroup} />
      </Modal>
    );
  }
}
