/* vehicleTariff.js | DTO | Типы и модели тарифов НТС */
// @flow
import type {Contractor, OrgUnitNode, RateType, VehicleModel, VehicleTariffType, VehicleTariffStatus} from './';
import type {FuelAndGazType, OperatingMode} from './index';

export type VehicleTariffOrganizationUnit = {
  id: number,
  vehicleTariffId: number, // id тарифа, к которому привязана эта запись
  organizationUnitId: number, //id филиала
  organizationUnit: OrgUnitNode
};

export const VehicleModelStatusEnum = {
  Ok: 'Ok',
  NoModel: 'NoModel',
  NoPlanTariff: 'NoPlanTariff'
};

export type VehicleTariffTypesAndModels = {
  vehicleModelId?: number, // Ссылка на модель
  vehicleModel?: VehicleModel, // Ссылка на модель
  productionYearStart: number, // Начальный год для периода выпуска ТС
  productionYearEnd: number, // Конечный год для периода выпуска ТС
  vehicleTypeTariff?: string, // Наименоване тарификатора
  operatingMode: OperatingMode, // Режим эксплуатации
  hasGas?: boolean, // Установлено ГБО
  leasing?: boolean, // Лизинг
  tariffFixed: number, // Тариф (руб.) за 1 час эксплуатации постоянная часть
  tariffFixedPlan: ?number, // Тариф (руб.) за 1 час эксплуатации постоянная часть ПЛАН (для фактического тарифа)
  tariffFixedDeviation: ?number, // Тариф (руб.) за 1 час эксплуатации постоянная часть ОТКЛОНЕНИЕ (для фактического тарифа)
  tariffFixedRealization: ?number, // Тариф (руб.) за 1 час эксплуатации постоянная часть ОСВОЕНИЕ (для фактического тарифа)
  tariffDynamic: number, // Тариф (руб.) за 1 км пробега переменная часть
  tariffDynamicPlan: ?number, // Тариф (руб.) за 1 км пробега переменная часть ПЛАН (для фактического тарифа)
  tariffDynamicDeviation: ?number, // Тариф (руб.) за 1 км пробега переменная часть ОТКЛОНЕНИЕ (для фактического тарифа)
  tariffDynamicRealization: ?number, // Тариф (руб.) за 1 км пробега переменная часть ОСВОЕНИЕ (для фактического тарифа)
  warmingNorm: number, // Норма списания на 1 час прогрева (л/час)
  fuelType: FuelAndGazType, // Тип топлива
  vehicleTypeISU?: string, // Тип ТС для ИСУ «Бюджетирование», формируется на беке
  vehicleTariffId: number, // Тариф на НТС
  id?: number,
  importBrandName: ?string, // Наименование марки и модели, указанные в импортированном файле
  importModelName: ?string, // Название производителя из файла импорта
  airConditionerNorm: ?number, // тарифы и нормы расхода топлива
  airConditionerTariff: ?number, // Кондиционер, Тариф за 1 час работы, руб.
  generatorNorm: ?number, // Силовой генератор, Норма расхода топлива на 1 час работы, л
  generatorTariff: ?number, // Силовой генератор, Тариф за 1 час работы, руб.
  heaterNorm: ?number, // Отопитель воздушный, Норма расхода топлива на 1 час работы, л
  heaterTariff: ?number, // Отопитель воздушный, Тариф за 1 час работы, руб.
  preHeaterNorm: ?number, // Подогреватель жидкостной, Норма расхода топлива на 1 час работы, л
  preHeaterTariff: ?number, // Подогреватель жидкостной, Тариф за 1 час работы, руб.
  trailerNorm: ?number, // Пробег ТС с прицепом, Норма расхода топлива на 1 км, л
  trailerTariff: ?number, // Пробег ТС с прицепом, Тариф за 1 км, руб.
  warmingTariff: ?number, // Простой с включенным ДВС, Тариф за 1 час работы, руб.
  winchNorm: ?number, // Лебедка, Норма расхода топлива на 1 час работы, л
  winchTariff: ?number, // Лебедка, Тариф за 1 час работы, руб.
  modelStatus: ?VehicleModelStatusEnum; // Статус. Определяется по vehicleModelId и tariffFixedPlan, используется на фронте
};

export type VehicleTariff = {
  id: number,
  vehicleTariffType: VehicleTariffType,
  // Подрядчик
  contractorId: number,
  contractor?: Contractor,
  // Начало действия тарифа
  startDate: string,
  // Окончание действия
  endDate?: string,
  // версия тарифа
  version: RateType,
  // статус тарифа
  status: VehicleTariffStatus,
  //Основание для тарифа
  tariffBases: string,
  // Тип ТС для ИСУ «Бюджетирование»
  vehicleTypeISU: string,
  ai98Price?: number,
  ai95Price?: number,
  ai92Price?: number,
  dieselPrice?: number,
  gasPrice?: number,
  typesAndModels: VehicleTariffTypesAndModels[],
  vehicleTariffOrganizationUnitLinks: VehicleTariffOrganizationUnit[], // массив филиалов, на которые действует тариф
  electricityPrice?: number,
  optionalEquipmentAi92Price?: number,
  optionalEquipmentAi95Price?: number,
  optionalEquipmentAi98Price?: number,
  optionalEquipmentDieselPrice?: number,
  optionalEquipmentElectricityPrice?: number,
  optionalEquipmentGasPrice?: number,
};
