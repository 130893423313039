// @flow
import React, {type Node} from 'react';
import notification from 'antd/lib/notification';
import Icon from 'antd/lib/icon';

type NotificationConfig = {
  className?: string,
  description?: string,
  message?: string,
  duration?: number,
  icon?: Node,
  key?: string,
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight',
  style?: any,
  onClose?: () => void | (() => Promise<void>),
  onClick?: () => void | (() => Promise<void>)
};

export const notificationLoading = (config: NotificationConfig) => {
  notification.open({
    duration: 0,
    ...config,
    icon: <Icon style={{color: '#286EF6'}} type="loading" />,
  });
};

export const notificationError = (error: any, config: NotificationConfig = {}) => {
  notification.error({
    message: 'Ошибка',
    description: error.title || error.message,
    ...config,
  });
};
