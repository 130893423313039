// @flow
import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { budgetSummaryContractApi } from '../../lib/api';
import type { CognosContract } from '../../lib/types';

import AutocompleteSelect from './AutocompleteSelect';

type CognosContractsProps = SelectProps & {
  cognosContracts?: CognosContract[]
};

export default (props: CognosContractsProps) => (
  <AutocompleteSelect
    placeholder="Выберите договор"
    fetch={(params: any) => {
      return {
        data: params?.search
          ? props.cognosContracts.filter((cognosContracts: CognosContract) =>
              cognosContracts.contractNumber.includes(params?.search)
            )
          : props.cognosContracts || budgetSummaryContractApi.fetch(params)
      };
    }}
    fetchSingle={(id: any) => {
      return id || budgetSummaryContractApi.get(id);
    }}
    notFoundText="Договоры не найдены"
    renderOption={(cognosContract: CognosContract, Option) => (
      <Option value={cognosContract.contractNumber} key={cognosContract.id}>
        {cognosContract.contractNumber}
      </Option>
    )}
    {...props}
  />
);
