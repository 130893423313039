// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import {type UploadProps} from 'antd/lib/upload';
import orderBy from 'lodash/orderBy';
import difference from 'lodash/difference';

import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Section} from './../../components/layout';
import {fileApi, vehicleIssueApi} from './../../lib/api';
import type {MaintenanceFileTag, VehicleIssue} from './../../lib/types';
import {Form} from './../../components';
import {getPathWithHistoryParams, navigate, validateFile} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';
import CancelButton from './../../components/CancelButton';
import Uploader from './components/Uploader';

const StyledSection = styled(Section)`
  padding: 16px 0;
`;
const SectionContent = styled.div`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  tag: MaintenanceFileTag,
  vehicleIssueId: string
};

type State = {
  vehicleIssue: VehicleIssue,
  previewVisible: boolean,
  previewFile: Object
};

class Files extends React.Component<Props, State> {
  state = {
    previewVisible: false,
    previewFile: {},
    vehicleIssue: {}
  };

  async componentDidMount() {
    const { vehicleIssueId } = this.props;
    const vehicleIssue = await vehicleIssueApi.getVehicleIssue(vehicleIssueId);
    this.setState({ vehicleIssue });
  }

  onSubmit = async (values: VehicleIssue) => {
    const { vehicleIssue } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      // $FlowFixMe flow тупит
      await vehicleIssueApi.updateVehicleIssue({ ...vehicleIssue, ...values });
      if (values.files.length < vehicleIssue.files.length) {
        this.handleCancel(values.files);
      }
      navigate(`/maintenances/${vehicleIssue.maintenanceId}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = (files: any) => {
    const { vehicleIssue } = this.state;
    difference(
      files.map(file => file.id),
      vehicleIssue.files.map(file => file.id)
    ).forEach(id => fileApi.deleteFile(id));
    navigate(`/maintenances/${vehicleIssue.maintenanceId}`);
  };

  handlePreview = (file: any) => {
    this.setState({
      previewFile: file,
      previewVisible: true
    });
  };

  beforeUpload = function(file: UploadProps) {
    const validFileType = validateFile(file.type, this.props.tag);
    if (!validFileType) {
      notification.error({
        message: 'Загружать можно только изображения и PDF!'
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({ message: 'Файл должен быть менее 10MB!' });
    }
    return validFileType && isLt2M;
  };

  handleClose = () => this.setState({ previewVisible: false, previewFile: {} });

  render() {
    const { tag } = this.props;
    const { vehicleIssue } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/maintenances')}>
                Ремонт
              </Crumb>
              <Crumb to={`/maintenances/${vehicleIssue.maintenanceId}`}>
                Ремонт №{vehicleIssue.maintenanceId}
              </Crumb>
              <Crumb>Добавление файла</Crumb>
            </Breadcrumbs>
          }
        />
        <Form initialValues={vehicleIssue || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, dirty, values } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <SectionContent>
                    <FormField label="Файлы" required name="files">
                      {({ value, name }) => (
                        <Uploader
                          value={orderBy(value, 'createdTime', ['desc'])}
                          multiple
                          tag={tag}
                          onChange={files => setFieldValue(name, files)}
                        />
                      )}
                    </FormField>
                  </SectionContent>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton
                    dirty={dirty}
                    onClick={() => this.handleCancel(values.files)}
                  >
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default Files;
