// @flow

import type {
  VehicleGroup,
  VehicleGroupLeaf,
  VehicleGroupNode,
  VehicleGroupParent,
  VehicleType,
  VehicleTypesPlainTree
} from './types';
import {
  vehicleGroupEnum,
  vehicleGroups,
  vehicleTypeEnum,
  vehicleTypes
} from './enum/vehicleTypes';
import { selfVehiclePlanVehicleTypeEnum } from './enum';

/**
 * Возвращает родительский узел (вид ТС) в виде родителя для TreeSelect
 * @param value Вид ТС
 * @param children Дети
 */
export const getVehicleGroupParent = (
  value: VehicleGroup,
  children: VehicleGroupLeaf[]
): VehicleGroupParent => ({
  title: vehicleGroups[value],
  value,
  key: value,
  children
});

/**
 * Возвращает типы для вида ТС в виде детей для TreeSelect
 * @param vehicleTypes[] Массив энумок типов ТС
 */
export const getVehicleGroupChildren = (
  types: VehicleType[]
): VehicleGroupLeaf[] => {
  return types
    .sort((a: VehicleType, b: VehicleType) =>
      // Проверяем, чтобы не было ошибок, cannot get localeCompare from undefined
      // В случае, если vehicleTypes[a] undefined, то отдаем -1, что равнозначно результату vehicleTypes[a].localeCompare(undefined)
      vehicleTypes[a] ? vehicleTypes[a].localeCompare(vehicleTypes[b]) : -1
    )
    .map(value => ({
      title: vehicleTypes[value],
      value,
      key: value
    }));
};

/**
 * Дерево типов ТС
 */
export const vehicleTypesTree: VehicleGroupParent[] = [
  getVehicleGroupParent(
    vehicleGroupEnum.specialBuses,
    getVehicleGroupChildren([
      vehicleTypeEnum.avtobusUral6x6,
      vehicleTypeEnum.avtobusFordTransit4x2,
      vehicleTypeEnum.avtobusFordTransit4x4,
      vehicleTypeEnum.avtobusPaz4x2,
      vehicleTypeEnum.avtobusPaz4x4,
      vehicleTypeEnum.avtobusKamAz4x4,
      vehicleTypeEnum.avtobusKamAz6x6,
      vehicleTypeEnum.avtobusGazel4x2,
      vehicleTypeEnum.avtobusGazel4x4,
      vehicleTypeEnum.avtobusNefAz4x4,
      vehicleTypeEnum.avtobusNefAz6x6,
      vehicleTypeEnum.avtobusUaz4x4,
      vehicleTypeEnum.avtobusFiatDucato4x2,
      vehicleTypeEnum.avtobusGaz4x4
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.passengerBuses,
    getVehicleGroupChildren([
      vehicleTypeEnum.avtobusMalyi1124Mest,
      vehicleTypeEnum.avtobusOsoboMalyi910Mest,
      vehicleTypeEnum.avtobusSrednyi2530Mest,
      vehicleTypeEnum.avtobusBolshoiSvyshe30Mest
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.carLifts,
    getVehicleGroupChildren([
      vehicleTypeEnum.agpVyletStrelyDo28M,
      vehicleTypeEnum.agpVyletStrelySvyshe28M,
      vehicleTypeEnum.agpRpnVyletStrelyDo28M,
      vehicleTypeEnum.agpRpnVyletStrelySvyshe28M
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.truckCranes,
    getVehicleGroupChildren([
      vehicleTypeEnum.avtokranGPDo24T,
      vehicleTypeEnum.avtokranGPOt25TDo49T,
      vehicleTypeEnum.avtokranGPSvyshe50T
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.autolabs,
    getVehicleGroupChildren([
      vehicleTypeEnum.etlNaShassyUaz,
      vehicleTypeEnum.etlNaShassyGaz4x4,
      vehicleTypeEnum.etlNaShassyGaz4x2,
      vehicleTypeEnum.etlNaShassyGazel4x4,
      vehicleTypeEnum.etlNaShassyGazel4x2,
      vehicleTypeEnum.etlNaShassyKamAz6x6,
      vehicleTypeEnum.etlNaShassyKamAz4x4,
      vehicleTypeEnum.etlNaShassyFordTransit4x4,
      vehicleTypeEnum.etlNaShassyFordTransit4x2
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.bkmBgm,
    getVehicleGroupChildren([
      vehicleTypeEnum.bgmNaTraktore,
      vehicleTypeEnum.bkmNaAvtomobyle,
      vehicleTypeEnum.bkmNaTraktore,
      vehicleTypeEnum.bkmRpnNaAvtomobyle,
      vehicleTypeEnum.bkmRpnNaTraktore
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.tractorsExcavators,
    getVehicleGroupChildren([
      vehicleTypeEnum.traktorTiagach,
      vehicleTypeEnum.traktorPum,
      vehicleTypeEnum.traktorBuldozer,
      vehicleTypeEnum.ekskavatorVKovshaDo05M3,
      vehicleTypeEnum.ekskavatorVKovshaSvyshe05M3
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.tippers,
    getVehicleGroupChildren([
      vehicleTypeEnum.samosvalKlassaN1Do3Tn,
      vehicleTypeEnum.samosvalKlassaN2Do7Tn,
      vehicleTypeEnum.samosvalKlassaN3Svyshe7Tn
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.flatbedTrucks,
    getVehicleGroupChildren([
      vehicleTypeEnum.gruzovoiBortovoiKlassaN1Do3Tn,
      vehicleTypeEnum.gruzovoiBortovoiKlassaN2Do7Tn,
      vehicleTypeEnum.gruzovoiBortovoiKlassaN3Svyshe7Tn,
      vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
      vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.cargoVans,
    getVehicleGroupChildren([
      vehicleTypeEnum.gruzovoiFurgonKlassaN1Do3Tn,
      vehicleTypeEnum.gruzovoiFurgonKlassaN2Do7Tn,
      vehicleTypeEnum.gruzovoiFurgonKlassaN3Svyshe7Tn
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.otherTrucks,
    getVehicleGroupChildren([
      vehicleTypeEnum.avtomobylTiagachBezKmu,
      vehicleTypeEnum.avtomobylTiagachSKmu,
      vehicleTypeEnum.evakuatorDo3Tn,
      vehicleTypeEnum.evakuatorDo7Tn,
      vehicleTypeEnum.evakuatorSvyshe7Tn,
      vehicleTypeEnum.avtomobylDliaPerevozkyOpasnyxGruzov,
      vehicleTypeEnum.avtomobylKommunalnyi
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.tankTrucks,
    getVehicleGroupChildren([
      vehicleTypeEnum.avtomobylMaslovozVDo5M3,
      vehicleTypeEnum.avtomobylMaslovozVSvyshe5M3,
      vehicleTypeEnum.avtomobylAssenyzatorVDo5M3,
      vehicleTypeEnum.avtomobylAssenyzatorVSvyshe5M3
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.electricVehicles,
    getVehicleGroupChildren([
      vehicleTypeEnum.electricCar,
      vehicleTypeEnum.electricCargo,
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.utilityVehicles,
    getVehicleGroupChildren([
      vehicleTypeEnum.uazFermerSGruzovoiPloshchadkoi,
      vehicleTypeEnum.uazFurgonTselnometallycheskyi,
      vehicleTypeEnum.gazelFermerSGruzovoiPloshchadkoi,
      vehicleTypeEnum.gazelFurgonTselnometallycheskyi,
      vehicleTypeEnum.gazFermerSGruzovoiPloshchadkoi,
      vehicleTypeEnum.gazFurgonTselnometallycheskyi,
      vehicleTypeEnum.kamAzFermerSGruzovoiPloshchadkoi,
      vehicleTypeEnum.kamAzFurgonTselnometallycheskyi,
      vehicleTypeEnum.fordTransitFurgonTselnometallycheskyi,
      vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.floatingTransport,
    getVehicleGroupChildren([
      vehicleTypeEnum.gydrotsykl,
      vehicleTypeEnum.lodkaSMotorom,
      vehicleTypeEnum.lodkaVesla
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.trailers,
    getVehicleGroupChildren([
      vehicleTypeEnum.prytsepLegkovoiGPDo750Kg,
      vehicleTypeEnum.prytsepAvtomobylnyi,
      vehicleTypeEnum.prytsepTraktornyi,
      vehicleTypeEnum.prytsepSKmu,
      vehicleTypeEnum.pPrytsepBezKmu,
      vehicleTypeEnum.pPrytsepSKmu,
      vehicleTypeEnum.prytsepRospusk,
      vehicleTypeEnum.prytsepDliaDgu,
      vehicleTypeEnum.prytsepKabelnyi,
      vehicleTypeEnum.prytsepYzmelchytel,
      vehicleTypeEnum.prytsepKompressor,
      vehicleTypeEnum.prytsepTsysterna,
      vehicleTypeEnum.prytsepGenerator
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.atvs,
    getVehicleGroupChildren([
      vehicleTypeEnum.bolotoxod,
      vehicleTypeEnum.kvadrotsykl,
      vehicleTypeEnum.snegoxod,
      vehicleTypeEnum.snegoBolotoxod,
    ])
  ),
  getVehicleGroupParent(
    vehicleGroupEnum.cars,
    getVehicleGroupChildren([
      vehicleTypeEnum.car,
      vehicleTypeEnum.legkovoiPolnopryvodnyi
    ])
  )
].sort((a: VehicleGroupNode, b: VehicleGroupNode) =>
  (vehicleTypes[a.value] || vehicleGroups[a.value]).localeCompare(
    vehicleTypes[b.value] || vehicleGroups[b.value]
  )
);

/**
 * "Плоское" представление дерева видов ТС
 * Имеет вид:
 * {
 *   "buses": [ vehicleTypesEnum.bus, ... ],
 *   ...
 * }
 */
export const vehicleTypesPlainTree: VehicleTypesPlainTree = vehicleTypesTree.reduce(
  (acc: VehicleTypesPlainTree, cur: VehicleGroupParent) => ({
    ...acc,
    [cur.value]: cur.children.map(node => node.value)
  }),
  {}
);

/**
 *   Сопоставление группы типа тс с показателем по которому предполагается техобслуживание
 *
 */
export const vehicleGroupsPlanMaintenanceType = {
  cars: selfVehiclePlanVehicleTypeEnum.kilometrage,
  specialBuses: selfVehiclePlanVehicleTypeEnum.kilometrage,
  passengerBuses: selfVehiclePlanVehicleTypeEnum.kilometrage,
  truckCranes: selfVehiclePlanVehicleTypeEnum.all,
  bkmBgm: selfVehiclePlanVehicleTypeEnum.workHours,
  carLifts: selfVehiclePlanVehicleTypeEnum.all,
  trailers: selfVehiclePlanVehicleTypeEnum.kilometrage,
  utilityVehicles: selfVehiclePlanVehicleTypeEnum.kilometrage,
  autolabs: selfVehiclePlanVehicleTypeEnum.kilometrage,
  tractorsExcavators: selfVehiclePlanVehicleTypeEnum.all,
  atvs: selfVehiclePlanVehicleTypeEnum.all,
  floatingTransport: selfVehiclePlanVehicleTypeEnum.workHours,
  tippers: selfVehiclePlanVehicleTypeEnum.kilometrage,
  flatbedTrucks: selfVehiclePlanVehicleTypeEnum.kilometrage,
  cargoVans: selfVehiclePlanVehicleTypeEnum.kilometrage,
  otherTrucks: selfVehiclePlanVehicleTypeEnum.kilometrage,
  tankTrucks: selfVehiclePlanVehicleTypeEnum.all
};
/**
 *   Особые типы тс с показателем по которому предполагается техобслуживание
 *
 */
export const vehicleTypesPlanMaintenanceTypeSpecial = {
  bkmNaAvtomobyle: selfVehiclePlanVehicleTypeEnum.all,
  bkmRpnNaAvtomobyle: selfVehiclePlanVehicleTypeEnum.all
};
