// @flow
import React, { useState, useCallback } from 'react';
import { currentMaintenanceCostApi } from '../../lib/api';
import type { UserAccess, CurrentMaintenanceCost } from '../../lib/types';
import { formatDateTimeToString, navigate } from '../../lib/helpers';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { withUserAccess } from './../withUserAccess';

import { addAccessRight } from './accessRight';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';

const { Field } = Card;

type InnerProps = {
  data: CurrentMaintenanceCost
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/current-maintenance-cost/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Модель ТС">{props.data.vehicleModelName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Трудоёмкость работ на 1000 км. пробега (н/ч)">
            {props.data.workEffort}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Норма затрат на материалы на 1000 км. пробега (руб.)">
            {props.data.materialExpenseRate}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Норма затрат на материалы на 1000 км. пробега с учётом коэффициента (руб.)">
            {props.data.materialExpenseRateWithCoefficient}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Трудоемкость на 1000 м/ч (н/ч)">
            {props.data.effort}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Доля затрат на материалы от первоначальной стоимости ТС (для самоходных машин и прицепов)">
            {props.data.materialsToTotalVehiclePriceRatio}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Коэффициент увеличения цены на материалы">
            {props.data.costMagnificationFactor}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата начала">
            {formatDateTimeToString(props.data.startDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата окончания">
            {formatDateTimeToString(props.data.endDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: CurrentMaintenanceCost, setData] = useState({
    id: props.id,
    name: '',
    isAvailableForSelect: false
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await currentMaintenanceCostApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await currentMaintenanceCostApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость текущего ремонта',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
