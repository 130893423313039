// @flow
import {Button, Tooltip} from 'antd';
import AntIcon from 'antd/lib/icon';
import Menu from 'antd/lib/menu';
import Popconfirm from 'antd/lib/popconfirm';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import styled from 'styled-components';

import {FormField} from '../../../../../components/Form';
import {StyledInputNumber} from '../../../../../components/hoc/common/components/elements';
import CommonForm, {
  type CommonFormProps,
} from '../../../../../components/hoc/common/handbook/CommonForm';
import {
  ButtonsRow,
  Dropdown,
  Icon,
  Table,
} from '../../../../../components/ui';

import {
  accessTypeEnum,
  branchBudgetSummaryCognosStatusEnum,
  contractStatusEnum,
  taxRates,
} from '../../../../../lib/enum';
import {plus, toLocalStringRu} from '../../../../../lib/helpers';
import type {
  BranchBudgetSummaryCognosStatus,
  BudgetsForAnalysis,
  CognosContract,
  CognosContractMonths, ContractsStatusType,
  UserAccess,
} from '../../../../../lib/types';
import {editDetailedBudgetAccessRight} from '../../../details/accessRight';
import {withUserAccess} from '../../../../withUserAccess';
import {BranchBudgetSummaryMonth} from '../../../../../lib/types';

export const MONTH = [
  {name: 'january', title: 'Январь'},
  {name: 'february', title: 'Февраль'},
  {name: 'march', title: 'Март'},
  {name: 'april', title: 'Апрель'},
  {name: 'may', title: 'Май'},
  {name: 'june', title: 'Июнь'},
  {name: 'july', title: 'Июль'},
  {name: 'august', title: 'Август'},
  {name: 'september', title: 'Сентябрь'},
  {name: 'october', title: 'Октябрь'},
  {name: 'november', title: 'Ноябрь'},
  {name: 'december', title: 'Декабрь'},
];

const StyledTable = styled(Table)`
  .ant-table-tbody > tr > td {
    padding: 6px 5px 6px 10px;
  }
`;

type ExpenseDirectionData = {
  branchBudgetCognosStatus: BranchBudgetSummaryCognosStatus,
  branchBudgetSummaryLineItemName: string,
  cognosContractMonths: {sum: number, month: BranchBudgetSummaryMonth}[],
  contractNumber: string,
  contractsStatus: ContractsStatusType,
  expenseDirectionName: string,
}

export default withUserAccess(
  (
    props: CommonFormProps<$Shape<BudgetsForAnalysis>> & {
      expenseDirectionData?: ExpenseDirectionData,
      onContractform: Function,
      onDelete: Function,
      onChangeStatus: Function,
      budgetSummaryType: 'summary' | 'details',
      addContract: Function,
      userAccess: UserAccess[],
    },
  ) => {
    const {
      onContractform,
      expenseDirectionData,
      onDelete,
      onChangeStatus,
      addContract,
      budgetSummaryType,
      ...otherProps
    } = props;
    
    return (
      <CommonForm {...otherProps}>
        {({setFieldValue, values, dirty}) => {
          let data = [];
          
          expenseDirectionData && data.push(expenseDirectionData);

          if (budgetSummaryType === 'details' && expenseDirectionData) {
            const redistributionByClosedPeriod = {
              contractNumber: 'Перераспределение по закрытому периоду',
              cognosContractMonths: expenseDirectionData.cognosContractMonths.map(item => ({
                sum: item.month.cognosClosePeriodEconomy,
              }))
            };
            data.splice(1, 0, redistributionByClosedPeriod);
          }
          
          const totalSum = values?.reduce(
            (summator, item) => {
              expenseDirectionData.totalSum = 0;
              const totalSum = item.cognosContractMonths.reduce(
                (summ, month: CognosContractMonths, index) => {
                  if (month?.isOpenPeriod) {
                    const sum = plus(
                      +summator.cognosContractMonths[index]?.sum || 0,
                      +item.cognosContractMonths[index]?.sum || 0,
                    );
                    summator.cognosContractMonths[index] = {
                      sum,
                      warn:
                        sum !==
                        expenseDirectionData?.cognosContractMonths[index]?.sum,
                    };
                    
                    month.coefWarn = false;
                    if (month?.sum > 0) {
                      month.coefWarn =
                        month?.cognosContractCoefMonths.reduce(
                          (summator, item) => {
                            summator = plus(summator, item.coef);
                            return summator;
                          },
                          0,
                        ) !== 1;
                    }
                    
                    expenseDirectionData.totalSum = plus(
                      expenseDirectionData.totalSum,
                      expenseDirectionData.cognosContractMonths[index].sum,
                    );
                    
                    expenseDirectionData.totalCoefWarn =
                      expenseDirectionData.totalCoefWarn ||
                      expenseDirectionData.cognosContractMonths[index].coefWarn;
                    return plus(+summ, month.sum || 0);
                  }
                  return summ;
                },
                0,
              );
              item.totalSum = totalSum;
              
              summator.totalSum = plus(summator.totalSum ?? 0, totalSum);
              summator.totalWarn = summator.totalSum !== expenseDirectionData.totalSum;
              return summator;
            },
            {
              contractNumber: 'Сумма по договорам',
              cognosContractMonths: [],
            },
          );
          
          data.push(totalSum);
          
          data = [...data, ...values];
          
          const canManipulateData = expenseDirectionData?.contractsStatus === contractStatusEnum.expensesEditing;
          const canSave = values?.length > 0 && canManipulateData && dirty;
          const canApprove = canManipulateData && !totalSum.totalWarn && !dirty;
          const canEdit = expenseDirectionData?.contractsStatus === contractStatusEnum.coefEditing;
          const hasCoefWarn = props.data.some(contract => contract.cognosContractMonths.some(month => month.coefWarn))
          const hasAccess = props.userAccess.some(access =>
            [accessTypeEnum.admin, accessTypeEnum.handlingBranchDetailedBudget].includes(access)
          )
          
          const canAddContract = (isEmpty(values) || canManipulateData) &&
            props.userAccess.some(access => editDetailedBudgetAccessRight.includes(access));
          
          const canAddContractDisabled = !!dirty && canManipulateData;
          const rowsOffset = budgetSummaryType === 'details' ? 3 : 2;
          return (
            <>
              <>
                <StyledTable
                  data={expenseDirectionData ? data : []}
                  columns={[
                    {
                      title: 'Номер договора',
                      dataIndex: 'contractNumber',
                      stopPropagation: true,
                      onCell: record => ({
                        onClick: () => canManipulateData && onContractform(record, true),
                      }),
                      render: (contractNumber, record: CognosContract) => {
                        if (record.id) {
                          return (
                            <>
                              {contractNumber}
                              {record.cognosContractMonths.some(month => month.coefWarn) && (
                                <Tooltip
                                  placement="top"
                                  title="Заполните все коэффициенты инкассации"
                                >
                                  <AntIcon
                                    type="exclamation-circle"
                                    theme="twoTone"
                                    twoToneColor="red"
                                    style={{
                                      marginLeft: '10px',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              <br />
                              {record.isuBudgetingData?.name && <p>{record.isuBudgetingData?.name}</p>}
                              {record.branchBudgetSummaryExpenseDirectionId && <p>Ставка НДС: {taxRates[record.taxRate]}</p>}
                            </>
                          );
                        } else {
                          return contractNumber;
                        }
                      },
                    },
                    {
                      title: '',
                      dataIndex: 'id',
                      key: 'options',
                      stopPropagation: true,
                      render: (id, record) => {
                        if (!hasAccess) {
                          return null;
                        }
                        return id
                          ? (canEdit && (
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    onClick={() => onContractform(record)}
                                  >
                                    Коэффициенты инкассации
                                  </Menu.Item>
                                </Menu>
                              }
                            >
                              <AntIcon
                                style={{fontSize: 16, color: '#2770FF'}}
                                type="ellipsis"
                              />
                            </Dropdown>
                          ))
                          : record.cognosContractMonths.some(month => month?.warn)
                            ? (
                              <AntIcon
                                type="exclamation-circle"
                                theme="twoTone"
                                twoToneColor="red"
                              />
                            )
                            : null;
                      },
                    },
                    ...MONTH.map((month, index) => {
                      return {
                        title: month.title,
                        dataIndex: `cognosContractMonths[${index}].sum`,
                        render: (sum, row, rowIndex) => {
                          return row.id && index !== 12 && canManipulateData && hasAccess
                            ? (
                              <FormField name={`[${rowIndex - rowsOffset}].cognosContractMonths[${index}].sum`} key={index}>
                                {({name, value}) => {
                                  return (
                                    <StyledInputNumber
                                      size="small"
                                      value={value}
                                      onChange={value => setFieldValue(name, value)}
                                      disabled={!row.cognosContractMonths[index]?.isOpenPeriod}
                                      min={0}
                                      decimalSeparator={','}
                                      precision={2}
                                    />
                                  );
                                }}
                              </FormField>
                            )
                            : (
                              <>
                                {toLocalStringRu(sum, {minimumFractionDigits: 2})}
                                {!!row?.cognosContractMonths[index]?.warn && (
                                  <AntIcon
                                    style={{paddingLeft: '10px'}}
                                    type="exclamation-circle"
                                    theme="twoTone"
                                    twoToneColor="red"
                                  />
                                )}
                              </>
                            );
                        },
                      };
                    }),
                    {
                      title: 'Итог (открытый период)',
                      dataIndex: 'totalSum',
                      render: (totalSum, row) => {
                        return (
                          <>
                            {toLocalStringRu(totalSum, {
                              minimumFractionDigits: 2,
                            })}
                            {!!row?.totalWarn && (
                              <AntIcon
                                style={{paddingLeft: '10px'}}
                                type="exclamation-circle"
                                theme="twoTone"
                                twoToneColor="red"
                              />
                            )}
                          </>
                        );
                      },
                    },
                    {
                      dataIndex: 'id',
                      key: 'delete',
                      render: (id: number) => id && canManipulateData && hasAccess
                        ? (
                          <Popconfirm
                            title="Вы уверены, что хотите удалить запись?"
                            onConfirm={async () => onDelete(id)}
                          >
                            <Icon type="x" />
                          </Popconfirm>
                        )
                        : null
                    },
                  ]}
                  rowKey="id"
                />
                {hasAccess && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      paddingTop: '16px',
                    }}
                  >
                    <ButtonsRow>
                      {canAddContract && (
                        <Tooltip
                          title="Сохраните изменения перед добавлением нового договора"
                          trigger={!canAddContractDisabled ? 'focus' : 'hover'}
                          placement="left"
                        >
                          <Button
                            onClick={() => {
                              addContract();
                            }}
                            disabled={canAddContractDisabled}
                          >
                            Добавить новый договор
                          </Button>
                        </Tooltip>
                      )}
                    </ButtonsRow>
                    <ButtonsRow>
                      {(canEdit || expenseDirectionData?.contractsStatus === contractStatusEnum.approved) &&
                        (expenseDirectionData?.branchBudgetCognosStatus !== branchBudgetSummaryCognosStatusEnum.sended &&
                          expenseDirectionData?.branchBudgetCognosStatus !== branchBudgetSummaryCognosStatusEnum.approved) && (
                          <Button onClick={() => onChangeStatus(contractStatusEnum.expensesEditing)}>
                            Редактировать
                          </Button>
        
                        )}
                      {canEdit && (
                        <Tooltip
                          title="Необходимо устранить ошибки в коэффициентах инкасации"
                          trigger={hasCoefWarn ? 'hover' : 'contextMenu'}
                          placement="left"
                        >
                          <Button
                            disabled={hasCoefWarn}
                            type="primary"
                            onClick={() => onChangeStatus(contractStatusEnum.approved)}
                          >
                            Утвердить коэффициенты
                          </Button>
                        </Tooltip>
                      )}
                      {canManipulateData && (
                        <>
                          <Button
                            type="primary"
                            disabled={!canSave}
                            htmlType="submit"
                          >
                            Сохранить
                          </Button>
                          <Tooltip
                            title="Сумма по договорам и сумма по потребностям должны сходиться"
                            trigger={canApprove ? 'contextMenu' : 'hover'}
                            placement="left"
                          >
                            <Button
                              disabled={!canApprove}
                              onClick={() => onChangeStatus(contractStatusEnum.coefEditing)}
                            >
                              Утвердить затраты
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </ButtonsRow>
                  </div>
                )}
              </>
            </>
          );
        }}
      </CommonForm>
    );
  },
);
