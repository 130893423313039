// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import { Link } from '@reach/router';

import type {
  UserAccess,
} from './../../lib/types';
import { accessTypeEnum } from './../../lib/enum';
import { TabItem, Tabs } from './../../components/ui';
import Header from '../../components/layout/Header';
import { withUserAccess } from './../withUserAccess';
import { Router } from '@reach/router';
import ElectricVehiclesDrivers from './ElectricVehiclesDrivers/ElectricVehiclesDrivers';
import NonElectricVehiclesDrivers from './NonElectricVehiclesDrivers/NonElectricVehiclesDrivers';
import { DriverType } from '../../lib/enum/driverType';

export const canAddDriverAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingDriver
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

const getCurrentRoute = () => {
  const currentRoute = window.location.pathname;
  const currentTab = currentRoute.split("/").slice(-1);
  return currentTab[0];
}

class DriverList extends Component<Props, State> {
  canAdd = () =>
    this.props.userAccess.some(access => canAddDriverAccess.includes(access));

  render() {
    const currentDriverType = getCurrentRoute() === 'electricVehiclesDrivers' ? DriverType.EVUser : DriverType.driver;

    return (
      <>
        <Header
          left={<h1>Водители</h1>}
          right={
            this.canAdd() && (
              <Link to={`/admin/drivers/form/${currentDriverType}/new`}>
                <Button type="primary" data-cy="addDriver">
                  Создать
                </Button>
              </Link>
            )
          }
        />
        <Tabs withRouter>
          <TabItem
            label='Водители'
            url="/admin/drivers/nonElectricVehiclesDrivers"
          />
          <TabItem
            label='Пользователи электрокаров'
            url="/admin/drivers/electricVehiclesDrivers"
          />
        </Tabs>
        <Router>
          <NonElectricVehiclesDrivers userAccess={this.props.userAccess} path="nonElectricVehiclesDrivers" />
          <ElectricVehiclesDrivers userAccess={this.props.userAccess} path="electricVehiclesDrivers" />
        </Router>
      </>
    );
  }
}

export default withUserAccess(DriverList);
