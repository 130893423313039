// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import {actApi} from '../../../lib/api';
import {getPathWithHistoryParams, navigate, setQueryParams} from '../../../lib/helpers';
import {actStatusEnum, expenseDirectionTypeEnum} from '../../../lib/enum';
import type {Act, ActStatusType, UserAccess} from '../../../lib/types';

import {Header, Panel} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Icon} from '../../../components/ui';

import {ActInfo, List} from './components';

import {withUserAccess} from '../../withUserAccess';
import {handlingExternalActs} from '../accessRight';

type Props = {
  actId: number,
  userAccess: UserAccess[]
};
type State = {
  act: ?Act
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export class ActCard extends React.Component<Props, State> {
  state = {
    act: null
  };

  componentDidMount = async () => {
    const { actId } = this.props;
    try {
      if (actId) {
        const act = await actApi.getAct(actId);
        act.incomeSchedule &&
          setQueryParams({
            startDate: act.incomeSchedule.startDate,
            endDate: act.incomeSchedule.endDate
          });
        this.setState({ act });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  changeStatusAct = async (status: ActStatusType, totalSumApi: ?Function) => {
    const { actId } = this.props;
    try {
      let updated = await actApi.changeStatus(actId, status);
      if (totalSumApi) {
        let sum;
        if (
          [
            expenseDirectionTypeEnum.gtnTax,
            expenseDirectionTypeEnum.passTax
          ].includes(this.state.act?.expenseDirectionType)
        ) {
          sum = await totalSumApi({ taxActId: updated.id });
        } else {
          sum = await totalSumApi({ actId: updated.id });
        }

        updated = await actApi.updateAct({
          ...updated,
          sum
        });
      }
      this.setState({ act: updated });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  canAdd = () =>
    this.props.userAccess.some(access => handlingExternalActs.includes(access));

  render() {
    const { actId } = this.props;
    const { act } = this.state;
    if (act === null) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/act/')}>
                Реестр первичных документов
              </Crumb>
              <Crumb>Акт №{act && act.id}</Crumb>
            </Breadcrumbs>
          }
        />
        {act && (
          <>
            <StyledPanel>
              <h1 style={{ marginBottom: '10px' }}>
                Акт №{act.actNumber}
                {![actStatusEnum.workedOut, actStatusEnum.approved].includes(
                  act.status
                ) &&
                  this.canAdd() && (
                    <StyledIcon
                      onClick={() => navigate(`/admin/act/${actId}/edit`)}
                      type="edit"
                      size={16}
                    />
                  )}
              </h1>
            </StyledPanel>

            <ActInfo act={act} />

            <List
              changeStatusAct={this.changeStatusAct}
              services={act.expenseDirectionType}
              actId={actId}
              act={act}
            />
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(ActCard);
