// @flow
import { convertFromHourToSecond, convertFromKmToM, convertFromKopToRub, convertFromMToKm, convertFromRubToKop, convertFromSecondToHour } from '../helpers';
import { ContractTrip, ContractTripExpenseDirection, ContractTripMonth, ContractTripVehicleLayout } from '../types/contractTrips';
import { downloadRequestWithToken, fetchRequest } from './';
import type { ListResponse } from './index';

export const convertArrayOfTripsFromServer = (trips: ContractTrip[]) => {
  return trips.map((trip) => convertDataFromServer(trip));
};

export const convertDataFromServer = (trip: ContractTrip) => ({
  ...trip,
  distance: convertFromMToKm(trip.distance),
  distancePrice: convertFromKopToRub(trip.distancePrice),
  distancePriceBackup: convertFromKopToRub(trip.distancePriceBackup),
  distanceWithTrailer: convertFromMToKm(trip.distanceWithTrailer),
  distanceWithTrailerPrice: convertFromKopToRub(trip.distanceWithTrailerPrice),
  distanceWithTrailerPriceBackup: convertFromKopToRub(trip.distanceWithTrailerPriceBackup),
  heaterPrice: convertFromKopToRub(trip.heaterPrice),
  heaterPriceBackup: convertFromKopToRub(trip.heaterPriceBackup),
  heaterTime: convertFromSecondToHour(trip.heaterTime),
  movementPrice: convertFromKopToRub(trip.movementPrice),
  movementPriceBackup: convertFromKopToRub(trip.movementPriceBackup),
  movementTime: convertFromSecondToHour(trip.movementTime, 3),
  preheaterPrice: convertFromKopToRub(trip.preheaterPrice),
  preheaterPriceBackup: convertFromKopToRub(trip.preheaterPriceBackup),
  preheaterTime: convertFromSecondToHour(trip.preheaterTime),
  powerGeneratorPrice: convertFromKopToRub(trip.powerGeneratorPrice),
  powerGeneratorPriceBackup: convertFromKopToRub(trip.powerGeneratorPriceBackup),
  powerGeneratorTime: convertFromSecondToHour(trip.powerGeneratorTime),
  winchPrice: convertFromKopToRub(trip.winchPrice),
  winchPriceBackup: convertFromKopToRub(trip.winchPriceBackup),
  winchTime: convertFromSecondToHour(trip.winchTime),
  sum: convertFromKopToRub(trip.sum),
  totalWorkTime: convertFromSecondToHour(trip.totalWorkTime),
  waitingPrice: convertFromKopToRub(trip.waitingPrice),
  waitingPriceBackup: convertFromKopToRub(trip.waitingPriceBackup),
  waitingTime: convertFromSecondToHour(trip.waitingTime, 3),
  waitingWithRunningEnginePrice: convertFromKopToRub(trip.waitingWithRunningEnginePrice),
  waitingWithRunningEnginePriceBackup: convertFromKopToRub(trip.waitingWithRunningEnginePriceBackup),
  waitingWithRunningEngineTime: convertFromSecondToHour(trip.waitingWithRunningEngineTime),
});

export const convertArrayOfTripsToServer = (trips: ContractTrip[]) => {
  return trips.map((trip) => convertDataToServer(trip));
};

const convertDataToServer = (trip: ContractTrip) => ({
  ...trip,
  distance: convertFromKmToM(trip.distance),
  distancePrice: convertFromRubToKop(trip.distancePrice),
  distanceWithTrailer: convertFromKmToM(trip.distanceWithTrailer),
  distanceWithTrailerPrice: convertFromRubToKop(trip.distanceWithTrailerPrice),
  heaterPrice: convertFromRubToKop(trip.heaterPrice),
  heaterTime: convertFromHourToSecond(trip.heaterTime),
  movementPrice: convertFromRubToKop(trip.movementPrice),
  movementTime: convertFromHourToSecond(trip.movementTime),
  preheaterPrice: convertFromRubToKop(trip.preheaterPrice),
  preheaterTime: convertFromHourToSecond(trip.preheaterTime),
  powerGeneratorPrice: convertFromRubToKop(trip.powerGeneratorPrice),
  powerGeneratorTime: convertFromHourToSecond(trip.powerGeneratorTime),
  winchPrice: convertFromRubToKop(trip.winchPrice),
  winchTime: convertFromHourToSecond(trip.winchTime),
  sum: convertFromRubToKop(trip.sum),
  totalWorkTime: convertFromHourToSecond(trip.totalWorkTime),
  waitingPrice: convertFromRubToKop(trip.waitingPrice),
  waitingTime: convertFromHourToSecond(trip.waitingTime),
  waitingWithRunningEnginePrice: convertFromRubToKop(trip.waitingWithRunningEnginePrice),
  waitingWithRunningEngineTime: convertFromHourToSecond(trip.waitingWithRunningEngineTime),
});

const convertLayoutDataFromServer = (layout: ContractTripVehicleLayout) => ({
  ...layout,
  distanceNormative: convertFromMToKm(layout.distanceNormative),
  fuelPrice: convertFromKopToRub(layout.fuelPrice),
  hoursNormative: convertFromSecondToHour(layout.hoursNormative),
  waitingWithRunningEnginePrice: convertFromKopToRub(
    layout.waitingWithRunningEnginePrice,
  ),
});

const convertLayoutDataToServer = (layout: ContractTripVehicleLayout) => ({
  ...layout,
  distanceNormative: convertFromKmToM(layout.distanceNormative),
  fuelPrice: convertFromRubToKop(layout.fuelPrice),
  hoursNormative: convertFromHourToSecond(layout.hoursNormative),
  waitingWithRunningEnginePrice: convertFromRubToKop(
    layout.waitingWithRunningEnginePrice,
  ),
});

export const fetchTripMonth = async (params): Promise<ListResponse<ContractTripMonth>> => {
  return await fetchRequest.get('/contractTripMonth', {...params});
};

export const fetchTripMonthById = async (monthId,): Promise<ContractTripMonth> => {
  const tripMonth = await fetchRequest.get(`/contractTripMonth/${monthId}`);
  return {
    ...tripMonth,
    contractTrips: tripMonth.contractTrips.map(convertDataFromServer),
  };
};

export const updateTariffsInTripMonth = async (monthId,): Promise<ContractTripMonth> => {
  const tripMonth = await fetchRequest.get(`/contractTripMonth/${monthId}/updateTariffs`);
  return {
    ...tripMonth,
    contractTrips: tripMonth.contractTrips.map(convertDataFromServer),
  };
};

export const fetchTrips = async (params = {}): Promise<ContractTripMonth> => {
  const tripList = await fetchRequest.get(`/contractTrip`, params);
  return {
    ...tripList,
    data: tripList.data.map(convertDataFromServer),
  };
};

export const getFilterOptions = async id => {
  return await fetchRequest.get(`/ContractTripMonth/${id}/getFilterOptions`);
};

const deleteMonth = async id => await fetchRequest.delete(`/contractTripMonth/${id}`);

// Обновить ПЛ НТС.
const updateTrip = async (tripDto: ContractTrip) => await fetchRequest.put(`/contractTrip`, convertDataToServer(tripDto));
// Декомпозировать ПЛ НТС.
const splitTrip = async (id: number, splitTripsDto: ContractTrip[]) => {
  return await fetchRequest.put(`/contractTrip/split/${id}`, convertArrayOfTripsToServer(splitTripsDto));
};
// Объединить ПЛ НТС.
const mergeTrip = async (id: number, splitTripsDto: ContractTrip[]) => {
  return await fetchRequest.put(`/contractTrip/merge/${id}`, convertArrayOfTripsToServer(splitTripsDto));
};
// Получить декомпозированные ПЛ НТС.
const getSplitTrips = async (id: number) => {
  const tripList = await fetchRequest.get(`/contractTrip/splitTrips/${id}`);
  return convertArrayOfTripsFromServer(tripList.data);
};
// Получить ПЛ НТС.
const getTrip = async (id: number) => {
  const trip = await fetchRequest.get(`/contractTrip/trip/${id}`);
  return convertDataFromServer(trip);
};
// Получить тарифы ТС для ПЛ НТС.
const getTariff = async (tripId: number, contractVehicleId: number) => {
  const tariff = await fetchRequest.get(`/contractTrip/tariff/${tripId}/${contractVehicleId}`);
  return convertDataFromServer(tariff);
};
// Обновить поле "Направление расходов" всех ПЛ НТС по ТС (атрибут поиска "Модель" + "Гос. номер").
const updateExpenseDirectionByVehicle = async (request: ContractTripExpenseDirection) => 
{
  return await fetchRequest.put('/contractTrip/updateExpenseDirectionByVehicle', request, {headers: {
    'Content-Type': 'application/json',
    // eslint-disable-next-line no-console
  },}).catch((err) => console.log(err));
}
// Получить закрепление подрядчиков.
const getContractorBranchLink = async (branchId: number) => {
  const response = await fetchRequest.get(`/contractorbranchlink?branchId=${branchId}`);
  return response.data[0];
};

const checkErrors = id => fetchRequest.get(`/contracttripmonth/${id}/checkingError`);

const changeStatus = (id, status) => fetchRequest.put(`/contracttripmonth/${id}/changeStatus?status=${status}`);

const getExpenseDirections = id => fetchRequest.get(`/ContractTripVehicleLayot/getExpenseDirectionsSumByContractTripMonth/${id}`);

const getTripVehicleLayout = async params => {
  const result = await fetchRequest.get('/ContractTripVehicleLayot', params);
  if (result?.data?.length) {
    result.data = result.data.map(convertLayoutDataFromServer);
  }
  return result;
};

const updateTripVehicleLayout = layout => fetchRequest.put('/ContractTripVehicleLayot', convertLayoutDataToServer(layout));

const getReport = async params => {
  const result = await fetchRequest.get('/report/contracttrips', params);
  if (result?.data?.length) {
    result.data = result.data.map(convertDataFromServer);
  }
  return result;
}

const printReport = async params => {
  const isPrinted = await downloadRequestWithToken('/report/contracttrips/print', {...params});
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

const sendToCognos = id => fetchRequest.put(`/ContractTripVehicleLayot/exportToCognosByContractTripMonth/${id}`);

const getSum = async params => {
  const result = await fetchRequest.get('/contractTrip/getSum', params);
  if (result) {
    return convertDataFromServer(result)
  }
  return;
}

export default {
  fetchTripMonth,
  fetchTripMonthById,
  fetchTrips,
  deleteMonth,
  getFilterOptions,
  updateTrip,
  checkErrors,
  changeStatus,
  getExpenseDirections,
  getTripVehicleLayout,
  updateTripVehicleLayout,
  getReport,
  printReport,
  sendToCognos,
  getSum,
  splitTrip,
  mergeTrip,
  getSplitTrips,
  getTrip,
  getTariff,
  getContractorBranchLink,
  updateTariffsInTripMonth,
  updateExpenseDirectionByVehicle,
};
