// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import notification from 'antd/lib/notification';
import moment from 'moment';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import Filter from './components/Filter';
import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportVehicleOutputRatio,
  ReportVehicleOutputRatioParams
} from '../../../lib/types/reportVehicleOutputRatio';
import { notificationLoading } from '../../../components/Notifications';
import { reportVehicleOutputRatioApi } from '../../../lib/api';
import type { VehicleType } from '../../../lib/types';
import { vehicleTypes } from '../../../lib/enum';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialParams: ReportVehicleOutputRatioParams = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  params: ReportVehicleOutputRatioParams,
  data: ReportVehicleOutputRatio[],
  loading: boolean
};

/**
 * Отчет об учете заявок ГПМ
 */
export class VehicleOutputRatioList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      }
    }));
  };

  async componentDidMount() {
    await this.fetchReportVehicleOutputRatios();
  }

  fetchReportVehicleOutputRatios = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportVehicleOutputRatioApi.fetchReportVehicleOutputRatio(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyParams = this.fetchReportVehicleOutputRatios;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print'
      });
      await reportVehicleOutputRatioApi.printReportVehicleOutputRatio(
        this.state.params
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName'
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      render: (type: VehicleType) => vehicleTypes[type]
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (value: number) => `${value} км.`
    },
    {
      title: 'Коэффициент выхода',
      dataIndex: 'outputRatio'
    },
    {
      title: 'Количество путевых листов',
      dataIndex: 'tripCount',
      render: (value: number) => `${value} шт.`
    }
  ];

  render() {
    const { loading, data } = this.state;
    return (
      <>
        <Header left={<h1>Отчет о коэффициентах выхода ТС</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default VehicleOutputRatioList;
