// @flow
import React from 'react';
import {connect} from 'react-redux';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import type {AppState} from '../../ducks/redux';
import {addMdmNode, cleanMdmNode, fetchMdmNode, updateMdmNode} from '../../ducks/mdmNode';
import {Header, Panel} from '../../components/layout';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import type {MdmNode} from '../../lib/types/mdm';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  fetchMdmNode: (guid: string) => Promise<void>,
  cleanMdmNode: Function,
  addMdmNode: (node: MdmNode) => Promise<void>,
  updateMdmNode: (node: MdmNode) => Promise<void>,
  mdmNode: MdmNode,
  mdmGuid: string
};

export class MdmForm extends React.Component<Props> {
  async componentDidMount() {
    const { mdmGuid } = this.props;
    await this.props.cleanMdmNode();
    try {
      if (mdmGuid) {
        await this.props.fetchMdmNode(mdmGuid);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: MdmNode) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.mdmGuid) {
        await this.props.updateMdmNode(values);
        notification.success({
          message: 'Успешное обновление',
          description: 'Запись успешно обновлена'
        });
      } else {
        await this.props.addMdmNode(values);
        notification.success({
          message: 'Успешное создание',
          description: 'Запись успешно создана'
        });
      }
      this.handleBack();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleBack = () => navigate('/admin/mdm', true);

  render() {
    const { mdmGuid, mdmNode } = this.props;
    if (!mdmNode) return null;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/mdm')}>МТРиО</Crumb>
              {mdmGuid ? (
                <Crumb>{mdmNode.payload.name}</Crumb>
              ) : (
                <Crumb>Новая запись МТРиО</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{mdmNode.name}</h1>
        </StyledPanel>
        <InnerForm
          mdmNode={mdmNode}
          onCancel={this.handleBack}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    mdmNode: state.mdmNode
  }),
  {
    fetchMdmNode,
    cleanMdmNode,
    updateMdmNode,
    addMdmNode
  }
)(MdmForm);
