// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import type {Assignment, ContractVehicle, ContractVehiclePlan} from '../../../../../../lib/types';
import {
  assignmentApi,
  contractVehicleApi,
  contractVehicleBudgetApi,
  contractVehiclePlanApi,
  fetchRequest,
} from '../../../../../../lib/api';
import {formatDate, formatDateRangeString, getPathWithHistoryParams} from '../../../../../../lib/helpers';
import {Grid, Header, Section, SectionTitle, TopPanel} from './../../../../../../components/layout';
import {GridItem} from '../../../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';
import {contractVehicleTypeEnum, entityStatusEnum, vehicleStatusEnum} from '../../../../../../lib/enum';
import {printNotification} from '../../../../../../lib/notificationWrapper';
import Tabs from './../components/Tabs';
import InnerTable from './components/InnerTable';
import {Link} from '@reach/router';
import {CognosType} from '../../../ContractVehicleBudget';
import VehicleSection from './components/VehicleSection';
import {notificationLoading} from '../../../../../../components/Notifications';
import ModalContractVehicle from './components/ModalContractVehicle';
import ModalPlannedContractVehicle from './components/ModalPlannedContractVehicle';

type Props = {
  contractVehiclePlanId: number,
  cognosType: $Keys<CognosType>
};
type State = {
  contractVehiclePlan: ?ContractVehiclePlan,
  assignment: ?Assignment,
  contractVehiclesEditMode: boolean,
  plannedContractVehiclesEditMode: boolean,
  fetchedVehicles: ContractVehicle[],
  selectedVehicles: ContractVehicle[],
  isLoading: boolean,
  budgetIsCreated: boolean,
  contractVehicleModal: boolean,
  plannedVehicleModal: boolean
};

const Content = styled.div`
  padding: 16px;
`;

const StyledButton = styled(Button)`
  margin: 10px 10px 0 0;
`;

export default class extends Component<Props, State> {
  state = {
    contractVehiclePlan: null,
    assignment: null,
    contractVehiclesEditMode: false,
    plannedContractVehiclesEditMode: false,
    fetchedVehicles: [],
    selectedVehicles: [],
    isLoading: false,
    budgetIsCreated: false,
    contractVehicleModal: false,
    plannedVehicleModal: false
  };

  componentDidMount() {
    this.fetch();
  }

  getAssignment = async (
    contractVehiclePlanId: number
  ): Promise<Assignment> => {
    return await assignmentApi.getByContractVehiclePlan(contractVehiclePlanId);
  };

  fetchVehicles = async (orgUnitId, type, filter = {}) => {
    const CONTRACT_VEHICLE_TYPE = [
      contractVehicleTypeEnum.fixed,
      contractVehicleTypeEnum.oneOff
    ];
    const PLANNED_CONTRACT_VEHICLE_TYPE = [
      contractVehicleTypeEnum.oneOffPlan,
      contractVehicleTypeEnum.fixedPlan
    ];
    this.setState({ isLoading: true });
    const { data } = await contractVehicleApi.fetchContractVehicles({
      'vehicle.nodeId': orgUnitId,
      'vehicle.status': [
        vehicleStatusEnum.draft,
        vehicleStatusEnum.onAgreeing,
        vehicleStatusEnum.working,
        vehicleStatusEnum.onAgreeing
      ],
      ...filter,
      type:
        type === 'contractVehicles'
          ? CONTRACT_VEHICLE_TYPE
          : PLANNED_CONTRACT_VEHICLE_TYPE,
      page: undefined,
      pageSize: undefined
    });
    const currentVehicles =
      type === 'contractVehicles'
        ? this.state.contractVehiclePlan.contractVehicles
        : this.state.contractVehiclePlan.plannedContractVehicles;
    if (data.length !== currentVehicles.length) {
      const currentVehiclesIds = currentVehicles.reduce((arr, item) => {
        arr.push(item.id);
        return arr;
      }, []);
      const fetchedVehiclesIds = data.reduce((arr, item) => {
        arr.push(item.id);
        return arr;
      }, []);
      const excludesIds = currentVehiclesIds.filter(
        id => !fetchedVehiclesIds.includes(id)
      );
      await Promise.all(
        excludesIds.map(id =>
          contractVehicleApi
            .fetchContractVehicle(id)
            .then(vehicleData => data.push(vehicleData))
        )
      );
    }
    this.setState({
      fetchedVehicles: data,
      isLoading: false
    });
  };

  fetch = async () => {
    try {
      const { contractVehiclePlanId } = this.props;
      const contractVehiclePlan = await contractVehiclePlanApi.get(
        contractVehiclePlanId
      );
      const assignment = await this.getAssignment(contractVehiclePlanId);
      let budgetIsCreated = false;
      if (assignment?.status === entityStatusEnum.approved) {
        const contractVehicleBudget = await contractVehicleBudgetApi.getByContractVehiclePlan(
          contractVehiclePlanId
        );
        if (contractVehicleBudget?.status === entityStatusEnum.created) {
          budgetIsCreated = true;
        }
      }
      this.setState({ contractVehiclePlan, assignment, budgetIsCreated });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  handlePrint = () => {
    printNotification(
      async () =>
        await contractVehiclePlanApi.print(this.props.contractVehiclePlanId)
    );
  };

  saveVehicles = async type => {
    if (!this.state.selectedVehicles.length) {
      this.handleCancel();
      return;
    }
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      const data = { ...this.state.contractVehiclePlan };
      if (type === 'contractVehicles') {
        data.contractVehicles = this.state.selectedVehicles;
      } else {
        data.plannedContractVehicles = this.state.selectedVehicles;
      }
      const contractVehiclePlan = await fetchRequest.put(
        '/ContractVehiclePlan/rebuild',
        data
      );
      this.setState({
        contractVehiclePlan,
        assignment: {
          ...this.state.assignment,
          status: entityStatusEnum.approvement
        }
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка сохранения данных',
        description: e.message
      });
    } finally {
      notification.close('saving');
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.setState({
      fetchedVehicles: [],
      selectedVehicles: [],
      contractVehiclesEditMode: false,
      plannedContractVehiclesEditMode: false
    });
  };

  hideModals = () => {
    this.setState({
      contractVehicleModal: false,
      plannedVehicleModal: false
    });
  };

  addVehicle = vehicleData => {
    this.setState({
      fetchedVehicles: [...this.state.fetchedVehicles, vehicleData],
      contractVehicleModal: false,
      plannedVehicleModal: false
    });
  };

  render() {
    const { contractVehiclePlanId, cognosType } = this.props;
    const {
      contractVehiclePlan,
      assignment,
      contractVehiclesEditMode,
      plannedContractVehiclesEditMode,
      fetchedVehicles,
      isLoading,
      budgetIsCreated,
      contractVehicleModal,
      plannedVehicleModal
    } = this.state;
    if (contractVehiclePlan === null) {
      return null;
    }
    let contractVehicles =
      contractVehiclePlan && contractVehiclePlan.contractVehicles
        ? contractVehiclePlan.contractVehicles
        : [];
    const plannedContractVehicles =
      contractVehiclePlan && contractVehiclePlan.plannedContractVehicles
        ? contractVehiclePlan.plannedContractVehicles
        : [];
    return (
      <>
        {contractVehiclePlan && (
          <>
            <Header
              left={
                <Breadcrumbs>
                      <Crumb to={getPathWithHistoryParams(`/budget/contract-vehicle/fixed/${cognosType}`)}>
                    Бюджеты НТС
                  </Crumb>
                  <Crumb>Закрепленные НТС</Crumb>
                </Breadcrumbs>
              }
            />
            <TopPanel>
              <h1>Планируемый перечень ТС</h1>
            </TopPanel>
            <Tabs
              cognosType={cognosType}
              contractVehiclePlanId={contractVehiclePlanId}
              assignmentId={(assignment && assignment.id) || undefined}
              assignmentApproved={
                !!(
                  assignment && assignment.status === entityStatusEnum.approved
                )
              }
            />

            <Section>
              <SectionTitle
                divider
                suffix={<Button onClick={this.handlePrint}>Печать</Button>}
              >
                Период и версия бюджета
              </SectionTitle>
              <Content>
                <Grid gutter="16px" cols={4}>
                  <GridItem>
                    <Field label="Планируемый период">
                      <>
                        {formatDateRangeString(
                          contractVehiclePlan.startDate,
                          contractVehiclePlan.endDate,
                          'DD.MM.YYYY'
                        )}
                      </>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Версия бюджета">
                      <>
                        {contractVehiclePlan &&
                          contractVehiclePlan.budgetVersion &&
                          contractVehiclePlan.budgetVersion.name}
                      </>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата формирования">
                      <>{formatDate(contractVehiclePlan.date)}</>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Регламент">
                      <Link
                        to={`/budget/contract-vehicle/work-plans/${contractVehiclePlan.regulationId}/card`}
                      >
                        Регламент {contractVehiclePlan.regulationId}
                      </Link>
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Филиал">
                      <>{contractVehiclePlan.orgUnitName}</>
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Section>
              <SectionTitle
                divider
                suffix={
                  budgetIsCreated &&
                  contractVehiclesEditMode && (
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({ contractVehicleModal: true })
                      }
                    >
                      Добавить ТС
                    </Button>
                  )
                }
              >
                ТС согласно инвентарной картотеке
              </SectionTitle>
              <Content>
                {contractVehiclesEditMode ? (
                  <VehicleSection
                    contractVehiclePlanType="contractVehicles"
                    data={fetchedVehicles}
                    loading={isLoading}
                    onChange={(type, selectedVehicles) =>
                      this.setState({ selectedVehicles })
                    }
                    applyFilter={(type, filters) => {
                      this.fetchVehicles(
                        contractVehiclePlan.orgUnitId,
                        'contractVehicles',
                        filters
                      );
                    }}
                    cleanFilter={() =>
                      this.fetchVehicles(
                        contractVehiclePlan.orgUnitId,
                        'contractVehicles'
                      )
                    }
                    initialSelectedRows={contractVehicles}
                  />
                ) : (
                  <InnerTable data={contractVehicles} />
                )}
                {budgetIsCreated ? (
                  contractVehiclesEditMode ? (
                    <>
                      <StyledButton
                        type="primary"
                        onClick={() => this.saveVehicles('contractVehicles')}
                      >
                        Сохранить
                      </StyledButton>
                      <StyledButton onClick={this.handleCancel}>
                        Отменить
                      </StyledButton>
                    </>
                  ) : (
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        this.fetchVehicles(
                          contractVehiclePlan.orgUnitId,
                          'contractVehicles'
                        );
                        this.setState({
                          contractVehiclesEditMode: true,
                          plannedContractVehiclesEditMode: false
                        });
                      }}
                    >
                      Редактировать
                    </StyledButton>
                  )
                ) : null}
              </Content>
            </Section>

            <Section>
              <SectionTitle
                divider
                suffix={
                  budgetIsCreated &&
                  plannedContractVehiclesEditMode && (
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({ plannedVehicleModal: true })
                      }
                    >
                      Добавить ТС
                    </Button>
                  )
                }
              >
                ТС согласно плану обновления
              </SectionTitle>
              <Content>
                {plannedContractVehiclesEditMode ? (
                  <VehicleSection
                    contractVehiclePlanType="plannedContractVehicles"
                    data={fetchedVehicles}
                    loading={isLoading}
                    onChange={(type, selectedVehicles) =>
                      this.setState({ selectedVehicles })
                    }
                    applyFilter={(type, filters) => {
                      this.fetchVehicles(
                        contractVehiclePlan.orgUnitId,
                        'plannedContractVehicles',
                        filters
                      );
                    }}
                    cleanFilter={() =>
                      this.fetchVehicles(
                        contractVehiclePlan.orgUnitId,
                        'plannedContractVehicles'
                      )
                    }
                    initialSelectedRows={plannedContractVehicles}
                  />
                ) : (
                  <InnerTable data={plannedContractVehicles} />
                )}
                {budgetIsCreated ? (
                  plannedContractVehiclesEditMode ? (
                    <>
                      <StyledButton
                        type="primary"
                        onClick={() =>
                          this.saveVehicles('plannedContractVehicles')
                        }
                      >
                        Сохранить
                      </StyledButton>
                      <StyledButton onClick={this.handleCancel}>
                        Отменить
                      </StyledButton>
                    </>
                  ) : (
                    <StyledButton
                      type="primary"
                      onClick={() => {
                        this.fetchVehicles(
                          contractVehiclePlan.orgUnitId,
                          'plannedContractVehicles'
                        );
                        this.setState({
                          plannedContractVehiclesEditMode: true,
                          contractVehiclesEditMode: false
                        });
                      }}
                    >
                      Редактировать
                    </StyledButton>
                  )
                ) : null}
              </Content>
            </Section>

            <ModalContractVehicle
              visible={contractVehicleModal}
              onCancel={this.hideModals}
              addContractVehicle={this.addVehicle}
              orgUnitId={contractVehiclePlan && contractVehiclePlan.orgUnitId}
            />
            <ModalPlannedContractVehicle
              visible={plannedVehicleModal}
              onCancel={this.hideModals}
              addContractVehicle={this.addVehicle}
              orgUnitId={contractVehiclePlan && contractVehiclePlan.orgUnitId}
            />
          </>
        )}
      </>
    );
  }
}
