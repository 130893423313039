// @flow
import React from 'react';
import {TabItem, Tabs} from '../../../components/ui';

const entryPointPath = '/admin/working-with-budgeting/';

export default () => {
  return (
    <Tabs withRouter>
      <TabItem
        label="Сроки отправки фактических данных в ИСУ «Бюджетирование»"
        url={`${entryPointPath}`}
      />
      <TabItem
        label="Календарь проведения бюджетного комитета"
        url={`${entryPointPath}calendar`}
      />
    </Tabs>
  );
};
