// @flow

import React from 'react';
import moment from 'moment';

import {
  Card,
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../components';
import {formatDateTimeToISOString} from '../../../lib/helpers';
import {SectionContent} from '../../../components/hoc/common/components/elements';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import type {VehicleType} from '../../../lib/types';
import Datepicker from 'antd/lib/date-picker';

const { Field } = Card;

const { OsagoTransportTypeSelect } = Selects;

export type BranchFilterParams = {
  startDate?: string,
  endDate?: string,
  osagoType?: VehicleType,
  nodeId?: number
};

type Props = {
  filter: BranchFilterParams,
  applyFilter: Function,
  cleanFilter: Function
};

export const BranchFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<BranchFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата начала"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(value, dateString) || undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата окончания"
                  value={values.endDate ? moment(values.endDate) : undefined}
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(value, dateString) || undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  placeholder="Все подразделения"
                  size="small"
                  onlyBranches
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OsagoTransportTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values.osagoType}
                  selectableGroup
                  onChange={value => changeValue('osagoType', value)}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default BranchFilter;
