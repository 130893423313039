// @flow
import React from 'react';

import styled from 'styled-components';

import {TabItem} from '../../../components/ui';
import Index from '../../../components/ui/Tabs/Tabs';
import {navigate} from '../../../lib/helpers';

const StyledTabs = styled(Index)`
  & .tabs__item:first-child {
    margin-left: 0;
  }
`;

type Props = {
  defaultTab: 'optional' | 'attached' | 'tires' | 'batteries'
};

export const NavigationTabs = ({defaultTab}: Props) => (
  <StyledTabs defaultTab={defaultTab}>
    <TabItem
      tabKey="attached"
      label="Навесное и дополнительное оборудование"
      onClick={() => navigate('/equipment/attached', true)}
    />
    <TabItem
      tabKey="optional"
      label="Приборы и бортовые устройства"
      onClick={() => navigate('/equipment/optional', true)}
    />
    <TabItem
      tabKey="tires"
      label="Шины"
      onClick={() => navigate('/equipment/tires', true)}
    />
    <TabItem
      tabKey="batteries"
      label="АКБ"
      onClick={() => navigate('/equipment/batteries', true)}
    />
  </StyledTabs>
);

export default NavigationTabs;
