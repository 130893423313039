// @flow
import React from 'react';
import type { SelectProps } from 'antd/lib/select';
import { vehiclePlanStatus } from '../../lib/enum';
import type { VehiclePlanStatus } from '../../lib/types';
import AutoGeneratedEnumSelect from './AutoGeneratedEnumSelect';

type Props = {
  exclude?: VehiclePlanStatus[]
} & SelectProps;
export default ({ exclude, ...props }: Props) => {
  let autoType = {};
  if (Array.isArray(exclude)) {
    Object.keys(vehiclePlanStatus).forEach(status => {
      if (!exclude.includes(status)) {
        autoType[status] = vehiclePlanStatus[status];
      }
    });
  } else {
    autoType = { ...vehiclePlanStatus };
  }
  const Select = AutoGeneratedEnumSelect({
    placeholder: 'Выберите статус nnnnn',
    autoType
  });
  return <Select {...props} />;
};
