// @flow
import type {FuelsAct} from '../types';
import CommonApi from './../commonApi';
import {convertFromKopToRub} from '../helpers';
import {fetchRequest} from './index';

export const convertDataFromServer = (fuelsAct: FuelsAct) => ({
  ...fuelsAct,
  sum: convertFromKopToRub(fuelsAct.sum),
});

const fuelsAct = new CommonApi<FuelsAct>(
  'fuelsAct',
  'акт списания топлива',
  undefined,
  convertDataFromServer
);

const getFuelActById = async (actId: number) => {
  return await fetchRequest.get(`/fuelsAct/${actId}`);
}

const recalculateAct = async (actId: number) => {
  return await fetchRequest.put(`/fuelsAct/${actId}/recalculate`);
}

const addEquipment = async (data) => {
  return await fetchRequest.post('/fuelsAct/equipment', data);
}

const updateEquipment = async (data) => {
  return await fetchRequest.put('/fuelsAct/equipment', data);
}

const deleteEquipment = async (id) => {
  return await fetchRequest.delete(`/fuelsAct/equipment/${id}`);
}

export default {
  ...fuelsAct,
  getFuelActById,
  recalculateAct,
  addEquipment,
  updateEquipment,
  deleteEquipment
};
