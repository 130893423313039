// @flow

import React, { Component } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button/button';

import type { FetchListParams } from './../../lib/api';
import { vehicleIssueGroupApi } from './../../lib/api';
import type { ListState, UserAccess } from './../../lib/types';
import { accessTypeEnum } from './../../lib/enum';
import {
  getListInitialState,
  setQueryParams,
  navigate
} from './../../lib/helpers';

import { notificationLoading } from './../../components/Notifications';
import { Header, Section } from './../../components/layout';
import { Icon, Operations, Table } from './../../components/ui';
import type { VehicleIssueGroup } from '../../lib/types/vehicleIssueGroup';

import { withUserAccess } from './../withUserAccess';
import type { VehicleIssueGroupParams } from './components/Filter';
import Filter from './components/Filter';

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingVehicleIssueGroups
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<VehicleIssueGroup> & {
  filter: VehicleIssueGroupParams
};

export class VehicleIssueGroupList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true
    }
  ];

  archiveVehicleIssueGroup = async (data: VehicleIssueGroup) => {
    try {
      notificationLoading({
        message: 'Архивирование...',
        key: 'deleting'
      });
      await vehicleIssueGroupApi.updateVehicleIssueGroup({
        ...data,
        isArchive: true
      });
      await this.fetchVehicleIssueGroup(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchVehicleIssueGroup = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const {
      data,
      totalCount
    } = await vehicleIssueGroupApi.fetchVehicleIssueGroup({
      page,
      ...params,
      ...this.state.filter
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  canAdd = () =>
    this.props.userAccess.some(access => addAccess.includes(access));

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '30px',
        stopPropagation: true,
        render: (record: VehicleIssueGroup) =>
          record.isArchive ? null : (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите перевести запись в архив?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () =>
                  await this.archiveVehicleIssueGroup(record)
                }
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
      });
    }
    await this.fetchVehicleIssueGroup(page);
  }

  applyFilter = (filter: VehicleIssueGroupParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchVehicleIssueGroup(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchVehicleIssueGroup(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Группы дефектов</h1>}
          right={
            canAdd && (
              <Link to="/admin/vehicle-issue-group/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () =>
                navigate(`/admin/vehicle-issue-group/edit/${record.id}`)
            })}
            columns={this.columns}
            fetch={this.fetchVehicleIssueGroup}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(VehicleIssueGroupList);
