//@flow
import React, {Fragment, useState} from 'react';
import Button from 'antd/lib/button';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import CommonForm from '../../../../components/hoc/common/handbook/CommonForm';
import Field from '../../../../components/card/Field';
import {FormField} from '../../../../components/Form';
import {formatDateRangeString, formatDateTimeToString, plus, toLocalStringRu, tooltipFullText} from '../../../../lib/helpers';
import {branchBudgetSummaryCognosTypeEnum, budgetLineItemBudgetInline} from '../../../../lib/enum';
import type {BranchBudgetSummary, BranchBudgetSummaryLineItem, BudgetLineItemBudgetType, BudgetSummary} from '../../../../lib/types';
import type {BudgetSummaryMonth} from '../../../../lib/types/budgetSummary';
import {SectionContent} from '../../../../components/hoc/common/components/elements';
import VehiclePlanSelect from '../../../../components/selects/VehiclePlanSelect';
import VehicleContractPlanSelect from '../../../../components/selects/VehicleContractPlanSelect';
import Table from './Table';
import {BranchTabs} from './index';
import {SectionTitle} from '../../../../components/layout';

const whiteSpaceWrapColumn = (data: string) => {
  return (
    <div style={{whiteSpace: 'pre-line', overflow: 'auto'}}>
      {toLocalStringRu(data)}
    </div>
  );
};

type Props = {
  branchBudgetSummary?: BranchBudgetSummary,
  budgetSummary?: BudgetSummary,
  orgUnitId: number,
  onSubmit: (payload: any) => any,
  showTable?: boolean,
  id: number,
  source?: $Keys<branchBudgetSummaryCognosTypeEnum>,
};

export default ({showTable = true, ...props}: Props) => {
  const [filter] = useState({
    status: 'approved',
    budgetStatus: 'calculationDone',
    budgetVersionId: props.budgetSummary?.budgetVersionId,
  });
  
  const columns = [
    {
      title: 'Статья бюджетной формы',
      dataIndex: 'budgetLineItemName',
      key: 'budgetLineItemName',
      render: whiteSpaceWrapColumn,
    },
    {
      title: 'Направление расходов',
      dataIndex: 'expenseDirectionName',
      key: 'expenseDirectionName',
      render: (expenseDirectionName: string) => (
        <>{whiteSpaceWrapColumn(expenseDirectionName)}</>
      ),
    },
    {
      title: 'Изменение бюджета Итого',
      dataIndex: props.source === branchBudgetSummaryCognosTypeEnum.cognos ? 'cognosDelta' : 'budgetChange',
      key: 'budgetChange',
      render: whiteSpaceWrapColumn,
    },
    {
      title: 'Предложение в бюджет итого',
      dataIndex: props.source === branchBudgetSummaryCognosTypeEnum.cognos ? 'sum' : 'newCalculation',
      key: 'newCalculation',
      render: whiteSpaceWrapColumn,
    },
    {
      title: 'Комментарии филиала',
      dataIndex: 'note',
      key: 'note',
      width: 200,
      render: (note: string) =>
        tooltipFullText(
          note,
          30,
          note?.split(';').map(comment => (
            <>
              {comment}
              <br />
              <br />
            </>
          )) ?? null,
        ),
    },
  ];
  
  if (props.source === branchBudgetSummaryCognosTypeEnum.cognos &&
      columns.findIndex(item => item.dataIndex === 'note') >= 0) {
    columns.pop();
  }

  const summator = (months, ids: number[]) => months.reduce(
      (sum, month) => {
        let summator = sum;
        if (ids.includes(month.month)) {
          summator.plan = plus(summator.plan, month.plan);
        }
        return summator;
      },
      {plan: 0},
    );
  
  return (
    <>
      <CommonForm
        onSubmit={props.onSubmit}
        data={props.branchBudgetSummary}
        useFooter={false}
      >
        {({values, setFieldValue, handleSubmit, dirty, isSubmitting}) => {
          let itogData: Map<BudgetLineItemBudgetType, BudgetSummaryMonth & { itogAfterLine: number }> = new Map();
          
          const data = showTable
            ? values.budgetSummaryLineItems?.map((budget: BranchBudgetSummaryLineItem, index: number) => {
              const months = [
                ...budget.months,
                {
                  month: 13,
                  ...summator(budget.months, [1, 2, 3]),
                },
                {
                  month: 14,
                  ...summator(budget.months, [4, 5, 6]),
                },
                {
                  month: 15,
                  ...summator(budget.months, [7, 8, 9]),
                },
                {
                  month: 16,
                  ...summator(budget.months, [10, 11, 12]),
                },
                {
                  month: 17,
                  ...summator(
                    budget.months,
                    Array.from({length: 12}, (_, i) => i + 1),
                  ),
                },
              ];
              // Формируем итог по месяцам
              const branchBudget = itogData.get(
                budget.budgetLineItemBudgetType,
              );
              itogData.set(budget.budgetLineItemBudgetType, {
                itogAfterLine: index,
                sum: plus(branchBudget?.sum ?? 0, budget.sum ?? 0),
                cognosDelta: plus(branchBudget?.cognosDelta ?? 0, budget.cognosDelta ?? 0),
                budgetChange: plus(
                  branchBudget?.budgetChange ?? 0,
                  budget.budgetChange ?? 0,
                ),
                newCalculation: plus(
                  branchBudget?.newCalculation ?? 0,
                  budget.newCalculation ?? 0,
                ),
                months: months.map((month: BudgetSummaryMonth) => ({
                  month: month.month,
                  plan: plus(
                    branchBudget?.months?.find(
                      el => el.month === month.month,
                    )?.plan ?? 0,
                    month.plan ?? 0,
                  ),
                })),
              });
              
              return {
                ...budget,
                expenseDirectionName: 'Итого',
                rowId: index,
                months,
                expenseDirections: budget.expenseDirections.map(
                  (expenseDirection, index2) => ({
                    ...expenseDirection,
                    rowId: index * 1000 + index2,
                    months: [
                      ...expenseDirection.months,
                      {
                        month: 13,
                        ...summator(expenseDirection.months, [1, 2, 3]),
                      },
                      {
                        month: 14,
                        ...summator(expenseDirection.months, [4, 5, 6]),
                      },
                      {
                        month: 15,
                        ...summator(expenseDirection.months, [7, 8, 9]),
                      },
                      {
                        month: 16,
                        ...summator(expenseDirection.months, [10, 11, 12]),
                      },
                      {
                        month: 17,
                        ...summator(
                          expenseDirection.months,
                          Array.from({length: 12}, (_, i) => i + 1),
                        ),
                      },
                    ],
                  }),
                ),
              };
            },
            ) ?? []
            : [];
          
          if (data.length > 0) {
            let rowId = 0;
            
            for (let [budgetLineItemBudgetName, branchBudget] of itogData) {
              rowId--;
              // Не выводим undefined
              if (budgetLineItemBudgetName)
                // Вставляем итог с учетом уже вставленных итогов
              {
                data.splice(branchBudget.itogAfterLine - rowId, 0, {
                  ...branchBudget,
                  budgetLineItemName:
                    'Итого по ' +
                    budgetLineItemBudgetInline[budgetLineItemBudgetName],
                  rowId,
                });
              }
            }
          }
          
          return (
            <>
              <Fragment noWrapMe>
                {props.orgUnitId === '-1' && (
                  <SectionTitle divider>
                    <BranchTabs id={props.id} orgUnitId={-1} showChildItems={false} />
                  </SectionTitle>
                )}
                <SectionContent>
                  {values.id > 0 && (
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Наименование бюджета СТС"
                          name="selfVehiclePlanId"
                        >
                          {({name, value}) => (
                            <VehiclePlanSelect
                              value={value}
                              onChange={(value?: number, option: any) => {
                                !!value &&
                                setFieldValue(
                                  'selfVehiclePlan',
                                  option.props.vehiclePlan,
                                );
                                setFieldValue(name, value);
                              }}
                              filter={filter}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Наименование бюджета НТС"
                          required
                          name="contractVehiclePlanId"
                        >
                          {({name, value}) => (
                            <VehicleContractPlanSelect
                              value={value}
                              onChange={(value?: number, option: any) => {
                                !!value &&
                                setFieldValue(
                                  'contractVehiclePlan',
                                  option.props.vehiclePlan,
                                );
                                setFieldValue(name, value);
                              }}
                              filter={filter}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {values.selfVehiclePlan && (
                          <Field label="Период формирования">
                            {`${formatDateRangeString(
                              values.selfVehiclePlan.startDate,
                              values.selfVehiclePlan.endDate,
                              'DD.MM.YYYY',
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.contractVehiclePlan && (
                          <Field label="Период формирования">
                            {`${formatDateRangeString(
                              values.contractVehiclePlan.startDate,
                              values.contractVehiclePlan.endDate,
                              'DD.MM.YYYY',
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.selfVehiclePlan && (
                          <Field label="Дата формирования">
                            {`${formatDateTimeToString(
                              values.selfVehiclePlan.date,
                              'DD.MM.YYYY',
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.contractVehiclePlan && (
                          <Field label="Дата формирования">
                            {`${formatDateTimeToString(
                              values.contractVehiclePlan.date,
                              'DD.MM.YYYY',
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                    </Grid>
                  )}
                  {dirty && (
                    <GridItem>
                      <Field label=" ">
                        <Button
                          type="primary"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                        >
                          Сохранить
                        </Button>
                      </Field>
                    </GridItem>
                  )}
                  {values.id === -1 && (
                    <Table
                      data={data}
                      columns={columns}
                      loading={true}
                      rowClassName={record => {
                        return record.rowId < 0
                          ? 'ant-table-row-color-light-grey'
                          : null;
                      }}
                    />
                  )}
                </SectionContent>
              </Fragment>
            </>
          );
        }}
      </CommonForm>
    </>
  );
};
