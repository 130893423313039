// @flow
import React from 'react';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  Selects
} from '../../../../components';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import Field from '../../../../components/card/Field';
import { SectionContent } from '../../../../components/hoc/common/components/elements';

const { ExpenseDirectionSelect } = Selects;

export type BudgetLineItemFilterParams = {
  estimateExpenseDirectionId?: string
};

type Props = {
  filter: BudgetLineItemFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<BudgetLineItemFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionSelect
                  size="small"
                  onChange={estimateExpenseDirectionId =>
                    changeValue(
                      'estimateExpenseDirectionId',
                      estimateExpenseDirectionId
                    )
                  }
                  value={values.estimateExpenseDirectionId}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);
