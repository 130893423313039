// @flow
import React, { type ComponentType } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import styled from 'styled-components';
import { FormikProps } from 'formik';

import { Section } from './../../components/layout';
import Grid, { GridItem } from './../../components/layout/Grid';
import type { FuelMultiplier } from '../../lib/types/fuelMultiplier';
import Form from '../../components/Form';
import type { FormFieldProps } from '../../components/Form';
import { Selects } from '../../components';
import CancelButton from '../../components/CancelButton';

const { YesNoSelect } = Selects;
type FormProps = {
  multiplier: FuelMultiplier,
  onSubmit: Function,
  onCancel: Function
};

const InnerForm = ({ onSubmit, multiplier, onCancel }: FormProps) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={multiplier || { isWinterTime: false }}
    >
      {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          dirty,
          isSubmitting
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Наименование" name="name" required>
                      {({ value, name }) => (
                        <Input
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Значение" name="value" required>
                      {({ value, name }) => (
                        <InputNumber
                          value={value}
                          name={name}
                          onChange={change => {
                            setFieldValue(name, change);
                          }}
                          min={0}
                          step={0.1}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="shouldUsedAlways"
                      label="Постоянный коэффициент"
                    >
                      {({ value, name }) => (
                        <YesNoSelect
                          value={value}
                          name={name}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                data-cy="save"
                htmlType="submit"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default InnerForm;
