//@flow
import {Button} from 'antd';
import Popconfirm from 'antd/lib/popconfirm';
import React, {useState} from 'react';
import {SectionContent} from '../../../components/hoc/common/components/elements';
import {SectionTitle} from '../../../components/layout';
import Section from '../../../components/layout/Section';
import {Icon} from '../../../components/ui';
import Table, {TableHeader} from '../../../components/ui/Table';
import {fuelsAndGazTypes, operatingModes, rateTypeEnum} from '../../../lib/enum';
import type {FuelAndGazType, OperatingMode, VehicleTariff} from '../../../lib/types';
import type {VehicleTariffTypesAndModels} from '../../../lib/types/vehicleTariff';
import {TypesAndModelsModal} from './TypesAndModelsModal';
import { isTariffFact } from '../../../lib/helpers';
import {VehicleTariffStatusIcon} from './VehicleTariffStatusIcon';
import {vehicleTariffApi} from '../../../lib/api';
import {notificationError} from '../../../components/Notifications';
import {NO_DATA} from '../constants';

type TypesAndModelsTableSectionProps = {
  values: VehicleTariff,
  setFieldValue: Function,
  version: rateTypeEnum
};
export const TypesAndModelsTableSection = ({
  values,
  setFieldValue,
  version
}: TypesAndModelsTableSectionProps) => {
  const [showTypesAndModelsModal, setShowTypesAndModelsModal] = useState(false);
  const [typesAndModels, setTypesAndModels,] = useState<VehicleTariffTypesAndModels>({});
  const tariffColumnsWidth = '80px';
  const isVehicleTariffFact = isTariffFact(version);
  const vehicleTariffId = values.id;

  const deleteTypesAndModels = (typesAndModels: VehicleTariffTypesAndModels,) => {
    let newTypesAndModels = values.typesAndModels || [];
    newTypesAndModels = newTypesAndModels.filter(el => {
      return typesAndModels !== el;
    });
    setFieldValue('typesAndModels', newTypesAndModels);
    setShowTypesAndModelsModal(false);
  };

  return (
    <Section>
      <SectionTitle
        divider
        suffix={
          <Button
            onClick={() => {
              // $FlowFixMe
              setTypesAndModels({});
              setShowTypesAndModelsModal(true);
            }}
          >
            Добавить
          </Button>
        }
      />
      <SectionContent>
        <Table
          columns={[
            {
              title: 'Марка ТС',
              dataIndex: 'vehicleModel.brandName',
              render: (name: string, record: VehicleTariffTypesAndModels) => name || record.importBrandName,
              fixed: 'left',
              width: 150,
            },
            {
              title: 'Модель ТС',
              dataIndex: 'vehicleModel.name',
              render: (name: string, record: VehicleTariffTypesAndModels) => name || record.importModelName,
              fixed: 'left',
              width: 200,
            },
            {
              title: 'Статус',
              dataIndex: 'modelStatus',
              render: (value) => <VehicleTariffStatusIcon modelStatus={value} />,
              width: 50,
            },
            {
              title: 'Год начала выпуска',
              dataIndex: 'productionYearStart',
              width: 120,
            },
            {
              title: 'Год окончания выпуска',
              dataIndex: 'productionYearEnd',
              width: 150,
            },
            {
              title: 'Режим эксплуатации',
              dataIndex: 'operatingMode',
              render: (type: OperatingMode) => operatingModes[type],
              width: 150,
            },
            {
              title: 'Установлено ГБО',
              dataIndex: 'hasGas',
              render: (hasGas: boolean) => (hasGas ? 'Да' : 'Нет'),
              width: 110,
            },
            {
              title: 'Лизинг',
              dataIndex: 'leasing',
              render: (leasing: boolean) => (leasing ? 'Да' : 'Нет'),
              width: 80,
            },
            {
              title: (
                <TableHeader width="190px">
                  Тариф (руб.) за 1 час эксплуатации постоянная часть
                </TableHeader>
              ),
              dataIndex: !isVehicleTariffFact ? 'tariffFixed' : undefined,
              width: !isVehicleTariffFact ? 210 : undefined,
              children: isVehicleTariffFact ? [
                {
                  title: <TableHeader width={tariffColumnsWidth}>Факт.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffFixed',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>План.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffFixedPlan',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>Отклонение.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffFixedDeviation',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>Освоение.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffFixedRealization',
                  render: (value) => value ?? NO_DATA,
                },
              ] : undefined,
            },
            {
              title: (
                <TableHeader width="190px">
                  Тариф (руб.) за 1 км пробега переменная часть
                </TableHeader>
              ),
              dataIndex: !isVehicleTariffFact ? 'tariffDynamic' : undefined,
              width: !isVehicleTariffFact ? 190 : undefined,
              children: isVehicleTariffFact ? [
                {
                  title: <TableHeader width={tariffColumnsWidth}>Факт.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffDynamic',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>План.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffDynamicPlan',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>Отклонение.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffDynamicDeviation',
                  render: (value) => value ?? NO_DATA,
                },
                {
                  title: <TableHeader width={tariffColumnsWidth}>Освоение.</TableHeader>,
                  width: 125,
                  dataIndex: 'tariffDynamicRealization',
                  render: (value) => value ?? NO_DATA,
                },
              ] : undefined,
            },
            {
              title: 'Тип топлива',
              dataIndex: 'fuelType',
              render: (fuelType: FuelAndGazType) => fuelsAndGazTypes[fuelType],
              width: 100,
            },
            {
              title: <TableHeader width="200px">Простой с включенным ДВС (обогрев салона)</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма списания топлива на прогрев, л/ч</TableHeader>,
                  width: 135,
                  dataIndex: 'warmingNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'warmingTariff',
                },
              ],
            },
            {
              title: <TableHeader width="200px">Пробег ТС с прицепом</TableHeader>,
              children: [
                {
                  title: <TableHeader width="105px">Норма расхода топлива на 1 км, л</TableHeader>,
                  width: 140,
                  dataIndex: 'trailerNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 км, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'trailerTariff',
                },
              ],
            },
            {
              title: <TableHeader width="180px">Отопитель воздушный (обогрев салона)</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                  width: 135,
                  dataIndex: 'heaterNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'heaterTariff',
                },
              ],
            },
            {
              title: <TableHeader width="200px">Подогреватель жидкостной</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                  width: 135,
                  dataIndex: 'preHeaterNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'preHeaterTariff',
                },
              ],
            },
            {
              title: <TableHeader width="200px">Силовой генератор</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                  width: 135,
                  dataIndex: 'generatorNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'generatorTariff',
                },
              ],
            },
            {
              title: <TableHeader width="200px">Лебедка</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                  width: 135,
                  dataIndex: 'winchNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'winchTariff',
                },
              ],
            },
            {
              title: <TableHeader width="200px">Кондиционер</TableHeader>,
              children: [
                {
                  title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                  width: 135,
                  dataIndex: 'airConditionerNorm',
                },
                {
                  title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                  width: 125,
                  dataIndex: 'airConditionerTariff',
                },
              ],
            },
            {
              title: '',
              dataIndex: 'id',
              key: 'delete',
              stopPropagation: true,
              width: 30,
              render: (id: number, record: VehicleTariffTypesAndModels) => (
                <Popconfirm
                  title="Вы уверены, что хотите удалить запись?"
                  onConfirm={async () => deleteTypesAndModels(record)}
                >
                  <Icon type="x" />
                </Popconfirm>
              ),
            },
          ]}
          data={values.typesAndModels}
          onRow={(record: VehicleTariffTypesAndModels) => {
            return {
              onClick: () => {
                setTypesAndModels(record);
                setShowTypesAndModelsModal(true);
              },
            };
          }}
          bordered
          scroll={{
            y: '500px',
            x: 'auto',
          }}
        />
        <TypesAndModelsModal
          vehicleTariffVehicle={typesAndModels}
          visible={showTypesAndModelsModal}
          onClose={() => setShowTypesAndModelsModal(false)}
          onSave={async (
            oldVehicleTariffVehicle: VehicleTariffTypesAndModels,
            vehicleTariffVehicle: VehicleTariffTypesAndModels,
          ) => {
            if (vehicleTariffVehicle.vehicleTariffId === undefined) {
              vehicleTariffVehicle.vehicleTariffId = vehicleTariffId;
            }
            try {
              vehicleTariffVehicle = await vehicleTariffApi.calculateVehicleModel(vehicleTariffVehicle);
            } catch (e) {
              console.error('[onSave] calculateVehicleModel error', e);
              notificationError(e, {message: 'Предварительный расчёт временно недоступен'});
            }
            let found = false;
            let newTypesAndModels = (values.typesAndModels || []).map(
              el => {
                if (el === oldVehicleTariffVehicle) {
                  found = true;
                  return vehicleTariffVehicle;
                }
                return el;
              },
            );
            if (!found) {
              newTypesAndModels.push(vehicleTariffVehicle);
            }
            setFieldValue('typesAndModels', newTypesAndModels);
            setShowTypesAndModelsModal(false);
          }}
        />
      </SectionContent>
    </Section>
  );
};
