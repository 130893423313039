// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import set from 'lodash/set';
import get from 'lodash/get';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import qs from 'query-string';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import cloneDeep from 'lodash/cloneDeep';

import {contractVehicleDailyBudgetApi} from '../../../lib/api';
import {Buttons, SectionContent} from './elements';
import {SpreadsheetTable} from '../../../components/ui';
import Spinner from '../../../components/Spinner';
import type {SpreadsheetTableColumn} from '../../../components/ui/SpreadsheetTable';
import {notificationLoading} from '../../../components/Notifications';
import type {DailyPlanning, DailyPlanningDate} from '../../../lib/types/contractVehicleDailyBudget';
import {getDates, getTimeLimitStatus, isRangeInOneMonth} from '../lib';
import type {UserAccess} from '../../../lib/types';
import {accessTypeEnum, operationLimitGroupStatusEnum, quarters, quartersEnum} from '../../../lib/enum';
import type {AppState} from '../../../ducks/redux';
import {Day} from '../elements';
import Section from '../../../components/layout/Section';
import type {OperationLimitGroupStatus} from '../../../lib/types/operationLimitGroup';
import {columns, getStatus, totalValuesColumn} from './lib';
import type {YearDailyBudgetFilterParams} from './components/YearFilter';
import YearFilter from './components/YearFilter';

const initialFilter: any = {
  mode: 'byMonth',
  startDate: moment
  .utc()
  .startOf('month')
  .toISOString(),
  endDate: moment
  .utc()
  .endOf('month')
  .toISOString(),
};

type Props = {
  userAccess: UserAccess[],
  orgUnitId: number
};

type State = {
  budgets: DailyPlanning[],
  // Неизменяемый план, нужен для возвращения предыдущих значений
  staticBudgets: DailyPlanning[],
  changedDateTimes: DailyPlanningDate[],
  monthColumns: SpreadsheetTableColumn[],
  loading: boolean,
  filter: YearDailyBudgetFilterParams
};

/**
 * Планирование лимитных дней на год
 */
class ContractVehicleDailyBudgetTable extends React.Component<Props, State> {
  state = {
    budgets: [],
    staticBudgets: [],
    monthColumns: [],
    changedDateTimes: [],
    loading: false,
    filter: initialFilter,
  };
  
  tableRef: any = null;
  
  async componentDidMount() {
    // Явно проставляем значения из queryString,
    // чтобы корректно работало очищение фильтра
    const {
      startDate = initialFilter.startDate,
      endDate = initialFilter.endDate,
      mode = initialFilter.mode,
    } = qs.parse(window.location.search);
    // Делаем запрос на основе данных из query params
    this.setState({filter: {mode, startDate, endDate}}, async () => {
      // Если даты периода в одном месяце, то запрашиваем данные
      if (isRangeInOneMonth(startDate, endDate)) {
        await this.fetchContractVehicleDailyBudget();
      } else {
        // иначе выводим ячейки с указанием месяца и квартала и запрашиваем месяц
        this.setState(
          ({filter}) => ({
            filter: {
              ...filter,
              mode: 'byPeriod',
            },
          }),
          this.fetchContractVehicleDailyBudget,
        );
      }
    });
  }
  
  async componentDidUpdate(prevProps: Props) {
    if (prevProps.orgUnitId !== this.props.orgUnitId) {
      await this.fetchContractVehicleDailyBudget();
    }
  }
  
  /**
   * Функция сброса несохраненных значений
   */
  revertValues = () => {
    this.setState(
      prevState => ({
        // Клонируем неизменные значения
        // т.к. иначе будет некорректный сброс - из-за ссылок на объекты
        budgets: cloneDeep(prevState.staticBudgets),
        changedDateTimes: [],
      }),
      () => {
        // После перерисовываем таблицу
        // иначе данные сбросятся только после сброка фокуса с текущей ячейки
        if (this.tableRef) {
          this.tableRef.forceUpdateGrids();
        }
      },
    );
  };
  
  /**
   * Функция изменения значений лимитного дня
   * @param value Число (пробег, часы)
   * @param key Ключ
   * @param index Индекс лимита в массиве
   */
  onChangeDateValues = (value: number, key: string = '', index: number) => {
    const {budgets, changedDateTimes} = this.state;
    const item: DailyPlanning = {...budgets[index]};
    value = parseFloat(value || 0);
    set(item, key, value);
    // Получаем ключ изменяемого дня для сохранения
    // его в список измененных дней, которые нужно будет отправить на сервер
    const datePath = key.replace(
      /(\.distance|\.hours|\.yearHours|\.yearDistance)/g,
      '',
    );
    const dateTime = get(item, datePath);
    const date = datePath.replace('dateTimes.', '');
    this.setState({
      budgets: Object.assign([...budgets], {
        [index]: item,
      }),
      changedDateTimes: [
        ...changedDateTimes.filter(date => date.id !== dateTime.id),
        {
          contractVehicleId: item.vehicle.id,
          operationLimitId: item.timeLimit.id,
          distance: 0,
          hours: 0,
          date,
          ...dateTime,
        },
      ],
    });
  };
  
  /**
   * Является ли день выходным
   * @param day День
   */
  isWeekendDay = (day: string) => {
    const {budgets = []} = this.state;
    if (!isEmpty(budgets)) {
      const {dateTimes} = budgets[0];
      return dateTimes[day] && dateTimes[day].isWeekend;
    }
  };
  
  /**
   * Генерирует колонки на основе приходящих с сервера данных
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByPeriod = (days: string[]) => {
    // Группируем все данные по месяцам
    const byMonths = groupBy(days, day => moment(day).format('MMMM'));
    // Получаем месяца
    const months: string[] = Object.keys(byMonths);
    
    // Идем по кварталам
    return Object.keys(quartersEnum)
    .filter(quarter => {
      const quarterMonths: string[] = quartersEnum[quarter];
      return quarterMonths.filter(month => months.includes(month)).length > 0;
    })
    .map<SpreadsheetTableColumn>(quarter => {
      return {
        header: {
          title: quarters[quarter],
        },
        // идем по месяцам
        columns: months
        .filter(month => quartersEnum[quarter].includes(month))
        .map(month => {
          return {
            header: {
              expandable: true,
              render: (title?: string) => (
                <p style={{textTransform: 'capitalize'}}>{title}</p>
              ),
            },
            // идем по дням
            columns: byMonths[month]
            .sort((a, b) => new Date(a) - new Date(b))
            .map<SpreadsheetTableColumn>(day => {
              const isWeekend = this.isWeekendDay(day);
              return {
                header: {
                  render: () => (
                    <>
                      {moment(day).format('DD MMM')}
                      <Day isWeekend={isWeekend}>
                        {moment(day).format('ddd')}
                      </Day>
                    </>
                  ),
                },
                columns: [
                  {
                    header: {
                      title: 'Часы',
                    },
                    cell: {
                      editable: this.canHandling(),
                      keyPath: `dateTimes.${day}.yearHours`,
                      compareWith: {
                        key: isWeekend
                          ? 'timeLimit.hoursWeekend'
                          : 'timeLimit.hours',
                        compare: (
                          cellValue: number,
                          compareValue: number,
                        ) =>
                          cellValue !== compareValue
                            ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                            : null,
                        backgroundColor: '#fff3f1',
                      },
                    },
                    width: 60,
                  },
                  {
                    header: {
                      title: 'Пробег',
                    },
                    cell: {
                      editable: this.canHandling(),
                      keyPath: `dateTimes.${day}.yearDistance`,
                      compareWith: {
                        key: isWeekend
                          ? 'timeLimit.distanceWeekend'
                          : 'timeLimit.distance',
                        compare: (
                          cellValue: number,
                          compareValue: number,
                        ) =>
                          cellValue !== compareValue
                            ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                            : null,
                        backgroundColor: '#fff3f1',
                      },
                      cellStyle: {
                        background: 'rgba(196, 196, 196, 0.1)',
                      },
                    },
                    width: 60,
                  },
                ],
              };
            }),
          };
        }),
      };
    });
  };
  
  /**
   * Генерирует колонки на основе приходящих с сервера данных
   * @param days Дни
   * @returns {Array<SpreadsheetTableColumn>} Массив колонок
   */
  getColumnsByMonth = (days: string[]) => {
    return days
    .sort((a, b) => new Date(a) - new Date(b))
    .map<SpreadsheetTableColumn>(day => {
      const isWeekend = this.isWeekendDay(day);
      return {
        header: {
          render: () => (
            <>
              {moment(day).format('DD MMM')}
              <Day isWeekend={isWeekend}>{moment(day).format('ddd')}</Day>
            </>
          ),
        },
        columns: [
          {
            header: {
              title: 'Часы',
            },
            cell: {
              editable: this.canHandling(),
              compareWith: {
                key: isWeekend ? 'timeLimit.hoursWeekend' : 'timeLimit.hours',
                compare: (cellValue: number, compareValue: number) =>
                  cellValue !== compareValue
                    ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                    : null,
                backgroundColor: '#fff3f1',
              },
              keyPath: `dateTimes.${day}.yearHours`,
            },
            width: 60,
          },
          {
            header: {
              title: 'Пробег',
            },
            cell: {
              editable: this.canHandling(),
              keyPath: `dateTimes.${day}.yearDistance`,
              compareWith: {
                key: isWeekend
                  ? 'timeLimit.distanceWeekend'
                  : 'timeLimit.distance',
                compare: (cellValue: number, compareValue: number) =>
                  cellValue !== compareValue
                    ? `Значение изменено. Значение согласно регламенту: ${compareValue}`
                    : null,
                backgroundColor: '#fff3f1',
              },
              cellStyle: {
                background: 'rgba(196, 196, 196, 0.1)',
              },
            },
            width: 60,
          },
        ],
      };
    });
  };
  
  /**
   * Сохранение лимитных дней
   */
  handleSave = async () => {
    try {
      const {changedDateTimes} = this.state;
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await Promise.all(
        changedDateTimes.map(async (value: DailyPlanningDate) => {
          if (value.id) {
            await contractVehicleDailyBudgetApi.updateDailyPlanningDate(value);
          } else {
            await contractVehicleDailyBudgetApi.createDailyPlanningDate(value);
          }
        }),
      );
      this.setState({changedDateTimes: []});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  fetchContractVehicleDailyBudget = async () => {
    const {filter} = this.state;
    const {orgUnitId} = this.props;
    try {
      this.setState({loading: true});
      const budgets = await contractVehicleDailyBudgetApi.fetchDailyBudgets({
        ...filter,
        orgUnitId,
      });
      const dates = getDates(filter.startDate, filter.endDate);
      this.setState(
        {
          budgets,
          staticBudgets: cloneDeep(budgets),
          loading: false,
        },
        () => {
          const {mode} = filter;
          if (mode === 'byMonth') {
            this.setState({monthColumns: this.getColumnsByMonth(dates)});
          } else {
            if (mode === 'byPeriod') {
              this.setState({monthColumns: this.getColumnsByPeriod(dates)});
            }
          }
        },
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };
  
  applyFilter = (filter: YearDailyBudgetFilterParams) =>
    this.setState({filter}, this.fetchContractVehicleDailyBudget);
  
  cleanFilter = () =>
    this.setState(
      {
        filter: {
          ...initialFilter,
          mode: 'byMonth',
        },
      },
      this.fetchContractVehicleDailyBudget,
    );
  
  /**
   * Возможность редактирования пользователем
   */
  canHandling = () => {
    const status = getStatus(this.state.budgets, 'yearStatus');
    const assignmentLimitStatus = getTimeLimitStatus(
      this.state.budgets,
      'assignmentLimitStatus',
    );
    return (
      this.isValidYear() &&
      assignmentLimitStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.draft &&
      this.props.userAccess.some(access =>
        [accessTypeEnum.admin, accessTypeEnum.handlingYearLimitsPlan].includes(
          access,
        ),
      )
    );
  };
  
  /**
   * Проверка на валидность года
   *
   * Нельзя менять статус года, который не является следующим
   */
  isValidYear = () => {
    // const { endDate, startDate } = this.state.filter;
    // const startYear = moment.utc(startDate).year();
    // const endYear = moment.utc(endDate).year();
    // Пока так
    return true;
    // Год в фильтре должен быть одним
    // if (startYear !== endYear) {
    //   return false;
    // } else {
    //   // Это последующий год
    //   return endYear > moment().year();
    // }
  };
  
  /**
   * Возможность отправки на утверждение пользователем
   */
  canSendToAgreeing = () => {
    const status = getStatus(this.state.budgets, 'yearStatus');
    const assignmentLimitStatus = getTimeLimitStatus(
      this.state.budgets,
      'assignmentLimitStatus',
    );
    return (
      this.isValidYear() &&
      assignmentLimitStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.draft &&
      this.canHandling()
    );
  };
  
  /**
   * Возможность утверждения пользователем
   */
  canApprove = () => {
    const status = getStatus(this.state.budgets, 'yearStatus');
    const assignmentLimitStatus = getTimeLimitStatus(
      this.state.budgets,
      'assignmentLimitStatus',
    );
    return (
      this.isValidYear() &&
      assignmentLimitStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.onAgreeing &&
      this.props.userAccess.some(access =>
        [accessTypeEnum.admin, accessTypeEnum.approvingYearLimitsPlan].includes(
          access,
        ),
      )
    );
  };
  
  changeStatus = async (status: OperationLimitGroupStatus) => {
    const {endDate} = this.state.filter;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const budgets = await Promise.all(
        this.state.budgets.map(
          async budget =>
            await contractVehicleDailyBudgetApi.updateBudgets({
              startDate: moment
              .utc(endDate)
              .startOf('year')
              .toISOString(),
              endDate: moment
              .utc(endDate)
              .endOf('year')
              .toISOString(),
              isYearStatus: true,
              limitId: budget.timeLimit.id,
              status,
            }),
        ),
      );
      this.setState({budgets});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  handleSendToAgreeing = async () => {
    await this.changeStatus(operationLimitGroupStatusEnum.onAgreeing);
    notification.success({
      message: 'Годовой план был отправлен на согласование',
    });
  };
  
  handleApprove = async () => {
    await this.changeStatus(operationLimitGroupStatusEnum.approved);
    notification.success({
      message: 'Годовой план был утвержден',
    });
  };
  
  render() {
    const {
      loading,
      budgets,
      filter,
      monthColumns,
      changedDateTimes,
    } = this.state;
    
    const hasChangedValues = !isEmpty(changedDateTimes);
    const canSendToAgreeing = !hasChangedValues && this.canSendToAgreeing();
    const canHandling = hasChangedValues && this.canHandling();
    const canApprove = !hasChangedValues && this.canApprove();
    const showFooter = canHandling || canSendToAgreeing || canApprove;
    return (
      <>
        <Section>
          <SectionContent>
            <YearFilter
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </SectionContent>
          {loading ? (
            <SectionContent>
              <Spinner isLoading />
            </SectionContent>
          ) : (
            <>
              <SpreadsheetTable
                fixedColumnCount={1}
                ref={table => (this.tableRef = table)}
                style={{height: `calc(100vh - ${showFooter ? 317 : 237}px)`}}
                onChange={this.onChangeDateValues}
                data={budgets}
                notFoundText="Данных о бюджете нет"
                columns={[...columns, ...monthColumns, totalValuesColumn]}
              />
            </>
          )}
        </Section>
        {showFooter && (
          <Section>
            <SectionContent>
              <Buttons>
                {canHandling && (
                  <Button type="primary" onClick={this.handleSave}>
                    Сохранить
                  </Button>
                )}
                {hasChangedValues && (
                  <Button type="primary" onClick={this.revertValues}>
                    Отменить правки
                  </Button>
                )}
                {canSendToAgreeing && (
                  <Button type="primary" onClick={this.handleSendToAgreeing}>
                    На утверждение
                  </Button>
                )}
                {canApprove && (
                  <Button type="primary" onClick={this.handleApprove}>
                    Утвердить
                  </Button>
                )}
              </Buttons>
            </SectionContent>
          </Section>
        )}
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access,
}))(ContractVehicleDailyBudgetTable);
