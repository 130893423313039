// @flow

import React, {Component} from 'react';
import notification from 'antd/lib/notification';

import styled from 'styled-components';

import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import type {OrgUnitNode, UserAccess} from '../../lib/types';
import {Panel} from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import InnerForm from './components/InnerForm';
import {notificationLoading} from '../../components/Notifications';
import {orgUnitApi} from '../../lib/api';
import {withUserAccess} from '../withUserAccess';
import {accessTypeEnum} from '../../lib/enum';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  orgUnitId: number,
  parentOrgUnitId?: number,
  userAccess: UserAccess[],
};

type State = {
  orgUnit: OrgUnitNode,
  isAdmin: boolean,
}

class OrgUnitForm extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      orgUnit: null,
      isAdmin: this.props.userAccess.includes(accessTypeEnum.admin),
    }
  }

  async componentDidMount() {
    const {orgUnitId, parentOrgUnitId} = this.props;
    let orgUnit;
    try {
      if (parentOrgUnitId) {
        const parent = await orgUnitApi.getById(parentOrgUnitId);
        orgUnit = {parentId: parent.id}
      } else {
        orgUnit = await orgUnitApi.getById(orgUnitId);
      }
      this.setState({orgUnit});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message
      })
    }
  }

  render() {
    const {orgUnit, isAdmin} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/org-units')}>
                Подразделения
              </Crumb>
              {orgUnit && (
                <Crumb to={`/admin/org-units/${orgUnit.id}`}>
                  Подразделение №{orgUnit.id}
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {orgUnit?.id ? `Подразделение №${orgUnit.id}` : 'Новое подразделение'}
          </h1>
        </StyledPanel>
        <InnerForm
          orgUnit={orgUnit}
          onCancel={() => navigate('/admin/org-units', true)}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await orgUnitApi.updateOrgUnit(values);
              } else {
                await orgUnitApi.addOrgUnit(values);
              }
              notification.success({
                message: 'Успешно сохранено',
                description: 'Изменения успешно сохранены'
              });
              navigate('/admin/org-units', true);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
          isAdmin={isAdmin}
        />
      </>
    );
  }
}

export default withUserAccess(OrgUnitForm);
