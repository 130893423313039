// @flow

import React from 'react';
import styled from 'styled-components';
import Select, { SelectProps } from 'antd/lib/select';

const StyledSelect = styled(Select)`
  .ant-select-search--inline .ant-select-search__field {
    padding-left: 0px;
  }
`;

export const placeholderCustom = `.ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
  margin: 0px 10px 0px 8px;
  font-size: 13px;
}`;

export const searchInputCustom = `
.ant-select-search--inline .ant-select-search__field {
  padding: 0px 22px 0px 8px;
}
`;

export const CustomStyledSelect = styled(StyledSelect)`
  ${placeholderCustom}
  .ant-select-selection {
    font-size: 13px;
  }
`;

export default class CustomSelect extends Select<SelectProps, {}> {
  render() {
    const { size } = this.props;
    return size === 'small' ? (
      <CustomStyledSelect
        dropdownStyle={{ minWidth: '150px' }}
        {...this.props}
      />
    ) : (
      <StyledSelect
        style={{ paddingLeft: '0px' }}
        dropdownStyle={{ minWidth: '150px' }}
        {...this.props}
      />
    );
  }
}
