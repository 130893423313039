// @flow

import React from 'react';

import Button from 'antd/lib/button';
import { companyApi } from '../../lib/api';
import { navigate } from '../../lib/helpers';

import Page from './../../components/hoc/common/handbook/PageContext';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Filter from './components/Filter';
import {withUserAccess} from './../withUserAccess';

const entryPointPath = '/admin/companies/';

const COLUMNS = [
  {
    title: '№',
    dataIndex: 'id',
    sorter: true
  },
  {
    title: 'Наименование',
    dataIndex: 'name',
    sorter: true
  },
  {
    title: 'Адрес',
    dataIndex: 'address',
    sorter: true
  }
];



export default withUserAccess(({ userAccess }) => {
  
  return (
    <Page>
    <CommonListPage
      crud={companyApi}
      pageHeaderProps={{
        mainHeader: 'Контрагенты',
        access: {
          add: () => navigate(`${entryPointPath}new`)
        }
      }}
      tableListProps={{
        columns: COLUMNS,
        canDelete: true,
        onSelect: (id: number) => navigate(`/admin/companies/${id}`)
      }}
      filter={Filter}
    />
  </Page>
  );
});

