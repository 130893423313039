// @flow

import React, {Component, Fragment} from 'react';
import {type FormikProps} from 'formik';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {connect} from 'react-redux';

import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import Input from 'antd/lib/input';
import {RequiredFieldMessage} from '../../../components/Form';

import {addRepairOrder, clear, fetchRepairOrder, updateRepairOrder} from './../../../ducks/repairOrder';
import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import type {Contractor, FormErrors, RepairOrder, RepairOrderVehicle} from '../../../lib/types';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Form, Selects} from './../../../components';
import RepairOrderVehicleForm from './RepairOrderVehicleForm';
import {notificationLoading} from './../../../components/Notifications';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const { ContractorSelect } = Selects;

type Props = {
  repairOrder: RepairOrder,
  updateRepairOrder: Function,
  addRepairOrder: Function,
  repairOrderId: number,
  fetchRepairOrder: Function,
  clear: Function
};

class RepairOrderForm extends Component<Props> {
  componentDidMount() {
    try {
      const { repairOrderId } = this.props;
      if (repairOrderId > 0) {
        this.props.fetchRepairOrder(repairOrderId);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка загрузки заявки',
        description: err.message
      });
      navigate('/services/', true);
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const { repairOrder } = this.props;
    return (
      <Fragment>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/services')}>
                Обслуживание
              </Crumb>
              {repairOrder ? (
                <Fragment>
                  <Crumb to={`/services/repair-order/${repairOrder.id}`}>
                    Заявка на ремонт №{repairOrder.id}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </Fragment>
              ) : (
                <Crumb to="/services/repair-order/new">
                  Новая заявка на ремонт
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {repairOrder
              ? `Заявка на ремонт №${repairOrder.id}`
              : 'Новая заявка на ремонт'}
          </h1>
        </StyledPanel>
        <Form
          initialValues={repairOrder}
          enableReinitialize
          validate={(values: RepairOrder) => {
            let errors: FormErrors<RepairOrder> = {};
            if (!values.vehicles || values.vehicles.length === 0) {
              errors.vehicles = 'Необходимо добавить хотя бы одно ТС';
            }
            if (!values.contractorId) {
              errors.contractorId = new RequiredFieldMessage(
                'Обязательное поле'
              );
            }
            if (!values.contractNumber) {
              errors.contractNumber = new RequiredFieldMessage(
                'Обязательное поле'
              );
            }
            return errors;
          }}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              if (values.id) {
                await this.props.updateRepairOrder(values);
                navigate(`/service-orders/${values.id}`);
              } else {
                await this.props.addRepairOrder(values);
                navigate('/services', true);
              }
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, isSubmitting } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Content>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Подрядчик"
                          hasFeedback
                          required
                          fast
                          name="contractorId"
                        >
                          {({ value, name }) => (
                            <ContractorSelect
                              value={value}
                              onChange={(contractorId: number) =>
                                setFieldValue(name, contractorId)
                              }
                              renderOption={(
                                contractor: Contractor,
                                Option
                              ) => (
                                <Option
                                  key={contractor.id}
                                  value={contractor.id}
                                >
                                  {contractor.company
                                    ? contractor.company.name
                                    : contractor.name}
                                </Option>
                              )}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Номер договора"
                          hasFeedback
                          required
                          fast
                          name="contractNumber"
                        >
                          {fieldProps => <Input {...fieldProps} />}
                        </FormField>
                      </GridItem>
                      <GridItem fullWidth>
                        <FormField
                          fast
                          name="vehicles"
                          hasFeedback={false}
                          help={false}
                          required={false}
                          validate={false}
                        >
                          {props => {
                            const { value, name } = props;
                            return (
                              <RepairOrderVehicleForm
                                vehicles={orderBy(value, 'vehicleId')}
                                onChange={(vehicles: RepairOrderVehicle[]) =>
                                  setFieldValue(name, vehicles)
                                }
                              />
                            );
                          }}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                </Footer>
              </form>
            );
          }}
        </Form>
      </Fragment>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    repairOrder: state.repairOrder,
    repairOrderId: parseInt(ownProps.repairOrderId, 10)
  }),
  {
    addRepairOrder,
    fetchRepairOrder,
    updateRepairOrder,
    clear
  }
)(RepairOrderForm);
