// @flow
import React, { useState, useEffect } from 'react';
import Button from 'antd/lib/button';

import {
  ModalImage,
  StyledModal,
  ModalPrevArrow,
  ModalNextArrow,
  ImageButtons
} from './Slider.elements';

/**
 * Элемент данных слайдера
 */
export type SliderItem = {
  // Наименование файла
  title: string,
  // Ссылка на картинку
  imageUrl: ?string,
  url: string,
  contentType: string,
  // Вызывается при нажатии на картинку
  onSlideClick?: Function,
  addSlidesWidth?: Function
};

type SliderProps = {
  // Элементы слайдера
  items: SliderItem[],
  // Количество отображаемых слайдов на одной странице
  slidesToShow?: number,
  show: boolean,
  handleModalVisible: ?Function
};

/**
 * Компонент слайдера картинок,
 * с возможностью открытия картинок в модальном окне
 */
const Slider = ({
  items,
  handleModalVisible,
  show = false,
  ...sliderProps
}: SliderProps) => {
  const [modalVisible, setModalVisible] = useState(show);
  const [currentSlide, setCurrentSlide] = useState(0);

  /**
   * Переход к следующему слайду
   */
  const toNextSlide = () => {
    if (currentSlide !== items.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  /**
   * Переход к предыдущему слайду
   */
  const toPrevSlide = () => {
    if (currentSlide !== 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  // Подписываемся на нажатие клавиш вправо/влево
  useEffect(() => {
    const eventListener = ({ keyCode }) => {
      if (modalVisible) {
        // Стрелка влево
        if (keyCode === 37) {
          toPrevSlide();
          // Стрелка вправо
        } else if (keyCode === 39) {
          toNextSlide();
        }
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => window.removeEventListener('keydown', eventListener);
  });

  const slide = items[currentSlide];

  return (
    <>
      {slide && (
        <StyledModal
          onCancel={() => {
            handleModalVisible && handleModalVisible(false);
            setModalVisible(false);
          }}
          title={slide.title}
          footer={null}
          width="50%"
          visible={modalVisible}
        >
          <ModalPrevArrow onClick={toPrevSlide} visible={currentSlide !== 0} />
          <ModalNextArrow
            onClick={toNextSlide}
            visible={currentSlide !== items.length - 1}
          />
          {slide.imageUrl ? (
            <>
              <ModalImage
                loading="lazy"
                src={slide.imageUrl}
                alt={slide.title}
              />
              <ImageButtons>
                <Button
                  type="primary"
                  href={`${slide.imageUrl}?isInline=false`}
                  target="_blank"
                >
                  Скачать
                </Button>
                <Button
                  type="primary"
                  href={`${slide.imageUrl}?isInline`}
                  target="_blank"
                >
                  Открыть в новой вкладке
                </Button>
              </ImageButtons>
            </>
          ) : (
            <p>
              Предпросмотр недоступен. Вы можете &nbsp;
              <a
                href={`${slide.url}?isInline=false`}
                // eslint-disable-next-line
                target="_blank"
              >
                скачать
              </a>
              &nbsp; файл
            </p>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default Slider;
