// @flow
import type {
  CalculationStatus,
  InspectionGtnCalculation,
  InspectionGtnCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  inspectionGtnCalculation: InspectionGtnCalculation
): InspectionGtnCalculation => ({
  ...inspectionGtnCalculation,
  sum: convertFromRubToKop(inspectionGtnCalculation.sum),
  taxSum: convertFromRubToKop(inspectionGtnCalculation.taxSum),
  workSum: convertFromRubToKop(inspectionGtnCalculation.workSum),
  months: inspectionGtnCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  inspectionGtnCalculation: InspectionGtnCalculation
): InspectionGtnCalculation => ({
  ...inspectionGtnCalculation,
  sum: convertFromKopToRub(inspectionGtnCalculation.sum),
  taxSum: convertFromKopToRub(inspectionGtnCalculation.taxSum),
  workSum: convertFromKopToRub(inspectionGtnCalculation.workSum),
  months: inspectionGtnCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  inspectionGtnCalculationMonth: InspectionGtnCalculationMonth
): InspectionGtnCalculationMonth => ({
  ...inspectionGtnCalculationMonth,
  cost: convertFromRubToKop(inspectionGtnCalculationMonth.cost),
  workPrice: convertFromRubToKop(inspectionGtnCalculationMonth.workPrice),
  taxPrice: convertFromRubToKop(inspectionGtnCalculationMonth.taxPrice),
  workCost: convertFromRubToKop(inspectionGtnCalculationMonth.workCost),
  taxCost: convertFromRubToKop(inspectionGtnCalculationMonth.taxCost)
});

export const convertDataFromServerMonth = (
  inspectionGtnCalculationMonth: InspectionGtnCalculationMonth
): InspectionGtnCalculationMonth => ({
  ...inspectionGtnCalculationMonth,
  cost: convertFromKopToRub(inspectionGtnCalculationMonth.cost),
  workPrice: convertFromKopToRub(inspectionGtnCalculationMonth.workPrice),
  taxPrice: convertFromKopToRub(inspectionGtnCalculationMonth.taxPrice),
  workCost: convertFromKopToRub(inspectionGtnCalculationMonth.workCost),
  taxCost: convertFromKopToRub(inspectionGtnCalculationMonth.taxCost)
});

const basedUrl = 'inspectionGtnCalculation';
const inspectionGtnCalculation = new CommonApi<InspectionGtnCalculation>(
  basedUrl,
  'расчет потребности в ТО ГТН',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async monthData => {
  const updated = await fetchRequest.put(
    `/InspectionGtnCalculation/correct`,
    convertDataToServerMonth(monthData)
  );
  if (!updated) {
    throw new Error(`Не удалось сменить статус расчет`);
  }
};

export default {
  ...inspectionGtnCalculation,
  calculate,
  changeStatus,
  print,
  updateMonth
};
