// @flow

import React, {Component} from 'react';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import {regulationApi} from '../../../../../lib/api';
import {printNotification} from '../../../../../lib/notificationWrapper';
import type {EntityStatusType, Regulation, UserAccess} from '../../../../../lib/types';
import {entityStatus, entityStatusEnum} from '../../../../../lib/enum';
import {getPathWithHistoryParams} from '../../../../../lib/helpers';

import {Grid, Header, Section, TopPanel} from './../../../../../components/layout';
import {OrgUnitSelect} from './../../../../../components';
import {GridItem} from './../../../../../components/layout/Grid';
import {ButtonsRow, Icon} from '../../../../../components/ui';
import {Selects} from '../../../../../components';
import Breadcrumbs, {Crumb} from './../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../components/card/Field';

import {notificationLoading} from '../../../../../components/Notifications';
import {withUserAccess} from '../../../../withUserAccess';
import {canHandling} from '../lib';

import {handlingAccessRightWorkPlans} from './../accessRight';

import TimeLimit from './TimeLimit/TimeLimits';
import Input from 'antd/lib/input';

type Props = {
  userAccess: UserAccess[],
  regulationId: number,
  readonly: boolean
};

type State = {
  workPlans: Regulation,
  readonly: boolean
};

const { BudgetVersionSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;
  & img {
    width: 100%;
  }
`;

class Form extends Component<Props, State> {
  state = {
    workPlans: {
      id: this.props.regulationId,
      orgUnitId: null,
      orgUnitName: null,
      status: entityStatusEnum.created,
      budgetVersionId: null,
      budgetVersion: { name: '' }
    },
    readonly: this.props.readonly
  };

  componentDidMount = () => {
    if (this.props.regulationId) {
      this.fetchData();
    }
  };

  fetchData = async () => {
    try {
      if (this.state.workPlans.id) {
        const data = await regulationApi.get(this.state.workPlans.id);
        this.setState({ workPlans: data });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  changeStatus = async (newStatus: EntityStatusType) => {
    const data = await regulationApi.changeStatus.update({
      ...this.state.workPlans,
      status: newStatus
    });
    this.setState({ workPlans: data });

    this.updateRegulation(data);
  };

  setEdit = async () => {
    this.state.workPlans.status === entityStatusEnum.approved &&
      this.changeStatus(entityStatusEnum.created);
    this.setState({ readonly: false });
  };

  createRegulation = async () => {
    try {
      notificationLoading({
        message: 'Создание регламента...',
        key: 'creating'
      });
      let data = await regulationApi.add({
        orgUnitId: this.state.workPlans.orgUnitId,
        budgetVersionId: this.state.workPlans.budgetVersionId,
        notation: this.state.workPlans.notation,
        status: entityStatusEnum.created,
      });
      this.setState({ workPlans: data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('creating');
    }
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      handlingAccessRightWorkPlans.includes(access)
    );

  handlePrint = () => {
    printNotification(
      async () => await regulationApi.print(this.state.workPlans.id)
    );
  };

  // редактируем regulation для отображении записи с системном журнале
  updateRegulation = (data?: any) => {
    regulationApi.update(data || this.state.workPlans);
  };

  render() {
    const { workPlans, readonly } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams(
                  '/budget/contract-vehicle/work-plans'
                )}
              >
                Регламенты НТС
              </Crumb>
              <Crumb>Регламент</Crumb>
            </Breadcrumbs>
          }
          right={
            <ButtonsRow>
              {workPlans.status === entityStatusEnum.approved && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          }
        />
        <TopPanel>
          <h1>
            Регламент работы НТС
            {readonly &&
              this.canEdit() &&
              canHandling(this.props.userAccess) && (
                <OperationIcon onClick={this.setEdit} type="edit" size={16} />
              )}
          </h1>
        </TopPanel>

        <Section>
          <Content>
            <Grid gutter="16px" cols={!!workPlans?.id ? 3 : 2}>
              {!workPlans?.id && (
                <>
                  <GridItem>
                    <Field label="Подразделение" mBottomNone>
                      <OrgUnitSelect
                        value={workPlans?.orgUnitId}
                        onlyBranches
                        onChange={orgUnitId =>
                          this.setState(prevState => {
                            return {
                              workPlans: { ...prevState.workPlans, orgUnitId }
                            };
                          })
                        }
                      />
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Версия" mBottomNone>
                      <BudgetVersionSelect
                        value={workPlans?.budgetVersionId}
                        onChange={budgetVersionId =>
                          this.setState(prevState => {
                            return {
                              workPlans: {
                                ...prevState.workPlans,
                                budgetVersionId
                              }
                            };
                          })
                        }
                      />
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Примечание" mBottomNone>
                      <Input
                        value={workPlans?.notation}
                        onChange={({ target }) =>
                          this.setState(prevState => {
                            return {
                              workPlans: {
                                ...prevState.workPlans,
                                notation: target.value
                              }
                            };
                          })
                        }
                      />
                    </Field>
                  </GridItem>
                  <GridItem
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'right'
                    }}
                  >
                    <Button
                      type="primary"
                      disabled={!workPlans.orgUnitId || !workPlans?.budgetVersionId}
                      onClick={this.createRegulation}
                    >
                      Создать
                    </Button>
                  </GridItem>
                </>
              )}
              {!!workPlans?.id && (
                <>
                  <GridItem>
                    <Field label="Подразделение" mBottomNone>
                      {workPlans?.orgUnitName ?? ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Версия" mBottomNone>
                      {workPlans?.budgetVersion?.name ?? ''}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Статус" mBottomNone>
                      {entityStatus[workPlans.status]}
                    </Field>
                  </GridItem>
                </>
              )}
            </Grid>
          </Content>
        </Section>

        {!!workPlans?.id && (
          <TimeLimit
            {...workPlans}
            readonly={readonly}
            changeStatus={this.changeStatus}
            updateRegulation={this.updateRegulation}
          />
        )}
      </>
    );
  }
}

export default withUserAccess(Form);
