// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';

import {formatDateTimeToISOString} from '../../../lib/helpers';
import {serviceTypeEnum} from '../../../lib/enum';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from '../../../components';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import Field from '../../../components/card/Field';
import Input from '../../../components/ui/CustomInput';

const { ContractorSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type DriverInspectionFilterParams = {
  actDate?: string,
  contractorId?: number,
  actNumber?: string
};

type Props = {
  filter: DriverInspectionFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const DriverInspectionFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<DriverInspectionFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Номер акта"
                  size="small"
                  onChange={({ target: { value } }) =>
                    changeValue('actNumber', !!value ? value : undefined)
                  }
                  value={values.actNumber}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value => changeValue('act.stage.contract.contractorId', value)}
                  value={values['act.stage.contract.contractorId']}
                  filter={{
                    services: serviceTypeEnum.driversCheck
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={values.actDate && moment.utc(values.actDate)}
                  placeholder="Дата окончания"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'actDate',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default DriverInspectionFilter;
