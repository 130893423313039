// @flow

import React from 'react';
import styled from 'styled-components';
import {monthsEnum, newContractTripStatusEnum, newContractTripStatuses} from '../../../../lib/enum';
import {Card, FilterButtonsContainer, OrgUnitSelect} from '../../../../components';
import Select from '../../../../components/ui/Select';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import {Filter} from '../../../../components/hoc';
import FilterContainer from '../../../../components/hoc/FilterContainer';
import FilterItem from '../../../../components/hoc/FilterItem';
import {MonthSelect} from '../../../../components/selects/AutoGeneratedEnumSelects';
import range from 'lodash/range';

const {Option} = Select;
const {Field} = Card;

const Content = styled.div`
  padding: 16px;
`;

type ContractTripMonthFilterParams = {
  status?: $Keys<newContractTripStatusEnum>,
  month?: $Keys<monthsEnum>,
  year?: number,
  orgUnitId?: number,
};

type Props = {
  filter: ContractTripMonthFilterParams,
  applyFilter: Function,
  cleanFilter: Function,
};

const years = range(2018, new Date().getFullYear() + 5);

const ContractTripMonthFilter = ({
  filter,
  applyFilter,
  cleanFilter,
}: Props) => (
  <Content>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional,
        }: FilterChildrenParams<ContractTripMonthFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  allowClear
                  placeholder="Выберите статус"
                  value={values.status}
                  size="small"
                  onChange={(value: string) => changeValue('status', value)}
                >
                  {Object.keys(newContractTripStatusEnum).map((key: string) => (
                    <Option key={key} value={key}>
                      {newContractTripStatuses[key]}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <MonthSelect
                  size="small"
                  onChange={(value) => changeValue('month', value)}
                  value={values.month}
                  allowClear
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  allowClear
                  placeholder="Выберите год"
                  value={values.year}
                  size="small"
                  onChange={(value: string) => changeValue('year', value)}
                >
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  allowClear
                  onlyBranches
                  placeholder="Выберите филиал"
                  value={values.nodeId}
                  size="small"
                  onChange={(value: string) => changeValue('nodeId', value)}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default ContractTripMonthFilter;
