// @flow
import type {
  MaintenanceOperation,
  MaintenancePartAndConsumables
} from './../../../lib/types';
import { plus, multipliedBy } from './../../../lib/helpers';

export const sumOperations = (
  operations: MaintenanceOperation[],
  isContractor: boolean
) => {
  let sum = 0;
  let sumContractor = 0;
  operations.forEach((operation: MaintenanceOperation) => {
    sum = plus(
      sum,
      multipliedBy(
        operation.count,
        multipliedBy(operation.price, operation.hours)
      )
    );
    if (isContractor) {
      sumContractor = plus(
        sumContractor,
        multipliedBy(operation.count, operation.contractorPrice)
      );
    }
  });
  return { sum, sumContractor };
};

export const sumPartsAndConsumbles = (
  parts: MaintenancePartAndConsumables[],
  isContractor: boolean
) => {
  let sum = 0;
  let sumContractor = 0;
  parts.forEach((part: MaintenancePartAndConsumables) => {
    if (isContractor) {
      const mtrCurrentCost = (part && part.mtr && part.mtr.currentCost) || 0;
      sum = plus(sum, multipliedBy(part.count, mtrCurrentCost));
      sumContractor = plus(
        sumContractor,
        multipliedBy(part.count, part.contractorCost)
      );
    } else {
      const stockPartCost = (part.stockPart && part.stockPart.cost) || 0;
      sum = plus(sum, multipliedBy(part.count, stockPartCost));
    }
  });
  return { sum, sumContractor };
};
