// @flow

import React from 'react';

import notification from 'antd/lib/notification';

import { expertiseServiceGpmApi } from '../../../../../lib/api';
import type { ExpertiseServiceGpm } from '../../../../../lib/types';

import Filter from '../../../../services/expertisesServicesGpm/Filter';
import { EXPERTISE_SERVICE_GPM_COLUMNS } from '../../../../services/expertisesServicesGpm';

import { ListTable } from '../../../lib';

export default class extends ListTable<ExpertiseServiceGpm> {
  fetch = async (page: number = 1) => {
    const params = super.fetch(page);
    try {
      const data = await expertiseServiceGpmApi.fetchExpertiseServiceGpmList(
        params
      );
      const totalSum = await expertiseServiceGpmApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(EXPERTISE_SERVICE_GPM_COLUMNS)}
      </>
    );
  }
}
