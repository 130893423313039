/* @flow */

import React from 'react';
import { Redirect } from '@reach/router';
import { type Persistor } from 'redux-persist';

export default class Logout extends React.Component<
  { persistor: Persistor },
  {}
> {
  componentDidMount() {
    localStorage.clear();
    this.props.persistor.purge();
  }
  render() {
    return <Redirect to="/login" noThrow />;
  }
}
