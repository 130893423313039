// @flow
import React, { Component } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import InputNumber from 'antd/lib/input-number';
import type { moment } from 'moment';

import type { AttachedEquipment } from './../../../../lib/types';
import { Card } from '../../../../components';
import { attachedEquipmentTypes } from './../../../../lib/enum';
import RemoveEquipmentButton from './../RemoveEquipmentButton';
import { formatDate } from './../../lib';
import { Spoiler, Icon } from './../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { EquipmentActions } from '../elements';

const { Field } = Card;
const ButtonGroup = Button.Group;

const SpoilerContent = styled.div`
  padding: 16px;
  border-bottom: 1px solid #c0ccd7;
`;

type Props = {
  onChange: (attachedEquipment: AttachedEquipment) => void,
  onRemove: (attachedEquipment: AttachedEquipment) => void,
  attachedEquipment: AttachedEquipment,
  editable: boolean
};

type State = {
  editMode: boolean,
  attachedEquipment: AttachedEquipment
};

class EditableItem extends Component<Props, State> {
  state = {
    editMode: false,
    attachedEquipment: this.props.attachedEquipment
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.attachedEquipment, this.props.attachedEquipment)) {
      this.setState({
        attachedEquipment: this.props.attachedEquipment
      });
    }
  }

  onApply = async () => {
    await this.props.onChange(this.state.attachedEquipment);
    this.setState({
      editMode: false
    });
  };

  changeAttachedEquipment = (fieldName: string, value: any) => {
    this.setState(prevState => ({
      attachedEquipment: {
        ...prevState.attachedEquipment,
        [fieldName]: value
      }
    }));
  };

  onCancel = () =>
    this.setState({
      attachedEquipment: this.props.attachedEquipment,
      editMode: false
    });

  render() {
    const { onRemove, editable } = this.props;
    const { attachedEquipment = {}, editMode } = this.state;
    const {
      name,
      brandName,
      code,
      type,
      installDate,
      removeDate,
      inventoryNumber,
      fuelConsumption,
      machineHours,
      specifications
    } = attachedEquipment;
    return (
      <Spoiler
        key={attachedEquipment.id}
        label={`${name} ${brandName}`}
        suffix={
          editable && (
            <EquipmentActions>
              <ButtonGroup>
                {editMode ? (
                  <>
                    <Button
                      data-test="check"
                      onClick={this.onApply}
                      icon="check"
                    />
                    <Button data-test="close" onClick={this.onCancel}>
                      <Icon type="x" />
                    </Button>
                  </>
                ) : (
                  <Button
                    data-test="edit"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <Icon type="edit" />
                  </Button>
                )}
              </ButtonGroup>
              <RemoveEquipmentButton
                onConfirm={(removeDate: string | moment) =>
                  onRemove({
                    ...this.props.attachedEquipment,
                    removeDate
                  })
                }
              />
            </EquipmentActions>
          )
        }
      >
        <SpoilerContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Наименование">{name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Тип">{attachedEquipmentTypes[type]}</Field>
            </GridItem>
            <GridItem>
              <Field label="Марка">{brandName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Мнемокод">{code}</Field>
            </GridItem>
            {specifications && (
              <GridItem>
                <Field label="Характеристики">{specifications}</Field>
              </GridItem>
            )}
            {inventoryNumber && (
              <GridItem>
                <Field label="Инвентарный номер">{inventoryNumber}</Field>
              </GridItem>
            )}
            <GridItem>
              <Field label="Количество отработанных часов">
                {machineHours} ч.
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Расход топлива">
                {editMode ? (
                  <InputNumber
                    min={0}
                    step={0.1}
                    value={fuelConsumption}
                    name={name}
                    onChange={(value: number) =>
                      this.changeAttachedEquipment('fuelConsumption', value)
                    }
                  />
                ) : (
                  fuelConsumption
                )}{' '}
                л/маш. час
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Дата установки">{formatDate(installDate)}</Field>
            </GridItem>
            <GridItem>
              <Field label="Дата снятия">{formatDate(removeDate)}</Field>
            </GridItem>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}

export default EditableItem;
