// @flow
import React from 'react';
import Button from 'antd/lib/button';
import {ModelMatchesApi} from '../../lib/api';
import {getListInitialState, navigate} from '../../lib/helpers';
import type {ListState, ModelMatches} from '../../lib/types';
import Table from '../../components/ui/Table';
import {Header, Section} from '../../components/layout';

type State = ListState<ModelMatches>;

export class List extends React.Component<State> {
  state = {
    ...getListInitialState(),
  };
  
  columns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
    },
    {
      title: 'Модель',
      dataIndex: 'modelName',
    },
  ];
  
  componentDidMount = async () => {
    await this.fetch(this.state.page);
  };
  
  fetch = async (page: number) => {
    const {pageSize} = this.state;
    this.setState({loading: true});
    const data = await ModelMatchesApi.fetch({page, pageSize});
    this.setState({...data, loading: false, page});
  };
  
  render() {
    const {data, totalCount, pageSize, page, loading} = this.state;
    return (
      <>
        <Header
          left={<h1>Модели для сопоставления</h1>}
          right={
            <Button
              type="primary"
              onClick={() => navigate('/admin/model-matches/new')}
            >
              Создать
            </Button>
          }
        />
        <Section>
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetch}
            onRow={(row: ModelMatches) => ({onClick: () => navigate(`/admin/model-matches/${row.id}`)})}
            loading={loading}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
          />
        </Section>
      </>
    );
  }
}

export default List;
