// @flow
import React from 'react';
import { Link } from '@reach/router';
import qs from 'query-string';

import { Header, Section } from '../../components/layout';
import Table from '../../components/ui/Table';
import type { MdmNode } from '../../lib/types/mdm';
import { Icon, Operations } from '../../components/ui';
import Filter from './components/Filter';
import { getListInitialState, setQueryParams } from '../../lib/helpers';
import type { ListState } from '../../lib/types';
import type { FetchListParams } from '../../lib/api';
import { mdmApi } from '../../lib/api';
import type { MdmFilterParams } from './components/Filter';

type Props = {
  location: Location & { state: { page: number } }
};

type State = ListState<MdmNode> & {
  filter: MdmFilterParams,
  shouldLoadChilds: {
    [key: string]: boolean
  }
};

export class MdmList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    shouldLoadChilds: {},
    filter: {}
  };

  changeExpanding = (path: string, value: boolean) => {
    this.setState(prevState => ({
      shouldLoadChilds: {
        ...prevState.shouldLoadChilds,
        [path]: value
      }
    }));
  };

  // @deprecated Сейчас присылается просто список, не дерево
  // handleRowExpand = async (shouldLoadChilds: any, node: MdmNode) => {
  //   /**
  //    * Проверяем подгружались ли данные для этого узла
  //    * для предотвращения избыточной загрузки
  //    * потомков при раскрытии и скрытии узла
  //    */
  //   let shouldLoad = this.state.shouldLoadChilds[node.path];
  //   // Если нет
  //   if (shouldLoad === undefined) {
  //     // То разрешаем подгрузить данные для узла
  //     this.changeExpanding(node.path, true);
  //     shouldLoad = true;
  //   }
  //   // Проверяем следует ли подгрузить данные (в первый раз)
  //   if (shouldLoad) {
  //     // подгружаем потомков
  //     await this.props.fetchMdmChilds(node);
  //     // Говорим, что больше не нужно подгружать потомков
  //     this.changeExpanding(node.path, false);
  //   }
  // };

  columns = [
    { title: 'Наименование', dataIndex: 'name' },
    { title: 'Текущая цена', dataIndex: 'currentCost' },
    { title: 'Мнемокод', dataIndex: 'mnemocode' },
    { title: 'GUID', dataIndex: 'id' },
    {
      title: 'Архив',
      dataIndex: 'isArchive',
      render: isArchive => (isArchive ? 'Да' : 'Нет')
    },
    {
      dataIndex: 'guid',
      width: 30,
      render: (text: string, record: MdmNode) => (
        <Operations>
          <Link to={`/admin/mdm/${record.id}`}>
            <Icon type="edit" size={16} />
          </Link>
        </Operations>
      )
    }
  ];

  fetchMdm = async (
    page: number = 1,
    params: FetchListParams<MdmFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await mdmApi.fetchMdmList({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchMdm);

  applyFilter = (filter: MdmFilterParams) =>
    this.setState({ filter }, this.fetchMdm);

  async componentDidMount() {
    const { filter: stateFilter } = this.state;
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter: { ...stateFilter, ...filter } }, () =>
      this.fetchMdm(page)
    );
  }

  render() {
    const { location } = this.props;
    const { data, page, pageSize, totalCount, filter } = this.state;
    return (
      <>
        <Header left={<h1>МТРиО</h1>} />
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            columns={this.columns}
            data={data}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            rowKey="id"
            fetch={this.fetchMdm}
            fetchOnMount
          />
        </Section>
      </>
    );
  }
}

export default MdmList;
