// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Datepicker from 'antd/lib/date-picker';

import Input from 'antd/lib/input/Input';
import {Spoiler} from './../../../components/ui';
import Select from './../../../components/ui/Select';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Card, Filter, Selects} from './../../../components';
import type {NodeFilterType, Vehicle} from '../../../lib/types';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import {applyMaskToValue} from '../../../lib/helpers';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';
import {ownerTypes} from '../../../lib/enum';
import {DecomissedStatuses} from '../../../lib/api/tire';
import moment from 'moment';

const { BrandsSelect, VehicleSelect } = Selects;
const { Field } = Card;
const { Option } = Select;
const { RangePicker } = Datepicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

const Buttons = styled(GridItem)`
  margin: 0 -10px;

  & .ant-btn {
    margin: 0 10px;
  }
`;

export type TireFilterParams = {
  code?: string,
  'brand.id'?: number,
  vehicleId?: number,
  isFree?: boolean,
  nodeId?: number,
  nodeFilterType?: NodeFilterType,
  factoryNumber?: string,
  decomissionStatus?: string,
  installDateStart: string,
  installDateEnd: string,
  removeDateStart: string,
  removeDateEnd: string,
  wasInstalledOnVehicleId? : number
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: TireFilterParams
};

const TireFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<TireFilterParams>) => (
            <>
              <GridItem>
                <Field label="Марка">
                  <BrandsSelect
                    onChange={value => changeValue('brand.id', value)}
                    value={values['brand.id']}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Гос. номер">
                  <VehicleSelect
                    placeholder="Все ТС"
                    value={parseInt(values.vehicleId, 10) || undefined}
                    optionTitle={(vehicle: Vehicle) => applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask)}
                    filter={{ownerType: ownerTypes.self}}
                    onChange={value => changeValue('vehicleId', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод">
                  <Input
                    value={values.code}
                    onChange={e => changeValue('code', e.target.value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Статус шин">
                  <Select
                    allowClear
                    placeholder="Выберите статус шин"
                    onChange={value => changeValue('decomissionStatus', value)}
                    value={values.decomissionStatus}
                  >
                    <Option value={DecomissedStatuses.decomissed}>
                      Списанные
                    </Option>
                    <Option value={DecomissedStatuses.notDecomissed}>
                      Несписанные
                    </Option>
                    <Option value={null}>Все</Option>
                  </Select>
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата установки">
                  <RangePicker
                    format="DD.MM.YYYY"
                    placeholder={['Начало', 'Конец']}
                    value={[
                      values.installDateStart
                        ? moment.utc(values.installDateStart)
                        : null,
                      values.installDateEnd
                        ? moment.utc(values.installDateEnd)
                        : null
                    ]}
                    onChange={value => {
                      if (value) {
                        const [startDate, endDate] = value;
                        changeValue('installDateStart', startDate ? startDate.format() : undefined);
                        changeValue('installDateEnd', endDate? endDate.format() : undefined);
                      }
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Гос. номер (при снятии)">
                  <VehicleSelect
                    placeholder="Все ТС"
                    value={values.wasInstalledOnVehicleId}
                    optionTitle={(vehicle: Vehicle) =>
                      applyMaskToValue(
                        vehicle.licensePlate,
                        formatLicensePlateMask
                      )
                    }
                    filter={{
                      ownerType: ownerTypes.self
                    }}
                    onChange={value => changeValue('wasInstalledOnVehicleId', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата снятия">
                  <RangePicker
                    format="DD.MM.YYYY"
                    placeholder={['Начало', 'Конец']}
                    value={[
                      values.removeDateStart
                        ? moment.utc(values.removeDateStart)
                        : null,
                      values.removeDateEnd
                        ? moment.utc(values.removeDateEnd)
                        : null
                    ]}
                    onChange={value => {
                      if (value) {
                        const [startDate, endDate] = value;
                        changeValue('removeDateStart', startDate ? startDate.format() : undefined);
                        changeValue('removeDateEnd', endDate? endDate.format() : undefined);
                      }
                    }}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default TireFilter;
