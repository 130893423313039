// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import moment from 'moment';

import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import type {ExpertiseServiceGpm} from './../../../lib/types';
import {Form, Selects} from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import {expertiseServiceGpmApi} from './../../../lib/api';
import {serviceTypeEnum} from './../../../lib/enum';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect, ContractServiceSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  expertiseServiceGpm: ExpertiseServiceGpm,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  expertiseServiceGpm,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={expertiseServiceGpm} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const { handleSubmit, handleBlur, setFieldValue, dirty } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField fast label="Вид услуг" required name="services">
                    {({ value, name }) => (
                      <ContractServiceSelect
                        name={name}
                        value={value}
                        placeholder="Выберите вид услуги"
                        filter={{
                          having: [
                            serviceTypeEnum.expertise,
                            serviceTypeEnum.maintenance
                          ]
                        }}
                        onChange={value => {
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>

                <GridItem>
                  <FormField
                    fast
                    label="Дата экспертизы/ТО"
                    required
                    name="maintenanceDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Следующая дата экспертизы/ТО"
                    required
                    name="nextActDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  id: ?number,
  vehicleId: ?number
};

type State = {
  expertiseServiceGpm: $Shape<ExpertiseServiceGpm>
};

class ExpertiseServiceGpmForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const expertiseServiceGpmId = parseInt(this.props.id, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (expertiseServiceGpmId) {
        const expertiseServiceGpm = await expertiseServiceGpmApi.fetchExpertiseServiceGpm(
          expertiseServiceGpmId
        );
        this.setState({
          expertiseServiceGpm
        });
      } else if (vehicleId) {
        this.setState({
          expertiseServiceGpm: {
            vehicleId
          }
        });
      } else {
        this.setState({
          expertiseServiceGpm: {}
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.id) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/maintenance/expertise-service-gpm`);
    } else {
      if (id) {
        navigate(`/services/expertises-services-gpm/${id}`);
      } else {
        navigate(`/services/expertises-services-gpm`, true);
      }
    }
  };

  onSubmit = async (expertiseServiceGpm: ExpertiseServiceGpm) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const expertiseServiceGpmId = parseInt(this.props.id, 10);
      if (expertiseServiceGpmId) {
        await expertiseServiceGpmApi.updateExpertiseServiceGpm(
          expertiseServiceGpm
        );
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(expertiseServiceGpmId);
      } else {
        const added = await expertiseServiceGpmApi.addExpertiseServiceGpm(
          expertiseServiceGpm
        );

        notification.success({
          message: 'Успешное добавление',
          description: `Экспертиза ПС/ТО приборов безопасности ПС успешно добавлено`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const expertiseServiceGpmId = parseInt(this.props.id, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { expertiseServiceGpm } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId ? (
                <Crumb to={`/vehicles/${vehicleId}`}>ТС</Crumb>
              ) : (
                <Crumb
                  to={getPathWithHistoryParams(
                    '/services/expertises-services-gpm'
                  )}
                >
                  Список экспертиз
                </Crumb>
              )}
              {expertiseServiceGpmId ? (
                <Crumb>Редактирование экспертизы</Crumb>
              ) : (
                <Crumb>Новая Экспертиза ПС/ТО приборов безопасности ПС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {expertiseServiceGpmId
              ? `Экспертиза ПС/ТО приборов безопасности ПС №${expertiseServiceGpmId ||
                  ''}`
              : 'Новая Экспертиза ПС/ТО приборов безопасности ПС'}
          </h1>
        </StyledPanel>
        <InnerForm
          expertiseServiceGpm={{
            paymentAmount: 0,
            ...expertiseServiceGpm
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect()}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default ExpertiseServiceGpmForm;
