// @flow

import Button from 'antd/lib/button';

import notification from 'antd/lib/notification';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import {notificationLoading} from '../../../../../components/Notifications';
import {accessTypeEnum, calculationStatusEnum, entityStatusEnum, vehiclePlanStatusEnum} from '../../../../../lib/enum';
import {plus, toLocalStringRu} from '../../../../../lib/helpers';

import type {
  CalculationStatus,
  OverhaulCalculation,
  OverhaulCalculationMonth,
  RepairBudget,
  UserAccess,
  VehiclePlan,
} from '../../../../../lib/types';

import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget, canEditOverhaulRepair} from '../../accessRight';

import type {VehicleListFilterParams} from '../../components/FilterVehicleList';

import {
  COGNOS_DELTA,
  COLUMNS,
  Container,
  DecomissioningDateWarn,
  filteredData,
  LicensePlate,
  MONTH,
  OverhaulRepairCostWarn,
} from '../../lib';

import {headerPanel} from '../components/Common';
import {Section} from './../../../../../components/layout';

import {AntTable, ButtonsRow} from './../../../../../components/ui';
import {overhaulCalculationApi, repairBudgetApi, vehiclePlanApi} from './../../../../../lib/api';
import Filter from './../../components/FilterVehicleList';

import Modal from './components/Modal';
import InfoBlock from '../../components/InfoBlock';
import TotalBlock from '../../Budget/components/TotalBlock';

const StyledSpan = styled.span`
  color: #1890ff;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

type Props = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = {
  visible: boolean,
  readonly: boolean,
  vehiclePlan?: VehiclePlan,
  loading: boolean,
  monthIndex: ?number,
  rowIndex: ?number,
  data: OverhaulCalculation[],
  filter: VehicleListFilterParams,
  currentOverhaulCalculationMonth: ?OverhaulCalculationMonth,
  budgetRepair?: RepairBudget,
  cognosData: ?number
};
export default withUserAccess(
  class extends React.Component<Props, State> {
    state = {
      visible: false,
      data: [],
      vehiclePlan: undefined,
      loading: false,
      monthIndex: null,
      rowIndex: null,
      filter: {},
      currentOverhaulCalculationMonth: null,
      readonly: true,
      budgetRepair: undefined,
    };

    isRangeMonths = (monthIndex: number) => {
      const {vehiclePlan} = this.state;
      if (vehiclePlan?.startDate) {
        const start = moment(vehiclePlan.startDate).get('month');
        const end = moment(vehiclePlan.endDate).get('month');
        return start <= monthIndex && end >= monthIndex;
      }
      return false;
    };

    // дата после списания
    afterPlannedWriteoffDate = (
      monthIndex: number,
      plannedWriteoffDate?: ?string,
    ) => {
      const {vehiclePlan} = this.state;
      if (vehiclePlan && plannedWriteoffDate) {
        const year = moment(vehiclePlan.date).get('year');
        return moment(`${year}-${monthIndex + 1}-01`).isAfter(
          plannedWriteoffDate,
          'month',
        );
      }
      return false;
    };

    onCell = (monthIndex: number) => (
      overhaulCalculation: OverhaulCalculation,
      rowIndex: number,
    ) => {
      if (!this.canEdit()) {
        return;
      }
      return {
        onClick:
          !this.isDraft() ||
          this.afterPlannedWriteoffDate(
            monthIndex,
            overhaulCalculation?.selfVehiclePlanVehicle?.vehicle
              ?.plannedWriteoffDate ||
            overhaulCalculation?.selfVehiclePlanVehicle?.plannedWriteoffDate,
          ) ||
          !canEditOverhaulRepair(this.props.userAccess)
            ? () => {
              !!overhaulCalculation?.id &&
              this.setState({
                visible: true,
                monthIndex,
                rowIndex,
                currentOverhaulCalculationMonth:
                  overhaulCalculation?.months[monthIndex],
              });
            }
            : () => {
              !!overhaulCalculation?.id &&
              this.isRangeMonths(monthIndex) &&
              this.setState({
                visible: true,
                monthIndex,
                rowIndex,
                currentOverhaulCalculationMonth:
                  overhaulCalculation.months[monthIndex],
              });
            },
      };
    };

    columns = [
      ...COLUMNS.map(column => {
        return column.key === 'licensePlate'
          ? {
            ...column,
            render: (licensePlate: string, record?: any) => {
              return (
                <Container>
                  <LicensePlate>{licensePlate}</LicensePlate>
                  {record?.selfVehiclePlanVehicle?.plannedWriteoffDate && (
                    <DecomissioningDateWarn
                      date={
                        record?.selfVehiclePlanVehicle.plannedWriteoffDate
                      }
                    />
                  )}
                  {licensePlate &&
                    this.isDraft() &&
                    record?.hasNoMaterialsCost && <OverhaulRepairCostWarn />}
                </Container>
              );
            },
          }
          : column;
      }),
      ...MONTH.map((month, monthIndex) => ({
        title: month.title,
        children: [
          {
            title: 'Вид работ',
            width: 150,
            onCell: this.onCell(monthIndex),
            render: (cost: ?number, overhaul: OverhaulCalculation) =>
              this.isDraft() &&
              this.isRangeMonths(monthIndex) &&
              !this.afterPlannedWriteoffDate(
                monthIndex,
                overhaul?.selfVehiclePlanVehicle?.vehicle.plannedWriteoffDate ||
                overhaul?.selfVehiclePlanVehicle?.plannedWriteoffDate,
              ) &&
              canEditOverhaulRepair(this.props.userAccess) ? (
                <StyledSpan>
                  {overhaul.months[monthIndex].operations?.length
                    ? 'Редактировать'
                    : 'Добавить'}
                </StyledSpan>
              ) : (
                '-'
              ),
          },
          {
            title: 'Стоимость',
            width: 150,
            dataIndex: `months[${monthIndex}].cost`,
            render: (cost: number) =>
              toLocalStringRu(cost, {minimumFractionDigits: 2}),
          },
        ],
      })),
      {
        title: 'Итого стоимость',
        children: [
          {
            title: 'Материалы',
            children: [
              {
                title: 'Хоз. способ',
                width: 150,
                dataIndex: 'internalMaterialsSum',
                render: (internalMaterialsSum: number) =>
                  toLocalStringRu(internalMaterialsSum, {
                    minimumFractionDigits: 2,
                  }),
              },
              {
                title: 'Подряд',
                width: 150,
                dataIndex: 'contractMaterialsSum',
                render: (contractMaterialsSum: number) =>
                  toLocalStringRu(contractMaterialsSum, {
                    minimumFractionDigits: 2,
                  }),
              },
            ],
          },
          {
            title: 'Работы',
            children: [
              {
                title: 'Хоз. способ',
                width: 150,
                dataIndex: 'internalOperationsSum',
                render: (internalOperationsSum: number) =>
                  toLocalStringRu(internalOperationsSum, {
                    minimumFractionDigits: 2,
                  }),
              },
              {
                title: 'Подряд',
                width: 150,
                dataIndex: 'contractOperationsSum',
                render: (contractOperationsSum: number) =>
                  toLocalStringRu(contractOperationsSum, {
                    minimumFractionDigits: 2,
                  }),
              },
            ],
          },
        ],
      },
      {
        title: 'Итого',
        width: 150,
        dataIndex: 'sum',
        render: (sum: number) =>
          toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
    ];

    handleMonthSubmit = (month: OverhaulCalculationMonth) => {
      const {rowIndex, monthIndex} = this.state;
      let {data} = this.state;
      if (data) {
        // $FlowFixMe
        data[rowIndex].months[monthIndex] = month;
        this.setState({data});
      }
      this.handleMonthCancel();
    };

    handleMonthCancel = () => {
      this.setState({
        visible: false,
        currentOverhaulCalculationMonth: null,
        monthIndex: null,
        rowIndex: null,
      });
    };

    componentDidMount() {
      this.getData();
    }

    getData = async () => {
      this.setState({loading: true});
      try {
        await this.getVehiclePlan();
        await this.fetch();
      } finally {
        this.setState({loading: false, readonly: !this.isDraft()});
      }
    };

    getVehiclePlan = async () => {
      const {vehiclePlanId} = this.props;
      try {
        const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
        this.setState({vehiclePlan});
      } catch (error) {
        notification.warning({message: 'Не удалось запросить данные'});
      }
    };

    fetch = async () => {
      const {vehiclePlanId} = this.props;

      const itogCalculation = (data: any) => {
        const itog = {
          months: Array(12)
          .fill()
          .map(() => ({cost: 0})),
          sum: 0,
        };
        data.forEach((item: any) => {
          itog.sum = plus(item.sum || 0, itog.sum);
          item.months.forEach((month: any, index) => {
            itog.months[index].cost = plus(
              itog.months[index].cost,
              month.cost || 0,
            );
          });
        });
        return itog;
      };

      try {
        let {data} = await overhaulCalculationApi.fetch({
          vehiclePlanId,
          pageSize: undefined,
          page: undefined,
        });

        if (data.length) {
          data = [...data, itogCalculation(data)];
        }

        const budgetRepair = await repairBudgetApi.getByVehiclePlan(
          vehiclePlanId,
        );

        this.setState({data, budgetRepair});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    };

    isDraft = () =>
      this.state.vehiclePlan?.overhaulCalculationStatus ===
      calculationStatusEnum.draft;

    changeStatus = async (status: CalculationStatus) => {
      const {vehiclePlanId} = this.props;
      try {
        notificationLoading({
          message: 'Сохранение данных',
          key: 'saving',
        });
        await overhaulCalculationApi.updateCalculations(vehiclePlanId);
        await overhaulCalculationApi.changeStatus(vehiclePlanId, status);
        this.getData();
      } catch (error) {
        notification.error({message: 'Не удалось обновить данные данные'});
      } finally {
        notification.close('saving');
      }
    };

    calculate = async () => {
      const {vehiclePlanId} = this.props;
      const {data} = this.state;
      try {
        notificationLoading({
          message: 'Сохранение данных',
          key: 'saving',
        });
        if (data.length === 0) {
          await overhaulCalculationApi.calculate(vehiclePlanId);
        } else {
          await overhaulCalculationApi.updateCalculations(vehiclePlanId);
        }
        this.getData();
      } catch (error) {
        notification.error({message: 'Не удалось обновить данные данные'});
      } finally {
        notification.close('saving');
      }
    };

    applyFilter = (filter: VehicleListFilterParams) =>
      this.setState({filter});

    cleanFilter = () => this.setState({filter: {}});

    canEdit = () =>
      this.props.userAccess.some(access =>
        [
          accessTypeEnum.editingBudgetCopy,
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
        ].includes(access),
      );

    render() {
      const {vehiclePlanId} = this.props;
      const {
        currentOverhaulCalculationMonth,
        visible,
        loading,
        filter,
        data,
        vehiclePlan,
        budgetRepair,
        cognosData,
      } = this.state;
      const filtered = filteredData(data, filter);
      const planApproved =
        vehiclePlan?.status === vehiclePlanStatusEnum.approved;
      const budgetRepairApproved =
        budgetRepair?.status === entityStatusEnum.approved;
      const isCognosStatus =
        vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
        vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
      const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
        ? false
        : Math.abs((data[data.length - 1]?.sum || 0) - cognosData) > COGNOS_DELTA;

      return (
        <>
          {headerPanel({
            title: 'Капитальный ремонт',
            vehiclePlanId,
            vehiclePlan,
          })}
          <Section>
            <InfoBlock
              links={{
                'Виды работ по капитальному ремонту ТС':
                  '/admin/overhaul-types/',
                'Стоимость нормо-часа': '/admin/maintenance-operation-price',
              }}
            />
            <div style={{padding: '16px 16px 0px'}}>
              <Filter
                vehicleType={true}
                filter={filter}
                applyFilter={this.applyFilter}
                cleanFilter={this.cleanFilter}
                vehiclePlanId={vehiclePlanId}
              />
            </div>
            <AntTable
              data={filtered}
              bordered
              pagination={{pageSize: 50}}
              loading={loading}
              columns={
                !this.state.readonly
                  ? this.columns
                  : [
                    ...COLUMNS.map(column =>
                      column.key === 'licensePlate'
                        ? {
                          ...column,
                          render: (licensePlate: string, record?: any) => {
                            return (
                              <Container>
                                <LicensePlate>{licensePlate}</LicensePlate>
                                {record?.selfVehiclePlanVehicle
                                  ?.plannedWriteoffDate && (
                                  <DecomissioningDateWarn
                                    date={
                                      record?.selfVehiclePlanVehicle
                                        .plannedWriteoffDate
                                    }
                                  />
                                )}
                                {licensePlate &&
                                  this.isDraft() &&
                                  record?.hasNoMaterialsCost && (
                                    <OverhaulRepairCostWarn />
                                  )}
                              </Container>
                            );
                          },
                        }
                        : column,
                    ),
                    ...MONTH.map((month, monthIndex) => ({
                      title: month.title,
                      onCell: this.onCell(monthIndex),
                      dataIndex: `months[${monthIndex}].cost`,
                      width: 150,
                      render: (cost: number) =>
                        toLocalStringRu(cost, {minimumFractionDigits: 2}),
                    })),
                    {
                      title: 'Итого',
                      width: 150,
                      dataIndex: 'sum',
                      render: (sum: number) =>
                        toLocalStringRu(sum, {minimumFractionDigits: 2}),
                    },
                  ]
              }
              scroll={{
                y: 'calc(100vh - 460px)',
                x: '2800px',
              }}
              rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
                ? 'table-row-error'
                : null
              }
            />
            {visible && (
              <Modal
                visible={visible}
                handleSave={this.handleMonthSubmit}
                handleCancel={this.handleMonthCancel}
                overhaulCalculationMonth={currentOverhaulCalculationMonth}
                readonly={this.state.readonly}
              />
            )}
          </Section>
          <Section style={{padding: '16px'}}>
            <Wrapper>
              {isCognosStatus && (
                <TotalBlock
                  filteredDataTotalSum={
                    data.length ? data[data.length - 1]?.sum : 0
                  }
                  controllerName="overhaulCalculation"
                  vehiclePlanId={vehiclePlanId}
                  setCognosData={cognosData => this.setState({cognosData})}
                />
              )}
              {canEditBudget(this.props.userAccess) && (
                <ButtonsRow>
                  {this.isDraft() && (
                    <>
                      <Button type="primary" onClick={this.calculate}>
                        Рассчитать
                      </Button>
                      <Button
                        style={{marginLeft: '16px'}}
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!budgetRepairApproved && !planApproved && !this.isDraft() && (
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.draft)
                      }
                    >
                      Редактировать
                    </Button>
                  )}
                </ButtonsRow>
              )}
            </Wrapper>
          </Section>
        </>
      );
    }
  }
);
