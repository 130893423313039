//@flow
import styled from 'styled-components';
import {Spin} from 'antd';
import React from 'react';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.85);
  height: 100vh;
  width: 100vw;
  z-index: 9999;
`;

const TextBlock = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 24px;
  & > * {
    margin-bottom: 10px;
  }
`;

export default (props) => (
  <Container>
    {props.children && <TextBlock>{props.children}</TextBlock>}
    <Spin size='large' />
  </Container>
)
