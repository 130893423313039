// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {okeiApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';

import {Filter} from './components';

const entryPointPath = '/admin/okei/';

const columns = [
  {
    title: 'Код',
    dataIndex: 'code'
  },
  {
    title: 'Наименование единицы измерения',
    dataIndex: 'name'
  },
  {
    title: 'Условное обозначение',
    dataIndex: 'alias'
  }
];


const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default () => {
  return (
    <Page>
      <CommonListPage
        crud={okeiApi}
        pageHeaderProps={{
          mainHeader: 'Общероссийский классификатор единиц измерения',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
};
