// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import notification from 'antd/lib/notification';

import type { Tire, UserAccess } from '../../../lib/types';
import { Tires as TiresComponents } from './../components';
import { resetVehicleStatus, setVehicleTires } from '../../../ducks/vehicle';
import { tireApi } from './../../../lib/api';
import type { AppState } from '../../../ducks/redux';
import { notificationLoading } from '../../../components/Notifications';
import type { Vehicle } from '../../../lib/types';
import { accessTypeEnum } from '../../../lib/enum';
import WorkCardModal from '../../Equipment/Tires/components/WorkCardModal';
import isEqual from 'lodash/isEqual';

const { TiresForm, EditableItem } = TiresComponents;

const canWriteOffAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.decomissingEquipment
];

type Props = {
  items: Array<Tire>,
  resetVehicleStatus: (vehicleId: number) => void,
  setVehicleTires: (vehicleId: number) => void,
  vehicleId: number,
  vehicle: Vehicle,
  editable: boolean,
  canAddFuelMeasurement: boolean,
  employeeBranchOrgUnitId: number,
  userAccess: UserAccess[]
};

type State = {
  availableTires: Tire[],
  isLoading: boolean,
  showWorkCardModal: boolean,
  writtenOffId: ?number,
  tires: Tire[]
};

export class Tires extends Component<Props, State> {
  state = {
    availableTires: [],
    isLoading: false,
    showWorkCardModal: false,
    writtenOffId: null,
    tires: []
  };

  async componentDidMount() {
    this.setState({ tires: this.props.items ? this.props.items : [] });
    await this.fetchAvailableTires();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.items, this.props.items)) {
      this.setState({ tires: this.props.items ? this.props.items : [] });
    }
  }

  fetchAvailableTires = async (search?: string) => {
    if (this.props.editable) {
      this.setState({
        isLoading: true
      });
      let availableTires = [];
      const result = await tireApi.fetchTires({
        search,
        page: 1,
        pageSize: 50,
        isFree: true,
        nodeId: this.props.employeeBranchOrgUnitId,
        nodeFilterType: 'branchAndChildren',
        isArchive: false
      });

      if (result) {
        availableTires = result.data;
      }
      this.setState({
        availableTires,
        isLoading: false
      });
    }
  };

  onAdd = async (tire: Tire) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await tireApi.setTireVehicle(
        tire.id,
        this.props.vehicleId,
        tire.installDate,
      );
      await this.props.resetVehicleStatus(this.props.vehicleId);
      await this.props.setVehicleTires(this.props.vehicleId);
      await this.fetchAvailableTires();
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onEdit = async (tire: Tire) => {
    await tireApi.updateTireVehicle(tire);
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleTires(this.props.vehicleId);
  };

  onRemove = async (tire: Tire) => {
    await tireApi.updateTire({ ...tire, vehicleId: null });
    await this.props.resetVehicleStatus(this.props.vehicleId);
    await this.props.setVehicleTires(this.props.vehicleId);
    await this.fetchAvailableTires();
  };

  handleSearch = debounce(
    async (value: string) => await this.fetchAvailableTires(value),
    500
  );

  writeOffTire = async tireData => {
    try {
      notificationLoading({
        message: 'Списание...',
        key: 'writingOff'
      });
      if (tireData) {
        await tireApi.writeOffTire(tireData);
        notification.success({
          message: 'Успешно',
          description: 'Шина успешно списана'
        });
        this.setState({
          showWorkCardModal: false,
          writtenOffId: null
        });
        tireApi
          .fetchVehicleTires(this.props.vehicleId)
          .then(data => this.setState({ tires: data }));
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('writingOff');
    }
  };

  render() {
    const { editable, canAddFuelMeasurement, vehicle }: Props = this.props;
    const {
      availableTires,
      isLoading,
      tires,
      showWorkCardModal,
      writtenOffId
    } = this.state;
    const canWriteOff = this.props.userAccess.some(access =>
      canWriteOffAccess.includes(access)
    );
    return (
      <>
        <WorkCardModal
          visible={showWorkCardModal}
          onClose={() => this.setState({ showWorkCardModal: false })}
          onSave={formData =>
            this.writeOffTire({ ...formData, tireId: writtenOffId })
          }
        />
        {tires.map((item: Tire) => {
          if (!item) {
            return null;
          }
          return (
            <EditableItem
              key={item.id}
              tire={item}
              vehicle={vehicle}
              onChange={this.onEdit}
              onRemove={editable ? this.onRemove : null}
              editable={false} // TODO: нужны требования, но пока не можем редактировать шины в инвентарной карточке ТС
              canAddFuelMeasurement={canAddFuelMeasurement}
              writeOff={
                canWriteOff
                  ? () =>
                      this.setState({
                        showWorkCardModal: true,
                        writtenOffId: parseInt(item.id, 10)
                      })
                  : undefined
              }
            />
          );
        })}
        {editable && (
          <TiresForm
            onSubmit={this.onAdd}
            vehicle={vehicle}
            handleSearch={this.handleSearch}
            fetchData={this.fetchAvailableTires}
            isLoading={isLoading}
            availableTires={availableTires}
          />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
    userAccess: state.auth.profile.access
  }),
  {
    resetVehicleStatus,
    setVehicleTires
  }
)(Tires);
