// @flow
import React, { useState, useCallback } from 'react';

import { navigate } from '../../lib/helpers';
import { debetCreditAccountsApi } from '../../lib/api';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {
  type CommonFormProps
} from '../../components/hoc/common/handbook/CommonForm';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

import Grid, { GridItem } from '../../components/layout/Grid';
import { FormField } from '../../components/Form';
import { StyledInput } from '../../components/hoc/common/components/elements';
import { DebetCreditAccountItem } from '../../lib/types/debetCreditAccounts';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/debet-credit-accounts';

const InnerForm = (props: CommonFormProps<$Shape<DebetCreditAccountItem>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Дебет"
                  fast
                  required
                  name="debet"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Кредит"
                  fast
                  required
                  name="credit"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: DebetCreditAccountItem, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await debetCreditAccountsApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: DebetCreditAccountItem) => {
    let id = payload.id;
    if (id) {
      await debetCreditAccountsApi.update(payload);
    } else {
      await debetCreditAccountsApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Дебет/кредит счетов',
        access: {
          delete: data.id
            ? () => {
                debetCreditAccountsApi.delete(data.id);
                navigate(entryPointPath);
              }
            : undefined
        }
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
