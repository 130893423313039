// @flow
import React, {useCallback, useState} from 'react';

import {navigate} from '../../../lib/helpers';
import {expensesDistributionApi} from '../../../lib/api';
import type {ExpensesDistribution} from '../../../lib/types';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import InnerForm from './components/InnerForm';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/expense-direction/distribution';

export default (props: PageProps) => {
  const [data: ExpensesDistribution, setData] = useState({
    id: props.id
  });

  const onFetch = useCallback(async () => {
    !!props.id && setData(await expensesDistributionApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: ExpensesDistribution) => {
    let id = payload.id;
    if (id) {
      await expensesDistributionApi.update(payload);
    } else {
      await expensesDistributionApi.add(payload);
    }
    await navigate(`${entryPointPath}`);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          {
            to: '/admin/expense-direction/distribution',
            name: 'Список распределения'
          },
          { name: 'Распределение затрат' }
        ],
        mainHeader: 'Распределение затрат'
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
