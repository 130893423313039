// @flow
import React, { Component, type Node, type ComponentType } from 'react';
import styled from 'styled-components';

import Spinner from './../Spinner';
import Grid, {
  GridItem,
  type GridProps,
  type GridItemProps
} from '../layout/Grid';

const Wrapper = styled.div.attrs({
  className: 'list-table'
})`
  display: flex;
  flex-direction: column;
  border: 1px solid #c0ccd7;
  border-radius: 3px;
`;

Wrapper.displayName = 'ListTable.Wrapper';

export const Header = styled(Grid).attrs({
  className: 'list-table__header',
  gutter: '16px'
})`
  background: #c0ccd7;
  padding: 10px 16px;
  border-radius: inherit inherit 0 0;
  color: #3d4042;
  word-wrap: break-word;
`;

Header.displayName = 'ListTable.Header';

export const Content = styled.div.attrs({
  className: 'list-table__content'
})`
  display: flex;
  flex-direction: column;
`;

Content.displayName = 'ListTable.Content';

export const Row = styled(Grid).attrs({
  className: 'list-table__row'
})`
  border-bottom: 1px solid #e4ebf2;
  padding: 16px;
  &:last-child {
    border-bottom: none;
  }
`;

Row.defaultProps = {
  gutter: '16px'
};

Row.displayName = 'ListTable.Row';

export const Cell = styled(GridItem)``;

Cell.displayName = 'ListTable.Cell';

const NoDataRow = styled(Row)`
  align-items: center;

  p {
    text-align: center;
  }
`;

type ListTableColumn = {
  /**
   * Наименование колонки
   */
  title?: string,
  /**
   * Стили для колонки (например, чтобы селекты не расползались - используем overflow: hidden)
   */
  style?: any,
  /**
   * Ключ, по которому выводятся данные колонки
   */
  key?: string,
  /**
   * Индекс, по которому выводятся данные колонки после этого смотрим key
   */
  dataIndex?: string,
  /**
   * Функция рендеринга содержимого колонки
   * По-умолчанию, выводится содержимое по ключу (key)
   * @param value - содержимое по ключу key
   * @param data - содержимое объекта, соответствующего строке
   * @param index - номер строки
   *
   * Пример:
   * {
   *   title: 'Операции',
   *   key: 'id',
   *   render: (id: number, record: User, index: number) => (
   *     <div>
   *       <DeleteButton onClick={async () => await this.deleteUser(id)} />
   *     </div>
   *   )
   * }
   */
  render?: (value: any, data: any, index: number) => Node,
  /**
   * Функция рендеринга содержимого колонки без ключа
   * @param data - содержимое объекта, соответствующего строке
   * @param index - номер строки
   *
   * Пример:
   * {
   *   title: 'Операции',
   *   render: (record: User, index: number) => (
   *     <div>
   *       <DeleteButton onClick={async () => await this.deleteUser(record.id)} />
   *     </div>
   *   )
   * }
   */
  renderRecord?: (data: any, index: number) => Node,
  /**
   * Ширина колонки
   */
  width?: string,
  /**
   * colspan && rowspan
   */
  cellUnion?: {
    gridColumn: string,
    gridRow: string
  }
};

const defaultRender = (value: any, data?: any, index?: any) => value;

const renderHeaderColumn = (
  { key, title = '', cellUnion, style }: ListTableColumn,
  index: number
) => (
  <Cell style={style} key={key || index} cellUnion={cellUnion}>
    {title}
  </Cell>
);

const renderCells = (
  data: any,
  index: number,
  columns: ListTableColumn[]
): Node =>
  columns.map((column, colIndex) => {
    const {
      render = defaultRender,
      key,
      dataIndex,
      width,
      renderRecord,
      style
    } = column;
    const value = dataIndex ? data[dataIndex] : data[key];
    return (
      <Cell width={width} key={key || colIndex} style={style}>
        {key
          ? typeof render === 'function' && render(value, data, index)
          : typeof renderRecord === 'function' && renderRecord(data, index)}
      </Cell>
    );
  });

// $FlowFixMe Row с заданной разметкой
const DefaultRow: ComponentType<GridProps> = ({ columns, ...otherProps }) => (
  <Row cols={columns.map(column => column.width || '1fr')} {...otherProps} />
);

const renderDataRow = (
  data: ?any,
  index: number,
  columns: ListTableColumn[],
  renderRowFunction?: Function,
  rowClassName?: Function
) => {
  if (data) {
    const cells = renderCells(data, index, columns);
    const className = rowClassName ? rowClassName(data, index) : {};
    if (renderRowFunction) {
      return renderRowFunction(data, index, cells, DefaultRow, Cell);
    } else {
      return (
        <DefaultRow key={index} columns={columns} className={className}>
          {cells}
        </DefaultRow>
      );
    }
  }
  return null;
};

type ListTableProps = {
  /**
   * Описание колонок
   */
  columns?: ListTableColumn[],
  /**
   * Массив данных
   */
  data: any[],
  // Идет загрузка данных
  isLoading?: boolean,
  /**
   * Функциональный children
   * По-умолчанию выводит содержимое (Content - это все строки таблицы)
   * Content - строки таблицы
   * Row - Строка таблицы
   * Пример:
   * {({content, Row, Cell}) => (
   *   {content}
   *   <Row cols={3}>
   *     <Cell>Example Cell</Cell>
   *     <Cell>Example Cell</Cell>
   *     <Cell>Example Cell</Cell>
   *   </Row>
   * )}
   */
  children?: ({
    content: Node,
    Row: ComponentType<GridProps>,
    Cell: ComponentType<GridItemProps>
  }) => Node,
  /**
   * Функция отрисовки строк таблицы
   *
   * @param record Данные
   * @param index Индекс
   * @param rowCells Коллекция реакт элементов ячеек
   * @param Row Компонент Row (Строка)
   * @param Cell Компонент Cell (Ячейка)
   *
   * Можно использовать для обертки строк
   */
  renderRow?: (
    record: any,
    index: number,
    rowContent: Node,
    Row: ComponentType<GridProps>,
    Cell: ComponentType<GridItemProps>
  ) => Node,
  // Текст при отсутствии данных
  noDataText: string,
  // Показывать шапку
  showHeader: boolean,
  header?: Node,
  className?: string,
  style?: any,
  styleContent?: any,
  /**
   * функция для стилей для строк в таблице
   * @param data - содержимое объекта, соответствующего строке
   * @param index - номер строки
   */
  rowClassName?: (data: any, index: number) => string
};

/**
 * Компонент для отображения табличных списков
 * Пример использования: в заявках, в путевых листах
 */
export default class ListTable extends Component<ListTableProps> {
  static defaultProps = {
    isLoading: false,
    noDataText: 'Данные отсутствуют',
    showHeader: true
  };
  render() {
    const {
      children = ({ content }) => content,
      columns = [],
      data = [],
      isLoading,
      noDataText,
      renderRow,
      showHeader,
      header,
      className,
      rowClassName,
      style,
      styleContent = {}
    } = this.props;
    return (
      <Wrapper style={style} className={className}>
        {showHeader &&
          (header ? (
            header
          ) : (
            <Header cols={columns.map(column => column.width || '1fr')}>
              {columns.map(renderHeaderColumn)}
            </Header>
          ))}
        {isLoading ? (
          <Spinner isLoading={isLoading} />
        ) : (
          <Content style={styleContent}>
            {children({
              content: data.length ? (
                data.map((value, index) =>
                  renderDataRow(value, index, columns, renderRow, rowClassName)
                )
              ) : (
                <NoDataRow cols="1fr">
                  <p>{noDataText}</p>
                </NoDataRow>
              ),
              Row,
              Cell
            })}
          </Content>
        )}
      </Wrapper>
    );
  }
}
