// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {notificationLoading} from '../../components/Notifications';
import {ModelMatchesApi} from '../../lib/api';
import type {ModelMatches} from '../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};

type State = {
  modelMatches: ?ModelMatches,
};

export class Form extends React.Component<Props, State> {
  state = {
    modelMatches: {},
  };
  
  async componentDidMount() {
    const {id} = this.props;
    try {
      if (id) {
        const modelMatches = await ModelMatchesApi.get(id);
        this.setState({modelMatches});
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
  }
  
  handleSubmit = async (values: ModelMatches) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        await ModelMatchesApi.update(values);
      } else {
        await ModelMatchesApi.add(values);
      }
      notification.success({
        message: 'Запись добавлена',
      });
      await navigate(`/admin/model-matches/`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  handleCancel = () => navigate('/admin/model-matches/', true);
  
  render() {
    const {modelMatches} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/model-matches/')}>
                Модели для сопоставления
              </Crumb>
              {modelMatches?.id ? (
                <Crumb>Модель №{modelMatches.id}</Crumb>
              ) : (
                <Crumb>Новая модель</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {modelMatches?.id
              ? `Модель №${modelMatches.id}`
              : 'Новая модель'}
          </h1>
        </StyledPanel>
        <InnerForm
          modelMatches={modelMatches}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default Form;
