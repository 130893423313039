// @flow
import React from 'react';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';

import Spoiler from '../../../../components/ui/Spoiler';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import {formatDateTimeToISOString} from '../../../../lib/helpers';
import type {ReportTaxedTripsElectricParams} from '../../../../lib/types/reportTaxedTripsElectric';
import {OrgUnitSelect} from '../../../../components';
import DriverSelect from '../../../../components/selects/DriverSelect';
import BrandsSelect from '../../../../components/selects/BrandsSelect';
import VehicleModelSelect from '../../../../components/selects/VehicleModelSelect';
import {VehicleOwnerTypesEnum} from '../../../../lib/types/vehicleModel';
import VehicleSelect from '../../../../components/selects/VehicleSelect';
import {ownerTypes} from '../../../../lib/enum';

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const { RangePicker } = Datepicker;

type Props = {
  onChangeFilter: (key: $Keys<ReportTaxedTripsElectricParams>, value: any) => void,
  params: ReportTaxedTripsElectricParams,
  cleanParams: () => void,
  applyParams: () => Promise<any>,
  onExport: Function
};

/**
 * Фильтр с параметрами для отчета о таксировках
 */
export default class Filter extends React.Component<Props> {
  render() {
    const { params, onExport } = this.props;
    return (
      <Spoiler defaultExpanded label="Параметры">
        <SpoilerContent>
          <Grid gutter="16px" media={[{ size: 'lg', cols: 6 }]}>
            <GridItem>
              <Field label="Отчетный период">
                <RangePicker
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm')
                    ]
                  }}
                  placeholder={['Начало', 'Конец']}
                  value={[
                    params.startDate ? moment.utc(params.startDate) : null,
                    params.endDate ? moment.utc(params.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    this.props.onChangeFilter(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    this.props.onChangeFilter(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label='Подразделение'>
                <OrgUnitSelect
                  value={params.orgUnitId}
                  onChange={id => this.props.onChangeFilter('orgUnitId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label='Водитель/ пользователь ЭК'>
                <DriverSelect
                  value={params.driverId}
                  onChange={id => this.props.onChangeFilter('driverId', id)}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label='Марка'>
                <BrandsSelect
                  value={params.brandId}
                  onChange={id => {
                    this.props.onChangeFilter('vehicleId', undefined);
                    this.props.onChangeFilter('brandId', id);
                  }}
                  mode='multiple'
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label='Модель'>
                <VehicleModelSelect
                  type={VehicleOwnerTypesEnum.self}
                  value={params.modelId}
                  onChange={id => {
                    this.props.onChangeFilter('vehicleId', undefined);
                    this.props.onChangeFilter('modelId', id);
                  }}
                  filter={ {
                    brandId: params.brandId || undefined,
                    electricModels: true
                  }}
                  mode='multiple'
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label='Госномер'>
                <VehicleSelect
                  value={params.vehicleId}
                  onChange={id => {
                    this.props.onChangeFilter('vehicleId', id);
                    this.props.onChangeFilter('brandId', undefined);
                    this.props.onChangeFilter('modelId', undefined);
                  }}
                  filter={{ownerType: ownerTypes.self, onlyElectric: true}}
                  optionTitle={vehicle => vehicle.licensePlate}
                  mode='multiple'
                />
              </Field>
            </GridItem>
            <Footer fullWidth>
              <Button type="primary" onClick={onExport}>
                Экспорт
              </Button>
              <Button type="primary" onClick={this.props.applyParams}>
                Применить
              </Button>
              <Button type="secondary" onClick={this.props.cleanParams}>
                Очистить
              </Button>
            </Footer>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}
