// @flow
import { accessTypeEnum } from '../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch
];

export const editAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch
];

export default {
  addAccessRight,
  editAccessRight
};
