// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import type {
  InspectionGtn,
  ListState,
  UserAccess,
  Vehicle
} from './../../../lib/types';
import {
  getListInitialState,
  setQueryParams,
  convertVehicleToString,
  formatDateTimeToString,
  navigate,
  checkDate,
  toLocalStringRu,
  sumWidthColumns
} from './../../../lib/helpers';
import { inspectionGtnApi, type FetchListParams } from './../../../lib/api';

import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';

import { Section } from './../../../components/layout';
import { Table, Icon, ButtonsRow } from './../../../components/ui';
import { TableHeader } from './../../../components/ui/Table';
import { notificationLoading } from './../../../components/Notifications';
import Header from './../../../components/layout/Header';

import { withUserAccess } from './../../withUserAccess';

import { canHandleService, ACT_COLUMNS, TAX_ACT_COLUMNS } from '../lib';

import InspectionGtnFilter from './Filter';
import type { InspectionGtnFilterParams } from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};
type State = ListState<InspectionGtn> & {
  filterPath: string
};

export const COLUMNS = [
  {
    title: 'Марка',
    sorter: true,
    width: 400,
    breakByWidth: true,
    dataIndex: 'vehicle',
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle)
  },
  {
    title: 'Дата обслуживания',
    dataIndex: 'maintenanceDate',
    width: 150,
    breakByWidth: true,
    render: (value: string) => checkDate(value)
  },
  {
    title: <TableHeader width={150}>Дата следующего обслуживания</TableHeader>,
    dataIndex: 'nextActDate',
    width: 150,
    breakByWidth: true,
    render: (value: string) => formatDateTimeToString(value, 'DD.MM.YYYY')
  },
  {
    title: <TableHeader width={165}>Сумма оплаты в руб. без НДС</TableHeader>,
    dataIndex: 'paymentAmount',
    width: 165,
    breakByWidth: true,
    render: (paymentAmount: number) => toLocalStringRu(paymentAmount || 0)
  }
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };
  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };
  columns = [
    ...COLUMNS,
    {
      title: 'Выполнения работ',
      children: [...ACT_COLUMNS]
    },
    {
      title: 'Пошлина',
      children: [...TAX_ACT_COLUMNS]
    }
  ];

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 40,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deleteInspectionGtn(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        )
      });
    }
    await this.fetchInspectionGtnList(page);
  }

  deleteInspectionGtn = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await inspectionGtnApi.deleteInspectionGtn(id);
      await this.fetchInspectionGtnList(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchInspectionGtnList = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    try {
      this.setState({ loading: true });
      const { data, totalCount } = await inspectionGtnApi.fetchInspectionsGtn({
        ...filter,
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  handleRowClick = (id: number) => navigate(`/services/inspections-gtn/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  setPersistFilter = async (values: InspectionGtnFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async (filter: InspectionGtnFilterParams = {}) => {
    await this.setPersistFilter(filter);
    await this.fetchInspectionGtnList();
  };

  cleanFilter = this.applyFilter;

  handlePrint = async () => {
    try {
      const filter = this.props.persistFilters[this.state.filterPath];
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print'
      });
      await inspectionGtnApi.printReport(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];
    return (
      <>
        <Header
          left={<h1>Технические осмотры ГТН</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAdd() && (
                <Link to="/services/inspections-gtn/new">
                  <Button type="primary" data-cy="add">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <InspectionGtnFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            bordered
            fetch={this.fetchInspectionGtnList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            scroll={{
              x: sumWidthColumns(this.columns),
              y: 'calc(100vh - 270px)'
            }}
            loading={loading}
            columns={this.columns}
            data={data.map(item => ({
              ...item,
              key: item.id
            }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(List));
