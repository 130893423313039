// @flow
import React, { Component, Fragment } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import InputNumber from 'antd/lib/input-number';
import type { moment } from 'moment';

import type { OptionalEquipment } from './../../../../lib/types';
import { Card } from '../../../../components';
import { optionalEquipmentTypes } from './../../../../lib/enum';
import RemoveEquipmentButton from './../RemoveEquipmentButton';
import { formatDate } from './../../lib';
import { Spoiler, Icon } from '../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { EquipmentActions } from '../elements';

const { Field } = Card;
const ButtonGroup = Button.Group;

const SpoilerContent = styled.div`
  padding: 16px;
  border-bottom: 1px solid #c0ccd7;
`;

type Props = {
  onChange: (optionalEquipment: OptionalEquipment) => void,
  onRemove: (optionalEquipment: OptionalEquipment) => void,
  optionalEquipment: OptionalEquipment,
  editable: boolean
};

type State = {
  editMode: boolean,
  optionalEquipment: OptionalEquipment
};

class OptionalEquipmentItem extends Component<Props, State> {
  state = {
    editMode: false,
    optionalEquipment: this.props.optionalEquipment
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.optionalEquipment, this.props.optionalEquipment)) {
      this.setState({
        optionalEquipment: this.props.optionalEquipment
      });
    }
  }

  onApply = async () => {
    await this.props.onChange(this.state.optionalEquipment);
    this.setState({
      editMode: false
    });
  };

  changeOptionalEquipment = (fieldName: string, value: any) => {
    this.setState(prevState => ({
      optionalEquipment: {
        ...prevState.optionalEquipment,
        [fieldName]: value
      }
    }));
  };

  onCancel = () =>
    this.setState({
      optionalEquipment: this.props.optionalEquipment,
      editMode: false
    });

  render() {
    const { onRemove, editable } = this.props;
    const { optionalEquipment = {}, editMode } = this.state;
    const {
      name,
      brandName,
      code,
      type,
      installDate,
      removeDate,
      inventoryNumber,
      actualFuelConsumption
    } = optionalEquipment;
    return (
      <Spoiler
        key={optionalEquipment.id}
        label={`${name} ${brandName}`}
        suffix={
          editable && (
            <EquipmentActions>
              <ButtonGroup>
                {editMode ? (
                  <Fragment>
                    <Button
                      data-test="check"
                      onClick={this.onApply}
                      icon="check"
                    />
                    <Button data-test="close" onClick={this.onCancel}>
                      <Icon type="x" />
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    data-test="edit"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <Icon type="edit" />
                  </Button>
                )}
              </ButtonGroup>
              <RemoveEquipmentButton
                onConfirm={(removeDate: moment | string) =>
                  onRemove({
                    ...this.props.optionalEquipment,
                    removeDate
                  })
                }
              />
            </EquipmentActions>
          )
        }
      >
        <SpoilerContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Наименование">{name}</Field>
            </GridItem>
            <GridItem>
              <Field label="Тип">{optionalEquipmentTypes[type]}</Field>
            </GridItem>
            <GridItem>
              <Field label="Марка">{brandName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Мнемокод">{code}</Field>
            </GridItem>
            {inventoryNumber && (
              <GridItem>
                <Field label="Инвентарный номер">{inventoryNumber}</Field>
              </GridItem>
            )}
            <GridItem>
              <Field label="Фактический расход топлива на допол. обор., маш/час">
                {editMode ? (
                  <InputNumber
                    name="actualFuelConsumption"
                    min={0}
                    value={actualFuelConsumption}
                    onChange={(value: number) =>
                      this.changeOptionalEquipment(
                        'actualFuelConsumption',
                        value
                      )
                    }
                  />
                ) : (
                  actualFuelConsumption
                )}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Дата установки">{formatDate(installDate)}</Field>
            </GridItem>
            <GridItem>
              <Field label="Дата снятия">{formatDate(removeDate)}</Field>
            </GridItem>
          </Grid>
        </SpoilerContent>
      </Spoiler>
    );
  }
}

export default OptionalEquipmentItem;
