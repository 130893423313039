// @flow
import React from 'react';

import notification from 'antd/lib/notification';
import {connect} from 'react-redux';
import {FormikProps} from 'formik';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import {Header, Section} from '../../../components/layout';
import {cleanVehicle, fetchVehicle, updateVehicle} from '../../../ducks/vehicle';
import type {Vehicle} from '../../../lib/types';
import type {AppState} from '../../../ducks/redux';
import Grid, {GridItem} from '../../../components/layout/Grid';
import Form from '../../../components/Form';
import {formatDateTimeToISOString, navigate} from '../../../lib/helpers';
import {notificationLoading} from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import Checkbox from 'antd/lib/checkbox';
import {canShowGpmData} from '../../VehicleModels/lib';
import Select from 'antd/lib/select';
import {inspectionTypes, inspectionTypesEnum} from '../../../lib/enum';
import VehicleSubCategorySelect from '../../../components/selects/VehicleSubCategorySelect';

const {Option} = Select;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Budget extends React.Component<Props> {
  async componentDidMount() {
    const {vehicleId} = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate(`/vehicles/${values.id}/documentation/budget`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/documentation/budget`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const {vehicle = {}} = this.props;
    const isGpm = canShowGpmData(vehicle?.vehicleModel?.type);
    return (
      <>
        <Header left={vehicle && <h1>Бюджет {vehicle.licensePlate}</h1>} />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {handleSubmit, setFieldValue, dirty, values} = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField
                        fast
                        label="Планируемая дата приобретения"
                        name="plannedPurchaseDate"
                      >
                        {({value, name}) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString),
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Планируемая дата списания"
                        name="plannedWriteoffDate"
                      >
                        {({value, name}) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString),
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {isGpm && (
                      <GridItem>
                        <FormField
                          fast
                          label="Исключить из затрат на ТО ПБ ГПМ"
                          name="excludeGpmMaintenanceCalculation"
                        >
                          {({value, name}) => (
                            <Checkbox
                              checked={value}
                              onChange={({target}) => setFieldValue(name, target.checked)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField fast label="Учет регистрации ТС для бюджета" name="inspectionType">
                        {({value, name}) => (
                          <Select
                            value={value}
                            onChange={newValue => setFieldValue(name, newValue)}
                            placeholder="Выберите вариант учета затрат"
                          >
                            {Object.keys(inspectionTypes).map(value => (
                              <Option key={value} value={value}>{inspectionTypesEnum[value]}</Option>
                            ))}
                          </Select>
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField fast label="Подкатегория ТС" name="vehicleSubCategoryId">
                        {({value, name}) => (
                          <VehicleSubCategorySelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                            filter={{vehicleCategoryId: values.vehicleModel?.categoryId}}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10),
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle,
  },
)(Budget);
