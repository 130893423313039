/* contractTrips.js | DTO | ПЛ НТС */
import { AssignmentLimit } from './assignmentLimit';
import { Contractor } from './contractor';
import type { VehicleTariff } from './vehicleTariff';

export type ContractTripVehicleLayout = {
  assigment: ?string,
  assignmentLimit?: AssignmentLimit,
  assignmentLimitId: ?number,
  contractTripMonth: ?ContractTripMonth, // eslint-disable-line
  contractTripMonthId: number,
  contractVehicleId: number,
  contractVehicleName: ?string,
  details: ?(any[]),
  distanceNormative: number,
  expenseDirection: ?any,
  expenseDirectionId: ?number,
  fuelPrice: number,
  fuelType: string,
  hoursNormative: number,
  id: number,
  originalContractVehicleId: ?number,
  originalContractVehicleName: ?string,
  waitingWithRunningEnginePrice: number,
  weekDaysBeforeChangeTariff: number,
  daysOffBeforeChangeTariff: number,
  weekDaysAfterChangeTariff: number,
  daysOffAfterChangeTariff: number,
};

export type ContractTripMonth = {
  contractTripVehicleLayots: ContractTripVehicleLayout[],
  contractTrips: ContractTrip[], // eslint-disable-line
  contractor: Contractor,
  contractorId: number,
  created: string,
  id: number,
  month: $Keys<monthsEnum>,
  orgUnitAddress: ?string,
  orgUnitId: number,
  orgUnitInn: ?string,
  orgUnitKpp: ?string,
  orgUnitMnemonic: string,
  orgUnitName: string,
  orgUnitOgrn: ?string,
  orgUnitPhone: ?string,
  parentOrgUnitMnemonic: ?string,
  status: $Keys<newContractTripStatusEnum>,
  year: number,
  cognosVehicleTariffsStatus: CognosSendingStatus,
  cognosVehicleLayoutStatus: CognosSendingStatus,
  VehicleTariffId: number,
};

export const ContractTripError = {
  notError: 'Без ошибок',
  vehicleNotFound: 'ТС не найдено',
  tariffNotFound: 'Тариф не найден',
  tariffNotMatch: 'Тариф не совпадает'
};

export type ContractTrip = {
  contractTripMonth: ContractTripMonth,
  contractTripMonthId: number,
  contractVehicleId: number,
  distance: number,
  distancePrice: number,
  distancePriceBackup: number,
  distanceWithTrailer: number,
  distanceWithTrailerPrice: number,
  distanceWithTrailerPriceBackup: number,
  error: $Keys<ContractTripError>,
  endDate: string,
  errorDescription: ?string,
  fuelType: string,
  heaterPrice: number,
  heaterPriceBackup: number,
  heaterTime: number,
  id: number,
  licensePlate: string,
  movementPrice: number,
  movementPriceBackup: number,
  movementTime: number,
  number: number,
  preheaterPrice: number,
  preheaterPriceBackup: number,
  preheaterTime: number,
  startDate: string,
  sum: number,
  totalWorkTime: number,
  vehicleBrand: string,
  vehicleModel: string,
  vehicleProductionYear: number,
  vehicleType: string,
  vehicleTariff: VehicleTariff,
  vehicleTariffId: number,
  waitingPrice: number,
  waitingPriceBackup: number,
  waitingTime: number,
  waitingWithRunningEnginePrice: number,
  waitingWithRunningEnginePriceBackup: number,
  waitingWithRunningEngineTime: number,
  powerGeneratorTime: number,
  powerGeneratorPrice: number,
  powerGeneratorPriceBackup: number,
  winchTime: number,
  winchPrice: number,
  winchPriceBackup: number,
  originalContractVehicleId: ?number,
  originalContractVehicleName: ?string,
  excelNumber: number,
  expenseDirectionId: ?number,
  expenseDirectionName: ?string,
  parentId: ?number,
  isOriginalTariff: boolean,
};

export type ContractTripExpenseDirection = {
  contractVehicleId: number,
  licensePlate: string,
  expenseDirectionId: number,
  contractTripMonthId: number,
}