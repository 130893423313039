// @flow
import React from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import ReactJSON from 'react-json-view';

import type { MdmNode } from '../../../lib/types/mdm';
import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Button from 'antd/lib/button';
import CancelButton from '../../../components/CancelButton';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  onSubmit: (node: MdmNode) => Promise<void>,
  onCancel: () => void,
  mdmNode: MdmNode
};

export default ({ onSubmit, onCancel, mdmNode }: Props) => (
  <Form onSubmit={onSubmit} initialValues={mdmNode}>
    {(FormField: FormFieldType, formikProps: FormikProps) => {
      const { handleSubmit, setFieldValue, dirty, isSubmitting } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px" cols={3}>
              <GridItem>
                <FormField name="name" required label="Наименование">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="mnemocode" required label="Мнемокод">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField name="currentCost" required label="Текущая цена">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem fullWidth>
                <FormField name="payload" required label="Модель">
                  {({ name, value }) => (
                    <ReactJSON
                      src={value}
                      name={false}
                      onEdit={({ updated_src }) =>
                        setFieldValue(name, updated_src)
                      }
                      onAdd={({ updated_src }) =>
                        setFieldValue(name, updated_src)
                      }
                      displayDataTypes={false}
                      onDelete={({ updated_src }) =>
                        setFieldValue(name, updated_src)
                      }
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);
