// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { contractApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { ActFilterParams } from './../../containers/Act';

type Props = SelectProps & { filter: ActFilterParams };

export default React.memo<Props>(({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await contractApi.fetchContracts({
        ...filter,
        ...params
      });
    }}
    fetchOnFocus
    refetchParams={{ filter }}
    fetchSingle={contractApi.getContract}
    notFoundText="Договоры не найдены"
    placeholder="Выберите договор"
    renderOption={(contract: any, Option) => (
      <Option key={contract.id} value={contract.id} contract={contract}>
        {contract.documentNumber
          ? contract.documentNumber
          : contract.accountNumber}
      </Option>
    )}
    {...selectProps}
  />
));
