// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';
import { Link } from '@reach/router';

import type {
  FuelAndOil,
  FuelAndOilView,
  FuelAndOilSubView,
  FuelAndOilType,
  FuelAndOilSubType
} from './../../lib/types/fuelAndOil';
import {
  fuelAndOilView,
  fuelAndOilSubView,
  fuelAndOilType,
  fuelAndOilSubType
} from './../../lib/enum';
import { navigate } from '../../lib/helpers';
import { fetchFuelsAndOils } from '../../ducks/fuelsAndOils';
import { deleteFuelAndOil } from '../../ducks/fuelAndOil';
import type { AppState } from '../../ducks/redux';
import { Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import type { sortOrder } from '../../components/ui/Table';
import { notificationLoading } from './../../components/Notifications';

type Props = {
  fuelsAndOils: Array<FuelAndOil>,
  fetchFuelsAndOils: Function,
  deleteFuelAndOil: Function,
  page: number,
  pageSize: number,
  totalCount: number,
  location: any
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class FuelAndOilList extends PureComponent<Props> {
  async deleteFuelAndOil(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await this.props.deleteFuelAndOil(id);
      await this.fetchFuelsAndOils(this.props.page);
      notification.success({
        message: 'Успешное удаление',
        description: `ГСМ с идентификатором ${id} был успешно удален`
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  fetchFuelsAndOils = async (
    page: number,
    orderBy?: string,
    order?: sortOrder
  ) => {
    await this.props.fetchFuelsAndOils(page, {
      orderBy,
      byDescending: order === 'descend'
    });
  };

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Вид',
      dataIndex: 'view',
      sorter: true,
      render: (view: FuelAndOilView) => fuelAndOilView[view]
    },
    {
      title: 'Подвид',
      dataIndex: 'subView',
      sorter: true,
      render: (subView: FuelAndOilSubView) => fuelAndOilSubView[subView]
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      sorter: true,
      render: (type: FuelAndOilType) => fuelAndOilType[type]
    },
    {
      title: 'Подтип',
      dataIndex: 'subType',
      sorter: true,
      render: (subType: FuelAndOilSubType) => fuelAndOilSubType[subType]
    },
    {
      title: '',
      dataIndex: 'operations',
      sorter: true,
      // eslint-disable-next-line no-unused-vars
      render: (text: string, model: FuelAndOil) => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => this.deleteFuelAndOil(model.id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      )
    }
  ];

  handleRowClick = (id: number) => navigate(`/admin/fuels-and-oils/${id}`);

  render() {
    const { fuelsAndOils, page, pageSize, totalCount, location } = this.props;
    return (
      <>
        <Header
          left={<h1>ГСМ</h1>}
          right={
            <Link to="/admin/fuels-and-oils/new">
              <Button type="primary" data-cy="addFuels">
                Создать
              </Button>
            </Link>
          }
        />
        <Section>
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            columns={this.columns}
            data={fuelsAndOils.map(model => ({
              ...model,
              key: model.id
            }))}
            fetch={this.props.fetchFuelsAndOils}
            fetchOnMount
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    fuelsAndOils: state.fuelsAndOils.data,
    page: state.fuelsAndOils.page,
    pageSize: state.fuelsAndOils.pageSize,
    totalCount: state.fuelsAndOils.totalCount
  }),
  {
    fetchFuelsAndOils,
    deleteFuelAndOil
  }
)(FuelAndOilList);
