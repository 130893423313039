// @flow
import React from 'react';
import Button from 'antd/lib/button';
import {MaintenanceWorkApi} from '../../lib/api';
import {getListInitialState, navigate} from '../../lib/helpers';
import type {ListState, MaintenanceWork, UserAccess} from '../../lib/types';
import Table from '../../components/ui/Table';
import {Header, Section} from '../../components/layout';
import notification from 'antd/lib/notification';
import {accessTypeEnum, newEntityStatuses} from '../../lib/enum';
import qs from 'query-string';
import MaintenanceWorkFilter from './components/Filter';
import Popconfirm from 'antd/lib/popconfirm';
import {Icon} from '../../components/ui';
import withUserAccess from '../withUserAccess/withUserAccess';

type Props = {
  userAccess: UserAccess[],
};

type State = ListState<MaintenanceWork> & {
  filter: any,
};

export class List extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };
  
  columns = [
    {
      title: '№',
      dataIndex: 'id',
    },
    {
      title: 'Код работы',
      dataIndex: 'code',
    },
    {
      title: 'Наименование работы',
      dataIndex: 'name',
    },
    {
      title: 'Модель',
      dataIndex: 'vehicleModelMatch.modelName',
    },
    {
      title: 'Марка',
      dataIndex: 'vehicleModelMatch.brandName',
    },
    {
      title: 'Норма-часы',
      dataIndex: 'hoursNorm',
    },
    {
      title: 'Статус работы',
      dataIndex: 'status',
      render: status => newEntityStatuses[status],
    },
    {
      stopPropagation: true,
      width: 20,
      dataIndex: 'id',
      key: 'delete',
      render: (id: number) => {
        if (this.canAdd()) {
          return (
            <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={() => this.handleDelete(id)}
            >
              <Icon type="x" />
            </Popconfirm>
          )
        }
        return null;
      }
    },
  ];
  
  componentDidMount = async () => {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({filter, page: page || 1}, () => this.fetch(page || 1));
  };
  
  fetch = async (page: number) => {
    try {
      const {pageSize, filter} = this.state;
      this.setState({loading: true});
      const result = await MaintenanceWorkApi.fetch({page, pageSize, ...filter});
      this.setState({...result, loading: false, page});
    } catch (e) {
      notification.error({message: 'Ошибка', description: e.title || e.message});
    }
  };
  
  applyFilter = (filter) => this.setState({filter}, this.fetch);
  
  clearFilter = () => this.setState({filter: {}}, this.fetch);
  
  handleDelete = async (id) => {
    try {
      await MaintenanceWorkApi.delete(id);
      notification.success({
        message: 'Запись удалена',
      });
      this.fetch(this.state.page);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  }
  
  canAdd = () => [
    accessTypeEnum.admin,
    accessTypeEnum.approvementMaintenanceWork,
  ].some(access => this.props.userAccess.includes(access));
  
  render() {
    const {data, totalCount, pageSize, page, loading, filter} = this.state;
    return (
      <>
        <Header
          left={<h1>Работы по ТО</h1>}
          right={this.canAdd() && (
            <Button
              type="primary"
              onClick={() => navigate('/admin/maintenance-works/new')}
            >
              Создать
            </Button>
          )}
        />
        <Section>
          <MaintenanceWorkFilter
            applyFilter={this.applyFilter}
            cleanFilter={this.clearFilter}
            filter={filter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetch}
            onRow={(row: MaintenanceWork) => ({onClick: () => navigate(`/admin/maintenance-works/${row.id}`)})}
            loading={loading}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(List);
