import orderBy from 'lodash/orderBy';

import type { WayPoint } from './types';

export const MAPBOX_API_KEY = `${process.env.REACT_APP_MAPBOX_API_KEY || ''}`;
export const YANDEX_API_KEY = `${process.env.REACT_APP_YANDEX_MAPS_API_KEY || ''}`;
export const YANDEX_API_SUGGEST_KEY = `${process.env.REACT_APP_YANDEX_MAPS_API_SUGGEST_KEY || ''}`;

window.onload = () => {
  let targetYandex = document.querySelector('#targetYandex');
  const yandexScript = document.createElement('script');
  yandexScript.setAttribute(
    'src',
    `https://api-maps.yandex.ru/2.1/?apikey=${YANDEX_API_KEY}&lang=ru_RU&suggest_apikey=${YANDEX_API_SUGGEST_KEY}`
  );
  yandexScript.setAttribute('type', 'text/javascript');
  yandexScript.addEventListener('load', () => {});
  targetYandex.insertAdjacentElement('beforeBegin', yandexScript);
};

export const geocode = async (lat: number, long: number): Promise<string> => {
  return window.ymaps
    ? await window.ymaps
        .geocode(`${lat},${long}`, { options: { json: true } })
        .then(function(res) {
          // Выведем в консоль данные, полученные в результате геокодирования объекта.
          return res.geoObjects
            .get(0)
            .properties.getAll().metaDataProperty.GeocoderMetaData.text;
        })
    : undefined;
};

export const getYmapPlace = async (request: string): Promise => {
  let res = await window.ymaps?.geocode(request);
  res = res?.geoObjects?.get(0).properties.getAll().boundedBy;
  return {
    lat: res[0][0] + 0.002343,
    lng: res[0][1] + 0.004105
  };
};

export type Direction = {
  // дистаниция в метрах
  distance: number,
  // геометрия в geoJSON
  geoJSON: any,
  // нулевой пробег до объекта
  distanceAtStart: number,
  // нулевой пробег до места стоянки
  distanceAtEnd: number,
  // boundung box - прямоугольник вокруг маршрута
  bounds?: {
    northeast: {
      lat: number,
      long: number
    },
    southwest: {
      lat: number,
      long: number
    }
  }
};

export const directions = async (waypoints: WayPoint[]): Promise<Direction> => {
  if (waypoints.length < 2) {
    throw new Error('At least two wayoints required');
  }
  if (waypoints.length >= 10) {
    throw new Error('Maps platform allows max 10 waypoint');
  }
  // нам важен порядок точек, чтобы построить правильные ребра графа
  const orderedWaypoint = orderBy(waypoints, [
    'departureDateTime',
    'arrivedDateTime'
  ]);

  const origin = `${orderedWaypoint[0].latitude},${orderedWaypoint[0].longitude}`;
  const destination = `${
    orderedWaypoint[orderedWaypoint.length - 1].latitude
  },${orderedWaypoint[orderedWaypoint.length - 1].longitude}`;

  const transitPoints = [];

  if (orderedWaypoint.length > 2) {
    // если точек больше чем 2, то из промежуточных нужно сделать отедельный параметр запрос
    for (let i = 1; i <= orderedWaypoint.length - 2; i++) {
      transitPoints.push(
        `${orderedWaypoint[i].latitude},${orderedWaypoint[i].longitude}`
      );
    }
  }
  let ymapRoute = [{ type: 'wayPoint', point: origin.split(',') }];
  transitPoints.forEach(point =>
    ymapRoute.push({ type: 'viaPoint', point: point.split(',') })
  );
  ymapRoute.push({ type: 'wayPoint', point: destination.split(',') });
  let distance = await window.ymaps?.route(ymapRoute);
  let distanceAtStart = await window.ymaps?.route(ymapRoute.slice(0, 2));
  let distanceAtEnd = await window.ymaps?.route(ymapRoute.slice(-2));
  let coordinates = [];
  if (distance) {
    distance.getPaths().each(function(path) {
      coordinates = coordinates.concat(path.geometry.getCoordinates());
    });
  }
  return {
    distance: distance?.getLength(),
    distanceAtStart: distanceAtStart?.getLength(),
    distanceAtEnd: distanceAtEnd?.getLength(),
    geoJSON: {
      type: 'LineString',
      coordinates: coordinates
    }
  };
};

export const getYmapPlaces = async (address: string): Promise => {
  let suggests = await window.ymaps?.suggest(address, {
    boundedBy: [
      [37.543063989549644, 71.543063989549644],
      [55.543063989549644, 168.543063989549644]
    ]
  });
  return suggests
    ? suggests.map(suggest => {
        return {
          address: suggest.value,
          name: suggest.displayName,
          radius: 1
        };
      })
    : [];
};

export const getCurrentPosition = async (): Promise<Position> =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
