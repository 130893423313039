// @flow
import React from 'react';
import {FormikProps} from 'formik';
import Modal from 'antd/lib/modal';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type {FormFieldType} from '../../../../components/Form';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import {StyledInputNumber} from '../../../ContractVehicleWorkPlans/TimeLimit/components/elements';
import {Form} from '../../../../components';
import EmployeeSelect from '../../../../components/selects/EmployeeSelect';

import {convertDateToString} from '../../../Vehicles/lib';
import type {BatteryWorkCard} from '../../../../lib/types/batteryWorkCard';
import {withUserAccess} from '../../../withUserAccess';

type Props = {
  visible: boolean,
  cardValues?: BatteryWorkCard,
  onClose: () => void,
  onSave: () => void,
  employeeBranchOrgUnitId: number,
  isAdmin: boolean,
  isDecomissed: boolean
};

type FormProps = {
  onSubmit: () => void,
  initialValues: BatteryWorkCard,
  employeeBranchOrgUnitId: number,
  isAdmin: boolean,
  isDecomissed: boolean
};

const InnerForm = ({
  onSubmit,
  initialValues = {},
  employeeBranchOrgUnitId,
  isAdmin,
  isDecomissed
}: FormProps) => {
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, setFieldValue, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Номер АКБ" name="number">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата изготовления АКБ"
                  required
                  name="manufactureDate"
                >
                  {({ name, value }) => (
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={value ? moment(value) : null}
                      onChange={(value, dateString) => {
                        setFieldValue(
                          name,
                          convertDateToString(value, dateString)
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <FormField label="Изготовитель АКБ" required name="manufacturer">
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Нормативная наработка АКБ до списания"
              required
              name="normativeWorking"
            >
              {({ name, value }) => (
                <StyledInputNumber
                  name={name}
                  value={value}
                  onChange={(value: number) => setFieldValue(name, value)}
                />
              )}
            </FormField>
            <FormField
              label="Причина списания"
              required
              name="decomissionReason"
            >
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="АКБ при выдаче из отделения: плотность электролита, г/куб. см"
              required
              name="electrolyteDensity"
            >
              {({ name, value }) => (
                <StyledInputNumber
                  name={name}
                  value={value}
                  onChange={(value: number) => setFieldValue(name, value)}
                />
              )}
            </FormField>
            <FormField
              label="Заключение по списанию АКБ"
              required
              name="conclusion"
            >
              {field => <Input {...field} />}
            </FormField>
            <FormField
              label="Председатель комиссии"
              required
              name="commissionChairmanEmployeeId"
            >
              {({ name, value }) => (
                <EmployeeSelect
                  onChange={value => setFieldValue(name, value)}
                  value={value}
                  filter={{ nodeId: employeeBranchOrgUnitId }}
                />
              )}
            </FormField>
            <FormField
              label="Члены комиссии"
              required
              name="commissionMemberEmployeeIds"
            >
              {({ name }) => (
                <EmployeeSelect
                  mode="multiple"
                  allowClear={false}
                  onChange={value => setFieldValue(name, value)}
                  value={values[name]}
                  filter={{ nodeId: employeeBranchOrgUnitId }}
                />
              )}
            </FormField>
            <FormField label="Дата" required name="date">
              {({ name, value }) => (
                <DatePicker
                  format="DD.MM.YYYY"
                  value={value ? moment(value) : null}
                  onChange={(value, dateString) => {
                    setFieldValue(name, convertDateToString(value, dateString));
                  }}
                  disabled={!isAdmin && isDecomissed}
                />
              )}
            </FormField>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </form>
        );
      }}
    </Form>
  );
};

const WorkCardModal = ({
  visible,
  onSave,
  onClose,
  cardValues,
  employeeBranchOrgUnitId,
  isAdmin,
  isDecomissed
}: Props) => {
  return (
    <Modal
      destroyOnClose
      title={'Карточка учета работы АКБ'}
      visible={visible}
      footer={false}
      width={700}
      onCancel={onClose}
    >
      <InnerForm
        initialValues={cardValues}
        onSubmit={onSave}
        employeeBranchOrgUnitId={employeeBranchOrgUnitId}
        isAdmin={isAdmin}
        isDecomissed={isDecomissed}
      />
    </Modal>
  );
};


export default withUserAccess(WorkCardModal);
