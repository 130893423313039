// @flow
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import type {FormikProps} from 'formik';
import * as yup from 'yup';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import Upload, {UploadProps} from 'antd/lib/upload';
import Icon from 'antd/lib/icon';
import message from 'antd/lib/message';
import {apiUrl, ContractorBranchLinkApi} from '../../../lib/api';
import type {ContractVehicle} from '../../../lib/types';
import tokenManager from './../../../lib/tokenManager';
import {gpmVehicleTypes} from '../../../lib/enum';
import {licenseNumberValidate, licensePlateValidate, stsValidate, vinValidate} from '../../../lib/validation';
import {Form, OrgUnitSelect, Selects} from './../../../components';
import {Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {LicensePlateInput, PhoneInput, VINInput} from './../../../components/masked-inputs';
import CancelButton from './../../../components/CancelButton';
import {CONTRACT_VEHICLE_TYPE_DATE, REQUIRED_CONTRACT_VEHICLE_TYPE} from '../lib';
import {VehicleOwnerTypesEnum} from '../../../lib/types/vehicleModel';

const StyledGrid = styled(Grid)`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const {
  VehicleModelSelect,
  LocationSelect,
  VehicleStatusSelect,
  ContractVehicleTypeSelect,
  YesNoSelect,
} = Selects;

const beforeUpload = function(
  file: UploadProps,
  setFieldValue: Function,
  fieldName: string,
) {
  const isImage = file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png' ||
    file.type === 'image/webp';
  if (!isImage) {
    message.error('Загружать можно только изображения!');
  }
  const isLt2M = file.size / 1024 / 1024 < 32;
  if (!isLt2M) {
    message.error('Картинка должна быть менее 32MB!');
  }

  const canUpload = isImage && isLt2M;
  if (canUpload) {
    setFieldValue(fieldName, file);
  }
  return canUpload;
};

type Props = {
  vehicle: ContractVehicle,
  onSubmit: Function,
  onCancel: Function,
  changeDate: Function,
  formatDate: Function,
  employeeBranchOrgUnitId: number,
  sectionStyle?: any,
  hideFields?: string[]
};

export default ({
  onSubmit,
  vehicle,
  onCancel,
  changeDate,
  formatDate,
  employeeBranchOrgUnitId,
  sectionStyle = {},
  hideFields = [],
}: Props) => (
  <Form
    onSubmit={onSubmit}
    initialValues={vehicle || {}}
    validationSchema={yup.object().shape({
      vehicle: yup.object().shape({
        licensePlate: licensePlateValidate.nullable(),
        vin: vinValidate.nullable(),
        sts: stsValidate.nullable(),
      }),
      licenseNumber: licenseNumberValidate,
    })}
  >
    {(FormField, formikProps: FormikProps) => {
      let {
        handleSubmit,
        handleBlur,
        setFieldValue,
        handleFocus,
        values,
        dirty,
        isSubmitting,
      } = formikProps;
      const {vehicle} = values;
      return (
        <form onSubmit={handleSubmit}>
          <Section style={sectionStyle}>
            <SectionTitle>Первичные данные</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem style={{overflow: 'hidden'}}>
                <FormField
                  fast
                  label="Модель ТС"
                  name="vehicle.vehicleModelId"
                  required
                >
                  {({value, name}) => (
                    <VehicleModelSelect
                      value={value}
                      name={name}
                      data-cy="vehicleModelSelect"
                      onBlur={() => handleBlur({target: {name}})}
                      onChange={async (value: number) =>
                        setFieldValue(name, value)
                      }
                      type={VehicleOwnerTypesEnum.contract}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  fast
                  label="Год выпуска"
                  name="vehicle.yearIssued"
                  required
                >
                  {({value, name}) => (
                    <InputNumber
                      id="yearIssued"
                      min={1900}
                      max={moment().year()}
                      value={value}
                      name={name}
                      onChange={(value: number) => setFieldValue(name, value)}
                      onBlur={handleBlur}
                    />
                  )}
                </FormField>
              </GridItem>
              {!hideFields.includes('type') && (
                <GridItem>
                  <FormField label="Тип НТС" name="type" required>
                    {({name, value}) => (
                      <ContractVehicleTypeSelect
                        value={value}
                        onChange={value => {
                          setFieldValue(name, value);
                          setFieldValue('vehicle.status', undefined);
                          setFieldValue('startDate', undefined);
                          setFieldValue('endDate', undefined);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
              {values.id && (
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField label="Статус ТС" name="vehicle.status" required>
                    {({value, name}) => (
                      <VehicleStatusSelect
                        disabled={!values.type}
                        contractType={values.type}
                        name={name}
                        data-cy="vehicleStatusSelect"
                        placeholder="Выберите статус ТС"
                        onChange={(value: string) => setFieldValue(name, value)}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}

              {CONTRACT_VEHICLE_TYPE_DATE.includes(values.type) && (
                <>
                  <GridItem>
                    <FormField
                      label="Дата начала действия статуса"
                      name="startDateStatus"
                      required
                    >
                      {({name, value}) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                              .utc(value)
                              .startOf('day')
                              .toISOString(),
                            );
                            setFieldValue('endDate', undefined);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата окончания действия статуса"
                      required
                      name="endDateStatus"
                    >
                      {({name, value}) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={!values.startDateStatus}
                          disabledDate={(date: string) =>
                            values.startDateStatus
                              ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(
                                moment.utc(values.startDateStatus),
                              )
                              : false
                          }
                          onChange={(value: string) =>
                            setFieldValue(
                              name,
                              moment
                              .utc(value)
                              .startOf('day')
                              .toISOString(),
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </>
              )}

              <GridItem>
                <FormField label="Установлено ГБО" name="hasGBO" required>
                  {({name, value}) => (
                    <YesNoSelect
                      placeholder="Выберите значение"
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Лизинг" name="leasing" required>
                  {({name, value}) => (
                    <YesNoSelect
                      placeholder="Выберите значение"
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </StyledGrid>

            <SectionTitle>Номера</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <FormField
                  label="Гос. номер"
                  name="vehicle.licensePlate"
                  required={
                    !REQUIRED_CONTRACT_VEHICLE_TYPE.includes(values.type)
                  }
                >
                  {field => <LicensePlateInput {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField fast label="VIN" name="vehicle.vin">
                  {field => <VINInput {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  fast
                  label="Серийный номер"
                  name="vehicle.serialNumber"
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  fast
                  label="Гаражный номер ТС подрядчика"
                  name="vehicle.firstBitGarageNumber"
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              {vehicle &&
                vehicle.vehicleModel &&
                gpmVehicleTypes.includes(vehicle.vehicleModel.type) && (
                  <GridItem>
                    <FormField
                      label="Регистрационный номер подъемного сооружения"
                      fast
                      required
                      name="vehicle.gpmRegistrationNumber"
                    >
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                )}
              <GridItem>
                <FormField
                  fast
                  label="ГЛОНАСС/GPS-трекер"
                  name="vehicle.deviceId"
                >
                  {field => (
                    <Input
                      {...field}
                      placeholder="Введите идентификатор блока"
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Имя конфигурационного файла для АвтоГРАФ"
                  fast
                  name="vehicle.configFileName"
                >
                  {field => (
                    <Input
                      {...field}
                      placeholder="Например, БЭС - СМиА.ini"
                      disabled
                    />
                  )}
                </FormField>
              </GridItem>
            </StyledGrid>

            <SectionTitle>Прикрепление</SectionTitle>
            <StyledGrid gutter="16px">
              {values.contractor && (
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField fast label="Подрядчик" name="contractor.company.name">
                    {({value}) => value}
                  </FormField>
                </GridItem>
              )}
              {!hideFields.includes('orgUnitId') && (
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField
                    fast
                    label="Служба закрепления/РЭС"
                    required
                    name="vehicle.orgUnitId"
                  >
                    {({value, name}) => (
                      <OrgUnitSelect
                        value={`${value || ''}`}
                        onChange={async (value: number) => {
                          const id = parseInt(value, 10);
                          setFieldValue(name, id);
                          const contractor = await ContractorBranchLinkApi.getContractorByOrganization(id);
                          if (contractor) {
                            setFieldValue('contractorId', contractor.id);
                            setFieldValue('contractor.company.name', contractor.name);
                          }
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
              <GridItem>
                <FormField fast label="Населенный пункт базирования ТС" name="vehicle.locationId">
                  {({value, name}) => (
                    <LocationSelect
                      geolocationApi={false}
                      placeholder="Выберите место стоянки"
                      onChange={(value: string) => setFieldValue(name, value)}
                      onFocus={handleFocus}
                      name={name}
                      data-cy="locationSelect"
                      filter={{
                        isDefault: true,
                        nodeId: employeeBranchOrgUnitId,
                        nodeFilterType: 'branchAndChildren',
                      }}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
              {!hideFields.includes('usingNodeId') && (
                <GridItem style={{overflow: 'hidden'}}>
                  <FormField
                    fast
                    label="Подразделение использования"
                    required
                    name="vehicle.usingNodeId"
                  >
                    {({value, name}) => (
                      <OrgUnitSelect
                        value={`${value || ''}`}
                        onChange={(value: number) => {
                          const id = parseInt(value, 10);
                          setFieldValue(name, id);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
            </StyledGrid>

            <SectionTitle>Водители</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <FormField fast label="Имя" name="primaryDriverFirstname">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField fast label="Фамилия" name="primaryDriverLastname">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField fast label="Отчество" name="primaryDriverMiddlename">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  fast
                  label="Контактный номер"
                  name="primaryContactNumber"
                >
                  {field => <PhoneInput {...field} />}
                </FormField>
              </GridItem>
            </StyledGrid>

            {values.ovbType && (
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Имя второго водителя"
                    name="secondaryDriverFirstname"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Фамилия второго водителя"
                    name="secondaryDriverLastname"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Отчество"
                    name="secondaryDriverMiddlename"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Контактный номер второго водителя"
                    name="secondaryContactNumber"
                  >
                    {field => <PhoneInput {...field} />}
                  </FormField>
                </GridItem>
              </StyledGrid>
            )}

            {values.ovbType && (
              <StyledGrid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    label="Имя третьего водителя"
                    name="tertiaryDriverFirstname"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Фамилия третьего водителя"
                    name="tertiaryDriverLastname"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Отчество третьего водителя"
                    name="tertiaryDriverMiddlename"
                  >
                    {field => <Input {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Контактный номер третьего водителя"
                    name="tertiaryContactNumber"
                  >
                    {field => <PhoneInput {...field} />}
                  </FormField>
                </GridItem>
              </StyledGrid>
            )}

            <SectionTitle>Пропуск</SectionTitle>
            <StyledGrid gutter="16px">
              <GridItem>
                <FormField
                  label="Наименование действующего пропуска"
                  name="passName"
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Номер пропуска" name="passNumber">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата окончания пропуска"
                  name="passEndDate"
                >
                  {({value, name}) => (
                    <DatePicker
                      format="DD.MM.YYYY"
                      value={formatDate(value)}
                      name={name}
                      onChange={(value: Object, dateString: string) => {
                        setFieldValue(name, changeDate(value, dateString));
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Сумма уплаченная за пропуск, руб."
                  name="passCost"
                >
                  {({value, name}) => (
                    <InputNumber
                      min={0}
                      value={value}
                      name={name}
                      onChange={(value: number) => setFieldValue(name, value)}
                      onBlur={handleBlur}
                    />
                  )}
                </FormField>
              </GridItem>
            </StyledGrid>

            <StyledGrid gutter="16px">
              <GridItem>
                <FormField fast label="Фото" name="vehicle.posterImage">
                  {({value, name}) => (
                    <StyledUpload
                      action={`${apiUrl}/file`}
                      listType="picture"
                      beforeUpload={(file: UploadProps) =>
                        beforeUpload(file, setFieldValue, name)
                      }
                      headers={{
                        Authorization: `Bearer ${tokenManager.getToken()}`,
                      }}
                      fileList={
                        value
                          ? [value].map(item =>
                            item.uid ? item : {...item, uid: item.id},
                          )
                          : []
                      }
                      onChange={({file}) => {
                        if (file.status === 'removed') {
                          setFieldValue(name, null);
                        } else {
                          if (file.status === 'done' && file.response) {
                            const response = file.response;
                            let newFile = {
                              url: response.url,
                              name: response.fullName,
                              id: response.id,
                              uid: file.uid,
                              path: response.path,
                            };
                            setFieldValue(name, newFile);
                          }
                        }
                      }}
                    >
                      <Button>
                        <Icon type="upload" /> Загрузить
                      </Button>
                    </StyledUpload>
                  )}
                </FormField>
              </GridItem>
            </StyledGrid>
          </Section>
          <Footer style={sectionStyle}>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    float: left;
    width: 100%;
    margin-right: 8px;
    height: 118px;

    img {
      height: 100px;
      width: auto;
      object-fit: cover;
    }
  }

  .ant-upload-animate-enter {
    animation-name: uploadAnimateInlineIn;
  }

  .ant-upload-animate-leave {
    animation-name: uploadAnimateInlineOut;
  }
`;
