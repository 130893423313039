// @flow
import React from 'react';
import type { VehiclePlan } from '../../../../../lib/types';
import { getLabel } from '../../Budget/components/Tab';
import { TabItem, Tabs } from './../../../../../components/ui';

type Props = {
  vehiclePlanId: number,
  vehiclePlan?: VehiclePlan
};

export default ({ vehiclePlanId, vehiclePlan }: Props) => {
  return (
    <Tabs withRouter>
      <TabItem
        label={getLabel('Текущий ремонт', [
          vehiclePlan?.currentRepairCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/current-repair`}
      />
      <TabItem
        label={getLabel('Капитальный ремонт', [
          vehiclePlan?.overhaulCalculationStatus
        ])}
        url={`/budget/vehicle/${vehiclePlanId}/overhaul-repair`}
      />
      <TabItem
        label={getLabel('Бюджет по ремонтам', undefined)}
        url={`/budget/vehicle/${vehiclePlanId}/budget-repair`}
      />
    </Tabs>
  );
};
