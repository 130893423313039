// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';

import type { MeasuringDeviceCertification } from './../../../lib/types';
import { measuringDeviceCertificationApi } from '../../../lib/api';

import { Icon, ListTable } from '../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

import { COMMON_MAINTENANCE_COLUMNS } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  measuringDeviceCertifications: MeasuringDeviceCertification[]
};

class MeasuringDeviceCertificationList extends Component<Props, State> {
  state = {
    loading: false,
    measuringDeviceCertifications: []
  };

  async componentDidMount() {
    this.fetchMeasuringDeviceCertificationes();
  }

  fetchMeasuringDeviceCertificationes = async () => {
    try {
      this.setState({
        loading: true
      });
      const measuringDeviceCertifications = await measuringDeviceCertificationApi.fetchVehicleMeasuringDeviceCertifications(
        this.props.vehicleId
      );
      this.setState({
        measuringDeviceCertifications: measuringDeviceCertifications.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  onDelete = async (
    measuringDeviceCertification: MeasuringDeviceCertification
  ) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (measuringDeviceCertification.id) {
        await measuringDeviceCertificationApi.deleteMeasuringDeviceCertification(
          measuringDeviceCertification.id
        );
        notification.success({
          message: `Сертификация средств измерений успешно удален`
        });
        await this.fetchMeasuringDeviceCertificationes();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { measuringDeviceCertifications, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          ...COMMON_MAINTENANCE_COLUMNS,
          {
            width: '20px',
            renderRecord: (record: MeasuringDeviceCertification) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${
                      this.props.vehicleId
                    }/measuring-device-certifications/${parseInt(record.id)}`}
                  >
                    <StyledAntIcon type="edit" />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null
          }
        ]}
        data={measuringDeviceCertifications}
      />
    );
  }
}

export default MeasuringDeviceCertificationList;
