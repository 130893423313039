// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { mdmApi } from './../../lib/api';
import { formatRub } from './../../lib/helpers';
import AutocompleteSelect from './AutocompleteSelect';
import type { MdmFilterParams } from '../../containers/Mdm/components/Filter';

type Props = SelectProps & {
  filter?: MdmFilterParams,
  hideMnemocode?: boolean,
  disableNonePrice?: boolean,
  excludeIds?: number[]
};

export default ({
  filter = {},
  resultAsObject = false,
  hideMnemocode = false,
  disableNonePrice = false,
  excludeIds = [],
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    style={{ maxWidth: '100%' }}
    fetch={async params => {
      let data = await mdmApi.fetchMdmList({
        ...filter,
        ...params
      });
      data = {
        ...data,
        data: data.data.filter(mdm => !excludeIds.includes(mdm.id))
      };
      return data;
    }}
    fetchSingle={mdmApi.fetchMdmNode}
    notFoundText="Код не найден"
    placeholder="Выберите код мтр"
    renderOption={(mdm: any, Option) => (
      <Option
        key={mdm.mnemocode}
        value={mdm.mnemocode}
        mdm={mdm}
        disabled={disableNonePrice && !mdm.currentCost}
      >
        {(hideMnemocode ? '' : `${mdm.mnemocode} - `) + mdm.name + '; '}
        {mdm.currentCost ? (
          <>
            Цена:{' '}
            <span style={{ color: '#2770ff' }}>
              {formatRub(mdm.currentCost)}
            </span>
          </>
        ) : (
          <span style={{ color: '#ec4444' }}>нет цены</span>
        )}
      </Option>
    )}
    {...selectProps}
  />
);
