// @flow

import type {Vehicle} from '../types';
import {VehicleWorkMeter} from '../types/vehicleWorkMeter';
import {fetchRequest} from './index';
import {convertFromHourToSecond, convertFromKmToM, convertFromMToKm, convertFromSecondToHour} from '../helpers';

export const convertDataToServer = (workMeter: VehicleWorkMeter) => ({
  ...workMeter,
  newMachineHoursIndications: convertFromHourToSecond(workMeter.newMachineHoursIndications),
  newOdometrIndications: convertFromKmToM(workMeter.newOdometrIndications),
  previosMachineHoursIndications: convertFromHourToSecond(workMeter.previosMachineHoursIndications),
  previosOdometrIndications: convertFromKmToM(workMeter.previosOdometrIndications),
});

export const convertDataFromServer = (workMeter: VehicleWorkMeter) => ({
  ...workMeter,
  newMachineHoursIndications: convertFromSecondToHour(workMeter.newMachineHoursIndications),
  newOdometrIndications: convertFromMToKm(workMeter.newOdometrIndications),
  previosMachineHoursIndications: convertFromSecondToHour(workMeter.previosMachineHoursIndications),
  previosOdometrIndications: convertFromMToKm(workMeter.previosOdometrIndications),
});

export const addVehicleWorkMeter = async (id: number, values: VehicleWorkMeter): Promise<Vehicle> => {
  const updated = await fetchRequest.post(`/vehicle/${id}/addWorkMeter`, convertDataToServer(values),);
  if (!updated) {
    throw new Error('Не удалось сохранить данные');
  }
};


export default {
  addVehicleWorkMeter
};
