// @flow
import React, {useCallback, useState} from 'react';

import {okeiApi} from '../../lib/api';
import type {Okei, UserAccess} from '../../lib/types';
import {getValueObject, navigate} from '../../lib/helpers';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, {GridItem} from '../../components/layout/Grid';
import {Card} from '../../components';

import {withUserAccess} from './../withUserAccess';

import {addAccessRight} from './accessRight';

const { Field } = Card;

type InnerProps = {
  data: Okei
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/okei/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Код">{getValueObject(props, 'data.code')}</Field>
        </GridItem>
        <GridItem>
          <Field label="Наименование единицы измерения">
            {getValueObject(props, 'data.name')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Условное обозначение">
            {getValueObject(props, 'data.alias')}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: Okei, setData] = useState({
    id: props.id,
    name: '',
    isAvailableForSelect: false
  });

  const breadCrumbs = [
    { to: `${entryPointPath}`, name: 'ОКЕИ' },
    { to: `${entryPointPath}${data.id}`, name: data.id.toString() }
  ];

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await okeiApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await okeiApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'ОКЕИ',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
