// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import DatePicker from 'antd/lib/date-picker';

import Spoiler from '../../../../components/ui/Spoiler';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import {Selects} from '../../../../components';
import moment from 'moment';
import {formatDateTimeToISOString} from '../../../../lib/helpers';
import type {ReportFuelConsumptionParams} from '../../../../lib/types/reportFuelConsumption';
import {Filter} from '../../../../components/hoc';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';

const { RangePicker } = DatePicker;

const { BrandsSelect, VehicleTypeSelect, FuelTypeSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Footer = styled(GridItem)`
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

type Props = {
  filter: ReportFuelConsumptionParams,
  cleanFilter: Function,
  applyFilter: Function
};

const ReportFuelConsumptionFilter = ({
  filter,
  applyFilter,
  cleanFilter
}: Props) => (
  <Spoiler defaultExpanded label="Параметры">
    <SpoilerContent>
      <Grid gutter="16px" media={[{ size: 'lg', cols: 3 }]}>
        <Filter
          initialValues={filter}
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter
          }: FilterChildrenParams<ReportFuelConsumptionParams>) => (
            <>
              <GridItem>
                <Field label="Подразделение">
                  <OrgUnitSelect
                    allowClear
                    value={values.orgUnitId}
                    onChange={id => changeValue('orgUnitId', id)}
                    isFilter={true}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Марка ТС">
                  <BrandsSelect
                    allowClear
                    value={values.vehicleBrandId}
                    mode="multiple"
                    onChange={values => changeValue('vehicleBrandId', values)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип ТС">
                  <VehicleTypeSelect
                    value={values.vehicleType}
                    selectableGroup
                    onChange={types => changeValue('vehicleType', types)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Вид топлива">
                  <FuelTypeSelect
                    allowClear
                    mode="multiple"
                    value={values.fuelType}
                    onChange={fuelTypes => changeValue('fuelType', fuelTypes)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Отчетный период">
                  <RangePicker
                    format="DD.MM.YYYY HH:mm"
                    showTime={{
                      format: 'HH:mm',
                      defaultValue: [
                        moment('00:00', 'HH:mm'),
                        moment('23:59', 'HH:mm')
                      ]
                    }}
                    placeholder={['Начало', 'Конец']}
                    value={[
                      values.startDate ? moment.utc(values.startDate) : null,
                      values.endDate ? moment.utc(values.endDate) : null
                    ]}
                    onChange={(value, dateString) => {
                      const [startDate, endDate] = value;
                      const [startDateString, endDateString] = dateString;
                      changeValue(
                        'startDate',
                        formatDateTimeToISOString(startDate, startDateString)
                      );
                      changeValue(
                        'endDate',
                        formatDateTimeToISOString(endDate, endDateString)
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <Footer fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="secondary" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Footer>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default ReportFuelConsumptionFilter;
