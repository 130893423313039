import {
  maintenanceStatusesEnum,
  maintenanceMatrixValidActionEnum
} from './../../lib/enum';

const notAllowed = maintenanceMatrixValidActionEnum.notAllowed;
const allowed = maintenanceMatrixValidActionEnum.allowed;
const approvedDeclined = [
  maintenanceStatusesEnum.approved,
  maintenanceStatusesEnum.declined
];

export default [
  {
    approvingDefectiveStatementAllowed: notAllowed,
    approvingDetailedStatementAllowed: notAllowed,
    approvingMaintenanceAllowed: allowed,
    approvingBtnDefective: false,
    approvingBtnDetailed: false,
    approvingBtnMaintenance: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status => ![...approvedDeclined].includes(status)
      )
    ],
    readDefectiveStatus: [...approvedDeclined],
    readDetailedStatus: [...approvedDeclined]
  },
  {
    approvingDefectiveStatementAllowed: notAllowed,
    approvingDetailedStatementAllowed: allowed,
    approvingMaintenanceAllowed: notAllowed,
    approvingBtnDefective: false,
    approvingBtnDetailed: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvedDetailedDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnMaintenance: false,
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ]
  },
  {
    approvingDefectiveStatementAllowed: notAllowed,
    approvingDetailedStatementAllowed: allowed,
    approvingMaintenanceAllowed: allowed,
    approvingBtnDefective: false,
    approvingBtnDetailed: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvedDetailedDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnMaintenance: [
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ],
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ]
  },
  {
    approvingDefectiveStatementAllowed: allowed,
    approvingDetailedStatementAllowed: notAllowed,
    approvingMaintenanceAllowed: notAllowed,
    approvingBtnDefective: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvedDefectiveStatement,
            maintenanceStatusesEnum.approvementDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnDetailed: false,
    approvingBtnMaintenance: false,
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement
    ]
  },
  {
    approvingDefectiveStatementAllowed: allowed,
    approvingDetailedStatementAllowed: notAllowed,
    approvingMaintenanceAllowed: allowed,
    approvingBtnDefective: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvedDefectiveStatement,
            maintenanceStatusesEnum.approvementDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnDetailed: false,
    approvingBtnMaintenance: [
      maintenanceStatusesEnum.approvedDefectiveStatement
    ],
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement
    ]
  },
  {
    approvingDefectiveStatementAllowed: allowed,
    approvingDetailedStatementAllowed: allowed,
    approvingMaintenanceAllowed: notAllowed,
    approvingBtnDefective: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvementDefectiveStatement,
            maintenanceStatusesEnum.approvedDefectiveStatement,
            maintenanceStatusesEnum.approvedDetailedDefectiveStatement,
            maintenanceStatusesEnum.declinedDetailedDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnDetailed: [
      maintenanceStatusesEnum.approvedDefectiveStatement,
      maintenanceStatusesEnum.declinedDetailedDefectiveStatement
    ],
    approvingBtnMaintenance: false,
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement,
      maintenanceStatusesEnum.approvementDetailedDefectiveStatement,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement,
      maintenanceStatusesEnum.declinedDetailedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ]
  },

  {
    approvingDefectiveStatementAllowed: allowed,
    approvingDetailedStatementAllowed: allowed,
    approvingMaintenanceAllowed: allowed,
    approvingBtnDefective: [
      ...Object.keys(maintenanceStatusesEnum).filter(
        status =>
          ![
            ...approvedDeclined,
            maintenanceStatusesEnum.approvementDefectiveStatement,
            maintenanceStatusesEnum.approvedDefectiveStatement,
            maintenanceStatusesEnum.approvedDetailedDefectiveStatement,
            maintenanceStatusesEnum.declinedDetailedDefectiveStatement
          ].includes(status)
      )
    ],
    approvingBtnDetailed: [
      maintenanceStatusesEnum.approvedDefectiveStatement,
      maintenanceStatusesEnum.declinedDetailedDefectiveStatement
    ],
    approvingBtnMaintenance: [
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ],
    readDefectiveStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvementDefectiveStatement,
      maintenanceStatusesEnum.approvedDefectiveStatement,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement,
      maintenanceStatusesEnum.approvementDetailedDefectiveStatement,
      maintenanceStatusesEnum.declinedDetailedDefectiveStatement
    ],
    readDetailedStatus: [
      ...approvedDeclined,
      maintenanceStatusesEnum.approvedDetailedDefectiveStatement
    ]
  }
];
