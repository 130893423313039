// @flow

import Button from 'antd/lib/button/button';
import React, {Component} from 'react';

import {Header} from '../../../components/layout';
import {ButtonsRow, Tabs, TabItem} from '../../../components/ui';

import type {ListState, UserAccess, VehicleTariff} from '../../../lib/types';

import {withUserAccess} from '../../withUserAccess';

import addAccess from '../accessRight';

import type {VehicleTariffFilterParams} from './Filter';
import {UploadFileModal} from './UploadFileModal';
import { Router } from '@reach/router';
import PlannedTab from './PlannedTab/PlannedTab';
import FactTab from './FactTab/FactTab';
import { vehicleTariffApi } from '../../../lib/api';
import { printNotification } from '../../../lib/notificationWrapper';
import {PLANNED_ROUTE, FACT_ROUTE} from '../constants';
import {navigate} from '../../../lib/helpers';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
  employeeBranchOrgUnitId: number,
};

type State = ListState<VehicleTariff> & {
  filter: VehicleTariffFilterParams
};

export class VehicleTariffBrandList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: {},
      modalVisible: false,
    };
  }

  componentDidMount() {
    const currentRoute = this.getCurrentRoute();
    if (!(currentRoute === PLANNED_ROUTE || currentRoute === FACT_ROUTE)) {
      navigate(`/admin/vehicle-tariff/${PLANNED_ROUTE}`);
    }
  }
  
  getCurrentRoute = () => {
    const currentRoute = window.location.pathname;
    const currentTab = currentRoute.split("/").slice(-1);
    return currentTab[0];
  }
  

  canAdd = () =>
    this.props.userAccess.some(access => addAccess.includes(access));

  handlePrint = (tariff: string) => printNotification(async () => await vehicleTariffApi.print(tariff));    

  render() {
    const {location} = this.props;
    const {modalVisible} = this.state;
    const canAdd = this.canAdd();
    const currentVehicleTariff = this.getCurrentRoute();

    return (
      <>
        <Header
          left={<h1>Тарифы на НТС</h1>}
          right={
            <ButtonsRow>
              {currentVehicleTariff !== FACT_ROUTE && <Button onClick={() => this.handlePrint(currentVehicleTariff)}>Печать</Button>} 
              {canAdd && <Button type="primary" onClick={() => this.setState({modalVisible: true})}>Создать</Button>}
            </ButtonsRow>
          }
        />
        <Tabs withRouter>
          <TabItem
            label='План'
            url={`/admin/vehicle-tariff/${PLANNED_ROUTE}`}
          />
          <TabItem
            label='Факт'
            url={`/admin/vehicle-tariff/${FACT_ROUTE}`}
          />
        </Tabs>
        <Router>
          <PlannedTab canAdd={canAdd}
                      location={location}
                      path={PLANNED_ROUTE}
                      />
          <FactTab canAdd={canAdd}
                   location={location}
                   path={FACT_ROUTE}
                   />
        </Router>
        <UploadFileModal
          currentVehicleTariff={currentVehicleTariff}
          visible={modalVisible}
          onClose={() => this.setState({modalVisible: false})}
        />
      </>
    );
  }
}

export default withUserAccess(VehicleTariffBrandList);
