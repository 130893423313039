// @flow

import React, { Component } from 'react';
import { Link } from '@reach/router';
import qs from 'query-string';
import moment from 'moment';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button/button';

import { washingPriceListApi } from './../../lib/api';
import type { FetchListParams } from './../../lib/api';
import type {
  WashingPriceList,
  ListState,
  UserAccess
} from './../../lib/types';
import {
  getListInitialState,
  setQueryParams,
  navigate
} from './../../lib/helpers';

import { withUserAccess } from './../withUserAccess';
import { notificationLoading } from './../../components/Notifications';
import { Header, Section } from './../../components/layout';
import { Table, Icon } from './../../components/ui';

import Filter from './components/Filter';
import type { WashPriceFilterParams } from './components/Filter';
import ActionsDropdown from './components/ActionsDropdown';
import canAddWashingPriceListAccess from './accessRight';
type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<WashingPriceList> & {
  filter: WashPriceFilterParams
};

export class WashingPriceListTable extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns: Array<Object> = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName',
      sorter: true,
      width: 200
    },
    {
      title: 'Номер договора',
      dataIndex: 'contractNumber',
      width: 100
    },
    {
      title: 'Дата начала действия',
      dataIndex: 'startDate',
      sorter: true,
      render: (startDate: string) =>
        startDate ? moment.utc(startDate).format('DD MMMM YYYY') : startDate
    },
    {
      title: 'Дата окончания действия',
      dataIndex: 'endDate',
      sorter: true,
      render: (endDate: string) =>
        endDate ? moment.utc(endDate).format('DD MMMM YYYY') : endDate
    }
  ];

  deleteWashingPrice = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await washingPriceListApi.deleteWashingPrice(id);
      await this.fetchWashingPrices(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchWashingPrices = async (
    page: number = 1,
    params: FetchListParams<WashPriceFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await washingPriceListApi.fetchWashingPrices({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchWashingPrices(page));
    if (this.canAdd()) {
      this.columns.push({
        title: '',
        width: '20px',
        // Нужно для того, чтобы не отрабатывал onRow
        stopPropagation: true,
        render: (record: any) => (
          <ActionsDropdown
            onDelete={this.deleteWashingPrice}
            washingPriceListId={record.id}
          >
            <Icon type="dots" />
          </ActionsDropdown>
        )
      });
    }
  }

  canAdd = () =>
    this.props.userAccess.some(access =>
      canAddWashingPriceListAccess.includes(access)
    );

  applyFilter = (filter: WashPriceFilterParams) =>
    this.setState({ filter }, this.fetchWashingPrices);

  cleanFilter = () => this.setState({ filter: {} }, this.fetchWashingPrices);

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Прейскурант на мойки</h1>}
          right={
            canAdd && (
              <Link to="/admin/washing-price-list/new">
                <Button type="primary">Создать</Button>
              </Link>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () =>
                canAdd
                  ? navigate(`/admin/washing-price-list/${record.id}/edit`)
                  : navigate(`/admin/washing-price-list/${record.id}`)
            })}
            columns={this.columns}
            fetch={this.fetchWashingPrices}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(WashingPriceListTable);
