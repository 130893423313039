// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from 'antd/lib/icon';

import type { Order } from './../../../lib/types';
import { WaypointsViewer, Card } from '../../../components';
import { Section, SectionTitle, Divider } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { vehicleTypes, vehicleGroups } from '../../../lib/enum';
import { applyMaskToValue } from './../../../lib/helpers';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const { Field } = Card;

const SectionContent = styled.div`
  padding: 16px;
`;

const ExpandButton = styled.div`
  color: #2770ff;
  cursor: pointer;
  padding: 10px;
  float: right;
`;

const ArrowIcon = styled(Icon)`
  font-size: 9px;
`;

type OrderCardProps = {
  order: Order
};

type State = {
  showAll: boolean
};

export default class OrderShortCard extends Component<OrderCardProps, State> {
  state = {
    showAll: false
  };

  render() {
    const { order }: OrderCardProps = this.props;
    const { showAll } = this.state;
    if (!order) {
      return null;
    }
    const { contractVehicleOrder } = order;
    return (
      <Section>
        <SectionTitle divider>
          {`Рейс по заявке №${order.id}`} доп. найм
        </SectionTitle>

        {contractVehicleOrder && (
          <>
            <SectionContent>
              <Grid cols={4}>
                <GridItem>
                  <Field label="Тип ТС">
                    {vehicleTypes[order.vehicleType] ||
                      vehicleGroups[order.vehicleGroup]}
                  </Field>
                </GridItem>
                {contractVehicleOrder.vehicleBrandModel && (
                  <GridItem>
                    <Field label="Марка и модель">
                      {contractVehicleOrder.vehicleBrandModel}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Гос. номер">
                    {applyMaskToValue(
                      contractVehicleOrder.licensePlate,
                      formatLicensePlateMask
                    )}
                  </Field>
                </GridItem>
                {contractVehicleOrder.trailerBrandModel && (
                  <GridItem>
                    <Field label="Марка и модель прицепа">
                      {contractVehicleOrder.trailerBrandModel}
                    </Field>
                  </GridItem>
                )}
                {contractVehicleOrder.trailerLicensePlate && (
                  <GridItem>
                    <Field label="Гос. номер прицепа">
                      {applyMaskToValue(
                        contractVehicleOrder.trailerLicensePlate,
                        formatLicensePlateMask
                      )}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Водитель">{contractVehicleOrder.driver}</Field>
                </GridItem>
                <GridItem span={2}>
                  <Field label="Контактные лица">
                    {contractVehicleOrder.contactEmployees}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
            <Divider />
          </>
        )}
        {showAll ? (
          <>
            <SectionTitle>Маршрут</SectionTitle>
            <WaypointsViewer waypoints={order.route.waypoints} />
            <ExpandButton onClick={() => this.setState({ showAll: false })}>
              Скрыть <ArrowIcon type="up" />
            </ExpandButton>
          </>
        ) : (
          <ExpandButton onClick={() => this.setState({ showAll: true })}>
            Подробнее <ArrowIcon type="down" />
          </ExpandButton>
        )}
      </Section>
    );
  }
}
