//@flow
import React, {useCallback} from 'react';

import {FilterItem, OrgUnitSelect} from '../../../components';
import Field from '../../../components/card/Field';
import {CommonFilter, type FilterProps} from '../../../components/hoc/common/handbook/CommonFilter';

export type PlaceParamsFilter = {
  nodeId?: number
};

export const PlaceFilter = (props: FilterProps<PlaceParamsFilter>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PlaceParamsFilter>>) =>
      CommonFilter<PlaceParamsFilter>({ ...filterProps, ...props }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                size="small"
                value={values.nodeId}
                onlyBranches
                onChange={value => changeValue('nodeId', value)}
                isFilter={true}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PlaceFilter;
