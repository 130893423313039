// @flow
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table';
import React, {Component} from 'react';
import styled from 'styled-components';

import {Card, Filter, FilterButtonsContainer, FilterContainer, FilterItem} from '../../../components';
import {Panel, Section} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import Grid, {GridItem} from '../../../components/layout/Grid';
import Header from '../../../components/layout/Header';
import {Icon} from '../../../components/ui';
import {TableHeader} from '../../../components/ui/Table';
import {vehicleTariffApi} from '../../../lib/api';
import {accessTypeEnum, fuelsAndGazTypes, operatingModes, rateTypeEnum, vehicleTariffStatus, vehicleTariffStatusEnum} from '../../../lib/enum';
import {
  convertContractorToString,
  formatDateTimeToString,
  formatRub,
  getPathWithHistoryParams,
  isTariffFact,
  navigate,
} from '../../../lib/helpers';
import type {FuelAndGazType, OperatingMode, UserAccess, VehicleTariff} from '../../../lib/types';
import type {VehicleTariffTypesAndModels} from '../../../lib/types/vehicleTariff';
import {VehicleModelStatusEnum} from '../../../lib/types/vehicleTariff';
import {withUserAccess} from '../../withUserAccess';
import addAccess from '../accessRight';
import {SubTitle} from './InnerFields';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import Select from 'antd/lib/select';
import YesNoSelect from '../../../components/selects/YesNoSelect';
import {OperatingModeSelect} from '../../../components/selects/AutoGeneratedEnumSelects';
import Button from 'antd/lib/button';
import {notificationError, notificationLoading} from '../../../components/Notifications';
import InputNumber from 'antd/lib/input-number';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';
import { printNotification } from '../../../lib/notificationWrapper';
import {VehicleTariffStatusIcon} from './VehicleTariffStatusIcon';
import {NO_DATA} from '../constants';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const SectionContent = styled.div`
  padding: 16px;
`;

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;

  & img {
    width: 100%;
  }
`;

const Cell = styled.p`
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const HeaderBtnContainer = styled.div`
  & button {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`

const {Field} = Card;

type Props = {
  userAccess: UserAccess[],
  vehicleTariffId: number,
  version: string,
};

type State = {
  vehicleTariff: ?VehicleTariff,
  filteredModels: VehicleTariffTypesAndModels[],
  hasUnmappedModels: boolean,
  editMode: boolean,
  prices: any,
  brandsForFilter: any[],
  modelsForFilter: any[],
};

class VehicleTariffCard extends Component<Props, State> {
  state = {
    vehicleTariff: null,
    filteredModels: [],
    hasUnmappedModels: false,
    editMode: false,
    prices: {},
    brandsForFilter: [],
    modelsForFilter: [],
  };

  async componentDidMount() {
    let vehicleTariffId = parseInt(this.props.vehicleTariffId, 10);
    if (vehicleTariffId) {
      try {
        const vehicleTariff = await vehicleTariffApi.getVehicleTariff(vehicleTariffId);
        this.dataPreparation(vehicleTariff);
      } catch (error) {
        this.createErrorNotification(error.message);
      }
    }
  }

  createErrorNotification = (description: string) => {
    notification.error({
      message: 'Ошибка',
      description,
    });
  }

  dataPreparation = (vehicleTariff) => {
    try {
      let countOfUnmappedModels = 0;
      let countOfNotFoundPlanTariffs = 0;
      const brandsIds = new Set();
      const modelsIds = new Set();
      const brandsForFilter = [];
      const modelsForFilter = [];
      if (vehicleTariff.typesAndModels.length) {
        vehicleTariff.typesAndModels.forEach((model: VehicleTariffTypesAndModels) => {
          if (!model.tariffFixedPlan) {
            countOfNotFoundPlanTariffs += 1;
          }

          if (!model.vehicleModelId) {
            countOfUnmappedModels += 1;
          }

          if (!brandsIds.has(model?.vehicleModel?.brandId)) {
            brandsIds.add(model?.vehicleModel?.brandId);
            brandsForFilter.push({id: model?.vehicleModel?.brandId, name: model?.vehicleModel?.brandName});
          }

          if (!modelsIds.has(model?.vehicleModel?.id)) {
            modelsIds.add(model?.vehicleModel?.id);
            modelsForFilter.push({id: model?.vehicleModel?.id, name: model?.vehicleModel?.name});
          }
        });

        if (countOfUnmappedModels) {
          this.createErrorNotification(`В АС «Транспорт» не найдено сопоставление
                                        по ${countOfUnmappedModels} моделям`)
        }

        if (countOfNotFoundPlanTariffs && (vehicleTariff?.version === rateTypeEnum.fact)) {
          this.createErrorNotification(`В АС «Транспорт» не найдены плановые 
                                        тарифы по ${countOfNotFoundPlanTariffs} моделям`)
        }
      }

      this.setState({
        vehicleTariff,
        prices: {
          ai92Price: vehicleTariff.ai92Price,
          ai95Price: vehicleTariff.ai95Price,
          ai98Price: vehicleTariff.ai98Price,
          dieselPrice: vehicleTariff.dieselPrice,
          electricityPrice: vehicleTariff.electricityPrice,
          gasPrice: vehicleTariff.gasPrice,
          optionalEquipmentAi92Price: vehicleTariff.optionalEquipmentAi92Price,
          optionalEquipmentAi95Price: vehicleTariff.optionalEquipmentAi95Price,
          optionalEquipmentAi98Price: vehicleTariff.optionalEquipmentAi98Price,
          optionalEquipmentDieselPrice: vehicleTariff.optionalEquipmentDieselPrice,
          optionalEquipmentElectricityPrice: vehicleTariff.optionalEquipmentElectricityPrice,
          optionalEquipmentGasPrice: vehicleTariff.optionalEquipmentGasPrice,
        },
        editMode: false,
        filteredModels: vehicleTariff.typesAndModels || [],
        hasUnmappedModels: !!countOfUnmappedModels,
        brandsForFilter,
        modelsForFilter,
      });
    } catch (error) {
      this.createErrorNotification(error.message);
    }
  };

  canHandle = () => this.props.userAccess.some(access => addAccess.includes(access));

  canEditPrices = () => this.props.userAccess.some(access => [
    accessTypeEnum.admin,
    accessTypeEnum.editingFuelPriceOnlyInVehicleTariff,
  ].includes(access));

  getStatusSelectOptions = (vehicleTariff: ?VehicleTariff) => {
    if (!vehicleTariff?.typesAndModels?.length) return [];

    const isVehicleTariffFact = isTariffFact(vehicleTariff.version);

    const typesAndModelsHas = (modelStatus: VehicleModelStatusEnum) =>
      vehicleTariff.typesAndModels.some((item) => item.modelStatus === modelStatus);

    const options = [];

    if (!isVehicleTariffFact || typesAndModelsHas(VehicleModelStatusEnum.Ok)) {
      options.push({
        value: 'true',
        title: isVehicleTariffFact ? 'Модель и тариф найдены' : 'Модель найдена',
        modelStatus: VehicleModelStatusEnum.Ok,
      });
    }
    if (!isVehicleTariffFact || typesAndModelsHas(VehicleModelStatusEnum.NoModel)) {
      options.push({
        value: 'false',
        title: 'Модель не найдена',
        modelStatus: VehicleModelStatusEnum.NoModel,
      });
    }
    if (isVehicleTariffFact && typesAndModelsHas(VehicleModelStatusEnum.NoPlanTariff)) {
      options.push({
        value: 'tariffNotFound',
        title: 'Тариф не найден',
        modelStatus: VehicleModelStatusEnum.NoPlanTariff,
      });
    }

    return options;
  };

  applyFilter = filter => {
    let filteredModels = this.state.vehicleTariff?.typesAndModels || [];

    if (!filteredModels.length) {
      return;
    }

    const {hasGas, leasing, operatingMode, hasModel, modelId, brandId} = filter;
  
    const statusSelectOption = hasModel
      ? this.getStatusSelectOptions(this.state.vehicleTariff).find((option) => option.value === hasModel)
      : undefined;

    filteredModels = filteredModels.filter((model: VehicleTariffTypesAndModels) => {
      if (statusSelectOption && statusSelectOption.modelStatus !== model.modelStatus) {
        return false;
      }
      if (hasGas !== undefined && model?.hasGas !== hasGas) {
        return false;
      }
      if (leasing !== undefined && model?.leasing !== leasing) {
        return false;
      }
      if (operatingMode !== undefined && model?.operatingMode !== operatingMode) {
        return false;
      }
      if (modelId !== undefined && model?.vehicleModel?.id !== modelId) {
        return false;
      }
      if (brandId !== undefined && model?.vehicleModel?.brandId !== brandId) {
        return false;
      }
      return true;
    });

    this.setState({filteredModels});
  };

  cleanFilter = () => {
    this.setState({filteredModels: this.state.vehicleTariff.typesAndModels || []});
  };

  compareModels = async () => {
    try {
      notificationLoading({message: 'Осуществляется сопоставление моделей', key: 'comparing'});
      const vehicleTariff = await vehicleTariffApi.matchingModels(this.props.vehicleTariffId);
      this.dataPreparation(vehicleTariff);
    } catch (e) {
      notificationError(e, {message: 'Ошибка сопоставления моделей'});
    } finally {
      notification.close('comparing');
    }
  };

  actionForTariff = async (message: string, notificationKey: String, status: vehicleTariffStatusEnum) => {
    try {
      notificationLoading({message, key: notificationKey});
      const vehicleTariff = await vehicleTariffApi.updateVehicleTariff({
        ...this.state.vehicleTariff,
        status,
      });
      this.dataPreparation(vehicleTariff);
    } catch (e) {
      notificationError(e);
    } finally {
      notification.close(notificationKey);
    }
  }

  approveTariff = async () => {
    this.actionForTariff('Утверждение тарифа', 'approving', vehicleTariffStatusEnum.approved)
  };

  deactivateTariff = async () => {
    this.actionForTariff('Деактивация тарифа', 'deactivating', vehicleTariffStatusEnum.deactivated)
  }

  updateTariff = async () => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const {vehicleTariff, prices} = this.state;
      const newTariff = await vehicleTariffApi.updateVehicleTariff({
        ...vehicleTariff,
        status: vehicleTariffStatusEnum.draft,
        ai92Price: prices.ai92Price || 0,
        ai95Price: prices.ai95Price || 0,
        ai98Price: prices.ai98Price || 0,
        dieselPrice: prices.dieselPrice || 0,
        electricityPrice: prices.electricityPrice || 0,
        gasPrice: prices.gasPrice || 0,
        optionalEquipmentAi92Price: prices.optionalEquipmentAi92Price || 0,
        optionalEquipmentAi95Price: prices.optionalEquipmentAi95Price || 0,
        optionalEquipmentAi98Price: prices.optionalEquipmentAi98Price || 0,
        optionalEquipmentDieselPrice: prices.optionalEquipmentDieselPrice || 0,
        optionalEquipmentElectricityPrice: prices.optionalEquipmentElectricityPrice || 0,
        optionalEquipmentGasPrice: prices.optionalEquipmentGasPrice || 0,
      });
      this.dataPreparation(newTariff);
    } catch (error) {
      this.createErrorNotification(error.message);
    } finally {
      notification.close('saving');
    }
  };

  handlePrint = (vehicleTariffId: number) => printNotification(async () => await vehicleTariffApi.printTariff(vehicleTariffId));    

  render() {
    const {vehicleTariff, filteredModels, hasUnmappedModels, prices, editMode, modelsForFilter, brandsForFilter} = this.state;

    const {vehicleTariffId} = this.props;
    const tariffColumnsWidth = '80px';
    const isVehicleTariffFact = isTariffFact(vehicleTariff?.version);
    const statusSelectOptions = this.getStatusSelectOptions(vehicleTariff);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(`/admin/vehicle-tariff/${vehicleTariff?.version}`)}>
                Перечень тарифов на НТС
              </Crumb>
              <Crumb to={`/admin/vehicle-tariff/tariff/${vehicleTariff?.version}/${vehicleTariffId}`}>
                Тариф на НТС №{vehicleTariffId}
              </Crumb>
            </Breadcrumbs>
          }
          right={(
            <HeaderBtnContainer>
              {vehicleTariff && vehicleTariff.status !== vehicleTariffStatusEnum.deactivated &&
                (this.canEditPrices() || this.canHandle()) && (
                <Button
                  onClick={() => this.setState({editMode: true})}
                >
                  Задать стоимость топлива
                </Button>
              )}
              {isVehicleTariffFact && <Button onClick={() => this.handlePrint(vehicleTariffId)}>Печать</Button>}
              {this.canHandle() && (
                <>
                  {hasUnmappedModels && (
                    <Button type="primary"
                            onClick={this.compareModels}>Сопоставить модели</Button>
                  )}
                  {vehicleTariff?.status === vehicleTariffStatusEnum.draft && (
                    <Button
                      type="primary"
                      onClick={this.approveTariff}
                      disabled={hasUnmappedModels}
                      title={hasUnmappedModels ? 'Имеются несопоставленные модели' : undefined}
                    >
                      Утвердить
                    </Button>
                  )}
                  {isVehicleTariffFact && vehicleTariff?.status === vehicleTariffStatusEnum.approved && (
                    <Button type="primary" onClick={this.deactivateTariff}>Деактивировать</Button>
                  )}
                </>
              )}
            </HeaderBtnContainer>
          )}
        />
        <StyledPanel>
          <h1>
            {`Тариф на НТС №${vehicleTariffId}`}
            {this.canHandle() && (vehicleTariff?.status !== vehicleTariffStatusEnum.deactivated) && (
              <OperationIcon
                onClick={() => navigate(`/admin/vehicle-tariff/tariff/${vehicleTariff?.version}/${vehicleTariffId}/edit`)}
                type="edit"
                size={16}
              />
            )}
          </h1>
        </StyledPanel>
        <Section>
          {vehicleTariff && (
            <>
              <SectionContent>
                <Grid gutter="16px" cols={6}>
                  <GridItem>
                    <Field label="Подрядчик">
                      {convertContractorToString(vehicleTariff.contractor)}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Основания для тарифа">
                      {vehicleTariff.tariffBases}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Начало действия тарифа">
                      {formatDateTimeToString(
                        vehicleTariff.startDate,
                        'DD.MM.YYYY',
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Окончание действия тарифа">
                      {formatDateTimeToString(
                        vehicleTariff.endDate,
                        'DD.MM.YYYY',
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Версия тарифа">
                      {vehicleTariffStatus[vehicleTariff?.status]}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Филиалы">
                      {vehicleTariff.vehicleTariffOrganizationUnitLinks.length
                        ? (
                          <div>
                            {vehicleTariff.vehicleTariffOrganizationUnitLinks.map(link => (
                              <span key={link.id} style={{color: '#3d4042'}}>
                              {`${link.organizationUnit?.name}  ` || ''}
                            </span>
                            ))}
                          </div>
                        )
                        : <span>-</span>
                      }
                    </Field>
                  </GridItem>
                </Grid>
                <Grid gutter="16px" cols={6}>
                  <GridItem fullWidth>
                    <SubTitle>
                      ТС
                    </SubTitle>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена АИ-95, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.ai95Price}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              ai95Price: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.ai95Price)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена АИ-92, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.ai92Price}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              ai92Price: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.ai92Price)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена ДТ, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.dieselPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              dieselPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.dieselPrice)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена Газ, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.gasPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              gasPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.gasPrice)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена Электроэнергия, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.electricityPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              electricityPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.electricityPrice)
                      }
                    </Field>
                  </GridItem>
                </Grid>
                <Grid gutter="16px" cols={6}>
                  <GridItem fullWidth>
                    <SubTitle>
                      Доп. оборудование
                    </SubTitle>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена АИ-95, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.optionalEquipmentAi95Price}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              optionalEquipmentAi95Price: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.optionalEquipmentAi95Price)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена АИ-92, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.optionalEquipmentAi92Price}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              optionalEquipmentAi92Price: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.optionalEquipmentAi92Price)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена ДТ, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.optionalEquipmentDieselPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              optionalEquipmentDieselPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.optionalEquipmentDieselPrice)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена Газ, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.optionalEquipmentGasPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              optionalEquipmentGasPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.optionalEquipmentGasPrice)
                      }
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Цена Электроэнергия, руб.">
                      {editMode
                        ? <InputNumber
                          value={prices.optionalEquipmentElectricityPrice}
                          onChange={value => this.setState({
                            prices: {
                              ...prices,
                              optionalEquipmentElectricityPrice: value,
                            },
                          })}
                          min={0}
                        />
                        : formatRub(vehicleTariff.optionalEquipmentElectricityPrice)
                      }
                    </Field>
                  </GridItem>
                  {editMode && (
                    <GridItem fullWidth>
                      <Button
                        type="primary"
                        onClick={this.updateTariff}
                        style={{marginRight: '10px'}}
                      >Сохранить
                      </Button>
                      <Button onClick={() => {
                        this.setState({
                          editMode: false,
                          prices: {
                            ai92Price: vehicleTariff.ai92Price,
                            ai95Price: vehicleTariff.ai95Price,
                            dieselPrice: vehicleTariff.dieselPrice,
                            electricityPrice: vehicleTariff.electricityPrice,
                            gasPrice: vehicleTariff.gasPrice,
                            optionalEquipmentAi92Price: vehicleTariff.optionalEquipmentAi92Price,
                            optionalEquipmentAi95Price: vehicleTariff.optionalEquipmentAi95Price,
                            optionalEquipmentDieselPrice: vehicleTariff.optionalEquipmentDieselPrice,
                            optionalEquipmentElectricityPrice: vehicleTariff.optionalEquipmentElectricityPrice,
                            optionalEquipmentGasPrice: vehicleTariff.optionalEquipmentGasPrice,
                          },
                        });
                      }}>Отменить</Button>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
              <SectionContent>
                <FilterContainer style={{marginBottom: '20px'}}>
                  <Filter cleanFilter={this.cleanFilter} applyFilter={this.applyFilter} initialValues={{}}>
                    {({values, changeValue, applyFilter, cleanFilter}: FilterChildrenParams) => (
                      <>
                        <FilterItem>
                          <Field mBottomNone>
                            <Select
                              size="small"
                              onChange={value => changeValue('hasModel', value)}
                              value={values.hasModel}
                              placeholder="Статус"
                              allowClear
                            >
                              {statusSelectOptions.map((option) => (
                                <Select.Option value={option.value}>
                                  {option.title}
                                </Select.Option>
                              ))}
                            </Select>
                          </Field>
                        </FilterItem>
                        <FilterItem>
                          <Field mBottomNone>
                            <OperatingModeSelect
                              size="small"
                              onChange={value => changeValue('operatingMode', value)}
                              value={values.operatingMode}
                              placeholder="Режим эксплуатации"
                            />
                          </Field>
                        </FilterItem>
                        <FilterItem>
                          <Field mBottomNone>
                            <YesNoSelect
                              placeholder="Установлено ГБО"
                              value={values.hasGas}
                              onChange={value => changeValue('hasGas', value)}
                              size="small"
                              fullWidth
                            />
                          </Field>
                        </FilterItem>
                        <FilterItem>
                          <Field mBottomNone>
                            <YesNoSelect
                              placeholder="Лизинг"
                              value={values.leasing}
                              onChange={value => changeValue('leasing', value)}
                              size="small"
                              fullWidth
                            />
                          </Field>
                        </FilterItem>
                        <FilterItem>
                          <Field mBottomNone>
                            <AutocompleteSelect
                              fetch={({search}) => {
                                if (search) {
                                  return {data: brandsForFilter.filter(item =>
                                      item.name.toLowerCase().includes(search.toLowerCase())
                                    )}
                                }
                                return {data: brandsForFilter};
                              }}
                              fetchSingle={id => brandsForFilter.find(item => item.id === id)}
                              notFoundText='Марки не найдены'
                              placeholder="Марка ТС"
                              value={values.brandId}
                              onChange={value => changeValue('brandId', value)}
                              size="small"
                              fullWidth
                              renderOption={({id, name}, Option) => <Option key={id} value={id}>{name}</Option>}
                            />
                          </Field>
                        </FilterItem>
                        <FilterItem>
                          <Field mBottomNone>
                            <AutocompleteSelect
                              fetch={({search}) => {
                                if (search) {
                                  return {data: modelsForFilter.filter(item =>
                                      item.name.toLowerCase().includes(search.toLowerCase())
                                    )}
                                }
                                return {data: modelsForFilter};
                              }}
                              fetchSingle={id => modelsForFilter.find(item => item.id === id)}
                              notFoundText='Модели не найдены'
                              placeholder="Модель ТС"
                              value={values.modelId}
                              onChange={value => {
                                changeValue('modelId', value);
                                if (values.hasModel === 'false') {
                                  changeValue('hasModel', undefined);
                                }
                              }}
                              size="small"
                              fullWidth
                              renderOption={({id, name}, Option) => <Option key={id} value={id}>{name}</Option>}
                            />
                          </Field>
                        </FilterItem>
                        <FilterButtonsContainer applyFilter={applyFilter} cleanFilter={cleanFilter} />
                      </>
                    )}
                  </Filter>
                </FilterContainer>
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: 'Марка ТС',
                      dataIndex: 'vehicleModel.brandName',
                      render: (name: string, record: VehicleTariffTypesAndModels) => name || record.importBrandName,
                      fixed: 'left',
                      width: 150,
                    },
                    {
                      title: 'Модель ТС',
                      dataIndex: 'vehicleModel.name',
                      render: (name: string, record: VehicleTariffTypesAndModels) => (
                        <Cell style={{maxWidth: '180px'}}>
                          {name || record.importModelName}
                        </Cell>
                      ),
                      fixed: 'left',
                      width: 200,
                    },
                    {
                      title: 'Статус',
                      dataIndex: 'modelStatus',
                      render: (value) => <VehicleTariffStatusIcon modelStatus={value} />,
                      width: 70,
                    },
                    {
                      title: 'Год начала выпуска',
                      dataIndex: 'productionYearStart',
                      width: 150,
                    },
                    {
                      title: 'Год окончания выпуска',
                      dataIndex: 'productionYearEnd',
                      width: 170,
                    },
                    {
                      title: 'Режим эксплуатации',
                      dataIndex: 'operatingMode',
                      render: (type: OperatingMode) => operatingModes[type],
                      width: 160,
                    },
                    {
                      title: 'Установлено ГБО',
                      dataIndex: 'hasGas',
                      render: (hasGas: boolean) => (hasGas ? 'Да' : 'Нет'),
                      width: 135,
                    },
                    {
                      title: 'Лизинг',
                      dataIndex: 'leasing',
                      render: (leasing: boolean) => (leasing ? 'Да' : 'Нет'),
                      width: 70,
                    },
                    {
                      title: (
                        <TableHeader width="190px">
                          Тариф (руб.) за 1 час эксплуатации постоянная часть
                        </TableHeader>
                      ),
                      dataIndex: !isVehicleTariffFact ? 'tariffFixed' : undefined,
                      width: !isVehicleTariffFact ? 210 : undefined,
                      children: isVehicleTariffFact ? [
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Факт.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffFixed',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>План.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffFixedPlan',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Отклонение.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffFixedDeviation',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Освоение.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffFixedRealization',
                          render: (value) => value ?? NO_DATA,
                        },
                      ] : undefined,
                    },
                    {
                      title: (
                        <TableHeader width="190px">
                          Тариф (руб.) за 1 км пробега переменная часть
                        </TableHeader>
                      ),
                      dataIndex: !isVehicleTariffFact ? 'tariffDynamic' : undefined,
                      width: !isVehicleTariffFact ? 190 : undefined,
                      children: isVehicleTariffFact ? [
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Факт.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffDynamic',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>План.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffDynamicPlan',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Отклонение.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffDynamicDeviation',
                          render: (value) => value ?? NO_DATA,
                        },
                        {
                          title: <TableHeader width={tariffColumnsWidth}>Освоение.</TableHeader>,
                          width: 125,
                          dataIndex: 'tariffDynamicRealization',
                          render: (value) => value ?? NO_DATA,
                        },
                      ] : undefined,
                    },
                    {
                      title: 'Тип топлива',
                      dataIndex: 'fuelType',
                      render: (fuelType: FuelAndGazType) => fuelsAndGazTypes[fuelType],
                      width: 130,
                    },
                    {
                      title: <TableHeader width="200px">Простой с включенным ДВС (обогрев салона)</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма списания топлива на прогрев, л/ч</TableHeader>,
                          width: 135,
                          dataIndex: 'warmingNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'warmingTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="200px">Пробег ТС с прицепом</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="105px">Норма расхода топлива на 1 км, л</TableHeader>,
                          width: 140,
                          dataIndex: 'trailerNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 км, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'trailerTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="180px">Отопитель воздушный (обогрев салона)</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                          width: 135,
                          dataIndex: 'heaterNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'heaterTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="200px">Подогреватель жидкостной</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                          width: 135,
                          dataIndex: 'preHeaterNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'preHeaterTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="200px">Силовой генератор</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                          width: 135,
                          dataIndex: 'generatorNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'generatorTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="200px">Лебедка</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                          width: 135,
                          dataIndex: 'winchNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'winchTariff',
                        },
                      ],
                    },
                    {
                      title: <TableHeader width="200px">Кондиционер</TableHeader>,
                      children: [
                        {
                          title: <TableHeader width="100px">Норма расхода топлива на 1 час работы, л</TableHeader>,
                          width: 135,
                          dataIndex: 'airConditionerNorm',
                        },
                        {
                          title: <TableHeader width="90px">Тариф за 1 час работы, руб.</TableHeader>,
                          width: 125,
                          dataIndex: 'airConditionerTariff',
                        },
                      ],
                    },
                    {
                      title: 'Тип ТС для ИСУ «Бюджетирование»',
                      dataIndex: 'vehicleTypeISU',
                      width: 280,
                      render: value => <Cell>{value}</Cell>,
                    },
                  ]}
                  dataSource={filteredModels}
                  scroll={{
                    y: '500px',
                    x: 2350,
                  }}
                  bordered
                  rowKey='id'
                />
              </SectionContent>
            </>
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(VehicleTariffCard);
