import React from 'react';
import {Section, SectionTitle} from "./layout";

const NotFound = () => (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <h1>
        <Section>
          <SectionTitle>Страница не найдена</SectionTitle>
        </Section>
      </h1>
    </div>
);

export default NotFound;
