// @flow
import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';

import Popconfirm from 'antd/lib/popconfirm';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import AntIcon from 'antd/lib/icon';
import DatePicker from 'antd/lib/date-picker';
import notification from 'antd/lib/notification';

import {detailedDefectiveStatementApi} from './../../../lib/api';
import type {DetailedDefectiveStatement, Maintenance, MaintenanceMatrix, UserAccess} from '../../../lib/types';
import {maintenanceMatrixValidActionEnum, maintenanceStatusesEnum} from '../../../lib/enum';

import {saveDetailedDefectiveStatement, setDetailedDefectiveStatement} from '../../../ducks/detailedDefectiveStatement';

import {Card} from './../../../components';
import {notificationLoading} from '../../../components/Notifications';
import {Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Dropdown, Popover} from './../../../components/ui';

import maintenanceMatrixRules from './../maintenanceMatrixRules';
import {canApprovingDetailedDefectiveStatements} from '../accessRight';

import {DeclineDetailedDefectiveStatementSelect, Operations, OperationsReadOnly, PartsAndConsumables, PartsAndConsumablesReadOnly} from './components';

const Content = styled.div`
  padding: 16px;
`;
const ContentTitle = styled.h2`
  margin: 16px 0;
  font-weight: 500;
`;
const StyledButtonDiv = styled.div`
  button {
    margin-left: 10px;
  }
`;
const {Field} = Card;
type Props = {
  userAccess: UserAccess[],
  maintenance: Maintenance,
  detailedDefectiveStatementReadOnly: boolean,
  detailedDefectiveStatement: DetailedDefectiveStatement,
  changeStatus: Function,
  saveDetailedDefectiveStatement: Function,
  // матрица ремонтов
  maintenanceMatrix: ?MaintenanceMatrix
};
type State = {
  declinedDetailedDefectiveStatement: ?(DetailedDefectiveStatement[]),
  // выбранная отклоненная дет. деф. ведомость
  declined: ?DetailedDefectiveStatement
};

class DetailedDefectiveStatementCard extends Component<Props, State> {
  state = {
    declinedDetailedDefectiveStatement: [],
    declined: null,
  };
  
  componentDidMount() {
    this.getDeclined();
  }
  
  // получим отклоненные дет. деф. ведомости
  getDeclined = async () => {
    const {maintenance} = this.props;
    const declined = await detailedDefectiveStatementApi.fetchDetailedDefectiveStatement(
      {
        maintenanceId: maintenance.id,
        returnDeleted: true,
        isDeleted: true,
      },
    );
    this.setState({declinedDetailedDefectiveStatement: declined.data});
  };
  
  onChangeStatus = async ({key, declineReason}: any) => {
    const {changeStatus} = this.props;
    await changeStatus('detailedDefectiveStatement')({key, declineReason});
    if (key === maintenanceStatusesEnum.declinedDetailedDefectiveStatement) {
      this.getDeclined();
    }
  };
  
  canApprovingDetailedDefectiveStatements = () =>
    this.props.userAccess.some(access =>
      canApprovingDetailedDefectiveStatements.includes(access),
    );
  
  // проверим доступен ли параментр
  checkAllowed = (access: $Keys<MaintenanceMatrix>) => {
    const {maintenanceMatrix} = this.props;
    return (
      maintenanceMatrix &&
      maintenanceMatrix[access] !== maintenanceMatrixValidActionEnum.notAllowed
    );
  };
  
  // проверим показывать или нет валидацию
  checkShowApproving(params: string) {
    const {maintenance} = this.props;
    const rules = this.getMaintenanceMatrixRules();
    if (maintenance) {
      if (Array.isArray(rules[params])) {
        return rules[params].includes(maintenance.status);
      }
      return rules[params];
    }
    return false;
  }
  
  getMaintenanceMatrixRules = (): any => {
    const {maintenanceMatrix} = this.props;
    const rules = maintenanceMatrixRules.find((item: any) => {
      if (maintenanceMatrix) {
        return (
          item.approvingDefectiveStatementAllowed ===
          maintenanceMatrix.approvingDefectiveStatementAllowed &&
          item.approvingDetailedStatementAllowed ===
          maintenanceMatrix.approvingDetailedStatementAllowed &&
          item.approvingMaintenanceAllowed ===
          maintenanceMatrix.approvingMaintenanceAllowed
        );
      }
      return false;
    });
    return rules
      ? rules
      : maintenanceMatrixRules[maintenanceMatrixRules.length - 1];
  };
  
  onChangeDeclineDetailedDefectiveStatement = async (
    value: any,
    option: any,
  ) => {
    let declined = null;
    if (value !== undefined) {
      declined = option.props.data;
    }
    this.setState({declined});
  };
  
  handlePrint = async ({key}: any) => {
    const {detailedDefectiveStatement} = this.props;
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      if (detailedDefectiveStatement) {
        switch (key) {
          case 'print':
            await detailedDefectiveStatementApi.printDetailedDefectiveStatement(
              detailedDefectiveStatement.id,
            );
            break;
          case 'printOs3':
            await detailedDefectiveStatementApi.printOs3FormDetailedDefectiveStatement(
              detailedDefectiveStatement.id,
            );
            break;
          case 'printWriteOffAct':
            await detailedDefectiveStatementApi.printWriteOffActDetailedDefectiveStatement(
              detailedDefectiveStatement.id,
            );
            break;
          default:
            break;
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    }
    finally {
      notification.close('printing');
    }
  };
  
  // кнопки для дет. дефектной ведомости
  getSuffixDetailedDefectiveStatement = () => {
    const {maintenance, detailedDefectiveStatement} = this.props;
    const {declined} = this.state;
    const {declinedDetailedDefectiveStatement} = this.state;
    const canApproveBtn = this.checkShowApproving('approvingBtnDetailed');
    const addPrint = this.checkAllowed(
      'printDetailedDefectiveStatementAllowed',
    );
    const addPrintOs3 = this.checkAllowed('printFormOS3Allowed');
    const addPrintWriteOffAct = this.checkAllowed('printActOffMPZAllowed');
    const dateRangeExists =
      detailedDefectiveStatement.startDate &&
      detailedDefectiveStatement.endDate;
    return (
      <>
        {maintenance && (
          <StyledButtonDiv>
            <DeclineDetailedDefectiveStatementSelect
              detailedDefectiveStatement={declinedDetailedDefectiveStatement}
              onChange={this.onChangeDeclineDetailedDefectiveStatement}
              maintenanceId={maintenance.id}
            />
            {detailedDefectiveStatement &&
              !declined &&
              detailedDefectiveStatement.id !== 0 &&
              [addPrint, addPrintOs3, addPrintWriteOffAct].includes(true) && (
                <Dropdown
                  overlay={
                    <Menu onClick={this.handlePrint}>
                      {addPrint && (
                        <Menu.Item key={'print'}>Дефектная ведомость</Menu.Item>
                      )}
                      {addPrintOs3 && (
                        <Menu.Item key={'printOs3'}>Форма ОС-3</Menu.Item>
                      )}
                      {addPrintWriteOffAct && (
                        <Menu.Item key={'printWriteOffAct'}>
                          Акт о списании материально-производственных запасов
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button type="primary">Печатные формы</Button>
                </Dropdown>
              )}
            {this.canApprovingDetailedDefectiveStatements() &&
              !declined &&
              canApproveBtn && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        disabled={!dateRangeExists}
                        onClick={() =>
                          this.onChangeStatus({
                            key:
                            maintenanceStatusesEnum.approvedDetailedDefectiveStatement,
                          })
                        }
                      >
                        {!dateRangeExists ? (
                          <Popover
                            content={'Укажите дату начала и дату окончания'}
                          >
                            Утвердить
                          </Popover>
                        ) : (
                          'Утвердить'
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        <DeclineDetailedDefectiveStatementButton
                          onConfirm={declineReason => {
                            this.onChangeStatus({
                              key:
                              maintenanceStatusesEnum.declinedDetailedDefectiveStatement,
                              declineReason,
                            });
                          }}
                        />
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    <AntIcon
                      style={{fontSize: 16, color: '#2770FF'}}
                      type="ellipsis"
                    />
                  </Button>
                </Dropdown>
              )}
          </StyledButtonDiv>
        )}
      </>
    );
  };
  
  getOperationComponent = () => {
    const {declined} = this.state;
    const {
      detailedDefectiveStatement,
      detailedDefectiveStatementReadOnly,
      maintenance,
    } = this.props;
    if (declined || detailedDefectiveStatementReadOnly) {
      return (
        <OperationsReadOnly
          detailedDefectiveStatement={declined || detailedDefectiveStatement}
          orderContractorType={maintenance.orderContractorType}
        />
      );
    }
    return (
      <Operations
        orderContractorType={maintenance.orderContractorType}
        executorId={maintenance.executorId}
        maintenanceId={maintenance.id}
        vehicleModelId={maintenance.vehicle.vehicleModelId}
        vehicleId={maintenance.vehicle.id}
        operations={[...detailedDefectiveStatement.operations]}
        maintenanceWorkKind={maintenance.maintenanceWorkType.workKind}
      />
    );
  };
  
  getPartsComponent = () => {
    const {declined} = this.state;
    const {
      detailedDefectiveStatementReadOnly,
      detailedDefectiveStatement,
      maintenance,
    } = this.props;
    if (declined || detailedDefectiveStatementReadOnly) {
      return (
        <PartsAndConsumablesReadOnly
          workType={maintenance.maintenanceWorkType.workType}
          orderContractorType={maintenance.orderContractorType}
          detailedDefectiveStatement={declined || detailedDefectiveStatement}
        />
      );
    }
    return (
      <PartsAndConsumables
        workType={maintenance.maintenanceWorkType.workType}
        orderContractorType={maintenance.orderContractorType}
        maintenanceId={maintenance.id}
        parts={[...detailedDefectiveStatement.parts]}
      />
    );
  };
  
  savePeriod = async (startDate: string, endDate: string) => {
    const {
      detailedDefectiveStatement,
      saveDetailedDefectiveStatement,
    } = this.props;
    try {
      notificationLoading({
        message: 'Сохрание периода...',
        key: 'saving',
      });
      if (detailedDefectiveStatement) {
        await saveDetailedDefectiveStatement({
          ...detailedDefectiveStatement,
          startDate,
          endDate,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при сохранении периода',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  render() {
    const {declined} = this.state;
    const {
      maintenance,
      detailedDefectiveStatement,
      detailedDefectiveStatementReadOnly,
    } = this.props;
    return (
      <Section>
        <SectionTitle
          divider
          suffix={this.getSuffixDetailedDefectiveStatement()}
        >
          Детальная дефектно-ресурсная ведомость
        </SectionTitle>
        <Content>
          {maintenance && (
            <>
              <PeriodDetailedDefectiveStatement
                detailedDefectiveStatement={
                  declined || detailedDefectiveStatement
                }
                detailedDefectiveStatementReadOnly={
                  detailedDefectiveStatementReadOnly
                }
                savePeriod={this.savePeriod}
              />
              {declined && (
                <Grid gutter="16px">
                  <GridItem fullWidth>
                    <Field label="Причина отклонения">
                      {declined.declineReason ?? '-'}
                    </Field>
                  </GridItem>
                </Grid>
              )}
              <ContentTitle style={{marginTop: 0}}>Работы</ContentTitle>
              {this.getOperationComponent()}
              
              <ContentTitle>Расходники и запчасти</ContentTitle>
              {this.getPartsComponent()}
            </>
          )}
        </Content>
      </Section>
    );
  }
}

function DeclineDetailedDefectiveStatementButton(props) {
  const [reason, setReason] = useState(null);
  const [visible, setVisible] = useState(false);
  const onConfirm = e => {
    if (reason === null) {
      notification.warning({
        message: 'Укажите причину отклонения',
      });
      setVisible(true);
    } else {
      props.onConfirm(reason);
      setReason(null);
    }
  };
  return (
    <Popconfirm
      zIndex={2000}
      placement="left"
      onConfirm={onConfirm}
      visible={visible}
      onVisibleChange={setVisible}
      title={
        <>
          <Input
            placeholder="Причина отклонения"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </>
      }
    >
      Отклонить
    </Popconfirm>
  );
}

function PeriodDetailedDefectiveStatement(props) {
  const {
    detailedDefectiveStatement,
    savePeriod,
    detailedDefectiveStatementReadOnly,
  } = props;
  const [dirty, setDirty] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  
  useEffect(() => {
    const {startDate, endDate} = detailedDefectiveStatement;
    setDateRange({
      startDate: startDate ? moment(startDate) : startDate,
      endDate: endDate ? moment(endDate) : endDate,
    });
  }, [detailedDefectiveStatement]);
  
  return (
    <Grid gutter="16px" style={{marginBottom: '16px'}} cols={4}>
      <GridItem>
        <DatePicker
          placeholder="Дата начала"
          value={dateRange.startDate}
          format="DD MMMM YYYY"
          disabled={detailedDefectiveStatementReadOnly}
          onChange={(startDate: string) => {
            setDateRange({...dateRange, startDate});
            !dirty && setDirty(true);
          }}
        />
      </GridItem>
      <GridItem>
        <DatePicker
          placeholder="Дата окончания"
          value={dateRange.endDate}
          format="DD MMMM YYYY"
          disabled={detailedDefectiveStatementReadOnly || !dateRange.startDate}
          disabledDate={(date: string) =>
            dateRange.startDate
              ? moment
              .utc(date)
              .startOf('day')
              .isBefore(moment.utc(dateRange.startDate), 'day')
              : false
          }
          onChange={(endDate: string) => {
            setDateRange({...dateRange, endDate});
            !dirty && setDirty(true);
          }}
        />
      </GridItem>
      {dirty && (
        <GridItem fullWidth style={{marginTop: '10px'}}>
          <Button
            type="primary"
            onClick={() => {
              savePeriod(
                moment
                .utc(dateRange.startDate)
                .startOf('day')
                .toISOString(),
                moment
                .utc(dateRange.endDate)
                .startOf('day')
                .toISOString(),
              );
              setDirty(false);
            }}
          >
            Сохранить
          </Button>
        </GridItem>
      )}
    </Grid>
  );
}

export default connect(
  state => ({
    userAccess: state.auth.profile.access,
    detailedDefectiveStatement: state.detailedDefectiveStatement,
  }),
  {setDetailedDefectiveStatement, saveDetailedDefectiveStatement},
)(DetailedDefectiveStatementCard);
