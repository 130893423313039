// @flow
import React, { useState, useCallback } from 'react';

import { navigate, formatRub, multipliedBy } from '../../lib/helpers';
import { overhaulTypeApi } from '../../lib/api';
import type { UserAccess, OverhaulType } from '../../lib/types';
import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';

import { Card } from '../../components';
import { Table } from '../../components/ui';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Section, SectionTitle } from '../../components/layout';
import { SectionContent } from '../../components/hoc/common/components/elements';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';

import { withUserAccess } from './../withUserAccess';
import { addAccessRight } from './accessRight';

const { Field } = Card;

type InnerProps = {
  data: $Shape<OverhaulType>
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/overhaul-types/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Section>
        <SectionContent>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Модель ТС">{props.data.vehicleModelName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Вид работы по капитальному ремонту">
                {props.data.vehicleIssueGroupName}
              </Field>
            </GridItem>
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle divider>Операции по ремонту</SectionTitle>
        <SectionContent>
          <Grid gutter="16px">
            {props.data.operations.map((el, index) => {
              return (
                <GridItem fullWidth key={index}>
                  <Field>
                    {index + 1}: {el.name}
                  </Field>
                </GridItem>
              );
            })}
          </Grid>
        </SectionContent>
      </Section>
      <Section>
        <SectionTitle divider>
          Планируемый перечень запасных запчастей
        </SectionTitle>
        <SectionContent>
          <Table
            columns={[
              {
                title: 'Наименование',
                width: 250,
                dataIndex: 'name'
              },
              {
                title: 'Мнемокод',
                dataIndex: 'mnemocode'
              },
              {
                title: 'Кол-во',
                dataIndex: 'count'
              },
              {
                title: 'Цена',
                dataIndex: 'currentCost',
                render: cost => formatRub(cost)
              },
              {
                title: 'Стоимость',
                dataIndex: 'currentCost',
                render: (cost: number, record: any) =>
                  formatRub(multipliedBy(cost, record.count))
              }
            ]}
            data={props.data.mtrs}
          />
        </SectionContent>
      </Section>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: OverhaulType, setData] = useState({
    id: +props.id,
    mtrs: [],
    operations: []
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await overhaulTypeApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await overhaulTypeApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Виды работ по капитальному ремонту ТС',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} noWrapMe />
    </CommonCardPage>
  );
});
