// @flow
import CommonApi from '../commonApi';
import { OilConsumptionDataType } from '../types/oilConsumption';

const basedUrl = 'OilConsumption';
const writeOffAct = new CommonApi<OilConsumptionDataType>(
  basedUrl,
  'акт списания МПЗ'
);
export default { ...writeOffAct };
