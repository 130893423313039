// @flow
import React, {useState, useCallback} from 'react';

import {navigate} from '../../lib/helpers';
import {ISUBudgetingDataApi} from '../../lib/api';
import {ISUBudgetingDataType} from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import {InnerForm} from './components';
import {getBreadCrumbsByUrlForEdit} from '../../lib/autoBreadcrumbs';

type PageProps = {
  id: number,
};

const entryPointPath = '/admin/ISUBudgetingData';

export default (props: PageProps) => {
  const [data: ISUBudgetingDataType, setData] = useState({
    id: props.id,
  });
  
  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, props.id);
  
  const onFetch = useCallback(async () => {
    !!props.id && setData(await ISUBudgetingDataApi.get(props.id));
  }, [props.id]);
  
  const onSubmit = async (payload: ISUBudgetingDataType) => {
    let id = payload.id;
    if (id) {
      await ISUBudgetingDataApi.update(payload);
    } else {
      let data = await ISUBudgetingDataApi.add(payload);
      id = data.id;
    }
    await navigate(`${entryPointPath}`, true);
  };
  
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Данные для ИСУ "Бюджетирование"',
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
