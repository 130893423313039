// @flow
import React from 'react';
import styled from 'styled-components';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from '../../../../components';
import {CustomInput} from '../../../../components/ui';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import type {OwnerType, Vehicle, VehicleStatus, VehicleType} from '../../../../lib/types';
import {ownerTypes} from '../../../../lib/enum';
import OrgUnitSelect from '../../../../components/OrgUnitSelect';
import Field from '../../../../components/card/Field';
import {applyMaskToValue} from '../../../../lib/helpers';
import {formatLicensePlateMask} from '../../../../components/masked-inputs/LicensePlateInput';
import WashingPlanVehiclesSelect from './WashingPlanVehiclesSelect';

const {
  VehicleTypeSelect,
  VehicleStatusSelect,
  BrandsSelect,
  VehicleModelSelect
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type MounthlyWashingPlansFilterParams = {
  nodeId?: number,
  yearIssued?: number,
  status?: VehicleStatus,
  'vehicleModel.type'?: VehicleType,
  'vehicleModel.id'?: number,
  'vehicleModel.brand.id'?: number,
  'vehicleModel.osagoNeeded'?: boolean,
  vehicleId?: number,
  vin?: string,
  ownerType?: OwnerType
};

type Props = {
  filter: MounthlyWashingPlansFilterParams,
  monthlyWashingPlanId: number,
  cleanFilter: Function,
  applyFilter: Function
};

export const MounthlyWashingPlansFilter = ({
  filter,
  monthlyWashingPlanId,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<MounthlyWashingPlansFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  placeholder="Все подразделения"
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <WashingPlanVehiclesSelect
                  placeholder="Все ТС"
                  monthlyWashingPlanId={monthlyWashingPlanId}
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) =>
                    applyMaskToValue(
                      vehicle.licensePlate,
                      formatLicensePlateMask
                    )
                  }
                  filter={{
                    ownerType: ownerTypes.self
                  }}
                  onChange={value => {
                    changeValue('vehicleId', value);
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  placeholder="Все типы ТС"
                  size="small"
                  value={values['vehicleModel.type']}
                  selectableGroup
                  onChange={value => changeValue('vehicleModel.type', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleStatusSelect
                  placeholder="Все статусы"
                  size="small"
                  value={values.status}
                  onChange={value => changeValue('status', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <CustomInput
                  size="small"
                  placeholder="VIN"
                  value={values.vin}
                  onChange={({ target: { value } }) =>
                    changeValue('vin', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <BrandsSelect
                  placeholder="Все марки"
                  size="small"
                  value={values['vehicleModel.brand.id']}
                  onChange={value =>
                    changeValue('vehicleModel.brand.id', value)
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleModelSelect
                  placeholder="Все модели ТС"
                  size="small"
                  value={values['vehicleModel.id']}
                  filter={{
                    'brand.id': values['vehicleModel.brand.id'],
                    type: values['vehicleModel.type']
                  }}
                  onChange={value => changeValue('vehicleModel.id', value)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MounthlyWashingPlansFilter;
