// @flow
import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import type { BudgetsForAnalysis, UserAccess } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import { OrgUnitSelect, Selects } from '../../../components';
import { StyledInputNumber } from '../../../components/hoc/common/components/elements';
import { ApproveYesNoSelect } from '../../../components/selects/AutoGeneratedEnumSelects';

import { withUserAccess } from './../../withUserAccess';
import { approvingAccessRight } from './../accessRight';

const { VehiclePlanSelect } = Selects;

const filter = {
  status: 'approved',
  budgetStatus: 'calculationDone'
};

export default withUserAccess(
  (
    props: CommonFormProps<$Shape<BudgetsForAnalysis>> & {
      userAccess: UserAccess[]
    }
  ) => {
    const canApprove = () =>
      props.userAccess.some(access => approvingAccessRight.includes(access));
    return (
      <CommonForm {...props}>
        {({ setFieldValue, values }) => {
          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Подразделение"
                    fast
                    required
                    name="orgUnitId"
                  >
                    {({ name, value }) => (
                      <OrgUnitSelect
                        value={value}
                        onlyBranches
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Бюджет основной"
                    fast
                    required
                    name="mainBudgetId"
                  >
                    {({ name, value }) => (
                      <VehiclePlanSelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        filter={filter}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Бюджет БК" fast required name="bkBudgetId">
                    {({ name, value }) => (
                      <VehiclePlanSelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        filter={filter}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Бюджет БКФ"
                    fast
                    required
                    name="bkfBudgetId"
                  >
                    {({ name, value }) => (
                      <VehiclePlanSelect
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        filter={filter}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Максимальные затраты"
                    fast
                    required
                    name="maxRate"
                  >
                    {({ name, value }) => (
                      <StyledInputNumber
                        min={0}
                        max={100}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата начала" name="startDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                              .utc(value)
                              .startOf('day')
                              .toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата окончания" name="endDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={!values.startDate}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                                .utc(date)
                                .startOf('day')
                                .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                              .utc(value)
                              .startOf('day')
                              .toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {canApprove() && (
                  <GridItem>
                    <FormField label="Статус" required name="status">
                      {({ name, value }) => (
                        <ApproveYesNoSelect
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
              </Grid>
            </>
          );
        }}
      </CommonForm>
    );
  }
);
