// @flow

import React from 'react';
import TreeSelect, {
  TreeNode,
  type TreeSelectProps
} from 'antd/lib/tree-select';

import { maintenanceOperationApi } from '../../lib/api';
import type {
  MaintenanceOperation,
  MaintenanceOperationGroup
} from '../../lib/types';

type Props = {
  onSelectGroup: (value: MaintenanceOperationGroup) => void,
  onSelectOperation: (value: MaintenanceOperation) => void
} & TreeSelectProps;

type State = {
  group?: MaintenanceOperationGroup
};

export default class MaintenanceOperationGroupSelect extends React.Component<
  Props,
  State
> {
  state = {
    group: undefined
  };

  async componentDidMount() {
    const group = await maintenanceOperationApi.fetchMaintenanceOperations();
    this.setState({
      group
    });
  }

  onSelect = (value: any, title: string[], treeNode: TreeNode) => {
    const { props } = treeNode.triggerNode;
    const { group, operation } = props;
    if (group !== undefined) {
      this.props.onSelectGroup(group);
    } else if (operation !== undefined) {
      this.props.onSelectOperation(operation);
    }
  };

  renderTree(group?: MaintenanceOperationGroup) {
    if (group === undefined) return null;
    if (group.children.length === 0) {
      return (
        <TreeNode
          key={group.key}
          title={group.code}
          value={group.id}
          group={group}
        >
          {group.operations.map(operation => (
            <TreeNode
              value={`${operation.groupId}-${operation.id}`}
              key={operation.id}
              title={operation.name}
              operation={operation}
            />
          ))}
        </TreeNode>
      );
    }
    return (
      <TreeNode key={group.key} title={group.code} value={group}>
        {group.operations.map(operation => (
          <TreeNode
            value={`${operation.groupId}-${operation.id}`}
            key={operation.id}
            title={operation.name}
          />
        ))}
        {group.children.map(group => this.renderTree(group))}
      </TreeNode>
    );
  }

  render() {
    return (
      <TreeSelect allowClear onChange={this.onSelect}>
        {this.renderTree(this.state.group)}
      </TreeSelect>
    );
  }
}
