// @flow
import type {
  CalculationStatus,
  TireCalculation,
  TireCalculationMonth
} from './../types';

import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  tireCalculation: TireCalculation
): TireCalculation => ({
  ...tireCalculation,
  sum: convertFromRubToKop(tireCalculation.sum),
  months: tireCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  tireCalculation: TireCalculation
): TireCalculation => ({
  ...tireCalculation,
  sum: convertFromKopToRub(tireCalculation.sum),
  months: tireCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  tireCalculationMonth: TireCalculationMonth
): TireCalculationMonth => ({
  ...tireCalculationMonth,
  cost: convertFromRubToKop(tireCalculationMonth.cost),
  details: tireCalculationMonth.details.map(tireDetails => ({
    ...tireDetails,
    costForOneTire: convertFromRubToKop(tireDetails.costForOneTire)
  }))
});

export const convertDataFromServerMonth = (
  tireCalculationMonth: TireCalculationMonth
): TireCalculationMonth => ({
  ...tireCalculationMonth,
  cost: convertFromKopToRub(tireCalculationMonth.cost),
  details: tireCalculationMonth.details.map(tireDetails => ({
    ...tireDetails,
    costForOneTire: convertFromKopToRub(tireDetails.costForOneTire)
  }))
});

const basedUrl = 'tireCalculation';
const tireCalculation = new CommonApi<TireCalculation>(
  basedUrl,
  'расчет автошин',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async (monthData: TireCalculationMonth) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/correct`,
    convertDataToServerMonth(monthData)
  );
  if (!updated) {
    throw new Error(`Не удалось обновить данные`);
  }
};

export default {
  ...tireCalculation,
  calculate,
  changeStatus,
  print,
  updateMonth
};
