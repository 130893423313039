// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';

import { FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';
import type { FormFieldType } from '../../../../../components/Form';
import { GridItem } from '../../../../../components/layout';

import { notificationLoading } from '../../../../../components/Notifications';
import {
  calculationStatusEnum,
  entityStatusEnum
} from '../../../../../lib/enum';
import { toLocalStringRu } from '../../../../../lib/helpers';

import type {
  RepairBudget,
  UserAccess,
  VehiclePlan
} from '../../../../../lib/types';

import { withUserAccess } from '../../../../withUserAccess';
import { canApprovingBudgetRepair } from '../../accessRight';

import { headerPanel } from '../components/Common';
import { Form } from './../../../../../components';
import Field from './../../../../../components/card/Field';
import {
  Grid,
  Section,
  SectionTitle
} from './../../../../../components/layout';
import { repairBudgetApi, vehiclePlanApi } from './../../../../../lib/api';

const StyledSection = styled(Section)`
  padding: 16px;
`;

type Props = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
type State = {
  repairBudget?: RepairBudget,
  vehiclePlan?: VehiclePlan
};
export default withUserAccess(
  class extends React.Component<Props, State> {
    state = {
      repairBudget: undefined,
      vehiclePlan: undefined
    };

    componentWillMount() {
      this.fetch();
    }

    fetch = async () => {
      const { vehiclePlanId } = this.props;
      try {
        const repairBudget = await repairBudgetApi.getByVehiclePlan(
          vehiclePlanId
        );
        const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
        this.setState({ repairBudget, vehiclePlan });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    };

    checkOverhaulCalculationStatus = async () => {
      const { vehiclePlanId } = this.props;
      const { overhaulCalculationStatus } = await vehiclePlanApi.get(
        vehiclePlanId
      );
      if (overhaulCalculationStatus !== calculationStatusEnum.calculationDone) {
        throw new Error(
          'Не сформирован план по капитальному и текущему ремонту'
        );
      }
    };

    handleSubmit = async (repairBudget: RepairBudget) => {
      try {
        notificationLoading({
          message: 'Сохранение...',
          key: 'saving'
        });
        if (repairBudget.status === entityStatusEnum.created) {
          await this.checkOverhaulCalculationStatus();
        }
        const updated = await repairBudgetApi.update({
          ...repairBudget,
          status:
            repairBudget.status === entityStatusEnum.created
              ? entityStatusEnum.approvement
              : entityStatusEnum.approved
        });
        this.setState({
          repairBudget: updated
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      } finally {
        notification.close('saving');
      }
    };

    declineRepairBudget = async () => {
      const { repairBudget } = this.state;
      try {
        const updated = await repairBudgetApi.update({
          ...repairBudget,
          status: entityStatusEnum.created
        });
        this.setState({
          repairBudget: updated
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    };

    render() {
      const { vehiclePlanId, userAccess } = this.props;
      const { repairBudget, vehiclePlan } = this.state;
      return (
        <>
          {headerPanel({ title: 'Текущий ремонт', vehiclePlanId, vehiclePlan })}
          <InnerForm
            repairBudget={repairBudget}
            userAccess={userAccess}
            onSubmit={this.handleSubmit}
            onCancel={this.declineRepairBudget}
          />
        </>
      );
    }
  }
);

type FormProps = {
  repairBudget: ?RepairBudget,
  userAccess: UserAccess[],
  onSubmit: Function,
  onCancel: Function
};
export const InnerForm = ({
  userAccess,
  repairBudget,
  onSubmit,
  onCancel
}: FormProps) => {
  return (
    <Form initialValues={repairBudget} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, setFieldValue, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={3}>
                <GridItem>
                  <Field label="Итого стоимость с учетом работ и материалов, руб">
                    {toLocalStringRu(values.plannedSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Итого стоимость работ, руб">
                    {toLocalStringRu(values.operationsSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Итого стоимость материалов, руб">
                    {toLocalStringRu(values.materialsSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Отклонения от заключенных договоров">
                    {toLocalStringRu(values.differenceSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>
                <GridItem>
                  {values.status !== entityStatusEnum.created ? (
                    <Field label="Причина отклонения">
                      {values.diffReason ? values.diffReason : '-'}
                    </Field>
                  ) : (
                    <FormField
                      label="Причина отклонения"
                      name="diffReason"
                      required={+values.differenceSum < 0}
                    >
                      {({ name, value }) => (
                        <Input
                          name={name}
                          disabled={!canApprovingBudgetRepair(userAccess)}
                          value={value}
                          onChange={e => setFieldValue(name, e.target.value)}
                          placeholder="Введите причину отклонения"
                        />
                      )}
                    </FormField>
                  )}
                </GridItem>
                <GridItem />
                {values.status === entityStatusEnum.created &&
                  canApprovingBudgetRepair(userAccess) && (
                    <GridItem fullWidth>
                      <Button type="primary" htmlType="submit">
                        На согласование
                      </Button>
                    </GridItem>
                  )}
                {values.status === entityStatusEnum.approvement &&
                  canApprovingBudgetRepair(userAccess) && (
                    <GridItem fullWidth style={{ marginTop: '16px' }}>
                      <Button type="primary" htmlType="submit">
                        Согласовать
                      </Button>
                      <Button onClick={onCancel} style={{ marginLeft: '16px' }}>
                        Отклонить
                      </Button>
                    </GridItem>
                  )}
              </Grid>
            </StyledSection>

            <StyledSection>
              <SectionTitle divider>Текущий ремонт</SectionTitle>
              <Grid gutter="16px" style={{ marginTop: '16px' }} col={3}>
                <GridItem>
                  <Field label="Итого стоимость текущего ремонта c учетом работ и материалов">
                    {toLocalStringRu(values.currentRepairSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>

                <GridItem fullWidth>
                  <SectionTitle>Ремонт хоз.способом</SectionTitle>
                  <Grid cols={3}>
                    <GridItem>
                      <Field label="Итого стоимость хоз.способом с учетом работ и материалов, руб.">
                        {toLocalStringRu(values.currentRepairFullInternalSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость материалов для хоз.способа, руб.">
                        {toLocalStringRu(
                          values.currentRepairInternalMaterialsSum,
                          { minimumFractionDigits: 2 }
                        )}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость работ для хоз.способа, руб">
                        {toLocalStringRu(
                          values.currentRepairInternalOperationsSum,
                          { minimumFractionDigits: 2 }
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                </GridItem>

                <GridItem fullWidth>
                  <SectionTitle>Ремонт подрядом</SectionTitle>
                  <Grid cols={3}>
                    <GridItem>
                      <Field label="Итого стоимость с учетом работ и материалов, руб.">
                        {toLocalStringRu(values.currentRepairFullContractSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость материалов для подряда, руб.">
                        {toLocalStringRu(
                          values.currentRepairContractMaterialsSum,
                          { minimumFractionDigits: 2 }
                        )}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость работ для подряда, руб">
                        {toLocalStringRu(
                          values.currentRepairContractOperationsSum,
                          { minimumFractionDigits: 2 }
                        )}
                      </Field>
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </StyledSection>

            <StyledSection>
              <SectionTitle divider>Капитальный ремонт</SectionTitle>
              <Grid col={3} gutter="16px" style={{ marginTop: '16px' }}>
                <GridItem>
                  <Field label="Итого стоимость капитального ремонта c учетом работ и материалов, руб.">
                    {toLocalStringRu(values.overhaulSum, {
                      minimumFractionDigits: 2
                    })}
                  </Field>
                </GridItem>

                <GridItem fullWidth>
                  <SectionTitle>Ремонт хоз.способом</SectionTitle>
                  <Grid cols={3}>
                    <GridItem>
                      <Field label="Итого стоимость хоз.способом с учетом работ и материалов, руб.">
                        {toLocalStringRu(values.overhaulFullInternalSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость материалов для хоз. способа, руб.">
                        {toLocalStringRu(values.overhaulInternalMaterialsSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость работ для хоз. способа, руб">
                        {toLocalStringRu(values.overhaulInternalOperationsSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                  </Grid>
                </GridItem>

                <GridItem fullWidth>
                  <SectionTitle>Ремонт подрядом</SectionTitle>
                  <Grid cols={3}>
                    <GridItem>
                      <Field label="Итого стоимость с учетом работ и материалов, руб.">
                        {toLocalStringRu(values.overhaulFullContractSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость материалов для подряда, руб.">
                        {toLocalStringRu(values.overhaulContractMaterialsSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Итого стоимость работ для подряда, руб">
                        {toLocalStringRu(values.overhaulContractOperationsSum, {
                          minimumFractionDigits: 2
                        })}
                      </Field>
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </StyledSection>
          </form>
        );
      }}
    </Form>
  );
};
