// @flow

import type { User } from './../types';
import type { Profile } from './../../ducks/auth';
import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { UsersFilterParams } from '../../containers/Users/Filter';

export const addUser = async (user: User): Promise<void> => {
  const added = await fetchRequest.post('/users', user);
  if (added) return added;
  throw new Error('Не удалось создать пользователя');
};

export const updateUser = async (user: User): Promise<User> => {
  const updated = await fetchRequest.put('/users', user);
  if (updated) return updated;
  throw new Error('Не удалось обновить пользователя');
};

export const fetchUser = async (
  id: number,
  params: any = {}
): Promise<User> => {
  let user = await fetchRequest.get(`/users/${id}`, params);
  if (user) return user;
  throw new Error('Не удалось загрузить пользователя');
};

export const fetchUsers = async (
  params: FetchListParams<UsersFilterParams> = initialFetchListParams
): Promise<ListResponse<User>> =>
  await fetchRequest.get('/users', {
    ...initialFetchListParams,
    ...params
  });

export const deleteUser = async (id: number): Promise<User> => {
  const deleted = await fetchRequest.delete(`/users/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить пользователя');
};

export const fetchProfile = async (): Promise<Profile> => {
  const profile = await fetchRequest.get('/users/me');
  if (profile) return profile;
  throw new Error('Не удалось получить текущий профиль');
};

export const changePassword = async (user: {
  password: string,
  userId: number
}): Promise<void> => {
  const changed = await fetchRequest.post('/users/change-password', user);
  if (changed) return changed;
  throw new Error('Не удалось создать пользователя');
};

export const print = async () => {
  const printed = await downloadRequestWithToken('/users/print');
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  addUser,
  updateUser,
  fetchUsers,
  fetchUser,
  deleteUser,
  fetchProfile,
  changePassword,
  print
};
