// @flow

import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { Tire } from '../types/index';
import type { FetchListParams, ListResponse } from './index';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromMToKm,
  convertFromSecondToHour
} from '../helpers';
import type { TireFilterParams } from '../../containers/Equipment/Tires/Filter';
import type { TireWorkCard } from '../types/tireWorkCard';

// Коды статусов списания шин для использования в запросах
export const DecomissedStatusCode = {
  // Не списано
  notDecomissed: 0,
  // Необходимо списать
  toDecomissed: 1,
  // Списано
  decomissed: 2
};

// Статусы списания шин
export const DecomissedStatuses = {
  // Не списано
  notDecomissed: 'notDecomissed',
  // Необходимо списать
  toDecomissed: 'toDecomissed',
  // Списано
  decomissed: 'decomissed'
};

export const getDecomissionStatusName = (status?: string): string => {
  switch (status) {
    case DecomissedStatuses.notDecomissed:
      return 'В работе';
    case DecomissedStatuses.toDecomissed:
      return 'Требует списания';
    case DecomissedStatuses.decomissed:
      return 'Списано';
    default:
      return '-';
  }
};

export const convertDataFromServer = (tire: Tire) => ({
  ...tire,
  actualKilometrage: convertFromMToKm(tire.actualKilometrage),
  normKilometrage: convertFromMToKm(tire.normKilometrage),
  workHours: convertFromSecondToHour(tire.workHours),
  normWorkHours: convertFromSecondToHour(tire.normWorkHours),
  initialKilometrage: convertFromMToKm(tire.initialKilometrage),
  initialWorkHours: convertFromSecondToHour(tire.initialWorkHours)
});

const convertDataToServer = (tire: Tire) => ({
  ...tire,
  actualKilometrage: convertFromKmToM(tire.actualKilometrage),
  normKilometrage: convertFromKmToM(tire.normKilometrage),
  workHours: convertFromHourToSecond(tire.workHours),
  normWorkHours: convertFromHourToSecond(tire.normWorkHours),
  initialKilometrage: convertFromKmToM(tire.initialKilometrage),
  initialWorkHours: convertFromHourToSecond(tire.initialWorkHours)
});

export const addTire = async (tire: Tire): Promise<Tire> => {
  const added = await fetchRequest.post('/tire', convertDataToServer(tire));
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать шину');
};

export const fetchTires = async (
  params: FetchListParams<TireFilterParams> = initialFetchListParams
): Promise<ListResponse<Tire>> => {
  const tires = await fetchRequest.get('/tire', {
    ...initialFetchListParams,
    ...params
  });
  return {
    ...tires,
    data: tires.data.map(convertDataFromServer)
  };
};

export const fetchTire = async (
  id: number,
  params: any = {}
): Promise<Tire> => {
  const tire = await fetchRequest.get(`/tire/${id}`, params);
  if (tire) return convertDataFromServer(tire);
  throw new Error('Не удалось');
};

export const updateTire = async (tire: Tire): Promise<Tire> => {
  const updated = await fetchRequest.put('/tire', convertDataToServer(tire));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить шину');
};

export const deleteTire = async (id: number): Promise<Tire> => {
  const deleted = await fetchRequest.delete(`/tire/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить шину');
};

export const writeOffTire = async (tireWorkCard: TireWorkCard): void => {
  const updated = await fetchRequest.post('/tireworkcard ', tireWorkCard);
  if (updated) return;
  throw new Error('Не удалось списать шину');
};

export const updateTireWorkCard = async (tireWorkCard: TireWorkCard): void => {
  const updated = await fetchRequest.put('/tireworkcard ', tireWorkCard);
  if (updated) return;
  throw new Error('Не удалось обновить карточку учета шины');
};

export const setTireVehicle = async (
  tireId: number,
  vehicleId: number,
  installDate?: ?string
): Promise<Object> => {
  const updatedTire = await fetchRequest.post(
    `/tire/${tireId}/${vehicleId}${
      installDate ? `?installDate=${installDate}` : ''
    }`
  );
  return convertDataFromServer(updatedTire);
};

export const fetchVehicleTires = async (vehicleId: number): Promise<Tire[]> => {
  const tires = await fetchRequest.get(`/tire/${vehicleId}/vehicle`);
  return tires.map(convertDataFromServer);
};

export const updateTireVehicle = async (tire: Tire) => {
  const updatedTire = await fetchRequest.put(
    '/tire/',
    convertDataToServer(tire)
  );
  return convertDataFromServer(updatedTire);
};

export const fetchHistory = async (
  params: FetchListParams<any>,
  tireId: number
): Promise<ListResponse<Object>> =>
  await fetchRequest.get(`/tirehistory/${tireId}`);

export const printWorkCard = async (
  workCardId: number,
  type: 'xls' | 'pdf'
) => {
  const printed = await downloadRequestWithToken(
    `/tireworkcard/${workCardId}/Print?type=${type}`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  setTireVehicle,
  fetchTires,
  addTire,
  fetchVehicleTires,
  fetchTire,
  updateTire,
  deleteTire,
  updateTireVehicle,
  fetchHistory,
  writeOffTire,
  updateTireWorkCard,
  printWorkCard
};
