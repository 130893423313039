/* ContractTripList.js | ПЛ НТС */
// @flow
import React, { Component } from 'react';
import { Header } from '../../../components/layout';
import Section from '../../../components/layout/Section';
import { getListInitialState, navigate } from '../../../lib/helpers';
import type { ListState, UserAccess } from '../../../lib/types';
import { ContractTripApi } from '../../../lib/api';
import notification from 'antd/lib/notification';
import ContractTripMonthFilter from './components/ContractTripMonthFilter';
import { Button } from 'antd';
import { withUserAccess } from '../../withUserAccess';
import {
  accessTypeEnum,
  monthsNamesTranslitEnum,
  newContractTripStatusEnum,
  newContractTripStatuses,
} from '../../../lib/enum';
import { Table } from '../../../components/ui';
import moment from 'moment';
import { ContractTripMonth } from '../../../lib/types/contractTrips';
import qs from 'query-string';
import FileUploadModal from './components/FileUploadModal';
import { notificationLoading } from '../../../components/Notifications';
import Icon from '../../../components/ui/Icon';
import Popconfirm from 'antd/lib/popconfirm';

type Props = {
  userAccess: UserAccess
};

type State = ListState<ContractTripMonth> & {
  filter: string,
  columns: any[],
  fileModalVisible: boolean
};

class TripListByType extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    columns: [
      {
        title: '№',
        dataIndex: 'id',
        width: 25
      },
      {
        title: 'Месяц',
        dataIndex: 'month',
        width: 80,
        render: month => monthsNamesTranslitEnum[month]
      },
      {
        title: 'Год',
        dataIndex: 'year',
        width: 80
      },
      {
        title: 'Филиал',
        dataIndex: 'orgUnitName'
      },
      {
        title: 'Дата формирования',
        dataIndex: 'created',
        render: value => moment.utc(value).format('DD.MM.YYYY')
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        render: (status: string) => newContractTripStatuses[status]
      }
    ],
    fileModalVisible: false
  };

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        page: page || 1,
        filter: { ...filter }
      },
      () => this.fetch(page)
    );
  }

  fetch = async (page = 1) => {
    try {
      const { filter, pageSize } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await ContractTripApi.fetchTripMonth({
        page,
        pageSize,
        ...filter
      });
      this.setState({ data, totalCount, loading: false, page });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title
      });
    }
  };

  applyFilter = filter => {
    this.setState({ filter, page: 1 }, this.fetch);
  };

  cleanFilter = () => {
    this.setState({ filter: {} }, this.fetch);
  };

  canEdit = () =>
    [accessTypeEnum.admin, accessTypeEnum.handlingContractTrip].some(access =>
      this.props.userAccess.includes(access)
    );

  deleteMonth = async id => {
    try {
      notificationLoading({ message: 'Удаление записи', key: 'deleting' });
      await ContractTripApi.deleteMonth(id);
      notification.success({
        message: 'Запись удалена'
      });
      this.fetch(this.state.page);
    } catch (e) {
      notification.error({
        message: 'Ошибка удаления',
        description: e.title || e.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const {
      filter,
      data,
      page,
      pageSize,
      columns,
      totalCount,
      loading,
      fileModalVisible
    } = this.state;
    return (
      <>
        <Header
          left={<h1>Наемные ТС</h1>}
          right={
            this.canEdit() && (
              <Button
                type="primary"
                onClick={() => this.setState({ fileModalVisible: true })}
              >
                Создать
              </Button>
            )
          }
        />
        <Section>
          <ContractTripMonthFilter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            columns={
              this.canEdit()
                ? [
                    ...columns,
                    {
                      stopPropagation: true,
                      width: 30,
                      dataIndex: 'delete',
                      render: (value, item) => {
                        if (item.status === newContractTripStatusEnum.inSended) {
                          return;
                        }
                        return (
                          <Popconfirm
                            title="Вы уверены, что хотите удалить запись?"
                            onConfirm={() => this.deleteMonth(item.id)}
                          >
                            <Icon type="x" />
                          </Popconfirm>
                        )
                      }
                    }
                  ]
                : columns
            }
            onRow={record => ({
              onClick: () => navigate(`/trips/contract/${record.id}`)
            })}
            fetch={this.fetch}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount
            }}
            data={data}
            rowKey="id"
          />
        </Section>
        <FileUploadModal
          visible={fileModalVisible}
          onClose={() => this.setState({ fileModalVisible: false })}
          updateTripList={() => this.fetch(page)}
          vehicleList={data}
        />
      </>
    );
  }
}

export default withUserAccess(TripListByType);
