// @flow
import React, {Component} from 'react';
import qs from 'query-string';

import Button from 'antd/lib/button';
import AntIcon from 'antd/lib/icon';
import Menu from 'antd/lib/menu';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';

import type {ContractVehiclePlan, ListState, UserAccess} from '../../../../../lib/types';
import {ContractVehiclePlanStatus} from '../../../../../lib/types';
import {contractVehiclePlanApi} from './../../../../../lib/api';
import {contractVehiclePlanStatus} from '../../../../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToString,
  getListInitialState,
  navigate,
} from '../../../../../lib/helpers';

import {Header, Section} from './../../../../../components/layout';
import {Dropdown, Operations, Table} from './../../../../../components/ui';
import {notificationLoading} from '../../../../../components/Notifications';

import {withUserAccess} from './../../../../withUserAccess';

import type {RegistrBudgetFilterParams} from '../components';
import {Filter, MainTabs} from './../components';
import {addAccessRightContractVehicleBudget} from '../accessRight';
import {CognosType} from '../../ContractVehicleBudget';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
  cognosType: $Keys<CognosType>
};
type State = ListState<ContractVehiclePlan> & {
  filter: RegistrBudgetFilterParams
};

class RegistrBudgetList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
  };

  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState(
      {
        filter: {...filter},
        page,
      },
      () => this.fetchRegistrBudget(page),
    );
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.cognosType !== prevProps.cognosType) {
      await this.setState({filter: {}});
      await this.fetchRegistrBudget();
    }
  }

  columns = [
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName',
    },
    {
      title: 'Планируемый период',
      dataIndex: 'startDate',
      render: (text: string, record: any): ?string =>
        formatDateRangeString(record.startDate, record.endDate),
    },
    {
      title: 'Версия бюджета',
      dataIndex: 'budgetVersion.name',
    },
    {
      title: 'Дата формирования',
      dataIndex: 'date',
      width: '150',
      render: (created: string) =>
        `${formatDateTimeToString(created, 'DD.MM.YYYY')}`,
    },
    {
      title: 'Наименование',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      render: (status: ContractVehiclePlanStatus) =>
        status ? contractVehiclePlanStatus[status] : '-',
    },
    {
      title: 'Регламент №',
      dataIndex: 'regulationId',
    },
    {
      width: '20px',
      render: (contractVehiclePlan: ContractVehiclePlan, index: number) => {
        if (this.props.cognosType === CognosType.afterCognos) {
          return null;
        }
        const contractVehiclePlanId = contractVehiclePlan.id || 0;
        return (
          <Operations key={index}>
            <Dropdown
              overlayStyle={{zIndex: '999'}}
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() =>
                      navigate(
                        `/budget/contract-vehicle/fixed/${contractVehiclePlanId}/clone`,
                      )
                    }
                  >
                    Копировать перечень ТС
                  </Menu.Item>
                  <Menu.Item>
                    <Popconfirm
                      title="Вы действительно хотите удалить?"
                      okText="Да"
                      cancelText="Нет"
                      placement="bottomRight"
                      onConfirm={() =>
                        this.deleteRegistrBudget(contractVehiclePlanId)
                      }
                    >
                      Удалить бюджет НТС
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <AntIcon
                style={{fontSize: 16, color: '#2770FF'}}
                type="ellipsis"
              />
            </Dropdown>
          </Operations>
        );
      },
      key: 'operations',
    },
  ];

  deleteRegistrBudget = async (id: number = 1) => {
    const {page} = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await contractVehiclePlanApi.delete(id);
      this.fetchRegistrBudget(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchRegistrBudget = async (page: number = 1) => {
    const {filter} = this.state;
    this.setState({loading: true});
    const data = await contractVehiclePlanApi.fetch({
      ...filter,
      page,
      iscognos: this.props.cognosType === CognosType.afterCognos,
    });
    this.setState({...data, loading: false, page});
  };

  applyFilter = (filter: RegistrBudgetFilterParams) => {
    const {page} = this.state;
    this.setState({filter, loading: true}, () =>
      this.fetchRegistrBudget(page),
    );
  };

  cleanFilter = () => {
    const {page} = this.state;
    this.setState({filter: {}, loading: true}, () =>
      this.fetchRegistrBudget(page),
    );
  };

  canAdd = () =>
    this.props.userAccess.some(access =>
      addAccessRightContractVehicleBudget.includes(access),
    );

  render() {
    const {location, cognosType} = this.props;
    const {data, totalCount, pageSize, page, loading, filter} = this.state;
    return (
      <>
        <Header
          left={<h1>Закрепленные НТС</h1>}
          right={
            this.canAdd() && (
              <Button
                type="primary"
                onClick={() => navigate('/budget/contract-vehicle/fixed/new')}
              >
                Создать
              </Button>
            )
          }
        />
        <MainTabs disableAllTabs={loading} />
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
            cognosType={cognosType}
          />
          <Table
            columns={[
              {
                title: '№',
                dataIndex:
                  this.props.cognosType === CognosType.beforeCognos
                    ? 'id'
                    : 'number',
                width: 30,
              },
              ...this.columns,
            ]}
            data={data}
            fetch={this.fetchRegistrBudget}
            fetchOnMount={false}
            onRow={(row: any) => ({
              onClick: () =>
                navigate(
                  `/budget/contract-vehicle/fixed/${cognosType}/${row.id}/card`,
                ),
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(RegistrBudgetList);
