// @flow
import React, { type ComponentType } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import { FormikProps } from 'formik';

import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { Contractor } from '../../../lib/types';
import Form, { type FormFieldProps } from '../../../components/Form';
import { Selects } from './../../../components';
import CancelButton from '../../../components/CancelButton';
import Input from 'antd/lib/input';

const { CompanySelect, ContractServiceSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  contractor: Contractor,
  onSubmit: Function,
  onCancel: Function
};

const InnerForm = ({ onSubmit, contractor, onCancel }: FormProps) => {
  return (
    <Form onSubmit={onSubmit} initialValues={contractor || {}}>
      {(FormField: ComponentType<FormFieldProps>, formikProps: FormikProps) => {
        const {
          handleSubmit,
          handleBlur,
          setFieldValue,
          dirty,
          isSubmitting
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Вид оказываемой услуги"
                      name="services"
                      required
                    >
                      {({ name, value }) => (
                        <ContractServiceSelect
                          mode="multiple"
                          onBlur={() => {
                            handleBlur({
                              target: {
                                name
                              }
                            });
                          }}
                          data-cy="servicesSelect"
                          value={value}
                          name={name}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Контрагенты" name="companyId" required>
                      {({ value, name }) => (
                        <CompanySelect
                          value={value}
                          name={name}
                          data-cy="companySelect"
                          onChange={value => setFieldValue(name, value)}
                          onBlur={() => {
                            handleBlur({
                              target: {
                                name
                              }
                            });
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Код для «ИСУ Бюджетирование»"
                      name="cognosCode"
                    >
                      {props => <Input {...props} />}
                    </FormField>
                  </GridItem>
                </Grid>
              </Content>
            </Section>
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                data-cy="save"
                htmlType="submit"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};

export default InnerForm;
