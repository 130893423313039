// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import moment from 'moment';

import Divider from 'antd/lib/divider';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';

import type { Act } from '../../../../lib/types';
import {
  contractDocumentTypeEnum,
  entityStatusEnum
} from '../../../../lib/enum';
import {
  formatDateTimeToString,
  dividedBy,
  plus,
  multipliedBy
} from '../../../../lib/helpers';

import Form from '../../../../components/Form';
import type { FormFieldType } from '../../../../components/Form';
import { Section } from '../../../../components/layout';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import CancelButton from '../../../../components/CancelButton';
import Selects from '../../../../components/selects';
import Field from '../../../../components/card/Field';

const {
  ContractSelect,
  ExpenseDirectionTypeSelect,
  StageSelect,
  YesNoSelect,
  RateTaxesSelect,
  IncomeScheduleSelect
} = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

type Props = {
  act: ?Act,
  onCancel: Function,
  onSubmit: Function
};

export default ({ act, onCancel, onSubmit }: Props) => {
  const [useAccountNumber, setUseAccountNumber] = useState(false);
  useEffect(() => {
    setUseAccountNumber(
      act && act.contract && act.contract.accountNumber ? true : false
    );
  }, [act]);
  return (
    <Form initialValues={act} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <GridItem fullWidth>
                <Field label="По номеру лицевого счета">
                  <YesNoSelect
                    value={useAccountNumber}
                    onChange={value => {
                      setUseAccountNumber(value);
                    }}
                  />
                </Field>
              </GridItem>
              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <FormField
                    label={`Номер ${
                      useAccountNumber ? 'лицевого счета' : 'договора'
                    }`}
                    name="contractId"
                    required
                  >
                    {({ name, value }) => {
                      return (
                        <ContractSelect
                          placeholder="Введите номер"
                          name={name}
                          filter={{
                            contractStatus: entityStatusEnum.approved,
                            documentType: useAccountNumber
                              ? contractDocumentTypeEnum.account
                              : contractDocumentTypeEnum.contract
                          }}
                          value={
                            (values.contract && values.contract.id) || value
                          }
                          onChange={(contractId: number, option: any) => {
                            setFieldValue(name, contractId);
                            setFieldValue(
                              'contract',
                              option ? option.props.contract : undefined
                            );
                            setFieldValue('stage', undefined);
                            setFieldValue('stageId', undefined);
                          }}
                        />
                      );
                    }}
                  </FormField>
                </GridItem>
                {values.contract && (
                  <>
                    {values.contract.contractor &&
                      values.contract.contractor.company && (
                        <GridItem>
                          <FormField
                            name="contract.contractor.company.name"
                            label="Контрагент"
                          >
                            {({ name, value }) => (
                              <Input disabled value={value} />
                            )}
                          </FormField>
                        </GridItem>
                      )}
                    {values.contract.registrationDate && (
                      <GridItem>
                        <FormField
                          name="contract.registrationDate"
                          label="Дата договора"
                        >
                          {({ name, value }) => (
                            <Input
                              disabled
                              value={formatDateTimeToString(
                                value,
                                'DD.MM.YYYY'
                              )}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    {values.contractor && values.contractor.company.name && (
                      <GridItem span={2}>
                        <FormField
                          name="contract.contractor.company.name"
                          label="Контрагент"
                        >
                          {({ name, value }) => (
                            <Input disabled value={value} />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                    <GridItem>
                      <FormField name="stageId" label="Этап" required>
                        {({ name, value }) => (
                          <StageSelect
                            filter={{ contractId: values.contractId }}
                            name={name}
                            value={value}
                            onChange={(stageId, option) => {
                              setFieldValue('incomeSchedule', undefined);
                              setFieldValue('incomeScheduleId', undefined);
                              setFieldValue(name, stageId);
                              setFieldValue(
                                'stage',
                                option ? option.props.stage : undefined
                              );
                              setFieldValue(
                                'expenseDirectionType',
                                option?.props?.stage?.expenseDirection?.type
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {values.stage && (
                      <>
                        <GridItem>
                          <FormField
                            name="expenseDirectionType"
                            label="Тип направления"
                          >
                            {({ name, value }) => (
                              <ExpenseDirectionTypeSelect
                                disabled={!useAccountNumber}
                                name={name}
                                value={value}
                                onChange={type => setFieldValue(name, type)}
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            name="stage.expenseDirection.name"
                            label="Направление расходов"
                          >
                            {({ name, value }) => (
                              <Input disabled name={name} value={value} />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            name="stage.accountNumber"
                            label="Номер л/с"
                          >
                            {({ name, value }) => (
                              <Input disabled name={name} value={value} />
                            )}
                          </FormField>
                        </GridItem>
                      </>
                    )}
                    {values.stageId && (
                      <GridItem>
                        <FormField
                          name="incomeScheduleId"
                          label="Точка графика"
                          required
                        >
                          {({ name, value }) => (
                            <IncomeScheduleSelect
                              filter={{
                                stageId: values.stageId,
                                pageSize: undefined,
                                page: undefined,
                              }}
                              name={name}
                              value={value}
                              onChange={(value, option) => {
                                setFieldValue(name, value);
                                setFieldValue(
                                  'incomeSchedule',
                                  option ? option.props.incomeschedule : null
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                  </>
                )}
              </Grid>

              {values.incomeSchedule && (
                <>
                  <Divider />
                  <Grid gutter="16px" cols={4}>
                    <GridItem>
                      <FormField
                        name="incomeSchedule.schedulePoint"
                        label="Точка графика"
                      >
                        {({ name, value }) => (
                          <Input name={name} value={value} disabled />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="incomeSchedule.startDate"
                        required
                        label="Дата начала действия"
                      >
                        {({ name, value }) => (
                          <DatePicker
                            name={name}
                            disabled
                            value={value ? moment(value) : value}
                            format="DD MMMM YYYY"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата окончания действия"
                        name="incomeSchedule.endDate"
                      >
                        {({ name, value }) => (
                          <DatePicker
                            name={name}
                            value={value ? moment(value) : value}
                            format="DD MMMM YYYY"
                            disabled
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="incomeSchedule.unit"
                        label="Единицы измерения"
                      >
                        {({ name, value }) => (
                          <Input name={name} value={value} disabled />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField name="incomeSchedule.count" label="Количество">
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            disabled
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="incomeSchedule.sum"
                        label="Сумма обслуживания (без НДС)"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            disabled
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField name="incomeSchedule.tax" label="Ставка НДС">
                        {({ name, value }) => (
                          <RateTaxesSelect name={name} value={value} disabled />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Сумма обслуживания (с НДС)">
                        {() => (
                          <StyledInputNumber
                            disabled
                            value={plus(
                              values.incomeSchedule.sum,
                              multipliedBy(
                                values.incomeSchedule.sum,
                                dividedBy(values.incomeSchedule.tax, 100)
                              )
                            )}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </>
              )}

              <Divider />

              <Grid gutter="16px" cols={4}>
                <GridItem>
                  <FormField name="actNumber" required label="Номер акта">
                    {({ name, value }) => (
                      <Input
                        name={name}
                        value={value}
                        onChange={e => setFieldValue(name, e.target.value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата акта" name="actDate" required>
                    {({ name, value }) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                              .utc(value)
                              .startOf('day')
                              .toISOString()
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>

            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {values.id ? 'Сохранить' : 'Создать'}
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
