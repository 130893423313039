// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {overhaulTypeApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {addAccessRight} from './accessRight';

const entryPointPath = '/admin/overhaul-types/';

const columns = [
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModelName'
  },
  {
    title: 'Виды работ по капитальному ремонту',
    dataIndex: 'vehicleIssueGroupName'
  },
  {
    title: 'Кол-во каждой запасной части',
    dataIndex: 'mtrs',
    render: mtrs => {
      return mtrs?.reduce((sum, el) => sum + el.count, 0);
    }
  }
];

export default withUserAccess(({ userAccess }) => {

  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));

  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}new`) : undefined
  };

  return (
    <Page>
      <CommonListPage
        crud={overhaulTypeApi}
        pageHeaderProps={{
          mainHeader: 'Виды работ по капитальному ремонту ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
        filter={Filter}
      />
    </Page>
  );
});
