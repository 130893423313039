// @flow
import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import * as React from 'react';
import { Form, OrgUnitSelect } from '../../../../components';
import { notificationLoading } from '../../../../components/Notifications';
import { Grid, GridItem } from '../../../../components/layout';
import { FuelTypeSelect } from '../../../../components/selects/AutoGeneratedEnumSelects';
import EmployeePositionSelect from '../../../../components/selects/EmployeePositionSelect';
import { ContractTripApi, orgUnitApi } from '../../../../lib/api';
import { ContractTripVehicleLayout } from '../../../../lib/types/contractTrips';

type Props = {
  visible: boolean,
  onClose: Function,
  layout: ContractTripVehicleLayout,
  updateLayoutList: Function,
  orgUnitId: number,
};

type State = {
  disableButtons: boolean,
}

class LayoutEditingModal extends React.Component<Props, State> {
  state = {
    disableButtons: false,
  };
  
  onSubmit = async values => {
    try {
      notificationLoading({message: 'Обновление данных', key: 'saving'});
      await ContractTripApi.updateTripVehicleLayout(values);
      notification.success({
        message: 'Данные обновлены',
      });
      this.props.updateLayoutList();
      this.props.onClose();
    } catch (e) {
      notification.error({
        message: 'Не удалось обновить данные макета',
        description: e.message || e.title,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  render() {
    const {onClose, visible, layout, orgUnitId} = this.props;
    const {disableButtons} = this.state;
    return (
      <Modal
        onCancel={onClose}
        visible={visible}
        destroyOnClose
        width={650}
        title={`Редактирование макета транспортных расходов ${layout?.contractVehicleName || 'ТС'}`}
        footer={false}
      >
        <Form
          initialValues={{
            ...layout,
            showSelect: Boolean(layout?.originalContractVehicleId),
            assignmentType: null,
          }}
          onSubmit={values => {
            delete values.showSelect;
            delete values.assignmentType;
            delete values.post;
            this.onSubmit(values);
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const {setFieldValue, values} = formikProps;
            
            const switchAssignmentType = type => {
              setFieldValue('assignmentType', values.assignmentType !== type ? type : null);
            };
            
            const setServiceName = async (id) => {
              this.setState({disableButtons: true});
              const parentOrgUnit = await orgUnitApi.getById(this.props.orgUnitId);
              const selectedOrgUnit = await orgUnitApi.getById(id);
              let name = new RegExp(selectedOrgUnit.mnemonic, 'i').test(selectedOrgUnit.name)
                ? selectedOrgUnit.name
                : `${selectedOrgUnit.mnemonic} ${selectedOrgUnit.name}`;
              if (selectedOrgUnit.type === 'service') {
                name += ` ${parentOrgUnit.name}`;
              }
              setFieldValue('assigment', name);
              this.setState({disableButtons: false});
            };
            
            const setPostName = async (post) => {
              this.setState({disableButtons: true});
              const parentOrgUnit = await orgUnitApi.getById(this.props.orgUnitId);
              const selectedOrgUnit = await orgUnitApi.getById(post.orgUnitId);
              const name = `${post.positionTitle} ${selectedOrgUnit.mnemonic} ${parentOrgUnit.name}`;
              setFieldValue('assigment', name);
              setFieldValue('post', name);
              this.setState({disableButtons: false});
            };
            
            return (
              <form onSubmit={formikProps.handleSubmit}>
                <Grid gutter="16px">
                  <GridItem fullWidth>
                    <FormField label="Закрепление" name="assigment">
                      {({value}) => value || 'У текущего ТС закрепление отсутствует'}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth>
                    <Grid gutter="16px" style={{marginBottom: '15px'}}>
                      <GridItem>
                        <Button
                          disabled={disableButtons}
                          type="primary"
                          size="small"
                          onClick={() => switchAssignmentType('service')}
                        >
                          Закрепить за службой
                        </Button>
                      </GridItem>
                      <GridItem>
                        <Button
                          disabled={disableButtons}
                          type="primary"
                          size="small"
                          onClick={() => switchAssignmentType('post')}
                        >
                          Закрепить за должностью
                        </Button>
                      </GridItem>
                    </Grid>
                  </GridItem>
                  {
                    values.assignmentType
                      ? (
                        <GridItem fullWidth>
                          {
                            values.assignmentType === 'service'
                              ? <OrgUnitSelect onChange={setServiceName} />
                              : (
                                <EmployeePositionSelect
                                  branchId={orgUnitId}
                                  value={values.post}
                                  allowClear={false}
                                  onSelect={setPostName}
                                />
                              )
                          }
                        </GridItem>
                      )
                      : null
                  }
                  <GridItem>
                    <FormField label="Отработано по произв. календарю (до изменения тарифа)" name="weekDaysBeforeChangeTariff">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Отработано в выходные (до изменения тарифа)" name="daysOffBeforeChangeTariff">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Отработано по произв. календарю (после изменения тарифа)" name="weekDaysAfterChangeTariff">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Отработано в выходные (после изменения тарифа)" name="daysOffAfterChangeTariff">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Норматив (км)" name="distanceNormative">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Норматив (часы)" name="hoursNormative">
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Норма списания на 1 час прогрева"
                      name="waitingWithRunningEnginePrice"
                    >
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Стоимость топлива за 1 л"
                      name="fuelPrice"
                    >
                      {({name, value}) => (
                        <InputNumber
                          min={0}
                          value={value}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тип топлива"
                      name="fuelType"
                    >
                      {({name, value}) => (
                        <FuelTypeSelect
                          allowClear
                          value={value ?? undefined}
                          onChange={newValue => setFieldValue(name, newValue)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
                <Grid>
                  <GridItem
                    fullWidth
                    customStyles={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Button disabled={disableButtons} type="primary" htmlType="submit">
                      Сохранить
                    </Button>
                    <Button onClick={onClose}>Отменить</Button>
                  </GridItem>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal>
    );
  }
}

export default LayoutEditingModal;
