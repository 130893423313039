// @flow

import type { Driver } from './../types';

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { DriverFilterParams } from '../../containers/Driver/Filter';

export const addDriver = async (driver: Driver): Promise<Driver> => {
  const added = await fetchRequest.post('/driver', driver);
  if (added) return added;
  throw new Error('Не удалось создать водителя');
};

export const updateDriver = async (driver: Driver): Promise<Driver> => {
  const updated = await fetchRequest.put('/driver', driver);
  if (updated) return updated;
  throw new Error('Не удалось обновить водителя');
};

export const fetchDrivers = async (
  params: FetchListParams<DriverFilterParams> = initialFetchListParams
): Promise<ListResponse<Driver>> =>
  await fetchRequest.get('/driver', {
    ...initialFetchListParams,
    ...params
  });

export const fetchDriver = async (
  id: number,
  params: any = {}
): Promise<Driver> => {
  const driver = await fetchRequest.get(`/driver/${id}`, params);
  if (driver) return driver;
  throw new Error('Не удалось загрузить водителя');
};

export const deleteDriver = async (id: number): Promise<Driver> => {
  const deleted = await fetchRequest.delete(`/driver/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить водителя');
};

export default {
  addDriver,
  updateDriver,
  fetchDrivers,
  deleteDriver,
  fetchDriver
};
