// @flow
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {navigate} from '../../lib/helpers';
import {fetchRequest} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import {getBreadCrumbsByUrlForEdit} from '../../lib/autoBreadcrumbs';
import {Section, SectionTitle} from '../../components/layout';
import {MONTH} from '../budget/vehicle/lib';
import {AntTable} from '../../components/ui';
import {fuelTypeEnum, fuelTypes} from '../../lib/enum';
import {Spin} from 'antd';

const Content = styled.div`
  padding: 16px;
`;

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const entryPointPath = '/admin/planned-fuel-consumption';

const InnerTable = ({ data }) => {
  const [columns, setColumns] = useState([
    {
      title: 'Филиал',
      width: 100,
      fixed: 'left',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Тип топлива',
      width: 220,
      fixed: 'left',
      dataIndex: 'typeOfFuel',
      render: fuelTypes => fuelTypes.map(type => <p key={type}>{type}</p>)
    },
    {
      title: 'Цена топлива',
      width: 120,
      fixed: 'left',
      dataIndex: 'priceOfFuel',
      render: fuelPrice => fuelPrice.map(price => <p key={price}>{price}</p>)
    },
    {
      title: 'ИТОГО',
      className: 'total-cell',
      children: [
        {
          title: 'Количество топлива',
          dataIndex: `total.values`,
          className: 'total-cell',
          render: values =>
            values?.map(value => <p key={Math.random()}>{value}</p>)
        },
        {
          title: 'Стоимость топлива',
          dataIndex: `total.cost`,
          className: 'total-cell',
          render: price => price?.map(cost => <p key={Math.random()}>{cost}</p>)
        }
      ]
    }
  ]);
  
  useEffect(() => {
    if (data?.perBranch?.length && data.perBranch[0].perMonth?.length) {
      const newColumns = [...columns];
      data.perBranch[0].perMonth.forEach((item, index) => {
        newColumns.splice(-1, 0, {
          title: MONTH[item.monthNumber - 1].title,
          children: [
            {
              title: 'Количество топлива',
              dataIndex: `perMonths[${index}].values`,
              render: values =>
                values?.map(value => <p key={Math.random()}>{value}</p>)
            },
            {
              title: 'Стоимость топлива',
              dataIndex: `perMonths[${index}].cost`,
              render: price => price?.map(cost => <p key={Math.random()}>{cost}</p>)
            }
          ]
        })
      });
      setColumns([...newColumns]);
    }
    // eslint-disable-next-line
  }, [data]);
  
  if (!data.budgetSummary || !data.perBranch) {
    return null;
  }

  const getFuelPriceNameByType = (type: string) => {
    if (type === fuelTypeEnum.autoGreases) {
      return 'autoGreasePrice';
    } else {
      return `${fuelTypeEnum[type]}Price`;
    }
  };
  const dataSource = data.perBranch.map(item => {
    const perMonths = item.perMonth.map(month => ({
      values: month.perType.map(item => Math.round(item.count * 100) / 100),
      cost: month.perType.map(item => item.cost / 100)
    }));
    const totalValues = [];
    const totalCost = [];
    perMonths.forEach(month => {
      month.values.forEach((value, ind) => {
        if (!totalValues[ind]) {
          totalValues[ind] = Math.round(value * 100) / 100;
        } else {
          totalValues[ind] = Math.round((totalValues[ind] + value) * 100) / 100;
        }
      });
      month.cost.forEach((cost, ind) => {
        if (!totalCost[ind]) {
          totalCost[ind] = Math.round(cost * 100) / 100;
        } else {
          totalCost[ind] = Math.round((totalCost[ind] + cost) * 100) / 100;
        }
      });
    });
    return {
      orgUnitName: item.organizationUnit.name,
      typeOfFuel: item.perMonth[0].perType.map(
        typeItem => fuelTypes[typeItem.type]
      ),
      priceOfFuel: item.perMonth[0].perType.map(
        ({ type }) => data.fuelPrices[getFuelPriceNameByType(type)] / 100
      ),
      perMonths,
      total: {
        values: totalValues,
        cost: totalCost
      }
    };
  });
  return (
    <Section>
      <SectionTitle divider>
        Версия бюджета: {data.budgetSummary.budgetVersion.name}
        <br />
        Год: {new Date(data.budgetSummary.startDate).getFullYear()}
      </SectionTitle>
      <Content>
        <AntTable
          bordered
          data={dataSource}
          columns={columns}
          pagination={{ defaultPageSize: 20 }}
          // значения свойств scroll и bodyStyle нужны для адаптации высоты таблицы под высоту окна браузера,
          // чтоб всегда была видна горизонтальная прокрутка
          scroll={{
            x: 800 + (400 * (dataSource?.[0]?.perMonths?.length || 0)),
            y: 1500,
          }}
          bodyStyle={{
            height: `${document.documentElement.clientHeight - 260}px`
          }}
        />
      </Content>
    </Section>
  );
};

export default props => {
  const [data, setData] = useState({ id: props.id });
  const [isLoading, setIsLoading] = useState(false);
  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);
  const onFetch = useCallback(async () => {
    if (!!props.id) {
      setIsLoading(true);
      setData(
        await fetchRequest.get(`/PlannedFuelConsumption/bybudget/${data.id}`)
      );
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Плановый расход ГСМ'
      }}
      onFetch={onFetch}
      onCancel={onCancel}
    >
      {isLoading ? (
        <Preloader>
          <Spin size={'large'} />
        </Preloader>
      ) : (
        <InnerTable data={data} useFooter noWrapMe />
      )}
    </CommonCardPage>
  );
};
