// @flow
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Filter, FilterButtonsContainer, FilterContainer, FilterItem } from '../../../components';
import { notificationError, notificationLoading } from '../../../components/Notifications';
import Field from '../../../components/card/Field';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import { SectionContent } from '../../../components/hoc/common/components/elements';
import { Grid, GridItem, Panel, Section } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Header from '../../../components/layout/Header';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';
import { TabItem, Table, Tabs } from '../../../components/ui';
import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import { ContractTripApi, downloadRequestWithToken, fetchRequest } from '../../../lib/api';
import {
  accessTypeEnum,
  cognosSendingStatuses,
  fuelTypes,
  monthsNamesTranslitEnum,
  newContractTripStatusEnum,
  newContractTripStatuses,
} from '../../../lib/enum';
import { getPathWithHistoryParams, setQueryParams } from '../../../lib/helpers';
import type { ListState, UserAccess } from '../../../lib/types';
import { ContractTripMonth, ContractTripVehicleLayout } from '../../../lib/types/contractTrips';
import { withUserAccess } from '../../withUserAccess';
import LayoutEditingModal from './components/LayoutEditingModal';

const StyledPanel = styled(Panel)`
  padding-top: 0;
  padding-bottom: 0;
`;

const SectionTitle = styled.h2`
  margin: 0 0 10px;
`;

const BigSectionTitle = styled(SectionTitle)`
  font-size: 16px;
`;

const StyledSpan = styled.div`
  color: ${({warning}) =>
          warning !== undefined ? (warning ? '#ff2020' : '#079607') : '#054dd9'};
  cursor: pointer;
`;

type Props = {
  id: number,
  userAccess: UserAccess[],
  setFilter: (payload: PersistFilterPayload) => void,
};

type OptionItem = {
  name: string,
  id: number,
}

type State = ListState<ContractTripVehicleLayout> & {
  columns: any[],
  tripMonth: ?ContractTripMonth,
  expenseDirections: any[],
  selectedLayoutVehicle: ContractTripVehicleLayout,
  modalVisible: boolean,
  data: ContractTripVehicleLayout[],
  loading: boolean,
  filtersData?: {
    brandOptions: OptionItem[],
    modelOptions: OptionItem[],
    licensePlateOptions: OptionItem[],
  },
};

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0;
`;

class ContractTripLayout extends React.Component<Props, State> {
  state = {
    columns: [],
    tripMonth: null,
    expenseDirections: null,
    selectedLayoutVehicle: null,
    modalVisible: false,
    data: [],
    loading: false,
    filtersData: null,
    filterPath: window.location.pathname,
  };
  
  componentDidMount() {
    if (this.props.id) {
      this.fetchFiltersData();
      this.fetchMonth(this.props.id);
      this.fetchExpenseDirections(this.props.id);
      const { filterPath } = this.state;
      const filter = this.props.persistFilters[filterPath] || {};
      this.setState(
        {
          filter: { ...filter }
        },
        this.fetchLayout
      );
    }
  }
  
  fetchMonth = async monthId => {
    try {
      const tripMonth = await ContractTripApi.fetchTripMonthById(monthId);
      this.setState({tripMonth});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title,
      });
    }
  };
  
  handleOnRow = (item) => {
    const statusesForEditing = [newContractTripStatusEnum.created, newContractTripStatusEnum.declined];
    if (this.canEdit() && statusesForEditing.includes(this.state.tripMonth.status))
    {
      this.setState({selectedLayoutVehicle: item, modalVisible: true});
    }
  }
  
  // данные в таблице этой компонены должны отображаться нестандартно. Потому, для корректного отображения,
  // необходимо изменить структуру массива данных для отрисовки в таблице.
  formattingData = data => {
    const rows = {
      'Направление расходов': [],
      'Закрепление': [],
      'Подменяемое ТС': [],
      'Тип топлива': [],
    };
    const columns = [];
    const structuredData = [];
    if (data.length) {
      columns.push(
        {
          title: 'Параметры макета',
          dataIndex: 'title',
          width: 380,
          fixed: true,
        },
        {
          title: 'Ед. измерения',
          dataIndex: 'units',
          width: 120,
          fixed: true,
        },
      );
      data.forEach(item => {
        rows['Направление расходов'].push([item.id, item.expenseDirection?.name]);
        rows['Закрепление'].push([item.id, item.assigment]);
        rows['Подменяемое ТС'].push([item.id, item.originalContractVehicleName]);
        rows['Тип топлива'].push([item.id, fuelTypes[item.fuelType] ?? item.fuelType]);
        columns.push({
          title: item.contractVehicleName,
          dataIndex: item.id,
          width: data.length >= 2 ? 300 : null,
          ellipsis: true,
          onCell: () => ({
            onClick: () => this.handleOnRow(item),
          }),
        });
        item.details
        .sort((a, b) => a.order - b.order)
        .forEach(detail => {
          if (!rows[detail.budgetLine]) {
            rows[detail.budgetLine] = [
              ['units', detail.measure],
              [item.id, Math.round(detail.value * 100) / 100],
            ];
          } else {
            rows[detail.budgetLine].push([
              item.id,
              Math.round(detail.value * 100) / 100,
            ]);
          }
        });
      });
      Object.keys(rows).forEach(key => {
        const record = {};
        record.title = key;
        rows[key].forEach(value => (record[value[0]] = value[1]));
        structuredData.push(record);
      });
      columns.push({title: 'ИТОГО', dataIndex: 'total', width: 70});
      structuredData.forEach((item, index) => {
        if (index > 3) {
          const total = Object.values(item).reduce((sum, value) => {
            if (typeof value === 'number') {
              return sum + value;
            }
            return sum;
          }, 0);
          item.total = Math.round(total * 100) / 100;
        }
      });
    }
    return {data: structuredData, columns};
  };
  
  fetchLayout = async (params) => {
    try {
      const { filter } = this.state;
      this.setState({loading: true});
      const request = {
        contractTripMonthId: this.props.id,
        orderBy: 'contractVehicle.Vehicle.LicensePlate',
        ...filter,
        ...params
      }
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('expenseDirectionId')) {
        request.expenseDirectionId = (new URLSearchParams(window.location.search)).get('expenseDirectionId')
      }
      const {data} = await ContractTripApi.getTripVehicleLayout(request);
      
      this.setState({
        loading: false,
        ...this.formattingData(data),
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title,
      });
    }
  };
  
  fetchExpenseDirections = async id => {
    try {
      const expenseDirections = await ContractTripApi.getExpenseDirections(id);
      this.setState({expenseDirections});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message || e.title,
      });
    }
  };

  fetchFiltersData = async () => {
    try {
      const filtersData = await fetchRequest.get(
        `/ContractTripVehicleLayot/getFilterOptionsByContractTripMonth/${this.props.id}`,
      );
      this.setState({filtersData: {...this.state.filtersData, ...filtersData}});
    } catch (e) {
      notificationError(e);
    }
  }
  
  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование печатной формы',
        key: 'printing',
      });
      const urlParams = new URLSearchParams(window.location.search)
      await downloadRequestWithToken(
        `/ContractTripVehicleLayot/printByContractTripMonth/${this.props.id}`,
        urlParams.has('expenseDirectionId') ? {
          expenseDirectionId: urlParams.get('expenseDirectionId')
        } : undefined
      );
    } catch (e) {
      notification.error({
        message: 'Ошибка формирования печатной формы',
        description: e.message || e.title,
      });
    }
    finally {
      notification.close('printing');
    }
  };
  
  handleSend = async () => {
    try {
      notificationLoading({
        message: 'Отправка в ИСУ',
        key: 'sending',
      });
      const result = await ContractTripApi.sendToCognos(this.props.id);
      notification.success({message: result});
      this.fetchMonth(this.props.id);
    } catch (e) {
      notification.error({
        message: 'Ошибка отправки',
        description: e.message || e.title,
      });
    }
    finally {
      notification.close('sending');
    }
  };
  
  canSendToCognos = () => [
    accessTypeEnum.admin,
    accessTypeEnum.handlingContractTripVehicleLayout,
  ].some(access => this.props.userAccess.includes(access));

  canEdit = () =>
  [accessTypeEnum.admin, accessTypeEnum.handlingContractTripVehicleLayout].some(access =>
    this.props.userAccess.includes(access)
  );

  canApprove = () => {
    const statusesForApprove = [newContractTripStatusEnum.created, newContractTripStatusEnum.declined];
    return this.canEdit() && statusesForApprove.includes(this.state.tripMonth?.status);
  };

  handleApprove = async () => {
    notification.close('checkingResult');
    try {
      notificationLoading({ message: 'Сохранение данных', key: 'saving' });
      const tripMonth = await ContractTripApi.changeStatus(
        this.state.tripMonth.id,
        newContractTripStatusEnum.aproved
      );
      this.setState({ tripMonth });
    } catch (e) {
      notification.error({
        message: 'Ошибка Сохранения данных',
        description: e.title || e.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canDecline = () => {
    const statusesForDecline = [newContractTripStatusEnum.aproved, newContractTripStatusEnum.sendError, newContractTripStatusEnum.sended];
    return this.canEdit() && statusesForDecline.includes(this.state.tripMonth?.status);
  };

  handleDecline = async () => {
    notification.close('checkingResult');
    try {
      notificationLoading({ message: 'Сохранение данных', key: 'saving' });
      const tripMonth = await ContractTripApi.changeStatus(
        this.state.tripMonth.id,
        newContractTripStatusEnum.declined
      );
      this.setState({ tripMonth });
    } catch (e) {
      notification.error({
        message: 'Ошибка Сохранения данных',
        description: e.title || e.message
      });
    } finally {
      notification.close('saving');
    }
  };

  setPersistFilter = async (values: OrderFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async filter => {
    this.setState({ filter }, this.fetchLayout);
    await this.setPersistFilter(filter);
  };

  cleanFilter = async () => {
    setQueryParams({ expenseDirectionId: undefined, page: undefined });
    this.setState({ filter: {} }, this.fetchLayout);
    await this.setPersistFilter({});
  };
  
  render() {
    const {id} = this.props;
    const {
      data,
      columns,
      loading,
      tripMonth,
      expenseDirections,
      selectedLayoutVehicle,
      modalVisible,
      filtersData,
      filter,
    } = this.state;
    
    if (!id) {
      return null;
    }
    
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/trips/contract')}>
                Наемные ТС
              </Crumb>
              <Crumb>Реестр №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            <>
              {this.canApprove() && (
                <Button type="primary" style={{marginRight: '10px'}} onClick={this.handleApprove}>
                  Утвердить
                </Button>
              )}
              {this.canDecline() && (
                <Button type="primary" style={{marginRight: '10px'}} onClick={this.handleDecline}>
                  Отклонить
                </Button>
              )}
              {(tripMonth?.status === newContractTripStatusEnum.aproved ||
                tripMonth?.status === newContractTripStatusEnum.sendError) && this.canSendToCognos() && (
                <Button type='primary' style={{marginRight: '10px'}} onClick={this.handleSend}>Отправить в ИСУ</Button>
              )}
              <Button type="primary" onClick={this.handlePrint}>
                Печать
              </Button>
            </>
          }
        />
        <StyledPanel>
          <h1 style={{marginBottom: '14px'}}>{`Реестр ПЛ НТС №${id ||
          ''}`}</h1>
          {(
            <Tabs withRouter>
              <TabItem
                url={`/trips/contract/${id}`}
                label="Реестр ПЛ НТС"
                style={{marginLeft: 0}}
              />
              <TabItem
                url={`/trips/contract/layout/${id}`}
                label="Макет фактических транспортных расходов"
              />
            </Tabs>
          )}
        </StyledPanel>
        {tripMonth && (
          <>
            <Section>
              <SectionContent>
                <Grid gutter="16px" cols={5}>
                  <GridItem>
                    <Field label="Контрагент">
                      {tripMonth.contractor.company.name}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Филиал">{tripMonth.orgUnitName}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Месяц">
                      {monthsNamesTranslitEnum[tripMonth.month]}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Год">{tripMonth.year}</Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Статус">
                      {newContractTripStatuses[tripMonth.status]}
                    </Field>
                  </GridItem>
                </Grid>
              </SectionContent>
              <SectionContent>
              <Title>Статусы отправки в ИСУ Бюджетирование</Title>
                <Grid  gutter="16px" cols={5}>
                  <GridItem>
                  <Field label="Фактический тарификатор" >
                    {cognosSendingStatuses[tripMonth.cognosVehicleTariffsStatus]}
                  </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Макет фактических транспортных расходов" >
                      {cognosSendingStatuses[tripMonth.cognosVehicleLayoutStatus]}
                    </Field>
                  </GridItem>
                </Grid>  
              </SectionContent>
            </Section>
            {!!expenseDirections?.length && (
              <Section>
                <SectionContent>
                  <BigSectionTitle>
                    {`Итого расход в месяц (тыс. руб):
                    ${Math.round(expenseDirections.reduce(
                      (sum, item) => sum + item.sum,
                      0
                    ) * 100) / 100}`}
                  </BigSectionTitle>
                  <SectionTitle>
                    Суммы по направлениям расходов (тыс. руб)
                  </SectionTitle>
                  <Grid gutter="16px">
                    {expenseDirections.map((item, index) => (
                      <GridItem key={index}>
                        <Field label={item.name || '-'}>
                        <StyledSpan
                          onClick={() => {
                            setQueryParams({ expenseDirectionId: item.id, page: undefined });
                            this.fetchLayout();
                          }}
                        >
                          {Math.round(item.sum * 100) / 100}
                        </StyledSpan>
                        </Field>
                      </GridItem>
                    ))}
                  </Grid>
                </SectionContent>
              </Section>
            )}
          </>
        )}
        <Section>
          <SectionContent>
            {filtersData && (
              <FilterContainer style={{marginBottom: '20px'}}>
                <Filter cleanFilter={this.cleanFilter} applyFilter={this.applyFilter} initialValues={filter}>
                  {({values, changeValue, applyFilter, cleanFilter}: FilterChildrenParams) => (
                    <>
                      <FilterItem>
                        <Field mBottomNone>
                          <AutocompleteSelect
                            fetch={({search}) => {
                              if (search && filtersData.modelOptions) {
                                return {data: filtersData.modelOptions.filter(item =>
                                    item.name.toLowerCase().includes(search.toLowerCase())
                                  )}
                              }
                              return {data: filtersData.modelOptions || []};
                            }}
                            fetchSingle={id => {
                              return filtersData.modelOptions
                                ? filtersData.modelOptions.find(item => item.id === id)
                                : undefined
                            }}
                            notFoundText='Модели не найдены'
                            placeholder="Модель ТС"
                            value={values['contractVehicle.vehicle.vehicleModelId']}
                            onChange={value => changeValue('contractVehicle.vehicle.vehicleModelId', value)}
                            size="small"
                            fullWidth
                            renderOption={({id, name}, Option) => <Option key={id} value={id}>{name}</Option>}
                          />
                        </Field>
                      </FilterItem>
                      <FilterItem>
                        <Field mBottomNone>
                          <AutocompleteSelect
                            fetch={({search}) => {
                              if (search && filtersData.brandOptions) {
                                return {data: filtersData.brandOptions.filter(item =>
                                    item.name.toLowerCase().includes(search.toLowerCase())
                                  )}
                              }
                              return {data: filtersData.brandOptions || []};
                            }}
                            fetchSingle={id => {
                              return filtersData.brandOptions
                                ? filtersData.brandOptions.find(item => item.id === id)
                                : undefined
                            }}
                            notFoundText='Марки не найдены'
                            placeholder="Марка ТС"
                            value={values['contractVehicle.vehicle.vehicleModel.brandId']}
                            onChange={value => changeValue('contractVehicle.vehicle.vehicleModel.brandId', value)}
                            size="small"
                            fullWidth
                            renderOption={({id, name}, Option) => <Option key={id} value={id}>{name}</Option>}
                          />
                        </Field>
                      </FilterItem>
                      <FilterItem>
                        <Field mBottomNone>
                          <AutocompleteSelect
                            fetch={({search}) => {
                              if (search && filtersData.licensePlateOptions) {
                                return {data: filtersData.licensePlateOptions.filter(item =>
                                    item.name.toLowerCase().includes(search.toLowerCase())
                                  )}
                              }
                              return {data: filtersData.licensePlateOptions || []};
                            }}
                            fetchSingle={id => {
                              return filtersData.licensePlateOptions
                                ? filtersData.licensePlateOptions.find(item => item.id === id)
                                : undefined
                            }}
                            notFoundText='Номера не найдены'
                            placeholder="Гос. номер"
                            value={values['contractVehicle.vehicle.Id']}
                            onChange={value => {
                              changeValue('contractVehicle.vehicle.Id', value);
                              changeValue('contractVehicle.vehicle.vehicleModelId', undefined);
                              changeValue('contractVehicle.vehicle.vehicleModel.brandId', undefined);
                            }}
                            size="small"
                            fullWidth
                            renderOption={({id, name}, Option) => <Option key={id} value={id}>{name}</Option>}
                          />
                        </Field>
                      </FilterItem>
                      <FilterButtonsContainer applyFilter={applyFilter} cleanFilter={cleanFilter} />
                    </>
                  )}
                </Filter>
              </FilterContainer>
            )}
            <Table
              columns={columns}
              loading={loading}
              pagination={false}
              data={data}
              rowKey="title"
              bordered
              scroll={{y: 600, x: 'auto'}}
            />
          </SectionContent>
        </Section>
        <LayoutEditingModal
          layout={selectedLayoutVehicle}
          updateLayoutList={() => {
            this.fetchExpenseDirections(id);
            this.fetchLayout();
          }}
          visible={modalVisible}
          onClose={() => {
            this.setState({
              selectedLayoutVehicle: null,
              modalVisible: false,
            });
          }}
          orgUnitId={tripMonth?.orgUnitId}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(ContractTripLayout));

