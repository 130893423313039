// @flow
import React, { useState, useCallback } from 'react';

import { navigate } from '../../lib/helpers';
import { reasonsForRejectinfOfOrderApi } from '../../lib/api';
import { type ReasonForRejectingOfOrder } from '../../lib/types';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {
  type CommonFormProps
} from '../../components/hoc/common/handbook/CommonForm';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';

import Grid, { GridItem } from '../../components/layout/Grid';
import { FormField } from '../../components/Form';
import { StyledInput } from '../../components/hoc/common/components/elements';

type PageProps = {
  id: number
};

const entryPointPath = '/admin/reasons-for-rejection-of-order/';

const InnerForm = (
  props: CommonFormProps<$Shape<ReasonForRejectingOfOrder>>
) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label={`Причина отклонения`}
                  fast
                  required
                  name="title"
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: ReasonForRejectingOfOrder, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await reasonsForRejectinfOfOrderApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: ReasonForRejectingOfOrder) => {
    let id = payload.id;
    if (id) {
      await reasonsForRejectinfOfOrderApi.update(payload);
    } else {
      await reasonsForRejectinfOfOrderApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async (payload: ReasonForRejectingOfOrder) => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Причины отклонения заявки на ГПМ/ ТС',
        access: {
          delete: data.id
            ? () => {
                reasonsForRejectinfOfOrderApi.delete(data.id);
                navigate(entryPointPath);
              }
            : undefined
        }
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
