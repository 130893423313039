// @flow
import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import Checkbox from 'antd/lib/checkbox';
import DatePicker from 'antd/lib/date-picker';

import type {SelfVehiclePlanVehicle, VehiclePlan} from './../../../../../lib/types';
import {vehicleStatuses, vehicleTypes} from './../../../../../lib/enum';

import ListTable from './../../../../../components/ui/ListTable';
import Spinner from './../../../../../components/Spinner';
import Grid, {GridItem} from './../../../../../components/layout/Grid';

import {setVehiclePlanVehicle} from './../../../../../ducks/vehiclePlan';

import type {VehicleListFilterParams} from './../../components/FilterVehicleList';
import Filter from './../../components/FilterVehicleList';

import {iconSorter} from './../../lib';

type Props = {
  data: SelfVehiclePlanVehicle[],
  vehiclePlan: ?$Shape<VehiclePlan>,
  vehiclePlanType: string,
  setVehiclePlanVehicle: Function,
  selectedVehicle?: SelfVehiclePlanVehicle[],
  changeEditVehiclePlanVehicle?: Function,
  loading?: boolean
};

type State = {
  localData: SelfVehiclePlanVehicle[],
  selectedVehicle: Map<number, SelfVehiclePlanVehicle>,
  filter: VehicleListFilterParams,
  sorter: any
};

const Row = styled(Grid).attrs(props => {
  return {
    column: props.column || false
  };
})`
  font-size: 12px;
  grid-template-columns: 30px 1fr 1fr 1fr 150px 150px ${props =>
      props.column ? '1fr' : ''};
`;
const ListHeader = styled(Row)`
  background: #e4ebf2;
  font-weight: bold;
  min-width: 730px;
  border-radius: 2px 2px 0px 0px;
`;
const Cell = styled(GridItem)`
  padding: 11px 16px;
`;
const CheckboxCell = styled(Cell)`
  width: 30px;
`;

const RenderRow = props => {
  const {
    row,
    selected,
    addVehicle,
    deleteVehicle,
    isSelfVehicle,
    indexRow,
    changeDateEnd,
    changePlannedPurchaseDate,
    changeEditVehiclePlanVehicle
  } = props;
  return (
    <Row column={true}>
      <CheckboxCell>
        <Checkbox
          checked={selected}
          onChange={e => {
            if (e.target.checked) {
              addVehicle(row.vehicleId, row);
            } else {
              deleteVehicle(row.vehicleId);
            }
          }}
        />
      </CheckboxCell>
      <Cell>{vehicleTypes[row.vehicle?.vehicleModel?.type]}</Cell>
      <Cell>
        {isSelfVehicle ? (
          `${row.vehicle.vehicleModel.brandName} ${row.vehicle.vehicleModel.name}`
        ) : (
          <span
            className="link-color pointer"
            onClick={() => {
              if (changeEditVehiclePlanVehicle) {
                changeEditVehiclePlanVehicle(
                  'plannedVehicles'
                )('editVehiclePlanVehicle', { ...row.vehicle });
                changeEditVehiclePlanVehicle('plannedVehicles')('modal', true);
              }
            }}
          >
            {`${row.vehicle.vehicleModel.brandName} ${row.vehicle.vehicleModel.name}`}
          </span>
        )}
      </Cell>
      <Cell>{row.vehicle.licensePlate}</Cell>
      <Cell>{row.vehicle.yearIssued}</Cell>
      <Cell>{vehicleStatuses[row.vehicle.status]}</Cell>
      <Cell>
        <DatePicker
          size={'small'}
          format="DD MMMM YYYY"
          defaultValue={
            !isSelfVehicle
              ? row.plannedPurchaseDate && moment(row.plannedPurchaseDate).utc()
              : row.plannedWriteoffDate && moment(row.plannedWriteoffDate).utc()
          }
          onChange={(value: string) => {
            isSelfVehicle
              ? changeDateEnd(value, row, indexRow)
              : changePlannedPurchaseDate(value, row, indexRow);
          }}
        />
      </Cell>
    </Row>
  );
};

const initSorter = {
  type: null,
  brand: null,
  license: null
};
class InnerTable extends React.PureComponent<Props, State> {
  state = {
    localData: [],
    selectedVehicle: new Map(),
    filter: {},
    sorter: initSorter
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.data, this.props.data)) {
      let selectedVehicle = new Map();
      if (isEmpty(this.props.selectedVehicle)) {
        this.props.data.forEach(item =>
          selectedVehicle.set(item.vehicleId, item)
        );
      } else {
        if (Array.isArray(this.props.selectedVehicle))
          this.props.selectedVehicle.forEach(item => {
            selectedVehicle.set(item.vehicleId, item);
          });
      }
      this.setState(
        { selectedVehicle, localData: [...this.props.data] },
        this.setVehicle
      );
    }
  }

  addVehicle = (vehicleId: number, data: SelfVehiclePlanVehicle) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.set(vehicleId, data);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  deleteVehicle = (vehicleId: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      selectedVehicle.delete(vehicleId);
      return { selectedVehicle };
    }, this.setVehicle);
  };

  setVehicle = () =>
    this.props.setVehiclePlanVehicle(
      this.state.selectedVehicle,
      this.props.vehiclePlanType === 'selfVehicles'
    );

  onChangeMonth = (name: string, value: boolean, index: number) => {
    let { localData } = this.state;
    localData[index][name] = value;
    this.setState({ localData }, () =>
      this.addVehicle(localData[index]['vehicleId'], localData[index])
    );
  };

  handleHeader = (name: string) => {
    // const { sorter } = this.state;
    this.setState(state => ({
      ...state,
      sorter: {
        ...initSorter,
        [name]: state.sorter[name]
          ? false
          : state.sorter[name] === false
          ? null
          : true
      }
    }));
  };

  header = () => {
    const { data, vehiclePlanType } = this.props;
    const { selectedVehicle, filter, sorter } = this.state;
    const isSelfVehicle = vehiclePlanType === 'selfVehicles';
    return (
      <ListHeader column={true}>
        <CheckboxCell>
          {isEmpty(filter) && (
            <Checkbox
              indeterminate={
                selectedVehicle.size > 0 && selectedVehicle.size < data.length
              }
              checked={
                selectedVehicle.size === data.length && data.length !== 0
              }
              onChange={e => {
                let selectedVehicle = new Map();
                if (e.target.checked) {
                  data.forEach(item =>
                    selectedVehicle.set(item.vehicleId, item)
                  );
                }
                this.setState({ selectedVehicle }, this.setVehicle);
              }}
            />
          )}
        </CheckboxCell>
        <Cell
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => this.handleHeader('type')}
        >
          <span classNmae="ant-table-column-title">Тип ТС</span>
          {iconSorter(sorter.type)}
        </Cell>
        <Cell
          onClick={() => this.handleHeader('brand')}
          style={{ display: 'flex', cursor: 'pointer' }}
        >
          <span classNmae="ant-table-column-title">Марка и модель</span>
          {iconSorter(sorter.brand)}
        </Cell>
        <Cell
          style={{ display: 'flex', cursor: 'pointer' }}
          onClick={() => this.handleHeader('license')}
        >
          <span classNmae="ant-table-column-title">Госномер</span>
          {iconSorter(sorter.license)}
        </Cell>
        <Cell>Год выпуска</Cell>
        <Cell>Текущий статус</Cell>
        {isSelfVehicle ? (
          <Cell>Планируемая дата списания</Cell>
        ) : (
          <Cell>Планируемая дата приобретения</Cell>
        )}
      </ListHeader>
    );
  };

  changeDateEnd = (date: moment, row: any, indexRow: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      const data = {
        ...row,
        plannedWriteoffDate: date
          ? moment
              .utc(date)
              .startOf('day')
              .toISOString()
          : null
      };
      selectedVehicle.get(row.vehicleId) &&
        selectedVehicle.set(row.vehicleId, data);

      return { selectedVehicle, localData: state.localData };
    }, this.setVehicle);
  };

  changePlannedPurchaseDate = (date: moment, row: any, indexRow: number) => {
    this.setState(state => {
      const selectedVehicle = new Map(state.selectedVehicle);
      const data = {
        ...row,
        plannedPurchaseDate: date
          ? moment
              .utc(date)
              .startOf('day')
              .toISOString()
          : null
      };
      selectedVehicle.get(row.vehicleId) &&
        selectedVehicle.set(row.vehicleId, data);

      return { selectedVehicle, localData: state.localData };
    }, this.setVehicle);
  };

  applyFilter = filter => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const {
      loading,
      vehiclePlanType,
      changeEditVehiclePlanVehicle
    } = this.props;
    const { selectedVehicle, localData, filter } = this.state;
    const sorter = [...localData];
    return (
      <>
        <Filter
          filter={filter}
          showHideEmpty={false}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
        />
        <Spinner isLoading={loading}>
          <ListTable
            key={vehiclePlanType}
            style={{ overflowX: 'auto' }}
            styleContent={{ minWidth: '730px' }}
            data={sorter
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { yearIssued } = filter;
                return yearIssued
                  ? selfVehicle.vehicle.yearIssued === yearIssued
                  : true;
              })
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { vehicleModelId } = filter;
                return vehicleModelId
                  ? selfVehicle.vehicle.vehicleModelId === vehicleModelId
                  : true;
              })
              .filter((selfVehicle: SelfVehiclePlanVehicle) => {
                const { vehicleId } = filter;
                return vehicleId ? selfVehicle.vehicle.id === vehicleId : true;
              })
              .sort((a: SelfVehiclePlanVehicle, b: SelfVehiclePlanVehicle) => {
                const { sorter } = this.state;
                let sort = 0;

                const reverse = (value: number) => {
                  let reverse = null;
                  switch (value) {
                    case 1:
                      reverse = -1;
                      break;
                    case -1:
                      reverse = 1;
                      break;
                    default:
                      reverse = 0;
                  }
                  return reverse;
                };

                if (sorter.type !== null) {
                  const first = a?.vehicle?.vehicleModel.type ?? false;
                  const second = b?.vehicle?.vehicleModel.type ?? false;
                  const res =
                    first && second
                      ? vehicleTypes[first].localeCompare(vehicleTypes[second])
                      : 0;

                  sort = sorter.type ? res : reverse(res);
                }

                if (sorter.brand !== null) {
                  const first = a?.vehicle?.vehicleModel ?? false;
                  const second = b?.vehicle?.vehicleModel ?? false;
                  const res =
                    first && second
                      ? `${first.brandName} ${first.name}`.localeCompare(
                          `${second.brandName} ${second.name}`
                        )
                      : 0;

                  sort = sorter.brand ? res : reverse(res);
                }

                if (sorter.license !== null) {
                  const first = a?.vehicle?.licensePlate ?? '0';
                  const second = b?.vehicle?.licensePlate ?? '0';
                  const res =
                    a.vehicle !== undefined ? first.localeCompare(second) : 0;

                  sort = sorter.license ? res : reverse(res);
                }

                return sort;
              })}
            header={this.header()}
            renderRow={(row, index) => {
              return (
                <RenderRow
                  indexRow={index}
                  isSelfVehicle={vehiclePlanType === 'selfVehicles'}
                  onChangeMonth={this.onChangeMonth}
                  changeDateEnd={this.changeDateEnd}
                  changePlannedPurchaseDate={this.changePlannedPurchaseDate}
                  changeEditVehiclePlanVehicle={changeEditVehiclePlanVehicle}
                  key={row.id}
                  idx={row.id}
                  selected={selectedVehicle.has(row.vehicleId)}
                  row={row}
                  addVehicle={this.addVehicle}
                  deleteVehicle={this.deleteVehicle}
                />
              );
            }}
          />
        </Spinner>
      </>
    );
  }
}

export default connect(() => ({}), { setVehiclePlanVehicle })(InnerTable);
