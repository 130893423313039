// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import { FilterContainer, FilterItem } from '../../../../../components';
import Field from '../../../../../components/card/Field';
import Select from '../../../../../components/ui/Select';
import { fuelOilsEnum, fuelOilsTypes } from '../../../../../lib/enum/fuelType';
import { Button } from 'antd';
import { oilUnitsOfMeasurementFromParusTranslit } from '../../../../../lib/enum';

const SpoilerContent = styled.div`
  padding: 16px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #3d4042;
`;

const ButtonsContainer = styled.div`
  margin-top: 18px;
`;

const { Option } = Select;

type Props = {
  filters: any,
  cleanFilter: Function,
  applyFilter: Function
};

export const CardFilter = ({ filters, cleanFilter, applyFilter }: Props) => {
  const [values, setValues] = useState(filters);

  const changeValue = (key, value) => {
    if (!values) {
      setValues({ [key]: value });
    } else {
      setValues({ ...values, [key]: value });
    }
  };
  return (
    <SpoilerContent>
      <Title>Фильтр</Title>
      <FilterContainer>
        <FilterItem>
          <Field mBottomNone label="Мнемокод">
            <Input
              size="small"
              value={values?.mnemocode}
              onChange={e => changeValue('mnemocode', e.target.value)}
              allowClear
            />
          </Field>
        </FilterItem>
        <FilterItem>
          <Field mBottomNone label="Наименование">
            <Input
              size="small"
              value={values?.name}
              onChange={e => changeValue('name', e.target.value)}
              allowClear
            />
          </Field>
        </FilterItem>
        <FilterItem>
          <Field mBottomNone label="Тип масла">
            <Select
              value={values?.oilTypes}
              onChange={value => changeValue('oilTypes', value)}
              size="small"
              style={{ marginBottom: '-2px' }}
              allowClear
            >
              {Object.keys(fuelOilsEnum).map(key => (
                <Option key={key} value={key}>
                  {fuelOilsTypes[key]}
                </Option>
              ))}
            </Select>
          </Field>
        </FilterItem>
        <FilterItem>
          <Field mBottomNone label="Единица измерения">
            <Select
              value={values?.measure}
              onChange={value => changeValue('measure', value)}
              size="small"
              style={{ marginBottom: '-2px' }}
              allowClear
            >
              {Object.keys(oilUnitsOfMeasurementFromParusTranslit).map(key => (
                <Option key={key} value={key}>
                  {oilUnitsOfMeasurementFromParusTranslit[key]}
                </Option>
              ))}
            </Select>
          </Field>
        </FilterItem>
        <ButtonsContainer>
          <Button
            type="primary"
            size="small"
            onClick={() => applyFilter(values)}
            style={{ marginRight: '10px' }}
          >
            Применить
          </Button>
          <Button
            size="small"
            onClick={() => {
              setValues(null);
              cleanFilter();
            }}
          >
            Сбросить
          </Button>
        </ButtonsContainer>
      </FilterContainer>
    </SpoilerContent>
  );
};

export default CardFilter;
