// @flow
import React from 'react';
import styled from 'styled-components';
import Input from 'antd/lib/input';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem} from './../../../components';
import type {FilterChildrenParams} from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import OrgUnitSelect from './../../../components/OrgUnitSelect';
import StockSelect from '../../../components/selects/StockSelect';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type StockPartFilterParams = {
  nodeId?: number,
  mtrId?: string,
  search?: string,
  stockId?: string
};

type Props = {
  filter: StockPartFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<StockPartFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  placeholder="Выберите подразделение"
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Наименование и код МТР"
                  size="small"
                  onChange={e =>
                    changeValue('search', e.target.value || undefined)
                  }
                  value={values['search']}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <StockSelect
                  size="small"
                  value={values['stockId']}
                  onSelect={value => {
                    changeValue('stockId', value);
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default StockPartFilter;
