// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';
import Select from './../ui/Select';

const { Option } = Select;
const rateTaxes = [10, 18, 20];

// Выпадающий список часов с шагом 1
export default (props: SelectProps) => (
  <Select {...props} allowClear>
    <Option value={0} key={0}>
      Без НДС
    </Option>
    {rateTaxes.map(tax => (
      <Option value={tax} key={tax}>
        {`${tax} %`}
      </Option>
    ))}
  </Select>
);
