// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {connect} from 'react-redux';

import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {Header, Panel} from '../../components/layout';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import type {FuelCard} from '../../lib/types';
import InnerForm from './components/InnerForm';
import {fuelCardApi} from './../../lib/api';
import type {AppState} from './../../ducks/redux';
import {notificationLoading} from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  fuelCardId: ?number,
  employeeBranchOrgUnitId: number
};

type State = {
  fuelCard: FuelCard
};

export class FuelCardsForm extends Component<Props, State> {
  state = {};
  async componentDidMount() {
    if (this.props.fuelCardId) {
      try {
        const fuelCard = await fuelCardApi.fetchFuelCard(this.props.fuelCardId);
        this.setState({
          fuelCard
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  handleSubmit = async (values: FuelCard) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      let fuelCardId = this.props.fuelCardId;
      if (fuelCardId) {
        await fuelCardApi.updateFuelCard(values);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        const addedFuelCard = await fuelCardApi.addFuelCard(values);
        fuelCardId = addedFuelCard.id;
        notification.success({
          message: 'Успешное создание',
          description: 'Топливная карта была успешно создана'
        });
      }
      navigate(`/admin/fuel-cards/${fuelCardId}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/fuel-cards', true);

  render() {
    const { fuelCard } = this.state;
    const { employeeBranchOrgUnitId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/fuel-cards')}>
                Топливные карты
              </Crumb>
              {this.props.fuelCardId ? (
                <>
                  <Crumb to={`/admin/fuel-cards/${this.props.fuelCardId}`}>
                    Топливная карта №{this.props.fuelCardId}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новая топливная карта</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {this.props.fuelCardId
              ? `Топливная карта №${this.props.fuelCardId}`
              : 'Новая топливная карта'}
          </h1>
        </StyledPanel>
        <InnerForm
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          fuelCard={fuelCard || { orgUnitId: employeeBranchOrgUnitId }}
        />
      </>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(FuelCardsForm);
