// @flow
import { accessTypeEnum } from '../../lib/enum';
import { formatDateTimeToString } from '../../lib/helpers';
import type { UserAccess } from '../../lib/types';

export const ACT_COLUMNS = [
  {
    title: 'Номер акта',
    dataIndex: 'act.actNumber',
    width: 100
  },
  {
    title: 'Дата акта',
    breakByWidth: true,
    dataIndex: 'act.actDate',
    width: 100,
    render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY')
  },
  {
    title: 'Подрядчик',
    dataIndex: 'act.contract.contractor.company.name',
    width: 200,
    breakByWidth: true
  }
];

export const TAX_ACT_COLUMNS = [
  {
    title: 'Номер акта',
    dataIndex: 'taxAct.actNumber',
    width: 100
  },
  {
    title: 'Дата акта',
    breakByWidth: true,
    dataIndex: 'taxAct.actDate',
    render: (date: string) => formatDateTimeToString(date, 'DD.MM.YYYY'),
    width: 100
  },
  {
    title: 'Подрядчик',
    dataIndex: 'taxAct.contract.contractor.company.name',
    width: 200,
    breakByWidth: true
  }
];

/**
 * Проверка на возможность создания/редактирования
 * @param userAccess Доступы пользователя
 * @returns {boolean}
 */
export const canHandleService = (userAccess: UserAccess[]) => {
  return [
    accessTypeEnum.admin,
    accessTypeEnum.adminBranch,
    accessTypeEnum.handlingMaintenanceServices
  ].some(access => userAccess.includes(access));
};

/**
 * Проверка на возможность просмотра пользователем разделов обслуживания
 * @param userAccess Доступы пользователя
 * @returns {boolean}
 */
export const canViewingService = (userAccess: UserAccess[]) => {
  return [
    accessTypeEnum.admin,
    accessTypeEnum.adminBranch,
    accessTypeEnum.viewingMaintenanceServices,
    accessTypeEnum.viewingAllMaintenanceServices
  ].some(access => userAccess.includes(access));
};
