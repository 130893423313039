// @flow
import type { ReasonForRejectingOfOrder } from '../types';
import CommonApi from './../commonApi';

const reasonsForRejectinfOfOrder = new CommonApi<ReasonForRejectingOfOrder>(
  'ordertoreworkreason',
  'Причины отклонения заявки'
);

export default {
  ...reasonsForRejectinfOfOrder
};
