// @flow
import React, {useState, type Node, useEffect} from 'react';

import {
  StyledBadge,
  StyledTabItem,
  TabContent,
  TabItems,
  Wrapper,
} from './Tabs.elements';
import {TabItem} from './TabItem';
import {hasExactMatch} from './lib';
import {navigate} from '../../../lib/helpers';

type TabsProps = {
  /**
   * Ключ открытой по-умолчанию вкладки
   */
  defaultTab?: number | string,
  children: Node,
  /**
   * Функция, вызываемая при смене вкладки
   */
  onChange?: (tabKey?: number | string) => any,
  /**
   * Фон у вкладок
   */
  background?: string,
  className?: string,
  type?: 'buttons' | 'primary',
  /**
   * Принудительный перерендер содержимого вкладок
   */
  forceRender?: boolean,
  /**
   * Синхронизирует tabKey и последнюю часть адреса в пути страницы
   *
   * Например, если tabKey=month, а адрес страницы /daily-budget/month
   * то такой таб будет выделяться как активный
   */
  withRouter?: boolean,
  style?: any,
  /**
   * Блокировать все вкладки. Удобно использовать для блокировки переключения вкладок
   * во время загрузки данных
   */
  disabled?: boolean,
};

/**
 * Компонент вкладок
 */
const Tabs = ({
  defaultTab,
  children,
  background,
  className,
  type = 'primary',
  forceRender,
  onChange,
  style,
  withRouter,
  disabled,
}: TabsProps) => {
  const exactMatch = withRouter && hasExactMatch(children);
  const [currentTab, setCurrentTab] = useState(
    // Когда используем синхронизацию с адресом,
    // то как ключ берем pathname из адреса
    withRouter ? window.location.pathname : defaultTab,
  );

  useEffect(() => setCurrentTab(defaultTab), [defaultTab]);

  const changeTab = (key: string) => {
    setCurrentTab(key);
    if (onChange) {
      onChange(key);
    }
  };
  /**
   * Функция для разбора вкладок
   */
  const parseChildrens = (children: Node) => {
    const tabItems = [],
      tabContents = [];
    React.Children.forEach(children, (child, index) => {
      if (child && child.type === TabItem) {
        const {
          label,
          badge,
          children,
          onClick,
          tabKey,
          url,
          activeUrl,
          style,
        } = child.props;
        let active = false;
        if (withRouter) {
          // Если в списке табов есть полное совпадение url с pathname
          if (exactMatch) {
            if (Array.isArray(activeUrl)) {
              active = activeUrl.includes(window.location.pathname);
            } else {
              active = window.location.pathname === url;
            }
          } else {
            if (Array.isArray(activeUrl)) {
              active = activeUrl.includes(window.location.pathname);
            } else {
              active = window.location.pathname.includes(url);
            }
          }
        } else {
          // Если не указали таб по-умолчанию, то делаем активным самый первый
          active = currentTab ? tabKey === currentTab : index === 0;
        }
        tabItems.push({
          tabKey,
          label,
          badge,
          active,
          onClick,
          url,
          style,
        });
        tabContents.push({
          tabKey,
          url,
          children,
          active,
        });
      }
    });
    return {
      tabItems,
      tabContents,
    };
  };

  const {tabContents, tabItems} = parseChildrens(children);
  const contents = forceRender
    ? tabContents.filter(content => content.active)
    : tabContents;
  return (
    <Wrapper className={className} type={type} style={style}>
      <TabItems background={background} type={type}>
        {tabItems.map(tabItem => {
          const {active, tabKey, onClick, badge, label, url, style} = tabItem;
          const key = withRouter ? url : tabKey;
          return (
            <StyledTabItem
              active={active}
              tabKey={key}
              type={type}
              key={key}
              onClick={() => {
                if (disabled) {
                  return;
                }
                if (!active) {
                  onClick && onClick(key);
                  changeTab(key);
                  withRouter && navigate(url);
                }
              }}
              style={style}
              disabled={disabled}
            >
              {label}
              {badge && <StyledBadge>{badge}</StyledBadge>}
            </StyledTabItem>
          );
        })}
      </TabItems>
      {contents.map(content => (
        <TabContent
          path={content.tabKey || content.url}
          key={content.tabKey || content.url}
          active={content.active}
        >
          {content.children}
        </TabContent>
      ))}
    </Wrapper>
  );
};

export default Tabs;
