// @flow

import type {Dispatch} from 'redux';

import type {Action} from './redux';
import {maintenanceOperationApi} from '../lib/api';
import {MaintenanceOperation} from '../lib/types';

export const SET_MAINTENANCE_OPERATION_GROUPS =
  'vehicles/maintenance-operation-group/set';

const initialState: ?MaintenanceOperation = null;

const reducer = (
  state: ?MaintenanceOperation = initialState,
  {type, payload}: Action,
): ?MaintenanceOperation => {
  switch (type) {
    case SET_MAINTENANCE_OPERATION_GROUPS:
      return payload;
    default:
      return state;
  }
};

export const fetchMaintenanceOperationGroups: Function = (): Function => async (
  dispatch: Dispatch,
): Promise<void> => {
  const maintenanceOperations = await maintenanceOperationApi.fetchMaintenanceOperations();
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUPS,
    payload: {
      ...maintenanceOperations,
    },
  });
};

export default reducer;
