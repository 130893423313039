// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { branchBudgetSummaryMonthApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { BranchBudgetSummaryMonth } from '../../lib/types';
import { toLocalStringRu, MONTH } from '../../lib/helpers';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите месяц"
    fetch={async params => {
      return await branchBudgetSummaryMonthApi.fetch({
        ...params,
        ...filter
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={branchBudgetSummaryMonthApi.get}
    notFoundText="Mесяц не найдены"
    renderOption={(
      branchBudgetSummaryMonth: BranchBudgetSummaryMonth,
      Option
    ) => (
      <Option
        value={branchBudgetSummaryMonth.id}
        key={branchBudgetSummaryMonth.id}
        branchBudgetSummaryMonth={branchBudgetSummaryMonth}
      >
        {MONTH[branchBudgetSummaryMonth.month - 1]}{' '}
        {toLocalStringRu(branchBudgetSummaryMonth.economyResidue)}
      </Option>
    )}
    {...selectProps}
  />
);
