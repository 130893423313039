//@flow
import React, {useCallback} from 'react';

import {FilterItem, OrgUnitSelect} from '../../../components';
import Field from '../../../components/card/Field';
import {CommonFilter, type FilterProps} from '../../../components/hoc/common/handbook/CommonFilter';
import {Input} from 'antd';

export type PlaceParamsFilter = {
  nodeId?: number,
  parusId?: string,
  search?: string,
};

export const PlaceFilter = (props: FilterProps<PlaceParamsFilter>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<PlaceParamsFilter>>) => CommonFilter<PlaceParamsFilter>({...filterProps, ...props}),
    [props],
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                size="small"
                value={values.nodeId}
                onlyBranches
                onChange={value => changeValue('nodeId', value)}
                isFilter={true}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.parusId}
                onChange={({target}) => changeValue('parusId', target.value)}
                placeholder="Rn"
                allowClear
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Input
                size="small"
                value={values.search}
                onChange={({target}) => changeValue('search', target.value)}
                placeholder="Полное наименование"
                allowClear
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default PlaceFilter;
