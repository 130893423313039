/* TripEditingModal.js | Редактирование ПЛ НТС */
// @flow
import Button from 'antd/lib/button';
import Checkbox from "antd/lib/checkbox";
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import { FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { Form } from '../../../../components';
import { notificationLoading } from '../../../../components/Notifications';
import { Grid, GridItem } from '../../../../components/layout';
import ContractVehicleSelect from '../../../../components/selects/ContractVehicleSelect';
import ExpenseDirectionSelect from "../../../../components/selects/ExpenseDirectionSelect";
import { ContractTripApi } from '../../../../lib/api';
import { fuelTypes } from '../../../../lib/enum';
import { showConfirmation } from "../../../../lib/helpers";
import type { ContractTrip } from '../../../../lib/types/contractTrips';

type Props = {
  visible: boolean,
  onClose: Function,
  trip: ContractTrip,
  updateTripList: Function,
  openSplitModal: (trip: ContractTrip) => void
};

type State = {
  tripIdBackup: number, // ID ПЛ НТС.
  originalContractVehicleIdIdBackup: number, // ID подменяемого ТС.
  movementPriceBackup: number, // Тариф в движении, руб - Тариф (руб.) за 1 час эксплуатации постоянная часть
  waitingPriceBackup: number, // Тариф ожидания, руб - Тариф (руб.) за 1 час эксплуатации постоянная часть x Коэфф. простоя
  waitingWithRunningEnginePriceBackup: number, // Тариф ожидания с вкл. двигателем, руб - Простой с включенным ДВС, Тариф за 1 час работы, руб.
  heaterPriceBackup: number, // Тариф отопителя, руб - Отопитель воздушный, Тариф за 1 час работы, руб.
  preheaterPriceBackup: number, // Тариф подогревателя, руб - Подогреватель жидкостной, Тариф за 1 час работы, руб.
  powerGeneratorPriceBackup: number, // Тариф силовой генератор, руб - Силовой генератор, Тариф за 1 час работы, руб.
  winchPriceBackup: number, // Тариф лебедка, руб - Лебедка, Тариф за 1 час работы, руб.
  distancePriceBackup: number, // Тариф за км, руб - Тариф (руб.) за 1 км пробега переменная часть
  distanceWithTrailerPriceBackup: number, // Тариф за км с прицепом, руб - Пробег ТС с прицепом, Тариф за 1 км, руб.
};

class TripEditingModal extends React.Component<Props, State> {
  state = { };

  // Сохранить ПЛ НТС.
  onSubmit = async values => {
    notification.close('isOriginalTariff');
    const { updateTripList, onClose } = this.props;
    try {
      notificationLoading({ message: 'Обновление данных', key: 'saving' });
      const trip = values;
      // Бэкап тарифов ПЛ НТС.
      trip.movementPriceBackup = this.state.movementPriceBackup;
      trip.waitingPriceBackup = this.state.waitingPrice;
      trip.waitingWithRunningEnginePriceBackup = this.state.waitingWithRunningEnginePrice;
      trip.heaterPriceBackup = this.state.heaterPrice;
      trip.preheaterPriceBackup = this.state.preheaterPrice;
      trip.distancePriceBackup = this.state.distancePrice;
      trip.distanceWithTrailerPriceBackup = this.state.distanceWithTrailerPrice;
      trip.powerGeneratorPriceBackup = this.state.powerGeneratorPrice;
      trip.winchPriceBackup = this.state.winchPrice;
      // API.
      await ContractTripApi.updateTrip(trip);
      notification.success({ message: 'Данные обновлены' });
      updateTripList();
      onClose();
    } catch (e) {
      notification.error({ message: 'Не удалось обновить данные ПЛ', description: e.message || e.title });
    } finally {
      notification.close('saving');
    }
  };

  // Открыть модальное окно "Декомпозиция ПЛ НТС".
  onSplitTrip = () => {
    const { onClose, trip, openSplitModal } = this.props;
    try {
      notificationLoading({ message: 'Декомпозиция ПЛ НТС', key: 'splitting' })
      onClose();
      openSplitModal(trip);
    } catch (e) {
      notification.error({ message: 'Не удалось открыть декомпозицию ПЛ НТС', description: e.message || e.title });
    } finally {
      notification.close('splitting');
    }
  };

  // Открыть модальное окно "Декомпозиция ПЛ НТС".
  onSplitTripConfirmation = async () => {
    showConfirmation('Открыть декомпозицию ПЛ НТС', this.onSplitTrip);
  }

  // Запросить тарифы подменямого ТС.
  onSetOriginalTariff = async (setFieldValue, isOriginalTariff: boolean, values) => {
    notification.close('isOriginalTariff');
    const { trip } = this.props;
    if (!values.originalContractVehicleId) {
      notification.warn({ message: "Внимание", description: "Подменяемое ТС не выбрано", duration: 5, key: 'isOriginalTariff' });
      trip.isOriginalTariff = false;
      this.setState({ trip });
      setFieldValue('isOriginalTariff', false);
      return false;
    }
    try {
      // Применить тарифы.
      if (isOriginalTariff) {
        // API.
        const tariff = await ContractTripApi.getTariff(trip.id, values.originalContractVehicleId);
        this.setState({ tariff: tariff });
        const branchId = trip.contractTripMonth.orgUnitId;
        const contractorBranchLink = await ContractTripApi.getContractorBranchLink(branchId);
        this.setState({ contractorBranchLink: contractorBranchLink });
        // Сбросить бэкапы тарифов ПЛ НТС.
        await this.onResetOriginalTariff();
        // Сохранить тарифы ПЛ НТС.
        this.setState({ tripIdBackup: values.id });
        this.setState({ originalContractVehicleIdIdBackup: values.originalContractVehicleId });
        this.setState({ movementPriceBackup: values.movementPrice });
        this.setState({ waitingPriceBackup: values.waitingPrice });
        this.setState({ waitingWithRunningEnginePriceBackup: values.waitingWithRunningEnginePrice });
        this.setState({ heaterPriceBackup: values.heaterPrice });
        this.setState({ preheaterPriceBackup: values.preheaterPrice });
        this.setState({ powerGeneratorPriceBackup: values.powerGeneratorPrice });
        this.setState({ winchPriceBackup: values.winchPrice });
        this.setState({ distancePriceBackup: values.distancePrice });
        this.setState({ distanceWithTrailerPriceBackup: values.distanceWithTrailerPrice });
        // Применить тарифы ПЛ НТС из тарифов ТС.
        this.onSetTripTariff(setFieldValue);
        // Результат.
        return true;
      }
      // Откатить тарифы ПЛ НТС.
      else {
        this.onRollbackTripTariff(setFieldValue, values);
      }
    } catch (e) {
      setFieldValue('isOriginalTariff', false);
      // notification.error({ message: 'Не удалось получить тарифы подменямого ТС', description: e.message || e.title });
      notification.warn({ message: "Внимание", duration: 5, key: 'isOriginalTariff',
        description: "В справочнике фактических тарифов отсутствуют значения для модели подменяемого ТС" });
    }
    return false;
  }

  // Сбросить бэкапы тарифов ПЛ НТС.
  onResetOriginalTariff = async () => {
    this.setState({ tripIdBackup: 0 });
    this.setState({ originalContractVehicleIdIdBackup: 0 });
    this.setState({ movementPriceBackup: 0 });
    this.setState({ waitingPriceBackup: 0 });
    this.setState({ waitingWithRunningEnginePriceBackup: 0 });
    this.setState({ heaterPriceBackup: 0 });
    this.setState({ preheaterPriceBackup: 0 });
    this.setState({ powerGeneratorPriceBackup: 0 });
    this.setState({ winchPriceBackup: 0 });
    this.setState({ distancePriceBackup: 0 });
    this.setState({ distanceWithTrailerPriceBackup: 0 });
  }

  // Применить тарифы ПЛ НТС из тарифов ТС.
  onSetTripTariff = (setFieldValue) => {
    notification.close('isOriginalTariff');
    const { tariff, contractorBranchLink } = this.state;
    if (!tariff) {
      notification.warn({ message: "Внимание", duration: 5, key: 'isOriginalTariff',
        description: "В справочнике фактических тарифов отсутствуют значения для модели подменяемого ТС" });
      return;
    }
    // Тариф в движении, руб - Тариф (руб.) за 1 час эксплуатации постоянная часть
    if (tariff.tariffFixed > 0) {
      setFieldValue('movementPrice', Number((tariff.tariffFixed / 100).toFixed(2)));
    } else {
      setFieldValue('movementPrice', 0);
    }
    // Тариф ожидания, руб - Тариф (руб.) за 1 час эксплуатации постоянная часть x Коэфф. простоя
    if (tariff.tariffFixed > 0) {
      setFieldValue('waitingPrice', Number((tariff.tariffFixed * contractorBranchLink.downtimeRatio / 100).toFixed(2)));
    } else {
      setFieldValue('waitingPrice', 0);
    }
    // Тариф ожидания с вкл. двигателем, руб - Простой с включенным ДВС, Тариф за 1 час работы, руб.
    if (tariff.warmingTariff > 0) {
      setFieldValue('waitingWithRunningEnginePrice', Number((tariff.warmingTariff / 100).toFixed(2)));
    } else {
      setFieldValue('waitingWithRunningEnginePrice', 0);
    }
    // Тариф отопителя, руб - Отопитель воздушный, Тариф за 1 час работы, руб.
    if (tariff.heaterTariff > 0) {
      setFieldValue('heaterPrice', Number((tariff.heaterTariff / 100).toFixed(2)));
    } else {
      setFieldValue('heaterPrice', 0);
    }
    // Тариф подогревателя, руб - Подогреватель жидкостной, Тариф за 1 час работы, руб.
    if (tariff.preHeaterTariff > 0) {
      setFieldValue('preheaterPrice', Number((tariff.preHeaterTariff / 100).toFixed(2)));
    } else {
      setFieldValue('preheaterPrice', 0);
    }
    // Тариф силовой генератор, руб - Силовой генератор, Тариф за 1 час работы, руб.
    if (tariff.generatorTariff > 0) {
      setFieldValue('powerGeneratorPrice', Number((tariff.generatorTariff / 100).toFixed(2)));
    } else {
      setFieldValue('powerGeneratorPrice', 0);
    }
    // Тариф лебедка, руб - Лебедка, Тариф за 1 час работы, руб.
    if (tariff.winchTariff > 0) {
      setFieldValue('winchPrice', Number((tariff.winchTariff / 100).toFixed(2)));
    } else {
      setFieldValue('winchPrice', 0);
    }
    // Тариф за км, руб - Тариф (руб.) за 1 км пробега переменная часть
    if (tariff.tariffDynamic > 0) {
      setFieldValue('distancePrice', Number((tariff.tariffDynamic / 100).toFixed(2)));
    } else {
      setFieldValue('distancePrice', 0);
    }
    // Тариф за км с прицепом, руб - Пробег ТС с прицепом, Тариф за 1 км, руб.
    if (tariff.trailerTariff > 0) {
      setFieldValue('distanceWithTrailerPrice', Number((tariff.trailerTariff / 100).toFixed(2)));
    } else {
      setFieldValue('distanceWithTrailerPrice', 0);
    }
    // Уведомление.
    notification.warn({ message: "Внимание", duration: 5, key: 'isOriginalTariff',
      description: "Применены тарифы подменямого ТС" });
  }

  // Откатить тарифы ПЛ НТС.
  onRollbackTripTariff = (setFieldValue, values) => {
    notification.close('isOriginalTariff');
    const { trip, tripIdBackup, originalContractVehicleIdIdBackup } = this.state;
    // Тот же ПЛ НТС.
    if (trip !== undefined && tripIdBackup !== undefined && originalContractVehicleIdIdBackup !== undefined &&
      tripIdBackup === trip.id && originalContractVehicleIdIdBackup === values.originalContractVehicleId) {
      // Откатить тарифы.
      setFieldValue('movementPrice', this.state.movementPriceBackup);
      setFieldValue('waitingPrice', this.state.waitingPriceBackup);
      setFieldValue('waitingWithRunningEnginePrice', this.state.waitingWithRunningEnginePriceBackup);
      setFieldValue('heaterPrice', this.state.heaterPriceBackup);
      setFieldValue('preheaterPrice', this.state.preheaterPriceBackup);
      setFieldValue('powerGeneratorPrice', this.state.powerGeneratorPriceBackup);
      setFieldValue('winchPrice', this.state.winchPriceBackup);
      setFieldValue('distancePrice', this.state.distancePriceBackup);
      setFieldValue('distanceWithTrailerPrice', this.state.distanceWithTrailerPriceBackup);
      // Сбросить бэкапы тарифов ПЛ НТС.
      this.onResetOriginalTariff().then();
      // Уведомление.
      notification.warn({ message: "Внимание", description: "Тарифы ТС приведены к изначальным", duration: 5, key: 'isOriginalTariff' });
    }
    // Другой ПЛ НТС.
    else {
      // Загрузить бэкапы тарифов ПЛ НТС из БД.
      this.onLoadTripTariffBackup(setFieldValue);
    }
  }

  // Загрузить текущие тарифы ПЛ НТС из БД.
  onLoadTripTariffCurrent = (setFieldValue) => {
    notification.close('isOriginalTariff');
    // API.
    ContractTripApi.getTrip(this.props.trip.id).then(getTrip => {
      if (!getTrip) {
        notification.warn({ message: "Внимание", description: "ПЛ НТС не загружен", duration: 5, key: 'isOriginalTariff' });
        setFieldValue('isOriginalTariff', false);
        return;
      }
      // Откатить тарифы.
      setFieldValue('movementPrice', getTrip.movementPrice);
      setFieldValue('waitingPrice', getTrip.waitingPrice);
      setFieldValue('waitingWithRunningEnginePrice', getTrip.waitingWithRunningEnginePrice);
      setFieldValue('heaterPrice', getTrip.heaterPrice);
      setFieldValue('preheaterPrice', getTrip.preheaterPrice);
      setFieldValue('powerGeneratorPrice', getTrip.powerGeneratorPrice);
      setFieldValue('winchPrice', getTrip.winchPrice);
      setFieldValue('distancePrice', getTrip.distancePrice);
      setFieldValue('distanceWithTrailerPrice', getTrip.distanceWithTrailerPrice);
      // Сбросить бэкапы тарифов ПЛ НТС.
      this.onResetOriginalTariff().then();
      // Уведомление.
      notification.warn({ message: "Внимание", description: "Загружены текущие тарифы ПЛ НТС", duration: 5, key: 'isOriginalTariff' });
    });
  }

  // Загрузить бэкапы тарифов ПЛ НТС из БД.
  onLoadTripTariffBackup = (setFieldValue) => {
    notification.close('isOriginalTariff');
    // API.
    ContractTripApi.getTrip(this.props.trip.id).then(getTrip => {
      if (!getTrip) {
        notification.warn({ message: "Внимание", description: "ПЛ НТС не загружен", duration: 5, key: 'isOriginalTariff' });
        setFieldValue('isOriginalTariff', false);
        return;
      }
      // Откатить тарифы.
      setFieldValue('movementPrice', getTrip.movementPriceBackup);
      setFieldValue('waitingPrice', getTrip.waitingPriceBackup);
      setFieldValue('waitingWithRunningEnginePrice', getTrip.waitingWithRunningEnginePriceBackup);
      setFieldValue('heaterPrice', getTrip.heaterPriceBackup);
      setFieldValue('preheaterPrice', getTrip.preheaterPriceBackup);
      setFieldValue('powerGeneratorPrice', getTrip.powerGeneratorPriceBackup);
      setFieldValue('winchPrice', getTrip.winchPriceBackup);
      setFieldValue('distancePrice', getTrip.distancePriceBackup);
      setFieldValue('distanceWithTrailerPrice', getTrip.distanceWithTrailerPriceBackup);
      // Сбросить бэкапы тарифов ПЛ НТС.
      this.onResetOriginalTariff().then();
      // Уведомление.
      notification.warn({ message: "Внимание", description: "Загружены бэкапы тарифов ПЛ НТС", duration: 5, key: 'isOriginalTariff' });
    });
  }

  updateExpenseDirectionByVehicle = async (request: ContractTripExpenseDirection) => {
    await ContractTripApi.updateExpenseDirectionByVehicle(request);
    this.props.updateTripList();
  }

  render() {
    const { onClose, visible, trip } = this.props;
    return (
      <Modal
        onCancel={onClose}
        visible={visible}
        destroyOnClose
        width={650}
        title="Редактирование ПЛ НТС"
        footer={false}
      >
        <Form
          initialValues={{
            ...trip,
            showSelectOriginalVehicle: Boolean(trip?.originalContractVehicleId),
          }}
          onSubmit={values => {
            delete values.showSelectOriginalVehicle;
            this.onSubmit(values);
          }}
          >
          {(FormField, formikProps: FormikProps) => {
            const { setFieldValue, values } = formikProps;
            const getSum = values => {
              const sum = Object.keys(values).reduce((sum, key) => {
                // чтобы получить общую сумму, нам нужно ссумировать тариф, умноженный на
                // соответствующее значение. в большинстве случаев, имя тарифа и имя значения похожи, различаются только
                // окончанием имени (movementPrice, movementTime), но есть два исключения, которые вынес в отдельные логические блоки.
                if (/Price/i.test(key)) {
                  if (key === 'distancePrice') {
                    return sum + values[key] * values.distance;
                  } else {
                    if (key === 'distanceWithTrailerPrice') {
                      return sum + values[key] * values.distanceWithTrailer;
                    } else {
                      const timeName = key.replace(/Price/, 'Time');
                      if (values[timeName] !== undefined) {
                        return sum + values[key] * values[timeName];
                      } else {
                        return sum;
                      }
                    }
                  }
                } else {
                  return sum;
                }
              }, 0);
              return Number(((sum * 100) / 100).toFixed(2));
            };
            // Форма внутри модельного окна.
            return (
              <form onSubmit={formikProps.handleSubmit}>
                {/* Значение для чтения */}
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Номер" name="number">
                      {({ value }) => value}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата начала" name="startDate">
                      {({ value }) => moment(value).format('DD.MM.YYYY')}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата окончания" name="endDate">
                      {({ value }) => moment(value).format('DD.MM.YYYY')}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Гос. номер" name="licensePlate">
                      {({ value }) => value}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тип топлива" name="fuelType">
                      {({ value }) => fuelTypes[value] || value}
                    </FormField>
                  </GridItem>
                </Grid>
                {/* Значение для редактирования */}
                <Grid>
                  <GridItem fullWidth>
                    <FormField label="НТС" name="contractVehicleId">
                      {({ name, value }) =>
                          <ContractVehicleSelect
                            placeholder="Выберите НТС"
                            allowClear
                            value={value}
                            size="small"
                            onChange={(value: string) => setFieldValue(name, value)}
                            disabled={(trip.parentId > 0)}
                          />
                      }
                    </FormField>
                  </GridItem>
                  {/* Направление расходов */}
                  <GridItem fullWidth>
                    <FormField label="Направление расходов" name="expenseDirectionId">
                      {({name, value}) => (
                        <ExpenseDirectionSelect
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue)
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth customStyles='margin-bottom: 14px;'>
                    <Button
                      type="primary"
                      onClick={() => this.updateExpenseDirectionByVehicle({
                        contractVehicleId: values.contractVehicleId,
                        licensePlate: values.licensePlate,
                        expenseDirectionId: values.expenseDirectionId,
                        contractTripMonthId: trip.contractTripMonth.id,
                      })}
                      disabled={!values.contractVehicleId || !values.licensePlate || !values.expenseDirectionId}
                    >
                      НР для всех НТС
                    </Button>
                  </GridItem>
                  {/* Подменное ТС */}
                  <GridItem fullWidth={!values.showSelectOriginalVehicle}>
                    <FormField label="Используется как подменное ТС" name="showSelectOriginalVehicle" >
                      {({name, value}) => (
                        <Checkbox
                          checked={value}
                          onChange={e => {
                            if (!e.target.checked) {
                              setFieldValue('originalContractVehicleId', null);
                            }
                            setFieldValue(name, e.target.checked);
                            setFieldValue('isOriginalTariff', false);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {values.showSelectOriginalVehicle && (
                    // Применить тарифы подменяемого ТС
                    <GridItem>
                      {((trip.parentId > 0) ? <span /> :
                          <FormField label="Применить тарифы подменяемого ТС" name="isOriginalTariff" >
                            {({name, value}) => (
                              <div>
                                <Checkbox
                                  checked={value}
                                  onChange={e => {
                                    setFieldValue(name, e.target.checked);
                                    // Запросить тарифы подменямого ТС.
                                    this.onSetOriginalTariff(setFieldValue, e.target.checked, values).then();
                                  }}
                                />
                              </div>
                            )}
                          </FormField>
                      )}
                    </GridItem>
                  )}
                  {values.showSelectOriginalVehicle && (
                    // Подменяемое ТС
                    <GridItem fullWidth={true}>
                      <FormField name="originalContractVehicleId" >
                        {({name, value}) => (
                          <ContractVehicleSelect
                            placeholder="Выберите НТС"
                            allowClear
                            value={value}
                            onChange={(value: string) => {
                              setFieldValue(name, value);
                              setFieldValue('isOriginalTariff', false);
                              // this.onRollbackTripTariff(setFieldValue, values.originalContractVehicleId);
                            }} />
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  {/* Поля для редактирования */}
                  <GridItem>
                    <FormField label="Время в движении, ч." name="movementTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            // Суммируем значения полей "Время в движении, ч." и "Время ожидания, ч."
                            setFieldValue('totalWorkTime', values.waitingTime + newValue);
                            setFieldValue('sum', getSum({ ...values, [name]: newValue }));
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тариф в движении, руб."
                      name="movementPrice"
                    >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время ожидания, ч." name="waitingTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            // Суммируем значения полей "Время в движении, ч." и "Время ожидания, ч."
                            setFieldValue('totalWorkTime', values.movementTime + newValue);
                            setFieldValue('sum', getSum({ ...values, [name]: newValue }));
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тариф ожидания, руб." name="waitingPrice">
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время простоя с включенным двигателем, ч." name="waitingWithRunningEngineTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тариф простоя с включенным двигателем, руб."
                      name="waitingWithRunningEnginePrice"
                    >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время отопителя, ч." name="heaterTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тариф отопителя, руб." name="heaterPrice">
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время подогревания, ч." name="preheaterTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тариф подогревания, руб."
                      name="preheaterPrice"
                    >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время силовой генератор, ч." name="powerGeneratorTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тариф силовой генератор, руб."
                      name="powerGeneratorPrice"
                    >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время лебедка, ч." name="winchTime" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тариф лебедка, руб."
                      name="winchPrice"
                    >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Пробег, км." name="distance">
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тариф, км." name="distancePrice">
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Пробег с прицепом, км." name="distanceWithTrailer" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Тариф с прицепом, км." name="distanceWithTrailerPrice" >
                      {({ name, value }) => (trip.parentId > 0 ? <p>{value}</p> :
                        <InputNumber
                          min={0}
                          size="small"
                          value={value}
                          onChange={newValue => {
                            setFieldValue(name, newValue);
                            setFieldValue(
                              'sum',
                              getSum({ ...values, [name]: newValue })
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Время работы всего, ч." name="totalWorkTime" >
                      {({ value }) => value}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Сумма без НДС, руб." name="sum">
                      {({ value }) => value}
                    </FormField>
                  </GridItem>
                </Grid>
                {/* Кнопки */}
                <Grid>
                  {(trip.parentId > 0
                      ? <GridItem fullWidth customStyles={{display: 'flex', justifyContent: 'space-between' }} >
                        <Button type="primary" onClick={this.onSplitTrip}>Декомпозированные ПЛ НТС</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                        <Button onClick={onClose}>Отменить</Button>
                      </GridItem>
                      : <GridItem fullWidth customStyles={{display: 'flex', justifyContent: 'space-between' }} >
                        <Button type="primary" onClick={this.onSplitTripConfirmation}>Разделить</Button>
                        <Button type="primary" htmlType="submit">Сохранить</Button>
                        <Button onClick={onClose}>Отменить</Button>
                      </GridItem>
                  )}
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal>
    );
  }
}

export default TripEditingModal;
