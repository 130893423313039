// @flow
import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import { ListTable } from '../../../components/ui';
import { withEmptyRow } from '../../../lib/helpers';
import type { VehicleTaxRate } from '../../../lib/types';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import {
  StyledInput,
  StyledInputNumber
} from '../../../components/hoc/common/components/elements';

export default (props: CommonFormProps<$Shape<VehicleTaxRate>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Наименование объекта налогообложения"
                  fast
                  required
                  name="name"
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>

              <GridItem />

              <GridItem>
                <FormField label="Дата начала" name="startDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата окончания" name="endDate" required>
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.startDate}
                      disabledDate={(date: string) =>
                        values.startDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.startDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem fullWidth>
                <ListTable
                  columns={[
                    {
                      title: 'Мощность двигателя от, л.с.',
                      key: 'powerFrom',
                      render: (value: number, data, index) => (
                        <StyledInputNumber
                          value={
                            values.powerPeriodTaxRates?.[index - 1]?.powerTo ??
                            0
                          }
                          disabled
                        />
                      )
                    },
                    {
                      title: 'Мощность двигателя до, л.с.',
                      key: 'powerTo',
                      render: (value: number, data, index) => (
                        <FormField
                          name={`powerPeriodTaxRates[${index}].powerTo`}
                          required={values.powerPeriodTaxRates?.[index + 1]}
                        >
                          {({ value }) => (
                            <StyledInputNumber
                              value={value}
                              min={
                                values.powerPeriodTaxRates?.[index - 1]
                                  ?.powerTo ?? 0
                              }
                              onChange={value =>
                                setFieldValue(
                                  `powerPeriodTaxRates[${index}].powerTo`,
                                  value
                                )
                              }
                            />
                          )}
                        </FormField>
                      )
                    },
                    {
                      title: 'Налоговая ставка, руб',
                      key: 'taxRate',
                      render: (value: number, data, index) => (
                        <FormField
                          name={`powerPeriodTaxRates[${index}].taxRate`}
                          required={
                            values.powerPeriodTaxRates?.[index]?.powerTo ||
                            index === 0
                          }
                        >
                          {({ name, value }) => (
                            <StyledInputNumber
                              value={value}
                              min={0}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                            />
                          )}
                        </FormField>
                      )
                    }
                  ]}
                  data={
                    (values.powerPeriodTaxRates?.length || 0) === 0 ||
                    !!values.powerPeriodTaxRates?.[
                      values.powerPeriodTaxRates.length - 1
                    ]?.powerTo
                      ? withEmptyRow(values.powerPeriodTaxRates)
                      : values.powerPeriodTaxRates
                  }
                />
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
