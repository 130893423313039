// @flow
import React, { useState } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import DatePicker from 'antd/lib/date-picker';

import moment from 'moment';
import qs from 'query-string';

import { formatDateTimeToISOString, navigate } from './../../../lib/helpers';

type Props = {
  washingPriceListId: number,
  closeDropdown: Function
};

export default ({ washingPriceListId, closeDropdown }: Props) => {
  const [startDate, setStartDate] = useState(
    moment
      .utc()
      .startOf('day')
      .toISOString()
  );
  const [endDate, setEndDate] = useState(
    moment
      .utc()
      .add(1, 'month')
      .toISOString()
  );
  return (
    <Popconfirm
      overlayStyle={{
        zIndex: 2000
      }}
      placement="left"
      title={
        <>
          <p>Выберите дату начала действия прейскуранта</p>
          <DatePicker
            popupStyle={{
              zIndex: 2001
            }}
            format="DD.MM.YYYY"
            disabledDate={current => current.isBefore(new Date(), 'day')}
            onChange={(value: Object, dateString: string) => {
              const dateTime = formatDateTimeToISOString(
                value,
                dateString,
                'DD.MM.YYYY'
              );
              setStartDate(dateTime);
            }}
          />
          <p>Выберите дату окончания действия прейскуранта</p>
          <DatePicker
            popupStyle={{
              zIndex: 2001
            }}
            format="DD.MM.YYYY"
            disabledDate={current => current.isBefore(new Date(), 'day')}
            onChange={(value: Object, dateString: string) => {
              const dateTime = formatDateTimeToISOString(
                value,
                dateString,
                'DD.MM.YYYY'
              );
              setEndDate(dateTime);
            }}
          />
        </>
      }
      onConfirm={() => {
        closeDropdown();
        navigate(
          `/admin/washing-price-list/${washingPriceListId}/copy?${qs.stringify({
            startDate,
            endDate
          })}`
        );
      }}
    >
      <p>Копировать прейскурант</p>
    </Popconfirm>
  );
};
