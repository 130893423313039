// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { branchBudgetSummaryApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { BranchBudgetSummary } from '../../lib/types';

type Props = SelectProps & {
  filter: any
};
export default ({ filter = {}, ...props }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите свод бюджета по филиалу"
    fetch={async params => {
      return await branchBudgetSummaryApi.fetch({
        ...filter,
        ...params
      });
    }}
    fetchSingle={branchBudgetSummaryApi.get}
    notFoundText="Своды не найдены"
    renderOption={(branchBudgetSummary: BranchBudgetSummary, Option) => (
      <Option
        value={branchBudgetSummary.id}
        key={branchBudgetSummary.id}
        branchBudgetSummary={branchBudgetSummary}
      >
        {`${branchBudgetSummary.id}${
          branchBudgetSummary.name ? ` - ${branchBudgetSummary.name}` : ''
        }`}
      </Option>
    )}
    {...props}
  />
);
