// @flow
import React from 'react';
import {connect} from 'react-redux';
import notification from 'antd/lib/notification';

import type {InspectionGtn, UserAccess} from '../../../lib/types';
import {inspectionGtnApi} from '../../../lib/api';
import {canHandleService} from '../lib';
import {convertVehicleToString, formatDateTimeToString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';

import {Header} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import type {AppState} from '../../../ducks/redux';
import {Icon} from '../../../components/ui';
import {Panel, SectionContent} from '../elements';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, {GridItem} from '../../../components/layout/Grid';

import {ActInfo} from './../../Act';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  inspectionGtn: InspectionGtn
};

class InspectionsGtnCard extends React.Component<Props, State> {
  state = {
    inspectionGtn: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const inspectionGtn = await inspectionGtnApi.fetchInspectionGtn(
        parseInt(id, 10)
      );
      this.setState({ inspectionGtn });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { inspectionGtn } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/services/inspections-gtn')}>
                Список технических осмотров ГТН
              </Crumb>
              <Crumb>Технический осмотр ГТН №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() => navigate(`/services/inspections-gtn/${id}/edit`)}
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Технический осмотр ГТН №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px" cols={3}>
              {inspectionGtn.vehicle !== null ? (
                <GridItem>
                  <Field label="ТС">
                    {convertVehicleToString(inspectionGtn.vehicle)}
                  </Field>
                </GridItem>
              ) : null}

              {!!inspectionGtn.maintenanceDate && (
                <GridItem>
                  <Field label="Дата обслуживания">
                    {formatDateTimeToString(
                      inspectionGtn.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              {inspectionGtn.nextActDate !== null ? (
                <GridItem>
                  <Field label="Следующая дата обслуживания">
                    {formatDateTimeToString(
                      inspectionGtn.nextActDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              ) : null}

              <GridItem>
                <Field label="Стоимость ТО ГТН, руб">
                  {inspectionGtn.price || 0}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Стоимость госпошлины ТО ГТН, руб">
                  {inspectionGtn.taxPrice || 0}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Общая стоимость ТО ГТН">
                  {(inspectionGtn.totalPrice || 0).toLocaleString('ru-RU', {
                    style: 'currency',
                    currency: 'RUB'
                  })}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
          {inspectionGtn && inspectionGtn.act && (
            <ActInfo act={inspectionGtn.act} />
          )}
          {inspectionGtn && inspectionGtn.taxAct && (
            <ActInfo act={inspectionGtn.taxAct} />
          )}
        </Section>
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(InspectionsGtnCard);
