// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import isNil from 'lodash/isNil';

import type {Battery, Employee, UserAccess} from '../../../lib/types';
import {batteryApi} from '../../../lib/api';
import {convertVehicleToString, formatDateTimeToString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import {accessTypeEnum} from '../../../lib/enum';
import {Card} from './../../../components';
import {Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import HistoryTable from './../HistoryTable';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {withUserAccess} from './../../withUserAccess';
import {ButtonOperations, Dropdown, Spoiler} from './../../../components/ui';
import type {DropdownType} from '../../../components/ui/Dropdown';
import AttachVehicleButton from './../components/AttachVehicleButton';
import RemoveEquipmentButton from './../../Vehicles/components/RemoveEquipmentButton';
import CopyBatteryButton from './components/CopyBatteryButton';
import {notificationLoading} from '../../../components/Notifications';
import {DecomissedStatuses, getDecomissionStatusName} from '../../../lib/api/tire';
import WorkCardModal from './components/WorkCardModal';
import type {BatteryWorkCard} from '../../../lib/types/batteryWorkCard';

const SectionContent = styled.div`
  padding: 16px;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const { Field } = Card;

export const canEditBatteryAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.decomissingEquipment
];

type Props = {
  batteryId: number,
  userAccess: UserAccess[]
};

type State = {
  battery: ?Battery,
  needUpdateHistoryList: boolean,
};

/** Карточка АКБ */
export class BatteryCard extends Component<Props, State> {
  state = {
    hasHistory: false,
    showWorkCardModal: false,
    isUpdatingWorkCard: false,
    needUpdateHistoryList: false,
  };

  dropdown: ?DropdownType;

  async componentDidMount() {
    try {
      await this.fetchBattery();
      let hasHistory;
      await batteryApi
        .fetchHistory({}, this.props.batteryId)
        .then(({ data }) => (hasHistory = !!data.length));
      this.setState({ hasHistory });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка',
        description: err && err.message
      });
    }
  }

  fetchBattery = async () => {
    try {
      const battery = await batteryApi.fetchBattery(this.props.batteryId);
      this.setState({ battery });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    }
  };

  deleteBattery = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await batteryApi.deleteBattery(this.props.batteryId);
      navigate('/equipment/batteries', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    installDate?: string,
    removeDate?: string
  ) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const { battery } = this.state;
      if (!battery) {
        return;
      }
      let updatedBattery;
      if (vehicleId) {
        updatedBattery = await batteryApi.setBatteryVehicle(
          this.props.batteryId,
          vehicleId,
          installDate
        );
      } else {
        updatedBattery = await batteryApi.updateBattery({
          ...battery,
          vehicleId,
          installDate,
          removeDate
        }, removeDate);
      }
      this.setState({
        battery: updatedBattery,
        needUpdateHistoryList: true,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено'
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('saving');
    }
  };

  writeOffBattery = async (batteryData: BatteryWorkCard) => {
    try {
      notificationLoading({
        message: 'Списание...',
        key: 'writingOff'
      });
      if (this.state.battery) {
        await batteryApi.writeOffBattery(batteryData);
        notification.success({
          message: 'Успешно',
          description: 'АКБ успешно списан'
        });
        this.setState({ showWorkCardModal: false, needUpdateHistoryList: true });
        this.fetchBattery();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('writingOff');
    }
  };

  updateWorkCard = async (batteryData: BatteryWorkCard) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'updatingWorkCard'
      });
      if (batteryData) {
        await batteryApi.updateBatteryWorkCard(batteryData);
        notification.success({
          message: 'Успешно',
          description: 'Данные карточки учета АКБ сохранены'
        });
        this.setState({
          showWorkCardModal: false,
          isUpdatingWorkCard: false,
          needUpdateHistoryList: true,
        });
        await this.fetchBattery();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({needUpdateHistoryList: false});
      notification.close('updatingWorkCard');
    }
  };

  printDocuments = async (workCardId: number, documentType: 'xls' | 'pdf') => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print'
      });
      await batteryApi.printWorkCard(workCardId, documentType);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { batteryId, userAccess } = this.props;
    const { battery, hasHistory, needUpdateHistoryList } = this.state;
    const canEdit = userAccess.some(access =>
      canEditBatteryAccess.includes(access)
    );
    if (!battery) {
      return null;
    }
    const { batteryWorkCard } = battery;
    const canUnfixVehicle = parseInt(battery.vehicleId, 10) > 0;

    return (
      <>
        <WorkCardModal
          visible={this.state.showWorkCardModal}
          onClose={() => this.setState({ showWorkCardModal: false })}
          onSave={formData => {
            formData.batteryId = batteryId;
            this.state.isUpdatingWorkCard
              ? this.updateWorkCard(formData)
              : this.writeOffBattery(formData);
          }}
          cardValues={batteryWorkCard}
          isAdmin={userAccess.includes(accessTypeEnum.admin)}
          isDecomissed={
            battery.decomissionStatus === DecomissedStatuses.decomissed
          }
        />
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/equipment/batteries')}>
                АКБ
              </Crumb>
              <Crumb>
                {battery.brand} {battery.name}
              </Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {this.state.battery.decomissionStatus !==
                  DecomissedStatuses.decomissed && hasHistory ? (
                  <Button
                    type="primary"
                    onClick={() => this.setState({ showWorkCardModal: true })}
                  >
                    Списать
                  </Button>
                ) : null}
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(null, battery.installDate, removeDate)
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                <AttachVehicleButton
                  disableDate={false}
                  onConfirm={this.setVehicle}
                  vehicleId={battery.vehicleId}
                />
                <Dropdown
                  ref={dropdown => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <CopyBatteryButton
                          battery={battery}
                          afterSubmit={() => {
                            if (this.dropdown) {
                              this.dropdown.onVisibleChange(false);
                            }
                          }}
                        />
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        onClick={() =>
                          navigate(`/equipment/batteries/edit/${batteryId}`)
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteBattery}
                          onVisibleChange={flag =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon="ellipsis"
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {battery.brand} {battery.name}
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{battery.name}</Field>
              </GridItem>
              {!isNil(battery.notation) && (
                <GridItem>
                  <Field label="Примечание">{battery.notation}</Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Марка">
                  {!!battery.brand ? battery.brand : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Напряжение (В)">{battery.voltage}</Field>
              </GridItem>
              <GridItem>
                <Field label="Ёмкость">{battery.capacity} ампер/час</Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод">{battery.code}</Field>
              </GridItem>
              <GridItem>
                <Field label="Дата выдачи">
                  {formatDateTimeToString(battery.issuedDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата установки">
                  {formatDateTimeToString(battery.installDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата снятия">
                  {formatDateTimeToString(battery.removeDate, 'DD.MM.YYYY')}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Норматив эксплуатации (месяцы)">
                  {battery.norm}
                </Field>
              </GridItem>
              {!isNil(battery.batchNumber) && (
                <GridItem>
                  <Field label="Номер партии">{battery.batchNumber}</Field>
                </GridItem>
              )}
              {!isNil(battery.factoryNumber) && (
                <GridItem>
                  <Field label="Заводской номер">{battery.factoryNumber}</Field>
                </GridItem>
              )}
              {!isNil(battery.vehicle) && (
                <GridItem>
                  <Field label="Прикрепленное ТС">
                    {convertVehicleToString(battery.vehicle)}
                  </Field>
                </GridItem>
              )}
              {!isNil(battery.otherBatteryMtrId) && (
                <GridItem>
                  <Field label="Аналог акб для расчета бюджета">
                    {battery.otherBatteryMtr?.mnemocode}{' '}
                    {battery.otherBatteryMtr?.name}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="Планируемая дата списания">
                  {!!battery.planeDecomissionDate
                    ? formatDateTimeToString(
                        battery.planeDecomissionDate,
                        'DD.MM.YYYY'
                      )
                    : '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Статус">
                  {getDecomissionStatusName(battery.decomissionStatus)}
                </Field>
              </GridItem>
              {battery.decomissionStatus === DecomissedStatuses.decomissed ? (
                <GridItem>
                  <Field label="Дата списания">
                    {!!battery.decomissionDate
                      ? formatDateTimeToString(
                          battery.decomissionDate,
                          'DD.MM.YYYY'
                        )
                      : '-'}
                  </Field>
                </GridItem>
              ) : null}
            </Grid>
          </SectionContent>
        </Section>
        {batteryWorkCard ? (
          <Section>
            <Spoiler showDivider={false} label="Карточка учета работы">
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Наименование предприятия">
                      {batteryWorkCard.vehicle
                        ? `${batteryWorkCard.vehicle.location.name}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Номер АКБ">
                      {batteryWorkCard.number ? batteryWorkCard.number : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата изготовления АКБ">
                      {formatDateTimeToString(
                        batteryWorkCard.manufactureDate,
                        'DD.MM.YYYY'
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Изготовитель АКБ">
                      {batteryWorkCard.manufacturer}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Нормативная наработка АКБ до списания">
                      {batteryWorkCard.normativeWorking}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="АКБ установлена на ТС">
                      {batteryWorkCard.vehicle
                        ? `${batteryWorkCard.vehicle.licensePlate} ${batteryWorkCard.vehicle.vehicleModel.name} ${batteryWorkCard.vehicle.vehicleModel.brandName}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Водитель">
                      {batteryWorkCard.vehicle?.driver?.employee
                        ? `${batteryWorkCard.vehicle.driver.employee.lastname} ${batteryWorkCard.vehicle.driver.employee.firstname} ${batteryWorkCard.vehicle.driver.employee.middlename}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="АКБ, поступившая в отделение (участок): срок эксплуатации на автомобиле">
                      {batteryWorkCard.mounthsInUsing}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Причина списания">
                      {batteryWorkCard.decomissionReason}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="АКБ при выдаче из отделения: плотность электролита, г/куб. см">
                      {batteryWorkCard.electrolyteDensity}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Заключение по списанию АКБ">
                      {batteryWorkCard.conclusion}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Председатель комиссии">
                      {batteryWorkCard.commissionChairmanEmployee
                        ? `${batteryWorkCard.commissionChairmanEmployee.lastname} ${batteryWorkCard.commissionChairmanEmployee.firstname} ${batteryWorkCard.commissionChairmanEmployee.middlename}`
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Члены комиссии">
                      {batteryWorkCard.commissionMemberEmployees
                        ? batteryWorkCard.commissionMemberEmployees.map(
                            (employee: Employee) => {
                              if (!employee) {
                                return <p key={Math.random()}>-</p>;
                              }
                              return (
                                <p
                                  key={employee.id}
                                >{`${employee.lastname} ${employee.firstname} ${employee.middlename}`}</p>
                              );
                            }
                          )
                        : '-'}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Дата">
                      {formatDateTimeToString(
                        batteryWorkCard.date,
                        'DD.MM.YYYY'
                      )}
                    </Field>
                  </GridItem>
                </Grid>
                <Grid rows={1} cols={24}>
                  <GridItem
                    span={6}
                    customStyles={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      maxWidth: 200
                    }}
                  >
                    <Button
                      style={{ marginTop: 15 }}
                      onClick={() =>
                        this.printDocuments(batteryWorkCard.id, 'xls')
                      }
                    >
                      печать xls
                    </Button>
                    <Button
                      style={{ marginTop: 15 }}
                      onClick={() =>
                        this.printDocuments(batteryWorkCard.id, 'pdf')
                      }
                    >
                      печать pdf
                    </Button>
                  </GridItem>
                  {canEdit && (
                    <GridItem
                      span={18}
                      customStyles={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        style={{ marginTop: 15 }}
                        onClick={() =>
                          this.setState({
                            showWorkCardModal: true,
                            isUpdatingWorkCard: true
                          })
                        }
                      >
                        Редактировать
                      </Button>
                    </GridItem>
                  )}
                </Grid>
              </SectionContent>
            </Spoiler>
          </Section>
        ) : null}
        <Section>
          <SectionTitle>История</SectionTitle>
          {batteryId > 0 && (
            <HistoryTable
              needUpdateList={needUpdateHistoryList}
              equipmentId={batteryId}
              fetchHistory={batteryApi.fetchHistory}
            />
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(BatteryCard);
