// @flow
import React, { useCallback, useState } from 'react';
import { passTariffApi } from '../../lib/api';
import type { PassTariff, UserAccess } from '../../lib/types';
import { formatDateTimeToString, navigate } from '../../lib/helpers';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';
import { withUserAccess } from './../withUserAccess';
import { addAccessRight } from './accessRight';
import { getBreadCrumbsByUrl } from '../../lib/autoBreadcrumbs';

const { Field } = Card;

type InnerProps = {
  data: PassTariff
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/pass-tariff';

const breadCrumbs = getBreadCrumbsByUrl(entryPointPath);

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Подразделение">{props.data.orgUnitName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Модель ТС">
            {props.data.vehicleModel?.brand?.name}{' '}
            {props.data.vehicleModel?.name}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Тариф за превышение допустимой нормы в весенний период без НДС, руб.">
            {props.data.excessRate || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Сумма тарифа компенсации за вред принесённый дорогам без НДС, руб.">
            {props.data.roadDamageRate || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Ставка госпошлины за выдачу пропусков на весенний период без НДС, руб.">
            {props.data.springPassesTax || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Ставка госпошлины за выдачу пропусков (негабарит) без НДС, руб.">
            {props.data.cargoPassesTax || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Ставка госпошлины за согласование пропусков (негабарит) без НДС, руб.">
            {props.data.cargoPassesAgreementTax || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Стоимость бланка, руб.">
            {props.data.blankPrice || '-'}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата начала">
            {formatDateTimeToString(props.data.startDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата окончания">
            {formatDateTimeToString(props.data.endDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: PassTariff, setData] = useState({
    id: props.id,
    name: '',
    isAvailableForSelect: false
  });

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access)
  );

  const access = {
    delete: canEdit
      ? async () => {
          await passTariffApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}/edit/${data.id}`)
      : false
  };

  const onFetch = useCallback(async () => {
    setData(await passTariffApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs: [
          ...breadCrumbs,
          {
            name: `${props.id}`,
            parent: 'Тарифы на пропуска'
          }
        ],
        mainHeader: 'Тарифы на пропуска',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
