// @flow
import { accessTypeEnum } from './../../lib/enum';

export const addAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch
];

export const viewingAccessRight = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.viewingSelfVehiclePlanBudget,
  accessTypeEnum.viewingAllSelfVehiclePlanBudget
];

export default addAccessRight;
