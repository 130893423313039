// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import FormItem from 'antd/lib/form/FormItem';

import type {
  MaintenanceOperation,
  MaintenancePartAndConsumables,
  MaintenanceTypeOfWorkType,
  MaintenanceTypeOils,
  MdmNode,
  orderContractorType
} from './../../../lib/types';
import type { MaintenanceOperationStatus } from './../../../lib/enum';
import {
  maintenanceOperationStatuses,
  orderContractorTypeEnum,
  maintenanceTypeOfWorkEnum,
  maintenanceTypeOils
} from './../../../lib/enum';
import {
  getValueObject,
  isEmptyValue,
  toFixed,
  toLocalStringRu,
  minus,
  multipliedBy
} from './../../../lib/helpers';

import { Selects } from './../../../components';
import YesNoSelect from '../../../components/selects/YesNoSelect';

const {
  MaintenanceOperationStatusSelect,
  StockPartSelect,
  MaintenanceOilsSelect
} = Selects;

const StyledSum = styled.div`
  text-align: right;
  padding: 10px 0px;
  span {
    font-weight: 500;
  }
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;
const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;

const required = (value, record = {}) => {
  if (!isEmpty(record)) {
    return isEmptyValue(value) ? 'error' : 'validating';
  }
  return 'validating';
};

export const operationColumns = (
  isContractor: boolean,
  onChange: Function,
  readOnly: boolean = false,
  contractorOrderType: orderContractorType
) => {
  return [
    {
      title: 'Код',
      key: 'code',
      width: '100px',
      render: (value: string, record: MaintenanceOperation, index: number) => {
        if (readOnly || isContractor) {
          return value;
        }
        return <Input disabled value={value} placeholder="Код работы" />;
      }
    },
    {
      title: 'Наименование',
      key: 'name',
      render: (name: string, record: MaintenanceOperation, index: number) => {
        if (readOnly || isContractor) {
          return name;
        }
        return (
          <StyledFormItem validateStatus={required(name, record)}>
            <Input
              name="name"
              onChange={e => onChange(index, 'name', e.target.value)}
              value={name}
              placeholder="Наименование работы"
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Выезд',
      key: 'isFieldWork',
      width: '70px',
      render: (
        isFieldWork: boolean,
        record: MaintenanceOperation,
        index: number
      ) => {
        if (readOnly || isContractor) {
          return isFieldWork ? 'Да' : 'Нет';
        } else {
          return (
            <StyledFormItem>
              <YesNoSelect
                onChange={value => onChange(index, 'isFieldWork', value)}
                value={!!isFieldWork}
                placeholder="Признак выезда"
              />
            </StyledFormItem>
          );
        }
      }
    },
    {
      title: 'Количество',
      key: 'count',
      width: '50px',
      render: (count: number, record: MaintenanceOperation, index: number) => {
        if (readOnly || isContractor) {
          return count;
        } else {
          return (
            <StyledFormItem validateStatus={required(count, record)}>
              <StyledInputNumber
                name="count"
                onChange={count => onChange(index, 'count', count)}
                value={count}
                min={0}
              />
            </StyledFormItem>
          );
        }
      }
    },
    {
      title: 'Нормочасы',
      key: 'hours',
      width: '70px',
      render: (hours: number, record: MaintenanceOperation, index: number) => {
        if (readOnly || isContractor) {
          return (
            <>
              {hours} {isContractor && diff(hours, record.contractorHours)}
            </>
          );
        } else {
          return (
            <StyledInputNumber
              disabled
              name="number"
              onChange={value => onChange(index, 'hours', value)}
              value={hours}
              min={0}
            />
          );
        }
      }
    },
    {
      title: 'Цена',
      key: 'price',
      width: '80px',
      render: (price: number, record: MaintenanceOperation, index: number) => {
        if (readOnly || isContractor) {
          return (
            <>
              {toLocalStringRu(price)}{' '}
              {isContractor && diff(price, record.contractorPrice)}
            </>
          );
        } else {
          return (
            <StyledInputNumber
              disabled
              name="price"
              onChange={price => onChange(index, 'price', price)}
              value={price}
              min={0}
            />
          );
        }
      }
    },
    {
      title: 'Сумма',
      key: 'sum',
      width: '80px',
      render: (sum: number, record: MaintenanceOperation, index: number) => {
        const sumOperation = multipliedBy(
          multipliedBy(record.price, record.count),
          record.hours
        );
        if (readOnly || isContractor) {
          return (
            <>
              {toLocalStringRu(sumOperation)}{' '}
              {isContractor &&
                diff(
                  sumOperation,
                  multipliedBy(record.count, record.contractorSum)
                )}
            </>
          );
        } else {
          return (
            <StyledInputNumber
              name="sum"
              disabled
              value={isNaN(sumOperation) ? null : sumOperation}
              min={0}
            />
          );
        }
      }
    },
    contractorOrderType !== orderContractorTypeEnum.internal && {
      title: 'Статус',
      width: '130px',
      style: {
        overflow: 'hidden'
      },
      key: 'status',
      render: (
        status: MaintenanceOperationStatus,
        record: MaintenanceOperation,
        index: number
      ) => {
        if (readOnly) {
          return maintenanceOperationStatuses[status];
        } else {
          return (
            <MaintenanceOperationStatusSelect
              name="status"
              onChange={value => onChange(index, 'status', value)}
              value={status}
            />
          );
        }
      }
    }
  ].filter(Boolean);
};

export const partColumns = (
  isContractor: boolean,
  onChange: Function,
  readOnly: boolean = false,
  workType?: MaintenanceTypeOfWorkType
) => {
  return [
    {
      title: 'Код МТР',
      width: '120px',
      key: 'mtrCode',
      dataIndex: 'mtr',
      render: (
        mtr: MdmNode,
        record: MaintenancePartAndConsumables,
        index: number
      ) => {
        if (readOnly || isContractor) {
          return isContractor
            ? mtr
              ? mtr.mnemocode
              : null
            : record.stockPart.mtr && record.stockPart.mtr.mnemocode;
        }
        return (
          <Input
            disabled
            value={
              record.stockPart && record.stockPart.mtr
                ? record.stockPart.mtr.mnemocode
                : null
            }
          />
        );
      }
    },
    {
      title: 'Деталь',
      key: 'mtr',
      style: {
        overflow: 'hidden',
        minWidth: '100px'
      },
      render: (
        mtr: MdmNode,
        record: MaintenancePartAndConsumables,
        index: number
      ) => {
        if (readOnly || isContractor) {
          return isContractor
            ? mtr
              ? mtr.name
              : null
            : record.stockPart.mtr && record.stockPart.mtr.name;
        }
        return (
          <StyledFormItem validateStatus={required(record.stockPartId, record)}>
            <StockPartSelect
              fullInfo
              name="stockPartId"
              value={record.stockPartId}
              onChange={(value, option) => {
                if (value) {
                  onChange(index, 'stockPart', option.props.part);
                } else {
                  onChange(index, 'stockPart', undefined);
                }
              }}
            />
          </StyledFormItem>
        );
      }
    },
    ...(workType === maintenanceTypeOfWorkEnum.changeOil
      ? [
          {
            title: 'Тип масел',
            key: 'typeOils',
            dataIndex: 'typeOils',
            render: (
              type: ?MaintenanceTypeOils,
              record: MaintenancePartAndConsumables,
              index: number
            ) => {
              if (readOnly) {
                return type ? maintenanceTypeOils[type] : null;
              }
              return (
                <StyledFormItem
                  validateStatus={required(record.typeOils, record)}
                >
                  <MaintenanceOilsSelect
                    fullInfo
                    name="typeOils"
                    value={record.typeOils}
                    onChange={(value, option) => {
                      if (value) {
                        onChange(index, 'typeOils', value);
                      } else {
                        onChange(index, 'typeOils', undefined);
                      }
                    }}
                  />
                </StyledFormItem>
              );
            }
          }
        ]
      : []),
    {
      title: '№ партии',
      dataIndex: 'mtr',
      key: 'batchNumber',
      width: '80px',
      render: (mtr: MdmNode, record: MaintenancePartAndConsumables) => {
        if (readOnly || isContractor) {
          return getValueObject(record, 'stockPart.batchNumber');
        }
        return (
          <Input
            disabled
            value={getValueObject(record, 'stockPart.batchNumber')}
          />
        );
      }
    },
    {
      title: 'Стоимость ед. (руб. без НДС)',
      width: '80px',
      key: 'cost',
      dataIndex: 'mtr',
      render: (mtr: MdmNode, record: MaintenancePartAndConsumables) => {
        if (readOnly || isContractor) {
          return isContractor
            ? mtr && (
                <>
                  {toLocalStringRu(mtr.currentCost) || '-'}{' '}
                  {diff(mtr.currentCost || 0, record.contractorCost)}
                </>
              )
            : toLocalStringRu(getValueObject(record, 'stockPart.cost'));
        }
        return (
          <Input disabled value={getValueObject(record, 'stockPart.cost')} />
        );
      }
    },
    {
      title: 'Ед. измерения',
      width: '50px',
      key: 'measure',
      dataIndex: 'mtr',
      render: (mtr: MdmNode, record: MaintenancePartAndConsumables) => {
        if (readOnly || isContractor) {
          return isContractor
            ? getValueObject(record, 'mtr.measure')
            : getValueObject(record, 'stockPart.mtr.measure');
        }
        return (
          <Input
            disabled
            value={getValueObject(record, 'stockPart.mtr.measure')}
          />
        );
      }
    },
    {
      title: 'Количество',
      key: 'count',
      width: '50px',
      render: (
        count: number,
        record: MaintenancePartAndConsumables,
        index: number
      ) => {
        return readOnly || isContractor ? (
          toLocalStringRu(count)
        ) : (
          <StyledFormItem validateStatus={required(count, record)}>
            <StyledInputNumber
              name="count"
              min={0}
              onChange={value => onChange(index, 'count', value)}
              value={count}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Сумма',
      key: 'sum',
      dataIndex: 'count',
      width: '80px',
      render: (count: number, record: MaintenancePartAndConsumables) => {
        if (readOnly || isContractor) {
          if (isContractor) {
            const currentCost = getValueObject(record, 'mtr.currentCost');
            const contractorSum = multipliedBy(
              record.count,
              record.contractorCost
            );
            return currentCost ? (
              <>
                {toLocalStringRu(multipliedBy(count, currentCost))}{' '}
                {diff(multipliedBy(currentCost, count), contractorSum)}
              </>
            ) : (
              <>- {diff(0, contractorSum)}</>
            );
          }
          const cost = getValueObject(record, 'stockPart.cost');
          return cost !== undefined ? multipliedBy(count, cost) : '-';
        }
        let sum = null;
        if (record && record.stockPart) {
          sum = record.count
            ? multipliedBy(record.count, record.stockPart.cost)
            : 0;
        }
        return <Input disabled value={sum} />;
      }
    },
    ...(isContractor
      ? [
          {
            title: 'Статус',
            width: '130px',
            style: {
              overflow: 'hidden'
            },
            key: 'status',
            render: (
              status: MaintenanceOperationStatus,
              record: MaintenancePartAndConsumables,
              index: number
            ) =>
              readOnly ? (
                maintenanceOperationStatuses[status]
              ) : (
                <MaintenanceOperationStatusSelect
                  name="status"
                  onChange={value => onChange(index, 'status', value)}
                  value={status}
                />
              )
          }
        ]
      : [])
  ];
};

export const itog = (
  sum: number,
  sumContractor: number,
  isContractor: boolean = false
) => (
  <StyledSum>
    <span>Итого:</span>{' '}
    {sum > 0
      ? toFixed(sum).toLocaleString('ru-RU', {
          style: 'currency',
          currency: 'RUB'
        })
      : '-'}
    {isContractor ? diff(sum, sumContractor, true) : null}
  </StyledSum>
);

// разница для вывода в работах и запчастях
export const diff = (a: number, b: number, currency: boolean = false) => {
  const diff = minus(a, b);
  if (diff !== 0) {
    return (
      <span style={{ color: diff > 0 ? '#2770FF' : '#E9180A' }}>
        ({diff > 0 ? '-' : '+'}
        {currency
          ? toFixed(Math.abs(diff)).toLocaleString('ru-RU', {
              style: 'currency',
              currency: 'RUB'
            })
          : toFixed(Math.abs(diff))}
        )
      </span>
    );
  }
  return null;
};
