// @flow
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React, {Component} from 'react';
import {Section} from '../../../../../components/layout';
import {notificationError, notificationLoading} from '../../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../../components/ui';
import {TableHeader} from '../../../../../components/ui/Table';
import {maintenanceOperationContractCalculationApi, vehiclePlanApi} from '../../../../../lib/api';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum} from '../../../../../lib/enum';
import {getListInitialState, plus, toLocalStringRu} from '../../../../../lib/helpers';
import type {
  CalculationStatus,
  ListState,
  MaintenanceOperationContractCalculation,
  TireCalculation,
  UserAccess,
  VehiclePlan,
} from '../../../../../lib/types';
import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget} from '../../accessRight';
import {headerPanel} from '../../components/Common';

import {COGNOS_DELTA, COLUMNS, MONTH} from '../../lib';
import type {ParamsFilter} from '../components/Filter';
import Filter from '../../components/FilterVehicleList';
import styled from 'styled-components';
import TotalBlock from '../../Budget/components/TotalBlock';
import {MaintenanceEditingModal} from './MaintenanceEditingModal';
import InfoBlock from '../../components/InfoBlock';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../../contractVehicle/fixed/lib';
import moment from 'moment/moment';
import DatePicker from 'antd/lib/date-picker';
import Select from 'antd/lib/select';


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  padding: 15px 15px 0;
`;

const {Option} = Select;

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<MaintenanceOperationContractCalculation> & {
  columns: any,
  visible: boolean,
  maintenanceOperationContractCalculation: ?MaintenanceOperationContractCalculation,
  filter: ParamsFilter,
  vehiclePlan: VehiclePlan,
  dataRow?: ?MaintenanceOperationContractCalculation,
  rowIndex?: ?number,
  monthIndex?: ?number,
  modalVisible: boolean,
  cognosData: ?number,
  openPeriod?: {
    start: string,
    end: string,
  }
};

const List = class extends Component<Props, State> {
  originalData: MaintenanceOperationContractCalculation;

  onCell = (
    data: MaintenanceOperationContractCalculation,
    rowIndex: number,
    monthIndex: number,
  ) => ({
    onClick: () => {
      if (!this.canEdit()) {
        return;
      }
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (data.id && vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos &&
        vehiclePlan?.maintenanceOperationContractCalculationStatus === calculationStatusEnum.draft &&
        dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({
          dataRow: data,
          rowIndex,
          monthIndex,
          modalVisible: true,
        });
      } else {
        return;
      }
    },
  });
  onCancel = () =>
    this.setState({
      rowIndex: null,
      monthIndex: null,
      dataRow: null,
      modalVisible: false,
    });
  onSave = async (monthData: TireCalculation) => {
    const {rowIndex = 0} = this.state;
    this.setState({loading: true});
    try {
      if (monthData && Number.isInteger(rowIndex)) {
        await maintenanceOperationContractCalculationApi.updateMonth(monthData);
        await this.fetch();
      }
    } catch (error) {
      notification.warning({
        message: 'Не удалось обновить данные',
        description: error.message,
      });
    } finally {
      this.setState({
        loading: false,
        modalVisible: false,
        rowIndex: null,
        dataRow: null,
      });
    }
  };
  async componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    let openPeriod = null;
    if (this.props.vehiclePlanId) {
      openPeriod = await getOpenPeriod(this.props.vehiclePlanId);
    }
    this.setState({filter, openPeriod}, this.getData);
  }
  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };
  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };

  itogCalculation = (data: any) => {
    const itog = {
      months: Array(12)
      .fill()
      .map(() => ({cost: 0})),
      sum: 0,
    };
    data.forEach((item: any) => {
      itog.sum = plus(item.sum || 0, itog.sum);
      item.months.forEach((month: any, index) => {
        itog.months[index].cost = plus(
          itog.months[index].cost,
          month.cost || 0,
        );
      });
    });
    return itog;
  };

  fetch = async () => {
    const {vehiclePlanId} = this.props;

    try {
      let {data} = await maintenanceOperationContractCalculationApi.fetch({
        'selfVehiclePlanVehicle.vehicle.vehicleModel.type': this.state.filter.type,
        'selfVehiclePlanVehicle.vehicle.vehicleModel.id': this.state.filter.vehicleModelId,
        'selfVehiclePlanVehicle.vehicle.id': this.state.filter.vehicleId,
        'selfVehiclePlanVehicle.vehicle.yearIssued': this.state.filter.yearIssued,
        'selfVehiclePlanVehicle.vehicle.hideEmpty': this.state.filter.hideEmpty,
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      if (data.length) {
        data = [...data, this.itogCalculation(data)];
      }

      this.setState({data});
      this.originalData = this.state.data;
    } catch (e) {
      notification.error({message: 'Не удалось запросить данные'});
    }
  };
  applyFilter = (filter: any) => {
    let noFilter = false;
    Object.values(filter).forEach(value => {
      if (value === undefined) noFilter = true;
    });

    if (noFilter) {
      this.cleanFilter();
      return;
    }
    const filtered = this.originalData
    .filter(selfVehicle =>
      !!filter.vehicleId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle.vehicleId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.vehicleModelId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleModelId ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModelId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.type && selfVehicle.selfVehiclePlanVehicle
        ? filter.type ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModel.type
        : true,
    )
    .filter(selfVehicle =>
      !!filter.yearIssued && selfVehicle.selfVehiclePlanVehicle
        ? filter.yearIssued ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.yearIssued
        : true,
    )
    .filter(selfVehicle => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });

    if (filtered.length !== 0) {
      this.setState({data: [...filtered]});
    } else {
      this.cleanFilter();
    }
  };
  cleanFilter = async () => {
    this.setState({filter: {}}, async () => {
      await this.fetch();
    });
  };
  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await maintenanceOperationContractCalculationApi.changeStatus(
        vehiclePlanId,
        status,
      );
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  calculate = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await maintenanceOperationContractCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  isDraft = () =>
    this.state.vehiclePlan?.maintenanceOperationContractCalculationStatus === calculationStatusEnum.draft;
  state = {
    ...getListInitialState(),
    vehiclePlan: undefined,
    columns: [
      ...COLUMNS,
      {
        title: 'Дата последнего ТО',
        dataIndex: 'lastMaintenanceDate',
        width: '150px',
        render: (date, record) => {
          if (this.isDraft() && this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvementAfterCognos &&
            this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvedAfterCognos) {
            if (!record.id) {
              return null;
            }
            return (
              <DatePicker
                size="small"
                value={date ? moment(date).utc() : null}
                onChange={async value => {
                  const newDate = value.utc().toISOString();
                  if (newDate !== record.lastMaintenanceDate) {
                    record.lastMaintenanceDate = newDate;
                    try {
                      await maintenanceOperationContractCalculationApi.update(record);
                      await this.getData();
                    } catch (e) {
                      notificationError(e);
                    }
                  }
                }}
                format="DD.MM.YYYY"
              />
            );
          }
          const value = date ? moment(date).utc().format('DD.MM.YYYY') : null;
          const color = moment(this.state.vehiclePlan?.startDate).utc().format('DD.MM.YYYY') === value
            ? 'red'
            : null;
          return <span style={{color}}>{value}</span>;
        },
      },
      {
        title: 'Вид нормо-часа',
        dataIndex: `workingHourPriceType`,
        width: '300px',
        render: (value, record) => {
          if (this.isDraft() && this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvementAfterCognos &&
                                this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvedAfterCognos) {
            return Number.isInteger(record.id) && (
              <Select
                size="small"
                value={value}
                onChange={async value => {
                  const newRecord = {...record, workingHourPriceType: value}
                  try {
                    await maintenanceOperationContractCalculationApi.update(newRecord);
                    await this.getData();
                  } catch (e) {
                    notificationError(e);
                  }
                }}
              >
                <Option key="0" value="contractMethod">Подрядный метод</Option>
                <Option key="1" value="contractMethodOnTheGo">Подрядный метод на выезде</Option>
              </Select>
            );
          }

          const workingHourPriceTypeText = {
            "contractMethod": "Подрядный метод",
            "contractMethodOnTheGo": "Подрядный метод на выезде",
          }

          return <span>{workingHourPriceTypeText[value]}</span>;
        },
      },
      {
        title: 'С момента последнего ТО',
        children: [
          {
            title: 'Пробег',
            dataIndex: 'milageSinceLastMaintenance',
            width: '150px',
            render: value => value ?? '-',
          },
          {
            title: 'Маш.часы',
            dataIndex: 'machineHoursSinceLastMaintenance',
            width: '150px',
            render: value => value ?? '-',
          },
        ],
      },
      ...MONTH.map((month, index) => ({
        title: month.title,
        children: [
          {
            title: (
              <TableHeader>
                Трудоёмкость одного технического обслуживания
              </TableHeader>
            ),
            dataIndex: `months[${index}].workEffort`,
            width: '190px',
            render: workEffort => toLocalStringRu(workEffort),
            onCell: (monthData, rowIndex) =>
              this.onCell(monthData, rowIndex, index),
          },
          {
            title: (
              <TableHeader>
                Стоимость материалов в месяц, руб. без НДС
              </TableHeader>
            ),
            dataIndex: `months[${index}].materialSum`,
            width: '180px',
            render: materialSum =>
              toLocalStringRu(materialSum, {minimumFractionDigits: 2}),
            onCell: (monthData, rowIndex) =>
              this.onCell(monthData, rowIndex, index),
          },
          {
            title: <TableHeader width="100px">Итого, руб. без НДС</TableHeader>,
            dataIndex: `months[${index}].cost`,
            width: '120px',
            render: cost => toLocalStringRu(cost, {minimumFractionDigits: 2}),
            onCell: (monthData, rowIndex) =>
              this.onCell(monthData, rowIndex, index),
          },
        ],
      })),
      {
        title: 'Трудоёмкость ТО итого',
        dataIndex: `workEffort`,
        width: '180px',
        render: workEffort => toLocalStringRu(workEffort),
      },
      {
        title: (
          <TableHeader>
            Сумма в год на материалы подрядом, руб. без НДС
          </TableHeader>
        ),
        dataIndex: `materialSum`,
        width: '180px',
        render: materialSum =>
          toLocalStringRu(materialSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader width="200px">
            Затраты за услуги выполнения работы подрядным способом без запасных
            частей и материалов, руб. без НДС
          </TableHeader>
        ),
        dataIndex: `materialLessSum`,
        width: '220px',
        render: materialLessSum =>
          toLocalStringRu(materialLessSum, {minimumFractionDigits: 2}),
      },
      {
        title: (
          <TableHeader>Всего планируемые затраты, руб. без НДС</TableHeader>
        ),
        dataIndex: `sum`,
        width: '200px',
        render: sum => toLocalStringRu(sum, {minimumFractionDigits: 2}),
      },
    ],
    filter: {},
    modalVisible: false,
    openPeriod: null,
  };
  print = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'print',
      });
      await maintenanceOperationContractCalculationApi.print(vehiclePlanId);
    } catch (error) {
      notification.error({message: 'Не удалось выполнить'});
    } finally {
      notification.close('print');
    }
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  render() {
    const {vehiclePlanId} = this.props;
    const {
      columns,
      loading,
      data,
      vehiclePlan,
      modalVisible,
      dataRow,
      monthIndex,
      cognosData,
    } = this.state;
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : Math.abs((data[data.length - 1]?.sum || 0) - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'Техобслуживание',
          passTab: true,
        })}
        <Section>
          <InfoBlock
            links={{
              'Модели': '/vehicle-models/self',
              'Затраты на ТО': '/admin/maintenance-cost-allocation/',
              'Стоимость нормо-часа': '/admin/maintenance-operation-price',
            }}
          />
          <FilterWrapper>
            <Filter
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              filter={this.state.filter}
              vehiclePlanId={vehiclePlanId}
              vehicleType
            />
          </FilterWrapper>
          <AntTable
            loading={loading}
            data={[...data]}
            columns={columns}
            bordered
            scroll={{
              x: 6000,
              y: 'calc(100vh - 540px)',
            }}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <MaintenanceEditingModal
            handleSave={this.onSave}
            handleCancel={this.onCancel}
            visible={modalVisible}
            data={dataRow}
            monthIndex={monthIndex}
          />
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={
                  data.length ? data[data.length - 1]?.sum : 0
                }
                controllerName="maintenanceOperationContractCalculation"
                vehiclePlanId={vehiclePlanId}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            {canEditBudget(this.props.userAccess) && (
              <ButtonsRow>
                {this.isDraft() && (
                  <>
                    {!isCognosStatus && (
                      <Button type="primary" onClick={this.calculate}>
                        Рассчитать
                      </Button>
                    )}
                    <Button
                      style={{margin: '0 16px'}}
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.calculationDone)
                      }
                      disabled={approveButtonIsDisabled}
                      title={
                        approveButtonIsDisabled
                          ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                          : undefined
                      }
                    >
                      Утвердить
                    </Button>
                  </>
                )}
                {!planApproved &&
                  !this.isDraft() &&
                  vehiclePlan?.status !==
                  vehiclePlanStatusEnum.approvedAfterCognos && (
                    <>
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(calculationStatusEnum.draft)
                        }
                        style={{marginRight: '16px'}}
                      >
                        Редактировать
                      </Button>
                    </>
                  )}
                {data.length > 0 && (
                  <Button onClick={this.print}>Печать</Button>
                )}
              </ButtonsRow>
            )}
          </Wrapper>
        </Section>
      </>
    );
  }
};

export default withUserAccess(List);
