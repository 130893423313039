// @flow
import React, {type Node, useCallback, useContext, useEffect, useReducer, useState} from 'react';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import PageHeader, {type PageHeaderProps} from './PageHeader';
import {type Column} from '../../../ui/Table';
import {FilterContext, PageContext} from './PageContext';
import {notificationLoading} from '../../../Notifications';

import {Icon, Table} from '../../../ui';
import {Section} from '../../../layout';

import type {CommonApiMethod} from '../../../../lib/commonApi';

type ApiListProps = {
  // загрузка данных листа
  onFetch?: Function,
  // удаление записи
  onDelete?: Function,
  // добавление записи
  onAdd?: Function
};

type TableListProps = {
  // событие выбор записи
  onSelect?: Function,

  // колонки
  columns: Column[],

  // data
  data?: any[],

  // настройки нумератора страниц
  pageSize?: number,

  // добавляет столбец для удаления записей
  canDelete?: boolean,

  // замена key, в качестве значению указывается имя уникального столбца, по дефолту поле id
  rowKey?: string,

  // дополнительные параметры для отображении таблицы
  params?: any
};

type ListPageProps = {
  // api
  crud?: CommonApiMethod,

  // пропсы заголовка страницы
  pageHeaderProps: PageHeaderProps,

  // компонент подзаголовка, если нужно что-то добавить между заголовком и таблицей
  subHeader?: Node,

  // фильтры для манипуляции с данными
  filter?: any => any | Node,

  // Свойства таблицы
  tableListProps: TableListProps,
  oldCrud?: ApiListProps,
  // повторно подтянуть данные
  reFetch?: boolean,
  // параметр манипуляции данными на сервере
  filterMixin?: {}
};

// Состояние для служебных колонок
type reducerProps = {
  type: string,
  columns?: Column[],
  dataIndex?: string
};

// обработчик состояния служебных колонок
const columnsReducer = (state: Column[], action: reducerProps) => {
  switch (action.type) {
    case 'add':
      return [...state, ...(action.columns ?? [])];
    case 'delete':
      return state.filter(col => col.dataIndex !== action.dataIndex);
    default:
      throw Error('Unexpected action');
  }
};

export default (props: $Exact<ListPageProps>) => {
  const page = useContext(PageContext);
  const filter = useContext(FilterContext);

  const [totalCount, setTotalCount] = useState(0);

  const [data, setData] = useState(props.tableListProps.data ?? []);
  const [mixinColumnsState, dispatchMixinColumns] = useReducer(
    columnsReducer,
    [],
  );
  const [loading, setLoading] = useState(false);

  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    if (deleting) {
      notificationLoading({
        message: 'Удаление записи...',
        key: 'deleting',
      });
    } else {
      notification.close('deleting');
    }
    return () => {
      notification.close('deleting');
    };
  }, [deleting]);

  const handleFetch = useCallback(async () => {
    setLoading(true);
    try {
      const p = {page, ...props.filterMixin, ...filter};
      const data =
        (await props.crud?.fetch(p)) || (await props.oldCrud?.onFetch?.(p));
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      setData(data.data);
      setTotalCount(data.totalCount);
    } catch (error) {
      notification.error({message: 'Не удалось запросить данные'});
    } finally {
      setLoading(false);
    }
  }, [filter, page, props.crud, props.filterMixin, props.oldCrud]);

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    if (props.reFetch) {
      handleFetch();
    }
  }, [props.reFetch, handleFetch]);

  const handleRowDelete = useCallback(
    async (id: number, record: any) => {
      setDeleting(true);
      try {
        if (props.oldCrud?.onDelete === undefined) {
          await props.crud?.delete(id);
        } else {
          await props.oldCrud?.onDelete?.(id, record);
        }
        await handleFetch();
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
      setDeleting(false);
    },
    [handleFetch, props.crud, props.oldCrud],
  );

  useEffect(() => {
    if (props.tableListProps.canDelete) {
      dispatchMixinColumns({
        type: 'add',
        columns: [
          {
            stopPropagation: true,
            width: 20,
            dataIndex: 'id',
            render: (id: number, record: any) => (
              <Popconfirm
                title="Вы уверены, что хотите удалить запись?"
                onConfirm={async () => await handleRowDelete(id, record)}
              >
                <Icon type="x" />
              </Popconfirm>
            ),
          },
        ],
      });
    } else {
      dispatchMixinColumns({type: 'delete', dataIndex: 'id'});
    }
  }, [handleRowDelete, props.tableListProps.canDelete]);

  const handleRowClick = async (id: number, record: any) => {
    await props.tableListProps.onSelect?.(id, record);
  };

  const Filter = useCallback(() => {
    const applyFilter = async () => {
      await handleFetch();
    };

    const cleanFilter = async () => {
      await handleFetch();
    };

    let afilter;
    if (props.filter) {
      if (React.isValidElement(props.filter)) {
        // $FlowFixMe проверка на undefined есть
        afilter = React.cloneElement(props.filter, {
          cleanFilter,
          applyFilter,
          filter,
        });
      } else {
        afilter = props.filter?.({cleanFilter, applyFilter, filter});
      }
    }
    return afilter || null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, handleFetch, props.filter]);

  return (
    <>
      <PageHeader {...props.pageHeaderProps} />
      {props.subHeader}
      <Section>
        <Filter />
        <Table
          onRow={record => ({
            onClick: () => handleRowClick(record.id, record),
          })}
          loading={loading}
          data={data}
          columns={[...props.tableListProps.columns, ...mixinColumnsState]}
          pagination={{
            page,
            totalCount,
            pageSize: props.tableListProps.pageSize || 20,
          }}
          rowKey={props.tableListProps.rowKey ?? 'id'}
          {...props.tableListProps.params}
        />
      </Section>
    </>
  );
};
