// @flow

import type {Dispatch} from 'redux';
import type {Action} from './redux';
import {maintenanceOperationApi} from '../lib/api';
import {MaintenanceOperation} from '../lib/types';

export const SET_MAINTENANCE_OPERATION_GROUP =
  'vehicles/maintenance-operation-group/set';
const CLEAR_MAINTENANCE_OPERATION_GROUP =
  'vehicles/maintenance-operation-group/clear';

const initialState: ?MaintenanceOperation = null;

export const replaceMaintenanceOperationGroupInTree = (
  node: MaintenanceOperation,
  maintenanceOperationGroup: MaintenanceOperation,
): MaintenanceOperation => {
  let currentNode = {...node};
  if (node.id === maintenanceOperationGroup.id) {
    currentNode = {
      ...currentNode,
      ...maintenanceOperationGroup,
    };
  }
  let children = currentNode.children ? [...currentNode.children] : [];
  children = children.map<MaintenanceOperationGroup>(
    (child: MaintenanceOperationGroup) =>
      replaceMaintenanceOperationGroupInTree(child, maintenanceOperationGroup),
  );
  return {
    ...currentNode,
    children: children.length ? children : [],
  };
};

// eslint-disable-next-line
const addMaintenanceOperationGroupToTree = (
  node: MaintenanceOperation,
  parentId: number,
  maintenanceOperationGroup: MaintenanceOperation,
) => {
  let children = node.children ? [...node.children] : [];
  children = children.map(child =>
    addMaintenanceOperationGroupToTree(
      child,
      parentId,
      maintenanceOperationGroup,
    ),
  );
  if (node.id === parentId) {
    children = [...children, maintenanceOperationGroup];
  }
  return {
    ...node,
    children: children.length ? children : null,
  };
};

// eslint-disable-next-line
const removeFromTree = (
  node: MaintenanceOperation,
  maintenanceOperationGroupId: number,
) => {
  let children = node.children ? [...node.children] : [];
  children = children
  .filter(child => child.id !== maintenanceOperationGroupId)
  .map(child => removeFromTree(child, maintenanceOperationGroupId));
  return {
    ...node,
    children: children.length ? children : null,
  };
};

const reducer = (
  state: ?MaintenanceOperation = initialState,
  {type, payload}: Action,
): ?MaintenanceOperation => {
  switch (type) {
    case SET_MAINTENANCE_OPERATION_GROUP:
      return payload;
    case CLEAR_MAINTENANCE_OPERATION_GROUP:
      return initialState;
    default:
      return state;
  }
};

export const clear = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAR_MAINTENANCE_OPERATION_GROUP,
  });

export const fetchMaintenanceOperationGroup: Function = (
  id: number,
): Function => async (dispatch: Dispatch): Promise<void> => {
  const maintenanceOperationGroup = await maintenanceOperationApi.fetchMaintenanceOperation(
    id,
  );
  dispatch({
    type: SET_MAINTENANCE_OPERATION_GROUP,
    payload: maintenanceOperationGroup,
  });
};

export default reducer;
