// @flow

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Table from 'antd/lib/table';
import notification from 'antd/lib/notification';

import { formatDateTimeToString, applyMaskToValue } from '../../lib/helpers';
import { vehicleTypes, vehicleItemHistoryTypeEnum } from '../../lib/enum';
import { Pagination } from './../../components/ui';
import { formatLicensePlateMask } from '../../components/masked-inputs/LicensePlateInput';
import { Link } from '@reach/router';

const StyledPagination = styled(Pagination)`
  padding: 10px;
`;

type Props = {
  equipmentId: number,
  fetchHistory: Function,
  needUpdateList: boolean,
};

type State = {
  totalCount: number,
  pageSize: number,
  page: number,
  historyList: Object[]
};

/** Вывод списка истории закреплений оборудования */
class HistoryTable extends Component<Props, State> {
  state = {
    totalCount: 0,
    pageSize: 10,
    page: 1,
    historyList: []
  };

  async componentDidMount() {
    await this.fetchHistory(1);
  }

  componentDidUpdate(prevProps: Props): * {
    if (prevProps.needUpdateList !== this.props.needUpdateList && this.props.needUpdateList) {
      this.fetchHistory(this.state.page);
    }
  }

  fetchHistory = async (page: number) => {
    try {
      const { pageSize } = this.state;
      const historyList = await this.props.fetchHistory(
        { page, pageSize },
        parseInt(this.props.equipmentId)
      );
      this.setState({
        historyList: historyList.data || [],
        totalCount: historyList.totalCount,
        page
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при получении истории',
        description: err && err.message
      });
    }
  };

  columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      render: (text: string) => formatDateTimeToString(text, 'DD.MM.YYYY')
    },
    {
      title: 'Событие',
      dataIndex: 'type',
      render: (type: string) => vehicleItemHistoryTypeEnum[type]
    },
    {
      title: 'Дата события',
      dataIndex: 'userDate',
      render: (text: string) => text ? formatDateTimeToString(text, 'DD.MM.YYYY') : "-"
    },
    {
      title: 'Тип ',
      dataIndex: 'vehicle.vehicleModel.type',
      render: (type: string) => vehicleTypes[type]
    },
    { title: 'Марка ', dataIndex: 'vehicle.vehicleModel.brandName' },
    { title: 'Модель ', dataIndex: 'vehicle.vehicleModel.name' },
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate',
      render: (licensePlate: ?string, history: any) =>
        licensePlate && (
          <Link to={`/vehicles/${history.vehicle.id}`}>
            {applyMaskToValue(licensePlate, formatLicensePlateMask)}
          </Link>
        )
    }
  ];

  render() {
    const { historyList, totalCount, pageSize } = this.state;
    return (
      <Fragment>
        <Table
          dataSource={historyList.map(item => ({
            ...item,
            key: item.installDate
          }))}
          columns={this.columns}
          pagination={false}
        />
        <StyledPagination
          totalCount={totalCount}
          pageSize={pageSize}
          fetch={this.fetchHistory}
          currentPage={this.state.page}
        />
      </Fragment>
    );
  }
}

export default HistoryTable;
