// @flow
import Button from 'antd/lib/button';

import notification from 'antd/lib/notification';
import React from 'react';
import {notificationError, notificationLoading} from '../../../../../components/Notifications';
import {accessTypeEnum, calculationStatusEnum, entityStatusEnum, vehiclePlanStatusEnum} from '../../../../../lib/enum';
import {plus, toLocalStringRu} from '../../../../../lib/helpers';

import type {
  CalculationStatus,
  CurrentRepairCalculation,
  RepairBudget,
  UserAccess,
  VehiclePlan,
} from '../../../../../lib/types';

import type {VehicleListFilterParams} from '../../components/FilterVehicleList';

import {COGNOS_DELTA, COLUMNS, filteredData, MONTH} from '../../lib';

import {headerPanel} from '../components/Common';
import {Section} from './../../../../../components/layout';

import {AntTable, ButtonsRow} from './../../../../../components/ui';
import {
  currentRepairCalculationApi,
  downloadRequestWithToken,
  repairBudgetApi,
  vehiclePlanApi,
} from './../../../../../lib/api';
import Filter from './../../components/FilterVehicleList';
import {canEditBudget} from '../../accessRight';
import {withUserAccess} from '../../../../withUserAccess';
import styled from 'styled-components';
import TotalBlock from '../../Budget/components/TotalBlock';
import {CurrentRepairEditingModal} from './CurrentRepairEditingModal';
import InfoBlock from '../../components/InfoBlock';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../../contractVehicle/fixed/lib';
import Select from 'antd/lib/select';
import moment from 'moment/moment';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const {Option} = Select;

type Props = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = {
  data: CurrentRepairCalculation[],
  loading: boolean,
  filter: VehicleListFilterParams,
  vehiclePlan?: VehiclePlan,
  budgetRepair?: RepairBudget,
  modalVisible: boolean,
  dataRow: ?CurrentRepairCalculation,
  monthIndex: ?number,
  cognosData: ?number,
  openPeriod?: {
    start: string,
    end: string,
  },
};
const Card = class extends React.Component<Props, State> {
  state = {
    data: [],
    filter: {},
    loading: false,
    vehiclePlan: undefined,
    budgetRepair: undefined,
    modalVisible: false,
    openPeriod: null,
  };
  columns = [
    ...COLUMNS,
    {
      title: 'Вид нормо-часа',
      dataIndex: `workingHourPriceType`,
      width: '300px',
      render: (value, record) => {
        if (this.isDraft() && this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvementAfterCognos &&
                              this.state.vehiclePlan.status !== vehiclePlanStatusEnum.approvedAfterCognos) {
          return Number.isInteger(record.id) && (
            <Select
              size="small"
              value={value}
              onChange={async value => {
                const newRecord = {...record, workingHourPriceType: value}
                try {
                  await currentRepairCalculationApi.update(newRecord);
                  await this.getData();
                } catch (e) {
                  notificationError(e);
                }
              }}
            >
              <Option key="0" value="contractMethod">Подрядный метод</Option>
              <Option key="1" value="contractMethodOnTheGo">Подрядный метод на выезде</Option>
            </Select>
          );
        }

        const workingHourPriceTypeText = {
          "contractMethod": "Подрядный метод",
          "contractMethodOnTheGo": "Подрядный метод на выезде",
        }

        return <span>{workingHourPriceTypeText[value]}</span>;
      },
    },
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      width: 150,
      dataIndex: `months[${monthIndex}].cost`,
      render: (sum: number) =>
        toLocalStringRu(sum, {minimumFractionDigits: 2}),
      onCell: monthData => this.onCell(monthData, monthIndex),
    })),
    {
      title: 'Итого стоимость',
      children: [
        {
          title: 'Материалы',
          children: [
            {
              title: 'Хоз. способ',
              width: 150,
              dataIndex: 'internalMaterialsSum',
              render: (internalMaterialsSum: number) =>
                toLocalStringRu(internalMaterialsSum, {
                  minimumFractionDigits: 2,
                }),
            },
            {
              title: 'Подряд',
              width: 150,
              dataIndex: 'contractMaterialsSum',
              render: (contractMaterialsSum: number) =>
                toLocalStringRu(contractMaterialsSum, {
                  minimumFractionDigits: 2,
                }),
            },
            {
              title: 'Общая',
              width: 150,
              dataIndex: 'fullMaterialsSum',
              render: (fullMaterialsSum: number) =>
                toLocalStringRu(fullMaterialsSum, {minimumFractionDigits: 2}),
            },
          ],
        },
        {
          title: 'Работы',
          children: [
            {
              title: 'Хоз. способ',
              width: 150,
              dataIndex: 'internalOperationsSum',
              render: (internalOperationsSum: number) =>
                toLocalStringRu(internalOperationsSum, {
                  minimumFractionDigits: 2,
                }),
            },
            {
              title: 'Подряд',
              width: 150,
              dataIndex: 'contractOperationsSum',
              render: (contractOperationsSum: number) =>
                toLocalStringRu(contractOperationsSum, {
                  minimumFractionDigits: 2,
                }),
            },
            {
              title: 'Общая',
              width: 150,
              dataIndex: 'fullOperationsSum',
              render: (fullOperationsSum: number) =>
                toLocalStringRu(fullOperationsSum, {minimumFractionDigits: 2}),
            },
          ],
        },
      ],
    },
    {
      title: 'Итого',
      width: 150,
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, {minimumFractionDigits: 2}),
    },
  ];
  async componentWillMount() {
    this.getData();
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }
  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };
  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };
  fetch = async () => {
    const {vehiclePlanId} = this.props;

    const itogCalculation = (data: any) => {
      const itog = {
        months: Array(12)
        .fill()
        .map(u => ({cost: 0})),
        fullMaterialsSum: 0,
        fullOperationsSum: 0,
        sum: 0,
      };
      data.forEach((item: any) => {
        itog.sum = plus(item.sum || 0, itog.sum);
        itog.fullMaterialsSum = plus(
          item.fullMaterialsSum || 0,
          itog.fullMaterialsSum,
        );
        itog.fullOperationsSum = plus(
          item.fullOperationsSum || 0,
          itog.fullOperationsSum,
        );
        item.months.forEach((month: any, index) => {
          itog.months[index].cost = plus(
            itog.months[index].cost,
            month.cost || 0,
          );
        });
      });
      return itog;
    };

    try {
      let {data} = await currentRepairCalculationApi.fetch({
        vehiclePlanId,
        pageSize: undefined,
        page: undefined,
      });

      if (data.length) {
        data = [...data, itogCalculation(data)];
      }

      const budgetRepair = await repairBudgetApi.getByVehiclePlan(
        vehiclePlanId,
      );

      this.setState({data, budgetRepair});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };
  applyFilter = (filter: VehicleListFilterParams) => this.setState({filter});
  cleanFilter = () => this.setState({filter: {}});
  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await currentRepairCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  calculate = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await currentRepairCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  isDraft = () =>
    this.state.vehiclePlan?.currentRepairCalculationStatus ===
    calculationStatusEnum.draft;
  onCell = (calculation: CurrentRepairCalculation, monthIndex: number) => {
    return {
      onClick: () => {
        if (!this.canEdit()) {
          return;
        }
        const {openPeriod, vehiclePlan} = this.state;
        const date = moment().month(monthIndex).format();
        if (vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos && calculation.id &&
            dateIsIncludeInOpenPeriod(openPeriod, date)) {
          this.setState({
            dataRow: {...calculation},
            monthIndex: monthIndex,
            modalVisible: true,
          });
        }
      },
    };
  };
  handleSave = async (monthData: CurrentRepairCalculation) => {
    const {rowIndex = 0} = this.state;
    this.setState({loading: true});
    try {
      if (monthData && Number.isInteger(rowIndex)) {
        await currentRepairCalculationApi.updateMonth(monthData);
        await this.fetch();
      }
    } catch (error) {
      notification.warning({
        message: 'Не удалось обновить данные',
        description: error.message,
      });
    } finally {
      this.setState({
        loading: false,
        modalVisible: false,
        dataRow: undefined,
        monthIndex: undefined,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      modalVisible: false,
      dataRow: undefined,
      monthIndex: undefined,
    });
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формируется печатная форма',
        key: 'printing',
      });
      await downloadRequestWithToken(
        `/currentRepairCalculation/print?vehiclePlanId=${this.props.vehiclePlanId}`,
      );
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title,
      });
    } finally {
      notification.close('printing');
    }
  };

  render() {
    const {vehiclePlanId} = this.props;
    const {
      data,
      loading,
      filter,
      vehiclePlan,
      budgetRepair,
      modalVisible,
      dataRow,
      monthIndex,
      cognosData,
    } = this.state;
    const filtered = filteredData(data, filter);
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const budgetRepairApproved =
      budgetRepair?.status === entityStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : Math.abs((data[data.length - 1]?.sum || 0) - cognosData) > COGNOS_DELTA;

    return (
      <>
        {headerPanel({title: 'Текущий ремонт', vehiclePlanId, vehiclePlan})}
        <Section>
          <InfoBlock
            links={{
              'Распределение затрат на ТР':
                '/admin/maintenance-cost-allocation-tr/',
              'Стоимость ТР': '/admin/current-maintenance-cost/',
              'Стоимость нормо-часа': '/admin/maintenance-operation-price',
            }}
          />
          <div style={{padding: '16px 16px 0px'}}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              vehiclePlanId={vehiclePlanId}
            />
          </div>
          <AntTable
            data={filtered}
            bordered
            loading={loading}
            pagination={{pageSize: 50}}
            columns={this.columns}
            scroll={{
              y: 'calc(100vh - 450px)',
              x: '3500px',
            }}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <CurrentRepairEditingModal
            visible={modalVisible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            data={dataRow}
            monthIndex={monthIndex}
          />
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={
                  data.length ? data[data.length - 1]?.sum : 0
                }
                controllerName="currentRepairCalculation"
                vehiclePlanId={vehiclePlanId}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            {canEditBudget(this.props.userAccess) && (
              <ButtonsRow>
                <Button
                  onClick={this.handlePrint}
                  style={{marginRight: '10px'}}
                >
                  Печать
                </Button>
                {this.isDraft() && (
                  <>
                    {!isCognosStatus && (
                      <Button type="primary" onClick={this.calculate}>
                        Рассчитать
                      </Button>
                    )}
                    <Button
                      style={{marginLeft: '16px'}}
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.calculationDone)
                      }
                      disabled={approveButtonIsDisabled}
                      title={
                        approveButtonIsDisabled
                          ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                          : undefined
                      }
                    >
                      Утвердить
                    </Button>
                  </>
                )}
                {!budgetRepairApproved && !planApproved && !this.isDraft() && (
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStatus(calculationStatusEnum.draft)
                      }
                    >
                      Редактировать
                    </Button>
                  </>
                )}
              </ButtonsRow>
            )}
          </Wrapper>
        </Section>
      </>
    );
  }
};

export default withUserAccess(Card);
