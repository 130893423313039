// @flow
import type {
  CalculationStatus,
  FuelAndOilCalculation,
  FuelAndOilCalculationMonth,
  FuelAndOilCalculationType
} from '../types';

import CommonApi from '../commonApi';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour
} from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  fuelAndOilCalculation: FuelAndOilCalculation
): FuelAndOilCalculation => ({
  ...fuelAndOilCalculation,
  sum: convertFromRubToKop(fuelAndOilCalculation.sum),
  oilsSum: convertFromKopToRub(fuelAndOilCalculation.oilsSum),
  fuelCostSum: convertFromKopToRub(fuelAndOilCalculation.fuelCostSum),
  months: fuelAndOilCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  fuelAndOilCalculation: FuelAndOilCalculation
): FuelAndOilCalculation => ({
  ...fuelAndOilCalculation,
  sum: convertFromKopToRub(fuelAndOilCalculation.sum),
  fuelCostSum: convertFromKopToRub(fuelAndOilCalculation.fuelCostSum),
  oilsSum: convertFromKopToRub(fuelAndOilCalculation.oilsSum),
  months: fuelAndOilCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  fuelAndOilCalculationMonth: FuelAndOilCalculationMonth
): FuelAndOilCalculationMonth => ({
  ...fuelAndOilCalculationMonth,
  fuel: {
    ...fuelAndOilCalculationMonth.fuel,
    cost: convertFromRubToKop(fuelAndOilCalculationMonth.fuel?.cost)
  },
  oils:
    fuelAndOilCalculationMonth.oils === null
      ? fuelAndOilCalculationMonth.oils
      : fuelAndOilCalculationMonth.oils?.map(
          (el: FuelAndOilCalculationType) => ({
            ...el,
            cost: convertFromRubToKop(el.cost)
          })
        ),
  oilsSum: convertFromRubToKop(fuelAndOilCalculationMonth.oilsSum),
  correctedKilometrage: convertFromKmToM(
    fuelAndOilCalculationMonth.correctedKilometrage
  ),
  correctedWorkHours: convertFromHourToSecond(
    fuelAndOilCalculationMonth.correctedWorkHours
  )
});

export const convertDataFromServerMonth = (
  fuelAndOilCalculationMonth: FuelAndOilCalculationMonth
): FuelAndOilCalculationMonth => ({
  ...fuelAndOilCalculationMonth,
  fuel: {
    ...fuelAndOilCalculationMonth.fuel,
    cost: convertFromKopToRub(fuelAndOilCalculationMonth.fuel?.cost)
  },
  oils: fuelAndOilCalculationMonth.oils?.map(
    (el: FuelAndOilCalculationType) => ({
      ...el,
      cost: convertFromKopToRub(el.cost)
    })
  ),
  oilsSum: convertFromKopToRub(fuelAndOilCalculationMonth.oilsSum),
  correctedKilometrage: convertFromMToKm(
    fuelAndOilCalculationMonth.correctedKilometrage
  ),
  correctedWorkHours: convertFromSecondToHour(
    fuelAndOilCalculationMonth.correctedWorkHours
  )
});

const basedUrl = 'fuelAndOilCalculation';
const fuelAndOilCalculation = new CommonApi<FuelAndOilCalculation>(
  basedUrl,
  'расчет гсм',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  data: FuelAndOilCalculation
): Promise<FuelAndOilCalculation> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations`,
    data
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось отредактировать`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number) => {
  const printed = await downloadRequestWithToken(
    `/${basedUrl}/print?vehiclePlanId=${vehiclePlanId}`
  );
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async (monthData: FuelAndOilCalculationMonth) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/correct`,
    convertDataToServerMonth(monthData)
  );
  if (updated) {
    return convertDataFromServerMonth(updated);
  }
  throw new Error(`Не удалось обновить данные`);
};

export default {
  ...fuelAndOilCalculation,
  print,
  updateCalculations,
  calculate,
  changeStatus,
  updateMonth
};
