// @flow

import React, {PureComponent} from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';
import {Link} from '@reach/router';
import qs from 'query-string';

import type {Brand, ListState} from '../../lib/types';
import {Icon, Operations, Table} from './../../components/ui';
import {Section} from './../../components/layout';
import Header from '../../components/layout/Header';
import type {FetchListParams} from '../../lib/api';
import {brandApi} from '../../lib/api';
import {getListInitialState, navigate, setQueryParams} from '../../lib/helpers';
import {notificationLoading} from '../../components/Notifications';

type Props = {
  location: Location & { state: { page: number } }
};

type State = ListState<Brand>;

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class BrandList extends PureComponent<Props, State> {
  static defaultProps = {
    location: {},
  };
  state = getListInitialState();
  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'operations',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, brand: Brand) => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => this.deleteBrand(brand.id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      ),
    },
  ];
  fetchBrands = async (page: number = 1, params: FetchListParams<any> = {}) => {
    this.setState({loading: true});
    const {data, totalCount} = await brandApi.fetchBrands({
      page,
      ...params,
      orderBy: 'id',
      byDescending: false,
    });
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };
  async deleteBrand(id: number) {
    const {page} = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await brandApi.deleteBrand(id);
      notification.success({
        message: 'Успешное удаление',
        description: `Марка с идентификатором ${id} была успешно удалена`,
      });
      await this.fetchBrands(page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }
  handleRowClick = (id: number) => navigate(`/admin/brands/${id}`);

  async componentDidMount() {
    const {page} = qs.parse(window.location.search);
    await this.fetchBrands(page);
  }

  render() {
    const {location} = this.props;
    const {data, totalCount, pageSize, page, loading} = this.state;
    return (
      <>
        <Header
          left={<h1>Марки</h1>}
          right={
            <Link to="/admin/brands/new">
              <Button type="primary" data-cy="addBrand">
                Создать
              </Button>
            </Link>
          }
        />
        <Section>
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            columns={this.columns}
            data={data.map(brand => ({
              ...brand,
              key: brand.id,
            }))}
            fetch={this.fetchBrands}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default BrandList;
