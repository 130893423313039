// @flow
import React from 'react';

import type { PropsHeader } from '../../lib';
import { commonHeaderPanel } from '../../lib';

import RepairTab from './Tab';

export const headerPanel = (props: PropsHeader) => {
  const { title, vehiclePlanId, vehiclePlan } = props;
  return (
    <>
      {commonHeaderPanel({ title, vehiclePlanId, vehiclePlan })}
      <RepairTab
        vehiclePlanId={props.vehiclePlanId}
        vehiclePlan={vehiclePlan}
      />
    </>
  );
};
