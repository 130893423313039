// @flow
import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import type {FormFieldType} from '../../../components/Form';
import Form from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import type {ModelMatches} from '../../../lib/types';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  modelMatches: ?ModelMatches,
  onCancel: Function,
  onSubmit: Function
};

export default ({modelMatches, onCancel, onSubmit}: Props) => (
  <Form initialValues={modelMatches} onSubmit={onSubmit}>
    {(
      FormField: FormFieldType,
      {
        handleSubmit,
        values,
        dirty,
        isSubmitting,
      }: FormikProps,
    ) => (
      <form onSubmit={handleSubmit}>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Марка" fast name="brandName" required>
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Модель" fast name="modelName" required>
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
          </Grid>
        </StyledSection>
        <Footer>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {values.id ? 'Сохранить' : 'Создать'}
          </Button>
          <CancelButton dirty={dirty} onClick={onCancel}>
            Отменить
          </CancelButton>
        </Footer>
      </form>
    )}
  </Form>
);
