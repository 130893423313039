// @flow
import React from 'react';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from './../../../../../../../components';
import OrgUnitSelect from './../../../../../../../components/OrgUnitSelect';
import type {FilterChildrenParams} from './../../../../../../../components/hoc/Filter';
import Field from './../../../../../../../components/card/Field';
import {VehicleOwnerTypesEnum} from '../../../../../../../lib/types/vehicleModel';

const {
  VehicleModelSelect,
  ExpenseDirectionTreeSelect,
  ContractVehicleSelect,
} = Selects;

export type FixedRegistBudgetBudgetFilterParams = {
  'contractVehicle.vehicle.vehicleModel.id'?: number,
  nodeId?: number,
  contractVehicleId?: number,
  'regulationLimit.regulationLimitGroup.expenseDirectionId'?: number
};

type Props = {
  filter: FixedRegistBudgetBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({filter, cleanFilter, applyFilter}: Props) => (
  <FilterContainer>
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
      additionalFilter
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter,
        showAdditional,
        handleAdditional,
      }: FilterChildrenParams<FixedRegistBudgetBudgetFilterParams>) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                placeholder="Все модели ТС"
                size="small"
                value={values['contractVehicle.vehicle.vehicleModel.id']}
                onChange={value =>
                  changeValue('contractVehicle.vehicle.vehicleModel.id', value)
                }
                type={VehicleOwnerTypesEnum.contract}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ContractVehicleSelect
                placeholder="Все ТС"
                size="small"
                value={values.contractVehicleId}
                onChange={value => changeValue('contractVehicleId', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ExpenseDirectionTreeSelect
                placeholder="Направление расходов"
                size="small"
                value={
                  values[
                    'regulationLimit.regulationLimitGroup.expenseDirectionId'
                    ]
                }
                onChange={value =>
                  changeValue(
                    'regulationLimit.regulationLimitGroup.expenseDirectionId',
                    value,
                  )
                }
              />
            </Field>
          </FilterItem>

          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                placeholder="Закрепление"
                size="small"
                onChange={value => changeValue('nodeId', value)}
                value={values.nodeId}
                isFilter={true}
              />
            </Field>
          </FilterItem>

          <FilterButtonsContainer
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
            handleAdditional={handleAdditional}
            showAdditional={showAdditional}
          />
        </>
      )}
    </Filter>
  </FilterContainer>
);
