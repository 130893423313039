// @flow

import React, {type ComponentType, useEffect, useState} from 'react';
import moment from 'moment';
import {FormikProps} from 'formik';

import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import notification from 'antd/lib/notification';

import type {STSType, Vehicle} from './../../../../../lib/types';
import {formatDateTimeToISOString} from './../../../../../lib/helpers';
import {ownerTypes, serviceTypeEnum, STSEnum} from './../../../../../lib/enum';
import {
  expertiseServiceGpmApi,
  inspectionGibddApi,
  inspectionGtnApi,
  measuringDeviceCertificationApi,
  vehicleApi,
} from './../../../../../lib/api';

import Selects from './../../../../../components/selects';
import type {FormFieldProps} from './../../../../../components/Form';
import Form from './../../../../../components/Form';
import Grid, {GridItem} from './../../../../../components/layout/Grid';

import {gps, measuringDevices, toGibdd, toGpmAndExpertise, toGtn} from './../../lib';

const { VehicleModelSelect, YesNoSelect } = Selects;
type PlannedVehicle = {
  stsType: STSType,
  usingNodeId: number,
  orgUnitId: number,
  vehicleModelId: number,
  // Дата принятия тс в эксплуатацию
  commissioningDate: string,
  // Дата следующего ТО ГИБДД
  inspectionsGibddNextAct: string,
  // Дата следующего ТО ГТН
  inspectionsGtndNextAct: string,
  // Дата следующего ТО ГПМ
  inspectionsGpmNextAct: string,
  // Дата следующего эспертизы ГПМ
  inspectionsGpmExpertisedNextAct: string,
  // Дата следующего Мониторинга ТС
  hasGps: boolean,
  // Дата следующий тарировки
  measuringDeviceCertificationNextAct: string
};
type Props = {
  visible: boolean,
  vehicle?: ?Vehicle,
  orgUnitId?: number,
  onCancel: Function,
  addVehicle: Function
};
export default (props: Props) => {
  const { visible, onCancel, orgUnitId, addVehicle } = props;
  const [vehicleType, setVehicleType] = useState(undefined);
  const [lastMaintenance, setLastMeintenance] = useState(null);

  useEffect(() => {
    if (props.vehicle) {
      getData(props.vehicle);
    } else {
      setVehicleType(undefined);
      setLastMeintenance(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.vehicle]);

  const getData = async (vehicle: Vehicle) => {
    setVehicleType(props.vehicle?.vehicleModel.type);
    const lastMaintenance = await vehicleApi.getLastMaintenance(vehicle.id);
    setLastMeintenance(lastMaintenance);
  };

  const onSubmit = async (plannedVehicle: PlannedVehicle) => {
    try {
      const vehicle = props.vehicle
        ? await vehicleApi.updateVehicle({
            ...props.vehicle,
            // $FlowFixMe
            ...plannedVehicle
          })
        : // $FlowFixMe
          await vehicleApi.addVehicle(plannedVehicle);

      if (plannedVehicle.inspectionsGibddNextAct) {
        if (lastMaintenance?.lastInspectionGibdd) {
          await inspectionGibddApi.updateInspectionGibdd({
            ...lastMaintenance.lastInspectionGibdd,
            nextActDate: plannedVehicle.inspectionsGibddNextAct
          });
        } else {
          await inspectionGibddApi.addInspectionGibdd({
            vehicleId: vehicle.id,
            nextActDate: plannedVehicle.inspectionsGibddNextAct
          });
        }
      }

      if (plannedVehicle.inspectionsGtndNextAct) {
        if (lastMaintenance?.lastInspectionGtn) {
          await inspectionGtnApi.updateInspectionGtn({
            ...lastMaintenance.lastInspectionGtn,
            nextActDate: plannedVehicle.inspectionsGtndNextAct
          });
        } else {
          await inspectionGtnApi.addInspectionGtn({
            vehicleId: vehicle.id,
            nextActDate: plannedVehicle.inspectionsGtndNextAct
          });
        }
      }

      if (plannedVehicle.inspectionsGpmNextAct) {
        if (lastMaintenance?.lastExpertiseServiceGpmMaintenance) {
          await expertiseServiceGpmApi.updateExpertiseServiceGpm({
            ...lastMaintenance.lastExpertiseServiceGpmMaintenance,
            nextActDate: plannedVehicle.inspectionsGpmNextAct
          });
        } else {
          await expertiseServiceGpmApi.addExpertiseServiceGpm({
            vehicleId: vehicle.id,
            nextActDate: plannedVehicle.inspectionsGpmNextAct,
            services: serviceTypeEnum.maintenance
          });
        }
      }

      if (plannedVehicle.inspectionsGpmExpertisedNextAct) {
        if (lastMaintenance?.lastExpertiseServiceGpm) {
          await expertiseServiceGpmApi.updateExpertiseServiceGpm({
            ...lastMaintenance.lastExpertiseServiceGpm,
            nextActDate: plannedVehicle.inspectionsGpmExpertisedNextAct
          });
        } else {
          await expertiseServiceGpmApi.addExpertiseServiceGpm({
            vehicleId: vehicle.id,
            nextActDate: plannedVehicle.inspectionsGpmExpertisedNextAct,
            services: serviceTypeEnum.expertise
          });
        }
      }

      if (plannedVehicle.measuringDeviceCertificationNextAct) {
        if (lastMaintenance?.lastMeasuringDeviceCertification) {
          await measuringDeviceCertificationApi.updateMeasuringDeviceCertification(
            {
              ...lastMaintenance.lastMeasuringDeviceCertification,
              nextActDate: plannedVehicle.measuringDeviceCertificationNextAct
            }
          );
        } else {
          await measuringDeviceCertificationApi.addMeasuringDeviceCertification(
            {
              vehicleId: vehicle.id,
              nextActDate: plannedVehicle.measuringDeviceCertificationNextAct
            }
          );
        }
      }

      addVehicle(vehicle, plannedVehicle);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };
  return (
    <Modal
      destroyOnClose
      width={800}
      title={props.vehicle?.id ? 'Редактирование ТС' : "Добавление нового ТС"}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={{
          stsType: STSEnum.forBudget,
          usingNodeId: orgUnitId ?? props.vehicle?.usingNodeId,
          ownerType: ownerTypes.self,
          vehicleModelId: props.vehicle?.vehicleModelId,
          hasGps: props.vehicle?.hasGps,
          inspectionsGibddNextAct:
            lastMaintenance?.lastInspectionGibdd?.nextActDate,
          inspectionsGtndNextAct:
            lastMaintenance?.lastInspectionGtn?.nextActDate,
          measuringDeviceCertificationNextAct:
            lastMaintenance?.lastMeasuringDeviceCertification?.nextActDate,
          inspectionsGpmNextAct:
            lastMaintenance?.lastExpertiseServiceGpmMaintenance?.nextActDate,
          inspectionsGpmExpertisedNextAct:
            lastMaintenance?.lastExpertiseServiceGpm?.nextActDate,
          orgUnitId: orgUnitId ?? props.vehicle?.orgUnitId
        }}
      >
        {(
          FormField: ComponentType<FormFieldProps>,
          formikProps: FormikProps
        ) => {
          const {
            handleSubmit,
            dirty,
            isSubmitting,
            setFieldValue
          } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px" cols={1}>
                <GridItem>
                  <FormField label="Модель ТС" name="vehicleModelId" required>
                    {({ value, name }) => (
                      <VehicleModelSelect
                        onChange={(vehicleModelId, option) => {
                          setVehicleType(option?.props?.vehicleModel?.type);
                          setFieldValue(name, vehicleModelId);
                        }}
                        disabled={props.vehicle}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>

                {toGibdd(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующего ТО ГИБДД"
                      name="inspectionsGibddNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {toGtn(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующего ТО ГТН"
                      name="inspectionsGtndNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {toGpmAndExpertise(vehicleType) && (
                  <>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата следующего ТО ГПМ"
                        name="inspectionsGpmNextAct"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        fast
                        label="Дата следующей экспертизы ГПМ"
                        name="inspectionsGpmExpertisedNextAct"
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={value ? moment.utc(value) : value}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                formatDateTimeToISOString(value, dateString)
                              );
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </>
                )}

                {gps(vehicleType) && (
                  <GridItem>
                    <FormField fast label="Мониторинг ТС" name="hasGps">
                      {({ value, name }) => (
                        <YesNoSelect
                          format="DD.MM.YYYY"
                          value={value}
                          onChange={(value: boolean) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                {measuringDevices(vehicleType) && (
                  <GridItem>
                    <FormField
                      fast
                      label="Дата следующей тарировки"
                      name="measuringDeviceCertificationNextAct"
                    >
                      {({ value, name }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          value={value ? moment.utc(value) : value}
                          onChange={(value: Object, dateString: string) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}

                <GridItem fullWidth>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    data-cy="save"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                  <Button
                    style={{ marginLeft: '16px' }}
                    dirty={dirty}
                    onClick={onCancel}
                  >
                    Отменить
                  </Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
