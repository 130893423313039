// @flow

import type {Vehicle} from '../types';
import type {VehicleWithWashingPrices} from '../types/vehicle';

import type {FetchListParams, ListResponse} from './index';
import {downloadRequestWithToken, fetchRequest, initialFetchListParams} from './index';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour,
} from '../helpers';
import type {VehicleFilterParams} from '../../containers/Vehicles/Filter';
import {convertDataFromServer as convertVehicleModelFromServer} from './vehicleModel';
import {
  convertDataFromServer as convertVehicleWorkMeterFromServer,
  convertDataToServer as convertVehicleWorkMeterToServer,
} from './vehicleWorkMeter';

export const convertDataFromServerVehiclePrice = (vehicle: VehicleWithWashingPrices) => ({
  ...convertDataFromServer(vehicle),
  interiorPrice: convertFromKopToRub(vehicle?.interiorPrice ?? 0),
  bodyPrice: convertFromKopToRub(vehicle?.bodyPrice ?? 0),
  enginePrice: convertFromKopToRub(vehicle?.enginePrice ?? 0),
});

export const convertDataFromServer = (vehicle: Vehicle) => ({
  ...vehicle,
  kilometrage: convertFromMToKm(vehicle?.kilometrage ?? 0),
  averageKilometrage: convertFromMToKm(vehicle?.averageKilometrage ?? 0),
  averageDailyKilometrage: convertFromMToKm(vehicle?.averageDailyKilometrage ?? 0),
  kilometrageFromLastOverhaul: convertFromMToKm(vehicle?.kilometrageFromLastOverhaul ?? 0),
  normKilometrage: convertFromMToKm(vehicle?.normKilometrage ?? 0),
  initialCost: convertFromKopToRub(vehicle?.initialCost ?? 0),
  residualValue: convertFromKopToRub(vehicle?.residualValue ?? 0),
  partOfCostsMaintenanceFromInitialCost: convertFromKopToRub(vehicle?.partOfCostsMaintenanceFromInitialCost ?? 0),
  maintenanceCosts: convertFromKopToRub(vehicle?.maintenanceCosts ?? 0),
  overhaulCosts: convertFromKopToRub(vehicle?.overhaulCosts ?? 0),
  totalRepairCosts: convertFromKopToRub(vehicle?.totalRepairCosts ?? 0),
  costPerHourForCompanies: convertFromKopToRub(vehicle?.costPerHourForCompanies ?? 0),
  outputRatio: parseFloat((vehicle?.outputRatio || 0).toFixed(2)),
  engineWorkHours: convertFromSecondToHour(vehicle?.engineWorkHours),
  averageEngineWorkHours: convertFromSecondToHour(vehicle?.averageEngineWorkHours),
  normEngineWorkHours: convertFromSecondToHour(vehicle?.normEngineWorkHours),
  averageDailyEngineWorkHours: convertFromSecondToHour(vehicle?.averageDailyEngineWorkHours),
  engineWorkHoursFromLastOverhaul: convertFromSecondToHour(vehicle?.engineWorkHoursFromLastOverhaul),
  vehicleModel: convertVehicleModelFromServer(vehicle?.vehicleModel),
  osago: vehicle?.osago
    ? {
      ...vehicle.osago,
      paymentAmount: vehicle.osago ? convertFromKopToRub(vehicle.osago.paymentAmount) : 0,
    }
    : vehicle?.osago,
  engineWorkHoursByMeter: convertFromSecondToHour(vehicle?.engineWorkHoursByMeter),
  kilometrageByOdometr: convertFromMToKm(vehicle?.kilometrageByOdometr),
  vehicleWorkMeters: vehicle.vehicleWorkMeters?.length ? vehicle.vehicleWorkMeters.map(convertVehicleWorkMeterFromServer) : [],
});

const convertDataToServer = (vehicle: Vehicle) => ({
  ...vehicle,
  kilometrage: convertFromKmToM(vehicle.kilometrage),
  averageKilometrage: convertFromKmToM(vehicle.averageKilometrage),
  averageDailyKilometrage: convertFromKmToM(vehicle.averageDailyKilometrage),
  kilometrageFromLastOverhaul: convertFromKmToM(vehicle.kilometrageFromLastOverhaul),
  normKilometrage: convertFromKmToM(vehicle.normKilometrage),
  initialCost: convertFromRubToKop(vehicle.initialCost),
  residualValue: convertFromRubToKop(vehicle.residualValue),
  partOfCostsMaintenanceFromInitialCost: convertFromRubToKop(vehicle.partOfCostsMaintenanceFromInitialCost),
  maintenanceCosts: convertFromRubToKop(vehicle.maintenanceCosts),
  overhaulCosts: convertFromRubToKop(vehicle.overhaulCosts),
  totalRepairCosts: convertFromRubToKop(vehicle.totalRepairCosts),
  costPerHourForCompanies: convertFromRubToKop(vehicle.costPerHourForCompanies),
  engineWorkHours: convertFromHourToSecond(vehicle.engineWorkHours),
  averageEngineWorkHours: convertFromHourToSecond(vehicle.averageEngineWorkHours),
  normEngineWorkHours: convertFromHourToSecond(vehicle.normEngineWorkHours),
  averageDailyEngineWorkHours: convertFromHourToSecond(vehicle.averageDailyEngineWorkHours),
  engineWorkHoursFromLastOverhaul: convertFromHourToSecond(vehicle.engineWorkHoursFromLastOverhaul),
  osago: {
    ...vehicle.osago,
    paymentAmount: vehicle.osago ? convertFromRubToKop(vehicle.osago.paymentAmount) : 0,
  },
  engineWorkHoursByMeter: convertFromHourToSecond(vehicle?.engineWorkHoursByMeter),
  kilometrageByOdometr: convertFromKmToM(vehicle?.kilometrageByOdometr),
  vehicleWorkMeters: vehicle.vehicleWorkMeters?.length ? vehicle.vehicleWorkMeters.map(convertVehicleWorkMeterToServer) : [],
});

export const addVehicle = async (vehicle: Vehicle): Promise<Vehicle> => {
  const added = await fetchRequest.post(
    '/vehicle',
    convertDataToServer(vehicle),
  );
  if (added) {
    return convertDataFromServer(added);
  }
  throw new Error('Не удалось создать ТС');
};

export const updateVehicle = async (vehicle: Vehicle): Promise<Vehicle> => {
  const updated = await fetchRequest.put(
    '/vehicle',
    convertDataToServer(vehicle),
  );
  if (updated) {
    return convertDataFromServer(updated);
  }
  throw new Error('Не удалось обновить ТС');
};

export const fetchVehicles = async (
  params: FetchListParams<VehicleFilterParams> = initialFetchListParams,
): Promise<ListResponse<Vehicle>> => {
  const vehicles = await fetchRequest.get('/vehicle', {
    ...initialFetchListParams,
    ...params,
  });
  return {
    ...vehicles,
    data: vehicles.data.map(convertDataFromServer),
  };
};

export const fetchVehiclesForWashingPlan = async (
  params: FetchListParams<VehicleFilterParams> = initialFetchListParams,
  monthDate: string,
): Promise<ListResponse<VehicleWithWashingPrices>> => {
  const vehicles = await fetchRequest.get(`/vehicle/${monthDate}/prices`, {
    ...initialFetchListParams,
    ...params,
  });
  return {
    ...vehicles,
    data: vehicles.data.map(convertDataFromServerVehiclePrice),
  };
};

export const fetchVehicle = async (
  id: number,
  params: any = {},
): Promise<Vehicle> => {
  const vehicle = await fetchRequest.get(`/vehicle/${id}`, params);
  if (vehicle) {
    return convertDataFromServer(vehicle);
  }
  throw new Error('Не удалось загрузить ТС');
};

export const deleteVehicle = async (id: number): Promise<Vehicle> => {
  const deleted = await fetchRequest.delete(`/vehicle/${id}`);
  if (deleted) {
    return convertDataFromServer(deleted);
  }
  throw new Error('Не удалось удалить ТС');
};

export const setDrivingVehicle = async (
  id: number,
  drivingVehicleId: ?number,
) => {
  const updated = await fetchRequest.post(
    `/vehicle/${id}/drivingVehicle/${drivingVehicleId || ''}`,
  );
  if (updated) {
    return convertDataFromServer(updated);
  }
  throw new Error('Не удалось удалить ТС');
};

export const print = async (
  params: FetchListParams<VehicleFilterParams>,
): Promise<boolean> => {
  const printed = await downloadRequestWithToken('/vehicle/print', {
    ...params,
  });
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось распечатать отчет');
};

export const subscribeParusInventoryCards = async (): Promise<any> => {
  const accounts = await fetchRequest.get(
    `/vehicle/parus/subscribe/inventoryCards`,
    {},
  );
  if (accounts) {
    return accounts;
  }
  throw new Error('Не удалось подписаться на обновления по складам!');
};

export const parus = async (vehicleId: number): Promise<any> => {
  const accounts = await fetchRequest.get(
    `/vehicle/parus/update/${vehicleId}`,
    {},
  );
  if (accounts) {
    return accounts;
  }
  throw new Error('Не удалось обработать данные для Паруса');
};

const getVehicles = async (params: any) => {
  const getVehicles = await fetchRequest.get(
    `/vehicle/vehicleTaxPlan/getVehicles`,
    params,
  );
  if (getVehicles) {
    return getVehicles;
  }
  throw new Error('Не удалось получить данные по машинам');
};

const getLastMaintenance = async (vehicleId: number) => {
  const lastMaintenance = await fetchRequest.get(
    `/vehicle/${vehicleId}/lastMaintenance`,
  );
  if (lastMaintenance) {
    return lastMaintenance;
  }
  throw new Error('Не удалось получить данные по машине');
};

export const changeBlockedStatus = async (
  id: number,
  status: string,
) => {
  const updated = await fetchRequest.post(
    `/vehicle/${id}/changeBlockedStatus`,
    status
  );
  if (updated) {
    return convertDataFromServer(updated);
  }
  throw new Error('Не удалось изменить статус');
};

export default {
  getLastMaintenance,
  addVehicle,
  getVehicles,
  updateVehicle,
  fetchVehicles,
  deleteVehicle,
  fetchVehicle,
  setDrivingVehicle,
  print,
  fetchVehiclesForWashingPlan,
  subscribeParusInventoryCards,
  parus,
  changeBlockedStatus
};
