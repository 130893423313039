// @flow
import {DatePicker, Input, Modal, Radio, Switch} from 'antd';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import qs from 'query-string';
import React, {useState} from 'react';
import styled from 'styled-components';

import {GridItem, Header, Section} from '../../components/layout';
import Grid from '../../components/layout/Grid';
import {notificationLoading} from '../../components/Notifications';
import CompanySelect from '../../components/selects/CompanySelect';
import ButtonsRow from '../../components/ui/ButtonsRow';
import Table from '../../components/ui/Table';

import {contractApi} from '../../lib/api';
import {contractDocumentType, contractDocumentTypeEnum, entityStatus} from '../../lib/enum';
import {
  convertEmployeeToString,
  formatDateTimeToISOString,
  formatDateTimeToString,
  formatRub,
  getListInitialState,
  navigate,
  plus,
  sumWidthColumns,
} from '../../lib/helpers';
import type {
  Contract,
  ContractDocumentType,
  Employee,
  EntityStatusType,
  ListState,
  Stage,
  UserAccess,
} from '../../lib/types';

import {withUserAccess} from '../withUserAccess';

import {addContractAccess} from './accessRight';
import type {ContractFilterParams} from './components/Filter';
import Filter from './components/Filter';

const { RangePicker } = DatePicker;

const StyledSwitch = styled(Switch)`
  background-color: #1890ff;
  margin: 0 20px 0 20px;
`;
type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<Contract> & {
  filter: ContractFilterParams,
  requestPopupVisible: boolean
};

export class ContractList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    requestPopupVisible: false
  };

  columns = [
    {
      title: 'Филиал',
      dataIndex: 'branchName',
      width: 100,
      breakByWidth: true
    },
    {
      title: '№  документа',
      key: 'documentNumber',
      dataIndex: 'documentNumber',
      width: 200,
      breakByWidth: true,
      render: (documentNumber: string, record: Contract) =>
        documentNumber ? documentNumber : record.accountNumber
    },
    {
      title: 'Тип документа',
      key: 'documentType',
      dataIndex: 'documentType',
      width: 150,
      breakByWidth: true,
      render: (type: ContractDocumentType) => contractDocumentType[type]
    },
    {
      title: 'Контрагент',
      dataIndex: 'contractor.company.name',
      width: 300,
      breakByWidth: true,
      render: (contractorName: string) =>
        contractorName ? contractorName : '-'
    },
    {
      title: 'Сумма',
      dataIndex: 'stages',
      width: 150,
      breakByWidth: true,
      render: (stages: Stage[]) => {
        let sum = 0;
        stages.forEach((stage: Stage) => (sum = plus(sum, stage.stageSum)));
        return formatRub(sum);
      }
    },
    {
      title: 'Статус',
      dataIndex: 'contractStatus',
      width: 100,
      breakByWidth: true,
      render: (status: EntityStatusType) => entityStatus[status]
    },
    {
      title: 'Период действия',
      dataIndex: 'startDate',
      width: 150,
      breakByWidth: true,
      render: (value: any, record: Contract) =>
        `${formatDateTimeToString(
          record.startDate,
          'DD.MM.YYYY'
        )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY')}`
    },
    {
      title: 'Куратор',
      width: 200,
      breakByWidth: true,
      dataIndex: 'employee',
      render: (employee: Employee) =>
        convertEmployeeToString(employee, { initials: true })
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      width: 250,
      breakByWidth: true
    }
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);

    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchContract(page)
    );
  }

  canAdd = () =>
    this.props.userAccess.some(access => addContractAccess.includes(access));

  fetchContract = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await contractApi.fetchContracts({
      ...filter,
      page
    });
    this.setState({ ...data, loading: false, page });
  };

  onConfirmRequest = async (data: any) => {
    let startDate: string, endDate: string;
    if (data.dateRange) {
      [startDate, endDate] = data.dateRange;
    }
    const documentType = data.docType;
    const { number, contractorMnemocode } = data;
    try {
      try {
        notificationLoading({
          message: 'Запрос данных',
          key: 'request'
        });
        let dataResult;
        if (data.dateRange) {
          dataResult = await contractApi.updateParusContractsForPeriod({
            startDate,
            endDate,
            documentType
          });
        } else {
          dataResult = await contractApi.updateParusContracts({
            documentType,
            number,
            contractorMnemocode
          });
        }

        if (dataResult) {
          notification.success({ message: dataResult.status });
        } else {
          notification.error('Ошибка запросы');
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message
        });
      }
    } finally {
      notification.close('request');
    }
  };

  applyFilter = (filter: ContractFilterParams) => {
    const { page } = this.state;
    this.setState({ filter }, () => this.fetchContract(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {} }, () => this.fetchContract(page));
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр договоров</h1>}
          right={
            this.canAdd() && (
              <>
                <ButtonsRow>
                  <Button
                    onClick={() => {
                      this.setState({ requestPopupVisible: true });
                    }}
                  >
                    Запросить данные
                  </Button>
                </ButtonsRow>
              </>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchContract}
            onRow={(row: Contract) => ({
              onClick: () => navigate(`/admin/contract/${row.id}`)
            })}
            scroll={{
              y: 'calc(100vh - 270px)',
              x: sumWidthColumns(this.columns)
            }}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
          <RequestPopup
            visible={this.state.requestPopupVisible}
            onClose={() => this.setState({ requestPopupVisible: false })}
            onOk={async data => {
              this.setState({ requestPopupVisible: false });
              await this.onConfirmRequest(data);
            }}
          />
        </Section>
      </>
    );
  }
}

type RequestPopupProps = {
  onClose: Function,
  onOk: ({
    dateRange?: string[],
    docType?: ContractDocumentType,
    documentNumber?: string,
    contractorId?: number
  }) => any,
  visible: boolean
};

type SwitchType = 'period' | 'contractor';

const RequestPopup = (props: RequestPopupProps) => {
  const [dateRange, setDateRange] = useState([
    moment.utc().startOf('day'),
    moment.utc().endOf('day')
  ]);
  const [number, setNumber] = useState(undefined);
  const [contractor, setContractor] = useState(undefined);
  const [docType, setDocType] = useState(contractDocumentTypeEnum.contract);
  const [switchType: SwitchType, setSwitchType] = useState('period');

  const canSave = () => {
    return (
      (switchType === 'contractor' && (number || contractor?.mnemocode)) ||
      (switchType === 'period' && dateRange[0] && dateRange[1])
    );
  };
  return (
    <Modal
      maskClosable
      destroyOnClose
      visible={props.visible}
      closable={false}
      style={{ top: 20, right: 20, position: 'absolute' }}
      width={400}
      okButtonProps={{ disabled: !canSave() }}
      onOk={() => {
        // eslint-disable-next-line no-unused-expressions
        props.onOk(
          switchType === 'period'
            ? {
                dateRange: [
                  formatDateTimeToISOString(
                    dateRange[0],
                    moment(dateRange[0]).format('DD.MM.YYYY HH:mm')
                  ),
                  formatDateTimeToISOString(
                    dateRange[1],
                    moment(dateRange[1]).format('DD.MM.YYYY HH:mm')
                  )
                ],
                docType
              }
            : {
                number,
                contractorMnemocode: contractor?.mnemocode,
                docType
              }
        );
      }}
      onCancel={props.onClose}
    >
      <Grid cols={1} rowGutter="16px">
        <GridItem fullWidth>
          За период
          <StyledSwitch
            checked={switchType === 'contractor'}
            onChange={checked => {
              setSwitchType(checked ? 'contractor' : 'period');
            }}
          />
          По номеру/контрагенту
        </GridItem>

        {switchType === 'period' && (
          <GridItem>
            <RangePicker
              format="DD.MM.YYYY"
              value={dateRange}
              onChange={date => {
                setDateRange(date);
              }}
            />
          </GridItem>
        )}
        {switchType === 'contractor' && (
          <>
            <GridItem>
              <Input
                value={number}
                placeholder="Номер документа"
                onChange={e => {
                  setNumber(e.target.value);
                }}
              />
            </GridItem>
            <GridItem>
              <CompanySelect
                placeholder="Контрагент"
                value={contractor?.id}
                onChange={(id, option) => {
                  setContractor(option?.props.company);
                }}
              />
            </GridItem>
          </>
        )}
        <GridItem>
          <Radio.Group
            value={docType}
            onChange={e => setDocType(e.target.value)}
          >
            <Radio value={contractDocumentTypeEnum.contract}>
              По договорам
            </Radio>
            <Radio value={contractDocumentTypeEnum.account}>
              По лицевым счетам
            </Radio>
          </Radio.Group>
        </GridItem>
      </Grid>
    </Modal>
  );
};

export default withUserAccess(ContractList);
