// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {VehicleTariff} from '../../../lib/types';
import {vehicleTariffApi} from '../../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';

import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../../components/layout';
import InnerFields from './InnerFields';
import {notificationLoading} from '../../../components/Notifications';
import {vehicleTariffStatusEnum} from '../../../lib/enum';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  version: string,
  vehicleTariffId: number,
  clone?: boolean
};

type State = {
  vehicleTariff: ?VehicleTariff
};

export class VehicleTariffForm extends React.Component<Props, State> {
  url: string;
  constructor(props: Props) {
    super(props);
    this.state = {
      vehicleTariff: null,
    };
    this.url = `/admin/vehicle-tariff/tariff`;
  }

  async componentDidMount() {
    const {vehicleTariffId, clone} = this.props;

    try {
      if (vehicleTariffId) {
        const vehicleTariff = await vehicleTariffApi.getVehicleTariff(
          vehicleTariffId,
        );
        if (vehicleTariff.status === vehicleTariffStatusEnum.deactivated && !clone) {
          this.navigateToCard(vehicleTariff.version, vehicleTariff.id);
        } else {
          this.setState({vehicleTariff});
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  navigateToCard = (tariffVersion?: string, tariffId?: number) => {
    const version = tariffVersion ?? this.state.vehicleTariff?.version;
    const vehicleTariffId = tariffId ?? this.props.vehicleTariffId;

    if (version) {
      navigate(`${this.url}/${version}/${vehicleTariffId}`, true);
    }
  }

  handleSubmit = async (values: VehicleTariff) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });

      if (values.id && !this.props.clone) {
        await vehicleTariffApi.updateVehicleTariff({
          ...values,
          status: vehicleTariffStatusEnum.draft,
          ai92Price: values.ai92Price || 0,
          ai95Price: values.ai95Price || 0,
          dieselPrice: values.dieselPrice || 0,
          electricityPrice: values.electricityPrice || 0,
          gasPrice: values.gasPrice || 0,
        });
        this.navigateToCard();
      } else {
        const newTariff = await vehicleTariffApi.addVehicleTariff({
          typesAndModels: [], // добавлено для устранения ошибок сохранения данных
          ...values,
          status: vehicleTariffStatusEnum.draft,
          vehicleTariffType: 'byBrand',
          id: undefined,
        });
        this.navigateToCard(newTariff.version, newTariff.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => this.navigateToCard();

  render() {
    const {vehicleTariff} = this.state;
    const {vehicleTariffId, clone} = this.props;

    const pageTitle = vehicleTariffId && !clone ? `Тариф на НТС №${vehicleTariffId}` : 'Новый тариф на НТС';

    if (!vehicleTariff) {
      return <></>;
    } else {
      return (
        <>
          <Header
            left={
              <Breadcrumbs>
                <Crumb to={getPathWithHistoryParams(`/admin/vehicle-tariff/${vehicleTariff.version}`)}>
                  Перечень тарифов на НТС
                </Crumb>
                <Crumb>{pageTitle}</Crumb>
              </Breadcrumbs>
            }
          />
          <StyledPanel>
            <h1>{pageTitle}</h1>
          </StyledPanel>
          <InnerFields
            vehicleTariff={vehicleTariff}
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        </>
      );
    }    
  }
}

export const VehicleTariffFormClone = (props) => <VehicleTariffForm {...props} clone />;

