// @flow
import React, {createRef} from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';
import {MaintenanceEstimate, maintenanceEstimateMaintenanceLink} from '../../../../lib/types/maintenanceEstimate';
import {downloadRequestWithToken, maintenanceApi, MaintenanceEstimatesApi, orgUnitApi} from '../../../../lib/api';
import notification from 'antd/lib/notification';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import {withUserAccess} from '../../../withUserAccess';
import type {Maintenance, OrgUnitNode, User, UserAccess} from '../../../../lib/types';
import Field from '../../../../components/card/Field';
import moment from 'moment/moment';
import Select from 'antd/lib/select';
import {maintenanceStatusesEnum} from '../../../../lib/enum';
import Button from 'antd/lib/button';
import {getPathWithHistoryParams, navigate} from '../../../../lib/helpers';
import {notificationLoading} from '../../../../components/Notifications';
import Section from '../../../../components/layout/Section';
import {SectionContent} from '../../../../components/hoc/common/components/elements';
import {DefectCard} from './components/DefectCard';
import {Header} from '../../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../../components/layout/Breadcrumbs';
import Panel from '../../../../components/layout/Panel';
import {handlingInternalActs} from '../../accessRight';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const StyledPanel = styled(Panel)`
  padding: 16px;
`;

const {Option} = Select;

const entryPointPath = '/admin/act/internal/estimates';

type Props = {
  id: number,
  userProfile: User,
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number,
};

type State = {
  loading: boolean,
  estimate: MaintenanceEstimate,
  usersBranch: OrgUnitNode,
  maintenances: Maintenance[],
  currentMaintenancesIds: number[],
  inputValues: any,
  editMode: boolean,
};

export class Card extends React.Component<Props, State> {
  state = {
    loading: false,
    estimate: null,
    usersBranch: null,
    maintenances: null,
    currentMaintenancesIds: [],
    inputValues: createRef({}),
    editMode: false,
  };
  
  async componentDidMount() {
    const orgUnits = await orgUnitApi.fetchOrgUnits({onlyBranch: true});
    const usersBranch = orgUnits.children.find(item => item.id === this.props.employeeBranchOrgUnitId);
    const {data: maintenances} = await maintenanceApi.fetchMaintenances({status: maintenanceStatusesEnum.approved});
    this.setState({usersBranch, maintenances});
    if (this.props.id) {
      await this.fetchEstimate();
    }
  }
  
  getCurrentMaintenances = (estimate) => {
    const currentMaintenancesIds = estimate.maintenanceEstimateMaintenanceLinks.map(item => item.maintenanceId);
    this.setState({currentMaintenancesIds});
  };
  
  fetchEstimate = async () => {
    try {
      this.setState({loading: true});
      const estimate = await MaintenanceEstimatesApi.get(this.props.id);
      this.getCurrentMaintenances(estimate);
      this.setState({estimate, loading: false});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  handleSubmit = async () => {
    try {
      notificationLoading({message: 'Сохранение данных', key: 'saving'});
      this.setState({loading: true});
      const {currentMaintenancesIds, estimate, inputValues} = this.state;
      const {userProfile} = this.props;
      if (estimate) {
        // если смета уже создана, т.е. estimate !== undefined, то сначала фильтруем имеющиеся в смете ссылки на дефектные ведомости
        estimate.maintenanceEstimateMaintenanceLinks = estimate.maintenanceEstimateMaintenanceLinks.filter(
          item => currentMaintenancesIds.includes(item.maintenanceId),
        );
        // затем в оставшихся дефектных ведомостях заменяем значения полей на значения из инпутов
        estimate.maintenanceEstimateMaintenanceLinks.forEach(item => {
          if (inputValues.current?.[item.id]) {
            item.justification = inputValues.current[item.id].justification;
            item.fare = inputValues.current[item.id].fare;
          }
        });
      }
      const newEstimate = estimate
        ? estimate
        : {
          maintenanceEstimateMaintenanceLinks: [],
          orgUnitId: userProfile.employee.orgUnitId,
        };
      currentMaintenancesIds.forEach(id => {
        if (!newEstimate.maintenanceEstimateMaintenanceLinks.find(item => item.maintenanceId === id)) {
          newEstimate.maintenanceEstimateMaintenanceLinks.push({
            justification: 'РД 03112178-1023-99',
            maintenanceId: id,
          });
        }
      });
      const result = estimate
        ? await MaintenanceEstimatesApi.update(newEstimate)
        : await MaintenanceEstimatesApi.add(newEstimate);
      notification.success({message: 'Данные сохранены'});
      if (!estimate && result) {
        navigate(`/admin/act/internal/estimates/${result.id}`);
        return;
      }
      this.getCurrentMaintenances(result);
      this.setState({estimate: result, loading: false});
    } catch (e) {
      notification.error({message: 'Ошибка сохранения данных', description: e.message || e.title});
    }
    finally {
      notification.close('saving');
    }
  };
  
  handlePrint = async () => {
    try {
      notificationLoading({message: 'Формируется печатная форма', key: 'printing'});
      await downloadRequestWithToken(`/MaintenanceEstimate/${this.props.id}/print`);
    } catch (e) {
      notification.error({message: 'Ошибка формирования печатной формы', description: e.title || e.message});
    }
    finally {
      notification.close('printing');
    }
  };
  
  canEdit = () => this.props.userAccess.some(access => handlingInternalActs.includes(access));
  
  render() {
    const {id, userProfile} = this.props;
    const {loading, usersBranch, estimate, maintenances, currentMaintenancesIds, inputValues, editMode} = this.state;
    
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(entryPointPath)}>Реестр первичных документов</Crumb>
              {id ? <Crumb>Смета № {id}</Crumb> : <Crumb>Новая смета</Crumb>}
            </Breadcrumbs>
          }
          right={
            <>
              {this.canEdit() && !editMode && (
                <Button
                  type="primary"
                  style={{marginRight: '10px'}}
                  onClick={() => this.setState({editMode: true})}
                >
                  Редактировать
                </Button>
              )}
              <Button onClick={this.handlePrint}>Печать</Button>
            </>
          }
        />
        <StyledPanel>
          <h1>{id ? `Смета №${id}` : 'Новая смета'}</h1>
        </StyledPanel>
        {loading || (id && !estimate)
          ? (
            <Preloader>
              <Spin size={'large'} />
            </Preloader>
          )
          : (
            <>
              <Section>
                <SectionContent>
                  <Grid gutter="16px" cols={4}>
                    {maintenances && (
                      <GridItem>
                        <Field label="Карточки работ">
                          <Select
                            mode="multiple"
                            value={currentMaintenancesIds}
                            onChange={(idsArr) => this.setState({currentMaintenancesIds: idsArr})}
                            disabled={!editMode}
                          >
                            {maintenances.map(item => (
                              <Option key={item.id} value={item.id}>Карточка работ №{item.id}</Option>
                            ))}
                          </Select>
                        </Field>
                      </GridItem>
                    )}
                    {estimate?.created && (
                      <GridItem>
                        <Field label="Дата документа">{moment(estimate.created).format('DD.MM.YYYY')}</Field>
                      </GridItem>
                    )}
                    <GridItem>
                      <Field label="Филиал">{usersBranch?.name}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Подразделение">{userProfile.employee.orgUnitName}</Field>
                    </GridItem>
                  </Grid>
                </SectionContent>
              </Section>
              {estimate?.maintenanceEstimateMaintenanceLinks?.length && (
                estimate.maintenanceEstimateMaintenanceLinks.map((link: maintenanceEstimateMaintenanceLink) => (
                  <DefectCard
                    key={link.id}
                    maintenanceLink={link}
                    onChange={value => inputValues.current = {...inputValues.current, [link.id]: value}}
                    editMode={editMode}
                  />
                ))
              )}
              {editMode && (
                <Section>
                  <SectionContent>
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState({editMode: false});
                        this.handleSubmit();
                      }}
                      disabled={!currentMaintenancesIds.length}
                      style={{marginRight: '10px'}}
                    >
                      Сохранить
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({editMode: false});
                        this.fetchEstimate();
                      }}
                    >
                      Отмена
                    </Button>
                  </SectionContent>
                </Section>
              )}
            </>
          )
        }
      </>
    );
  };
}

export default withUserAccess(Card);
