// @flow

import type { Dispatch } from 'redux';

import type { Company } from './../lib/types';
import { companyApi } from './../lib/api';
import type { Action, AppState } from './redux';

export const FETCH_COMPANY = 'vehicles/company/fetch';
export const UPDATE_COMPANY = 'vehicles/company/update';
export const CLEAN_COMPANY = 'vehicles/company/clean';

export type CompanyState = ?Company;

const initialState: CompanyState = null;

const reducer = (
  state: CompanyState = initialState,
  { type, payload }: Action
): CompanyState => {
  switch (type) {
    case FETCH_COMPANY:
      return { ...payload };
    case CLEAN_COMPANY:
      return null;
    default:
      return state;
  }
};

export const addCompany: Function = (company: Company): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  await companyApi.add(company);
};

export const updateCompany: Function = (company: Company): Function => async (
  dispatch: Dispatch,
  getState: () => AppState
): Promise<void> => {
  const updated = await companyApi.update(company);
  dispatch({
    type: UPDATE_COMPANY,
    payload: {
      ...updated
    }
  });
};

export const fetchCompany = (id: number) => async (dispatch: Dispatch) => {
  let company = await companyApi.get(id);
  dispatch({
    type: FETCH_COMPANY,
    payload: {
      ...company
    }
  });
};

export const cleanCompany = () => (dispatch: Dispatch) =>
  dispatch({
    type: CLEAN_COMPANY
  });

export default reducer;
