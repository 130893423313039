// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import {platonApi} from './../../../../../lib/api';
import {formatDate} from './../../../../../lib/helpers';

import {Table} from './../../../../../components/ui';
import {Header, Panel, Section} from './../../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../../components/layout/Breadcrumbs';

import {Filter, PLATON_COLUMNS as COLUMNS} from './../../../../services/platon';

import {GroupListTable} from './../../lib';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

export default class extends GroupListTable<any, any> {
  fetch = async (page: number = 1) => {
    const params = {
      ...super.fetch(page)
    };
    try {
      const data = await platonApi.fetchPlatons({
        ...params,
        ...this.getDate(params.period)
      });
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const { period } = filter;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/budget/execution/pass">Платон</Crumb>
              <Crumb>Сравнение данных</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Платон {formatDate(period, 'MMMM YYYY')}</h1>
        </StyledPanel>
        <Section>
          <Filter
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            filter={filter}
          />
          <Table
            fetch={this.fetch}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            loading={loading}
            columns={COLUMNS}
            data={data}
          />
        </Section>
      </>
    );
  }
}
