// @flow

import type { Location } from './../types';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { LocationFilterParams } from '../../containers/Locations/ASVehicles/Filter';

export const addLocation = async (location: Location): Promise<Location> => {
  const added = await fetchRequest.post('/location', location);
  if (added) return added;
  throw new Error('Не удалось создать объект');
};

export const updateLocation = async (location: Location): Promise<Location> => {
  const updated = await fetchRequest.put('/location', location);
  if (updated) return updated;
  throw new Error('Не удалось обновить объект');
};

export const fetchLocations = async (
  params: FetchListParams<LocationFilterParams> = initialFetchListParams
): Promise<ListResponse<Location>> =>
  await fetchRequest.get('/location', {
    ...initialFetchListParams,
    ...params
  });

export const fetchLocation = async (
  id: number,
  params: any = {}
): Promise<Location> => {
  const Location = await fetchRequest.get(`/location/${id}`, params);
  if (Location) return Location;
  throw new Error('Не удалось загрузить объект');
};

export const deleteLocation = async (id: number): Promise<Location> => {
  const deleted = await fetchRequest.delete(`/location/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить объект');
};

export const deleteLocations = async (ids: number[]): Promise<Location[]> => {
  const json = JSON.stringify(ids);
  const deleted = await fetchRequest.delete(`/location`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: json
  });
  if (deleted) return deleted;
  throw new Error('Не удалось удалить объекты');
};

export default {
  addLocation,
  updateLocation,
  fetchLocations,
  fetchLocation,
  deleteLocation,
  deleteLocations
};
