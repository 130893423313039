// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import Datepicker from 'antd/lib/date-picker';

import type { RangePickerProps } from 'antd/lib/date-picker';

const { RangePicker } = Datepicker;
const CustomStyled = styled(RangePicker)`
  .ant-calendar-picker-input.ant-input {
    font-size: 13px;
  }
`;

export default class CustomRangePicker extends Component<RangePickerProps> {
  render() {
    return <CustomStyled {...this.props} />;
  }
}
