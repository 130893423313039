// @flow
import type { ContractVehiclePlan } from './../types';
import CommonApi from './../commonApi';
import { downloadRequestWithToken } from './index';

const contractVehiclePlan = new CommonApi<ContractVehiclePlan>(
  'contractVehiclePlan',
  'план НТС'
);

const print = async (id: number) => {
  const printed = await downloadRequestWithToken(
    `/contractVehiclePlan/${id}/print`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default { ...contractVehiclePlan, print };
