// @flow
import CommonApi from './../commonApi';
import {InventoryCardCompareResult} from '../types/inventoryCardCompareResult';

const inventoryCardCompareResult = new CommonApi<InventoryCardCompareResult>(
  'InventoryCardCompareResult',
  'Сверка ИК'
);

export default inventoryCardCompareResult;
