// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';

import moment from 'moment';

import type {Employee, Order, Shift, Trip, TripStatus, UserAccess} from './../../lib/types';
import {cleanShift, fetchShift, updateShift, updateShiftTrip} from './../../ducks/shift';
import TripShortCard from './components/TripShortCard';
import OrderShortCard from './components/OrderShortCard';
import MedicInput from './components/MedicInput';
import {tripApi} from './../../lib/api';
import {changeStatus, updateTrip} from './../../ducks/trip';
import {
  accessTypeEnum,
  medicOwnerEnum,
  medicOwnerTypes,
  ownerTypes,
  positionEnum,
  tripStatusEnum,
} from './../../lib/enum';
import {Card, Selects} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import {withUserAccess} from './../withUserAccess';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {declineNumber, formatDateTimeToString, getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {convertEmployeeToString} from '../../lib/helpers';
import type {AppState} from '../../ducks/redux';
import {notificationLoading} from './../../components/Notifications';

const { Field } = Card;
const { EmployeeSelect, MedicOwnerTypeSelect } = Selects;

const Wrapper = styled.div``;
const StyledEmployeeSelect = styled(EmployeeSelect)`
  min-width: 300px;
`;
const StyledSection = styled(Section)`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding: 0 16px;
`;
const StyledTitle = styled.h1`
  padding-bottom: 16px;
`;

const BlueText = styled.span`
  color: #2770ff;
  ${props => props.pointer && 'cursor: pointer;'};
`;

const PrintStyledSection = styled(StyledSection)`
  background: #ccddff;
`;
const WorkersSection = styled(StyledSection)`
  & .field__value {
    margin-bottom: 0;
  }
`;

type Props = {
  fetchShift: Function,
  shiftId: ?string,
  cleanShift: Function,
  employees: Array<Employee>,
  shift: ?Shift,
  updateShift: Function,
  changeTripStatus: (id: number, status: TripStatus) => Promise<Trip>,
  updateShiftTrip: (trip: Trip) => void,
  updateTrip: (trip: Trip) => Trip,
  userAccess: UserAccess[],
  employeeBranchOrgUnitId: number
};

type State = {
  shift: ?Shift,
  printTripIds: number[],
  medicTypeSelect: $Values<typeof medicOwnerEnum>
};

export class ShiftCard extends Component<Props, State> {
  state = {
    shift: this.props.shift,
    printTripIds: [],
    medicTypeSelect: medicOwnerEnum.own
  };

  async componentDidMount() {
    const { shiftId } = this.props;
    this.props.cleanShift();
    try {
      await this.props.fetchShift(shiftId);
      if (this.props.shift && isString(this.props.shift.medicFullName)) {
        this.setState({ medicTypeSelect: medicOwnerEnum.hired });
      }
    } catch (error) {
      notification.error({
        message: 'Наряд не найден'
      });
      navigate('/shifts', true);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.shift, this.props.shift)) {
      this.setState({
        shift: this.props.shift
      });
    }
  }

  onTripPrint = async (tripId: number) => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'printing'
      });
      await tripApi.printTrip(tripId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  changeStatus = async (tripId: number, status: TripStatus) => {
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'changeStatus'
      });

      await this.props.changeTripStatus(tripId, status);
      await this.props.fetchShift(this.props.shiftId);
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при изменении статуса',
        description: err ? err.message : ''
      });
    } finally {
      notification.close('changeStatus');
    }
  };

  canEditShift = () =>
    this.props.userAccess.some(
      access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.handlingTrip
        ].includes(access) &&
        this.props.shift &&
        moment.utc(this.props.shift.date).isSameOrAfter(moment().startOf('day')) // Если дата просрочена, то не имеем права ничего менять
    );

  updateShift = () => {
    const { shift } = this.state;
    if (shift) {
      const { trips, ...partShift } = shift;
      this.props.updateShift(partShift);
    }
  };

  toggleTripToPrint = (id: number) =>
    this.setState(prevState => {
      if (prevState.printTripIds.includes(id)) {
        return {
          printTripIds: prevState.printTripIds.filter(
            (itemId: number) => itemId !== id
          )
        };
      }
      return {
        printTripIds: [...prevState.printTripIds, id]
      };
    });

  isCheckedToPrint = (id: number) => this.state.printTripIds.includes(id);

  tripPhraseByCount = (count: number) =>
    declineNumber(count, 'путевой лист', 'путевых листа', 'путевых листов');

  canUserPrint = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.handlingTrip
      ].includes(access)
    );

  canPrint = (trip: Trip): boolean =>
    trip.vehicle &&
    trip.vehicle.ownerType === ownerTypes.self &&
    trip.status !== tripStatusEnum.canceled &&
    parseInt(trip.idNumber, 10) > 0 &&
    this.canUserPrint();

  printTrips = async (type: string) => {
    const { shift, printTripIds } = this.state;
    let tripIds = [];
    switch (type) {
      case 'selected':
        tripIds = printTripIds;
        break;
      case 'all':
        if (shift) {
          tripIds = shift.trips
            .filter((trip: Trip) => this.canPrint(trip))
            .map((trip: Trip) => trip.id);
        }
        break;
      default:
        break;
    }
    notificationLoading({
      message:
        tripIds.length > 0
          ? `Формирование ${
              tripIds.length > 1 ? 'файлов' : 'файла'
            } для печати запущено`
          : 'Путевых листов для печати не найдено'
    });
    await Promise.all(tripIds.map(tripApi.printTrip));
    notification.close('printNotification');
  };

  onPrintOneTrip = async (tripId: number) => {
    await this.onTripPrint(tripId);
    notification.close('print');
  };

  render() {
    const { shift, printTripIds, medicTypeSelect } = this.state;
    const { employeeBranchOrgUnitId } = this.props;
    if (!shift) {
      return null;
    }
    const canEdit = this.canEditShift();
    const showPrintPanel = shift.trips
      .map(this.canPrint)
      .some(canPrint => !!canPrint);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/shifts')}>Наряды</Crumb>
              <Crumb to={`/shifts/${shift.id}`}>
                Наряд на {formatDateTimeToString(shift.date, 'DD MMMM YYYY')}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <StyledTitle>
            Наряд на {formatDateTimeToString(shift.date, 'DD MMMM YYYY')}
          </StyledTitle>
        </StyledPanel>
        <WorkersSection>
          <Grid gutter="16px" cols={3}>
            <GridItem>
              <Field label="Тип мед. работника">
                {canEdit ? (
                  <MedicOwnerTypeSelect
                    value={medicTypeSelect}
                    onChange={medicTypeSelect =>
                      this.setState({ medicTypeSelect })
                    }
                  />
                ) : (
                  medicOwnerTypes[medicTypeSelect]
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Медицинский работник">
                {canEdit ? (
                  medicTypeSelect === medicOwnerEnum.own ? (
                    <StyledEmployeeSelect
                      placeholder="Выберите мед. сотрудника"
                      filter={{
                        positions: [positionEnum.medic],
                        nodeId: employeeBranchOrgUnitId,
                        nodeFilterType: 'branchAndChildren'
                      }}
                      onChange={(value: string) => {
                        this.setState(
                          prevState => ({
                            // $FlowFixMe не хватает параметров
                            shift: {
                              ...prevState.shift,
                              medicFullName: null,
                              medicId: value ? parseInt(value, 10) : undefined
                            }
                          }),
                          this.updateShift
                        );
                      }}
                      value={shift.medicId}
                    />
                  ) : (
                    <MedicInput
                      value={shift.medicFullName}
                      handleBlur={medicFullName => {
                        this.setState(
                          prevState => ({
                            // $FlowFixMe не хватает параметров
                            shift: {
                              ...prevState.shift,
                              medicId: null,
                              medicFullName
                            }
                          }),
                          this.updateShift
                        );
                      }}
                    />
                  )
                ) : (
                  convertEmployeeToString(shift.medic)
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Контролер технического осмотра">
                {canEdit ? (
                  <StyledEmployeeSelect
                    placeholder="Выберите механика"
                    filter={{
                      positions: [positionEnum.mechanic],
                      nodeId: employeeBranchOrgUnitId,
                      nodeFilterType: 'branchAndChildren'
                    }}
                    onChange={(value: string) =>
                      this.setState(
                        prevState => ({
                          // $FlowFixMe не хватает параметров
                          shift: {
                            ...prevState.shift,
                            engineerId: parseInt(value, 10)
                          }
                        }),
                        this.updateShift
                      )
                    }
                    value={shift.engineerId}
                  />
                ) : (
                  convertEmployeeToString(shift.engineer)
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Контролёр ГПМ">
                {canEdit ? (
                  <StyledEmployeeSelect
                    placeholder="Выберите контролёра ГПМ"
                    filter={{
                      positions: positionEnum.gpmController,
                      nodeId: employeeBranchOrgUnitId,
                      nodeFilterType: 'branchAndChildren'
                    }}
                    onChange={(value: string) =>
                      this.setState(
                        prevState => ({
                          // $FlowFixMe не хватает параметров
                          shift: {
                            ...prevState.shift,
                            gpmControllerId: parseInt(value, 10)
                          }
                        }),
                        this.updateShift
                      )
                    }
                    value={shift.gpmControllerId}
                  />
                ) : (
                  convertEmployeeToString(shift.gpmController)
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Диспетчер">
                {canEdit ? (
                  <StyledEmployeeSelect
                    placeholder="Выберите диспетчера"
                    filter={{
                      positions: positionEnum.dispatcher,
                      nodeId: employeeBranchOrgUnitId,
                      nodeFilterType: 'branchAndChildren'
                    }}
                    onChange={(dispatcherId: number) =>
                      this.setState(
                        prevState => ({
                          // $FlowFixMe не хватает параметров
                          shift: {
                            ...prevState.shift,
                            dispatcherId
                          }
                        }),
                        this.updateShift
                      )
                    }
                    value={shift.dispatcherId}
                  />
                ) : (
                  convertEmployeeToString(shift.dispatcher)
                )}
              </Field>
            </GridItem>
          </Grid>
        </WorkersSection>
        {showPrintPanel && (
          <PrintStyledSection>
            Вы&nbsp;
            {printTripIds.length > 0 ? (
              <>
                выбрали <BlueText>{printTripIds.length}</BlueText>
                &nbsp;
                {this.tripPhraseByCount(printTripIds.length)}
                .&nbsp;
                <BlueText
                  pointer={true}
                  onClick={async () => await this.printTrips('selected')}
                >
                  Распечатать выбранные
                </BlueText>
              </>
            ) : (
              <>не выбрали путевых листов для печати</>
            )}
            &nbsp;|&nbsp;
            <BlueText
              pointer={true}
              onClick={async () => await this.printTrips('all')}
            >
              Распечатать все
            </BlueText>
          </PrintStyledSection>
        )}

        <Wrapper>
          {shift.trips &&
            shift.trips.map((trip: Trip) => (
              <TripShortCard
                key={trip.id}
                trip={trip}
                onPrint={this.onPrintOneTrip}
                changeStatus={this.changeStatus}
                canEdit={
                  [tripStatusEnum.draft, tripStatusEnum.created].includes(
                    trip.status
                  ) && canEdit
                }
                updateTrip={async (trip: Trip): Promise<boolean> => {
                  let res = false;
                  try {
                    notificationLoading({
                      message: 'Сохранение данных...',
                      key: 'saving'
                    });
                    const updatedRoute = await this.props.updateTrip(trip);
                    this.props.updateShiftTrip(updatedRoute);
                    res = true;
                  } catch (err) {
                    notification.error({
                      message: 'Произошла ошибка при обновлении',
                      description: err ? err.message : ''
                    });
                  } finally {
                    notification.close('saving');
                  }
                  return res;
                }}
                canPrint={this.canPrint(trip)}
                onSelectToPrint={this.toggleTripToPrint}
                checkedToPrint={this.isCheckedToPrint(trip.id)}
              />
            ))}
          {shift.orders &&
            shift.orders.map((order: Order) => (
              <OrderShortCard key={order.id} order={order} />
            ))}
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props) => ({
  shift: state.shift,
  shiftId: parseInt(props.shiftId, 10)
});

export default connect(mapStateToProps, {
  fetchShift,
  cleanShift,
  updateShift,
  changeTripStatus: changeStatus,
  updateShiftTrip,
  updateTrip
})(withUserAccess(ShiftCard));
