// @flow
import React from 'react';
import {connect} from 'react-redux';

import notification from 'antd/lib/notification';

import type {AppState} from '../../../ducks/redux';

import type {UserAccess, VehicleMonitoring} from '../../../lib/types';
import {vehicleMonitoringApi} from '../../../lib/api';
import {formatDateRangeString, formatDateTimeToString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';

import {Header} from '../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Icon} from '../../../components/ui';
import Section from '../../../components/layout/Section';
import Field from '../../../components/card/Field';
import Grid, {GridItem} from '../../../components/layout/Grid';

import {ActInfo} from './../../Act';

import {Panel, SectionContent} from '../elements';
import {canHandleService} from '../lib';

type Props = {
  id: number,
  userAccess: UserAccess[]
};

type State = {
  vehicleMonitoring: VehicleMonitoring
};

class VehicleMonitoringCard extends React.Component<Props, State> {
  state = {
    vehicleMonitoring: {}
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const vehicleMonitoring = await vehicleMonitoringApi.fetchVehicleMonitoring(
        parseInt(id, 10)
      );
      this.setState({ vehicleMonitoring });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  render() {
    const { id, userAccess } = this.props;
    const { vehicleMonitoring } = this.state;

    const canHandle = canHandleService(userAccess);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/services/vehicles-monitoring')}
              >
                Список актов по мониторингу ТС
              </Crumb>
              <Crumb>Акт по мониторингу №{id}</Crumb>
            </Breadcrumbs>
          }
          right={
            canHandle && (
              <Icon
                onClick={() =>
                  navigate(`/services/vehicles-monitoring/${id}/edit`)
                }
                size={16}
                style={{ cursor: 'pointer' }}
                type="edit"
              />
            )
          }
        />
        <Panel>
          <h1>Акт по мониторингу №{id}</h1>
        </Panel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Кол-во ТС">
                  {vehicleMonitoring.countVehicle} шт
                </Field>
              </GridItem>

              {!!vehicleMonitoring.maintenanceDate && (
                <GridItem>
                  <Field label="Дата оказания">
                    {formatDateTimeToString(
                      vehicleMonitoring.maintenanceDate,
                      'DD.MM.YYYY'
                    )}
                  </Field>
                </GridItem>
              )}

              <GridItem>
                <Field label="Период оказания">
                  {formatDateRangeString(
                    vehicleMonitoring.startDate,
                    vehicleMonitoring.endDate,
                    'D MMMM YYYY'
                  )}
                </Field>
              </GridItem>

              <GridItem>
                <Field label="Сумма, руб.">
                  {(vehicleMonitoring.paymentAmount || 0).toLocaleString(
                    'ru-RU',
                    {
                      style: 'currency',
                      currency: 'RUB'
                    }
                  )}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>

        {vehicleMonitoring && vehicleMonitoring.act && (
          <ActInfo act={vehicleMonitoring.act} />
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: { id: string }) => ({
  id: parseInt(ownProps.id, 10),
  userAccess: state.auth.profile.access
}))(VehicleMonitoringCard);
