// @flow

import {SelectProps} from 'antd/lib/select';
import React from 'react';

import {roleApi} from '../../lib/api';
import type {Role} from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps) => (
  <AutocompleteSelect
    fetch={async params => {
      try {
        return await roleApi.fetchRoles({...selectProps.filter, ...params});
      } catch (e) {
        return {data: [], totalCount: 0};
      }
    }}
    fetchSingle={roleApi.fetchRole}
    notFoundText="Роли не найдены"
    placeholder="Выберите роль"
    valueKey="id"
    renderOption={(role: Role, Option) => (
      <Option key={role.id} value={role.id} role={role}>
        {role.name}
      </Option>
    )}
    {...selectProps}
  />
);
