import React from 'react';
import { notificationLoading } from './Notifications';
import { downloadRequestWithToken } from '../lib/api';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';

type Props = {
  url: string
};

// Компонент стандартной кнопки печати документа
// скачивает сформированный на беке файл
const PrintButton: React.FC<Props> = ({ url }) => (
  <Button
    onClick={async () => {
      try {
        notificationLoading({
          message: 'Формирование файла для печати',
          key: 'print'
        });
        await downloadRequestWithToken(url);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.title
        });
      } finally {
        notification.close('print');
      }
    }}
  >
    Печать
  </Button>
);

export default PrintButton;
