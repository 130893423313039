// @flow
import React, {Component} from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {ExpenseDirectionType} from './../../../../../../lib/types';
import {expenseDirectionTypeEnum, expenseDirectionTypes} from './../../../../../../lib/enum';
import {contractVehicleBudgetApi} from './../../../../../../lib/api';

import {Grid, Header, Section, SectionTitle, TopPanel} from './../../../../../../components/layout';
import Table from './../../../../../../components/ui/Table';
import {GridItem} from './../../../../../../components/layout/Grid';
import Breadcrumbs, {Crumb} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';

import {COLUMNS} from './elements';
import {getPathWithHistoryParams} from '../../../../../../lib/helpers';
import {CognosType} from '../../../ContractVehicleBudget';

type Props = {
  contractVehicleBudgetId: number,
  contractVehiclePlanId: number,
  type: string,
  cognosType: $Keys<CognosType>
};
type State = {
  expenseDirectionType: ?ExpenseDirectionType,
  budgetDetails: any[],
  loading: boolean
};

const Content = styled.div`
  padding: 16px;
`;

export default class extends Component<Props, State> {
  state = {
    expenseDirectionType: this.getType(),
    budgetDetails: [],
    loading: false
  };

  componentDidMount() {
    this.fetch();
  }

  fetch = async () => {
    const { contractVehicleBudgetId } = this.props;
    const { expenseDirectionType } = this.state;
    this.setState({ loading: true });
    try {
      if (expenseDirectionType) {
        const {
          budgetDetails
        } = await contractVehicleBudgetApi.getExpenseDirectionBudget(
          contractVehicleBudgetId,
          expenseDirectionType
        );
        this.setState({ budgetDetails, loading: false });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
    this.setState({ loading: false });
  };

  getType() {
    switch (this.props.type) {
      case 'car-services':
        return expenseDirectionTypeEnum.carServices;
      case 'other-vehicle-services':
        return expenseDirectionTypeEnum.otherVehicleServices;
      case 'acs-vehicle-services':
        return expenseDirectionTypeEnum.acsVehicleServices;
      case 'cargo-services':
        return expenseDirectionTypeEnum.cargoServices;
      case 'mechanism-services':
        return expenseDirectionTypeEnum.mechanismServices;
      case 'vehicle-services-from-profit':
        return expenseDirectionTypeEnum.vehicleServicesFromProfit;
      default:
    }
    return null;
  }

  render() {
    const { contractVehiclePlanId, cognosType } = this.props;
    const { loading, budgetDetails, expenseDirectionType } = this.state;
    const expenseDirectionName = expenseDirectionType
      ? expenseDirectionTypes[expenseDirectionType]
      : '';
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams(
                  `/budget/contract-vehicle/fixed/${cognosType}`
                )}
              >
                Закрепленные НТС
              </Crumb>
              <Crumb
                to={`/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget`}
              >
                Бюджет
              </Crumb>
              <Crumb>Направление</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Направление: {expenseDirectionName}</h1>
        </TopPanel>
        <Section>
          <SectionTitle divider>
            Расшифровка затрат по направлению затрат
          </SectionTitle>
          <Content>
            <Grid gutter="16px" cols="5">
              <GridItem>
                <Field label="Направление расходов">
                  {expenseDirectionName}
                </Field>
              </GridItem>
            </Grid>
          </Content>
          <Table columns={COLUMNS} data={budgetDetails} loading={loading} />
        </Section>
      </>
    );
  }
}
