// @flow
import React, {Component} from 'react';

import styled from 'styled-components';
import moment from 'moment';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';

import type {LicensePlateChange} from './../../../lib/types';
import {licensePlateChangeApi} from './../../../lib/api';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from './../../../lib/helpers';

import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Form, Selects} from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  licensePlateChange: LicensePlateChange,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  licensePlateChange,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={licensePlateChange} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const { handleSubmit, handleBlur, setFieldValue, dirty } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    required
                    label="Дата замены"
                    name="maintenanceDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  licensePlateChangeId: ?number,
  vehicleId: ?number
};

type State = {
  licensePlateChange: $Shape<LicensePlateChange>
};

class LicensePlateChangeForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const licensePlateChangeId = parseInt(
        this.props.licensePlateChangeId,
        10
      );
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (licensePlateChangeId) {
        const licensePlateChange = await licensePlateChangeApi.fetchLicensePlateChange(
          licensePlateChangeId
        );
        this.setState({
          licensePlateChange
        });
      } else if (vehicleId) {
        this.setState({
          licensePlateChange: {
            vehicleId
          }
        });
      } else {
        this.setState({
          licensePlateChange: {}
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.licensePlateChangeId) => {
    if (this.props.vehicleId) {
      navigate(
        `/vehicles/${this.props.vehicleId}/maintenance/license-plate-changes`
      );
    } else {
      if (id) {
        navigate(`/services/license-plate-changes/${id}`);
      } else {
        navigate(`/services/license-plate-changes/`, true);
      }
    }
  };

  onSubmit = async (licensePlateChange: LicensePlateChange) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const licensePlateChangeId = parseInt(
        this.props.licensePlateChangeId,
        10
      );
      if (licensePlateChangeId) {
        await licensePlateChangeApi.updateLicensePlateChange(
          licensePlateChange
        );
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(licensePlateChangeId);
      } else {
        const added = await licensePlateChangeApi.addLicensePlateChange(
          licensePlateChange
        );

        notification.success({
          message: 'Успешное добавление',
          description: `Замена гос. номера успешно добавлена`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const licensePlateChangeId = parseInt(this.props.licensePlateChangeId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { licensePlateChange } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId > 0 ? (
                <Crumb
                  to={`/vehicles/${vehicleId}/maintenance/license-plate-changes`}
                >
                  ТС
                </Crumb>
              ) : (
                <Crumb
                  to={getPathWithHistoryParams(
                    '/services/license-plate-changes'
                  )}
                >
                  Список замен гос. номеров
                </Crumb>
              )}
              {licensePlateChangeId ? (
                <Crumb>Редактирование замены гос. номера</Crumb>
              ) : (
                <Crumb>Новая замена гос. номера</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {licensePlateChangeId
              ? `Замена гос. номера №${licensePlateChangeId}`
              : 'Новая замена гос. номера'}
          </h1>
        </StyledPanel>
        <InnerForm
          licensePlateChange={{
            paymentAmount: 0,
            ...licensePlateChange
          }}
          onSubmit={this.onSubmit}
          onCancel={() =>
            this.redirect(licensePlateChange.id || licensePlateChangeId)
          }
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default LicensePlateChangeForm;
