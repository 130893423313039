// @flow
import React from 'react';

import notification from 'antd/lib/notification';
import {connect} from 'react-redux';
import {FormikProps} from 'formik';
import Input from 'antd/lib/input/Input';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import * as yup from 'yup';

import {Header, Section} from '../../../components/layout';
import {cleanVehicle, fetchVehicle, updateVehicle} from '../../../ducks/vehicle';
import type {UserAccess, Vehicle} from '../../../lib/types';
import type {AppState} from '../../../ducks/redux';
import Grid, {GridItem} from '../../../components/layout/Grid';
import {Form, Selects} from '../../../components';
import {formatDateTimeToISOString as changeDate, navigate} from './../../../lib/helpers';
import {getDisabledDate} from '../lib';
import {notificationLoading} from '../../../components/Notifications';

import {LicensePlateInput, PTSInput, STSInput} from '../../../components/masked-inputs';
import {licensePlateValidate, ptsValidate, stsValidate} from '../../../lib/validation';
import CancelButton from '../../../components/CancelButton';
import {withUserAccess} from '../../withUserAccess';
import {accessTypeEnum} from '../../../lib/enum';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';
import {mdmApi} from '../../../lib/api';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const { StouSelect, YesNoSelect } = Selects;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function,
  userAccess: UserAccess[]
};

class Numbers extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.isLease) {
        values.otherDocumentKind = null;
        values.otherDocumentSerialNumber = null;
        values.otherDocumentIssuedDate = null;
        values.nomenclatureNumber = null;
      }
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/documentation/numbers`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/documentation/numbers`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {}, userAccess } = this.props;
    return (
      <>
        <Header left={vehicle && <h1>Номера {vehicle.licensePlate}</h1>} />
        <Form
          initialValues={vehicle}
          onSubmit={this.handleSubmit}
          validationSchema={yup.object().shape({
            licensePlate: licensePlateValidate.nullable(),
            sts: stsValidate.nullable(),
            pts: ptsValidate.nullable()
          })}
        >
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
              values,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem fullWidth>
                      <FormField
                        label="Нерегистрируемое"
                        name="unregistered"
                        defaultValue={false}
                      >
                        {({ value, name }) => (
                          <YesNoSelect
                            value={value}
                            name={name}
                            onChange={(value: boolean) =>
                              setFieldValue(name, value)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="VIN"
                        name="vin"
                        required={!values.serialNumber && !values.unregistered}
                      >
                        {({ value, name }) => (
                          <Input
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={17}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        required={!values.vin && !values.unregistered}
                        label="Серийный номер"
                        name="serialNumber"
                      >
                        {field => (
                          <Input
                            {...field}
                            placeholder="Введите серийный номер"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="licensePlate"
                        label="Гос. номер"
                        required={!values.unregistered}
                      >
                        {field => <LicensePlateInput {...field} />}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Инвентарный номер"
                        fast
                        name="inventoryNumber"
                        required={values.isLease}
                      >
                        {({ value, name }) => (
                          <Input
                            placeholder="Введите инвентарный номер"
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label={values.isLease ? "Серия и номер АТС" : "Серия и номер СТС"}
                        name="sts"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <STSInput
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label={values.isLease ? "Дата выдачи АТС" : "Дата выдачи СТС"}
                        name="stsDate"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            /**
                             * Валидация, минимальное значение 01.01.1980,
                             * но не позже текущего дня
                             */
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Серия и номер ПТС"
                        name="pts"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <PTSInput
                            value={value}
                            name={name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата выдачи ПТС"
                        name="ptsDate"
                        required={!values.unregistered}
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField name="deviceId" label="ГЛОНАСС/GPS-трекер">
                        {field => (
                          <Input
                            {...field}
                            placeholder="Введите идентификатор блока"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="advantumId"
                        label="Идентификатор ТС в Адвантум"
                      >
                        {field => (
                          <Input
                            {...field}
                            placeholder="Введите идентификатор ТС в Адвантум"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Имя конфигурационного файла для АвтоГРАФ"
                        fast
                        name="configFileName"
                      >
                        {field => (
                          <Input
                            {...field}
                            placeholder="Например, БЭС - СМиА.ini"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        name="garageNumber"
                        fast
                        label="Гаражный номер"
                      >
                        {field => (
                          <Input
                            {...field}
                            placeholder="Введите гаражный номер"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Дата снятия с учета в ГИБДД"
                        name="deregistrationDate"
                        fast
                      >
                        {({ value, name }) => (
                          <DatePicker
                            format="DD.MM.YYYY"
                            value={this.formatDate(value)}
                            onChange={(value: Object, dateString: string) => {
                              setFieldValue(
                                name,
                                changeDate(value, dateString)
                              );
                            }}
                            disabledDate={(currentDate: moment) =>
                              getDisabledDate(currentDate, values.yearIssued)
                            }
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Мнемокод ТС по СТОУ"
                        name="mnemonicStou"
                        required={!values.isLease}
                        fast
                      >
                        {({ value, name }) => (
                          <StouSelect
                            disabled={
                              !userAccess.some(access =>
                                [
                                  accessTypeEnum.admin,
                                  accessTypeEnum.adminBranch
                                ].includes(access)
                              ) && values.inventoryCardParusId
                            }
                            value={value}
                            name={name}
                            onChange={(value: boolean, option: any) => {
                              setFieldValue(name, value);
                              if (option) {
                                setFieldValue(
                                  'typeStou',
                                  option.props.stou.name
                                );
                              }
                            }}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Тип ТС по СТОУ" name="typeStou">
                        {fieldProps => <Input disabled {...fieldProps} />}
                      </FormField>
                    </GridItem>
                    {!values.isLease && (
                      <>
                        <GridItem>
                          <FormField
                            fast
                            label="Префикс накладной"
                            name="otherDocumentKind"
                          >
                            {fieldProps => <Input {...fieldProps} />}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Номер накладной"
                            name="otherDocumentSerialNumber"
                          >
                            {fieldProps => <Input {...fieldProps} />}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Дата накладной"
                            name="otherDocumentIssuedDate"
                          >
                            {({ value, name }) => (
                              <DatePicker
                                format="DD.MM.YYYY"
                                value={value ? moment.utc(value) : value}
                                onChange={(value: Object, dateString: string) => {
                                  setFieldValue(
                                    name,
                                    changeDate(value, dateString)
                                  );
                                }}
                                disabledDate={(currentDate: moment) =>
                                  getDisabledDate(currentDate, values.yearIssued)
                                }
                              />
                            )}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField
                            fast
                            label="Мнемокод номенклатуры"
                            name="nomenclatureNumber"
                          >
                            {({ name, value }) => (
                              <AutocompleteSelect
                                fetch={mdmApi.fetchMdmList}
                                fetchSingle={() => null}
                                notFoundText="Код не найден"
                                value={value}
                                onChange={value => setFieldValue(name, value)}
                                renderOption={(item, Option) => (
                                  <Option value={item.mnemocode}>
                                    {item.mnemocode}
                                  </Option>
                                )}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </>
                    )}
                    <GridItem>
                      <FormField fast label="Является арендованным ТС" name="isLease" required>
                        {({ name, value }) => (
                          <YesNoSelect
                            value={value}
                            onChange={(value) => setFieldValue(name, value)}
                            placeholder={null}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(withUserAccess(Numbers));
