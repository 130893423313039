// @flow
import { omit } from 'lodash';
import {
  STSTypes,
  approveTypes,
  authorizeTypes,
  briefingViews,
  budgetLineItemBudget,
  budgetSummaryStatus,
  businessDayTypes,
  certificationAreas,
  chassisConfigurations,
  cognosLogStatus,
  contractDocumentType,
  contractVehicleType,
  driverStatuses,
  employeeCertificationViews,
  engineTypes,
  entityTypes,
  expenseDirectionCognosStatus,
  expenseDirectionDisplayRuleType,
  expenseDirectionTypes,
  fuelAndOilView,
  fuelGroups,
  fuelTypes,
  fuelTypesCalculation,
  fuelsAndGazTypes,
  insuranceClasses,
  leasers,
  maintenanceOperationStatuses,
  maintenanceStatusesTypes,
  maintenanceTypeOfWork,
  maintenanceTypeOils,
  maintenanceTypes,
  measureTypeFuelPrice,
  medicOwnerEnum,
  medicOwnerTypes,
  monthlyWashingPlanStatuses,
  monthsNamesTranslitEnum,
  operatingModes,
  operationType,
  orderContractorTypes,
  orderDispatcherTypes,
  orderObjectives,
  orderTypeEnum,
  orderTypes,
  orgUnitTypes,
  osagoCalculationStatuses,
  osagoTransportType,
  positions,
  pseudoNameTypes,
  rateType,
  repairPlanTypes,
  serviceOrderTypes,
  tariffTypes,
  taxRates,
  trainingViews,
  vehicleIssueTypes,
  vehicleOvbTypes,
  vehicleTariffStatus,
  vehicleTariffStatusWithoutDeactivated,
  washingStatusTypes,
  washingTypes,
  workHourPriceTypes,
} from '../../lib/enum';
import { RepairTypes, WorkSubTypes, WorkTypes } from '../../lib/types/maintenanceOperation';
import AutoGeneratedEnumSelect, { AutoGeneratedMultipleEnumSelect } from './AutoGeneratedEnumSelect';

export const ExpenseDirectionDisplayRuleTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите способ',
  autoType: expenseDirectionDisplayRuleType
});

export const OperationTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите способ выполнения',
  autoType: operationType
});
export const AuthorizeTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите группу дефекта',
  autoType: authorizeTypes
});
// Выпадающий список типов дня производственного календаря
export const BusinessDayTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип дня',
  autoType: businessDayTypes
});
export const CertificationAreaSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите область аттестации',
  autoType: certificationAreas
});
// Выпадающий список колесных формул привода
export const ChassisConfigurationSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите колесную формулу',
  autoType: chassisConfigurations
});
export const ContractVehicleTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: contractVehicleType
});
// Выпадающий список видов инструктажа
export const DriverBriefingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: briefingViews
});
export const DriverStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус водителя',
  autoType: driverStatuses
});
// Выпадающий список видов инструктажа
export const EmployeeBriefingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: briefingViews
});
// Выпадающий список видов аттестаций
export const EmployeeCertificationViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: employeeCertificationViews
});
// Выпадающий список типов двигателя
export const EngineTypeSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: engineTypes
});
// Выпадающий список типов сущностей
export const EntityTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип события',
  autoType: entityTypes
});
export const ExpenseDirectionTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип направления',
  autoType: expenseDirectionTypes
});
// Выпадающий список типов топлива
export const FuelGroupSelect = AutoGeneratedEnumSelect({
  placeholder: 'Введите вид топлива',
  autoType: fuelGroups
});
// Выпадающий список типов топлива
export const FuelTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: fuelTypes
});
export const FuelTypesSelect = AutoGeneratedMultipleEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: fuelTypes
});
export const FuelTypeCalculationSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: fuelTypesCalculation
});
export const InsuranceClassSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите класс годового срока страхования',
  autoType: insuranceClasses
});
export const MaintenanceOperationStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: maintenanceOperationStatuses
});
export const MaintenanceStatusesTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус ремонта',
  autoType: maintenanceStatusesTypes
});
export const MaintenanceTypeOfWorkSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип для видов работ',
  autoType: maintenanceTypeOfWork
});
export const MaintenanceTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: maintenanceTypes
});
export const MeasureTypeFuelPriceSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: measureTypeFuelPrice
});
// Выпадающий список видом мед. работников
export const MedicOwnerTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип мед. работника',
  value: medicOwnerEnum.own,
  autoType: medicOwnerTypes
});
export const MonthlyWashingPlanStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: monthlyWashingPlanStatuses
});
export const OperatingModeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите режим эксплуатации',
  autoType: operatingModes
});
export const OrderContractorTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: orderContractorTypes
});
export const OrderObjectiveSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите цель поездки',
  autoType: orderObjectives
});
export const OrderTypeDispatcherSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид заявок',
  autoType: orderDispatcherTypes
});
// Тип заявки "Дополнительная" является устаревшей и мы не должны ее показывать
export const OrderTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип заявки',
  autoType: omit(orderTypes, orderTypeEnum.additional)
});
export const OsagoCalculationStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: osagoCalculationStatuses
});
export const OsagoTransportTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип транспорта',
  autoType: osagoTransportType
});
export const PositionSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите обязанности сотрудника',
  autoType: positions
});
export const VehicleTariffStatusWithoutDeactivatedSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: vehicleTariffStatusWithoutDeactivated
});
export const VehicleTariffStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: vehicleTariffStatus
});
export const RateTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: rateType
});
export const RepairePlanSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: repairPlanTypes
});
export const ServiceOrderTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: serviceOrderTypes
});
export const STSTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип СТС',
  autoType: STSTypes
});
export const TariffTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип тарифа',
  autoType: tariffTypes
});
// Выпадающий список видов обучения
export const TrainingViewSelect = AutoGeneratedEnumSelect({
  placeholder: '',
  autoType: trainingViews
});
export const VehicleIssueTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите группу дефекта',
  autoType: vehicleIssueTypes
});
export const VehicleOvbTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите режим работы ОВБ',
  autoType: vehicleOvbTypes
});
export const WashingStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус мойки',
  autoType: washingStatusTypes
});
export const WashingTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид мойки',
  autoType: washingTypes
});
export const WorkHourPriceSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип нормо-часа',
  autoType: workHourPriceTypes
});
export const ApproveYesNoSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: approveTypes
});
export const PseudoNameSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите псевдо имя',
  autoType: pseudoNameTypes
});
export const MonthsSelect = AutoGeneratedMultipleEnumSelect({
  placeholder: 'Выберите месяцы',
  autoType: monthsNamesTranslitEnum
});
export const MonthSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите месяц',
  autoType: monthsNamesTranslitEnum
});
export const BudgetSummaryStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: budgetSummaryStatus
});
export const MaintenanceOilsSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: maintenanceTypeOils
});
export const ContractDocumentSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: contractDocumentType
});
export const BudgetLineItemBudgetTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: budgetLineItemBudget
});
export const FuelsAndGazTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите тип',
  autoType: (({ai98, ...rest}) => rest)(fuelsAndGazTypes) // Rem ai98 from select
});
export const FuelAndOilViewSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид ГСМ',
  autoType: fuelAndOilView
});
export const TaxRateSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите ставку НДС',
  autoType: taxRates
});
export const CognosLogStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: cognosLogStatus
});
export const ExpenseDirectionCognosStatusSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите статус',
  autoType: expenseDirectionCognosStatus
});
export const RepairTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Вид ремонта',
  autoType: RepairTypes
});
export const WorkTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Тип работы',
  autoType: WorkTypes
});
export const WorkSubTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Подтип работы',
  autoType: WorkSubTypes
});
export const OrgUnitTypesSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид подразделения',
  autoType: orgUnitTypes
});
export const LeasersSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите арендодателя',
  autoType: leasers
});
