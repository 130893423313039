// @flow
import {Button, InputNumber} from 'antd';
import Icon from 'antd/lib/icon';

import Modal from 'antd/lib/modal';
import {FormikProps} from 'formik';
import React, {useState} from 'react';
import styled from 'styled-components';
import {Form} from '../../../../../components';
import {Grid, GridItem} from '../../../../../components/layout';
import Selects from '../../../../../components/selects';
import {TaxRateSelect} from '../../../../../components/selects/AutoGeneratedEnumSelects';
import {Table} from '../../../../../components/ui';
import {TableHeader} from '../../../../../components/ui/Table';

import {
  cognosContractMonthEnum,
  ISUBudgetingData,
  ISUBudgetingDataTypeEnum,
  monthsNamesTranslitEnum,
  taxRates,
} from '../../../../../lib/enum';
import {plus} from '../../../../../lib/helpers';

import type {CognosContract, CognosContractCoefMonth, CognosContractMonths} from '../../../../../lib/types';

const {ISUBudgetingSelect} = Selects;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  margin: 2px;
  padding: 0;
  background: ${props =>
          props.colored ? 'rgba(39, 112, 255, 0.1)' : undefined};
`;

const StyledTable = styled(Table)`
  // Фикс для хрома, для отображениия текста в ячейке, в которой есть стрелка
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-overflow: initial;
    padding: 2px 4px;
  }

  // Да простят меня боги, но без этого в дьявольском хроме все летит к херам
  .ant-table-scroll > div.ant-table-header > table,
  .ant-table-scroll > div.ant-table-body > table {
    width: 100% !important;
  }
`;
export type ModalProps<T> = {
  visible: boolean,
  data: T,
  onSave?: Function,
  onCancel?: Function,
  fullMode?: boolean
};

export const MONTH = [
  {type: cognosContractMonthEnum.pastYear, name: 'december', title: 'Декабрь (предыдущий год)'},
  {type: cognosContractMonthEnum.currentYear, name: 'january', title: 'Январь'},
  {type: cognosContractMonthEnum.currentYear, name: 'february', title: 'Февраль'},
  {type: cognosContractMonthEnum.currentYear, name: 'march', title: 'Март'},
  {type: cognosContractMonthEnum.currentYear, name: 'april', title: 'Апрель'},
  {type: cognosContractMonthEnum.currentYear, name: 'may', title: 'Май'},
  {type: cognosContractMonthEnum.currentYear, name: 'june', title: 'Июнь'},
  {type: cognosContractMonthEnum.currentYear, name: 'july', title: 'Июль'},
  {type: cognosContractMonthEnum.currentYear, name: 'august', title: 'Август'},
  {type: cognosContractMonthEnum.currentYear, name: 'september', title: 'Сентябрь'},
  {type: cognosContractMonthEnum.currentYear, name: 'october', title: 'Октябрь'},
  {type: cognosContractMonthEnum.currentYear, name: 'november', title: 'Ноябрь'},
  {type: cognosContractMonthEnum.currentYear, name: 'december', title: 'Декабрь'},
  {type: cognosContractMonthEnum.nextYear, name: 'january', title: 'Январь (следующий год)'},
];

export default (props: ModalProps<CognosContract>) => {
  const {visible, onCancel, onSave} = props;

  const [canSave, setCanSave] = useState(true);

  const fullMode = props.data?.id && props.fullMode;

  return (
    (
      <Modal
        centered
        destroyOnClose
        width={!fullMode ? '40%' : '95%'}
        title={!fullMode
          ? props.data?.id
            ? props.data?.contractNumber
            : 'Новый договор'
          : 'Коэффициенты инкассации'
        }
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button key="back" onClick={onCancel}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            form="modal-form"
            htmlType="submit"
            disabled={!canSave}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form initialValues={props.data} onSubmit={onSave}>
          {(FormField, formikProps: FormikProps) => {
            const {setFieldValue, handleSubmit, values} = formikProps;
            let canSave = true;

            // eslint-disable-next-line
            values.cognosContractMonths?.forEach(
              (item: CognosContractMonths) => {
                let totalCoef = 0;
                item.cognosContractCoefMonths.forEach(
                  (itemCoef: CognosContractCoefMonth) => {
                    totalCoef = plus(itemCoef.coef ?? 0, totalCoef);
                  },
                );
                item.totalCoef = totalCoef;
                item.totalWarn = totalCoef !== 1 && totalCoef !== 0;
                canSave = canSave && !item.totalWarn;
              },
            );

            setCanSave(canSave);
            return (
              <form onSubmit={handleSubmit} id="modal-form">
                <Grid gutter="16px" cols={2}>
                  <GridItem>
                    <FormField
                      label={ISUBudgetingData.activityDirection}
                      name={fullMode
                        ? 'isuBudgetingData.name'
                        : `${ISUBudgetingDataTypeEnum.isuBudgetingDataId}`
                      }
                      required={!props.data?.id}
                    >
                      {({name, value}) =>
                        fullMode
                          ? value
                          : (
                            <ISUBudgetingSelect
                              name={name}
                              value={value}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                            />
                          )
                      }
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Ставка НДС"
                      name="taxRate"
                      required={!props.data?.id}
                    >
                      {({name, value}) =>
                        fullMode ? (
                          taxRates[value]
                        ) : (
                          <TaxRateSelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                          />
                        )
                      }
                    </FormField>
                  </GridItem>
                </Grid>
                {fullMode && (
                  <StyledTable
                    data={values.cognosContractMonths}
                    columns={[
                      {
                        title: 'Период начисления',
                        dataIndex: 'monthType',
                        render: monthType => {
                          return monthsNamesTranslitEnum[monthType];
                        },
                        width: 150,
                      },
                      {
                        title: 'Период оплаты',
                        children: [
                          ...MONTH.map((month, index) => {
                            return {
                              title: (
                                <TableHeader width="80px">
                                  {month.title}
                                </TableHeader>
                              ),
                              dataIndex: `cognosContractCoefMonths[${index}]`,
                              key: `cognosContractCoefMonths[${index}]`,
                              render: (coef, row, rowIndex) => {
                                return (row.sum > 0 && (
                                  <FormField
                                    name={`cognosContractMonths[${rowIndex}].cognosContractCoefMonths[${index}].coef`}
                                    key={index}
                                  >
                                    {({name, value}) => (
                                      <StyledInputNumber
                                        size="small"
                                        value={value || null}
                                        onChange={value => setFieldValue(name, value || null)}
                                        disabled={!row.isOpenPeriod}
                                        step={0.1}
                                        min={0}
                                        max={1}
                                        colored={row.sum > 0}
                                      />
                                    )}
                                  </FormField>
                                ));
                              },
                            };
                          }),
                        ],
                      },
                      {
                        title: 'Итого',
                        dataIndex: 'totalCoef',
                        render: (totalCoef, row) => {
                          return (
                            <>
                              {totalCoef}
                              {!!row?.totalWarn && (
                                <Icon
                                  style={{paddingLeft: '10px'}}
                                  type="exclamation-circle"
                                  theme="twoTone"
                                  twoToneColor="red"
                                />
                              )}
                            </>
                          );
                        },
                      },
                    ]}
                    pagination={false}
                    bordered
                    rowKey="id"
                    style={{
                      overflow: 'auto',
                    }}
                    step={0.1}
                    scroll={{
                      y: 'calc(60vh)',
                      x: 'auto',
                    }}
                  />
                )}
              </form>
            );
          }}
        </Form>
      </Modal>
    ) ?? null
  );
};
