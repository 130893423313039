// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {notificationLoading} from '../../components/Notifications';
import {TypesOfRepairsApi} from '../../lib/api';
import {RepairKind} from '../../lib/types/typesOfRepairs';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};

type State = {
  typeOfRepair: ?RepairKind,
};

export class TypesOfRepairsForm extends React.Component<Props, State> {
  state = {
    typeOfRepair: null,
  };
  
  async componentDidMount() {
    const {id} = this.props;
    try {
      if (id) {
        const typeOfRepair = await TypesOfRepairsApi.get(id);
        this.setState({typeOfRepair});
      } else {
        this.setState({typeOfRepair: {}});
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
  }
  
  handleSubmit = async (values: RepairKind) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        await TypesOfRepairsApi.update(values);
      } else {
        await TypesOfRepairsApi.add(values);
      }
      notification.success({
        message: 'Запись добавлена',
      });
      await navigate(`/admin/types-of-repairs`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  handleCancel = () => navigate('/admin/types-of-repairs/', true);
  
  render() {
    const {typeOfRepair} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/types-of-repairs/')}
              >
                Виды ремонта
              </Crumb>
              {typeOfRepair?.id ? (
                <Crumb>Вид ремонта №{typeOfRepair.id}</Crumb>
              ) : (
                <Crumb>Новый вид ремонта</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {typeOfRepair?.id
              ? `Вид ремонта №${typeOfRepair.id}`
              : 'Новый вид ремонта'}
          </h1>
        </StyledPanel>
        <InnerForm
          typeOfRepair={typeOfRepair}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default TypesOfRepairsForm;
