import { accessTypeEnum } from './../../lib/enum';

const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];

export const viewingExternalActs = [
  ...adminAccess,
  accessTypeEnum.viewingExternalActs,
  accessTypeEnum.viewingAllExternalActs
];

export const handlingExternalActs = [
  ...adminAccess,
  accessTypeEnum.handlingExternalActs
];

export const approvingExternalActs = [
  ...adminAccess,
  accessTypeEnum.approvingExternalActs
];

export const viewingInternalActs = [
  ...adminAccess,
  accessTypeEnum.viewingInternalActs,
  accessTypeEnum.viewingAllInternalActs,
  accessTypeEnum.viewingBranchFactFuelConsumption,
  accessTypeEnum.viewingAllFactFuelConsumption
];
export const handlingInternalActs = [
  ...adminAccess,
  accessTypeEnum.handlingInternalActs
];
export const approvingInternalActs = [
  ...adminAccess,
  accessTypeEnum.approvingInternalActs
];

export default {
  viewingExternalActs,
  handlingExternalActs,
  viewingInternalActs,
  handlingInternalActs
};
