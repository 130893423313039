// @flow
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { OutsourcingAct } from './../types';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataFromServer = (outsourcingAct: OutsourcingAct) => ({
  ...outsourcingAct,
  sum: convertFromKopToRub(outsourcingAct.sum),
  workHoursPrice: convertFromKopToRub(outsourcingAct.workHoursPrice)
});

export const convertDataToServer = (outsourcingAct: OutsourcingAct) => ({
  ...outsourcingAct,
  sum: convertFromRubToKop(outsourcingAct.sum),
  workHoursPrice: convertFromRubToKop(outsourcingAct.workHoursPrice)
});

export const deleteOutsourcingAct = async (
  id: number
): Promise<OutsourcingAct> => {
  const deleted = await fetchRequest.delete(`/outsourcingAct/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить акт оказания услуг');
};

export const addOutsourcingAct = async (
  outsourcingAct: OutsourcingAct
): Promise<OutsourcingAct> => {
  const added = await fetchRequest.post(
    '/outsourcingAct',
    convertDataToServer(outsourcingAct)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить акт оказания услуг');
};

export const updateOutsourcingAct = async (
  outsourcingAct: OutsourcingAct
): Promise<OutsourcingAct> => {
  const updated = await fetchRequest.put(
    '/outsourcingAct',
    convertDataToServer(outsourcingAct)
  );
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось отредактировать акт оказания услуг');
};

export const fetchOutsourcingAct = async (
  params: FetchListParams<OutsourcingAct> = initialFetchListParams
): Promise<ListResponse<OutsourcingAct>> => {
  const outsourcingAct = await fetchRequest.get('/outsourcingAct', {
    ...initialFetchListParams,
    ...params
  });
  if (outsourcingAct)
    return {
      ...outsourcingAct,
      data: outsourcingAct.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось получить акты оказания услуг');
};

export const getOutsourcingAct = async (
  id: number
): Promise<OutsourcingAct> => {
  const outsourcingAct = await fetchRequest.get(`/outsourcingAct/${id}`);
  if (outsourcingAct) return convertDataFromServer(outsourcingAct);
  throw new Error('Не удалось получить акт оказания услуг');
};

export default {
  deleteOutsourcingAct,
  addOutsourcingAct,
  updateOutsourcingAct,
  getOutsourcingAct,
  fetchOutsourcingAct
};
