// @flow
import React, {useCallback, useState} from 'react';

import {navigate} from '../../lib/helpers';
import {deadlinesForSendingDataToCognosApi} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {type CommonFormProps} from '../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import {DeadlinesForSendingDataToCognosItem} from '../../lib/types/deadlinesForSendingDataToCognos';
import {InputNumber} from 'antd';

type PageProps = { id: number };

const entryPointPath = '/admin/working-with-budgeting/';

const InnerForm = (
  props: CommonFormProps<$Shape<DeadlinesForSendingDataToCognosItem>>,
) => {
  return (
    <CommonForm {...props}>
      {({setFieldValue}) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Дата начала"
                  fast
                  required
                  name="startMonthDay"
                  hasFeedback={false}
                >
                  {({name, value}) => (
                    <InputNumber
                      min={1}
                      max={31}
                      onChange={value => setFieldValue(name, value)}
                      style={{width: '100%'}}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Дата окончания"
                  fast
                  required
                  name="endMonthDay"
                  hasFeedback={false}
                >
                  {({name, value}) => (
                    <InputNumber
                      min={1}
                      max={31}
                      onChange={value => setFieldValue(name, value)}
                      style={{width: '100%'}}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: DeadlinesForSendingDataToCognosItem, setData] = useState({id: props.id});
  
  const breadCrumbs = [
    {
      to: entryPointPath,
      name: 'Сроки отправки данных в ИСУ "Бюджетирование"',
    },
    {
      name: props.id ? props.id : 'Новая запись',
    },
  ];
  
  const onFetch = useCallback(async () => {
    !!props.id &&
    setData(await deadlinesForSendingDataToCognosApi.get(props.id));
  }, [props.id]);
  
  const onSubmit = async (payload: DeadlinesForSendingDataToCognosItem) => {
    let id = payload.id;
    if (id) {
      await deadlinesForSendingDataToCognosApi.update(payload);
    } else {
      await deadlinesForSendingDataToCognosApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };
  
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Сроки отправки фактических данных в ИСУ «Бюджетирование»',
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
