// @flow
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {ButtonsRow} from '../../../../../../components/ui';
import businessCalendarForBudget from '../../../../../../lib/api/businessCalendarForBudget';
import {printNotification} from '../../../../../../lib/notificationWrapper';
import {entityStatusEnum, regulationLimitTypeEnum} from '../../../../../../lib/enum';
import {
  assignmentApi,
  assignmentLimitGroupApi,
  contractVehicleBudgetApi,
  contractVehiclePlanApi,
} from './../../../../../../lib/api';
import type {
  Assignment,
  AssignmentLimit,
  AssignmentLimitGroup,
  BusinessCalendarForBudget,
  ContractVehiclePlan,
  EntityStatusType,
  RegulationLimitType,
  UserAccess,
} from '../../../../../../lib/types';
import {Header, Section, SectionTitle, TopPanel} from './../../../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../../../../../components/Notifications';
import {withUserAccess} from './../../../../../withUserAccess';
import Tabs from './../components/Tabs';
import {approveAccessRightContractVehicleBudget, handlingAccessRightContractVehicleBudget} from '../../accessRight';
import AssignmentGroups from './AssignmentGroups';
import Input from 'antd/lib/input';
import Popconfirm from 'antd/lib/popconfirm';
import {WorkDaysCalendarContext} from './components/WorkDaysCalendarContext';
import {CognosType} from '../../../ContractVehicleBudget';

const SectionContent = styled.div`
  padding: 16px;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  contractVehiclePlanId: number,
  userAccess: UserAccess[],
  cognosType: $Keys<CognosType>
};

type State = {
  // несохраненные изменения
  hasChanges: {
    ovb: boolean,
    employee: boolean,
    oneOffRequests: boolean,
    orgUnit: boolean
  },
  // перечень тс
  contractVehiclePlan: ?ContractVehiclePlan,
  // закрепление
  assignment: ?Assignment,
  // Группы лимитов служб
  orgUnit: AssignmentLimitGroup[],
  // Группы лимитов должностным лицам
  employee: AssignmentLimitGroup[],
  // Группа лимитов ОВБ
  ovb: AssignmentLimitGroup[],
  // Группа лимитов разовых заявок
  oneOffRequests: AssignmentLimitGroup[],
  // Флаг состояния загрузки
  loading: boolean,
  // Календарь рабочих и нерабочих дней в месяце
  workDaysCalendar: BusinessCalendarForBudget[],
  editMode: boolean,
  // бюджет в статусе "черновик"
  budgetIsCreated: boolean
};

class Assignments extends React.Component<Props, State> {
  state = {
    contractVehiclePlan: null,
    assignment: null,
    orgUnit: [],
    employee: [],
    ovb: [],
    oneOffRequests: [],
    loading: false,
    workDaysCalendar: [],
    hasChanges: {
      ovb: false,
      employee: false,
      oneOffRequests: false,
      orgUnit: false
    },
    editMode: false,
    budgetIsCreated: false
  };

  async componentDidMount() {
    await this.fetch();
  }

  async updateContractVehiclePlan() {
    const { contractVehiclePlan } = this.state;
    try {
      await contractVehiclePlanApi.update(contractVehiclePlan);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    }
  }

  setChanges = (type: string) => (value: boolean) => {
    this.setState({
      hasChanges: {
        ...this.state.hasChanges,
        [type]: value
      }
    });
  };

  /**
   * Создает функцию изменения куска стейта по типу лимитов
   * @param type Тип лимитов
   */
  createChangeGroupsHandler = (type: RegulationLimitType) => (
    groups: AssignmentLimitGroup[]
  ) => {
    this.setState({ [(type: string)]: groups });
  };

  getContractVehiclePlan = async () => {
    const { contractVehiclePlanId } = this.props;
    try {
      return await contractVehiclePlanApi.get(contractVehiclePlanId);
    } catch (e) {
      notification.error({message: 'Ошибка', description: e.title || e.message});
    }
  };

  getAssignment = async (
    contractVehiclePlanId: number
  ): Promise<Assignment> => {
    return await assignmentApi.getByContractVehiclePlan(contractVehiclePlanId);
  };

  // редактируем assignmet для отображении записи с системном журнале
  updateAssignment = async () => {
    const assignment = await assignmentApi.update({
      ...this.state.assignment,
      status:
        this.state.assignment.status === entityStatusEnum.approved
          ? entityStatusEnum.approvement
          : this.state.assignment.status
    });
    this.setState({ assignment, budgetIsCreated: false });
  };

  /**
   * Подгрузка групп лимитов по типам
   */
  fetch = async () => {
    this.setState({ loading: true });

    const contractVehiclePlan = await this.getContractVehiclePlan();
    const assignment = await this.getAssignment(contractVehiclePlan.id);
    let budgetIsCreated = false;
    if (assignment?.status === entityStatusEnum.approved) {
      const contractVehicleBudget = await contractVehicleBudgetApi.getByContractVehiclePlan(
        contractVehiclePlan.id
      );
      if (contractVehicleBudget?.status === entityStatusEnum.created) {
        budgetIsCreated = true;
      }
    }
    const workDaysCalendar = (
      await businessCalendarForBudget.fetch({
        year: moment(contractVehiclePlan.startDate).year()
      })
    )?.data;

    await Promise.all(
      Object.keys(regulationLimitTypeEnum).map(async type => {
        const { data: groups } = await assignmentLimitGroupApi.fetch({
          assignmentId: assignment.id,
          'regulationLimitGroup.type': type,
          pageSize: 0
        });
        this.setState({ [(type: string)]: groups });
      })
    );
    this.setState({
      loading: false,
      contractVehiclePlan,
      assignment,
      workDaysCalendar,
      budgetIsCreated
    });
  };

  handleChangeStatus = (status: EntityStatusType) => async () => {
    let { contractVehiclePlan, assignment } = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (assignment) {
        assignment = await assignmentApi.changeStatus({
          ...assignment,
          status
        });
        if (status === entityStatusEnum.declined) {
          contractVehiclePlan = await contractVehiclePlanApi.update({
            ...contractVehiclePlan,
            status: entityStatusEnum.declined
          });
        }
        this.fetch();
        this.setState({
          assignment,
          contractVehiclePlan,
          editMode: false,
          budgetIsCreated: status === entityStatusEnum.approved
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  canApproved = () =>
    this.props.userAccess.some(access =>
      approveAccessRightContractVehicleBudget.includes(access)
    );

  canEditing = () =>
    this.props.userAccess.some(access =>
      handlingAccessRightContractVehicleBudget.includes(access)
    );

  handlePrint = () => {
    printNotification(
      async () =>
        await assignmentLimitGroupApi.print(this.state.assignment?.id ?? 0)
    );
  };

  checkData = () => {
    const { ovb, employee, orgUnit, oneOffRequests } = this.state;
    const check = (assignmentLimitGroups: AssignmentLimitGroup[]) => {
      let res = true;
      assignmentLimitGroups.forEach(
        (assignmentLimitGroup: AssignmentLimitGroup) => {
          assignmentLimitGroup.assignmentLimit.forEach(
            (assignmentLimit: AssignmentLimit) => {
              if (!assignmentLimit.contractVehicleId) {
                res = false;
              }
            }
          );
        }
      );
      return res;
    };

    return (
      check(ovb) && check(employee) && check(orgUnit) && check(oneOffRequests)
    );
  };

  render() {
    const {
      ovb,
      employee,
      orgUnit,
      oneOffRequests,
      loading,
      assignment,
      contractVehiclePlan,
      hasChanges,
      editMode,
      budgetIsCreated
    } = this.state;
    const { contractVehiclePlanId, cognosType } = this.props;
    const editing =
      ![entityStatusEnum.approved, entityStatusEnum.declined].includes(
        assignment && assignment.status
      ) && this.canEditing();

    const selectedVehiclesIdsMap = new Set();
    [
      ...this.state.employee,
      ...this.state.orgUnit,
      ...this.state.ovb,
      ...this.state.oneOffRequests
    ].forEach((assignmentLimitGroup: AssignmentLimitGroup) =>
      assignmentLimitGroup.assignmentLimit.map(
        (assignmentLimit: AssignmentLimit) =>
          selectedVehiclesIdsMap.add(assignmentLimit.contractVehicleId)
      )
    );
    const selectedVehiclesIds = [...selectedVehiclesIdsMap];
    return (
      <>
        <WorkDaysCalendarContext.Provider value={this.state.workDaysCalendar}>
          <Header
            left={
              <Breadcrumbs>
                <Crumb to={`/budget/contract-vehicle/fixed/${cognosType}`}>
                  Закрепленные НТС
                </Crumb>
                <Crumb>Перечень НТС</Crumb>
              </Breadcrumbs>
            }
            right={
              <ButtonsRow>
                {contractVehiclePlan?.status === entityStatusEnum.approved && (
                  <Button onClick={this.handlePrint}>Печать</Button>
                )}
              </ButtonsRow>
            }
          />
          <TopPanel>
            <h1>Планируемый перечень ТС</h1>
          </TopPanel>
          <Tabs
            cognosType={cognosType}
            contractVehiclePlanId={contractVehiclePlanId}
            assignmentId={(assignment && assignment.id) || undefined}
            assignmentApproved={
              !!(assignment && assignment.status === entityStatusEnum.approved)
            }
          />

          <Section>
            <SectionTitle divider>Должностные лица</SectionTitle>
            <SectionContent>
              <AssignmentGroups
                setChanges={this.setChanges(regulationLimitTypeEnum.employee)}
                editing={editing || editMode}
                groups={employee}
                loading={loading}
                updateAssignment={this.updateAssignment}
                onChangeGroups={this.createChangeGroupsHandler(
                  regulationLimitTypeEnum.employee
                )}
                type={regulationLimitTypeEnum.employee}
                selectedVehiclesIds={selectedVehiclesIds}
              />
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle divider>Службы</SectionTitle>
            <SectionContent>
              <AssignmentGroups
                setChanges={this.setChanges(regulationLimitTypeEnum.orgUnit)}
                editing={editing || editMode}
                groups={orgUnit}
                loading={loading}
                updateAssignment={this.updateAssignment}
                onChangeGroups={this.createChangeGroupsHandler(
                  regulationLimitTypeEnum.orgUnit
                )}
                type={regulationLimitTypeEnum.orgUnit}
                selectedVehiclesIds={selectedVehiclesIds}
              />
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle divider>Разовые заявки</SectionTitle>
            <SectionContent>
              <AssignmentGroups
                setChanges={this.setChanges(
                  regulationLimitTypeEnum.oneOffRequests
                )}
                editing={editing || editMode}
                groups={oneOffRequests}
                loading={loading}
                updateAssignment={this.updateAssignment}
                onChangeGroups={this.createChangeGroupsHandler(
                  regulationLimitTypeEnum.oneOffRequests
                )}
                type={regulationLimitTypeEnum.oneOffRequests}
                selectedVehiclesIds={selectedVehiclesIds}
              />
            </SectionContent>
          </Section>

          <Section>
            <SectionTitle divider>ОВБ</SectionTitle>
            <SectionContent>
              <AssignmentGroups
                setChanges={this.setChanges(regulationLimitTypeEnum.ovb)}
                editing={editing || editMode}
                groups={ovb}
                loading={loading}
                updateAssignment={this.updateAssignment}
                onChangeGroups={this.createChangeGroupsHandler(
                  regulationLimitTypeEnum.ovb
                )}
                type={regulationLimitTypeEnum.ovb}
                selectedVehiclesIds={selectedVehiclesIds}
              />
            </SectionContent>
          </Section>

          {assignment &&
            this.canApproved() &&
            [
              entityStatusEnum.created,
              entityStatusEnum.approvement,
              entityStatusEnum.editing,
              entityStatusEnum.approved
            ].includes(assignment.status) && (
              <Section>
                <SectionContent>
                  {[
                    entityStatusEnum.created,
                    entityStatusEnum.editing
                  ].includes(assignment.status) && (
                    <Popconfirm
                      overlayStyle={{ zIndex: 2000 }}
                      placement="left"
                      title={
                        <Input
                          placeholder="Введите наименование бюджета"
                          value={contractVehiclePlan?.name || null}
                          onChange={e =>
                            contractVehiclePlan &&
                            this.setState({
                              contractVehiclePlan: {
                                ...contractVehiclePlan,
                                name: e.target.value
                              }
                            })
                          }
                        />
                      }
                      onConfirm={async () => {
                        let checkChanges = false;
                        Object.keys(hasChanges).forEach((key: string) => {
                          if (hasChanges[key]) {
                            checkChanges = true;
                          }
                        });
                        if (checkChanges) {
                          notification.error({
                            message: 'Ошибка',
                            description: 'Есть несохраненные изменения'
                          });
                        } else {
                          if (!this.checkData()) {
                            notification.error({
                              message: 'Ошибка',
                              description: 'Не везде указаны ТС'
                            });
                          } else {
                            await this.handleChangeStatus(
                              entityStatusEnum.approvement
                            )();
                            await this.updateContractVehiclePlan();
                          }
                        }
                      }}
                    >
                      <Button type="primary">На согласование</Button>
                    </Popconfirm>
                  )}
                  {entityStatusEnum.approvement === assignment.status && (
                    <>
                      <Button
                        type="primary"
                        onClick={this.handleChangeStatus(
                          entityStatusEnum.approved
                        )}
                        style={{ marginRight: '10px' }}
                      >
                        Утвердить
                      </Button>
                      <Button
                        onClick={this.handleChangeStatus(
                          entityStatusEnum.declined
                        )}
                        style={{ marginRight: '10px' }}
                      >
                        Отклонить
                      </Button>
                    </>
                  )}
                  {budgetIsCreated ? (
                    editMode ? (
                      <>
                        <StyledButton
                          onClick={() => {
                            this.fetch();
                            this.setState({ editMode: false });
                          }}
                        >
                          Отменить
                        </StyledButton>
                      </>
                    ) : (
                      <StyledButton
                        type="primary"
                        onClick={() => this.setState({ editMode: true })}
                      >
                        Редактировать
                      </StyledButton>
                    )
                  ) : null}
                </SectionContent>
              </Section>
            )}
        </WorkDaysCalendarContext.Provider>
      </>
    );
  }
}

export default withUserAccess(Assignments);
