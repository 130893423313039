// @flow

import React from 'react';
import {TreeSelectProps} from 'antd/lib/tree-select';
import notification from 'antd/lib/notification';

import type {NodeFilterType, OrgUnitNode} from './../lib/types/';
import {orgUnitApi} from '../lib/api';

import TreeSelect from './ui/TreeSelect';

// Фильтры по оргюнитам
type OrgUnitFilter = {
  nodeId?: number,
  nodeFilterType?: NodeFilterType,
  // Выбор только среди филиалов
  onlyBranch?: boolean,
  // Выбор только среди служб
  onlyService?: boolean,
  // доступ для которого надо вернуть все филиалы
  accessType: string
};

type State = {
  tree: ?OrgUnitNode
};

type Props = TreeSelectProps & {
  onlyBranches: boolean,
  filter?: OrgUnitFilter,
  showAllBranchesOption?: boolean,
  allBranchesOptionValue?: any,
  hideMainBranch?: boolean,
  isFilter?: boolean, // признак того, что селект используется в фильтре. если true, в запросе подразделений возвращает
  // удаленные подразделения
};
export default class OrgUnitSelect extends React.Component<Props, State> {
  static defaultProps = {
    onlyBranches: false,
  };

  state = {
    tree: null,
  };

  _isMounted = false;

  mapData = (node: OrgUnitNode) => ({
    ...node,
    value: node.id,
    title: node.name,
    children: node.children
      ? node.children
      .filter(node =>
        this.props.onlyBranches ? node.type === 'branch' : true,
      )
      .map<OrgUnitNode>(this.mapData)
      : [],
  });

  async componentDidMount() {
    this._isMounted = true;
    try {
      const tree = this.mapData(
        await orgUnitApi.fetchOrgUnits({...this.props.filter, returnDeleted: this.props.isFilter ? true : undefined}),
      );
      if (this._isMounted) {
        if (this.props.showAllBranchesOption) {
          this.setState({
            tree: {
              children: this.props.hideMainBranch ? tree.children : [tree],
              value: this.props.allBranchesOptionValue || null,
              title: 'Все филиалы',
              key: 0,
            },
          });
        } else {
          this.setState({tree});
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {tree} = this.state;
    return (
      <TreeSelect
        treeDefaultExpandAll={false}
        placeholder="Выберите подразделение"
        className="orgUnitSelect"
        allowClear
        showSearch
        treeNodeFilterProp="title"
        treeData={tree ? [tree] : []}
        {...this.props}
      />
    );
  }
}
