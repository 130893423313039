// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import {OrgUnitSelect, Selects} from './../../components';
import {Filter} from './../../components/hoc';
import {Spoiler} from './../../components/ui';
import Grid, {GridItem} from './../../components/layout/Grid';
import Field from '../../components/card/Field';
import {type Position} from '../../lib/types';
import type {FilterChildrenParams} from '../../components/hoc/Filter';

const { PositionSelect } = Selects;

const Content = styled(Grid)`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type EmployeesFilterParams = {
  nodeId?: number,
  search?: string,
  positions?: Position[],
  isDefault?: boolean
};

type Props = {
  applyFilter: (filters: EmployeesFilterParams) => void,
  cleanFilter: Function,
  filter: EmployeesFilterParams
};

const EmployeeFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <Spoiler label="Фильтр">
    <Content gutter="16px">
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<EmployeesFilterParams>) => (
          <>
            <GridItem>
              <Field label="ФИО">
                <Input
                  placeholder="Введите ФИО сотрудника"
                  value={values.search}
                  onChange={({ target: { value } }) =>
                    changeValue('search', value)
                  }
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Организация">
                <OrgUnitSelect
                  value={values.nodeId}
                  onChange={(value: number) => changeValue('nodeId', value)}
                  isFilter={true}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Должность">
                <PositionSelect
                  value={values.positions}
                  mode="multiple"
                  onChange={(value: string, selectedOptions: Object[]) =>
                    changeValue(
                      'positions',
                      selectedOptions.map(option => option.props.value)
                    )
                  }
                />
              </Field>
            </GridItem>
            <Buttons fullWidth>
              <Button type="primary" onClick={applyFilter}>
                Подтвердить
              </Button>
              <Button type="plain" onClick={cleanFilter}>
                Отменить
              </Button>
            </Buttons>
          </>
        )}
      </Filter>
    </Content>
  </Spoiler>
);

export default EmployeeFilter;
