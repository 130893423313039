// @flow
import React from 'react';
import {FormikProps} from 'formik';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import {Grid, GridItem} from '../../../components/layout';
import {Form, OrgUnitSelect} from '../../../components';
import type {FormFieldType} from '../../../components/Form';
import Select from 'antd/lib/select';
import {vehicleStatusEnum, vehicleStatuses} from '../../../lib/enum';

const {Option} = Select;

type Props = {
  visible: boolean,
  onClose: () => void,
  onSave: () => void,
};

const CompareParametersModal = ({visible, onSave, onClose}: Props) => {
  return (
    <Modal
      title={'Принудительная сверка'}
      visible={visible}
      width={500}
      onCancel={onClose}
      footer={false}
    >
      <Form
        initialValues={{
          statuses: [null],
          branchIds: [null],
          hidenValue: Math.random(), // костыль, необходимый для сброса значений при закрытии модалки
        }}
        onSubmit={values => {
          delete values.hidenValue;
          if (values.statuses.includes(null)) {
            values.statuses = null;
          }
          if (values.branchIds.includes(null)) {
            values.branchIds = null;
          }
          onSave(values);
        }}
      >
        {(FormField: FormFieldType, formikProps: FormikProps) => {
          const {handleSubmit, setFieldValue} = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Филиал" name="branchIds" required>
                    {({name, value}) => (
                      <OrgUnitSelect
                        value={value}
                        onlyBranches
                        multiple
                        showAllBranchesOption
                        hideMainBranch
                        onChange={(newValue, namesArr, props) => {
                          if (props.triggerValue === null && !!newValue.length) {
                            setFieldValue(name, [null]);
                            return;
                          } else {
                            if (newValue[0] === null) {
                              newValue.shift();
                            } else {
                              if (!newValue.length) {
                                setFieldValue(name, undefined);
                                return;
                              }
                            }
                          }
                          setFieldValue(name, newValue);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Статус" name="statuses" required>
                    {({name, value}) => (
                      <Select
                        value={value}
                        mode="multiple"
                        placeholder="Выберите статус"
                        onChange={(newValue, event) => {
                          if (event[event.length - 1]?.props?.value === null) {
                            setFieldValue(name, [null]);
                            return;
                          } else {
                            if (newValue[0] === null) {
                              newValue.shift();
                            } else {
                              if (!newValue.length) {
                                setFieldValue(name, undefined);
                                return;
                              }
                            }
                          }
                          setFieldValue(name, newValue);
                        }}
                      >
                        <Option key="all" value={null}>Все статусы</Option>
                        {Object.keys(vehicleStatusEnum).filter(key => key !== vehicleStatusEnum.isArchive).map(key => (
                          <Option key={key} value={vehicleStatusEnum[key]}>{vehicleStatuses[key]}</Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
              </Grid>
              <Grid gutter="16px">
                <GridItem>
                  <Button type="primary" htmlType="submit">Ок</Button>
                </GridItem>
                <GridItem>
                  <Button onClick={onClose}>Отмена</Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

export default CompareParametersModal;
