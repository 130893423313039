// @flow
import React, { useState } from 'react';

import qs from 'query-string';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import { stockPartApi } from '../../lib/api';
import {
  formatDateTimeToISOString,
  getListInitialState,
  navigate,
  multipliedBy
} from '../../lib/helpers';
import { accessTypeEnum } from '../../lib/enum';
import type { StockPart, ListState, UserAccess } from '../../lib/types';
import Table from '../../components/ui/Table';
import { GridItem, Header, Section } from '../../components/layout';
import { notificationLoading } from '../../components/Notifications';
import type { StockPartFilterParams } from './components/Filter';
import ButtonsRow from '../../components/ui/ButtonsRow';
import Grid from '../../components/layout/Grid';

import { withUserAccess } from './../withUserAccess';

import Filter from './components/Filter';
import StockSelect from '../../components/selects/StockSelect';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};
type State = ListState<StockPart> & {
  filter: StockPartFilterParams
};

const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingStocks,
  accessTypeEnum.handlingAllStocks
];

export class StockPartList extends React.Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Склад',
      dataIndex: 'stock.mnemocode'
    },
    {
      title: 'Название',
      dataIndex: 'mtr.name'
    },
    {
      title: 'Код МТР',
      dataIndex: 'mtr.mnemocode'
    },
    {
      title: 'Стоимость ед.',
      dataIndex: 'cost'
    },
    {
      title: 'Количество',
      dataIndex: 'count'
    },
    {
      title: 'Ед. измерения',
      dataIndex: 'mtr.measure'
    },
    {
      title: '№ серии',
      dataIndex: 'serialNumber'
    },
    {
      title: '№ партии',
      dataIndex: 'batchNumber'
    },
    {
      title: 'Сумма',
      width: '50px',
      dataIndex: '',
      render: (record: StockPart) => multipliedBy(record.cost, record.count)
    }
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchStockPart(page)
    );
  }

  fetchStockPart = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await stockPartApi.fetchStockPart({
      ...filter,
      page
    });
    this.setState({ ...data, loading: false, page });
  };

  onConfirmRequest = async (data: any) => {
    const checkDate = data.date;
    const stocksId = data.stocksId;
    try {
      try {
        notificationLoading({
          message: 'Запрос данных',
          key: 'request'
        });
        const data = await stockPartApi.updateParusStockParts({
          checkDate,
          stocksId
        });
        if (data) {
          notification.success({ message: data.status });
        } else {
          notification.error('Ошибка запросы');
        }
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message
        });
      }
    } finally {
      notification.close('request');
    }
  };

  canAdd = () =>
    this.props.userAccess.some(access => addAccess.includes(access));

  applyFilter = (filter: StockPartFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchStockPart(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchStockPart(page)
    );
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={
            <h1>
              Перечень запасных частей и материалов для работ хоз. способом
            </h1>
          }
          right={
            canAdd && (
              <ButtonsRow>
                <RequestPopup onConfirm={this.onConfirmRequest} />
              </ButtonsRow>
            )
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            fetch={this.fetchStockPart}
            fetchOnMount
            onRow={(row: StockPart) => ({
              onClick: () => navigate(`/admin/stock-part/${row.id}`)
            })}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

type RequestPopupProps = {
  onConfirm?: ({ date: string, stocksId: number[] }) => any
};

const RequestPopup = (props: RequestPopupProps) => {
  const [date, setDate] = useState(moment.utc().startOf('day'));
  const [stocksId, setStocksId] = useState([]);

  return (
    <Popconfirm
      title={
        <div
          style={{
            width: 500
          }}
        >
          <Grid cols={1}>
            <GridItem fullWidth>
              <p>Дата снятия остатков</p>
            </GridItem>
            <GridItem>
              <DatePicker
                format="DD.MM.YYYY"
                value={date}
                onChange={date => {
                  setDate(date);
                }}
              />
            </GridItem>
            <GridItem fullWidth>
              <StockSelect
                allowClear
                value={stocksId}
                mode="multiple"
                onChange={value => {
                  setStocksId(value);
                }}
              />
            </GridItem>
          </Grid>
        </div>
      }
      onConfirm={() => {
        // eslint-disable-next-line no-unused-expressions
        props.onConfirm?.({
          date: formatDateTimeToISOString(
            date,
            moment(date).format('DD.MM.YYYY HH:mm')
          ),
          stocksId
        });
      }}
    >
      <Button>Запросить данные</Button>
    </Popconfirm>
  );
};

export default withUserAccess(StockPartList);
