// @flow
import React from 'react';

import Icon from 'antd/lib/icon';

import type { BranchBudgetSummary } from '../../../../lib/types';
import {branchBudgetSummaryCognosTypeEnum, budgetSummaryStatusEnum} from '../../../../lib/enum';

import { TabItem, Tabs } from './../../../../components/ui';

type Props = {
  branchData: BranchBudgetSummary[],
  id: number
};

export default (props: Props) => {
  return (
    <Tabs
      withRouter
      defaultTab={`/budget/details/${props.id}/${props.branchData[0]?.orgUnitId}/primary`}
    >
      {props.branchData.map(el => {
        const StatusIcon =
          el.proposalStatus === budgetSummaryStatusEnum.approvement ? (
            <Icon
              type="warning"
              style={{ color: 'red', margin: '0 0 2px 3px' }}
            />
          ) : el.proposalStatus === budgetSummaryStatusEnum.approved ? (
            <Icon
              type="check-circle"
              style={{ color: 'green', margin: '0 0 2px 3px' }}
            />
          ) : null;
        if (el.id > 0 && el.type !== branchBudgetSummaryCognosTypeEnum.primary) {
          return null;
        }
        return (
          <TabItem
            label={
              <>
                {el.orgUnitName} {StatusIcon}
              </>
            }
            key={el.orgUnitId}
            url={`/budget/details/${props.id}/${el.orgUnitId}/primary`}
            activeUrl={[
              `/budget/details/${props.id}/${el.orgUnitId}/cognos`,
              `/budget/details/${props.id}/${el.orgUnitId}/primary`,
              `/budget/details/${props.id}/${el.orgUnitId}/primary/distribution-economy`,
              `/budget/details/${props.id}/${el.orgUnitId}/primary/proposal-economy`,
              `/budget/details/${props.id}/${el.orgUnitId}/primary/contracts`,
              `/budget/details/${props.id}/${el.orgUnitId}/corrected`,
              `/budget/details/${props.id}/${el.orgUnitId}/corrected/distribution-economy`,
              `/budget/details/${props.id}/${el.orgUnitId}/corrected/proposal-economy`,
              `/budget/details/${props.id}/${el.orgUnitId}/corrected/contracts`,
            ]}
          />
        );
      })}
    </Tabs>
  );
};
