// @flow

import React, {useEffect} from 'react';
import {Router} from '@reach/router';

import {withUserAccess} from './../../withUserAccess';

import {addAccessRightContractVehicleBudget, addAccessRightWorkPlans} from './fixed/accessRight';

import {
  FixedRegistrBudgetAssignments,
  FixedRegistrBudgetBudgetList,
  FixedRegistrBudgetExpenseDirectionCard,
  FixedRegistrBudgetList,
  FixedRegistrBudgetVehicleCard,
  FixedRegistrBudgetVehicleListCard,
  FixedRegistrBudgetVehicleListForm,
  FixedWorkPlansCard,
  FixedWorkPlansForm,
  FixedWorkPlansList,
} from './fixed';
import {navigate} from '../../../lib/helpers';

export const CognosType = {
  beforeCognos: 'beforeCognos',
  afterCognos: 'afterCognos',
};

export default withUserAccess(({userAccess, ...props}) => {
  useEffect(() => {
    if (!props['*'].includes('fixed') && !props['*'].includes('work-plans')) {
      navigate('fixed/beforeCognos');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canAddContractVehicleBudget = userAccess.some(access =>
    addAccessRightContractVehicleBudget.includes(access),
  );
  const canAddWorkPlans = userAccess.some(access =>
    addAccessRightWorkPlans.includes(access),
  );
  return (
    <>
      <Router>
        <FixedRegistrBudgetList path="/fixed/:cognosType" />
        {canAddContractVehicleBudget && (
          <FixedRegistrBudgetVehicleListForm path="/fixed/new" />
        )}
        {canAddContractVehicleBudget && (
          <FixedRegistrBudgetVehicleListForm path="/fixed/:contractVehiclePlanId/clone" />
        )}
        <FixedRegistrBudgetVehicleListCard path="/fixed/:cognosType/:contractVehiclePlanId/card" />
        <FixedRegistrBudgetAssignments path="/fixed/:cognosType/:contractVehiclePlanId/assignments" />
        <FixedRegistrBudgetBudgetList path="/fixed/:cognosType/:contractVehiclePlanId/budget" />
        <FixedRegistrBudgetExpenseDirectionCard
          path="/fixed/:cognosType/:contractVehiclePlanId/budget/:contractVehicleBudgetId/expense-direction/:type" />
        <FixedRegistrBudgetVehicleCard path="/fixed/:cognosType/:assignmentLimitId/budget/vehicle" />

        <FixedWorkPlansList path="/work-plans" />
        <FixedWorkPlansCard path="/work-plans/:regulationId/card" />
        {canAddWorkPlans && <FixedWorkPlansForm path="/work-plans/new" />}
      </Router>
    </>
  );
});
