import React, {Component} from 'react';
import {ReactComponent as Logo} from './logo.svg';

import styled from 'styled-components';

import LoginForm from './LoginForm';

class LoginScreen extends Component {
  render() {
    return (
      <Background>
        <Content>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <LoginForm />
        </Content>
      </Background>
    );
  }
}

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f4f8;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  height: 280px;
  width: 350px;
`;

const LogoWrapper = styled.div`
  margin-bottom: 45px;
`;
export default LoginScreen;
