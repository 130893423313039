// @flow
import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type {DetailedDefectiveStatement, MaintenanceOperation, OrderContractorType} from '../../../../lib/types';
import {orderContractorTypeEnum, workHourPriceEnum} from '../../../../lib/enum';
import {withEmptyRow, withoutEmptyRow} from '../../../../lib/helpers';

import {saveOperations, setDetailedDefectiveStatement} from '../../../../ducks/detailedDefectiveStatement';

import {Card} from './../../../../components';
import {Icon, ListTable} from './../../../../components/ui';
import Grid, {GridItem} from './../../../../components/layout/Grid';
import {notificationLoading} from '../../../../components/Notifications';

import {itog, operationColumns} from '../elements';
import {sumOperations} from '../lib';
import {fetchRequest, maintenanceOperationPriceApi} from '../../../../lib/api';
import {WorkKindEnum} from '../../../../lib/types/maintenanceWorkType';
import Select from 'antd/lib/select';

const {Field} = Card;
const {Option} = Select;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const OperationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const SelectWrapper = styled(Grid)`
  margin-bottom: 16px;
`;

type Props = {
  saveOperations: Function,
  setDetailedDefectiveStatement: Function,
  detailedDefectiveStatement: DetailedDefectiveStatement,
  orderContractorType: OrderContractorType,
  operations: MaintenanceOperation[],
  maintenanceId: number,
  vehicleModelId: number,
  vehicleId: number,
  executorId: number,
  maintenanceWorkKind: $Keys<WorkKindEnum>,
};

type State = {
  touched: boolean,
  columns: any[],
  operations: MaintenanceOperation[],
  maintenanceWorks: any[],
  maintenanceOperations: MaintenanceOperation[],
};

/**
 * Работы
 */
class Operations extends React.Component<Props, State> {
  state = {
    touched: false,
    columns: [],
    operations: [],
    maintenanceWorks: [],
    maintenanceOperations: [],
  };
  
  async componentDidMount() {
    let columns = operationColumns(
      this.isContractor(),
      this.onChange,
      false,
      this.props.orderContractorType,
    );
    if (!this.isContractor()) {
      columns = [
        ...columns,
        {
          width: '20px',
          renderRecord: (record: MaintenanceOperation, index: number) =>
            !isEmpty(record) ? (
              <OperationButtons>
                <StyledIcon onClick={() => this.onDelete(index)} type="x" />
              </OperationButtons>
            ) : null,
        },
      ];
    }
    
    const maintenanceWorks = await fetchRequest.get(`/detailedDefectiveStatement/maintenanceWorksByVehicle/${this.props.vehicleId}`);
    const maintenanceOperations = await fetchRequest.get(`/detailedDefectiveStatement/operationsByVehicle/${this.props.vehicleId}`);
    this.setState({columns, operations: this.props.operations, maintenanceWorks, maintenanceOperations});
  }
  
  componentDidUpdate(prevProps: Props) {
    if (!isEqual(this.props.operations, prevProps.operations)) {
      this.setState({operations: this.props.operations});
    }
  }
  
  isContractor = () =>
    this.props.orderContractorType === orderContractorTypeEnum.contractor;
  
  onChange = (index: number, key: $Keys<MaintenanceOperation>, value: any) => {
    let operations = !this.isContractor()
      ? withEmptyRow([...this.state.operations])
      : [...this.state.operations];
    
    if (this.state.touched === false) {
      this.setState({touched: true});
    }
    operations.splice(index, 1, {
      ...operations[index],
      [(key: string)]: value,
    });
    this.setState({operations});
  };
  
  onDelete = (index: number) => {
    if (this.state.touched === false) {
      this.setState({touched: true});
    }
    this.setState({
      operations: this.state.operations.filter((value, id) => id !== index),
    });
  };
  
  onSelectOperation = async (id: number) => {
    const {
      executorId,
      maintenanceId,
      orderContractorType,
      detailedDefectiveStatement,
      setDetailedDefectiveStatement,
      maintenanceWorkKind,
    } = this.props;
    const {operations, maintenanceOperations, maintenanceWorks} = this.state;
    let price = 0;
    try {
      const {data} = await maintenanceOperationPriceApi.fetchMaintenanceOperationPrice({
        orgUnitId: executorId,
        workingHourPriceType: orderContractorType === orderContractorTypeEnum.internal
          ? workHourPriceEnum.economicMethod
          : workHourPriceEnum.contractMethod,
      });
      if (this.state.touched === false) {
        this.setState({touched: true});
      }
      if (data?.length) {
        price = data[0].workingHourCost;
      }
    } catch (e) {
      notification.error({message: 'Ошибка получения стоимости нормо-часа', description: e.title || e.message});
    }
    const selectedElement = maintenanceWorkKind === WorkKindEnum.to
      ? maintenanceWorks.find(item => item.id === id)
      : maintenanceOperations.find(item => item.id === id)
    this.setState({
      operations: [
        ...withoutEmptyRow(operations),
        {
          price,
          maintenanceId: maintenanceId,
          id: undefined,
          hours: maintenanceWorkKind === WorkKindEnum.repair ? selectedElement.hours : selectedElement.hoursNorm,
          name: selectedElement.name,
          code: selectedElement.code,
        },
      ],
    });
    const newDetailedDefectiveStatement = {...detailedDefectiveStatement};
    if (maintenanceWorkKind === WorkKindEnum.to) {
      newDetailedDefectiveStatement.maintenanceWorkId = id;
    } else {
      newDetailedDefectiveStatement.operationId = id;
    }
    setDetailedDefectiveStatement(newDetailedDefectiveStatement);
  };
  
  handleSubmit = async () => {
    const {saveOperations} = this.props;
    const {operations} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      withoutEmptyRow(operations).forEach(operation => {
        if (!operation.name || !operation.count) {
          throw new Error(
            `Проверьте заполненность полей: наименование, количество`,
          );
        }
      });
      await saveOperations(withoutEmptyRow(operations));
      this.setState({touched: false});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  render() {
    const {columns, touched, operations, maintenanceWorks, maintenanceOperations} = this.state;
    const {orderContractorType, maintenanceWorkKind} = this.props;
    const data = !this.isContractor() ? withEmptyRow(operations) : operations;
    const {sum, sumContractor} = sumOperations(
      withoutEmptyRow(data),
      this.isContractor(),
    );
    return (
      <>
        {orderContractorType !== orderContractorTypeEnum.contractor && (
          <SelectWrapper cols={3} gutter="16px">
            <GridItem>
              <Field label="Вид работ">
                {maintenanceWorkKind === WorkKindEnum.to
                  ? (
                    <Select
                      placeholder="Выберите работу по ТО"
                      onChange={this.onSelectOperation}
                      value={undefined}
                    >
                      {maintenanceWorks.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )
                  : (
                    <Select
                      placeholder="Выберите работу по ремонту ТС"
                      onChange={this.onSelectOperation}
                      value={undefined}
                    >
                      {maintenanceOperations.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )
                }
              </Field>
            </GridItem>
          </SelectWrapper>
        )}
        <ListTable columns={columns} data={data} />
        {itog(sum, sumContractor, this.isContractor())}
        {touched && (
          <Grid style={{marginTop: '10px'}}>
            <GridItem fullWidth>
              <Button type="primary" onClick={this.handleSubmit}>
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    detailedDefectiveStatement: state.detailedDefectiveStatement,
  }),
  {
    setDetailedDefectiveStatement,
    saveOperations,
  },
)(Operations);
