// @flow
import React, { Component } from 'react';

import type { FormikProps } from 'formik';
import moment from 'moment';
import styled from 'styled-components';

import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import notification from 'antd/lib/notification';

import { Form } from '../../../components';
import CancelButton from '../../../components/CancelButton';
import { notificationLoading } from '../../../components/Notifications';
import { Footer, Header, Panel, Section } from '../../../components/layout';
import Breadcrumbs, { Crumb } from '../../../components/layout/Breadcrumbs';
import Grid, { GridItem } from '../../../components/layout/Grid';
import type { FormFieldType } from './../../../components/Form';

import { osagoMultiplier } from '../../../lib/api';
import {
  disabledDateMin,
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  navigate,
} from './../../../lib/helpers';
import type { OsagoGeneralMultiplier } from './../../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  location: any,
  osagoGeneralMultiplierId: ?string
};
type State = {
  osagoGeneralMultiplier: ?$Shape<OsagoGeneralMultiplier>
};

const SectionContent = styled.div`
  padding: 16px;
`;

export default class OsagoGeneralMultiplierForm extends Component<
  Props,
  State
> {
  state = {
    osagoGeneralMultiplier: null
  };

  onSubmit = async (values: OsagoGeneralMultiplier) => {
    const { osagoGeneralMultiplierId } = this.props;
    notificationLoading({
      message: 'Сохранение данных...',
      key: 'saving'
    });
    try {
      if (osagoGeneralMultiplierId) {
        await osagoMultiplier.updateOsagoGeneralMultiplier(values);
      } else {
        await osagoMultiplier.addOsagoGeneralMultiplier(values);
      }
      notification.success({
        message: 'Успешное обновление',
        description: 'Данные были успешно обновлены'
      });
      navigate('/admin/osago-multiplier/', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  async componentDidMount() {
    let osagoGeneralMultiplierId = parseInt(
      this.props.osagoGeneralMultiplierId,
      10
    );
    try {
      if (osagoGeneralMultiplierId) {
        try {
          const osagoGeneralMultiplier = await osagoMultiplier.getOsagoGeneralMultiplier(
            osagoGeneralMultiplierId
          );
          this.setState({ osagoGeneralMultiplier });
        } catch (error) {
          notification.error({
            message: 'Ошибка',
            description: error.message
          });
        }
      } else {
        let osagoGeneralMultiplier = {};
        osagoGeneralMultiplier.startDate = getValueObject(
          this.props,
          'location.state.startDate'
        );

        this.setState({ osagoGeneralMultiplier });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onCancel = () => navigate('/admin/osago-multiplier/', true);

  render() {
    const { osagoGeneralMultiplierId } = this.props;
    const { osagoGeneralMultiplier } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/osago-multiplier/')}
              >
                Общие коэффициенты ОСАГО
              </Crumb>
              <Crumb>
                {osagoGeneralMultiplierId
                  ? `Общие коэффициенты ОСАГО №${osagoGeneralMultiplierId}`
                  : 'Новые общие коэффициенты ОСАГО'}
              </Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoGeneralMultiplierId
              ? `Редактируем запись №${osagoGeneralMultiplierId}`
              : `Новая запись`}
          </h1>
        </StyledPanel>
        <Form initialValues={osagoGeneralMultiplier} onSubmit={this.onSubmit}>
          {(FormField: FormFieldType, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              dirty,
              setFieldValue,
              isSubmitting,
              values
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <SectionContent>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        <Grid gutter="16px" cols={5}>
                          <GridItem>
                            <FormField
                              label="КБМ"
                              required
                              hasFeedback={false}
                              name="kbm"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КБМ Энерготранс"
                              required
                              hasFeedback={false}
                              name="kbmEnergotrans"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КБМ КТС"
                              required
                              hasFeedback={false}
                              name="kbmKts"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КН"
                              required
                              hasFeedback={false}
                              name="kn"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КО"
                              required
                              hasFeedback={false}
                              name="ko"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КВС"
                              required
                              hasFeedback={false}
                              name="kvc"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КС"
                              required
                              hasFeedback={false}
                              name="kc"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField
                              label="КП"
                              required
                              hasFeedback={false}
                              name="kp"
                              fast
                            >
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0.01}
                                  value={value}
                                  onChange={(count: number) =>
                                    setFieldValue(name, count)
                                  }
                                  onBlur={handleBlur}
                                />
                              )}
                            </FormField>
                          </GridItem>

                          <GridItem span={2}>
                            <FormField
                              fast
                              label="Дата начала"
                              name="startDate"
                              required
                            >
                              {({ value, name }) => (
                                <DatePicker
                                  format="DD.MM.YYYY"
                                  value={value ? moment.utc(value) : value}
                                  onChange={(
                                    value: Object,
                                    dateString: string
                                  ) => {
                                    setFieldValue(
                                      name,
                                      formatDateTimeToISOString(
                                        value,
                                        dateString
                                      )
                                    );
                                    setFieldValue('endDate', undefined);
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem span={2}>
                            <FormField label="Дата окончания" name="endDate">
                              {({ value, name }) => (
                                <DatePicker
                                  format="DD.MM.YYYY"
                                  value={value ? moment.utc(value) : value}
                                  disabled={values.startDate ? false : true}
                                  disabledDate={(currentDate: moment) =>
                                    disabledDateMin(
                                      currentDate,
                                      moment(values.startDate)
                                    )
                                  }
                                  onChange={(
                                    value: Object,
                                    dateString: string
                                  ) => {
                                    setFieldValue(
                                      name,
                                      formatDateTimeToISOString(
                                        value,
                                        dateString
                                      )
                                    );
                                  }}
                                />
                              )}
                            </FormField>
                          </GridItem>
                        </Grid>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.onCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
