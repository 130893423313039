import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Section} from '../../../../../components/layout';
import {notificationLoading} from '../../../../../components/Notifications';

import {AntTable, ButtonsRow, Icon} from '../../../../../components/ui';

import {fuelsAndOilsMechanismApi, vehiclePlanApi} from '../../../../../lib/api';
import {
  accessTypeEnum,
  calculationStatusEnum,
  fuelTypeEnum,
  fuelTypes,
  vehiclePlanStatusEnum,
} from '../../../../../lib/enum';
import {plus, toLocalStringRu, withEmptyRow} from '../../../../../lib/helpers';
import type {
  CalculationStatus,
  FuelAndOilMechanism,
  FuelAndOilMechanismMonth,
  UserAccess,
} from '../../../../../lib/types';
import {headerPanel} from '../../components/Common';

import {COGNOS_DELTA, MONTH} from '../../lib';
import {ModalFuelAndOilMechanism} from './MechanismsModal';
import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget} from '../../accessRight';
import TotalBlock from '../../Budget/components/TotalBlock';
import InfoBlock from '../../components/InfoBlock';

type ListMechanismProps = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default withUserAccess((props: ListMechanismProps) => {
  const [data, setData] = useState([]);
  const [vehiclePlan, setVehiclePlan] = useState({});
  const [currentRowId, setCurrentRowId] = useState(-1);
  const [visibleData, setVisibleData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [totalValues, setTotalValues] = useState({petrol: 0, diesel: 0});
  const [cognosData, setCognosData] = useState(null);

  const columns = [
    {
      title: 'Наименование средства малой механизации',
      dataIndex: 'smallScaleMechanizationTool.name',
      width: '400px',
    },
    {
      title: 'Марка топлива',
      dataIndex: 'type',
      render: type => fuelTypes[type],
    },
    ...MONTH.map((month, index) => ({
      title: month.title,
      children: [
        {
          title: 'Количество, л.',
          dataIndex: `months[${index}].count`,
          render: (count, row) => (row.id ? toLocalStringRu(count) : null),
        },
        {
          title: 'Стоимость, руб.',
          dataIndex: `months[${index}].cost`,
          render: (count, row) =>
            row.id ? toLocalStringRu(count, {minimumFractionDigits: 2}) : null,
        },
      ],
    })),
    {
      title: 'Итого, руб.',
      render: (record: FuelAndOilMechanism) => {
        let sum = 0;
        if (Array.isArray(record.months)) {
          record.months.forEach((month: FuelAndOilMechanismMonth) => {
            sum = plus(sum, month.cost);
          });
        }
        return toLocalStringRu(sum, {minimumFractionDigits: 2});
      },
    },
    {
      key: 'delete',
      dataIndex: 'id',
      width: '50px',
      onCell: () => ({onClick: (e: any) => e.stopPropagation()}),
      render: (id: number, record: FuelAndOilMechanism, rowIndex: number) => {
        return (
          Number.isInteger(id) &&
          !readOnly && (
            <Popconfirm
              placement="topRight"
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => onDelete(id, rowIndex)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )
        );
      },
    },
  ];

  const setTotal = data => {
    let petrol = 0,
      diesel = 0;
    if (data.length) {
      data.forEach((vehicleData: FuelAndOilMechanism) => {
        vehicleData.months.forEach((monthsData: FuelAndOilMechanismMonth) => {
          if (
            vehicleData.type === fuelTypeEnum.ai80 ||
            vehicleData.type === fuelTypeEnum.ai92 ||
            vehicleData.type === fuelTypeEnum.ai95
          ) {
            petrol += monthsData.cost;
          } else {
            if (vehicleData.type === fuelTypeEnum.dieselFuel) {
              diesel += monthsData.cost;
            }
          }
        });
      });
    }
    setTotalValues({petrol, diesel});
  };

  const fetch = useCallback(async () => {
    try {
      const {data} = await fuelsAndOilsMechanismApi.fetch({
        vehiclePlanId: props.vehiclePlanId,
      });
      setTotal(data);
      setData(data);
    } catch (e) {
      notification.error({message: 'Ошибка', description: e.message});
    }
  }, [props.vehiclePlanId]);

  const fetchVehiclePlan = useCallback(async () => {
    try {
      const vehiclePlan = await vehiclePlanApi.get(props.vehiclePlanId);
      setVehiclePlan(vehiclePlan);
    } catch (e) {
      notification.error({message: 'Ошибка', description: e.message});
    }
  }, [props.vehiclePlanId]);

  const update = useCallback(
    async (dataRow: FuelAndOilMechanism) => {
      try {
        let newData = [...data];
        if (dataRow.id) {
          const updatedData = await fuelsAndOilsMechanismApi.update(dataRow);
          newData[currentRowId] = updatedData;
        } else {
          const addedData = await fuelsAndOilsMechanismApi.add(dataRow);
          newData.push(addedData);
        }
        setTotal(newData.filter(data => !!data.id));
        setData(newData.filter(data => !!data.id));
      } catch (e) {
        notification.error({message: 'Ошибка', description: e.message});
      }
    },
    [currentRowId, data],
  );

  const onDelete = async (id: number, rowIndex: number) => {
    try {
      notificationLoading({
        message: 'Удаление записи...',
        key: 'delete',
      });
      await fuelsAndOilsMechanismApi.delete(id);
      data.splice(rowIndex, 1);
      setTotal([...data]);
      setData([...data]);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('delete');
    }
  };

  useEffect(() => {
    fetchVehiclePlan();
    fetch();
  }, [fetch, fetchVehiclePlan]);

  useEffect(() => {
    setReadOnly(
      vehiclePlan?.fuelAndOilMechanismCalculationStatus ===
      calculationStatusEnum.calculationDone,
    );
  }, [vehiclePlan]);

  const onAdd = useCallback(() => {
    setCurrentRowId(data.length);
    setData([
      ...data,
      {
        type: null,
        name: '',
        months: MONTH.map((el, index) => {
          return {
            month: index + 1,
            count: 0,
            cost: 0,
          };
        }),
        vehiclePlanId: props.vehiclePlanId,
      },
    ]);
    setModalVisible(true);
  }, [data, props.vehiclePlanId]);

  const itog = (data: FuelAndOilMechanism[]) => {
    let itog = {
      id: true,
      smallScaleMechanizationTool: {name: 'Итого:'},
      months: Array(12)
      .fill()
      .map(() => ({cost: 0})),
    };
    data.forEach(mechamism =>
      mechamism.months.forEach(
        (month: FuelAndOilMechanismMonth, index: number) => {
          itog.months[index].cost = plus(itog.months[index].cost, month.cost);
        },
      ),
    );
    return itog;
  };

  useEffect(() => {
    data.forEach(item => {
      item.months.sort((a, b) => a.month - b.month);
    })
    setVisibleData(
      readOnly
        ? data.length === 0
          ? []
          : [...data, itog(data)]
        : withEmptyRow(data.length === 0 ? [] : [...data, itog(data)], {
          emptyRow: {
            smallScaleMechanizationTool: {
              name: (
                <Button size="small" onClick={onAdd}>
                  Добавить
                </Button>
              ),
            },
          },
        }),
    );
  }, [data, onAdd, readOnly]);

  const onAddCancel = () => {
    setModalVisible(false);
    let newData = [...data];
    newData[currentRowId].id ?? newData.pop();
    setData(newData);
    setCurrentRowId(-1);
  };

  const changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await fuelsAndOilsMechanismApi.changeStatus(vehiclePlanId, status);
      fetch();
      fetchVehiclePlan();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  const isDraft = () =>
    vehiclePlan?.fuelAndOilMechanismCalculationStatus ===
    calculationStatusEnum.draft;

  const isRangeMonths = (monthIndex: number) => {
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;

  const canEdit = () =>
    props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );
  const isCognosStatus = vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
    vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
  const approveButtonIsDisabled = (!isCognosStatus && props.userAccess.includes(accessTypeEnum.admin))
    ? false
    : (Math.abs(totalValues.petrol + cognosData?.gsmPetrolValue - cognosData?.petrolValue,) > COGNOS_DELTA ||
      Math.abs(totalValues.diesel + cognosData?.gsmDieselValue - cognosData?.dieselValue,
      ) > COGNOS_DELTA);

  return (
    <>
      {headerPanel({
        vehiclePlanId: props.vehiclePlanId,
        vehiclePlan,
        title: 'Средства малой механизации',
        passTab: true,
      })}
      <Section>
        <InfoBlock links={{ГСМ: '/admin/fuel-price'}} />
        <AntTable
          onRow={(record, rowIndex) => ({
            onClick: async () => {
              if (!canEdit()) {
                return;
              }
              if (Number.isInteger(record.id)) {
                await setCurrentRowId(rowIndex);
                setModalVisible(true);
              }
            },
          })}
          columns={columns}
          data={visibleData}
          pagination={{pageSize: 50}}
          rowKey="name"
          bordered
          rowClassName={record => record.selfVehiclePlanVehicle?.changed && isDraft()
            ? 'table-row-error'
            : null
          }
        />
        <ModalFuelAndOilMechanism
          visible={modalVisible}
          isRangeMonths={isRangeMonths}
          handleSave={(data: FuelAndOilMechanism) => {
            setModalVisible(false);
            update(data);
            setCurrentRowId(-1);
          }}
          handleCancel={onAddCancel}
          data={visibleData[currentRowId]}
          readOnly={readOnly}
        />
      </Section>
      <Section style={{padding: '16px'}}>
        <Wrapper>
          {isCognosStatus && (
            <TotalBlock
              filteredDataTotalSum={totalValues}
              controllerName="fuelAndOilMechanism"
              vehiclePlanId={props.vehiclePlanId}
              setCognosData={setCognosData}
            />
          )}
          {canEditBudget(props.userAccess) && (
            <ButtonsRow>
              {isDraft() && (
                <Button
                  type="primary"
                  onClick={() =>
                    changeStatus(calculationStatusEnum.calculationDone)
                  }
                  disabled={approveButtonIsDisabled}
                  title={
                    approveButtonIsDisabled
                      ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                      : undefined
                  }
                >
                  Утвердить
                </Button>
              )}
              {!planApproved &&
                !isDraft() &&
                vehiclePlan?.status !==
                vehiclePlanStatusEnum.approvedAfterCognos && (
                  <Button
                    type="primary"
                    onClick={() => changeStatus(calculationStatusEnum.draft)}
                  >
                    Редактировать
                  </Button>
                )}
            </ButtonsRow>
          )}
        </Wrapper>
      </Section>
    </>
  );
});
