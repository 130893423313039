// @flow
import React, {Component} from 'react';
import Button from 'antd/lib/button';
import {Link} from '@reach/router';

import type {ListState, Stou} from '../../lib/types';
import Filter, {type StouFilterParams} from './Filter';
import {Table} from './../../components/ui';
import {Section} from './../../components/layout';
import Header from '../../components/layout/Header';

import {type FetchListParams, stouApi} from './../../lib/api';
import {getListInitialState, navigate, setQueryParams} from '../../lib/helpers';

type Props = {};

type State = ListState<Stou> & {
  filter: StouFilterParams
};

/** Вывод списка СТОУ */
class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };

  state = {
    ...getListInitialState(),
    filter: {}
  };

  fetchStous = async (
    page: number = 1,
    params: FetchListParams<StouFilterParams> = {}
  ) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const { data, totalCount } = await stouApi.fetchStouList({
      ...filter,
      page,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  columns = [
    { title: '№', dataIndex: 'id', sorter: true, width: 25 },
    { title: 'Мнемокод', dataIndex: 'mnemonic', sorter: true },
    { title: 'Наименование', dataIndex: 'name', sorter: true }
  ];

  handleRowClick = (id: number) => navigate(`/admin/stou/${id}`);

  cleanFilter = () => this.setState({ filter: {} }, this.fetchStous);

  applyFilter = (filter: StouFilterParams) =>
    this.setState({ filter }, this.fetchStous);

  render() {
    const { data, totalCount, page, pageSize, loading, filter } = this.state;

    return (
      <>
        <Header
          left={<h1>СТОУ</h1>}
          right={
            <Link to="/admin/stou/new">
              <Button data-cy="addStou" type="primary">
                Создать
              </Button>
            </Link>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            loading={loading}
            data={data.map(stou => ({
              ...stou,
              key: stou.id
            }))}
            fetch={this.fetchStous}
            fetchOnMount
            columns={this.columns}
            pagination={{
              page,
              pageSize,
              totalCount
            }}
          />
        </Section>
      </>
    );
  }
}

export default List;
