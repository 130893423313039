// @flow
import {Link as ReachLink} from '@reach/router';
import React, {type ComponentType, Fragment} from 'react';
import styled, {css} from 'styled-components';

import type {Profile} from '../../ducks/auth';

import {accessTypeEnum} from '../../lib/enum';
import {convertEmployeeToString, navigate} from '../../lib/helpers';

import {Menu, MenuItem} from '../ui';

import Divider from './Divider';
import Button from 'antd/lib/button';
import {fetchRequest} from '../../lib/api';

/**
 * Обертка сайдбара
 * Является областью по которой
 * перемещается основное содержимое
 */
const Wrapper = styled.aside`
  background: white;
  width: 200px;
  height: 100vh;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.07);
  ${props => !props.expanded &&
          css`
            & * {
              opacity: 0;
            }

            width: 0;
            padding: 0;
          `};
`;

/**
 * Основное содержимое сайдбара
 * Зафиксировано и двигается по обертке Wrapper
 */
const Content = styled.div`
  position: sticky;
  box-sizing: border-box;
  padding-top: 10px;
  top: 50px;
  max-height: 100vh;
  overflow-y: auto;
  padding-bottom: 30px;
`;
const ProfileWrapper = styled.div`
  background: #e4ebf2;
  padding: 8px 16px 15px;
`;
const ProfileEmployee = styled.p`
  font-weight: 500;
  margin-bottom: ${({marginBottom = 8}) => marginBottom}px;
`;
const ProfileRole = styled.p`
  color: #98a3ad;
  font-size: 12px;
`;
const ProfileBottomBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const LogoutButton = styled(Button)`
  width: 12px;
  height: 12px;
  border: none !important;
  padding: 0;
  margin: 0;

  &:hover, &:active {
    border: none;
    background-color: inherit;
  }
`;

type LinkProps = {
  to: string,
  replace?: boolean
};

// Роли, для которых всегда есть доступ к основным разделам
const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];
// клеймы для простомотра прейскурантов
const priceDirectory = [
  accessTypeEnum.viewingAllPriceLists,
  accessTypeEnum.viewingPriceLists,
  accessTypeEnum.handlingPriceLists,
  accessTypeEnum.approvingPriceLists,
];
// клеймы для просмотра ремонтов
const maintenance = [
  accessTypeEnum.approvingMaintenances,
  accessTypeEnum.approvingDetailedDefectiveStatements,
  accessTypeEnum.approvingDefectiveStatements,
  accessTypeEnum.creatingMaintenances,
  accessTypeEnum.viewingMaintenances,
  accessTypeEnum.viewingAllMaintenances,
];

const LogoutIcon = props => (
  <svg width={12} height={12} viewBox="0 0 612 612" {...props}>
    <g fill="#2770FF">
      <path
        d="M222.545 333.818h319.909l-69.545 111.273h55.637L612 306l-83.454-139.091h-55.637l69.545 111.273H222.545z" />
      <path d="M0 612h445.091V431.182h-55.637v125.181H55.636V55.636h333.818v125.182h55.637V0H0z" />
    </g>
  </svg>
);

/**
 * Определяем атрибут active
 * для ссылок с частично совпадающим URL
 */
const Link = (props: LinkProps) => (
  <ReachLink
    {...props}
    getProps={({isPartiallyCurrent}) =>
      isPartiallyCurrent ? {active: 'active'} : null
    }
  />
);

/**
 * Раздел меню в сайдбаре
 */
const StyledLink: ComponentType<LinkProps> = styled(Link)`
  display: block;
  color: #3d4042;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 16px;
  transition: background 0.1s;
  position: relative;

  &:hover {
    color: white;
    background: #2770ff;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:focus {
    text-decoration: none;
  }

  &[active] {
    color: white;
    background: #2770ff;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 16px;
`;

type Props = {
  /**
   * Раскрытость сайдбара
   */
  expanded: boolean,
  profile: Profile
};

const mainLinks = [
  {
    link: '/vehicles',
    text: 'Транспорт',
    access: [
      ...adminAccess,
      accessTypeEnum.creatingVehicle,
      accessTypeEnum.handlingOrder,
      accessTypeEnum.viewingVehicleBranch,
      accessTypeEnum.viewingVehicleServiceOnly,
      accessTypeEnum.approvingVehicle,
    ],
    cy: 'vehicles',
  },
  {
    link: '/orders',
    text: 'Заявки',
    access: [
      ...adminAccess,
      accessTypeEnum.creatingOrder,
      accessTypeEnum.approvingGpmOrder,
      accessTypeEnum.handlingOrder,
      accessTypeEnum.viewingOrdersBranch,
      accessTypeEnum.viewingAllOrders,
      accessTypeEnum.viewingOrdersServiceOnly,
    ],
    cy: 'orders',
  },
  {
    link: '/shifts',
    text: 'Наряды',
    access: [
      ...adminAccess,
      accessTypeEnum.viewingAllShifts,
      accessTypeEnum.viewingShiftsServiceOnly,
      accessTypeEnum.viewingShiftsBranch,
      accessTypeEnum.creatingOrder,
      accessTypeEnum.handlingOrder,
    ],
    cy: 'shifts',
  },
  {
    isGroup: true,
    label: 'Путевые листы',
    access: [
      ...adminAccess,
      accessTypeEnum.handlingTrip,
      accessTypeEnum.handlingOrder,
      accessTypeEnum.viewingAllTrips,
      accessTypeEnum.viewingTripsServiceOnly,
      accessTypeEnum.viewingTripsBranch,
    ],
    items: [
      {
        link: '/trips/self',
        text: 'Собственные ТС',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingTrip,
          accessTypeEnum.handlingOrder,
          accessTypeEnum.viewingAllTrips,
          accessTypeEnum.viewingTripsServiceOnly,
          accessTypeEnum.viewingTripsBranch,
        ],
      },
      {
        link: '/trips/contract',
        text: 'Наемные ТС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingBranchContractTrip,
          accessTypeEnum.viewingAllContractTrip,
          accessTypeEnum.handlingContractTrip,
        ],
      },
    ],
    cy: 'trips',
  },
  {
    link: '/distance-tracking',
    text: 'Учет пробегов',
    access: [...adminAccess],
    cy: 'monitoring',
  },
  {
    isGroup: true,
    showDivider: false,
    access: [
      ...adminAccess,
      accessTypeEnum.viewingMaintenanceServices,
      accessTypeEnum.viewingAllMaintenanceServices,
      accessTypeEnum.handlingMaintenanceServices,
    ],
    label: 'Обслуживание',
    items: [
      {
        link: '/services/washings',
        text: 'Мойки',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/pass',
        text: 'Пропуска',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/osagos',
        text: 'ОСАГО',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/inspections-gibdd',
        text: 'Технический осмотр ГИБДД',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/inspections-gtn',
        text: 'Технический осмотр ГТН',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/expertises-services-gpm',
        text: 'Экспертиза ПС/ТО приборов безопасности ПС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/measuring-device-certifications',
        text: 'Тарировка цистерн',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/driver-inspections',
        text: 'Осмотр водителей',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/platons',
        text: 'Платон',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/vehicles-monitoring',
        text: 'Услуги по мониторингу ТС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
      {
        link: '/services/license-plate-changes',
        text: 'Замена гос. номеров',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceServices,
          accessTypeEnum.viewingAllMaintenanceServices,
          accessTypeEnum.handlingMaintenanceServices,
        ],
        cy: '',
      },
    ],
  },
  {
    isGroup: true,
    showDivider: false,
    label: 'Бюджет',
    access: [
      ...adminAccess,
      accessTypeEnum.viewingBranchOsagoCalculations,
      accessTypeEnum.viewingAllBranchesOsagoCalculations,
      accessTypeEnum.formationBranchOsagoCalculations,
      accessTypeEnum.formationAllBranchesOsagoCalculations,
      accessTypeEnum.viewingAllWashingPlans,
      accessTypeEnum.viewingWashingPlans,
      accessTypeEnum.formationWashingPlans,
      accessTypeEnum.approvingWashingPlans,
      accessTypeEnum.viewingAllBudgetExecutions,
      accessTypeEnum.viewingBudgetExecutions,
      accessTypeEnum.viewingContractVehicleBudget,
      accessTypeEnum.viewingAllContractVehicleBudgets,
      accessTypeEnum.viewingSelfVehiclePlan,
      accessTypeEnum.viewingAllSelfVehiclePlan,
      accessTypeEnum.handlingBudgetAnalysis,
      accessTypeEnum.viewingBudgetAnalysis,
      accessTypeEnum.viewingAllBudgetAnalysis,
      accessTypeEnum.approvingSelfVehiclePlanBudgetItog,
      accessTypeEnum.handlingBranchVehicleTaxPlans,
      accessTypeEnum.handlingAllVehicleTaxPlans,
      accessTypeEnum.viewingBranchVehicleTaxPlans,
      accessTypeEnum.viewingAllVehicleTaxPlans,
      accessTypeEnum.handlingDetailedBudget,
      accessTypeEnum.handlingBranchDetailedBudget,
      accessTypeEnum.viewingDetailedBudget,
      accessTypeEnum.approvingBranchDetailedBudget,
      accessTypeEnum.approvingDetailedBudget,
      accessTypeEnum.viewingBranchPlanFuelConsumption,
      accessTypeEnum.viewingAllPlanFuelConsumption,
      accessTypeEnum.viewingBranchFactFuelConsumption,
      accessTypeEnum.viewingAllFactFuelConsumption,
      accessTypeEnum.editingBudgetCopy,
    ],
    items: [
      {
        link: '/budget/tax',
        text: 'Налог',
        access: [
          accessTypeEnum.handlingBranchVehicleTaxPlans,
          accessTypeEnum.handlingAllVehicleTaxPlans,
          accessTypeEnum.viewingBranchVehicleTaxPlans,
          accessTypeEnum.viewingAllVehicleTaxPlans,
          ...adminAccess,
        ],
        cy: '',
      },
      {
        link: '/budget/osago-calculations',
        text: 'ОСАГО',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingBranchOsagoCalculations,
          accessTypeEnum.viewingAllBranchesOsagoCalculations,
          accessTypeEnum.formationBranchOsagoCalculations,
          accessTypeEnum.formationAllBranchesOsagoCalculations,
        ],
        cy: '',
      },
      {
        link: '/budget/monthly-washing-plans',
        text: 'Мойки',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingAllWashingPlans,
          accessTypeEnum.viewingWashingPlans,
          accessTypeEnum.formationWashingPlans,
          accessTypeEnum.approvingWashingPlans,
        ],
        cy: '',
      },
      {
        link: '/budget/execution',
        text: 'Исполнение договоров',
        access: [
          ...adminAccess,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingBudgetExecutions,
          accessTypeEnum.viewingAllBudgetExecutions,
        ],
        cy: '',
      },
      {
        link: '/budget/vehicle',
        text: 'Бюджет СТС',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingSelfVehiclePlan,
          accessTypeEnum.viewingAllSelfVehiclePlan,
          accessTypeEnum.approvingSelfVehiclePlanBudgetItog,
          accessTypeEnum.editingBudgetCopy,
        ],
        cy: '',
      },
      {
        link: '/budget/contract-vehicle/',
        text: 'Бюджет НТС',
        access: [
          ...adminAccess,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingContractVehicleBudget,
          accessTypeEnum.viewingAllContractVehicleBudgets,
          accessTypeEnum.editingBudgetCopy,
        ],
        cy: '',
      },
      {
        link: '/budget/budget-analysis',
        text: 'Анализ затрат',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingBudgetAnalysis,
          accessTypeEnum.viewingBudgetAnalysis,
          accessTypeEnum.viewingAllBudgetAnalysis,
        ],
        cy: '',
      },
      {
        link: '/budget/summary/',
        text: 'Основной бюджет',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingDetailedBudget,
          accessTypeEnum.handlingBranchDetailedBudget,
          accessTypeEnum.viewingDetailedBudget,
          accessTypeEnum.approvingBranchDetailedBudget,
          accessTypeEnum.approvingDetailedBudget,
        ],
        cy: '',
      },
      {
        link: '/budget/details/',
        text: 'Уточнения в бюджет',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingDetailedBudget,
          accessTypeEnum.handlingBranchDetailedBudget,
          accessTypeEnum.viewingDetailedBudget,
          accessTypeEnum.approvingBranchDetailedBudget,
          accessTypeEnum.approvingDetailedBudget,
        ],
        cy: 'detailedBudget',
      },
      {
        link: '/admin/contract/',
        text: 'Реестр договоров',
        access: [
          ...adminAccess,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingContracts,
          accessTypeEnum.viewingAllContracts,
          accessTypeEnum.handlingContracts,
        ],
        cy: 'contract',
      },
      {
        link: '/admin/act/',
        text: 'Реестр первичных документов',
        access: [
          ...adminAccess,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingExternalActs,
          accessTypeEnum.viewingAllExternalActs,
          accessTypeEnum.handlingExternalActs,
          accessTypeEnum.viewingInternalActs,
          accessTypeEnum.viewingAllInternalActs,
          accessTypeEnum.handlingInternalActs,
          accessTypeEnum.viewingBranchFactFuelConsumption,
          accessTypeEnum.viewingAllFactFuelConsumption,
        ],
        cy: 'act',
      },
      {
        link: '/admin/planned-fuel-consumption',
        text: 'Плановый расход ГСМ',
        access: [
          accessTypeEnum.admin,
          accessTypeEnum.viewingBranchPlanFuelConsumption,
          accessTypeEnum.viewingAllPlanFuelConsumption,
        ],
        cy: 'plannedFuelConsumption',
      },
      {
        link: '/admin/actual-fuel-consumption',
        text: 'Фактический расход ГСМ',
        access: [
          accessTypeEnum.admin,
          accessTypeEnum.viewingBranchFactFuelConsumption,
          accessTypeEnum.viewingAllFactFuelConsumption,
        ],
        cy: 'actualFuelConsumption',
      },
    ],
  },
  {
    link: '/maintenances',
    text: 'Ремонт',
    access: [...adminAccess, ...maintenance],
    cy: 'maintenances',
  },
  {
    link: '/contract-vehicle-work-plans/',
    text: 'План работ НТС',
    access: [
      ...adminAccess,
      accessTypeEnum.viewingTimeLimits,
      accessTypeEnum.viewingBranchTimeLimits,
      accessTypeEnum.viewingLimitAssignment,
      accessTypeEnum.viewingAllLimitAssignment,
      accessTypeEnum.viewingBranchYearLimitsPlan,
      accessTypeEnum.viewingYearLimitsPlan,
      accessTypeEnum.viewingBranchMonthLimitsPlan,
      accessTypeEnum.viewingMonthLimitsPlan,
    ],
    cy: 'contract-vehicle-work-plans',
  },
  {
    isGroup: true,
    showDivider: false,
    label: 'Отчеты',
    access: [...adminAccess, accessTypeEnum.viewingReports],
    items: [
      {
        link: '/report/drivers',
        text: 'Водители',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/fuels-and-oils',
        text: 'Расход ГСМ',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/fuel-cards',
        text: 'Топливные карты',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/vehicle-works',
        text: 'Работа ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/write-off-vehicles',
        text: 'Рекомендуемые к списанию/замене ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/taxed-trips',
        text: 'Таксированные путевые листы',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/electric-taxed-trips',
        text: 'Таксированные путевые листы по электрокарам',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/gpm-order',
        text: 'Учёт заявок ГПМ',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/vehicle-output-ratio',
        text: 'Коэффициент выхода ТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/business-trip',
        text: 'Командировки',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/weekend-orders',
        text: 'Реестр заявок на выходные дни',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/schedule-maintenance',
        text: 'График ТО',
        access: [...adminAccess, accessTypeEnum.viewingReports],
        cy: '',
      },
      {
        link: '/report/register-contract-trips',
        text: 'Реестр ПЛ НТС',
        access: [...adminAccess, accessTypeEnum.viewingReports],
      },
    ],
  },
  {
    isGroup: true,
    showDivider: true,
    label: 'Администрирование',
    access: [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingEquipment,
      accessTypeEnum.handlingEmployees,
      accessTypeEnum.viewingAllEquipments,
      accessTypeEnum.viewingBranchEquipments,
      accessTypeEnum.handlingDriver,
      accessTypeEnum.viewingAllDrivers,
      accessTypeEnum.viewingBranchDrivers,
      accessTypeEnum.viewingSystemJournal,
      accessTypeEnum.handlingLocation,
      accessTypeEnum.viewingAllLocations,
      accessTypeEnum.viewingBranchLocations,
      accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
      accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
      accessTypeEnum.viewingWashingVehicleTypes,
      accessTypeEnum.handlingWashingVehicleTypes,
      accessTypeEnum.viewingOsagoMultipliers,
      accessTypeEnum.handlingOsagoMultipliers,
      accessTypeEnum.viewingJobTitles,
      accessTypeEnum.viewingAllJobTitles,
      accessTypeEnum.viewingMaintenanceWorkTypes,
      accessTypeEnum.handlingMaintenanceWorkTypes,
      accessTypeEnum.handlingCompareExpenseDirections,
      accessTypeEnum.viewingCompareExpenseDirections,
      accessTypeEnum.handlingExpenseDirections,
      accessTypeEnum.viewingExpenseDirections,
      accessTypeEnum.handlingVehicleIssueGroups,
      accessTypeEnum.viewingVehicleIssueGroups,
      accessTypeEnum.viewingStocksAndStockParts,
      accessTypeEnum.viewingAllStocksAndStockParts,
      accessTypeEnum.handlingStocks,
      accessTypeEnum.handlingAllStocks,
      accessTypeEnum.viewingMaintenanceOperationGroups,
      accessTypeEnum.handlingMaintenanceOperationGroups,
      accessTypeEnum.viewingMaintenanceMatrix,
      accessTypeEnum.handlingMaintenanceMatrix,
      accessTypeEnum.viewingContracts,
      accessTypeEnum.viewingAllContracts,
      accessTypeEnum.viewingExternalActs,
      accessTypeEnum.viewingAllExternalActs,
      accessTypeEnum.viewingInternalActs,
      accessTypeEnum.viewingAllInternalActs,
      accessTypeEnum.viewingBudgetVersion,
      accessTypeEnum.viewingVehicleTariff,
      accessTypeEnum.approvingSelfVehiclePlanBudget,
      accessTypeEnum.handlingSelfVehiclePlanBudget,
      accessTypeEnum.viewingPlaces,
      accessTypeEnum.viewingAllPlaces,
      accessTypeEnum.viewingCognosLog,
      accessTypeEnum.viewingAllCognosLog,
      accessTypeEnum.viewingSelfVehiclePlanBudget,
      accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      accessTypeEnum.handlingTireUsingLimits,
      accessTypeEnum.handlingTripEditLimit,
      accessTypeEnum.handlingDebitCreditAccount,
      accessTypeEnum.handlingCognosActualDataSendingTimeline,
      accessTypeEnum.handlingUatToParusOilType,
      accessTypeEnum.handlingVehicleModel,
      accessTypeEnum.viewingVehicleModel,
      accessTypeEnum.viewingInventoryCardCompareDate,
      accessTypeEnum.editingInventoryCardCompareDate,
      accessTypeEnum.viewingCalendarBK,
      accessTypeEnum.handlingCalendarBK,
      accessTypeEnum.viewingContractVehicleModel,
      accessTypeEnum.handlingContractVehicleModel,
      accessTypeEnum.viewingVehicleCategory,
      accessTypeEnum.handlingVehicleCategory,
      ...priceDirectory,
    ],
    items: [
      {
        link: '/admin/maintenance-matrix',
        text: 'Матрица ремонтов',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingMaintenanceMatrix,
          accessTypeEnum.handlingMaintenanceMatrix,
        ],
        cy: 'maintenanceMatrix',
      },
      {
        link: '/admin/org-units',
        text: 'Подразделения',
        access: adminAccess,
        cy: 'orgUnits',
      },
      {
        link: '/admin/users',
        text: 'Пользователи',
        access: adminAccess,
        cy: 'users',
      },
      {
        link: '/admin/roles',
        text: 'Роли',
        access: adminAccess,
        cy: 'roles',
      },
      {
        link: '/admin/drivers/nonElectricVehiclesDrivers',
        text: 'Водители',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingDriver,
          accessTypeEnum.viewingAllDrivers,
          accessTypeEnum.viewingBranchDrivers,
        ],
        cy: 'drivers',
      },
      {
        link: '/admin/employees',
        text: 'Сотрудники',
        access: [...adminAccess, accessTypeEnum.handlingEmployees],
        cy: 'employees',
      },
      {
        link: '/admin/locations/uat',
        text: 'Объекты',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingLocation,
          accessTypeEnum.viewingAllLocations,
        ],
        cy: 'info',
      },
      {
        link: '/admin/journals',
        text: 'Системный журнал',
        access: [...adminAccess, accessTypeEnum.viewingSystemJournal],
        cy: 'journals',
      },
      {
        link: '/admin/fuel-cards',
        text: 'Топливные карты',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingFuelCard,
          accessTypeEnum.viewingAllFuelCards,
          accessTypeEnum.viewingBranchFuelCards,
        ],
        cy: 'fuel-cards',
      },
      {
        link: '/admin/subscriptions/',
        text: 'Подписки',
        access: [...adminAccess],
        cy: 'subscriptions',
      },
      {
        link: '/admin/place/',
        text: 'Местоположения',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingPlaces,
          accessTypeEnum.viewingAllPlaces,
        ],
        cy: 'place',
      },
      {
        link: '/admin/cognos-log/',
        text: 'Отправка в ИСУ Бюджетирование',
        access: [
          ...adminAccess,
          accessTypeEnum.viewingCognosLog,
          accessTypeEnum.viewingAllCognosLog,
        ],
        cy: 'cognos-log',
      },
      {
        link: '/admin/inventory-card-compare',
        text: 'Сверка ИК',
        access: [
          accessTypeEnum.admin,
          accessTypeEnum.viewingAllCompareInventoryCardResult,
          accessTypeEnum.viewingBranchCompareInventoryCardResult,
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Оборудование',
        access: [
          ...adminAccess,
          accessTypeEnum.handlingEquipment,
          accessTypeEnum.viewingAllEquipments,
          accessTypeEnum.viewingBranchEquipments,
        ],
        items: [
          {
            link: '/equipment/attached',
            text: 'Навесное и доп. оборудование',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'attached',
          },
          {
            link: '/equipment/optional',
            text: 'Приборы и бортовые устройства',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'optional',
          },
          {
            link: '/equipment/tires',
            text: 'Шины',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'tires',
          },
          {
            link: '/equipment/batteries',
            text: 'АКБ',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingEquipment,
              accessTypeEnum.viewingAllEquipments,
              accessTypeEnum.viewingBranchEquipments,
            ],
            cy: 'batteries',
          },
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Справочники',
        access: [
          accessTypeEnum.admin,
          accessTypeEnum.viewingVehicleWashingTypeToGroupsMap,
          accessTypeEnum.handlingVehicleWashingTypeToGroupsMaps,
          accessTypeEnum.viewingWashingVehicleTypes,
          accessTypeEnum.handlingWashingVehicleTypes,
          accessTypeEnum.viewingOsagoMultipliers,
          accessTypeEnum.handlingOsagoMultipliers,
          accessTypeEnum.viewingJobTitles,
          accessTypeEnum.viewingAllJobTitles,
          accessTypeEnum.viewingMaintenanceWorkTypes,
          accessTypeEnum.handlingMaintenanceWorkTypes,
          accessTypeEnum.handlingCompareExpenseDirections,
          accessTypeEnum.viewingCompareExpenseDirections,
          accessTypeEnum.handlingExpenseDirections,
          accessTypeEnum.viewingExpenseDirections,
          accessTypeEnum.handlingVehicleIssueGroups,
          accessTypeEnum.viewingVehicleIssueGroups,
          accessTypeEnum.viewingStocksAndStockParts,
          accessTypeEnum.viewingAllStocksAndStockParts,
          accessTypeEnum.handlingStocks,
          accessTypeEnum.handlingAllStocks,
          accessTypeEnum.viewingMaintenanceOperationGroups,
          accessTypeEnum.handlingMaintenanceOperationGroups,
          accessTypeEnum.newFeaturesDemo,
          accessTypeEnum.viewingContracts,
          accessTypeEnum.viewingAllContracts,
          accessTypeEnum.viewingExternalActs,
          accessTypeEnum.viewingAllExternalActs,
          accessTypeEnum.viewingInternalActs,
          accessTypeEnum.viewingAllInternalActs,
          accessTypeEnum.viewingBudgetVersion,
          accessTypeEnum.viewingVehicleTariff,
          accessTypeEnum.approvingSelfVehiclePlanBudget,
          accessTypeEnum.handlingSelfVehiclePlanBudget,
          accessTypeEnum.viewingSelfVehiclePlanBudget,
          accessTypeEnum.viewingAllSelfVehiclePlanBudget,
          accessTypeEnum.handlingTireUsingLimits,
          accessTypeEnum.handlingVehicleModel,
          accessTypeEnum.viewingVehicleModel,
          accessTypeEnum.handlingTripEditLimit,
          accessTypeEnum.handlingDebitCreditAccount,
          accessTypeEnum.handlingCognosActualDataSendingTimeline,
          accessTypeEnum.handlingUatToParusOilType,
          accessTypeEnum.viewingWorkDict,
          accessTypeEnum.editingWorkDict,
          accessTypeEnum.viewingInventoryCardCompareDate,
          accessTypeEnum.editingInventoryCardCompareDate,
          accessTypeEnum.viewingCalendarBK,
          accessTypeEnum.handlingCalendarBK,
          accessTypeEnum.viewingContractVehicleModel,
          accessTypeEnum.handlingContractVehicleModel,
          accessTypeEnum.viewingVehicleCategory,
          accessTypeEnum.handlingVehicleCategory,
        ],
        items: [
          {
            link: '/admin/registration-place/',
            text: 'Место регистрации ТС',
            access: [...adminAccess],
            cy: 'registration-place',
          },
          {
            link: '/admin/maintenance-work-type',
            text: 'Виды работ по обслуживанию ТС',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingMaintenanceWorkTypes,
              accessTypeEnum.handlingMaintenanceWorkTypes,
            ],
            cy: 'maintenance-type-work',
          },
          {
            link: '/admin/compare-expense-direction',
            text: 'Сопоставление видов работ и направлений расходов',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingCompareExpenseDirections,
              accessTypeEnum.viewingCompareExpenseDirections,
            ],
            cy: 'compare-expense-direction',
          },
          {
            link: '/admin/expense-direction/',
            text: 'Направление расходов',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingExpenseDirections,
              accessTypeEnum.viewingExpenseDirections,
              accessTypeEnum.handlingContracts,
            ],
            cy: 'expense-direction',
          },
          {
            link: '/admin/stock-part',
            text: 'Перечень материалов',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingStocksAndStockParts,
              accessTypeEnum.viewingAllStocksAndStockParts,
            ],
            cy: 'stock-part',
          },
          {
            link: '/admin/osago-multiplier/',
            text: 'Коэффициенты ОСАГО',
            access: [
              ...adminAccess,
              accessTypeEnum.viewingOsagoMultipliers,
              accessTypeEnum.handlingOsagoMultipliers,
            ],
            cy: 'washing-price',
          },
          {
            link: '/admin/vehicle-issue-group',
            text: 'Группы дефектов',
            access: [
              ...adminAccess,
              accessTypeEnum.handlingVehicleIssueGroups,
              accessTypeEnum.viewingVehicleIssueGroups,
            ],
            cy: 'vehicle-issue-group',
          },
          {
            link: '/admin/brands',
            text: 'Марки',
            access: [accessTypeEnum.admin],
            cy: 'brands',
          },
          {
            link: '/vehicle-models/',
            text: 'Модели',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingVehicleModel,
              accessTypeEnum.viewingVehicleModel,
              accessTypeEnum.viewingContractVehicleModel,
              accessTypeEnum.handlingContractVehicleModel,
            ],
            cy: 'vehicleModels',
          },
          {
            link: '/admin/fuels-and-oils',
            text: 'ГСМ',
            access: [accessTypeEnum.admin],
            cy: 'fuelsAndOils',
          },
          {
            link: '/admin/companies',
            text: 'Контрагенты',
            access: [accessTypeEnum.admin, accessTypeEnum.adminBranch, accessTypeEnum.companiesHandling],
            cy: 'companies',
          },
          {
            link: '/admin/contractors',
            text: 'Подрядчики',
            access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
            cy: 'contractors',
          },
          {
            link: '/admin/fuel-multipliers',
            text: 'Коэффициенты',
            access: [accessTypeEnum.admin],
            cy: 'fuelMultipliers',
          },
          {
            link: '/admin/vehicle-tariff/planned',
            text: 'Тарифы на НТС',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.adminBranch,
              accessTypeEnum.viewingVehicleTariff,
            ],
          },
          {
            link: '/admin/maintenance-operations',
            text: 'Работы по ремонту ТС',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.changeStatusMaintenanceWork,
              accessTypeEnum.handlingMaintenanceWork,
            ],
            cy: 'maintenanceOperationGroups',
          },
          {
            link: '/admin/mdm',
            text: 'МТРиО',
            access: [accessTypeEnum.admin, accessTypeEnum.handlingContracts],
            cy: 'mdm',
          },
          {
            link: '/admin/job-titles',
            text: 'Должности для регламентов',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingJobTitles,
              accessTypeEnum.viewingAllJobTitles,
            ],
            cy: 'jobTitles',
          },
          {
            link: '/admin/business-calendar',
            text: 'Производственный календарь',
            access: [accessTypeEnum.admin],
            cy: 'businessCalendar',
          },
          {
            link: '/admin/the-business-calendar-for-budget',
            text: 'Производственный календарь для бюджета',
            access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
            cy: 'businessCalendarForBudget',
          },
          {
            link: '/admin/stou',
            text: 'СТОУ',
            access: [accessTypeEnum.admin],
            cy: 'stou',
          },
          {
            link: '/admin/budget-version',
            text: 'Версии бюджета',
            access: [accessTypeEnum.admin, accessTypeEnum.viewingBudgetVersion],
            cy: 'budgetVersion',
          },
          {
            link: '/admin/okei',
            text: 'ОКЕИ',
            access: [accessTypeEnum.admin],
            cy: 'okei',
          },
          {
            link: '/admin/stock/',
            text: 'Склады',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingStocksAndStockParts,
              accessTypeEnum.viewingAllStocksAndStockParts,
              accessTypeEnum.handlingStocks,
              accessTypeEnum.handlingAllStocks,
            ],
            cy: 'stock',
          },
          {
            link: '/admin/ISUBudgetingData',
            text: 'Данные для ИСУ "Бюджетирование"',
            access: [accessTypeEnum.admin],
            cy: 'ISUBudgetingData',
          },
          {
            link: '/admin/standardOfTireOperation',
            text: 'Норматив эксплуатации шин',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingTireUsingLimits,
            ],
            cy: 'standardOfTireOperation',
          },
          {
            link: '/admin/reasons-for-rejection-of-order/',
            text: 'Причины отклонения заявки на ТС',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingOrderToReworkReasons,
            ],
            cy: 'reasonsRejectionOfOrder',
          },
          {
            link: '/admin/deadlines-for-making-changes-on-verified-trips',
            text: 'Сроки внесения изменений в оттаксированный ПЛ',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingTripEditLimit,
            ],
            cy: 'deadlinesForMakingChangesOnVerifiedTrips',
          },
          {
            link: '/admin/debet-credit-accounts',
            text: 'Дебет/кредит счетов',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingDebitCreditAccount,
            ],
            cy: 'debetCreditAccount',
          },
          {
            link: '/admin/working-with-budgeting/',
            text: 'Работа с ИСУ «Бюджетирование»',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingCognosActualDataSendingTimeline,
              accessTypeEnum.viewingCalendarBK,
              accessTypeEnum.handlingCalendarBK,
            ],
          },
          {
            link: '/admin/types-of-oils/',
            text: 'Типы масел для учета расхода',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingUatToParusOilType,
            ],
            cy: 'typesOfOils',
          },
          {
            link: '/admin/securing-contractors/',
            text: 'Закрепление подрядчиков',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingContractorBranchLink,
              accessTypeEnum.handlingContractorBranchLink,
            ],
          },
          {
            link: '/admin/types-of-repairs/',
            text: 'Виды ремонта',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingWorkDict,
              accessTypeEnum.editingWorkDict,
            ],
          },
          {
            link: '/admin/maintenance-works/',
            text: 'Работы по ТО',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.handlingMaintenanceWork,
              accessTypeEnum.changeStatusMaintenanceWork,
              accessTypeEnum.approvementMaintenanceWork,
            ],
          },
          {
            link: '/admin/model-matches/',
            text: 'Модели для сопоставления',
            access: [accessTypeEnum.admin],
          },
          {
            link: '/admin/compare-dates',
            text: 'Сроки сверки ИК',
            access: [
              accessTypeEnum.admin,
              accessTypeEnum.viewingInventoryCardCompareDate,
              accessTypeEnum.editingInventoryCardCompareDate,
            ],
          },
          {
            isGroup: true,
            showDivider: false,
            label: 'Для бюджета СТС',
            access: [
              ...adminAccess,
              accessTypeEnum.approvingSelfVehiclePlanBudget,
              accessTypeEnum.handlingSelfVehiclePlanBudget,
              accessTypeEnum.viewingSelfVehiclePlanBudget,
              accessTypeEnum.viewingAllSelfVehiclePlanBudget,
              accessTypeEnum.viewingVehicleCategory,
              accessTypeEnum.handlingVehicleCategory,
            ],
            items: [
              {
                link: '/admin/expertise-service-gpm-cost/',
                text: 'Стоимость ТО ГПМ',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'expertise-service-gpm-cost',
              },
              {
                link: '/admin/gps-subscription-service-cost/',
                text: 'Стоимость GPS',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'gps-subscription-service-cost',
              },
              {
                link: '/admin/inspection-gtn-cost/',
                text: 'Стоимость ТО Гостехнадзор',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'inspection-gtn-cost',
              },
              {
                link: '/admin/license-plate-change-cost/',
                text: 'Стоимость замены гос.номеров',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'license-plate-change-cost',
              },
              {
                link: '/admin/trip-inspection-cost/',
                text: 'Стоимость осмотров',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'trip-inspection-cost',
              },
              {
                link: '/admin/trip-inspection-frequency/',
                text: 'Периодичность осмотров',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'trip-inspection-frequency',
              },
              {
                link: '/admin/overhaul-types/',
                text: 'Виды работ по капремонту',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'overhaul-types',
              },
              {
                link: '/admin/current-maintenance-cost/',
                text: 'Стоимость ремонта',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'current-maintenance-cost',
              },
              {
                link: '/admin/maintenance-cost-allocation-tr/',
                text: 'Затраты на ТР',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'maintenance-cost-allocation-tr',
              },
              {
                link: '/admin/maintenance-cost-allocation/',
                text: 'Затраты на ТО',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'maintenance-cost-allocation',
              },
              {
                link: '/admin/vehicle-tax-rate/',
                text: 'Налоговые коэффициенты',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'vehicle-tax-rate',
              },
              {
                link: '/admin/vehicle-tax-map/',
                text: 'Сопоставление налогов и типов ТС',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'vehicle-tax-map',
              },
              {
                link: '/admin/budgets-for-analysis/',
                text: 'Бюджеты для анализа',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'budgets-for-analysis',
              },
              {
                link: '/admin/pass-tariff',
                text: 'Тарифы на пропуска',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'pass-tariff',
              },
              {
                link: '/admin/small-scale-mechanization/',
                text: 'Средства малой механизации',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'small-scale-mechanization',
              },
              {
                link: '/admin/measuring-device-certification-cost/',
                text: 'Стоимость тарировки',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'measuring-device-certification-cost',
              },
              {
                link: '/admin/inspection-gibdd-cost/',
                text: 'Стоимость ТО ГИБДД',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.approvingSelfVehiclePlanBudget,
                  accessTypeEnum.handlingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingSelfVehiclePlanBudget,
                  accessTypeEnum.viewingAllSelfVehiclePlanBudget,
                ],
                cy: 'inspection-gibdd-cost',
              },
              {
                link: '/admin/vehicle-categories/',
                text: 'Категории ТО ГИБДД',
                access: [
                  accessTypeEnum.admin,
                  accessTypeEnum.viewingVehicleCategory,
                  accessTypeEnum.handlingVehicleCategory,
                ],
              },
            ],
          },
        ],
      },
      {
        isGroup: true,
        showDivider: false,
        label: 'Прейскуранты',
        access: [...adminAccess, ...priceDirectory],
        items: [
          {
            link: '/admin/fuel-price',
            text: 'ГСМ',
            access: [
              ...adminAccess,
              accessTypeEnum.newFeaturesDemo,
              ...priceDirectory,
            ],
            cy: 'fuel-price',
          },
          {
            link: '/admin/maintenance-operation-price',
            text: 'Стоимость нормо-часа',
            access: [
              ...adminAccess,
              accessTypeEnum.newFeaturesDemo,
              ...priceDirectory,
            ],
            cy: 'maintenance-operation-price',
          },
          {
            link: '/admin/washing-price-list',
            text: 'Мойки',
            access: [...adminAccess, ...priceDirectory],
            cy: 'washing-price',
          },
          {
            link: '/admin/monitoring-price',
            text: 'Мониторинг',
            access: [...adminAccess, ...priceDirectory],
            cy: 'monitoring-price',
          },
          {
            link: '/admin/federal-highway-fare/',
            text: 'Стоимость пропусков',
            access: [...adminAccess, ...priceDirectory],
            cy: 'federal-highway-fare',
          },
        ],
      },
    ],
  },
  {
    link: '/instructions',
    text: 'Инструкции',
    access: Object.keys(accessTypeEnum),
    cy: 'instructions',
  },
];

const getFlatMenu = (list: any, parentName: string) => {
  let res = {};
  list.forEach((el) => {
    if (el.isGroup) {
      res[el.label] = {
        name: el.label,
        parent: parentName,
      };
      res = {...res, ...getFlatMenu(el.items, el.label)};
    } else {
      const link = el.link.slice(
        0,
        el.link.indexOf('?') === -1 ? undefined : el.link.indexOf('?'),
      );
      res[link] = {
        name: el.text,
        to: link,
        parent: parentName,
      };
    }
  });
  return res;
};

export const flatMenu = {
  ...getFlatMenu(mainLinks, '/'),
};

const renderMenu = (userAccess, links, parentKey) => {
  return links
  .filter(v => !!v)
  .map((item: any, index) => {
    return userAccess.some(access => item.access.includes(access)) ? (
      item.isGroup ? (
        <Fragment key={`menu-group-${index}`}>
          {item.showDivider && <StyledDivider color="light" />}
          <Menu label={item.label}>
            {renderMenu(userAccess, item.items, `menu-group-${index}`)}
          </Menu>
        </Fragment>
      ) : parentKey ? (
        <MenuItem
          data-cy={item.cy}
          to={item.link}
          key={`${parentKey}/link-${index}`}
        >
          {item.text}
        </MenuItem>
      ) : (
        <StyledLink data-cy={item.cy} to={item.link} key={`link-${index}`}>
          {item.text}
        </StyledLink>
      )
    ) : null;
  });
};

const logout = async () => {
  navigate('/logout');
  await fetchRequest.delete('/auth/logout');
};

/**
 * Компонент боковой левой колонки сайта
 */
const Sidebar = ({expanded, profile}: Props) => {
  const {access: userAccess} = profile;
  // Для продакшена нам не нужно показывать текущую роль
  const showRoleName = process.env.NODE_ENV !== 'production';
  return (
    <Wrapper expanded={expanded}>
      <Content>{renderMenu(userAccess, mainLinks)}</Content>
      <ProfileWrapper>
        {showRoleName ? (
          <>
            <ProfileEmployee>
              {convertEmployeeToString(profile.employee)}
            </ProfileEmployee>
            <ProfileBottomBlock>
              <ProfileRole>{profile.roleName}</ProfileRole>
              <LogoutButton onClick={logout} title="Выход">
                <LogoutIcon />
              </LogoutButton>
            </ProfileBottomBlock>
          </>
        ) : (
          <ProfileBottomBlock>
            <ProfileEmployee marginBottom={0}>
              {convertEmployeeToString(profile.employee)}
            </ProfileEmployee>
            <LogoutButton onClick={logout} title="Выход">
              <LogoutIcon />
            </LogoutButton>
          </ProfileBottomBlock>
        )}
      </ProfileWrapper>
    </Wrapper>
  );
};

export default Sidebar;
