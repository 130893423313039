// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {typesOfOilsApi} from '../../lib/api';
import {fuelOilsTypes} from '../../lib/enum/fuelType';

const entryPointPath = '/admin/types-of-oils/';

const TypesOfOils = () => {
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30
    },
    {
      title: 'Тип масла',
      dataIndex: 'oilType',
      render: type => fuelOilsTypes[type]
    },
    {
      title: 'Тип масла для ИСУ «Парус»',
      dataIndex: 'parusOil.name'
    },
    {
      title: 'Мнемокод',
      dataIndex: 'parusOil.mnemocode'
    }
  ];

  return (
    <Page>
      <CommonListPage
        crud={typesOfOilsApi}
        pageHeaderProps={{
          mainHeader: 'Типы масел для учета расхода',
          access: {
            add: () => navigate(`${entryPointPath}new`)
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default React.memo(TypesOfOils);
