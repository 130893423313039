// @flow
import React, { useCallback, useState } from 'react';
import { smallScaleMechanizationToolApi } from '../../lib/api';
import type { SmallScaleMechanizationTool, UserAccess } from '../../lib/types';
import { navigate } from '../../lib/helpers';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, { GridItem } from '../../components/layout/Grid';
import { Card } from '../../components';

import { getBreadCrumbsByUrlForCard } from '../../lib/autoBreadcrumbs';
import { addAccessRight } from './accessRight';
import { withUserAccess } from '../withUserAccess';

const { Field } = Card;

type InnerProps = {
  data: SmallScaleMechanizationTool
};

type PageProps = {
  id: number,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/small-scale-mechanization/';

const InnerForm = (props: InnerProps) => {
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Наименование">{props.data.name}</Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: SmallScaleMechanizationTool, setData] = useState({
    id: props.id,
    name: ''
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canHandling = () =>
    props.userAccess.some(access => addAccessRight.includes(access));

  const access = {
    delete: canHandling()
      ? async () => {
          await smallScaleMechanizationToolApi.delete(data.id);
          await navigate(`${entryPointPath}`, true);
        }
      : undefined,
    edit: canHandling()
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : undefined
  };

  const onFetch = useCallback(async () => {
    setData(await smallScaleMechanizationToolApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Средства малой механизации',
        access
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
