// @flow
import React, {useState} from 'react';

import Popconfirm from 'antd/lib/popconfirm';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';

import {vehicleTaxPlanApi} from './../../../lib/api';
import {formatDateRangeString, formatDateTimeToString, navigate} from '../../../lib/helpers';

import Page from './../../../components/hoc/common/handbook/PageContext';
import CommonListPage from './../../../components/hoc/common/handbook/ListPage';
import {Dropdown} from './../../../components/ui';
import {withUserAccess} from './../../withUserAccess';

import Filter from './components/Filter';
import {canAdd} from './accessRight';

const columns = [
  {
    title: '№',
    key: 'idBudgetVehicle',
    dataIndex: 'id'
  },
  {
    title: 'Филиал',
    key: 'orgUnitName',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Планируемый период',
    key: 'startDate',
    dataIndex: 'startDate',
    render: (text: string, record: any): ?string =>
      formatDateRangeString(record.startDate, record.endDate, 'D MMMM YYYY')
  },
  {
    title: 'Версия бюджета',
    key: 'budgetVersionName',
    dataIndex: 'budgetVersion.name'
  },
  {
    title: 'Дата формирования',
    key: 'date',
    dataIndex: 'date',
    width: '150',
    render: (created: string) =>
      `${formatDateTimeToString(created, 'DD.MM.YYYY')}`
  }
];

const access = {
  add: () => navigate(`/budget/tax/new`)
};

export default withUserAccess(({ userAccess }) => {
  const [reFetch, setReFetch] = useState(false);

  const onDelete = async (id: number) => {
    try {
      await vehicleTaxPlanApi.delete(id);
      await setReFetch(true);
    } catch (error) {
    } finally {
      setReFetch(false);
    }
  };

  const columnsTable = canAdd(userAccess)
    ? [
        ...columns,
        {
          stopPropagation: true,
          title: '',
          dataIndex: 'id',
          render: (id: number) => {
            return (
              <Dropdown
                key={id}
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Popconfirm
                        overlayStyle={{
                          zIndex: 2000
                        }}
                        placement="left"
                        title={<>Вы действительно хотите удалить?</>}
                        onConfirm={() => {
                          onDelete(id);
                        }}
                      >
                        Удалить
                      </Popconfirm>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Icon
                  style={{ fontSize: 16, color: '#2770FF' }}
                  type="ellipsis"
                />
              </Dropdown>
            );
          }
        }
      ]
    : [...columns];
  return (
    <Page>
      <CommonListPage
        crud={vehicleTaxPlanApi}
        reFetch={reFetch}
        pageHeaderProps={{
          mainHeader: 'Налог СТС',
          access: canAdd(userAccess) ? access : undefined
        }}
        tableListProps={{
          columns: [...columnsTable],
          onSelect: (id: number) => navigate(`/budget/tax/${id}/card`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
