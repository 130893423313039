import React, { useState } from 'react';

import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';

import { getValueObject } from './../../../../../../../lib/helpers';
import {
  contractVehicleTypeEnum,
  vehicleStatusEnum
} from './../../../../../../../lib/enum';

import { Selects } from './../../../../../../../components';
import { Grid } from './../../../../../../../components/layout';
import { GridItem } from './../../../../../../../components/layout/Grid';

const { ContractVehicleSelect } = Selects;

type Props = {
  visible: boolean,
  orgUnitId: number,
  onCancel: Function,
  addContractVehicle: Function
};
export default (props: Props) => {
  const { visible, onCancel, orgUnitId, addContractVehicle } = props;
  const [contractVehicle, setContractVehicle] = useState(undefined);
  return (
    <Modal
      destroyOnClose
      centered
      width={800}
      title="Добавление нового ТС"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Grid gutter="16px">
        <GridItem>
          <ContractVehicleSelect
            value={contractVehicle && contractVehicle.id}
            filters={{
              'vehicle.nodeId': orgUnitId,
              'vehicle.status': [
                vehicleStatusEnum.draft,
                vehicleStatusEnum.onAgreeing,
                vehicleStatusEnum.working,
                vehicleStatusEnum.onAgreeing
              ],
              type: [
                contractVehicleTypeEnum.indefined,
                contractVehicleTypeEnum.substitution
              ]
            }}
            onChange={(contractVehicle, option) => {
              const vehicle = getValueObject(option, 'props.vehicle');
              setContractVehicle(vehicle);
            }}
          />
        </GridItem>
        <GridItem>
          <Button
            type="primary"
            disabled={!contractVehicle}
            onClick={() => {
              addContractVehicle(contractVehicle);
              setContractVehicle(undefined);
            }}
          >
            Добавить
          </Button>
        </GridItem>
      </Grid>
    </Modal>
  );
};
