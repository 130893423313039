//@flow
import React, {useCallback} from 'react';
import {FilterItem} from '../../../components';
import Field from '../../../components/card/Field';
import {CommonFilter, type FilterProps} from '../../../components/hoc/common/handbook/CommonFilter';
import {InventoryCardCompareTypeEnum, InventoryCardCompareTypes} from '../../../lib/types/inventoryCardCompareResult';
import Select from 'antd/lib/select';

export type FilterParams = {
  type: $Keys<InventoryCardCompareTypeEnum>
};

const {Option} = Select;

export const Filter = (props: FilterProps<FilterParams>) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<FilterParams>>) => CommonFilter<FilterParams>({...filterProps, ...props}),
    [props],
  );
  return (
    <Filter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <Select
                size="small"
                value={values.type}
                onChange={value => changeValue('type', value)}
                placeholder="Выберите тип"
              >
                {Object.keys(InventoryCardCompareTypes).map(value => (
                  <Option key={value} value={value}>{InventoryCardCompareTypes[value]}</Option>
                ))}
              </Select>
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
