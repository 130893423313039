// @flow

export const fuelOilsTypes = {
  motorOil: 'Моторное масло',
  transmissionOil: 'Трансмиссионное масло',
  specialOil: 'Специальные масла и жидкости',
  autoGreases: 'Пластичные смазки',
};

export const fuelOilsEnum = {
  motorOil: 'motorOil',
  transmissionOil: 'transmissionOil',
  specialOil: 'specialOil',
  autoGreases: 'autoGreases',
};

export const fuelTypes = {
  dieselFuel: 'ДТ',
  ai80: 'АИ-80',
  ai92: 'АИ-92',
  ai95: 'АИ-95',
  ai98: 'АИ-98',
  propaneButane: 'Пропан-Бутан',
  methane: 'Метан',
  electrofuel: 'Электроэнергия',
  ...fuelOilsTypes,
};

export const fuelTypeEnum = {
  dieselFuel: 'dieselFuel',
  ai80: 'ai80',
  ai92: 'ai92',
  ai95: 'ai95',
  ai98: 'ai98',
  propaneButane: 'propaneButane',
  methane: 'methane',
  electrofuel: 'electrofuel',
  ...fuelOilsEnum,
};

export const fuelTypesCalculation = {
  dieselFuel: 'ДТ',
  ai80: 'АИ-80',
  ai92: 'АИ-92',
  ai95: 'АИ-95',
};

export const fuelsAndGazTypes = {
  dieselFuel: 'ДТ',
  ai92: 'АИ-92',
  ai95: 'АИ-95',
  ai98: 'АИ-98',
  electricity: 'Электроэнергия',
  methane: 'Метан',
  propane: 'Пропан',
  butane: 'Бутан',
};

export const fuelsAndGazEnum = {
  dieselFuel: 'dieselFuel',
  ai92: 'ai92',
  ai95: 'ai95',
  ai98: 'ai98',
  electricity: 'electricity',
  methane: 'methane',
  propane: 'propane',
  butane: 'butane',
};
