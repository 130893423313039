// @flow
import React from 'react';
import styled from 'styled-components';

import type {DriverStatus, NodeFilterType} from './../../lib/types';
import {Card, Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from './../../components';
import OrgUnitSelect from '../../components/OrgUnitSelect';
import type {FilterChildrenParams} from './../../components/hoc/Filter';
import { PositionSelect } from '../../components/selects/AutoGeneratedEnumSelects';

const { DriverStatusSelect, DriverSelect, EmployeePositionSimpleSelect } = Selects;
const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;

const StyledPositionSelect = styled(PositionSelect)`
  .ant-select-selection {
    font-size: 13px;
  }
`;

export type DriverFilterParams = {
  'employee.nodeId'?: number,
  type?: string,
  status?: DriverStatus,
  nodeId?: number,
  id?: number,
  nodeFilterType?: NodeFilterType,
  'employee.positionTitle'?: number,
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: DriverFilterParams
};

const getCurrentRoute = () => {
  const currentRoute = window.location.pathname;
  const currentTab = currentRoute.split("/").slice(-1);
  return currentTab[0];
}

const DriverFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <Content>
    <FilterContainer>
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter
        }: FilterChildrenParams<DriverFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <DriverSelect
                  size="small"
                  value={values.id}
                  onChange={(value: number) => changeValue('id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('employee.nodeId', value)}
                  value={values['employee.nodeId']}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            {(getCurrentRoute() === 'electricVehiclesDrivers') && (
              <FilterItem>
                <Field mBottomNone>
                  <EmployeePositionSimpleSelect
                    size="small"
                    onChange={value => changeValue('employee.positionTitle', value)}
                    value={values['employee.positionTitle']}
                />
                </Field>
              </FilterItem>
            )}
            <FilterItem>
              <Field mBottomNone>
                <DriverStatusSelect
                  size="small"
                  value={values['status']}
                  onChange={value => changeValue('status', value)}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default DriverFilter;
