// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import { tripApi } from '../../../../../lib/api';
import type { Trip, Vehicle } from '../../../../../lib/types';
import {
  tripStatusEnum,
  ownerTypes,
  tripStatuses,
  vehicleTypes,
  vehicleGroups
} from '../../../../../lib/enum';

import { Filter } from '../../../../Trips';
import { ListTable } from '../../../lib';
import { Link } from '@reach/router';
import {
  applyMaskToValue,
  formatDateRangeString
} from '../../../../../lib/helpers';
import { formatLicensePlateMask } from '../../../../../components/masked-inputs/LicensePlateInput';

const SELF_TRIP_COLUMNS = [
  {
    title: '№',
    dataIndex: 'idNumber',
    sorter: true,
    width: 25
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (status: string, record: Trip) => (
      <Link to={`/trips/self/${record.id}/card`}>{tripStatuses[status]}</Link>
    )
  },
  {
    title: 'Тип',
    dataIndex: 'vehicleType',
    sorter: true,
    render: (text: string, record: Trip): ?string =>
      vehicleTypes[text] || vehicleGroups[record.vehicleGroup]
  },
  {
    title: 'Служба',
    sorter: true,
    sorterKey: 'node.name',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Период выделения',
    dataIndex: 'startDate',
    sorterKey: 'expectedRoute.startDate',
    sorter: true,
    render: (
      text: string, // eslint-disable-line no-unused-vars
      record: Trip
    ): string => formatDateRangeString(record.startDate, record.endDate)
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (vehicle: Vehicle, trip: Trip) => {
      if (vehicle) {
        const licensePlate =
          vehicle.licensePlate &&
          applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
        if (vehicle.isDeleted) return licensePlate;
        return vehicle.ownerType === ownerTypes.self ? (
          <Link to={`/vehicles/${vehicle.id}`}>{licensePlate}</Link>
        ) : (
          // $FlowFixMe
          <Link to={`/vehicles/contract/${trip.contractVehicleId}`}>
            {licensePlate}
          </Link>
        );
      }
      return null;
    }
  }
];

export default class extends ListTable<Trip> {
  fetch = async (page: number = 1) => {
    const params = {
      ...super.fetch(page),
      isArchive: false,
      'vehicle.ownerType': ownerTypes.self,
      status: tripStatusEnum.verification
    };
    try {
      const data = await tripApi.fetchTrips(params);
      const totalSum = await tripApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(SELF_TRIP_COLUMNS)}
      </>
    );
  }
}
