// @flow
import type { Moment } from "moment";
import type { FormErrors } from "../../lib/types";
import type { BusinessDay, WayPoint } from "./../../lib/types";
import type { WaypointsType } from "./WaypointsForm";

import { waypointTypeEnum } from "../../lib/enum";
import { withEmptyRow, withoutEmptyRow } from "../../lib/helpers";

import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";
import moment from "moment";
import shortId from "shortid";

/**
 * Сортирует маршрутные точки по времени прибытия и отправления
 * @param waypoints Маршрнутные точки
 * @returns {WayPoint[]} Отсортированные маршрутные точки
 */
export function sortWaypoints(waypoints: WayPoint[]) {
  return orderBy(waypoints, ['departureDateTime', 'arrivedDateTime']);
}

/**
 * Функция валидации маршрутной точки
 * @param waypoint Маршрутная точка
 * @param {FormErrors<WayPoint>} Объект с текстом ошибки по ключам
 */
export function validateWaypoint(
  waypoint: WayPoint,
  transitWaypoints?: WayPoint[]
): FormErrors<WayPoint> {
  const isEndPoint = waypoint.type === 'end';
  const isStartPoint = waypoint.type === 'start';
  const errors = {};
  if (isEmpty(waypoint.name)) {
    errors.name = 'Необходимо указать наименование точки';
  }
  if (!isStartPoint && isEmpty(waypoint.arrivedDateTime)) {
    errors.arrivedDateTime = 'Необходимо для заполнения';
  }
  if (!isEndPoint && isEmpty(waypoint.departureDateTime)) {
    errors.departureDateTime = 'Необходимо для заполнения';
  }
  if (isEndPoint && transitWaypoints) {
    const lastWaypoint = transitWaypoints[transitWaypoints.length - 1];
    if (
      lastWaypoint &&
      moment(lastWaypoint.departureDateTime).isAfter(waypoint.arrivedDateTime)
    ) {
      errors.arrivedDateTime =
        'Время прибытия должно быть больше времени отправления из последней точки';
    }
  }
  if (
    !isEmpty(waypoint.arrivedDateTime) &&
    !isEmpty(waypoint.departureDateTime)
  ) {
    const arrivedDateTime = moment.utc(waypoint.arrivedDateTime);
    const departureDateTime = moment.utc(waypoint.departureDateTime);
    if (!arrivedDateTime.isValid()) {
      errors.arrivedDateTime = 'Неверное значение даты';
    }
    if (!departureDateTime.isValid()) {
      errors.arrivedDateTime = 'Неверное значение даты';
    }
    if (
      arrivedDateTime.isValid() &&
      departureDateTime.isValid() &&
      arrivedDateTime.isAfter(departureDateTime)
    ) {
      errors.arrivedDateTime =
        'Время прибытия должно быть раньше времени отправления';
    }
  }
  return errors;
}

/**
 * Функция для получения маршрутных точек
 */
export const getWaypoints: (
  waypoints: WayPoint[]
) => {
  start?: WayPoint,
  transit: WayPoint[],
  end?: WayPoint
} = (waypoints: WayPoint[]) => {
  const transit = waypoints.filter(w => w.type === waypointTypeEnum.transit);
  const start = waypoints.find(
    waypoint => waypoint.type === waypointTypeEnum.start
  );

  const end = waypoints.find(
    waypoint => waypoint.type === waypointTypeEnum.end
  );
  return { start, transit, end };
};

/**
 * Возврашает по-умолчанию проставленные даты прибытия и отправления
 * по дате ПРИБЫТИЯ
 *
 *
 * @param value Дата прибытия
 * @param prevDepartureDate Предыдущая дата отправления
 * @param waypoint Точка
 * @returns
 * {{
 *  arrivedDateTime: string,
 *  departureDateTime?: string
 * }} Поля с временем прибытия и отправления
 */
export function getDefaultDateTimes(
  value?: string,
  prevDepartureDate: ?string,
  waypoint: WayPoint
): {
  arrivedDateTime?: string,
  departureDateTime?: string
} {
  const momentValue = moment
    .utc(value)
    .add(Math.abs(0 - (moment.utc(value).minute() % 30)), 'm');
  const momentPrevDate = moment.utc(prevDepartureDate);
  let [hours, minutes] = prevDepartureDate
    ? [momentPrevDate.hours(), momentPrevDate.minutes()]
    : [8, 0];
  // Если мы меняем уже проставленное значение даты,
  // то берем значение часов и минут из даты прибытия
  const hasPrevValue = [
    waypoint.arrivedDateTime,
    waypoint.departureDateTime
  ].filter(Boolean).length;
  if (hasPrevValue) {
    hours = momentValue.hours();
    minutes = momentValue.minutes();
  }
  const arrivedDateTime = moment.utc(value).set({
    hours,
    minutes,
    seconds: 0,
    milliseconds: 0
  });
  if (prevDepartureDate && !hasPrevValue) arrivedDateTime.add(1, 'hour');
  return {
    arrivedDateTime: arrivedDateTime.toISOString(),
    departureDateTime:
      waypoint.type !== waypointTypeEnum.end
        ? arrivedDateTime.toISOString()
        : undefined
  };
}

/**
 * Добавляет пустой WayPoint к массиву WayPoint
 *
 * @param waypoints Массив
 * @returns {WayPoint[]} Массив с пустым WayPoint
 */
export const withEmptyWaypoint = (waypoints: WayPoint[]) => {
  return withEmptyRow(
    waypoints.map(w => ({
      // Добавляем key для элементов, у которых он отсутсвует
      ...w,
      key: w.key || shortId.generate()
    })),
    {
      emptyRow: {
        key: shortId.generate(),
        type: 'transit'
      },
      ignoredEmptyRowKeys: ['key']
    }
  );
};

/**
 * Вовзращает массив WayPoint без пустой маршрутной точки
 * @param waypoints Массив точек
 * @returns {WayPoint[]} Массив без пустых точек
 */
export const withoutEmptyWaypoint = (waypoints: WayPoint[]) => {
  return withoutEmptyRow(waypoints, { type: 'transit' }, ['key']);
};

/**
 * Копирует маршрутную точку и возвращает обновленный массив точек
 * @param waypoint Копируемая точка
 * @param waypoints Массив точек
 * @returns {WayPoint[]} Получившийся массив точек
 */
export const copyWaypoint = (waypoint: WayPoint, waypoints: WayPoint[]) => {
  const transit = [...waypoints];
  // Перегенерируем ключ для уникальности и удалим ненужные поля
  waypoint = omit({ ...waypoint, key: shortId.generate() }, [
    'id',
    'arrivedDateTime',
    'departureDateTime',
    'notation'
  ]);
  // Последний непустой WayPoint
  transit.splice(waypoints.length - 1, 0, waypoint);
  return transit;
};

export type BusinessDaysMap = Map<string,BusinessDay>;

export type DisableDatePayload = {
  type: WaypointsType,
  calendarDates: BusinessDaysMap
};

export type DisabledDepartureDatePayload = {
  currentDate: string | Date,
  routeStartDate: ?string | ?Date,
  routeEndDate: ?string | ?Date,
  arrivalDate: ?string | ?Date,
  prevArrivalDate: ?(string | Date),
  setLoading: (value: boolean) => void
};

export type DisabledArrivalDatePayload = {
  // Проверяемая дата
  currentDate: string | Date,
  // Дата начала маршрута
  routeStartDate: ?string | ?Date,
  // Дата окончания маршрута
  routeEndDate: ?string | ?Date,
  // Дата отправления
  departureDate: ?string | ?Date,
  // Дата прибытия предыдущей точки
  prevDepartureDate: ?string | ?Date,
  setLoading: (value: boolean) => void
};

export const isDayOff = (dates: BusinessDaysMap, day: Moment) => {
  const date = dates.get(day.clone().startOf('day').format())
  if (
    (date?.dayType === 'dayOff') 
    || (day.isAfter(day.clone().hour((date?.workHours ?? 0) + 9).minute(0)))
    || (day.isBefore(day.clone().hour(8).minute(0)))
  ) {
    return true
  }
  return false;
}

/**
 * Функция блокировки дат в селекторе даты
 *
 * Вызывается для каждого элемента даты в календаре
 *
 * Возвращает true, если нужно дату заблокировать
 *
 * @returns {string} Строка с сообщением об ошибке
 */
export const getDisabledArrivalDate = (
  {
    routeEndDate,
    routeStartDate,
    currentDate,
    departureDate,
    prevDepartureDate,
    setLoading
  }: DisabledArrivalDatePayload,
  { type, calendarDates }: DisableDatePayload
): string | boolean => {
  if (type === 'business') {
    return false;
  }

  if (prevDepartureDate) {
    if (moment(currentDate).isSame(prevDepartureDate))
      return 'Дата прибытия не может быть датой отправления предыдущей точки';
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc(prevDepartureDate).startOf('day'))
    ) {
      return 'Дата прибытия не может быть раньше дня отправления из предыдущей точки';
    }
  }

  if (routeStartDate) {
    if (
      moment
        .utc(currentDate)
        .startOf('day')
        .isBefore(moment.utc(routeStartDate).startOf('day'))
    ) {
      return 'Дата прибытия не может быть раньше начала маршрута';
    }
  }

  if (routeEndDate) {
    if (
      moment.utc(currentDate).isAfter(moment.utc(routeEndDate).endOf('day'))
    ) {
      return 'Дата прибытия не должна быть позже окончания маршрута';
    }
  }

  return false
};

export const getDisabledDepartureDate = (
  {
    currentDate,
    routeStartDate,
    routeEndDate,
    arrivalDate,
    prevArrivalDate
  }: DisabledDepartureDatePayload,
  { type, calendarDates }: DisableDatePayload
): string | boolean => {
  const currentDateInMoment = moment.utc(currentDate);
  if (type === 'business') {
    return false;
  }
  /**
   * Для аварийных заявок
   */
  if (type === 'emergency') {
    const arrivalDateInMoment = moment.utc(arrivalDate);
    const calendarDate = calendarDates.get(arrivalDateInMoment.clone().startOf('day').format());
    if(calendarDate) {
      let day = arrivalDateInMoment.clone();
      const arrivalDateBeforeToday = arrivalDateInMoment.isBefore(moment.utc().startOf('day'))
      const arrivalDateAfterWorkingHours = arrivalDateInMoment.isAfter(arrivalDateInMoment.clone().startOf('day').hour(calendarDate.workHours + 9))
      if(calendarDate.dayType === 'dayOff' || arrivalDateAfterWorkingHours || arrivalDateBeforeToday) {
        for (; calendarDates.get(day.add(1, 'days')?.clone()?.startOf('day').format())?.dayType === 'dayOff';) { }
      }
      if(!currentDateInMoment.isBetween(moment.utc(arrivalDate).startOf('day'), day.endOf('day'), undefined, '[]')) {
        return 'Доступно для выбора только в текущем нерабочем периоде';
      }
    }
  }
  if (arrivalDate) {
    if (
      moment
        .utc(currentDate)
        .isBefore(moment.utc(arrivalDate), 'day')
    ) {
      return 'Дата отправления не может быть раньше дня прибытия';
    }
  }
  if (routeStartDate) {
    if (
      moment
        .utc(currentDate)
        .isBefore(moment.utc(routeStartDate), 'day')
    ) {
      return 'Дата отправления не может быть раньше начала маршрута';
    }
  }
};

export const getDisabledDepartureTime = (
  {
    currentDate,
    routeStartDate,
    routeEndDate,
    arrivalDate,
    prevArrivalDate
  }: DisabledDepartureDatePayload,
  { type, calendarDates }: DisableDatePayload
): string | boolean => {
  
  if (type === 'emergency') {
    const currentDateInMoment = moment.utc(currentDate);
    const arrivalDateInMoment = moment.utc(arrivalDate);
    const calendarDate = calendarDates.get(arrivalDateInMoment.clone().startOf('day').format());
    if(calendarDate) {
      let day = arrivalDateInMoment.clone();
      const arrivalDateBeforeToday = arrivalDateInMoment.isBefore(moment.utc().startOf('day'))
      const arrivalDateAfterWorkingHours = arrivalDateInMoment.isAfter(arrivalDateInMoment.clone().startOf('day').hour(calendarDate.workHours + 9))
      if(calendarDate.dayType === 'dayOff' || arrivalDateAfterWorkingHours || arrivalDateBeforeToday) {
        for (; calendarDates.get(day.add(1, 'days')?.clone()?.startOf('day').format())?.dayType === 'dayOff';) { }
      }
      if(day.isSame(arrivalDateInMoment, 'day') && (arrivalDateAfterWorkingHours || arrivalDateBeforeToday)) {
        day = day.endOf('day')
      } else {
        day.hour(7).minute(30)
      }
      if(!currentDateInMoment.isBetween(moment.utc(arrivalDate), day, undefined, '[]')) {
        return 'Доступно для выбора только в текущем нерабочем периоде';
      }
    }
  }
  return false;
};
