// @flow
import React, {useEffect, useState} from 'react';
import Button from 'antd/lib/button';
import Table from './Table';
import {
  ImageButtons,
  ModalImage,
  ModalNextArrow,
  ModalPrevArrow,
  NextArrow,
  PrevArrow,
  SlideImage,
  StyledModal,
  StyledReactSlick,
  StyledSlide,
} from './Slider.elements';
import FileIcon from '../../assets/images/file-icon.svg';
import {formatDateTimeToString} from '../../lib/helpers';
import {downloadRequestWithToken} from '../../lib/api';
import {notificationError, notificationLoading} from '../Notifications';
import notification from 'antd/lib/notification';

/**
 * Элемент данных слайдера
 */
export type SliderItem = {
  // Наименование файла
  title: string,
  // Ссылка на картинку
  imageUrl: ?string,
  url: string,
  contentType: string,
  // Вызывается при нажатии на картинку
  onSlideClick?: Function,
  addSlidesWidth?: Function
};

/**
 * Компонент отдельного слайда
 */
const Slide = ({imageUrl, title, onSlideClick}: SliderItem) => {
  return (
    <StyledSlide>
      <SlideImage
        title={title}
        src={imageUrl || FileIcon}
        onClick={onSlideClick}
      />
    </StyledSlide>
  );
};

type SliderProps = {
  // Элементы слайдера
  items: SliderItem[],
  // Отображать в табличном виде
  tableView?: boolean,
  // Количество отображаемых слайдов на одной странице
  slidesToShow?: number,
  // Количество появляющихся/исчезающих слайдов, после нажатия на стрелки
  slidesToScroll?: number
};

/**
 * Компонент слайдера картинок,
 * с возможностью открытия картинок в модальном окне
 */
const Slider = ({
  items,
  tableView = false,
  slidesToShow = 4,
  slidesToScroll = 1,
  ...sliderProps
}: SliderProps) => {
  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(items.length >= slidesToShow);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  /**
   * Переход к следующему слайду
   */
  const toNextSlide = () => {
    if (currentSlide !== items.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  /**
   * Переход к предыдущему слайду
   */
  const toPrevSlide = () => {
    if (currentSlide !== 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  /**
   * Метод вызываемый при переключении слайдов
   *
   * react-slick не умеет сам прятать кнопки для переключения слайдов
   * поэтому мы помогаем ему в этом
   *
   * Здесь oldIndex и newIndex это индексы новых слайдов -
   * слайдов, которые появились в отображении
   *
   * Например, у нас всего 8 слайдов,
   * из них мы всегда отображаем 4 слайда (prop slidesToShow),
   * при переключении слайдов появляется новый 1 слайд (prop slidesToScroll)
   *
   * Тогда при нажатии вправо в первый раз oldIndex == 0, newIndex == 1
   * При нажатии вправо во второй раз oldIndex == 1, newIndex == 2
   * и т.д. пока не упремся в правую или левую границы
   *
   * @param oldIndex Индекс предыдушего слайда
   * @param newIndex Индекс текущего нового слайда
   */
  const beforeSlideChange = (oldIndex: number, newIndex: number) => {
    /**
     * Проверка на то, что это правая граница
     *
     * Например, при 8 слайдах, 4 показываемых слайдов и шаге в 2 слайда получаем:
     * В 1-й раз нажали вправо: 8 - 2 !== 4
     * Во 2-й раз нажали вправо: 8 - 4 == 4 (значит достигли правого края)
     *
     */
    const isRightBound = items.length - newIndex === slidesToShow;
    // Если newIndex == 0, то это левая граница
    const isLeftBound = newIndex === 0;
    // Если достигнута левая граница, то не показываем левую стрелку
    if (isLeftBound) {
      setShowPrevArrow(false);
    } else {
      setShowPrevArrow(true);
    }
    // Если достигнута правая граница, то не показываем правую стрелку
    if (isRightBound) {
      setShowNextArrow(false);
    } else {
      setShowNextArrow(true);
    }
  };

  // Подписываемся на нажатие клавиш вправо/влево
  useEffect(() => {
    const eventListener = ({keyCode}) => {
      if (modalVisible) {
        // Стрелка влево
        if (keyCode === 37) {
          toPrevSlide();
          // Стрелка вправо
        } else if (keyCode === 39) {
          toNextSlide();
        }
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => window.removeEventListener('keydown', eventListener);
  });

  const slide = items[currentSlide];
  return (
    <>
      {tableView ? (
        <Table
          onRow={(file, index) => ({
            onClick: () => {
              setCurrentSlide(index ? index : 0);
              setModalVisible(true);
            },
          })}
          fetch={async () => undefined}
          columns={[
            {
              title: 'Наименование файла',
              dataIndex: 'title',
            },
            {
              title: 'Тип файла',
              dataIndex: 'contentType',
            },
            {
              title: 'Дата загрузки',
              dataIndex: 'createdTime',
              render: date => formatDateTimeToString(date),
            },
            {
              stopPropagation: true,
              dataIndex: 'imageUrl',
              width: '50px',
              render: (imageUrl, {url}) => imageUrl
                ? (
                  <Button
                    type="primary"
                    icon="download"
                    size="small"
                    href={`${imageUrl}?isInline=false`}
                    target="_blank"
                  />
                )
                : (
                  <Button
                    type="primary"
                    icon="download"
                    size="small"
                    onClick={async () => {
                      try {
                        notificationLoading({message: 'Скачивание файла...', key: 'downloading'});
                        await downloadRequestWithToken(url, null, null, true);
                        notification.success({message: 'Скачивание завершено'});
                      } catch (e) {
                        notificationError(e);
                      } finally {
                        notification.close('downloading');
                      }
                    }}
                  />
                ),
            },
          ]}
          data={items}
        />
      ) : (
        <StyledReactSlick
          {...sliderProps}
          accessibility={false}
          beforeChange={beforeSlideChange}
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToScroll}
          infinite={false}
          prevArrow={<PrevArrow visible={showPrevArrow} />}
          nextArrow={<NextArrow visible={showNextArrow} />}
          variableWidth
          swipe={false}
        >
          {items.map((value, index) => (
            <Slide
              onSlideClick={() => {
                setCurrentSlide(index);
                setModalVisible(true);
              }}
              {...value}
              key={index}
            />
          ))}
        </StyledReactSlick>
      )}
      {slide && (
        <StyledModal
          onCancel={() => setModalVisible(false)}
          title={slide.title}
          footer={null}
          width="50%"
          visible={modalVisible}
        >
          <ModalPrevArrow onClick={toPrevSlide} visible={currentSlide !== 0} />
          <ModalNextArrow
            onClick={toNextSlide}
            visible={currentSlide !== items.length - 1}
          />
          {slide.imageUrl ? (
            <>
              <ModalImage
                loading="lazy"
                src={slide.imageUrl}
                alt={slide.title}
              />
              <ImageButtons>
                <Button
                  type="primary"
                  href={`${slide.imageUrl}?isInline=false`}
                  target="_blank"
                >
                  Скачать
                </Button>
                <Button
                  type="primary"
                  href={`${slide.imageUrl}?isInline`}
                  target="_blank"
                >
                  Открыть в новой вкладке
                </Button>
              </ImageButtons>
            </>
          ) : (
            <p>
              Предпросмотр недоступен. Вы можете &nbsp;
              <a
                href={`${slide.url}?isInline=false`}
                // eslint-disable-next-line
                target="_blank"
              >
                скачать
              </a>
              &nbsp; файл
            </p>
          )}
        </StyledModal>
      )}
    </>
  );
};

export default Slider;
