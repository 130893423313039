// @flow
import React, {useEffect, useState} from 'react';
import {Link} from '@reach/router';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {formatDate, MONTH, toLocalStringRu} from './../../../lib/helpers';
import {budgetAnalysisTypes, budgetAnalysisTypesEnum} from './../../../lib/enum';
import {budgetAnalysisApi} from '../../../lib/api';

import {notificationLoading} from '../../../components/Notifications';
import {Table} from './../../../components/ui';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Section, SectionTitle} from './../../../components/layout';

const Content = styled.div`
  padding: 0px;
`;

type Props = {
  branchBudgetSummaryExpenseDirectionId: number,
  budgetAnalysisId: number,
  month: number
};
export default ({
  branchBudgetSummaryExpenseDirectionId,
  month,
  budgetAnalysisId
}: Props) => {
  const [data, setData] = useState([]);

  const fetch = async () => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting'
      });
      const data = await budgetAnalysisApi.getExpenseDirectionInfo({
        branchBudgetSummaryExpenseDirectionId,
        month
      });
      setData(data);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      notification.close('getting');
    }
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchBudgetSummaryExpenseDirectionId, month]);

  const link = (documentNumber: string, id: number, type: string) => {
    switch (type) {
      case budgetAnalysisTypesEnum.externalAct:
        return <Link to={`/admin/act/${id}`}>{documentNumber}</Link>;
      case budgetAnalysisTypesEnum.maintenance:
        return <Link to={`/maintenances/${id}`}>{documentNumber}</Link>;
      default:
        return documentNumber;
    }
  };

  return (
    <>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to="/budget/budget-analysis">Анализ затрат</Crumb>
            <Crumb to={`/budget/budget-analysis/${budgetAnalysisId}`}>
              Анализ бюджета № {budgetAnalysisId}
            </Crumb>
            <Crumb>Детализация</Crumb>
          </Breadcrumbs>
        }
      />
      <Section>
        <SectionTitle>Детализация за {MONTH[month - 1]}</SectionTitle>
        <Content>
          <Table
            data={data}
            rowKey="id"
            columns={[
              {
                title: 'Номер документа',
                dataIndex: 'documentNumber',
                width: 100,
                render: (documentNumber: string, record: any) =>
                  link(documentNumber, record.id, record.type)
              },
              {
                title: 'Дата формирования',
                dataIndex: 'date',
                render: date => formatDate(date)
              },
              {
                title: 'Сумма по документу',
                dataIndex: 'sum',
                render: sum => toLocalStringRu(sum)
              },
              {
                title: 'Тип документа',
                dataIndex: 'type',
                render: type => budgetAnalysisTypes[type]
              }
            ]}
          />
        </Content>
      </Section>
    </>
  );
};
