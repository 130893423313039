// @flow
import CommonApi from './../commonApi';
import {ContractorBranchLink} from '../types/contractorBranchLink';
import {fetchRequest} from './index';
import {notificationError} from '../../components/Notifications';

const contractorBranchLink = new CommonApi<ContractorBranchLink>(
  'contractorbranchlink',
  'Закрепление подрядчиков',
);

const getContractors = async (params) => {
  try {
    return await fetchRequest.get('/ContractorBranchLink/getContractors', params);
  } catch (e) {
    notificationError(e);
  }
}

const getContractorByOrganization = async orgUnutId => {
  try {
    return await fetchRequest.get(`/ContractorBranchLink/getContractorByOrganization/${orgUnutId}`);
  } catch (e) {
    notificationError(e);
  }
}

export default {...contractorBranchLink, getContractors, getContractorByOrganization};
