import React from 'react';
import { TypesOfOilsItem } from '../../../../../lib/types/typesOfOils';
import styled from 'styled-components';
import { fuelOilsTypes } from '../../../../../lib/enum/fuelType';
import Select from 'antd/lib/select';

const { Option } = Select;

const StyledSelect = styled(Select)`
  .ant-select-selection-selected-value .additionaly,
  .ant-select-selection__choice__content .additionaly {
    display: none;
  }
`;

const Additionaly = styled.div.attrs(() => ({
  className: 'additionaly'
}))`
  display: flex;
  justify-content: space-between;
`;

const LittleText = styled.p`
  margin: 0;
  font-size: 12px;
`;

type Props = {
  data: TypesOfOilsItem[],
  onChange: data => void,
  oilTypeId: number
};

const OilSelect = ({ data, oilTypeId, onChange }: Props) => {
  if (!data.length) {
    return null;
  }
  return (
    <StyledSelect
      value={oilTypeId}
      onChange={dataId => {
        const selectedItem = data.find(item => item.id === dataId);
        onChange(selectedItem);
      }}
      size="small"
    >
      {data.map((item: TypesOfOilsItem) => (
        <Option key={item.id} value={item.id}>
          {item.parusOil.name}
          <Additionaly>
            <LittleText className="code">{item.parusOil.mnemocode}</LittleText>
            <LittleText className="type">
              {fuelOilsTypes[item.oilType]}
            </LittleText>
          </Additionaly>
        </Option>
      ))}
    </StyledSelect>
  );
};

export default OilSelect;
