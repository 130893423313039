// @flow
import InputNumber from 'antd/lib/input-number';
import React from 'react';
import styled from 'styled-components';
import { regulationLimitTypeEnum } from '../../../../../../../lib/enum';
import {
  convertContractVehicleToString,
  tooltipFullText
} from '../../../../../../../lib/helpers';

import type {
  AssignmentLimit,
  BusinessCalendarForBudget
} from '../../../../../../../lib/types';

import { Cell, Row } from '../../../elements';

import ContractVehicleSelect from './ContractVehicleSelect';
import { getGridTemplate } from './elements';
import { WorkDaysCalendarContext } from './WorkDaysCalendarContext';

type Props = {
  // Лимит
  limit: AssignmentLimit,
  // Индекс лимита в массиве лимитов
  index: number,
  // Функция изменения значения лимита по ключу в массиве лимитов
  onChange: (index: number, name: $Keys<AssignmentLimit>, value: any) => void,
  editable: boolean,
  selectedVehiclesIds: number[]
};

const StyledRow = styled(Row)`
  display: flex;
`;

const NumberCell = styled(Cell)`
  padding: 0;
  width: 57.5px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  margin: 2px;
`;
const ContentNumberCell = styled(Cell)`
  width: 57.5px;
  padding: 8px;
`;

const FirstFixedCell = styled(Cell)`
  background: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 300px;
`;
const SecondFixedCell = styled(FirstFixedCell)`
  border-right: 1px solid #c0ccd7;
`;
const DayInfoCell = styled(Cell)`
  min-width: 1380px;
  width: calc(100% - 600px);
  padding: 0;
`;
/**
 * Компонент строки лимита в закреплении
 */
export default ({
  limit,
  index,
  editable,
  onChange,
  selectedVehiclesIds
}: Props) => {
  return (
    <>
      <StyledRow media={getGridTemplate(limit.regulationLimit.type)} gutter={0}>
        {limit.regulationLimit.type === regulationLimitTypeEnum.orgUnit && (
          <FirstFixedCell>
            {limit.regulationLimit.orgUnitName ?? '-'}
          </FirstFixedCell>
        )}
        {limit.regulationLimit.type ===
          regulationLimitTypeEnum.oneOffRequests && (
          <FirstFixedCell>
            {limit.regulationLimit.orgUnitName ?? '-'}
          </FirstFixedCell>
        )}
        {limit.regulationLimit.type === regulationLimitTypeEnum.employee && (
          <FirstFixedCell>
            {tooltipFullText(
              limit?.regulationLimit?.employeePositionTitle +
                ' ' +
                limit?.regulationLimit?.employeeOrgUnitName ?? '',
              40
            )}
          </FirstFixedCell>
        )}
        {limit.regulationLimit.type === regulationLimitTypeEnum.ovb && (
          <FirstFixedCell>
            {limit.regulationLimit.ovbName ?? '-'}
          </FirstFixedCell>
        )}
        <SecondFixedCell>
          {editable ? (
            <ContractVehicleSelect
              size="small"
              contractVehiclePlanId={limit.contractVehiclePlanId}
              value={limit.contractVehicleId}
              onChange={id => onChange(index, 'contractVehicleId', id)}
              // excludedIds={selectedVehiclesIds}
            />
          ) : (
            tooltipFullText(
              convertContractVehicleToString(limit.contractVehicle) ?? '-',
              35
            )
          )}
        </SecondFixedCell>

        <DayInfoCell>
          {limit.assignmentMonths.map((month, indexMonth) => {
            const { assignmentMonths } = limit;
            return (
              <>
                <WorkDaysCalendarContext.Consumer>
                  {workDaysCalendar => (
                    <>
                      {editable ? (
                        <NumberCell>
                          <StyledInputNumber
                            value={month.weekDaysCount}
                            max={
                              workDaysCalendar.find(
                                (wdc: BusinessCalendarForBudget) =>
                                  wdc.month === indexMonth
                              )?.workingDaysInMonth
                            }
                            min={0}
                            onChange={weekDaysCount => {
                              assignmentMonths[indexMonth] = {
                                ...assignmentMonths[indexMonth],
                                weekDaysCount
                              };
                              onChange(
                                index,
                                'assignmentMonths',
                                assignmentMonths
                              );
                            }}
                          />
                        </NumberCell>
                      ) : (
                        <ContentNumberCell>
                          {month.weekDaysCount}
                        </ContentNumberCell>
                      )}
                      {editable ? (
                        <NumberCell>
                          <StyledInputNumber
                            value={month.daysOffCount}
                            max={
                              workDaysCalendar.find(
                                (wdc: BusinessCalendarForBudget) =>
                                  wdc.month === indexMonth
                              )?.dayOffsInMonth
                            }
                            min={0}
                            onChange={daysOffCount => {
                              assignmentMonths[indexMonth] = {
                                ...assignmentMonths[indexMonth],
                                daysOffCount
                              };
                              onChange(
                                index,
                                'assignmentMonths',
                                assignmentMonths
                              );
                            }}
                          />
                        </NumberCell>
                      ) : (
                        <ContentNumberCell>
                          {month.daysOffCount}
                        </ContentNumberCell>
                      )}
                    </>
                  )}
                </WorkDaysCalendarContext.Consumer>
              </>
            );
          })}
        </DayInfoCell>
      </StyledRow>
    </>
  );
};
