import type { Action } from './redux';
import { Dispatch } from 'redux';

const OPEN_SIDEBAR = 'vehicles/sidebar/open';
const CLOSE_SIDEBAR = 'vehicles/sidebar/close';
const TOGGLE_SIDEBAR = 'vehicles/sidebar/toggle';

type SidebarState = {
  opened: boolean
};

const initialState: SidebarState = {
  opened: true
};

const reducer = (state: SidebarState = initialState, action: Action) => {
  const { type } = action;
  switch (type) {
    case OPEN_SIDEBAR:
      return { ...state, opened: true };
    case CLOSE_SIDEBAR:
      return { ...state, opened: false };
    case TOGGLE_SIDEBAR:
      return { ...state, opened: !state.opened };
    default:
      return state;
  }
};

export const openSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: OPEN_SIDEBAR });

export const closeSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: CLOSE_SIDEBAR });

export const toggleSidebar = () => (dispatch: Dispatch) =>
  dispatch({ type: TOGGLE_SIDEBAR });

export default reducer;
