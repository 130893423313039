import React, {useEffect, useState} from 'react';
import {Modal} from 'antd';
import notification from 'antd/lib/notification';
import {fetchRequest} from '../../../lib/api';
import {notificationLoading} from '../../../components/Notifications';
import TreeSelect from 'antd/lib/tree-select';

type Props = {
	visible: boolean,
	closeModal: () => void,
	vehicleId: number,
}

export const ModalBranchSelect = ({visible, closeModal, vehicleId}: Props) => {
	const [orgUnitId, setOrgUnitId] = useState(null);
	const [tree, setTree] = useState([]);

	useEffect(() => {
		fetchRequest.get('/orgUnit/branches').then(tree => {
			setTree([{
				title: tree.name,
				children: tree.children.map(i => ({
					title: i.name,
					children: [],
					value: i.id,
				})),
				value: tree.id,
			}]);
		})
	}, []);

	return (
		<Modal
			destroyOnClose
			title='Выберите филиал'
			visible={visible}
			onCancel={() => {
				setOrgUnitId(null);
				closeModal()
			}}
			onOk={async () => {
				if (!orgUnitId) {
					notification.error({
						message: 'Выберите филиал',
					})
					return;
				}
				try {
					notificationLoading({
						message: 'Перемещение ТС...',
						key: 'moving',
					})
					await fetchRequest.get(`/vehicle/${vehicleId}/CopyToAnotherBranch/${orgUnitId}`);
					setOrgUnitId(null);
					closeModal();
					notification.success({
						message: 'ТС перемещен в выбранный филиал'
					});
				} catch (e) {
					notification.error({
						message: 'Ошибка',
						description: e.message,
					})
				} finally {
					notification.close('moving')
				}
			}}
		>
			<TreeSelect
				placeholder="Выберите подразделение"
				onChange={setOrgUnitId}
				treeData={tree}
			/>
		</Modal>
	)
}
