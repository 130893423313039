// @flow
import React, { useCallback } from 'react';
import qs from 'query-string';

export const PageContext = React.createContext<number>(1);
export const FilterContext = React.createContext<
  $Rest<any, {| page: number |}>
>({});

const Page = (props: { children: any }) => {
  let { page, ...filters } = qs.parse(window.location.search);

  const Children = useCallback(() => props.children, [props.children]);

  return (
    <PageContext.Provider value={page}>
      <FilterContext.Provider value={filters}>
        <Children />
      </FilterContext.Provider>
    </PageContext.Provider>
  );
};

export default Page;
