// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import {Filter, OrgUnitSelect, Selects} from '../../../components';
import {Spoiler} from '../../../components/ui';
import Grid, {GridItem} from '../../../components/layout/Grid';
import Field from '../../../components/card/Field';
import type {NodeFilterType} from '../../../lib/types';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import LocationSelect from '../../../components/selects/LocationSelect';

const Content = styled(Grid)`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  margin-top: 16px;

  & .ant-btn {
    margin: 0 10px;
  }
`;

const StyledLocationSelect = styled(LocationSelect)`
  .ant-select-selection {
    height: 24px;
    font-size: 13px;
  }

  .ant-select-selection-selected-value {
    line-height: 24px;
  }

  .ant-select-search__field {
    width: 90%
    margin-left: 10px;
    position: absolute;
  }
`;

const {YesNoSelect} = Selects;

export type LocationFilterParams = {
  nodeId?: number,
  isDefault?: boolean,
  nodeFilterType?: NodeFilterType,
  search?: string,
  id?: number,
  source?: string
};

type Props = {
  applyFilter: (filters: LocationFilterParams) => void,
  cleanFilter: Function,
  filter: LocationFilterParams
};

const LocationFilter = ({filter, cleanFilter, applyFilter}: Props) => (
  <Spoiler label="Фильтр">
    <Content width="50%" cols={3} gutter="16px">
      <Filter
        initialValues={filter}
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
      >
        {({
          values,
          changeValue,
          cleanFilter,
          applyFilter,
        }: FilterChildrenParams<LocationFilterParams>) => (
          <>
            <GridItem>
              <Field label="Наименование или адрес">
                <StyledLocationSelect
                  geolocationApi={false}
                  showArrow={true}
                  filter={{...filter, source: "toir"}}
                  value={values['id']}
                  onChange={async (text, options) => {
                    changeValue('id', text)
                  }}
                  placeholder="Выберите наименование или адрес"
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Подразделение">
                <OrgUnitSelect
                    value={values['nodeId']}
                    onChange={(value: number) => changeValue('nodeId', value)}
                    size="small"
                  />
              </Field>
            </GridItem>
            <Buttons fullWidth>
              <Button type="primary" onClick={applyFilter}>
                Подтвердить
              </Button>
              <Button type="plain" onClick={cleanFilter}>
                Очистить
              </Button>
            </Buttons>
          </>
        )}
      </Filter>
    </Content>
  </Spoiler>
);

export default LocationFilter;
