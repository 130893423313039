// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { MaintenanceWorkType } from './../../lib/types';
import { maintenanceWorkTypeApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps & {withoutArchive: boolean}) => (
  <AutocompleteSelect
    placeholder="Выберите вид работ"
    fetch={(params) =>
      maintenanceWorkTypeApi.fetchMaintenanceTypeWork({...params, isArchive: selectProps.withoutArchive ? false : undefined})}
    fetchSingle={maintenanceWorkTypeApi.getMaintenanceTypeWork}
    notFoundText="Виды работ не найдены"
    renderOption={(maintenanceWorkType: MaintenanceWorkType, Option) => (
      <Option key={maintenanceWorkType.id} value={maintenanceWorkType.id}>
        {maintenanceWorkType.name}
      </Option>
    )}
    {...selectProps}
  />
);
