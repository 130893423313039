// @flow

import React, {Component} from 'react';
import Table from 'antd/lib/table';
import styled from 'styled-components';
import {accessTypeEnum, orgUnitTypes} from '../../lib/enum';
import type {OrgUnitNode, UserAccess} from '../../lib/types';
import {Section} from './../../components/layout';
import {Icon, Operations} from './../../components/ui';
import Header from '../../components/layout/Header';
import {orgUnitApi} from '../../lib/api';
import {notification} from 'antd/lib';
import {Link} from '@reach/router';
import Popconfirm from 'antd/lib/popconfirm';
import {withUserAccess} from '../withUserAccess';
import {notificationLoading} from '../../components/Notifications';

type Props = {
  orgUnits: OrgUnitNode,
  deleteOrgUnit: Function,
  fetchOrgUnits: Function,
  userAccess: UserAccess[],
};

type State = {
  orgUnits: OrgUnitNode,
  loading: boolean,
  isAdmin: boolean,
}
const StyledIcon = styled(Icon)`
  color: #1890ff;
  margin: 0 5px;
  cursor: pointer;
`;

class OrgUnitTable extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      orgUnits: null,
      loading: false,
      isAdmin: this.props.userAccess.includes(accessTypeEnum.admin),
    }
  }

  async deleteOrgUnit(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await orgUnitApi.deleteOrgUnit(id);
      notification.success({message: 'Подразделение удалено'})
      await this.fetch();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Мнемокод',
      dataIndex: 'mnemonic',
      key: 'mnemonic',
    },
    {
      title: 'Тип',
      dataIndex: 'departmentType',
      key: 'departmentType',
    },
    {
      title: 'Вид',
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => orgUnitTypes[text],
    },
    {
      title: 'Rn',
      dataIndex: 'rn',
    },
    {
      title: ' ',
      dataIndex: 'id',
      className: 'operations',
      render: (text: string, record: OrgUnitNode): any => (
        <Operations>
          <Link data-cy="edit" to={`/admin/org-units/${record.id}`}>
            <StyledIcon type="edit" />
          </Link>
          {this.state.isAdmin && (
            <>
              <Link data-cy="add" to={`/admin/org-units/${record.id}/new`}>
                <StyledIcon type="plus" />
              </Link>
              {!(record.children && record.children.length) && (
                <Popconfirm
                  title="Вы действительно хотите удалить?"
                  okText="Да"
                  cancelText="Нет"
                  onConfirm={async () => await this.deleteOrgUnit(record.id)}
                >
                  <StyledIcon type="x" />
                </Popconfirm>
              )}
            </>
          )}
        </Operations>
      ),
    },
  ];

  async componentDidMount() {
    await this.fetch()
  }

  fetch = async () => {
    try {
      this.setState({loading: true});
      const orgUnits = await orgUnitApi.fetchOrgUnits();
      await this.setState({orgUnits});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message
      })
    } finally {
      this.setState({loading: false});
    }
  }

  render() {
    const {orgUnits, loading} = this.state;
    return (
      <>
        <Header left={<h1>Подразделения</h1>} />
        <Section>
          <Table
            loading={loading}
            defaultExpandAllRows={false}
            columns={this.columns}
            dataSource={orgUnits ? [orgUnits] : null}
            pagination={false}
            rowKey='id'
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(OrgUnitTable);
