// @flow
import React, { useRef } from 'react';
import Menu from 'antd/lib/menu';
import Button from 'antd/lib/button';

import { navigate } from '../../../lib/helpers';
import { Dropdown } from '../../../components/ui';
import type { Order } from '../../../lib/types';
import CopyOrderButton from './CopyOrderButton';
import RedirectOrderButton from './RedirectOrderButton';

type Props = {
  // Заявка
  order: Order,
  // Может ли пользователь перенаправить заявку
  canRedirectOrder: boolean,
  // Может ли пользователь подтвердить заявку
  canApprove?: boolean,
  // Может ли пользователь редактировать заявку
  canEdit: boolean,
  // Может ли пользователь копировать заявку
  canCopy: boolean,
  // Может ли пользователь отменять заявку
  canCancel: boolean,
  // Может ли пользователь восстановить заявку
  canRestore: boolean,
  // Функция отмены заявки
  onCancel: Function,
  // Функция удаления заявки
  onDelete?: Function,
  // Функция восстановления заявки
  onRestore: Function
};

/**
 * Компонент действий над заявкой в карточке заявки
 */
const OrderCardActionsDropdown = ({
  order,
  canEdit,
  canRedirectOrder,
  canCopy,
  canCancel,
  canRestore,
  onCancel,
  onRestore
}: Props) => {
  const dropdown = useRef();
  const showDivider = canEdit || canRestore;

  return (
    <Dropdown
      ref={dropdown}
      overlay={
        <Menu>
          {order.vehicleId === null && canRedirectOrder ? (
            <Menu.Item>
              <RedirectOrderButton order={order} />
            </Menu.Item>
          ) : null}
          {order.contractVehicleOrderId === null && (
            <Menu.Item
              onClick={() => {
                navigate(`/orders/${order.id}/contract-vehicle-order/new`);
              }}
            >
              Допнайм ТС
            </Menu.Item>
          )}
          {canCopy && (
            <Menu.Item>
              <CopyOrderButton orderId={order.id} />
            </Menu.Item>
          )}
          {canCancel && (
            <Menu.Item onClick={onCancel} data-test="orderCancel">
              Отменить заявку
            </Menu.Item>
          )}
          {showDivider && <Menu.Divider />}
          {canEdit && (
            <Menu.Item onClick={() => navigate(`/orders/edit/${order.id}`)}>
              Редактировать
            </Menu.Item>
          )}
          {canRestore && (
            <Menu.Item
              onClick={() => {
                onRestore();
                dropdown.current && dropdown.current.onVisibleChange(false);
              }}
            >
              Восстановить
            </Menu.Item>
          )}
        </Menu>
      }
    >
      <Button className="openActionsDropdown" type="primary" icon="ellipsis" />
    </Dropdown>
  );
};

export default OrderCardActionsDropdown;
