// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import React, {useCallback, useEffect, useState} from 'react';
import {Footer, Section} from '../../../../../components/layout';
import {notificationLoading} from '../../../../../components/Notifications';
import {ButtonsRow} from '../../../../../components/ui';
import {fetchRequest, vehiclePlanApi} from '../../../../../lib/api';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum} from '../../../../../lib/enum';
import type {
  BatteryCalculation,
  CalculationStatus,
  UserAccess,
  VehiclePlan,
  VehiclePlanStatus,
} from '../../../../../lib/types';
import type {PropsHeader} from '../../lib';
import {COGNOS_DELTA, COLUMNS_MONTH, commonHeaderPanel, itogCalculation} from '../../lib';
import Filter from './../../components/FilterVehicleList';
import PassTab from './PassTab';

import BudgetTab from './Tab';
import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget} from '../../accessRight';
import styled from 'styled-components';
import AntTable from '../../../../../components/ui/AntTable';
import {toLocalStringRu} from '../../../../../lib/helpers';
import {EditingModal} from './EditingModal';
import InfoBlock from '../../components/InfoBlock';

type PropsHeaderCustom = PropsHeader & {
  passTab?: boolean
};

export const headerPanel = (props: PropsHeaderCustom) => {
  const {title, vehiclePlanId, vehiclePlan} = props;
  return (
    <>
      {commonHeaderPanel({title, vehiclePlanId, vehiclePlan})}
      <BudgetTab
        vehiclePlanId={props.vehiclePlanId}
        vehiclePlan={vehiclePlan}
      />
      {!!props.passTab && (
        <PassTab
          vehiclePlanId={props.vehiclePlanId}
          vehiclePlan={vehiclePlan}
        />
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalBlock = styled.div`
  padding: 15px;
  background-color: ${({warning}) =>
          warning !== undefined ? (warning ? '#f58181' : '#88e288') : '#d0d0d0'};

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;

type ModalData = {
  monthData: BatteryCalculation | any,
  monthIndex: number,
  handleCancel: () => void,
  setVehiclePlan?: (vehiclePlan: VehiclePlan) => void
};

type FooterProps = {
  calculate: Function,
  changeStatus: Function,
  handleFetch: Function,
  vehiclePlanId: number,
  handlePrint?: (filter: any) => any,
  calculationDone: boolean,
  budgetStatus: VehiclePlanStatus,
  canEdit: boolean,
  statusField: string,
  isCognosStatus: boolean,
  filteredDataTotalSum: number,
  isAdmin: boolean,
};

const CommonFooter = ({
  calculate,
  changeStatus,
  handleFetch,
  vehiclePlanId,
  handlePrint,
  calculationDone,
  budgetStatus,
  canEdit,
  statusField,
  isCognosStatus,
  filteredDataTotalSum,
  isAdmin,
}: FooterProps) => {
  const [totalValue, setTotalValue] = useState(null);
  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusField]);
  const calculateAction = async () => {
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'saving',
      });
      await calculate(vehiclePlanId);
      handleFetch();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось выполнить расчет',
      });
    }
    finally {
      notification.close('saving');
    }
  };

  const changeStatusAction = async (status: CalculationStatus) => {
    try {
      if (status === calculationStatusEnum.calculationDone) {
        notificationLoading({
          message: 'Утверждение...',
          key: 'approving',
        });
      } else {
        notificationLoading({
          message: 'Редактирование...',
          key: 'approving',
        });
      }
      await changeStatus(vehiclePlanId, status);
      handleFetch();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    }
    finally {
      notification.close('approving');
    }
  };

  const getTotal = async () => {
    if (!isCognosStatus) {
      return;
    }
    let controllerName;
    switch (statusField) {
      case 'batteryCalculationStatus':
        controllerName = 'batteryCalculation';
        break;
      case 'gpmMaintenanceCalculationStatus':
        controllerName = 'gpmMaintenanceCalculation';
        break;
      case 'vehicleMonitoringCalculationStatus':
        controllerName = 'vehicleMonitoringCalculation';
        break;
      case 'measuringDeviceCertificationCalculationStatus':
        controllerName = 'measuringDeviceCertificationCalculation';
        break;
      case 'inspectionGtnCalculationStatus':
        controllerName = 'inspectionGtnCalculation';
        break;
      case 'inspectionGibddCalculationStatus':
        controllerName = 'inspectionGibddCalculation';
        break;
      case 'driverInspectionCalculationStatus':
        controllerName = 'driverInspectionCalculation';
        break;
      default:
        break;
    }
    if (controllerName) {
      try {
        const {value} = await fetchRequest.get(
          `/${controllerName}/getcognosvalue/${vehiclePlanId}`,
        );
        setTotalValue(value / 100);
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message,
        });
      }
    }
  };

  const approveButtonIsDisabled = () => isCognosStatus && Math.abs(filteredDataTotalSum - totalValue) > COGNOS_DELTA;

  return !calculationDone || !!handlePrint || !!canEdit ? (
    <Footer style={{alignItems: 'flex-start'}}>
      {isCognosStatus && totalValue !== null && (
        <Container>
          <TotalBlock>
            Итого:{' '}
            {toLocalStringRu(filteredDataTotalSum, {
              minimumFractionDigits: 2,
            })}
          </TotalBlock>
          <TotalBlock>
            Итого (Контрольные значения БК):{' '}
            {toLocalStringRu(totalValue, {minimumFractionDigits: 2})}
          </TotalBlock>
          <TotalBlock
            warning={Math.abs(filteredDataTotalSum - totalValue) > COGNOS_DELTA}
          >
            Дельта:{' '}
            {toLocalStringRu(filteredDataTotalSum - totalValue, {
              minimumFractionDigits: 2,
            })}
          </TotalBlock>
        </Container>
      )}
      <ButtonsRow>
        {!!canEdit && (
          <>
            {!calculationDone && (
              <>
                {calculate && (
                  <Button type="primary" onClick={calculateAction}>
                    Рассчитать
                  </Button>
                )}
                <Button
                  type="primary"
                  onClick={() =>
                    changeStatusAction(calculationStatusEnum.calculationDone)
                  }
                  disabled={approveButtonIsDisabled()}
                  title={approveButtonIsDisabled()
                    ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                    : undefined
                  }
                >
                  Утвердить
                </Button>
              </>
            )}
            {calculationDone &&
              budgetStatus !== vehiclePlanStatusEnum.approved &&
              budgetStatus !== vehiclePlanStatusEnum.approvedAfterCognos && (
                <>
                  <Button
                    type="primary"
                    onClick={() =>
                      changeStatusAction(calculationStatusEnum.draft)
                    }
                  >
                    Редактировать
                  </Button>
                </>
              )}
          </>
        )}
        {handlePrint && <Button onClick={handlePrint}>Печать</Button>}
      </ButtonsRow>
    </Footer>
  ) : null;
};

type Props = PropsHeaderCustom & {
  location: Location & { state: { page: number } },
  crud: any,
  columns?: any,
  tableProps?: any,
  scrollX?: number,
  calcScrollY?: number,
  statusField: string,
  handlePrint?: (filter: any) => any,
  itogCalculation?: (data: any) => any,
  userAccess: UserAccess[],
  linksToDictionaries: ?any,
  editingModalData?: ModalData
};
export default withUserAccess((props: Props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState({});
  const [vehiclePlan, setVehiclePlan] = useState(undefined);

  const handleFetch = useCallback(async () => {
    setLoading(true);
    try {
      let {data} = await props.crud.fetch({
        vehiclePlanId: props.vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      const vehiclePlan = await vehiclePlanApi.get(props.vehiclePlanId);
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      setData(data);
      setVehiclePlan(vehiclePlan);
      // Передаем vehiclePlan в родительский компонент
      if (props.editingModalData?.setVehiclePlan) {
        props.editingModalData.setVehiclePlan(vehiclePlan);
      }
    }
    finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.crud, props.vehiclePlanId]);

  const applyFilter = filter => setFilter(filter);

  const cleanFilter = () => setFilter({});

  useEffect(() => {
    handleFetch();
  }, [handleFetch]);

  useEffect(() => {
    const filtered = data
    .filter(selfVehicle =>
      !!filter.vehicleId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle.vehicleId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.vehicleModelId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleModelId ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModelId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.type && selfVehicle.selfVehiclePlanVehicle
        ? filter.type ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModel.type
        : true,
    )
    .filter(selfVehicle =>
      !!filter.yearIssued && selfVehicle.selfVehiclePlanVehicle
        ? filter.yearIssued ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.yearIssued
        : true,
    )
    .filter(selfVehicle => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });
    if (filtered.length !== 0) {
      setFilteredData([
        ...filtered,
        !!props.itogCalculation
          ? props.itogCalculation(filtered)
          : itogCalculation(filtered),
      ]);
    } else {
      setFilteredData([]);
    }
  }, [data, filter, props]);

  const handleSave = async monthData => {
    try {
      if (monthData && props.crud.updateMonth) {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'updating',
        });
        await props.crud.updateMonth(monthData);
        await handleFetch();
        props.editingModalData.handleCancel();
        notification.success({message: 'Данные обновлены'});
      }
    } catch (error) {
      notification.error({
        message: 'Не удалось обновить данные',
        description: error.message,
      });
    }
    finally {
      notification.close('updating');
    }
  };

  const canEdit = () =>
    props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  const isAdmin = props.userAccess.includes(accessTypeEnum.admin);

  return (
    <>
      {headerPanel({...props, vehiclePlan})}
      <Section>
        <InfoBlock links={props.linksToDictionaries} additionalText={props.additionalText || ''} />
        <div style={{padding: '16px 16px 0px'}}>
          <Filter
            vehicleType={true}
            filter={filter}
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
            vehiclePlanId={props.vehiclePlanId}
          />
        </div>
        <AntTable
          data={filteredData}
          columns={props.columns ? props.columns : COLUMNS_MONTH}
          loading={loading}
          pagination={{pageSize: 50}}
          scroll={{
            x: props.scrollX ? `${props.scrollX}px` : '2100px',
            y: `calc(100vh - ${
              props.calcScrollY ? props.calcScrollY : '300'
            }px)`,
          }}
          rowClassName={record => record.selfVehiclePlanVehicle?.changed &&
              vehiclePlan?.[props.statusField] === calculationStatusEnum.draft
            ? 'table-row-error'
            : null
          }
          {...props.tableProps}
        />
        {vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos &&
          props.editingModalData?.monthData && canEdit() && (
            <EditingModal
              visible={!!props.editingModalData.monthData}
              handleCancel={props.editingModalData.handleCancel}
              handleSave={handleSave}
              data={props.editingModalData.monthData}
              monthIndex={props.editingModalData.monthIndex}
              type={props.location.href.split('/').reverse()[0]}
            />
          )}
      </Section>
      {vehiclePlan && (
        <CommonFooter
          calculationDone={vehiclePlan[props.statusField] === calculationStatusEnum.calculationDone}
          calculate={
            vehiclePlan.status !== vehiclePlanStatusEnum.approvementAfterCognos &&
            vehiclePlan.status !== vehiclePlanStatusEnum.approvedAfterCognos
              ? props.crud.calculate
              : undefined
          }
          changeStatus={props.crud.changeStatus}
          vehiclePlanId={props.vehiclePlanId}
          handlePrint={
            filteredData.length > 0 && props.handlePrint
              ? // $FlowFixMe не видит, что проверка на существование функции сделана
              () => props.handlePrint(filter)
              : undefined
          }
          handleFetch={handleFetch}
          budgetStatus={vehiclePlan.status}
          canEdit={canEditBudget(props.userAccess)}
          statusField={props.statusField}
          isCognosStatus={
            vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
            vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos
          }
          filteredDataTotalSum={
            filteredData.length ? filteredData[filteredData.length - 1]?.sum : 0
          }
          isAdmin={isAdmin}
        />
      )}
    </>
  );
});
