// @flow
import React from 'react';
import {SelectProps} from 'antd/lib/select';
import Select from './../ui/Select';
import {contractVehicleTypeEnum, vehicleStatusEnum, vehicleStatuses} from '../../lib/enum';
import VehicleStatus from './../VehicleStatus';

const {Option} = Select;

export default (selectProps: SelectProps) => {
  const {contractType} = selectProps;
  let keys;
  switch (contractType) {
    case contractVehicleTypeEnum.fixedPlan:
    case contractVehicleTypeEnum.oneOffPlan:
    case contractVehicleTypeEnum.indefined:
      keys = [vehicleStatusEnum.draft];
      break;
    case contractVehicleTypeEnum.fixed:
    case contractVehicleTypeEnum.oneOff:
    case contractVehicleTypeEnum.substitution:
    case contractVehicleTypeEnum.byOrder:
      keys = Object.keys(vehicleStatuses).filter(
        status => status !== vehicleStatusEnum.limitExceed,
      );
      break;
    default:
      keys = Object.keys(vehicleStatuses);
  }
  return (
    <Select allowClear placeholder="Выберите статус ТС" {...selectProps}>
      {keys.map(key => {
        const {exclude} = selectProps;
        if (exclude?.length && exclude.includes(key)) {
          return null;
        }
        return (
          <Option key={key} value={key}>
            <VehicleStatus status={key} />
          </Option>
        )
      })}
    </Select>
  );
};
