// @flow
import React, {Component} from 'react';
import {Table} from './../../components/ui';
import {Section} from './../../components/layout';
import Header from '../../components/layout/Header';
import moment from 'moment';
import {inventoryCardCompareApi} from '../../lib/api';
import qs from 'query-string';
import {InventoryCardCompareResult, InventoryCardCompareTypes} from '../../lib/types/inventoryCardCompareResult';
import Button from 'antd/lib/button';
import {Filter} from './components/Filter';

type State = {
  data: InventoryCardCompareResult[],
  totalCount: number,
  pageSize: number,
  page: number,
  filter: ?any,
};

const columns = [
  {
    title: '№',
    dataIndex: 'id',
    width: 50,
  },
  {
    title: 'Дата сверки',
    dataIndex: 'created',
    width: 100,
    render: date => moment(date).format('DD.MM.YYYY'),
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    render: type => InventoryCardCompareTypes[type],
  },
  {
    title: 'Количество критичных расхождений',
    dataIndex: 'noCompareCriticalFieldsCount',
    render: value => value || 0,
  },
  {
    title: 'Количество расхождений',
    dataIndex: 'noCompareFieldsCount',
    render: value => value || 0,
  },
  {
    title: '',
    key: 'file',
    dataIndex: 'file',
    stopPropagation: true,
    width: 50,
    render: file => {
      return file && file.url
        ? (
          <Button
            type="primary"
            icon="download"
            size="small"
            href={`${file.url}?isInline=false`}
            target="_blank"
          />
        )
        : null;
    },
  },
];

export class List extends Component<null, State> {
  state = {
    data: [],
    totalCount: 0,
    pageSize: 30,
    page: 1,
    filter: {},
  };

  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({page: page || 1, filter}, () => this.fetch(page || 1));
  }

  fetch = async (page: number) => {
    const {totalCount, pageSize, filter} = this.state;
    const result = await inventoryCardCompareApi.fetch({
      orderBy: 'id',
      byDescending: true,
      page,
      totalCount,
      pageSize, ...filter,
    });
    this.setState({
      data: result.data,
      totalCount: result.totalCount,
      page,
    });
  };

  render() {
    const {data, totalCount, pageSize, page, filter} = this.state;
    return (
      <>
        <Header left={<h1>Сверка ИК</h1>} />
        <Section>
          <Filter
            filter={filter}
            applyFilter={values => this.setState({filter: values}, () => this.fetch(page))}
            cleanFilter={() => this.setState({filter: {}}, () => this.fetch(page))}
          />
          <Table
            fetch={this.fetch}
            columns={columns}
            data={data}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
          />
        </Section>
      </>
    );
  }
}

export default List;
