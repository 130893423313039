// @flow
import React from 'react';
import styled from 'styled-components';
import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';
import notification from 'antd/lib/notification';
import {downloadRequestWithToken, maintenanceDocumentApi} from '../../../../lib/api';
import type {DetailedDefectiveStatement, Maintenance, MaintenanceDocument} from '../../../../lib/types';
import {
  getActualDetailedDefectiveStatement,
  updateDetailedDefectiveStatement,
} from '../../../../lib/api/detailedDefectiveStatement';
import {SectionContent} from '../../../../components/hoc/common/components/elements';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import moment from 'moment';
import {Spin} from 'antd';
import {vehicleTypes} from '../../../../lib/enum';
import {fetchEmployee} from '../../../../lib/api/employee';
import {isNil} from 'lodash';
import Button from 'antd/lib/button';
import {EditingModal} from './components/EditingModal';
import {notificationLoading} from '../../../../components/Notifications';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const BlockTitle = styled.h2`
  margin: 0 0 10px;
`;

const entryPointPath = '/admin/act/internal/defective-statement';

type Props = {
  id: number,
};

type State = {
  maintenance: Maintenance,
  defectiveStatement: DetailedDefectiveStatement,
  employees: any,
  maintenanceDocument: MaintenanceDocument,
  modalVisible: boolean,
  initialValues: any,
};

export class DefectiveResourceStatementCard extends React.Component<Props, State> {
  state = {
    maintenance: null,
    defectiveStatement: null,
    employees: {},
    maintenanceDocument: null,
    modalVisible: false,
    initialValues: {},
  };
  
  async componentDidMount() {
    await this.fetch();
  }
  
  fetchEmployees = async () => {
    const {maintenance} = this.state;
    const {firstEmployee, secondEmployee, thirdEmployee} = maintenance;
    const employees = {};
    if (firstEmployee) {
      employees.firstCommissioner = await fetchEmployee(firstEmployee.personId);
    }
    if (secondEmployee) {
      employees.secondCommissioner = await fetchEmployee(secondEmployee.personId);
    }
    if (thirdEmployee) {
      employees.thirdCommissioner = await fetchEmployee(thirdEmployee.personId);
    }
    this.setState({employees});
  };
  
  fetch = async () => {
    try {
      const maintenanceDocument = await maintenanceDocumentApi.getMaintenanceDocument(this.props.id);
      const {maintenanceId, maintenance} = maintenanceDocument;
      const defectiveStatement = await getActualDetailedDefectiveStatement(maintenanceId);
      this.getInitialValues(maintenanceDocument, defectiveStatement);
      this.setState({maintenance, defectiveStatement, maintenanceDocument}, this.fetchEmployees);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  getFullName = (commissioner) => {
    if (commissioner) {
      return <p style={{margin: 0}}>{`${commissioner.lastname} ${commissioner.firstname} ${commissioner.middlename}`}</p>;
    }
    return '';
  };
  
  getCommissionerNames = () => {
    const {maintenance} = this.state;
    const {firstEmployee, secondEmployee, thirdEmployee} = maintenance;
    if (!firstEmployee && !secondEmployee && !thirdEmployee) {
      return '-';
    }
    return (
      <>
        {this.getFullName(firstEmployee)}
        {this.getFullName(secondEmployee)}
        {this.getFullName(thirdEmployee)}
      </>
    );
  };
  
  getCommissionerPositions = () => {
    const {employees} = this.state;
    const keys = Object.keys(employees);
    if (!keys.length) {
      return '-';
    }
    return (
      <>
        {keys.map(key => <p key={employees[key].id} style={{margin: 0}}>{employees[key].positionTitle || '-'}</p>)}
      </>
    );
  };
  
  handleSubmit = async (values) => {
    try {
      notificationLoading({message: 'Сохранение данных', key: 'saving'});
      const {maintenanceDocument, defectiveStatement} = this.state;
      const {documentBase, equipmentType} = values;
      const newMaintenanceDocument = {...maintenanceDocument, documentBase, equipmentType};
      values.operations.forEach(operation => {
        const currentOperation = defectiveStatement.operations.find(item => item.id === operation.id);
        currentOperation.isEquivalent = operation.isEquivalent;
        currentOperation.isSpecialCondition = operation.isSpecialCondition;
        currentOperation.conditionType = operation.conditionType;
        currentOperation.conditionName = operation.conditionName;
      })
      await maintenanceDocumentApi.updateMaintenanceDocument(newMaintenanceDocument);
      await updateDetailedDefectiveStatement(defectiveStatement);
      this.setState({modalVisible: false});
      notification.success({message: 'Данные сохранены'});
      this.fetch();
    } catch (e) {
      notification.error({message: 'Ошибка', description: e.title || e.message});
    } finally {
      notification.close('saving');
    }
  }
  
  getInitialValues = (maintenanceDocument, defectiveStatement) => {
    const initialValues = {
      documentBase: maintenanceDocument.documentBase,
      equipmentType: maintenanceDocument.equipmentType,
      operations: defectiveStatement.operations.map(operation => ({
        id: operation.id,
        name: operation.name,
        isEquivalent: operation.isEquivalent,
        isSpecialCondition: operation.isSpecialCondition,
        conditionType: operation.conditionType,
        conditionName: operation.conditionName,
      }))
    };
    this.setState({initialValues});
  }
  
  handlePrint = async () => {
    try {
      notificationLoading({message: 'Формирование печатной формы', key: 'printing'});
      await downloadRequestWithToken(`/MaintenanceDocument/${this.state.maintenanceDocument.id}/print`);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    } finally {
      notification.close('printing');
    }
  }
  
  render() {
    const {id} = this.props;
    const {maintenance, defectiveStatement, maintenanceDocument, modalVisible, initialValues} = this.state;
    const breadCrumbs = [
      {to: entryPointPath, name: 'Реестр первичных документов'},
      {
        to: `${entryPointPath}/${id}`,
        name: `Дефектно-ресурсная ведомость № ${id}`,
      },
    ];
    
    if (!maintenance || !defectiveStatement) {
      return (
        <Preloader>
          <Spin size={'large'} />
        </Preloader>
      );
    }
    
    return (
      <>
        <CommonCardPage
          pageHeaderProps={{
            breadCrumbs,
            mainHeader: `Дефектно-ресурсная ведомость №${id}`,
            rightHeader: (
              <>
                <Button
                  type='primary'
                  onClick={() => this.setState({modalVisible: true})}
                  style={{marginRight: '10px'}}
                >
                  Редактировать
                </Button>
                <Button type='primary' onClick={this.handlePrint}>Печать</Button>
              </>
            ),
          }}
        >
          <SectionContent>
            <BlockTitle>Основная информация</BlockTitle>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование карточки работ">{`Карточка работ №${maintenance.id}`}</Field>
              </GridItem>
              <GridItem>
                <Field label="Вид работ">{maintenance.maintenanceWorkType.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Дата документа">{moment(maintenance.created).format('DD.MM.YYYY')}</Field>
              </GridItem>
              <GridItem>
                <Field label="Основание">{maintenanceDocument.documentBase ? maintenanceDocument.documentBase : '-'}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
          <SectionContent>
            <BlockTitle>Транспортное средство</BlockTitle>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Марка">{maintenance.vehicle.vehicleModel.brandName}</Field>
              </GridItem>
              <GridItem>
                <Field label="Модель">{maintenance.vehicle.vehicleModel.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Государственный регистрационный знак">{maintenance.vehicle.licensePlate}</Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">{vehicleTypes[maintenance.vehicle.vehicleModel.type]}</Field>
              </GridItem>
              <GridItem>
                <Field label="Инвентарный номер">{maintenance.vehicle.inventoryNumber || '-'}</Field>
              </GridItem>
              <GridItem>
                <Field label="Филиал">{maintenance.vehicle.parentOrgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="Подразделение">{maintenance.vehicle.orgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="Подрядная организация">{maintenance.executor.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Вид оборудования">{maintenanceDocument.equipmentType ? maintenanceDocument.equipmentType : '-'}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
          <SectionContent>
            <BlockTitle>Ответственные лица</BlockTitle>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Члены комиссии - ФИО (Дефектный акт)">{this.getCommissionerNames()}</Field>
              </GridItem>
              <GridItem>
                <Field label="Члены комиссии - Должность (Дефектный акт)">{this.getCommissionerPositions()}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
          <SectionContent>
            <BlockTitle>Описание работ по устранению дефектов</BlockTitle>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Группа дефектов (Объект ТОиР)">{maintenance?.vehicleIssues?.vehicleIssueGroup?.name || '-'}</Field>
              </GridItem>
              <GridItem>
                <Field label="Характеристика дефекта">{maintenance?.vehicleIssues?.description || '-'}</Field>
              </GridItem>
              {defectiveStatement.operations.map(item => (
                <GridItem fullWidth key={item.id}>
                  <Grid gutter="16px" cols={6}>
                    <GridItem>
                      <Field label="Наименование работы">{item.name}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Количество">{item.count}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Возможность применения эквивалента">
                        {isNil(item.isEquivalent)
                          ? '-'
                          : item.isEquivalent
                            ? 'Да'
                            : 'Нет'
                        }
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Требуется особое условие">
                        {isNil(item.isSpecialCondition)
                          ? '-'
                          : item.isSpecialCondition
                            ? 'Да'
                            : 'Нет'
                        }
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Вид особых условий">{item.conditionType || '-'}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Наименование">{item.conditionName || '-'}</Field>
                    </GridItem>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </SectionContent>
          <SectionContent>
            <BlockTitle>Расходники и запчасти</BlockTitle>
            <Grid gutter="16px">
              {defectiveStatement.parts.map(item => (
                <GridItem fullWidth key={item.id}>
                  <Grid gutter="16px" cols={4}>
                    <GridItem>
                      <Field label="Код МТР">{item.stockPart.mtr.mnemocode || '-'}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="МТР">{item.stockPart.mtr.name || '-'}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Единица измерения">{item.stockPart.mtr.measure}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Количество">{item.count}</Field>
                    </GridItem>
                  </Grid>
                </GridItem>
              ))}
            </Grid>
          </SectionContent>
        </CommonCardPage>
        <EditingModal
          onCancel={() => this.setState({modalVisible: false})}
          onSubmit={this.handleSubmit}
          visible={modalVisible}
          initialValues={initialValues}
        />
      </>
    );
  };
}
