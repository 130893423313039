// @flow
import React, {useState} from 'react';

import {navigate} from '../../lib/helpers';
import {calendarOfBKApi, deadlinesForSendingDataToCognosApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import Tabs from './components/Tabs';
import {accessTypeEnum} from '../../lib/enum';
import {withUserAccess} from '../withUserAccess';
import {Icon} from '../../components/ui';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';
import {notificationLoading} from '../../components/Notifications';
import moment from 'moment';
import {Router} from '@reach/router';

const entryPointPath = '/admin/working-with-budgeting/';

export const typesOfCurrentDict = {
  deadlines: 'deadlines',
  calendar: 'calendar',
};

const List = ({type, userAccess}) => {
  const [reFetch, setReFetch] = useState(false);
  
  const deadlinesColumns = [
    {
      title: 'Дата начала',
      dataIndex: 'startMonthDay',
    },
    {
      title: 'Дата окончания',
      dataIndex: 'endMonthDay',
    },
  ];
  const calendarColumns = [
    {
      title: 'Дата и месяц начала',
      dataIndex: 'startDay',
      render: (value, record) => moment(`${record.startMonth} ${value}`).format('D MMMM')
    },
    {
      title: 'Дата и месяц окончания',
      dataIndex: 'finishDay',
      render: (value, record) => moment(`${record.finishMonth} ${value}`).format('D MMMM')
    },
    {
      dataIndex: 'id',
      stopPropagation: true,
      width: 20,
      render: id => {
        return canEditCalendar() && (
          <Popconfirm
            title="Вы действительно хотите удалить запись?"
            onConfirm={async () => deleteRecord(id)}
            okText="Да"
            cancelText="Нет"
          >
            <Icon type="x" />
          </Popconfirm>
        );
      },
    },
  ];
  
  const canSeeCalendar = () => [
    accessTypeEnum.admin,
    accessTypeEnum.viewingCalendarBK,
    accessTypeEnum.handlingCalendarBK,
  ].some(access => userAccess.includes(access));
  
  const canEditCalendar = () => [
    accessTypeEnum.admin,
    accessTypeEnum.handlingCalendarBK,
  ].some(access => userAccess.includes(access));
  
  const deleteRecord = async (id) => {
    try {
      notificationLoading({
        message: 'Удаление записи',
        key: 'deleting',
      });
      await calendarOfBKApi.delete(id);
      setReFetch(true);
      notification.success({message: 'Запись удалена'});
    } catch (e) {
      notification.error({
        message: 'Ошибка удаления записи',
        description: e.title || e.message,
      });
    }
    finally {
      notification.close('deleting');
      setReFetch(false);
    }
  };
  
  return (
    <Page>
      <CommonListPage
        crud={type === typesOfCurrentDict.deadlines
          ? deadlinesForSendingDataToCognosApi
          : calendarOfBKApi
        }
        pageHeaderProps={{
          mainHeader: 'Работа с ИСУ «Бюджетирование»',
          access: {
            add: type === typesOfCurrentDict.calendar && canEditCalendar()
              ? () => navigate(`${entryPointPath}${type}/new`)
              : undefined,
          },
        }}
        tableListProps={{
          columns: type === typesOfCurrentDict.deadlines
            ? deadlinesColumns
            : calendarColumns,
          onSelect: (id: number) => {
            if (type === typesOfCurrentDict.calendar && !canEditCalendar()) {
              return;
            }
            navigate(`${entryPointPath}${type}/${id}`);
          },
          pageSize: 20,
          reFetch: reFetch,
        }}
        subHeader={canSeeCalendar() && <Tabs />}
      />
    </Page>
  );
};

const Routes = (props) => (
  <Router>
    <List {...props} path='/' type={typesOfCurrentDict.deadlines} />
    <List {...props} path='/calendar' type={typesOfCurrentDict.calendar} />
  </Router>
)

export default React.memo(withUserAccess(Routes));
