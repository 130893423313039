// @flow

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';
import {Link} from '@reach/router';

import {navigate} from '../../lib/helpers';
import type {UserAccess, VehicleModel, VehicleType} from '../../lib/types';
import {fetchVehicleModels} from '../../ducks/vehicleModels';
import {deleteVehicleModel} from '../../ducks/vehicleModel';
import type {AppState} from '../../ducks/redux';
import {Icon, Operations, Table} from './../../components/ui';
import {Section} from './../../components/layout';
import Filter from './Filter';
import Header from '../../components/layout/Header';
import {accessTypeEnum, vehicleTypes} from '../../lib/enum';
import {notificationLoading} from '../../components/Notifications';
import {VehicleOwnerTypesEnum} from '../../lib/types/vehicleModel';
import {HeaderTabs} from './components/HeaderTabs';

type Props = {
  models: Array<VehicleModel>,
  fetchVehicleModels: Function,
  deleteVehicleModel: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  type: $Keys<VehicleOwnerTypesEnum>,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class VehicleModelList extends PureComponent<Props> {
  static defaultProps = {
    location: {},
  };

  async componentDidMount() {
    await this.fetchVehicleModels(this.props.page || 1);
    if (!this.isAdmin() && this.props.userAccess.includes(accessTypeEnum.viewingContractVehicleModel) &&
      !this.props.userAccess.includes(accessTypeEnum.viewingVehicleModel)) {
      await navigate(VehicleOwnerTypesEnum.contract);
    }
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.type !== this.props.type) {
      if (!this.isAdmin() && this.props.userAccess.includes(accessTypeEnum.viewingContractVehicleModel) &&
        !this.props.userAccess.includes(accessTypeEnum.viewingVehicleModel)) {
        await navigate(VehicleOwnerTypesEnum.contract);
      }
    }
  }

  fetchVehicleModels = async (page: number, params) => await this.props.fetchVehicleModels(page, {
    byDescending: true,
    vehicleOwnerType: this.props.type,
    ...params,
    orderBy: params?.orderBy || 'id',
  });

  async deleteVehicleModel(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await this.props.deleteVehicleModel(id);
      await this.fetchVehicleModels(this.props.page);
      notification.success({
        message: 'Успешное удаление',
        description: `Модель с идентификатором ${id} была успешно удалена`,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50,
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      sorter: true,
      sorterKey: 'brand.name',
      width: 300,
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      sorter: true,
      render: (type: VehicleType) => vehicleTypes[type],
      width: 300,
    },
    {
      title: 'Количество ТС',
      dataIndex: 'vehicleCount',
      sorter: false,
      width: 85,
    },
    {
      title: '',
      width: 30,
      dataIndex: 'operations',
      render: (text: string, model: VehicleModel) => {
        const {type} = this.props;
        return (type === VehicleOwnerTypesEnum.self && this.canEdit()) ||
        (type === VehicleOwnerTypesEnum.contract && this.canEditContractVehicles())
          ? (
            <Operations>
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={() => this.deleteVehicleModel(model.id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
          : null;
      },
    },
  ];

  handleRowClick = (id: number) => navigate(`/vehicle-models/${this.props.type}/${id}`);

  canEdit = () => this.props.userAccess.some(access =>
    [accessTypeEnum.admin, accessTypeEnum.handlingVehicleModel].includes(
      access,
    ),
  );

  isAdmin = () => this.props.userAccess.includes(accessTypeEnum.admin);

  canEditContractVehicles = () => this.props.userAccess.some(access =>
    [accessTypeEnum.admin, accessTypeEnum.handlingContractVehicleModel].includes(
      access,
    ),
  );

  render() {
    const {
      models,
      totalCount,
      pageSize,
      page,
      location,
      userAccess,
      type,
    } = this.props;

    return (
      <>
        <Header
          left={<h1>Модели ТС</h1>}
          right={((type === VehicleOwnerTypesEnum.self && this.canEdit()) ||
            (type === VehicleOwnerTypesEnum.contract && this.canEditContractVehicles())) && (
            <Link to={`/vehicle-models/${type}/new`}>
              <Button type="primary" data-cy="addModel">
                Создать
              </Button>
            </Link>
          )}
        />
        <HeaderTabs
          selfTabAccess={this.isAdmin() || userAccess.includes(accessTypeEnum.viewingVehicleModel)}
          contractTabAccess={this.isAdmin() || userAccess.includes(accessTypeEnum.viewingContractVehicleModel)}
        />
        <Section>
          <Filter type={type} />
          <Table
            fetch={this.fetchVehicleModels}
            fetchOnMount={false}
            onRow={record => ({onClick: () => this.handleRowClick(record.id)})}
            columns={this.columns}
            data={models.map(model => ({
              ...model,
              key: model.id,
            }))}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    userAccess: state.auth.profile.access,
    models: state.vehicleModels.data,
    totalCount: state.vehicleModels.totalCount,
    pageSize: state.vehicleModels.pageSize,
    page: state.vehicleModels.page,
  }),
  {
    fetchVehicleModels,
    deleteVehicleModel,
  },
)(VehicleModelList);
