// @flow
import React from 'react';
import findKey from 'lodash/findKey';
import { type TreeSelectProps } from 'antd/lib/tree-select';
import TreeSelect from './../ui/TreeSelect';

import type {
  VehicleGroup,
  VehicleGroupParent,
  VehicleType
} from '../../lib/types';
import {
  vehicleTypesPlainTree,
  vehicleTypesTree
} from '../../lib/vehicleTypesTree';
import {
  vehicleGroupEnum,
  vehicleGroups,
  vehicleTypeEnum
} from '../../lib/enum';
import Select from 'antd/lib/select';

type Props = TreeSelectProps & {
  /**
   * Исключаемые виды ТС из дерева
   */
  excludeGroups?: VehicleGroup[],
  /**
   * Список типов ТС, которые нужно вывести
   *
   * Оставляет только те виды ТС, в которых есть указанные типы
   * Например, когда нужно вывести типы ГПМ
   */
  onlyTypes?: VehicleType[],
  /**
   * Если true, то можно выбирать виды ТС
   *
   * Тогда в качестве значения подставляется
   * массив типов ТС из этого вида
   */
  selectableGroup?: boolean,
  /**
   * Если true, то на выходе отдаем объект типа VehicleGroupType
   */
  resultAsObject?: boolean,
  /**
   * Выводить только группы ТС
   */
  onlyGroups?: boolean,
  /**
   * Список видов ТС, которые нельзя выбрать напрямую - только их типы ТС
   */
  nonSelectableGroups?: VehicleGroup[]
};

type State = {
  value: VehicleGroup | VehicleType
};

export type VehicleGroupAndType = {
  group: ?VehicleGroup,
  type: ?VehicleType
};

/**
 * Компонент выбора типов ТС, разбитых по видам ТС
 */
export default class VehicleTypeSelect extends React.Component<Props, State> {
  state = {
    value: this.props.value
  };

  static defaultProps = {
    selectableGroup: false,
    resultAsObject: false
  };

  getTree = (): VehicleGroupParent[] => {
    const {
      onlyTypes,
      excludeGroups,
      selectableGroup,
      nonSelectableGroups
    } = this.props;
    const preparedTree = vehicleTypesTree
      .filter(node => {
        if (onlyTypes) {
          // Оставляем только те виды ТС, для которых указаны типы из onlyTypes
          return node.children.some(child => onlyTypes.includes(child.value));
        }
        if (excludeGroups) {
          return !excludeGroups.includes(node.value);
        }
        return true;
      })
      .map(node =>
        // Оставляем только указанные типы ТС
        onlyTypes
          ? {
              ...node,
              children: node.children.filter(child =>
                onlyTypes.includes(child.value)
              )
            }
          : node
      );
    // Делаем виды ТС выбираемыми или невыбираемыми
    return preparedTree.map((node: VehicleGroupParent) => ({
      ...node,
      selectable: nonSelectableGroups
        ? !nonSelectableGroups.includes(node.value)
        : selectableGroup
    }));
  };

  onChangeNode = (value: VehicleGroup | VehicleType) => {
    const { onChange } = this.props;
    this.setState({ value });
    if (this.props.resultAsObject) {
      // В данном случае заполняется либо группа, либо тип
      onChange(
        ({
          group: vehicleGroupEnum[value],
          type: vehicleTypeEnum[value]
        }: VehicleGroupAndType)
      );
    } else if (vehicleGroupEnum[value]) {
      // Если выбрали родительский узел
      onChange(vehicleTypesPlainTree[value]);
    } else {
      onChange(value);
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { value: propsValue } = this.props;
    if (propsValue !== prevProps.value) {
      if (Array.isArray(propsValue)) {
        const value = findKey(vehicleTypesPlainTree, value =>
          value.some(type => propsValue.includes(type))
        );
        this.setState({ value });
      } else {
        this.setState({ value: propsValue });
      }
    }
  }

  render() {
    const { value } = this.state;
    const { onlyGroups, onChange, multiple } = this.props;
    const tree = this.getTree();
    return onlyGroups ? (
      <Select
        allowClear
        value={value}
        onChange={value => onChange && onChange(vehicleGroupEnum[value])}
        {...this.props}
      >
        {Object.keys(vehicleGroupEnum).map(vehicleGroup => (
          <Select.Option key={vehicleGroup} value={vehicleGroup}>
            {vehicleGroups[vehicleGroup]}
          </Select.Option>
        ))}
      </Select>
    ) : (
      <TreeSelect
        placeholder="Выберите тип ТС"
        showSearch
        treeNodeFilterProp="title"
        allowClear
        treeData={tree}
        {...this.props}
        value={multiple ? this.props.value : value}
        onChange={this.onChangeNode}
      />
    );
  }
}
