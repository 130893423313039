// @flow
import styled, { css } from 'styled-components';

type Props = {
  maxWidth?: string
};
export const FilterItem = styled.div.attrs({
  className: 'filter__item'
})`
  ${({ maxWidth }: Props) => {
    return css`
      max-width: ${maxWidth ?? `200px`};
      margin-right: 9px;
      width: calc(100% / 4 - 40px);
    `;
  }}
`;

export default FilterItem;
