// @flow
import type { Dispatch } from 'redux';

import type { Credentials, UserAccess } from './../lib/types';
import type { Action } from './redux';
import { authApi, userApi, tokenWrapper } from './../lib/api';
import type { Employee } from '../lib/types';

export type Profile = {
  access: UserAccess[],
  employee: Employee,
  employeeId: number,
  employeeOrgUnitId: number,
  roleName: string,
  employeeBranchOrgUnitId: number
};

export type AuthState = {
  profile: $Shape<Profile>
};

export const FETCH_PROFILE = 'vehicles/auth/fetch-profile';
const initialState: AuthState = {
  profile: {
    access: []
  }
};
const reducer = (
  state: AuthState = initialState,
  { type, payload }: Action
): AuthState => {
  switch (type) {
    case FETCH_PROFILE:
      return {
        ...state,
        profile: payload
      };
    default:
      return state;
  }
};

export const login: Function = (
  credentials: Credentials
): Function => async (): Promise<void> => {
  const result: Object = await authApi.login(credentials);
  if (result) {
    const { accessToken } = result;
    tokenWrapper.setToken(accessToken);
  } else {
    throw new Error('login failed');
  }
};

export const fetchProfile = () => async (
  dispatch: Dispatch
): Promise<Profile> => {
  const profile = await userApi.fetchProfile();
  dispatch({
    type: FETCH_PROFILE,
    payload: profile
  });
  return profile;
};

export default reducer;
