// @flow
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import moment from 'moment';
import React, {Component} from 'react';
import styled from 'styled-components';
import {Section} from '../../../../components/layout';
import {notificationLoading} from '../../../../components/Notifications';
import {AntTable} from '../../../../components/ui';
import ButtonsRow from '../../../../components/ui/ButtonsRow';
import {accessTypeEnum, calculationStatusEnum, vehiclePlanStatusEnum} from '../../../../lib/enum';
import {getListInitialState, toLocalStringRu} from '../../../../lib/helpers';
import {printNotification} from '../../../../lib/notificationWrapper';
import type {
  CalculationStatus,
  LicensePlateChangeCalculation,
  ListState,
  UserAccess,
  VehiclePlan,
} from '../../../../lib/types';
import {withUserAccess} from '../../../withUserAccess';
import {canEditBudget} from '../accessRight';
import type {VehicleListFilterParams} from '../components/FilterVehicleList';
import Filter from '../components/FilterVehicleList';
import {COGNOS_DELTA, COLUMNS, filteredData, itogCalculation, MONTH} from '../lib';
import {licensePlateChangeCalculationApi, vehiclePlanApi} from './../../../../lib/api';
import {headerPanel} from './components/Common';
import TotalBlock from './components/TotalBlock';
import InfoBlock from '../components/InfoBlock';
import Select from 'antd/lib/select';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess
};

type State = ListState<LicensePlateChangeCalculation> & {
  vehiclePlan: VehiclePlan,
  itog: any,
  filter: VehicleListFilterParams,
  cognosData: ?number
};

const {Option} = Select;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const LicensePlateChange = class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    filter: {},
    itog: itogCalculation([]),
  };
  componentDidMount() {
    this.getData();
  }
  isRangeMonths = (monthIndex: number) => {
    const {vehiclePlan} = this.state;
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };
  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };
  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    try {
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      this.setState({vehiclePlan});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };
  fetch = async () => {
    const {vehiclePlanId} = this.props;
    try {
      let {data} = await licensePlateChangeCalculationApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      this.setState({data});
    } catch (error) {
      notification.warning({message: 'Не удалось запросить данные'});
    }
  };
  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    const {data} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await licensePlateChangeCalculationApi.updateCalculations(data);
      await licensePlateChangeCalculationApi.changeStatus(
        vehiclePlanId,
        status,
      );
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };
  calculate = async () => {
    const {vehiclePlanId} = this.props;
    const {data} = this.state;
    try {
      notificationLoading({
        message: 'Расчет стоимости...',
        key: 'saving',
      });
      if (data.length !== 0) {
        await licensePlateChangeCalculationApi.updateCalculations(data);
      } else {
        await licensePlateChangeCalculationApi.calculate(vehiclePlanId);
      }
      this.getData();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };
  canEdit = () => this.props.userAccess.some(access =>
    [
      accessTypeEnum.editingBudgetCopy,
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
    ].includes(access),
  );
  columns = [
    ...COLUMNS,
    ...MONTH.map((month, monthIndex) => ({
      title: month.title,
      key: `${monthIndex}`,
      children: [
        {
          title: 'Замена',
          dataIndex: `months[${monthIndex}].count`,
          render: (value, record: LicensePlateChangeCalculation) => {
            return Number.isInteger(record.id) && (
              <Select
                size="small"
                value={value}
                disabled={!this.isRangeMonths(monthIndex) || !this.canEdit()}
                onChange={async value => {
                  let {data} = this.state;
                  const findIndex = data.findIndex(item => item.id === record.id);
                  if (value || value === 0) {
                    data[findIndex].months.forEach(
                      (month: any, index: number) => {
                        if (month.count > 0) {
                          notification.warning({
                            message: `Для этой записи была уже указана замена госномера за ${MONTH[index].title}`,
                          });
                        }
                        month.count = 0;
                      },
                    );
                  }
                  data[findIndex].months[monthIndex].count = value;
                  await this.setState({data});
                  await this.calculate();
                }}
              >
                <Option key="0" value={0}>Нет</Option>
                <Option key="1" value={1}>Один номер</Option>
                <Option key="2" value={2}>Два номера</Option>
              </Select>
            );
          },
        },
        {
          title: 'Стоимость',
          dataIndex: `months[${monthIndex}].cost`,
          render: (cost: number) =>
            toLocalStringRu(cost, {minimumFractionDigits: 2}),
        },
      ],
    })),
    {
      title: 'Итог',
      width: 200,
      key: 'sum',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, {minimumFractionDigits: 2}),
    },
  ];
  applyFilter = (filter: VehicleListFilterParams) => this.setState({filter});

  cleanFilter = () => this.setState({filter: {}});

  handlePrint = () => {
    printNotification(async () => {
      await licensePlateChangeCalculationApi.print(
        this.props.vehiclePlanId,
        this.state.filter,
      );
    });
  };

  render() {
    const {vehiclePlanId} = this.props;
    const {data, loading, vehiclePlan, filter, cognosData} = this.state;
    const filtered = filteredData(data, filter);
    const itog = itogCalculation(filtered);
    const isDraft =
      vehiclePlan?.licensePlateChangeCalculationStatus ===
      calculationStatusEnum.draft;
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
        ? false
        : Math.abs(itog?.sum - cognosData) > COGNOS_DELTA;
    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'Расчет стоимости и потребности в замене гос. номеров',
        })}
        <Section>
          <InfoBlock
            links={{
              'Стоимость замены гос. номеров':
                '/admin/license-plate-change-cost/',
            }}
          />
          <div style={{padding: '16px 16px 0px'}}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              vehiclePlanId={vehiclePlanId}
            />
          </div>
          <AntTable
            loading={loading}
            data={filtered.length !== 0 ? [...filtered, itog] : []}
            columns={
              isDraft
                ? this.columns
                : [
                  ...COLUMNS,
                  ...MONTH.map((month, monthIndex) => ({
                    title: month.title,
                    dataIndex: `months[${monthIndex}].cost`,
                    render: (cost: number) =>
                      toLocalStringRu(cost, {minimumFractionDigits: 2}),
                  })),
                  {
                    title: 'Итог',
                    width: 150,
                    key: 'sum',
                    dataIndex: 'sum',
                    render: (sum: number) =>
                      toLocalStringRu(sum, {minimumFractionDigits: 2}),
                  },
                ]
            }
            bordered
            scroll={{
              x: isDraft ? 3500 : 2400,
              y: 'calc(100vh - 510px)',
            }}
            pagination={{pageSize: 50}}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && isDraft
              ? 'table-row-error'
              : null}
          />
        </Section>
        {!loading && (
          <Footer>
            {isCognosStatus && (
              <TotalBlock
                filteredDataTotalSum={itog?.sum}
                controllerName="licensePlateChangeCalculation"
                vehiclePlanId={vehiclePlanId}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            <ButtonsRow>
              {canEditBudget(this.props.userAccess) && (
                <>
                  {isDraft && (
                    <>
                      {vehiclePlan?.status !==
                        vehiclePlanStatusEnum.approvedAfterCognos &&
                        vehiclePlan?.status !==
                        vehiclePlanStatusEnum.approvementAfterCognos && (
                          <Button type="primary" onClick={this.calculate}>
                            {data.length === 0 ? 'Сформировать' : 'Рассчитать'}
                          </Button>
                        )}
                      <Button
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!planApproved && !isDraft && vehiclePlan?.status !== vehiclePlanStatusEnum.approvedAfterCognos && (
                    <>
                      <Button type="primary" onClick={() => this.changeStatus(calculationStatusEnum.draft)}>
                        Редактировать
                      </Button>
                    </>
                  )}
                </>
              )}
              {data.length > 0 && (
                <Button onClick={this.handlePrint}>Печать</Button>
              )}
            </ButtonsRow>
          </Footer>
        )}
      </>
    );
  }
};

export default withUserAccess(LicensePlateChange);
