// @flow

import React from 'react';

import notification from 'antd/lib/notification';

import { inspectionGtnApi } from '../../../../../lib/api';
import type { InspectionGtn } from '../../../../../lib/types';

import Filter from '../../../../services/inspectionsGtn/Filter';
import { INSPECTION_GTN_COLUMNS } from '../../../../services/inspectionsGtn';

import { ListTable } from '../../../lib';

export default class extends ListTable<InspectionGtn> {
  fetch = async (page: number = 1) => {
    const params = super.fetch(page);
    try {
      const data = await inspectionGtnApi.fetchInspectionsGtn(params);
      const totalSum = await inspectionGtnApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(
          INSPECTION_GTN_COLUMNS.map(column =>
            column.dataIndex === 'paymentAmount'
              ? { ...column, dataIndex: 'taxPrice' }
              : column
          )
        )}
      </>
    );
  }
}
