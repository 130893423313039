// @flow
import React from 'react';
import {smallScaleMechanizationToolApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';

import {navigate} from '../../lib/helpers';
import {addAccessRight} from './accessRight';
import {withUserAccess} from '../withUserAccess';

const entryPointPath = '/admin/small-scale-mechanization/';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Наименование',
    dataIndex: 'name'
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));

  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}new`) : undefined
  };
  return (
    <Page>
      <CommonListPage
        crud={smallScaleMechanizationToolApi}
        pageHeaderProps={{
          mainHeader: 'Средства малой механизации',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
      />
    </Page>
  );
});
