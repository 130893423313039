// @flow
import React from 'react';
import {TabItem, Tabs} from '../../../components/ui';

export default () => {
  return (
    <Tabs withRouter>
      <TabItem
        label="Направление расходов"
        url={`/admin/expense-direction/`}
      />
      <TabItem
        label="Статьи бюджетной формы"
        url={`/admin/expense-direction/budget-line-item`}
      />
      <TabItem
        label="Распределение затрат"
        url={`/admin/expense-direction/distribution`}
      />
    </Tabs>
  );
};
