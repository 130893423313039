// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { budgetLineItemApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';
import type { BudgetLineItem } from '../../lib/types';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите статью"
    fetch={async params => {
      return await budgetLineItemApi.fetch({
        ...params,
        ...filter
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={budgetLineItemApi.get}
    notFoundText="Статьи не найдены"
    renderOption={(budgetLineItem: BudgetLineItem, Option) => (
      <Option
        value={budgetLineItem.id}
        key={budgetLineItem.id}
        budgetLineItem={budgetLineItem}
      >
        {budgetLineItem.mnemocode} {budgetLineItem.name}
      </Option>
    )}
    {...selectProps}
  />
);
