// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { Driver } from '../../lib/types';
import { driverApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import type { DriverFilterParams } from '../../containers/Driver/Filter';
import { convertEmployeeToString } from '../../lib/helpers';
import moment from 'moment';
import { DriverQualificationDocument } from '../../lib/types';
import { driverQualificationEnum } from '../../lib/enum';

export type DriverSelectProps = SelectProps & {
  filter?: DriverFilterParams,
  filterWithoutLicense?: boolean
};

export default (selectProps: DriverSelectProps) => (
  <AutocompleteSelect
    fetch={async params => 
      await driverApi.fetchDrivers({
        ...params,
        ...selectProps.filter,
      })
    }
    fetchSingle={driverApi.fetchDriver}
    notFoundText="Водители не найдены"
    placeholder="Выберите водителя"
    renderOption={(driver: Driver, Option) => {
      let drivingLicenseIsEnd;
      if (selectProps.filterWithoutLicense) {
        const drivingLicense = driver.qualificationDocuments.find(
          (doc: DriverQualificationDocument) => {
            return doc?.qualification === driverQualificationEnum.driverLicense;
          }
        );
        // Если в\у нет вообще, это не должно блокировать выбор такого водителя
        // только если в\у есть, но оно просрочено, блокируем водителя для выбора
        drivingLicenseIsEnd =
          !!drivingLicense &&
          moment().isSameOrAfter(drivingLicense?.documentEndDate, 'day');
      }

      return (
        <Option
          disabled={drivingLicenseIsEnd}
          title={
            drivingLicenseIsEnd
              ? 'Водительское удостоверение недействительно'
              : undefined
          }
          key={driver.id}
          value={driver.id}
        >
          {convertEmployeeToString(driver.employee)}
        </Option>
      );
    }}
    {...selectProps}
  />
);
