// @flow
import React, { useEffect, useState, type Node } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import Breadcrumbs, { Crumb } from '../../../layout/Breadcrumbs';
import { Header, Panel } from '../../../layout';
import { Icon } from '../../../ui';
import { notificationLoading } from '../../../Notifications';
import { getPathWithHistoryParams } from '../../../../lib/helpers';

const Operations = styled.div`
  display: inline-flex;
  align-items: center;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const OperationIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
  width: 16px;
  height: 16px;
  & img {
    width: 100%;
  }
`;

/**
 * Ссылка вверху страницы
 */
type CrumbLink = {
  // гиперссылка
  to?: string,
  // наименование
  name: string
};

type CustomAccess = {
  title: string,
  action: Function
};
/**
 * Разрешения на действие, если поле не инициализировано, то доступ запрещен, соответствующий объект-манимулятор не отображается
 */
type AccessProps = {
  edit?: Function,
  add?: Function,
  delete?: Function,
  print?: Function,
  custom?: CustomAccess
};

/**
 * Пропсы заголовка страницы
 */
export type PageHeaderProps = {
  access?: AccessProps,
  breadCrumbs?: Array<CrumbLink>,
  // заголовок страницы
  mainHeader?: Node,
  // дополнительный левый header
  leftHeader?: any,
  // дополнительный правый header
  rightHeader?: any
};

export default (props: PageHeaderProps) => {
  // Хук удаления текущей записи
  const [deleting, setDeleting] = useState(false);
  useEffect(() => {
    if (deleting) {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
    } else {
      notification.close('deleting');
    }
    return () => {
      notification.close('deleting');
    };
  }, [deleting]);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      // props.access.delete && (await props.access.delete());
      // eslint-disable-next-line no-unused-expressions
      props.access?.delete?.();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
    setDeleting(false);
  };

  const breadCrumbs =
    props.breadCrumbs &&
    props.breadCrumbs.map((el, i) => (
      <Crumb to={getPathWithHistoryParams(el.to)} key={i}>
        {el.name}
      </Crumb>
    ));

  const header = (
    <h1>
      {props.mainHeader}
      {props.access && props.access.edit && (
        <OperationIcon onClick={props.access.edit} type="edit" size={16} />
      )}
    </h1>
  );

  return (
    <>
      <Header
        left={
          <>
            {breadCrumbs && <Breadcrumbs>{breadCrumbs}</Breadcrumbs>}
            {!breadCrumbs && header}
            {props.leftHeader}
          </>
        }
        right={
          <Operations>
            {props.access?.print && (
              <Button data-cy="printStou" onClick={props.access.print}>
                Печать
              </Button>
            )}
            {props.access?.add && (
              <Button
                style={{ marginLeft: '10px' }}
                data-cy="addStou"
                type="primary"
                onClick={props.access.add}
              >
                Создать
              </Button>
            )}
            {props.access?.delete && (
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={handleDelete}
              >
                <OperationIcon type="trash" size={16} />
              </Popconfirm>
            )}
            {props.access?.custom && (
              <Button
                style={{ marginLeft: '10px' }}
                data-cy="customStou"
                type="primary"
                onClick={props.access.custom.action}
              >
                {props.access.custom.title}
              </Button>
            )}
            {props.rightHeader}
          </Operations>
        }
      />
      {breadCrumbs && <StyledPanel>{header}</StyledPanel>}
    </>
  );
};
