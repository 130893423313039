// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table/Table';
import moment from 'moment';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import Button from 'antd/lib/button';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import type {
  ReportFuelCard,
  ReportFuelCardsParams
} from '../../../lib/types/reportFuelCards';
import { applyMaskToValue } from './../../../lib/helpers';
import { fuelGroups, vehicleTypes } from '../../../lib/enum';
import Filter from './components/Filter';
import type { FuelGroup, VehicleType } from '../../../lib/types';
import { reportFuelCardsApi } from '../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialParams: ReportFuelCardsParams = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  filter: ReportFuelCardsParams,
  data: ReportFuelCard[],
  loading: boolean
};

export const COLUMNS = [
  {
    title: 'Гос. номер',
    dataIndex: 'licensePlate',
    render: (licensePlate: ?string) =>
      licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
  },
  {
    title: 'Марка ТС',
    dataIndex: 'vehicleBrandName'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'type',
    render: (type: VehicleType) => vehicleTypes[type]
  },
  {
    title: 'Топливная карта',
    dataIndex: 'cardNumber'
  },
  {
    title: 'Топлива израсходовано',
    dataIndex: 'fuelIssued',
    render: (fuelIssued: number) => `${fuelIssued} л.`
  },
  {
    title: 'Вид топлива',
    dataIndex: 'fuelGroup',
    render: (group: FuelGroup) => fuelGroups[group]
  },
  {
    title: 'Служба',
    dataIndex: 'orgUnitName'
  }
];

/**
 * Отчет о топливных картах
 */
export class FuelCardsReportList extends React.Component<null, State> {
  state = {
    filter: initialParams,
    data: [],
    loading: false
  };

  columns = [...COLUMNS];

  async componentDidMount() {
    const { ...filter } = qs.parse(window.location.search);
    this.setState(
      { filter: isEmpty(filter) ? initialParams : filter },
      this.fetchReportFuelCards
    );
  }

  fetchReportFuelCards = async () => {
    const { filter } = this.state;
    try {
      this.setState({ loading: false });
      const data = await reportFuelCardsApi.fetchReportFuelCards(filter);
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  applyFilter = async (filter: ReportFuelCardsParams) =>
    this.setState({ filter }, this.fetchReportFuelCards);

  cleanFilter = () =>
    this.setState({ filter: initialParams }, this.fetchReportFuelCards);

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing'
      });
      await reportFuelCardsApi.printReportFuelCards(this.state.filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  render() {
    const { data, filter, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Отчет о топливных картах</h1>}
          right={
            <Button type="primary" onClick={this.handleExport}>
              Экспорт
            </Button>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={data}
            columns={this.columns}
            rowKey="vehicleId"
          />
        </Section>
      </>
    );
  }
}

export default FuelCardsReportList;
