// @flow
import {Button, notification} from 'antd';
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';

import CommonCardPage from '../../../components/hoc/common/handbook/CardPage';
import Section, {SectionTitle} from '../../../components/layout/Section';
import {branchBudgetSummaryApi, budgetSummaryApi} from '../../../lib/api';
import {getBreadCrumbsByUrlForEdit} from '../../../lib/autoBreadcrumbs';
import {
  accessTypeEnum,
  branchBudgetSummaryCognosTypeEnum,
  budgetSummaryEnum,
  budgetSummaryStatusEnum,
} from '../../../lib/enum';

import {navigate} from '../../../lib/helpers';
import {changeNotification, saveNotification} from '../../../lib/notificationWrapper';
import type {BranchBudgetSummary, BudgetSummary, BudgetSummaryStatusType, UserAccess} from '../../../lib/types';

import {withUserAccess} from './../../withUserAccess';
import {approveDetailedBudgetAccessRight, editDetailedBudgetAccessRight, viewDetailedBudgetAccessRight} from './accessRight';

import {BranchForm, Economy, InnerForm, Tabs} from './components';
import Cognos from './components/Cognos';
import Table from 'antd/lib/table';

type PageProps = {
  id: number,
  orgUnitId?: number,
  userAccess: UserAccess[],
  type?: string,
  source?: $Keys<branchBudgetSummaryCognosTypeEnum>,
};

const entryPointPath = '/budget/details/';

const nextStatusResolver = {
  [budgetSummaryStatusEnum.created]: budgetSummaryStatusEnum.approvement,
  [budgetSummaryStatusEnum.declined]: budgetSummaryStatusEnum.approvement,
  [budgetSummaryStatusEnum.approvement]: budgetSummaryStatusEnum.approved,
  [budgetSummaryStatusEnum.approved]: budgetSummaryStatusEnum.declined,
};
const nameResolver = {
  [budgetSummaryStatusEnum.created]: 'На согласование',
  [budgetSummaryStatusEnum.declined]: 'На согласование',
  [budgetSummaryStatusEnum.approvement]: 'Согласовать',
  [budgetSummaryStatusEnum.approved]: 'Отклонить',
};

export default withUserAccess((props: PageProps) => {
  const [data: BudgetSummary, setData] = useState<BudgetSummary>({
    status: budgetSummaryStatusEnum.created,
    type: budgetSummaryEnum.clarification,
    id: 0,
  });
  const [loadingData, setLoadingData] = useState(false);
  const [branchesData: BranchBudgetSummary[], setBranchesData] = useState([]);
  const [expandedRows: string[], setExpandedRows] = useState([]);
  
  const [branchData: BranchBudgetSummary, setBranchData] = useState({
    id: 0,
    status: budgetSummaryStatusEnum.created,
    contractVehiclePlanId: 0,
    budgetSummaryId: 0,
  });
  
  const breadCrumbs = useMemo(
    () => getBreadCrumbsByUrlForEdit(entryPointPath, props.id),
    [props.id],
  );
  
  const onFetch = useCallback(async (id?: number) => {
    if (id) {
      try {
        const data = await budgetSummaryApi.get(id);
        setData(data)
      } catch (e) {
        notification.error({
          message: 'Ошибка получения данных',
          description: e.title || e.message,
        })
      }
    }
  }, []);
  
  const onSubmit = useCallback(async (payload: BudgetSummary) => {
    await saveNotification(async () => {
      let id = payload.id;
      if (id) {
        await budgetSummaryApi.addClarification(payload);
      } else {
        let data = await budgetSummaryApi.addClarification(payload);
        id = data.id;
      }
      await navigate(`${entryPointPath}${id}/-1/primary`);
    });
  }, []);
  
  const onFetchBranches = useCallback(async (budgetSummaryId?: number) => {
    if (!budgetSummaryId) {
      return;
    }
    
    try {
      setLoadingData(true);
      let {data} = await branchBudgetSummaryApi.fetch({budgetSummaryId});
      data = canApprove() || props.userAccess.includes(accessTypeEnum.viewingDetailedBudget)
        ? [
          ...data,
          {
            orgUnitId: -1,
            id: -1,
            orgUnitName: 'Свод',
          },
        ]
        : [...data];
      setBranchesData(
        data.sort((a, b) => {
          return a.orgUnitMnemonic > b.orgUnitMnemonic ? 1 : -1;
        }),
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const onFetchBranch = useCallback(async (id?: number) => {
    if (!id) {
      return;
    }
    try {
      const data = await branchBudgetSummaryApi.get(id);
      setBranchData(data);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  }, []);
  
  const onFetchSummary = useCallback(async (id?: number) => {
    if (!id) {
      return;
    }
    try {
      let data = await budgetSummaryApi.getExpenseDirections(
        id,
        props.source
      );
      data = {
        budgetSummaryLineItems: data,
        orgUnitId: -1,
        id: -1,
        orgUnitName: 'Свод',
        budgetSummaryId: 0,
        status: budgetSummaryStatusEnum.created,
      };
      setBranchData(data);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  }, [props.source]);
  
  const onSubmitBranch = useCallback(async (payload: BranchBudgetSummary) => {
    await saveNotification(async () => {
      const data = await branchBudgetSummaryApi.updateClarification(payload);
      setBranchData(data);
    });
  }, []);
  
  useEffect(() => {
    async function fetcher() {
      await Promise.all([onFetch(props.id), onFetchBranches(props.id)]);
    }
    fetcher().then();
  }, [onFetch, onFetchBranches, props.id]);
  
  const changeStatus = useCallback(
    async (params: { status: BudgetSummaryStatusType, id: number }) => {
      await changeNotification(async () => {
        await budgetSummaryApi.changeStatus(params);
        setData({...data, status: params.status});
      });
    },
    [data],
  );
  
  const changeBranchStatus = useCallback(
    async (data: any) => {
      await changeNotification(async () => {
        await branchBudgetSummaryApi.changeStatus(data);
        setBranchData({...branchData, status: data.status});
        await onFetchBranches(branchData.budgetSummaryId);
      });
    },
    [branchData, onFetchBranches],
  );
  
  useEffect(() => {
    if (+props.orgUnitId > 0) {
      const selectedBranch = branchesData.find(el => el.orgUnitId === +props.orgUnitId && el.type === props.source);
      if (selectedBranch) {
        onFetchBranch(selectedBranch.id);
      } else {
        setBranchData({})
      }
    } else {
      if (+props.orgUnitId === -1) {
        onFetchSummary(props.id);
      }
    }
  }, [props.id, onFetchBranch, onFetchSummary, props.orgUnitId, branchesData, props.source]);
  
  const statusChangeButton = useCallback(() => {
    const allow =
      !!branchesData.length &&
      branchesData.every(
        (el: BranchBudgetSummary) =>
          el.status === budgetSummaryStatusEnum.approved,
      );
    return (
      (data.status === budgetSummaryStatusEnum.approvement ||
        data.status === budgetSummaryStatusEnum.approved ||
        allow) && (
        <Button
          type="primary"
          onClick={() =>
            changeStatus({
              status: nextStatusResolver[data.status],
              id: data.id,
            })
          }
        >
          {nameResolver[data.status]}
        </Button>
      )
    );
  }, [branchesData, changeStatus, data.id, data.status]);
  
  const branchStatusChangeButton = useCallback(() => {
    if (
      branchData.id === -1 &&
      data.status === budgetSummaryStatusEnum.created &&
      canApprove()
    ) {
      return (
        <Button
          type="primary"
          onClick={() =>
            changeStatus({
              status: budgetSummaryStatusEnum.approved,
              id: data.id,
            })
          }
        >
          Утвердить
        </Button>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchData, changeBranchStatus, changeStatus, data.id, data.status]);
  
  const canEdit = () => props.userAccess.some(access => editDetailedBudgetAccessRight.includes(access));
  
  const canView = () => props.userAccess.some(access => viewDetailedBudgetAccessRight.includes(access));
  
  const canApprove = () => props.userAccess.some(access => approveDetailedBudgetAccessRight.includes(access));
  
  const getContent = () => {
    switch (props.source) {
      case branchBudgetSummaryCognosTypeEnum.cognos:
        const primaryBranchId = branchesData.find(el => el.orgUnitId === +props.orgUnitId && el.type === 'primary')?.id;
        if (props.orgUnitId > 0) {
          return (
            <Cognos
              orgUnitId={props.orgUnitId}
              branchBudgetSummary={branchData}
              id={props.id}
              userAccess={props.userAccess}
              fetchBranchData={() => onFetchBranch(branchData.id)}
              primaryBranchId={primaryBranchId}
            />
          );
        }
      // eslint-disable-next-line
      case branchBudgetSummaryCognosTypeEnum.corrected:
        if (props.orgUnitId > 0) {
          return (
            <Economy
              setBranchBudgetSummary={async (data: any, expandedRows: string[] = []) => {
                setBranchData(data);
                setExpandedRows(expandedRows);
                await onFetchBranches(branchData.budgetSummaryId);
              }}
              expandedRows={expandedRows}
              orgUnitId={props.orgUnitId}
              branchBudgetSummary={branchData}
              budgetSummary={data}
              id={props.id}
              type={props.type}
              onSubmit={onSubmitBranch}
              source={props.source}
            />
          );
        }
      // eslint-disable-next-line
      default:
        return !!branchData.contractVehiclePlanId
          ? (canView()
            ? (
              <Economy
                setBranchBudgetSummary={async (data: any, expandedRows: string[] = []) => {
                  setBranchData(data);
                  setExpandedRows(expandedRows);
                  await onFetchBranches(branchData.budgetSummaryId);
                }}
                expandedRows={expandedRows}
                orgUnitId={props.orgUnitId}
                branchBudgetSummary={branchData}
                budgetSummary={data}
                id={props.id}
                type={props.type}
                onSubmit={onSubmitBranch}
                source={props.source}
              />
            )
            : null
          )
          : (canEdit() || props.userAccess.includes(accessTypeEnum.viewingDetailedBudget))
              && branchData.type !== branchBudgetSummaryCognosTypeEnum.cognos
            ? (
              <BranchForm
                orgUnitId={props.orgUnitId}
                branchBudgetSummary={branchData}
                budgetSummary={data}
                onSubmit={onSubmitBranch}
                id={props.id}
                source={props.source}
              />
            )
            : <Table />
    }
  }
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Уточнение бюджета',
        rightHeader: <>{statusChangeButton()}</>,
      }}
    >
      <Fragment noWrapMe>
        {!loadingData && (
          <InnerForm data={data} onFetch={onFetch} onSubmit={onSubmit} />
        )}
        <Section>
          {!!data.id && (
            <>
              <SectionTitle divider suffix={branchStatusChangeButton()}>
                <Tabs branchData={branchesData} id={props.id} />
              </SectionTitle>
              {getContent()}
            </>
          )}
        </Section>
      </Fragment>
    </CommonCardPage>
  );
});
