// @flow
import type {
  ChassisConfiguration,
  DriverQualification,
  DrivingLicenseCategory,
  EngineType,
  FuelType,
  VehicleCategory,
  VehicleType,
} from './';

export type VehicleModelFilter = {
  type?:
    | 'car'
    | 'bus'
    | 'trailers'
    | 'motorcycles'
    | 'specialCar'
    | 'manipulator'
    | 'jibCrane'
    | 'carLift'
};

export const VehicleOwnerTypesEnum = {
  self: 'self',
  contract: 'contract',
};

export const WorkAccountingTypes = {
  kilometrage: 'kilometrage',
  workHours: 'workHours',
  all: 'all',
};

// Модель ТС
export type VehicleModel = {
  id: number,
  // название модели
  name: string,
  // комплектация
  configuration?: string,
  // тип ТС
  type: VehicleType,
  // марка
  brandName: string,
  // id марки
  brandId: number,
  // Количество шин
  wheelCount?: number,
  // Мнемокод ТС по МТР
  mnemonicMdm?: string,
  // Тип ТС (спр) 1С.МДМ
  typeMdm?: string,
  // Категория прав для ТС (А, B, С, D, Е)
  rightsCategory?: DrivingLicenseCategory[],
  // Колесная формула
  chassisConfiguration: ChassisConfiguration,
  // Подлежит ОСАГО
  osagoNeeded?: boolean,
  // Масса ТС без нагрузки, т
  unladenMass?: number,
  // Полная масса ТС, т
  grossWeight?: number,
  // Количество осей, шт
  axlesNumber?: number,
  // Нагрузка на ось, т (перед.)
  frontAxleLoad?: number,
  // Нагрузка на ось, т (зад.)
  backAxleLoad?: number,
  // Грузоподъемность
  carrying?: number,
  // Габариты ТС
  dimensions?: string,
  // Максимальная разрешенная масса по ПТС, кг
  maximumAuthorizedMass?: number,
  // Тип двигателя
  engineType?: EngineType,
  // Рабочий объем двигателя, куб. см
  engineCapacity?: number,
  // Мощность двигателя(кВт/л.с.)
  enginePower?: number,
  // Максимальная скорость (км/ч)
  maxSpeed?: number,
  // Количество пассажирских мест (для автобусов)
  passengerSeatsNumber?: number,
  // Экологический класс ТС
  ecologicClass?: string,
  // Категория ТС
  category?: VehicleCategory,
  categoryId: ?number,
  // Грузоподъемность ГПМ
  gpmCarrying?: number,
  // Высота подъема
  liftHeight?: number,
  // Вылет стрелы
  gpmHandlingRadius?: number,
  // Глубина бурения ( до мм)
  drillingDeep?: number,
  // Диаметр бурения (до мм)
  drillingDiameter?: number,
  // Объем топливного бака для основного топлива, л
  primaryTankVolume?: number,
  // Объем топливного бака для иного топлива, л
  secondaryTankVolume?: number,
  // Расход по нормативу для основного вида топлива, литр/100 км
  primaryFuelConsumption: number,
  // Расход по нормативу для доп. вида топлива, литр/100 км
  secondaryFuelConsumption?: ?number,
  // Расход по нормативу для основного вида топлива дополнительного оборудования (если оно имеется), л/маш часы
  primaryEquipmentFuelConsumption?: ?number,
  // Расход по нормативу для доп вида топлива доп оборудования (если оно имеется), л/маш часы
  secondaryEquipmentFuelConsumption?: ?number,
  // Вид основного топлива
  primaryFuelType: FuelType,
  // Вид дополнительного топлива
  secondaryFuelType?: ?FuelType,
  // Тарифы на основной вид топлива
  // Постоянная часть тарифа
  fixedRatePart?: number,
  // Постоянная часть тарифа (в пути) (руб)
  fixedRateInTransit?: number,
  // Переменная часть тарифа (руб)
  fixedRateInDowntime?: number,
  // Стоимость прогрева (руб)
  warmingUpCost?: number,

  // Тарифы на дополнительный вид топлива
  // Постоянная часть тарифа
  secondaryFixedRatePart?: number,
  // Постоянная часть тарифа (в пути) (руб)
  secondaryFixedRateInTransit?: number,
  // Переменная часть тарифа (руб)
  secondaryFixedRateInDowntime?: number,
  // Стоимость прогрева (руб)
  secondaryWarmingUpCost?: number,

  // Необходимая квалификация водителя
  driverQualification?: DriverQualification,

  // Трудоёмкость одного ТО (чел/час)
  workHours?: number,
  // Средние затраты на материалы ТО (копейки)
  materialsCost?: number,
  // Интервал проведения технического обслуживания (км)
  kilometrageInterval?: number,
  // Интервал проведения технического обслуживания (моточас)
  engineWorkInterval?: number,
  // Параметр учета работы
  workAccountingType: $Keys<WorkAccountingTypes>,
  vehicleOwnerType: $Keys<VehicleOwnerTypesEnum>,
};
