// @flow
import qs from 'query-string';
import React from 'react';

import notification from 'antd/lib/notification';

import {fuelsActApi, oilsActApi, outsourcingActApi} from './../../../lib/api';
import {getPathWithHistoryParams, navigate} from './../../../lib/helpers';
import type {FuelsAndOilsAct, OilsAct, OutsourcingAct} from './../../../lib/types';

import {Header, Panel} from './../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';

import OutSourceInnerForm from './components/OutSourceInnerForm';
import FuelsAndOilsInnerForm from './components/FuelsAndOilsInnerForm';
import styled from 'styled-components';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  type: string,
  location: Location
};
type State = {
  tripId?: number
};

export class ActForm extends React.Component<Props, State> {
  componentDidMount() {
    this.setState({ tripId: qs.parse(this.props.location.search)['tripId'] });
  }

  addUpdateOutsourcingAct = async (outsourcingAct: OutsourcingAct) => {
    if (outsourcingAct.id) {
      return await outsourcingActApi.updateOutsourcingAct(outsourcingAct);
    }
    return await outsourcingActApi.addOutsourcingAct(outsourcingAct);
  };

  addUpdateFuelsAct = async (fuelsAct: FuelsAndOilsAct) => {
    if (fuelsAct.id) {
      return await fuelsActApi.update(fuelsAct);
    }
    return await fuelsActApi.add(fuelsAct);
  };

  addUpdateOilsAct = async (oilsAct: OilsAct) => {
    if (oilsAct.id) {
      return await oilsActApi.update(oilsAct);
    }
    return await oilsActApi.add(oilsAct);
  };

  isOutsourceAct = () => this.props.type === 'outsourcing-act';

  getRouteByType = () => {
    let route = 'oils-act';
    switch (this.props.type) {
      case 'outsourcing-act':
        route = 'outsourcing-act';
        break;
      case 'fuels-act':
        route = 'fuels-act';
        break;
      default:
    }
    return route;
  };

  handleSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      switch (this.props.type) {
        case 'outsourcing-act':
          await this.addUpdateOutsourcingAct(values);
          break;
        case 'fuels-act':
          const act = await this.addUpdateFuelsAct(values);
          await navigate(`/admin/act/internal/write-off-fuels-act/${act.id}`);
          return;
        default:
          await this.addUpdateOilsAct(values);
      }
      await navigate(`/admin/act/internal/${this.getRouteByType()}`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () =>
    navigate(`/admin/act/internal/${this.getRouteByType()}`, true);

  render() {
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams(
                  `/admin/act/internal/${this.getRouteByType()}`
                )}
              >
                Реестр первичных документов
              </Crumb>
              <Crumb>Новый акт</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Новый акт</h1>
        </StyledPanel>
        {this.isOutsourceAct() && (
          <OutSourceInnerForm
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
            tripId={this.state?.tripId}
          />
        )}
        {!this.isOutsourceAct() && (
          <FuelsAndOilsInnerForm
            onSubmit={this.handleSubmit}
            onCancel={this.handleCancel}
          />
        )}
      </>
    );
  }
}

export default ActForm;
