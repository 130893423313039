// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import moment from 'moment';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';

import type {DriverInspection} from '../../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import {driverInspectionApi} from './../../../lib/api';

import {Form} from './../../../components';
import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import Header from './../../../components/layout/Header';
import {notificationLoading} from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const {RangePicker} = DatePicker;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  driverInspection: DriverInspection
};

const InnerForm = ({onSubmit, onCancel, driverInspection}: FormProps) => (
  <Form initialValues={driverInspection} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {handleSubmit, setFieldValue, values, dirty} = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    fast
                    required
                    label="Дата оказания"
                    name="maintenanceDate"
                  >
                    {({value, name}) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="startDate" required label="Период оказания">
                    {({name, value}) => (
                      <RangePicker
                        format="DD.MM.YYYY"
                        placeholder={['Начало', 'Конец']}
                        value={[
                          values.startDate
                            ? moment.utc(values.startDate)
                            : null,
                          values.endDate ? moment.utc(values.endDate) : null,
                        ]}
                        onChange={(value, dateString) => {
                          const [startDate, endDate] = value;
                          const [startDateString, endDateString] = dateString;
                          setFieldValue(
                            'startDate',
                            formatDateTimeToISOString(
                              startDate,
                              startDateString,
                            ),
                          );
                          setFieldValue(
                            'endDate',
                            formatDateTimeToISOString(endDate, endDateString),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Сумма оплаты, руб."
                    name="paymentAmount"
                  >
                    {({name, value}) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  driverInspectionId: ?number
};

type State = {
  driverInspection: $Shape<DriverInspection>
};

class DriverInspectionForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const driverInspectionId = parseInt(this.props.driverInspectionId, 10);

      if (driverInspectionId) {
        const driverInspection = await driverInspectionApi.fetchDriverInspection(
          driverInspectionId,
        );
        this.setState({
          driverInspection,
        });
      } else {
        this.setState({
          driverInspection: {},
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.driverInspectionId) => {
    if (id) {
      navigate(`/services/driver-inspections/${id}`);
    } else {
      navigate(`/services/driver-inspections`, true);
    }
  };

  onSubmit = async (driverInspection: DriverInspection) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const driverInspectionId = parseInt(this.props.driverInspectionId, 10);
      if (driverInspectionId) {
        await driverInspectionApi.updateDriverInspection(driverInspection);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены',
        });
        this.redirect(driverInspectionId);
      } else {
        const added = await driverInspectionApi.addDriverInspection(
          driverInspection,
        );

        notification.success({
          message: 'Успешное добавление',
          description: `Осмотр водителей успешно добавлен`,
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const driverInspectionId = parseInt(this.props.driverInspectionId, 10);
    const {driverInspection} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/services/driver-inspections')}
              >
                Мед. осмотры водителей
              </Crumb>
              {driverInspectionId ? (
                <Crumb>Редактирование мед. осмотра водителей</Crumb>
              ) : (
                <Crumb>Новый акт мед. осмотра водителей</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {driverInspectionId
              ? `Мед. осмотр водителей №${driverInspectionId || ''}`
              : 'Новый акт мед. осмотр водителей'}
          </h1>
        </StyledPanel>
        <InnerForm
          driverInspection={{
            paymentAmount: 0,
            ...driverInspection,
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect()}
        />
      </>
    );
  }
}

export default DriverInspectionForm;
