// @flow
import React, {useEffect} from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import type moment from 'moment';
import {Element, scroller} from 'react-scroll';

import type {Employee, FuelMultiplier, Vehicle} from '../../../../lib/types';
import {getVehicleConstMultipliers} from '../../lib';
import {fuelTypes, vehicleTypeEnum} from '../../../../lib/enum/index';
import {Section} from '../../../../components/layout/index';
import {Card} from '../../../../components';
import Grid, {GridItem} from '../../../../components/layout/Grid';

import {Icon, ListTable} from '../../../../components/ui';
import FuelCard from '../../containers/FuelCard';
import SectionTitle from '../SectionTitle';
import {convertEmployeeToString, formatDateTimeToString, navigate, isVehicleTypeElectric} from '../../../../lib/helpers';
import {VehicleFuelsAndOilsList} from './../../containers/VehicleFuelAndOils';
import {canShowFuelData} from '../../../VehicleModels/lib';
import {WorkAccountingTypes} from '../../../../lib/types/vehicleModel';

const { Field } = Card;

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  anchor: ?string
};
export default ({ vehicle, editable, anchor }: Props) => {
  const isDualFuelEngine =
    vehicle.vehicleModel && !!vehicle.vehicleModel.secondaryFuelType;
  let constantFuelMultipliers =
    vehicle && vehicle.fuelMultipliers
      ? vehicle.fuelMultipliers.filter(
          (item: FuelMultiplier) => item.shouldUsedAlways
        )
      : [];

  constantFuelMultipliers = [
    ...constantFuelMultipliers,
    ...getVehicleConstMultipliers(vehicle)
  ];

  useEffect(() => {
    if (anchor) {
      scroller.scrollTo(anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  });

  const isElectricVehicle = isVehicleTypeElectric(vehicle?.vehicleModel?.type);

  return (
    <>
      {canShowFuelData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <Element name="fuel">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/fuel`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Топливо
            </SectionTitle>
            {isDualFuelEngine && <SectionTitle>Основное топливо</SectionTitle>}
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Вид топлива">
                    {fuelTypes[vehicle.vehicleModel.primaryFuelType]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label={isElectricVehicle ? "Уровень зарядки" : "Уровень топлива"}>
                    {vehicle.primaryFuelLevel || '0'} {isElectricVehicle ? "%" : "л."}&nbsp;
                  </Field>
                </GridItem>
                {!isElectricVehicle && (
                  <GridItem>
                    <Field label="Объем топливного бака">
                      {vehicle.vehicleModel.primaryTankVolume}
                      &nbsp;
                      {vehicle.vehicleModel.primaryTankVolume !== null && 'л.'}
                    </Field>
                  </GridItem>
                )}
              </Grid>
            </SectionContent>
            {isDualFuelEngine && vehicle.vehicleModel.secondaryFuelType && (
              <>
                <SectionTitle>Дополнительное топливо</SectionTitle>
                <SectionContent>
                  <Grid gutter="16px">
                    <GridItem>
                      {vehicle.vehicleModel.secondaryFuelType && (
                        <Field label="Вид топлива">
                          {fuelTypes[vehicle.vehicleModel.secondaryFuelType]}
                        </Field>
                      )}
                    </GridItem>
                    {!isEmpty(vehicle.secondaryFuelLevel) ? (
                      <GridItem>
                        <Field label={isElectricVehicle ? "Уровень зарядки" : "Уровень топлива"}>
                          {vehicle.secondaryFuelLevel} {isElectricVehicle ? "%" : "л"}
                        </Field>
                      </GridItem>
                    ) : null}
                    {!isElectricVehicle && (
                      <GridItem>
                        <Field label="Объем топливного бака">
                          {vehicle.vehicleModel.secondaryTankVolume}
                          &nbsp;
                          {vehicle.vehicleModel.secondaryTankVolume !== null &&
                            'л.'}
                        </Field>
                      </GridItem>
                    )}
                    {(parseFloat(
                      vehicle.vehicleModel.secondaryEquipmentFuelConsumption
                    ) > 0) && !isElectricVehicle && (
                      <GridItem>
                        <Field label="Норма расх. топл. на л/машчас работы оборудования">
                          {
                            vehicle.vehicleModel
                              .secondaryEquipmentFuelConsumption
                          }
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </SectionContent>
              </>
            )}
            {(vehicle.vehicleModel.workAccountingType ===
              WorkAccountingTypes.all ||
              vehicle.vehicleModel.workAccountingType ===
                WorkAccountingTypes.kilometrage) && (
              <>
                <SectionTitle>Расход по пробегу</SectionTitle>
                <SectionContent>
                  <Grid cols={2}>
                    <GridItem>
                      <Field
                        label={
                          isElectricVehicle ? "Расход по нормативу" :
                          (
                            vehicle.vehicleModel.secondaryFuelConsumption
                              ? 'Расход основного топлива по нормативу'
                              : 'Расход по нормативу'
                          )
                        }
                      >
                        {vehicle.vehicleModel.primaryFuelConsumption
                          ? `${vehicle.vehicleModel.primaryFuelConsumption} ${isElectricVehicle ? "кВт·ч/100км" : "л/100 км"}`
                          : '-'}
                      </Field>
                    </GridItem>
                    {vehicle.vehicleModel.secondaryFuelConsumption && (
                      <GridItem>
                        <Field label="Расход дополнительного топлива по нормативу">
                          {`${vehicle.vehicleModel.secondaryFuelConsumption} ${isElectricVehicle ? "кВт·ч/100км" : "л/100 км"}`}
                        </Field>
                      </GridItem>
                    )}
                    {(constantFuelMultipliers.length > 0) && !isElectricVehicle ? (
                      <GridItem>
                        <Field label="Фактический расход (с учетом коэффициентов)">
                          {`${Math.round(
                            (1 +
                              (sumBy(constantFuelMultipliers, 'value') || 0)) *
                              (vehicle.vehicleModel.primaryFuelConsumption ||
                                0) *
                              100
                          ) / 100} л/100 км`}
                        </Field>
                      </GridItem>
                    ) : (
                      <GridItem>
                        <Field label="Фактический расход">
                          {isElectricVehicle ? 
                            `${Math.round(
                              (vehicle.factElectricConsumption || 0) *
                                100
                            ) / 100} кВт·ч/100км`
                          :
                            `${Math.round(
                              (vehicle.vehicleModel.primaryFuelConsumption || 0) *
                                100
                            ) / 100} л/100км`
                          }
                        </Field>
                      </GridItem>
                    )}
                    {!isElectricVehicle && (
                      <GridItem>
                        <Field label="Коэффициенты поправок к расходу топлива">
                          {constantFuelMultipliers.length
                            ? constantFuelMultipliers.map((m, index) => (
                                <span key={index}>
                                  {m.name} x{m.value} <br />
                                </span>
                              ))
                            : '0%'}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </SectionContent>
              </>
            )}
            {(vehicle.vehicleModel.workAccountingType ===
              WorkAccountingTypes.all ||
              vehicle.vehicleModel.workAccountingType ===
                WorkAccountingTypes.workHours) && !isElectricVehicle && (
              <>
                <SectionTitle>Расход по маш. часам</SectionTitle>
                <SectionContent>
                  <Grid cols={2}>
                    <GridItem>
                      <Field
                        label={
                          vehicle.vehicleModel.secondaryEquipmentFuelConsumption
                            ? 'Расход основного топлива по нормативу'
                            : 'Расход по нормативу'
                        }
                      >
                        {vehicle.vehicleModel.primaryEquipmentFuelConsumption
                          ? `${vehicle.vehicleModel.primaryEquipmentFuelConsumption} л/ч.`
                          : '-'}
                      </Field>
                    </GridItem>
                    {vehicle.vehicleModel.secondaryEquipmentFuelConsumption && (
                      <GridItem>
                        <Field label="Расход дополнительного топлива по нормативу">
                          {`${vehicle.vehicleModel.secondaryEquipmentFuelConsumption} л/ч.`}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.vehicleModel.workAccountingType ===
                      WorkAccountingTypes.workHours &&
                    constantFuelMultipliers.length > 0 ? (
                      <GridItem>
                        <Field label="Фактический расход (с учетом коэффициентов)">
                          {`${Math.round(
                            (1 +
                              (sumBy(constantFuelMultipliers, 'value') || 0)) *
                              (vehicle.vehicleModel
                                .primaryEquipmentFuelConsumption || 0) *
                              100
                          ) / 100} л/ч.`}
                        </Field>
                      </GridItem>
                    ) : (
                      <GridItem>
                        <Field label="Фактический расход">
                          {`${Math.round(
                            (vehicle.vehicleModel
                              .primaryEquipmentFuelConsumption || 0) * 100
                          ) / 100} л/ч.`}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.vehicleModel.workAccountingType ===
                      WorkAccountingTypes.workHours &&
                      constantFuelMultipliers.length > 0 && (
                        <GridItem>
                          <Field label="Коэффициенты поправок к расходу топлива">
                            {constantFuelMultipliers.length
                              ? constantFuelMultipliers.map((m, index) => (
                                  <span key={index}>
                                    {m.name} x{m.value} <br />
                                  </span>
                                ))
                              : '0%'}
                          </Field>
                        </GridItem>
                      )}
                  </Grid>
                </SectionContent>
              </>
            )}
            <FuelCard vehicle={vehicle} editable={false} />
          </Section>
        </Element>
      )}
      <Element name="oils-technical-liquids">
        <Section>
          <SectionTitle
            divider
            suffix={
              editable && (
                <StyledIcon
                  onClick={() =>
                    navigate(
                      `/vehicles/${vehicle.id}/edit/oils-technical-liquids`
                    )
                  }
                  type="edit"
                  size={16}
                />
              )
            }
          >
            Масла и тех. жидкости
          </SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <VehicleFuelsAndOilsList vehicleId={vehicle.id} />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      </Element>
      {canShowFuelData(vehicle.vehicleModel && vehicle.vehicleModel.type) && (
        <Section>
          <SectionTitle divider>Контрольные замеры уровня {isElectricVehicle ? 'заряда' : 'топлива'}</SectionTitle>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem fullWidth>
                <ListTable
                  columns={[
                    {
                      title: 'Дата',
                      dataIndex: 'date',
                      key: 'date',
                      render: (date: string | moment) =>
                        formatDateTimeToString(date, 'DD.MM.YYYY')
                    },
                    {
                      title: `Предыдущее значение, ${isElectricVehicle? '%' : 'л'}`,
                      key: 'oldPrimaryFuelLevel',
                      dataIndex: 'oldPrimaryFuelLevel'
                    },
                    {
                      title: `Новое значение, ${isElectricVehicle? '%' : 'л'}`,
                      key: 'newPrimaryFuelLevel',
                      dataIndex: 'newPrimaryFuelLevel'
                    },
                    {
                      title: 'Документ-основание',
                      key: 'document',
                      dataIndex: 'document'
                    },
                    {
                      title: 'Сотрудник',
                      key: 'gauger',
                      dataIndex: 'gauger',
                      render: (gauger: Employee) =>
                        convertEmployeeToString(gauger)
                    }
                  ]}
                  data={vehicle.fuelMeasurements}
                />
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      )}
    </>
  );
};
