// @flow
import React from 'react';
import styled from 'styled-components';
import {Filter, FilterButtonsContainer, FilterContainer, FilterItem} from './../../../components';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import {MaintenanceWorkApi, ModelMatchesApi} from '../../../lib/api';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';

const SpoilerContent = styled.div`
  padding: 16px;
`;

type Props = {
  filter: any,
  cleanFilter: Function,
  applyFilter: Function
};

const fetch = async (params, key) => {
  const result = await MaintenanceWorkApi.fetch({...params});
  const filteredData = new Set();
  result.data.forEach(item => filteredData.add(item[key]));
  return {totalCount: result.totalCount, data: [...filteredData.values()]};
}

export const MaintenanceWorkFilter = ({
  filter,
  cleanFilter,
  applyFilter,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values.code}
                  onChange={newValue => changeValue('code', newValue)}
                  placeholder="Код работы"
                  notFoundText='Код не найден'
                  size='small'
                  fetch={params => fetch(params, 'code')}
                  fetchSingle={MaintenanceWorkApi.get}
                  renderOption={(code, Option) => <Option key={code} value={code}>{code}</Option>}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values.name}
                  onChange={newValue => changeValue('name', newValue)}
                  size='small'
                  fetch={params => fetch(params, 'name')}
                  fetchSingle={MaintenanceWorkApi.get}
                  placeholder="Наименование работы"
                  notFoundText='Работы не найдены'
                  renderOption={(name, Option) => <Option key={name} value={name}>{name}</Option>}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values['vehicleModelMatchId']}
                  onChange={newValue => changeValue('vehicleModelMatchId', newValue)}
                  placeholder="Модель"
                  notFoundText='Модели не найдены'
                  size='small'
                  fetch={ModelMatchesApi.fetch}
                  fetchSingle={ModelMatchesApi.get}
                  renderOption={(model, Option) => (
                    <Option key={model.id} value={model.id}>{`${model.brandName} ${model.modelName}`}</Option>
                  )}
                />
              </Field>
            </FilterItem>
            
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MaintenanceWorkFilter;
