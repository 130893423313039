export const operatingModeEnum = {
  h24: 'h24',
  h12: 'h12',
  h8: 'h8',
  passenger: 'passenger',
  oneOffRequests: 'oneOffRequests',
};

export const operatingModes = {
  passenger: 'Легковой',
  oneOffRequests: 'Разовая заявка',
  h24: 'ОВБ 24 ч',
  h12: 'ОВБ / Бригадный 12 ч',
  h8: 'ОВБ / Бригадный 8 ч',
};
