import styled, {css} from 'styled-components';
import Badge from '../Badge';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const TabItems = styled.div`
  display: flex;
  ${props => css`
    background: ${props.background || 'white'};
  `};
  ${props =>
          props.type === 'buttons' &&
          css`
            margin: 0 -4px;
          `};
`;

export const TabContent = styled.div`
  ${props =>
          !props.active &&
          css`
            display: none;
          `};
`;

export const StyledBadge = styled(Badge)`
  margin-left: 5px;
`;

export const StyledTabItem = styled.div.attrs({
  className: 'tabs__item',
})`
  ${props =>
          props.type === 'primary' &&
          css`
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            padding: 10px 0 5px 0;
            margin: 0 16px;
            cursor: pointer;
            border-bottom: 8px solid transparent;
            transition: border-color 0.15s;
            ${props => props.active && css`border-color: #2770ff;`};
            ${props => props.disabled && css`
              color: #808080;
              border-color: rgba(255, 255, 255, 0);
              cursor: progress;
            `};
          `};
  ${props =>
          props.type === 'buttons' &&
          css`
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            margin: 8px 4px;
            border-radius: 2px;
            padding: 5px 8px;
            background: white;
            cursor: pointer;
            ${props =>
                    props.active &&
                    css`
                      background: #7eaaff;
                      color: white;
                    `};
          `};
`;
