// @flow
import React, { Component } from 'react';
import { inspectionGibddCalculationApi } from '../../../../lib/api';
import { printNotification } from '../../../../lib/notificationWrapper';
import Common from './components/Common';
import { COLUMNS, MONTH } from '../lib';
import { toLocalStringRu } from '../../../../lib/helpers';
import type { InspectionGibddCalculation } from '../../../../lib/types';
import { VehiclePlan } from '../../../../lib/types';
import { calculationStatusEnum } from '../../../../lib/enum';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';
import moment from 'moment/moment';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = {
  monthData: ?InspectionGibddCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

export default class extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };
  
  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }

  columns = [
    ...COLUMNS,
    ...MONTH.map((month, index) => ({
      title: month.title,
      width: '150',
      dataIndex: `months[${index}].cost`,
      render: (cost: number) =>
        toLocalStringRu(cost, { minimumFractionDigits: 2 }),
      onCell: monthData => this.onCell(monthData, index)
    })),
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, { minimumFractionDigits: 2 })
    }
  ];

  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null
    });
  };

  onCell = (monthData, monthIndex) => ({
    onClick: () => {
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (monthData.id && vehiclePlan?.inspectionGibddCalculationStatus === calculationStatusEnum.draft &&
          openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({ monthData, monthIndex });
      }
    }
  });

  render() {
    const { location, vehiclePlanId } = this.props;
    const { monthData, monthIndex } = this.state;
    return (
      <Common
        columns={this.columns}
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={inspectionGibddCalculationApi}
        calcScrollY={430}
        statusField={'inspectionGibddCalculationStatus'}
        title={'Планирование ТО ГИБДД'}
        handlePrint={filter => {
          printNotification(async () => {
            await inspectionGibddCalculationApi.print(vehiclePlanId, filter);
          });
        }}
        editingModalData={{
          monthData,
          monthIndex,
          handleCancel: this.handleCancel,
          setVehiclePlan: vehiclePlan => this.setState({ vehiclePlan })
        }}
        linksToDictionaries={{
          'Стоимость ТО ГИБДД': '/admin/inspection-gibdd-cost/',
          'Тех. осмотры': '/services/inspections-gibdd/'
        }}
        additionalText={' (необходимо в ИК СТС проверить отметку о включении ТС в бюджет ТО ГИБДД)'}
      />
    );
  }
}
