// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {fetchRequest} from '../../lib/api';

const entryPointPath = '/admin/planned-fuel-consumption';

const List = () => {
  const columns = [
    {
      title: 'Год бюджета',
      dataIndex: 'startDate',
      render: date => new Date(date).getFullYear(),
      width: 80
    },
    {
      title: 'Версия бюджета',
      dataIndex: 'budgetVersion.name'
    }
  ];

  return (
    <Page>
      <CommonListPage
        crud={{ fetch: () => fetchRequest.get('/PlannedFuelConsumption') }}
        pageHeaderProps={{
          mainHeader: 'Плановый расход ГСМ'
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}/${id}`),
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default React.memo(List);
