// @flow
import type { Place } from './../types';
import CommonApi from './../commonApi';
import { fetchRequest } from './index';

let basedUrl = 'place';

const place = new CommonApi<Place>(basedUrl, 'Местоположения');

const updateParus = async (): Promise<any> => {
  const updated = await fetchRequest.get(`/${basedUrl}/parus/update`);
  if (updated) return updated;
  throw new Error(`Не удалось обновить`);
};

export default {
  ...place,
  updateParus
};
