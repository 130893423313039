//@flow
import React, {useCallback, useEffect, useState} from 'react';
import {FilterItem} from '../../../../components';
import Field from '../../../../components/card/Field';
import {CommonFilter, type FilterProps} from '../../../../components/hoc/common/handbook/CommonFilter';
import SelectsList from '../../../../components/selects';
import {budgetSummaryStatus, budgetSummaryStatusEnum} from '../../../../lib/enum';
import Select from 'antd/lib/select';
import {budgetVersionApi} from '../../../../lib/api';
import moment from 'moment';
import {formatDateTimeToISOString} from '../../../../lib/helpers';
import Datepicker from 'antd/lib/date-picker';

const {YearSelect} = SelectsList;
const {Option} = Select;
const {RangePicker} = Datepicker

type BudgetSummaryParamsFilter = {
  year?: number
};

export const BudgetSummaryFilter = (props: FilterProps<BudgetSummaryParamsFilter>) => {
  const [budgetVersions, setBudgetVersions] = useState([]);
  
  const fetchBudgetVersions = async () => {
    const {data} = await budgetVersionApi.fetch();
    const versions = data.map(item => ({
      name: item.name,
      id: String(item.id),
    }));
    setBudgetVersions(versions);
  }
  
  useEffect(() => {
    fetchBudgetVersions();
  }, []);

  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<BudgetSummaryParamsFilter>>) =>
      CommonFilter<BudgetSummaryParamsFilter>({...filterProps, ...props}),
    [props],
  );

  return (
    <Filter>
      {(values, changeValue) => {
        return (
          <>
            <FilterItem>
              <Field mBottomNone>
                <YearSelect
                  size="small"
                  onChange={value => changeValue('year', value)}
                  value={values.year}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                  placeholder='Выберите статус'
                >
                  {Object.keys(budgetSummaryStatus).map(key => {
                    if (key !== budgetSummaryStatusEnum.approvement) {
                      return (
                        <Option key={key} value={key}>{budgetSummaryStatus[key]}</Option>
                      );
                    }
                    return null;
                  })}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  size="small"
                  onChange={value => changeValue('budgetVersionId', value)}
                  value={values['budgetVersionId']}
                  placeholder='Выберите версию бюджета'
                >
                  {budgetVersions.map(item => (
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
          </>
        );
      }}
    </Filter>
  );
};
export default BudgetSummaryFilter;
