// @flow
import React from 'react';
import {SelectProps} from 'antd/lib/select';
import {journalApi} from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';

// Выпадающий список типов сообщение
export default (props: SelectProps) => (
  <AutocompleteSelect
    fetch={async params => {
      const {data} = await journalApi.getJournalTypes(params);
      // манипуляции с Set необходимы для создания массива с уникальными значениями. с бека приходят дублирующиеся
      // значения, что приводит к некорректной работе селекта.
      const filteredData = [...new Set(data).values()];
      return {
        data: filteredData,
        totalCount: filteredData.length,
      }
    }}
    fetchSingle={() => {}}
    notFoundText="Типы событий не найдены"
    placeholder="Выберите тип события"
    renderOption={(journal: any, Option) => (
      <Option value={journal} key={journal}>
        {journal}
      </Option>
    )}
    {...props}
  />
);
