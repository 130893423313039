// @flow
import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import Button from 'antd/lib/button';
import type {FormFieldType} from '../../../components/Form';
import Form from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import type {MaintenanceWork, ModelMatches} from '../../../lib/types';
import Select from 'antd/lib/select';
import {MaintenanceTypeCodes, MaintenanceTypes} from '../../../lib/types/maintenanceWork';
import InputNumber from 'antd/lib/input-number';
import {newEntityStatuses} from '../../../lib/enum';
import type {RepairKind} from '../../../lib/types/typesOfRepairs';
import Input from 'antd/lib/input';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const {Option} = Select;

type Props = {
  maintenanceWork: ?MaintenanceWork,
  onCancel: Function,
  onSubmit: Function,
  modelMatches: ModelMatches[],
  typesOfRepairs: RepairKind[],
};

export default ({maintenanceWork, onCancel, onSubmit, modelMatches}: Props) => {
  
  return (
    <Form initialValues={maintenanceWork} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          handleSubmit,
          values,
          dirty,
          isSubmitting,
          setFieldValue,
        }: FormikProps,
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Вид ТО" name="maintenanceType" required hasFeedback={false}>
                  {({name, value}) => (
                    <Select
                      value={value}
                      onChange={newValue => {
                        setFieldValue(name, newValue);
                        setFieldValue('maintenanceTypeCode', MaintenanceTypeCodes[newValue]);
                        setFieldValue('code', `РА1-1-${MaintenanceTypeCodes[newValue] || '-'}-${values.number || ''}`);
                      }}
                    >
                      {Object.keys(MaintenanceTypes).map(key => (
                        <Option key={key} value={key}>{MaintenanceTypes[key]}</Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Код вида ТО" name="maintenanceTypeCode">
                  {({value}) => <p style={{margin: '6px 0 0'}}>{value}</p>}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Наименование работы" name="name" required hasFeedback={false}>
                  {({value, name}) => (
                    <Input value={value} onChange={({target}) => setFieldValue(name, target.value)} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Код работы" name="code">
                  {({value}) => <p style={{margin: '6px 0 0'}}>{value}</p>}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Марка и модель" name="vehicleModelMatchId" required hasFeedback={false}>
                  {({value, name}) => (
                    <Select
                      value={value}
                      onChange={newValue => {
                        setFieldValue(name, newValue);
                        if (values.number) {
                          setFieldValue('number', null);
                          setFieldValue('code', `РА1-${values.maintenanceTypeCode}-`);
                        }
                      }}
                    >
                      {modelMatches.map(item => (
                        <Option key={item.id} value={item.id}>{`${item.brandName} ${item.modelName}`}</Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Номер работы" name="number">
                  {({value}) => value || '-'}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма-часы" name="hoursNorm" required hasFeedback={false}>
                  {({value, name}) => (
                    <InputNumber
                      min={0}
                      value={value}
                      onChange={newValue => setFieldValue(name, newValue)}
                    />
                  )}
                </FormField>
              </GridItem>
              {values.status && (
                <GridItem>
                  <FormField label="Статус" name="status">
                    {({value}) => <p style={{margin: '6px 0 0'}}>{newEntityStatuses[value]}</p>}
                  </FormField>
                </GridItem>
              )}
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
}
