// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {maintenanceOperationApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';

import {Card, DeclinePopupButton} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {Button} from 'antd';
import type {MaintenanceOperation, UserAccess} from '../../lib/types';
import {accessTypeEnum, entityStatusEnum, newEntityStatuses} from '../../lib/enum';
import {notificationLoading} from '../../components/Notifications';
import {deleteMaintenanceOperation} from '../../lib/api/maintenanceOperation';
import {withUserAccess} from '../withUserAccess';
import {WorkSubTypes, WorkTypes, WorkTypesEnum} from '../../lib/types/maintenanceOperation';
import Popconfirm from 'antd/lib/popconfirm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const SectionContent = styled.div`
  padding: 16px;
`;

const ControlButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const {Field} = Card;

type Props = {
  maintenanceOperationId: string,
  userAccess: UserAccess[],
};

type State = {
  operation: ?MaintenanceOperation,
};

class MaintenanceOperationCard extends Component<Props, State> {
  state = {
    operation: {},
  };
  
  canEdit = () =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.handlingMaintenanceWork,
      accessTypeEnum.changeStatusMaintenanceWork,
    ].some(access => this.props.userAccess.includes(access));
  
  canApprove = () =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.changeStatusMaintenanceWork,
    ].some(access => this.props.userAccess.includes(access));
  
  changeStatus = async (status, declineReason) => {
    try {
      notificationLoading({
        message: 'Изменение статуса...',
        key: 'changeStatus',
      });
      await maintenanceOperationApi.changeMaintenanceOperationStatus(
        this.props.maintenanceOperationId,
        status,
        declineReason,
      );
      notification.success({
        message: 'Статус изменен',
      });
      await this.fetch();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
    finally {
      notification.close('changeStatus');
    }
  };
  
  componentDidMount() {
    this.fetch();
  }
  
  fetch = async () => {
    const {maintenanceOperationId} = this.props;
    try {
      const operation = await maintenanceOperationApi.fetchMaintenanceOperation(maintenanceOperationId);
      this.setState({operation});
    } catch (err) {
      notification.error({
        message: 'Ошибка загрузки работы',
        description: err.message,
      });
      await navigate('/admin/maintenance-operations/', true);
    }
  };
  
  deleteRecord = async () => {
    try {
      notificationLoading({message: 'Удаление записи', key: 'deleting'});
      await deleteMaintenanceOperation(this.props.maintenanceOperationId);
      navigate('/admin/maintenance-operations');
      notification.success({message: 'Запись удалена'});
    } catch (e) {
      notification.error({message: 'Ошибка удаления', description: e.title || e.message});
    }
    finally {
      notification.close('deleting');
    }
  };
  
  render() {
    const {operation} = this.state;
    const {maintenanceOperationId} = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/maintenance-operations/')}>
                Работы по ремонту ТС
              </Crumb>
              <Crumb>Работа №{operation.id || ''}</Crumb>
            </Breadcrumbs>
          }
          right={(
            <ControlButtonsBlock>
              {this.canEdit() && operation.status === entityStatusEnum.created && operation.id && (
                <Button
                  type="primary"
                  onClick={() => this.changeStatus(entityStatusEnum.approvement)}
                  style={{marginRight: '10px'}}
                >
                  Отправить на утверждение
                </Button>
              )}
              {this.canEdit() && operation.status !== entityStatusEnum.approvement && (
                <>
                  <Button
                    type="primary"
                    onClick={() => navigate(`/admin/maintenance-operations/${maintenanceOperationId}/edit`)}
                    style={{marginRight: '10px'}}
                  >
                    Редактировать
                  </Button>
                  <Popconfirm
                    title="Вы уверены, что хотите удалить запись?"
                    onConfirm={this.deleteRecord}
                  >
                    <Button>Удалить</Button>
                  </Popconfirm>
                </>
              )
              }
              {this.canApprove() && operation.status === entityStatusEnum.approvement && (
                <>
                  <Button
                    type="primary"
                    onClick={() => this.changeStatus(entityStatusEnum.approved)}
                    style={{marginRight: '10px'}}
                  >
                    Утвердить
                  </Button>
                  <DeclinePopupButton onConfirm={(reason) => this.changeStatus(entityStatusEnum.declined, reason)} />
                </>
              )}
            </ControlButtonsBlock>
          )}
        />
        <StyledPanel>
          <h1>{`Работа №${operation.id || ''}`}</h1>
        </StyledPanel>
        <Section>
          {operation.id && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Вид ремонта">{operation.repairKind?.name || '-'}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Код вида ремонта">{operation.repairKind?.code || '-'}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Тип работ">{WorkTypes[operation.workType]}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Код типа работ">{operation.workTypeCode}</Field>
                </GridItem>
                {operation.workType === WorkTypesEnum.workshop && (
                  <>
                    <GridItem>
                      <Field label="Подтип работ">{WorkSubTypes[operation.workSubType]}</Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Код подтипа работ">{operation.workSubTypeCode}</Field>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <Field label="Наименование работы">{operation.name}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Номер работы">{operation.number}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Единица оборудования">{operation.unit}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Код работы">{operation.code}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Марка и модель">
                    {!!operation.vehicleModelMatch
                      ? `${operation.vehicleModelMatch.brandName} ${operation.vehicleModelMatch.modelName}`
                      : '-'
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Норма-часы">{operation.hours}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Статус">{newEntityStatuses[operation.status]}</Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceOperationCard);
