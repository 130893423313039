// @flow
import React from 'react';
import {Button} from 'antd';
import notification from 'antd/lib/notification';

import {contractApi, stockPartApi, vehicleApi} from '../../lib/api';

import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, {GridItem} from '../../components/layout/Grid';
import Field from '../../components/card/Field';

export default () => {
  
  const fetchWrapper = async (fetcher: () => any) => {
    try {
      const data = await fetcher();
      notification.info({message: data.status});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };
  
  const fetchContracts = async () => {
    return await fetchWrapper(contractApi.subscribeParusContracts);
  };
  
  const fetchAccounts = async () => {
    return await fetchWrapper(contractApi.subscribeParusAccounts);
  };
  
  const fetchStockParts = async () => {
    return await fetchWrapper(stockPartApi.subscribeParusStockParts);
  };
  
  const fetchInventoryCards = async () => {
    return await fetchWrapper(vehicleApi.subscribeParusInventoryCards);
  };
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        mainHeader: 'Подписки',
      }}
      onFetch={() => {}}
    >
      <>
        <Grid gutter="16px" cols={4}>
          <GridItem>
            <Field label="Договоры">
              <Button onClick={fetchContracts}>
                Подписаться на обновления
              </Button>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Лицевой счет">
              <Button onClick={fetchAccounts}>Подписаться на обновления</Button>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Склады">
              <Button onClick={fetchStockParts}>
                Подписаться на обновления
              </Button>
            </Field>
          </GridItem>
          <GridItem>
            <Field label="Инвентарные карточки ТС">
              <Button onClick={fetchInventoryCards}>
                Подписаться на обновления
              </Button>
            </Field>
          </GridItem>
        </Grid>
      </>
    </CommonCardPage>
  );
};
