// @flow
import React, { type Element } from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import type {
  OperationLimitGroup,
  OperationLimitType,
  UserAccess
} from '../../../lib/types';
import ListTable from '../../../components/ui/ListTable';
import {
  accessTypeEnum,
  operationLimitGroupStatusEnum,
  operationLimitTypeEnum
} from '../../../lib/enum';
import Spinner from '../../../components/Spinner';
import {
  EmployeeHeader,
  ListTableOvbHeader,
  OrgUnitHeader
} from './components/elements';
import { operationLimitApi, operationLimitGroupApi } from '../../../lib/api';
import AssignmentGroupRow from './components/AssignmentGroupRow';
import { notificationLoading } from './../../../components/Notifications';
import type { OperationLimitGroupStatus } from '../../../lib/types/operationLimitGroup';
import { Buttons } from './components/elements';
import { getTimeLimitStatus } from '../lib';
import Popover from '../../../components/ui/Popover';
import Icon from 'antd/lib/icon';

type Props = {
  type: OperationLimitType,
  // Группы лимитов
  groups: OperationLimitGroup[],
  // Доступы
  userAccess: UserAccess[],
  // Функция изменения значения
  onChangeGroups: (groups: OperationLimitGroup[]) => void,
  // Флаг, указывающий процесс загрузки
  loading: boolean
};

/**
 * Компонент отрисовки групп лимитов для закрепления
 */
export default class AssignmentGroups extends React.Component<Props> {
  handleChange = (
    index: number,
    name: $Keys<OperationLimitGroup>,
    value: any
  ) => {
    const groups = [...this.props.groups];
    groups[index][name] = value;
    this.props.onChangeGroups(groups);
  };

  handleSaveGroup = async (group: OperationLimitGroup, index: number) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const groups = [...this.props.groups];
      group = {
        ...group,
        operationLimits: await Promise.all(
          group.operationLimits.map(
            async limit => await operationLimitApi.updateOperationLimit(limit)
          )
        )
      };
      groups.splice(index, 1, group);
      this.props.onChangeGroups(groups);
      await this.changeGroupsStatus(operationLimitGroupStatusEnum.draft);
      notification.success({
        message: 'Лимиты были успешно зафиксированы'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  changeGroupsStatus = async (status: OperationLimitGroupStatus) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const updatedGroups = this.props.groups.map(group => ({
        ...group,
        assignmentLimitStatus: status
      }));
      await Promise.all(
        updatedGroups.map(async group =>
          operationLimitGroupApi.updateOperationLimitGroup(group)
        )
      );
      this.props.onChangeGroups(updatedGroups);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  /**
   * Утверждение
   */
  handleApprove = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.approved);
    notification.success({
      message: `Закрепление ${this.getTypeForNotification()} было утверждено`
    });
  };

  /**
   * Отправка на согласование
   */
  handleSendToAgreeing = async () => {
    await this.changeGroupsStatus(operationLimitGroupStatusEnum.onAgreeing);
    notification.success({
      message: `Закрепление ${this.getTypeForNotification()} было отправлено на согласование`
    });
  };

  renderHeader = (type: OperationLimitType) => {
    switch (type) {
      case operationLimitTypeEnum.employee:
        return <EmployeeHeader />;
      case operationLimitTypeEnum.orgUnit:
        return <OrgUnitHeader />;
      case operationLimitTypeEnum.ovb:
      default:
        return <ListTableOvbHeader />;
    }
  };

  getTypeForNotification = () => {
    switch (this.props.type) {
      case 'employee':
        return 'должностных групп';
      case 'orgUnit':
        return 'служб';
      case 'ovb':
      default:
        return 'ОВБ';
    }
  };

  getFieldForValidating = () => {
    switch (this.props.type) {
      case operationLimitTypeEnum.employee:
        return ['contractVehicleId', 'employeeId'];
      case operationLimitTypeEnum.orgUnit:
      case operationLimitTypeEnum.ovb:
      default:
        return ['contractVehicleId'];
    }
  };

  hasGroupsEmptyFields = () => {
    const { groups } = this.props;
    const fields = this.getFieldForValidating();
    return groups.some(group => {
      return (
        isEmpty(group.operationLimits) ||
        group.operationLimits.some(limit =>
          fields.some(field => {
            return isNil(limit[field]);
          })
        )
      );
    });
  };

  canHandling = () => {
    const timeLimitStatus = getTimeLimitStatus(
      this.props.groups,
      'timeLimitStatus'
    );
    return (
      timeLimitStatus === operationLimitGroupStatusEnum.approved &&
      this.props.userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.viewingLimitAssignment,
          accessTypeEnum.viewingAllLimitAssignment
        ].includes(access)
      )
    );
  };

  canApprove = () => {
    const timeLimitStatus = getTimeLimitStatus(
      this.props.groups,
      'timeLimitStatus'
    );
    const status = getTimeLimitStatus(
      this.props.groups,
      'assignmentLimitStatus'
    );
    return (
      timeLimitStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.onAgreeing &&
      this.props.userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.approvingLimitAssignment
        ].includes(access)
      )
    );
  };

  canSendToAgreeing = () => {
    const timeLimitStatus = getTimeLimitStatus(
      this.props.groups,
      'timeLimitStatus'
    );
    const status = getTimeLimitStatus(
      this.props.groups,
      'assignmentLimitStatus'
    );
    return (
      timeLimitStatus === operationLimitGroupStatusEnum.approved &&
      status === operationLimitGroupStatusEnum.draft &&
      this.canHandling()
    );
  };

  wrapButtonWithWarning = (button: Element<any>) => {
    const hasGroupsEmptyFields = this.hasGroupsEmptyFields();
    return hasGroupsEmptyFields ? (
      <Popover
        title="Внимание!"
        width={160}
        content="Имеются пустые группы лимитов"
      >
        {React.cloneElement(button, {
          disabled: true,
          children: (
            <>
              <Icon type="warning" style={{ marginRight: 8 }} />
              {button.props.children}
            </>
          )
        })}
      </Popover>
    ) : (
      button
    );
  };

  render() {
    const { groups, loading, type } = this.props;

    const canHandling = this.canHandling();
    const canSendToAgreeing = this.canSendToAgreeing();
    const canApprove = this.canApprove();
    const showFooter = canHandling || canSendToAgreeing || canApprove;

    return (
      <Spinner isLoading={loading}>
        <ListTable
          style={{ overflowX: 'auto' }}
          data={groups}
          header={this.renderHeader(type)}
          renderRow={(group: OperationLimitGroup, index: number) => (
            <AssignmentGroupRow
              group={group}
              onSave={this.handleSaveGroup}
              editable={canHandling}
              onChange={this.handleChange}
              index={index}
              key={index}
            />
          )}
        />
        {showFooter && (
          <Buttons>
            {canSendToAgreeing &&
              this.wrapButtonWithWarning(
                <Button
                  size="small"
                  onClick={this.handleSendToAgreeing}
                  type="primary"
                >
                  На утверждение
                </Button>
              )}
            {canApprove &&
              this.wrapButtonWithWarning(
                <Button
                  size="small"
                  type="primary"
                  onClick={this.handleApprove}
                >
                  Утвердить
                </Button>
              )}
          </Buttons>
        )}
      </Spinner>
    );
  }
}
