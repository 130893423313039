// @flow

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import Popconfirm from 'antd/lib/popconfirm';
import { Link } from '@reach/router';

import { navigate } from '../../lib/helpers';
import type { Role, UserAccess } from './../../lib/types';
import { accessTypes } from './../../lib/enum';
import { fetchRoles } from '../../ducks/roles';
import { deleteRole } from '../../ducks/role';
import type { AppState } from '../../ducks/redux';
import { Icon, Operations, Table } from './../../components/ui';
import { Section } from './../../components/layout';
import Header from '../../components/layout/Header';
import type { sortOrder } from '../../components/ui/Table';
import { notificationLoading } from './../../components/Notifications';

type Props = {
  roles: Array<Role>,
  fetchRoles: Function,
  deleteRole: Function,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } }
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export class RoleList extends PureComponent<Props> {
  static defaultProps = {
    location: {}
  };

  fetchRoles = async (page: number, orderBy?: string, order?: sortOrder) =>
    await this.props.fetchRoles(page, {
      orderBy,
      byDescending: order === 'descend'
    });

  async deleteRole(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await this.props.deleteRole(id);
      await this.fetchRoles(this.props.page);
      notification.success({
        message: 'Успешное удаление',
        description: `Роль с идентификатором ${id} была успешно удалена`
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при удалении',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  columns = [
    {
      title: '№',
      dataIndex: 'id',
      sorter: true,
      width: 50
    },
    {
      title: 'Наименование',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Доступы',
      dataIndex: 'access',
      render: (access: UserAccess[]) =>
        access.map(accessItem => accessTypes[accessItem]).join(', ')
    },
    {
      title: '',
      dataIndex: 'operations',
      width: 50,
      // eslint-disable-next-line no-unused-vars
      render: (text: string, role: Role) => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={() => this.deleteRole(role.id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      )
    }
  ];

  handleRowClick = (id: number) => navigate(`/admin/roles/${id}`);

  render() {
    const { roles, totalCount, pageSize, page, location } = this.props;
    return (
      <>
        <Header
          left={<h1>Роли</h1>}
          right={
            <Link to="/admin/roles/new">
              <Button type="primary" data-cy="addRole">
                Создать
              </Button>
            </Link>
          }
        />
        <Section>
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.props.fetchRoles}
            fetchOnMount
            columns={this.columns}
            data={roles}
            rowKey="id"
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    roles: state.roles.data,
    totalCount: state.roles.totalCount,
    pageSize: state.roles.pageSize,
    page: state.roles.page
  }),
  {
    fetchRoles,
    deleteRole
  }
)(RoleList);
