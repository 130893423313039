// @flow

import React, { Component } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { Link } from '@reach/router';
import styled from 'styled-components';
import qs from 'query-string';

import type { Employee, ListState, User } from '../../lib/types';
import {
  ButtonOperations,
  Icon,
  Operations,
  Table
} from './../../components/ui';
import { Section } from './../../components/layout';
import type { UsersFilterParams } from './Filter';
import Filter from './Filter';
import Header from '../../components/layout/Header';
import {
  convertEmployeeToString,
  getListInitialState,
  navigate,
  setQueryParams
} from '../../lib/helpers';
import type { FetchListParams } from '../../lib/api';
import { userApi } from '../../lib/api';
import { notificationLoading } from '../../components/Notifications';

const StyledDiv = styled.div`
  white-space: break-spaces;
`;

type Props = {
  location: Location & { state: { page: number } }
};

type State = ListState<User> & {
  filter: UsersFilterParams
};

class UserList extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };
  state = {
    ...getListInitialState(),
    filter: {}
  };
  columns: Array<Object> = [
    {
      title: 'Логин',
      dataIndex: 'userName',
      className: 'vertical-align-top',
      sorter: true
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      width: 150,
      className: 'white-space-break',
      render: roles => (
        <StyledDiv>{roles.map(role => role.roleName).join(', ')}</StyledDiv>
      )
    },
    {
      title: 'ФИО',
      dataIndex: 'employee',
      sorter: true,
      className: 'vertical-align-top',
      sorterKey: 'employee.person.lastName',
      render: (employee: Employee) => convertEmployeeToString(employee)
    },
    {
      title: 'Подразделение',
      dataIndex: 'employee.orgUnitName',
      className: 'vertical-align-top',
      sorter: true,
      sorterKey: 'employee.node.name'
    },
    {
      title: ' ',
      className: 'vertical-align-top',
      dataIndex: 'id',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: User): any => (
        <Operations>
          <Popconfirm
            title="Вы действительно хотите удалить?"
            okText="Да"
            cancelText="Нет"
            onConfirm={async () => await this.deleteUser(record.id)}
          >
            <StyledIcon type="x" />
          </Popconfirm>
        </Operations>
      )
    }
  ];
  async deleteUser(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await userApi.deleteUser(id);
      await this.fetchUsers(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }
  handleRowClick = (id: number) => navigate(`/admin/users/${id}`);

  fetchUsers = async (
    page: number = 1,
    params: FetchListParams<UsersFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state;
      this.setState({ loading: true });
      const { data, totalCount } = await userApi.fetchUsers({
        ...filter,
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ loading: false, data, totalCount, page });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message
      });
    }
  };

  cleanFilter = () => this.setState({ filter: {} }, this.fetchUsers);

  applyFilter = (filter: UsersFilterParams) =>
    this.setState({ filter }, this.fetchUsers);

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState({ filter }, () => this.fetchUsers(page));
  }

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print'
      });
      await userApi.print();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, filter, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Пользователи</h1>}
          right={
            <ButtonOperations>
              <Button onClick={this.handlePrint}>Экспорт</Button>
              <Link to="/admin/users/new">
                <Button type="primary" data-cy="addUser">
                  Создать
                </Button>
              </Link>
            </ButtonOperations>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchUsers}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            data={data}
          />
        </Section>
      </>
    );
  }
}

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export default UserList;
