// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { branchBudgetSummaryLineItemApi } from '../../lib/api';
import type { BranchBudgetSummaryLineItem } from '../../lib/types';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any, optionKey?: string };

export default class extends React.Component<Props> {
  render() {
    const { filter, optionKey, ...selectProps } = this.props;
    return (
      <AutocompleteSelect
        placeholder="Выберите статью бюджетной формы"
        fetch={async params => {
          return await branchBudgetSummaryLineItemApi.fetch({
            ...filter,
            ...params
          });
        }}
        refetchParams={{ filter }}
        fetchSingle={branchBudgetSummaryLineItemApi.get}
        notFoundText="Наименование не найдено"
        renderOption={(
          branchBudgetSummaryLineItem: BranchBudgetSummaryLineItem,
          Option
        ) => (
          <Option
            key={`${optionKey}${branchBudgetSummaryLineItem.id}`}
            value={branchBudgetSummaryLineItem.id}
            branchBudgetSummaryLineItem={branchBudgetSummaryLineItem}
          >
            {branchBudgetSummaryLineItem.budgetLineItemName}
          </Option>
        )}
        {...selectProps}
      />
    );
  }
}
