// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import Button from 'antd/lib/button';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import InputNumber from 'antd/lib/input-number';
import sumBy from 'lodash/sumBy';
import Menu from 'antd/lib/menu';

import {Card, TripMap, WaypointsForm, WaypointsViewer} from './../../../components';
import type {Trip, TripRange, TripStatus, UserAccess, WayPoint} from '../../../lib/types';
import {DriverQualificationDocument} from '../../../lib/types';
import {selectTrip} from '../../../ducks/selectors';
import {changeStatus, cleanTrip, fetchTrip, updateTrip} from '../../../ducks/trip';
import {
  accessTypeEnum,
  driverQualificationEnum,
  orderObjectives,
  ownerTypes,
  tripStatusEnum,
  tripStatuses,
  vehicleTypes,
} from '../../../lib/enum';
import {fetchRequest, fuelMultiplierApi, tripApi} from './../../../lib/api';
import type {AppState} from '../../../ducks/redux';
import {
  applyMaskToValue,
  calculateDateRangeString,
  convertEmployeeToString,
  convertFromMToKm,
  formatDateRangeString,
  formatDateTimeToString,
  getPathWithHistoryParams,
  navigate,
  validateWaypointsOrder,
  isVehicleTypeElectric
} from '../../../lib/helpers';
import {Divider, Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {directions} from '../../../lib/gis';
import {withUserAccess} from './../../withUserAccess';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {getMinMaxWaypointDateTime} from '../lib';
import {Dropdown} from './../../../components/ui';
import {notificationLoading} from '../../../components/Notifications';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';
import moment from 'moment';
import type {TripAttachedEquipment, TripHistoryItem} from '../../../lib/types/trip';
import {TripHistoryTypes} from '../../../lib/types/trip';
import HistorySection from '../components/HistorySection';
import {FormField} from '../../../components/Form';
import CommonForm from '../../../components/hoc/common/handbook/CommonForm';
import PopoverBlock from '../components/PopoverBlock';
import {WorkAccountingTypes} from '../../../lib/types/vehicleModel';
import EquipmentRow from '../components/EquipmentRow';
import {getVehicleConstMultipliers} from '../../Vehicles/lib';

const {Field} = Card;

const Operations = styled.div`
  display: flex;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
`;

const Content = styled.div`
  padding: 16px;
`;

export const StyledGridItem = styled(GridItem)`
  .field > .field__value {
    margin-bottom: 23px !important;
  }
`;

type Props = {
  trip: Trip,
  fetchTrip: Function,
  tripId: string,
  cleanTrip: () => void,
  updateTrip: Function,
  changeStatus: (tripId: number, status: TripStatus) => Promise<void>,
  userAccess: UserAccess[]
};

type State = {
  showSelectDriver: boolean,
  trip: Trip,
  touched: boolean,
  expectedRouteGeometry: any,
  loading: boolean,
  hasDriverError: boolean,
  tripEditLimitDate: number,
  editMode: boolean,
  editFormRef: any,
  multiplersValue: number,
};

export class TripCard extends Component<Props, State> {
  state = {
    showSelectDriver: false,
    trip: this.props.trip,
    touched: false,
    expectedRouteGeometry: null,
    loading: false,
    hasDriverError: false,
    tripEditLimitDate: null,
    editMode: false,
    editFormRef: React.createRef(),
    multiplersValue: 0,
  };

  async componentDidMount() {
    try {
      const {tripId} = this.props;
      await this.props.cleanTrip();
      if (tripId) {
        await this.props.fetchTrip(tripId);
        let vehicleFuelMultipliers = await fuelMultiplierApi.fetchVehicleFuelMultipliers(
          this.state.trip.vehicleId
        );
        vehicleFuelMultipliers = [
          ...vehicleFuelMultipliers,
          ...getVehicleConstMultipliers(this.state.trip.vehicle, this.state.trip.startDate)
        ];
        const multiplersValue = vehicleFuelMultipliers.reduce((sum, item) => sum + item.value, 0);
        this.setState({multiplersValue});
      }
      // Высчитываем дату, после которой редактирование ПЛ невозможно
      let taxingDate = this.props.trip.taxingDate?.split('-');
      if (taxingDate) {
        taxingDate[1] = parseInt(taxingDate[1], 10) + 1;
        await fetchRequest
        .get('/tripeditlimit')
        .then(data => (taxingDate[2] = data.dayOfMonth));
        this.setState({tripEditLimitDate: taxingDate.join('-')});
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.trip, this.props.trip)) {
      this.setState({
        trip: this.props.trip,
      });
    }
  }

  openVerification = () => navigate(`/trips/self/${this.props.tripId}/verification`);

  updateTrip = async (tripData = undefined) => {
    const trip = tripData ? tripData : this.state.trip;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      await this.props.updateTrip(trip);
      this.setState({
        touched: false,
      });
      notification.success({
        message: 'Успешно обновлено',
        description: 'Данные путевого листа были успешно обновлены',
      });
    } catch (err) {
      notification.error({
        message: err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  discardChanges = () => this.setState({
    trip: this.props.trip,
    touched: false,
  });

  changeTripWaypoints = (waypoints: WayPoint[]) => {
    this.setState((prevState: State) => ({
      trip: {
        ...prevState.trip,
        expectedRoute: {
          ...prevState.trip.expectedRoute,
          waypoints,
        },
      },
      touched: true,
    }));
  };

  changeTripInfo = (fieldName: string, value: any) => this.setState(prevState => ({
    trip: {
      ...prevState.trip,
      [fieldName]: value,
    },
    touched: true,
  }));

  changeExpectedRouteInfo = (fieldName: string, value: any) => this.setState(prevState => ({
    trip: {
      ...prevState.trip,
      expectedRoute: {
        ...prevState.trip.expectedRoute,
        [fieldName]: value,
      },
    },
    touched: true,
  }));

  // Подсчитываем расстояние маршрута + расход топлива по плану
  calculateRoute = async () => {
    try {
      notificationLoading({
        message: 'Построение маршрута...',
        key: 'buildingRoute',
      });
      const {trip} = this.state;
      const expectedWaypoints =
        (trip.expectedRoute && trip.expectedRoute.waypoints) || [];
      const expectedRouteGeometry = await directions(expectedWaypoints);
      if (this.canEditTrip() && expectedRouteGeometry) {
        const vehicleFuelMultipliers = await fuelMultiplierApi.fetchVehicleFuelMultipliers(
          trip.vehicleId,
        );
        const sumFuelMultipliers =
          1 + (sumBy(vehicleFuelMultipliers, 'value') || 0);
        const distance = parseFloat(
          convertFromMToKm(expectedRouteGeometry.distance)?.toFixed(2),
        );
        // Ожидаемый расход = пробег * расход л/1км * сумму коэф. ТС
        const expectedFuelConsumption = parseFloat(
          (
            distance *
            (trip?.vehicle?.vehicleModel.primaryFuelConsumption / 100) *
            sumFuelMultipliers
          )?.toFixed(2),
        );
        this.setState((prevState: State) => {
          const trip = prevState.trip;
          const distanceAtStart = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtStart),
          );
          const distanceAtEnd = parseFloat(
            convertFromMToKm(expectedRouteGeometry.distanceAtEnd),
          );
          return {
            trip: {
              ...trip,
              distanceAtStart,
              distanceAtEnd,
              expectedRoute: {
                ...trip.expectedRoute,
                distance,
              },
              expectedFuelConsumption,
              odometerAtEnd: trip.odometerAtStart + distance,
            },
          };
        }, this.updateTrip);
        notification.success({
          message: 'Маршрут успешно построен',
        });
      }
      this.setState({
        expectedRouteGeometry,
      });
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при построении маршрута',
        description: err ? err.message : '',
      });
    } finally {
      notification.close('buildingRoute');
    }
  };

  canHandleTrip = () => this.props.userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingTrip,
    ].includes(access),
  );

  addDropDownAction = () => {
    const {trip} = this.state;
    return (
      trip &&
      (trip.status === tripStatusEnum.draft ||
        trip.status === tripStatusEnum.created ||
        trip.status === tripStatusEnum.opened ||
        trip.status === tripStatusEnum.verification) &&
      this.canHandleTrip()
    );
  };

  canEditTrip = () => {
    const {trip} = this.state;
    return (
      trip &&
      (trip.status === tripStatusEnum.draft ||
        trip.status === tripStatusEnum.created) &&
      this.canHandleTrip()
    );
  };

  canVerify = () => {
    const {trip, userAccess} = this.props;
    if (trip && trip.vehicle) {
      if (trip.vehicle.isDeleted) {
        return false;
      }
    }
    return (
      trip &&
      (trip.status === tripStatusEnum.opened ||
        trip.status === tripStatusEnum.verification) &&
      userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.handlingTrip,
        ].includes(access),
      )
    );
  };

  canCreateAct = () => {
    const {trip, userAccess} = this.props;
    return (
      trip &&
      trip.status === tripStatusEnum.verified &&
      !!trip.toOtherSide &&
      userAccess.some(access =>
        [
          accessTypeEnum.admin,
          accessTypeEnum.adminBranch,
          accessTypeEnum.handlingTrip,
        ].includes(access),
      )
    );
  };

  createAct = () => {
    const {tripId} = this.props;
    navigate(`/admin/act/internal/outsourcing-act/new?tripId=${tripId}`);
  };

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await tripApi.printTrip(this.props.trip.id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  handleImprint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await tripApi.imprintTrip(this.props.trip.id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  getHeaderTitle = function(trip: Trip) {
    let title = `Рейс №${trip.id}`;
    if (trip.idNumber) {
      title = `Путевой лист №${trip.idNumber || ''}`;
    }
    if (trip.vehicle && trip.vehicle.ownerType === ownerTypes.contract) {
      return title;
    }
    return `${title} по заявке №${trip.ordersId.join(', ')}`;
  };

  changeStatus = async (tripId: number, status: TripStatus) => {
    this.setState({loading: true});
    try {
      notificationLoading({
        message: 'Смена статуса...',
        style: {zIndex: '99999'},
        key: 'changeStatus',
      });
      await this.props.changeStatus(tripId, status);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('changeStatus');
      this.setState({loading: false});
    }
  };

  calculateDistanceFuelConsumption = async (trip: Trip) => {
    if (trip.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.workHours) {
      return 0;
    }
    const distance = parseFloat(trip.odometerAtEnd - trip.odometerAtStart);
    if (!distance || distance < 0) {
      return 0;
    }
    const tripRanges = trip.tripRanges || [];
    // Переводим расход в л/км
    const primaryFuelConsumption =
      ((trip.vehicle && trip?.vehicle?.vehicleModel.primaryFuelConsumption) ||
        0) / 100;

    const sumFuelMultiplier = trip.sumFuelMultiplier
      ? 1 + trip.sumFuelMultiplier
      : 1 + this.state.multiplersValue;
    let fuelConsumption = primaryFuelConsumption * sumFuelMultiplier * distance;
    if (tripRanges.length) {
      tripRanges.forEach((tripRange: TripRange) => {
        fuelConsumption +=
          primaryFuelConsumption *
          tripRange.amount *
          tripRange.sumFuelMultiplier;
      });
      return fuelConsumption;
    }

    return fuelConsumption;
  };

  calculateWorkHoursFuelConsumption = async (trip: Trip) => {
    if (trip.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.kilometrage) {
      return 0;
    }
    const vehicleMachineHoursAtStart = parseFloat(trip.vehicleMachineHoursAtStart || 0);
    const vehicleMachineHoursAtEnd = parseFloat(trip.vehicleMachineHoursAtEnd || vehicleMachineHoursAtStart);
    const machineHoursFuelConsumption = parseFloat(trip?.vehicle?.vehicleModel.primaryEquipmentFuelConsumption || 0);
    const attachedEquipmentMachineHours = parseFloat(trip.attachedEquipmentMachineHours || 0);
    const attachedEquipmentFuelConsumption = parseFloat(trip.attachedEquipmentFuelConsumption || 0);
    const machineHours = vehicleMachineHoursAtEnd - vehicleMachineHoursAtStart;
    if (machineHours <= 0) {
      return 0;
    }
    const sumFuelMultiplier = trip.sumFuelMultiplier &&
    trip.vehicle.vehicleModel.workAccountingType === WorkAccountingTypes.workHours
        ? 1 + ( trip.sumFuelMultiplier || this.state.multiplersValue || 0)
        : 1;

    return (
      Math.round(
        (machineHoursFuelConsumption * machineHours * sumFuelMultiplier +
          attachedEquipmentMachineHours * attachedEquipmentFuelConsumption) *
        100,
      ) / 100
    );
  };

  calculateFuelConsumption = async (trip: Trip) => {
    if (isVehicleTypeElectric(trip?.vehicle?.vehicleModel?.type)) return trip;
    const engineWorkFuelConsumption = await this.calculateWorkHoursFuelConsumption(
      trip
    );
    const distanceFuelConsumption = await this.calculateDistanceFuelConsumption(trip);
    const totalFuelConsumpton =
      engineWorkFuelConsumption +
      distanceFuelConsumption +
      trip.tripAttachedEquipmentLinks.reduce(
        (sum, item) => sum + item.fuelConsumption,
        0,
      );
    return {
      ...trip,
      engineWorkFuelConsumption,
      distanceFuelConsumption,
      totalFuelConsumpton,
      fuelAtEnd: trip.fuelAtStart - totalFuelConsumpton + trip.issuedFuel,
    };
  };

  handleSubmitFormData = async data => {
    const newTrip = {...this.state.trip};
    let mustSend = false;
    if (data.kilometrageToTrip !== null) {
      newTrip.odometerAtEnd = newTrip.odometerAtStart + data.kilometrageToTrip;
      newTrip.currentOdometerAtEnd = newTrip.currentOdometerAtStart + data.kilometrageToTrip;
      mustSend = true;
    }
    if (data.workHoursToTrip !== null) {
      newTrip.vehicleMachineHoursAtEnd = newTrip.vehicleMachineHoursAtStart + data.workHoursToTrip;
      newTrip.currentVehicleMachineHoursAtEnd = newTrip.currentVehicleMachineHoursAtStart + data.workHoursToTrip;
      mustSend = true;
    }
    if (data.fuel !== null) {
      newTrip.issuedFuel = data.fuel;
      mustSend = true;
    }
    if (Object.keys(data.attachedEquipmentTime).length) {
      Object.keys(data.attachedEquipmentTime).forEach(id => {
        const editedLink = newTrip.tripAttachedEquipmentLinks.find(link => link.attachedEquipmentId === parseInt(id, 10));
        editedLink.machineHours = data.attachedEquipmentTime[id];
        editedLink.fuelConsumption = data.attachedEquipmentTime[id] * editedLink.attachedEquipment.fuelConsumption;
      });
      mustSend = true;
    }
    if (!mustSend) {
      this.setState({editMode: false});
      return;
    }
    this.updateTrip(await this.calculateFuelConsumption(newTrip));
    this.setState({ editMode: false });
  };

  render() {
    const {changeStatus, updateTrip, userAccess} = this.props;
    const {
      expectedRouteGeometry,
      trip,
      touched,
      loading,
      hasDriverError,
      tripEditLimitDate,
      editMode,
      editFormRef,
    } = this.state;
    if (!trip) {
      return null;
    }
    const expectedWaypoints =
      (trip.expectedRoute && trip.expectedRoute.waypoints) || [];
    const canEdit = this.canEditTrip();
    const canHandle = this.canHandleTrip();
    const canVerify = this.canVerify();
    const canCreateAct = this.canCreateAct();
    const addDropDownAction = this.addDropDownAction();
    const routeMinMaxDates = getMinMaxWaypointDateTime(expectedWaypoints);
    const filteredHistoryItems: TripHistoryItem[] = trip.history?.filter(item => item.type === TripHistoryTypes.recalculating);
    const formattedHistoryItems = [];
    if (filteredHistoryItems?.length) {
      filteredHistoryItems.forEach((item: TripHistoryItem) => {
        Object.keys(item.changes).forEach((key: string) => {
          formattedHistoryItems.push({
            id: item.id,
            employee: item.employee,
            created: item.created,
            name: key,
            prevValue: item.changes[key].oldValue,
          });
        });
      });
    }
    const drivingLicense = trip?.driver?.qualificationDocuments?.find(
      (doc: DriverQualificationDocument) => doc?.qualification === driverQualificationEnum.driverLicense
    );
    const tractorMachinistLicense = trip?.driver?.qualificationDocuments?.find(
      (doc: DriverQualificationDocument) => doc?.qualification === driverQualificationEnum.tractorMachinistLicense
    );
    const drivingLicenseIsEnd = !!drivingLicense && moment().isSameOrAfter(drivingLicense?.documentEndDate, 'day');
    const tractorMachinistLicenseIsEnd = !!tractorMachinistLicense && moment().isSameOrAfter(tractorMachinistLicense?.documentEndDate, 'day');
    const canEditTaxingTripByOneWorkDay = userAccess.includes(accessTypeEnum.editTaxingTripByOneWorkDay);
    const canEditTaxingTripByNextMonth = userAccess.includes(accessTypeEnum.editTaxingTripByNextMonth);
    const showEditButton = trip.status === tripStatusEnum.verified && (userAccess.includes(accessTypeEnum.admin) ||
        (!!tripEditLimitDate && !!trip.dateBeforeCanEdit && ((canEditTaxingTripByOneWorkDay &&
            !moment().startOf('day').isSameOrAfter(moment(trip.dateBeforeCanEdit).startOf('day'))) ||
            (canEditTaxingTripByNextMonth && !moment().isSameOrAfter(tripEditLimitDate)))));
    const editFormData = {
      kilometrageToTrip: null,
      workHoursToTrip: null,
      fuel: null,
      attachedEquipmentTime: {},
    };
    const workAccountingType = trip?.vehicle?.vehicleModel?.workAccountingType;

    const isElectricVehicle = isVehicleTypeElectric(trip?.vehicle?.vehicleModel?.type);

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/trips/self')}>
                Путевые листы собственных ТС
              </Crumb>
              <Crumb>{this.getHeaderTitle(trip)}</Crumb>
            </Breadcrumbs>
          }
          right={
            <Operations>
              {showEditButton && (
                <Button
                  type="primary"
                  onClick={() => {
                    this.setState({editMode: true});
                    function scrollToEditForm() {
                      if (editFormRef.current) {
                        editFormRef.current.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                        });
                      } else {
                        setTimeout(scrollToEditForm, 100);
                      }
                    }
                    scrollToEditForm();
                  }}
                >
                  Редактировать
                </Button>
              )}
              {trip.status !== tripStatusEnum.draft &&
                trip.status !== tripStatusEnum.canceled && (
                  <Button onClick={this.handlePrint}>Печать</Button>
                )}
              {trip.status === tripStatusEnum.verified && (
                <Button onClick={this.handleImprint}>Впечатать</Button>
              )}
              {canCreateAct && (
                <Button type="primary" onClick={this.createAct}>
                  Создать "Акт услуг на сторону"
                </Button>
              )}
              {(canVerify ||
                (canHandle &&
                  [tripStatusEnum.draft, tripStatusEnum.created].includes(
                    trip.status,
                  ))) && (
                <Button.Group>
                  {trip.status === tripStatusEnum.draft && canHandle && (
                    <Button
                      onClick={async () => {
                        this.setState({ hasDriverError: drivingLicenseIsEnd || tractorMachinistLicenseIsEnd});
                        if (drivingLicenseIsEnd || tractorMachinistLicenseIsEnd) {
                          notification.error({
                            message: `${drivingLicenseIsEnd ? 'Водительское удостоверение' : 'Удостоверение тракториста' +
                              ' машиниста'} недействительно`,
                            description:
                              `У выбранного водителя истек срок действия ${drivingLicenseIsEnd ? 'водительского' +
                              ' удостоверения' : 'удостоверения тракториста машиниста'}. Выберите другого водителя.`
                          });
                          return;
                        }
                        if (
                          !validateWaypointsOrder(trip.expectedRoute.waypoints)
                        ) {
                          try {
                            this.setState({loading: true});
                            await updateTrip(trip);
                            await changeStatus(trip.id, tripStatusEnum.created);
                          } catch (error) {
                            notification.error({
                              message: 'Ошибка',
                              description: error.message,
                            });
                          } finally {
                            this.setState({loading: false});
                          }
                        } else {
                          notification.error({
                            message: 'Произошла ошибка при обновлении',
                            description:
                              'Время прибытия в каждой точке должно быть больше времени отправления из предыдущей точки',
                          });
                        }
                      }}
                      disabled={loading}
                      loading={loading}
                      type="primary"
                      className="createButton"
                    >
                      Создать
                    </Button>
                  )}
                  {trip.status === tripStatusEnum.created && canHandle && (
                    <Button
                      className="startButton"
                      loading={loading}
                      disabled={loading}
                      onClick={async () => {
                        this.setState({ hasDriverError: drivingLicenseIsEnd || tractorMachinistLicenseIsEnd });
                        if (drivingLicenseIsEnd || tractorMachinistLicenseIsEnd) {
                          notification.error({
                            message: `${drivingLicenseIsEnd ? 'Водительское удостоверение' : 'Удостоверение тракториста' +
                              ' машиниста'} недействительно`,
                            description:
                              `У выбранного водителя истек срок действия ${drivingLicenseIsEnd ? 'водительского' +
                                ' удостоверения' : 'удостоверения тракториста машиниста'}. Выберите другого водителя.`
                          });
                          return;
                        }
                        try {
                          this.setState({loading: true});
                          //  перед отправкой, обnullяем у ПЛ данные, которые должны быть записаны
                          //  только после завершения таксировки, иначе, могут быть ошибки
                          trip.odometerAtEnd = null;
                          trip.fuelAtEnd = null;
                          trip.vehicleMachineHoursAtEnd = null;
                          await updateTrip(trip);
                          await changeStatus(trip.id, tripStatusEnum.opened);
                        } catch (error) {
                          notification.error({
                            message: 'Ошибка',
                            description: error.message,
                          });
                        } finally {
                          this.setState({loading: false});
                        }
                      }}
                      type="primary"
                    >
                      Запустить в работу
                    </Button>
                  )}
                  {canVerify && (
                    <Button type="primary" onClick={this.openVerification}>
                      Таксировка
                    </Button>
                  )}
                </Button.Group>
              )}
              {addDropDownAction && (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        data-test="cancelButton"
                        onClick={() =>
                          this.changeStatus(trip.id, tripStatusEnum.canceled)
                        }
                      >
                        Отменить рейс
                      </Menu.Item>
                      {canEdit && (
                        <Menu.Item
                          onClick={() =>
                            navigate(`/trips/self/${trip.id}/edit`)
                          }
                        >
                          Редактировать
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    type="primary"
                    data-test="actionsButton"
                    icon="ellipsis"
                  />
                </Dropdown>
              )}
            </Operations>
          }
        />
        <StyledPanel>
          <h1>{this.getHeaderTitle(trip)}</h1>
        </StyledPanel>

        <Section>
          <Content>
            <Grid gutter="16px" media={[{size: 'lg', cols: 3}]}>
              <GridItem>
                <Field label="Статус">{tripStatuses[trip.status]}</Field>
              </GridItem>
              <GridItem>
                <Field label="Период выделения ТС">
                  {formatDateRangeString(trip.startDate, trip.endDate)}
                </Field>
              </GridItem>
              {!!trip.formationDate && (
                <GridItem>
                  <Field label="Дата формирования">
                    {trip.status !== tripStatusEnum.canceled &&
                      tripStatusEnum.draft &&
                      formatDateTimeToString(trip.formationDate)}
                  </Field>
                </GridItem>
              )}
              {!!trip.objective && (
                <GridItem>
                  <Field label="Цель поездки">
                    {orderObjectives[trip.objective]}
                  </Field>
                </GridItem>
              )}
              <GridItem>
                <Field label="С прицепом">
                  {trip.withTrailer ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение службы">{trip.orgUnitName}</Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение (ФИО)">
                  {convertEmployeeToString(trip.employee)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Количество пассажиров">{trip.workersCount}</Field>
              </GridItem>
              <GridItem>
                <Field label="Тип ТС">
                  {trip?.vehicle?.vehicleModel && vehicleTypes[trip.vehicle.vehicleModel.type]}
                </Field>
              </GridItem>
              {trip.vehicle && trip?.vehicle?.vehicleModel && (
                <>
                  <GridItem>
                    <Field label="Марка ТС">
                      {trip?.vehicle?.vehicleModel.brandName}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Модель ТС">
                      {trip?.vehicle?.vehicleModel.name}
                    </Field>
                  </GridItem>
                </>
              )}
              <GridItem>
                <Field label="Государственный регистрационный знак">
                  {trip.vehicle
                    ? applyMaskToValue(
                      trip.vehicle.licensePlate,
                      formatLicensePlateMask,
                    )
                    : trip.licensePlate}
                </Field>
              </GridItem>
              {trip.trailer && (
                <>
                  {trip?.trailer?.vehicleModel && (
                    <>
                      <GridItem>
                        <Field label="Марка прицепа">
                          {trip?.trailer?.vehicleModel.brandName}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Модель прицепа">
                          {trip?.trailer?.vehicleModel.name}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  {trip.trailer.licensePlate && (
                    <GridItem>
                      <Field label="Государственный регистрационный знак прицепа">
                        {applyMaskToValue(
                          trip.trailer.licensePlate,
                          formatLicensePlateMask,
                        )}
                      </Field>
                    </GridItem>
                  )}
                </>
              )}
              {!!trip.driver && (
                <GridItem>
                  <Field
                    label="Водитель"
                    labelStyle={
                      hasDriverError ? {color: 'tomato'} : undefined
                    }
                    valueStyle={
                      hasDriverError ? {color: 'tomato'} : undefined
                    }
                  >
                    {convertEmployeeToString(trip.driver.employee)}
                  </Field>
                </GridItem>
              )}
              {trip.vehicle && trip.vehicle.fuelCard && (
                <GridItem>
                  <Field label="Топливная карта">
                    {trip.vehicle.fuelCard.cardNumber}
                  </Field>
                </GridItem>
              )}
              {!!trip.totalWorkingTime && (
                <GridItem>
                  <Field label="Суммарное время работы">
                    {trip.totalWorkingTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.movementTime && (
                <GridItem>
                  <Field label="Время в движении">{trip.movementTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.movementPrice && (
                <GridItem>
                  <Field label="Тариф в движении">
                    {trip.movementPrice} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.idleTime && (
                <GridItem>
                  <Field label="Время простоя">{trip.idleTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.idlePrice && (
                <GridItem>
                  <Field label="Тариф простоя">{trip.idlePrice} руб.</Field>
                </GridItem>
              )}
              {!!trip.onHoldTime && (
                <GridItem>
                  <Field label="Время в ожидании">{trip.onHoldTime} ч.</Field>
                </GridItem>
              )}
              {!!trip.onHoldPrice && (
                <GridItem>
                  <Field label="Тариф в ожидании">
                    {trip.onHoldPrice} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.distanceByContractor && (
                <GridItem>
                  <Field label="Пробег">{trip.distanceByContractor} км.</Field>
                </GridItem>
              )}
              {!!trip.pricePerKm && (
                <GridItem>
                  <Field label="Тариф за км">{trip.pricePerKm} руб.</Field>
                </GridItem>
              )}
              {!!trip.totalPrice && (
                <GridItem>
                  <Field label="Сумма без НДС">{trip.totalPrice} руб.</Field>
                </GridItem>
              )}
              {!!trip.airHeaterTime && (
                <GridItem>
                  <Field label="Время работы воздушного отопителя">
                    {trip.airHeaterTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.airHeaterRate && (
                <GridItem>
                  <Field label="Время работы жидкостного подогревателя">
                    {trip.airHeaterRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.liquidHeaterTime && (
                <GridItem>
                  <Field label="Тариф для жидкостного подогревателя">
                    {trip.liquidHeaterTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.liquidHeaterRate && (
                <GridItem>
                  <Field label="Тариф для жидкостного подогревателя">
                    {trip.liquidHeaterRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.powerGeneratorTime && (
                <GridItem>
                  <Field label="Время работы силового отопителя">
                    {trip.powerGeneratorTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.powerGeneratorRate && (
                <GridItem>
                  <Field label="Тариф для силового генератора">
                    {trip.powerGeneratorRate} руб.
                  </Field>
                </GridItem>
              )}
              {!!trip.winchTime && (
                <GridItem>
                  <Field label="Время работы лебедки">
                    {trip.winchTime} ч.
                  </Field>
                </GridItem>
              )}
              {!!trip.winchRate && (
                <GridItem>
                  <Field label="Тариф для лебедки">{trip.winchRate} руб.</Field>
                </GridItem>
              )}
              {!!trip.trailerKilometrage && (
                <GridItem>
                  <Field label="Пробег с прицепом">
                    {trip.trailerKilometrage} км
                  </Field>
                </GridItem>
              )}
              {!!trip.trailerRate && (
                <GridItem>
                  <Field label="Тариф с прицепом">
                    {trip.trailerRate} руб.
                  </Field>
                </GridItem>
              )}
            </Grid>
          </Content>
          {trip.status === tripStatusEnum.verified &&
            !!trip.tripAttachedEquipmentLinks?.length && (
              <>
                <Divider />
                <SectionTitle>Навесное оборудование</SectionTitle>
                <Content>
                  <Grid>
                    {trip.tripAttachedEquipmentLinks.map(
                      (equipment: TripAttachedEquipment, index) => (
                        <EquipmentRow
                          equipment={equipment}
                          key={index}
                          editMode={false}
                        />
                      ),
                    )}
                  </Grid>
                  <Grid>
                    <GridItem>
                      <Field label="Общее количество маш. часов">
                        {trip.tripAttachedEquipmentLinks.reduce(
                          (sum, item) => sum + item.machineHours,
                          0,
                        )}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Общий расход топлива">
                        {!!trip.tripAttachedEquipmentLinks.length
                          ? Math.round(
                          trip.tripAttachedEquipmentLinks.reduce(
                            (sum, item) => sum + item.fuelConsumption,
                            0,
                          ) * 100,
                        ) / 100
                          : '-'}
                      </Field>
                    </GridItem>
                  </Grid>
                </Content>
              </>
            )}
          {workAccountingType &&
            (workAccountingType === WorkAccountingTypes.kilometrage || workAccountingType === WorkAccountingTypes.all)
              && trip.status !== tripStatusEnum.draft &&  (
              <>
                <Divider />
                <SectionTitle>Показания одометра</SectionTitle>
                <Content>
                  <Grid>
                    <GridItem>
                      <Field label="При выезде из гаража">
                        {Math.round((trip.currentOdometerAtStart || 0) * 100) / 100}
                      </Field>
                    </GridItem>
                    {/* Показываем только после таксировки */}
                    {trip.status === tripStatusEnum.verified && (
                      <>
                        {parseFloat(trip.currentOdometerAtEnd) > 0 && (
                          <GridItem>
                            <Field label="При заезде в гараж">
                              {Math.round((trip.currentOdometerAtEnd || 0) * 100) / 100}
                            </Field>
                          </GridItem>
                        )}
                        <GridItem>
                          <Field label="Пробег за рейс">
                            {Math.round(
                                ((trip.currentOdometerAtEnd || 0) - (trip.currentOdometerAtStart || 0)) * 100) / 100}
                          </Field>
                        </GridItem>
                      </>
                    )}

                    {trip.monitoringNotation && (
                      <GridItem>
                        <Field label="Примечание">
                          {trip.monitoringNotation}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </Content>
              </>
            )}
          {workAccountingType &&
            (workAccountingType === WorkAccountingTypes.workHours || workAccountingType === WorkAccountingTypes.all)
            && trip.status !== tripStatusEnum.draft && (
              <>
                <Divider />
                <SectionTitle>Маш. часы</SectionTitle>
                <Content>
                  <Grid>
                    <GridItem>
                      <Field label="При выезде из гаража">
                        {Math.round((trip.currentVehicleMachineHoursAtStart || 0) * 100) / 100}
                      </Field>
                    </GridItem>
                    {trip.status === tripStatusEnum.verified && (
                      <>
                        <GridItem>
                          <Field label="При заезде в гараж">
                            {Math.round((trip.currentVehicleMachineHoursAtEnd || 0) * 100) / 100}
                          </Field>
                        </GridItem>
                        <GridItem>
                          <Field label="Маш. часы за рейс">
                            {Math.round(
                              ((trip.currentVehicleMachineHoursAtEnd || 0) -
                                (trip.currentVehicleMachineHoursAtStart || 0)) * 100) / 100}
                          </Field>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </Content>
              </>
            )}
          <Divider />
          <SectionTitle
            suffix={
              canEdit && (
                <Button type="primary" onClick={this.calculateRoute}>
                  Построить маршрут
                </Button>
              )
            }
          >
            Планируемый маршрут
          </SectionTitle>
          <Content>
            <Grid>
              <GridItem>
                <Field label="Суммарное время движения">
                  {calculateDateRangeString(
                    routeMinMaxDates.min,
                    routeMinMaxDates.max,
                  )}
                </Field>
              </GridItem>
              <GridItem fullWidth>
                <Field>
                  {canEdit ? (
                    <WaypointsForm
                      disableDates={false}
                      waypoints={expectedWaypoints}
                      onChange={(waypoints: WayPoint[]) =>
                        this.changeTripWaypoints(waypoints)
                      }
                      appendRows={[
                        canEdit && expectedRouteGeometry && (
                          <TripMap
                            geoJSON={expectedRouteGeometry.geoJSON}
                            waypoints={trip.expectedRoute.waypoints}
                          />
                        ),
                      ]}
                    />
                  ) : (
                    <WaypointsViewer
                      waypoints={
                        trip.expectedRoute && trip.expectedRoute.waypoints
                      }
                    />
                  )}
                </Field>
              </GridItem>
              <GridItem fullWidth>
                <Grid gutter="16px" cols={4} media={[{size: 'lg', cols: 5}]}>
                  <StyledGridItem>
                    <Field label="Нулевой пробег от гаража до объекта, км">
                      {canEdit ? (
                        <InputNumber
                          name="distanceAtStart"
                          min={0}
                          decimalSeparator=","
                          value={
                            typeof trip.distanceAtStart === 'number'
                              ? Math.round(trip.distanceAtStart * 100) / 100
                              : 0
                          }
                          onChange={(value: number) =>
                            this.changeTripInfo('distanceAtStart', value)
                          }
                        />
                      ) : (
                        Math.round(trip.distanceAtStart * 100) / 100
                      )}
                    </Field>
                  </StyledGridItem>
                  <StyledGridItem>
                    <Field label="Предполагаемый пробег, км">
                      {canEdit ? (
                        <InputNumber
                          name="distance"
                          min={0}
                          decimalSeparator=","
                          value={
                            typeof trip?.expectedRoute?.distance === 'number'
                              ? Math.round(
                              trip?.expectedRoute?.distance * 100,
                            ) / 100
                              : 0
                          }
                          onChange={(value: number) =>
                            this.changeExpectedRouteInfo('distance', value)
                          }
                        />
                      ) : (
                        Math.round(trip.expectedRoute?.distance * 100) / 100
                      )}
                    </Field>
                  </StyledGridItem>
                  <StyledGridItem>
                    <Field label="Нулевой пробег от объекта до гаража, км">
                      {canEdit ? (
                        <InputNumber
                          name="distanceAtEnd"
                          min={0}
                          decimalSeparator=","
                          value={
                            typeof trip.distanceAtEnd === 'number'
                              ? Math.round(trip.distanceAtEnd * 100) / 100
                              : 0
                          }
                          onChange={(value: number) =>
                            this.changeTripInfo('distanceAtEnd', value)
                          }
                        />
                      ) : (
                        Math.round(trip.distanceAtEnd * 100) / 100
                      )}
                    </Field>
                  </StyledGridItem>
                  <StyledGridItem>
                    <Field label={isElectricVehicle ? "Расход эл. эн. по нормативу, кВт·ч/100 км" : "Расход топлива по нормативу, л"}>
                      {canEdit ? (
                        <InputNumber
                          name="expectedFuelConsumption"
                          min={0}
                          decimalSeparator=","
                          value={
                            typeof trip.expectedFuelConsumption === 'number'
                              ? Math.round(trip.expectedFuelConsumption * 100) /
                              100
                              : 0
                          }
                          onChange={(value: number) =>
                            this.changeTripInfo(
                              'expectedFuelConsumption',
                              value,
                            )
                          }
                        />
                      ) : (
                        Math.round(trip.expectedFuelConsumption * 100) / 100
                      )}
                    </Field>
                  </StyledGridItem>
                </Grid>
              </GridItem>
            </Grid>
          </Content>

          {touched &&
            [tripStatusEnum.created, tripStatusEnum.draft].includes(
              trip.status,
            ) && (
              <Content>
                <Grid>
                  <GridItem fullWidth>
                    <Button.Group>
                      <Button type="primary" onClick={() => this.updateTrip()}>
                        Применить изменения
                      </Button>
                      <Button onClick={this.discardChanges}>Отменить</Button>
                    </Button.Group>
                  </GridItem>
                </Grid>
              </Content>
            )}
          {parseInt(trip.actualRouteId, 10) > 0 && trip.actualRoute && (
            <>
              <Divider />
              <SectionTitle>Фактический маршрут</SectionTitle>
              <Content>
                <Grid>
                  <GridItem fullWidth>
                    <Field>
                      <WaypointsViewer waypoints={trip.actualRoute.waypoints} />
                    </Field>
                  </GridItem>
                  <GridItem fullWidth>
                    <Grid
                      gutter="16px"
                      cols={4}
                      media={[{size: 'lg', cols: 5}]}
                    >
                      {parseFloat(trip.distanceFuelConsumption) > 0 && (
                        <GridItem>
                          {(trip.status === tripStatusEnum.verified) && isElectricVehicle ? (
                            <Field label="Фактический расход зарядки, %">
                              {Math.round(trip.distanceFuelConsumption * 100) /
                                100}{' '}
                              %
                            </Field>
                          ) : (
                            <Field label="Фактический расход топлива по пробегу, л">
                              {Math.round(trip.distanceFuelConsumption * 100) /
                                100}{' '}
                              л.
                            </Field>
                          )}
                        </GridItem>
                      )}
                      {parseFloat(trip.engineWorkFuelConsumption) > 0 && (
                        <GridItem>
                          <Field label="Фактический расход топлива по маш.часам, л">
                            {Math.round(trip.engineWorkFuelConsumption * 100) /
                              100}{' '}
                            л.
                          </Field>
                        </GridItem>
                      )}
                      {(trip.status === tripStatusEnum.verified) && !isElectricVehicle && (
                        <GridItem>
                          <Field label="Фактический расход топлива (общий), л">
                            {Math.round(trip.totalFuelConsumpton * 100) / 100}{' '}
                            л.
                          </Field>
                        </GridItem>
                      )}
                      {(trip.status === tripStatusEnum.verified ||
                        trip.status === tripStatusEnum.verification) && (
                        <GridItem>
                          {isElectricVehicle ? (
                            <Field label="Уровень % зарядки при заезде">
                              {canHandle && (trip.status === tripStatusEnum.verification) ? (
                                <InputNumber
                                  name="fuelAtEnd"
                                  min={0}
                                  decimalSeparator=","
                                  value={
                                    typeof trip.fuelAtEnd === 'number'
                                    ? Math.round(trip.fuelAtEnd * 100) /
                                      100
                                    : 0
                                  }
                                  onChange={(value: number) =>
                                    this.changeTripInfo(
                                      'fuelAtEnd',
                                      value,
                                    )
                                  }
                                />
                              ) : (
                                <>
                                  {Math.round(trip.fuelAtEnd * 100) / 100} %
                                </>
                              )}
                            </Field>
                          ) : (
                            <Field label="Уровень топлива при заезде">
                              {Math.round(trip.fuelAtEnd * 100) / 100} л.
                            </Field>
                          )}
                        </GridItem>
                      )}
                      {(trip.status !== tripStatusEnum.verified) && !isElectricVehicle && (
                        <StyledGridItem>
                          <Field label="Выдано топлива">
                            {trip.issuedFuel || 0} л.
                          </Field>
                        </StyledGridItem>
                      )}
                    </Grid>
                  </GridItem>
                  {editMode && (
                    <GridItem ref={editFormRef} fullWidth>
                      <CommonForm data={editFormData}>
                        {({setFieldValue}) => (
                          <>
                            <Grid>
                              {(trip?.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.kilometrage ||
                                trip?.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.all) && (
                                <GridItem>
                                  <FormField label="Пробег за рейс" fast name="kilometrageToTrip" hasFeedback={false}>
                                    {({name}) => (
                                      <InputNumber
                                        decimalSeparator=","
                                        name={name}
                                        onChange={value => {
                                          setFieldValue(name, value);
                                          editFormData[name] = value;
                                        }}
                                        style={{width: '90%'}}
                                        min={0}
                                      />
                                    )}
                                  </FormField>
                                </GridItem>
                              )}
                              {(trip?.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.workHours ||
                                trip?.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.all) && (
                                <GridItem>
                                  <FormField
                                    label="Количество машино-часов за рейс"
                                    fast
                                    name="workHoursToTrip"
                                    hasFeedback={false}
                                  >
                                    {({name}) => (
                                      <InputNumber
                                        decimalSeparator=","
                                        name={name}
                                        onChange={value => {
                                          setFieldValue(name, value);
                                          editFormData[name] = value;
                                        }}
                                        style={{width: '90%'}}
                                        min={0}
                                      />
                                    )}
                                  </FormField>
                                </GridItem>
                              )}
                              {!isElectricVehicle && (
                                <GridItem>
                                  <FormField
                                    label="Выдано топлива"
                                    fast
                                    name="fuel"
                                    hasFeedback={false}
                                  >
                                    {({name}) => (
                                      <InputNumber
                                        decimalSeparator=","
                                        name={name}
                                        onChange={value => {
                                          setFieldValue(name, value);
                                          editFormData[name] = value;
                                        }}
                                        style={{width: '90%'}}
                                        min={0}
                                      />
                                    )}
                                  </FormField>
                                </GridItem>
                              )}
                              {!!trip.tripAttachedEquipmentLinks?.length && (
                                <>
                                  <GridItem
                                    fullWidth
                                    customStyles={{
                                      margin: '5px 0 10px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Время работы навесного оборудования, ч. мин
                                  </GridItem>
                                  {trip.tripAttachedEquipmentLinks.map(link => {
                                    return (
                                      <GridItem key={link.attachedEquipmentId}>
                                        <FormField
                                          label={`${link.attachedEquipment.name} ${link.attachedEquipment.code}`}
                                          fast
                                          name={link.attachedEquipmentId}
                                          hasFeedback={false}
                                        >
                                          {({name}) => (
                                            <InputNumber
                                              decimalSeparator=","
                                              name={name}
                                              onChange={value => {
                                                setFieldValue(name, value);
                                                editFormData.attachedEquipmentTime[
                                                  link.attachedEquipmentId
                                                  ] = value;
                                              }}
                                              style={{width: '90%'}}
                                              min={0}
                                            />
                                          )}
                                        </FormField>
                                      </GridItem>
                                    );
                                  })}
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </CommonForm>
                    </GridItem>
                  )}
                </Grid>
              </Content>
              {touched &&
                [tripStatusEnum.verified].includes(
                  trip.status,
                ) && (
                  <Content>
                    <Grid>
                      <GridItem fullWidth>
                        <Button.Group>
                          <Button type="primary" onClick={() => this.updateTrip()}>
                            Применить изменения
                          </Button>
                          <Button onClick={this.discardChanges}>Отменить</Button>
                        </Button.Group>
                      </GridItem>
                    </Grid>
                  </Content>
                )}
            </>
          )}
          {trip.tripRanges && trip.tripRanges.length > 0 && (
            <>
              <Divider />
              <SectionTitle>Топливные коэффициенты по отрезкам</SectionTitle>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Отрезок, км" />
                  </GridItem>
                  <GridItem>
                    <Field label="Коэффициент" />
                  </GridItem>
                  {trip.tripRanges.map((tripRange: TripRange) => (
                    <>
                      <GridItem key={tripRange.id}>
                        <Field label="">
                          {Math.round(tripRange.amount * 100) / 100} км
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="">
                          {Math.round(tripRange.sumFuelMultiplier * 100) / 100}
                        </Field>
                      </GridItem>
                    </>
                  ))}
                </Grid>
              </Content>
            </>
          )}
          {trip.odometerReaderEmployee && (
            <Content>
              <Grid>
                <GridItem>
                  <Field label="Работник проводивший таксировку">
                    {convertEmployeeToString(trip.odometerReaderEmployee)}
                  </Field>
                </GridItem>
              </Grid>
            </Content>
          )}
          {/* Показываем только, если прошла таксировка */}
          {trip.status === tripStatusEnum.verified && (
            <>
              <Divider />
              <SectionTitle>Иное</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <Field label="На сторону">
                      {trip.toOtherSide ? 'Да' : 'Нет'}
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
            </>
          )}
          {(trip.startMedicCheckupDate ||
            trip.endMedicCheckupDate ||
            trip.medic) && (
            <>
              <Divider />
              <SectionTitle>Медосмотр</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <Field label="Дата и время предрейсового медосмотра">
                      {formatDateTimeToString(trip.startMedicCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.startMedic && (
                    <GridItem>
                      <Field label="ФИО медицинского работника предрейсового медосмотра">
                        {convertEmployeeToString(trip.startMedic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.startMedicFullName && (
                    <GridItem>
                      <Field label="ФИО наемного медицинского работника предрейсового медосмотра">
                        {trip.startMedicFullName}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Дата и время послерейсового медосмотра">
                      {formatDateTimeToString(trip.endMedicCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.endMedic && (
                    <GridItem>
                      <Field label="ФИО медицинского работника послерейсового медосмотра">
                        {convertEmployeeToString(trip.endMedic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endMedicFullName && (
                    <GridItem>
                      <Field label="ФИО наемного медицинского работника послерейсового медосмотра">
                        {trip.endMedicFullName}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}

          {(parseInt(trip.startMechanicId, 10) > 0 ||
            parseInt(trip.endMechanicId, 10) > 0) && (
            <>
              <SectionTitle>
                Контроль технического состояния машины
              </SectionTitle>
              <Content>
                <Grid>
                  {trip.startTechCheckupDate && (
                    <GridItem>
                      <Field label="Дата и время предрейсового технического осмотра">
                        {formatDateTimeToString(trip.startTechCheckupDate)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.startMechanic && (
                    <GridItem>
                      <Field label="ФИО контролера предрейсового технического осмотра">
                        {convertEmployeeToString(trip.startMechanic)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endTechCheckupDate && (
                    <GridItem>
                      <Field label="Дата и время послерейсового технического осмотра">
                        {formatDateTimeToString(trip.endTechCheckupDate)}
                      </Field>
                    </GridItem>
                  )}
                  {trip.endMechanic && (
                    <GridItem>
                      <Field label="ФИО контролера послерейсового технического осмотра">
                        {convertEmployeeToString(trip.endMechanic)}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}

          {(trip.startGpmControllerCheckupDate ||
            trip.endGpmControllerCheckupDate) && (
            <>
              <Divider />
              <SectionTitle>Контроль ГПМ</SectionTitle>
              <Content>
                <Grid>
                  <GridItem>
                    <Field label="Дата и время предрейсового осмотра">
                      {formatDateTimeToString(
                        trip.startGpmControllerCheckupDate,
                      )}
                    </Field>
                  </GridItem>
                  {trip.startGpmController && (
                    <GridItem>
                      <Field label="ФИО контролера ГПМ предрейсового осмотра">
                        {convertEmployeeToString(trip.startGpmController)}
                      </Field>
                    </GridItem>
                  )}
                  <GridItem>
                    <Field label="Дата и время послерейсового осмотра">
                      {formatDateTimeToString(trip.endGpmControllerCheckupDate)}
                    </Field>
                  </GridItem>
                  {trip.endGpmController && (
                    <GridItem>
                      <Field label="ФИО контролера ГПМ послерейсового осмотра">
                        {convertEmployeeToString(trip.endGpmController)}
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              </Content>
            </>
          )}
          {editMode && (
            <Content>
              <PopoverBlock
                tripId={trip.id}
                onSubmit={() => this.handleSubmitFormData(editFormData)}
              />
              <Button
                onClick={() => {
                  this.setState({editMode: false});
                  editFormData.fuel = null;
                  editFormData.attachedEquipmentTime = {};
                  editFormData.kilometrageToTrip = null;
                  editFormData.workHoursToTrip = null;
                }}
              >
                Отмена
              </Button>
            </Content>
          )}
        </Section>
        {!!formattedHistoryItems.length && (
          <HistorySection history={formattedHistoryItems} />
        )}
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    trip: selectTrip(state),
  }),
  {
    fetchTrip,
    cleanTrip,
    updateTrip,
    changeStatus,
  },
)(withUserAccess(TripCard));
