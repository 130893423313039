import React from 'react';
import styled from 'styled-components';

import type {RegulationLimitType} from './../../../../../../../lib/types';
import {SHORT_MONTH} from './../../../../../../../lib/helpers';
import Grid, {GridItem} from './../../../../../../../components/layout/Grid';
import {regulationLimitTypeEnum, regulationLimitTypes} from './../../../../../../../lib/enum';
import {HeaderBoldCell, HeaderCell, ListHeader} from './../../../elements';

const Header = styled(GridItem)`
  width: 600px;
  background: #e4ebf2;
  border-right: 1px solid #c0ccd7;
  height: 88px;
`;

const header = (title: string) => {
  return (
    <ListHeader
      style={{ minWidth: '1980px', display: 'flex', position: 'sticky', zIndex: '999', left: '0', top: '0', }}
      media={getGridTemplate(regulationLimitTypeEnum.orgUnit)}
      rows={3}
    >
      <Header>
        <Grid
          media={[
            {
              size: '(max-width: 599x)',
              cols: ['300px', '300px']
            },
            {
              size: '(min-width: 600px)',
              cols: ['300px', '300px']
            }
          ]}
        >
          <GridItem
            style={{
              alignSelf: 'center'
            }}
          >
            <HeaderBoldCell>{title}</HeaderBoldCell>
          </GridItem>
          <GridItem
            style={{
              alignSelf: 'center'
            }}
          >
            <HeaderBoldCell>ТС</HeaderBoldCell>
          </GridItem>
        </Grid>
      </Header>
      <GridItem>
        <Grid style={{ gridTemplateColumns: 'auto' }}>{commonCells(3)}</Grid>
      </GridItem>
    </ListHeader>
  );
};

// Функция возвращает разметку грида для строк
export const getGridTemplate = (type: RegulationLimitType) => {
  switch (type) {
    case regulationLimitTypeEnum.employee:
    case regulationLimitTypeEnum.orgUnit:
    case regulationLimitTypeEnum.ovb:
    default:
      return [
        {
          size: '(max-width: 1979px)',
          cols: ['1fr']
        },
        {
          size: '(min-width: 1980px)',
          cols: ['1fr']
        }
      ];
  }
};

const months = SHORT_MONTH;
const commonCells = (startFrom: number) => (
  <>
    <GridItem
      style={{
        borderLeft: '1px solid #c0ccd7',
        borderRight: '1px solid #c0ccd7'
      }}
      cellUnion={{
        gridColumn: 'span 24'
      }}
    >
      <HeaderBoldCell>Дни работы</HeaderBoldCell>
    </GridItem>
    {months.map((month, index) => {
      return (
        <>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7'
            }}
            cellUnion={{
              gridColumn: `span 2`,
              gridRow: `2`
            }}
          >
            <HeaderCell>{month}</HeaderCell>
          </GridItem>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7',
              width: '57.5px'
            }}
            cellUnion={{
              gridColumn: `span 1`,
              gridRow: `3`
            }}
          >
            <HeaderCell>Буд</HeaderCell>
          </GridItem>
          <GridItem
            style={{
              borderLeft: '1px solid #c0ccd7',
              borderTop: '1px solid #c0ccd7',
              width: '57.5px'
            }}
            cellUnion={{
              gridColumn: `span 1`,
              gridRow: `3`
            }}
          >
            <HeaderCell>Вых</HeaderCell>
          </GridItem>
        </>
      );
    })}
  </>
);

export const EmployeeHeader = () => header('Должность');

export const OrgUnitHeader = () => header('Служба');

export const ListTableHeader = ({ type }: { type: RegulationLimitType }) => (
  <ListHeader cols={getGridTemplate(type)} rows={2}>
    <GridItem
      cellUnion={{
        gridColumn: '1 / span 1',
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>{regulationLimitTypes[type]}</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const ListTableOvbHeader = () => header('ОВБ');

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  & > * {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }
`;
