// @flow
import CommonApi from '../commonApi';
import type {
  CalculationStatus,
  FuelAndOilMechanism,
  FuelAndOilMechanismMonth
} from '../types';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { fetchRequest } from './index';

export const convertDataToServer = (
  fuelAndOilMechanism: FuelAndOilMechanism
): FuelAndOilMechanism => ({
  ...fuelAndOilMechanism,
  months: fuelAndOilMechanism.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  fuelAndOilMechanism: FuelAndOilMechanism
): FuelAndOilMechanism => ({
  ...fuelAndOilMechanism,
  months: fuelAndOilMechanism.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  fuelAndOilMechanismMonth: FuelAndOilMechanismMonth
): FuelAndOilMechanismMonth => ({
  ...fuelAndOilMechanismMonth,
  cost: convertFromRubToKop(fuelAndOilMechanismMonth.cost)
});

export const convertDataFromServerMonth = (
  fuelAndOilMechanismMonth: FuelAndOilMechanismMonth
): FuelAndOilMechanismMonth => ({
  ...fuelAndOilMechanismMonth,
  cost: convertFromKopToRub(fuelAndOilMechanismMonth.cost)
});

const basedUrl = 'fuelAndOilMechanism';
const fuelAndOilMechanism = new CommonApi<FuelAndOilMechanism>(
  basedUrl,
  'расчет гсм топливо, средства малой механизации',
  convertDataToServer,
  convertDataFromServer
);

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...fuelAndOilMechanism,
  changeStatus
};
