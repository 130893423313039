// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import moment from 'moment';
import notification from 'antd/lib/notification';

import Header from '../../../components/layout/Header';
import {Section} from '../../../components/layout';
import Filter from './components/Filter';
import type {ReportBusinessTrip, ReportBusinessTripParams} from '../../../lib/types/reportBusinessTrips';
import {reportBusinessTripsApi} from '../../../lib/api';
import {notificationLoading} from '../../../components/Notifications';
import {applyMaskToValue} from '../../../lib/helpers';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';

const initialParams: ReportBusinessTripParams = {
  startDate: moment
  .utc()
  .startOf('month')
  .toISOString(),
  endDate: moment
  .utc()
  .endOf('month')
  .toISOString(),
};

type State = {
  params: any,
  data: ReportBusinessTrip[],
  loading: boolean
};

/**
 * Реестр путевых листов по автотранспортным услугам
 */
export class BusinessTripList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
  };
  columns = [
    {
      title: '№',
      dataIndex: 'tripNumber',
    },
    {
      title: 'Дата путевого листа',
      dataIndex: 'date',
      render: (date: string) => moment.utc(date).format('DD.MM.YYYY'),
    },
    {
      title: 'Марка',
      dataIndex: 'vehicleModel',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask),
    },
    {
      title: 'Время работы всего',
      dataIndex: 'workingTime',
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км`,
    },
    {
      title: 'Маршрут следования',
      dataIndex: 'route',
    },
    {
      title: 'Кол-во дней командировки',
      dataIndex: 'daysCount',
    },
    {
      title: 'ФИО, ответственного за ТС',
      dataIndex: 'employee',
    },
    {
      title: 'Номер приказа о командировке',
      dataIndex: 'orderRequisites',
    },
    {
      title: 'Номер заявки на командировку',
      dataIndex: 'orderNumber',
    },
  ];
  async componentDidMount() {
    await this.fetchReport();
  }
  fetchReport = async (params: ReportBusinessTripParams = initialParams) => {
    try {
      this.setState({loading: true});
      const data = await reportBusinessTripsApi.fetchReportBusinessTrips(
        params,
      );
      this.setState({data});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      this.setState({loading: false});
    }
  };
  fetchData = async () => {
    const {
      startDate,
      endDate,
      firstName,
      lastName,
      middleName,
      mnemocode,
      personNumber,
      businessTripOrderNumber,
    } = this.state.params;
    try {
      notificationLoading({
        message: 'Запрос данных...',
        key: 'req',
      });
      await reportBusinessTripsApi.fetchData({
        startDate,
        endDate,
        firstName,
        lastName,
        middleName,
        mnemocode,
        personNumber,
        businessTripOrderNumber,
      });
      notification.success({message: 'Данные успешно запрошены!'});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('req');
    }
  };
  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value,
      },
    }));
  };
  applyParams = async () => await this.fetchReport(this.state.params);
  cleanParams = () => this.setState({params: initialParams});
  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print',
      });
      await reportBusinessTripsApi.printReportBusinessTrips(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('print');
    }
  };
  render() {
    const {loading} = this.state;
    return (
      <>
        <Header left={<h1>Командировки</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            fetchData={this.fetchData}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={this.state.data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default BusinessTripList;
