// @flow
import React from 'react';
import {monthsNamesTranslitEnum} from '../../lib/enum';
import {budgetVersionApi} from './../../lib/api';
import {navigate} from '../../lib/helpers';
import Page from '../../components/hoc/common/handbook/PageContext';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import {withUserAccess} from './../withUserAccess';

import addAccessRight from './accessRight';

const entryPointPath = '/admin/budget-version/';

const columns = [
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: 'Доступно для выбора пользователем при формирование предложений',
    dataIndex: 'isAvailableForSelect',
    render: available => (available ? 'Доступно' : 'Недоступно')
  },
  {
    title: 'Начало открытого периода',
    dataIndex: 'periodStart',
    render: periodStart => !!periodStart && monthsNamesTranslitEnum[periodStart]
  }
];

const access = {
  add: () => navigate(`${entryPointPath}new`)
};

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some(access => addAccessRight.includes(access));

  return (
    <Page>
      <CommonListPage
        crud={budgetVersionApi}
        pageHeaderProps={{
          mainHeader: 'Версии бюджета',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canAdd()
        }}
      />
    </Page>
  );
});
