// @flow
import type {FileInfo} from './index';

export type InventoryCardCompareTypeEnum = {
  automatically: 'automatically',
  manually: 'manually',
  manuallyForOneCar: 'manuallyForOneCar',
}

export const InventoryCardCompareTypes = {
  automatically: 'Автоматическая',
  manually: 'Вручную',
  manuallyForOneCar: 'Единичная сверка',
}

export type InventoryCardCompareResult = {
  // кол-во сравниваемых карточек
  allCompareCount: number,
  created: string,
  file: FileInfo,
  fileId: number,
  id: number,
  // кол-во карточек, по которым найдены расхождения
  noCompareCount: number,
  nodeId: number,
  type: $Keys<InventoryCardCompareTypeEnum>,
};
