// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {fetchRequest} from '../../lib/api';
import moment from 'moment';
import {cognosSendingStatuses, monthsNamesTranslitEnum, monthsNumbersEnum} from '../../lib/enum';
import notification from 'antd/lib/notification';

const entryPointPath = '/admin/actual-fuel-consumption';

const List = () => {
  const columns = [
    {
      title: 'Год',
      dataIndex: 'year'
    },
    {
      title: 'Месяц',
      dataIndex: 'month',
      render: monthNumber =>
        monthsNamesTranslitEnum[monthsNumbersEnum[monthNumber]]
    },
    {
      title: 'Филиал',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Дата отправки',
      dataIndex: 'cognosSendDate',
      render: date => (!!date ? moment(date).format('DD.MM.YYYY') : '-')
    },
    {
      title: 'Статус отправки',
      dataIndex: 'cognosSendStatus',
      render: status => cognosSendingStatuses[status]
    }
  ];

  return (
    <Page>
      <CommonListPage
        crud={{ fetch: () => fetchRequest.get('/factfuelconsumption') }}
        pageHeaderProps={{
          mainHeader: 'Фактический расход ГСМ',
          access: {
            custom: {
              title: 'Сформировать',
              action: () =>
                fetchRequest
                  .post('/factfuelconsumption')
                  .then(resp => {
                    if (resp?.id) {
                      navigate(`${entryPointPath}/${resp.id}`);
                    }
                  })
                  .catch(({ message }) => {
                    notification.error({
                      message: 'Ошибка',
                      description: message
                    });
                  })
            }
          }
        }}
        tableListProps={{
          columns,
          onSelect: id => navigate(`${entryPointPath}/${id}`),
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default React.memo(List);
