// @flow
import React, {Component} from 'react';

import styled from 'styled-components';
import moment from 'moment';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {FormikProps} from 'formik';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';

import type {InspectionGtn} from './../../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate, plus} from './../../../lib/helpers';
import {inspectionGtnApi} from './../../../lib/api';

import {Panel, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Form, Selects} from './../../../components';
import Header from './../../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { VehicleSelect } = Selects;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  inspectionGtn: InspectionGtn,
  showVehicleSelect: boolean
};

const InnerForm = ({
  onSubmit,
  onCancel,
  inspectionGtn,
  showVehicleSelect
}: FormProps) => (
  <Form initialValues={inspectionGtn} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        values
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px" cols={3}>
                {showVehicleSelect && (
                  <GridItem>
                    <FormField label="ТС" required name="vehicleId">
                      {({ name, value }) => (
                        <VehicleSelect
                          name={name}
                          value={value}
                          onBlur={() => handleBlur({ target: { name } })}
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          data-cy="vehicleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField
                    fast
                    label="Дата обслуживания"
                    name="maintenanceDate"
                    required
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Следующая дата обслуживания"
                    name="nextActDate"
                  >
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        disabledDate={value =>
                          moment(value).isBefore(values.maintenanceDate)
                        }
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость ТО ГТН, руб"
                    name="price"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(
                            'totalPrice',
                            plus(value, values.taxPrice)
                          );
                          setFieldValue(
                            'paymentAmount',
                            plus(value, values.price)
                          );
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Стоимость госпошлины ТО ГТН, руб"
                    name="taxPrice"
                    required
                  >
                    {({ name, value }) => (
                      <InputNumber
                        min={0}
                        name={name}
                        value={value}
                        onChange={(value: number) => {
                          setFieldValue(
                            'totalPrice',
                            plus(value, values.price)
                          );
                          setFieldValue(
                            'paymentAmount',
                            plus(value, values.price)
                          );
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    fast
                    label="Общая стоимость ТО ГТН"
                    name="paymentAmount"
                  >
                    {({ name, value }) => (
                      <InputNumber disabled min={0} name={name} value={value} />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button type="primary" htmlType="submit" data-cy="save">
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  inspectionGtnId: ?number,
  vehicleId: ?number
};

type State = {
  inspectionGtn: $Shape<InspectionGtn>
};

class InspectionGtnForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    try {
      const inspectionGtnId = parseInt(this.props.inspectionGtnId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (inspectionGtnId) {
        const inspectionGtn = await inspectionGtnApi.fetchInspectionGtn(
          inspectionGtnId
        );
        this.setState({
          inspectionGtn
        });
      } else if (vehicleId) {
        this.setState({
          inspectionGtn: {
            vehicleId
          }
        });
      } else {
        this.setState({
          inspectionGtn: {
            price: 0,
            taxPrice: 0,
            totalPrice: 0
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      this.redirect();
    }
  }

  redirect = (id: ?number = this.props.inspectionGtnId) => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/maintenance/inspections-gtn`);
    } else {
      if (id) {
        navigate(`/services/inspections-gtn/${id}`);
      } else {
        navigate(`/services/inspections-gtn/`, true);
      }
    }
  };

  onSubmit = async (inspectionGtn: InspectionGtn) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const inspectionGtnId = parseInt(this.props.inspectionGtnId, 10);
      if (inspectionGtnId) {
        await inspectionGtnApi.updateInspectionGtn(inspectionGtn);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        this.redirect(inspectionGtnId);
      } else {
        const added = await inspectionGtnApi.addInspectionGtn(inspectionGtn);

        notification.success({
          message: 'Успешное добавление',
          description: `Технический осмотр ГТН успешно добавлен`
        });
        this.redirect(added.id);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const inspectionGtnId = parseInt(this.props.inspectionGtnId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { inspectionGtn } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              {vehicleId > 0 ? (
                <Crumb
                  to={`/vehicles/${vehicleId}/maintenance/inspections-gibdd`}
                >
                  ТС
                </Crumb>
              ) : (
                <Crumb
                  to={getPathWithHistoryParams('/services/inspections-gtn')}
                >
                  Список технических осмотров ГТН
                </Crumb>
              )}
              {inspectionGtnId ? (
                <Crumb>Редактирование технического осмотра ГТН</Crumb>
              ) : (
                <Crumb>Новый технический осмотр ГТН</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {inspectionGtnId
              ? `Технический осмотр ГТН №${inspectionGtnId}`
              : 'Новый технический осмотр ГТН'}
          </h1>
        </StyledPanel>
        <InnerForm
          inspectionGtn={{
            totalPrice: 0,
            ...inspectionGtn
          }}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect(inspectionGtn.id || inspectionGtnId)}
          showVehicleSelect={!this.props.vehicleId}
        />
      </>
    );
  }
}

export default InspectionGtnForm;
