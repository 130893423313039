//@flow
import React, { useCallback } from 'react';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';

import { formatDateTimeToISOString } from '../../../../lib/helpers';
import { FilterItem } from '../../../../components';
import Field from '../../../../components/card/Field';
import {
  CommonFilter,
  type FilterProps
} from '../../../../components/hoc/common/handbook/CommonFilter';
import Select from '../../../../components/selects';

const { BudgetVersionSelect, BudgetSummaryStatusSelect } = Select;

export type BudgetSummaryParamsFilter = {
  status?: string,
  date?: string,
  budgetVersionId?: number
};

export const BudgetSummaryFilter = (
  props: FilterProps<BudgetSummaryParamsFilter>
) => {
  const Filter = useCallback(
    (filterProps: $Shape<FilterProps<BudgetSummaryParamsFilter>>) =>
      CommonFilter<BudgetSummaryParamsFilter>({ ...filterProps, ...props }),
    [props]
  );
  return (
    <Filter>
      {(values, changeValue) => {
        return (
          <>
            <FilterItem>
              <Field mBottomNone>
                <BudgetSummaryStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={values.date && moment.utc(values.date)}
                  placeholder="Дата формирования"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'date',
                      formatDateTimeToISOString(value, dateString) || undefined
                    )
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <BudgetVersionSelect
                  size="small"
                  placeholder="Версия бюджета"
                  onChange={value => changeValue('budgetVersionId', value)}
                  value={values.budgetVersionId}
                />
              </Field>
            </FilterItem>
          </>
        );
      }}
    </Filter>
  );
};
export default BudgetSummaryFilter;
