// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import moment from 'moment';
import notification from 'antd/lib/notification';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import Filter from './components/Filter';
import type {
  ReportWeekendOrders,
  ReportWeekendOrdersParams
} from '../../../lib/types/reportWeekendOrders';
import { reportWeekendOrdersApi } from '../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';

const initialParams: ReportWeekendOrdersParams = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  params: ReportWeekendOrdersParams,
  data: ReportWeekendOrders[],
  loading: boolean
};

/**
 * Реестр путевых листов по автотранспортным услугам
 */
export class WeekendOrderList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false
  };

  async componentDidMount() {
    await this.fetchReport();
  }

  fetchReport = async (params: ReportWeekendOrdersParams = initialParams) => {
    try {
      this.setState({ loading: true });
      if (params.ownerType === undefined) {
        delete params.ownerType;
      }
      const data = await reportWeekendOrdersApi.fetchReportWeekendOrders(
        params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<any>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      }
    }));
  };

  applyParams = async () => await this.fetchReport(this.state.params);

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print'
      });
      await reportWeekendOrdersApi.printReportWeekendOrders(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: '№',
      dataIndex: 'orderId'
    },
    {
      title: 'Сотрудник',
      dataIndex: 'employee'
    },
    {
      title: 'ТС',
      dataIndex: 'vehicle'
    },
    {
      title: 'Маршрут',
      dataIndex: 'route'
    },
    {
      title: 'Период выделения',
      dataIndex: 'period'
    },
    {
      title: 'Примечание',
      dataIndex: 'notation'
    }
  ];

  render() {
    const { loading } = this.state;
    return (
      <>
        <Header left={<h1>Реестр заявок на выходные дни</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            loading={loading}
            pagination={false}
            dataSource={this.state.data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default WeekendOrderList;
