// @flow
import React from 'react';

import notification from 'antd/lib/notification';

import { washingApi } from './../../../../../lib/api';
import { Header, Section } from './../../../../../components/layout';
import { GroupListTable, COLUMNS } from './../../lib';

import { Tabs, Filter, type ExecutionBudgetFilterParams } from '../';

export default class extends GroupListTable<any, ExecutionBudgetFilterParams> {
  columns = COLUMNS.map(column => ({
    ...column,
    dataIndex: column.dataIndex === 'act.sum' ? 'factSum' : column.dataIndex
  }));

  fetch = async (page: number = 1) => {
    const params = {
      ...super.fetch(page)
    };
    try {
      const data = await washingApi.groupedList(params);
      this.setState({ ...data, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        <Header left={<h1>Исполнение договоров</h1>} />
        <Tabs />
        <Section>
          {this.renderFilter(Filter)}
          {this.renderTable(this.columns)}
        </Section>
      </>
    );
  }
}
