// @flow
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import InputNumber from 'antd/lib/input-number';

import {minus, MONTH, plus, toLocalStringRu, tooltipFullText} from '../../../../lib/helpers';
import type {BranchBudgetSummaryLineItem} from '../../../../lib/types';
import {branchBudgetSummaryMonthRedistributionApi} from '../../../../lib/api';

import Field from '../../../../components/card/Field';
import {AntTable} from '../../../../components/ui';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import {Selects} from '../../../../components';
import {notification} from 'antd';

const {
  BranchBudgetSummaryLineItemSelect,
  BranchBudgetSummaryExpenseDirectionSelect,
  BranchBudgetSummaryMonthSelect
} = Selects;

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const WhiteSpace = styled.div`
  white-space: pre-line;
  overflow: auto;
`;
const StyledTable = styled(AntTable)``;

type Props = {
  visible: boolean,
  onCancel: Function,
  onSave: Function,
  monthIndex: ?number,
  branchBudgetSummaryId: number,
  branchBudgetSummaryLineItem: BranchBudgetSummaryLineItem,
  monthToId: ?number,
  readOnly: boolean
};
export default ({
  visible,
  onCancel,
  onSave,
  branchBudgetSummaryLineItem,
  branchBudgetSummaryId,
  monthIndex,
  monthToId,
  readOnly
}: Props) => {
  const [budget, setBudget] = useState(branchBudgetSummaryLineItem);
  const [economyTransfer, setTransfer] = useState(0);
  const [redistributionData, setRedistributionData] = useState([]);
  const [data, setData] = useState([]);
  const [transferData, setTransferData] = useState<any>({});

  const clearTransferData = (field: string) => {
    switch (field) {
      case 'branchBudgetSummaryLineItem':
        setTransferData(prevState => ({
          ...prevState,
          branchBudgetSummaryExpenseDirectionId: undefined,
          expenseDirectionNameFrom: undefined,
          branchBudgetSummaryExpenseDirection: undefined
        }));
      // eslint-disable-next-line
      case 'branchBudgetSummaryExpenseDirection':
        setTransferData(prevState => ({
          ...prevState,
          monthToId: undefined,
          monthFromId: undefined,
          monthFromIndex: undefined,
          branchBudgetSummaryMonthId: undefined,
          branchBudgetSummaryMonth: undefined
        }));
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setBudget(branchBudgetSummaryLineItem);
  }, [branchBudgetSummaryLineItem, monthIndex]);

  useEffect(() => {
    if (Number.isInteger(monthToId)) {
      getRedistributionData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthToId]);

  const getRedistributionData = async () => {
    const { data } = await branchBudgetSummaryMonthRedistributionApi.fetch({
      monthToId
    });
    setRedistributionData(data);
  };

  return (
    <Modal
      destroyOnClose
      width={1200}
      title="Покрытие потребности"
      visible={visible}
      onCancel={() => {
        onCancel();
        setData([]);
        setTransferData({});
        setTransfer(0);
      }}
      onOk={() => {
        onSave(data, redistributionData);
        setData([]);
      }}
    >
      {readOnly !== true && (
        <>
          <StyledTable
            columns={[
              {
                title: (
                  <div>
                    Статья бюджетной форм
                    <br />
                    куда производится перенос
                  </div>
                ),
                dataIndex: 'budgetLineItemName',
                render: budgetLineItemName => (
                  <WhiteSpace>
                    {tooltipFullText(budgetLineItemName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: (
                  <div>
                    Направление расходов
                    <br />
                    куда производится перенос
                  </div>
                ),
                dataIndex: 'expenseDirectionName',
                render: expenseDirectionName => (
                  <WhiteSpace>
                    {tooltipFullText(expenseDirectionName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: (
                  <div>
                    Месяц на который
                    <br />
                    производится перенос
                  </div>
                ),
                dataIndex: 'months',
                render: months => MONTH[monthIndex]
              },
              {
                title: <div>Итого перерасход</div>,
                dataIndex: 'months',
                key: 'economyResidue',
                render: (months, record) =>
                  toLocalStringRu(record.months[monthIndex].economyResidue)
              },
              {
                title: <div>Покрытый перерасход</div>,
                dataIndex: 'months',
                render: months => toLocalStringRu(economyTransfer)
              },
              {
                title: <div>Непокрытый перерасход</div>,
                dataIndex: 'months',
                render: (months, record) =>
                  toLocalStringRu(
                    minus(
                      record.months[monthIndex].economyResidue,
                      economyTransfer
                    )
                  )
              }
            ]}
            data={[budget]}
            pagination={false}
          />
          <Grid
            style={{
              marginTop: 16,
              paddingTop: 16,
              borderTop: '1px solid #c0ccd7'
            }}
            gutter="16px"
          >
            <GridItem>
              <Field>
                <BranchBudgetSummaryLineItemSelect
                  filter={{
                    withEconomy: true,
                    branchBudgetSummaryId: branchBudgetSummaryId
                  }}
                  value={transferData?.branchBudgetSummaryLineItemId}
                  onChange={(
                    branchBudgetSummaryLineItemId: number,
                    option: any
                  ) => {
                    clearTransferData('branchBudgetSummaryLineItem');
                    setTransferData(prevState => ({
                      ...prevState,
                      branchBudgetSummaryLineItemId,
                      budgetLineItemNameFrom:
                        option?.props?.branchBudgetSummaryLineItem
                          ?.budgetLineItemName,
                      branchBudgetSummaryLineItem:
                        option?.props?.branchBudgetSummaryLineItem
                    }));
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field>
                <BranchBudgetSummaryExpenseDirectionSelect
                  value={transferData?.branchBudgetSummaryExpenseDirectionId}
                  disabled={!transferData?.branchBudgetSummaryLineItemId}
                  onChange={(
                    branchBudgetSummaryExpenseDirectionId: number,
                    option: any
                  ) => {
                    clearTransferData('branchBudgetSummaryExpenseDirection');
                    setTransferData(prevState => ({
                      ...prevState,
                      branchBudgetSummaryExpenseDirectionId,
                      expenseDirectionNameFrom:
                        option?.props?.branchBudgetSummaryExpenseDirection
                          ?.expenseDirectionName,
                      branchBudgetSummaryExpenseDirection:
                        option?.props?.branchBudgetSummaryExpenseDirectionn
                    }));
                  }}
                  filter={{
                    withEconomy: true,
                    branchBudgetSummaryLineItemId:
                      transferData?.branchBudgetSummaryLineItemId
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field>
                <BranchBudgetSummaryMonthSelect
                  value={transferData?.branchBudgetSummaryMonthId}
                  disabled={
                    !transferData?.branchBudgetSummaryExpenseDirectionId
                  }
                  onChange={(
                    branchBudgetSummaryMonthId: number,
                    option: any
                  ) => {
                    setTransferData(prevState => ({
                      ...prevState,
                      monthToId: budget.months[monthIndex].id,
                      monthFromId: branchBudgetSummaryMonthId,
                      monthFromIndex:
                        option?.props?.branchBudgetSummaryMonth?.month,
                      branchBudgetSummaryMonthId,
                      isOpenPeriod:
                        option?.props?.branchBudgetSummaryMonth?.isOpenPeriod,
                      branchBudgetSummaryMonth:
                        option?.props?.branchBudgetSummaryMonth
                    }));
                  }}
                  filter={{
                    withEconomy: true,
                    branchBudgetSummaryExpenseDirectionId:
                      transferData?.branchBudgetSummaryExpenseDirectionId
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field>
                <StyledInputNumber
                  disabled={!transferData?.branchBudgetSummaryMonthId}
                  placeholder="Укажите сумму"
                  value={transferData?.sum}
                  min={0}
                  onChange={sum => {
                    const verificationValue = Math.abs(plus(budget.months[monthIndex].economyResidue, economyTransfer))
                    if (sum <= verificationValue) {
                      setTransferData(prevState => ({
                        ...prevState,
                        sum
                      }));
                    } else {
                      notification.warning({
                        message: (
                            <>
                              <p>{`Введенное значение не должно превышать ${verificationValue}`}</p>
                              <p>(Итого перерасход + Покрытый перерасход)</p>
                            </>
                          )
                      })
                    }
                  }}
                />
              </Field>
            </GridItem>
            <GridItem>
              <Field>
                <Button
                  type="primary"
                  disabled={
                    !transferData?.sum ||
                    !transferData?.branchBudgetSummaryMonthId ||
                    !transferData?.branchBudgetSummaryExpenseDirectionId ||
                    !transferData?.branchBudgetSummaryLineItemId
                  }
                  onClick={() => {
                    setTransfer(plus(economyTransfer, transferData.sum));
                    setData([
                      ...data,
                      {
                        ...transferData
                      }
                    ]);
                    setTransferData({});
                  }}
                >
                  Применить
                </Button>
              </Field>
            </GridItem>
          </Grid>
        </>
      )}
      <Grid style={{ marginTop: 16 }}>
        <GridItem style={{ fontWeight: 'bold' }}>
          Покрытие расходов из экономии открытого период
        </GridItem>
        <GridItem fullWidth style={{ marginTop: 16 }}>
          <StyledTable
            columns={[
              {
                title: 'Статья бюджетной формы',
                dataIndex: 'budgetLineItemNameFrom',
                render: budgetLineItemName => (
                  <WhiteSpace>
                    {tooltipFullText(budgetLineItemName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: 'Направление расходов',
                dataIndex: 'expenseDirectionNameFrom',
                render: expenseDirectionName => (
                  <WhiteSpace>
                    {tooltipFullText(expenseDirectionName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: 'Месяц',
                dataIndex: 'monthFromIndex',
                render: month => MONTH[month - 1]
              },
              {
                title: 'Перенесенная сумма',
                dataIndex: 'sum',
                key: 'sum',
                render: sum => toLocalStringRu(sum)
              }
            ]}
            data={[...redistributionData, ...data].filter(
              item => item.isOpenPeriod === true
            )}
            pagination={false}
          />
        </GridItem>
      </Grid>

      <Grid style={{ marginTop: 16 }}>
        <GridItem style={{ fontWeight: 'bold' }}>
          Покрытие расходов из экономии закрытого периода
        </GridItem>
        <GridItem fullWidth style={{ marginTop: 16 }}>
          <StyledTable
            columns={[
              {
                title: 'Статья бюджетной формы',
                dataIndex: 'budgetLineItemNameFrom',
                render: budgetLineItemName => (
                  <WhiteSpace>
                    {tooltipFullText(budgetLineItemName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: 'Направление расходов',
                dataIndex: 'expenseDirectionNameFrom',
                render: expenseDirectionName => (
                  <WhiteSpace>
                    {tooltipFullText(expenseDirectionName, 35)}
                  </WhiteSpace>
                )
              },
              {
                title: 'Месяц',
                dataIndex: 'monthFromIndex',
                render: month => MONTH[month - 1]
              },
              {
                title: 'Перенесенная сумма',
                dataIndex: 'sum',
                key: 'sum',
                render: sum => toLocalStringRu(sum)
              }
            ]}
            data={[...redistributionData, ...data].filter(
              item => item.isOpenPeriod === false
            )}
            pagination={false}
          />
        </GridItem>
      </Grid>
    </Modal>
  );
};
