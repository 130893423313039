// @flow
import {Link} from '@reach/router';
import Icon from 'antd/lib/icon';
import notification from 'antd/lib/notification';
import React, {Component} from 'react';
import styled from 'styled-components';
import {notificationLoading} from '../../../../components/Notifications';
import Popover from '../../../../components/ui/Popover';
import {COLUMNS, MONTH} from '../lib';
import {batteryCalculationApi} from '../../../../lib/api';
import type {BatteryCalculation} from '../../../../lib/types';
import {VehiclePlan} from '../../../../lib/types';
import Common from './components/Common';
import {toLocalStringRu} from '../../../../lib/helpers';
import {calculationStatusEnum} from '../../../../lib/enum';
import moment from 'moment/moment';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';

type Props = {
  location?: Location & { state: { page: number } },
  vehiclePlanId?: number
};

type State = {
  monthData: ?BatteryCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

const StyledIcon = styled(Icon)`
  position: relative;
  top: -1px;
  right: -4px;
`;

class BudgetVehicleBattery extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };

  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }

  handlePrint = async (filter: any) => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'print'
      });
      await batteryCalculationApi.print(vehiclePlanId, filter);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: 'Не удалось выполнить'
      });
    } finally {
      notification.close('print');
    }
  };

  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null
    });
  };

  render() {
    const { location, vehiclePlanId } = this.props;
    const { monthData, monthIndex, vehiclePlan, openPeriod } = this.state;
    const columns = [
      ...COLUMNS.map(column => {
        return column.key === 'licensePlate'
          ? {
              ...column,
              render: (licensePlate: string, record: BatteryCalculation) => {
                return record.hasNoCost
                  ? licensePlate && (
                      <Popover
                        placement="right"
                        width={150}
                        zIndex={3000}
                        content={
                          'Необходимо проверить наличие аналога АКБ или стоимости в справочнике МТРиО'
                        }
                      >
                        <Link
                          to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                        >
                          {licensePlate}{' '}
                        </Link>
                        <StyledIcon
                          type="warning"
                          style={{ color: '#eccd0e' }}
                        />
                      </Popover>
                    )
                  : licensePlate && (
                      <Link
                        to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}
                      >
                        {licensePlate}
                      </Link>
                    );
              }
            }
          : { ...column };
      }),
      ...MONTH.map((month, index) => ({
        title: month.title,
        width: '150',
        dataIndex: `months[${index}].cost`,
        render: (cost: number) =>
          toLocalStringRu(cost, { minimumFractionDigits: 2 }),
        onCell: monthData => ({
          onClick: () => {
            const date = moment().month(index).format();
            if (monthData.id &&
                vehiclePlan?.batteryCalculationStatus === calculationStatusEnum.draft &&
                openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
              this.setState({ monthData, monthIndex: index });
            }
          }
        })
      })),
      {
        title: 'Итог',
        dataIndex: 'sum',
        render: (sum: number) =>
          toLocalStringRu(sum, { minimumFractionDigits: 2 })
      }
    ];
    return (
      <>
        <Common
          location={location}
          columns={columns}
          vehiclePlanId={vehiclePlanId}
          calcScrollY={430}
          crud={batteryCalculationApi}
          statusField={'batteryCalculationStatus'}
          title={'План расхода АКБ'}
          handlePrint={this.handlePrint}
          editingModalData={{
            monthData,
            monthIndex,
            handleCancel: this.handleCancel,
            setVehiclePlan: vehiclePlan => this.setState({ vehiclePlan })
          }}
          linksToDictionaries={{ МТРиО: '/admin/mdm' }}
        />
      </>
    );
  }
}

export default BudgetVehicleBattery;
