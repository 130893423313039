import React, {memo, useEffect, useState} from 'react';
import moment from 'moment';
import {Section, SectionTitle} from '../../../../../components/layout';
import {AntTable} from '../../../../../components/ui';
import styled from 'styled-components';
import {OilConsumpionType} from '../../../../../lib/types/oilConsumption';
import {
  fuelAndOilType,
  monthsNamesTranslitEnum,
  oilConsumptionStatuses,
  oilUnitsOfMeasurementFromParusTranslit,
} from '../../../../../lib/enum';
import CardFilter from './CardFilter';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import {typesOfOilsApi} from '../../../../../lib/api';
import OilSelect from './OilSelect';
import {TypesOfOilsItem} from '../../../../../lib/types/typesOfOils';

const Content = styled.div`
  padding: 16px;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 7px;
`;

const InnerTable = ({ data, editMode, updateData, setEditModeOff }) => {
  const { oilConsumptions } = data;
  const [editedData, setEditedData] = useState({});
  const [typesOfOils: TypesOfOilsItem[], setTypesOfOils] = useState([]);
  const columns = [
    {
      title: 'Номенклатура (наименование)',
      dataIndex: 'name'
    },
    {
      title: 'Мнемокод',
      dataIndex: 'mnemocode'
    },
    {
      title: 'Наименование',
      dataIndex: 'uatToParusOilType',
      width: 300,
      render: (type, item) => {
        if (editMode) {
          return (
            <OilSelect
              data={typesOfOils}
              oilTypeId={
                !!editedData[item.id]?.oil
                  ? editedData[item.id].oil.id
                  : item.uatToParusOilType?.id
              }
              onChange={data =>
                setEditedData({
                  ...editedData,
                  [item.id]: {
                    ...editedData[item.id],
                    oil: data
                  }
                })
              }
            />
          );
        } else {
          if (!type) {
            return 'Нет данных';
          }
          return type?.parusOil.name;
        }
      },
      key: 'oilName'
    },
    {
      title: 'Тип масла',
      dataIndex: 'uatToParusOilType',
      key: 'typeOfOil',
      render: (type, item) => {
        if (editMode) {
          return !!editedData[item.id]?.oil
            ? fuelAndOilType[editedData[item.id].oil.oilType]
            : !!type
            ? fuelAndOilType[type?.oilType]
            : 'Нет данных';
        } else {
          if (!type) {
            return 'Нет данных';
          }
          return fuelAndOilType[type?.oilType];
        }
      }
    },
    {
      title: 'Количество',
      dataIndex: 'parusQuantity'
    },
    {
      title: 'Единица измерения',
      dataIndex: 'measure',
      render: measure => oilUnitsOfMeasurementFromParusTranslit[measure]
    },
    {
      title: 'Количество в литрах',
      dataIndex: 'litrQuantity',
      render: (value, item) => {
        if (editMode) {
          return (
            <InputNumber
              size="small"
              defaultValue={value}
              onChange={count =>
                setEditedData({
                  ...editedData,
                  [item.id]: {
                    ...editedData[item.id],
                    count
                  }
                })
              }
              min={0}
            />
          );
        } else {
          return value;
        }
      }
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      render: value => value / 100
    },
    {
      title: 'Документ-подтверждение',
      dataIndex: 'documentNumber'
    }
  ];
  const [filters, setFilters] = useState(null);
  const [filteredOilConsumptions, setFilteredOilConsumptions] = useState(null);
  const filterItems = (
    items: OilConsumpionType[],
    key: string,
    value: string
  ) => {
    if (!value) {
      return items;
    }
    return items.filter(item => {
      if (key === 'oilTypes') {
        return item.uatToParusOilType?.oilType === value;
      } else {
        return item[key].toLowerCase() === value.toLowerCase();
      }
    });
  };
  const getTypesOfOils = async () => {
    const { data } = await typesOfOilsApi.fetch();
    if (data.length) {
      await setTypesOfOils(data);
    }
  };
  useEffect(() => {
    if (filters) {
      const keys = Object.keys(filters);
      let items = oilConsumptions;
      keys.forEach(key => {
        items = filterItems(items, key, filters[key] && filters[key].trim());
      });
      setFilteredOilConsumptions(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  useEffect(() => {
    getTypesOfOils();
  }, []);
  return (
    <>
      <CardFilter
        filters={filters}
        cleanFilter={() => {
          setFilters(null);
          setFilteredOilConsumptions(null);
        }}
        applyFilter={filtersData => {
          if (filtersData) {
            setFilters(filtersData);
          }
        }}
      />
      <Section>
        <SectionTitle divider>
          <Title>Филиал: {`${data.orgUnitName} ${data.orgUnitMnemonic}`}</Title>
          <Title>Месяц: {monthsNamesTranslitEnum[data.month]}</Title>
          <Title>
            Дата документа:{` ${moment(data.created).format('DD.MM.YYYY')}`}
          </Title>
          <Title style={{ marginTop: '20px' }}>
            Статус: {oilConsumptionStatuses[data.status]}
          </Title>
        </SectionTitle>
        <Content>
          <AntTable
            bordered
            data={filteredOilConsumptions || oilConsumptions}
            columns={columns}
            pagination={false}
            style={{ borderBottom: '1px solid #e8e8e8' }}
            rowClassName={item =>
              !item.uatToParusOilType && !editMode ? 'table-row-error' : ''
            }
          />
          {editMode && (
            <div style={{ marginTop: '10px' }}>
              <Button
                type="primary"
                onClick={() => {
                  updateData(editedData);
                  setEditedData({});
                }}
              >
                Сохранить
              </Button>
              <Button
                onClick={() => {
                  setEditedData({});
                  setEditModeOff();
                }}
                style={{ marginLeft: '10px' }}
              >
                Отменить
              </Button>
            </div>
          )}
        </Content>
      </Section>
    </>
  );
};

export default memo(InnerTable);
