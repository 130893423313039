//@flow
import {Link} from '@reach/router';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';

import Field from '../../../../../components/card/Field';
import {FormField} from '../../../../../components/Form';
import {SectionContent} from '../../../../../components/hoc/common/components/elements';
import CommonForm from '../../../../../components/hoc/common/handbook/CommonForm';
import {SectionTitle} from '../../../../../components/layout';
import Grid, {GridItem} from '../../../../../components/layout/Grid';
import VehicleContractPlanSelect from '../../../../../components/selects/VehicleContractPlanSelect';
import VehiclePlanSelect from '../../../../../components/selects/VehiclePlanSelect';
import {branchBudgetSummaryApi, fetchRequest} from '../../../../../lib/api';
import {
  accessTypeEnum,
  branchBudgetSummaryCognosStatus,
  branchBudgetSummaryCognosStatusEnum,
  branchBudgetSummaryCognosTypeEnum,
  budgetLineItemBudgetInline,
  budgetSummaryStatus,
  budgetSummaryStatusEnum,
  cognosSendingStatusEnum,
  cognosSendingStatuses,
  contractVehiclePlanStatusEnum,
  vehiclePlanStatusEnum,
} from '../../../../../lib/enum';
import {
  contractVehiclePlanInfoString,
  formatDateRangeString,
  formatDateTimeToString,
  plus,
  vehiclePlanInfoString,
} from '../../../../../lib/helpers';
import {printNotification} from '../../../../../lib/notificationWrapper';
import type {
  BranchBudgetSummary,
  BranchBudgetSummaryLineItem,
  BudgetLineItemBudgetType,
  BudgetSummary,
  UserAccess,
} from '../../../../../lib/types';
import type {BudgetSummaryMonth} from '../../../../../lib/types/budgetSummary';

import Table from '../../../details/components/Table';
import BranchTabs from '../BranchTabs';
import Columns from './../TableColumns';
import {editDetailedBudgetAccessRight} from '../../../details/accessRight';
import {withUserAccess} from '../../../../withUserAccess';
import moment from 'moment';
import notification from 'antd/lib/notification';
import {notificationLoading} from '../../../../../components/Notifications';

type Props = {
  branchBudgetSummary?: BranchBudgetSummary,
  budgetSummary: BudgetSummary,
  orgUnitId: number,
  onSubmit: (payload: any) => any,
  userAccess: UserAccess[],
  source: $Keys<branchBudgetSummaryCognosTypeEnum>,
  setBranchData: () => void
};

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0;
`;

const Block = styled(Grid)`
  margin-bottom: 20px;
  border-bottom: 1px solid #adb8c3;
`;

export default withUserAccess((props: Props) => {
  const {
    userAccess,
    branchBudgetSummary,
    budgetSummary,
    onSubmit,
    orgUnitId,
    source,
    setBranchData
  } = props;
  const [
    originalBranchData: ?BranchBudgetSummary,
    setOriginalBranchData
  ] = useState(null);

  const fetchBranchBudgetSummary = async id => {
    try {
      const data = await branchBudgetSummaryApi.get(id);
      setOriginalBranchData(data);
    } catch (e) {
      notification.error({
        message: 'Ошибка запроса филиалов',
        description: e.message
      });
    }
  };

  const fetchBranches = async () => {
    try {
      return await branchBudgetSummaryApi.fetch({
        budgetSummaryId: budgetSummary.id
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка запроса бюджета филиала',
        description: e.message
      });
    }
  };

  // тут идут запросы бюджетов филиалов и конкретного бюджета филиала
  useEffect(() => {
    if (orgUnitId > 0) {
      fetchBranches().then(({ data }: BranchBudgetSummary[]) => {
        const currentBranchBudget = data.find(
          branch =>
            branch.orgUnitId === +orgUnitId &&
            branch.type === branchBudgetSummaryCognosTypeEnum.primary
        );
        if (currentBranchBudget) {
          fetchBranchBudgetSummary(currentBranchBudget.id);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filter] = useState({
    status: 'approved',
    budgetStatus: 'calculationDone',
    budgetVersionId: budgetSummary?.budgetVersionId
  });

  const print = () => {
    printNotification(
      async () =>
        branchBudgetSummary?.id &&
        (await branchBudgetSummaryApi.print(branchBudgetSummary.id))
    );
  };

  const summator = (months, ids: number[]) =>
    months.reduce(
      (sum, month) => {
        let summator = sum;
        if (ids.includes(month.month)) {
          summator.plan = plus(summator.plan, month.plan);
        }
        return summator;
      },
      {
        plan: 0
      }
    );

  const loadBudgetFromCognos = async () => {
    notificationLoading({
      message: 'Выполняется загрузка данных из ИСУ',
      key: 'loading'
    });
    await fetchRequest
      .put(`/BranchBudgetSummary/loadFromCognos/${originalBranchData.id}`)
      .then(data => {
        notification.success({
          message: 'Данные успешно получены'
        });
        setBranchData(data);
      })
      .catch(e => {
        notification.error({
          message: 'Не удалось загрузить данные',
          description: e.message
        });
      })
      .finally(() => {
        notification.close('loading');
      });
  };

  const isAdmin = userAccess.includes(accessTypeEnum.admin);

  // проверка, что все условия для отображения кнопки для обычных пользователей (не админов) выполнены
  const showDownloadButton =
    originalBranchData &&
    originalBranchData.cognosStatus ===
      branchBudgetSummaryCognosStatusEnum.approved &&
    (originalBranchData.selfVehiclePlan.status !==
      vehiclePlanStatusEnum.approvedAfterCognos ||
      originalBranchData.contractVehiclePlan.status !==
        contractVehiclePlanStatusEnum.approvedAfterCognos);

  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        data={branchBudgetSummary}
        useFooter={false}
      >
        {({ values, setFieldValue, handleSubmit, dirty, isSubmitting }) => {
          let itogData: Map<
            BudgetLineItemBudgetType,
            BudgetSummaryMonth & { itogAfterLine: number }
          > = new Map();

          const data =
            values.budgetSummaryLineItems?.map(
              (budget: BranchBudgetSummaryLineItem, index: number) => {
                const months = [
                  ...budget.months,
                  {
                    month: 13,
                    ...summator(budget.months, [1, 2, 3])
                  },
                  {
                    month: 14,
                    ...summator(budget.months, [4, 5, 6])
                  },
                  {
                    month: 15,
                    ...summator(budget.months, [7, 8, 9])
                  },
                  {
                    month: 16,
                    ...summator(budget.months, [10, 11, 12])
                  },
                  {
                    month: 17,
                    ...summator(
                      budget.months,
                      Array.from({ length: 12 }, (_, i) => i + 1)
                    )
                  }
                ];
                // Формируем итог по месяцам
                const branchBudget = itogData.get(
                  budget.budgetLineItemBudgetType
                );
                itogData.set(budget.budgetLineItemBudgetType, {
                  itogAfterLine: index,
                  sum: plus(branchBudget?.sum ?? 0, budget.sum ?? 0),
                  months: months.map((month: BudgetSummaryMonth) => ({
                    month: month.month,
                    plan: plus(
                      branchBudget?.months?.find(el => el.month === month.month)
                        ?.plan ?? 0,
                      month.plan ?? 0
                    )
                  }))
                });

                return {
                  ...budget,
                  expenseDirectionName: 'Итого',
                  rowId: index,
                  months,
                  expenseDirections: budget.expenseDirections.map(
                    (expenseDirection, index2) => ({
                      ...expenseDirection,
                      rowId: index * 1000 + index2,
                      months: [
                        ...expenseDirection.months,
                        {
                          month: 13,
                          ...summator(expenseDirection.months, [1, 2, 3])
                        },
                        {
                          month: 14,
                          ...summator(expenseDirection.months, [4, 5, 6])
                        },
                        {
                          month: 15,
                          ...summator(expenseDirection.months, [7, 8, 9])
                        },
                        {
                          month: 16,
                          ...summator(expenseDirection.months, [10, 11, 12])
                        },
                        {
                          month: 17,
                          ...summator(
                            expenseDirection.months,
                            Array.from({ length: 12 }, (_, i) => i + 1)
                          )
                        }
                      ]
                    })
                  )
                };
              }
            ) ?? [];

          if (data.length > 0) {
            let rowId = 0;

            for (let [budgetLineItemBudgetName, branchBudget] of itogData) {
              rowId--;
              // Не выводим undefined
              if (budgetLineItemBudgetName)
                // Вставляем итог с учетом уже вставленных итогов
                data.splice(branchBudget.itogAfterLine - rowId, 0, {
                  ...branchBudget,
                  budgetLineItemName:
                    'Итого по ' +
                    budgetLineItemBudgetInline[budgetLineItemBudgetName],
                  rowId
                });
            }
          }
          const canEdit =
            (values.status === budgetSummaryStatusEnum.created ||
              values.status === budgetSummaryStatusEnum.declined) &&
            userAccess.some(access =>
              editDetailedBudgetAccessRight.includes(access)
            );
          return (
            <>
              <Fragment noWrapMe>
                <SectionTitle
                  divider
                  noWrapMe
                  suffix={
                    <>
                      {orgUnitId > 0 &&
                        source === branchBudgetSummaryCognosTypeEnum.cognos &&
                        userAccess.some(access => {
                          return [
                            accessTypeEnum.loadingBudgetFromCognos,
                            accessTypeEnum.admin
                          ].includes(access);
                        }) && (
                          <Button
                            type="primary"
                            onClick={loadBudgetFromCognos}
                            style={{ marginRight: '10px' }}
                            disabled={!(showDownloadButton || isAdmin)}
                          >
                            Загрузить
                          </Button>
                        )}
                      {orgUnitId > 0 && <Button onClick={print}>Печать</Button>}
                    </>
                  }
                >
                  <BranchTabs
                    id={budgetSummary.id}
                    orgUnitId={orgUnitId}
                    branchBudgetStatus={branchBudgetSummary?.status}
                    showChildTabs={orgUnitId > 0}
                    source={source}
                  />
                </SectionTitle>
              </Fragment>
              <SectionContent noWrapMe>
                {values.id > 0 && (
                  <>
                    {source === branchBudgetSummaryCognosTypeEnum.cognos && (
                      <Block gutter="16px">
                        <GridItem>
                          <FormField
                            label="Дата загрузки данных"
                            name="cognosLoadDate"
                          >
                            {({ value }) => moment(value).format('DD.MM.YYYY')}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField label="Год" name="cognosYear">
                            {({ value }) => value || '-'}
                          </FormField>
                        </GridItem>
                        <GridItem>
                          <FormField label="Версия" name="cognosVersion">
                            {({ value }) => value || '-'}
                          </FormField>
                        </GridItem>
                      </Block>
                    )}
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField label="Наименование" required name="name">
                          {({ name, value }) => {
                            return canEdit ? (
                              <Input
                                value={value}
                                onChange={e =>
                                  setFieldValue(name, e.target.value)
                                }
                              />
                            ) : (
                              values.name
                            );
                          }}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Статус" name="status">
                          {({ value }) => {
                            return budgetSummaryStatus[value];
                          }}
                        </FormField>
                      </GridItem>
                      {!!values.declineReason &&
                        values.status !== budgetSummaryStatusEnum.approved && (
                          <GridItem fullWidth>
                            <FormField
                              label="Причина отклонения"
                              name="declineReason"
                            >
                              {({ value }) => value}
                            </FormField>
                          </GridItem>
                        )}
                      <GridItem>
                        <FormField
                          label="Наименование бюджета СТС"
                          required={!values.contractVehiclePlanId}
                          name="selfVehiclePlanId"
                        >
                          {({ name, value }) => {
                            return canEdit ? (
                              <VehiclePlanSelect
                                value={value}
                                onChange={(value?: number, option: any) => {
                                  setFieldValue(
                                    'selfVehiclePlan',
                                    option?.props?.vehiclePlan
                                  );
                                  setFieldValue(name, value);
                                }}
                                filter={filter}
                              />
                            ) : (
                              !!values.selfVehiclePlan && (
                                <Link to={`/budget/vehicle/${value}/card`}>
                                  {vehiclePlanInfoString(
                                    values.selfVehiclePlan,
                                    source === branchBudgetSummaryCognosTypeEnum.cognos ||
                                    source === branchBudgetSummaryCognosTypeEnum.corrected
                                  )}
                                </Link>
                              )
                            );
                          }}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Наименование бюджета НТС"
                          required={!values.selfVehiclePlanId}
                          name="contractVehiclePlanId"
                        >
                          {({ name, value }) => {
                            return canEdit ? (
                              <VehicleContractPlanSelect
                                value={value}
                                onChange={(value?: number, option: any) => {
                                  setFieldValue(
                                    'contractVehiclePlan',
                                    option?.props?.vehiclePlan
                                  );
                                  setFieldValue(name, value);
                                }}
                                filter={filter}
                              />
                            ) : (
                              !!values.contractVehiclePlan && (
                                <Link
                                  to={`/budget/contract-vehicle/fixed/${
                                    source ===
                                    branchBudgetSummaryCognosTypeEnum.primary
                                      ? 'beforeCognos'
                                      : 'afterCognos'
                                  }/${value}/card`}
                                >
                                  {contractVehiclePlanInfoString(
                                    values.contractVehiclePlan,
                                    source ===
                                      branchBudgetSummaryCognosTypeEnum.cognos ||
                                      source ===
                                        branchBudgetSummaryCognosTypeEnum.corrected
                                  )}
                                </Link>
                              )
                            );
                          }}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        {values.selfVehiclePlan && (
                          <Field label="Период формирования">
                            {`${formatDateRangeString(
                              values.selfVehiclePlan.startDate,
                              values.selfVehiclePlan.endDate,
                              'DD.MM.YYYY'
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.contractVehiclePlan && (
                          <Field label="Период формирования">
                            {`${formatDateRangeString(
                              values.contractVehiclePlan.startDate,
                              values.contractVehiclePlan.endDate,
                              'DD.MM.YYYY'
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.selfVehiclePlan && (
                          <Field
                            label={
                              source ===
                              branchBudgetSummaryCognosTypeEnum.cognos
                                ? 'Дата формирования копии'
                                : 'Дата формирования'
                            }
                          >
                            {`${formatDateTimeToString(
                              values.selfVehiclePlan.date,
                              'DD.MM.YYYY'
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                      <GridItem>
                        {values.contractVehiclePlan && (
                          <Field
                            label={
                              source ===
                              branchBudgetSummaryCognosTypeEnum.cognos
                                ? 'Дата формирования копии'
                                : 'Дата формирования'
                            }
                          >
                            {`${formatDateTimeToString(
                              values.contractVehiclePlan.date,
                              'DD.MM.YYYY'
                            )}`}
                          </Field>
                        )}
                      </GridItem>
                    </Grid>
                    {source !== branchBudgetSummaryCognosTypeEnum.cognos && (
                      <>
                        <Title>Статусы отправки в ИСУ Бюджетирование</Title>
                        <Grid>
                          <GridItem
                            fullWidth
                            customStyles={{ marginBottom: '10px' }}
                          >
                            <FormField
                              label="Основной бюджет"
                              name="cognosStatus"
                              style={{ fontWeight: 'bold' }}
                            >
                              {({ value }) =>
                                branchBudgetSummaryCognosStatus[value]
                              }
                            </FormField>
                          </GridItem>
                          {values.cognosVehicleTariffsStatus !==
                            cognosSendingStatusEnum.notNecessary && (
                            <GridItem>
                              <FormField
                                label="Статус отправки тарифов НТС"
                                name="cognosVehicleTariffsStatus"
                              >
                                {({ value }) =>
                                  value ===
                                  branchBudgetSummaryCognosStatusEnum.notProcessed
                                    ? 'Не обработан в ИСУ Бюджетирование'
                                    : cognosSendingStatuses[value]
                                }
                              </FormField>
                            </GridItem>
                          )}
                          {values.cognosRegulationsStatus !==
                            cognosSendingStatusEnum.notNecessary && (
                            <GridItem>
                              <FormField
                                label="Статус отправки регламентов НТС"
                                name="cognosRegulationsStatus"
                              >
                                {({ value }) => cognosSendingStatuses[value]}
                              </FormField>
                            </GridItem>
                          )}
                          {values.cognosContractBudgetDetailsStatus !==
                            cognosSendingStatusEnum.notNecessary && (
                            <GridItem>
                              <FormField
                                label="Статус отправки детализации бюджета НТС"
                                name="cognosContractBudgetDetailsStatus"
                              >
                                {({ value }) => cognosSendingStatuses[value]}
                              </FormField>
                            </GridItem>
                          )}
                          {values.cognosPlanFuelConsumptionStatus !==
                            cognosSendingStatusEnum.notNecessary && (
                            <GridItem>
                              <FormField
                                label="Статус отправки планового расхода ГСМ"
                                name="cognosPlanFuelConsumptionStatus"
                              >
                                {({ value }) => cognosSendingStatuses[value]}
                              </FormField>
                            </GridItem>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {dirty && (
                  <GridItem>
                    <Field label=" ">
                      <Button
                        type="primary"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        Сохранить
                      </Button>
                    </Field>
                  </GridItem>
                )}
                <Table
                  data={data}
                  columns={[
                    ...Columns,
                    {
                      dataIndex: 'cognosStatus',
                      title: 'Статус ИСУ Бюджетирование',
                      key: 'cognosStatus',
                      width: 250,
                      render: cognosStatus =>
                        cognosStatus
                          ? branchBudgetSummaryCognosStatus[cognosStatus]
                          : null
                    }
                  ]}
                  loading={true}
                />
              </SectionContent>
            </>
          );
        }}
      </CommonForm>
    </>
  );
});
