// @flow

import React, { Component } from 'react';
import qs from 'query-string';
import Popconfirm from 'antd/lib/popconfirm';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import { Icon, Operations, Table } from "../../../components/ui";
import { driverApi } from "../../../lib/api";
import { accessTypeEnum, driverStatuses, positions as positionsTranslate} from "../../../lib/enum";
import { getListInitialState, setQueryParams } from "../../../lib/helpers";
import { notificationLoading } from '../../../components/Notifications';
import { navigate } from '@reach/router';
import { Section } from '../../../components/layout';
import Filter from '../Filter';
import { DriverType } from '../../../lib/enum/driverType';


const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export const canAddDriverAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingDriver
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<Driver> & {
  filter: DriverFilterParams
};

class ElectricVehiclesDrivers extends Component<Props, State> {
    static defaultProps = {
      location: {}
    };
  
    state = {
      ...getListInitialState(),
      filter: {}
    };
  
    fetchDrivers = async (
      page: number = 1,
      params: FetchListParams<DriverFilterParams> = {}
    ) => {
      try {
        const { filter } = this.state;
        this.setState({ loading: true });
        const { data, totalCount } = await driverApi.fetchDrivers({
          ...filter,
          page,
          ...{type: DriverType.EVUser},
          ...params
        });
        setQueryParams({ page });
        this.setState({ data, totalCount, page });
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      } finally {
        this.setState({ loading: false });
      }
    };
  
    componentDidMount() {
      const { page, ...filter } = qs.parse(window.location.search);
      this.setState({ filter }, async () => await this.fetchDrivers(page));
    }
  
    canAdd = () =>
      this.props.userAccess.some(access => canAddDriverAccess.includes(access));
  
    async deleteDriver(id: number) {
      try {
        notificationLoading({
          message: 'Удаление...',
          key: 'deleting'
        });
        const { page, pageSize } = this.state;
        await driverApi.deleteDriver(id);
        await this.fetchDrivers(page, { pageSize });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      } finally {
        notification.close('deleting');
      }
    }
  
    columns = [
      {
        title: '№',
        dataIndex: 'id',
        width: 25
      },
      {
        title: 'ФИО',
        dataIndex: 'employeeId',
        sorter: true,
        sorterKey: 'employee.person.lastName',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: Driver) =>
          record.employee
            ? `${record.employee.lastname || ''} ${record.employee.firstname ||
                ''} ${record.employee.middlename || ''}`
            : ''
      },
      {
        title: 'Подразделение',
        sorter: true,
        sorterKey: 'employee.node.name',
        dataIndex: 'orgUnitName'
      },
      {
        title: 'Должность',
        sorter: true,
        dataIndex: 'employee.positionTitle',
        render: (position: string) =>
          position?.length ? position : "-"
      },
      {
        title: 'Статус',
        sorter: true,
        dataIndex: 'status',
        render: (status: string): string => driverStatuses[status]
      },
      {
        title: ' ',
        width: 20,
        dataIndex: 'delete',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: OrgUnitNode): any => (
          <Operations>
            {this.canAdd() && (
              <Popconfirm
                title="Вы действительно хотите удалить?"
                okText="Да"
                cancelText="Нет"
                onConfirm={async () => await this.deleteDriver(record.id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            )}
          </Operations>
        )
      }
    ];
  
    handleRowClick = (id: number) => navigate(`/admin/drivers/form/${id}`);
  
    cleanFilter = () => this.setState({ filter: {} }, this.fetchDrivers);
  
    applyFilter = (filter: DriverFilterParams) =>
      this.setState({ filter }, this.fetchDrivers);
  
    render() {
      const { location } = this.props;
      const { data, totalCount, page, pageSize, loading, filter } = this.state;
      const canAdd = this.canAdd();
      return (
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={
              canAdd
                ? record => ({
                    onClick: () => this.handleRowClick(record.id)
                  })
                : null
            }
            rowKey="id"
            loading={loading}
            columns={this.columns}
            data={data}
            fetch={this.fetchDrivers}
            fetchOnMount
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      );
    }
  }

  export default ElectricVehiclesDrivers;