// @flow
import React, {Component} from 'react';

import notification from 'antd/lib/notification';
import type {FormikErrors} from 'formik';
import {connect} from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import {RequiredFieldMessage} from '../../components/Form';

import type {AppState} from '../../ducks/redux';
import {changeStatus, cleanTrip, fetchTripForVerification, verifyTrip} from '../../ducks/trip';
import {routeApi, shiftApi, tripApi, tripRangeApi} from '../../lib/api';
import {tripStatusEnum} from '../../lib/enum';
import type {AttachedEquipment, KilometrageInfo, Trip, TripRange, TripStatus} from '../../lib/types';
import {Panel} from './../../components/layout';
import {convertFromMToKm, formatDateRangeString, minus, navigate} from '../../lib/helpers';

import {directions} from '../../lib/gis';
import VerificationForm from './components/VerificationForm';
import {notificationLoading} from '../../components/Notifications';
import {WorkAccountingTypes} from '../../lib/types/vehicleModel';

type Props = {
  trip: Trip,
  fetchTripForVerification: (id: number, onCancel: () => any) => Promise<Trip>,
  verifyTrip: (trip: Trip) => void,
  cleanTrip: () => void,
  updateTrip: Function,
  tripId: number,
  employeeId: number,
  changeStatus: Function
};

type State = {
  kilometragePopconfirmVisible: boolean,
  kilometrage: number,
  trip: Trip,
  actualRouteGeometry: any,
  shiftEmployees: any,
  vehicleAttachedEquipments: AttachedEquipment[]
};

export class TripVerification extends Component<Props, State> {
  state = {
    kilometragePopconfirmVisible: false,
    kilometrage: 0,
    trip: this.props.trip,
    actualRouteGeometry: null,
    shiftEmployees: {},
    vehicleAttachedEquipments: []
  };

  async componentDidMount() {
    const { tripId, trip } = this.props;
    try {
      await this.props.cleanTrip();
      await this.props.fetchTripForVerification(
        parseInt(tripId, 10),
        this.handleCancel
      );
      // Если ТС заархивирована
      if (trip && trip.vehicle) {
        if (trip.vehicle.isDeleted) {
          await navigate(`/trips/self/${tripId}/card`);
          notification.error({
            message: 'Нельзя таксировать заархивированные ТС'
          });
        }
      }
      const shiftEmployees = await shiftApi.fetchShiftEmployeesByTripId(tripId);
      this.setState({
        shiftEmployees
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  async changeStatus(status: TripStatus) {
    await this.props.changeStatus(parseInt(this.props.tripId, 10), status);
  }

  handleCancel = async () => {
    navigate(`/trips/self/${this.props.tripId}/card`);
  };

  handleSubmit = async (trip: Trip) => {
    trip = {
      ...trip,
      issuedFuel: trip.issuedFuel ?? 0
    }

    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const { tripRanges = [] } = trip;
      const tripRangesAmount = sumBy(tripRanges, 'amount');
      const distance = minus(trip.odometerAtEnd, trip.odometerAtStart);

      if (tripRanges.length > 0 && distance !== tripRangesAmount) {
        notification.error({
          message: 'Ошибка',
          description:
            'Сумма отрезков маршрута с топливными коэффициентами должна совпадать со значением пробега'
        });
        return;
      }
      await this.props.verifyTrip(trip);
      await this.changeStatus(tripStatusEnum.verified);
      await Promise.all(
        tripRanges.map((tripRange: TripRange) =>
          tripRangeApi.addTripRange({
            ...tripRange,
            sumFuelMultiplier: sumBy(tripRange.fuelMultipliers, 'value') || 0
          })
        )
      );
      notification.success({
        message: 'Успешно',
        description: 'Таксировка прошла успешно'
      });
      navigate(`/trips/self/${this.props.tripId}/card`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  validate = (values: Trip) => {
    let errors: FormikErrors<Trip> = {};
    if (!values.odometerAtEnd) {
      errors.odometerAtEnd = new RequiredFieldMessage('Обязательное поле');
    } else if (values.odometerAtEnd < values.odometerAtStart) {
      errors.odometerAtEnd =
        'Пробег при выезде не может быть меньше фактического пробега';
    }
    if (!values.endDate) {
      errors.endDate = new RequiredFieldMessage('Обязательное поле');
    }
    if (!values.startMedicId) {
      errors.startMedicId = new RequiredFieldMessage('Обязательное поле');
    }
    if (!values.startMedicCheckupDate) {
      errors.startMedicCheckupDate = new RequiredFieldMessage(
        'Обязательное поле'
      );
    }
    if (!values.endMedicId) {
      errors.endMedicId = new RequiredFieldMessage('Обязательное поле');
    }
    if (!values.endMedicCheckupDate) {
      errors.endMedicCheckupDate = new RequiredFieldMessage(
        'Обязательное поле'
      );
    }

    if (!values.startMechanicId) {
      errors.startMechanicId = new RequiredFieldMessage('Обязательное поле');
    }
    if (!values.startTechCheckupDate) {
      errors.startTechCheckupDate = new RequiredFieldMessage(
        'Обязательное поле'
      );
    }
    if (!values.endMechanicId) {
      errors.endMechanicId = new RequiredFieldMessage('Обязательное поле');
    }
    if (!values.endTechCheckupDate) {
      errors.endTechCheckupDate = new RequiredFieldMessage('Обязательное поле');
    }
    const fuelAtEnd = parseFloat(values.fuelAtEnd);
    // Такая проверка нужна, чтоб убрать NaN
    if (fuelAtEnd < 0 || !fuelAtEnd) {
      errors.fuelAtEnd = 'Неверное количество топлива';
    }
    if (
      values.vehicle &&
      parseFloat(
        values.vehicle?.vehicleModel?.primaryEquipmentFuelConsumption
      ) > 0
    ) {
      const vehicleMachineHoursAtEnd = parseFloat(
        values.vehicleMachineHoursAtEnd
      );
      if (isNaN(vehicleMachineHoursAtEnd)) {
        errors.vehicleMachineHoursAtEnd = new RequiredFieldMessage(
          'Обязательное поле'
        );
      } else if (
        vehicleMachineHoursAtEnd < parseFloat(values.vehicleMachineHoursAtStart)
      ) {
        errors.vehicleMachineHoursAtEnd =
          'Значение не может быть меньше начального значения';
      }
    }
    return errors;
  };

  async componentDidUpdate(prevProps: Props) {
    if (this.props.trip && !prevProps.trip) {
      this.setState({ trip: this.props.trip });
      if (this.props.trip.status === tripStatusEnum.verified) {
        navigate(`/trips/self/${this.props.tripId}/card`);
      }
    }
  }

  getInitialValues = (): $Shape<Trip> => {
    const { trip, shiftEmployees } = this.state;

    if (trip) {
      // currentVehicleMachineHoursAtEnd и currentOdometerAtEnd не должны быть null, это приводит к ошибке при таксировке
      if (!trip.vehicleMachineHoursAtEnd && !trip.currentVehicleMachineHoursAtEnd) {
        trip.currentVehicleMachineHoursAtEnd = trip.vehicleMachineHoursAtEnd;
      }
      if (!trip.odometerAtEnd && !trip.currentOdometerAtEnd) {
        trip.currentOdometerAtEnd = trip.odometerAtEnd;
      }
      const clonedTrip = cloneDeep(trip);
      if (!trip.startMedicId && shiftEmployees.medic) {
        clonedTrip.startMedicId = shiftEmployees.medic.id;
      }
      if (!trip.startMechanicId && shiftEmployees.engineer) {
        clonedTrip.startMechanicId = shiftEmployees.engineer.id;
      }
      return this.calculateFuelConsumption(trip);
    }
    return {};
  };

  /**
   * Подсчет расхода по машчасам
   *
   * ОФР - Общий фактический расход
   * ФРТС - Фактический расход ТС
   * ФРО - Фактический расход оборудования
   * МЧТС - Маш. часы ТС
   * МЧО - Маш. часы оборудования
   * НРТС - Нормативный расход ТС
   * НРО - Нормативный расход оборудования
   *
   * НПМЧ - Показания счетчика маш. часов при выезде (начальные)
   * КПМЧ - Показания счетчика маш. часов при возвращении (конечные)
   *
   * Формула:
   *   // Общий расход
   *   ОФР = ФРТС + ФРО;
   *   // Фактический расход ТС
   *   ФРТС = МЧТС * ФРТС;
   *   // Маш. часы ТС
   *   МЧТС = КПМЧ - НПМЧ;
   *   // Фактический расход оборудования
   *   ФРО = НРО * МЧО;
   */
  calculateWorkHoursFuelConsumption = (trip: Trip) => {
    if (
      trip.vehicle?.vehicleModel?.workAccountingType ===
      WorkAccountingTypes.kilometrage
    ) {
      return 0;
    }
    const vehicleMachineHoursAtStart = parseFloat(
      trip.vehicleMachineHoursAtStart || 0
    );
    const vehicleMachineHoursAtEnd = parseFloat(
      trip.vehicleMachineHoursAtEnd || vehicleMachineHoursAtStart
    );
    const machineHoursFuelConsumption = parseFloat(
      trip.vehicle?.vehicleModel?.primaryEquipmentFuelConsumption || 0
    );
    const attachedEquipmentMachineHours = parseFloat(
      trip.attachedEquipmentMachineHours || 0
    );
    const attachedEquipmentFuelConsumption = parseFloat(
      trip.attachedEquipmentFuelConsumption || 0
    );
    const machineHours = vehicleMachineHoursAtEnd - vehicleMachineHoursAtStart;
    if (machineHours <= 0) {
      return 0;
    }
    const sumFuelMultiplier =
      trip.sumFuelMultiplier &&
      trip.vehicle.vehicleModel.workAccountingType ===
        WorkAccountingTypes.workHours
        ? 1 + trip.sumFuelMultiplier
        : 1;

    return (
      Math.round(
        (machineHoursFuelConsumption * machineHours * sumFuelMultiplier +
          attachedEquipmentMachineHours * attachedEquipmentFuelConsumption) *
          100
      ) / 100
    );
  };

  /**
   * Подсчет расхода по пробегу с коэффициентами
   *
   *  ФР - Фактический расход
   *  НРТС - Нормативный расход ТС, л/1(!)км
   *  НПО - Начальные показания одометра
   *  КПО - Конечные показания одометра
   *  Д - Дистанция
   *  О - Отрезки рейса с длиной и суммарным коэффициентом
   *  ДО - Длина отрезка
   *  СКО - Суммарный коэффициент отрезка
   *  СКТС - Суммарный коэффициент ТС
   *
   *  Формула:
   *  Д = КПО - НПО
   *    При наличии О, ФР считается след. образом:
   *      ФР = ЦИКЛ(ФР + (НРТС * ДО * (1 + СКО)))
   *    При отсутствии О:
   *      ФР = НРТС + Д + (1 + СКТС)
   */
  calculateDistanceFuelConsumption = (trip: Trip) => {
    if (trip.vehicle?.vehicleModel?.workAccountingType === WorkAccountingTypes.workHours) {
      return 0;
    }
    const distance = parseFloat(trip.odometerAtEnd - trip.odometerAtStart);
    if (!distance || distance < 0) {
      return 0;
    }
    const tripRanges = trip.tripRanges || [];
    // Переводим расход в л/км
    const primaryFuelConsumption = (trip.vehicle?.vehicleModel?.primaryFuelConsumption || 0) / 100;

    const sumFuelMultiplier = trip.sumFuelMultiplier
      ? 1 + trip.sumFuelMultiplier
      : 1;
    let fuelConsumption = primaryFuelConsumption * sumFuelMultiplier * distance;
    if (tripRanges.length) {
      tripRanges.forEach((tripRange: TripRange) => {
        fuelConsumption +=
          primaryFuelConsumption *
          tripRange.amount *
          tripRange.sumFuelMultiplier;
      });
      return fuelConsumption;
    }

    return fuelConsumption;
  };

  /**
   * Подсчет оставшегося уровня топлива
   *
   * ФР - Фактический расход (фактический расход по маш. часам + расход по пробегу)
   * В - Выдано ГСМ
   * НУТ - Начальный уровень топлива
   * КУТ - Конечный уровень топлива
   *
   * Формула:
   *  КУТ = НУТ + В - ФР
   */
  calculateFuelAtEnd = (trip: Trip) => {
    const attachedEquipmentsFuelConsumption = trip.tripAttachedEquipmentLinks.reduce(
      (sum, link) => sum + link.fuelConsumption,
      0
    );
    const actualFuelConsumption =
      (trip.engineWorkFuelConsumption || 0) +
      (trip.distanceFuelConsumption || 0) +
      attachedEquipmentsFuelConsumption;
    const fuelAtEnd =
      (trip.fuelAtStart || 0) + (trip.issuedFuel || 0) - actualFuelConsumption;
    return Math.round(fuelAtEnd * 100) / 100;
  };

  calculateFuelConsumption = (trip: Trip) => {
    const engineWorkFuelConsumption = this.calculateWorkHoursFuelConsumption(
      trip
    );
    const distanceFuelConsumption = this.calculateDistanceFuelConsumption(trip);
    return {
      ...trip,
      engineWorkFuelConsumption,
      distanceFuelConsumption,
      fuelAtEnd: this.calculateFuelAtEnd({
        ...trip,
        engineWorkFuelConsumption,
        distanceFuelConsumption
      })
    };
  };

  calculateRoute = async (trip: Trip) => {
    try {
      notificationLoading({
        message: 'Построение маршрута...',
        key: 'buildingRoute'
      });
      const actualWaypoints =
        (trip.actualRoute && trip.actualRoute.waypoints) || [];

      const actualRouteGeometry = await directions(actualWaypoints);
      if (actualRouteGeometry) {
        this.setState((prevState: State) => {
          const distanceAtStart = convertFromMToKm(
            actualRouteGeometry.distanceAtStart
          );
          const distanceAtEnd = convertFromMToKm(
            actualRouteGeometry.distanceAtEnd
          );
          return {
            actualRouteGeometry,
            trip: {
              ...trip,
              distanceAtStart,
              distanceAtEnd
            }
          };
        });
        notification.success({
          message: 'Маршрут успешно построен'
        });
      }
      this.setState({
        actualRouteGeometry
      });
      if (trip.actualRoute) {
        await routeApi.updateRoute(trip.actualRoute);
      }
      await tripApi.updateTrip(trip);
    } catch (err) {
      notification.error({
        message: 'Произошла ошибка при построении маршрута',
        description: err ? err.message : ''
      });
    } finally {
      notification.close('buildingRoute');
    }
  };

  /**
   * Получение данных о пробеге за маршрут из АвтоГРАФа
   */
  getKilometrage = async (): Promise<?KilometrageInfo> => {
    try {
      notificationLoading({
        message: 'Получение данных',
        key: 'getKilometrage'
      });
      const kilometrage = await tripApi.getTripKilometrage(this.props.tripId);
      return {
        ...kilometrage,
        monitoringDistance: convertFromMToKm(kilometrage.monitoringDistance)
      };
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('getKilometrage');
    }
  };

  render() {
    const { trip, actualRouteGeometry, shiftEmployees } = this.state;
    return (
      <>
        <Panel>
          <h1>Таксировка</h1>
          {trip && (
            <p>
              Путевой лист №{trip.idNumber}
              &nbsp;
              <span>{formatDateRangeString(trip.startDate, trip.endDate)}</span>
            </p>
          )}
        </Panel>
        <VerificationForm
          trip={this.getInitialValues()}
          employeeId={this.props.employeeId}
          onCancel={this.handleCancel}
          onSubmit={this.handleSubmit}
          getKilometrage={this.getKilometrage}
          actualRouteGeometry={actualRouteGeometry}
          calculateRoute={this.calculateRoute}
          handleCancel={this.handleCancel}
          shiftEmployees={shiftEmployees}
          calculateWorkHoursFuelConsumption={this.calculateWorkHoursFuelConsumption}
          calculateDistanceFuelConsumption={this.calculateDistanceFuelConsumption}
          calculateFuelConsumption={this.calculateFuelConsumption}
          calculateFuelAtEnd={this.calculateFuelAtEnd}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, ownProps: Props) => ({
    trip: state.trip,
    tripId: parseInt(ownProps.tripId, 10),
    employeeId: state.auth.profile.employeeId
  }),
  {
    verifyTrip,
    cleanTrip,
    fetchTripForVerification,
    changeStatus
  }
)(TripVerification);
