// @flow

import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';

type Props = {
  // Подпись поля
  label?: string,
  // Добавляет красную звездочку
  required?: boolean,
  // Убираем отступы снизу margin-bottom
  mBottomNone?: boolean,
  children: Node,
  labelStyle?: $Shape<CSSStyleDeclaration>,
  valueStyle?: $Shape<CSSStyleDeclaration>
};

const Wrapper = styled.div.attrs({
  className: 'field'
})`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
`;

const Label = styled.p.attrs({
  className: 'field__label'
})`
  color: #838d96;
  margin-bottom: 5px;
`;

const ValueMarginBottomNone = styled.div.attrs({
  className: 'field__value'
})`
  margin-bottom: 0px;
`;
const ValueMarginBottom = styled.div.attrs({
  className: 'field__value'
})`
  margin-bottom: 23px;
`;
const Required = styled.span`
  color: red;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 12px;
`;

/**
 * Поле карточки
 */
export default ({
  label,
  mBottomNone,
  labelStyle,
  valueStyle,
  children,
  required
}: Props) => {
  return (
    <Wrapper>
      {label && (
        <Label style={labelStyle}>
          {label} {required && <Required>*</Required>}{' '}
        </Label>
      )}
      {mBottomNone ? (
        <ValueMarginBottomNone style={valueStyle}>
          {children}
        </ValueMarginBottomNone>
      ) : (
        <ValueMarginBottom style={valueStyle}>{children}</ValueMarginBottom>
      )}
    </Wrapper>
  );
};
