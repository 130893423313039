import React, { useState } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import DatePicker from 'antd/lib/date-picker';

import { formatDateTimeToISOString, navigate } from './../../../lib/helpers';

export default ({ orderId }) => {
  const [copyDate, setCopyDate] = useState(new Date());
  return (
    <Popconfirm
      overlayStyle={{
        zIndex: 2000
      }}
      placement="left"
      title={
        <>
          <p>Выберите дату заявки</p>
          <DatePicker
            popupStyle={{
              zIndex: 2001
            }}
            format="DD.MM.YYYY"
            disabledDate={current => current.isBefore(new Date(), 'day')}
            onChange={(value: Object, dateString: string) => {
              const dateTime = formatDateTimeToISOString(
                value,
                dateString,
                'DD.MM.YYYY'
              );
              setCopyDate(dateTime);
            }}
          />
        </>
      }
      onConfirm={() =>
        navigate(`/orders/${orderId}/copy?date=${copyDate.toString()}`)
      }
    >
      Копировать заявку
    </Popconfirm>
  );
};
