// @flow
import React from 'react';
import uniqBy from 'lodash/uniqBy';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import styled from 'styled-components';
import Upload from 'antd/lib/upload';
import notification from 'antd/lib/notification';
import Modal from 'antd/lib/modal';
import find from 'lodash/find';
import moment from 'moment';
import shortId from 'shortid';

import { apiUrl, fileApi } from '../../../lib/api';
import { isImage, validateFile, prepareUploadItem } from '../../../lib/helpers';
import type { UploadProps, UploadFile } from 'antd/lib/upload';
import tokenManager from '../../../lib/tokenManager';
import fileIcon from '../../../assets/images/file-icon.svg';
import type { MaintenanceFileTag } from '../../../lib/types';
import { notificationLoading } from './../../../components/Notifications';

const StyledUpload = styled(Upload)`
  .ant-upload-list-picture-card .ant-upload-list-item {
    width: 150px;
    height: 150px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-name {
    display: block;
    position: absolute;
    bottom: 0;
    background: #fffc;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
`;

type File = UploadFile & {
  fullName: string,
  path: string,
  url: string,
  contentType: string,
  tag: string,
  contentDisposition: string,
  id: number
};

type Props = {
  onChange: (files: File[]) => void,
  tag: MaintenanceFileTag,
  value: File[],
  multiple?: boolean
};

type State = {
  previewVisible: boolean,
  previewFile: ?File
};

export default class Uploader extends React.Component<Props, State> {
  state: State = {
    previewVisible: false,
    previewFile: null
  };

  validateFile = (file: UploadProps) => {
    const validFileType = validateFile(file.type, this.props.tag);
    if (!validFileType) {
      notification.error({
        message: 'Загружать можно только изображения и PDF!'
      });
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({ message: 'Файл должен быть менее 10MB!' });
    }
    return validFileType && isLt2M;
  };

  handleOpenPreview = (previewFile: File) => {
    this.setState({
      previewFile,
      previewVisible: true
    });
  };

  onRemove = async (file: any): Promise<boolean> => {
    let result = false;
    try {
      if (file.response) {
        notificationLoading({
          message: 'Удаление файла...',
          key: 'saving'
        });
        await fileApi.deleteFile(file.id);
        notification.success({
          message: 'Успешно удалено',
          description: 'Изменения успешно сохранены'
        });
      } else {
        file.status = 'removed';
      }
      result = true;
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
    return result;
  };

  handleClosePreview = () =>
    this.setState({ previewVisible: false, previewFile: null });

  render() {
    const { tag, value, onChange, multiple } = this.props;
    const { previewFile, previewVisible } = this.state;
    return (
      <>
        <StyledUpload
          action={`${apiUrl}/file`}
          listType="picture-card"
          data={{
            tag
          }}
          onPreview={this.handleOpenPreview}
          onRemove={this.onRemove}
          multiple={multiple}
          beforeUpload={(file: UploadProps) => this.validateFile(file)}
          fileList={(value || [])
            .filter(item => item.tag === tag)
            .map(prepareUploadItem)}
          headers={{
            Authorization: `Bearer ${tokenManager.getToken()}`
          }}
          onChange={info => {
            let { file, fileList } = info;

            let currentValue = value.map(prepareUploadItem);

            fileList = fileList.map(item => {
              if (item.response) {
                item.url = item.response.url;
                item.tag = item.response.tag;
                item.id = item.response.id;
                item.contentType = item.response.contentType;
                if (!isImage(item.contentType)) {
                  item.thumbUrl = fileIcon;
                }
              }
              return item;
            });

            if (file.status === 'removed') {
              currentValue = currentValue.filter(item => item.uid !== file.uid);
            }

            fileList = uniqBy(currentValue.concat(fileList), 'uid');

            if (this.props.multiple) {
              onChange(
                fileList.map(file => ({
                  tag,
                  createdTime: moment.utc().toISOString(),
                  uid: shortId.generate(),
                  ...file
                }))
              );
            } else {
              const finded = find(fileList, { uid: file.uid });
              const preparedFile = {
                createdTime: moment.utc().toISOString(),
                uid: shortId.generate(),
                ...(finded || file),
                tag
              };
              onChange([preparedFile]);
            }
          }}
        >
          <Button>
            <Icon type="upload" /> Загрузить
          </Button>
        </StyledUpload>
        {previewFile && (
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleClosePreview}
          >
            {isImage(previewFile.contentType) ? (
              <img
                alt={previewFile.fullName || 'Изображение'}
                style={{ width: '100%' }}
                src={previewFile.url || previewFile.thumbUrl}
              />
            ) : (
              <div>
                Файл недоступен для просмотра.&nbsp;
                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={previewFile.url} target="_blank">
                  Скачать
                </a>
              </div>
            )}
          </Modal>
        )}
      </>
    );
  }
}
