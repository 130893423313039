import React from 'react';
import {TabItem, Tabs} from './../../../../components/ui';
import {getPathWithHistoryParams} from '../../../../lib/helpers';

export default () => (
  <Tabs withRouter>
    <TabItem
      label="Акт выполненных работ хоз. способом"
      url={getPathWithHistoryParams('/admin/act/internal')}
    />
    <TabItem
      label="Дефектно-ресурсная ведомость"
      url={getPathWithHistoryParams('/admin/act/internal/defective-statement')}
    />
    <TabItem label="Реестр смет" url="/admin/act/internal/estimates" />
    <TabItem
      label="ОС-3"
      url={getPathWithHistoryParams('/admin/act/internal/os3')}
    />
    <TabItem
      label="Акт оказания услуг на сторону "
      url={getPathWithHistoryParams('/admin/act/internal/outsourcing-act')}
    />
    <TabItem
      label="Акт списания топлива"
      url={getPathWithHistoryParams('/admin/act/internal/fuels-act')}
    />
    <TabItem
      label="Акт списания масел"
      url={getPathWithHistoryParams('/admin/act/internal/oils-act')}
    />
    <TabItem label="Акт списания МПЗ" url="/admin/act/internal/mpz" />
    <TabItem
      label="Реестр учёта расхода масел"
      url="/admin/act/internal/oil-consumption-accounting"
    />
  </Tabs>
);
