// @flow
import React from 'react';
import notification from 'antd/lib/notification';
// import moment from 'moment';
import Header from '../../../components/layout/Header';
import {Section} from '../../../components/layout';
import {applyMaskToValue} from '../../../lib/helpers';
import type {ReportTaxedTripElectric, ReportTaxedTripsElectricParams} from '../../../lib/types/reportTaxedTripsElectric'; 
import Filter from './components/Filter';
import {reportTaxedTripsElectricsApi} from '../../../lib/api';
import {notificationLoading} from '../../../components/Notifications';
import {formatLicensePlateMask} from '../../../components/masked-inputs/LicensePlateInput';
import {Table} from '../../../components/ui';
import moment from 'moment';

const rowsPerPage = 100;

const initialParams: ReportTaxedTripsElectricParams = {
  //Раскомментировать, если нужны по умолчанию даты текущего дня
  // startDate: moment
  //   .utc()
  //   .startOf('month')
  //   .toISOString(),
  // endDate: moment
  //   .utc()
  //   .endOf('month')
  //   .toISOString(),
  pageSize: rowsPerPage,
};

type State = {
  params: ReportTaxedTripsElectricParams,
  data: ReportTaxedTripElectric[],
  loading: boolean,
  page: number,
  totalCount: number,
  pageSize: number
};

/**
 * Отчет о таксировках
 */
export class TaxedTripsElectricList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false,
    page: 1,
    pageSize: rowsPerPage,
    totalCount: 0
  };

  async componentDidMount() {
    await this.fetchReportTaxedTripsElectric();
  }

  fetchReportTaxedTripsElectric = async (page = 1) => {
    const params = this.state.params;
    try {
      this.setState({ loading: true, page });
      const response = await reportTaxedTripsElectricsApi.fetchReportTaxedTripsElectrics({
        ...params,
        page
      });
      this.setState({ data: response.data, totalCount: response.totalCount });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<ReportTaxedTripsElectricParams>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      },
      page: 1
    }));
  };

  applyParams = () => this.fetchReportTaxedTripsElectric(this.state.page);

  cleanParams = async () => {
    await this.setState({params: initialParams, page: 1});
    await this.fetchReportTaxedTripsElectric(this.state.page);
  }

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing'
      });
      await reportTaxedTripsElectricsApi.printReportTaxedTripsElectrics(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Дата',
      dataIndex: 'startDate',
      render: date => date ? moment(date).utc().format('DD.MM.YYYY') : '-',
    },
    {
      title: 'Номер ПЛ',
      dataIndex: 'tripNumber',
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Марка/модель ТС',
      dataIndex: 'vehicleName'
    },
    {
      title: 'Водитель/ пользователь ЭК',
      dataIndex: 'driverName'
    },
    {
      title: 'Пробег',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`
    },
    {
      title: '% зарядки при выезде из гаража',
      dataIndex: 'fuelAtStart',
      render: (fuelAtStart: number) => `${fuelAtStart.toFixed(2)}`
    },
    {
      title: '% зарядки при заезде в гараж',
      dataIndex: 'fuelAtEnd',
      render: (fuelAtEnd: number) => `${fuelAtEnd.toFixed(2)}`
    },
    {
      title: 'Фактический расход % зарядки',
      dataIndex: 'fuelConsumption',
      render: (fuelConsumption: number) => `${fuelConsumption.toFixed(2)}`
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
    }
  ];

  render() {
    const { data, loading, page, pageSize, totalCount } = this.state;
    return (
      <>
        <Header left={<h1>Отчет о таксированных путевых листах по электрокарам</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onChangeFilter={this.handleChangeFilter}
            onExport={this.handleExport}
          />
          <Table
            // Высчитываем высоту таблицы. 290 - примерное значение высоты остальных элементов страницы - хедера, фильтров и т.д.
            bodyStyle={{
              height: data.length ? `${document.documentElement.clientHeight - 290}px` : "auto"
            }}
            pagination={{
              page,
              pageSize,
              totalCount
            }}
            loading={loading}
            data={data}
            columns={this.columns}
            fetch={this.fetchReportTaxedTripsElectric}
            rowKey='tripNumber'
          />
        </Section>
      </>
    );
  }
}

export default TaxedTripsElectricList;
