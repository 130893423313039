// @flow

import type {OrgUnitNode} from '../types';
import type {FetchListParams} from './index';
import {fetchRequest, initialFetchListParams} from './index';

export const addOrgUnit = async (orgUnit: OrgUnitNode): Promise<OrgUnitNode> => {
  const added = await fetchRequest.post('/orgUnit', orgUnit);
  if (added) return added;
  throw new Error('Не удалось создать подразделение');
};

export const updateOrgUnit = async (orgUnit: OrgUnitNode): Promise<OrgUnitNode> => {
  const updated = await fetchRequest.put('/orgUnit', orgUnit);
  if (updated) return updated;
  throw new Error('Не удалось обновить подразделение');
};

// если передать accessType то бэк вернёт все филиалы для пользователя с этим accessType
// например так возвращаются все филиалы при переназначении заявки по доступу
export const fetchOrgUnits = async (
  params: FetchListParams<any> = initialFetchListParams,
  accessType: String
): Promise<OrgUnitNode> =>
  await fetchRequest.get('/orgUnit', {
    ...initialFetchListParams,
    ...params
  });

export const getById = async (id): Promise<OrgUnitNode> => await fetchRequest.get(`/orgUnit/${id}`);

export const deleteOrgUnit = async (id) => {
  const deleted = await fetchRequest.delete(`/orgUnit/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить подразделение');
};

export default {
  addOrgUnit,
  fetchOrgUnits,
  getById,
  updateOrgUnit,
  deleteOrgUnit,
};
