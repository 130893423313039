import styled from 'styled-components';
import Icon from './Icon';

export {default as AntTable} from './AntTable';
export {default as Select} from './Select';
export {default as Burger} from './Burger';
export {default as Menu, MenuItem} from './Menu';
export {default as Icon} from './Icon';
export {default as Badge} from './Badge';
export {default as Tabs, TabItem} from './Tabs';
export {default as Spoiler} from './Spoiler';
export {default as Operations} from './Operations';
export {default as Pagination} from './Pagination';
export {default as Table} from './Table';
export {default as CustomInput} from './CustomInput';
export {
  default as ListTable,
  Header as ListTableHeader,
  Cell,
  Row,
} from './ListTable';
export {default as Slider} from './Slider';
export {default as FilesPreview} from './FilesPreview';
export {default as Dropdown} from './Dropdown';
export {default as SpreadsheetTable} from './SpreadsheetTable';
export {default as Popover} from './Popover';
export {default as ButtonsRow} from './ButtonsRow';
export {default as Preloader} from './Preloader'

export const ButtonOperations = styled.div`
  display: flex;

  & > * {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const ActionIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;
