// @flow
import React from 'react';
import {passTariffApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';
import {Filter} from './components';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import {addAccessRight} from './accessRight';

const entryPointPath = '/admin/pass-tariff';

const columns = [
  {
    title: 'Подразделение',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Модель ТС',
    dataIndex: 'vehicleModel',
    render: model => {
      return `${model.brand.name} ${model.name}`;
    }
  },
  {
    title:
      'Тариф за превышение допустимой нормы в весенний период без НДС, руб.',
    dataIndex: 'excessRate',
    render: value => value || 0
  },
  {
    title:
      'Ставка госпошлины за выдачу пропусков на весенний период без НДС, руб.',
    dataIndex: 'springPassesTax',
    render: value => value || 0
  },
  {
    title: 'Сумма тарифа компенсации за вред принесённый дорогам без НДС, руб.',
    dataIndex: 'roadDamageRate',
    render: value => value || 0
  },
  {
    title: 'Ставка госпошлины за выдачу пропусков (негабарит) без НДС, руб.',
    dataIndex: 'cargoPassesTax',
    render: value => value || 0
  },
  {
    title:
      'Ставка госпошлины за согласование пропусков (негабарит) без НДС, руб.',
    dataIndex: 'cargoPassesAgreementTax',
    render: value => value || 0
  },
  {
    title: 'Стоимость бланка, руб.',
    dataIndex: 'blankPrice',
    render: value => value || 0
  },
  {
    title: 'Дата начала',
    dataIndex: 'startDate',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY')
  },
  {
    title: 'Дата окончания',
    dataIndex: 'endDate',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY')
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));
  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}/new`) : undefined
  };

  return (
    <Page>
      <CommonListPage
        crud={passTariffApi}
        pageHeaderProps={{
          mainHeader: 'Тарифы на пропуска',
          access
        }}
        tableListProps={{
          columns: columns,
          onSelect: (id: number) => navigate(`${entryPointPath}/${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
