// @flow

import React, {Component} from 'react';
import notification from 'antd/lib/notification';

import styled from 'styled-components';

import type {User, UserAccess} from './../../lib/types';
import {Panel} from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {authorizeTypeEnum} from '../../lib/enum';
import {fetchRequest, userApi} from '../../lib/api';
import InnerForm from './components/InnerForm';
import {connect} from 'react-redux';
import type {AppState} from '../../ducks/redux';
import {notificationLoading} from './../../components/Notifications';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  userId: ?string,
  userAccess: UserAccess[]
};

type State = {
  user: ?User
};

class UserForm extends Component<Props, State> {
  state = {
    user: null,
  };

  async componentDidMount() {
    let userId = parseInt(this.props.userId, 10);
    if (userId) {
      try {
        const user = await userApi.fetchUser(userId);
        this.setState({user});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
    }
  }

  onSubmit = async (values: User) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (this.props.userId) {
        if (values.password) {
          await userApi.changePassword({
            userId: values.id,
            password: values.password,
          });
        }
        await userApi.updateUser(values);
      } else {
        await userApi.addUser(values);
      }

      // после изменения ролей пользователя, бэк блокирует возможность любых api-запросов пока пользователь не
      // перелогинится. чтобы понять, заблокированы ли запросы отправляем запрос users/me. если бэк вернет ошибку 401,
      // осуществляется редирект на строницу логина через блок catch
      await fetchRequest.get('/users/me');

      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
      navigate('/admin/users', true);
    } catch (error) {
      if (error.message.includes('401')) {
        notification.close('saving');
        navigate('/logout');
        return;
      }
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => navigate('/admin/users', true);

  render() {
    const {user} = this.state;
    const {userId, userAccess} = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/users')}>
                Пользователи
              </Crumb>
              {userId ? (
                <Crumb to={`/admin/users/${userId}`}>
                  Пользователь №{userId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/users/new`}>Новый пользователь</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{user ? `Пользователь №${user.id}` : 'Новый пользователь'}</h1>
        </StyledPanel>
        <InnerForm
          user={
            user || {
              authorizeType: authorizeTypeEnum.standard,
            }
          }
          userAccess={userAccess}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access,
}))(UserForm);
