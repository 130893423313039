// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {MaintenanceWorkApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';

import {Card} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import Popconfirm from 'antd/lib/popconfirm';
import {Button} from 'antd';
import type {MaintenanceWork, UserAccess} from '../../lib/types';
import {accessTypeEnum, entityStatusEnum, newEntityStatuses} from '../../lib/enum';
import {withUserAccess} from '../withUserAccess';
import Input from 'antd/lib/input';
import {notificationLoading} from '../../components/Notifications';
import {MaintenanceTypes} from '../../lib/types/maintenanceWork';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const SectionContent = styled.div`
  padding: 16px;
`;

const ControlButtonsBlock = styled.div`
  display: flex;
  align-items: center;
`;

const {Field} = Card;

type Props = {
  id: string,
  userAccess: UserAccess[],
};

type State = {
  maintenanceWork: ?MaintenanceWork,
  reason: string,
};

class MaintenanceWorkCard extends Component<Props, State> {
  state = {
    maintenanceWork: {},
    reason: '',
  };
  
  async componentDidMount() {
    this.fetch();
  }
  
  fetch = async () => {
    const {id} = this.props;
    if (id) {
      try {
        const maintenanceWork = await MaintenanceWorkApi.get(id);
        this.setState({maintenanceWork});
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message || error.title,
        });
      }
    }
  }
  
  deleteRecord = async () => {
    try {
      await MaintenanceWorkApi.delete(this.props.id);
      navigate('/admin/maintenance-works/', true);
      notification.success({
        message: 'Запись удалена',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };
  
  canApprove = () => [
    accessTypeEnum.admin,
    accessTypeEnum.changeStatusMaintenanceWork,
  ].some(access => this.props.userAccess.includes(access));
  
  handleApprovement = async () => {
    try {
      notificationLoading({message: 'Отправка данных', key: 'sending'})
      await MaintenanceWorkApi.approvementMaintenance(this.props.id);
      await this.fetch();
      notification.success({
        message: 'Запись отправлена на утверждение',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    } finally {
      notification.close('sending');
    }
  };
  
  handleApprove = async () => {
    try {
      notificationLoading({message: 'Отправка данных', key: 'sending'})
      await MaintenanceWorkApi.approveMaintenance(this.props.id);
      await this.fetch();
      notification.success({
        message: 'Запись утверждена',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    } finally {
      notification.close('sending');
    }
  }
  
  handleDecline = async () => {
    const {reason} = this.state;
    if (!reason) {
      notification.error({message: 'Укажите причину отклонения'});
      return;
    }
    try {
      notificationLoading({message: 'Отправка данных', key: 'decline'})
      await MaintenanceWorkApi.declinedMaintenance(this.props.id, reason);
      await this.fetch();
      notification.success({
        message: 'Запись отклонена',
      });
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    } finally {
      notification.close('decline');
    }
  }
  
  canSendToApprovement = () => [
    accessTypeEnum.admin,
    accessTypeEnum.approvementMaintenanceWork,
  ].some(access => this.props.userAccess.includes(access));
  
  render() {
    const {maintenanceWork, reason} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/maintenance-works/')}>
                Работы по ТО
              </Crumb>
              <Crumb>Работа №{maintenanceWork?.id || ''}</Crumb>
            </Breadcrumbs>
          }
          right={(
            <ControlButtonsBlock>
              {this.canApprove() && maintenanceWork?.status === entityStatusEnum.approvement && (
                <>
                  <Button
                    onClick={this.handleApprove}
                    style={{marginRight: '10px'}}
                    type="primary"
                  >
                    Утвердить
                  </Button>
                  <Popconfirm
                    title={
                      <>
                        <Input
                          placeholder="Причина отклонения"
                          value={reason}
                          onChange={e => this.setState({reason: e.target.value})}
                        />
                      </>
                    }
                    onConfirm={this.handleDecline}
                  >
                    <Button>Отклонить</Button>
                  </Popconfirm>
                </>
              )}
              {this.canSendToApprovement() && maintenanceWork?.status === entityStatusEnum.created && (
                <Button
                  onClick={this.handleApprovement}
                  style={{marginRight: '10px'}}
                  type="primary"
                >
                  Отправить на утверждение
                </Button>
              )}
              {maintenanceWork?.status !== entityStatusEnum.approvement &&(
                <>
                  <Button
                    onClick={() => navigate(`/admin/maintenance-works/${maintenanceWork.id}/edit`)}
                    style={{marginRight: '10px'}}
                    type="primary"
                  >
                    Редактировать
                  </Button>
                  <Popconfirm
                    title="Вы уверены, что хотите удалить запись?"
                    onConfirm={this.deleteRecord}
                  >
                    <Button>Удалить</Button>
                  </Popconfirm>
                </>
              )}
            </ControlButtonsBlock>
          )}
        />
        <StyledPanel>
          <h1>{`Работа №${maintenanceWork?.id || ''}`}</h1>
        </StyledPanel>
        <Section>
          {maintenanceWork.id && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Код вида ТО">{maintenanceWork.maintenanceTypeCode}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Код работы">
                    {maintenanceWork.code}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Вид ТО">
                    {MaintenanceTypes[maintenanceWork.maintenanceType]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Наименование работы">
                    {maintenanceWork.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Номер работы">
                    {maintenanceWork.number}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Марка">
                    {maintenanceWork.vehicleModelMatch.brandName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Модель">
                    {maintenanceWork.vehicleModelMatch.modelName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Норма-часы">
                    {maintenanceWork.hoursNorm}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Статус">
                    {newEntityStatuses[maintenanceWork.status]}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceWorkCard);
