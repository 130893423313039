// @flow

import React, {Component} from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import Menu from 'antd/lib/menu';
import Button from 'antd/lib/button';

import type {OptionalEquipment, UserAccess} from '../../../lib/types';
import type {DropdownType} from './../../../components/ui/Dropdown';
import {optionalEquipmentApi} from '../../../lib/api';
import {accessTypeEnum, optionalEquipmentTypes} from '../../../lib/enum';
import {convertVehicleToString, formatDateTimeToString, getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import {Card} from './../../../components';
import {ButtonOperations, Dropdown} from './../../../components/ui';
import {Header, Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import HistoryTable from './../HistoryTable';
import Popconfirm from 'antd/lib/popconfirm';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {withUserAccess} from './../../withUserAccess';
import AttachVehicleButton from './../components/AttachVehicleButton';
import RemoveEquipmentButton from './../../Vehicles/components/RemoveEquipmentButton';
import {notificationLoading} from './../../../components/Notifications';

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const {Field} = Card;

export const canEditOptionalEquipmentAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingEquipment,
];

type Props = {
  optionalEquipmentId: number,
  userAccess: UserAccess[]
};

type State = {
  optionalEquipment: ?OptionalEquipment
};

/** Карточка доп. оборудования */
export class OptionalEquipmentCard extends Component<Props, State> {
  state = {};

  dropdown: ?DropdownType;

  async componentDidMount() {
    const {optionalEquipmentId} = this.props;
    try {
      const optionalEquipment = await optionalEquipmentApi.fetchOptionalEquipment(
        optionalEquipmentId,
      );
      this.setState({
        optionalEquipment,
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    }
  }

  deleteOptionalEquipment = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await optionalEquipmentApi.deleteOptionalEquipment(
        parseInt(this.props.optionalEquipmentId, 10),
      );
      navigate('/equipment/optional', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  setVehicle = async (
    vehicleId: ?number,
    installDate?: string,
    removeDate?: string,
  ) => {
    try {
      const {optionalEquipment} = this.state;
      if (!optionalEquipment) {
        return;
      }
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      let updatedOptionalEquipment;
      if (vehicleId) {
        updatedOptionalEquipment = await optionalEquipmentApi.setOptionalEquipmentVehicle(
          optionalEquipment.id,
          vehicleId,
          installDate,
        );
      } else {
        updatedOptionalEquipment = await optionalEquipmentApi.updateOptionalEquipment(
          {
            ...optionalEquipment,
            vehicleId,
            installDate,
            removeDate,
          },
        );
      }
      this.setState({
        optionalEquipment: updatedOptionalEquipment,
      });
      notification.success({
        message: vehicleId ? 'ТС успешно закреплено' : 'ТС успешно откреплено',
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const {userAccess} = this.props;
    const {optionalEquipment} = this.state;
    const canEdit = userAccess.some(access =>
      canEditOptionalEquipmentAccess.includes(access),
    );
    if (!optionalEquipment) {
      return null;
    }
    const canUnfixVehicle = parseInt(optionalEquipment.vehicleId, 10) > 0;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/equipment/optional')}>
                Приборы и бортовые устройства
              </Crumb>
              <Crumb>
                {optionalEquipment.brandName} {optionalEquipment.name}
              </Crumb>
            </Breadcrumbs>
          }
          right={
            canEdit && (
              <ButtonOperations>
                {canUnfixVehicle && (
                  <RemoveEquipmentButton
                    onConfirm={(removeDate: string) =>
                      this.setVehicle(
                        null,
                        optionalEquipment.installDate,
                        removeDate,
                      )
                    }
                  >
                    Открепить ТС
                  </RemoveEquipmentButton>
                )}
                <AttachVehicleButton
                  onConfirm={this.setVehicle}
                  vehicleId={optionalEquipment.vehicleId}
                />
                <Dropdown
                  ref={dropdown => (this.dropdown = dropdown)}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() =>
                          navigate(
                            `/equipment/optional/edit/${optionalEquipment.id}`,
                          )
                        }
                      >
                        Редактировать
                      </Menu.Item>
                      <Menu.Item>
                        <Popconfirm
                          title="Вы действительно хотите удалить?"
                          okText="Да"
                          cancelText="Нет"
                          placement="bottomRight"
                          onConfirm={this.deleteOptionalEquipment}
                          onVisibleChange={flag =>
                            this.dropdown && this.dropdown.onVisibleChange(flag)
                          }
                        >
                          Удалить
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button
                    className="openActionsDropdown"
                    type="primary"
                    icon="ellipsis"
                  />
                </Dropdown>
              </ButtonOperations>
            )
          }
        />
        <StyledPanel>
          <h1>
            {optionalEquipment.brandName} {optionalEquipment.name}
          </h1>
        </StyledPanel>
        <Section>
          <SectionContent>
            <Grid gutter="16px">
              <GridItem>
                <Field label="Наименование">{optionalEquipment.name}</Field>
              </GridItem>
              <GridItem>
                <Field label="Марка">{optionalEquipment.brandName}</Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">
                  {optionalEquipmentTypes[optionalEquipment.type]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод">{optionalEquipment.code}</Field>
              </GridItem>
              <GridItem>
                <Field label="Дата установки">
                  {formatDateTimeToString(
                    optionalEquipment.installDate,
                    'DD.MM.YYYY',
                  )}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата снятия">
                  {formatDateTimeToString(
                    optionalEquipment.removeDate,
                    'DD.MM.YYYY',
                  )}
                </Field>
              </GridItem>
              {optionalEquipment.inventoryNumber && (
                <GridItem>
                  <Field label="Инвентарный номер">
                    {optionalEquipment.inventoryNumber}
                  </Field>
                </GridItem>
              )}
              {optionalEquipment.vehicle && (
                <GridItem>
                  <Field label="Прикрепленное ТС">
                    {convertVehicleToString(optionalEquipment.vehicle)}
                  </Field>
                </GridItem>
              )}
            </Grid>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>История</SectionTitle>
          <HistoryTable
            equipmentId={optionalEquipment.id}
            fetchHistory={optionalEquipmentApi.fetchHistory}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(OptionalEquipmentCard);
