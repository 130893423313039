// @flow
import React, {useCallback, useState} from 'react';

import {navigate} from '../../lib/helpers';
import {calendarOfBKApi} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {type CommonFormProps} from '../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import type {CalendarOfBK} from '../../lib/types';
import notification from 'antd/lib/notification';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';

type PageProps = {
  id: number,
};

const entryPointPath = '/admin/working-with-budgeting/calendar';

const InnerForm = (props: CommonFormProps<$Shape<CalendarOfBK>>) => {
  const initialData = props.data;
  if (!initialData.startDay) {
    initialData.startDay = moment().get('date');
    initialData.startMonth = moment().get('month') + 1;
  }
  if (!initialData.finishDay) {
    initialData.finishDay = moment().get('date');
    initialData.finishMonth = moment().get('month') + 1;
  }
  return (
    <CommonForm {...props} data={initialData}>
      {({setFieldValue, values}) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Дата и месяц начала" fast required name='startDay' hasFeedback={false}>
                  {() => (
                    <DatePicker
                      defaultValue={moment(`${new Date().getFullYear()} ${values.startMonth} ${values.startDay}`)}
                      format="D MMMM"
                      onChange={(value: string) => {
                        const dateArr = moment(value).locale('en').format('D.MMMM').split('.');
                        setFieldValue('startDay', dateArr[0]);
                        setFieldValue('startMonth', dateArr[1].toLowerCase());
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата и месяц окончания" fast required name='finishDay' hasFeedback={false}>
                  {() => (
                    <DatePicker
                      defaultValue={moment(`${new Date().getFullYear()} ${values.finishMonth} ${values.finishDay}`)}
                      format="D MMMM"
                      onChange={(value: string) => {
                        const dateArr = moment(value).locale('en').format('D.MMMM').split('.');
                        setFieldValue('finishDay', dateArr[0]);
                        setFieldValue('finishMonth', dateArr[1].toLowerCase());
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: CalendarOfBK, setData] = useState({id: props.id});
  
  const breadCrumbs = [
    {
      to: entryPointPath,
      name: 'Сроки отправки данных в ИСУ "Бюджетирование"',
    },
    {
      name: props.id ? props.id : 'Новая запись',
    },
  ];
  
  const onFetch = useCallback(async () => {
    !!props.id && setData(await calendarOfBKApi.get(props.id));
  }, [props.id]);
  
  const onSubmit = async (payload: CalendarOfBK) => {
    let id = payload.id;
    if (payload.startDay && payload.finishDay) {
      const startDate = moment(`${new Date().getFullYear()} ${payload.startMonth} ${payload.startDay}`);
      const endDate = moment(`${new Date().getFullYear()} ${payload.finishMonth} ${payload.finishDay}`);
      if (moment(endDate).isBefore(startDate)) {
        notification.error({message: 'Дата окончания не может быть меньше даты начала'});
        return;
      }
    }
    if (id) {
      await calendarOfBKApi.update(payload);
    } else {
      await calendarOfBKApi.add(payload);
    }
    notification.success({message: 'Данные сохранены'});
    await navigate(`${entryPointPath}`, true);
  };
  
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Календарь проведения бюджетного комитета',
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
