import React from 'react';
import {Spin} from 'antd';
import styled from 'styled-components';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

export default () => (
  <Preloader>
    <Spin size={'large'} />
  </Preloader>
);
