// @flow

import type { DetailedDefectiveStatement } from './../types';

import {
  fetchRequest,
  initialFetchListParams,
  downloadRequestWithToken
} from './index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromKopToRub, convertFromRubToKop } from './../helpers';

export const convertDataFromServer = (
  detailedDefectiveStatement: DetailedDefectiveStatement
): DetailedDefectiveStatement => ({
  ...detailedDefectiveStatement,
  parts: detailedDefectiveStatement.parts.map(part => {
    const { stockPart, mtr } = part;
    return {
      ...part,
      stockPart: {
        ...stockPart,
        // $FlowFixMe если будет время можно исправить эту ошибку
        cost: stockPart ? convertFromKopToRub(stockPart.cost) : undefined
      },
      mtr: {
        ...mtr,
        currentCost: mtr ? convertFromKopToRub(mtr.currentCost) : undefined
      },
      contractorSum: convertFromKopToRub(part.contractorSum),
      contractorCost: convertFromKopToRub(part.contractorCost)
    };
  }),
  operations: detailedDefectiveStatement.operations.map(operation => {
    return {
      ...operation,
      sum: convertFromKopToRub(operation.sum),
      price: convertFromKopToRub(operation.price),
      contractorSum: convertFromKopToRub(operation.contractorSum),
      contractorPrice: convertFromKopToRub(operation.contractorPrice)
    };
  })
});

export const convertDataToServer = (
  detailedDefectiveStatement: DetailedDefectiveStatement
): DetailedDefectiveStatement => ({
  ...detailedDefectiveStatement,
  parts: detailedDefectiveStatement.parts.map(part => {
    const { stockPart, mtr } = part;
    return {
      ...part,
      stockPart: {
        ...stockPart,
        // $FlowFixMe если будет время можно исправить эту ошибку
        cost: stockPart ? convertFromRubToKop(stockPart.cost) : undefined
      },
      mtr: {
        ...mtr,
        currentCost: mtr ? convertFromRubToKop(mtr.currentCost) : undefined
      },
      contractorSum: convertFromRubToKop(part.contractorSum),
      contractorCost: convertFromRubToKop(part.contractorCost)
    };
  }),
  operations: detailedDefectiveStatement.operations.map(operation => {
    return {
      ...operation,
      sum: convertFromRubToKop(operation.sum),
      price: convertFromRubToKop(operation.price),
      contractorSum: convertFromRubToKop(operation.contractorSum),
      contractorPrice: convertFromRubToKop(operation.contractorPrice)
    };
  })
});

export const fetchDetailedDefectiveStatement = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<DetailedDefectiveStatement>> => {
  const detailedDefectiveStatement = await fetchRequest.get(
    '/detailedDefectiveStatement',
    {
      ...initialFetchListParams,
      ...params
    }
  );
  if (detailedDefectiveStatement)
    return {
      ...detailedDefectiveStatement,
      data: detailedDefectiveStatement.data.map(convertDataFromServer)
    };
  throw new Error('Не удалось получить детальную дефектную ведомость');
};

export const getActualDetailedDefectiveStatement = async (
  maintenanceId: number
): Promise<DetailedDefectiveStatement> => {
  const detailedDefectiveStatement = await fetchRequest.get(
    `/detailedDefectiveStatement/${maintenanceId}/getActual`
  );
  if (detailedDefectiveStatement)
    return convertDataFromServer(detailedDefectiveStatement);
  throw new Error(
    'Не удалось получить информацию по детальной дефектной ведомости'
  );
};

export const getDetailedDefectiveStatement = async (
  id: number
): Promise<DetailedDefectiveStatement> => {
  const detailedDefectiveStatement = await fetchRequest.get(
    `/detailedDefectiveStatement/${id}`
  );
  if (detailedDefectiveStatement)
    return convertDataFromServer(detailedDefectiveStatement);
  throw new Error(
    'Не удалось получить информацию по детальной дефектной ведомости'
  );
};

export const addDetailedDefectiveStatement = async (
  detailedDefectiveStatement: DetailedDefectiveStatement
): Promise<DetailedDefectiveStatement> => {
  const added = await fetchRequest.post(
    `/detailedDefectiveStatement`,
    convertDataToServer(detailedDefectiveStatement)
  );
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось добавить детальную дефектную ведомость');
};

export const updateDetailedDefectiveStatement = async (
  detailedDefectiveStatement: DetailedDefectiveStatement
): Promise<DetailedDefectiveStatement> => {
  const updeted = await fetchRequest.put(
    `/detailedDefectiveStatement`,
    convertDataToServer(detailedDefectiveStatement)
  );
  if (updeted) return convertDataFromServer(updeted);
  throw new Error('Не удалось отредактировать детальную дефектную ведомость');
};

export const deleteDetailedDefectiveStatement = async (
  id: number
): Promise<DetailedDefectiveStatement> => {
  const deleted = await fetchRequest.delete(
    `/detailedDefectiveStatement/${id}`
  );
  if (deleted) return deleted;
  throw new Error('Не удалось удалить детальную дефектную ведомость');
};

export const printDetailedDefectiveStatement = async (id: number) => {
  const print = await downloadRequestWithToken(
    `/detailedDefectiveStatement/${id}/print`
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const printOs3FormDetailedDefectiveStatement = async (id: number) => {
  const print = await downloadRequestWithToken(
    `/detailedDefectiveStatement/${id}/printOs3Form`
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export const printWriteOffActDetailedDefectiveStatement = async (
  id: number
) => {
  const print = await downloadRequestWithToken(
    `/detailedDefectiveStatement/${id}/printWriteOffAct`
  );
  if (print) return print;
  throw new Error('Не удалось загрузить файл');
};

export default {
  printWriteOffActDetailedDefectiveStatement,
  printDetailedDefectiveStatement,
  printOs3FormDetailedDefectiveStatement,
  fetchDetailedDefectiveStatement,
  getActualDetailedDefectiveStatement,
  getDetailedDefectiveStatement,
  addDetailedDefectiveStatement,
  updateDetailedDefectiveStatement,
  deleteDetailedDefectiveStatement
};
