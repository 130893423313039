// @flow
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type {ContractDocumentType, EntityStatusType} from '../../../lib/types';
import {formatDateTimeToISOString} from '../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../components';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import Input from 'antd/lib/input';

const {
  ContractDocumentSelect,
  EntityStatusSelect,
  ContractorSelect,
  ExpenseDirectionTypeSelect
} = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

const StyledInput = styled(Input)`
  &::placeholder {
    font-size: 13px;
  }
`;

export type ContractFilterParams = {
  expenseDirectionType?: number,
  nodeId?: number,
  contractStatus?: EntityStatusType,
  contractorId?: number,
  startDate?: string,
  endDate?: string,
  documentType?: ContractDocumentType
};

type Props = {
  filter: ContractFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ContractFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<ContractFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionTypeSelect
                  size="small"
                  onChange={value => changeValue('expenseDirectionType', value)}
                  value={values.expenseDirectionType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={value => changeValue('contractStatus', value)}
                  value={values.contractStatus}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder="Начало"
                  value={values.startDate ? moment.utc(values.startDate) : null}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      value
                        ? formatDateTimeToISOString(value, dateString)
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder="Конец"
                  value={values.endDate ? moment.utc(values.endDate) : null}
                  onChange={(value, dateString) => {
                    changeValue(
                      'endDate',
                      value
                        ? formatDateTimeToISOString(value, dateString)
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <ContractDocumentSelect
                  size="small"
                  onChange={value => changeValue('documentType', value)}
                  value={values.documentType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <StyledInput
                  placeholder="Номер документа"
                  size="small"
                  onChange={({ target }) => {
                    changeValue(
                      'accountNumber',
                      target.value.trim() !== ''
                        ? target.value.trim()
                        : undefined
                    );
                  }}
                  value={values.accountNumber}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ContractFilter;
