// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import type {EntityStatusType} from '../../../../lib/types';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../../components';
import type {FilterChildrenParams} from '../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';
import DatePicker from 'antd/lib/date-picker';

const { ContractorSelect, YearSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type ExecutionBudgetFilterParams = {
  status?: EntityStatusType,
  startDate?: string,
  endDate?: string,
  branchId?: number,
  orgUnitId?: number,
  year?: number,
  contractorId?: number
};

type Props = {
  filter: ExecutionBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

const { MonthPicker } = DatePicker;

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          showAdditional,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<ExecutionBudgetFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Подразделение"
                  onChange={value => changeValue('orgUnitId', value)}
                  value={values.orgUnitId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YearSelect
                  size="small"
                  onChange={value => changeValue('year', value)}
                  value={values.year}
                />
              </Field>
            </FilterItem>
            {/* <FilterItem>
              <Field mBottomNone>
                <EntityStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem> */}

            <FilterItem>
              <Field mBottomNone>
                <ContractorSelect
                  size="small"
                  onChange={value => changeValue('contractorId', value)}
                  value={values.contractorId}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <MonthPicker
                  size="small"
                  placeholder="Дата начала"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'MMMM YYYY'}
                  onChange={value => {
                    changeValue(
                      'startDate',
                      value
                        ? value
                            .utc()
                            .startOf('month')
                            .toISOString()
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <MonthPicker
                  size="small"
                  placeholder="Дата окончания"
                  value={values.endDate ? moment(values.endDate) : undefined}
                  format={'MMMM YYYY'}
                  onChange={value => {
                    changeValue(
                      'endDate',
                      value
                        ? value
                            .utc()
                            .endOf('month')
                            .toISOString()
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
