import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Menu from 'antd/lib/menu';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { getYmapPlace } from '../../lib/gis';
import type { OrderType, WayPoint } from '../../lib/types';
import { DateTimePicker } from '../DateTimePicker';
import { Cell, Row } from '../ui/ListTable';

import { WaypointPicker } from '../';
import Dropdown from '../ui/Dropdown';
import LocationSelect from './../selects/LocationSelect';
import {
  FormItem,
  LocationWrapper,
  MapLabel,
  OperationColumn,
  WaypointPickerWrapper
} from './WaypointTableRow.elements';
import {
  getDefaultDateTimes,
  validateWaypoint,
  withoutEmptyWaypoint
} from './lib';

type Props = {
  // ID филиала сотрудникам
  employeeBranchOrgUnitId: number,
  // Копирование точки
  copyWaypoint?: (waypoint: WayPoint) => void,
  // Маршрутная точка
  waypoint: WayPoint,
  // Функция изменения маршрутной точки
  onChange: Function,
  // Время начала маршрута
  routeStartDate?: string | moment,
  // Время окончания маршрута
  routeEndDate?: string | moment,
  // Время прибытия предыдущей точки
  prevDepartureDateTime?: string | moment,
  // Время отправления предыдущей точки
  prevArrivalDateTime?: string | moment,
  // Флаг, обозначающий пустую строку
  isEmptyRow?: boolean,
  // Функция удаления маршрутной точки
  removeWaypoint?: (waypoint: WayPoint) => void,
  // Промежуточные точки (для конечной точки)
  transitWaypoints?: WayPoint[],
  // Функция блокировки дат в точке отправления
  getDisabledArrivalDate: Function,
  // Функция блокировки дат в точке прибытия
  getDisabledDepartureDate: Function,
  getDisabledDepartureTime: Function,
  addNewRow?: Function,
  orderType?: OrderType,
  // Пользователь имеет возможность выбрать любую дату
  canChooseAnyDate?: boolean,
};

export default ({
  waypoint,
  onChange,
  employeeBranchOrgUnitId,
  routeEndDate,
  routeStartDate,
  isEmptyRow,
  removeWaypoint,
  prevDepartureDateTime,
  prevArrivalDateTime,
  copyWaypoint,
  transitWaypoints,
  getDisabledArrivalDate,
  getDisabledDepartureDate,
  getDisabledDepartureTime,
  addNewRow,
  orderType,
  canChooseAnyDate,
}: Props) => {
  const [showWaypointPicker, setShowWaypointPicker] = useState(false);
  const [errors, setErrors] = useState({});
  const [newRow, setNewRow] = useState(false); // Костыль для работы валидации форм

  useEffect(() => {
    if (transitWaypoints) {
      setErrors(
        validateWaypoint(waypoint, withoutEmptyWaypoint(transitWaypoints))
      );
    }
    // eslint-disable-next-line
  }, [transitWaypoints]);

  useEffect(() => {
    if (newRow) {
      addNewRow && addNewRow();
      setNewRow(false);
    }
  }, [addNewRow, newRow]);

  /**
   * Функция изменения значения точки
   * @param changedWaypoint
   */
  const handleChangeWaypointValue = (changedWaypoint: WayPoint) => {
    const newWaypoint = {
      ...waypoint,
      ...changedWaypoint
    };
    if (transitWaypoints) {
      setErrors(
        validateWaypoint(newWaypoint, withoutEmptyWaypoint(transitWaypoints))
      );
    } else setErrors(validateWaypoint(newWaypoint));
    onChange(newWaypoint);
  };

  const onMapSelect = (waypoint: WayPoint) => {
    const hasDateTimes =
      !!waypoint &&
      !![waypoint.arrivedDateTime, waypoint.departureDateTime].filter(Boolean)
        .length;
    // Время по умолчанию
    const date = prevDepartureDateTime
      ? prevDepartureDateTime
      : moment.utc().toISOString();
    const arrivedAndDepartureDateTimes = getDefaultArrivalDate(date);
    // Если даты прибытия и отправления проставлены
    // то значит мы просто поменяли значение локации
    if (hasDateTimes) handleChangeWaypointValue(waypoint);
    // Иначе нужно еще проставить даты по-умолчанию
    else
      handleChangeWaypointValue({
        ...waypoint,
        // $FlowFixMe
        ...arrivedAndDepartureDateTimes
      });
    setNewRow(true);
  };

  /**
   * Функция выбора значения из селекта
   * @param waypoint Точка
   * @param location объект геоданных
   */
  const onWaypointSelect = (waypoint: ?WayPoint, location: any) => {
    // Проставлены ли даты прибытия и отправления
    const hasDateTimes =
      !!waypoint &&
      !![waypoint.arrivedDateTime, waypoint.departureDateTime].filter(Boolean)
        .length;
    let arrivedAndDepartureDateTimes = {};
    // Для аварийных заявок время не проставляем
    if (orderType !== 'emergency') {
      // Время по умолчанию
      const date = prevDepartureDateTime
        ? prevDepartureDateTime
        : moment.utc().toISOString();
      arrivedAndDepartureDateTimes = getDefaultArrivalDate(date);
    }
    let valueToSave = {
      ...waypoint,
      name: null,
      id: null,
      address: null,
      latitude: null,
      longitude: null,
      radius: null
    };
    let { longitude, latitude, radius, address } = location;
    if (location?.address) {
      if (!latitude || !longitude) {
        getYmapPlace(location.address).then(resolve => {
          const { lat, lng } = resolve;
          latitude = lat;
          longitude = lng;
          valueToSave = {
            ...waypoint,
            latitude,
            longitude,
            name: address,
            radius,
            address
          };
          if (hasDateTimes) {
            handleChangeWaypointValue(valueToSave);
          } else {
            handleChangeWaypointValue({
              ...valueToSave,
              ...arrivedAndDepartureDateTimes
            });
          }
          setNewRow(true);
        });
      }
      valueToSave = {
        ...waypoint,
        latitude,
        longitude,
        name: address,
        radius,
        address
      };
      if (hasDateTimes) {
        handleChangeWaypointValue(valueToSave);
      } else {
        handleChangeWaypointValue({
          ...valueToSave,
          ...arrivedAndDepartureDateTimes
        });
      }
      setNewRow(true);
    }
  };

  /**
   * Вовзращает поля времени отправления и прибытия по-умолчанию
   * на основе времени предыдущей точки
   * при выборе даты прибытия
   *
   * @param value Время прибытия
   * @returns {arrivedDateTime: string, departureDateTime?: string }
   */
  const getDefaultArrivalDate = (value: string) => {
    return getDefaultDateTimes(value, prevDepartureDateTime, waypoint);
  };

  return (
    <Row cols={['1fr', '100px', '230px', '230px', '1fr', '16px']}>
      <Cell style={{ overflow: 'hidden', minWidth: '100px' }}>
        <LocationWrapper>
          <FormItem
            required
            validateStatus={errors.name && 'error'}
            help={errors.name}
          >
            <LocationSelect
              showArrow={false}
              onSelect={(text, option: any) =>
                onWaypointSelect(waypoint, option.props?.location)
              }
              value={waypoint.name}
              placeholder="Выберите точку маршрута"
              filter={{
                nodeId: employeeBranchOrgUnitId,
                nodeFilterType: 'branchAndChildren'
              }}
            />
          </FormItem>
        </LocationWrapper>
      </Cell>
      <Cell>
        <MapLabel
          onClick={() => setShowWaypointPicker(!showWaypointPicker)}
          showWaypointPicker={showWaypointPicker}
        >
          {showWaypointPicker ? 'Убрать карту' : 'Выбрать на карте'}
        </MapLabel>
      </Cell>
      <Cell>
        {waypoint.type !== 'start' && (
          <FormItem
            required
            validateStatus={errors && errors.arrivedDateTime && 'error'}
            help={errors && errors.arrivedDateTime}
          >
            <DateTimePicker
              onChangeDisabled
              value={waypoint.arrivedDateTime}
              className="arrivedDateTime"
              onChange={value => {
                const {departureDateTime, arrivedDateTime } = getDefaultArrivalDate(value)
                handleChangeWaypointValue({
                  ...waypoint,
                  // $FlowFixMe
                  arrivedDateTime,
                  departureDateTime: (canChooseAnyDate || !departureDateTime) 
                    ? departureDateTime 
                    : moment.max([moment.utc().startOf('day').hour(8), moment.utc(departureDateTime)])
                });
              }}
              format="HH:mm"
              disabledTime={time => {
                if (prevDepartureDateTime && !canChooseAnyDate) {
                  const hour = moment.utc(time).hour();
                  const minutes = moment.utc(time).minutes();
                  return moment
                    .utc(waypoint.arrivedDateTime)
                    .hour(hour)
                    .minutes(minutes)
                    .isSameOrBefore(prevDepartureDateTime);
                }
              }}
              disabledDate={(current: moment) => {
                if (getDisabledArrivalDate && !canChooseAnyDate) {
                  return getDisabledArrivalDate({
                    currentDate: current,
                    routeStartDate,
                    routeEndDate,
                    prevDepartureDate: prevDepartureDateTime,
                    departureDate: waypoint.departureDateTime
                  });
                } else {
                  return false;
                }
              }}
            />
          </FormItem>
        )}
      </Cell>
      <Cell>
        {waypoint.type !== 'end' && (
          <FormItem
            required
            validateStatus={errors && errors.departureDateTime && 'error'}
            help={errors && errors.departureDateTime}
          >
            <DateTimePicker
              onChangeDisabled
              value={waypoint.departureDateTime}
              className="departureDateTime"
              onChange={(value: string) =>
                handleChangeWaypointValue({
                  ...waypoint,
                  departureDateTime: value
                })
              }
              disabledTime={time => {
                if (getDisabledDepartureTime && waypoint.departureDateTime && !canChooseAnyDate) {
                  const hour = moment.utc(time).hour();
                  const minutes = moment.utc(time).minutes();
                  return getDisabledDepartureTime({
                    currentDate: moment
                      .utc(waypoint.departureDateTime)
                      .hour(hour)
                      .minutes(minutes),
                    routeStartDate,
                    routeEndDate,
                    prevArrivalDate: prevArrivalDateTime,
                    arrivalDate: waypoint.arrivedDateTime
                  })
                }
              }
                
              }
              disabledDate={(current: moment) =>{
                if(!canChooseAnyDate) {
                  return getDisabledDepartureDate({
                    currentDate: current,
                    routeStartDate,
                    routeEndDate,
                    prevArrivalDate: prevArrivalDateTime,
                    arrivalDate: waypoint.arrivedDateTime
                  })
                }
              }}
            />
          </FormItem>
        )}
      </Cell>
      <Cell>
        <Input
          value={waypoint.notation}
          placeholder="Примечание"
          onChange={({ target: { value: notation } }) =>
            handleChangeWaypointValue({
              ...waypoint,
              notation
            })
          }
        />
      </Cell>
      <Cell>
        {(copyWaypoint || removeWaypoint) && (
          <OperationColumn>
            <Dropdown
              overlay={
                <Menu>
                  {copyWaypoint && (
                    <Menu.Item onClick={() => copyWaypoint(waypoint)}>
                      Копировать точку
                    </Menu.Item>
                  )}
                  {!isEmptyRow && <Menu.Divider />}
                  {!isEmptyRow && removeWaypoint && (
                    <Menu.Item onClick={() => removeWaypoint(waypoint)}>
                      Удалить точку
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Icon
                style={{ fontSize: 16, color: '#2770FF' }}
                type="ellipsis"
              />
            </Dropdown>
          </OperationColumn>
        )}
      </Cell>
      <Cell fullWidth>
        {showWaypointPicker && (
          <WaypointPickerWrapper>
            <WaypointPicker
              onPickWaypoint={onMapSelect}
              waypoint={waypoint}
              showWaypointPicker={showWaypointPicker}
            />
          </WaypointPickerWrapper>
        )}
      </Cell>
    </Row>
  );
};
