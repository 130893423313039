// @flow
import React from 'react';
import type { BudgetSummaryStatusType } from '../../../../lib/types';

import { TabItem, Tabs } from './../../../../components/ui';
import {
  branchBudgetSummaryCognosTypeEnum,
  budgetSummaryStatusEnum
} from '../../../../lib/enum';

type Props = {
  orgUnitId: number,
  id: number,
  branchBudgetStatus?: BudgetSummaryStatusType,
  // отображать или нет дочерние табы (смета затрат, договоры)
  showChildTabs: ?boolean,
  source: $Keys<branchBudgetSummaryCognosTypeEnum>
};

export default ({
  id,
  orgUnitId,
  branchBudgetStatus,
  showChildTabs = true,
  source
}: Props) => {
  return (
    <Tabs
      withRouter
      type="buttons"
      size="small"
      defaultTab={`/budget/summary/${id}/${orgUnitId}/primary`}
    >
      <TabItem
        label="АС «Транспорт»"
        url={`/budget/summary/${id}/${orgUnitId}/primary`}
      >
        {showChildTabs && (
          <Tabs
            withRouter
            defaultTab={`/budget/summary/${id}/${orgUnitId}/primary`}
          >
            <TabItem
              label="Смета затрат"
              url={`/budget/summary/${id}/${orgUnitId}/primary`}
            />
            {branchBudgetStatus === budgetSummaryStatusEnum.approved && (
              <TabItem
                label="Договоры"
                url={`/budget/summary/${id}/${orgUnitId}/primary/contracts`}
              />
            )}
          </Tabs>
        )}
      </TabItem>
      <TabItem
        label="ИСУ «Бюджетирование»"
        url={`/budget/summary/${id}/${orgUnitId}/cognos`}
      >
        {showChildTabs && source === branchBudgetSummaryCognosTypeEnum.primary && (
          <Tabs
            withRouter
            defaultTab={`/budget/summary/${id}/${orgUnitId}/cognos`}
          >
            <TabItem
              label="Смета затрат"
              url={`/budget/summary/${id}/${orgUnitId}/cognos`}
            />
            {branchBudgetStatus === budgetSummaryStatusEnum.approved && (
              <TabItem
                label="Договоры"
                url={`/budget/summary/${id}/${orgUnitId}/cognos/contracts`}
              />
            )}
          </Tabs>
        )}
      </TabItem>
      <TabItem
        label="Скорректированный бюджет"
        url={`/budget/summary/${id}/${orgUnitId}/corrected`}
      >
        {showChildTabs && (
          <Tabs
            withRouter
            defaultTab={`/budget/summary/${id}/${orgUnitId}/corrected`}
          >
            <TabItem
              label="Смета затрат"
              url={`/budget/summary/${id}/${orgUnitId}/corrected`}
            />
            <TabItem
              label="Договоры"
              url={`/budget/summary/${id}/${orgUnitId}/corrected/contracts`}
            />
          </Tabs>
        )}
      </TabItem>
    </Tabs>
  );
};
