// @flow
import React from 'react';
import type {FormikProps} from 'formik';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Popconfirm from 'antd/lib/popconfirm';
import Table from 'antd/lib/table';
import InputNumber from 'antd/lib/input-number';
import styled from 'styled-components';
import moment from 'moment';

import type {WashingPriceList} from '../../../lib/types';

import {Form, Selects} from './../../../components';
import {Footer, Section} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import type {FormFieldType} from '../../../components/Form';
import CancelButton from './../../../components/CancelButton';
import {Icon} from '../../../components/ui';
import {SectionTitle} from '../../../components/layout';

import type {WashingPrice} from '../../../lib/types/washingPriceList';
import {withEmptyRow} from '../../../lib/helpers';
import {entityStatusEnum} from '../../../lib/enum';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import {VehicleOwnerTypesEnum} from '../../../lib/types/vehicleModel';

const SectionContent = styled.div`
  padding: 16px;
`;

const { ContractorSelect, ContractSelect } = Selects;

type FormProps = {
  washingPriceList: ?WashingPriceList,
  onSubmit: Function,
  onCancel: () => void,
};

export default ({
  washingPriceList,
  onSubmit,
  onCancel
}: FormProps) => {
  return (
    <Form initialValues={washingPriceList} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { values, handleSubmit, setFieldValue, dirty } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionTitle divider>Договор</SectionTitle>
              <SectionContent>
                <Grid gutter="16px" cols={2}>
                  <GridItem>
                    <FormField label="Подрядчик" name="contractorId">
                      {({ name, value }) => (
                        <ContractorSelect
                          name={name}
                          value={value}
                          onChange={(value?: number) => {
                            setFieldValue('contractId', undefined);
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Номер договора" name="contractId">
                      {({ name, value }) => (
                        <ContractSelect
                          name={name}
                          value={value}
                          disabled={!values.contractorId}
                          filter={{
                            contractorId: values.contractorId,
                            contractStatus: Object.keys(
                              entityStatusEnum
                            ).filter(
                              status =>
                                status === entityStatusEnum.approvement ||
                                status === entityStatusEnum.approved
                            )
                          }}
                          contractorId={values.contractorId}
                          onChange={(value: number, {props}) => {
                            setFieldValue(name, value);
                            setFieldValue('contractNumber', props.children);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата начала действия прейскуранта" name="startDate" required>
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                            setFieldValue('endDate', undefined);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата окончания действия прейскуранта" name="endDate" required>
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={!values.startDate}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value: string) =>
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Section>
              <SectionTitle>Цены в рублях, без НДС</SectionTitle>
              <FormField name="washingPrices">
                {({ name, value = [] }) => {
                  return (
                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: 'Модель ТС',
                          dataIndex: 'vehicleModelId',
                          width: 350,
                          render: (modelId: number, data: WashingPrice, index: number) => (
                            <FormField style={{ marginBottom: 0 }} name={`${name}[${index}].vehicleModelId`}>
                              {({ name, value }) => (
                                <VehicleModelSelect
                                  type={VehicleOwnerTypesEnum.self}
                                  size="small"
                                  value={value}
                                  onChange={(value: number) => setFieldValue(name, value)}
                                />
                              )}
                            </FormField>
                          )
                        },
                        {
                          title: 'Кузов',
                          dataIndex: 'bodyPrice',
                          render: (bodyPrice: number, data: WashingPrice, index: number) => (
                            <FormField style={{ marginBottom: 0 }} name={`${name}[${index}].bodyPrice`}>
                              {({ name, value }) => (
                                <InputNumber
                                  size="small"
                                  min={0}
                                  name={name}
                                  value={value}
                                  onChange={(value: number) => setFieldValue(name, value)}
                                />
                              )}
                            </FormField>
                          )
                        },
                        {
                          title: 'Салон',
                          dataIndex: 'interiorPrice',
                          render: (interiorPrice: number, data: WashingPrice, index: number) => (
                            <FormField style={{ marginBottom: 0 }} name={`${name}[${index}].interiorPrice`}>
                              {({ name, value }) => (
                                <InputNumber
                                  size="small"
                                  min={0}
                                  name={name}
                                  value={value}
                                  onChange={(value: number) => setFieldValue(name, value)}
                                />
                              )}
                            </FormField>
                          )
                        },
                        {
                          title: 'ДВС',
                          dataIndex: 'enginePrice',
                          render: (enginePrice: number, data: WashingPrice, index: number) => (
                            <FormField style={{ marginBottom: 0 }} name={`${name}[${index}].enginePrice`}>
                              {({ name, value }) => (
                                <InputNumber
                                  name={name}
                                  min={0}
                                  size="small"
                                  value={value}
                                  onChange={(value: number) => setFieldValue(name, value)}
                                />
                              )}
                            </FormField>
                          )
                        },
                        {
                          stopPropagation: true,
                          width: 20,
                          dataIndex: 'vehicleModelId',
                          key: 'deleting',
                          render: (id: number, record) => (
                            <FormField>
                              {() => (
                                <Popconfirm
                                  title="Вы уверены, что хотите удалить запись?"
                                  onConfirm={() => setFieldValue(`${name}`, values.washingPrices.filter(el => el !== record))}
                                >
                                  <Icon type="x" />
                                </Popconfirm>
                              )}
                            </FormField>
                          )
                        }
                      ]}
                      dataSource={withEmptyRow(value)}
                    />
                  );
                }}
              </FormField>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
