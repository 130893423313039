// @flow
import React, {Component} from 'react';
import notification from 'antd/lib/notification';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {Panel, Section} from '../../components/layout';
import Grid, {GridItem} from '../../components/layout/Grid';
import type {Location, UserAccess} from '../../lib/types';
import type {AppState} from '../../ducks/redux';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {locationApi} from '../../lib/api';
import {withUserAccess} from '../withUserAccess';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import Card from '../../components/card';
import { WaypointPicker } from '../../components';

const {Field} = Card;

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  locationId: ?number,
  employeeBranchOrgUnitId: number,
  userAccess: UserAccess[]
};

type State = {
  location?: Location
};

class LocationForm extends Component<Props, State> {
  state = {};

  async componentDidMount() {
    const {locationId} = this.props;
    if (locationId) {
      try {
        const location = await locationApi.fetchLocation(locationId);
        this.setState({
          location,
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
        navigate('/admin/locations', true);
      }
    }
  }

  render() {
    const {location} = this.state;
    return location ? (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/locations/toir')}>
                Объекты
              </Crumb>
              {location && (
                <Crumb to={`/admin/locations/form/${location.id}`}>
                  Объект №{location.id}
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{location && `Объект №${location.id}`}</h1>
        </StyledPanel>
        <Section>
          <Content>
            <Grid cols={6} gutter="16px">
              <GridItem span={3}>
                <Field label="Наименование">
                  {location.name}
                </Field>
              </GridItem>
              <GridItem span={3}>
                <Field label="Подразделение">
                  {location.orgUnitName}
                </Field>
              </GridItem>
              <GridItem span={3} style={{marginBottom: 10}}>
                <Field label="Адрес">
                  {location.address}
                </Field>
              </GridItem>
              <GridItem span={1}>
                <Field label="РЭС">
                  {location.district}
                </Field>
              </GridItem>
              <GridItem span={1}>
                <Field label="Код SWEO">
                  {location.sweoId}
                </Field>
              </GridItem>
              <GridItem span={1}>
                <Field label="ID объекта ТОиР">
                  {location.toirId}
                </Field>
              </GridItem>
              <GridItem span={6} style={{paddingBottom: "10px"}}>
                <WaypointPicker
                  location={location}
                  viewMode
                />
              </GridItem>
              <GridItem span={3}>
                <Field label="Долгота">
                  {location.longitude}
                </Field>
              </GridItem>
              <GridItem span={3}>
                <Field label="Широта">
                  {location.latitude}
                </Field>
              </GridItem>
              <GridItem span={3}>
                <Field label="Радиус геозоны, метры">
                  {location.radius}
                </Field>
              </GridItem>
              <GridItem span={3}>
                <Field label="Точка постоянного местоположения ТС">
                  {location.isDefault ? "Да" : "Нет"}
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>
      </>
    ) : (
      <></>
    );
  }
}

export default connect((state: AppState, props: Props) => ({
  locationId: parseInt(props.locationId, 10),
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId,
}))(withUserAccess(LocationForm));
