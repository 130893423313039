// @flow
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';

import type { StockPart } from '../../../lib/types';
import { multipliedBy } from '../../../lib/helpers';

import Form from '../../../components/Form';
import type { FormFieldType } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import Selects from './../../../components/selects';
import StockSelect from '../../../components/selects/StockSelect';

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const { MdmSelect } = Selects;

type Props = {
  stockPart: ?StockPart,
  onCancel: Function,
  onSubmit: Function
};

export default ({ stockPart, onCancel, onSubmit }: Props) => {
  const [mdm, setMdm] = useState(null);
  useEffect(() => {
    if (stockPart && stockPart.mtr) {
      setMdm(stockPart.mtr);
    }
  }, [stockPart]);
  return (
    <Form initialValues={stockPart} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Код МТР" required fast name="mtrId">
                  {({ name, value }) => (
                    <MdmSelect
                      value={mdm ? mdm.mnemocode : null}
                      optionLabelProp={'value'}
                      onChange={(value, option) => {
                        if (value === undefined) {
                          setFieldValue('name', undefined);
                          setFieldValue(name, undefined);
                          setMdm(null);
                        } else {
                          setFieldValue('name', option.props.mdm.name);
                          setFieldValue('cost', option.props.mdm.currentCost);
                          setFieldValue(name, option.props.mdm.id);
                          setMdm(option.props.mdm);
                        }
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Наименование детали" name="name">
                  {field => (
                    <Input disabled {...field} value={mdm ? mdm.name : null} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Модификация номеклатуры"
                  name="modificationName"
                >
                  {field => <Input disabled {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Склад получателя" fast name="stockId">
                  {({ name, value }) => (
                    <StockSelect
                      value={value}
                      onSelect={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Номер партии" fast name="batchNumber">
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Серийный номер накладной"
                  fast
                  name="serialNumber"
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Номер расходной накладной на отпуск в подразделение"
                  fast
                  name="invoiceNumber"
                >
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Стоимость ед. в руб. без НДС"
                      required
                      fast
                      name="cost"
                    >
                      {({ name, value }) => (
                        <StyledInputNumber
                          disabled
                          name={name}
                          value={value}
                          min={0}
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Количество" required fast name="count">
                      {({ name, value }) => (
                        <StyledInputNumber
                          name={name}
                          value={value}
                          min={0}
                          onChange={value => {
                            setFieldValue(name, value);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {values.cost && values.count && (
                    <GridItem>
                      Сумма: {multipliedBy(values.cost, values.count)} руб. без
                      НДС
                    </GridItem>
                  )}
                </Grid>
              </GridItem>
            </Grid>
          </StyledSection>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
