// @flow
import React from 'react';
import AntPopover, { PopoverProps } from 'antd/lib/popover';

type Props = PopoverProps & {
  // Ширина плашки
  width?: number,
  // Размер шрифта в плашке
  fontSize?: number,
  zIndex?: number
};

/**
 * Компонент появляющейся плашки
 */
const Popover = ({
  className,
  width,
  fontSize,
  overlayStyle,
  zIndex,
  ...props
}: Props) => (
  <AntPopover
    {...props}
    onClick={e => e?.stopPropagation()}
    overlayClassName="popover"
    overlayStyle={{
      width,
      fontSize,
      zIndex,
      ...overlayStyle
    }}
  />
);

Popover.defaultProps = {
  fontSize: 12
};

export default Popover;
