// @flow
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import {FormikProps} from 'formik';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import {Form} from '../../../components';
import CancelButton from '../../../components/CancelButton';
import type {FormFieldType} from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';

import {vehicleTariffStatus} from '../../../lib/enum';
import type {VehicleTariff} from '../../../lib/types';
import {TypesAndModelsTableSection} from './TypesAndModelsTableSection';
import {TextColors} from '../../../assets/styles/commonStyleVariables';
import SecuringContractorSelect from '../../../components/selects/SecuringContractorSelect';

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

export const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSection = styled(Section)`
  padding: 16px;
`;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

export const SubTitle = styled.h5`
  font-weight: bold;
  margin: 10px 0 10px;
`;

type Props = {
  vehicleTariff: ?VehicleTariff,
  onCancel: Function,
  onSubmit: Function
};

export default ({
  vehicleTariff,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <Form initialValues={vehicleTariff} onSubmit={onSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          values,
          dirty,
          isSubmitting,
        }: FormikProps,
      ) => {
        return (
          <form onSubmit={handleSubmit}>
            <StyledSection>
              <Grid gutter="16px" cols={6}>
                <GridItem>
                  <FormField required label="Подрядчик" name="contractorId">
                    {({name, value}) => (
                      <SecuringContractorSelect
                        name={name}
                        value={value}
                        onChange={id => setFieldValue(name, id)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Основания для тарифа" fast required name="tariffBases">
                    {field => <StyledInput {...field} />}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Начало действия тарифа" name="startDate" required>
                    {({name, value}) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                            .utc(value)
                            .startOf('day')
                            .toISOString(),
                          );
                        }}
                        disabledDate={(date: string) =>
                          values.endDate
                            ? moment.utc(date).endOf('day').isSameOrAfter(moment.utc(values.endDate))
                            : false
                        }
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Окончание действия тарифа" required name="endDate">
                    {({name, value}) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={!values.startDate}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                            .utc(date)
                            .startOf('day')
                            .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                            .utc(value)
                            .startOf('day')
                            .toISOString(),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Версия тарифа" fast name="status">
                    {({value}) => <p
                      style={{color: TextColors.darkGrey, marginTop: '6px'}}
                    >
                      {vehicleTariffStatus[value]}
                    </p>}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Филиалы" fast name="vehicleTariffOrganizationUnitLinks">
                    {({value}) => {
                      if (!!value?.length) {
                        return (
                          <div>
                            {value.map(link => (
                              <span key={link.id} style={{color: '#3d4042'}}>
                                {`${link.organizationUnit?.name}  ` || ''}
                              </span>
                            ))}
                          </div>
                        );
                      } else {
                        return <span>-</span>;
                      }
                    }}
                  </FormField>
                </GridItem>
              </Grid>
              <Grid gutter="16px" cols={6}>
                <GridItem fullWidth>
                  <SubTitle>ТС</SubTitle>
                </GridItem>
                <GridItem>
                  <FormField label="Цена АИ-95, руб." fast name="ai95Price">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена АИ-92, руб." fast name="ai92Price">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена ДТ, руб." fast name="dieselPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена Газ, руб." fast name="gasPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена Электроэнергия, руб." fast name="electricityPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
              <Grid gutter="16px" cols={6}>
                <GridItem fullWidth>
                  <SubTitle>Доп. оборудование</SubTitle>
                </GridItem>
                <GridItem>
                  <FormField label="Цена АИ-95, руб." fast name="optionalEquipmentAi95Price">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена АИ-92, руб." fast name="optionalEquipmentAi92Price">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена ДТ, руб." fast name="optionalEquipmentDieselPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена Газ, руб." fast name="optionalEquipmentGasPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Цена Электроэнергия, руб." fast name="optionalEquipmentElectricityPrice">
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        min={0}
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </StyledSection>
            <TypesAndModelsTableSection
              values={values}
              setFieldValue={setFieldValue}
              version={vehicleTariff?.version}
            />
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {values.id ? 'Сохранить' : 'Создать'}
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
