// @flow
import Modal from 'antd/lib/modal';
import React from 'react';
import type { CurrentRepairCalculation } from '../../../../../lib/types';
import { Form } from '../../../../../components';
import Button from 'antd/lib/button';
import { Grid, GridItem } from '../../../../../components/layout';
import {
  fuelTypes,
  monthsNamesTranslitEnum,
  monthsNumbersEnum
} from '../../../../../lib/enum';
import { WorkAccountingTypes } from '../../../../../lib/types/vehicleModel';
import InputNumber from 'antd/lib/input-number';

type Props = {
  visible: boolean,
  handleCancel: () => void,
  handleSave: () => void,
  data: CurrentRepairCalculation,
  monthIndex: number
};

export const CurrentRepairEditingModal = ({
  visible,
  handleCancel,
  handleSave,
  data,
  monthIndex
}: Props) => {
  const selectedMonth = data?.months[monthIndex];
  const initialValues = {
    month: selectedMonth,
    monthName: monthsNamesTranslitEnum[monthsNumbersEnum[selectedMonth?.month]],
    vehicleData: `${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.brandName} ${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.name}`,
    fuelType:
      fuelTypes[
        data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.primaryFuelType
      ]
  };
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Редактирование"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        initialValues={initialValues}
        onSubmit={data => handleSave(data.month, monthIndex)}
      >
        {(FormField, formikProps) => {
          const { handleSubmit, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Месяц" name="monthName">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка и модель ТС" name="vehicleData">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Основное топливо" name="fuelType">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
                {(data?.selfVehiclePlanVehicle?.type ===
                  WorkAccountingTypes.all ||
                  data?.selfVehiclePlanVehicle?.type ===
                    WorkAccountingTypes.kilometrage) && (
                  <GridItem>
                    <FormField
                      label="Пробег, км."
                      name="month.correctedKilometrage"
                    >
                      {({ value, name }) => (
                        <InputNumber
                          value={value || 0}
                          onChange={value => setFieldValue(name, value)}
                          min={0}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {(data?.selfVehiclePlanVehicle?.type ===
                  WorkAccountingTypes.all ||
                  data?.selfVehiclePlanVehicle?.type ===
                    WorkAccountingTypes.workHours) && (
                  <GridItem>
                    <FormField
                      label="Маш. часы, ч."
                      name="month.correctedWorkHours"
                    >
                      {({ value, name }) => (
                        <InputNumber
                          value={value || 0}
                          onChange={value => setFieldValue(name, value)}
                          min={0}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem fullWidth>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                </GridItem>
              </Grid>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
