// @flow
import type {DisplayExpenseDirection} from './../types';
import CommonApi from './../commonApi';

let basedUrl = 'displayExpenseDirection';

const displayExpenseDirection = new CommonApi<DisplayExpenseDirection>(
  basedUrl,
  'Отображение направления расходов'
);

export default displayExpenseDirection;
