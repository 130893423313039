// @flow
import React from 'react';

import type {
  DetailedDefectiveStatement,
  OrderContractorType
} from '../../../../lib/types';
import { ListTable } from '../../../../components/ui';
import { orderContractorTypeEnum } from '../../../../lib/enum';

import { operationColumns, itog } from './../elements';
import { sumOperations } from './../lib';

type Props = {
  orderContractorType: OrderContractorType,
  detailedDefectiveStatement: DetailedDefectiveStatement
};

type State = {};

/**
 * Работы
 */
export default class OperationsReadOnly extends React.Component<Props, State> {
  static defaultProps = {
    detailedDefectiveStatement: null,
    orderContractorType: null
  };

  isContractor = () =>
    this.props.orderContractorType === orderContractorTypeEnum.contractor;

  render() {
    const { detailedDefectiveStatement } = this.props;
    const data = detailedDefectiveStatement.operations;
    const { sum, sumContractor } = sumOperations(data, this.isContractor());
    return (
      <>
        <ListTable
          columns={operationColumns(
            this.isContractor(),
            undefined,
            true,
            this.props.orderContractorType
          )}
          data={data}
        />
        {itog(sum, sumContractor, this.isContractor())}
      </>
    );
  }
}
