// @flow
import React from 'react';
import styled from 'styled-components';
import type { FormikProps } from 'formik';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';

import moment from 'moment';
import { Form, Selects } from './../../../components';
import { Section, Footer } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import type { FormFieldType } from './../../../components/Form';
import CancelButton from './../../../components/CancelButton';
import type { CompareExpenseDirection } from '../../../lib/types';

const {
  MaintenanceWorkTypeSelect,
  OrderContractorTypeSelect,
  ExpenseDirectionTreeSelect,
  RepairePlanSelect
} = Selects;

const SectionContent = styled.div`
  padding: 16px;
`;

type FormProps = {
  compareExpenseDirection: ?CompareExpenseDirection,
  onSubmit: Function,
  onCancel: () => void
};

export default ({ compareExpenseDirection, onSubmit, onCancel }: FormProps) => {
  return (
    <Form initialValues={compareExpenseDirection} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, dirty, setFieldValue, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Вид работ"
                      name="maintenanceWorkTypeId"
                      required
                      fast
                    >
                      {({ value, name }) => (
                        <MaintenanceWorkTypeSelect
                          value={value}
                          onChange={(id: number) => setFieldValue(name, id)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Способ выполнения"
                      name="orderContractor"
                      required
                      fast
                    >
                      {({ value, name }) => (
                        <OrderContractorTypeSelect
                          value={value}
                          onChange={(type: string) => setFieldValue(name, type)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="График работ"
                      required
                      fast
                      name="repairPlan"
                    >
                      {({ value, name }) => (
                        <RepairePlanSelect
                          value={value}
                          onChange={(type: string) => setFieldValue(name, type)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Наименование направления расхода"
                      name="expenseDirectionId"
                      required
                      fast
                    >
                      {({ value, name }) => (
                        <ExpenseDirectionTreeSelect
                          value={value}
                          onChange={(type: number) => setFieldValue(name, type)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Дата начала" name="startDate" required>
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                            setFieldValue('endDate', undefined);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Дата окончания" name="endDate">
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={values.startDate ? false : true}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value: string) =>
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            )
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
