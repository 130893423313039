// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';
import { Link } from '@reach/router';

import type { Pass } from '../../../lib/types';
import { Icon, ListTable } from './../../../components/ui';
import { formatDateTimeToString } from '../../../lib/helpers';
import { passApi } from '../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  passes: Pass[]
};

class PassList extends Component<Props, State> {
  state = {
    loading: false,
    passes: []
  };
  timeout;

  async componentDidMount() {
    if (this.props.vehicleId) {
      this.timeout = setTimeout(() => {
        this.fetchPasses();
      }, 300);
    }
  }

  fetchPasses = async () => {
    if (this.props.vehicleId) {
      try {
        this.setState({
          loading: true
        });
        const passes = await passApi.fetchVehiclePasses(this.props.vehicleId);
        this.setState({
          passes: passes.data
        });
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message
        });
      } finally {
        this.setState({
          loading: false
        });
      }
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onDelete = async (pass: Pass) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (pass.id) {
        await passApi.deletePass(pass.id);
        notification.success({
          message: `Пропуск успешно удален`
        });
        await this.fetchPasses();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { passes, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          {
            title: 'Наименование',
            key: 'name'
          },
          {
            title: 'Номер',
            key: 'number'
          },
          {
            title: 'Дата окончания',
            key: 'discontinuedDate',
            width: '150px',
            render: (value: string) =>
              formatDateTimeToString(value, 'DD.MM.YYYY')
          },
          {
            width: '20px',
            renderRecord: (record: Pass) =>
              record && enableDelete ? (
                <Operations>
                  <Link to={`/services/pass/${parseInt(record.id)}/edit`}>
                    <StyledAntIcon type="edit" />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null
          }
        ]}
        data={passes}
      />
    );
  }
}

export default PassList;
