// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input/Input';

import type { NodeFilterType } from './../../../lib/types';
import { Spoiler } from './../../../components/ui';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { Selects, Card, Filter } from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';

const { BrandsSelect } = Selects;
const { Field } = Card;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;

export type AttachedEquipmentFilterParams = {
  code?: string,
  'brand.id'?: number,
  isFree?: boolean,
  nodeId?: number,
  nodeFilterType?: NodeFilterType
};

type Props = {
  cleanFilter: Function,
  applyFilter: Function,
  filter: AttachedEquipmentFilterParams
};

const AttachedEquipmentFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Spoiler label="Фильтр">
    <SpoilerContent>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          cleanFilter={cleanFilter}
          applyFilter={applyFilter}
        >
          {({
            values,
            changeValue,
            cleanFilter,
            applyFilter
          }: FilterChildrenParams<AttachedEquipmentFilterParams>) => (
            <>
              <GridItem>
                <Field label="Марка">
                  <BrandsSelect
                    onChange={value => changeValue('brand.id', value)}
                    value={values['brand.id']}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Мнемокод">
                  <Input
                    value={values.code}
                    onChange={e => changeValue('code', e.target.value)}
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </SpoilerContent>
  </Spoiler>
);

export default AttachedEquipmentFilter;
