// @flow

import { fetchRequest, initialFetchListParams } from './index';
import type { AttachedEquipment } from '../types/index';
import type { FetchListParams, ListResponse } from './index';
import { convertFromHourToSecond, convertFromSecondToHour } from './../helpers';
import type { AttachedEquipmentFilterParams } from './../../containers/Equipment/Attached/Filter';

export const convertDataFromServer = (
  attachedEquipment: AttachedEquipment
) => ({
  ...attachedEquipment,
  machineHours: convertFromSecondToHour(attachedEquipment.machineHours)
});

const convertDataToServer = (attachedEquipment: AttachedEquipment) => ({
  ...attachedEquipment,
  machineHours: convertFromHourToSecond(attachedEquipment.machineHours)
});

// Получить список навесного оборудования
export const fetchAttachedEquipments = async (
  params: FetchListParams<AttachedEquipmentFilterParams> = initialFetchListParams
): Promise<ListResponse<AttachedEquipment>> =>
  await fetchRequest.get('/attachedEquipment', {
    ...initialFetchListParams,
    ...params
  });

// Получить навесное оборудование по id
export const fetchAttachedEquipment = async (
  id: number,
  params: any = {}
): Promise<AttachedEquipment> => {
  const attached = await fetchRequest.get(`/attachedEquipment/${id}`, params);
  if (attached) {
    return convertDataFromServer(attached);
  }
  throw new Error('Не удалось загрузить навесное оборудование');
};

// Добавить навесное оборудование
export const addAttachedEquipment = async (
  attachedEquipment: AttachedEquipment
): Promise<AttachedEquipment> => {
  const added = await fetchRequest.post(
    '/attachedEquipment',
    convertDataToServer(attachedEquipment)
  );
  if (added) {
    return convertDataFromServer(added);
  }
  throw new Error('Не удалось добавить навесное оборудование');
};

// Удалить навесное оборудование по id
export const deleteAttachedEquipment = async (
  id: number
): Promise<AttachedEquipment> =>
  await fetchRequest.delete(`/attachedEquipment/${id}`);

// Обновление данных
export const updateAttachedEquipment = async (
  attachedEquipment: AttachedEquipment
): Promise<AttachedEquipment> => {
  const attached = await fetchRequest.put(
    '/attachedEquipment',
    convertDataToServer(attachedEquipment)
  );
  if (attached) {
    return convertDataFromServer(attached);
  }
  throw new Error('Не удалось обновить навесное оборудование');
};

// Привязка навесного оборудования к ТС
export const setAttachedEquipmentVehicle = async (
  attachedEquipmentId: number,
  vehicleId: number,
  installDate?: string
): Promise<Object> => {
  const attachedEquipment = await fetchRequest.post(
    `/attachedEquipment/${attachedEquipmentId}/${vehicleId}${
      installDate ? `?installDate=${installDate}` : ''
    }`
  );
  return convertDataFromServer(attachedEquipment);
};

// Получение навесного оборудования ТС
export const fetchVehicleAttachedEquipments = async (
  vehicleId: number
): Promise<Array<AttachedEquipment>> => {
  const array = await fetchRequest.get(
    `/attachedEquipment/${vehicleId}/vehicle`
  );
  if (array) {
    return array.map(convertDataFromServer);
  }
  throw new Error('Ошибка при загрузке навесного оборудования');
};

export const updateAttachedEquipmentVehicle = async (
  attachedEquipment: AttachedEquipment
) =>
  await fetchRequest.put(
    '/attachedEquipment/',
    convertDataToServer(attachedEquipment)
  );

export const fetchHistory = async (
  params: FetchListParams<any>,
  attachedEquipmentId: number
): Promise<ListResponse<Object>> =>
  await fetchRequest.get(`/attachedEquipmentHistory/${attachedEquipmentId}`);

export default {
  fetchAttachedEquipment,
  fetchAttachedEquipments,
  addAttachedEquipment,
  deleteAttachedEquipment,
  updateAttachedEquipment,
  setAttachedEquipmentVehicle,
  fetchVehicleAttachedEquipments,
  updateAttachedEquipmentVehicle,
  fetchHistory
};
