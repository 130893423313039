// @flow
import React, { Component } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import moment from 'moment';

import { Selects, Form } from './../../../../components';
import { ownerTypes } from './../../../../lib/enum';
import { convertDateToString } from './../../../../containers/Vehicles/lib';
import type { FormFieldType } from '../../../../components/Form';
import { FormikProps } from 'formik';
import type { Vehicle, Tire } from '../../../../lib/types';
import { isVehicleTractor } from '../../../../lib/helpers';

const { VehicleSelect } = Selects;
const getMomentValue = (value: ?moment) => (value ? moment.utc(value) : value);

export type AdditionalData = {
  installDate?: ?(string | moment),
  normKilometrage?: ?number,
  normWorkHours?: number
};

type Props = {
  onConfirm: (
    vehicleId: number,
    additionalData: AdditionalData
  ) => Promise<void>,
  tire?: Tire,
  vehicleId?: number,
  vehicle?: Vehicle,
  text?: string,
  showVehicleSelector: boolean
};

type State = {
  visible: boolean,
  isTractor: boolean,
  values: {
    vehicleId?: number,
    installDate?: string
  }
};

export default class AttachVehicleButton extends Component<Props, State> {
  initialState = {
    visible: false,
    values: {},
    isTractor: false
  };

  static defaultProps = {
    text: 'Добавить',
    showVehicleSelector: true
  };

  state = this.initialState;

  componentDidMount() {
    const { vehicle } = this.props;
    this.setState({ isTractor: isVehicleTractor(vehicle) });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.vehicleId !== this.props.vehicleId) {
      this.setState({
        values: {
          vehicleId: this.props.vehicleId ? parseInt(this.props.vehicleId, 10) : undefined
        }
      });
    }
  }

  handleSubmit = (values: any) => {
    const { vehicleId, ...additionalValues } = values;
    if (vehicleId || !this.props.showVehicleSelector) {
      this.props.onConfirm(vehicleId, additionalValues);
      this.setState({ visible: false });
      setTimeout(() => {
        this.setState(this.initialState);
      }, 500);
    }
  };

  showPopover = () =>
    this.setState({
      visible: true
    });

  hidePopover = () => this.setState(this.initialState);

  render() {
    const { visible, values, isTractor } = this.state;
    const { text, showVehicleSelector, tire } = this.props;
    const tireIssueDate = tire && tire.issuedDate ? tire.issuedDate : false;
    // срок до окончания службы шины в мес.
    const tireToEndMonths = (date: string) =>
      24 - Math.ceil(moment().diff(moment(date), 'months', true));
    return (
      <Form initialValues={values} onSubmit={this.handleSubmit}>
        {(FormField: FormFieldType, formikProps: FormikProps) => {
          const { setFieldValue, handleSubmit, handleBlur } = formikProps;
          return (
            <Popconfirm
              title={
                <>
                  {showVehicleSelector && (
                    <FormField name="vehicleId" required label="ТС закрепления">
                      {({ name, value }) => (
                        <VehicleSelect
                          value={value}
                          filter={{
                            ownerType: ownerTypes.self
                          }}
                          onChange={(value, option) => {
                            setFieldValue(name, value);
                            this.setState({
                              isTractor: option
                                ? isVehicleTractor(option.props.vehicle)
                                : false
                            });
                          }}
                        />
                      )}
                    </FormField>
                  )}
                  <FormField
                    label="Дата установки"
                    hasFeedback={false}
                    name="installDate"
                    required
                  >
                    {({ name, value }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        allowClear={false}
                        value={getMomentValue(value)}
                        onBlur={() => handleBlur({ target: { name } })}
                        onChange={(value: Object, dateString: string) =>
                          setFieldValue(
                            name,
                            convertDateToString(value, dateString)
                          )
                        }
                      />
                    )}
                  </FormField>
                  {isTractor ? (
                    <FormField
                      label="Норматив моточасов без учета износа"
                      name="normWorkHours"
                      hasFeedback={false}
                      required
                    >
                      {({ name, value }) => (
                        <InputNumber
                          value={value}
                          min={2}
                          onBlur={() => handleBlur({ target: { name } })}
                          decimalSeparator=","
                          step={0.01}
                          onChange={(normWorkHours: number) =>
                            setFieldValue(name, normWorkHours)
                          }
                        />
                      )}
                    </FormField>
                  ) : (
                    <FormField
                      label="Норматив километража без учета износа"
                      name="normKilometrage"
                      hasFeedback={false}
                      required
                    >
                      {({ name, value }) => (
                        <InputNumber
                          value={value}
                          min={2}
                          onBlur={() => handleBlur({ target: { name } })}
                          decimalSeparator=","
                          step={0.01}
                          onChange={(normKilometrage: number) =>
                            setFieldValue(name, normKilometrage)
                          }
                        />
                      )}
                    </FormField>
                  )}
                  {tireIssueDate && (
                    <FormField label="До списания">
                      {() => (
                        <InputNumber
                          disabled
                          value={`${tireToEndMonths(tireIssueDate)} мес.`}
                        />
                      )}
                    </FormField>
                  )}
                  <p>
                    Вы подтверждаете закрепление оборудования за выбранным ТС?
                  </p>
                </>
              }
              visible={visible}
              okText="Да"
              placement="bottomLeft"
              cancelText="Нет"
              onConfirm={handleSubmit}
              onCancel={this.hidePopover}
              icon={null}
              overlayClassName="without-icon"
            >
              <Button onClick={this.showPopover} type="primary">
                {text}
              </Button>
            </Popconfirm>
          );
        }}
      </Form>
    );
  }
}
