// @flow
import React from 'react';
import styled from 'styled-components';
import { DatePicker } from 'antd';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from '../../../components';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import type { Vehicle } from '../../../lib/types';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import Field from '../../../components/card/Field';

const { WashingStatusSelect, WashingTypeSelect, VehicleSelect } = Selects;
const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;
const { MonthPicker } = DatePicker;

export type WashingsFilterParams = {
  state?: string,
  type?: string,
  vehicleId?: string,
  date?: string | moment,
  factDate?: string | moment,
  startDate?: string | moment,
  endDate?: string | moment
};

type Props = {
  filter: WashingsFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const WashingsFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          showAdditional,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<WashingsFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  onChange={(value: number) => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <WashingTypeSelect
                  size="small"
                  onChange={value => changeValue('type', value)}
                  value={values.type}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <WashingStatusSelect
                  size="small"
                  onChange={value => changeValue('state', value)}
                  value={values.state}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <MonthPicker
                  size="small"
                  format={'MMMM YYYY'}
                  value={values.date && moment.utc(values.date)}
                  placeholder="Планируемая дата"
                  onChange={value =>
                    changeValue(
                      'date',
                      moment
                        .utc(value)
                        .startOf('month')
                        .toISOString()
                    )
                  }
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  value={values.factDate && moment.utc(values.factDate)}
                  format={'DD.MM.YYYY'}
                  placeholder="Факт дата"
                  onChange={(value, dateString) =>
                    changeValue(
                      'factDate',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default WashingsFilter;
