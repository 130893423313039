// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  CalculationStatus,
  MaterialCalculation,
  MaterialCalculationMonth,
  VehiclePlan
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { convertDataFromServer as convertDataFromServerMdm } from './mdm';

export const convertDataToServer = (
  materialCalculation: MaterialCalculation
): MaterialCalculation => ({
  ...materialCalculation,
  sum: convertFromRubToKop(materialCalculation.sum),
  months: materialCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  materialCalculation: MaterialCalculation
): MaterialCalculation => ({
  ...materialCalculation,
  sum: convertFromKopToRub(materialCalculation.sum),
  mtr: convertDataFromServerMdm(materialCalculation.mtr),
  months: materialCalculation.months.map(convertDataFromServerMonth)
});

export const convertDataToServerMonth = (
  materialCalculationMonth: MaterialCalculationMonth
): MaterialCalculationMonth => ({
  ...materialCalculationMonth,
  cost: convertFromRubToKop(materialCalculationMonth.cost)
});

export const convertDataFromServerMonth = (
  materialCalculationMonth: MaterialCalculationMonth
): MaterialCalculationMonth => ({
  ...materialCalculationMonth,
  cost: convertFromKopToRub(materialCalculationMonth.cost)
});

const basedUrl = 'materialCalculation';
const materialCalculation = new CommonApi<MaterialCalculation>(
  basedUrl,
  'расчет потребности в инструментах, материалах (РЭН)',
  convertDataToServer,
  convertDataFromServer
);

export const addOrUpdate = async (
  data: MaterialCalculation[]
): Promise<MaterialCalculation> => {
  const updated = await fetchRequest.post(`/${basedUrl}/addOrUpdate`, data);
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

export const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

export const sendToMto = async (
  vehiclePlanId: number
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.get(`/${basedUrl}/sendToMto/${vehiclePlanId}`);
  if (updated) return updated;
  throw new Error(updated.title);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export const setBzizBudgetLine= async (
  vehiclePlanId: number, budgetLineId: number): any =>
{
  const updated = await fetchRequest.get(`/${basedUrl}/setBzizLine/${vehiclePlanId}`, {budgetLineId}); 
  if (!updated) throw new Error(updated.title);
};

export const setStock= async (
  vehiclePlanId: number, stockId: number): any =>
{
  const updated = await fetchRequest.get(`/${basedUrl}/setStock/${vehiclePlanId}`, {stockId}); 
  if (!updated) throw new Error(updated.title);
};

export default {
  ...materialCalculation,
  changeStatus,
  addOrUpdate,
  print,
  sendToMto,
  setBzizBudgetLine,
  setStock
};
