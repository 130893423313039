// @flow
import {Descriptions, Icon, notification} from 'antd';
import React, {useCallback, useState} from 'react';
import CommonCardPage from '../../../../../components/hoc/common/handbook/CardPage';
import {branchBudgetSummaryExpenseDirectionApi, budgetSummaryContractApi, fetchRequest} from '../../../../../lib/api';
import {branchBudgetSummaryCognosTypeEnum, budgetSummaryEnum, monthsNamesTranslitEnum} from '../../../../../lib/enum';
import {navigate} from '../../../../../lib/helpers';
import type {BranchBudgetSummaryExpenseDirection, CognosContract} from '../../../../../lib/types';
import BudgetContractIndexModal from './BudgetContractIndexModal';
import InnerForm from './InnerForm';
import {changeNotification} from '../../../../../lib/notificationWrapper';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  padding-left: 10px;
  font-size: 16px;
`;

type PageProps = {
  id: number,
  summarydetails: 'summary' | 'details',
  orgUnitId: number,
  expenseDirectionId: number,
  source: $Keys<branchBudgetSummaryCognosTypeEnum>,
};

export default (props: PageProps) => {
  const entryPointPath = `/budget/${props.summarydetails}/`;
  
  const [
    expenseDirectionData: BranchBudgetSummaryExpenseDirection & CognosContract,
    setExpenseDirectionData,
  ] = useState();
  
  const [data: CognosContract[], setData] = useState([]);
  
  const [contractData: CognosContract, setContractData] = useState();
  
  const [showContractForm, setShowContractForm] = useState(false);
  const [contractFormFullMode, setContractFormFullMode] = useState(true);
  const [expenseDirectionId, setExpenseDirectionId] = useState(null);
  
  const onFetch = useCallback(async () => {
    const expenseDirectionData = await branchBudgetSummaryExpenseDirectionApi.get(
      props.expenseDirectionId,
    );
    
    setExpenseDirectionData({
      branchBudgetSummaryLineItemName: expenseDirectionData.branchBudgetSummaryLineItemName,
      expenseDirectionName: expenseDirectionData.expenseDirectionName,
      contractNumber: 'Потребность',
      contractsStatus: expenseDirectionData.contractsStatus,
      cognosContractMonths: expenseDirectionData.months.map(month => ({
        sum: props.summarydetails === 'details' ? month.totalNeed / 100 : month.plan,
        month: month,
      })),
      branchBudgetCognosStatus: expenseDirectionData.branchBudgetCognosStatus,
      coveredByContracts: expenseDirectionData.coveredByContracts,
    });
    setExpenseDirectionId(expenseDirectionData.id);
    
    const contractsData = expenseDirectionData.cognosContracts?.length
      ? expenseDirectionData.cognosContracts
      : [];
    contractsData.forEach((cdata: CognosContract) => {
      if (cdata.cognosContractMonths.length === 0) {
        Object.keys(monthsNamesTranslitEnum).forEach((month, index) => {
          cdata.cognosContractMonths.push({
            monthType: month,
            month: index + 1,
            sum: 0,
            cognosContractId: cdata.id,
          });
        });
      }
      cdata.cognosContractMonths.sort((a, b) => a.month - b.month)
      cdata.cognosContractMonths.forEach((month, index) =>
        month.isOpenPeriod = expenseDirectionData.months[index]?.isOpenPeriod
      )
    });
    
    !!props.id && setData(contractsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.expenseDirectionId, props.id]);
  
  const onSubmit = async (payload: CognosContract) => {
    try {
      let prom = [];
      if (Array.isArray(payload)) {
        payload.forEach(item => prom.push(budgetSummaryContractApi.update(item)));
        await Promise.all(prom);
      } else {
        if (payload.id) {
          await budgetSummaryContractApi.update(payload);
        } else {
          await budgetSummaryContractApi.add(payload);
        }
      }
      await onFetch()
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    }
  };
  
  const onDelete = async (id: number) => {
    try {
      await budgetSummaryContractApi.delete(id);
      onFetch();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    }
  };
  
  const onChangeStatus = async (status) => {
    await changeNotification(() => fetchRequest.put(
      '/branchBudgetSummaryExpenseDirection/changeStatus',
      {status, id: expenseDirectionId},
    ));
    await onFetch();
  };
  
  const onCancel = async () => {
    await navigate(
      `${entryPointPath}${props.id}/${props.orgUnitId}/${props.source ? props.source : 'primary'}/contracts`,
    );
  };
  
  return (
    <>
      <CommonCardPage
        pageHeaderProps={{
          leftHeader: (
            <>
              <Icon
                type="arrow-left"
                style={{cursor: 'pointer', fontSize: '16px', color: '#08c'}}
                onClick={onCancel}
              />
              <Descriptions size="small" style={{padding: '8px 0 0 10px'}}>
                <Descriptions.Item label="Статья бюджетной формы">
                  {expenseDirectionData?.branchBudgetSummaryLineItemName}
                </Descriptions.Item>
                <Descriptions.Item label="Направление расходов">
                  {expenseDirectionData?.expenseDirectionName}
                  {expenseDirectionData?.coveredByContracts
                    ? (
                      <StyledIcon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="green"
                      />
                    )
                    : (
                      <StyledIcon
                        type="exclamation-circle"
                        theme="twoTone"
                        twoToneColor="red"
                      />
                    )
                  }
                </Descriptions.Item>
              </Descriptions>
            </>
          ),
        }}
        onFetch={onFetch}
        onSubmit={onSubmit}
      >
        <InnerForm
          data={data}
          onDelete={onDelete}
          onContractform={(
            payload: CognosContract,
            editContractMetaInfo: boolean,
          ) => {
            if (payload.id) {
              setContractData(payload);
              setShowContractForm(true);
              setContractFormFullMode(!editContractMetaInfo);
            }
          }}
          expenseDirectionData={expenseDirectionData}
          budgetSummaryType={props.summarydetails}
          onChangeStatus={onChangeStatus}
          addContract={() => {
            setContractData({
              branchBudgetSummaryExpenseDirectionId: props.expenseDirectionId,
              budgetSummaryType:
                props.summarydetails === 'summary'
                  ? budgetSummaryEnum.main
                  : budgetSummaryEnum.clarification,
            });
            setShowContractForm(true);
          }}
          noWrapMe
        />
      </CommonCardPage>
      <BudgetContractIndexModal
        visible={showContractForm}
        onCancel={() => {
          setShowContractForm(false);
        }}
        onSave={payload => {
          onSubmit(payload);
          setShowContractForm(false);
        }}
        data={contractData}
        fullMode={contractFormFullMode}
      />
    </>
  );
};
