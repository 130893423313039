// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Divider from 'antd/lib/divider';

import type {MaintenanceMatrix} from '../../lib/types';
import {maintenanceMatrixApi} from '../../lib/api';
import {maintenanceMatrixValidAction, orderContractorTypes, repairPlanTypes} from '../../lib/enum';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';

import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import {notificationLoading} from './../../components/Notifications';

import {Card} from './../../components';
import {Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  maintenanceMatrixId: number
};

type State = {
  maintenanceMatrix: ?MaintenanceMatrix
};

export class MaintenanceMatrixCard extends React.Component<Props, State> {
  state = {
    maintenanceMatrix: null
  };

  async componentDidMount() {
    const { maintenanceMatrixId } = this.props;
    try {
      const maintenanceMatrix = await maintenanceMatrixApi.getMaintenanceMatrix(
        maintenanceMatrixId
      );
      this.setState({ maintenanceMatrix });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: MaintenanceMatrix) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await maintenanceMatrixApi.updateMaintenanceMatrix(values);
      } else {
        await maintenanceMatrixApi.addMaintenanceMatrix(values);
      }
      navigate(`/admin/maintenance-matrix`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/maintenance-matrix', true);

  render() {
    const { maintenanceMatrixId } = this.props;
    const { maintenanceMatrix } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/maintenance-matrix')}>
                Матрица ремонтов
              </Crumb>
              <Crumb>Матрица №{maintenanceMatrixId}</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1> Матрица №{maintenanceMatrixId}</h1>
        </StyledPanel>
        <Section>
          {maintenanceMatrix && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Виды работ">
                    {maintenanceMatrix.maintenanceWorkType.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Способ выполнения">
                    {
                      orderContractorTypes[
                        maintenanceMatrix.orderContractorType
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="График выполнения">
                    {repairPlanTypes[maintenanceMatrix.repairPlan]}
                  </Field>
                </GridItem>
              </Grid>

              <Divider />

              <Grid gutter="16px">
                <GridItem>
                  <Field label="Обязательность заполнения подрядчика и договора">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.contractorRequired
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Обязательность заполнения неисправностей">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.descriptionRequired
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Обязательность заполнения дефектной ведомости">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.defectiveStatementRequired
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Обязательность загрузки файлов">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.filesRequired
                      ]
                    }
                  </Field>
                </GridItem>
              </Grid>

              <Divider />

              <Grid gutter="16px">
                <GridItem>
                  <Field label="Возможность согласования дефектной ведомости">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.approvingDefectiveStatementAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможность согласования детальной дефектной ведомости">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.approvingDetailedStatementAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможность согласования карточки ремонта">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.approvingMaintenanceAllowed
                      ]
                    }
                  </Field>
                </GridItem>
              </Grid>

              <Divider />

              <Grid gutter="16px">
                <GridItem>
                  <Field label="Возможность печати дефектного акта">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printDefectiveActAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможность печати дефектной ведомости">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printDefectiveStatementAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможность печати детальной дефектной ведомости">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printDetailedDefectiveStatementAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможности печати акта на проведение ремонта хоз. способом">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printRepairActAllowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможность печати Формы ОС-3">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printFormOS3Allowed
                      ]
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Возможности печати Акта списания МПЗ">
                    {
                      maintenanceMatrixValidAction[
                        maintenanceMatrix.printActOffMPZAllowed
                      ]
                    }
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default MaintenanceMatrixCard;
