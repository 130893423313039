// @flow
import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import notification from 'antd/lib/notification';
import Menu from 'antd/lib/menu';
import Input from 'antd/lib/input/Input';
import Button from 'antd/lib/button';
import AntIcon from 'antd/lib/icon';

import type {
  VehicleIssue,
  VehicleIssueType,
  MaintenanceWorkType,
  Tire,
  Battery,
  MaintenanceMatrixValidActionType
} from '../../../lib/types';
import {
  maintenanceTypeOfWorkEnum,
  maintenanceMatrixValidActionEnum
} from './../../../lib/enum';
import { withEmptyRow, navigate } from './../../../lib/helpers';
import { vehicleIssueApi } from './../../../lib/api';

import { Selects } from './../../../components';
import Grid, { GridItem } from './../../../components/layout/Grid';
import { ListTable, Dropdown } from '../../../components/ui';

import ModalImage from './../components/ModalImage';

const { VehicleIssueGroupSelect, TireSelect, BatterySelect } = Selects;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const StyledSpan = styled('span')`
  font-size: 13px;
  color: #2770ff;
  top: 8px;
  position: relative;
  cursor: pointer;
`;

type Props = {
  onChange: (value: VehicleIssue[]) => void,
  vehicleId: number,
  handleSubmit: Function,
  vehicleIssues: VehicleIssue[],
  maintenanceId: number,
  handlePrint: ({ key: string, vehicleIssueId: number }) => void,
  maintenanceWorkType: MaintenanceWorkType,
  filesRequired: ?MaintenanceMatrixValidActionType,
  print: boolean
};

type State = {
  touched: boolean,
  columns: any[]
};

/**
 * Дефектовая ведомость
 */
export default class extends React.Component<Props, State> {
  static defaultProps = {
    vehicleIssues: []
  };
  state = {
    touched: false,
    columns: []
  };

  onChange(index: number, key: $Keys<VehicleIssue>, value: any) {
    let vehicleIssues = [...this.props.vehicleIssues];
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    switch (key) {
      case 'tire':
        vehicleIssues.splice(index, 1, {
          ...vehicleIssues[index],
          [(key: string)]: value,
          tireId: value ? value.id : undefined
        });
        break;
      case 'battery':
        vehicleIssues.splice(index, 1, {
          ...vehicleIssues[index],
          [(key: string)]: value,
          batteryId: value ? value.id : undefined
        });
        break;
      default:
        vehicleIssues.splice(index, 1, {
          ...vehicleIssues[index],
          [(key: string)]: value
        });
    }
    this.props.onChange(vehicleIssues);
  }

  onDelete(index: number) {
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    this.props.onChange(
      this.props.vehicleIssues.filter((value, id) => id !== index)
    );
  }

  handleSubmit = () => {
    const { vehicleIssues, maintenanceWorkType, filesRequired } = this.props;
    try {
      vehicleIssues.forEach(issue => {
        switch (maintenanceWorkType.workType) {
          case maintenanceTypeOfWorkEnum.akb:
            if (!!issue.batteryId === false) {
              throw new Error('Выберите АКБ');
            }
            break;
          case maintenanceTypeOfWorkEnum.tire:
            if (!!issue.tireId === false) {
              throw new Error('Выберите шины');
            }
            break;
          case maintenanceTypeOfWorkEnum.common:
            if (!!issue.vehicleIssueGroupId === false) {
              throw new Error('Выберите группу дефектов');
            }
            break;
          default:
            break;
        }
        if (
          filesRequired === maintenanceMatrixValidActionEnum.required &&
          isEmpty(issue.files)
        ) {
          throw new Error('Файлы обязательны');
        }
      });
      this.props.handleSubmit(() => this.setState({ touched: false }));
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  componentDidMount() {
    this.setColumns();
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.filesRequired !== prevProps.filesRequired ||
      this.props.print !== prevProps.print
    ) {
      this.setColumns();
    }
  }

  setColumns() {
    const { maintenanceWorkType } = this.props;
    switch (maintenanceWorkType.workType) {
      case maintenanceTypeOfWorkEnum.tire:
        this.setState({ columns: this.getColumnsTire() });
        break;
      case maintenanceTypeOfWorkEnum.akb:
        this.setState({ columns: this.getColumnsAkb() });
        break;
      default:
        this.setState({ columns: this.getColumnsCommon() });
        break;
    }
  }

  // проверим отображать файлы или нет
  checkFiles() {
    const { filesRequired } = this.props;
    return ![null, maintenanceMatrixValidActionEnum.notAllowed].includes(
      filesRequired
    );
  }

  async saveVehicleIssues(skipIndex: number) {
    const { vehicleIssues, maintenanceId } = this.props;
    vehicleIssues
      .filter((issue, index) => {
        return skipIndex !== index;
      })
      .forEach(issue => {
        if (issue.id) {
          vehicleIssueApi.updateVehicleIssue(issue);
        } else {
          vehicleIssueApi.addVehicleIssue({
            ...issue,
            maintenanceId
          });
        }
      });
  }

  async handleAddFiles(record: VehicleIssue, index: number, tag: string) {
    const { maintenanceId } = this.props;
    this.saveVehicleIssues(index);
    if (record.id) {
      vehicleIssueApi.updateVehicleIssue(record);
      navigate(`/maintenances/${record.id}/edit/files/${tag}`);
    } else {
      const added = await vehicleIssueApi.addVehicleIssue({
        ...record,
        maintenanceId
      });
      navigate(`/maintenances/${added.id}/edit/files/${tag}`);
    }
  }

  getColumnsAction(tag: string) {
    const { print } = this.props;
    const files = this.checkFiles();
    let fileColumn = [];
    if (files) {
      fileColumn.push({
        width: '150px',
        renderRecord: (record: VehicleIssue, index: number) => {
          if (record.vehicleIssueGroupId || record.batteryId || record.tireId) {
            return isEmpty(record.files) ? (
              <StyledSpan
                onClick={async () => {
                  this.handleAddFiles(record, index, tag);
                }}
              >
                Добавить файлы
              </StyledSpan>
            ) : (
              <ModalImage vehicleIssueId={record.id} files={record.files} />
            );
          }
          return null;
        }
      });
    }
    return [
      ...fileColumn,
      {
        width: '20px',
        renderRecord: (record: VehicleIssue, index: number) =>
          !isEmpty(record) ? (
            <Operations>
              <Dropdown
                overlay={
                  <Menu>
                    {!isEmpty(record.files) && files && (
                      <Menu.Item
                        onClick={() => this.handleAddFiles(record, index, tag)}
                      >
                        Добавить файлы
                      </Menu.Item>
                    )}
                    {!!record.id && print && (
                      <Menu.Item
                        onClick={() =>
                          this.props.handlePrint({
                            key: 'printVehicleIssue',
                            vehicleIssueId: record.id
                          })
                        }
                      >
                        Печать деф. акта
                      </Menu.Item>
                    )}
                    <Menu.Item onClick={() => this.onDelete(index)}>
                      Удалить
                    </Menu.Item>
                  </Menu>
                }
              >
                <AntIcon
                  style={{ fontSize: 16, color: '#2770FF' }}
                  type="ellipsis"
                />
              </Dropdown>
            </Operations>
          ) : null
      }
    ];
  }

  getColumnsCommon() {
    return [
      {
        title: 'Группа дефекта',
        style: {
          overflow: 'hidden'
        },
        key: 'vehicleIssueGroupId',
        render: (
          type: VehicleIssueType,
          record: VehicleIssue,
          index: number
        ) => (
          <VehicleIssueGroupSelect
            onChange={id => this.onChange(index, 'vehicleIssueGroupId', id)}
            value={type}
          />
        )
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) => (
          <Input
            onChange={e => this.onChange(index, 'description', e.target.value)}
            value={description}
            name="description"
            placeholder="Опишите характеристику дефекта"
          />
        )
      },
      ...this.getColumnsAction('common')
    ];
  }

  getColumnsAkb() {
    const { vehicleId } = this.props;
    return [
      {
        title: 'АКБ',
        style: {
          overflow: 'hidden'
        },
        key: 'batteryId',
        render: (type: number, record: VehicleIssue, index: number) => (
          <BatterySelect
            filter={{ vehicleId }}
            onChange={(batteryId, option) =>
              this.onChange(
                index,
                'battery',
                option ? option.props.battery : undefined
              )
            }
            value={type}
          />
        )
      },
      {
        title: 'Заводской номер',
        key: 'battery',
        render: (battery: Battery) => (
          <Input
            disabled
            value={
              battery && battery.factoryNumber ? battery.factoryNumber : null
            }
          />
        )
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) => (
          <Input
            onChange={e => this.onChange(index, 'description', e.target.value)}
            value={description}
            name="description"
            placeholder="Опишите характеристику дефекта"
          />
        )
      },
      ...this.getColumnsAction('replacementAkb')
    ];
  }

  getColumnsTire() {
    const { vehicleId } = this.props;
    return [
      {
        title: 'Шина',
        style: {
          overflow: 'hidden'
        },
        key: 'tireId',
        render: (type: number, record: VehicleIssue, index: number) => (
          <TireSelect
            filter={{ vehicleId }}
            onChange={(tireId, option) =>
              this.onChange(
                index,
                'tire',
                option ? option.props.tire : undefined
              )
            }
            value={type}
          />
        )
      },
      {
        title: 'Заводской номер',
        key: 'tire',
        render: (tire: Tire) => (
          <Input
            disabled
            value={tire && tire.factoryNumber ? tire.factoryNumber : null}
          />
        )
      },
      {
        title: 'Характеристика дефекта',
        key: 'description',
        render: (description: string, record: VehicleIssue, index: number) => (
          <Input
            onChange={e => this.onChange(index, 'description', e.target.value)}
            value={description}
            name="description"
            placeholder="Опишите характеристику дефекта"
          />
        )
      },
      ...this.getColumnsAction('replacementTire')
    ];
  }

  render() {
    const { vehicleIssues } = this.props;
    const { touched, columns } = this.state;
    return (
      <>
        <ListTable columns={columns} data={withEmptyRow(vehicleIssues)} />
        {touched && (
          <Grid style={{ marginTop: '10px' }}>
            <GridItem fullWidth>
              <Button type="primary" onClick={this.handleSubmit}>
                Сохранить
              </Button>
            </GridItem>
          </Grid>
        )}
      </>
    );
  }
}
