// @flow
import React from 'react';
import Page from '../../components/hoc/common/handbook/PageContext';

import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import {ISUBudgetingDataApi} from './../../lib/api';

const entryPointPath = '/admin/ISUBudgetingData';

export default () => {
  const columns = [
    {
      title: 'Наименование',
      dataIndex: 'name',
    },
    {
      title: 'Статья калькуляции',
      dataIndex: 'itemOfCalculationName',
    },
    {
      title: 'Особая отметка',
      dataIndex: 'specialMarkName',
    },
    {
      title: 'Вид движения',
      dataIndex: 'typeOfMovementByElementName',
    },
  ];
  
  const access = {add: () => navigate(`${entryPointPath}/new`)};
  
  return (
    <Page>
      <CommonListPage
        crud={ISUBudgetingDataApi}
        pageHeaderProps={{
          mainHeader: 'Данные для ИСУ "Бюджетирование"',
          access,
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}/${id}`),
        }}
      />
    </Page>
  );
};
