// @flow
import React from 'react';
import styled from 'styled-components';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  RangePicker
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import { SectionContent } from '../../../components/hoc/common/components/elements';
import type { OrderContractorType, RepairPlan } from '../../../lib/types';
import Selects from '../../../components/selects';
import moment from 'moment';
import { formatDateTimeToISOString } from '../../../lib/helpers';

let {
  MaintenanceWorkTypeSelect,
  OrderContractorTypeSelect,
  RepairePlanSelect,
  ExpenseDirectionTreeSelect,
  YesNoSelect
} = Selects;

YesNoSelect = styled(YesNoSelect)`
  min-width: 200px;
`;
export type CompareExpenseDirectionFilterParams = {
  maintenanceWorkTypeId?: number,
  orderContractor?: OrderContractorType,
  repairPlan?: RepairPlan,
  expenseDirectionId?: number,
  startDate?: string,
  endDate?: string,
  isArchive?: boolean
};

type Props = {
  filter: CompareExpenseDirectionFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<CompareExpenseDirectionFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <MaintenanceWorkTypeSelect
                  value={values.maintenanceWorkTypeId}
                  onChange={value =>
                    changeValue('maintenanceWorkTypeId', value)
                  }
                  size="small"
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrderContractorTypeSelect
                  placeholder="Выберите способ выполнения"
                  value={values.orderContractor}
                  onChange={value => changeValue('orderContractor', value)}
                  size="small"
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RepairePlanSelect
                  placeholder="Выберите график работ"
                  value={values.repairPlan}
                  onChange={value => changeValue('repairPlan', value)}
                  size="small"
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <ExpenseDirectionTreeSelect
                  value={values.expenseDirectionId}
                  onChange={value => changeValue('expenseDirectionId', value)}
                  size="small"
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      startDate
                        ? formatDateTimeToISOString(startDate, startDateString)
                        : undefined
                    );
                    changeValue(
                      'endDate',
                      endDate
                        ? formatDateTimeToISOString(endDate, endDateString)
                        : undefined
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Признак архива"
                  size="small"
                  onChange={value => changeValue('isArchive', value)}
                  value={values['isArchive']}
                  reset
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default StockPartFilter;
