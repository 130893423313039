// @flow
import React, { Component, Fragment } from 'react';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import isEqual from 'lodash/isEqual';
import DatePicker from 'antd/lib/date-picker';
import InputNumber from 'antd/lib/input-number';
import type { moment } from 'moment';

import { Card } from '../../../../components';
import RemoveEquipmentButton from './../RemoveEquipmentButton';
import {
  formatDateTimeToString,
  getMomentValue
} from './../../../../lib/helpers';
import { Icon } from '../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import type { FuelCard } from '../../../../lib/types';
import { fuelGroups } from '../../../../lib/enum';
import { SectionTitle } from '../../../../components/layout';

const { Field } = Card;
const ButtonGroup = Button.Group;

const Content = styled.div`
  padding: 16px;
`;

type Props = {
  onChange: (fuelCard: FuelCard) => void,
  onRemove: (fuelCard: FuelCard) => void,
  fuelCard: FuelCard,
  editable: boolean
};

type State = {
  editMode: boolean,
  fuelCard: FuelCard
};

class FuelCardItem extends Component<Props, State> {
  state = {
    editMode: false,
    fuelCard: this.props.fuelCard
  };

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.fuelCard, this.props.fuelCard)) {
      this.setState({
        fuelCard: this.props.fuelCard
      });
    }
  }

  onApply = async () => {
    await this.props.onChange(this.state.fuelCard);
    this.setState({
      editMode: false
    });
  };

  changeFuelCard = (fieldName: $Keys<FuelCard>, value: any) => {
    this.setState(prevState => ({
      fuelCard: {
        ...prevState.fuelCard,
        [(fieldName: string)]: value
      }
    }));
  };

  onCancel = () =>
    this.setState({
      fuelCard: this.props.fuelCard,
      editMode: false
    });

  render() {
    const { onRemove, editable } = this.props;
    const { fuelCard, editMode } = this.state;
    if (!fuelCard) return null;
    const {
      cardNumber,
      fuelGroup,
      installDate,
      removeDate,
      limit,
      balance
    } = fuelCard;
    return (
      <Fragment>
        <SectionTitle
          suffix={
            editable && (
              <ButtonGroup>
                {editMode ? (
                  <Fragment>
                    <Button
                      data-test="check"
                      onClick={this.onApply}
                      icon="check"
                    />
                    <Button data-test="close" onClick={this.onCancel}>
                      <Icon type="x" />
                    </Button>
                  </Fragment>
                ) : (
                  <Button
                    data-test="edit"
                    onClick={() => this.setState({ editMode: true })}
                  >
                    <Icon type="edit" />
                  </Button>
                )}
                <RemoveEquipmentButton
                  onConfirm={(removeDate: moment | string) =>
                    onRemove({
                      ...this.props.fuelCard,
                      removeDate
                    })
                  }
                />
              </ButtonGroup>
            )
          }
        >
          Топливная карта
        </SectionTitle>
        <Content>
          <Grid gutter="16px">
            <GridItem>
              <Field label="Номер карты">{cardNumber}</Field>
            </GridItem>
            <GridItem>
              <Field label="Тип топлива">{fuelGroups[fuelGroup]}</Field>
            </GridItem>
            <GridItem>
              <Field label="Лимит">{limit || '-'}</Field>
            </GridItem>
            <GridItem>
              <Field label="Остаток">
                {editMode ? (
                  <InputNumber
                    name="balance"
                    min={0}
                    value={balance}
                    onChange={(value: number) =>
                      this.changeFuelCard('balance', value)
                    }
                  />
                ) : (
                  <>{balance || '-'}</>
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Дата установки">
                {editMode ? (
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={getMomentValue(installDate)}
                    onChange={(value: Object, dateString: string) =>
                      this.changeFuelCard(
                        'installDate',
                        formatDateTimeToString(value, dateString)
                      )
                    }
                  />
                ) : (
                  formatDateTimeToString(installDate, 'DD.MM.YYYY')
                )}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Дата снятия">
                {editMode ? (
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={getMomentValue(removeDate)}
                    onChange={(value: Object, dateString: string) =>
                      this.changeFuelCard(
                        'removeDate',
                        formatDateTimeToString(value, dateString)
                      )
                    }
                  />
                ) : (
                  formatDateTimeToString(removeDate, 'DD.MM.YYYY')
                )}
              </Field>
            </GridItem>
          </Grid>
        </Content>
      </Fragment>
    );
  }
}

export default FuelCardItem;
