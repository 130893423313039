// @flow

import React from 'react';
import notification from 'antd/lib/notification';
import { tripApi } from '../../../../../lib/api';
import type { Trip, Vehicle, VehicleType } from '../../../../../lib/types';
import {
  contractTripStatusEnum,
  contractTripStatuses,
  entityStatusEnum,
  vehicleTypes
} from '../../../../../lib/enum';
import { Filter } from '../../../../Trips';
import { ListTable } from '../../../lib';
import type { ContractTripStatus } from '../../../../../lib/types/trip';
import { Link } from '@reach/router';
import {
  applyMaskToValue,
  formatDateRangeString
} from '../../../../../lib/helpers';
import { formatLicensePlateMask } from '../../../../../components/masked-inputs/LicensePlateInput';

export const COLUMNS = [
  {
    title: '№',
    fixed: true,
    dataIndex: 'contractIdNumber',
    sorter: true,
    width: 50
  },
  {
    title: 'Статус',
    dataIndex: 'contractTripStatus',
    sorter: true,
    stopPropagation: true,
    render: (status: ContractTripStatus, record: Trip) => (
      <Link to={`/trips/contract/${record.id}/card`}>
        {contractTripStatuses[status]}
      </Link>
    )
  },
  {
    title: 'Тип',
    dataIndex: 'vehicleType',
    sorter: true,
    render: (vehicleType: VehicleType, record: Trip): ?string =>
      record.vehicle
        ? record.vehicle.vehicleModel &&
          vehicleTypes[record.vehicle.vehicleModel.type]
        : vehicleTypes[vehicleType]
  },
  {
    title: 'Служба',
    sorter: true,
    sorterKey: 'node.name',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Период выделения',
    dataIndex: 'startDate',
    sorterKey: 'startDate',
    sorter: true,
    render: (
      text: string, // eslint-disable-line no-unused-vars
      record: Trip
    ): string => formatDateRangeString(record.startDate, record.endDate)
  },
  {
    title: 'ТС',
    dataIndex: 'vehicle',
    sorter: true,
    // Нужно для того, чтобы не отрабатывал onRow
    onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
    render: (vehicle: Vehicle, trip: Trip) => {
      if (vehicle) {
        const licensePlate =
          vehicle.licensePlate &&
          applyMaskToValue(vehicle.licensePlate, formatLicensePlateMask);
        if (vehicle.isDeleted) {
          return licensePlate;
        }
        return (
          <Link to={`/vehicles/contract/${trip.contractVehicleId || ''}/card`}>
            {licensePlate || trip.licensePlate}
          </Link>
        );
      }
      return null;
    }
  },
  {
    title: 'Пробег по регламенту',
    dataIndex: 'regulationsDistance',
    render: (distance: number) => !!distance && `${distance.toFixed(2)} км`
  },
  {
    title: 'Пробег от подрядчика',
    dataIndex: 'distanceByContractor',
    render: (distance: number) => !!distance && `${distance.toFixed(2)} км`
  },
  {
    title: 'Пробег по GPS',
    dataIndex: 'autographDistance',
    render: (distance: number) => !!distance && `${distance.toFixed(2)} км`
  }
];

export default class extends ListTable<Trip> {
  fetch = async (page: number = 1) => {
    const { actStatus } = this.props;
    const params = {
      ...super.fetch(page),
      createdByFirstBit: true,
      contractTripStatus:
        actStatus === entityStatusEnum.approved
          ? contractTripStatusEnum.accepted
          : contractTripStatusEnum.verified
    };
    try {
      const data = await tripApi.fetchTrips(params);
      const totalSum = await tripApi.getTotalSum(params);
      this.setState({ ...data, totalSum, loading: false, page });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    return (
      <>
        {super.renderFilter(Filter)}
        {super.renderTable(COLUMNS)}
      </>
    );
  }
}
