// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import type {
  ExpenseDirectionType,
  Act,
  ActStatusType,
  UserAccess
} from '../../../../lib/types';
import {
  expenseDirectionTypeEnum,
  actStatusEnum,
  contractTripStatusEnum
} from '../../../../lib/enum';
import { setQueryParams } from '../../../../lib/helpers';
import {
  expertiseServiceGpmApi,
  inspectionGibddApi,
  inspectionGtnApi,
  licensePlateChangeApi,
  maintenanceApi,
  measuringDeviceCertificationApi,
  osagoApi,
  passApi,
  washingApi,
  platonApi,
  vehicleMonitoringApi,
  fuelCardApi,
  tripApi,
  driverInspectionApi
} from '../../../../lib/api';

import { Section } from '../../../../components/layout';

import { withUserAccess } from '../../../withUserAccess';
import { handlingExternalActs } from '../../accessRight';

import {
  Pass,
  ExpertiseServiceGpm,
  InspectionsGibdd,
  InspectionsGtn,
  MeasuringDeviceCertifications,
  LicensePlateChange,
  Washing,
  Maintenance,
  ContractTrip,
  Osago,
  Platon,
  VehicleMonitoring,
  DriverInspection
} from './listTable';

const Content = styled.div`
  padding: 16px;
`;
type Props = {
  services: ExpenseDirectionType,
  actId: number,
  act: Act,
  changeStatusAct: Function,
  userAccess: UserAccess[]
};
type State = {
  unSelected: any,
  filter: any,
  totalSumApi: ?Function,
  Component: ?any
};

export class List extends Component<Props, State> {
  state = {
    unSelected: {},
    filter: {},
    totalSumApi: null,
    Component: false
  };

  componentDidMount() {
    this.setComponentAndTotalSumApi();
  }

  onSelectAll = (
    data: any,
    selected: boolean,
    selectedRecord: any[],
    fieldId: string = 'id'
  ) => {
    let { unSelected } = this.state;
    if (selected) {
      selectedRecord.forEach(
        item => unSelected[item[fieldId]] && delete unSelected[item[fieldId]]
      );
    } else {
      data.forEach(item => (unSelected[item[fieldId]] = item));
    }
    this.setState({ unSelected });
  };

  onSelect = (record: any, selected: boolean, fieldId: string = 'id') => {
    let { unSelected } = this.state;
    if (!selected) {
      unSelected[record[fieldId]] = record;
    } else {
      delete unSelected[record[fieldId]];
    }
    this.setState({ unSelected });
  };

  applyFilter = (filter: any) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  changeStatus = async (status: ActStatusType) => {
    const { totalSumApi } = this.state;
    await this.props.changeStatusAct(status, totalSumApi);
  };

  handleSubmit = async () => {
    const { actId, services } = this.props;
    const { filter, unSelected } = this.state;
    const maintenanceIds = [...Object.keys(unSelected)];
    const data = { actId, maintenanceIds };

    switch (services) {
      case expenseDirectionTypeEnum.driverInspection:
        await driverInspectionApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.expertise:
        await expertiseServiceGpmApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.gibdd:
        await inspectionGibddApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.gtnTax:
        await inspectionGtnApi.updateTaxAct(filter, data);
        break;
      case expenseDirectionTypeEnum.gtnWork:
        await inspectionGtnApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.licensePlateChange:
        await licensePlateChangeApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.battery:
      case expenseDirectionTypeEnum.tires:
      case expenseDirectionTypeEnum.currentRepair:
      case expenseDirectionTypeEnum.overhaul:
      case expenseDirectionTypeEnum.maintenance:
        await maintenanceApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.measuringDeviceCertification:
        await measuringDeviceCertificationApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.osago:
        await osagoApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.washing:
        await washingApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.carServices:
      case expenseDirectionTypeEnum.otherVehicleServices:
      case expenseDirectionTypeEnum.acsVehicleServices:
      case expenseDirectionTypeEnum.cargoServices:
      case expenseDirectionTypeEnum.mechanismServices:
      case expenseDirectionTypeEnum.provisionVehicleSecvices:
        await tripApi.updateAct(
          {
            ...filter,
            createdByFirstBit: true,
            contractTripStatus: contractTripStatusEnum.verified
          },
          data
        );
        break;
      case expenseDirectionTypeEnum.federalHighway:
        await platonApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.gps:
        await vehicleMonitoringApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.oilsAndGreases:
        await fuelCardApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.pass:
        await passApi.updateAct(filter, data);
        break;
      case expenseDirectionTypeEnum.passTax:
        await passApi.updateTaxAct(filter, data);
        break;
      default:
    }

    setQueryParams({ page: 1 });
    await this.changeStatus(actStatusEnum.signed);
    this.setState({ unSelected: {} });
  };

  setComponentAndTotalSumApi = () => {
    const { services } = this.props;
    let Component = false;
    let totalSumApi = null;
    switch (services) {
      case expenseDirectionTypeEnum.driverInspection:
        Component = DriverInspection;
        totalSumApi = driverInspectionApi.getTotalSum;
        break;
      case expenseDirectionTypeEnum.expertise:
        Component = ExpertiseServiceGpm;
        totalSumApi = expertiseServiceGpmApi.getTotalSum;
        break;
      case expenseDirectionTypeEnum.gibdd:
        Component = InspectionsGibdd;
        totalSumApi = inspectionGibddApi.getTotalSum;
        break;
      case expenseDirectionTypeEnum.gtnTax:
        totalSumApi = inspectionGtnApi.getTaxTotalSum;
        Component = InspectionsGtn;
        break;
      case expenseDirectionTypeEnum.gtnWork:
        totalSumApi = inspectionGtnApi.getTotalSum;
        Component = InspectionsGtn;
        break;
      case expenseDirectionTypeEnum.licensePlateChange:
        totalSumApi = licensePlateChangeApi.getTotalSum;
        Component = LicensePlateChange;
        break;
      case expenseDirectionTypeEnum.battery:
      case expenseDirectionTypeEnum.tires:
      case expenseDirectionTypeEnum.currentRepair:
      case expenseDirectionTypeEnum.overhaul:
      case expenseDirectionTypeEnum.maintenance:
        totalSumApi = maintenanceApi.getTotalSum;
        Component = Maintenance;
        break;
      case expenseDirectionTypeEnum.measuringDeviceCertification:
        totalSumApi = measuringDeviceCertificationApi.getTotalSum;
        Component = MeasuringDeviceCertifications;
        break;
      case expenseDirectionTypeEnum.osago:
        totalSumApi = osagoApi.getTotalSum;
        Component = Osago;
        break;
      case expenseDirectionTypeEnum.washing:
        totalSumApi = washingApi.getTotalSum;
        Component = Washing;
        break;
      case expenseDirectionTypeEnum.carServices:
      case expenseDirectionTypeEnum.otherVehicleServices:
      case expenseDirectionTypeEnum.acsVehicleServices:
      case expenseDirectionTypeEnum.cargoServices:
      case expenseDirectionTypeEnum.mechanismServices:
      case expenseDirectionTypeEnum.provisionVehicleSecvices:
        totalSumApi = tripApi.getTotalSum;
        Component = ContractTrip;
        break;
      case expenseDirectionTypeEnum.federalHighway:
        totalSumApi = platonApi.getTotalSum;
        Component = Platon;
        break;
      case expenseDirectionTypeEnum.gps:
        totalSumApi = vehicleMonitoringApi.getTotalSum;
        Component = VehicleMonitoring;
        break;
      case expenseDirectionTypeEnum.pass:
        totalSumApi = passApi.getTotalSum;
        Component = Pass;
        break;
      case expenseDirectionTypeEnum.passTax:
        totalSumApi = passApi.getTaxTotalSum;
        Component = Pass;
        break;
      default:
    }
    this.setState({ totalSumApi, Component });
  };

  canAdd = () =>
    this.props.userAccess.some(access => handlingExternalActs.includes(access));

  render() {
    const { actId, act, services } = this.props;
    const { unSelected, Component } = this.state;
    // const Component = this.getComponent();
    return (
      <>
        {Component && (
          <Section>
            <Content>
              <Component
                services={services}
                actId={actId}
                actSum={act.incomeSchedule && act.incomeSchedule.sum}
                actStatus={act.status}
                isTax={[
                  expenseDirectionTypeEnum.passTax,
                  expenseDirectionTypeEnum.gtnTax
                ].includes(services)}
                readOnly={[
                  actStatusEnum.signed,
                  actStatusEnum.workedOut,
                  actStatusEnum.approved
                ].includes(act.status)}
                applyFilter={this.applyFilter}
                cleanFilter={this.cleanFilter}
                onSelect={this.onSelect}
                onSelectAll={this.onSelectAll}
                location={window.location}
                unSelected={unSelected}
              />
            </Content>
          </Section>
        )}
        {this.canAdd() &&
          ![actStatusEnum.approved, actStatusEnum.workedOut].includes(
            act.status
          ) && (
            <Section>
              <Content>
                {act.status === actStatusEnum.created && Component !== false && (
                  <Button onClick={this.handleSubmit} type="primary">
                    Сформировать
                  </Button>
                )}
                {(act.status === actStatusEnum.signed ||
                  Component === false) && (
                  <>
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={() => this.changeStatus(actStatusEnum.workedOut)}
                      type="primary"
                    >
                      Согласовать
                    </Button>
                    {Component !== false && (
                      <Button
                        onClick={async () =>
                          await this.changeStatus(actStatusEnum.created)
                        }
                      >
                        Отменить
                      </Button>
                    )}
                  </>
                )}
              </Content>
            </Section>
          )}
      </>
    );
  }
}

export default withUserAccess(List);
