// @flow
import React from 'react';

import type {
  DetailedDefectiveStatement,
  OrderContractorType,
  MaintenanceTypeOfWorkType
} from '../../../../lib/types';
import { orderContractorTypeEnum } from '../../../../lib/enum';

import { ListTable } from '../../../../components/ui';

import { sumPartsAndConsumbles } from './../lib';
import { itog, partColumns } from './../elements';

type Props = {
  workType: MaintenanceTypeOfWorkType,
  orderContractorType: OrderContractorType,
  detailedDefectiveStatement: DetailedDefectiveStatement
};

/**
 * Запчасти и расходные материалы
 */
export default class PartsAndConsumables extends React.Component<Props> {
  static defaultProps = {
    detailedDefectiveStatement: null
  };

  isContractor = () =>
    this.props.orderContractorType === orderContractorTypeEnum.contractor;

  render() {
    const { detailedDefectiveStatement, workType } = this.props;
    const data = detailedDefectiveStatement.parts;
    const { sum, sumContractor } = sumPartsAndConsumbles(
      data,
      this.isContractor()
    );
    return (
      <>
        <ListTable
          columns={partColumns(this.isContractor(), undefined, true, workType)}
          data={data}
        />
        {itog(sum, sumContractor, this.isContractor())}
      </>
    );
  }
}
