/* Orders.js | Page | Заявки на ТС */
import { Link } from '@reach/router';
import Button from 'antd/lib/button';
import Icon from 'antd/lib/icon';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notificationLoading } from '../../../components/Notifications';
import { Section } from '../../../components/layout';
import { ButtonsRow, Dropdown, TabItem, Table, Tabs } from '../../../components/ui';
import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';
import {
  accessTypeEnum, orderObjectives, orderStatusesForFilters, orderTypes as typesOfOrder,
  vehicleGroups, vehicleTypes
} from '../../../lib/enum';
import { formatDateRangeString, getListInitialState, navigate, setQueryParams } from '../../../lib/helpers';
import type { Employee, ListState, Order, OrderObjective, OrderType, UserAccess, Vehicle, VehicleType } from '../../../lib/types';
import type { OrderFilterParams } from '../Filter';
import FilterOrder from '../Filter';
import type { FilterCalculationParams } from '../FilterCalculation';
import FilterCalculation from "../FilterCalculation";
import { canCopyOrder } from '../lib';
import Header from './../../../components/layout/Header';
import { orderApi, type FetchListParams } from './../../../lib/api';
import { withUserAccess } from './../../withUserAccess';
import CopyOrderButton from './../components/CopyOrderButton';

// Тип заявки.
export const orderPageType = {
  income: 'income',
  archive: 'archive',
  calculation: 'calculation',
};

// Права доступа к добавлению.
export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.handlingOrder,
  accessTypeEnum.creatingOrder,
];

// Права доступа к просморту вкладки "Расчёт стоимости ТС".
export const canViewAccessCalculation = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.viewingOrderCalculation,
  accessTypeEnum.viewingAllOrderCalculation,
];

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } },
  pageType: $Values<typeof orderPageType>,
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Order> & {
  filterPath: string
};

export class OrderList extends Component<Props, State> {
  static defaultProps = { location: {} };
  state = {
     ...getListInitialState(),
    filterPath: window.location.pathname,
  };

  // Права доступа.
  canAddOrder = () => this.props.userAccess.some(access => canAddAccess.includes(access));
  // Права доступа.
  canViewCalculation = () => this.props.userAccess.some(access => canViewAccessCalculation.includes(access));

  // Колонки таблицы.
  getColumns = () => {
    let columns = [
      { title: '№', dataIndex: 'id', sorter: true,
        // Нужно для того, чтобы не отрабатывал onRow
        onCell: () => ({onClick: (e: any) => e.stopPropagation()}),
        render: (id: number) => this.props.pageType === orderPageType.calculation
          ? <Link to={`/orders/${id}/calculation`}>{id}</Link>
          : <Link to={`/orders/${id}/card`}>{id}</Link>},
      { title: 'Дата подачи заявки', dataIndex: 'createdDate', key: 'createdDateOrder' },
    ];
    // ==========================================================================
    // Вкладки "Входящие заявки" и "Архив"
    // ==========================================================================
    if (this.props.pageType === orderPageType.income || this.props.pageType === orderPageType.archive) {
      // Вставить колонки.
      columns.splice(2, 0,
        { title: 'Период выделения', dataIndex: 'startDate', sorterKey: 'route.startDate', sorter: true,
          render: (text: string, record: Order): ?string => formatDateRangeString(record.startDate, record.endDate) },
        { title: 'Статус', dataIndex: 'status', sorter: true,
          render: (status: string) => orderStatusesForFilters[status] },
        { title: 'Служба', dataIndex: 'orgUnitName', sorterKey: 'node.name', sorter: true },
        { title: 'Примечание', dataIndex: 'notation', sorter: false },
        { title: 'Тип', dataIndex: 'vehicleType', sorter: true,
          render: (text: string, record: Order): ?string => vehicleTypes[text] || vehicleGroups[record.vehicleGroup] },
        { title: 'Цель поездки', dataIndex: 'objective', sorter: true,
          render: (text: string): ?string => orderObjectives[text] },
        { title: 'Заявитель', dataIndex: 'claimant',
          render: (claimant: Employee) => {
            return claimant
              ? `${claimant.lastname} ${claimant.firstname} ${claimant.middlename}`
              : '-'; } }
      );
    }
    // ==========================================================================
    // Вкладка "Входящие заявки"
    // ==========================================================================
    if (this.props.pageType === orderPageType.income) {
      // Вставить колонки.
      columns.splice(2, 0,
        { title: 'Время подачи заявки', dataIndex: 'createdTime' },
        { title: 'В распоряжении', dataIndex: 'employee',
          render: (employee: Employee) => {
            return employee
              ? `${employee.lastname} ${employee.firstname} ${employee.middlename}`
              : '-'; } },
        { title: 'Тип заявки', dataIndex: 'type',
          render: (type: OrderType) => typesOfOrder[type] },
      );
    }
    // ==========================================================================
    // Вкладка "Архив"
    // ==========================================================================
    if (this.props.pageType === orderPageType.archive) {
      // Вставить колонки.
      columns.splice(2, 0,
        { title: 'Время подачи заявки', dataIndex: 'createdTime' },
        { title: 'Гос. номер', dataIndex: 'vehicle',
          render: (vehicle: Vehicle) => vehicle && vehicle.licensePlate ? vehicle.licensePlate : null },
        { title: 'Марка', dataIndex: 'vehicle.vehicleModel.brandName' },
        { title: 'Модель', dataIndex: 'vehicle.vehicleModel.name' },
      );
      columns.splice(8, 0, { title: 'Причина отклонения', dataIndex: 'toReworkReasonTitle' });
    }
    // ==========================================================================
    // Вкладка "Расчёт стоимости ТС"
    // ==========================================================================
    if (this.canViewCalculation() && this.props.pageType === orderPageType.calculation) {
      // Вставить колонки.
      columns.splice(1, 0,
        { title: 'Дата подачи запроса', dataIndex: 'createdDate', key: 'createdDateCalculation' },
        { title: 'Время подачи запроса', dataIndex: 'createdTime' },
        { title: 'В распоряжении', dataIndex: 'orgUnitName' },
        { title: 'Период выделения', dataIndex: 'endDate',
          render: (text: string, record: Order): ?string  => formatDateRangeString(record.startDate, record.endDate) },
        { title: 'Тип ТС', dataIndex: 'vehicleType',
          render: (vehicleType: VehicleType) => vehicleType && vehicleTypes[vehicleType] },
        { title: 'Цель поездки', dataIndex: 'objective',
          render: (objective: OrderObjective) => objective && orderObjectives[objective] },
      );
    }
    // ==========================================================================
    // Кнопка "Копировать заявку"
    // ==========================================================================
    if ((this.props.pageType === orderPageType.income || this.props.pageType === orderPageType.archive) &&
        canCopyOrder(this.props.userAccess)) {
      columns.push({
        title: '',
        width: '30px',
        // Нужно для того, чтобы не отрабатывал onRow
        onCell: () => ({onClick: (e: any) => e.stopPropagation()}),
        render: (record: Order) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <CopyOrderButton orderId={record.id} />
                </Menu.Item>
              </Menu>
            }
          >
            <Icon style={{fontSize: 16, color: '#2770FF'}} type="ellipsis" />
          </Dropdown>
        ),
      });
    }
    return columns;
  };

  // Клик по строке таблиц "Входящие заявки" и "Архив".
  handleRowClick = (id: number) => navigate(`/orders/${id}/card`);

  // Клик по строке таблицы "Расчёт стоимости ТС".
  handleRowCalculationClick = (id: number) => navigate(`/orders/${id}/calculation`);

  // Печать.
  handlePrint = async () => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath];
    try {
      notificationLoading({
        message: 'Файл для печати формируется',
        key: 'print',
      });
      await orderApi.print(
        {
          ...filter,
          nodeId: filter?.orgUnitId,
          isArchive: this.props.pageType === orderPageType.archive,
        },
        'РеестрЗаявок.xlsx',
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  fetchOrders = async (page: number = 1, params: FetchListParams<OrderFilterParams> = {}) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({loading: true});
    const {data, totalCount} = await orderApi.fetchOrders({
      page,
      isArchive: this.props.pageType === orderPageType.archive,
      ...filter,
      ...params,
    });
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };

  fetchCalculations = async (page: number = 1, params: FetchListParams<FilterCalculationParams> = {}) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    this.setState({loading: true});
    const {data, totalCount} = await orderApi.fetchOrdersCalculations({
      page,
      ...filter,
      ...params,
    });
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };

  // Применить фильтры заявок / архива.
  setPersistFilterOrder = async (values: OrderFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  // Применить фильтры расчёта стоимости ТС.
  setPersistFilterCalculation = async (values: FilterCalculationParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  // Очистить фильтры заявок / архива.
  cleanFilterOrder = async () => {
    await this.setPersistFilterOrder({});
    await this.fetchOrders();
  };

  // Очистить фильтры расчёта стоимости ТС.
  cleanFilterCalculation = async () => {
    await this.setPersistFilterCalculation({});
    await this.fetchCalculations();
  };

  // Применить фильтры заявок / архива.
  applyFilterOrder = async (values: OrderFilterParams) => {
    await this.setPersistFilterOrder(values);
    await this.fetchOrders();
  };

  // Применить фильтры  расчёта стоимости ТС.
  applyFilterCalculation = async (values: FilterCalculationParams) => {
    await this.setPersistFilterCalculation(values);
    await this.fetchCalculations();
  };

  async componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      if (this.props.pageType === orderPageType.calculation)
        await this.setPersistFilterCalculation(filter);
      else
        await this.setPersistFilterOrder(filter);
    }
    if (this.props.pageType === orderPageType.calculation)
      await this.fetchCalculations(page);
    else
      await this.fetchOrders(page);
  }

  render() {
    const {location, userAccess} = this.props;
    const {data, totalCount, pageSize, page, loading, filterPath} = this.state;
    const filter = this.props.persistFilters[filterPath];
    const canAdd = this.canAddOrder();
    const handlingOrder = userAccess.includes(accessTypeEnum.handlingOrder);
    const handlingCalculation = userAccess.includes(accessTypeEnum.viewingOrderCalculation | accessTypeEnum.viewingAllOrderCalculation);
    return (
      <>
        <Header
          left={<h1>Заявки на транспортные средства</h1>}
          right={(this.canViewCalculation() && this.props.pageType === orderPageType.calculation)
            ? null
            : <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {canAdd && (
                <Link to="/orders/new">
                  <Button type="primary" data-cy="addOrder">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Tabs withRouter>
          <TabItem url="/orders" label="Входящие заявки" />
          <TabItem url="/orders/archive" label="Архив" />
          {this.canViewCalculation() && (
            <TabItem url="/orders/calculation" label="Расчёт стоимости ТС" />
          )}
        </Tabs>
        <Section>
          {/* Фильтр */}
          {(this.canViewCalculation() && this.props.pageType === orderPageType.calculation)
          ? <FilterCalculation
              handlingCalculation={handlingCalculation}
              mode={this.props.pageType}
              applyFilter={this.applyFilterCalculation}
              cleanFilter={this.cleanFilterCalculation}
              filter={filter}
            />
          : <FilterOrder
              handlingOrder={handlingOrder}
              mode={this.props.pageType}
              applyFilter={this.applyFilterOrder}
              cleanFilter={this.cleanFilterOrder}
              filter={filter}
            />}
          {/* Таблица */}
          {(this.canViewCalculation() && this.props.pageType === orderPageType.calculation)
            ? <Table
              onRow={record => ({onClick: () => this.handleRowCalculationClick(record.id)})}
              loading={loading}
              fetch={this.fetchCalculations}
              columns={this.getColumns()}
              pagination={{
                page,
                pageSize,
                totalCount,
                location,
              }}
              data={data.map(item => ({...item, key: item.id}))}
            />
            : <Table
              onRow={record => ({onClick: () => this.handleRowClick(record.id)})}
              loading={loading}
              fetch={this.fetchOrders}
              columns={this.getColumns()}
              pagination={{
                page,
                pageSize,
                totalCount,
                location,
              }}
              data={data.map(item => ({...item, key: item.id}))}
            />
          }
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters,
  }),
  {
    setFilter,
  },
)(withUserAccess(OrderList));
