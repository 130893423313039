// @flow
import React, {type Node, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';
import {SelectProps} from 'antd/lib/select';
import notification from 'antd/lib/notification';

import Select, {placeholderCustom, searchInputCustom} from './../ui/Select';
import {Spinner} from './../../components';
import {isNumberValue} from '../../lib/helpers';

const StyledSelect = styled(Select)`
  width: 100%;
  
  & ${placeholderCustom} & ${searchInputCustom}
`;

const { Option } = Select;

type Props = SelectProps & {
  filters?: any,
  /**
   * Функция, для подгрузки данных списка.
   *
   * По-умолчанию, когда список только появился на странице,
   * подгружается 50 записей.
   */
  fetch: () => Promise<any>,
  /**
   * Функция для подгрузки одного значения.
   *
   * Используется для добавления с список значения,
   * id которого пришел в value и которое отсутствует
   * с списке подгруженных по-умолчанию.
   */
  fetchSingle: (id: number, params: any) => Promise<any>,
  /**
   * Имя ключа, для которого выбирается значение
   * @default id
   */
  valueKey: string,
  /**
   * Текст сообщения при отсутствии данных
   * @default Нет данных
   */
  notFoundText: string,
  /**
   * Функция для отображения компонента списка (Option)
   * @param item Элемент списка
   * @param Option Компонент списка
   */
  renderOption: (item: any, Option: Option) => Node,
  /**
   * Подгружать ли данные при фокусе на селекте
   * @default false
   */
  fetchOnFocus: boolean,
  /**
   * Пропсы, при изменении которых необходима переподгрузка данных
   */
  refetchParams?: any,
  /**
   * Функция вызываемая при изменении значения
   * @param value Значение по ключу valueKey
   * @param optionPayload Данные из data
   */
  onChange?: (value: any, optionPayload: any) => void,
  /**
   * Параметр, позволяющий запрашивать одиночные элементы через пропс fetchSingle
   * любых типов, а не только числовых, как реализованно сейчас
   * @default false
   */
  singleFetchingAnyTypes?: boolean
};

/**
 * Ассинхронный селект с возможностью поиска.
 */
const AutocompleteSelect = (props: Props) => {
  let mounted = useRef(true); //контроль того, что компонент не удален во время асинхронной операции
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = async (search: string) => {
    setIsLoading(true);
    // отправляем запрос с ограничением в 50 результатов
    const { data } = await props.fetch({
      search,
      page: 1,
      pageSize: 50
    });
    setData(data);
    setIsLoading(false);
  };

  /**
   * Функция для подгрузки данных
   *
   * Если value не указан, то подгружаются первые 50 записей
   * иначе подгружается 50 + 1 запись, связанная с value
   *
   * @param value Текущее значение селекта, которое нужно подгрузить
   */
  const fetchData = async (value?: number) => {
    try {
      const { disabled, fetchSingle, fetch, singleFetchingAnyTypes } = props;
      if (mounted.current) {
        if (mounted.current) setIsLoading(true);
        let loadedData = data;
        if (!disabled) {
          const { data } = await fetch({
            page: 1,
            pageSize: 50
          });
          loadedData = data;
        }
        // Проп fetchSingle обязателен
        if (typeof fetchSingle !== 'function') {
          throw new Error('Prop fetchSingle must be a function');
        }
        // Если value - число
        if (typeof value === 'number' || (value && singleFetchingAnyTypes)) {
          // Запрашиваем запись
          const loadedValue = await fetchSingle(value, {
            returnDeleted: true
          });
          const isValueLoaded = !isNil(loadedValue);
          // И добавляем эту запись, если она существует
          if (mounted.current) {
            setData(
              isValueLoaded
                ? uniqBy([loadedValue, ...loadedData], 'id')
                : loadedData
            );
          }
        } else {
          if (mounted.current) {
            setData(loadedData);
          }
        }
        if (mounted.current) {
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  const handleFocus = async (e: any) => {
    const { onFocus, fetchOnFocus } = props;
    if (onFocus) {
      onFocus(e);
    }
    if (fetchOnFocus) {
      await fetchData();
    }
  };

  const handleChange = async (value: any, option: any) => {
    const { onChange } = props;
    if (value === undefined) await fetchData();
    if (onChange) {
      onChange(value, option);
    }
  };

  const handleSearch = debounce(fetch, 500);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, [props.filters]);

  useEffect(() => {
    fetchData(props.value);
    // eslint-disable-next-line
  }, [props.value, props.refetchParams]);

  const {
    notFoundText = 'Не найдено',
    size,
    renderOption,
    value,
    ...selectProps
  } = props;

  const Component = size === 'small' ? StyledSelect : Select;
  const val = value
    ? isNumberValue(value)
      ? parseFloat(value)
      : value
    : value;
  return (
    <Component
      showSearch
      onSearch={handleSearch}
      filterOption={false}
      defaultActiveFirstOption={false}
      notFoundContent={<Spinner isLoading={isLoading}>{notFoundText}</Spinner>}
      defaultValue={null}
      loading={isLoading}
      allowClear
      {...selectProps}
      {...props}
      value={val}
      onFocus={handleFocus}
      onChange={handleChange}
    >
      {isLoading
        ? null
        : data.map((item, index) => renderOption(item, Option, index))}
    </Component>
  );
};

AutocompleteSelect.defaultProps = {
  notFoundText: 'Не найдено',
  fetchOnFocus: false,
  valueKey: 'id',
  refetchParams: {}
};

export default AutocompleteSelect;
