// @flow
import { notification } from 'antd';
import React, { type ComponentType, type Node, useEffect } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import {
  FastField,
  Field,
  Formik,
  FormikActions,
  FormikBag,
  type FormikErrors,
  FormikProps
} from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';

export type ValidateStatus =
  | 'success'
  | 'warning'
  | 'error'
  | 'validating'
  | any;

type FormFieldChildrenProps = {
  value: any,
  setValue: (value: any) => void,
  target: Node,
  defaultValue?: any
};

/**
 * Компонент для отрисовки содержимого поля формы
 * и установки значения по умолчанию
 */
const FormFieldChildren = ({
  value,
  setValue,
  target,
  defaultValue
}: FormFieldChildrenProps) => {
  useEffect(() => {
    if (defaultValue && isNil(value)) {
      setValue(defaultValue);
      // На сервер лучше не отсылать пустые строки
    } else if (value === '') setValue(undefined);
  }, [value, defaultValue, setValue]);

  return target || null;
};

export type FormFieldProps = {
  name?: string,
  required?: boolean,
  error?: string,
  hasFeedback?: boolean,
  label?: string,
  children: (formikProps: FormikProps) => Node,
  validate?: (value: any) => ?string,
  validateStatus?: (
    value: any,
    error: string
  ) => ?ValidateStatus | ?ValidateStatus,
  // Функция для отображения вспомогательного текста (для ошибок и т.д)
  help?: ((value: any, error: string) => ?string) | ?string,
  // использовать FastField
  fast?: boolean,
  // текст ошибки
  requiredErrorMessage?: string,
  className?: string,
  style?: any,
  // Значение по-умолчанию, если в onChange приходит null
  defaultValue?: any,
  disableHelp?: boolean,
  renderHelp?: (error: string) => Node
};

export type FormFieldType = ComponentType<FormFieldProps>;

// Необходим, так как formik использует библиотеку deepmerge 2.2.1 для
// объединения ошибок из нескольких мест,
// в данной версии библлиотека не поддерживает копирование Symbol
export class RequiredFieldMessage {
  message: string;
  __required: symbol;
  constructor(message: string, isError?: boolean) {
    this.message = message;
    this.__required = Symbol.for(
      isError ? 'errorFieldMessage' : 'requiredFieldMessage'
    );
  }

  toString = () => {
    return this.message;
  };

  // так не проверять, так как babel забил хуй на поддержку, смотреть обсуждения от 2017 года
  // static check = (field: any) => field instanceof RequiredFieldMessage;
  static check = (field: any) =>
    field?.__required === Symbol.for('requiredFieldMessage');

  static checkAndReturnMessage = (field: any) => {
    if (RequiredFieldMessage.check(field)) {
      return field.message;
    } else {
      return field;
    }
  };
}

export const FormField = ({
  required,
  requiredErrorMessage = 'Обязательное поле',
  hasFeedback = true,
  name,
  children,
  label,
  validate,
  fast = false,
  className,
  help,
  validateStatus,
  defaultValue = null,
  disableHelp,
  style,
  renderHelp
}: FormFieldProps) => {
  const FormField = fast ? FastField : Field;
  return (
    <FormField
      name={name}
      validate={(value: string) => {
        if (required && isNil(value)) {
          return new RequiredFieldMessage(requiredErrorMessage);
        } else if (typeof validate === 'function') {
          return validate(value);
        }
      }}
      render={({ field, form }) => {
        const { value } = field;
        const { errors, setFieldValue } = (form: FormikBag);

        const helpMessage = disableHelp
          ? null
          : typeof help === 'function'
          ? help(
              value,
              RequiredFieldMessage.checkAndReturnMessage(get(errors, name))
            )
          : RequiredFieldMessage.checkAndReturnMessage(get(errors, name)) ||
            help;
        return (
          <FormItem
            style={style}
            className={className}
            label={label}
            required={required}
            hasFeedback={hasFeedback}
            validateStatus={
              typeof validateStatus === 'function'
                ? validateStatus(
                    value,
                    RequiredFieldMessage.checkAndReturnMessage(
                      get(errors, name)
                    )
                  )
                : RequiredFieldMessage.checkAndReturnMessage(get(errors, name))
                ? 'error'
                : validateStatus
            }
            help={!renderHelp && helpMessage}
          >
            <FormFieldChildren
              target={children(field)}
              value={field.value}
              setValue={value => setFieldValue(field.name, value)}
              defaultValue={defaultValue}
            />
            {renderHelp && helpMessage ? renderHelp(helpMessage) : null}
          </FormItem>
        );
      }}
    />
  );
};

FormField.defaultProps = {
  help: (value, error) => error,
  validateStatus: (value, error) => error && 'error'
};

type FormProps = {
  onSubmit?: (values: any, formikActions: FormikActions) => Promise<void>,
  onValidationError?: Function,
  validate?: (values: any) => FormikErrors,
  validationSchema?: any,
  initialValues: any,
  children: (
    FormField: ComponentType<FormFieldProps>,
    formikProps: FormikProps
  ) => Node,
  disableRequiredMessage?: boolean
};

const Form = ({
  children,
  validate,
  disableRequiredMessage = false,
  ...formikProps
}: FormProps) => (
  <Formik
    {...formikProps}
    onSubmit={async (values, actions) => {
      const { onSubmit } = formikProps;
      onSubmit && (await onSubmit(values, actions));
      actions.setSubmitting(false);
    }}
    initialValues={formikProps.initialValues || {}}
    enableReinitialize
    render={({
      handleSubmit,
      submitForm,
      validateForm,
      errors,
      ...formikProps
    }: FormikProps) =>
      children(FormField, {
        ...formikProps,
        errors: Object.entries(errors)?.reduce((errors, [key, value]) => {
          errors[key] = RequiredFieldMessage.checkAndReturnMessage(value);
          return errors;
        }, {}),
        validateForm,
        submitForm,
        handleSubmit: async e => {
          // копируем частично код handleSubmit, так как handleSubmit не возвращает promise
          if (e && e.preventDefault) {
            e.preventDefault();
          }

          await submitForm();

          if (!disableRequiredMessage) {
            const errors = await validateForm();

            let hasRequired = false;
            let error = null;
            const deepFind = obj => {
              Object.keys(obj).forEach(key => {
                if (typeof obj[key] === 'object') {
                  hasRequired =
                    obj[key]?.__required === Symbol.for('requiredFieldMessage');
                  if (
                    obj[key]?.__required === Symbol.for('errorFieldMessage')
                  ) {
                    error = obj[key].message;
                  }
                  !error && !hasRequired && deepFind(obj[key]);
                }
              });
            };

            deepFind(errors);

            hasRequired &&
              notification.error({
                message:
                  'Сохранение невозможно. Имеются незаполненные обязательные поля.'
              });
            error && notification.error({ message: error });
          }
        }
      })
    }
    validate={(values: any) => {
      if (validate !== undefined) {
        let errors = validate(values);
        return pickBy(errors, value => !isEmpty(value));
      }
    }}
  />
);

export default Form;
