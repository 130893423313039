// @flow
import React from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import AntIcon from 'antd/lib/icon';
import Menu from 'antd/lib/menu';

import type {Contract, User, UserAccess} from '../../lib/types';
import {contractApi, stageApi} from '../../lib/api';
import {contractDocumentTypeEnum, entityStatus, entityStatusEnum} from '../../lib/enum';
import {convertEmployeeToString, formatDateTimeToString, getPathWithHistoryParams, navigate} from './../../lib/helpers';

import {Card} from './../../components';
import Grid, {GridItem} from './../../components/layout/Grid';
import {Header, Panel, Section, SectionTitle} from './../../components/layout';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {Dropdown, Icon} from './../../components/ui';
import {notificationLoading} from './../../components/Notifications';

import {withUserAccess} from './../withUserAccess';
import {addContractAccess, approvingStatusAccess} from './accessRight';

import {Stages} from './components';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Content = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  contractId: number,
  userProfile: User,
  userAccess: UserAccess[]
};

type State = {
  contract: ?Contract
};

export class ContractCard extends React.Component<Props, State> {
  state = {
    contract: null
  };

  async componentDidMount() {
    const { contractId } = this.props;
    try {
      const contract = await contractApi.getContract(contractId);
      this.setState({ contract });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  fetchStages = async () => {
    const { contract } = this.state;
    if (contract) {
      try {
        const stages = await stageApi.fetchStage({ contractId: contract.id });
        this.setState({
          contract: {
            ...contract,
            stages: stages.data
          }
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  };

  handleCancel = () => navigate('/admin/contract/', true);

  changeStatus = async ({ key }: any) => {
    const { contractId } = this.props;
    const { contract } = this.state;
    try {
      notificationLoading({
        message: 'Смена статуса...',
        key: 'changeStatus'
      });
      if (
        contract &&
        isEmpty(contract.stages) &&
        [entityStatusEnum.approvement, entityStatusEnum.approved].includes(key)
      ) {
        throw new Error('Заполните этапы');
      }
      const updated = await contractApi.changeStatus(contractId, key);
      this.setState({ contract: updated });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('changeStatus');
    }
  };

  canChangeStatus = () => {
    const { contract } = this.state;
    return (
      this.props.userAccess.some(access =>
        approvingStatusAccess.includes(access)
      ) ||
      (contract && contract.userId === this.props.userProfile.id)
    );
  };

  isAccount = () =>
    this.state.contract?.documentType === contractDocumentTypeEnum.account;

  canAprroved = () =>
    this.props.userAccess.some(access =>
      approvingStatusAccess.includes(access)
    );

  canAdd = () =>
    this.props.userAccess.some(access => addContractAccess.includes(access));

  render() {
    const { contractId } = this.props;
    const { contract } = this.state;
    if (contract === null) {
      return null;
    }
    const status = contract && contract.contractStatus;
    const readOnly = true;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/contract/')}>
                Реестр договоров
              </Crumb>
              <Crumb>Договор №{contractId}</Crumb>
            </Breadcrumbs>
          }
          right={
            <>
              {!readOnly &&
                (this.canChangeStatus() || this.canAprroved()) &&
                [entityStatusEnum.created, entityStatusEnum.editing].includes(
                  status
                ) && (
                  <Button
                    onClick={() =>
                      this.changeStatus({ key: entityStatusEnum.approvement })
                    }
                  >
                    На согласование
                  </Button>
                )}
              {!readOnly &&
                this.canAprroved() &&
                [
                  entityStatusEnum.approvement,
                  entityStatusEnum.declined,
                  entityStatusEnum.approved
                ].includes(status) && (
                  <Dropdown
                    overlay={
                      <Menu onClick={this.changeStatus}>
                        {entityStatusEnum.approvement === status &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.approved}>
                              Утвердить
                            </Menu.Item>
                          )}
                        {entityStatusEnum.approvement === status &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.declined}>
                              Отклонить
                            </Menu.Item>
                          )}
                        {[
                          entityStatusEnum.approved,
                          entityStatusEnum.declined
                        ].includes(status) &&
                          this.canChangeStatus() && (
                            <Menu.Item key={entityStatusEnum.editing}>
                              Вернуть на редактирование
                            </Menu.Item>
                          )}
                      </Menu>
                    }
                  >
                    <Button>
                      <AntIcon
                        style={{ fontSize: 16, color: '#2770FF' }}
                        type="ellipsis"
                      />
                    </Button>
                  </Dropdown>
                )}
            </>
          }
        />
        {contract && (
          <>
            <StyledPanel>
              <h1 style={{ marginBottom: '10px' }}>
                {this.isAccount()
                  ? `Лицевой счет № ${contract.accountNumber}`
                  : `Договор № ${contract.documentNumber}`}
                {contract.registrationDate
                  ? ` от ${formatDateTimeToString(
                      contract.registrationDate,
                      'DD.MM.YYYY'
                    )}`
                  : null}
                {this.canAdd() && !readOnly && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/admin/contract/${contractId}/edit`)
                    }
                    type="edit"
                    size={16}
                  />
                )}
              </h1>
              <Grid gutter="16px" cols={7}>
                <GridItem>
                  <Field label="Статус">
                    {entityStatus[contract.contractStatus]}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата начала">
                    {contract.startDate
                      ? `${formatDateTimeToString(
                          contract.startDate,
                          'DD.MM.YYYY'
                        )}`
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания">
                    {contract.endDate
                      ? `${formatDateTimeToString(
                          contract.endDate,
                          'DD.MM.YYYY'
                        )}`
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Контрагент">
                    {contract.contractor?.company?.name
                      ? contract.contractor.company.name
                      : '-'}
                  </Field>
                </GridItem>
                {this.isAccount() && (
                  <GridItem>
                    <Field label="Примечание">
                      {contract.note ? contract.note : '-'}
                    </Field>
                  </GridItem>
                )}
                {!this.isAccount() && (
                  <>
                    <GridItem>
                      <Field label="Номер протокола ЦЗК">
                        {contract.protocolNumber
                          ? contract.protocolNumber
                          : '-'}
                      </Field>
                    </GridItem>
                  </>
                )}
                <GridItem>
                  <Field label="Куратор">
                    {contract.employee
                      ? convertEmployeeToString(contract.employee)
                      : '-'}
                  </Field>
                </GridItem>
              </Grid>
            </StyledPanel>

            {(contract.registrationDate ||
              contract.approvalDate ||
              contract.closingDate ||
              contract.protocolSubject) &&
              !this.isAccount() && (
                <Section>
                  <SectionTitle divider>Общая информация</SectionTitle>
                  <Content>
                    <Grid cols={3}>
                      <GridItem>
                        <Field label="Дата регистрации">
                          {`${
                            contract.registrationDate
                              ? formatDateTimeToString(
                                  contract.registrationDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата утверждения">
                          {`${
                            contract.approvalDate
                              ? formatDateTimeToString(
                                  contract.approvalDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата закрытия">
                          {`${
                            contract.closingDate
                              ? formatDateTimeToString(
                                  contract.closingDate,
                                  'DD.MM.YYYY'
                                )
                              : '-'
                          }`}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Предмет договора">
                          {contract.protocolSubject || '-'}
                        </Field>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
              )}

            <Stages
              readOnly={readOnly}
              contractId={contract.id}
              stages={contract.stages}
              fetchStages={this.fetchStages}
            />
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(ContractCard);
