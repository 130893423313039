import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import styled from 'styled-components';
import { businessDayApi, fetchRequest } from '../../../../lib/api';

import { notificationLoading } from '../../../../components/Notifications';
import notification from 'antd/lib/notification';

type Props = {
  onClose: () => void,
  visible: boolean
};

const { Option } = Select;

const StyledButton = styled(Button)`
  margin-top: 15px;
`;

const years = [];
const currentYear = new Date().getFullYear();

const YearsSelectModal = ({ onClose, visible }: Props) => {
  const [selectedYear, setSelectedYear] = useState(currentYear);
  useEffect(() => {
    if (!years.length) {
      businessDayApi
        .fetchBusinessDays({ page: 1, orderBy: 'date', byDescending: true })
        .then(({ data }) => {
          const biggestYear = new Date(data[0].date).getFullYear();
          for (let i = 2019; i <= biggestYear; ++i) {
            years.push(i);
          }
        });
    }
  }, []);
  const updateCalendar = () => {
    notificationLoading({
      message: 'Обновление календаря',
      key: 'update'
    });
    fetchRequest
      .get(
        `/productionCalendarForBudget/updatefrombusinessdays?year=${selectedYear}`
      )
      .then(data => {
        notification.success({
          message: 'Успешно обновлено',
          description: data,
          duration: 7,
          key: 'updateSuccess'
        });
        onClose();
      })
      .catch(error => {
        notification.error({
          message: 'Произошла ошибка',
          description: error
        });
      })
      .finally(() => notification.close('update'));
  };
  return (
    <Modal
      width={400}
      title="Выберите год"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Select value={selectedYear} onChange={value => setSelectedYear(value)}>
        {years.map(year => (
          <Option key={year} value={year}>
            {year}
          </Option>
        ))}
      </Select>
      <StyledButton type="primary" onClick={updateCalendar}>
        Обновить
      </StyledButton>
    </Modal>
  );
};

export default YearsSelectModal;
