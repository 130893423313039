// @flow
import React, {Component} from 'react';
import type {FormikProps} from 'formik';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import notification from 'antd/lib/notification';

import type {BusinessDay} from '../../lib/types';
import {formatDateTimeToISOString, getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {businessDayTypeEnum} from '../../lib/enum';
import {businessDayApi} from './../../lib/api';
import {Form, Selects} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {notificationLoading} from '../../components/Notifications';
import CancelButton from '../../components/CancelButton';
import InputNumber from 'antd/lib/input-number';

const Content = styled.div`
  padding: 16px;
`;

const { TextArea } = Input;

const { BusinessDayTypeSelect } = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const StyledNumberInput = styled(InputNumber)`
  width: 100%;
`;

type Props = {
  dayId: ?number
};

type State = {
  businessDay: $Shape<BusinessDay>
};

class BusinessDayForm extends Component<Props, State> {
  state = {
    businessDay: {
      dayType: businessDayTypeEnum.dayOff
    }
  };

  async componentDidMount() {
    const dayId = parseInt(this.props.dayId);
    if (dayId > 0) {
      const businessDay = await businessDayApi.fetchBusinessDay(dayId);
      this.setState({
        businessDay
      });
    }
  }

  onSubmit = async (businessDay: BusinessDay) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const dayId = parseInt(this.props.dayId);
      if (dayId > 0) {
        await businessDayApi.updateBusinessDay(businessDay);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        await businessDayApi.addBusinessDay(businessDay);
        notification.success({
          message: 'Успешное добавлено'
        });
      }
      await navigate('/admin/business-calendar', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { businessDay } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/business-calendar')}>
                Производственный календарь
              </Crumb>
              {businessDay ? (
                <Crumb>Редактирование дня</Crumb>
              ) : (
                <Crumb>Новый день</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{businessDay ? `Редактирование дня` : 'Новый день'}</h1>
        </StyledPanel>
        <InnerForm
          businessDay={businessDay}
          onSubmit={this.onSubmit}
          onCancel={() => navigate('/admin/business-calendar', true)}
        />
      </>
    );
  }
}

type FormProps = {
  onSubmit: Function,
  onCancel: Function,
  businessDay: $Shape<BusinessDay>
};

const InnerForm = ({ onSubmit, onCancel, businessDay }: FormProps) => (
  <Form initialValues={businessDay} onSubmit={onSubmit}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        handleBlur,
        setFieldValue,
        dirty,
        isSubmitting,
        values
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Дата" required name="date" fast>
                    {({ value, name }) => (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment.utc(value) : value}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Тип дня" required name="dayType" fast>
                    {({ name, value }) => (
                      <BusinessDayTypeSelect
                        name={name}
                        value={value}
                        onBlur={() => handleBlur({ target: { name } })}
                        onChange={(value: string) => {
                          if (value === businessDayTypeEnum.dayOff) {
                            setFieldValue('workHours', 0);
                          }
                          setFieldValue(name, value);
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Рабочие часы" name="workHours">
                    {({ name, value }) => (
                      <StyledNumberInput
                        value={value}
                        disabled={values.dayType === businessDayTypeEnum.dayOff}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField name="description" label="Описание" fast>
                    {field => <TextArea {...field} />}
                  </FormField>
                </GridItem>
              </Grid>
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              data-cy="save"
            >
              Сохранить
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      );
    }}
  </Form>
);

export default BusinessDayForm;
