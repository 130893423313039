//@flow
import React, {useCallback} from 'react';

import {FilterItem} from '../../../components';
import Field from '../../../components/card/Field';
import {CommonFilter, type FilterProps} from '../../../components/hoc/common/handbook/CommonFilter';

import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';
import {formatDateTimeToISOString} from '../../../lib/helpers';
import OrgUnitSelect from '../../../components/OrgUnitSelect';
import {ApproveYesNoSelect} from '../../../components/selects/AutoGeneratedEnumSelects';

export type GpsSubscriptionServiceCostFilterParams = {
  nodeId?: number,
  startDate?: string,
  endDate?: string
};

export const GpsSubscriptionServiceCostFilter = (
  props: FilterProps<GpsSubscriptionServiceCostFilterParams>
) => {
  const Filter = useCallback(
    (
      filterProps: $Shape<FilterProps<GpsSubscriptionServiceCostFilterParams>>
    ) =>
      CommonFilter<GpsSubscriptionServiceCostFilterParams>({
        ...filterProps,
        ...props
      }),
    [props]
  );
  return (
    <Filter additionalFilter>
      {(values, changeValue) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <OrgUnitSelect
                size="small"
                onlyBranches
                onChange={value => changeValue('nodeId', value)}
                value={values.nodeId}
                isFilter={true}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Datepicker
                size="small"
                placeholder="Дата начала"
                value={values.startDate ? moment(values.startDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'startDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <Datepicker
                size="small"
                placeholder="Дата окончания"
                value={values.endDate ? moment(values.endDate) : undefined}
                format={'DD.MM.YYYY'}
                onChange={(value, dateString) => {
                  changeValue(
                    'endDate',
                    formatDateTimeToISOString(value, dateString)
                  );
                }}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <ApproveYesNoSelect
                size="small"
                value={values.status}
                onChange={value => changeValue('status', value)}
              />
            </Field>
          </FilterItem>
        </>
      )}
    </Filter>
  );
};
export default GpsSubscriptionServiceCostFilter;
