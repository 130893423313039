// @flow

import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { Contract, EntityStatusType } from './../types';
import {
  convertDataToServer as stageConvertDataToServer,
  convertDataFromServer as stageConvertDataFromServer
} from './stage';
import {
  convertDataToServer as offerConvertDataToServer,
  convertDataFromServer as offerConvertDataFromServer
} from './offer';

export const convertDataToServer = (contract: Contract): Contract => ({
  ...contract,
  stages: contract.stages.map(stageConvertDataToServer),
  offers: contract.offers.map(offerConvertDataToServer)
});

export const convertDataFromServer = (contract: Contract): Contract => ({
  ...contract,
  stages: contract.stages.map(stageConvertDataFromServer),
  offers: contract.offers.map(offerConvertDataFromServer)
});

export const addContract = async (contract: Contract): Promise<Contract> => {
  const added = await fetchRequest.post('/contract', contract);
  if (added) return convertDataFromServer(added);
  throw new Error('Не удалось создать подрядчика');
};

export const updateContract = async (contract: Contract): Promise<Contract> => {
  const updated = await fetchRequest.put('/contract', contract);
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить договор');
};

export const fetchContracts = async (
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<Contract>> => {
  const contracts = await fetchRequest.get('/contract', {
    ...initialFetchListParams,
    ...params
  });
  if (contracts) {
    return {
      ...contracts,
      data: contracts.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось получить список договоров');
};

export const getContract = async (
  id: number,
  params: any = {}
): Promise<Contract> => {
  const contract = await fetchRequest.get(`/contract/${id}`, params);
  if (contract) return convertDataFromServer(contract);
  throw new Error('Не удалось загрузить договор');
};

export const deleteContract = async (id: number): Promise<Contract> => {
  const deleted = await fetchRequest.delete(`/contract/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить договор');
};

export const changeStatus = async (
  id: number,
  status: EntityStatusType
): Promise<Contract> => {
  const contract = await fetchRequest.put(`/contract/changeStatus`, {
    id,
    status
  });
  if (contract) return convertDataFromServer(contract);
  throw new Error('Не удалось сменить статус договора');
};

export const updateParusContractsForPeriod = async (
  params: any
): Promise<any> => {
  const contract = await fetchRequest.get(
    `/contract/parus/updateContractsForPeriod`,
    {
      ...params
    }
  );
  if (contract) return contract;
  throw new Error('Не удалось сменить запросить данные!');
};

export const updateParusContracts = async (params: any): Promise<any> => {
  const contract = await fetchRequest.get(`/contract/parus/updateContracts`, {
    ...params
  });
  if (contract) return contract;
  throw new Error('Не удалось сменить запросить данные!');
};

export const subscribeParusContracts = async (): Promise<any> => {
  const contract = await fetchRequest.get(
    `/contract/parus/subscribe/contracts`,
    {}
  );
  if (contract) return contract;
  throw new Error('Не удалось подписаться на обновления по договорам!');
};

export const subscribeParusAccounts = async (): Promise<any> => {
  const accounts = await fetchRequest.get(
    `/contract/parus/subscribe/accounts`,
    {}
  );
  if (accounts) return accounts;
  throw new Error('Не удалось подписаться на обновления по лицевым счетам!');
};

export default {
  changeStatus,
  addContract,
  updateContract,
  fetchContracts,
  deleteContract,
  getContract,
  updateParusContracts,
  updateParusContractsForPeriod,
  subscribeParusContracts,
  subscribeParusAccounts
};
