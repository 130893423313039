// @flow

import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import qs from 'query-string';
import React, { Component } from 'react';
import styled from 'styled-components';

import { notificationLoading } from '../../components/Notifications';
import Header from '../../components/layout/Header';
import type { FetchListParams } from '../../lib/api';
import { employeeApi } from '../../lib/api';
import { getListInitialState, navigate, setQueryParams } from '../../lib/helpers';
import type { Employee, ListState, OrgUnitNode, Position, UserAccess } from '../../lib/types';
import { withUserAccess } from '../withUserAccess';
import { Section } from './../../components/layout';
import { ButtonOperations, Icon, Operations, Table } from './../../components/ui';
import { accessTypeEnum, positions as positionsTranslate } from './../../lib/enum';
import type { EmployeesFilterParams } from './Filter';
import Filter from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
};

type State = ListState<Employee> & {
  filter: EmployeesFilterParams,
  isAdmin: boolean,
};

const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

class EmployeeList extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };

  state = {
    ...getListInitialState(),
    filter: {},
    isAdmin: this.props.userAccess.includes(accessTypeEnum.admin),
  };

  columns = [
    {
      title: 'ФИО',
      dataIndex: 'name',
      sorter: true,
      sorterKey: 'person.lastName',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: Employee) =>
        `${record.lastname || ''} ${record.firstname ||
        ''} ${record.middlename || ''}`,
    },
    {
      title: 'Табельный номер',
      dataIndex: 'personNumber',
      sorter: true,
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName',
      sorterKey: 'node.name',
      sorter: true,
    },
    {
      title: 'Должность',
      dataIndex: 'positions',
      sorter: true,
      render: (positions: Position[]) =>
        positions && (
          <ul>
            {positions.map((position, index) => (
              <li key={index}>{positionsTranslate[position]}</li>
            ))}
          </ul>
        ),
    },
    {
      title: 'Rn',
      dataIndex: 'rn',
      sorterKey: 'rn',
      sorter: true,
    },
    {
      title: ' ',
      dataIndex: 'id',
      // eslint-disable-next-line no-unused-vars
      render: (text: string, record: OrgUnitNode): any => this.state.isAdmin && (
        <Operations>
          {!(record.children && record.children.length) && (
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={async () => await this.deleteEmployee(record.id)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )}
        </Operations>
      ),
    },
  ];

  async deleteEmployee(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await employeeApi.deleteEmployee(id);
      await this.fetchEmployees(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  }

  fetchEmployees = async (
    page: number = 1,
    params: FetchListParams<EmployeesFilterParams> = {},
  ) => {
    try {
      const {filter} = this.state;
      this.setState({loading: true});
      const {data, totalCount} = await employeeApi.fetchEmployees({
        ...filter,
        page,
        ...params,
      });
      setQueryParams({page});
      this.setState({loading: false, data, totalCount, page});
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.title || e.message,
      });
    }
  };

  cleanFilter = () => this.setState({filter: {}}, this.fetchEmployees);

  applyFilter = (filter: EmployeesFilterParams) =>
    this.setState({filter}, this.fetchEmployees);

  async componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({filter}, () => this.fetchEmployees(page));
  }

  handleRowClick = (id: number) => navigate(`/admin/employees/${id}`);

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати',
        key: 'print',
      });
      await employeeApi.print(this.state.filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const {location} = this.props;
    const {data, totalCount, pageSize, page, loading, filter, isAdmin} = this.state;
    return (
      <>
        <Header
          left={<h1>Сотрудники</h1>}
          right={
            <ButtonOperations>
              <Button onClick={this.handlePrint}>Экспорт</Button>
              {isAdmin && (
                <Button
                  type="primary"
                  data-cy="addEmployee"
                  onClick={() => navigate('/admin/employees/new')}
                >
                  Создать
                </Button>
              )}
            </ButtonOperations>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            rowKey="id"
            fetch={this.fetchEmployees}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            locale={{
              emptyText: 'Нет сотрудников',
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(EmployeeList);
