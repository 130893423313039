// @flow

import type {Dispatch} from 'redux';

import type {OrgUnitNode} from '../lib/types';
import {orgUnitApi} from './../lib/api';
import type {Action} from './redux';

export const SET_ORG_UNITS = 'vehicles/org-units/set';

const initialState: ?OrgUnitNode = null;

export const replaceOrgUnitInTree = (
  node: OrgUnitNode,
  orgUnit: OrgUnitNode
) => {
  let currentNode = { ...node };
  if (node.id === orgUnit.id) {
    currentNode = {
      ...currentNode,
      ...orgUnit
    };
  }
  let children = currentNode.children ? [...currentNode.children] : [];
  children = children.map<OrgUnitNode>(child =>
    replaceOrgUnitInTree(child, orgUnit)
  );
  return {
    ...currentNode,
    children: children.length ? children : null
  };
};

const addOrgUnitToTree = (
  node: OrgUnitNode,
  parentId: number,
  orgUnit: OrgUnitNode
) => {
  let children = node.children ? [...node.children] : [];
  children = children.map(child => addOrgUnitToTree(child, parentId, orgUnit));
  if (node.id === parentId) {
    children = [...children, orgUnit];
  }
  return {
    ...node,
    children: children.length ? children : null
  };
};

const removeFromTree = (node: OrgUnitNode, orgUnitId: number) => {
  let children = node.children ? [...node.children] : [];
  children = children
    .filter(child => child.id !== orgUnitId)
    .map(child => removeFromTree(child, orgUnitId));
  return {
    ...node,
    children: children.length ? children : null
  };
};

const reducer = (
  state: ?OrgUnitNode = initialState,
  { type, payload }: Action
): ?OrgUnitNode => {
  switch (type) {
    case SET_ORG_UNITS:
      return payload;
    default:
      return state;
  }
};

export const addOrgUnit: Function = (
  orgUnit: OrgUnitNode,
  parentOrgUnitId: number
): Function => async (
  dispatch: Dispatch,
  getState: Function
): Promise<void> => {
  const addedOrgUnit = await orgUnitApi.addOrgUnit({
    ...orgUnit,
    parentId: parentOrgUnitId
  });
  const state = getState();
  const orgUnits = addOrgUnitToTree(
    state.orgUnits,
    parentOrgUnitId,
    addedOrgUnit
  );
  dispatch({
    type: SET_ORG_UNITS,
    payload: {
      ...orgUnits
    }
  });
};

export const fetchOrgUnits: Function = (): Function => async (
  dispatch: Dispatch
): Promise<void> => {
  const orgUnits = await orgUnitApi.fetchOrgUnits();
  dispatch({
    type: SET_ORG_UNITS,
    payload: {
      ...orgUnits
    }
  });
};

export default reducer;
