// @flow
import Modal from 'antd/lib/modal';
import React from 'react';
import {Form} from '../../../../../../../components';
import Button from 'antd/lib/button';
import {Grid, GridItem} from '../../../../../../../components/layout';
import InputNumber from 'antd/lib/input-number';
import {monthsNamesTranslitEnum, monthsNumbersEnum} from '../../../../../../../lib/enum';
import {AssignmentMonth} from '../../../../../../../lib/types';
import type {SelectedLimits} from '../List';
import styled from 'styled-components';

const SubTitle = styled.h5`
  font-weight: bold;
  margin: 5px 0;
`;

type Props = {
  visible: boolean,
  handleCancel: () => void,
  handleSave: (monthData: AssignmentMonth) => void,
  monthData: AssignmentMonth,
  vehicleName: ?string,
  limits: ?SelectedLimits
};

export const EditingModal = ({
  visible,
  handleCancel,
  handleSave,
  monthData,
  vehicleName,
  limits,
}: Props) => {
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Редактирование"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form initialValues={monthData} onSubmit={handleSave}>
        {(FormField, formikProps) => {
          const {handleSubmit, setFieldValue} = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Месяц">
                    {() => monthsNamesTranslitEnum[monthsNumbersEnum[monthData?.month]] || '-'}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка и модель ТС">
                    {() => vehicleName || '-'}
                  </FormField>
                </GridItem>
                <GridItem fullWidth>
                  <SubTitle>Рабочие дни</SubTitle>
                </GridItem>
                <GridItem>
                  <FormField label="Пробег, км." name="correctedKilometrage">
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        min={0}
                        max={limits.distance / 1000}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Часы" name="correctedHours">
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        min={0}
                        max={limits.hours / 3600}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem fullWidth>
                  <SubTitle>Выходные дни</SubTitle>
                </GridItem>
                <GridItem>
                  <FormField label="Пробег, км." name="correctedWeekendKilometrage">
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        min={0}
                        disabled={!value}
                        max={monthData.correctedWeekendKilometrage}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Часы" name="correctedWeekendHours">
                    {({value, name}) => (
                      <InputNumber
                        value={value}
                        onChange={value => setFieldValue(name, value)}
                        min={0}
                        disabled={!value}
                        max={monthData.correctedWeekendHours}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
