// @flow

import React from 'react';
import styled from 'styled-components';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';
import {ownerTypes, tripStatusEnum, tripStatuses, tripTypeEnum} from '../../../lib/enum';
import {Card, FilterButtonsContainer, Selects} from './../../../components';
import Select from './../../../components/ui/Select';
import OrgUnitSelect from './../../../components/OrgUnitSelect';
import {formatDateTimeToISOString} from '../../../lib/helpers';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import {Filter} from './../../../components/hoc';
import type {OwnerType, TripStatus, TripType, VehicleType} from '../../../lib/types';
import FilterContainer from '../../../components/hoc/FilterContainer';
import FilterItem from '../../../components/hoc/FilterItem';
import type {ContractTripStatus} from '../../../lib/types/trip';
import InputNumber from 'antd/lib/input-number';

const {Option} = Select;
const {Field} = Card;
const {VehicleSelect, VehicleTypeSelect} = Selects;
const {RangePicker} = Datepicker;

const Content = styled.div`
  padding: 16px;
`;

export type TripFilterParams = {
  status?: TripStatus,
  isArchive?: boolean,
  vehicleType?: VehicleType,
  'vehicle.id'?: number,
  nodeId?: number,
  startDate?: string,
  endDate?: string,
  'vehicle.ownerType'?: OwnerType,
  createdByFirstBit?: boolean,
  contractTripStatus?: ContractTripStatus,
  actId?: number
};

type Props = {
  filter: TripFilterParams,
  applyFilter: Function,
  cleanFilter: Function,
  type: TripType,
  style?: any
};

const getStatuses = (type: TripType): TripStatus[] => {
  switch (type) {
    case tripTypeEnum.income:
      return Object.keys(tripStatusEnum).filter(
        (key: string) =>
          key !== tripStatusEnum.canceled && key !== tripStatusEnum.verified,
      );
    case tripTypeEnum.archive:
      return Object.keys(tripStatusEnum).filter(
        (key: string) =>
          key === tripStatusEnum.canceled || key === tripStatusEnum.verified,
      );
    default:
      return Object.keys(tripStatusEnum);
  }
};

const TripFilter = ({
  style,
  filter,
  applyFilter,
  cleanFilter,
  type,
}: Props) => (
  <Content style={{...style}}>
    <FilterContainer>
      <Filter
        initialValues={filter}
        applyFilter={applyFilter}
        cleanFilter={cleanFilter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional,
        }: FilterChildrenParams<TripFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Select
                  allowClear
                  placeholder="Выберите статус"
                  value={values.status}
                  size="small"
                  onChange={(value: string) => changeValue('status', value)}
                >
                  {getStatuses(type).map((key: string) => (
                    <Option key={key} value={key}>
                      {tripStatuses[key]}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleTypeSelect
                  size="small"
                  placeholder="Все типы ТС"
                  value={values.vehicleType}
                  selectableGroup
                  onChange={value => changeValue('vehicleType', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY HH:mm"
                  showTime={{
                    format: 'HH:mm',
                    defaultValue: [
                      moment('00:00', 'HH:mm'),
                      moment('23:59', 'HH:mm'),
                    ],
                  }}
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null,
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString),
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString),
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  placeholder="По всем ТС"
                  size="small"
                  value={values['vehicle.id']}
                  filter={{
                    ownerType: ownerTypes.self,
                  }}
                  onChange={value => changeValue('vehicle.id', value)}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  placeholder="Все подразделения"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <InputNumber
                  value={values.idNumber}
                  onChange={value => changeValue('idNumber', value)}
                  size='small'
                  placeholder='№'
                  style={{width: '100%'}}
                  min={0}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </Content>
);

export default TripFilter;
