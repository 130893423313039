// @flow
import React from 'react';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import { Filter } from '../../../components';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Field from '../../../components/card/Field';
import { formatDateTimeToISOString } from '../../../lib/helpers';
import InputNumber from 'antd/lib/input-number';
import { FilterButtons } from '../elements';
import QuarterSelect from './components/QuarterSelect';
import type { Quarter } from '../../../lib/types';
import { getQuarterByMonths } from './lib';
import VehicleSelect from '../../../components/selects/VehicleSelect';
import { ownerTypes } from '../../../lib/enum';

const { RangePicker, MonthPicker } = DatePicker;

type FilterMode = 'byPeriod' | 'byMonth' | 'byQuarters';

export type ContractVehicleAnalysisFilterParams = $Shape<{
  startDate: string,
  endDate: string,
  mode: FilterMode,
  quarters: Quarter[],
  vehicleId?: number
}>;

type Props = {
  filter: ContractVehicleAnalysisFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ContractVehiclesAnalysisFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Grid
    gutter="16px"
    media={[
      {
        size: 'md',
        cols: 3
      },
      {
        size: 'lg',
        cols: ['200px', '250px', '100px', '1fr', '1fr', '1fr']
      }
    ]}
  >
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<ContractVehicleAnalysisFilterParams>) => (
        <>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="За месяц">
              <MonthPicker
                size="small"
                format="MMMM YYYY"
                placeholder="Выберите месяц"
                value={moment(values.startDate)}
                onChange={value => {
                  if (value) {
                    const endDate = moment(value)
                      .endOf('month')
                      .hour(0)
                      .minutes(0)
                      .seconds(0);
                    changeValue('startDate', value.toISOString());
                    changeValue('endDate', endDate.toISOString());
                  } else {
                    const date = moment
                      .utc()
                      .hour(0)
                      .minutes(0)
                      .seconds(0);
                    changeValue(
                      'startDate',
                      date.startOf('month').toISOString()
                    );
                    changeValue('endDate', date.endOf('month').toISOString());
                  }
                  changeValue('mode', 'byMonth');
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field
              valueStyle={{ marginBottom: '0px' }}
              label="За отчетный период"
            >
              <RangePicker
                size="small"
                format="DD.MM.YYYY"
                placeholder={['Начало', 'Конец']}
                value={[
                  values.startDate ? moment.utc(values.startDate) : null,
                  values.endDate ? moment.utc(values.endDate) : null
                ]}
                onChange={(value, dateString) => {
                  if (value.length) {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  } else {
                    const date = moment
                      .utc()
                      .hour(0)
                      .minutes(0)
                      .seconds(0);
                    changeValue(
                      'startDate',
                      date.startOf('month').toISOString()
                    );
                    changeValue('endDate', date.endOf('month').toISOString());
                  }
                  changeValue('mode', 'byPeriod');
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="За год">
              <InputNumber
                style={{ width: '100%' }}
                size="small"
                min={2018}
                // max={moment().year() + 1}
                value={moment.utc(values.endDate).year()}
                onChange={(value: number) => {
                  const year = moment.utc().year(value);
                  changeValue('startDate', year.startOf('year').toISOString());
                  changeValue('endDate', year.endOf('year').toISOString());
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="За квартал">
              <QuarterSelect
                size="small"
                onChange={quarters => {
                  changeValue(
                    'quarters',
                    quarters.map(months => getQuarterByMonths(months))
                  );
                  changeValue('mode', 'byQuarters');
                }}
                placeholder="Выберите квартал"
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="По ТС">
              <VehicleSelect
                size="small"
                value={values.vehicleId}
                placeholder="Выберите ТС"
                filter={{
                  ownerType: ownerTypes.contract
                }}
                onChange={vehicleId => changeValue('vehicleId', vehicleId)}
              />
            </Field>
          </GridItem>
          <FilterButtons>
            <Button type="primary" onClick={applyFilter}>
              Применить
            </Button>
            <Button type="plain" onClick={cleanFilter}>
              Очистить
            </Button>
          </FilterButtons>
        </>
      )}
    </Filter>
  </Grid>
);

export default ContractVehiclesAnalysisFilter;
