// @flow
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';

import type { Vehicle } from '../../../lib/types';
import { formatDateTimeToISOString } from '../../../lib/helpers';

import {
  Filter,
  FilterContainer,
  FilterItem,
  Selects,
  FilterButtonsContainer
} from '../../../components';
import type { FilterChildrenParams } from '../../../components/hoc/Filter';
import Field from '../../../components/card/Field';
import Input from '../../../components/ui/CustomInput';

const { VehicleSelect } = Selects;
const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type PassFilterParams = {
  name?: string,
  vehicleId?: number,
  number?: string,
  maintenanceDate?: string | moment,
  startDate?: string | moment,
  endDate?: string | moment
};

type Props = {
  style?: any,
  filter: PassFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const PassFilter = ({
  style,
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SpoilerContent style={{ ...style }}>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter={true}
      >
        {({
          values,
          changeValue,
          applyFilter,
          showAdditional,
          handleAdditional,
          cleanFilter
        }: FilterChildrenParams<PassFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <VehicleSelect
                  size="small"
                  value={values.vehicleId}
                  optionTitle={(vehicle: Vehicle) => vehicle.licensePlate}
                  onChange={(value: number) => changeValue('vehicleId', value)}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Наименование"
                  size="small"
                  onChange={({ target: { value } }) =>
                    changeValue('name', value)
                  }
                  value={values.name}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <RangePicker
                  format="DD.MM.YYYY"
                  size="small"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Номер"
                  size="small"
                  onChange={({ target: { value } }) =>
                    changeValue('number', value)
                  }
                  value={values.number}
                />
              </Field>
            </FilterItem>

            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={
                    values.maintenanceDate && moment.utc(values.maintenanceDate)
                  }
                  placeholder="Дата выдачи"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'maintenanceDate',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default PassFilter;
