import React, { memo, useEffect, useState } from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { withUserAccess } from '../../withUserAccess';
import { accessTypeEnum } from '../../../lib/enum';
import { deadlinesForSendingDataToCognosApi } from '../../../lib/api';
import moment from 'moment';

const SendButton = ({ userAccess, recalculation, sendToCognos }) => {
  const rightsWithAccess = [
    accessTypeEnum.sendingFactFuelConsumptionToCognos,
    accessTypeEnum.admin
  ];
  const [disableSendButton, setDisableSendButton] = useState(true);
  const [period, setPeriod] = useState(null);
  useEffect(() => {
    deadlinesForSendingDataToCognosApi.fetch().then(({ data }) => {
      const { endMonthDay, startMonthDay } = data[0];
      if (!endMonthDay && !startMonthDay) {
        return;
      }
      let startDate = moment().date(startMonthDay);
      let endDate = moment().date(endMonthDay);
      if (startMonthDay > endMonthDay) {
        if (moment().isSameOrAfter(startDate)) {
          endDate.add(1, 'M');
        } else {
          startDate = startDate.add(-1, 'M');
        }
      }
      startDate.add(-1, 'd');
      endDate.add(1, 'd');
      setDisableSendButton(!moment().isBetween(startDate, endDate));
      setPeriod({ startMonthDay, endMonthDay });
    });
  }, []);
  if (!userAccess.some(access => rightsWithAccess.includes(access))) {
    return null;
  }

  return disableSendButton ? (
    <Tooltip
      title={
        period
          ? `Передача данных по фактическому расходу
	          ГСМ возможна в период с ${period.startMonthDay} по ${period.endMonthDay}`
          : 'Сроки предоставления данных по фактическому расходу ГСМ еще не определены'
      }
    >
      <Button type="primary" disabled>
        Отправить
      </Button>
    </Tooltip>
  ) : (
    <Popconfirm
      title={
        <>
          <p>Для отображения актуальных данных</p>
          <p>необходимо провести перерасчет значений</p>
        </>
      }
      onConfirm={sendToCognos}
      onCancel={recalculation}
      okText="Отправить"
      cancelText="Перерасчёт"
    >
      <Button type="primary">Отправить</Button>
    </Popconfirm>
  );
};

export default memo(withUserAccess(SendButton));
