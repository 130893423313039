// @flow
import React, { useCallback, useState } from 'react';

import { navigate } from '../../lib/helpers';
import { mdmApi, typesOfOilsApi } from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {
  type CommonFormProps
} from '../../components/hoc/common/handbook/CommonForm';
import { getBreadCrumbsByUrlForEdit } from '../../lib/autoBreadcrumbs';
import Grid, { GridItem } from '../../components/layout/Grid';
import { FormField } from '../../components/Form';
import type { TypesOfOilsItem } from '../../lib/types/typesOfOils';
import { Select } from '../../components/ui';
import { fuelOilsTypes } from '../../lib/enum/fuelType';
import AutocompleteSelect from '../../components/selects/AutocompleteSelect';
import type { MdmNode } from '../../lib/types';
import notification from 'antd/lib/notification';

type PageProps = { id: number };

const entryPointPath = '/admin/types-of-oils/';

const { Option } = Select;

const InnerForm = (props: CommonFormProps<$Shape<TypesOfOilsItem>>) => {
  const [newMnemocode, setNewMnemocode] = useState(null);

  const getNewMnemocode = async id => {
    try {
      const { mnemocode } = await mdmApi.fetchMdmNode(id);
      setNewMnemocode(mnemocode);
    } catch (e) {
      notification.error({
        message: 'Запись в справочнике МТРиО не найдена',
        description: e.title || e.message
      });
    }
  };

  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Тип масла"
                  fast
                  required
                  name="oilType"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <Select
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    >
                      {Object.keys(fuelOilsTypes).map(key => (
                        <Option key={key} value={key}>
                          {fuelOilsTypes[key]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Тип масла для ИСУ «Парус»"
                  fast
                  required
                  name="parusOilId"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <AutocompleteSelect
                      value={value}
                      fetch={mdmApi.fetchMdmList}
                      fetchSingle={mdmApi.fetchMdmNode}
                      notFoundText="Наименование не найдено"
                      renderOption={(item: MdmNode, Option) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      )}
                      onChange={value => {
                        setFieldValue(name, value);
                        getNewMnemocode(value);
                      }}
                      singleFetchingAnyTypes
                    />
                  )}
                </FormField>
              </GridItem>
              {(newMnemocode || values.parusOil?.mnemocode) && (
                <GridItem>
                  <FormField label="Мнемокод" name="parusOil.mnemocode">
                    {({ value }) => newMnemocode || value}
                  </FormField>
                </GridItem>
              )}
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default (props: PageProps) => {
  const [data: TypesOfOilsItem, setData] = useState({
    id: props.id
  });

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await typesOfOilsApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: TypesOfOilsItem) => {
    let id = payload.id;
    if (id) {
      await typesOfOilsApi.update(payload);
    } else {
      await typesOfOilsApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Типы масел для учета расхода',
        access: {
          delete: data.id
            ? () => {
                typesOfOilsApi.delete(data.id);
                navigate(entryPointPath);
              }
            : undefined
        }
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} useFooter noWrapMe />
    </CommonCardPage>
  );
};
