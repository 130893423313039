import {Router} from '@reach/router';
import React, {useEffect} from 'react';
import List from './VehicleModelList';
import {navigate} from '../../lib/helpers';

const VehicleModelList = (props) => {
  useEffect(() => {
    if (!props['*']) {
      navigate('self');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router>
      <List path="/self" type='self' />
      <List path="/contract" type='contract' />
    </Router>
  );
}

export {VehicleModelList};
export {default as VehicleModelForm} from './VehicleModelForm';
