import React, {useEffect, useState} from 'react';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';
import type {InspectionGibddCost, UserAccess} from '../../../lib/types';
import CommonForm, {type CommonFormProps} from '../../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../../components/layout/Grid';
import {FormField} from '../../../components/Form';
import {OrgUnitSelect} from '../../../components';
import {StyledInputNumber} from '../../../components/hoc/common/components/elements';
import VehicleCategorySelect from '../../../components/selects/VehicleCategorySelect';
import {ApproveYesNoSelect} from '../../../components/selects/AutoGeneratedEnumSelects';
import {withUserAccess} from './../../withUserAccess';
import {approvingAccessRight} from '../accessRight';
import {VehicleCategoriesApi} from '../../../lib/api';
import VehicleSubCategorySelect from '../../../components/selects/VehicleSubCategorySelect';

export default withUserAccess(
  (
    props: CommonFormProps<$Shape<InspectionGibddCost>> & {
      userAccess: UserAccess[]
    },
  ) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
      fetchCategories();
    }, []);

    const fetchCategories = async () => {
      const {data} = await VehicleCategoriesApi.fetch();
      setCategories(data);
    };

    const canApprove = () => props.userAccess.some(access => approvingAccessRight.includes(access));

    const isTruckCategory = category => ['N2', 'N3', 'M1', 'M2', 'M3'].includes(category.name);

    return (
      <CommonForm {...props}>
        {({setFieldValue, values}) => {
          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Филиал" fast required name="orgUnitId">
                    {({name, value}) => (
                      <OrgUnitSelect
                        value={value}
                        onlyBranches
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Стоимость за один осмотр (работа)"
                    fast
                    required
                    name="cost"
                  >
                    {({name, value}) => (
                      <StyledInputNumber
                        name={name}
                        value={value}
                        min={0}
                        onChange={value => setFieldValue(name, value)}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField
                    label="Категория транспортного средства"
                    fast
                    required
                    name="categoryId"
                  >
                    {({name, value}) => (
                      <VehicleCategorySelect
                        value={value}
                        onChange={(id: number) => {
                          setFieldValue(name, id);
                          setFieldValue('subCategoryId', null);
                          setFieldValue('category', categories.find(item => item.id === id));
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                {values.categoryId && (
                  <GridItem>
                    <FormField
                      label="Подкатегория транспортного средства"
                      required
                      name="subCategoryId"
                    >
                      {({name, value}) => (
                        <VehicleSubCategorySelect
                          value={value}
                          onChange={(id: number) => setFieldValue(name, id)}
                          filter={{vehicleCategoryId: values.categoryId}}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                {!!values.category &&
                  (isTruckCategory(values.category) ? (
                    <>
                      <GridItem>
                        <FormField
                          label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло до 5 лет (мес)."
                          name="interval_0_5"
                        >
                          {({name, value}) => (
                            <StyledInputNumber
                              name={name}
                              value={value}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло более 5 лет (мес)."
                          name="interval_5_infinity"
                        >
                          {({name, value}) => (
                            <StyledInputNumber
                              name={name}
                              value={value}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  ) : (
                    <>
                      <GridItem>
                        <FormField
                          label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло до 4 лет (мес)."
                          name="interval_0_4"
                        >
                          {({name, value}) => (
                            <StyledInputNumber
                              name={name}
                              value={value}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло от 4 до 10 лет (мес)."
                          name="interval_4_10"
                        >
                          {({name, value}) => (
                            <StyledInputNumber
                              name={name}
                              value={value}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло более 10 лет (мес)."
                          name="interval_10_infinity"
                        >
                          {({name, value}) => (
                            <StyledInputNumber
                              name={name}
                              value={value}
                              min={0}
                              onChange={value => setFieldValue(name, value)}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  ))}
                {canApprove() && (
                  <GridItem>
                    <FormField label="Статус" fast required name="status">
                      {({name, value}) => (
                        <ApproveYesNoSelect
                          name={name}
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                )}
                <GridItem>
                  <FormField label="Дата начала" name="startDate" required>
                    {({name, value}) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                            .utc(value)
                            .startOf('day')
                            .toISOString(),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Дата окончания" name="endDate" required>
                    {({name, value}) => (
                      <DatePicker
                        name={name}
                        value={value ? moment(value) : value}
                        format="DD MMMM YYYY"
                        disabled={!values.startDate}
                        disabledDate={(date: string) =>
                          values.startDate
                            ? moment
                            .utc(date)
                            .startOf('day')
                            .isSameOrBefore(moment.utc(values.startDate))
                            : false
                        }
                        onChange={(value: string) => {
                          setFieldValue(
                            name,
                            moment
                            .utc(value)
                            .startOf('day')
                            .toISOString(),
                          );
                        }}
                      />
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </>
          );
        }}
      </CommonForm>
    );
  },
);
