// @flow

import type { Company } from './../types';
import CommonApi from '../commonApi';

const basedUrl = 'company';
const company = new CommonApi<Company>(basedUrl, 'компании');

export default {
    ...company
  };
