// @flow
import React from 'react';
import type { SelectProps } from 'antd/lib/select';
import { entityStatus } from '../../lib/enum';
import type { EntityStatusType } from '../../lib/types';
import AutoGeneratedEnumSelect from './AutoGeneratedEnumSelect';

type Props = {
  exclude?: EntityStatusType[]
} & SelectProps;
export default ({ exclude, ...props }: Props) => {
  let autoType = {};
  if (Array.isArray(exclude)) {
    Object.keys(entityStatus).forEach(status => {
      if (!exclude.includes(status)) {
        autoType[status] = entityStatus[status];
      }
    });
  } else {
    autoType = { ...entityStatus };
  }
  const Select = AutoGeneratedEnumSelect({
    placeholder: 'Выберите статус',
    autoType
  });
  return <Select {...props} />;
};
