// @flow

import type { MaintenanceTypeOfWorkType } from './index';

export const  WorkKindEnum = {
  to: 'to',
  repair: 'repair',
}

export const  WorkKinds = {
  to: 'ТО',
  repair: 'Ремонт',
}

export type MaintenanceWorkType = {
  id: number,
  name: string,
  workKind: $Keys<WorkKindEnum>,
  workType: MaintenanceTypeOfWorkType,
  isArchive: boolean
};
