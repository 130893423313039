// @flow
import React from 'react';

import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import DatePicker from 'antd/lib/date-picker';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import { formatDateTimeToISOString, navigate } from '../../../lib/helpers';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { Form, Selects } from '../../../components';
import { getDisabledDate, getDisabledPlannedDate } from './../lib';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

const { MaintenanceTypeSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px 0;
`;
const SectionContent = styled.div`
  padding: 0 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class PlanedMaintenance extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/maintenance/planed-maintenance`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(
      `/vehicles/${this.props.vehicleId}/maintenance/planed-maintenance`
    );
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    return (
      <>
        <Header
          left={
            vehicle && <h1>Техническое обслуживание {vehicle.licensePlate}</h1>
          }
        />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, dirty } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <SectionContent>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Дата последнего технического обслуживания"
                          required
                          name="lastMaintenanceDate"
                        >
                          {({ value, name }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={this.formatDate(value)}
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                              }}
                              disabledDate={(currentDate: moment) =>
                                getDisabledDate(currentDate)
                              }
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Планируемая дата очередного технического обслуживания"
                          required
                          name="nextMaintenancePlannedDate"
                        >
                          {({ value, name }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={this.formatDate(value)}
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                              }}
                              disabledDate={getDisabledPlannedDate}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Тип ТО"
                          required
                          fast
                          name="maintenanceType"
                        >
                          {({ value, name }) => (
                            <MaintenanceTypeSelect
                              value={value}
                              onChange={(type: number) => {
                                setFieldValue(name, type);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(PlanedMaintenance);
