// @flow
import React, {useCallback, useState} from 'react';
import {navigate} from '../../lib/helpers';
import {compareDatesApi} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm from '../../components/hoc/common/handbook/CommonForm';
import {getBreadCrumbsByUrlForEdit} from '../../lib/autoBreadcrumbs';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import {Select} from '../../components/ui';
import type {CompareDate, UserAccess} from '../../lib/types';
import {withUserAccess} from '../withUserAccess';
import {accessTypeEnum, monthsNamesTranslitEnum} from '../../lib/enum';

type PageProps = {
  id: number,
  userProps: UserAccess[],
};

const entryPointPath = '/admin/compare-dates';

const {Option} = Select;

const monthDays = {
  january: 31,
  february: new Date().getFullYear() % 4 === 0 ? 29 : 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31,
};

const CompareDatesForm = (props: PageProps) => {
  const [data: CompareDate, setData] = useState({id: props.id});
  let days = [];
  
  const getDays = (value) => {
    const newDays = [];
    for (let i = 1; i <= value; ++i) {
      newDays.push(i);
    }
    days = newDays;
  };
  
  const canEdit = () => [
    accessTypeEnum.admin,
    accessTypeEnum.editingInventoryCardCompareDate,
  ].some(access => props.userAccess.includes(access));
  
  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);
  
  const onFetch = useCallback(async () => {
    !!props.id && setData(await compareDatesApi.get(props.id));
  }, [props.id]);
  
  const onSubmit = async (payload: CompareDate) => {
    let id = payload.id;
    if (id) {
      await compareDatesApi.update(payload);
    } else {
      await compareDatesApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };
  
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };
  
  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Сроки сверки ИК',
      }}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm data={data} useFooter={canEdit()} noWrapMe>
        {({setFieldValue, values}) => {
          getDays(monthDays[values.month]);
          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Месяц" required name="month" hasFeedback={false}>
                    {({name, value}) => (
                      <Select
                        disabled={!canEdit()}
                        value={value}
                        onChange={value => {
                          setFieldValue(name, value);
                          if (values.day && values.day > monthDays[value]) {
                            setFieldValue('day', monthDays[value]);
                          }
                        }}
                      >
                        {Object.keys(monthsNamesTranslitEnum).map(key => (
                          <Option key={key} value={key}>
                            {monthsNamesTranslitEnum[key]}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Число" required name="day" hasFeedback={false}>
                    {({name, value}) => (
                      <Select disabled={!canEdit()} value={value} onChange={value => setFieldValue(name, value)}>
                        {days.map(value => <Option key={value} value={value}>{value}</Option>)}
                      </Select>
                    )}
                  </FormField>
                </GridItem>
              </Grid>
            </>
          );
        }}
      </CommonForm>
    </CommonCardPage>
  );
};

export default withUserAccess(CompareDatesForm);
