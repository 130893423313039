// @flow
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Header } from '../../components/layout';

import Section from '../../components/layout/Section';
import { notificationLoading } from '../../components/Notifications';
import Table from '../../components/ui/Table';

import type { JournalFilter } from '../../ducks/journals';

import { journalApi } from '../../lib/api';
import {
  formatDateTimeToString,
  getListInitialState,
  navigate
} from '../../lib/helpers';
import type { Journal, ListState } from '../../lib/types';
import Filter from './components/Filter';

type Props = {
  location: any
};

type State = ListState<Journal> & {
  filter: JournalFilter
};

const DivEllipsis = styled('div')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class JournalList extends Component<Props, State> {
  static defaultProps = {
    journals: []
  };
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [
    {
      title: 'Дата создания',
      dataIndex: 'created',
      sorter: true,
      render: (created: Date) =>
        formatDateTimeToString(created, 'DD.MM.YYYY HH:mm', false)
    },
    {
      title: 'Пользователь',
      dataIndex: 'userName',
      sorter: true
    },
    {
      title: 'Тип',
      dataIndex: 'type',
      sorter: true
    },
    {
      title: 'Событие',
      dataIndex: 'message',
      sorter: true,
      width: '500px',
      render: (message: string) => <DivEllipsis>{message}</DivEllipsis>
    },
    {
      title: '',
      dataIndex: 'id',
      width: '50px',
      stopPropagation: true,
      render: id => (
        <Button
          icon="arrow-right"
          size="small"
          href={`/admin/journal/${id}`}
          target="_blank"
        />
      )
    }
  ];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchJournals(page)
    );
  }

  fetchJournals = async (page: number = 1) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await journalApi.fetchJournals({
      ...filter,
      page
    });
    this.setState({ ...data, loading: false, page });
  };

  applyFilter = (filter: JournalFilter) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () => this.fetchJournals(page));
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchJournals(page)
    );
  };

  handleRowClick = (id: number) => {
    navigate(`/admin/journal/${id}`);
  };

  handlePrint = async () => {
    const { filter } = this.state;
    try {
      notificationLoading({
        key: 'print',
        message: 'Формирование файла для печати'
      });
      await journalApi.printJournal(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    return (
      <>
        <Header
          left={<h1>Журнал</h1>}
          right={
            parseInt(totalCount, 10) > 0 && (
              <Button type="primary" onClick={this.handlePrint}>
                Печать
              </Button>
            )
          }
        />
        <Section>
          <Filter
            filters={filter}
            applyFilters={this.applyFilter}
            cleanFilters={this.cleanFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchJournals}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default JournalList;
