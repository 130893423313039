// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {stockApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';

import {addAccessRight, editAccessRight} from './accessRight';

const entryPointPath = '/admin/stock/';

const columns = [
  {
    title: 'Служба',
    dataIndex: 'orgUnitName'
  },
  {
    title: 'Мнемокод склада',
    dataIndex: 'mnemocode'
  }
];

export default withUserAccess(({ userAccess }) => {
  const canAdd = () =>
    userAccess.some(access => addAccessRight.includes(access));
  const canEdit = () =>
    userAccess.some(access => editAccessRight.includes(access));

  const access = {
    add: canAdd() ? () => navigate(`${entryPointPath}new`) : null
  };

  return (
    <Page>
      <CommonListPage
        crud={stockApi}
        pageHeaderProps={{
          mainHeader: 'Склады',
          access
        }}
        tableListProps={{
          columns,
          onSelect: canEdit()
            ? (id: number) => navigate(`${entryPointPath}edit/${id}`)
            : null,
          canDelete: !!access.add
        }}
        filter={Filter}
      />
    </Page>
  );
});
