// @flow

import { SelectProps } from 'antd/lib/select';
import React from 'react';

import { vehicleTariffApi } from '../../lib/api';
import { operatingModes, vehicleTariffEnum } from '../../lib/enum';
import { convertContractorToString } from '../../lib/helpers';
import type { VehicleTariff } from '../../lib/types';
import type { VehicleTariffTypesAndModels } from '../../lib/types/vehicleTariff';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & {
  filter?: any
};

export default ({ filter = {}, ...selectProps }: Props) => (
  <AutocompleteSelect
    placeholder="Выберите тип"
    fetch={async params =>
      await vehicleTariffApi.fetchVehicleTariff({
        ...params,
        ...filter,
        vehicleTariffType: vehicleTariffEnum.byType
      })
    }
    // запрашиваем тачки не по vehicleTariff а по вложенным в нее id тачек
    fetchSingle={id => {
      return vehicleTariffApi.fetchVehicleTariff({
        'typesAndModels.id': id
      })?.typesAndModels?.[0];
    }}
    notFoundText="Типы не найдены"
    renderOption={(vehicleTariff: VehicleTariff, Option) => {
      const Options = vehicleTariff.typesAndModels?.map(
        (type: VehicleTariffTypesAndModels) => {
          return (
            <Option
              key={type.id}
              vehicletariff={vehicleTariff}
              vehicletarifftype={type}
              value={type.id}
            >
              {`${type.vehicleTypeTariff ?? ''} ${type.productionYear} ${
                operatingModes[type.operatingMode]
              } ${type.leasing ? 'Лизинг' : ''} ${
                type.hasGas ? 'ГБО' : ''
              } ${convertContractorToString(vehicleTariff.contractor)}`}
            </Option>
          );
        }
      );
      return Options;
    }}
    {...selectProps}
  />
);
