// @flow
import React from 'react';

import {vehicleTaxMapApi} from './../../lib/api';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from './../withUserAccess';

import {Filter} from './components';
import {vehicleTypes} from '../../lib/enum';
import {navigate} from '../../lib/helpers';
import {addAccessRight} from './accessRight';

const entryPointPath = '/admin/vehicle-tax-map/';

const columns = [
  {
    title: 'Наименование объекта налогообложения',
    dataIndex: 'name'
  },
  {
    title: 'Тип ТС',
    dataIndex: 'vehicleType',
    render: (vehicleType: string) => {
      return vehicleTypes[vehicleType];
    }
  }
];

export default withUserAccess(({ userAccess }) => {
  const canHandling = () =>
    userAccess.some(access => addAccessRight.includes(access));

  const access = {
    add: canHandling() ? () => navigate(`${entryPointPath}new`) : undefined
  };
  return (
    <Page>
      <CommonListPage
        crud={vehicleTaxMapApi}
        pageHeaderProps={{
          mainHeader: 'Соответствие объекта налогообложения и категории ТС',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`),
          canDelete: canHandling()
        }}
        filter={Filter}
      />
    </Page>
  );
});
