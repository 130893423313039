import {flatMenu} from '../components/layout/Sidebar';

export const getBreadCrumbsByUrl = (url: string) => {
  return [flatMenu?.[url]];
};
export const getBreadCrumbsByUrlForEdit = (url: string, id: number) => {
  return [
    flatMenu?.[url],
    ...(id
      ? [{ to: `${url}${id}`, name: id.toString() }, { name: 'Редактирование' }]
      : [{ name: 'Новая запись' }])
  ];
};
export const getBreadCrumbsByUrlForCard = (url: string, id?: number) => {
  const crumb = Number.isInteger(id)
    ? { to: `${url}${id}`, name: id.toString() }
    : {};
  return [...getBreadCrumbsByUrl(url), crumb];
};
