// @flow
import React from 'react';
import styled from 'styled-components';
import {Filter, FilterButtonsContainer, FilterContainer, FilterItem} from './../../../components';
import type {FilterChildrenParams} from '../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import {WorkSubTypeSelect, WorkTypeSelect} from '../../../components/selects/AutoGeneratedEnumSelects';
import AutocompleteSelect from '../../../components/selects/AutocompleteSelect';
import {maintenanceOperationApi, ModelMatchesApi, TypesOfRepairsApi} from '../../../lib/api';
import type {ModelMatches} from '../../../lib/types';

const SpoilerContent = styled.div`
  padding: 16px;
`;

type Props = {
  cleanFilter: Function,
  applyFilter: Function
};

const fetch = async (params, key) => {
  const result = await maintenanceOperationApi.fetchMaintenanceOperations({...params});
  const filteredData = new Set();
  result.data.forEach(item => filteredData.add(item[key]));
  return {totalCount: result.totalCount, data: [...filteredData.values()]};
}

export const MaintenanceOperationFilter = ({
  filter,
  cleanFilter,
  applyFilter,
}: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
        }: FilterChildrenParams) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values.code}
                  onChange={newValue => changeValue('code', newValue)}
                  placeholder="Код работы"
                  notFoundText="Коды не найдены"
                  size="small"
                  fetch={(params) => fetch(params, 'code')}
                  fetchSingle={maintenanceOperationApi.fetchMaintenanceOperation}
                  renderOption={(code, Option) => <Option key={code} value={code}>{code}</Option>}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values.name}
                  onChange={newValue => changeValue('name', newValue)}
                  placeholder="Наименование работы"
                  notFoundText="Работы не найдены"
                  size="small"
                  fetch={(params) => fetch(params, 'name')}
                  fetchSingle={maintenanceOperationApi.fetchMaintenanceOperation}
                  renderOption={(name, Option) => <Option key={name} value={name}>{name}</Option>}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  value={values.repairKindId}
                  onChange={newValue => changeValue('repairKindId', newValue)}
                  placeholder="Вид ремонта"
                  notFoundText="Виды работ не найдены"
                  size="small"
                  fetch={TypesOfRepairsApi.fetch}
                  fetchSingle={TypesOfRepairsApi.get}
                  renderOption={(type, Option) => <Option key={type.id} value={type.id}>{type.name}</Option>}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <WorkTypeSelect
                  size="small"
                  onChange={value => changeValue('workType', value)}
                  value={values.workType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <WorkSubTypeSelect
                  size="small"
                  onChange={value => changeValue('workSubType', value)}
                  value={values.workSubType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <AutocompleteSelect
                  fetch={ModelMatchesApi.fetch}
                  fetchSingle={ModelMatchesApi.get}
                  size="small"
                  onChange={value => changeValue('vehicleModelMatchId', value)}
                  value={values.vehicleModelMatchId}
                  notFoundText="Модели не найдены"
                  placeholder="Модель ТС"
                  renderOption={(model: ModelMatches, Option) => (
                    <Option key={model.id} value={model.id}>{`${model.brandName} ${model.modelName}`}</Option>
                  )}
                />
              </Field>
            </FilterItem>
            
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default MaintenanceOperationFilter;
