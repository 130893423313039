// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type {Contract} from '../../lib/types';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';
import {contractApi} from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  contractId?: number
};

type State = {
  contract: ?Contract
};

export class ContractForm extends React.Component<Props, State> {
  state = {
    contract: null
  };

  async componentDidMount() {
    const { contractId } = this.props;
    try {
      if (contractId) {
        const contract = await contractApi.getContract(contractId);
        this.setState({ contract });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: Contract) => {
    let contract = null;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        contract = await contractApi.updateContract(values);
      } else {
        contract = await contractApi.addContract(values);
      }
      navigate(`/admin/contract/${contract.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/contract/', true);

  render() {
    const { contract } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/contract/')}>
                Реестр договоров
              </Crumb>
              {contract ? (
                <Crumb>Договор №{contract.id}</Crumb>
              ) : (
                <Crumb>Новый договор</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {contract
              ? `Договор №${contract.id}`
              : 'Добавление нового договора'}
          </h1>
        </StyledPanel>
        <InnerForm
          contract={contract}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default ContractForm;
