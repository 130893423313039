// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';

import styled from 'styled-components';
import moment from 'moment';

import DatePicker from 'antd/lib/date-picker';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import type {SelfVehiclePlanVehicle, UserAccess, Vehicle, VehiclePlan, VehicleStatus} from '../../../../lib/types';
import {ownerTypes, STSEnum, vehiclePlanStatusEnum, vehicleStatusEnum} from '../../../../lib/enum';
import {fetchRequest, vehicleApi, vehiclePlanApi} from './../../../../lib/api';
import {
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  isEmptyValue,
  navigate,
} from '../../../../lib/helpers';

import {Grid, Header, Section, SectionTitle, TopPanel} from './../../../../components/layout';
import {GridItem} from '../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../components/layout/Breadcrumbs';
import Field from './../../../../components/card/Field';
import {OrgUnitSelect, Selects} from './../../../../components';
import {notificationLoading} from '../../../../components/Notifications';

import ModalVehicle from './components/ModalVehicle';
import ModalPlannedVehicle from './components/ModalPlannedVehicle';

import VehicleSection from './components/VehicleSection';

import type {VehicleTypeData} from '../lib';
import {withUserAccess} from '../../../withUserAccess';

type VehicleParams = {
  data: SelfVehiclePlanVehicle[],
  loading: boolean,
  modal: boolean,
  editVehiclePlanVehicle?: ?Vehicle
};

const typesEnum = {
  clone: 'clone',
  edit: 'edit',
  listEditing: 'listEditing',
};

type Props = {
  type: ?$Keys<typesEnum>,
  location: Location & { state: { page: number } },
  vehiclePlanId: ?$Shape<number>,
  selfVehicles: ?$Shape<Map<number, SelfVehiclePlanVehicle>>,
  plannedVehicles: ?$Shape<Map<number, SelfVehiclePlanVehicle>>,
  userAccess: UserAccess[]
};

type State = {
  vehiclePlan: ?$Shape<VehiclePlan>,
  selfVehicles: VehicleParams,
  plannedVehicles: VehicleParams
};

const {BudgetVersionSelect} = Selects;
const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const {RangePicker} = DatePicker;
const VEHICLE_STATUS = [
  vehicleStatusEnum.draft,
  vehicleStatusEnum.working,
  vehicleStatusEnum.sendingToParus,
  vehicleStatusEnum.limitExceed,
  vehicleStatusEnum.onRepair,
  vehicleStatusEnum.onAgreeing,
];
const PLANNED_VEHICLE_STATUS = [vehicleStatusEnum.draft];
class BudgetVehicleForm extends Component<Props, State> {
  emptyVehicleParams = {
    data: [],
    loading: false,
    modal: false,
    editVehiclePlanVehicle: null,
  };

  state = {
    selfVehicles: {...this.emptyVehicleParams},
    plannedVehicles: {...this.emptyVehicleParams},
    vehiclePlan: null,
    editVehiclePlanVehicle: null,
  };

  componentDidMount = () => {
    const {vehiclePlanId} = this.props;
    vehiclePlanId && this.cloneEditVehiclePlan(vehiclePlanId);
  };

  cloneEditVehiclePlan = async (vehiclePlanId: number) => {
    const {type} = this.props;
    this.onChangeVehicle('selfVehicles')('loading', true);
    this.onChangeVehicle('plannedVehicles')('loading', true);
    try {
      // $FlowFixMe
      const vehiclePlan = await this.getContractVehiclePlan(vehiclePlanId);
      this.setState({
        vehiclePlan:
          type === typesEnum.clone
            ? {
              date: vehiclePlan.date,
              orgUnitId: vehiclePlan.orgUnitId,
              plannedVehicles: [
                ...vehiclePlan.plannedVehicles.map(planned => ({
                  ...planned,
                  id: undefined,
                })),
              ],
              selfVehicles: [
                ...vehiclePlan.selfVehicles.map(self => ({
                  ...self,
                  id: undefined,
                })),
              ],
            }
            : {...vehiclePlan},
      });
      if (this.props.type === typesEnum.listEditing) {
        this.onChangeVehicle('selfVehicles')('data', vehiclePlan.selfVehicles);
        this.onChangeVehicle('plannedVehicles')('data', vehiclePlan.plannedVehicles);
      } else {
        await this.fetch('selfVehicles')(vehiclePlan.orgUnitId, VEHICLE_STATUS);
        await this.fetch('plannedVehicles')(
          vehiclePlan.orgUnitId,
          PLANNED_VEHICLE_STATUS,
        );
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.onChangeVehicle('selfVehicles')('loading', false);
      this.onChangeVehicle('plannedVehicles')('loading', false);
    }
  };

  getContractVehiclePlan = async (
    vehiclePlanId: number,
  ): Promise<?VehiclePlan> => {
    try {
      notificationLoading({
        message: 'Получение данных...',
        key: 'getting',
      });
      return await vehiclePlanApi.get(vehiclePlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('getting');
    }
  };

  fetch = (selectedType: VehicleTypeData) => async (
    orgUnitId: number,
    status: VehicleStatus[],
  ) => {
    if (orgUnitId) {
      this.onChangeVehicle(selectedType)('loading', true);
      try {
        const {data} = await vehicleApi.fetchVehicles({
          nodeId: orgUnitId,
          status,
          page: undefined,
          pageSize: undefined,
          ownerType: ownerTypes.self,
          stsType:
            selectedType === 'selfVehicles'
              ? STSEnum.exploited
              : STSEnum.forBudget,
        });
        this.onChangeVehicle(selectedType)(
          'data',
          data.map(vehicle => ({
            vehicle,
            vehicleId: vehicle.id,
            plannedWriteoffDate: vehicle.plannedWriteoffDate,
            plannedPurchaseDate: vehicle.plannedPurchaseDate,
          })),
        );
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        this.onChangeVehicle(selectedType)('loading', false);
      }
    }
  };

  onChangeVehicle = (selectedType: VehicleTypeData) => (
    key: string,
    value: any,
  ) => {
    this.setState(prevState => ({
      // $FlowFixMe
      [selectedType]: {
        ...prevState[selectedType],
        [key]: value,
      },
    }));
  };

  onChangeVehiclePlan = async (key: string, value: any) => {
    await this.setState(
      prevState => {
        return {
          vehiclePlan: {
            ...prevState.vehiclePlan,
            [key]: value,
          },
        };
      },
      () => {
        if (key === 'orgUnitId') {
          // сбрасываем выбранные ТС
          this.setState(
            prevState => {
              return {
                vehiclePlan: {
                  ...prevState.vehiclePlan,
                  selfVehicles: [],
                  plannedVehicles: [],
                },
                selfVehicles: {...this.emptyVehicleParams},
                plannedVehicles: {
                  ...this.emptyVehicleParams,
                },
              };
            },
            () => {
              if (value) {
                this.fetch('selfVehicles')(value, VEHICLE_STATUS);
                this.fetch('plannedVehicles')(value, PLANNED_VEHICLE_STATUS);
              }
            },
          );
        }
      },
    );
  };

  validation = (vehiclePlan: VehiclePlan) => {
    const fields = {
      startDate: 'дату начала планируемого периода',
      endDate: 'дату окончания планируемого периода',
      date: 'дату формирования',
      orgUnitId: 'филиал',
      budgetVersionId: 'версию бюджета',
      selfVehicles: 'ТС согласно инвентарной картотеке',
    };
    const startYear = moment(vehiclePlan.startDate).get('year');
    const endYear = moment(vehiclePlan.endDate).get('year');
    Object.keys(fields).forEach(field => {
      if (isEmptyValue(vehiclePlan[field])) {
        throw new Error(`Выберите ${fields[field]}`);
      }
    });
    if (startYear !== endYear) {
      throw new Error(`Дата начала и дата конца не должны быть в разных годах`);
    }
  };

  handleVehiclePlan = async () => {
    let {vehiclePlan} = this.state;
    const {selfVehicles, plannedVehicles, type} = this.props;
    if (vehiclePlan) {
      try {
        vehiclePlan.selfVehicles = selfVehicles
          ? Array.from(selfVehicles.values())
          : [];
        vehiclePlan.plannedVehicles = plannedVehicles
          ? Array.from(plannedVehicles.values())
          : [];
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'saving',
        });
        this.validation(vehiclePlan);
        if (type === typesEnum.edit) {
          await vehiclePlanApi.update(vehiclePlan);
        } else {
          vehiclePlan = await vehiclePlanApi.add(vehiclePlan);
        }
        if (vehiclePlan.id) {
          navigate(`/budget/vehicle/${vehiclePlan.id}/card`);
        }
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  hideModal = (selectedType: VehicleTypeData) => () => {
    this.onChangeVehicle(selectedType)('modal', false);
  };

  updateWorkBalance = async () => {
    await fetchRequest.put(`/vehiclePlan/${this.props.vehiclePlanId}/updateworkbalance`);
  };

  addVehicleToPlan = async (type: VehicleTypeData, vehicle: Vehicle, additionalValues) => {
    const {selfVehicles, plannedVehicles} = this.state;
    try {
      notificationLoading({message: 'Добавление ТС', key: 'adding'});
      const addedVehicle = await vehiclePlanApi.addVehicleToPlan({
        isPlanned: type === 'plannedVehicles',
        vehicle,
        vehicleId: vehicle.id,
        vehiclePlanId: this.props.vehiclePlanId,
      });
      await this.updateWorkBalance();
      const vehicles = type === 'selfVehicles' ? [...selfVehicles.data] : [...plannedVehicles.data];
      if (additionalValues) {
        addedVehicle.lastInspectionGibdd = {};
        addedVehicle.lastInspectionGtn = {};
        addedVehicle.lastInspectionGibdd.nextActDate = additionalValues.inspectionsGibddNextAct;
        addedVehicle.lastInspectionGtn.nextActDate = additionalValues.inspectionsGtndNextAct;
      }
      vehicles.push(addedVehicle);
      this.onChangeVehicle(type)('data', vehicles);
    } catch (e) {
      notification.error({
        message: 'Ошибка добавления ТС',
        description: e.title || e.message,
      });
    } finally {
      notification.close('adding');
    }
  };

  addVehicle = async (vehicle: Vehicle) => {
    const {selfVehicles} = this.state;
    if (this.props.type === typesEnum.listEditing) {
      await this.addVehicleToPlan('selfVehicles', vehicle);
    } else {
      if (selfVehicles.data.findIndex(self => self.vehicleId === vehicle.id) === -1) {
        this.onChangeVehicle('selfVehicles')('data', [
          ...selfVehicles.data,
          {vehicle, vehicleId: vehicle.id},
        ]);
      }
    }
    this.hideModal('selfVehicles')();
  };

  addNewVehicle = async (vehicle: Vehicle, plannedVehicle) => {
    const {plannedVehicles} = this.state;
    this.hideModal('plannedVehicles')();
    if (this.props.type === typesEnum.listEditing) {
      const {inspectionsGibddNextAct, inspectionsGtndNextAct} = plannedVehicle;
      await this.addVehicleToPlan('plannedVehicles', vehicle, {inspectionsGibddNextAct, inspectionsGtndNextAct});
    } else {
      if (plannedVehicles.data.findIndex(self => self.vehicleId === vehicle.id) === -1) {
        this.onChangeVehicle('plannedVehicles')('data', [
          ...this.state.plannedVehicles.data,
          {vehicle, vehicleId: vehicle.id},
        ]);
      }
    }
  };

  deleteSelectedVehicle = async (type: VehicleTypeData, id) => {
    try {
      notificationLoading({message: 'Удаление ТС...', key: 'deleting'});
      await fetchRequest.delete(`/SelfVehiclePlanVehicle/${id}`);
      await this.updateWorkBalance();
      const data = this.state[type].data.filter(vehicle => vehicle.id !== id);
      this.onChangeVehicle(type)('data', data);
    } catch (e) {
      notification.error({
        message: 'Ошибка удаления ТС',
        description: e.message || e.title,
      });
    } finally {
      notification.close('deleting');
    }
  };

  approveVehiclePlan = async () => {
    try {
      notificationLoading({message: 'Утверждение плана бюджета СТС', key: 'approving'});
      await vehiclePlanApi.changeStatus({id: this.props.vehiclePlanId, status: vehiclePlanStatusEnum.listApproved});
      await navigate(`/budget/vehicle/${this.props.vehiclePlanId}/card`);
    } catch (e) {
      notification.error({
        message: 'Ошибка при утверждении плана бюджета СТС',
        description: e.title || e.message,
      });
    } finally {
      notification.close('approving');
    }
  };

  render() {
    const {vehiclePlan, selfVehicles, plannedVehicles} = this.state;
    const {type} = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/budget/vehicle')}>
                Бюджет СТС
              </Crumb>
              <Crumb>Перечень СТС</Crumb>
            </Breadcrumbs>
          }
        />
        <TopPanel>
          <h1>Планируемый перечень ТС</h1>
        </TopPanel>

        <Section>
          <SectionTitle divider>Период и версия бюджета</SectionTitle>
          <Content>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <Field label="Планируемый период">
                  <RangePicker
                    disabled={type === typesEnum.listEditing}
                    size="small"
                    format="DD.MM.YYYY"
                    placeholder={['Начало', 'Конец']}
                    value={[
                      vehiclePlan?.startDate
                        ? moment.utc(vehiclePlan.startDate)
                        : null,
                      vehiclePlan?.endDate
                        ? moment.utc(vehiclePlan.endDate)
                        : null,
                    ]}
                    onChange={(value, dateString) => {
                      const [startDate, endDate] = value;
                      const [startDateString, endDateString] = dateString;
                      this.onChangeVehiclePlan(
                        'startDate',
                        formatDateTimeToISOString(startDate, startDateString),
                      );
                      this.onChangeVehiclePlan(
                        'endDate',
                        formatDateTimeToISOString(endDate, endDateString),
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Версия бюджета">
                  <BudgetVersionSelect
                    disabled={type === typesEnum.listEditing}
                    size="small"
                    filter={{IsAvailableForSelect: true}}
                    value={vehiclePlan?.budgetVersionId}
                    onChange={(budgetVersionId: number) => {
                      this.onChangeVehiclePlan(
                        'budgetVersionId',
                        budgetVersionId,
                      );
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Дата формирования">
                  <DatePicker
                    disabled={type === typesEnum.listEditing}
                    size="small"
                    format="DD.MM.YYYY"
                    value={vehiclePlan?.date ? moment(vehiclePlan.date) : null}
                    onChange={(value: string) =>
                      this.onChangeVehiclePlan('date', value)
                    }
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Филиал">
                  <OrgUnitSelect
                    disabled={type === typesEnum.listEditing}
                    onlyBranches
                    size="small"
                    value={getValueObject(vehiclePlan, 'orgUnitId')}
                    onChange={orgUnitId =>
                      this.onChangeVehiclePlan('orgUnitId', orgUnitId)
                    }
                  />
                </Field>
              </GridItem>
            </Grid>
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() =>
                    this.onChangeVehicle('selfVehicles')('modal', true)
                  }
                  disabled={!vehiclePlan?.orgUnitId}
                >
                  Добавить ТС
                </Button>
              </>
            }
          >
            ТС согласно инвентарной картотеке
          </SectionTitle>
          <Content>
            <VehicleSection
              key={'selfVehicles'}
              vehiclePlanType="selfVehicles"
              data={selfVehicles.data}
              loading={selfVehicles.loading}
              selectedVehicle={vehiclePlan?.selfVehicles}
              isListEditing={type === typesEnum.listEditing}
              deleteSelectedVehicle={id => this.deleteSelectedVehicle('selfVehicles', id)}
              onChangeData={async (data) => {
                await this.onChangeVehicle('selfVehicles')('data', data)
              }}
            />
          </Content>
        </Section>

        <Section>
          <SectionTitle
            divider
            suffix={
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    this.onChangeVehicle('plannedVehicles')(
                      'editVehiclePlanVehicle',
                      null,
                    );
                    this.onChangeVehicle('plannedVehicles')('modal', true);
                  }}
                  disabled={!vehiclePlan?.orgUnitId}
                >
                  Добавить новое ТС
                </Button>
              </>
            }
          >
            ТС согласно плану обновления
          </SectionTitle>
          <Content>
            <VehicleSection
              key={'plannedVehicles'}
              vehiclePlanType="plannedVehicles"
              data={plannedVehicles.data}
              loading={plannedVehicles.loading}
              changeEditVehiclePlanVehicle={this.onChangeVehicle}
              selectedVehicle={vehiclePlan?.plannedVehicles}
              isListEditing={type === typesEnum.listEditing}
              deleteSelectedVehicle={id => this.deleteSelectedVehicle('plannedVehicles', id)}
            />
          </Content>
        </Section>

        {
          <>
            <ModalVehicle
              visible={selfVehicles.modal}
              onCancel={this.hideModal('selfVehicles')}
              addVehicle={this.addVehicle}
              orgUnitId={vehiclePlan?.orgUnitId}
              isListEditing={type === typesEnum.listEditing}
            />
            <ModalPlannedVehicle
              visible={plannedVehicles.modal}
              onCancel={this.hideModal('plannedVehicles')}
              addVehicle={this.addNewVehicle}
              vehicle={plannedVehicles?.editVehiclePlanVehicle}
              orgUnitId={vehiclePlan?.orgUnitId}
            />
          </>
        }
        <Footer>
          <Button onClick={type === typesEnum.listEditing ? this.approveVehiclePlan : this.handleVehiclePlan}
                  type="primary">
            {type === typesEnum.listEditing ? 'Утвердить' : 'Сформировать'}
          </Button>
        </Footer>
      </>
    );
  }
}

export default connect(state => ({
  selfVehicles: state.vehiclePlan.selfVehicles,
  plannedVehicles: state.vehiclePlan.plannedVehicles,
}))(withUserAccess(BudgetVehicleForm));
