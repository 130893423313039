//@flow
import {Button, Modal} from 'antd';
import {FormikProps} from 'formik';
import React, {useState} from 'react';

import {Form} from '../../../components';
import type {FormFieldType} from '../../../components/Form';
import {StyledInputNumber} from '../../../components/hoc/common/components/elements';
import {GridItem} from '../../../components/layout';
import Grid from '../../../components/layout/Grid';
import {FuelsAndGazTypeSelect, OperatingModeSelect} from '../../../components/selects/AutoGeneratedEnumSelects';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import YearSelect from '../../../components/selects/YearSelect';
import YesNoSelect from '../../../components/selects/YesNoSelect';

import type {VehicleTariffTypesAndModels} from '../../../lib/types/vehicleTariff';
import notification from 'antd/lib/notification';
import {VehicleOwnerTypesEnum} from '../../../lib/types/vehicleModel';
import {SubTitle} from './InnerFields';

type TypesAndModelsModalProps = {
  vehicleTariffVehicle: VehicleTariffTypesAndModels,
  visible: boolean,
  onClose: () => any,
  onSave: (
    oldVehicleTariffVehicle: VehicleTariffTypesAndModels,
    vehicleTariffVehicle: VehicleTariffTypesAndModels,
  ) => any
};

type TypesAndModelsModalFormProps = {
  values: VehicleTariffTypesAndModels,
  onSubmit: Function,
  setIsValid: Function,
  formId: string
};

const TypesAndModelsModalForm = ({values, onSubmit, setIsValid}: TypesAndModelsModalFormProps) => {
  return (
    <Form
      initialValues={values}
      onSubmit={values => {
        if (values.productionYearStart > values.productionYearEnd) {
          notification.error({
            message:
              'Год начала выпуска не может быть больше года окончания выпуска',
          });
          return;
        }
        onSubmit(values);
      }}
    >
      {(
        FormField: FormFieldType,
        {setFieldValue, handleSubmit, isValid}: FormikProps,
      ) => {
        setIsValid(isValid);
        return (
          <form
            onSubmit={e => {
              // необходимо, чтобы onSubmit не срабатывал в родительской форме
              e.preventDefault();
              handleSubmit(e);
              e.stopPropagation();
            }}
            id="vvv"
          >
            <Grid cols={6} gutter={'16px'}>
              <GridItem style={{overflow: 'hidden'}}>
                <FormField required label="Модель ТС" name="vehicleModelId">
                  {({name, value}) => (
                    <VehicleModelSelect
                      value={value}
                      filter={{
                        returnDeleted: false,
                        pageSize: undefined,
                      }}
                      onChange={async (value: number, option) => {
                        setFieldValue(name, value);
                        setFieldValue(
                          'vehicleModel',
                          option?.props?.vehicleModel,
                        );
                      }}
                      type={VehicleOwnerTypesEnum.contract}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Год начала выпуска" required name="productionYearStart">
                  {({name, value}) => (
                    <YearSelect
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Год окончания выпуска" required name="productionYearEnd">
                  {({name, value}) => (
                    <YearSelect
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Режим эксплуатации" required name="operatingMode">
                  {({name, value}) => (
                    <OperatingModeSelect value={value} onChange={value => setFieldValue(name, value)} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Установлено ГБО" required name="hasGas">
                  {({name, value}) => (
                    <YesNoSelect
                      name={name}
                      placeholder='Выберите признак ГБО'
                      fullWidth={true}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                      allowClear
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Лизинг" required name="leasing">
                  {({name, value}) => (
                    <YesNoSelect
                      name={name}
                      placeholder='Выберите признак лизинга'
                      fullWidth={true}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                      allowClear
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px" cols={3}>
              <GridItem>
                <FormField label="Тариф (руб.) за 1 час эксплуатации постоянная часть" required name="tariffFixed">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф (руб.) за 1 км пробега переменная часть" required name="tariffDynamic">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тип топлива" name="fuelType" required>
                  {({name, value}) => (
                    <FuelsAndGazTypeSelect
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px" cols={4}>
              <SubTitle>Простой с включенным ДВС (обогрев салона)</SubTitle>
              <SubTitle>Пробег ТС с прицепом</SubTitle>
              <SubTitle>Воздушный отопитель</SubTitle>
              <SubTitle>Жидкостной подогреватель</SubTitle>
            </Grid>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <FormField label="Норма списания топлива на прогрев, л/ч" name="warmingNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      defaultValue={null}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 км, л" name="trailerNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 час работы, л" name="heaterNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 час работы, л" name="preHeaterNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="warmingTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      defaultValue={null}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф за 1 км, руб." name="trailerTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="heaterTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="preHeaterTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px" cols={4} style={{marginTop: '15px'}}>
              <SubTitle>Силовой генератор</SubTitle>
              <SubTitle>Лебедка</SubTitle>
              <SubTitle>Кондиционер</SubTitle>
            </Grid>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 час работы, л" name="generatorNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 час работы, л" name="winchNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Норма расхода топлива на 1 час работы, л" name="airConditionerNorm">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
            <Grid gutter="16px" cols={4}>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="generatorTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="winchTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Тариф за 1 час работы, руб." name="airConditionerTariff">
                  {({name, value}) => (
                    <StyledInputNumber
                      min={0}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </form>
        );
      }}
    </Form>
  );
};

export const TypesAndModelsModal = (props: TypesAndModelsModalProps) => {
  const formId = 'vehicle-tariff';
  const [isValid, setIsValid] = useState();

  const onSubmit = (values: VehicleTariffTypesAndModels) => {
    props.onSave(props.vehicleTariffVehicle, values);
  };

  return (
    <Modal
      centered
      destroyOnClose
      footer={[
        <Button key="back" onClick={() => props.onClose()}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="vvv"
          disabled={!isValid}
        >
          Сохранить
        </Button>,
      ]}
      visible={props.visible}
      onCancel={props.onClose}
      width={'80%'}
      title={props.vehicleTariffVehicle?.id ? 'Редактирование модели' : 'Добавление модели'}
    >
      <TypesAndModelsModalForm
        values={props.vehicleTariffVehicle}
        onSubmit={onSubmit}
        setIsValid={setIsValid}
        formId={formId}
      />
    </Modal>
  );
};
