// @flow

import React, {Component} from 'react';
import {type FormikProps} from 'formik';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Input from 'antd/lib/input';
import isEmpty from 'lodash/isEmpty';
import {RequiredFieldMessage} from '../../components/Form';

import {clear, fetchMaintenance, updateMaintenance} from './../../ducks/maintenance';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import type {Contractor, FormErrors, Maintenance} from '../../lib/types';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Form, Selects} from './../../components';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {orderContractorTypeEnum} from './../../lib/enum';
import {notificationLoading} from './../../components/Notifications';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

const { ContractorSelect } = Selects;

type Props = {
  maintenanceId: number,
  maintenance: Maintenance,
  updateMaintenance: Function,
  fetchMaintenance: Function,
  clear: Function
};

class MaintenanceOrder extends Component<Props> {
  async componentDidMount() {
    const { maintenanceId } = this.props;
    await this.props.clear();
    try {
      if (maintenanceId) {
        await this.props.fetchMaintenance(maintenanceId);
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
      navigate(`/maintenances/${maintenanceId}`);
    }
  }

  render() {
    const { maintenance } = this.props;
    if (isEmpty(maintenance)) return null;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/maintenances')}>
                Ремонт
              </Crumb>
              <Crumb to={`/maintenances/${maintenance.id}`}>
                Ремонт №{maintenance.id}
              </Crumb>
              <Crumb>Создание заявки подрядчику</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenance
              ? `Заявка на ремонт №${maintenance.id}`
              : 'Новая заявка на ремонт'}
          </h1>
        </StyledPanel>
        <Form
          initialValues={maintenance}
          enableReinitialize
          validate={(values: Maintenance) => {
            let errors: FormErrors<Maintenance> = {};
            if (!values.contractorId) {
              errors.contractorId = new RequiredFieldMessage(
                'Обязательное поле'
              );
            }
            if (!values.contractNumber) {
              errors.contractNumber = new RequiredFieldMessage(
                'Обязательное поле'
              );
            }
            return errors;
          }}
          onSubmit={async values => {
            try {
              notificationLoading({
                message: 'Сохранение данных...',
                key: 'saving'
              });
              await this.props.updateMaintenance({
                ...values,
                orderContractorType: orderContractorTypeEnum.contractor
              });
              notification.success({
                message: 'Заявка успешно создана'
              });
              navigate(`/maintenances/${values.id}`);
            } catch (error) {
              notification.error({
                message: 'Ошибка',
                description: error.message
              });
            } finally {
              notification.close('saving');
            }
          }}
        >
          {(FormField, formikProps: FormikProps) => {
            const { handleSubmit, setFieldValue, isSubmitting } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Content>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField
                          label="Подрядчик"
                          hasFeedback
                          required
                          fast
                          name="contractorId"
                        >
                          {({ value, name }) => (
                            <ContractorSelect
                              value={value}
                              onChange={(contractorId: number) =>
                                setFieldValue(name, contractorId)
                              }
                              renderOption={(
                                contractor: Contractor,
                                Option
                              ) => (
                                <Option
                                  key={contractor.id}
                                  value={contractor.id}
                                >
                                  {contractor.company
                                    ? contractor.company.name
                                    : contractor.name}
                                </Option>
                              )}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Номер договора"
                          hasFeedback
                          required
                          fast
                          name="contractNumber"
                        >
                          {fieldProps => <Input {...fieldProps} />}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </Content>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    maintenance: state.maintenance,
    maintenanceId: parseInt(ownProps.maintenanceId, 10)
  }),
  {
    fetchMaintenance,
    updateMaintenance,
    clear
  }
)(MaintenanceOrder);
