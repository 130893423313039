// @flow
import React from 'react';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, Selects} from './../../../../../../../components';
import type {FilterChildrenParams} from './../../../../../../../components/hoc/Filter';
import Field from './../../../../../../../components/card/Field';
import {VehicleOwnerTypesEnum} from '../../../../../../../lib/types/vehicleModel';

const {
  VehicleModelSelect,
  VehicleSelect,
  YearSelect,
  ContractVehicleTypeSelect,
  VehicleStatusSelect,
} = Selects;

export type FixedVehicleListFilterParams = {
  'vehicle.vehicleModel.id'?: number,
  'vehicle.id'?: number,
  'vehicle.yearIssued'?: number,
  'vehicle.status'?: string,
  type?: string
};

type Props = {
  filter: FixedVehicleListFilterParams,
  cleanFilter: Function,
  applyFilter: Function,
  addition?: boolean
};

export default ({filter, cleanFilter, applyFilter, addition}: Props) => (
  <FilterContainer style={{marginBottom: '16px'}}>
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
      additionalFilter={addition ?? true}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter,
        showAdditional,
        handleAdditional,
      }: FilterChildrenParams<FixedVehicleListFilterParams>) => (
        <>
          <FilterItem>
            <Field mBottomNone>
              <VehicleModelSelect
                placeholder="Все модели ТС"
                size="small"
                value={values['vehicle.vehicleModel.id']}
                onChange={value =>
                  changeValue('vehicle.vehicleModel.id', value)
                }
                type={VehicleOwnerTypesEnum.contract}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <VehicleSelect
                placeholder="Все ТС"
                size="small"
                value={values['vehicle.id']}
                onChange={value => changeValue('vehicle.id', value)}
              />
            </Field>
          </FilterItem>
          <FilterItem>
            <Field mBottomNone>
              <YearSelect
                placeholder="Год"
                size="small"
                value={values['vehicle.yearIssued']}
                onChange={value => changeValue('vehicle.yearIssued', value)}
              />
            </Field>
          </FilterItem>

          {addition ?? (
            <>
              <FilterItem>
                <Field mBottomNone>
                  <ContractVehicleTypeSelect
                    size="small"
                    value={values.type}
                    onChange={value => changeValue('type', value)}
                  />
                </Field>
              </FilterItem>
              <FilterItem>
                <Field mBottomNone>
                  <VehicleStatusSelect
                    size="small"
                    placeholder="Cтатусы ТС"
                    value={values['vehicle.status']}
                    onChange={value => changeValue('vehicle.status', value)}
                  />
                </Field>
              </FilterItem>
            </>
          )}
          <FilterButtonsContainer
            applyFilter={applyFilter}
            cleanFilter={cleanFilter}
            handleAdditional={addition ?? handleAdditional}
            showAdditional={addition ?? showAdditional}
          />
        </>
      )}
    </Filter>
  </FilterContainer>
);
