// @flow
import React, {Component} from 'react';

import {TabItem, Tabs} from './../../../components/ui';
import {navigate} from '../../../lib/helpers';

type Props = {
  selectedTab: 'general' | 'branch' | 'vehicle' | 'kpr'
};

const COMMON_ROUTE = 'osago-multiplier';

export default class HeaderTabs extends Component<Props> {
  render() {
    return (
      <Tabs defaultTab={this.props.selectedTab}>
        <TabItem
          tabKey="general"
          label="Общие"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/`, true)}
        />
        <TabItem
          tabKey="branch"
          label="Филиал"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/branch`, true)}
        />
        <TabItem
          tabKey="base"
          label="Базовая ставка"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/base-rate`, true)}
        />
        <TabItem
          tabKey="kpr"
          label="КПР"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/kpr`, true)}
        />
        <TabItem
          tabKey="power"
          label="Мощность"
          onClick={() => navigate(`/admin/${COMMON_ROUTE}/power`, true)}
        />
      </Tabs>
    );
  }
}
