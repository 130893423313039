// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import {notificationLoading} from '../../components/Notifications';
import {MaintenanceWorkApi, ModelMatchesApi, TypesOfRepairsApi} from '../../lib/api';
import type {MaintenanceWork, ModelMatches} from '../../lib/types';
import {entityStatusEnum} from '../../lib/enum';
import type {RepairKind} from '../../lib/types/typesOfRepairs';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  id?: number,
};

type State = {
  maintenanceWork: ?MaintenanceWork,
  modelMatches: ModelMatches[],
  typesOfRepairs: RepairKind[],
};

export class Form extends React.Component<Props, State> {
  state = {
    maintenanceWork: null,
    modelMatches: [],
    typesOfRepairs: [],
  };
  
  async componentDidMount() {
    const {id} = this.props;
    try {
      let maintenanceWork = null;
      if (id) {
        maintenanceWork = await MaintenanceWorkApi.get(id);
      }
      const {data: modelMatches} = await ModelMatchesApi.fetch();
      const {data: typesOfRepairs} = await TypesOfRepairsApi.fetch();
      this.setState({maintenanceWork, modelMatches, typesOfRepairs});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
  }
  
  handleSubmit = async (values: MaintenanceWork) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (values.id) {
        const maintenanceWork = await MaintenanceWorkApi.update({...values, status: entityStatusEnum.created});
        notification.success({message: 'Изменения сохранены'});
        navigate(`/admin/maintenance-works/${values.id}`);
        this.setState({maintenanceWork});
      } else {
        const newWork = await MaintenanceWorkApi.add(values);
        navigate(`/admin/maintenance-works/${newWork.id}`)
        notification.success({message: 'Запись добавлена'});
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  handleCancel = () => navigate('/admin/maintenance-works/', true);
  
  render() {
    const {maintenanceWork, modelMatches, typesOfRepairs} = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/maintenance-works/')}
              >
                Работы по ТО
              </Crumb>
              {maintenanceWork?.id ? (
                <Crumb>Работа №{maintenanceWork.id}</Crumb>
              ) : (
                <Crumb>Новая работа</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceWork?.id
              ? `Работа №${maintenanceWork.id}`
              : 'Новая работа'}
          </h1>
        </StyledPanel>
        <InnerForm
          maintenanceWork={maintenanceWork}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
          modelMatches={modelMatches}
          typesOfRepairs={typesOfRepairs}
        />
      </>
    );
  }
}

export default Form;
