// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import { type FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

import CancelButton from '../../../components/CancelButton';
import { FormField } from '../../../components/Form';
import { Section } from '../../../components/layout';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FuelAndOilViewSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import Select from '../../../components/ui/Select';

import {
  fuelAndOilGroup,
  fuelAndOilSubType,
  fuelAndOilSubView,
  fuelAndOilType
} from '../../../lib/enum';
import type {
  FuelAndOil,
  FuelAndOilGroup,
  FuelAndOilSubType,
  FuelAndOilSubView,
  FuelAndOilType
} from '../../../lib/types/fuelAndOil';

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const { Option } = Select;

type FormProps = FormikProps<FuelAndOil> & {
  onCancel: Function
};

const InnerForm = ({
  handleSubmit,
  onCancel,
  setFieldValue,
  subViews,
  types,
  subTypes,
  groups,
  dirty,
  isSubmitting
}: FormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Section>
        <Content>
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Наименование" required hasFeedback name="name">
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Вязкость" name="viscosity">
                {({ name, value }) => (
                  <InputNumber
                    min={0}
                    value={value}
                    name={name}
                    onChange={cost => setFieldValue(name, cost)}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Вид" required hasFeedback name="view">
                {({ value }) => (
                  <FuelAndOilViewSelect
                    value={value}
                    onChange={view => {
                      setFieldValue('view', view);
                      setFieldValue('subView', null);
                      setFieldValue('type', null);
                      setFieldValue('subType', null);
                      setFieldValue('group', null);
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            {subViews && subViews.length > 0 && (
              <GridItem>
                <FormField label="Подвид" required hasFeedback name="subView">
                  {field => (
                    <Select
                      placeholder="Выберите подвид ГСМ"
                      {...field}
                      onChange={subView => {
                        setFieldValue('subView', subView);
                        setFieldValue('type', null);
                        setFieldValue('subType', null);
                        setFieldValue('group', null);
                      }}
                      data-cy="subViewSelect"
                    >
                      {subViews.map((subView: FuelAndOilSubView) => (
                        <Option key={subView} value={subView}>
                          {fuelAndOilSubView[subView]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
            )}
            {types && types.length > 0 && (
              <GridItem>
                <FormField label="Тип" required hasFeedback name="type">
                  {field => (
                    <Select
                      placeholder="Выберите тип ГСМ"
                      {...field}
                      onChange={type => {
                        setFieldValue('type', type);
                        setFieldValue('subType', null);
                        setFieldValue('group', null);
                      }}
                    >
                      {types.map((type: FuelAndOilType) => (
                        <Option key={type} value={type}>
                          {fuelAndOilType[type]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
            )}
            {subTypes && subTypes.length > 0 && (
              <GridItem>
                <FormField label="Подтип" required hasFeedback name="subType">
                  {field => (
                    <Select
                      placeholder="Выберите подтип ГСМ"
                      {...field}
                      onChange={subType => {
                        setFieldValue('subType', subType);
                        setFieldValue('group', null);
                      }}
                    >
                      {subTypes.map((subType: FuelAndOilSubType) => (
                        <Option key={subType} value={subType}>
                          {fuelAndOilSubType[subType]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
            )}
            {groups && groups.length > 0 && (
              <GridItem>
                <FormField label="Группа" required hasFeedback name="group">
                  {field => (
                    <Select
                      placeholder="Выберите группу ГСМ"
                      data-cy="groupSelect"
                      {...field}
                      onChange={group => setFieldValue('group', group)}
                    >
                      {groups.map((group: FuelAndOilGroup) => (
                        <Option key={group} value={group}>
                          {fuelAndOilGroup[group]}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormField>
              </GridItem>
            )}
          </Grid>
        </Content>
      </Section>
      <Footer>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          type="primary"
          htmlType="submit"
          data-cy="save"
        >
          Сохранить
        </Button>
        <CancelButton dirty={dirty} onClick={onCancel}>
          Отменить
        </CancelButton>
      </Footer>
    </form>
  );
};

export default InnerForm;
