// @flow
import CommonApi from '../commonApi';
import { cognosContractMonthEnum } from '../enum';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import type {
  CognosContract,
  CognosContractCoefMonth,
  CognosContractMonths
} from '../types';
import { fetchRequest } from './index';

const convertDataToServer = (cognosContract: CognosContract) => ({
  ...cognosContract,
  cognosContractMonths: cognosContract.cognosContractMonths?.map(
    (cognosContractMonth: CognosContractMonths) => ({
      ...cognosContractMonth,
      sum: convertFromRubToKop(cognosContractMonth.sum),
      cognosContractCoefMonths: cognosContractMonth.cognosContractCoefMonths?.map(
        cognosContractCoefMonth => ({
          ...cognosContractCoefMonth,
          coef: cognosContractCoefMonth?.coef ?? 0
        })
      )
    })
  )
});

const convertDataFromServer = (cognosContract: CognosContract) => ({
  ...cognosContract,
  cognosContractMonths: cognosContract.cognosContractMonths
    ?.map((cognosContractMonth: CognosContractMonths) => ({
      ...cognosContractMonth,
      sum: convertFromKopToRub(cognosContractMonth.sum),
      cognosContractCoefMonths: cognosContractMonth.cognosContractCoefMonths.sort(
        (a: CognosContractCoefMonth, b: CognosContractCoefMonth) => {
          let compare;
          if (a.type === b.type) {
            compare = a.month - b.month;
          } else {
            if (a.type === cognosContractMonthEnum.pastYear) {
              compare = -20;
            } else if (a.type === cognosContractMonthEnum.nextYear) {
              compare = 20;
            } else if (
              a.type === cognosContractMonthEnum.currentYear &&
              b.type === cognosContractMonthEnum.pastYear
            ) {
              compare = 20;
            } else {
              compare = -20;
            }
          }
          return compare;
        }
      )
    }))
    .sort((a, b) => a.month - b.month)
});

const baseUrl = 'CognosContract';
const budgetSummaryContracts = new CommonApi<CognosContract>(
  baseUrl,
  'Контракты по основному бюджету',
  convertDataToServer,
  convertDataFromServer
);

const changeStatus = async (payload: any) => {
  const updated = await fetchRequest.put(`/${baseUrl}/changeStatus`, payload);
  if (updated) return convertDataFromServer(updated);
  throw new Error(`Не удалось сменить статус`);
};

export default {
  ...budgetSummaryContracts,
  changeStatus
};
