// @flow
import React, {useEffect, useState} from 'react';
import {navigate} from '../../lib/helpers';
import {VehicleCategoriesApi, VehicleSubcategoriesApi} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm from '../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import type {ContractorBranchLink} from '../../lib/types/contractorBranchLink';
import {accessTypeEnum} from '../../lib/enum';
import {withUserAccess} from '../withUserAccess';
import type {UserAccess, VehicleCategory, VehicleSubcategory} from '../../lib/types';
import {vehicleCategoriesTypeEnum} from './VehicleCategoriesList';
import Input from 'antd/lib/input';
import TextArea from 'antd/lib/input/TextArea';
import VehicleCategorySelect from '../../components/selects/VehicleCategorySelect';

type Props = {
  id: number,
  type: $Keys<vehicleCategoriesTypeEnum>,
  userAccess: UserAccess[],
};

const entryPointPath = '/admin/vehicle-categories/';

const VehicleCategoriesForm = ({id, type, userAccess}: Props) => {
  const [data: VehicleCategory | VehicleSubcategory, setData] = useState({id});

  useEffect(() => {
    if (id && type) {
      onFetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadCrumbs = [
    {
      to: `${entryPointPath}${type === vehicleCategoriesTypeEnum.categories ? type : ''}`,
      name: type === vehicleCategoriesTypeEnum.categories ? 'Категории ТО ГИБДД' : 'Подкатегории ТО ГИБДД',
    },
    {name: id ? id : 'Новая запись'},
  ];

  const onFetch = async () => {
    const request = type === vehicleCategoriesTypeEnum.categories
      ? VehicleCategoriesApi.get
      : VehicleSubcategoriesApi.get;
    !!id && setData(await request(id));
  };

  const onSubmit = async (payload: ContractorBranchLink) => {
    let id = payload.id;
    if (id) {
      const request = type === vehicleCategoriesTypeEnum.categories
        ? VehicleCategoriesApi.update
        : VehicleSubcategoriesApi.update;
      await request(payload);
    } else {
      const request = type === vehicleCategoriesTypeEnum.categories
        ? VehicleCategoriesApi.add
        : VehicleSubcategoriesApi.add;
      await request(payload);
    }
    await navigate(`${entryPointPath}${vehicleCategoriesTypeEnum.subcategories === type ? type : ''}`);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}${vehicleCategoriesTypeEnum.subcategories === type ? type : ''}`);
  };

  const canEdit = () => userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.handlingVehicleCategory,
    ].includes(access),
  );

  const header = id
    ? `${type === vehicleCategoriesTypeEnum.categories ? 'Категория' : 'Подкатегория'} ТО ГИБДД № ${id}`
    : `Новая ${type === vehicleCategoriesTypeEnum.categories ? 'категория' : 'подкатегория'} ТО ГИБДД`;

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: header,
        access: canEdit() && {
          delete: data.id
            ? async () => {
              const request = type === vehicleCategoriesTypeEnum.categories
                ? VehicleCategoriesApi.delete
                : VehicleSubcategoriesApi.delete;
              await request(data.id);
              await navigate(`${entryPointPath}${type}`);
            }
            : undefined,
        },
      }}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <CommonForm data={data} useFooter={canEdit()} noWrapMe>
        {({setFieldValue}) => {
          return (
            <>
              <Grid gutter="16px">
                <GridItem>
                  <FormField
                    label="Категория ТС"
                    required
                    name={type === vehicleCategoriesTypeEnum.categories ? 'name' : 'vehicleCategoryId'}
                    hasFeedback={false}
                  >
                    {({name, value}) => {
                      if (type === vehicleCategoriesTypeEnum.categories) {
                        return (
                          <Input
                            value={value}
                            onChange={({target}) => setFieldValue(name, target.value)}
                            disabled={!canEdit()}
                          />
                        );
                      } else {
                        return (
                          <VehicleCategorySelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                            disabled={!canEdit()}
                          />
                        );
                      }
                    }}
                  </FormField>
                </GridItem>
                {type === vehicleCategoriesTypeEnum.subcategories && (
                  <>
                    <FormField
                      label="Подкатегория ТС"
                      required
                      name="name"
                      hasFeedback={false}
                    >
                      {({name, value}) => (
                        <Input
                          value={value}
                          onChange={({target}) => setFieldValue(name, target.value)}
                          disabled={!canEdit()}
                        />
                      )}
                    </FormField>
                    <FormField
                      label="Описание категории"
                      required
                      name="description"
                      hasFeedback={false}
                    >
                      {({name, value}) => (
                        <TextArea
                          value={value}
                          onChange={({target}) => setFieldValue(name, target.value)}
                          rows={4}
                          disabled={!canEdit()}
                        />
                      )}
                    </FormField>
                  </>
                )}
              </Grid>
            </>
          );
        }}
      </CommonForm>
    </CommonCardPage>
  );
};

export default withUserAccess(VehicleCategoriesForm);
