// @flow
import React from 'react';
import notification from 'antd/lib/notification';
import Table from 'antd/lib/table/Table';
import moment from 'moment';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import { applyMaskToValue } from './../../../lib/helpers';
import type {
  ReportVehicleWork,
  ReportVehicleWorkParams
} from '../../../lib/types/reportVehicleWork';
import { fuelTypes, vehicleTypes } from '../../../lib/enum';
import Filter from './components/Filter';
import type { FuelType, VehicleType } from '../../../lib/types';
import { reportVehicleWorksApi } from '../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialParams: ReportVehicleWorkParams = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  params: ReportVehicleWorkParams,
  data: ReportVehicleWork[],
  loading: boolean
};

/**
 * Отчет о работе ТС
 */
export class VehicleWorksList extends React.Component<{}, State> {
  state = {
    params: initialParams,
    data: [],
    loading: false
  };

  async componentDidMount() {
    await this.fetchReportVehicleWorks();
  }

  fetchReportVehicleWorks = async () => {
    try {
      this.setState({ loading: true });
      const data = await reportVehicleWorksApi.fetchReportVehicleWorks(
        this.state.params
      );
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleChangeFilter = (key: $Keys<ReportVehicleWorkParams>, value: any) => {
    this.setState(prevState => ({
      params: {
        ...prevState.params,
        [(key: string)]: value
      }
    }));
  };

  applyParams = this.fetchReportVehicleWorks;

  cleanParams = () => this.setState({ params: initialParams });

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing'
      });
      await reportVehicleWorksApi.printReportVehicleWorks(this.state.params);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      fixed: 'left',
      render: (licensePlate: ?string) =>
        licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
    },
    {
      title: 'Марка ТС',
      dataIndex: 'brandName',
      fixed: 'left'
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      render: (type: VehicleType) => vehicleTypes[type]
    },
    {
      title: 'Служба',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Пробег за период',
      dataIndex: 'kilometrage',
      render: (kilometrage: number) => `${kilometrage} км.`
    },
    {
      title: 'Количество маш/часов за период',
      dataIndex: 'workTime',
      render: (workTime: number) => `${workTime} ч.`
    },
    {
      title: 'Остаток на начало периода',
      dataIndex: 'fuelAmountAtTheBegining',
      render: (fuelAmountAtTheBegining: number) =>
        `${fuelAmountAtTheBegining} л.`
    },
    {
      title: 'Остаток на конец периода',
      dataIndex: 'fuelAmountAtTheEnd',
      render: (fuelAmountAtTheEnd: number) => `${fuelAmountAtTheEnd} л.`
    },
    {
      title: 'В том числе истрачено топлива',
      dataIndex: 'fuelConsumed',
      render: (fuelConsumed: number) => `${fuelConsumed} (м/ч, л)`
    },
    {
      title: 'Выдано топлива по Путевому Листу',
      dataIndex: 'fuelIssued',
      render: (fuelIssued: number) => `${fuelIssued} л.`
    },
    {
      title: 'Кол-во Путевых Листов',
      dataIndex: 'tripCount'
    },
    {
      title: 'Вид первичного топлива',
      dataIndex: 'primaryFuelType',
      render: (type: FuelType) => fuelTypes[type]
    },
    {
      title: 'Вид вторичного топлива',
      dataIndex: 'secondaryFuelType',
      render: (type: FuelType) => fuelTypes[type]
    }
  ];

  render() {
    const { data, loading } = this.state;
    return (
      <>
        <Header left={<h1>Отчет о работе ТС</h1>} />
        <Section>
          <Filter
            params={this.state.params}
            onChangeFilter={this.handleChangeFilter}
            applyParams={this.applyParams}
            cleanParams={this.cleanParams}
            onExport={this.handleExport}
          />
          <Table
            rowKey="vehicleId"
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}

export default VehicleWorksList;
