// @flow

import React, {Component} from 'react';
import {type FormikProps} from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import styled from 'styled-components';
import InputNumber from 'antd/lib/input-number';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import type {MaintenanceOperation, ModelMatches, UserAccess} from '../../lib/types';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Form} from './../../components';
import {notificationLoading} from '../../components/Notifications';
import {maintenanceOperationApi, ModelMatchesApi, TypesOfRepairsApi} from '../../lib/api';
import type {RepairKind} from '../../lib/types/typesOfRepairs';
import Select from 'antd/lib/select';
import {WorkSubTypes, WorkTypes, WorkTypesEnum} from '../../lib/types/maintenanceOperation';
import {entityStatusEnum, newEntityStatuses} from '../../lib/enum';
import {withUserAccess} from '../withUserAccess';

const Content = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;
const StyledParagraph = styled.p`
  margin: 6px 0 0;
`;
const {Option} = Select;

type Props = {
  userAccess: UserAccess[],
  maintenanceOperationId: number
};

type State = {
  typesOfRepairs: RepairKind[],
  operation: MaintenanceOperation,
  modelMatches: ModelMatches[],
};

const WorkTypeValues = {
  post: 1,
  workshop: 2,
};

class MaintenanceOperationForm extends Component<Props, State> {
  state = {
    operation: null,
    typesOfRepairs: [],
    modelMatches: [],
  };
  async componentDidMount() {
    try {
      if (this.props.maintenanceOperationId) {
        await this.fetch();
      } else {
        this.setState({operation: {}});
      }
      const {data: typesOfRepairs} = await TypesOfRepairsApi.fetch();
      const {data: modelMatches} = await ModelMatchesApi.fetch();
      this.setState({modelMatches, typesOfRepairs});
    } catch (err) {
      notification.error({
        message: 'Ошибка загрузки работы',
        description: err.message,
      });
      await navigate('/admin/maintenance-operations/', true);
    }
  }
  
  fetch = async () => {
    const {maintenanceOperationId} = this.props;
    try {
      const operation = await maintenanceOperationApi.fetchMaintenanceOperation(maintenanceOperationId);
      this.setState({operation});
    } catch (err) {
      notification.error({
        message: 'Ошибка загрузки работы',
        description: err.message,
      });
      await navigate('/admin/maintenance-operations/', true);
    }
  };
  
  handleSubmit = async values => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      values.status = entityStatusEnum.created;
      if (values.id) {
        await maintenanceOperationApi.updateMaintenanceOperation(values);
        navigate(`/admin/maintenance-operations/${values.id}`);
      } else {
        const newOperation = await maintenanceOperationApi.addMaintenanceOperation(values);
        navigate(`/admin/maintenance-operations/${newOperation.id}`);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены',
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  render() {
    const {operation, typesOfRepairs, modelMatches} = this.state;
    const {maintenanceOperationId} = this.props;
    if (!operation) {
      return null;
    }
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/maintenance-operations')}>
                Работы по ремонту ТС
              </Crumb>
              {operation?.id ? (
                <Crumb to={`/admin/maintenance-operations/${operation.id}`}>
                  Работа №{operation.id}
                </Crumb>
              ) : (
                <Crumb to={`/admin/maintenance-operations/new`}>
                  Новая работа
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{operation?.id ? `Работа №${operation?.id}` : 'Новая работа'}</h1>
        </StyledPanel>
        <Form initialValues={operation} onSubmit={this.handleSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              values,
              setFieldValue,
              handleChange,
              handleBlur,
              isSubmitting,
            } = formikProps;
            const setCode = values => {
              const {workTypeCode, repairKind, workSubTypeCode, number} = values;
              let code = `РА1-${repairKind.code || ''}-${workTypeCode || ''}${workSubTypeCode ? '-' + workSubTypeCode : ''}-`;
              if (number) {
                code += number;
              }
              setFieldValue('code', code);
            };
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <Content>
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField label="Вид ремонта" required name="repairKindId" hasFeedback={false}>
                          {({value, name}) => (
                            <Select
                              value={value}
                              onChange={newValue => {
                                setFieldValue(name, newValue);
                                const selectedTypeOfRepair = typesOfRepairs.find(item => item.id === newValue);
                                setFieldValue('repairKind', selectedTypeOfRepair);
                                setCode({
                                  ...values,
                                  repairKindId: newValue,
                                  repairKind: selectedTypeOfRepair,
                                });
                              }}
                            >
                              {typesOfRepairs.map(item => (
                                <Option key={item.id} value={item.id}>
                                  {item.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Код вида ремонта" name="repairKind.code">
                          {({value}) => <StyledParagraph>{value}</StyledParagraph>}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Тип работ" required name="workType" hasFeedback={false}>
                          {({value, name}) => (
                            <Select
                              value={value}
                              onChange={newValue => {
                                const newValues = {...values};
                                if (newValue === WorkTypesEnum.post) {
                                  setFieldValue('workSubType', null);
                                  setFieldValue('workSubTypeCode', null);
                                  newValues.workSubType = null;
                                  newValues.workSubTypeCode = null;
                                }
                                setFieldValue(name, newValue);
                                setFieldValue('workTypeCode', WorkTypeValues[newValue]);
                                newValues.workType = newValue;
                                newValues.workTypeCode = WorkTypeValues[newValue];
                                setCode(newValues);
                              }}
                            >
                              {Object.keys(WorkTypes).map(key => (
                                <Option key={key} value={key}>
                                  {WorkTypes[key]}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Код типа работ" name="workTypeCode">
                          {({value}) => <StyledParagraph>{value}</StyledParagraph>}
                        </FormField>
                      </GridItem>
                      {values.workType === WorkTypesEnum.workshop && (
                        <>
                          <GridItem>
                            <FormField label="Подтип работ" required name="workSubType" hasFeedback={false}>
                              {({value, name}) => (
                                <Select
                                  value={value}
                                  onChange={newValue => {
                                    setFieldValue(name, newValue);
                                    const subTypeCode = Object.keys(WorkSubTypes).indexOf(newValue) + 1;
                                    setFieldValue('workSubTypeCode', subTypeCode);
                                    setCode({
                                      ...values,
                                      workSubType: newValue,
                                      workSubTypeCode: subTypeCode,
                                    });
                                  }}
                                >
                                  {Object.keys(WorkSubTypes).map(key => (
                                    <Option key={key} value={key}>
                                      {WorkSubTypes[key]}
                                    </Option>
                                  ))}
                                </Select>
                              )}
                            </FormField>
                          </GridItem>
                          <GridItem>
                            <FormField label="Код подтипа работ" name="workSubTypeCode">
                              {({value}) => <StyledParagraph>{value}</StyledParagraph>}
                            </FormField>
                          </GridItem>
                        </>
                      )}
                      <GridItem>
                        <FormField label="Наименование работы" required name="name" hasFeedback={false}>
                          {({value, name}) => (
                            <Input
                              value={value}
                              onChange={({target}) => {
                                setFieldValue(name, target.value);
                                setCode({
                                  ...values,
                                  name: target.value,
                                });
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Номер работы" name="number">
                          {({value}) => <StyledParagraph>{value}</StyledParagraph>}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Единица оборудования" required name="unit" hasFeedback={false}>
                          {({value, name}) => (
                            <Input
                              name={name}
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Код работы" name="code">
                          {({value}) => <StyledParagraph>{value}</StyledParagraph>}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Марка и модель" required name="vehicleModelMatch.id" hasFeedback={false}>
                          {({value, name}) => (
                            <Select
                              value={value}
                              onChange={newValue => {
                                setFieldValue(name, newValue);
                                setFieldValue('vehicleModelMatchId', newValue);
                                const selectedItem = modelMatches.find(item => item.id === newValue);
                                setFieldValue('vehicleModelMatch.brandName', selectedItem.brandName);
                                setFieldValue('vehicleModelMatch.modelName', selectedItem.modelName);
                                setFieldValue('number', null);
                                setCode({...values, number: null});
                              }}
                            >
                              {modelMatches.map(item => (
                                <Option key={item.id} value={item.id}>
                                  {`${item.brandName} ${item.modelName}`}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Норма-часы" required name="hours" hasFeedback={false}>
                          {({value, name}) => (
                            <InputNumber
                              min={0}
                              value={value}
                              name={name}
                              onChange={(value: number) => setFieldValue(name, value)}
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      {values.status && (
                        <GridItem>
                          <FormField label="Статус" name="status">
                            {({value}) => <StyledParagraph>{newEntityStatuses[value]}</StyledParagraph>}
                          </FormField>
                        </GridItem>
                      )}
                    </Grid>
                  </Content>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Сохранить
                  </Button>
                  <Button onClick={() => navigate(`/admin/maintenance-operations/${maintenanceOperationId}`, true)}>
                    Отменить
                  </Button>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default withUserAccess(MaintenanceOperationForm);
