import React, {Component} from "react";
import {Icon} from 'antd';
import Menu from 'antd/lib/menu';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import qs from 'query-string';
import styled, { createGlobalStyle } from 'styled-components';

import {Section} from '../../../../components/layout';
import {Table} from '../../../../components/ui';
import { withUserAccess } from '../../../withUserAccess';
import {Filter} from '../../components';
import {notificationError, notificationLoading} from '../../../../components/Notifications';
import type {FetchListParams} from '../../../../lib/api';
import {vehicleTariffApi} from '../../../../lib/api';
import {vehicleTariffStatus} from '../../../../lib/enum';
import {formatDateTimeToString, getListInitialState, navigate, setQueryParams} from '../../../../lib/helpers';
import {Dropdown} from '../../../../components/ui';

import type {VehicleTariffStatus} from '../../../lib/types';

const StyledIcon = styled(Icon)`
  font-size: 16px;
  margin: 0 5px;
  color: #2770ff;
  cursor: pointer;
`;

const PopconfirmFullWidthContent = createGlobalStyle`
  .ant-dropdown-menu-item > span {
    display: block;
    width: 100%;
  }
`

type Props = {
  location: Location & { state: { page: number } },
  canAdd: () => boolean
};

type State = ListState<VehicleTariff> & {
  filter: VehicleTariffFilterParams
};

const COLUMNS = [
  {
    title: 'Подрядчик',
    key: 'companyName',
    dataIndex: 'contractor.company.name',
  },
  {
    title: 'Начало действия тарифа',
    key: 'startDate',
    dataIndex: 'startDate',
    width: '130px',
    render: (startDate: string) =>
      formatDateTimeToString(startDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Окончание действия тарифа ',
    key: 'endDate',
    dataIndex: 'endDate',
    width: '130px',
    render: (endDate: string) => formatDateTimeToString(endDate, 'DD.MM.YYYY'),
  },
  {
    title: 'Версия тарифа',
    key: 'status',
    dataIndex: 'status',
    width: '130px',
    render: (status: VehicleTariffStatus) => vehicleTariffStatus[status],
  },
];

class FactTab extends Component<Props, State> {
  columns: Object[];
  url: string;

  constructor(props: Props) {
    super(props);
    this.state = {
      ...getListInitialState(),
      filter: {},
      modalVisible: false,
    };
    this.columns = COLUMNS;
    this.url = '/admin/vehicle-tariff/tariff';
  }

  deleteVehicleTariff = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting',
      });
      await vehicleTariffApi.deleteVehicleTariff(id);
      await this.fetchVehicleTariff(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchVehicleTariff = async (
    page: number = 1,
    params: FetchListParams<any> = {},
  ) => {
    try {
      const {filter} = this.state;
      this.setState({loading: true});
      const {data, totalCount} = await vehicleTariffApi.fetchFactVehicleTariff({
        ...filter,
        page,
        ...params,
        vehicleTariffType: 'byBrand',
      });
      setQueryParams({page});
      this.setState({loading: false, data, totalCount, page});
    } catch (e) {
      notificationError(e)
    }
  };

  applyFilter = (filter: VehicleTariffFilterParams) => {
    const {page} = this.state;
    this.setState({filter}, () => this.fetchVehicleTariff(page));
  };

  cleanFilter = () => {
    const {page} = this.state;
    this.setState({filter: {}}, () =>
      this.fetchVehicleTariff(page),
    );
  };
  
  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    const {canAdd} = this.props;
    this.setState({filter}, () => this.fetchVehicleTariff(page));
    if (canAdd) {
      this.columns = [
        ...COLUMNS,
        {
          stopPropagation: true,
          title: ' ',
          width: 50,
          dataIndex: 'id',
          render: (id: number): any => (
            <Dropdown
              key={id}
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={async () => {
                      navigate(
                        `${this.url}/fact/${id}/edit/clone`,
                      );
                    }}
                  >
                    Копировать запись
                  </Menu.Item>

                  <Menu.Item>
                    <Popconfirm
                      overlayStyle={{
                        zIndex: 2000,
                      }}
                      placement="left"
                      title={<>Вы действительно хотите удалить?</>}
                      onConfirm={() => {
                        this.deleteVehicleTariff(id);
                      }}
                    >
                      Удалить
                    </Popconfirm>
                  </Menu.Item>
                </Menu>
              }
            >
              <StyledIcon type="ellipsis" />
            </Dropdown>
          ),
        },
      ];
    }
  }

  render() {
    const {location} = this.props;
    const {data, totalCount, pageSize, page, loading, filter} = this.state;

    return (
      <>
        <PopconfirmFullWidthContent />
        <Section>
          <Filter
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              version='fact'
          />
          <Table
              onRow={record => ({
                  onClick: () => navigate(`${this.url}/fact/${record.id}`),
              })}
              columns={this.columns}
              fetch={this.fetchVehicleTariff}
              loading={loading}
              pagination={{
                  page,
                  pageSize,
                  totalCount,
                  location,
              }}
              data={data}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(FactTab);