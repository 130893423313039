import React from 'react';
import moment from 'moment';

import type { OrderHistoryItem } from '../../../lib/types/order';
import { Spoiler } from '../../../components/ui';
import { Section } from '../../../components/layout';
import Table from 'antd/lib/table';

type Props = {
  history: OrderHistoryItem[]
};

const HistorySection = ({ history }: Props) => {
  const columns = [
    {
      title: 'Номер изменения',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: 'Дата изменения',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'ФИО пользователя, вносившего изменения',
      dataIndex: 'employee',
      key: 'employee'
    },
    {
      title: 'Подразделение пользователя, вносившего изменения',
      dataIndex: 'orgUnit',
      key: 'orgUnit'
    },
    {
      title: 'Событие (изменение)',
      dataIndex: 'event',
      key: 'event'
    }
  ];

  const dataSource = history
    .sort((a: OrderHistoryItem, b: OrderHistoryItem) =>
      moment(a.created).isBefore(b.created) ? -1 : 1
    )
    .map((item: OrderHistoryItem, index) => ({
      key: index,
      index: index + 1,
      date: moment(item.created).format('DD.MM.YYYY HH:mm'),
      employee: `${item.employee.lastname} ${item.employee.firstname} ${item.employee.middlename}`,
      orgUnit: item.employee.orgUnitName,
      event: item.type
    }));

  return (
    <Section>
      <Spoiler showDivider={false} label="История изменения">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={dataSource.length > 5 ? { pageSize: 5 } : false}
        />
      </Spoiler>
    </Section>
  );
};

export default React.memo(HistorySection);
