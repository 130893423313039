// @flow
import type { Dispatch } from 'redux';
import type { Action } from './redux';
import type { MonitoringVehicle } from '../lib/types/monitoringVehicle';
import { ownerTypes, fuelTypeEnum } from './../lib/enum';

export const SET_MONITORING_VEHICLES = 'vehicles/monitoring/set';
export const SET_VEHICLE_POSITION = 'vehicles/monitoring/set-vehicle-position';

export type MonitoringState = {
  monitoringVehicles: MonitoringVehicle[]
};

const initialState = {
  monitoringVehicles: []
};

const reducer = (
  state: MonitoringState = initialState,
  { type, payload }: Action
): MonitoringState => {
  switch (type) {
    case SET_MONITORING_VEHICLES:
      return {
        ...state,
        monitoringVehicles: payload
      };
    default:
      return state;
  }
};

export const fetchMonitoringVehicles = () => async (dispatch: Dispatch) => {
  dispatch({
    type: SET_MONITORING_VEHICLES,
    payload: monitoringVehiclesMock
  });
};

export const cleanMonitoringVehicles = () => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MONITORING_VEHICLES,
    payload: []
  });
};

const monitoringVehiclesMock: MonitoringVehicle[] = [
  {
    vehicle: {
      parentOrgUnitId: 0,
      parentOrgUnitName: null,
      orgUnitId: 1,
      orgUnitName: 'Главный',
      licensePlate: '2525',
      vehicleModelId: 1,
      vehicleModel: {
        name: 'клинцы',
        configuration: 'кук',
        brandId: 1,
        brandName: 'маз',
        type: 'jibCrane',
        fuelConsumption: 13,
        fuelsAndOils: [],
        technologyMap: null,
        id: 1,
        unladenMass: 0,
        grossWeight: 0,
        axlesNumber: 0,
        frontAxleLoad: null,
        backAxleLoad: null,
        dimensions: null,
        maximumAuthorizedMass: 0,
        enginePower: 0,
        passengerSeatsNumber: 0
      },
      status: 'working',
      yearIssued: 2010,
      vin: '3GNBABFW0BS506525',
      primaryFuelType: fuelTypeEnum.dieselFuel,

      kilometrage: 12,
      normKilometrage: 10,
      averageKilometrage: 0,
      averageDailyKilometrage: 0,
      kilometrageFromLastOverhaul: 0,
      pts: '14',
      ptsDate: '2018-11-19T00:00:00',
      sts: '2332',
      stsDate: '2018-11-19T00:00:00',
      registrationPlace: 'зжхзжх',
      primaryFuelConsumption: 13,
      primaryEquipmentFuelConsumption: 13,
      fuelLevel: 10,
      osago: null,
      osagoId: null,
      osagoStartedDate: '0001-01-01T00:00:00',
      osagoFinishedDate: '0001-01-01T00:00:00',
      driverId: 1,
      driver: {
        employee: {
          orgUnitId: 2,
          orgUnitName: 'КЭС',
          firstname: 'Иван',
          lastname: 'Зинин',
          middlename: 'Абрамович',
          birthdate: '0001-01-01T00:00:00',
          personId: 2,
          isDriver: true,
          isUser: true,
          avatarUrl: null,
          avatarId: null,
          position: 'driver',
          positionTitle: null,
          protocolNumber: null,
          protocolDate: null,
          orderNumber: null,
          orderDate: null,
          documentNumber: null,
          orgUnitMnemonic: '1221',
          parentOrgUnitMnemonic: null,
          orgUnitInn: null,
          orgUnitKpp: null,
          orgUnitOgrn: null,
          orgUnitAddress: null,
          orgUnitPhone: null,
          id: 2
        },
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        status: 'free',
        licenseNumber: '55656536',
        employeeId: 2,
        orgUnitMnemonic: null,
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      locationId: 1,
      location: {
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        name: 'прропро',
        longitude: 49.11090173150742,
        latitude: 55.79166295935427,
        radius: 100,
        address: 'ул. Мусы Джалиля, 7, Казань, Респ. Татарстан, Россия, 420111',
        isDefault: true,
        orgUnitMnemonic: '1221',
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      images: [],
      chassisNumber: null,
      bodyNumber: null,
      initialCost: 0,
      residualValue: 0,
      partOfCostsMaintenanceFromInitialCost: 0,
      maintenanceCosts: 0,
      overhaulCosts: 0,
      outputRatio: 0,
      totalRepairCosts: 0,
      lastMaintenanceDate: '0001-01-01T00:00:00',
      nextMaintenancePlannedDate: '0001-01-01T00:00:00',
      maintenanceType: 0,
      overhaulDate: '2018-11-23T00:00:00',
      nextOverhaulPlannedDate: '2018-11-23T00:00:00',
      lastExpertiseDate: '0001-01-01T00:00:00',
      lastExpertisePlannedDate: '0001-01-01T00:00:00',
      certificationChangeToolsLastDate: '0001-01-01T00:00:00',
      certificationChangeToolsPlannedDate: '0001-01-01T00:00:00',
      insuranceClass: 0,
      insuranceCasesCount: 0,
      diagnosticCard: null,
      orgUnitMnemonic: null,
      parentOrgUnitMnemonic: null,
      orgUnitInn: null,
      orgUnitKpp: null,
      orgUnitOgrn: null,
      orgUnitAddress: null,
      orgUnitPhone: null,
      inventoryId: 1,
      inventoryNumber: '58745',
      costPerHourForCompanies: 0,
      validPassName: null,
      passDiscontinuedDate: '0001-01-01T00:00:00',
      ownerType: ownerTypes.self,
      passNumber: null,
      passCost: 0,
      contractorId: 0,
      id: 1
    },
    vehicleId: 1,
    geoPosition: { lat: 55.7725, lng: 49.112872 },
    id: 1,
    lastSynchronizedDate: '11.02.2018, 11:50',
    address: '',
    state: 'inmove',
    sensors: ['AT 65', 'AT Guard'],
    status: 'active'
  },
  {
    vehicle: {
      parentOrgUnitId: 1,
      parentOrgUnitName: 'Главный',
      orgUnitId: 2,
      orgUnitName: 'КЭС',
      licensePlate: '999',
      vehicleModelId: 2,
      vehicleModel: {
        name: 'Lancer',
        configuration: '2.4 AT',
        brandId: 2,
        brandName: 'Mitsubishi',
        type: 'car',
        fuelConsumption: 10,
        fuelsAndOils: [],
        technologyMap: null,
        id: 2,
        unladenMass: 0,
        grossWeight: 0,
        axlesNumber: 0,
        frontAxleLoad: null,
        backAxleLoad: null,
        dimensions: null,
        maximumAuthorizedMass: 0,
        enginePower: 0,
        passengerSeatsNumber: 0
      },
      status: 'working',
      yearIssued: 2007,
      vin: '1B6HL26Y0RW143999',
      primaryFuelType: fuelTypeEnum.ai92,
      kilometrage: 20501,
      normKilometrage: 10000,
      averageKilometrage: 0,
      averageDailyKilometrage: 0,
      kilometrageFromLastOverhaul: 0,
      pts: '1',
      ptsDate: '2018-11-19T00:00:00',
      sts: '1',
      stsDate: '2018-11-19T00:00:00',
      registrationPlace: '1',
      primaryFuelConsumption: 10,
      primaryEquipmentFuelConsumption: 7,
      fuelLevel: 10,
      osago: null,
      osagoId: null,
      osagoStartedDate: '0001-01-01T00:00:00',
      osagoFinishedDate: '0001-01-01T00:00:00',
      driverId: 1,
      driver: {
        employee: {
          orgUnitId: 2,
          orgUnitName: 'КЭС',
          firstname: 'Иван',
          lastname: 'Зинин',
          middlename: 'Абрамович',
          birthdate: '0001-01-01T00:00:00',
          personId: 2,
          isDriver: true,
          isUser: true,
          avatarUrl: null,
          avatarId: null,
          position: 'driver',
          positionTitle: null,
          protocolNumber: null,
          protocolDate: null,
          orderNumber: null,
          orderDate: null,
          documentNumber: null,
          orgUnitMnemonic: '1221',
          parentOrgUnitMnemonic: null,
          orgUnitInn: null,
          orgUnitKpp: null,
          orgUnitOgrn: null,
          orgUnitAddress: null,
          orgUnitPhone: null,
          id: 2
        },
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        status: 'free',
        licenseNumber: '55656536',
        employeeId: 2,
        orgUnitMnemonic: null,
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      locationId: 1,
      location: {
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        name: 'прропро',
        longitude: 49.11090173150742,
        latitude: 55.79166295935427,
        radius: 100,
        address: 'ул. Мусы Джалиля, 7, Казань, Респ. Татарстан, Россия, 420111',
        isDefault: true,
        orgUnitMnemonic: '1221',
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      images: [],
      chassisNumber: null,
      bodyNumber: null,
      initialCost: 0,
      residualValue: 0,
      partOfCostsMaintenanceFromInitialCost: 0,
      maintenanceCosts: 0,
      overhaulCosts: 0,
      outputRatio: 0,
      totalRepairCosts: 0,
      lastMaintenanceDate: '0001-01-01T00:00:00',
      nextMaintenancePlannedDate: '0001-01-01T00:00:00',
      maintenanceType: 0,
      overhaulDate: '0001-01-01T00:00:00',
      nextOverhaulPlannedDate: '0001-01-01T00:00:00',
      lastExpertiseDate: '0001-01-01T00:00:00',
      lastExpertisePlannedDate: '0001-01-01T00:00:00',
      certificationChangeToolsLastDate: '0001-01-01T00:00:00',
      certificationChangeToolsPlannedDate: '0001-01-01T00:00:00',
      insuranceClass: 0,
      insuranceCasesCount: 0,
      diagnosticCard: null,
      orgUnitMnemonic: '1221',
      parentOrgUnitMnemonic: null,
      orgUnitInn: null,
      orgUnitKpp: null,
      orgUnitOgrn: null,
      orgUnitAddress: null,
      orgUnitPhone: null,
      inventoryId: 2,
      inventoryNumber: 'hdfhdfhd1231231',
      costPerHourForCompanies: 10,
      validPassName: null,
      passDiscontinuedDate: '0001-01-01T00:00:00',
      ownerType: ownerTypes.self,
      passNumber: null,
      passCost: 0,
      contractorId: 0,
      id: 2
    },
    vehicleId: 2,
    geoPosition: { lat: 55.767529, lng: 49.110292 },
    id: 2,
    address: '',
    lastSynchronizedDate: '11.02.2018, 11:50',
    state: 'stopped',
    sensors: ['AT 65', 'AT Guard'],
    status: 'nonactive'
  },
  {
    vehicle: {
      parentOrgUnitId: 1,
      parentOrgUnitName: 'Главный',
      orgUnitId: 2,
      orgUnitName: 'КЭС',
      licensePlate: 'е567нр',
      vehicleModelId: 1,
      vehicleModel: {
        name: 'клинцы',
        configuration: 'кук',
        brandId: 1,
        brandName: 'маз',
        type: 'jibCrane',
        fuelConsumption: 13,
        fuelsAndOils: [],
        technologyMap: null,
        id: 1,
        unladenMass: 0,
        grossWeight: 0,
        axlesNumber: 0,
        frontAxleLoad: null,
        backAxleLoad: null,
        dimensions: null,
        maximumAuthorizedMass: 0,
        enginePower: 0,
        passengerSeatsNumber: 0
      },
      status: 'working',
      yearIssued: 2007,
      vin: '1D7HE38N08S549273',
      primaryFuelType: fuelTypeEnum.dieselFuel,
      kilometrage: 678,
      normKilometrage: 56,
      averageKilometrage: 0,
      averageDailyKilometrage: 0,
      kilometrageFromLastOverhaul: 0,
      pts: '6789',
      ptsDate: '2018-11-12T00:00:00',
      sts: '12345',
      stsDate: '2018-11-05T00:00:00',
      registrationPlace: 'зжхзжх',
      primaryFuelConsumption: 16,
      primaryEquipmentFuelConsumption: 10,
      fuelLevel: 0,
      osago: null,
      osagoId: null,
      osagoStartedDate: '0001-01-01T00:00:00',
      osagoFinishedDate: '0001-01-01T00:00:00',
      driverId: 1,
      driver: {
        employee: {
          orgUnitId: 2,
          orgUnitName: 'КЭС',
          firstname: 'Иван',
          lastname: 'Зинин',
          middlename: 'Абрамович',
          birthdate: '0001-01-01T00:00:00',
          personId: 2,
          isDriver: true,
          isUser: true,
          avatarUrl: null,
          avatarId: null,
          position: 'driver',
          positionTitle: null,
          protocolNumber: null,
          protocolDate: null,
          orderNumber: null,
          orderDate: null,
          documentNumber: null,
          orgUnitMnemonic: '1221',
          parentOrgUnitMnemonic: null,
          orgUnitInn: null,
          orgUnitKpp: null,
          orgUnitOgrn: null,
          orgUnitAddress: null,
          orgUnitPhone: null,
          id: 2
        },
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        status: 'free',
        licenseNumber: '55656536',
        employeeId: 2,
        orgUnitMnemonic: null,
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      locationId: 1,
      location: {
        orgUnitId: 2,
        orgUnitName: 'КЭС',
        name: 'прропро',
        longitude: 49.11090173150742,
        latitude: 55.79166295935427,
        radius: 100,
        address: 'ул. Мусы Джалиля, 7, Казань, Респ. Татарстан, Россия, 420111',
        isDefault: true,
        orgUnitMnemonic: '1221',
        parentOrgUnitMnemonic: null,
        orgUnitInn: null,
        orgUnitKpp: null,
        orgUnitOgrn: null,
        orgUnitAddress: null,
        orgUnitPhone: null,
        id: 1
      },
      images: [],
      chassisNumber: null,
      bodyNumber: null,
      initialCost: 0,
      residualValue: 0,
      partOfCostsMaintenanceFromInitialCost: 0,
      maintenanceCosts: 0,
      overhaulCosts: 0,
      outputRatio: 0,
      totalRepairCosts: 0,
      lastMaintenanceDate: '0001-01-01T00:00:00',
      nextMaintenancePlannedDate: '0001-01-01T00:00:00',
      maintenanceType: 0,
      overhaulDate: '0001-01-01T00:00:00',
      nextOverhaulPlannedDate: '0001-01-01T00:00:00',
      lastExpertiseDate: '0001-01-01T00:00:00',
      lastExpertisePlannedDate: '0001-01-01T00:00:00',
      certificationChangeToolsLastDate: '0001-01-01T00:00:00',
      certificationChangeToolsPlannedDate: '0001-01-01T00:00:00',
      insuranceClass: 0,
      insuranceCasesCount: 0,
      diagnosticCard: null,
      orgUnitMnemonic: '1221',
      parentOrgUnitMnemonic: null,
      orgUnitInn: null,
      orgUnitKpp: null,
      orgUnitOgrn: null,
      orgUnitAddress: null,
      orgUnitPhone: null,
      inventoryId: 9,
      inventoryNumber: '234567',
      costPerHourForCompanies: 0,
      validPassName: null,
      passDiscontinuedDate: '0001-01-01T00:00:00',
      ownerType: ownerTypes.self,
      passNumber: null,
      passCost: 0,
      contractorId: 0,
      id: 3
    },
    vehicleId: 3,
    address: '',
    geoPosition: { lat: 55.774733, lng: 49.116107 },
    id: 3,
    lastSynchronizedDate: '11.02.2018, 11:50',
    state: 'inmove',
    sensors: ['AT 65', 'AT Guard'],
    status: 'nosignal'
  }
];

export default reducer;
