// @flow

import React, { Component } from 'react';
import styled from 'styled-components';
import { placeholderCustom, searchInputCustom } from './../ui/Select';
import AntdTreeSelectSelect from 'antd/lib/tree-select';

type Props = {
  size?: string,
  dropdownStyle?: any
};

export const CustomStyledTreeSelect = styled(AntdTreeSelectSelect)`
  ${placeholderCustom} & ${searchInputCustom}
`;

export class TreeSelect extends Component<Props> {
  render() {
    const { size } = this.props;
    const Component =
      size === 'small' ? CustomStyledTreeSelect : AntdTreeSelectSelect;
    return <Component dropdownStyle={{ minWidth: '150px' }} {...this.props} />;
  }
}

export default TreeSelect;
