// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';

import { inspectionGibddApi } from '../../../lib/api';
import type { InspectionGibdd } from './../../../lib/types';

import { Icon, ListTable } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

import { COMMON_MAINTENANCE_COLUMNS } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  inspectionsGibdd: InspectionGibdd[]
};

class InspectionGibddList extends Component<Props, State> {
  state = {
    loading: false,
    inspectionsGibdd: []
  };

  async componentDidMount() {
    this.fetchInspectionGibddes();
  }

  fetchInspectionGibddes = async () => {
    try {
      this.setState({
        loading: true
      });
      const inspectionsGibdd = await inspectionGibddApi.fetchVehicleInspectionsGibdd(
        this.props.vehicleId
      );
      this.setState({
        inspectionsGibdd: inspectionsGibdd.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  onDelete = async (inspectionGibdd: InspectionGibdd) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (inspectionGibdd.id) {
        await inspectionGibddApi.deleteInspectionGibdd(inspectionGibdd.id);
        notification.success({
          message: `Технический осмотр ГИБДД успешно удален`
        });
        await this.fetchInspectionGibddes();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete } = this.props;
    const { inspectionsGibdd, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          ...COMMON_MAINTENANCE_COLUMNS,
          {
            width: '20px',
            renderRecord: (record: InspectionGibdd) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${
                      this.props.vehicleId
                    }/inspections-gibdd/${parseInt(record.id)}`}
                  >
                    <StyledAntIcon type="edit" />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null
          }
        ]}
        data={inspectionsGibdd}
      />
    );
  }
}

export default InspectionGibddList;
