// @flow

import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {Link} from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import AntIcon from 'antd/lib/icon';
import type {
  DetailedDefectiveStatement,
  Maintenance,
  MaintenanceMatrix,
  UserAccess,
  VehicleIssue,
} from '../../lib/types';
import {
  maintenanceMatrixValidActionEnum,
  maintenanceStatusesEnum,
  maintenanceStatusesTypes,
  maintenanceTypeOfWorkEnum,
  orderContractorTypeEnum,
  orderContractorTypes,
  repairPlanTypes,
  vehicleTypes,
} from '../../lib/enum';
import {detailedDefectiveStatementApi, maintenanceApi, maintenanceMatrixApi, vehicleIssueApi} from '../../lib/api';
import {saveDetailedDefectiveStatement, setDetailedDefectiveStatement} from '../../ducks/detailedDefectiveStatement';
import {setMaintenance} from '../../ducks/maintenance';
import {Card} from './../../components';
import {Panel, Section, SectionTitle} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import {applyMaskToValue, convertContractorToString, getPathWithHistoryParams, navigate} from '../../lib/helpers';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Dropdown} from '../../components/ui';
import Spoiler from './../../components/ui/Spoiler';
import {notificationLoading} from '../../components/Notifications';
import {formatLicensePlateMask} from '../../components/masked-inputs/LicensePlateInput';
import {MaintenanceEmployee} from './components';
import {VehicleIssues, VehicleIssuesReadOnly} from './VehicleIssues';
import {DetailedDefectiveStatementCard} from './DetailedDefectiveStatememt';
import {addAccessRight, canApprovingDefectiveStatements, canApprovingMaintenance} from './accessRight';
import maintenanceMatrixRules from './maintenanceMatrixRules';
import Popconfirm from 'antd/lib/popconfirm';

const {Field} = Card;

const StyledPanel = styled(Panel)`
  padding-top: 0;

  & > h1 {
    margin-bottom: 16px;
  }
`;
const Content = styled.div`
  padding: 16px;
`;
const StyledButtonDiv = styled.div`
  button {
    margin-left: 10px;
  }
`;

type Props = {
  userAccess: UserAccess[],
  maintenanceId: number,
  setDetailedDefectiveStatement: Function,
  saveDetailedDefectiveStatement: Function,
  setMaintenance: Function,
  // актуалная дефектная веломость
  detailedDefectiveStatement: ?DetailedDefectiveStatement
};

type State = {
  maintenance: ?Maintenance,
  vehicleIssues: VehicleIssue[],
  // матрица ремонтов
  maintenanceMatrix: ?MaintenanceMatrix
};

export class MaintenanceCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      maintenance: null,
      vehicleIssues: [],
      detailedDefectiveStatement: null,
      maintenanceOperationPrice: null,
      maintenanceMatrix: null,
      parts: [],
      operations: [],
    };
  }
  
  getDetailedDefectiveStatement = async (id?: number) => {
    const {maintenance} = this.state;
    const {setDetailedDefectiveStatement} = this.props;
    const maintenanceId = id ? id : maintenance ? maintenance.id : null;
    const detailedDefectiveStatement = maintenanceId
      ? await detailedDefectiveStatementApi.getActualDetailedDefectiveStatement(
        maintenanceId,
      )
      : null;
    setDetailedDefectiveStatement(detailedDefectiveStatement);
  };
  
  // проверим доступен ли параментр
  checkAllowed = (access: $Keys<MaintenanceMatrix>) => {
    const {maintenanceMatrix} = this.state;
    return (
      maintenanceMatrix &&
      maintenanceMatrix[access] !== maintenanceMatrixValidActionEnum.notAllowed
    );
  };
  
  // кнопки согласования и печать для дефектной ведомости
  getSuffixDefectiveStatement = () => {
    const {maintenance} = this.state;
    const addPrint = this.checkAllowed('printDefectiveStatementAllowed');
    const canApprove = this.canApprovingDefectiveStatements();
    const canApproveBtn = this.checkShowApproving('approvingBtnDefective');
    return (
      <>
        {maintenance && canApprove && (
          <>
            {canApproveBtn && (
              <Button
                onClick={() =>
                  this.changeStatus('maintenance')({
                    key: maintenanceStatusesEnum.approvementDefectiveStatement,
                  })
                }
                type="primary"
                style={{
                  marginRight: '10px',
                  position: 'relative',
                  top: '-2px',
                }}
              >
                На согласование
              </Button>
            )}
            {maintenance.status ===
              maintenanceStatusesEnum.approvementDefectiveStatement && (
                <Dropdown
                  overlay={
                    <Menu onClick={this.changeStatus('maintenance')}>
                      <Menu.Item
                        key={maintenanceStatusesEnum.approvedDefectiveStatement}
                      >
                        Утвердить
                      </Menu.Item>
                      <Menu.Item
                        key={maintenanceStatusesEnum.declinedDefectiveStatement}
                      >
                        Отклонить
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button>
                    <AntIcon
                      style={{fontSize: 16, color: '#2770FF'}}
                      type="ellipsis"
                    />
                  </Button>
                </Dropdown>
              )}
          </>
        )}
        {maintenance && addPrint && (
          <Button
            style={{marginLeft: '10px', position: 'relative', top: '-2px'}}
            onClick={() => this.handlePrint({key: 'printMaintenance'})}
          >
            Печать
          </Button>
        )}
      </>
    );
  };
  
  // подтянем матрицу
  getMaintenanceMatrix = async () => {
    const {maintenance} = this.state;
    try {
      if (maintenance) {
        const maintenanceMatrix = await maintenanceMatrixApi.getMaintenanceMatrixByParams(
          {
            workTypeId: maintenance.maintenanceWorkTypeId,
            orderContractorType: maintenance.orderContractorType,
            repairPlan: maintenance.repairPlan,
          },
        );
        this.setState({maintenanceMatrix});
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    }
  };
  
  withOutEmpty = (data: any[]): Array<any> =>
    data ? data.filter(item => !isEmpty(item)) : [];
  
  // валидируем дефектную ведомость
  validateVehicleIssue = (data: VehicleIssues[]) => {
    const {maintenanceMatrix} = this.state;
    const vehicleIssues = this.withOutEmpty(data);
    if (
      maintenanceMatrix &&
      maintenanceMatrix.defectiveStatementRequired ===
      maintenanceMatrixValidActionEnum.allowed &&
      isEmpty(vehicleIssues)
    ) {
      throw new Error('Заполните дефектную ведомость');
    }
  };
  
  async componentDidMount() {
    const {maintenanceId, setMaintenance} = this.props;
    if (maintenanceId) {
      try {
        const maintenance = await maintenanceApi.fetchMaintenance(
          maintenanceId,
        );
        await this.getDetailedDefectiveStatement(maintenanceId);
        // кладем maintenance в redux это нужно чтобы брать значение
        // maintenanceWorkType.workType
        setMaintenance(maintenance);
        this.setState(
          {
            maintenance,
            vehicleIssues: maintenance.vehicleIssues,
          },
          this.getMaintenanceMatrix,
        );
      } catch (err) {
        notification.error({
          message: 'Ошибка',
          description: err.message,
        });
      }
    }
  }
  
  onChange = (stateKey: string) => (value: any[]) => {
    this.setState({[stateKey]: value});
  };
  
  // сохраняем карточку работ и дефектную ведомость
  saveMaintenance = async (callback?: Function) => {
    const {maintenance, vehicleIssues} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      const issues = this.withOutEmpty(vehicleIssues);
      this.validateVehicleIssue(issues);
      //$FlowFixMe
      const updatedMaintenance = await maintenanceApi.updateMaintenance({
        ...maintenance,
        vehicleIssues: issues,
      });
      this.setState(
        {
          maintenance: updatedMaintenance,
          vehicleIssues: updatedMaintenance.vehicleIssues,
        },
        () => (callback ? callback() : null),
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
    finally {
      notification.close('saving');
    }
  };
  
  handlePrint = async ({key, vehicleIssueId}: any) => {
    const {maintenance} = this.state;
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      if (maintenance) {
        switch (key) {
          case 'printMaintenance':
            await maintenanceApi.printMaintenance(maintenance.id);
            break;
          case 'printVehicleIssue':
            await vehicleIssueApi.printVehicleIssue(vehicleIssueId);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    }
    finally {
      notification.close('printing');
    }
  };
  
  changeStatus = (stateKey: string) => async ({key, declineReason}: any) => {
    const {
      detailedDefectiveStatement,
      saveDetailedDefectiveStatement,
    } = this.props;
    const {maintenance, vehicleIssues} = this.state;
    if (maintenance) {
      try {
        notificationLoading({
          message: 'Смена статуса...',
          key: 'saving',
        });
        ![
          maintenanceStatusesEnum.declinedDefectiveStatement,
          maintenanceStatusesEnum.declinedDetailedDefectiveStatement,
          maintenanceStatusesEnum.declined,
          maintenanceStatusesEnum.created,
        ].includes(key) && this.validateVehicleIssue(vehicleIssues);
        if (
          stateKey === 'detailedDefectiveStatement' &&
          key === maintenanceStatusesEnum.declinedDetailedDefectiveStatement
        ) {
          if (detailedDefectiveStatement && detailedDefectiveStatement.id) {
            await saveDetailedDefectiveStatement({
              ...detailedDefectiveStatement,
              declineReason,
            });
            await detailedDefectiveStatementApi.deleteDetailedDefectiveStatement(
              detailedDefectiveStatement.id,
            );
            await this.getDetailedDefectiveStatement();
          }
        }
        const data = await maintenanceApi.changeStatusMaintenance(
          maintenance.id,
          key,
        );
        this.setState({
          maintenance: {...data, status: key},
        });
        if (
          (data.maintenanceWorkType.workType ===
            maintenanceTypeOfWorkEnum.to2 ||
            data.maintenanceWorkType.workType ===
            maintenanceTypeOfWorkEnum.seasonMaintenance) &&
          key === maintenanceStatusesEnum.approved
        ) {
          try {
            await maintenanceApi.fillNextToDate(data);
            notification.success({
              message: 'День следующего ТО',
              description: 'День следующего ТО успешно установлен',
            });
          } catch (error) {
            notification.error({
              message: 'Ошибка',
              description: error.message,
            });
          }
        }
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message,
        });
      }
      finally {
        notification.close('saving');
      }
    }
  };
  
  canAdd = () =>
    this.props.userAccess.some(access => addAccessRight.includes(access));
  
  canApprovingMaintenance = () =>
    this.props.userAccess.some(access =>
      canApprovingMaintenance.includes(access),
    );
  
  canApprovingDefectiveStatements = () =>
    this.props.userAccess.some(access =>
      canApprovingDefectiveStatements.includes(access),
    );
  
  getMaintenanceMatrixRules = (): any => {
    const {maintenanceMatrix} = this.state;
    const rules = maintenanceMatrixRules.find((item: any) => {
      if (maintenanceMatrix) {
        return (
          item.approvingDefectiveStatementAllowed ===
          maintenanceMatrix.approvingDefectiveStatementAllowed &&
          item.approvingDetailedStatementAllowed ===
          maintenanceMatrix.approvingDetailedStatementAllowed &&
          item.approvingMaintenanceAllowed ===
          maintenanceMatrix.approvingMaintenanceAllowed
        );
      }
      return false;
    });
    return rules
      ? rules
      : maintenanceMatrixRules[maintenanceMatrixRules.length - 1];
  };
  
  // проверим показывать или нет валидацию
  checkShowApproving(params: string) {
    const {maintenance} = this.state;
    const rules = this.getMaintenanceMatrixRules();
    if (maintenance) {
      if (Array.isArray(rules[params])) {
        return rules[params].includes(maintenance.status);
      }
      return rules[params];
    }
    return false;
  }
  
  deleteMaintenance = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await maintenanceApi.deleteMaintenance(this.props.maintenanceId);
      navigate('/maintenances');
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }
  
  render() {
    const {
      maintenance,
      vehicleIssues,
      maintenanceMatrix,
    } = this.state;
    if (maintenance === null) {
      return null;
    }
    const maintenanceRules = this.getMaintenanceMatrixRules();
    const defectiveStatementReadOnly =
      maintenance &&
      maintenanceRules.readDefectiveStatus.includes(maintenance.status);
    const detailedDefectiveStatementReadOnly =
      maintenance &&
      maintenanceRules.readDetailedStatus.includes(maintenance.status);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/maintenances')}>
                Ремонт
              </Crumb>
              {maintenance && <Crumb>Карточка работ №{maintenance.id}</Crumb>}
            </Breadcrumbs>
          }
          right={
            <StyledButtonDiv>
              {maintenance &&
                this.canAdd() &&
                maintenance.orderContractorType === orderContractorTypeEnum.contractor &&
                  maintenanceStatusesEnum.approved !== maintenance.status && (
                  <Button type="primary">
                    <Link to={`/maintenances/${maintenance.id}/order`}>
                      Создать заявку
                    </Link>
                  </Button>
                )}
              {maintenance &&
                this.checkShowApproving('approvingBtnMaintenance') &&
                this.canApprovingMaintenance() && (
                  <Dropdown
                    overlay={
                      <Menu onClick={this.changeStatus('maintenance')}>
                        <Menu.Item key={maintenanceStatusesEnum.created}>
                          В работу
                        </Menu.Item>
                        <Menu.Item key={maintenanceStatusesEnum.approved}>
                          Утвердить
                        </Menu.Item>
                        <Menu.Item key={maintenanceStatusesEnum.declined}>
                          Отклонить
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button>
                      <AntIcon
                        style={{fontSize: 16, color: '#2770FF'}}
                        type="ellipsis"
                      />
                    </Button>
                  </Dropdown>
                )
              }
              {maintenance && maintenance.status === maintenanceStatusesEnum.approved && (
                <Popconfirm
                  title="Вы уверены, что хотите редактировать карточку работ?"
                  onConfirm={() => this.changeStatus('maintenance')({key: maintenanceStatusesEnum.created})}
                >
                  <Button type='primary'>
                    Отправить на редактирование
                  </Button>
                </Popconfirm>
              )}
              {this.canAdd() && (
                <Popconfirm
                  title="Вы уверены, что хотите удалить карточку работ?"
                  onConfirm={this.deleteMaintenance}
                >
                  <Button type='primary'>Удалить</Button>
                </Popconfirm>
              )}
            </StyledButtonDiv>
          }
        />
        <StyledPanel>
          <h1>{maintenance && <>Карточка работ №{maintenance.id}</>}</h1>
          {maintenance && (
            <Grid gutter="16px" cols={6}>
              <GridItem>
                <Field label="Статус">
                  {maintenance.status
                    ? maintenanceStatusesTypes[maintenance.status]
                    : ''}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Тип">
                  {maintenance.maintenanceWorkType.name}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="График работ">
                  {repairPlanTypes[maintenance.repairPlan]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Способ выполнения">
                  {orderContractorTypes[maintenance.orderContractorType]}
                </Field>
              </GridItem>
              {maintenance.executor && (
                <GridItem>
                  <Field label="Исполнитель">{maintenance.executor.name}</Field>
                </GridItem>
              )}
              {maintenance.contractor && (
                <GridItem>
                  <Field label="Подрядчик">
                    {convertContractorToString(maintenance.contractor)}
                  </Field>
                </GridItem>
              )}
              {maintenance.contractNumber && (
                <GridItem>
                  <Field label="Договор">{maintenance.contractNumber}</Field>
                </GridItem>
              )}
              {maintenance.expenseDirection && (
                <GridItem>
                  <Field label="Направление расходов">
                    {maintenance.expenseDirection.name}
                  </Field>
                </GridItem>
              )}
              
              {maintenance.description && (
                <GridItem fullWidth style={{marginTop: '10px'}}>
                  <Field label="Примечание">{maintenance.description}</Field>
                </GridItem>
              )}
            </Grid>
          )}
        </StyledPanel>
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <Content>
            <Grid cols={3} gutter="16">
              {maintenance &&
                maintenance.vehicle &&
                maintenance.vehicle.vehicleModel && (
                  <>
                    <GridItem>
                      <Field label="Тип">
                        {vehicleTypes[maintenance.vehicle.vehicleModel.type]}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Марка">
                        {maintenance.vehicle.vehicleModel.brandName}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Модель">
                        {maintenance.vehicle.vehicleModel.name}
                      </Field>
                    </GridItem>
                  </>
                )}
              <GridItem>
                {maintenance && maintenance.vehicle.licensePlate && (
                  <Field label="Государственный регистрационный знак">
                    {applyMaskToValue(
                      maintenance.vehicle.licensePlate,
                      formatLicensePlateMask,
                    )}
                  </Field>
                )}
              </GridItem>
            </Grid>
          </Content>
        </Section>
        
        <Section>
          <Spoiler label="Ответственные лица">
            <MaintenanceEmployee
              handleSubmit={this.saveMaintenance}
              onChange={this.onChange('maintenance')}
              maintenance={maintenance}
            />
          </Spoiler>
        </Section>
        
        <Section>
          {maintenance && (
            <>
              <SectionTitle divider suffix={this.getSuffixDefectiveStatement()}>
                Предварительная дефектно-ресурсная ведомость
              </SectionTitle>
              <Content>
                {defectiveStatementReadOnly ? (
                  <VehicleIssuesReadOnly
                    handlePrint={this.handlePrint}
                    maintenanceWorkType={maintenance.maintenanceWorkType}
                    vehicleIssues={vehicleIssues}
                  />
                ) : (
                  <VehicleIssues
                    maintenanceWorkType={maintenance.maintenanceWorkType}
                    handlePrint={this.handlePrint}
                    handleSubmit={this.saveMaintenance}
                    maintenanceId={maintenance.id}
                    vehicleId={maintenance.vehicleId}
                    onChange={this.onChange('vehicleIssues')}
                    vehicleIssues={vehicleIssues}
                    filesRequired={
                      maintenanceMatrix && maintenanceMatrix.filesRequired
                    }
                    print={this.checkAllowed('printDefectiveActAllowed')}
                  />
                )}
              </Content>
            </>
          )}
        </Section>
        
        <DetailedDefectiveStatementCard
          maintenance={maintenance}
          maintenanceMatrix={maintenanceMatrix}
          detailedDefectiveStatementReadOnly={
            detailedDefectiveStatementReadOnly
          }
          changeStatus={this.changeStatus}
        />
      </>
    );
  }
}

export default connect(
  state => ({
    userAccess: state.auth.profile.access,
    detailedDefectiveStatement: state.detailedDefectiveStatement,
  }),
  {
    setDetailedDefectiveStatement,
    saveDetailedDefectiveStatement,
    setMaintenance,
  },
)(MaintenanceCard);
