// @flow
import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {Spin} from 'antd';
import InnerTable from './components/InnerTable';
import {fetchRequest, OilConsumptionApi} from '../../../../lib/api';
import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';
import PrintButton from '../../../../components/PrintButton';
import {oilConsumptionStatusEnum} from '../../../../lib/enum';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {notificationLoading} from '../../../../components/Notifications';
import {OilConsumpionType} from '../../../../lib/types/oilConsumption';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const entryPointPath = '/admin/act/internal/oil-consumption-accounting';

const Card = ({ id }) => {
  const [data, setData] = useState({ id });
  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const breadCrumbs = [
    { to: `${entryPointPath}`, name: 'Реестр первичных документов' },
    {
      to: `${entryPointPath}${data.id}`,
      name: `Реестр учёта расхода масел, документ № ${id}`
    }
  ];
  const onFetch = useCallback(async () => {
    if (!!id) {
      setIsLoading(true);
      setData(await OilConsumptionApi.get(data.id));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleDownload = async () => {
    try {
      notificationLoading({
        message: 'Загрузка данных...',
        key: 'downloading'
      });
      const result = await fetchRequest.put(`/OilConsumption/${id}/reload`);
      await setData(result);
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message
      });
    } finally {
      notification.close('downloading');
    }
  };

  const updateData = async editedData => {
    if (Object.keys(editedData).length && data.oilConsumptions) {
      data.oilConsumptions.forEach((oilConsumption: OilConsumpionType) => {
        const editedConsumption = editedData[oilConsumption.id];
        if (editedConsumption) {
          if (editedConsumption.count) {
            oilConsumption.litrQuantity = editedConsumption.count;
          }
          if (editedConsumption.oil) {
            oilConsumption.uatToParusOilType = editedConsumption.oil;
            oilConsumption.uatToParusOilTypeId = editedConsumption.oil.id;
          }
        }
      });
      try {
        notificationLoading({ message: 'Сохранение данных...', key: 'saving' });
        const result = await OilConsumptionApi.update(data);
        await setData(result);
        setEditMode(false);
        notification.success({ message: 'Данные сохранены' });
      } catch (e) {
        notification.error({
          message: 'Ошибка',
          description: e.message
        });
      } finally {
        notification.close('saving');
      }
    }
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Реестр учёта расхода масел',
        rightHeader: (
          <>
            {(data.status === oilConsumptionStatusEnum.recivied ||
              data.status === oilConsumptionStatusEnum.calculationDone) && (
              <PrintButton url={`/OilConsumption/${id}/print`} />
            )}
            {data.status === oilConsumptionStatusEnum.error && (
              <Button type="primary" onClick={handleDownload}>
                Загрузить
              </Button>
            )}
            {data.status === oilConsumptionStatusEnum.recivied && (
              <Button
                style={{ marginLeft: '10px' }}
                type="primary"
                onClick={() => setEditMode(true)}
              >
                Редактировать
              </Button>
            )}
          </>
        )
      }}
      onFetch={onFetch}
    >
      {isLoading ? (
        <Preloader>
          <Spin size={'large'} />
        </Preloader>
      ) : (
        <InnerTable
          data={data}
          useFooter
          editMode={editMode}
          updateData={updateData}
          setEditModeOff={() => setEditMode(false)}
        />
      )}
    </CommonCardPage>
  );
};

export default memo(Card);
