import React from 'react';
import { SelectProps } from 'antd/lib/select';

import type { ContractVehicle } from './../../../../../../../lib/types';
import {
  contractVehiclePlanVehicleApi,
  contractVehicleApi
} from './../../../../../../../lib/api';
import { convertContractVehicleToString } from './../../../../../../../lib/helpers';

import AutocompleteSelect from './../../../../../../../components/selects/AutocompleteSelect';

export default (
  selectProps: SelectProps & {
    contractVehiclePlanId: number,
    excludedIds?: number[]
  }
) => {
  const fetchContractVehicles = async params => {
    const contractVehiclePlanVehicles = await contractVehiclePlanVehicleApi.fetch(
      {
        ...params,
        contractVehiclePlanId: selectProps.contractVehiclePlanId,
        pageSize: undefined
      }
    );
    return {
      ...contractVehiclePlanVehicles,
      data: contractVehiclePlanVehicles.data.map(
        contractVehiclePlanVehicle => contractVehiclePlanVehicle.contractVehicle
      )
    };
  };
  const fetchContractVehicle = async id => {
    const contractVehicle = await contractVehicleApi.fetchContractVehicle(id);
    return contractVehicle;
  };
  return (
    <AutocompleteSelect
      fetch={fetchContractVehicles}
      fetchSingle={fetchContractVehicle}
      notFoundText="ТС не найдены"
      placeholder="Выберите ТС"
      renderOption={(contractVehicle: ContractVehicle, Option) => (
        <Option
          key={contractVehicle.id}
          value={contractVehicle.id}
          contractVehicle={contractVehicle}
          disabled={selectProps?.excludedIds?.includes(contractVehicle.id)}
        >
          {contractVehicle?.vehicleId
            ? convertContractVehicleToString(contractVehicle)
            : contractVehicle.vehicleTypeTariff}
        </Option>
      )}
      {...selectProps}
    />
  );
};
