// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import {getPathWithHistoryParams, navigate} from '../../lib/helpers';
import type {Contractor} from '../../lib/types/contractor';
import {addContractor, cleanContractor, fetchContractor, updateContractor} from '../../ducks/contractor';
import type {AppState} from '../../ducks/redux';
import {Panel} from './../../components/layout';
import InnerForm from './components/InnerForm';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import {notificationLoading} from './../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  contractor: Contractor,
  fetchContractor: (id: number) => Promise<void>,
  updateContractor: (contractor: Contractor) => Promise<void>,
  addContractor: Function,
  cleanContractor: Function,
  contractorId: number
};

export class Form extends Component<Props> {
  async componentDidMount() {
    const { contractorId } = this.props;
    if (contractorId) {
      try {
        await this.props.fetchContractor(contractorId);
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/admin/contractors', true);
      }
    }
  }

  componentWillUnmount() {
    this.props.cleanContractor();
  }

  onCancel = () => {
    navigate('/admin/contractors', true);
  };

  onSubmit = async (contractor: Contractor) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (contractor.id) {
        await this.props.updateContractor(contractor);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
      } else {
        await this.props.addContractor(contractor);
        notification.success({
          message: 'Успешное добавление',
          description: `Подрядчик успешно добавлен`
        });
      }
      navigate('/admin/contractors', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const { contractor } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/contractors')}>
                Подрядчики
              </Crumb>
              {contractor ? (
                <>
                  <Crumb to={`/admin/contractors/${contractor.id}`}>
                    {contractor.name}
                  </Crumb>
                </>
              ) : (
                <Crumb to="/admin/contractors/new">Новый подрядчик</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {contractor ? `Подрядчик №${contractor.id}` : 'Новый подрядчик'}
          </h1>
        </StyledPanel>
        <InnerForm
          contractor={contractor}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    contractor: state.contractor,
    contractorId: parseInt(props.contractorId, 10)
  }),
  {
    fetchContractor,
    updateContractor,
    cleanContractor,
    addContractor
  }
)(Form);
