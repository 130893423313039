import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {toLocalStringRu} from '../../../../../lib/helpers';
import {fetchRequest} from '../../../../../lib/api';
import notification from 'antd/lib/notification';
import {COGNOS_DELTA} from '../../lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50%;
`;

const Block = styled.div`
  padding: 10px;
  background-color: ${({ warning }) =>
    warning !== undefined ? (warning ? '#f58181' : '#88e288') : '#d0d0d0'};
  margin: 0 8px 8px 0;
`;

const BlockWithChilds = styled(Block)`
  display: flex;
  flex-wrap: wrap;
`;

const BlockTitle = styled.p`
  margin: 0 0 8px;
  width: 100%;
  font-weight: bold;
`;

const LittleBlock = styled.div`
  width: 45%;
  margin: 5px;
  padding: 8px;
  background-color: ${({ warning }) =>
    warning !== undefined ? (warning ? '#f58181' : '#88e288') : 'inherit'};
`;

type Props = {
  filteredDataTotalSum: any,
  controllerName: string,
  vehiclePlanId: number,
  additionalData?: any,
  setCognosData?: (number | any) => void
};

const TotalBlock = ({
  filteredDataTotalSum,
  controllerName,
  vehiclePlanId,
  additionalData,
  setCognosData
}: Props) => {
  const [values, setValues] = useState(null);

  const getTotal = async () => {
    if (controllerName) {
      try {
        const result = await fetchRequest.get(
          `/${controllerName}/getcognosvalue/${vehiclePlanId}`
        );
        // переводим значения из копеек в рубли
        Object.keys(result).forEach(key => {
          if (result[key] > 0) {
            result[key] = result[key] / 100;
          }
        });
        setValues(result);
        if (setCognosData) {
          setCognosData(result.value ?? result);
        }
      } catch (e) {
        notification.error({
          message: 'Не удалось получить итоговое значение ИСУ',
          description: e.message
        });
      }
    }
  };

  useEffect(() => {
    getTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerName]);

  const getContent = () => {
    if (!values || filteredDataTotalSum === undefined) {
      return null;
    }
    const { value: totalValue } = values;
    switch (controllerName) {
      case 'springPassCalculation':
        const { cargoPassTotal } = additionalData;
        const { cargoPassValue } = values;
        return (
          <Wrapper>
            <Block>
              Итого:{' '}
              {toLocalStringRu(filteredDataTotalSum, {
                minimumFractionDigits: 2
              })}
            </Block>
            <Block>
              Итого (негабарит):{' '}
              {toLocalStringRu(cargoPassValue, { minimumFractionDigits: 2 })}
            </Block>
            <Block>
              Итого (весенний период + негабарит):{' '}
              {toLocalStringRu(filteredDataTotalSum + cargoPassTotal, {
                minimumFractionDigits: 2
              })}
            </Block>
            <Container>
              <Block>
                Итого (Контрольные значения БК):{' '}
                {toLocalStringRu(totalValue, { minimumFractionDigits: 2 })}
              </Block>
              <Block
                warning={
                  Math.abs(filteredDataTotalSum + cargoPassTotal - totalValue) >
                  COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum + cargoPassTotal - totalValue,
                  { minimumFractionDigits: 2 }
                )}
              </Block>
            </Container>
          </Wrapper>
        );
      case 'cargoPassCalculation':
        const { springPassTotal } = additionalData;
        const { springPassValue } = values;
        return (
          <Wrapper>
            <Block>
              Итого:{' '}
              {toLocalStringRu(filteredDataTotalSum, {
                minimumFractionDigits: 2
              })}
            </Block>
            <Block>
              Итого (весенний период):{' '}
              {toLocalStringRu(springPassValue, { minimumFractionDigits: 2 })}
            </Block>
            <Block>
              Итого (весенний период + негабарит):{' '}
              {toLocalStringRu(filteredDataTotalSum + springPassTotal, {
                minimumFractionDigits: 2
              })}
            </Block>
            <Container>
              <Block>
                Итого (Контрольные значения БК):{' '}
                {toLocalStringRu(totalValue, { minimumFractionDigits: 2 })}
              </Block>
              <Block
                warning={
                  Math.abs(
                    filteredDataTotalSum + springPassTotal - totalValue
                  ) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum + springPassTotal - totalValue,
                  { minimumFractionDigits: 2 }
                )}
              </Block>
            </Container>
          </Wrapper>
        );
      case 'fuelAndOilCalculation': {
        const {
          dieselValue,
          mechanismDieselValue,
          mechanismPetrolValue,
          oilValue,
          petrolValue
        } = values;
        return (
          <Wrapper>
            <BlockWithChilds>
              <BlockTitle>Итого год:</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(filteredDataTotalSum.petrol, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(filteredDataTotalSum.diesel, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
              <LittleBlock>
                Масло:{' '}
                {toLocalStringRu(filteredDataTotalSum.oil, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (механизмы):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(mechanismPetrolValue, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(mechanismDieselValue, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (ГСМ + малая механизация):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.petrol + mechanismPetrolValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.diesel + mechanismDieselValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
              <LittleBlock>
                Масло:{' '}
                {toLocalStringRu(filteredDataTotalSum.oil, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (Контрольные значения БК):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(petrolValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock
                warning={
                  Math.abs(
                    filteredDataTotalSum.petrol +
                      mechanismPetrolValue -
                      petrolValue
                  ) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.petrol +
                    mechanismPetrolValue -
                    petrolValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
              <LittleBlock>
                ДТ: {toLocalStringRu(dieselValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock
                warning={
                  Math.abs(
                    filteredDataTotalSum.diesel +
                      mechanismDieselValue -
                      dieselValue
                  ) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.diesel +
                    mechanismDieselValue -
                    dieselValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
              <LittleBlock>
                Масло: {toLocalStringRu(oilValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock
                warning={
                  Math.abs(filteredDataTotalSum.oil - oilValue) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(filteredDataTotalSum.oil - oilValue, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
          </Wrapper>
        );
      }
      case 'fuelAndOilMechanism': {
        const {
          dieselValue,
          petrolValue,
          gsmDieselValue,
          gsmPetrolValue
        } = values;
        return (
          <Wrapper>
            <BlockWithChilds>
              <BlockTitle>Итого год:</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(filteredDataTotalSum.petrol, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(filteredDataTotalSum.diesel, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (ГСМ):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(gsmPetrolValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(gsmDieselValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (ГСМ + малая механизация):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(filteredDataTotalSum.petrol + gsmPetrolValue, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
              <LittleBlock>
                ДТ:{' '}
                {toLocalStringRu(filteredDataTotalSum.diesel + gsmDieselValue, {
                  minimumFractionDigits: 2
                })}
              </LittleBlock>
            </BlockWithChilds>
            <BlockWithChilds>
              <BlockTitle>Итого (Контрольные значения БК):</BlockTitle>
              <LittleBlock>
                Бензин:{' '}
                {toLocalStringRu(petrolValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock
                warning={
                  Math.abs(
                    filteredDataTotalSum.petrol + gsmPetrolValue - petrolValue
                  ) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.petrol + gsmPetrolValue - petrolValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
              <LittleBlock>
                ДТ: {toLocalStringRu(dieselValue, { minimumFractionDigits: 2 })}
              </LittleBlock>
              <LittleBlock
                warning={
                  Math.abs(
                    filteredDataTotalSum.diesel + gsmDieselValue - dieselValue
                  ) > COGNOS_DELTA
                }
              >
                Дельта:{' '}
                {toLocalStringRu(
                  filteredDataTotalSum.diesel + gsmDieselValue - dieselValue,
                  { minimumFractionDigits: 2 }
                )}
              </LittleBlock>
            </BlockWithChilds>
          </Wrapper>
        );
      }
      default:
        return (
          <Container>
            <Block>
              Итого:{' '}
              {toLocalStringRu(filteredDataTotalSum, {
                minimumFractionDigits: 2
              })}
            </Block>
            <Block>
              Итого (Контрольные значения БК):{' '}
              {toLocalStringRu(totalValue, { minimumFractionDigits: 2 })}
            </Block>
            <Block
              warning={
                Math.abs(filteredDataTotalSum - totalValue) > COGNOS_DELTA
              }
            >
              Дельта:{' '}
              {toLocalStringRu(filteredDataTotalSum - totalValue, {
                minimumFractionDigits: 2
              })}
            </Block>
          </Container>
        );
    }
  };

  return getContent();
};

export default TotalBlock;
