// @flow

import React, { Component } from 'react';
import Button from 'antd/lib/button';
import qs from 'query-string';
import { Link } from '@reach/router';
import Popconfirm from 'antd/lib/popconfirm';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import type {
  Platon,
  ListState,
  UserAccess,
  Vehicle
} from './../../../lib/types';
import { Section } from './../../../components/layout';
import { Table, Icon, ButtonsRow } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';
import Header from './../../../components/layout/Header';
import {
  getListInitialState,
  setQueryParams,
  convertVehicleToString,
  formatDateTimeToString,
  toLocalStringRu,
  navigate
} from './../../../lib/helpers';
import { platonApi, type FetchListParams } from './../../../lib/api';
import { withUserAccess } from './../../withUserAccess';

import { canHandleService, ACT_COLUMNS } from '../lib';

import type { PersistFilterPayload } from '../../../ducks/persistFilters';
import { setFilter } from '../../../ducks/persistFilters';
import type { AppState } from '../../../ducks/redux';

import PlatonFilter from './Filter';
import type { PlatonFilterParams } from './Filter';

type Props = {
  location: Location & { state: { page: number } },
  userAccess: UserAccess[],
  persistFilters: any,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<Platon> & {
  filterPath: string
};

export const COLUMNS = [
  {
    title: 'Марка',
    sorter: true,
    dataIndex: 'vehicle',
    render: (vehicle: Vehicle) => convertVehicleToString(vehicle)
  },
  {
    title: 'Период оказания',
    width: '150',
    render: (record: Platon) => {
      return `${formatDateTimeToString(
        record.startDate,
        'DD.MM.YYYY'
      )} - ${formatDateTimeToString(record.endDate, 'DD.MM.YYYY')}`;
    }
  },
  {
    title: 'Сумма оплаты в руб. без НДС',
    dataIndex: 'paymentAmount',
    render: (paymentAmount: number) => toLocalStringRu(paymentAmount || 0)
  }
];

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {}
  };
  state = {
    ...getListInitialState(),
    filterPath: window.location.pathname
  };
  columns = [...COLUMNS, ...ACT_COLUMNS];

  async componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    if (this.canAdd()) {
      this.columns.push({
        stopPropagation: true,
        width: 20,
        dataIndex: 'id',
        render: (id: number) => (
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={async () => this.deletePlaton(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        )
      });
    }
    await this.fetchPlatonList(page);
  }

  deletePlaton = async (id: number) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await platonApi.deletePlaton(id);
      await this.fetchPlatonList(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  fetchPlatonList = async (
    page: number = 1,
    params: FetchListParams<> = {}
  ) => {
    const { filterPath } = this.state;
    const filter = this.props.persistFilters[filterPath] || {};
    try {
      this.setState({ loading: true });
      const { data, totalCount } = await platonApi.fetchPlatons({
        ...filter,
        page,
        ...params
      });
      setQueryParams({ page });
      this.setState({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  handleRowClick = (id: number) => navigate(`/services/platons/${id}`);

  canAdd = () => canHandleService(this.props.userAccess);

  setPersistFilter = async (values: PlatonFilterParams) => {
    const { filterPath } = this.state;
    await this.props.setFilter({
      path: filterPath,
      values
    });
  };

  applyFilter = async (filter: PlatonFilterParams = {}) => {
    await this.setPersistFilter(filter);
    await this.fetchPlatonList();
  };

  cleanFilter = this.applyFilter;

  handlePrint = async () => {
    try {
      const filter = this.props.persistFilters[this.state.filterPath];
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print'
      });
      await platonApi.printReport(filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  render() {
    const { location } = this.props;
    const {
      data,
      totalCount,
      pageSize,
      page,
      loading,
      filterPath
    } = this.state;
    const filter = this.props.persistFilters[filterPath];

    return (
      <>
        <Header
          left={<h1>Список актов "Платон"</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
              {this.canAdd() && (
                <Link to="/services/platons/new">
                  <Button type="primary" data-cy="add">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <PlatonFilter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            fetch={this.fetchPlatonList}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
            loading={loading}
            columns={this.columns}
            rowKey="id"
            data={data}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    persistFilters: state.persistFilters
  }),
  {
    setFilter
  }
)(withUserAccess(List));
