// @flow
import React, { Component } from 'react';
import Input from 'antd/lib/input/Input';
import InputNumber from 'antd/lib/input-number';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import Popconfirm from 'antd/lib/popconfirm';

import type { Cargo } from './../../../lib/types';
import { Icon, ListTable } from './../../../components/ui';
import { withEmptyRow } from './../../../lib/helpers';
import { cargoApi } from '../../../lib/api';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  cargos: Cargo[],
  onChange: Function
};

/**
 * Компонент отображает список прикрепленных к заявке по доп. найму ТС грузов
 */
class CargosForm extends Component<Props> {
  static defaultProps = {
    cargos: []
  };

  onChange(index: number, key: $Keys<Cargo>, value: any) {
    let cargos = withEmptyRow([...this.props.cargos]);
    cargos.splice(index, 1, {
      ...cargos[index],
      [(key: string)]: value
    });
    this.props.onChange(cargos);
  }

  onDelete(index: number) {
    this.props.onChange(this.props.cargos.filter((value, id) => id !== index));
  }

  render() {
    const { cargos } = this.props;
    return (
      <ListTable
        columns={[
          {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: Cargo, index: number) => (
              <Input
                onChange={e => this.onChange(index, 'name', e.target.value)}
                value={name}
                name="name"
                placeholder="Введите наименование"
              />
            )
          },
          {
            title: 'Количество мест, общ',
            key: 'placeNumber',
            width: '90px',
            render: (placeNumber: number, record: Cargo, index: number) => (
              <InputNumber
                min={0}
                onChange={(value: number) =>
                  this.onChange(index, 'placeNumber', value)
                }
                value={placeNumber}
                name="placeNumber"
              />
            )
          },
          {
            title: 'Общий вес, кг',
            key: 'totalWeight',
            width: '90px',
            render: (totalWeight: number, record: Cargo, index: number) => (
              <InputNumber
                min={0}
                onChange={(value: number) =>
                  this.onChange(index, 'totalWeight', value)
                }
                value={totalWeight}
                name="totalWeight"
              />
            )
          },
          {
            title: 'Вид упаковки',
            dataIndex: 'packageType',
            key: 'packageType',
            render: (packageType: string, record: Cargo, index: number) => (
              <Input
                onChange={e =>
                  this.onChange(index, 'packageType', e.target.value)
                }
                value={packageType}
                name="packageType"
                placeholder="Введите вид упаковки"
              />
            )
          },
          {
            width: '20px',
            renderRecord: (cargo: Cargo, index: number) =>
              !isEmpty(cargo) ? (
                <Operations>
                  {cargo.id ? (
                    <Popconfirm
                      title="Вы уверены, что хотите удалить груз?"
                      onConfirm={async () => {
                        if (cargo.id) await cargoApi.deleteCargo(cargo.id);
                        this.onDelete(index);
                      }}
                    >
                      <StyledIcon type="x" />
                    </Popconfirm>
                  ) : (
                    <StyledIcon onClick={() => this.onDelete(index)} type="x" />
                  )}
                </Operations>
              ) : null
          }
        ]}
        data={withEmptyRow(cargos)}
      />
    );
  }
}

export default CargosForm;
