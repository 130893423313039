// @flow
import type {
  VehiclePlanBudget,
  VehiclePlanBudgetExpenseDirection
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken } from './index';

export const convertDataToServer = (
  vehiclePlanBudget: VehiclePlanBudget
): VehiclePlanBudget => ({
  ...vehiclePlanBudget,
  januarySum: convertFromRubToKop(vehiclePlanBudget.januarySum),
  februarySum: convertFromRubToKop(vehiclePlanBudget.februarySum),
  marchSum: convertFromRubToKop(vehiclePlanBudget.marchSum),
  aprilSum: convertFromRubToKop(vehiclePlanBudget.aprilSum),
  maySum: convertFromRubToKop(vehiclePlanBudget.maySum),
  juneSum: convertFromRubToKop(vehiclePlanBudget.juneSum),
  julySum: convertFromRubToKop(vehiclePlanBudget.julySum),
  augustSum: convertFromRubToKop(vehiclePlanBudget.augustSum),
  septemberSum: convertFromRubToKop(vehiclePlanBudget.septemberSum),
  octoberSum: convertFromRubToKop(vehiclePlanBudget.octoberSum),
  novemberSum: convertFromRubToKop(vehiclePlanBudget.novemberSum),
  decemberSum: convertFromRubToKop(vehiclePlanBudget.decemberSum),
  fullSum: convertFromRubToKop(vehiclePlanBudget.fullSum),
  expenseDirections: vehiclePlanBudget.expenseDirections
    ? vehiclePlanBudget.expenseDirections.map(
        convertVehiclePlanBudgetExpenseDirectionToServer
      )
    : []
});

export const convertDataFromServer = (
  vehiclePlanBudget: VehiclePlanBudget
): VehiclePlanBudget => ({
  ...vehiclePlanBudget,
  januarySum: convertFromKopToRub(vehiclePlanBudget.januarySum),
  februarySum: convertFromKopToRub(vehiclePlanBudget.februarySum),
  marchSum: convertFromKopToRub(vehiclePlanBudget.marchSum),
  aprilSum: convertFromKopToRub(vehiclePlanBudget.aprilSum),
  maySum: convertFromKopToRub(vehiclePlanBudget.maySum),
  juneSum: convertFromKopToRub(vehiclePlanBudget.juneSum),
  julySum: convertFromKopToRub(vehiclePlanBudget.julySum),
  augustSum: convertFromKopToRub(vehiclePlanBudget.augustSum),
  septemberSum: convertFromKopToRub(vehiclePlanBudget.septemberSum),
  octoberSum: convertFromKopToRub(vehiclePlanBudget.octoberSum),
  novemberSum: convertFromKopToRub(vehiclePlanBudget.novemberSum),
  decemberSum: convertFromKopToRub(vehiclePlanBudget.decemberSum),
  fullSum: convertFromKopToRub(vehiclePlanBudget.fullSum),
  expenseDirections: vehiclePlanBudget.expenseDirections
    ? vehiclePlanBudget.expenseDirections.map(
        convertVehiclePlanBudgetExpenseDirectionFromServer
      )
    : []
});

const convertVehiclePlanBudgetExpenseDirectionToServer = (
  vehiclePlanBudgetExpenseDirection: VehiclePlanBudgetExpenseDirection
) => ({
  ...vehiclePlanBudgetExpenseDirection,
  januarySum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.januarySum),
  februarySum: convertFromRubToKop(
    vehiclePlanBudgetExpenseDirection.februarySum
  ),
  marchSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.marchSum),
  aprilSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.aprilSum),
  maySum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.maySum),
  juneSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.juneSum),
  julySum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.julySum),
  augustSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.augustSum),
  septemberSum: convertFromRubToKop(
    vehiclePlanBudgetExpenseDirection.septemberSum
  ),
  octoberSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.octoberSum),
  novemberSum: convertFromRubToKop(
    vehiclePlanBudgetExpenseDirection.novemberSum
  ),
  decemberSum: convertFromRubToKop(
    vehiclePlanBudgetExpenseDirection.decemberSum
  ),
  fullSum: convertFromRubToKop(vehiclePlanBudgetExpenseDirection.fullSum)
});

const convertVehiclePlanBudgetExpenseDirectionFromServer = (
  vehiclePlanBudgetExpenseDirection: VehiclePlanBudgetExpenseDirection
) => ({
  ...vehiclePlanBudgetExpenseDirection,
  januarySum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.januarySum),
  februarySum: convertFromKopToRub(
    vehiclePlanBudgetExpenseDirection.februarySum
  ),
  marchSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.marchSum),
  aprilSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.aprilSum),
  maySum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.maySum),
  juneSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.juneSum),
  julySum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.julySum),
  augustSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.augustSum),
  septemberSum: convertFromKopToRub(
    vehiclePlanBudgetExpenseDirection.septemberSum
  ),
  octoberSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.octoberSum),
  novemberSum: convertFromKopToRub(
    vehiclePlanBudgetExpenseDirection.novemberSum
  ),
  decemberSum: convertFromKopToRub(
    vehiclePlanBudgetExpenseDirection.decemberSum
  ),
  fullSum: convertFromKopToRub(vehiclePlanBudgetExpenseDirection.fullSum)
});

const basedUrl = 'vehiclePlanBudget';
const vehiclePlanBudget = new CommonApi<VehiclePlanBudget>(
  basedUrl,
  'предложение бюджета CТС',
  convertDataToServer,
  convertDataFromServer
);

const print = async (vehiclePlanId: number) => {
  const printed = await downloadRequestWithToken(
    `/${basedUrl}/print/${vehiclePlanId}`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default { ...vehiclePlanBudget, print };
