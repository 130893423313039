import React, {useEffect, useState} from 'react';

import {Button, Modal} from 'antd';
import notification from 'antd/lib/notification';
import {notificationError, notificationLoading} from '../../../components/Notifications';
import {WorkAccountingTypes} from '../../../lib/types/vehicleModel';
import {TabItem, Tabs} from '../../../components/ui';
import {VehicleWorkMeterApi} from '../../../lib/api';
import {Form} from '../../../components';
import type {FormikProps} from 'formik';
import styled from 'styled-components';
import Grid, {GridItem} from '../../../components/layout/Grid';
import moment from 'moment';
import {vehicleWorkMeterTypesEnum} from '../../../lib/enum/vehicleTypes';
import TextArea from 'antd/lib/input/TextArea';
import InputNumber from 'antd/lib/input-number';
import DatePicker from 'antd/lib/date-picker';

const ButtonsBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledDatePicker = styled(DatePicker)`
  width: 50% !important;
`;

const StyledTabs = styled(Tabs)`
	margin-bottom: 20px;
	& .tabs__item {
		font-weight: bold;
	}
`;

type Props = {
  visible: boolean,
  closeModal: () => void,
  workAccountingType: ?$Keys<WorkAccountingTypes>,
  vehicleId: number,
  updateVehicle: Function,
}

export const ModalChangeWorkMeter = ({visible, closeModal, workAccountingType, vehicleId, updateVehicle}: Props) => {
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    if (workAccountingType && workAccountingType === WorkAccountingTypes.workHours) {
      setCurrentTab(vehicleWorkMeterTypesEnum.machineHoursMeter);
      return;
    }
    setCurrentTab(vehicleWorkMeterTypesEnum.odometr);
  }, []);

  return (
    <Modal
      destroyOnClose
      title="Замена прибора учета"
      visible={visible}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        initialValues={{
          newOdometrIndications: null,
          previosOdometrIndications: null,
          newMachineHoursIndications: null,
          previosMachineHoursIndications: null,
        }}
        onSubmit={async (values) => {
          values.type = currentTab;
          try {
            notificationLoading({
              message: 'Сохранение данных',
              key: 'saving',
            });
            await VehicleWorkMeterApi.addVehicleWorkMeter(vehicleId, values);
            closeModal();
            notification.success({
              message: 'Данные сохранены',
            });
            updateVehicle();
          } catch (e) {
            notificationError(e);
          } finally {
            notification.close('saving');
          }
        }}
      >
        {(FormField, formikProps: FormikProps) => {
          let {handleSubmit, setFieldValue, resetForm} = formikProps;

          return (
            <>
              <StyledTabs
                defaultTab={workAccountingType === WorkAccountingTypes.workHours
                  ? vehicleWorkMeterTypesEnum.machineHoursMeter
                  : vehicleWorkMeterTypesEnum.odometr
                }
                onChange={tab => {
                  setCurrentTab(tab);
                  resetForm();
                }}
              >
                {(workAccountingType === WorkAccountingTypes.kilometrage || workAccountingType ===
                  WorkAccountingTypes.all) && (
                  <TabItem
                    label="Одометр"
                    tabKey={vehicleWorkMeterTypesEnum.odometr}
                    style={{marginLeft: 0, paddingTop: 0}}
                  />
                )}
                {(workAccountingType === WorkAccountingTypes.workHours || workAccountingType ===
                  WorkAccountingTypes.all) && (
                  <TabItem
                    label="Счетчик маш.часов"
                    tabKey={vehicleWorkMeterTypesEnum.machineHoursMeter}
                    style={{marginLeft: 0, paddingTop: 0}}
                  />
                )}
              </StyledTabs>
              <form onSubmit={handleSubmit}>
                <Grid gutter="16px" cols={1}>
                  <GridItem>
                    <FormField label="Дата установки нового прибора учета" name="installDate" required hasFeedback={false}>
                      {({name, value}) => (
                        <StyledDatePicker
                          value={value ? moment(value).utc() : null}
                          format="DD.MM.YYYY"
                          onChange={date => setFieldValue(name, date.utc().toISOString())}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Показания демонтируемого прибора учета"
                      name={currentTab === vehicleWorkMeterTypesEnum.odometr
                        ? 'previosOdometrIndications'
                        : 'previosMachineHoursIndications'
                      }
                      required
                      hasFeedback={false}
                    >
                      {({name, value}) => (
                        <InputNumber
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          min={0}
                          style={{width: '50%'}}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Показание нового прибора учета"
                      name={currentTab === vehicleWorkMeterTypesEnum.odometr
                        ? 'newOdometrIndications'
                        : 'newMachineHoursIndications'
                      }
                      required
                      hasFeedback={false}
                    >
                      {({name, value}) => (
                        <InputNumber
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                          min={0}
                          style={{width: '50%'}}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Причина демонтажа" name="demontageReason" required hasFeedback={false}>
                      {({name, value}) => (
                        <TextArea
                          value={value}
                          onChange={({target}) => setFieldValue(name, target.value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <ButtonsBlock>
                      <Button style={{marginRight: '10px'}} onClick={closeModal}>Отменить</Button>
                      <Button type="primary" htmlType="submit">Сохранить</Button>
                    </ButtonsBlock>
                  </GridItem>
                </Grid>
              </form>
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
