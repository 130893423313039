// @flow
import React from 'react';
import {Map as LeafletMap, Marker, TileLayer} from 'react-leaflet';
import notification from 'antd/lib/notification';
import * as Leaflet from 'leaflet';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import type {Location, WayPoint} from '../../lib/types/index';
import {geocode, getCurrentPosition, MAPBOX_API_KEY} from '../../lib/gis';

const LeafletBox = {height: 364, width: '100%', minWidth: 600}; // для лифлета нужна явная высота
const bboxKey = 'bbox';

type Props = {
  onPickWaypoint: (?WayPoint) => void,
  waypoint: WayPoint,
  location: Location,
  latitude: ?string,
  longitude: ?string,
  showWaypointPicker: boolean,
  viewMode?: boolean
};

type State = {
  isLoading: boolean,
  modalVisible: boolean,
  location: ?Location,
  waypoint: ?WayPoint,
  bbox: ?string,
  latitude: ?string,
  longitude: ?string,
  keyLoc: boolean,
  showWaypointPicker: boolean
};

class WaypointPicker extends React.Component<Props, State> {
  state = {
    modalVisible: false,
    waypoint: this.props.waypoint,
    isLoading: false,
    bbox: localStorage.getItem(bboxKey),
    location: this.props.location,
    keyLoc: true,
    showWaypointPicker: this.props.showWaypointPicker,
    viewMode: this.props.viewMode
  };

  async componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.waypoint, this.props.waypoint)) {
      this.setState({
        waypoint: this.props.waypoint,
      });
    }
    if (!isEqual(prevProps.location, this.props.location)) {
      const newState = {location: this.props.location};
      if (this.state.waypoint) {
        newState.waypoint = {
          address: this.props.location.address,
          latitude: this.props.location.latitude,
          longitude: this.props.location.longitude,
          name: this.props.location.name,
          radius: this.props.location.radius,
        };
      }
      this.setState(newState);
    }
  }

  async componentDidMount(prevProps: Props) {
    if (!localStorage.getItem(bboxKey)) {
      try {
        const {
          coords: {latitude, longitude},
        } = await getCurrentPosition();
        const bbox = Leaflet.latLng(latitude, longitude)
        .toBounds(1500)
        .toBBoxString();
        localStorage.setItem(bboxKey, bbox);
      } catch {
        notification.error({
          message: 'Не удалось определить ваше местоположение',
        });
      }
    }
    if (this.props.showWaypointPicker) {
      this.setState({
        keyLoc: false,
      });
    }
  }

  onMapClick = async (event: {
    latlng: { lat: number, lng: number, toBounds: Function }
  }) => {
    if (this.props.viewMode) return;

    const {lat, lng} = event.latlng;
    localStorage.setItem(bboxKey, event.latlng.toBounds(1500).toBBoxString());

    let address = '';

    this.setState(
      (state, props) => {
        return {
          isLoading: true,
          waypoint: {
            ...state.waypoint,
            address,
            latitude: lat,
            longitude: lng,
            radius: 1,
          },
        };
      },
      async () => {
        try {
          address = await geocode(lat, lng);
        } catch (error) {
          address = 'Ошибка. Не удалось определить адрес точки.';
        } finally {
          this.setState(
            {
              isLoading: false,
              keyLoc: false,
              // $FlowFixMe
              waypoint: {
                ...this.state.waypoint,
                address,
                name: address,
              },
            },
            () => {
              this.props.onPickWaypoint(this.state.waypoint);
            },
          );
        }
      },
    );
  };

  render() {
    return (
      <div>
        <div className="leaflet-container" style={LeafletBox}>
          <Map
            onMapClick={this.onMapClick}
            bbox={this.state.bbox}
            waypoint={this.state.waypoint || {}}
            location={this.state.location || {}}
            keyLoc={this.state.keyLoc}
            viewMode={this.state.viewMode}
          />
        </div>
      </div>
    );
  }
}

/*
  Компонент врапает лифлетовскую карту
 */
const Map = ({
  onMapClick,
  waypoint,
  bbox,
  location,
  keyLoc,
  viewMode
}: {
  keyLoc: boolean,
  viewMode: boolean,
  location: Location,
  waypoint: WayPoint,
  onMapClick: Function,
  bbox: any,
  latitude: ?string,
  longitude: ?string
}) => {
  const isValidLocation =
    !isEmpty(location) && !!location.latitude && !!location.longitude;
  const isValidWaypoint =
    !isEmpty(waypoint) && !!waypoint.latitude && !!waypoint.longitude;
  /**
   * Конвертирует bbox строку в bounds
   * @param bbox Строка вида 'southwest_lng,southwest_lat,northeast_lng,northeast_lat'
   */
  function convertBBoxToBounds(bbox: string) {
    if (bbox) {
      const bboxArray = bbox.split(',');
      return Leaflet.latLngBounds(
        [bboxArray[1], bboxArray[0]],
        [bboxArray[3], bboxArray[2]],
      );
    }
  }

  // const center = [55.62302935123444, 55.75251535367876]
  // const center = this.onChangeMap([55.62302935123444, 55.75251535367876])
  const centerLocation = isValidLocation
    ? [location.latitude, location.longitude]
    : [55.62302935123444, 55.75251535367876];
  // : [location.latitude, location.longitude];
  const centerWaypoint = isValidWaypoint
    ? [waypoint.latitude, waypoint.longitude]
    : [55.62302935123444, 55.75251535367876];

  const center = keyLoc ? centerLocation : centerWaypoint;

  return (
    <LeafletMap
      leaflet={{}} // хз что это, но помечено как обязательно поле
      onClick={onMapClick}
      bounds={(isValidWaypoint || viewMode) ? null : convertBBoxToBounds(bbox)}
      style={LeafletBox}
      center={center}
      zoom={13}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id="mapbox/streets-v11"
        accessToken={MAPBOX_API_KEY}
      />

      {isValidLocation && keyLoc && (
        <Marker position={[location.latitude, location.longitude]} />
      )}

      {isValidWaypoint && (
        <Marker position={[waypoint.latitude, waypoint.longitude]} />
      )}
    </LeafletMap>
  );
};

export default WaypointPicker;

// latitude: localStorage.getItem( 'lat'),
//   longitude: localStorage.getItem( 'lng')
