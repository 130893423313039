// @flow

import React, {Component} from 'react';
import {FormikProps} from 'formik';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';

import styled from 'styled-components';
import AutoComplete from 'antd/lib/auto-complete';
import debounce from 'lodash/debounce';
import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';

import type {Cargo, ContractVehicleOrder, LoadUnloadCargo, Order, VehicleModel} from './../../lib/types';
import {contractVehicleOrderApi, orderApi, vehicleModelApi} from './../../lib/api';
import {Form, Selects, Spinner} from './../../components';
import {Panel, Section} from './../../components/layout';
import Grid, {GridItem} from './../../components/layout/Grid';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {loadUnloadTypeEnum, serviceTypeEnum, vehicleGroupEnum, vehicleTypeEnum} from './../../lib/enum';
import {notificationLoading} from '../../components/Notifications';
import {convertEmployeeToString, getPathWithHistoryParams, navigate} from '../../lib/helpers';
import LoadUnloadsForm from '../Orders/components/LoadUnloadsForm';
import CargosForm from '../Orders/components/CargosForm';
import {getLoadUnloadCargosByOrder, saveCargo, saveLoadUnloadCargo} from '../../lib/api/order';
import {VehicleOwnerTypesEnum} from '../../lib/types/vehicleModel';

const {TextArea} = Input;
const {ContractorSelect} = Selects;

const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Content = styled.div`
  padding: 16px;
`;

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type FormProps = {
  onSubmit: Function,
  contractVehicleOrder: ContractVehicleOrder,
  vehicleModelSearch: (value: string) => Promise<void>,
  vehicleModels: string[],
  order: ?$Shape<Order>,
  isCar: boolean,
  loadCargos: LoadUnloadCargo[],
  unloadCargos: LoadUnloadCargo[]
};

const InnerForm = ({
  onSubmit,
  contractVehicleOrder,
  vehicleModelSearch,
  vehicleModels,
  order,
  isCar,
  loadCargos,
  unloadCargos,
}: FormProps) => (
  <Form onSubmit={onSubmit} initialValues={contractVehicleOrder}>
    {(FormField, formikProps: FormikProps) => {
      const {
        handleSubmit,
        setFieldValue,
        handleBlur,
        isSubmitting,
      } = formikProps;
      return (
        <form onSubmit={handleSubmit}>
          <Section>
            <Content>
              {isCar ? (
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Наименование подрядной организации"
                      name="contractorId"
                    >
                      {({name, value}) => (
                        <ContractorSelect
                          name={name}
                          value={value}
                          onBlur={() =>
                            handleBlur({
                              target: {name},
                            })
                          }
                          filter={{
                            services: serviceTypeEnum.contractVehicles,
                          }}
                          onChange={id => setFieldValue(name, id)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Гос. номер" name="licensePlate">
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Руководитель" name="orgUnitHeader">
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField
                      label="Контактные лица"
                      name="contactEmployees"
                      fast
                    >
                      {field => <TextArea {...field} />}
                    </FormField>
                  </GridItem>
                </Grid>
              ) : (
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Марка ТС" name="vehicleBrandModel">
                      {({value, name}) => (
                        <AutoComplete
                          onSelect={(vehicleBrandModel: string) =>
                            setFieldValue(name, vehicleBrandModel)
                          }
                          value={value}
                          onChange={(vehicleBrandModel: string) =>
                            setFieldValue(name, vehicleBrandModel)
                          }
                          onSearch={vehicleModelSearch}
                          dataSource={vehicleModels}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Гос. номер" name="licensePlate" fast>
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField label="Водитель" name="driver">
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  {order && order.withTrailer && (
                    <>
                      <GridItem>
                        <FormField
                          label="Марка прицепа"
                          name="trailerBrandModel"
                          fast
                        >
                          {({value, name}) => (
                            <AutoComplete
                              onSelect={(vehicleBrandModel: string) =>
                                setFieldValue(name, vehicleBrandModel)
                              }
                              value={value}
                              onChange={(vehicleBrandModel: string) =>
                                setFieldValue(name, vehicleBrandModel)
                              }
                              onSearch={vehicleModelSearch}
                              dataSource={vehicleModels}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Гос. номер прицепа"
                          name="trailerLicensePlate"
                          fast
                        >
                          {field => <Input {...field} />}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  <GridItem fullWidth>
                    <FormField
                      label="Контактные лица"
                      name="contactEmployees"
                      fast
                    >
                      {field => <TextArea {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField
                      label="Груз"
                      name="cargos"
                      defaultValue={order && order.cargos}
                      fast
                    >
                      {({value, name}) => (
                        <CargosForm
                          cargos={value}
                          onChange={(cargos: Cargo[]) =>
                            setFieldValue(name, cargos)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField
                      label="Погрузка"
                      name="loadCargos"
                      defaultValue={loadCargos}
                      fast
                    >
                      {({value, name}) => (
                        <LoadUnloadsForm
                          loadUnloads={value}
                          onChange={(loadCargos: LoadUnloadCargo[]) =>
                            setFieldValue(name, loadCargos)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem fullWidth>
                    <FormField
                      label="Выгрузка"
                      name="unloadCargos"
                      defaultValue={unloadCargos}
                      fast
                    >
                      {({value, name}) => (
                        <LoadUnloadsForm
                          loadUnloads={value}
                          onChange={(unloadCargos: LoadUnloadCargo[]) =>
                            setFieldValue(name, unloadCargos)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              )}
            </Content>
          </Section>
          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              data-cy="save"
            >
              Сохранить
            </Button>
          </Footer>
        </form>
      );
    }}
  </Form>
);

type Props = {
  contractVehicleOrder: ContractVehicleOrder,
  contractVehicleOrderId: number,
  orderId: number
};

type State = {
  order: ?Order,
  initialContractOrder: $Shape<ContractVehicleOrder>,
  contractVehicleOrder: ?ContractVehicleOrder,
  vehicleModels: string[],
  loading: boolean,
  loadCargos: LoadUnloadCargo[],
  unloadCargos: LoadUnloadCargo[]
};

class ContractVehicleOrderForm extends Component<Props, State> {
  state = {
    order: null,
    initialContractOrder: {},
    vehicleModels: [],
    contractVehicleOrder: null,
    loading: false,
    loadCargos: [],
    unloadCargos: [],
  };
  async componentDidMount() {
    this.fetchContractVehicleOrder();
  }

  fetchContractVehicleOrder = async () => {
    try {
      this.setState({
        loading: true,
      });
      const {contractVehicleOrderId, orderId} = this.props;
      const parsedContractVehicleOrderId = parseInt(contractVehicleOrderId, 10);
      let parsedOrderId = parseInt(orderId, 10);

      if (parsedContractVehicleOrderId) {
        const contractVehicleOrder = await contractVehicleOrderApi.fetchContractVehicleOrder(
          parsedContractVehicleOrderId,
        );
        parsedOrderId = contractVehicleOrder.orderId;
        this.setState({
          contractVehicleOrder,
        });
      }
      if (parsedOrderId > 0) {
        const order = await orderApi.fetchOrder(parsedOrderId);
        const {load, unload} = getLoadUnloadCargosByOrder(order);
        this.setState({
          order,
          loadCargos: load,
          unloadCargos: unload,
        });
        if (order && !parsedContractVehicleOrderId) {
          const contactEmployees = convertEmployeeToString(order.employee);
          this.setState({
            initialContractOrder: {
              contactEmployees,
            },
          });
        }
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  isCar = (order: ?Order) =>
    !!order &&
    ([vehicleTypeEnum.car, vehicleTypeEnum.legkovoiPolnopryvodnyi].includes(
        order.vehicleType,
      ) ||
      order.vehicleGroup === vehicleGroupEnum.cars);

  handleSearch = debounce(async (searchString: string) => {
    const vehicleModels = await vehicleModelApi.fetchVehicleModels({
      search: searchString,
      page: 1,
      pageSize: 50,
      vehicleOwnerType: VehicleOwnerTypesEnum.contract,
    });
    if (
      searchString &&
      vehicleModels &&
      vehicleModels.data &&
      vehicleModels.data.length
    ) {
      this.setState({
        vehicleModels: vehicleModels.data.map(
          (item: VehicleModel) => `${item.brandName} ${item.name}`,
        ),
      });
    } else {
      this.setState({
        vehicleModels: [],
      });
    }
  }, 500);

  render() {
    const {contractVehicleOrderId} = this.props;
    const {
      order,
      initialContractOrder,
      vehicleModels,
      contractVehicleOrder,
      loading,
    } = this.state;
    const orderId = parseInt(
      contractVehicleOrder ? contractVehicleOrder.orderId : order && order.id,
    );
    const initialValues = contractVehicleOrder || initialContractOrder;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/orders')}>Заявки</Crumb>
              {orderId > 0 && (
                <Crumb to={`/orders/${orderId}`}>Заявка №{orderId}</Crumb>
              )}
              {contractVehicleOrderId ? (
                <Crumb>Заявка на доп. найм ТС №{contractVehicleOrderId}</Crumb>
              ) : (
                <Crumb>Новая заявка на доп. найм ТС</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {contractVehicleOrderId > 0
              ? `Заявка на доп. найм ТС №${contractVehicleOrderId}`
              : 'Новая заявка на доп. найм ТС'}
          </h1>
        </StyledPanel>
        {loading ? (
          <Section>
            <Content>
              <Spinner isLoading={loading} />
            </Content>
          </Section>
        ) : (
          <InnerForm
            contractVehicleOrder={initialValues}
            vehicleModels={vehicleModels}
            vehicleModelSearch={this.handleSearch}
            order={order}
            loadCargos={this.state.loadCargos}
            unloadCargos={this.state.unloadCargos}
            isCar={this.isCar(order)}
            onSubmit={async values => {
              let {cargos, loadCargos, unloadCargos} = values;

              cargos = reject(cargos, isEmpty).map(cargo => ({
                ...cargo,
                orderId,
              }));
              loadCargos = reject(loadCargos, isEmpty).map(cargo => ({
                ...cargo,
                orderId,
                type: loadUnloadTypeEnum.load,
              }));
              unloadCargos = reject(unloadCargos, isEmpty).map(cargo => ({
                ...cargo,
                orderId,
                type: loadUnloadTypeEnum.unload,
              }));

              try {
                notificationLoading({
                  message: 'Сохранение данных...',
                  key: 'saving',
                });
                // Сохранение данных по грузам
                await Promise.all(cargos.map(saveCargo));
                await Promise.all(
                  [...loadCargos, ...unloadCargos].map(saveLoadUnloadCargo),
                );
                if (values.id) {
                  await contractVehicleOrderApi.updateContractVehicleOrder(
                    values,
                  );
                } else {
                  await contractVehicleOrderApi.addContractVehicleOrder({
                    ...values,
                    orderId,
                  });
                }
                notification.success({
                  message: 'Успешно сохранено',
                  description: 'Изменения успешно сохранены',
                });
                navigate(`/orders/${orderId}`);
              } catch (error) {
                notification.error({
                  message: 'Ошибка',
                  description: error.message,
                });
              } finally {
                notification.close('saving');
              }
            }}
          />
        )}
      </>
    );
  }
}

export default ContractVehicleOrderForm;
