// @flow
import Column from 'antd';
import Button from 'antd/lib/button';
import AntIcon from 'antd/lib/icon';
import notification from 'antd/lib/notification';
import React, { Component } from 'react';
import styled from 'styled-components';

import { Section, SectionTitle } from '../../../../components/layout';
import { notificationLoading } from '../../../../components/Notifications';
import Table from '../../../../components/ui/Table';

import {
  additionalCalculationApi,
  batteryCalculationApi,
  cargoPassCalculationApi,
  currentRepairCalculationApi,
  driverInspectionCalculationApi,
  federalHighwayCalculationApi,
  fuelsAndOilsCalculationApi,
  fuelsAndOilsMechanismApi,
  gpmMaintenanceCalculationApi,
  inspectionGibddCalculationApi,
  inspectionGtnCalculationApi,
  licensePlateChangeCalculationApi,
  maintenanceOperationContractCalculationApi,
  materialCalculationApi,
  measuringDeviceCertificationCalculationApi,
  overhaulCalculationApi,
  repairBudgetApi,
  springPassCalculationApi,
  tireCalculationApi,
  vehicleMonitoringCalculationApi,
  vehiclePlanApi,
  vehiclePlanBudgetApi,
  washingCalculationApi
} from '../../../../lib/api';
import {
  budgetLineItemBudgetInline,
  calculationStatusEnum,
  entityStatusEnum,
  vehiclePlanStatusEnum
} from '../../../../lib/enum';
import {
  getListInitialState,
  navigate,
  plus,
  toLocalStringRu
} from '../../../../lib/helpers';
import type {
  BudgetLineItemBudgetType,
  ListState,
  UserAccess,
  VehiclePlan,
  VehiclePlanBudget
} from '../../../../lib/types';
import type { BudgetSummaryMonth } from '../../../../lib/types/budgetSummary';

import { commonHeaderPanel } from '../lib';
import { canApprovingBudgetVehiclePlan } from '../accessRight';
import { withUserAccess } from '../../../withUserAccess';

const StyledTable = styled(Table)`
  .ant-table-row-colored {
    background: #cccccc;
  }

  // Фикс для хрома
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    text-overflow: initial;
  }
`;

const NAME_MONTH_SUM = [
  { name: 'fullSum', title: 'Итого' },
  { name: 'januarySum', title: 'Январь' },
  { name: 'februarySum', title: 'Февраль' },
  { name: 'marchSum', title: 'Март' },
  { name: 'firstItog', title: 'Итого за I кв.' },
  { name: 'aprilSum', title: 'Апрель' },
  { name: 'maySum', title: 'Май' },
  { name: 'juneSum', title: 'Июнь' },
  { name: 'secondItog', title: 'Итого за II кв.' },
  { name: 'julySum', title: 'Июль' },
  { name: 'augustSum', title: 'Август' },
  { name: 'septemberSum', title: 'Сентябрь' },
  { name: 'thirdItog', title: 'Итого за III кв.' },
  { name: 'octoberSum', title: 'Октябрь' },
  { name: 'novemberSum', title: 'Ноябрь' },
  { name: 'decemberSum', title: 'Декабрь' },
  { name: 'fourthItog', title: 'Итого за IV кв.' }
];

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<VehiclePlanBudget> & {
  columns: Column[],
  vehiclePlan: VehiclePlan
};

const List = class extends Component<Props, State> {
  whiteSpaceWrapColumn = (data: string) => {
    return (
      <div style={{ whiteSpace: 'pre-line', overflow: 'auto' }}>
        {toLocalStringRu(data, { minimumFractionDigits: 2 })}
      </div>
    );
  };

  state = {
    ...getListInitialState(),
    vehiclePlan: null,
    columns: [
      {
        dataIndex: 'budgetLineItemName',
        title: 'Статья бюджетной формы',
        key: 'budgetLineItemName',
        width: 250,
        fixed: true,
        render: this.whiteSpaceWrapColumn
      },
      {
        dataIndex: 'expenseDirectionName',
        title: 'Направление расходов',
        key: 'expenseDirectionName',
        width: 250,
        fixed: true,
        render: (expenseDirectionName: string) => (
          <>{this.whiteSpaceWrapColumn(expenseDirectionName)}</>
        )
      },
      ...NAME_MONTH_SUM.map(col => ({
        dataIndex: col.name,
        title: col.title,
        key: col.name,
        width: 85,
        render: this.whiteSpaceWrapColumn
      }))
    ]
  };

  approved = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Утверждаем...',
        key: 'changeStatus'
      });
      await vehiclePlanApi.changeStatus({
        status: vehiclePlanStatusEnum.approved,
        id: vehiclePlanId
      });
      this.getData();
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('changeStatus');
    }
  };

  print = async () => {
    const { vehiclePlanId } = this.props;
    try {
      notificationLoading({
        message: 'Печать...',
        key: 'print'
      });
      await vehiclePlanBudgetApi.print(vehiclePlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  declined = async () => {
    const { vehiclePlanId } = this.props;
    // const { vehiclePlan } = this.state;
    try {
      notificationLoading({
        message: 'Отклоняем...',
        key: 'changeStatus'
      });
      let vehiclePlan = await vehiclePlanApi.changeStatus({
        status: vehiclePlanStatusEnum.created,
        id: vehiclePlanId
      });
      vehiclePlan = await vehiclePlanApi.update({
        ...vehiclePlan,
        budgetStatus: calculationStatusEnum.draft
      });
      // $FlowFixMe
      this.setState({
        vehiclePlan
      });

      await batteryCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await gpmMaintenanceCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await vehicleMonitoringCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await measuringDeviceCertificationCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await inspectionGtnCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await inspectionGibddCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await licensePlateChangeCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await materialCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await washingCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await driverInspectionCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await federalHighwayCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await springPassCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await cargoPassCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await fuelsAndOilsCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );

      await fuelsAndOilsMechanismApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await tireCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await maintenanceOperationContractCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await currentRepairCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );
      await overhaulCalculationApi.changeStatus(
        vehiclePlanId,
        calculationStatusEnum.draft
      );

      const repairBudget = await repairBudgetApi.getByVehiclePlan(
        vehiclePlanId
      );
      await repairBudgetApi.update({
        ...repairBudget,
        status: entityStatusEnum.created
      });

      await additionalCalculationApi.changeStatus({
        status: calculationStatusEnum.draft,
        vehiclePlanId
      });
      navigate(`/budget/vehicle/${vehiclePlanId}/card`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('changeStatus');
    }
  };

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { vehiclePlanId } = this.props;
    this.setState({ loading: true });
    try {
      let { data } = await vehiclePlanBudgetApi.fetch({
        vehiclePlanId,
        page: undefined,
        pageSize: undefined
      });
      const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
      let itogData: Map<
        BudgetLineItemBudgetType,
        BudgetSummaryMonth & {
          itogAfterLine: number
        }
      > = new Map();

      data = data.map((budget: VehiclePlanBudget, index) => {
        const budgetLine = {
          ...budget,
          rowId: index,
          firstItog: plus(
            plus(budget.januarySum, budget.februarySum),
            budget.marchSum
          ),
          secondItog: plus(
            plus(budget.aprilSum, budget.maySum),
            budget.juneSum
          ),
          thirdItog: plus(
            plus(budget.julySum, budget.augustSum),
            budget.septemberSum
          ),
          fourthItog: plus(
            plus(budget.octoberSum, budget.novemberSum),
            budget.decemberSum
          ),
          expenseDirections: budget.expenseDirections.map(
            (expenseDirection, index2) => ({
              ...expenseDirection,
              rowId: index * 1000 + index2,
              firstItog: plus(
                plus(expenseDirection.januarySum, expenseDirection.februarySum),
                expenseDirection.marchSum
              ),
              secondItog: plus(
                plus(expenseDirection.aprilSum, expenseDirection.maySum),
                expenseDirection.juneSum
              ),
              thirdItog: plus(
                plus(expenseDirection.julySum, expenseDirection.augustSum),
                expenseDirection.septemberSum
              ),
              fourthItog: plus(
                plus(expenseDirection.octoberSum, expenseDirection.novemberSum),
                expenseDirection.decemberSum
              )
            })
          ),
          expenseDirectionName: 'Итого'
        };
        // Формируем итог по месяцам
        let branchBudget = itogData.get(budget.budgetLineItemBudgetType);
        const itogRow = {};

        NAME_MONTH_SUM.forEach(monthNameSum => {
          itogRow[monthNameSum.name] = plus(
            branchBudget?.[monthNameSum.name] ?? 0,
            budgetLine[monthNameSum.name] ?? 0
          );
        });
        itogData.set(budget.budgetLineItemBudgetType, {
          itogAfterLine: index,
          ...itogRow
        });

        return budgetLine;
      });

      const itogRow = data.reduce(
        (row, dataRow) => {
          NAME_MONTH_SUM.forEach(monthNameSum => {
            row[monthNameSum.name] = plus(
              row[monthNameSum.name] ?? 0,
              dataRow[monthNameSum.name] ?? 0
            );
          });
          return row;
        },
        { budgetLineItemName: 'ИТОГО:' }
      );
      if (data.length > 0) {
        let rowId = 0;
        for (let [budgetLineItemBudgetName, branchBudget] of itogData) {
          rowId--;
          // Не выводим undefined
          if (budgetLineItemBudgetName)
            // Вставляем итог с учетом уже вставленных итогов
            data.splice(branchBudget.itogAfterLine - rowId, 0, {
              ...branchBudget,
              budgetLineItemName:
                'Итого по ' +
                budgetLineItemBudgetInline[budgetLineItemBudgetName],
              rowId
            });
        }
        rowId--;
        data.push({ ...itogRow, rowId });
      }

      this.setState({ data: [...data], vehiclePlan });
    } catch (error) {
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { vehiclePlanId } = this.props;
    const { loading, data, vehiclePlan } = this.state;
    return (
      <>
        {commonHeaderPanel({
          title: 'Итоговый бюджет СТС для филиала',
          vehiclePlanId,
          vehiclePlan,
          right: vehiclePlan?.status !== vehiclePlanStatusEnum.approved &&
            vehiclePlan?.status !== vehiclePlanStatusEnum.approvedAfterCognos &&
            canApprovingBudgetVehiclePlan(this.props.userAccess) && (
              <>
                <Button
                  onClick={this.approved}
                  type="primary"
                  style={{ marginRight: 16 }}
                >
                  Утвердить
                </Button>
                <Button onClick={this.declined}>Отклонить</Button>
              </>
            )
        })}
        <Section>
          <SectionTitle
            suffix={
              <>
                <Button onClick={this.print} type="primary">
                  Печать
                </Button>
              </>
            }
          />
          <StyledTable
            loading={loading}
            data={data}
            columns={this.state.columns}
            childrenColumnName="expenseDirections"
            expandRowByClick
            expandIconAsCell={false}
            expandIconColumnIndex={1}
            expandIcon={row =>
              !!row.record.expenseDirections && (
                <AntIcon
                  type={row.expanded ? 'up' : 'down'}
                  style={{
                    fontSize: '12px',
                    float: 'right'
                  }}
                />
              )
            }
            bordered
            rowKey="rowId"
            style={{
              overflow: 'auto'
            }}
            scroll={{
              y: 'calc(100vh - 310px)',
              x: 'auto'
            }}
            rowClassName={record => {
              return record.rowId < 0 ? 'ant-table-row-colored' : null;
            }}
          />
        </Section>
      </>
    );
  }
};

export default withUserAccess(List);
