// @flow
import React from 'react';
import Table from 'antd/lib/table/Table';
import moment from 'moment';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import qs from 'query-string';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import Header from '../../../components/layout/Header';
import { Section } from '../../../components/layout';
import { applyMaskToValue } from './../../../lib/helpers';
import Filter from './components/Filter';
import type {
  ReportDriver,
  ReportDriversParams
} from '../../../lib/types/reportDrivers';
import { reportDriversApi } from '../../../lib/api';
import { notificationLoading } from '../../../components/Notifications';
import type { AppState } from '../../../ducks/redux';
import { formatLicensePlateMask } from '../../../components/masked-inputs/LicensePlateInput';

const initialFilter: ReportDriversParams = {
  startDate: moment
    .utc()
    .startOf('month')
    .toISOString(),
  endDate: moment
    .utc()
    .endOf('month')
    .toISOString()
};

type State = {
  filter: ReportDriversParams,
  loading: boolean,
  data: ReportDriver[]
};

type Props = {
  employeeBranchOrgUnitId: number
};

/**
 * Отчет о водителях
 */
export class DriversList extends React.Component<Props, State> {
  state = {
    filter: initialFilter,
    loading: false,
    data: []
  };

  async componentDidMount() {
    const { ...filter } = qs.parse(window.location.search);
    this.setState(
      { filter: isEmpty(filter) ? initialFilter : filter },
      this.fetchReportDrivers
    );
  }

  applyFilter = (filter: ReportDriversParams) =>
    this.setState({ filter }, this.fetchReportDrivers);

  cleanFilter = () =>
    this.setState({ filter: initialFilter }, this.fetchReportDrivers);

  fetchReportDrivers = async () => {
    const { filter } = this.state;
    try {
      this.setState({ loading: true });
      const data = await reportDriversApi.fetchReportDrivers(filter);
      this.setState({ data });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла отчета...',
        key: 'print'
      });
      await reportDriversApi.printReportDrivers(this.state.filter);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  columns = [
    {
      title: 'ФИО',
      dataIndex: 'fullName',
      fixed: 'left'
    },
    {
      title: 'Подразделение',
      dataIndex: 'orgUnitName'
    },
    {
      title: 'Тип ТС',
      dataIndex: 'type',
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        )
    },
    {
      title: 'Марка',
      dataIndex: 'brandName',
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        )
    },
    {
      title: 'Гос. номер',
      dataIndex: 'licensePlate',
      render: (value: string) =>
        value && (
          <ul>
            {value.split(',').map((licensePlate, index) => (
              <li key={index}>
                {applyMaskToValue(licensePlate, formatLicensePlateMask)}
              </li>
            ))}
          </ul>
        )
    },
    {
      title: 'Кол-во выписанных ПЛ (план)',
      dataIndex: 'plannedTripsCount',
      render: (value: number) => `${value} шт.`
    },
    {
      title: 'Кол-во выписанных ПЛ (факт)',
      dataIndex: 'actualTripsCount',
      render: (value: number) => `${value} шт.`
    },
    {
      title: 'Количество отработанных часов',
      dataIndex: 'workedTime',
      render: (workTime: number) => `${workTime} ч.`
    }
  ];

  render() {
    const { filter, data, loading } = this.state;
    const { employeeBranchOrgUnitId } = this.props;
    return (
      <>
        <Header
          left={<h1>Отчет о водителях</h1>}
          right={
            <Button type="primary" onClick={this.handleExport}>
              Экспорт
            </Button>
          }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
            employeeBranchOrgUnitId={employeeBranchOrgUnitId}
          />
          <Table
            pagination={false}
            loading={loading}
            dataSource={data}
            columns={this.columns}
            rowKey="driverId"
          />
        </Section>
      </>
    );
  }
}

export default connect((state: AppState) => ({
  employeeBranchOrgUnitId: state.auth.profile.employeeBranchOrgUnitId
}))(DriversList);
