// @flow
import { downloadRequestWithToken, fetchRequest } from './index';
import type {
  ReportTaxedTrip,
  ReportTaxedTripsParams
} from '../types/reportTaxedTrips';
import { convertFromMToKm } from '../helpers';

const convertFromServer = (reportItem: ReportTaxedTrip) => ({
  ...reportItem,
  kilometrage: convertFromMToKm(reportItem.kilometrage)
});

/**
 * Получение отчета
 */
export const fetchReportTaxedTrips = async (
  params: ReportTaxedTripsParams
): Promise<ReportTaxedTrip[]> => {
  const response = await fetchRequest.get('/report/taxed-trips/regular/', {
    ...params
  });
  return {
    data: response.data.map(convertFromServer),
    totalCount: response.totalCount
  };
};

export const printReportTaxedTrips = async (
  params: ReportTaxedTripsParams
): Promise<boolean> => {
  const isPrinted = await downloadRequestWithToken(
    '/report/taxed-trips/regular/print',
    {
      ...params
    }
  );
  if (isPrinted) return true;
  throw new Error('Не удалось загрузить отчет');
};

export default {
  fetchReportTaxedTrips,
  printReportTaxedTrips
};
