// @flow
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Upload from 'antd/lib/upload';
import { FormikProps } from 'formik';
import range from 'lodash/range';
import * as React from 'react';
import { Form } from '../../../../components';
import { notificationLoading } from '../../../../components/Notifications';
import { Grid, GridItem } from '../../../../components/layout';
import { MonthSelect } from '../../../../components/selects/AutoGeneratedEnumSelects';
import Select from '../../../../components/ui/Select';
import { ContractorBranchLinkApi, apiUrl } from '../../../../lib/api';
import tokenManager from '../../../../lib/tokenManager';
import type { User } from '../../../../lib/types';
import { ContractTripMonth } from '../../../../lib/types/contractTrips';
import { withUserAccess } from '../../../withUserAccess';

const {Option} = Select;

type FileData = {
  branchId: number,
  contractorId: number,
  file: any,
  month: string,
  year: number
};

type Props = {
  visible: boolean,
  onClose: Function,
  userProfile: User,
  employeeBranchOrgUnitId: number,
  updateTripList: Function,
  vehicleList: ContractTripMonth[],
};

type State = {
  data: FileData,
  contractorName: ?string,
  disableSubmitButton: boolean,
};

class FileUploadModal extends React.Component<Props, State> {
  state = {
    data: {},
    contractorName: null,
    disableSubmitButton: false,
  };
  
  async componentDidMount() {
    try {
      const {data} = await ContractorBranchLinkApi.fetch({
        branchId: this.props.employeeBranchOrgUnitId,
      });
      if (data?.length) {
        this.setState({
          data: {
            branchId: this.props.employeeBranchOrgUnitId,
            contractorId: data[0].contractorId,
          },
          contractorName: data[0].contractorName,
        });
      }
    } catch (e) {
      notification.error({
        message: 'Не удалось получить данные подрядчика',
        description: e.message || e.title,
      });
    }
  }
  
  render() {
    const {onClose, visible, userProfile, updateTripList} = this.props;
    const {orgUnitName} = userProfile.employee;
    const {data, contractorName, disableSubmitButton} = this.state;
    const years = range(new Date().getFullYear() - 1, new Date().getFullYear() + 5);
    const checkDoubles = (values) => {
      const hasDouble = this.props.vehicleList.some(item => item.month === values.month && item.year === values.year &&
      item.orgUnitId === values.branchId);
      if (hasDouble) {
        notification.error({message: 'В АС «Транспорт» уже существует реестр ПЛ НТС на данный период'});
      }
      return hasDouble;
    };
  
    return (
      <Modal
        onCancel={onClose}
        visible={visible}
        destroyOnClose
        width={600}
        title="Загрузка реестра ПЛ НТС"
        footer={false}
      >
        {data.contractorId ? (
          <Form initialValues={data} onSubmit={false}>
            {(FormField, formikProps: FormikProps) => {
              const {setFieldValue, values} = formikProps;
              return (
                <form onSubmit={formikProps.handleSubmit}>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField label="Филиал">{() => orgUnitName}</FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Подрядчик">
                        {() => contractorName}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Месяц" name="month" required>
                        {({name, value}) => (
                          <MonthSelect
                            value={value}
                            onChange={value => setFieldValue(name, value)}
                            size="small"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField label="Год" name="year" required>
                        {({name, value}) => (
                          <Select
                            placeholder="Выберите год"
                            allowClear
                            value={value}
                            size="small"
                            onChange={(value: string) =>
                              setFieldValue(name, value)
                            }
                          >
                            {years.map(year => (
                              <Option key={year} value={year}>
                                {year}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <Upload
                        accept=".xlsx"
                        showUploadList={false}
                        action={`${apiUrl}/ContractTripMonth/import`}
                        data={values}
                        headers={{
                          Authorization: `Bearer ${tokenManager.getToken()}`,
                        }}
                        beforeUpload={() => {
                          this.setState({disableSubmitButton: true});
                          notificationLoading({
                            message: 'Сохранение данных',
                            key: 'sending',
                          });
                        }}
                        onChange={({file}) => {
                          const {status, response} = file;
                          switch (status) {
                            case 'done':
                              notification.close('sending');
                              notification.success({
                                message: 'Данные сохранены',
                                description: response,
                                duration: 8,
                              });
                              this.setState({disableSubmitButton: false});
                              onClose();
                              updateTripList();
                              break;
                            case 'error':
                              notification.close('sending');
                              notification.error({
                                message: 'Ошибка',
                                description: response.title || response.message,
                                duration: null,
                              });
                              this.setState({disableSubmitButton: false});
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        <Button
                          disabled={disableSubmitButton}
                          htmlType="submit"
                          type="primary"
                          onClick={ev => {
                            if (!values.month || !values.year || checkDoubles(values)) {
                              ev.stopPropagation();
                            }
                          }}
                        >
                          Выбрать файл и отправить данные
                        </Button>
                      </Upload>
                    </GridItem>
                  </Grid>
                </form>
              );
            }}
          </Form>
        ) : (
          <p>У филиала не задана связка с подрядчиком</p>
        )}
      </Modal>
    );
  }
}

export default withUserAccess(FileUploadModal);
