// @flow
import type {Assignment, BranchBudgetSummary, BudgetSummaryStatusType} from '../types';
import CommonApi from './../commonApi';
import {convertFromKopToRub} from '../helpers';
import {downloadRequestWithToken, fetchRequest} from './index';

const convertDataFromServer = (branchBudgetSummary: BranchBudgetSummary) => ({
  ...branchBudgetSummary,
  budgetSummaryLineItems: branchBudgetSummary.budgetSummaryLineItems.map(
    budgetSummaryLineItem => {
      return {
        ...budgetSummaryLineItem,
        sum: convertFromKopToRub(budgetSummaryLineItem.sum),
        factSum: convertFromKopToRub(budgetSummaryLineItem.factSum),
        economyResidue: convertFromKopToRub(budgetSummaryLineItem.economyResidue),
        economy: convertFromKopToRub(budgetSummaryLineItem.economy),
        newCalculation: convertFromKopToRub(budgetSummaryLineItem.newCalculation),
        additionalNeed: convertFromKopToRub(budgetSummaryLineItem.additionalNeed),
        coveredByOpenPeriod: convertFromKopToRub(budgetSummaryLineItem.coveredByOpenPeriod),
        coveredByClosedPeriod: convertFromKopToRub(budgetSummaryLineItem.coveredByClosedPeriod),
        economyResidueFromOpenPeriod: convertFromKopToRub(budgetSummaryLineItem.economyResidueFromOpenPeriod),
        economyResidueFromClosedPeriod: convertFromKopToRub(budgetSummaryLineItem.economyResidueFromClosedPeriod),
        fundsAllocation: convertFromKopToRub(budgetSummaryLineItem.fundsAllocation),
        budgetChange: convertFromKopToRub(budgetSummaryLineItem.budgetChange),
        months: budgetSummaryLineItem.months?.map(month => ({
          ...month,
          writeOffSum: convertFromKopToRub(month.writeOffSum),
          plan: convertFromKopToRub(month.plan),
          need: convertFromKopToRub(month.need),
          fact: convertFromKopToRub(month.fact),
          economyResidue: convertFromKopToRub(month.economyResidue),
          economy: convertFromKopToRub(month.economy),
        })) ?? [],
        cognosFundsAllocation: convertFromKopToRub(budgetSummaryLineItem.cognosFundsAllocation ?? 0),
        cognosClosePeriodEconomy: convertFromKopToRub(budgetSummaryLineItem.cognosClosePeriodEconomy ?? 0),
        cognosOpenPeriodEconomy: convertFromKopToRub(budgetSummaryLineItem.cognosOpenPeriodEconomy ?? 0),
        expenseDirections: budgetSummaryLineItem?.expenseDirections?.map(
          expenseDirection => ({
            ...expenseDirection,
            sum: convertFromKopToRub(expenseDirection.sum),
            factSum: convertFromKopToRub(expenseDirection.factSum),
            economyResidue: convertFromKopToRub(expenseDirection.economyResidue),
            economy: convertFromKopToRub(expenseDirection.economy),
            newCalculation: convertFromKopToRub(expenseDirection.newCalculation),
            additionalNeed: convertFromKopToRub(expenseDirection.additionalNeed),
            coveredByOpenPeriod: convertFromKopToRub(expenseDirection.coveredByOpenPeriod ?? 0),
            coveredByClosedPeriod: convertFromKopToRub(expenseDirection.coveredByClosedPeriod),
            economyResidueFromOpenPeriod: convertFromKopToRub(expenseDirection.economyResidueFromOpenPeriod),
            economyResidueFromClosedPeriod: convertFromKopToRub(expenseDirection.economyResidueFromClosedPeriod),
            fundsAllocation: convertFromKopToRub(expenseDirection.fundsAllocation),
            budgetChange: convertFromKopToRub(expenseDirection.budgetChange),
            cognosFundsAllocation: convertFromKopToRub(expenseDirection.cognosFundsAllocation ?? 0),
            cognosClosePeriodEconomy: convertFromKopToRub(expenseDirection.cognosClosePeriodEconomy ?? 0),
            cognosOpenPeriodEconomy: convertFromKopToRub(expenseDirection.cognosOpenPeriodEconomy ?? 0),
            months:
              expenseDirection.months?.map(month => ({
                ...month,
                writeOffSum: convertFromKopToRub(month.writeOffSum),
                plan: convertFromKopToRub(month.plan),
                need: convertFromKopToRub(month.need),
                fact: convertFromKopToRub(month.fact),
                economyResidue: convertFromKopToRub(month.economyResidue),
                economy: convertFromKopToRub(month.economy),
                cognosFundsAllocation: convertFromKopToRub(month.cognosFundsAllocation),
                cognosClosePeriodEconomy: convertFromKopToRub(month.cognosClosePeriodEconomy),
                cognosOpenPeriodEconomy: convertFromKopToRub(month.cognosOpenPeriodEconomy),
              })) ?? [],
          }),
        ),
      };
    },
  ),
});

const baseUrl = 'branchBudgetSummary';
const branchBudgetSummary = new CommonApi<BranchBudgetSummary>(
  baseUrl,
  'Бюджет свод по филиалам',
  undefined,
  convertDataFromServer,
);

const changeStatus = async (data: {
  id: number,
  calculationEconomyStatus?: string,
  distributionEconomyStatus?: string,
  proposalStatus?: string,
  status?: BudgetSummaryStatusType
}): Promise<Assignment> => {
  const updated = await fetchRequest.put(`/${baseUrl}/changeStatus`, data);
  if (updated) {
    return convertDataFromServer(updated);
  }
  throw new Error(`Не удалось сменить статус`);
};

const updateClarification = async (
  branchBudgetSummary: BranchBudgetSummary,
) => {
  const updated = await fetchRequest.put(
    `/${baseUrl}/updateClarification`,
    branchBudgetSummary,
  );
  if (updated) {
    return convertDataFromServer(updated);
  }
  throw new Error(`Не удалось сменить статус`);
};

const print = async (branchBudgetSummaryId: number) => {
  const printed = await downloadRequestWithToken(
    `/${baseUrl}/print/${branchBudgetSummaryId}`,
  );
  if (printed) {
    return printed;
  }
  throw new Error('Не удалось загрузить файл');
};

const exportToCognos = async (branchBudgetSummaryId: number) => {
  const updated = await fetchRequest.put(
    `/${baseUrl}/exportToCognos/${branchBudgetSummaryId}`,
    null,
  );
  if (updated) {
    return updated;
  }
  throw new Error(`Не удалось отправить бюджет`);
};

const checkCognosStatus = async (branchBudgetSummaryId: number) => {
  const status = await fetchRequest.put(
    `/${baseUrl}/exportToCognos/checkBudgetStatus/${branchBudgetSummaryId}`,
    null,
  );
  if (status) {
    return status;
  }
  throw new Error(`Не удалось отправить бюджет`);
};

export default {
  ...branchBudgetSummary,
  updateClarification,
  print,
  changeStatus,
  checkCognosStatus,
  exportToCognos
};
