// @flow
import React from 'react';
import {SelectProps} from 'antd/lib/select';
import {ContractorBranchLinkApi} from './../../lib/api';
import {ContractorBranchLink} from '../../lib/types/contractorBranchLink';
import AutocompleteSelect from './AutocompleteSelect';

export default (selectProps: SelectProps & { filter: any }) => {
  return (
    <AutocompleteSelect
      placeholder="Выберите подрядчика"
      fetch={ContractorBranchLinkApi.getContractors}
      fetchSingle={async id => {
        const {data} = await ContractorBranchLinkApi.getContractors({id})
        if (data && data.length) {
          return data[0]
        } else {
          return undefined
        }
      }}
      notFoundText="Подрядчики не найдены"
      renderOption={(contractor: ContractorBranchLink, Option) => (
        <Option key={contractor.id} value={contractor.id} contractor={contractor}>
          {contractor.name}
        </Option>
      )}
      {...selectProps}
    />
  );
}
