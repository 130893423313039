// @flow
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import type {OsagoCalculationStatus} from './../../../lib/types';
import {formatDateTimeToISOString} from './../../../lib/helpers';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem,
  OrgUnitSelect,
  Selects,
} from './../../../components';
import type {FilterChildrenParams} from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';

const { OsagoCalculationStatusSelect, YearSelect } = Selects;

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type FilterYearsParams = {
  status?: OsagoCalculationStatus,
  year?: number,
  endDate?: string,
  created?: string,
  nodeId?: number
};

type Props = {
  filter: FilterYearsParams,
  cleanFilter: Function,
  applyFilter: Function
};

export default ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
        additionalFilter
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<FilterYearsParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <OsagoCalculationStatusSelect
                  size="small"
                  onChange={value => changeValue('status', value)}
                  value={values.status}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <DatePicker
                  size="small"
                  format="DD.MM.YYYY"
                  value={values.created && moment.utc(values.created)}
                  placeholder="Дата создания карточки"
                  onChange={(value: Object, dateString: string) =>
                    changeValue(
                      'created',
                      formatDateTimeToISOString(value, dateString)
                    )
                  }
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YearSelect
                  size="small"
                  onChange={value => changeValue('year', value)}
                  value={values.year}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
              handleAdditional={handleAdditional}
              showAdditional={showAdditional}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);
