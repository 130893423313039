// @flow
import React from 'react';
import styled from 'styled-components';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment';

import { formatDateTimeToISOString } from './../../../../lib/helpers';

import {
  Filter,
  FilterContainer,
  FilterItem,
  FilterButtonsContainer
} from './../../../../components';
import type { FilterChildrenParams } from './../../../../components/hoc/Filter';
import Field from './../../../../components/card/Field';

const SpoilerContent = styled.div`
  padding: 16px;
`;

export type FuelPriceParamsFilter = {
  startDate?: string,
  endDate?: string
};

type Props = {
  filter: FuelPriceParamsFilter,
  cleanFilter: Function,
  applyFilter: Function
};

export const ActFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter
        }: FilterChildrenParams<FuelPriceParamsFilter>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата начала"
                  value={
                    values.startDate ? moment(values.startDate) : undefined
                  }
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(value, dateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <Datepicker
                  size="small"
                  placeholder="Дата окончания"
                  value={values.endDate ? moment(values.endDate) : undefined}
                  format={'DD.MM.YYYY'}
                  onChange={(value, dateString) => {
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(value, dateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>

            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ActFilter;
