// @flow
import CommonApi from './../commonApi';
import {MaintenanceEstimate} from '../types/maintenanceEstimate';
import type {maintenanceEstimateMaintenanceLink} from '../types/maintenanceEstimate';
import {convertFromKopToRub, convertFromRubToKop} from '../helpers';

const convertDataDataToServer = (estimate: MaintenanceEstimate) => ({
  ...estimate,
  maintenanceEstimateMaintenanceLinks: estimate.maintenanceEstimateMaintenanceLinks.map((link: maintenanceEstimateMaintenanceLink) => ({
    ...link,
    fare: convertFromRubToKop(link.fare),
  }))
})

const convertDataDataFromServer = (estimate: MaintenanceEstimate) => ({
  ...estimate,
  maintenanceEstimateMaintenanceLinks: estimate.maintenanceEstimateMaintenanceLinks.map((link: maintenanceEstimateMaintenanceLink) => ({
    ...link,
    fare: convertFromKopToRub(link.fare),
  }))
})

const basedUrl = 'MaintenanceEstimate';
const maintenanceEstimate = new CommonApi<MaintenanceEstimate>(
  basedUrl,
  'Реестр смет',
  convertDataDataToServer,
  convertDataDataFromServer,
);

export default {
  ...maintenanceEstimate,
};
