//@flow
import React from 'react';
import moment from 'moment';
import DatePicker from 'antd/lib/date-picker';
import { Button } from 'antd';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';
import { budgetSummaryStatus } from '../../../../lib/enum';
import {
  formatDateRangeString,
  formatDateTimeToISOString,
  formatDateTimeToString
} from '../../../../lib/helpers';
import Select from '../../../../components/selects';
import Field from '../../../../components/card/Field';
import type { BudgetSummary } from '../../../../lib/types';

const { BudgetVersionSelect } = Select;
const { RangePicker } = DatePicker;

export default (props: CommonFormProps<$Shape<BudgetSummary>>) => {
  if (!props.data) return null;
  return (
    <CommonForm {...props} useFooter={false}>
      {({ values, setFieldValue, handleSubmit, dirty, isSubmitting }) => {
        const disabled = values.id !== 0;
        return (
          <>
            <Grid gutter="16px" cols={5}>
              <GridItem>
                <Field label="Статус">
                  <>{budgetSummaryStatus[values.status]}</>
                </Field>
              </GridItem>
              <GridItem>
                <FormField
                  label="Период формирования"
                  required
                  name="startDate"
                  fast
                >
                  {() =>
                    disabled ? (
                      formatDateRangeString(
                        values.startDate,
                        values.endDate,
                        'DD.MM.YYYY'
                      )
                    ) : (
                      <RangePicker
                        format="DD.MM.YYYY"
                        placeholder={['Начало', 'Конец']}
                        value={[
                          values.startDate
                            ? moment.utc(values.startDate)
                            : null,
                          values.endDate ? moment.utc(values.endDate) : null
                        ]}
                        onChange={(value, dateString) => {
                          const [startDate, endDate] = value;
                          const [startDateString, endDateString] = dateString;
                          setFieldValue(
                            'startDate',
                            formatDateTimeToISOString(
                              startDate,
                              startDateString
                            )
                          );
                          setFieldValue(
                            'endDate',
                            formatDateTimeToISOString(endDate, endDateString)
                          );
                        }}
                      />
                    )
                  }
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата формирования" required name="date" fast>
                  {({ name, value }) =>
                    disabled ? (
                      formatDateTimeToString(value, 'DD.MM.YYYY')
                    ) : (
                      <DatePicker
                        format="DD.MM.YYYY"
                        value={value ? moment(value) : null}
                        onChange={(value: Object, dateString: string) => {
                          setFieldValue(
                            name,
                            formatDateTimeToISOString(value, dateString)
                          );
                        }}
                      />
                    )
                  }
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Версия бюджета"
                  required
                  name="budgetVersionId"
                  fast
                >
                  {({ name, value }) => {
                    return disabled ? (
                      values.budgetVersion.name
                    ) : (
                      <BudgetVersionSelect
                        disabled={disabled}
                        placeholder="Версия бюджета"
                        onChange={(value: number) => setFieldValue(name, value)}
                        value={value}
                        filter={{}}
                      />
                    );
                  }}
                </FormField>
              </GridItem>
              {dirty && (
                <GridItem>
                  <Field label=" ">
                    <Button
                      type="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      Сохранить
                    </Button>
                  </Field>
                </GridItem>
              )}
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
