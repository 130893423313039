// @flow
import type { ISUBudgetingDataType } from '../types';
import CommonApi from '../commonApi';

const baseUrl = 'ISUBudgetingData';
const ISUBudgetingData = new CommonApi<ISUBudgetingDataType>(
  baseUrl,
  'Данные для ИСУ Бюджетирование'
);

export default {
  ...ISUBudgetingData
};
