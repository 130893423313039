// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';

import type { InspectionGtn } from './../../../lib/types';
import { inspectionGtnApi } from '../../../lib/api';

import { Icon, ListTable } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

import { COMMON_MAINTENANCE_COLUMNS } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  inspectionsGtn: InspectionGtn[]
};

class InspectionGtnList extends Component<Props, State> {
  state = {
    loading: false,
    inspectionsGtn: []
  };

  async componentDidMount() {
    this.fetchInspectionsGtn();
  }

  fetchInspectionsGtn = async () => {
    try {
      this.setState({
        loading: true
      });
      const inspectionsGtn = await inspectionGtnApi.fetchVehicleInspectionsGtn(
        this.props.vehicleId
      );
      this.setState({
        inspectionsGtn: inspectionsGtn.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  onDelete = async (inspectionGtn: InspectionGtn) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (inspectionGtn.id) {
        await inspectionGtnApi.deleteInspectionGtn(inspectionGtn.id);
        notification.success({
          message: `Технический осмотр ГТН успешно удален`
        });
        await this.fetchInspectionsGtn();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  render() {
    const { enableDelete, vehicleId } = this.props;
    const { inspectionsGtn, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={[
          ...COMMON_MAINTENANCE_COLUMNS,
          {
            width: '20px',
            renderRecord: (record: InspectionGtn) =>
              record && enableDelete ? (
                <Operations>
                  <Link
                    to={`/vehicles/${vehicleId}/inspections-gtn/${parseInt(
                      record.id
                    )}`}
                  >
                    <StyledAntIcon type="edit" />
                  </Link>
                  <Popconfirm
                    placement="topRight"
                    title="Вы действительно хотите удалить?"
                    okText="Да"
                    cancelText="Нет"
                    onConfirm={() => this.onDelete(record)}
                  >
                    <StyledIcon type="x" />
                  </Popconfirm>
                </Operations>
              ) : null
          }
        ]}
        data={inspectionsGtn}
      />
    );
  }
}

export default InspectionGtnList;
