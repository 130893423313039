// @flow

import React from 'react';

import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';
import InputNumber from 'antd/lib/input-number';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import type { Vehicle } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Form from '../../../components/Form';
import { canShowEngineWorkHoursData } from './../lib';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import { isVehicleTractor, navigate, isVehicleTypeElectric } from './../../../lib/helpers';
import { vehicleTypeEnum } from '../../../lib/enum';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Kilometrage extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/kilometrage`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/kilometrage`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle = {} } = this.props;
    const isElectricVehicle = isVehicleTypeElectric(vehicle?.vehicleModel?.type);
    return (
      <>
        <Header left={vehicle && <h1>Пробег {vehicle.licensePlate}</h1>} />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              setFieldValue,
              values,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid
                    gutter="16px"
                    cols={2}
                    media={[
                      { size: 'lg', cols: 3 },
                      { size: 'md', cols: 2 }
                    ]}
                  >
                    <GridItem>
                      <FormField
                        label="Текущий пробег, км"
                        required={!isVehicleTractor(values)}
                        name="kilometrage"
                      >
                        {({ value, name }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            min={0}
                            max={999999}
                            decimalSeparator=","
                            onChange={value => setFieldValue(name, value)}
                            placeholder="Введите текущий пробег"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Средний суточный пробег, км"
                        name="averageDailyKilometrage"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            min={0}
                            max={999999}
                            disabled
                            decimalSeparator=","
                            onChange={value => setFieldValue(name, value)}
                            placeholder="Введите средний суточный пробег"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Среднегодовой пробег, км"
                        name="averageKilometrage"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            min={0}
                            max={999999}
                            disabled
                            decimalSeparator=","
                            onChange={value => setFieldValue(name, value)}
                            placeholder="Введите среднегодовой пробег"
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Пробег с последнего кап ремонта, км"
                        name="kilometrageFromLastOverhaul"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            min={0}
                            max={999999}
                            decimalSeparator=","
                            onChange={value => setFieldValue(name, value)}
                          />
                        )}
                      </FormField>
                    </GridItem>
                    <GridItem>
                      <FormField
                        label="Коэффициент выхода, %"
                        name="outputRatio"
                      >
                        {({ name, value }) => (
                          <StyledInputNumber
                            name={name}
                            value={value}
                            min={0}
                            max={100}
                            onChange={value => setFieldValue(name, value)}
                            disabled
                          />
                        )}
                      </FormField>
                    </GridItem>
                    {canShowEngineWorkHoursData(
                      values.vehicleModel && values.vehicleModel.type
                    ) && (
                      <>
                        {!isElectricVehicle && (
                          <>
                            <GridItem>
                              <FormField
                                label="Показания счётчика моточасов, ч"
                                name="engineWorkHours"
                                required
                              >
                                {({ value, name }) => (
                                  <StyledInputNumber
                                    min={0}
                                    decimalSeparator=","
                                    step={0.1}
                                    precision={1}
                                    value={value}
                                    name={name}
                                    onChange={(value: number) =>
                                      setFieldValue(name, value)
                                    }
                                    onBlur={handleBlur}
                                  />
                                )}
                              </FormField>
                            </GridItem>
                            <GridItem>
                              <FormField
                                label="Среднегодовая наработка моточасов, ч"
                                name="averageEngineWorkHours"
                              >
                                {({ value, name }) => (
                                  <StyledInputNumber
                                    min={0}
                                    decimalSeparator=","
                                    step={0.1}
                                    value={value}
                                    name={name}
                                    onChange={(value: number) =>
                                      setFieldValue(name, value)
                                    }
                                    onBlur={handleBlur}
                                    disabled
                                  />
                                )}
                              </FormField>
                            </GridItem>
                          </>
                        )}
                        <GridItem>
                          <FormField
                            label="Норма наработки моточасов в месяц, ч"
                            name="normEngineWorkHours"
                          >
                            {({ name, value }) => (
                              <StyledInputNumber
                                name={name}
                                value={value}
                                min={0}
                                max={999999}
                                decimalSeparator=","
                                onChange={value => setFieldValue(name, value)}
                                placeholder="Введите норму наработки моточасов"
                              />
                            )}
                          </FormField>
                        </GridItem>
                        {!isElectricVehicle && (
                          <GridItem>
                            <FormField
                              label="Среднесуточная наработка моточасов, ч"
                              name="averageDailyEngineWorkHours"
                            >
                              {({ name, value }) => (
                                <StyledInputNumber
                                  name={name}
                                  value={parseFloat(value).toFixed(3)}
                                  min={0}
                                  max={999999}
                                  decimalSeparator=","
                                  onChange={value => setFieldValue(name, value)}
                                  placeholder="Введите среднесуточную наработку моточасов"
                                  disabled
                                />
                              )}
                            </FormField>
                          </GridItem>
                        )}
                        <GridItem>
                          <FormField
                            label="Наработка моточасов с последнего кап ремонта, ч"
                            name="engineWorkHoursFromLastOverhaul"
                          >
                            {({ name, value }) => (
                              <StyledInputNumber
                                name={name}
                                value={value}
                                min={0}
                                max={999999}
                                decimalSeparator=","
                                onChange={value => setFieldValue(name, value)}
                              />
                            )}
                          </FormField>
                        </GridItem>
                      </>
                    )}
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(Kilometrage);
