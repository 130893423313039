import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import Datepicker from 'antd/lib/date-picker';
import moment from 'moment/moment';
import styled from 'styled-components';

import Field from '../../../components/card/Field';
import { Moment } from 'moment/moment';

const ErrorString = styled.p`
  color: #c91438;
`;

type Props = {
  visible: true,
  handleOk: ({ reasonNote?: string, decommissioningDate: Moment }) => void,
  onModalClose: () => void,
  error?: string
};
export default (props: Props) => {
  const { visible, onModalClose, error } = props;

  const [reasonNote, setReasonNote] = useState(undefined);
  const [date, setDate] = useState(moment());
  return (
    <Modal
      title="Списание ТС"
      visible={visible}
      onCancel={onModalClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() =>
            props.handleOk({ reasonNote, decommissioningDate: date })
          }
        >
          Ок
        </Button>
      ]}
    >
      <Field label="Причина списания ТС">
        <Input
          value={reasonNote}
          onChange={e => setReasonNote(e.target.value)}
        />
      </Field>
      <Field label="Дата списания ТС">
        <Datepicker
          defaultValue={date}
          format={'DD.MM.YYYY'}
          onChange={moment => setDate(moment)}
        />
      </Field>
      {error && <ErrorString>{error}</ErrorString>}
    </Modal>
  );
};
