// @flow
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import {FormikProps} from 'formik';
import React, {useState} from 'react';
import styled from 'styled-components';

import CancelButton from '../../../components/CancelButton';
import type {FormFieldType} from '../../../components/Form';
import Form from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import Selects from '../../../components/selects';

import {accessTypeEnum, authorizeTypeEnum} from '../../../lib/enum';
import type {EmployeeFilter, User, UserAccess} from '../../../lib/types';

const {
  EmployeeSelect,
  UserRoleSelect,
  AuthorizeTypeSelect,
  ContractorSelect,
} = Selects;

const Content = styled.div`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  user: $Shape<User>,
  onSubmit: Function,
  onCancel: Function,
  userAccess: UserAccess[]
};

export default ({user, onSubmit, onCancel, userAccess}: Props) => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  return (
    <Form initialValues={user} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const {
          handleSubmit,
          values,
          handleBlur,
          setFieldValue,
          dirty,
          isSubmitting,
        } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <Content>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Логин" fast name="userName" required>
                      {field => <Input {...field} autocomplete="off" />}
                    </FormField>
                  </GridItem>
                  {(values.authorizeType === authorizeTypeEnum.standard &&
                    user.authorizeType === authorizeTypeEnum.ldap) ||
                  showPasswordField ? (
                    <GridItem>
                      <FormField label="Пароль" fast name="password" required>
                        {field => (
                          <Input
                            {...field}
                            type="password"
                            autoComplete="new-password"
                          />
                        )}
                      </FormField>
                    </GridItem>
                  ) : values.authorizeType === authorizeTypeEnum.ldap ? null : (
                    <GridItem>
                      <FormField label="Пароль">
                        {() => (
                          <Button
                            onClick={() => {
                              setShowPasswordField(true);
                            }}
                          >
                            Сменить пароль
                          </Button>
                        )}
                      </FormField>
                    </GridItem>
                  )}
                  <GridItem>
                    <FormField label="Роль" fast name="roles" required>
                      {({value, name}) => (
                        <UserRoleSelect
                          mode="multiple"
                          onChange={(value: string, option) => {
                            if (option.length === 0) {
                              setFieldValue(name, undefined);
                            } else {
                              setFieldValue(
                                name,
                                option.map(value => ({
                                  roleId: value.props.role.id,
                                  roleName: value.props.role.name,
                                })),
                              );
                            }
                          }}
                          onBlur={() => handleBlur({target: {name}})}
                          value={value ? value.map(role => role.roleId) : undefined}
                          data-cy="roleSelect"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Сотрудник"
                      name="employeeId"
                      required
                      fast
                    >
                      {({value, name}) => (
                        <EmployeeSelect
                          placeholder="Выберите сотрудника"
                          onChange={(value: string) =>
                            setFieldValue(name, value)
                          }
                          value={value}
                          showLink
                          data-cy="employeeSelect"
                          filter={
                            ({
                              isUser: false,
                            }: EmployeeFilter)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Тип авторизации"
                      fast
                      name="authorizeType"
                      required
                    >
                      {({value, name}) => (
                        <AuthorizeTypeSelect
                          placeholder="Выберите тип авторизации пользователя"
                          onChange={(value: string) => {
                            setFieldValue(name, value);
                            value === authorizeTypeEnum.ldap &&
                            setShowPasswordField(false);
                          }}
                          value={value}
                          data-cy="authorizeTypeSelect"
                          onBlur={() => handleBlur({target: {name}})}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="contractorId"
                      fast
                      label="Подрядчик (для 1С)"
                    >
                      {({value, name}) => (
                        <ContractorSelect
                          onChange={(value: number) =>
                            setFieldValue(name, value)
                          }
                          onBlur={() => handleBlur({target: {name}})}
                          value={value}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  {!values.authorizeType === authorizeTypeEnum.ldap &&
                    !!values.id &&
                    userAccess.includes(accessTypeEnum.admin) && (
                      <GridItem>
                        <FormField label="Новый пароль" name="password" fast>
                          {field => (
                            <Input
                              {...field}
                              type="password"
                              placeholder="Введите новый пароль"
                            />
                          )}
                        </FormField>
                      </GridItem>
                    )}
                </Grid>
              </Content>
            </Section>
            <Footer>
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                data-cy="save"
              >
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
