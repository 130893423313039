export const accessTypes = {
  newFeaturesDemo: 'Демонстрационный клейм',
  viewingSelfVehiclePlan: 'Просмотр бюджета СТС филиала',
  viewingAllSelfVehiclePlan: 'Просмотр всех бюджетов СТС',
  handlingSelfVehiclePlan: 'Создание перечня СТС',
  approvingSelfVehiclePlan: 'Утверждение перечня СТС',
  approvingSelfVehiclePlanBudget: 'Утверждение справочников бюджета',
  handlingSelfVehiclePlanBudget: 'Создание и редактирование справочников бюджета',
  viewingSelfVehiclePlanBudget: 'Просмотр справочников бюджета филиала',
  viewingAllSelfVehiclePlanBudget: 'Просмотр справочников бюджета всех филиалов',
  approvingSelfVehiclePlanBudgetItog: 'Утверждение предложения в бюджет по СТС',
  approvingSelfVehiclePlanBudgetRepair: 'Утверждение итогового предложения бюджета по ремонтам',
  repairBudgetApprovedOrDeclinedNotification: 'Оповещение при согласовании/отклонении Бюджета по ремонтам',
  viewingContractVehicleBudget: 'Просмотр Бюджета НТС филиала',
  viewingAllContractVehicleBudgets: 'Просмотр всех бюджетов НТС',
  handlingRegulation: 'Создание Регламента НТС',
  approvingRegulation: 'Утверждение Регламента НТС',
  handlingContractVehicleBudget: 'Создание Бюджета НТС',
  approvingContractVehicleBudget: 'Утверждение Бюджета НТС',
  viewingVehicleTariff: 'Просмотр справочника "Тарифы на НТС"',
  handlingVehicleTariff: 'Заполнение справочника "Тарифы на НТС"',
  viewingBudgetVersion: 'Просмотр справочника "Версии бюджета"',
  handlingBudgetVersion: 'Заполнение справочника "Версии бюджета"',
  branchBudgetSummaryApprovedNotification: 'Оповещение при факте согласования/отклонения Свода',
  regulationApprovementNotification: 'Уведомление об отправке регламента бюджета НТС на утверждение',
  regulationApprovedNotification: 'Уведомление об утверждении регламента бюджета НТС',
  vehicleTireScheduledReplacementNotification: 'Уведомление о плановой замене автошины на автомобиле',
  tractorTireScheduledReplacementNotification: 'Уведомление о плановой замене автошины на тракторе',
  tractorBatteryScheduledReplacementNotification: 'Уведомление о плановой замене АКБ на ТС',
  gpmExpertiseNotification: 'Уведомление о проведении экспертизы ГПМ',
  maintenanceSafetyEquipmentNotification: 'Уведомление о проведении ТО приборов безопасности ГПМ',
  tankCalibrationNotification: 'Уведомление о проведении сертификации средств измерения (тарировка цистерн)',
  vehicleMaintenanceNotification: 'Уведомление о проведении ТО ТС',
  gibddMaintenanceNotification: 'Уведомление о проведении ТО автомобилей в ГИБДД',
  gtnMaintenanceNotification: 'Уведомление о проведении ТО тракторов в ГТН',
  osagoExpirationNotification: 'Уведомление об окончании действия полиса ОСАГО',
  contractNotification: 'Оповещение по договорам из Паруса',
  viewingMaintenances: 'Просмотр карточек работ Филиала',
  creatingMaintenances: 'Создание карточки работ',
  viewingAllMaintenances: 'Просмотр всех карточек работ',
  approvingDefectiveStatements: 'Утверждение предварительной дефектной ведомости',
  approvingDetailedDefectiveStatements: 'Утверждение детальной дефектной ведомости',
  approvingMaintenances: 'Утверждение карточки работ',
  viewingMaintenanceWorkTypes: 'Просмотр справочника виды работ по обслуживанию ТС',
  handlingMaintenanceWorkTypes: 'Редактирование справочника виды работ по обслуживанию ТС',
  viewingCompareExpenseDirections: 'Просмотр справочника “Сопоставление видов ремонта и направлений затрат” ',
  handlingCompareExpenseDirections: 'Редактирование справочника “Сопоставление видов ремонта и направлений затрат”',
  viewingMaintenanceMatrix: 'Просмотр “Матрица работ”',
  handlingMaintenanceMatrix: 'Редактирование “Матрица работ”',
  viewingExpenseDirections: 'Просмотр справочника направления расходов',
  handlingExpenseDirections: 'Редактирование справочника направления расходов',
  viewingVehicleIssueGroups: 'Просмотр справочника “Группы дефектов”',
  handlingVehicleIssueGroups: 'Редактирование справочника “Группы дефектов”',
  viewingStocksAndStockParts: 'Просмотр “Перечень запасных частей” и складов филиала',
  viewingAllStocksAndStockParts: 'Просмотр “Перечень запасных частей” и складов всех филиалов',
  viewingMaintenanceOperationGroups: 'Просмотр справочника “Виды работ”',
  handlingMaintenanceOperationGroups: 'Редактирование справочника “Виды работ”',
  handlingStocks: 'Редактирование складов филиала',
  handlingAllStocks: 'Редактирование складов всех филиалов',
  creatingOrder: 'Создание заявки',
  approvingGpmOrder: 'Подтверждение заявки на ГПМ',
  handlingOrder: 'Обработка заявки',
  handlingTrip: 'Работа с путевыми листами',
  creatingVehicle: 'Создание ТС',
  approvingVehicle: 'Подтверждение ТС',
  viewingAllVehicles: 'Просмотр всех ТС',
  viewingVehicleServiceOnly: 'Просмотр ТС служб',
  viewingVehicleBranch: 'Просмотр ТС филиалов',
  admin: 'Администрирование системы',
  adminBranch: 'Администрирование филиала',
  viewingReports: 'Просмотр отчетов',
  viewingAllOrders: 'Просмотр всех заявок',
  viewingOrdersServiceOnly: 'Просмотр заявок службы',
  viewingOrdersBranch: 'Просмотр заявок филиала и служб филиала',
  viewingAllTrips: 'Просмотр всех путевых листов',
  viewingTripsServiceOnly: 'Просмотр путевых листов службы',
  viewingTripsBranch: 'Просмотр путевых листов филиала и служб филиала',
  viewingAllShifts: 'Просмотр всех нарядов',
  viewingShiftsServiceOnly: 'Просмотр нарядов службы',
  viewingShiftsBranch: 'Просмотр нарядов филиала и служб филиала',
  gager: 'Проведение контрольных измерений',
  handlingEquipment: 'Создание/редактирование оборудования',
  viewingAllEquipments: 'Просмотр оборудования всех филиалов и служб',
  viewingBranchEquipments: 'Просмотр оборудования филиала и служб филиала',
  viewingSystemJournal: 'Просмотр системного журнала',
  handlingDriver: 'Создание/редактирование водителя',
  viewingAllDrivers: 'Просмотр водителей всех филиалов и служб',
  viewingBranchDrivers: 'Просмотр водителей филиала и служб филиала',
  handlingLocation: 'Создание/редактирование объектов',
  viewingAllLocations: 'Просмотр объектов всех филиалов и служб',
  viewingBranchLocations: 'Просмотр объектов филиала и служб филиала',
  handlingFuelCard: 'Создание/редактирование топливных карт',
  viewingAllFuelCards: 'Просмотр топливных карт всех филиалов и служб',
  viewingBranchFuelCards: 'Просмотр топливных карт филиала и служб филиала',
  firstBit: 'Интеграция "Энерготранс"/"КТС"',
  handlingEmployees: 'Создание/редактирование сотрудников',
  approvingTimeLimits: 'Подтверждение регламента',
  handlingTimeLimits: 'Создание/редактирование регламента',
  viewingTimeLimits: 'Просмотр всех регламентов',
  viewingBranchTimeLimits: 'Просмотр регламента филиала',
  approvingLimitAssignment: 'Утверждение закрепления',
  handlingLimitAssignment: 'Редактирование закрепления',
  updatingDailyBudgets: 'Обновление бюджета',
  viewingLimitAssignment: 'Просмотр закрепления филиала',
  viewingAllLimitAssignment: 'Просмотр всех закреплений',
  approvingYearLimitsPlan: 'Утверждение годового плана',
  handlingYearLimitsPlan: 'Редактирование годового плана',
  viewingBranchYearLimitsPlan: 'Просмотр годового плана филиала',
  viewingYearLimitsPlan: 'Просмотр всех годовых планов',
  approvingMonthLimitsPlan: 'Утверждение месячного плана',
  handlingMonthLimitsPlan: 'Создание/редактирование месячного плана',
  viewingBranchMonthLimitsPlan: 'Просмотр месячного плана филиала',
  viewingMonthLimitsPlan: 'Просмотр всех месячных планов',
  handlingMaintenanceServices: 'Редактирование журналов учета работ филиала',
  viewingMaintenanceServices: 'Просмотр журналов учета работ по филиалу',
  viewingAllMaintenanceServices: 'Просмотр всех журналов работ',
  viewingAllBranchesOsagoCalculations: 'Просмотр расчетов ОСАГО всех филиалов',
  viewingBranchOsagoCalculations: 'Просмотр расчетов ОСАГО филиала',
  approvingAllBranchesOsagoCalculations: 'Утверждение расчетов ОСАГО всех филиалов',
  approvingBranchOsagoCalculations: 'Утверждение расчетов ОСАГО филиала',
  formationBranchOsagoCalculations: 'Формирование расчетов потребностей ОСАГО по филиалу',
  formationAllBranchesOsagoCalculations: 'Формирование расчеов потребностей ОСАГО по всем филиалам',
  formationWashingPlans: 'Формирование плана моек ТС филиала',
  approvingWashingPlans: 'Согласование плана моек ТС филиала',
  viewingWashingPlans: 'Просмотр плана моек ТС филиала',
  viewingAllWashingPlans: 'Просмотр всех планов моек ТС',
  handlingOsagoMultipliers: 'Редактирование справочника коэффиценты ОСАГО',
  viewingOsagoMultipliers: 'Просмотр коэффициентов ОСАГО',
  handlingWashingVehicleTypes: 'Редактирование справочника типы тс для моек',
  viewingWashingVehicleTypes: 'Просмотр справочника типы тс для моек',
  handlingVehicleWashingTypeToGroupsMaps: 'Редактирование справочника сопоставление типов тс для моек',
  viewingVehicleWashingTypeToGroupsMap: 'Просмотр справочника сопоставление типов тс для моек',
  handlingPriceLists: 'Редактирование прейскурантов филиала',
  approvingPriceLists: 'Утверждение прейскурантов филиала',
  viewingPriceLists: 'Просмотр прейскурантов филиала',
  viewingAllPriceLists: 'Просмотр всех прейскурантов филиала',
  viewingJobTitles: 'Просмотр должностей для регламентов Филиала',
  viewingAllJobTitles: 'Просмотр всех должностей для регламентов',
  handlingJobTitles: 'Редактирование должностей для регламентов Филиала',
  viewingContracts: 'Просмотр договоров филиала',
  viewingAllContracts: 'Просмотр договоров всех филиалов',
  handlingContracts: 'Создание договоров',
  approvingContracts: 'Утверждение и отклонение договоров',
  viewingExternalActs: 'Просмотр внешних документов филиала',
  viewingAllExternalActs: 'Просмотр внешних документов всех филиалов',
  handlingExternalActs: 'Создание внешних документов',
  approvingExternalActs: 'Подтверждение внешних документов',
  viewingInternalActs: 'Просмотр внутрених документов филиала',
  viewingAllInternalActs: 'Просмотр внутрених документов всех филиалов',
  handlingInternalActs: 'Создание внутренних документов',
  approvingInternalActs: 'Утверждение внутренних документов',
  viewingBudgetExecutions: 'Просмотр исполнения договоров по филиалу',
  viewingAllBudgetExecutions: 'Просмотр исполнения договоров по всем филиалам',
  handlingBudgetAnalysis: 'Создание анализа затрат по филиалу',
  viewingAllBudgetAnalysis: 'Просмотр анализа затрат по всем филиалам',
  viewingBudgetAnalysis: 'Просмотр анализа затрат по филиалу',
  budgetAnalysisNotification: 'Оповещение по анализу затрат филиала',
  sendingVehiclePlanBudgetToCognos: 'Отправка предложения в бюджет в Cognos',
  sendingVehiclePlanBudgetRepairToCognos: 'Отправка предложения бюджета по ремонтам в Cognos',
  sendingVehiclePlanBudgetTaxToCognos: 'Отправка предложения бюджета по налогам в Cognos',
  branchBudgetSummaryApprovementNotification: 'Оповещение при согласовании Свода',
  handlingBranchVehicleTaxPlans: 'Создание и редактирование Расчетов транспортного налога филиала',
  handlingAllVehicleTaxPlans: 'Создание и редактирование Расчетов транспортного налога по всем филиалам',
  viewingBranchVehicleTaxPlans: 'Просмотр расчетов транспортного налога филиала',
  viewingAllVehicleTaxPlans: 'Просмотр расчетов транспортного налога по всем филиалам',
  handlingPropertyIsAdditionalNeedOfExpenseDirections: 'Редактирование доп. потребности в направлениях расходов',
  viewingPlaces: 'Просмотр местоположений по филиалу',
  viewingAllPlaces: 'Просмотр местоположений по всем филиалам',
  requestingPlaces: 'Запрос данных по местоположениям',
  handlingDetailedBudget: 'Создание сводного предложения/уточнения в бюджет',
  handlingBranchDetailedBudget: 'Добавление и редактирование предложения/уточнение в бюджет по филиалу',
  viewingDetailedBudget: 'Просмотр предложения/уточнения в бюджет по всем филиалам',
  approvingBranchDetailedBudget: 'Утверждение предложения по уточнению бюджета филиала',
  approvingDetailedBudget: 'Утверждение предложения/уточнения в бюджет по филиалу',
  viewingAllCognosLog: 'Просмотр и отправка данных по всем филиалам',
  viewingCognosLog: 'Просмотр и отправка данных по филиалу',
  decomissingEquipment: 'Списание оборудования (шины, АКБ)',
  handlingTireUsingLimits: 'Изменение нормативов эксплуатации автомобильных шин',
  handlingOrderToReworkReasons: 'Редактирование справочника "Причины отклонения заявки на ГПМ/ТС"',
  toReworkOrder: 'Отправка заявки на доработку',
  createEmergencyOrderOnAnyDate: 'Формирование заявки на любой прошедший период',
  handlingTripEditLimit: 'Редактирование справочника “Сроки внесения изменений в оттаксированный ПЛ”',
  editTaxingTripByOneWorkDay: 'Редактирование оттаксированного ПЛ в течении следующего рабочего дня',
  editTaxingTripByNextMonth: 'Редактирование оттаксированного ПЛ до следующего отчётного месяца',
  viewingVehicleModel: 'Просмотр справочника "Модели ТС"',
  handlingVehicleModel: 'Редактирование справочника "Модели ТС"',
  editOrderBeyondRework: 'Редактирование заявки вне доработки',
  viewingBranchPlanFuelConsumption: 'Просмотр системного документа «Плановый расход ГСМ» по Филиалу',
  viewingAllPlanFuelConsumption: 'Просмотр системного документа «Плановый расход ГСМ» по всем Филиалам',
  viewingBranchFactFuelConsumption: 'Просмотр системного документа «Фактический расход ГСМ» по Филиалу',
  viewingAllFactFuelConsumption: 'Просмотр системного документа «Фактический расход ГСМ» по всем Филиалам',
  handlingDebitCreditAccount: 'Редактирование справочника «дебит/кредит счетов»',
  handlingUatToParusOilType: 'Редактирование справочника «Типы масел для учета расхода»',
  handlingCognosActualDataSendingTimeline: 'Редактирование справочника «Работа с ИСУ Бюджетирование»',
  sendingFactFuelConsumptionToCognos: 'Отправка данных по фактическому расходу ГСМ в ИСУ',
  handlingAllRegulations: 'Работа с регламентами по всем филиалам',
  loadingBudgetFromCognos: 'Загрузка бюджета из ИСУ «Бюджетирование»',
  loadingBudgetFromCognosNotification: 'Уведомление о загрузке бюджета из ИСУ «Бюджетирование»',
  editingBudgetCopy: 'Редактирование копии бюджета СТС/НТС',
  viewingContractorBranchLink: 'Просмотр справочника привязки филиала к подрядчику',
  handlingContractorBranchLink: 'Редактирвоание справочника привязки филиала к подрядчику',
  viewingBranchContractTrip: 'Просмотр ПЛ НТС по филиалу',
  viewingAllContractTrip: 'Просмотр ПЛ НТС по всем филиалам',
  handlingContractTrip: 'Редактирование ПЛ НТС',
  viewingContractTripVehicleLayout: 'Просмотр фактического транспортного макета',
  handlingContractTripVehicleLayout: 'Редактирвоание фактического транспортного макета',
  copyVehicleToAnotherBranch: 'Перемещение ТС',
  viewingWorkDict: 'Просмотр справочника «Виды ремонта»',
  editingWorkDict: 'Редактирование справочника «Виды ремонта»',
  handlingMaintenanceWork: 'Редактирование справочника Работы по ТО',
  changeStatusMaintenanceWork: 'Утверждение/отклонение записи в справочнике Работы по ТО',
  approvementMaintenanceWork: 'Отправка на утверждение записи в справочнике Работы по ТО',
  removingMaintenanceInternalAct: 'Удаление из реестра актов выполненных работ хоз. способом',
  removingDeffectiveStatement: 'Удаление из реестра дефектно-ресурсной ведомостей',
  editingDeffectiveStatement: 'Редактированию дефектно-ресурсной ведомости ',
  editingApprovedVehiclePlan: 'Редактирование планируемого перечня ТС',
  viewingContractVehicleModel: 'Просмотр моделей НТС',
  handlingContractVehicleModel: 'Создание моделей НТС',
  viewingVehicleCategory: 'Просмотр справочника «Категории ТО ГИБДД',
  handlingVehicleCategory: 'Заполнение справочника «Категории ТО ГИБДД»',
  viewingInventoryCardCompareDate: 'Просмотр справочника «Сроки сверки ИК»',
  editingInventoryCardCompareDate: 'Редактирования справочника «Сроки сверки ИК»',
  compareOneInventoryCard: 'Запуск единичной сверки ИК',
  compareAllInventoryCard: 'Запуск массовой сверки ИК',
  compareAllInventoryCardNotification: 'Уведомление о сверке ИК всех филиалов',
  compareBranchnventoryCardNotification: 'Уведомление о сверке ИК филиала',
  viewingAllCompareInventoryCardResult: 'Просмотр результата сверки ИК по всем филиалам',
  viewingBranchCompareInventoryCardResult: 'Просмотр результата сверки ИК по филиалу',
  viewingCalendarBK: 'Просмотр справочника «Календарь БК»',
  handlingCalendarBK: 'Редактирование справочника «Календарь БК»',
  handlingSelfOrder: 'Обработка собственных заявок (только для АУ)',
  editingFuelPriceOnlyInVehicleTariff: 'Редактирование стоимости топлива в тарифе НТС',
  maintenanceWorkChangeStatusNotification: 'Уведомления при утверждении/отклонении работы по ТО',
  maintenanceWorkApprovementNotification: 'Уведомления при отправке на утверждение работы по ТО',
  viewingContractVehicleRequests: 'Просмотр заявок на наемные ТС',
  monitoring: 'Отслеживание ТС',
  editingMaitenanceNormative: 'Изменение нормативов ТО',
  handlingVehicleWorkMeter: 'Замена прибора учета',
  expirationDriverDocNotification: 'Уведомления по водительским документам',
  handlingFuelsAct: 'Формирование акта списания топлива',
  viewingBranchLocationsToir: 'Просмотр объектов ТОиР в филиале',
  removingManyLocations: 'Массовое удаление объектов',
  canCancelIntegrationOrder: 'Отмена заявок полученых через интеграцию',
  changingStatusBlockedVehicles: 'Изменение статуса заблокированных ТС',
  changingOrderDispatcherNodeToAllBranches: 'Перенаправление заявки в любой филиал',
  companiesHandling: 'Редактирование справочника "Контрагенты"',
  editingFuelPrice: 'Изменения прайса ГСМ',
  viewingOrderCalculation: 'Просмотр расчета стоимости ТС',
  viewingAllOrderCalculation: 'Просмотр расчета стоимости ТС по всем филиалам',
  sendingMaterialsBudgetToMto: 'Отправка потребности в АС «МТО»',
  canCancelGpmOrder: 'Отмена заявки на ГПМ',
  creationIndefiniteOrder: 'Создание бессрочных заявок',
  changingTypeOfVehicleGpmOrder: 'Изменение типа заявки ТС/ГПМ ',
};

export const accessTypeEnum = {
  viewingSelfVehiclePlan: 'viewingSelfVehiclePlan',
  viewingAllSelfVehiclePlan: 'viewingAllSelfVehiclePlan',
  handlingSelfVehiclePlan: 'handlingSelfVehiclePlan',
  approvingSelfVehiclePlan: 'approvingSelfVehiclePlan',
  approvingSelfVehiclePlanBudget: 'approvingSelfVehiclePlanBudget',
  handlingSelfVehiclePlanBudget: 'handlingSelfVehiclePlanBudget',
  viewingSelfVehiclePlanBudget: 'viewingSelfVehiclePlanBudget',
  viewingAllSelfVehiclePlanBudget: 'viewingAllSelfVehiclePlanBudget',
  approvingSelfVehiclePlanBudgetItog: 'approvingSelfVehiclePlanBudgetItog',
  approvingSelfVehiclePlanBudgetRepair: 'approvingSelfVehiclePlanBudgetRepair',
  repairBudgetApprovedOrDeclinedNotification: 'repairBudgetApprovedOrDeclinedNotification',
  viewingContractVehicleBudget: 'viewingContractVehicleBudget',
  viewingAllContractVehicleBudgets: 'viewingAllContractVehicleBudgets',
  handlingRegulation: 'handlingRegulation',
  approvingRegulation: 'approvingRegulation',
  handlingContractVehicleBudget: 'handlingContractVehicleBudget',
  approvingContractVehicleBudget: 'approvingContractVehicleBudget',
  viewingVehicleTariff: 'viewingVehicleTariff',
  handlingVehicleTariff: 'handlingVehicleTariff',
  viewingBudgetVersion: 'viewingBudgetVersion',
  handlingBudgetVersion: 'handlingBudgetVersion',
  branchBudgetSummaryApprovedNotification: 'branchBudgetSummaryApprovedNotification',
  regulationApprovementNotification: 'regulationApprovementNotification',
  regulationApprovedNotification: 'regulationApprovedNotification',
  vehicleTireScheduledReplacementNotification: 'vehicleTireScheduledReplacementNotification',
  tractorTireScheduledReplacementNotification: 'tractorTireScheduledReplacementNotification',
  tractorBatteryScheduledReplacementNotification: 'tractorBatteryScheduledReplacementNotification',
  gpmExpertiseNotification: 'gpmExpertiseNotification',
  maintenanceSafetyEquipmentNotification: 'maintenanceSafetyEquipmentNotification',
  tankCalibrationNotification: 'tankCalibrationNotification',
  vehicleMaintenanceNotification: 'vehicleMaintenanceNotification',
  gibddMaintenanceNotification: 'gibddMaintenanceNotification',
  gtnMaintenanceNotification: 'gtnMaintenanceNotification',
  osagoExpirationNotification: 'osagoExpirationNotification',
  contractNotification: 'contractNotification',
  viewingMaintenances: 'viewingMaintenances',
  creatingMaintenances: 'creatingMaintenances',
  viewingAllMaintenances: 'viewingAllMaintenances',
  approvingDefectiveStatements: 'approvingDefectiveStatements',
  approvingDetailedDefectiveStatements: 'approvingDetailedDefectiveStatements',
  approvingMaintenances: 'approvingMaintenances',
  viewingMaintenanceWorkTypes: 'viewingMaintenanceWorkTypes',
  handlingMaintenanceWorkTypes: 'handlingMaintenanceWorkTypes',
  viewingCompareExpenseDirections: 'viewingCompareExpenseDirections',
  handlingCompareExpenseDirections: 'handlingCompareExpenseDirections',
  viewingMaintenanceMatrix: 'viewingMaintenanceMatrix',
  handlingMaintenanceMatrix: 'handlingMaintenanceMatrix',
  viewingExpenseDirections: 'viewingExpenseDirections',
  handlingExpenseDirections: 'handlingExpenseDirections',
  viewingVehicleIssueGroups: 'viewingVehicleIssueGroups',
  handlingVehicleIssueGroups: 'handlingVehicleIssueGroups',
  viewingStocksAndStockParts: 'viewingStocksAndStockParts',
  viewingAllStocksAndStockParts: 'viewingAllStocksAndStockParts',
  viewingMaintenanceOperationGroups: 'viewingMaintenanceOperationGroups',
  handlingMaintenanceOperationGroups: 'handlingMaintenanceOperationGroups',
  handlingStocks: 'handlingStocks',
  handlingAllStocks: 'handlingAllStocks',
  viewingMaintenanceServices: 'viewingMaintenanceServices',
  viewingAllMaintenanceServices: 'viewingAllMaintenanceServices',
  formationWashingPlans: 'formationWashingPlans',
  approvingWashingPlans: 'approvingWashingPlans',
  viewingWashingPlans: 'viewingWashingPlans',
  viewingAllWashingPlans: 'viewingAllWashingPlans',
  handlingOsagoMultipliers: 'handlingOsagoMultipliers',
  viewingOsagoMultipliers: 'viewingOsagoMultipliers',
  handlingWashingVehicleTypes: 'handlingWashingVehicleTypes',
  viewingWashingVehicleTypes: 'viewingWashingVehicleTypes',
  handlingVehicleWashingTypeToGroupsMaps: 'handlingVehicleWashingTypeToGroupsMaps',
  viewingVehicleWashingTypeToGroupsMap: 'viewingVehicleWashingTypeToGroupsMap',
  handlingPriceLists: 'handlingPriceLists',
  approvingPriceLists: 'approvingPriceLists',
  viewingPriceLists: 'viewingPriceLists',
  viewingAllPriceLists: 'viewingAllPriceLists',
  newFeaturesDemo: 'newFeaturesDemo',
  handlingEmployees: 'handlingEmployees',
  creatingOrder: 'creatingOrder',
  approvingGpmOrder: 'approvingGpmOrder',
  handlingOrder: 'handlingOrder',
  handlingTrip: 'handlingTrip',
  creatingVehicle: 'creatingVehicle',
  approvingVehicle: 'approvingVehicle',
  viewingAllVehicles: 'viewingAllVehicles',
  viewingVehicleServiceOnly: 'viewingVehicleServiceOnly',
  viewingVehicleBranch: 'viewingVehicleBranch',
  admin: 'admin',
  adminBranch: 'adminBranch',
  viewingReports: 'viewingReports',
  viewingAllOrders: 'viewingAllOrders',
  viewingOrdersServiceOnly: 'viewingOrdersServiceOnly',
  viewingOrdersBranch: 'viewingOrdersBranch',
  viewingAllTrips: 'viewingAllTrips',
  viewingTripsServiceOnly: 'viewingTripsServiceOnly',
  viewingTripsBranch: 'viewingTripsBranch',
  viewingAllShifts: 'viewingAllShifts',
  viewingShiftsServiceOnly: 'viewingShiftsServiceOnly',
  viewingShiftsBranch: 'viewingShiftsBranch',
  gager: 'gager',
  handlingEquipment: 'handlingEquipment',
  viewingAllEquipments: 'viewingAllEquipments',
  viewingBranchEquipments: 'viewingBranchEquipments',
  handlingMaintenanceServices: 'handlingMaintenanceServices',
  viewingSystemJournal: 'viewingSystemJournal',
  handlingDriver: 'handlingDriver',
  viewingAllDrivers: 'viewingAllDrivers',
  viewingBranchDrivers: 'viewingBranchDrivers',
  handlingLocation: 'handlingLocation',
  viewingAllLocations: 'viewingAllLocations',
  viewingBranchLocations: 'viewingBranchLocations',
  handlingFuelCard: 'handlingFuelCard',
  viewingAllFuelCards: 'viewingAllFuelCards',
  viewingBranchFuelCards: 'viewingBranchFuelCards',
  firstBit: 'firstBit',
  approvingTimeLimits: 'approvingTimeLimits',
  handlingTimeLimits: 'handlingTimeLimits',
  viewingTimeLimits: 'viewingTimeLimits',
  viewingBranchTimeLimits: 'viewingBranchTimeLimits',
  approvingLimitAssignment: 'approvingLimitAssignment',
  handlingLimitAssignment: 'handlingLimitAssignment',
  updatingDailyBudgets: 'updatingDailyBudgets',
  viewingLimitAssignment: 'viewingLimitAssignment',
  viewingAllLimitAssignment: 'viewingAllLimitAssignment',
  approvingYearLimitsPlan: 'approvingYearLimitsPlan',
  handlingYearLimitsPlan: 'handlingYearLimitsPlan',
  viewingBranchYearLimitsPlan: 'viewingBranchYearLimitsPlan',
  viewingYearLimitsPlan: 'viewingYearLimitsPlan',
  approvingMonthLimitsPlan: 'approvingMonthLimitsPlan',
  handlingMonthLimitsPlan: 'handlingMonthLimitsPlan',
  viewingBranchMonthLimitsPlan: 'viewingBranchMonthLimitsPlan',
  viewingMonthLimitsPlan: 'viewingMonthLimitsPlan',
  viewingJobTitles: 'viewingJobTitles',
  viewingAllJobTitles: 'viewingAllJobTitles',
  handlingJobTitles: 'handlingJobTitles',
  viewingContracts: 'viewingContracts',
  viewingAllContracts: 'viewingAllContracts',
  handlingContracts: 'handlingContracts',
  approvingContracts: 'approvingContracts',
  viewingExternalActs: 'viewingExternalActs',
  viewingAllExternalActs: 'viewingAllExternalActs',
  handlingExternalActs: 'handlingExternalActs',
  approvingExternalActs: 'approvingExternalActs',
  viewingInternalActs: 'viewingInternalActs',
  viewingAllInternalActs: 'viewingAllInternalActs',
  handlingInternalActs: 'handlingInternalActs',
  approvingInternalActs: 'approvingInternalActs',
  viewingBudgetExecutions: 'viewingBudgetExecutions',
  viewingAllBudgetExecutions: 'viewingAllBudgetExecutions',
  viewingAllBranchesOsagoCalculations: 'viewingAllBranchesOsagoCalculations',
  viewingBranchOsagoCalculations: 'viewingBranchOsagoCalculations',
  approvingAllBranchesOsagoCalculations: 'approvingAllBranchesOsagoCalculations',
  approvingBranchOsagoCalculations: 'approvingBranchOsagoCalculations',
  formationBranchOsagoCalculations: 'formationBranchOsagoCalculations',
  formationAllBranchesOsagoCalculations: 'formationAllBranchesOsagoCalculations',
  handlingBudgetAnalysis: 'handlingBudgetAnalysis',
  viewingAllBudgetAnalysis: 'viewingAllBudgetAnalysis',
  viewingBudgetAnalysis: 'viewingBudgetAnalysis',
  budgetAnalysisNotification: 'budgetAnalysisNotification',
  sendingVehiclePlanBudgetToCognos: 'sendingVehiclePlanBudgetToCognos',
  sendingVehiclePlanBudgetRepairToCognos: 'sendingVehiclePlanBudgetRepairToCognos',
  sendingVehiclePlanBudgetTaxToCognos: 'sendingVehiclePlanBudgetTaxToCognos',
  branchBudgetSummaryApprovementNotification: 'branchBudgetSummaryApprovementNotification',
  handlingBranchVehicleTaxPlans: 'handlingBranchVehicleTaxPlans',
  handlingAllVehicleTaxPlans: 'handlingAllVehicleTaxPlans',
  viewingBranchVehicleTaxPlans: 'viewingBranchVehicleTaxPlans',
  viewingAllVehicleTaxPlans: 'viewingAllVehicleTaxPlans',
  handlingPropertyIsAdditionalNeedOfExpenseDirections: 'handlingPropertyIsAdditionalNeedOfExpenseDirections',
  viewingPlaces: 'viewingPlaces',
  viewingAllPlaces: 'viewingAllPlaces',
  requestingPlaces: 'requestingPlaces',
  handlingDetailedBudget: 'handlingDetailedBudget',
  handlingBranchDetailedBudget: 'handlingBranchDetailedBudget',
  viewingDetailedBudget: 'viewingDetailedBudget',
  approvingBranchDetailedBudget: 'approvingBranchDetailedBudget',
  approvingDetailedBudget: 'approvingDetailedBudget',
  viewingAllCognosLog: 'viewingAllCognosLog',
  viewingCognosLog: 'viewingCognosLog',
  decomissingEquipment: 'decomissingEquipment',
  handlingTireUsingLimits: 'handlingTireUsingLimits',
  handlingOrderToReworkReasons: 'handlingOrderToReworkReasons',
  toReworkOrder: 'toReworkOrder',
  createEmergencyOrderOnAnyDate: 'createEmergencyOrderOnAnyDate',
  handlingTripEditLimit: 'handlingTripEditLimit',
  editTaxingTripByOneWorkDay: 'editTaxingTripByOneWorkDay',
  editTaxingTripByNextMonth: 'editTaxingTripByNextMonth',
  viewingBranchPlanFuelConsumption: 'viewingBranchPlanFuelConsumption',
  viewingAllPlanFuelConsumption: 'viewingAllPlanFuelConsumption',
  viewingBranchFactFuelConsumption: 'viewingBranchFactFuelConsumption',
  viewingAllFactFuelConsumption: 'viewingAllFactFuelConsumption',
  sendingFactFuelConsumptionToCognos: 'sendingFactFuelConsumptionToCognos',
  handlingDebitCreditAccount: 'handlingDebitCreditAccount',
  handlingUatToParusOilType: 'handlingUatToParusOilType',
  handlingCognosActualDataSendingTimeline: 'handlingCognosActualDataSendingTimeline',
  handlingAllRegulations: 'handlingAllRegulations',
  editOrderBeyondRework: 'editOrderBeyondRework',
  viewingVehicleModel: 'viewingVehicleModel',
  handlingVehicleModel: 'handlingVehicleModel',
  loadingBudgetFromCognos: 'loadingBudgetFromCognos',
  loadingBudgetFromCognosNotification: 'loadingBudgetFromCognosNotification',
  editingBudgetCopy: 'editingBudgetCopy',
  viewingContractorBranchLink: 'viewingContractorBranchLink',
  handlingContractorBranchLink: 'editingContractorBranchLink',
  viewingBranchContractTrip: 'viewingBranchContractTrip',
  viewingAllContractTrip: 'viewingAllContractTrip',
  handlingContractTrip: 'handlingContractTrip',
  viewingContractTripVehicleLayout: 'viewingContractTripVehicleLayout',
  handlingContractTripVehicleLayout: 'handlingContractTripVehicleLayout',
  copyVehicleToAnotherBranch: 'copyVehicleToAnotherBranch',
  viewingWorkDict: 'viewingWorkDict',
  editingWorkDict: 'editingWorkDict',
  handlingMaintenanceWork: 'handlingMaintenanceWork',
  changeStatusMaintenanceWork: 'changeStatusMaintenanceWork',
  approvementMaintenanceWork: 'approvementMaintenanceWork',
  removingMaintenanceInternalAct: 'removingMaintenanceInternalAct',
  removingDeffectiveStatement: 'removingDeffectiveStatement',
  editingDeffectiveStatement: 'editingDeffectiveStatement',
  editingApprovedVehiclePlan: 'editingApprovedVehiclePlan',
  viewingContractVehicleModel: 'viewingContractVehicleModel',
  handlingContractVehicleModel: 'handlingContractVehicleModel',
  viewingVehicleCategory: 'viewingVehicleCategory',
  handlingVehicleCategory: 'handlingVehicleCategory',
  viewingInventoryCardCompareDate: 'viewingInventoryCardCompareDate',
  editingInventoryCardCompareDate: 'editingInventoryCardCompareDate',
  compareOneInventoryCard: 'compareOneInventoryCard',
  compareAllInventoryCard: 'compareAllInventoryCard',
  compareAllInventoryCardNotification: 'compareAllInventoryCardNotification',
  compareBranchnventoryCardNotification: 'compareBranchnventoryCardNotification',
  viewingAllCompareInventoryCardResult: 'viewingAllCompareInventoryCardResult',
  viewingBranchCompareInventoryCardResult: 'viewingBranchCompareInventoryCardResult',
  viewingCalendarBK: 'viewingCalendarBK',
  handlingCalendarBK: 'handlingCalendarBK',
  handlingSelfOrder: 'handlingSelfOrder',
  editingFuelPriceOnlyInVehicleTariff: 'editingFuelPriceOnlyInVehicleTariff',
  maintenanceWorkChangeStatusNotification: 'maintenanceWorkChangeStatusNotification',
  maintenanceWorkApprovementNotification: 'maintenanceWorkApprovementNotification',
  viewingContractVehicleRequests: 'viewingContractVehicleRequests',
  monitoring: 'monitoring',
  editingMaitenanceNormative: 'editingMaitenanceNormative',
  handlingVehicleWorkMeter: 'handlingVehicleWorkMeter',
  expirationDriverDocNotification: 'expirationDriverDocNotification',
  handlingFuelsAct: 'handlingFuelsAct',
  viewingBranchLocationsToir: 'viewingBranchLocationsToir',
  removingManyLocations: 'removingManyLocations',
  canCancelIntegrationOrder: 'canCancelIntegrationOrder',
  changingStatusBlockedVehicles: 'changingStatusBlockedVehicles',
  changingOrderDispatcherNodeToAllBranches: 'changingOrderDispatcherNodeToAllBranches',
  companiesHandling: 'companiesHandling',
  editingFuelPrice: 'editingFuelPrice',
  viewingOrderCalculation: 'viewingOrderCalculation',
  viewingAllOrderCalculation: 'viewingAllOrderCalculation',
  sendingMaterialsBudgetToMto: 'sendingMaterialsBudgetToMto',
  canCancelGpmOrder: 'canCancelGpmOrder',
  creationIndefiniteOrder: 'creationIndefiniteOrder',
  changingTypeOfVehicleGpmOrder: 'changingTypeOfVehicleGpmOrder',
}
