// @flow

import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { vehiclePlanApi } from './../../lib/api';
import type { VehiclePlan } from '../../lib/types';
import { vehiclePlanInfoString } from './../../lib/helpers';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter?: any };

export default ({ filter = {}, ...selectProps }: Props) => {
  return (
    <AutocompleteSelect
      fetch={async params => {
        return await vehiclePlanApi.fetch({
          ...params,
          ...filter
        });
      }}
      refetchParams={{ filter }}
      fetchSingle={vehiclePlanApi.get}
      notFoundText="Бюджеты не найдены"
      placeholder="Выберите бюджет"
      renderOption={(vehiclePlan: VehiclePlan, Option) => (
        <Option
          key={vehiclePlan.id}
          value={vehiclePlan.id}
          vehiclePlan={vehiclePlan}
        >
          {vehiclePlanInfoString(vehiclePlan)}
        </Option>
      )}
      {...selectProps}
    />
  );
};
