// @flow
import React, {useEffect, useState} from 'react';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';

import {standardOfTireOperationApi} from '../../lib/api';
import type {StandardOfTireOperation} from '../../lib/types';
import type {CommonFormProps} from '../../components/hoc/common/handbook/CommonForm';
import CommonForm from '../../components/hoc/common/handbook/CommonForm';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import {StyledInput} from '../../components/hoc/common/components/elements';
import Button from 'antd/lib/button';

const InnerForm = (props: CommonFormProps<$Shape<StandardOfTireOperation>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField
                  label="Нормативное количество месяцев эксплуатации для типа ТС «Тракторы, экскаваторы»"
                  fast
                  required
                  name="tractorMouthsCount"
                >
                  {({ name, value }) => (
                    <StyledInput
                      name={name}
                      value={value}
                      onChange={e => setFieldValue(name, e.target.value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem fullWidth>
                <Button type="primary" htmlType="submit" data-cy="save">
                  Сохранить
                </Button>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

export default () => {
  const [data: ?StandardOfTireOperation, setData] = useState();
  useEffect(() => {
    const fetch = async () => {
      const response = await standardOfTireOperationApi.fetch();
      setData(response);
    };
    fetch();
  }, []);

  const onSubmit = async (payload: StandardOfTireOperation) => {
    let id = payload.id;
    if (id) {
      setData(payload);
      await standardOfTireOperationApi.update(payload);
    }
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        mainHeader: 'Норматив эксплуатации автомобильных шин'
      }}
      onSubmit={onSubmit}
    >
      <InnerForm data={data} noWrapMe />
    </CommonCardPage>
  );
};
