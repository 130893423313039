// @flow
import React from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import {Card, OrgUnitSelect, Selects} from './../../../components';
import Grid, {GridItem} from './../../../components/layout/Grid';
import {Spoiler} from './../../../components/ui';
import {Filter} from './../../../components/hoc';
import type {FilterChildrenParams} from './../../../components/hoc/Filter';

const { Field } = Card;

const Content = styled.div`
  padding: 16px;
`;
const Buttons = styled(GridItem)`
  margin: 0 -10px;
  & .ant-btn {
    margin: 0 10px;
  }
`;
const { VehicleModelSelect, ContractorSelect } = Selects;

export type WashPriceFilterParams = {
  nodeId?: number,
  vehicleModelId?: number,
  contractNumber?: string,
  contractorId?: number
};

type Props = {
  filter: WashPriceFilterParams,
  applyFilter: Function,
  cleanFilter: Function
};

const UserFilter = ({ filter, applyFilter, cleanFilter }: Props) => (
  <Spoiler label="Фильтр">
    <Content>
      <Grid gutter="16px">
        <Filter
          initialValues={filter}
          applyFilter={applyFilter}
          cleanFilter={cleanFilter}
        >
          {({
            values,
            changeValue,
            applyFilter,
            cleanFilter
          }: FilterChildrenParams<WashPriceFilterParams>) => (
            <>
              <GridItem span={1}>
                <Field label="Филиалы">
                  <OrgUnitSelect
                    filter={{ onlyBranch: true }}
                    value={values.nodeId}
                    onChange={(value: number) => changeValue('nodeId', value)}
                    data-cy="orgUnitSelect"
                    isFilter={true}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Модель ТС">
                  <VehicleModelSelect
                    value={values.vehicleModelId}
                    name="vehicleModelId"
                    data-cy="modelSelect"
                    onChange={(value: number) =>
                      changeValue('vehicleModelId', value)
                    }
                    placeholder="Выберите модель"
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Подрядчик">
                  <ContractorSelect
                    value={values.contractorId}
                    name="contractorId"
                    data-cy="contractorId"
                    onChange={value => changeValue('contractorId', value)}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Номер договора">
                  <Input
                    value={values.contractNumber}
                    name="contractNumber"
                    data-cy="contractNumber"
                    onChange={({ target: { value } }) =>
                      changeValue('contractNumber', value)
                    }
                  />
                </Field>
              </GridItem>
              <Buttons fullWidth>
                <Button type="primary" onClick={applyFilter}>
                  Применить
                </Button>
                <Button type="plain" onClick={cleanFilter}>
                  Очистить
                </Button>
              </Buttons>
            </>
          )}
        </Filter>
      </Grid>
    </Content>
  </Spoiler>
);

export default UserFilter;
