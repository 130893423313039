// @flow
import React, { Component } from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import styled from 'styled-components';
import moment from 'moment';

import { Card } from './../../../components';
import { getDisabledEqipmentInstallRemoveDate } from './../../../containers/Equipment/lib';

const { Field } = Card;
const PopconfirmTitleWrapper = styled.div``;
const ErrorText = styled.p`
  color: red;
  margin: 5px 0;
`;

const getMomentValue = (value: ?moment) => (value ? moment.utc(value) : value);

const convertDateToString = (value: Object, dateString: string): Date => {
  if (value) {
    return moment.utc(dateString, 'DD.MM.YYYY').toISOString();
  }
  return value;
};

type PopconfirmTitleProps = {
  onChange: (value: Object, dateString: string) => void,
  dateInstall?: Date,
  error?: string,
  disableDate?: boolean
};

const PopconfirmTitle = ({
  onChange,
  dateInstall,
  error,
  disableDate
}: PopconfirmTitleProps) => (
  <PopconfirmTitleWrapper>
    <Field label="Дата установки">
      <DatePicker
        format="DD.MM.YYYY"
        value={getMomentValue(dateInstall)}
        onChange={onChange}
        disabledDate={disableDate && getDisabledEqipmentInstallRemoveDate}
      />
      {error && <ErrorText>{error}</ErrorText>}
    </Field>
    <p>Подтверждаете дату установки оборудования?</p>
  </PopconfirmTitleWrapper>
);

type Props = {
  onConfirm: (installDate: Date) => void | Promise<void>,
  disabled: boolean,
  disableDate?: boolean
};

type State = {
  installDate: Date,
  visible: boolean,
  errors: Object
};

const initialState = {
  installDate: moment().toISOString(),
  visible: false,
  errors: {}
};

export default class AddEquipmentButton extends Component<Props, State> {
  state = initialState;

  static defaultProps = {
    disableDate: true
  };

  confirm = () => {
    if (moment(this.state.installDate).isValid) {
      this.props.onConfirm(this.state.installDate);
      this.setState(initialState);
    } else {
      this.setState({
        errors: {
          installDate: 'Неверная дата установки оборудования'
        }
      });
    }
  };

  showPopover = () =>
    this.setState({
      visible: true
    });

  hidePopover = () => this.setState(initialState);

  render() {
    const { installDate, visible, errors } = this.state;
    const { disabled, disableDate } = this.props;
    return (
      <Popconfirm
        icon={null}
        title={
          <PopconfirmTitle
            disableDate={disableDate}
            onChange={(value: Object, dateString: string) =>
              this.setState({
                installDate: convertDateToString(value, dateString)
              })
            }
            dateInstall={installDate}
            error={errors.installDate}
          />
        }
        visible={visible}
        okText="Да"
        placement="topLeft"
        cancelText="Нет"
        onConfirm={this.confirm}
        onCancel={this.hidePopover}
        overlayClassName="without-icon"
      >
        <Button onClick={this.showPopover} type="primary" disabled={disabled}>
          Добавить
        </Button>
      </Popconfirm>
    );
  }
}
