/* @flow */

import {Redirect, Router} from '@reach/router';
import Spin from 'antd/lib/spin';
import React, {Component, createElement} from 'react';
import {connect} from 'react-redux';
import styled, {css} from 'styled-components';
import {ErrorHandler, NotFound} from './components';
import AdvantumHack from './components/AdvantumHack';
import {Sidebar} from './components/layout';
import Burger from './components/ui/Burger';
import Act, {CompletedWorksCard, DefectiveResourceStatementCard} from './containers/Act';
import {BrandForm, BrandList} from './containers/Brands';
import {
  BudgetAnalysisCard,
  BudgetAnalysisForm,
  BudgetAnalysisInfo,
  BudgetAnalysisList,
} from './containers/budget/budgetAnalysis';
import {ContractVehicleBudget} from './containers/budget/contractVehicle';
import {BudgetDetailForm, BudgetDetailList} from './containers/budget/details';
import {ExecutionBudget} from './containers/budget/executionBudget';
import {
  MonthlyWashingPlanCard,
  MonthlyWashingPlanForm,
  MonthlyWashingPlanList,
} from './containers/budget/monthlyWashingPlans';
import {
  OsagoCalculationForm,
  OsagoCalculationFreeVehicleForm,
  OsagoCalculationList,
  OsagoCalculationListForm,
  OsagoCalulationsByYearList,
} from './containers/budget/osagoCalculations';
import {BudgetSummaryContractRegistryForm, BudgetSummaryForm, BudgetSummaryList} from './containers/budget/summary';
import {TaxCard, TaxForm, TaxList} from './containers/budget/tax';
import {VehicleBudget} from './containers/budget/vehicle';
import {BudgetsForAnalysisCard, BudgetsForAnalysisForm, BudgetsForAnalysisList} from './containers/BudgetsForAnalysis';
import {BudgetVersionCard, BudgetVersionForm, BudgetVersionList} from './containers/BudgetVersion';
import {BusinessCalendar, BusinessDayForm} from './containers/BusinessCalendar';
import {BusinessCalendarForBudget, BusinessCalendarForBudgetForm} from './containers/BusinessCalendar/ForBudget';
import {CognosLogCard, CognosLogList} from './containers/CognosLog';
import {CompaniesForm, CompaniesList} from './containers/Companies';
import {
  CompareExpenseDirectionCard,
  CompareExpenseDirectionForm,
  CompareExpenseDirectionList,
} from './containers/CompareExpenseDirection';
import {ContractCard, ContractForm, ContractList} from './containers/Contract';
import {ContractorsForm, ContractorsList} from './containers/Contractors';
import {ContractVehicleOrderForm} from './containers/ContractVehicleOrder';
import {ContractVehicleCard, ContractVehiclesForm, ContractVehiclesList} from './containers/ContractVehicles';
import ContractVehicleWorkPlans from './containers/ContractVehicleWorkPlans';
import {
  CurrentMaintenanceCostCard,
  CurrentMaintenanceCostForm,
  CurrentMaintenanceCostList,
} from './containers/CurrentMaintenanceCost';
import {DriverForm, DriverList} from './containers/Driver';
import {EmployeeForm, EmployeeList} from './containers/Employees';
import {
  AttachedEquipmentCard,
  AttachedEquipmentsForm,
  AttachedEquipmentsList,
  BatteriesForm,
  BatteriesList,
  BatteryCard,
  OptionalEquipmentCard,
  OptionalEquipmentForm,
  OptionalEquipmentList,
  TireCard,
  TiresForm,
  TiresList,
} from './containers/Equipment';
import ExpenseDirection from './containers/expenseDirection';
import {
  ExpertiseServiceGpmCostCard,
  ExpertiseServiceGpmCostForm,
  ExpertiseServiceGpmCostList,
} from './containers/ExpertiseServiceGpmCost';
import {FederalHighwayFareForm, FederalHighwayFareList} from './containers/FederalHighwayFare';
import {FuelCardsCard, FuelCardsForm, FuelCardsList} from './containers/FuelCards';
import {FuelMultiplierForm, FuelMultipliersList} from './containers/FuelMultipliers';
import {FuelsAndOilsForm, FuelsAndOilsList} from './containers/FuelsAndOils';
import {GpsSubscriptionServiceCostForm, GpsSubscriptionServiceCostList} from './containers/GpsSubscriptionServiceCost';
import {InspectionGibddCostCard, InspectionGibddCostForm, InspectionGibddCostList} from './containers/InspectionGibddCost';
import {InspectionGtnCostCard, InspectionGtnCostForm, InspectionGtnCostList} from './containers/InspectionGtnCost';
import {InstructionList} from './containers/Instructions';
import {ISUBudgetingDataForm, ISUBudgetingDataList} from './containers/ISUBudgetingData';
import {JobTitleForm, JobTitleList} from './containers/JobTitle';
import {JournalCard, JournalList} from './containers/Journal';
import {
  LicensePlateChangeCostCard,
  LicensePlateChangeCostForm,
  LicensePlateChangeCostList,
} from './containers/LicensePlateChangeCost';
import {LocationForm, LocationList} from './containers/Locations';
import {
  MaintenanceCard,
  MaintenanceCardReadOnly,
  MaintenanceFiles,
  MaintenanceForm,
  MaintenanceList,
  MaintenanceOrder,
} from './containers/Maintenance';
import {
  MaintenanceCostAllocationCard,
  MaintenanceCostAllocationForm,
  MaintenanceCostAllocationList,
} from './containers/MaintenanceCostAllocation';
import {
  MaintenanceCostAllocationTRCard,
  MaintenanceCostAllocationTRForm,
  MaintenanceCostAllocationTRList,
} from './containers/MaintenanceCostAllocationTR';
import {MaintenanceMatrixCard, MaintenanceMatrixForm, MaintenanceMatrixList} from './containers/MaintenanceMatrix';
import {
  MaintenanceOperarionPriceList,
  MaintenanceOperationPriceCard,
  MaintenanceOperationPriceForm,
} from './containers/MaintenanceOperationPrice';
import {
  MaintenanceOperationCard,
  MaintenanceOperationForm,
  MaintenanceOperationsTable,
} from './containers/MaintenanceOperations';
import {MaintenanceTypeWorkCard, MaintenanceTypeWorkForm, MaintenanceTypeWorkList} from './containers/MaintenanceWorkType';
import {MdmForm, MdmList} from './containers/Mdm';
import {
  MeasuringDeviceCertificationCostCard,
  MeasuringDeviceCertificationCostForm,
  MeasuringDeviceCertificationCostList,
} from './containers/MeasuringDeviceCertificationCost';
import {MonitoringPriceCard, MonitoringPriceForm, MonitoringPriceList} from './containers/MonitoringPrice';
import {OkeiCard, OkeiForm, OkeiList} from './containers/Okei';
import {OrderCard, OrderForm, OrderList, CalculationCard} from './containers/Orders';
import {OrgUnitForm, OrgUnitTable} from './containers/OrgUnits';
import {
  BaseRateMultiplier,
  OsagoBranchMultiplier,
  OsagoGeneralMultiplier,
  OsagoKprMultiplier,
  OsagoPowerMultiplier,
} from './containers/osagoMultiplier';
import {OverhaulTypeCard, OverhaulTypeForm, OverhaulTypeList} from './containers/OverhaulType';
import {PassTariffCard, PassTariffForm, PassTariffList} from './containers/PassTariff';
import {PlaceCard, PlaceList} from './containers/Place';
import {FuelPriceForm, FuelPriceFormViewMode, FuelPriceList} from './containers/priceLists';
import {RegistrationPlaceForm, RegistrationPlaceList} from './containers/RegistrationPlace';
import {
  RegisterContractTrips,
  ReportArchiveVehiclesList,
  ReportBusinessTripList,
  ReportComparingTransportServicesList,
  ReportDriversList,
  ReportFuelCardsList,
  ReportFuelsAndOilsList,
  ReportGpmOrderList,
  ReportScheduleMaintenanceList,
  ReportTaxedTripsList,
  ReportTaxedTripsElectricList,
  ReportTransportConsumptionsJournalList,
  ReportTripsRegistryList,
  ReportVehicleOutputRatioList,
  ReportVehicleWorksList,
  ReportWeekendOrdersList,
} from './containers/Reports';
import {RoleForm, RoleList} from './containers/Roles';
import {RepairOrderCard, RepairOrderForm} from './containers/ServiceOrders';
import {
  DriverInspectionForm,
  DriverInspectionList,
  ExpertiseServiceGpmForm,
  ExpertiseServiceGpmList,
  InspectionGibddForm,
  InspectionGibddList,
  InspectionGtnForm,
  InspectionGtnList,
  LicensePlateChangeForm,
  LicensePlateChangeList,
  MeasuringDeviceCertificationForm,
  MeasuringDeviceCertificationList,
  OsagoForm,
  OsagoList,
  PassForm,
  PassList,
  PlatonForm,
  PlatonList,
  VehicleMonitoringForm,
  VehicleMonitoringList,
  WashingList,
} from './containers/services';
import {DriverInspectionCard} from './containers/services/driverInspections';
import {ExpertiseServiceGpmCard} from './containers/services/expertisesServicesGpm';
import {InspectionGibddCard} from './containers/services/inspectionsGibdd';
import {InspectionGtnCard} from './containers/services/inspectionsGtn';
import {LicensePlateChangeCard} from './containers/services/licensePlateChange';
import {MeasuringDeviceCertificationCard} from './containers/services/measuringDeviceCertification';
import {OsagoCard} from './containers/services/osago';
import {PassCard} from './containers/services/pass';
import {PlatonCard} from './containers/services/platon';
import {VehicleMonitoringCard} from './containers/services/vehicleMonitoring';
import {WashingCard} from './containers/services/washings';
import {ShiftCard, ShiftList} from './containers/Shifts';
import {
  SmallScaleMechanizationToolCard,
  SmallScaleMechanizationToolForm,
  SmallScaleMechanizationToolList,
} from './containers/SmallScaleMechanizationTool';
import {StockForm, StockList} from './containers/Stock';
import {StockPartCard, StockPartList} from './containers/StockPart';
import {StouCard, StouForm, StouList} from './containers/Stou';
import SubscriptionsCard from './containers/Subscriptions/Card';
import {TripInspectionCostCard, TripInspectionCostForm, TripInspectionCostList} from './containers/TripInspectionCost';
import {
  TripInspectionFrequencyCard,
  TripInspectionFrequencyForm,
  TripInspectionFrequencyList,
} from './containers/TripInspectionFrequency';
import {
  ContractTripCard,
  ContractTripLayout,
  ContractTripList,
  SelfTripCard,
  SelfTripList,
  TripForm,
  TripVerification,
} from './containers/Trips';
import {UserForm, UserList} from './containers/Users';
import {VehicleIssueGroupForm, VehicleIssueGroupList} from './containers/VehicleIssueGroup';
import {VehicleModelForm, VehicleModelList} from './containers/VehicleModels';
import {
  VehicleAccountant,
  VehicleAttachment,
  VehicleBudgetEdit,
  VehicleCard,
  VehicleChangeTools,
  VehicleDriver,
  VehicleFiles,
  VehicleForm,
  VehicleFuel,
  VehicleFuelsEndOilsForm,
  VehicleGpm,
  VehicleKilometrage,
  VehicleList,
  VehicleMaintenance,
  VehicleNumbers,
  VehicleOsago,
  VehicleOverhaul,
  VehiclePlanedMaintenance,
  VehiclePrimary,
  VehicleTariffs,
  VehicleTrailers,
} from './containers/Vehicles';
import {VehicleTariffCard, VehicleTariffForm, VehicleTariffFormClone, VehicleTariffList} from './containers/vehicleTariff';
import {VehicleTaxMapCard, VehicleTaxMapForm, VehicleTaxMapList} from './containers/VehicleTaxMap';
import {VehicleTaxRateCard, VehicleTaxRateForm, VehicleTaxRateList} from './containers/VehicleTaxRate';
import {WashingPriceListCard, WashingPriceListForm, WashingPriceListTable} from './containers/WashingPriceList';
import {WashingForm} from './containers/Washings';
import StandardOfTireOperation from './containers/StandardOfTireOperation/StandardOfTireOperation';
import {fetchProfile, type Profile} from './ducks/auth';
import {toggleSidebar} from './ducks/sidebar';
import {accessTypeEnum} from './lib/enum';
import tokenManager from './lib/tokenManager';
import type {UserAccess} from './lib/types';
import {ReasonsForRejectingOfOrder, ReasonsForRejectingOfOrderForm} from './containers/ReasonsForRejectionOfOrder';
import DeadlinesForMakingChangesOnVerifiedTrips
  from './containers/DeadlinesForMakingChangesOnVerifiedTrips/DeadlinesForMakingChangesOnVerifiedTrips';
import {DebetCreditAccounts, DebetCreditAccountsForm} from './containers/DebetCreditAccounts';
import {CalendarForm, DeadlinesForm, WorkingWithBudgetingList} from './containers/WorkingWithBudgeting';
import {TypesOfOils, TypesOfOilsForm} from './containers/TypesOfOils';
import {PlannedFuelConsumption, PlannedFuelConsumptionReport} from './containers/PlannedFuelConsumption';
import {ActualFuelConsumption, ActualFuelConsumptionReport} from './containers/ActualFuelConsumption';
import {OilConsumptionAccountingCard} from './containers/Act/internal';
import {SecuringContractorsForm, SecuringContractorsList} from './containers/SecuringContractors';
import {TypesOfRepairsCard, TypesOfRepairsForm, TypesOfRepairsList} from './containers/TypesOfRepairs';
import {MaintenanceWorkCard, MaintenanceWorkForm, MaintenanceWorkList} from './containers/MaintenanceWorks';
import {ModelMatchesCard, ModelMatchesForm, ModelMatchesList} from './containers/ModelMatches';
import {CompareDatesForm, CompareDatesList} from './containers/CompareDates';
import {InventoryCardCompareList} from './containers/InventoryCardCompare';
import VehicleCategoriesList from './containers/VehicleCategories/VehicleCategoriesList';
import VehicleCategoriesForm from './containers/VehicleCategories/VehicleCategoriesForm';
import WriteOffFuelAct from './containers/Act/internal/fuel-acts/WriteOffFuelAct';
import LocationFormView from './containers/Locations/LocationFormView';

/*####APP_LAYOUT_IMPORT*/

const StyledBurger = styled(Burger)`
  position: fixed;
  z-index: 1000;
  border-radius: 50%;
  padding: 16px;
  ${props =>
          !props.expanded &&
          css`
            position: absolute;
          `};
`;

type Props = {
  fetchProfile: () => void,
  toggleSidebar: Function,
  sidebarOpened: boolean,
  profile: Profile
};

type State = {
  appState: 'loading' | 'authError' | 'normal'
};

// клеймы для редактирования прейскуранта
const priceDirectoryEdit = [
  accessTypeEnum.handlingPriceLists,
  accessTypeEnum.approvingPriceLists,
];
// клеймы для простомотра прейскурантов
const priceDirectory = [
  ...priceDirectoryEdit,
  accessTypeEnum.viewingAllPriceLists,
  accessTypeEnum.viewingPriceLists,
];
// клеймы для редактирования ремонтов
const maintenanceEdit = [
  accessTypeEnum.approvingMaintenances,
  accessTypeEnum.approvingDetailedDefectiveStatements,
  accessTypeEnum.approvingDefectiveStatements,
  accessTypeEnum.creatingMaintenances,
];
// клеймы для просмотра ремонтов
const maintenance = [
  ...maintenanceEdit,
  accessTypeEnum.viewingMaintenances,
  accessTypeEnum.viewingAllMaintenances,
];

export class AppLayout extends Component<Props, State> {
  token: string;

  constructor(props: Props) {
    super(props);
    this.token = tokenManager.getToken();

    this.state = {
      appState: 'loading',
    };
  }

  static defaultProps = {
    profile: {
      access: [],
    },
  };

  async componentDidMount() {
    if (this.token) {
      await this.fetchCurrentUserProfile();
    }
  }

  async fetchCurrentUserProfile() {
    try {
      await this.props.fetchProfile();
      this.setState({appState: 'normal'});
    } catch (err) {
      this.setState({appState: 'authError'});
      console.error(err && err.message ? err.message : 'App init error');
    }
  }

  routes = [
    // место регистрации ТС
    {
      component: RegistrationPlaceList,
      path: '/admin/registration-place',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: RegistrationPlaceForm,
      path: '/admin/registration-place/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: RegistrationPlaceForm,
      path: '/admin/registration-place/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    //   реестр первичных документов(Акты)
    {
      component: Act,
      path: '/admin/act/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingExternalActs,
        accessTypeEnum.viewingAllExternalActs,
        accessTypeEnum.handlingExternalActs,
        accessTypeEnum.viewingInternalActs,
        accessTypeEnum.viewingAllInternalActs,
        accessTypeEnum.handlingInternalActs,
        accessTypeEnum.viewingBranchFactFuelConsumption,
        accessTypeEnum.viewingAllFactFuelConsumption,
      ],
    },
    // реестр договоров
    {
      component: ContractList,
      path: '/admin/contract/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingContracts,
        accessTypeEnum.viewingAllContracts,
        accessTypeEnum.handlingContracts,
      ],
    },
    {
      component: ContractForm,
      path: '/admin/contract/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.handlingContracts,
      ],
    },
    {
      component: ContractCard,
      path: '/admin/contract/:contractId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingContracts,
        accessTypeEnum.viewingAllContracts,
      ],
    },
    {
      component: ContractForm,
      path: '/admin/contract/:contractId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.handlingContracts,
      ],
    },
    // матрица ремонтов
    {
      component: MaintenanceMatrixList,
      path: '/admin/maintenance-matrix',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingMaintenanceMatrix,
        accessTypeEnum.handlingMaintenanceMatrix,
      ],
    },
    {
      component: MaintenanceMatrixCard,
      path: '/admin/maintenance-matrix/:maintenanceMatrixId',
      access: [accessTypeEnum.admin, accessTypeEnum.viewingMaintenanceMatrix],
    },
    {
      component: MaintenanceMatrixForm,
      path: '/admin/maintenance-matrix/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingMaintenanceMatrix],
    },
    {
      component: MaintenanceMatrixForm,
      path: '/admin/maintenance-matrix/:maintenanceMatrixId/edit',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingMaintenanceMatrix],
    },
    // прейскурант ГСМ
    {
      component: FuelPriceList,
      path: '/admin/fuel-price',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectory,
      ],
    },
    {
      component: FuelPriceForm,
      path: '/admin/fuel-price/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.editingFuelPrice
      ],
    },
    {
      component: FuelPriceFormViewMode,
      path: '/admin/fuel-price/:fuelPriceId/view',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectory,
      ],
    },
    {
      component: FuelPriceForm,
      path: '/admin/fuel-price/:fuelPriceId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.editingFuelPrice
      ],
    },
    {
      component: FuelPriceForm,
      path: '/admin/fuel-price/:fuelPriceId/clone',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.editingFuelPrice
      ],
    },
    // тарифы на НТС
    {
      component: VehicleTariffList,
      path: '/admin/vehicle-tariff/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingVehicleTariff,
      ],
    },
    {
      component: VehicleTariffCard,
      path: '/admin/vehicle-tariff/tariff/:version/:vehicleTariffId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingVehicleTariff,
      ],
    },
    {
      component: VehicleTariffForm,
      path: '/admin/vehicle-tariff/tariff/:version/:vehicleTariffId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingVehicleTariff,
      ],
    },
    {
      component: VehicleTariffFormClone,
      path: '/admin/vehicle-tariff/tariff/:version/:vehicleTariffId/edit/clone',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingVehicleTariff,
      ],
    },
    // прейскурант на стоимость видов работ по филиалам
    {
      component: MaintenanceOperarionPriceList,
      path: '/admin/maintenance-operation-price',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectory,
      ],
    },
    {
      component: MaintenanceOperationPriceForm,
      path: '/admin/maintenance-operation-price/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectoryEdit,
      ],
    },
    {
      component: MaintenanceOperationPriceForm,
      path:
        '/admin/maintenance-operation-price/:maintenanceOperationPriceId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectoryEdit,
      ],
    },
    {
      component: MaintenanceOperationPriceCard,
      path: '/admin/maintenance-operation-price/:maintenanceOperationPriceId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.newFeaturesDemo,
        ...priceDirectoryEdit,
      ],
    },
    // виды работ по обслуживанию тс
    {
      component: MaintenanceTypeWorkList,
      path: '/admin/maintenance-work-type',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingMaintenanceWorkTypes,
        accessTypeEnum.handlingMaintenanceWorkTypes,
      ],
    },
    {
      component: MaintenanceTypeWorkCard,
      path: '/admin/maintenance-work-type/:maintenanceWorkTypeId',
      access: [accessTypeEnum.admin, accessTypeEnum.viewingMaintenanceWorkTypes],
    },
    {
      component: MaintenanceTypeWorkForm,
      path: '/admin/maintenance-work-type/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWorkTypes,
      ],
    },
    {
      component: MaintenanceTypeWorkForm,
      path: '/admin/maintenance-work-type/:maintenanceWorkTypeId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWorkTypes,
      ],
    },
    // сопоставление направления расходов
    {
      component: CompareExpenseDirectionList,
      path: '/admin/compare-expense-direction',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingCompareExpenseDirections,
        accessTypeEnum.handlingCompareExpenseDirections,
      ],
    },
    {
      component: CompareExpenseDirectionForm,
      path: '/admin/compare-expense-direction/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCompareExpenseDirections,
      ],
    },
    {
      component: CompareExpenseDirectionForm,
      path: '/admin/compare-expense-direction/:compareExpenseDirectionId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCompareExpenseDirections,
      ],
    },
    {
      component: CompareExpenseDirectionCard,
      path: '/admin/compare-expense-direction/:compareExpenseDirectionId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCompareExpenseDirections,
      ],
    },
    // направление расходов
    {
      component: ExpenseDirection,
      path: '/admin/expense-direction/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingExpenseDirections,
        accessTypeEnum.handlingExpenseDirections,
        accessTypeEnum.handlingContracts,
      ],
    },
    // склад
    {
      component: StockPartList,
      path: '/admin/stock-part',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingStocksAndStockParts,
        accessTypeEnum.viewingAllStocksAndStockParts,
      ],
    },
    {
      component: StockPartCard,
      path: '/admin/stock-part/:stockPartId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingStocksAndStockParts,
        accessTypeEnum.viewingAllStocksAndStockParts,
      ],
    },
    //прайсы для мониторинга
    {
      component: MonitoringPriceList,
      path: '/admin/monitoring-price',
      access: [accessTypeEnum.admin, ...priceDirectory],
    },
    {
      component: MonitoringPriceForm,
      path: '/admin/monitoring-price/new',
      access: [accessTypeEnum.admin, ...priceDirectoryEdit],
    },
    {
      component: MonitoringPriceForm,
      path: '/admin/monitoring-price/:id/edit',
      access: [accessTypeEnum.admin, ...priceDirectoryEdit],
    },
    {
      component: MonitoringPriceCard,
      path: '/admin/monitoring-price/:id',
      access: [accessTypeEnum.admin, ...priceDirectory],
    },
    //группы дефектов
    {
      component: VehicleIssueGroupList,
      path: '/admin/vehicle-issue-group',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingVehicleIssueGroups,
        accessTypeEnum.handlingVehicleIssueGroups,
      ],
    },
    {
      component: VehicleIssueGroupForm,
      path: '/admin/vehicle-issue-group/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingVehicleIssueGroups],
    },
    {
      component: VehicleIssueGroupForm,
      path: '/admin/vehicle-issue-group/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingVehicleIssueGroups],
    },
    //коэффициенты осаго
    {
      component: OsagoPowerMultiplier.OsagoPowerMultiplierList,
      path: '/admin/osago-multiplier/power',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoPowerMultiplier.OsagoPowerMultiplierForm,
      path: '/admin/osago-multiplier/power/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoPowerMultiplier.OsagoPowerMultiplierForm,
      path: '/admin/osago-multiplier/power/edit/:osagoPowerMultiplierId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoGeneralMultiplier.OsagoGeneralMultiplierList,
      path: '/admin/osago-multiplier/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoGeneralMultiplier.OsagoGeneralMultiplierForm,
      path: '/admin/osago-multiplier/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoGeneralMultiplier.OsagoGeneralMultiplierForm,
      path: '/admin/osago-multiplier/edit/:osagoGeneralMultiplierId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoKprMultiplier.OsagoKprMultiplierList,
      path: '/admin/osago-multiplier/kpr',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoKprMultiplier.OsagoKprMultiplierForm,
      path: '/admin/osago-multiplier/kpr/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoKprMultiplier.OsagoKprMultiplierForm,
      path: '/admin/osago-multiplier/kpr/edit/:osagoKprMultiplierId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoBranchMultiplier.OsagoBranchMultiplierList,
      path: '/admin/osago-multiplier/branch',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoBranchMultiplier.OsagoBranchMultiplierForm,
      path: '/admin/osago-multiplier/branch/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: OsagoBranchMultiplier.OsagoBranchMultiplierForm,
      path: '/admin/osago-multiplier/branch/edit/:osagoBranchMultiplierId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: BaseRateMultiplier.BaseRateMultiplierList,
      path: '/admin/osago-multiplier/base-rate',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: BaseRateMultiplier.BaseRateMultiplierForm,
      path: '/admin/osago-multiplier/base-rate/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    {
      component: BaseRateMultiplier.BaseRateMultiplierForm,
      path: '/admin/osago-multiplier/base-rate/edit/:osagoBaseRateMultiplierId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingOsagoMultipliers,
        accessTypeEnum.handlingOsagoMultipliers,
      ],
    },
    //
    {
      component: AdvantumHack,
      path: '/distance-tracking',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.monitoring,
      ],
    },
    // ремонты
    {
      component: MaintenanceList,
      path: '/maintenances',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch, ...maintenance],
    },
    {
      component: MaintenanceFiles,
      path: '/maintenances/:vehicleIssueId/edit/files/:tag',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        ...maintenanceEdit,
      ],
    },
    {
      component: MaintenanceCardReadOnly,
      path: '/maintenances/read/:maintenanceId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenances,
        accessTypeEnum.viewingAllMaintenances,
      ],
    },
    {
      component: MaintenanceForm,
      path: '/maintenances/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingMaintenances,
      ],
    },
    {
      component: MaintenanceCard,
      path: '/maintenances/:maintenanceId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        ...maintenanceEdit,
      ],
    },
    {
      component: MaintenanceOrder,
      path: '/maintenances/:maintenanceId/order',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        ...maintenanceEdit,
      ],
    },
    //
    {
      component: WashingForm,
      path: '/services/washings/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
        accessTypeEnum.newFeaturesDemo,
      ],
    },
    {
      component: WashingForm,
      path: '/services/washings/new/vehicles/:vehicleId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: WashingCard,
      path: '/services/washings/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: WashingForm,
      path: '/services/washings/:washingId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
        accessTypeEnum.newFeaturesDemo,
      ],
    },
    {
      component: WashingList,
      path: '/services/washings',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: RepairOrderForm,
      path: '/services/repair-order/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
      ],
    },
    {
      component: RepairOrderForm,
      path: '/services/repair-order/edit/:repairOrderId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
      ],
    },
    {
      component: RepairOrderCard,
      path: '/services/repair-order/:repairOrderId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
      ],
    },
    {
      component: MaintenanceOperationsTable,
      path: '/admin/maintenance-operations',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.handlingMaintenanceWork,
      ],
    },
    {
      component: MaintenanceOperationCard,
      path: '/admin/maintenance-operations/:maintenanceOperationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.handlingMaintenanceWork,
      ],
    },
    {
      component: MaintenanceOperationForm,
      path: '/admin/maintenance-operations/:maintenanceOperationId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.handlingMaintenanceWork,
      ],
    },
    {
      component: MaintenanceOperationForm,
      path:
        '/admin/maintenance-operations/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.handlingMaintenanceWork,
      ],
    },
    {
      component: WashingPriceListForm,
      path: '/admin/washing-price-list/new',
      access: [accessTypeEnum.admin, ...priceDirectoryEdit],
    },
    {
      component: WashingPriceListForm,
      path: '/admin/washing-price-list/:washingPriceListId/edit',
      access: [accessTypeEnum.admin, ...priceDirectoryEdit],
    },
    {
      component: WashingPriceListForm,
      path: '/admin/washing-price-list/:copyWashingPriceListId/copy',
      access: [accessTypeEnum.admin, ...priceDirectoryEdit],
    },
    {
      component: WashingPriceListTable,
      path: '/admin/washing-price-list',
      access: [accessTypeEnum.admin, ...priceDirectory],
    },
    {
      component: WashingPriceListCard,
      path: '/admin/washing-price-list/:washingPriceListId',
      access: [accessTypeEnum.admin, ...priceDirectory],
    },
    {
      component: FuelCardsForm,
      path: '/admin/fuel-cards/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingFuelCard],
    },
    {
      component: FuelCardsCard,
      path: '/admin/fuel-cards/:fuelCardId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingFuelCard,
        accessTypeEnum.viewingAllFuelCards,
        accessTypeEnum.viewingBranchFuelCards,
      ],
    },
    {
      component: FuelCardsForm,
      path: '/admin/fuel-cards/:fuelCardId/edit',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingFuelCard],
    },
    {
      component: FuelCardsList,
      path: '/admin/fuel-cards',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingFuelCard,
        accessTypeEnum.viewingAllFuelCards,
        accessTypeEnum.viewingBranchFuelCards,
      ],
    },
    {
      component: BrandForm,
      path: '/admin/brands/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: BrandForm,
      path: '/admin/brands/:brandId',
      access: [accessTypeEnum.admin],
    },
    {
      component: BrandList,
      path: '/admin/brands',
      access: [accessTypeEnum.admin],
    },
    {
      component: UserList,
      path: '/admin/users',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: UserForm,
      path: '/admin/users/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: UserForm,
      path: '/admin/users/:userId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: RoleList,
      path: '/admin/roles',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: RoleForm,
      path: '/admin/roles/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: RoleForm,
      path: '/admin/roles/:roleId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OrgUnitTable,
      path: '/admin/org-units',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OrgUnitForm,
      path: '/admin/org-units/:orgUnitId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OrgUnitForm,
      path: '/admin/org-units/:parentOrgUnitId/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: EmployeeList,
      path: '/admin/employees',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEmployees,
      ],
    },
    {
      component: EmployeeForm,
      path: '/admin/employees/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEmployees,
      ],
    },
    {
      component: EmployeeForm,
      path: '/admin/employees/:employeeId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEmployees,
      ],
    },
    {
      component: LocationList,
      path: '/admin/locations/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingLocation,
        accessTypeEnum.viewingAllLocations,
        accessTypeEnum.viewingBranchLocations,
      ],
    },
    {
      component: LocationForm,
      path: '/admin/locations/form/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingLocation,
      ],
    },
    {
      component: LocationForm,
      path: '/admin/locations/form/:locationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingLocation,
      ],
    },
    {
      component: LocationFormView,
      path: '/admin/locations/form/view/:locationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingAllLocations,
        accessTypeEnum.viewingBranchLocations,
      ],
    },
    {
      component: DriverList,
      path: '/admin/drivers/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDriver,
        accessTypeEnum.viewingAllDrivers,
        accessTypeEnum.viewingBranchDrivers,
      ],
    },
    {
      component: DriverForm,
      path: '/admin/drivers/form/:driverId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDriver,
      ],
    },
    {
      component: DriverForm,
      path: '/admin/drivers/form/:currentDriverType/new/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDriver,
      ],
    },
    {
      component: BatteriesList,
      path: '/equipment/batteries',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: BatteriesForm,
      path: '/equipment/batteries/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
      component: BatteriesForm,
      path: '/equipment/batteries/new/vehicle/:vehicleId',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },

    {
      component: BatteriesForm,
      path: '/equipment/batteries/edit/:batteryId',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
      component: BatteryCard,
      path: '/equipment/batteries/:batteryId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: TiresList,
      path: '/equipment/tires',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: TiresForm,
      path: '/equipment/tires/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
      component: TiresForm,
      path: '/equipment/tires/new/vehicle/:vehicleId',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
      component: TiresForm,
      path: '/equipment/tires/edit/:tireId',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingEquipment],
    },
    {
      component: TireCard,
      path: '/equipment/tires/:tireId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: AttachedEquipmentsList,
      path: '/equipment/attached',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: AttachedEquipmentsForm,
      path: '/equipment/attached/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
      ],
    },
    {
      component: AttachedEquipmentsForm,
      path: '/equipment/attached/edit/:attachedEquipmentId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
      ],
    },
    {
      component: AttachedEquipmentCard,
      path: '/equipment/attached/:attachedEquipmentId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: OptionalEquipmentList,
      path: '/equipment/optional',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: OptionalEquipmentForm,
      path: '/equipment/optional/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
      ],
    },
    {
      component: OptionalEquipmentForm,
      path: '/equipment/optional/edit/:optionalId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
      ],
    },
    {
      component: OptionalEquipmentCard,
      path: '/equipment/optional/:optionalEquipmentId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingEquipment,
        accessTypeEnum.viewingAllEquipments,
        accessTypeEnum.viewingBranchEquipments,
      ],
    },
    {
      component: VehicleModelList,
      path: '/vehicle-models/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingVehicleModel,
        accessTypeEnum.viewingVehicleModel,
        accessTypeEnum.viewingContractVehicleModel,
        accessTypeEnum.handlingContractVehicleModel,
      ],
    },
    {
      component: VehicleModelForm,
      path: '/vehicle-models/:type/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingVehicleModel,
        accessTypeEnum.handlingContractVehicleModel,
      ],
    },
    {
      component: VehicleModelForm,
      path: '/vehicle-models/:type/:vehicleModelId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingVehicleModel,
        accessTypeEnum.viewingVehicleModel,
        accessTypeEnum.viewingContractVehicleModel,
        accessTypeEnum.handlingContractVehicleModel,
      ],
    },
    {
      component: FuelsAndOilsList,
      path: '/admin/fuels-and-oils',
      access: [accessTypeEnum.admin],
    },
    {
      component: FuelsAndOilsForm,
      path: '/admin/fuels-and-oils/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: FuelsAndOilsForm,
      path: '/admin/fuels-and-oils/:fuelAndOilId',
      access: [accessTypeEnum.admin],
    },
    {
      component: FuelMultipliersList,
      path: '/admin/fuel-multipliers',
      access: [accessTypeEnum.admin],
    },
    {
      component: FuelMultiplierForm,
      path: '/admin/fuel-multipliers/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: FuelMultiplierForm,
      path: '/admin/fuel-multipliers/:multiplierId',
      access: [accessTypeEnum.admin],
    },
    {
      component: CompaniesList,
      path: '/admin/companies',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch, accessTypeEnum.companiesHandling],
    },
    {
      component: CompaniesForm,
      path: '/admin/companies/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch, accessTypeEnum.companiesHandling],
    },
    {
      component: CompaniesForm,
      path: '/admin/companies/:companyId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch, accessTypeEnum.companiesHandling],
    },
    {
      component: ContractorsList,
      path: '/admin/contractors',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: ContractorsForm,
      path: '/admin/contractors/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: ContractorsForm,
      path: '/admin/contractors/:contractorId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: BudgetVersionList,
      path: '/admin/budget-version',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBudgetVersion,
      ],
    },
    {
      component: BudgetVersionCard,
      path: '/admin/budget-version/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBudgetVersion,
      ],
    },
    {
      component: BudgetVersionForm,
      path: '/admin/budget-version/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBudgetVersion,
      ],
    },
    {
      component: BudgetVersionForm,
      path: '/admin/budget-version/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBudgetVersion,
      ],
    },
    {
      component: OkeiList,
      path: '/admin/okei',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OkeiCard,
      path: '/admin/okei/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OkeiForm,
      path: '/admin/okei/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OkeiForm,
      path: '/admin/okei/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: PlaceList,
      path: '/admin/place',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingPlaces,
        accessTypeEnum.viewingAllPlaces,
      ],
    },
    {
      component: PlaceCard,
      path: '/admin/place/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingPlaces,
        accessTypeEnum.viewingAllPlaces,
      ],
    },
    {
      component: FederalHighwayFareList,
      path: '/admin/federal-highway-fare',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: FederalHighwayFareForm,
      path: '/admin/federal-highway-fare/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: FederalHighwayFareForm,
      path: '/admin/federal-highway-fare/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: GpsSubscriptionServiceCostList,
      path: '/admin/gps-subscription-service-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: GpsSubscriptionServiceCostForm,
      path: '/admin/gps-subscription-service-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: GpsSubscriptionServiceCostForm,
      path: '/admin/gps-subscription-service-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MeasuringDeviceCertificationCostList,
      path: '/admin/measuring-device-certification-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MeasuringDeviceCertificationCostCard,
      path: '/admin/measuring-device-certification-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MeasuringDeviceCertificationCostForm,
      path: '/admin/measuring-device-certification-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: MeasuringDeviceCertificationCostForm,
      path: '/admin/measuring-device-certification-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGibddCostList,
      path: '/admin/inspection-gibdd-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGibddCostCard,
      path: '/admin/inspection-gibdd-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGibddCostForm,
      path: '/admin/inspection-gibdd-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGibddCostForm,
      path: '/admin/inspection-gibdd-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGtnCostList,
      path: '/admin/inspection-gtn-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGtnCostCard,
      path: '/admin/inspection-gtn-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGtnCostForm,
      path: '/admin/inspection-gtn-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: InspectionGtnCostForm,
      path: '/admin/inspection-gtn-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: LicensePlateChangeCostList,
      path: '/admin/license-plate-change-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: LicensePlateChangeCostCard,
      path: '/admin/license-plate-change-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: LicensePlateChangeCostForm,
      path: '/admin/license-plate-change-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: LicensePlateChangeCostForm,
      path: '/admin/license-plate-change-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: StockList,
      path: '/admin/stock',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingStocksAndStockParts,
        accessTypeEnum.viewingAllStocksAndStockParts,
      ],
    },
    {
      component: StockForm,
      path: '/admin/stock/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingAllStocks,
        accessTypeEnum.handlingStocks,
      ],
    },
    {
      component: StockForm,
      path: '/admin/stock/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingAllStocks,
        accessTypeEnum.handlingStocks,
      ],
    },
    {
      component: ExpertiseServiceGpmCostList,
      path: '/admin/expertise-service-gpm-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: ExpertiseServiceGpmCostCard,
      path: '/admin/expertise-service-gpm-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: ExpertiseServiceGpmCostForm,
      path: '/admin/expertise-service-gpm-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: ExpertiseServiceGpmCostForm,
      path: '/admin/expertise-service-gpm-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionCostList,
      path: '/admin/trip-inspection-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionCostCard,
      path: '/admin/trip-inspection-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionCostForm,
      path: '/admin/trip-inspection-cost/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionCostForm,
      path: '/admin/trip-inspection-cost/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionFrequencyList,
      path: '/admin/trip-inspection-frequency',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionFrequencyCard,
      path: '/admin/trip-inspection-frequency/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: TripInspectionFrequencyForm,
      path: '/admin/trip-inspection-frequency/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: TripInspectionFrequencyForm,
      path: '/admin/trip-inspection-frequency/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OverhaulTypeList,
      path: '/admin/overhaul-types',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: OverhaulTypeCard,
      path: '/admin/overhaul-types/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: OverhaulTypeForm,
      path: '/admin/overhaul-types/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: OverhaulTypeForm,
      path: '/admin/overhaul-types/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: CurrentMaintenanceCostList,
      path: '/admin/current-maintenance-cost',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: CurrentMaintenanceCostCard,
      path: '/admin/current-maintenance-cost/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: CurrentMaintenanceCostForm,
      path: '/admin/current-maintenance-cost/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: CurrentMaintenanceCostForm,
      path: '/admin/current-maintenance-cost/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: MaintenanceCostAllocationTRList,
      path: '/admin/maintenance-cost-allocation-tr',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationTRCard,
      path: '/admin/maintenance-cost-allocation-tr/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationTRForm,
      path: '/admin/maintenance-cost-allocation-tr/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationTRForm,
      path: '/admin/maintenance-cost-allocation-tr/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationList,
      path: '/admin/maintenance-cost-allocation',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationCard,
      path: '/admin/maintenance-cost-allocation/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationForm,
      path: '/admin/maintenance-cost-allocation/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: MaintenanceCostAllocationForm,
      path: '/admin/maintenance-cost-allocation/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: VehicleTaxRateList,
      path: '/admin/vehicle-tax-rate',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: VehicleTaxRateCard,
      path: '/admin/vehicle-tax-rate/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: VehicleTaxRateForm,
      path: '/admin/vehicle-tax-rate/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: VehicleTaxRateForm,
      path: '/admin/vehicle-tax-rate/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: VehicleTaxMapList,
      path: '/admin/vehicle-tax-map',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: VehicleTaxMapCard,
      path: '/admin/vehicle-tax-map/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: VehicleTaxMapForm,
      path: '/admin/vehicle-tax-map/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: VehicleTaxMapForm,
      path: '/admin/vehicle-tax-map/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: BudgetsForAnalysisList,
      path: '/admin/budgets-for-analysis',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: BudgetsForAnalysisCard,
      path: '/admin/budgets-for-analysis/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: BudgetsForAnalysisForm,
      path: '/admin/budgets-for-analysis/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: BudgetsForAnalysisForm,
      path: '/admin/budgets-for-analysis/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingSelfVehiclePlanBudget,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: PassTariffList,
      path: '/admin/pass-tariff',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: PassTariffCard,
      path: '/admin/pass-tariff/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: PassTariffForm,
      path: '/admin/pass-tariff/edit/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    {
      component: PassTariffForm,
      path: '/admin/pass-tariff/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingSelfVehiclePlanBudget,
      ],
    },
    /*####APP_LAYOUT_EXPORT*/
    {
      component: VehicleForm,
      path: '/vehicles/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: VehicleForm,
      path: '/vehicles/edit/:vehicleId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: VehicleList,
      path: '/vehicles',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
        accessTypeEnum.approvingVehicle,
        accessTypeEnum.viewingVehicleServiceOnly,
        accessTypeEnum.viewingVehicleBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.gager,
      ],
    },
    {
      component: ContractVehiclesForm,
      path: '/vehicles/contract/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: ContractVehiclesForm,
      path: '/vehicles/contract/edit/:vehicleId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: ContractVehiclesList,
      path: '/vehicles/contract',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
        accessTypeEnum.approvingVehicle,
        accessTypeEnum.viewingVehicleServiceOnly,
        accessTypeEnum.viewingVehicleBranch,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: ContractVehicleCard,
      path: '/vehicles/contract/:vehicleId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
        accessTypeEnum.approvingVehicle,
        accessTypeEnum.viewingVehicleServiceOnly,
        accessTypeEnum.viewingVehicleBranch,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/osago',
      component: VehicleOsago,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/kilometrage',
      component: VehicleKilometrage,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/numbers',
      component: VehicleNumbers,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/primary',
      component: VehiclePrimary,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/driver',
      component: VehicleDriver,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/gpm',
      component: VehicleGpm,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/planed-maintenance',
      component: VehiclePlanedMaintenance,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/files',
      component: VehicleFiles,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/files/:tag',
      component: VehicleFiles,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/attachment',
      component: VehicleAttachment,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/accountant',
      component: VehicleAccountant,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/budget',
      component: VehicleBudgetEdit,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/tariffs',
      component: VehicleTariffs,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/fuel',
      component: VehicleFuel,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/oils-technical-liquids',
      component: VehicleFuelsEndOilsForm,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/changeTools',
      component: VehicleChangeTools,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/overhaul',
      component: VehicleOverhaul,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/maintenance',
      component: VehicleMaintenance,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/edit/trailers',
      component: VehicleTrailers,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    // Тех. осмотр ГТН
    {
      component: InspectionGtnForm,
      path: '/vehicles/:vehicleId/inspections-gtn/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: InspectionGtnForm,
      path: '/vehicles/:vehicleId/inspections-gtn/:inspectionGtnId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: InspectionGibddForm,
      path: '/vehicles/:vehicleId/inspections-gibdd/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: InspectionGibddForm,
      path: '/vehicles/:vehicleId/inspections-gibdd/:inspectionGibddId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    // ОСАГО
    {
      component: OsagoForm,
      path: '/vehicles/:vehicleId/osagos/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: OsagoForm,
      path: '/vehicles/:vehicleId/osagos/:osagoId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: ExpertiseServiceGpmForm,
      path: '/vehicles/:vehicleId/expertises-services-gpm/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: ExpertiseServiceGpmForm,
      path:
        '/vehicles/:vehicleId/expertises-services-gpm/:expertiseServiceGpmId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: ExpertiseServiceGpmForm,
      path:
        '/vehicles/:vehicleId/expertises-services-gpm/:expertiseServiceGpmId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: MeasuringDeviceCertificationForm,
      path: '/vehicles/:vehicleId/measuring-device-certifications/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: MeasuringDeviceCertificationForm,
      path:
        '/vehicles/:vehicleId/measuring-device-certifications/:measuringDeviceCertificationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: MeasuringDeviceCertificationForm,
      path:
        '/vehicles/:vehicleId/measuring-device-certifications/:measuringDeviceCertificationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    // Осмотры водителей
    {
      component: DriverInspectionForm,
      path: '/vehicles/:vehicleId/driver-inspections/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: DriverInspectionForm,
      path: '/vehicles/:vehicleId/driver-inspections/:inspectionGtnId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: PlatonForm,
      path: '/vehicles/:vehicleId/platons/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: PlatonForm,
      path: '/vehicles/:vehicleId/platons/:platonId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: VehicleMonitoringForm,
      path: '/vehicles/:vehicleId/vehicles-monitoring/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: VehicleMonitoringForm,
      path: '/vehicles/:vehicleId/vehicles-monitoring/:vehicleMonitoringId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: LicensePlateChangeForm,
      path: '/vehicles/:vehicleId/license-plate-changes/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      component: LicensePlateChangeForm,
      path: '/vehicles/:vehicleId/license-plate-changes/:licensePlateChangeId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
      ],
    },
    {
      path: '/vehicles/:vehicleId/*',
      component: VehicleCard,
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingVehicle,
        accessTypeEnum.approvingVehicle,
        accessTypeEnum.viewingVehicleServiceOnly,
        accessTypeEnum.viewingVehicleBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.gager,
      ],
    },
    {
      component: OrderForm,
      path: '/orders/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: OrderForm,
      path: '/orders/edit/:orderId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: OrderForm,
      path: '/orders/:copyOrderId/copy',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: OrderList,
      path: '/orders/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.approvingGpmOrder,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.viewingOrdersBranch,
        accessTypeEnum.viewingAllOrders,
        accessTypeEnum.viewingOrdersServiceOnly,
      ],
    },
    {
      component: OrderCard,
      path: '/orders/:orderId/card',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.approvingGpmOrder,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.viewingOrdersBranch,
        accessTypeEnum.viewingAllOrders,
        accessTypeEnum.viewingOrdersServiceOnly,
      ],
    },
    {
      component: CalculationCard,
      path: '/orders/:calculationId/calculation',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingOrderCalculation,
        accessTypeEnum.viewingAllOrderCalculation,
      ],
    },
    {
      component: ShiftList,
      path: '/shifts',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.viewingAllShifts,
        accessTypeEnum.viewingShiftsServiceOnly,
        accessTypeEnum.viewingShiftsBranch,
      ],
    },
    {
      component: ShiftCard,
      path: '/shifts/:shiftId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.creatingOrder,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.viewingAllShifts,
        accessTypeEnum.viewingShiftsServiceOnly,
        accessTypeEnum.viewingShiftsBranch,
      ],
    },
    {
      component: SelfTripList,
      path: '/trips/self/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.handlingTrip,
        accessTypeEnum.viewingAllTrips,
        accessTypeEnum.viewingTripsServiceOnly,
        accessTypeEnum.viewingTripsBranch,
      ],
    },
    {
      component: ContractTripList,
      path: '/trips/contract',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBranchContractTrip,
        accessTypeEnum.viewingAllContractTrip,
        accessTypeEnum.handlingContractTrip,
      ],
    },
    {
      component: ContractTripCard,
      path: '/trips/contract/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBranchContractTrip,
        accessTypeEnum.viewingAllContractTrip,
        accessTypeEnum.handlingContractTrip,
      ],
    },
    {
      component: ContractTripLayout,
      path: '/trips/contract/layout/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingContractTripVehicleLayout,
      ],
    },
    {
      component: TripForm,
      path: '/trips/self/:tripId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.handlingTrip,
      ],
    },
    {
      component: SelfTripCard,
      path: '/trips/self/:tripId/card',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
        accessTypeEnum.handlingTrip,
        accessTypeEnum.viewingAllTrips,
        accessTypeEnum.viewingTripsServiceOnly,
        accessTypeEnum.viewingTripsBranch,
      ],
    },
    {
      component: TripVerification,
      path: '/trips/self/:tripId/verification',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingTrip,
        accessTypeEnum.viewingAllTrips,
        accessTypeEnum.viewingTripsServiceOnly,
        accessTypeEnum.viewingTripsBranch,
      ],
    },
    {
      component: JournalList,
      path: '/admin/journals',
      access: [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal],
    },
    {
      component: JournalCard,
      path: '/admin/journal/:journalId',
      access: [accessTypeEnum.admin, accessTypeEnum.viewingSystemJournal],
    },
    {
      component: ContractVehicleOrderForm,
      path: '/orders/:orderId/contract-vehicle-order/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: ContractVehicleOrderForm,
      path: '/orders/:orderId/contract-vehicle-order/:contractVehicleOrderId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingOrder,
      ],
    },
    {
      component: ReportTaxedTripsList,
      path: '/report/taxed-trips',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportTaxedTripsElectricList,
      path: '/report/electric-taxed-trips',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportScheduleMaintenanceList,
      path: '/report/schedule-maintenance',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportVehicleWorksList,
      path: '/report/vehicle-works',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportFuelCardsList,
      path: '/report/fuel-cards',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportArchiveVehiclesList,
      path: '/report/write-off-vehicles',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportFuelsAndOilsList,
      path: '/report/fuels-and-oils',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportDriversList,
      path: '/report/drivers',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportTripsRegistryList,
      path: '/report/trips-registry',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportComparingTransportServicesList,
      path: '/report/comparing-transport-services',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportTransportConsumptionsJournalList,
      path: '/report/transport-consumptions-journal',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportBusinessTripList,
      path: '/report/business-trip',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportWeekendOrdersList,
      path: '/report/weekend-orders',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: MdmList,
      path: '/admin/mdm',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingContracts,
      ],
    },
    {
      component: MdmForm,
      path: '/admin/mdm/:mdmGuid',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: JobTitleForm,
      path: '/admin/job-titles/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingJobTitles,
      ],
    },
    {
      component: JobTitleForm,
      path: '/admin/job-titles/:jobTitleId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingJobTitles,
      ],
    },
    {
      component: JobTitleList,
      path: '/admin/job-titles',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingAllJobTitles,
        accessTypeEnum.viewingJobTitles,
      ],
    },
    {
      component: BusinessCalendar,
      path: '/admin/business-calendar',
      access: [accessTypeEnum.admin],
    },
    {
      component: BusinessDayForm,
      path: '/admin/business-calendar/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: BusinessDayForm,
      path: '/admin/business-calendar/:dayId',
      access: [accessTypeEnum.admin],
    },
    {
      component: BusinessCalendarForBudget,
      path: '/admin/the-business-calendar-for-budget',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: BusinessCalendarForBudgetForm,
      path: '/admin/the-business-calendar-for-budget/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: BusinessCalendarForBudgetForm,
      path: '/admin/the-business-calendar-for-budget/:dayId',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: ReportGpmOrderList,
      path: '/report/gpm-order',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: ReportVehicleOutputRatioList,
      path: '/report/vehicle-output-ratio',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: PassForm,
      path: '/services/pass/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
        accessTypeEnum.viewingMaintenanceServices,
      ],
    },
    {
      component: PassCard,
      path: '/services/pass/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: PassForm,
      path: '/services/pass/:passId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
        accessTypeEnum.newFeaturesDemo,
      ],
    },
    {
      component: PassList,
      path: '/services/pass',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: PassForm,
      path: '/vehicles/:vehicleId/passes/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: OsagoForm,
      path: '/services/osagos/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: OsagoCard,
      path: '/services/osagos/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: OsagoForm,
      path: '/services/osagos/:osagoId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: OsagoList,
      path: '/services/osagos',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGibddForm,
      path: '/services/inspections-gibdd/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGibddCard,
      path: '/services/inspections-gibdd/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGibddForm,
      path: '/services/inspections-gibdd/:inspectionGibddId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGibddList,
      path: '/services/inspections-gibdd',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGtnForm,
      path: '/services/inspections-gtn/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGtnCard,
      path: '/services/inspections-gtn/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGtnForm,
      path: '/services/inspections-gtn/:inspectionGtnId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InspectionGtnList,
      path: '/services/inspections-gtn',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: ExpertiseServiceGpmForm,
      path: '/services/expertises-services-gpm/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: ExpertiseServiceGpmCard,
      path: '/services/expertises-services-gpm/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: ExpertiseServiceGpmForm,
      path: '/services/expertises-services-gpm/:id/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: ExpertiseServiceGpmList,
      path: '/services/expertises-services-gpm',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: InstructionList,
      path: '/instructions',
      access: (Object.keys(accessTypeEnum): UserAccess[]),
    },
    {
      component: MeasuringDeviceCertificationForm,
      path: '/services/measuring-device-certifications/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: MeasuringDeviceCertificationCard,
      path: '/services/measuring-device-certifications/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: MeasuringDeviceCertificationForm,
      path:
        '/services/measuring-device-certifications/:measuringDeviceCertificationId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: MeasuringDeviceCertificationList,
      path: '/services/measuring-device-certifications',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: DriverInspectionForm,
      path: '/services/driver-inspections/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: DriverInspectionCard,
      path: '/services/driver-inspections/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: DriverInspectionForm,
      path: '/services/driver-inspections/:driverInspectionId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: DriverInspectionList,
      path: '/services/driver-inspections',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    // Платон
    {
      component: PlatonForm,
      path: '/services/platons/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: PlatonCard,
      path: '/services/platons/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: PlatonForm,
      path: '/services/platons/:platonId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: PlatonList,
      path: '/services/platons',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: VehicleMonitoringForm,
      path: '/services/vehicles-monitoring/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: VehicleMonitoringCard,
      path: '/services/vehicles-monitoring/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: VehicleMonitoringForm,
      path: '/services/vehicles-monitoring/:vehicleMonitoringId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: VehicleMonitoringList,
      path: '/services/vehicles-monitoring',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: LicensePlateChangeForm,
      path: '/services/license-plate-changes/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
        accessTypeEnum.newFeaturesDemo,
      ],
    },
    {
      component: LicensePlateChangeCard,
      path: '/services/license-plate-changes/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: LicensePlateChangeForm,
      path: '/services/license-plate-changes/:licensePlateChangeId/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: LicensePlateChangeList,
      path: '/services/license-plate-changes',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingMaintenanceServices,
        accessTypeEnum.viewingAllMaintenanceServices,
        accessTypeEnum.handlingMaintenanceServices,
      ],
    },
    {
      component: ContractVehicleWorkPlans,
      path: '/contract-vehicle-work-plans/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.approvingTimeLimits,
        accessTypeEnum.handlingTimeLimits,
        accessTypeEnum.viewingTimeLimits,
        accessTypeEnum.viewingBranchTimeLimits,
        accessTypeEnum.approvingLimitAssignment,
        accessTypeEnum.handlingLimitAssignment,
        accessTypeEnum.updatingDailyBudgets,
        accessTypeEnum.viewingLimitAssignment,
        accessTypeEnum.viewingAllLimitAssignment,
        accessTypeEnum.approvingYearLimitsPlan,
        accessTypeEnum.handlingYearLimitsPlan,
        accessTypeEnum.viewingBranchYearLimitsPlan,
        accessTypeEnum.viewingYearLimitsPlan,
        accessTypeEnum.approvingMonthLimitsPlan,
        accessTypeEnum.handlingMonthLimitsPlan,
        accessTypeEnum.viewingBranchMonthLimitsPlan,
        accessTypeEnum.viewingMonthLimitsPlan,
      ],
    },
    {
      component: StouList,
      path: '/admin/stou',
      access: [accessTypeEnum.admin],
    },
    {
      component: StouForm,
      path: '/admin/stou/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: StouForm,
      path: '/admin/stou/edit/:stouId',
      access: [accessTypeEnum.admin],
    },
    {
      component: StouCard,
      path: '/admin/stou/:stouId',
      access: [accessTypeEnum.admin],
    },
    // бюджет налоги
    {
      component: TaxList,
      path: '/budget/tax',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBranchVehicleTaxPlans,
        accessTypeEnum.handlingAllVehicleTaxPlans,
        accessTypeEnum.viewingBranchVehicleTaxPlans,
        accessTypeEnum.viewingAllVehicleTaxPlans,
      ],
    },
    {
      component: TaxForm,
      path: '/budget/tax/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBranchVehicleTaxPlans,
        accessTypeEnum.handlingAllVehicleTaxPlans,
      ],
    },
    {
      component: TaxCard,
      path: '/budget/tax/:vehicleTaxPlanId/card',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBranchVehicleTaxPlans,
        accessTypeEnum.handlingAllVehicleTaxPlans,
        accessTypeEnum.viewingBranchVehicleTaxPlans,
        accessTypeEnum.viewingAllVehicleTaxPlans,
      ],
    },
    // бюджет НТС
    {
      component: ContractVehicleBudget,
      path: '/budget/contract-vehicle/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingContractVehicleBudget,
        accessTypeEnum.viewingAllContractVehicleBudgets,
      ],
    },
    // бюджет СТС
    {
      component: VehicleBudget,
      path: '/budget/vehicle/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingAllSelfVehiclePlan,
        accessTypeEnum.viewingSelfVehiclePlan,
        accessTypeEnum.approvingSelfVehiclePlanBudgetItog,
        accessTypeEnum.editingBudgetCopy,
      ],
    },
    // Исполнение договоров
    {
      component: ExecutionBudget,
      path: '/budget/execution/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.newFeaturesDemo,
        accessTypeEnum.viewingBudgetExecutions,
        accessTypeEnum.viewingAllBudgetExecutions,
      ],
    },
    {
      component: OsagoCalulationsByYearList,
      path: '/budget/osago-calculations',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBranchOsagoCalculations,
        accessTypeEnum.viewingAllBranchesOsagoCalculations,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: OsagoCalculationList,
      path: '/budget/osago-calculations/list',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingBranchOsagoCalculations,
        accessTypeEnum.viewingAllBranchesOsagoCalculations,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: OsagoCalculationListForm,
      path: '/budget/osago-calculations/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: OsagoCalculationFreeVehicleForm,
      path: '/budget/osago-calculations/new/vehicle-free-calculation',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: OsagoCalculationFreeVehicleForm,
      path:
        '/budget/osago-calculations/new/vehicle-free-calculation/:osagoCalculationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: OsagoCalculationForm,
      path: '/budget/osago-calculations/:osagoCalculationId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.formationBranchOsagoCalculations,
        accessTypeEnum.formationAllBranchesOsagoCalculations,
      ],
    },
    {
      component: MonthlyWashingPlanList,
      path: '/budget/monthly-washing-plans',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingAllWashingPlans,
        accessTypeEnum.viewingWashingPlans,
        accessTypeEnum.formationWashingPlans,
        accessTypeEnum.approvingWashingPlans,
      ],
    },
    {
      component: MonthlyWashingPlanForm,
      path: '/budget/monthly-washing-plans/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.formationWashingPlans,
        accessTypeEnum.approvingWashingPlans,
      ],
    },
    {
      component: MonthlyWashingPlanForm,
      path: '/budget/monthly-washing-plans/edit/:monthlyWashingPlanId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.formationWashingPlans,
        accessTypeEnum.approvingWashingPlans,
      ],
    },
    {
      component: MonthlyWashingPlanCard,
      path: '/budget/monthly-washing-plans/:monthlyWashingPlanId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingAllWashingPlans,
        accessTypeEnum.viewingWashingPlans,
        accessTypeEnum.formationWashingPlans,
        accessTypeEnum.approvingWashingPlans,
      ],
    },
    {
      component: SubscriptionsCard,
      path: '/admin/subscriptions/',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: BudgetSummaryList,
      path: '/budget/summary',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryForm,
      path: '/budget/summary/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryForm,
      path: '/budget/summary/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryForm,
      path: '/budget/summary/:id/:orgUnitId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryContractRegistryForm,
      path:
        '/budget/:summarydetails/:id/:orgUnitId/:source/contract/:expenseDirectionId/registry',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryForm,
      path: '/budget/summary/:id/:orgUnitId/:source/:type',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetSummaryForm,
      path: '/budget/summary/:id/:orgUnitId/:source',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetAnalysisList,
      path: '/budget/budget-analysis',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBudgetAnalysis,
        accessTypeEnum.viewingBudgetAnalysis,
        accessTypeEnum.viewingAllBudgetAnalysis,
      ],
    },
    {
      component: BudgetAnalysisInfo,
      path:
        '/budget/budget-analysis/:budgetAnalysisId/:branchBudgetSummaryExpenseDirectionId/:month',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBudgetAnalysis,
        accessTypeEnum.viewingBudgetAnalysis,
        accessTypeEnum.viewingAllBudgetAnalysis,
      ],
    },
    {
      component: BudgetAnalysisForm,
      path: '/budget/budget-analysis/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBudgetAnalysis,
        accessTypeEnum.viewingBudgetAnalysis,
        accessTypeEnum.viewingAllBudgetAnalysis,
      ],
    },
    {
      component: BudgetAnalysisCard,
      path: '/budget/budget-analysis/:budgetAnalysisId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingBudgetAnalysis,
        accessTypeEnum.viewingBudgetAnalysis,
        accessTypeEnum.viewingAllBudgetAnalysis,
      ],
    },
    {
      component: SmallScaleMechanizationToolList,
      path: '/admin/small-scale-mechanization',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: SmallScaleMechanizationToolForm,
      path: '/admin/small-scale-mechanization/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: SmallScaleMechanizationToolForm,
      path: '/admin/small-scale-mechanization/edit/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: SmallScaleMechanizationToolCard,
      path: '/admin/small-scale-mechanization/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingSelfVehiclePlanBudget,
        accessTypeEnum.viewingAllSelfVehiclePlanBudget,
      ],
    },
    {
      component: BudgetDetailList,
      path: '/budget/details/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetDetailForm,
      path: '/budget/details/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetDetailForm,
      path: '/budget/details/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetDetailForm,
      path: '/budget/details/:id/:orgUnitId/:source',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: BudgetDetailForm,
      path: '/budget/details/:id/:orgUnitId/:source/:type',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.handlingDetailedBudget,
        accessTypeEnum.handlingBranchDetailedBudget,
        accessTypeEnum.viewingDetailedBudget,
        accessTypeEnum.approvingBranchDetailedBudget,
        accessTypeEnum.approvingDetailedBudget,
      ],
    },
    {
      component: ISUBudgetingDataList,
      path: '/admin/ISUBudgetingData',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: ISUBudgetingDataForm,
      path: '/admin/ISUBudgetingData/new',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: ISUBudgetingDataForm,
      path: '/admin/ISUBudgetingData/:id/',
      access: [accessTypeEnum.admin, accessTypeEnum.adminBranch],
    },
    {
      component: CognosLogList,
      path: '/admin/cognos-log',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingCognosLog,
        accessTypeEnum.viewingAllCognosLog,
      ],
    },
    {
      component: CognosLogCard,
      path: '/admin/cognos-log/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingCognosLog,
        accessTypeEnum.viewingAllCognosLog,
      ],
    },
    // Норматив эксплуатации шин
    {
      component: StandardOfTireOperation,
      path: '/admin/standardOfTireOperation',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingTireUsingLimits],
    },
    // Причины отклонения заявки на ГПМ/ ТС
    {
      component: ReasonsForRejectingOfOrder,
      path: '/admin/reasons-for-rejection-of-order/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingOrderToReworkReasons,
      ],
    },
    {
      component: ReasonsForRejectingOfOrderForm,
      path: '/admin/reasons-for-rejection-of-order/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingOrderToReworkReasons,
      ],
    },
    {
      component: ReasonsForRejectingOfOrderForm,
      path: '/admin/reasons-for-rejection-of-order/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingOrderToReworkReasons,
      ],
    },
    // Сроки внесения изменений в оттаксированный ПЛ
    {
      component: DeadlinesForMakingChangesOnVerifiedTrips,
      path: '/admin/deadlines-for-making-changes-on-verified-trips',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingTripEditLimit],
    },
    {
      component: DebetCreditAccounts,
      path: '/admin/debet-credit-accounts',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingDebitCreditAccount],
    },
    {
      component: DebetCreditAccountsForm,
      path: '/admin/debet-credit-accounts/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingDebitCreditAccount],
    },
    {
      component: DebetCreditAccountsForm,
      path: '/admin/debet-credit-accounts/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingDebitCreditAccount],
    },
    {
      component: WorkingWithBudgetingList,
      path: '/admin/working-with-budgeting/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCognosActualDataSendingTimeline,
        accessTypeEnum.viewingCalendarBK,
        accessTypeEnum.handlingCalendarBK,
      ],
    },
    {
      component: DeadlinesForm,
      path: '/admin/working-with-budgeting/deadlines/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCognosActualDataSendingTimeline,
      ],
    },
    {
      component: CalendarForm,
      path: '/admin/working-with-budgeting/calendar/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCalendarBK,
      ],
    },
    {
      component: CalendarForm,
      path: '/admin/working-with-budgeting/calendar/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingCalendarBK,
      ],
    },
    {
      component: TypesOfOils,
      path: '/admin/types-of-oils/',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingUatToParusOilType],
    },
    {
      component: TypesOfOilsForm,
      path: '/admin/types-of-oils/new',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingUatToParusOilType],
    },
    {
      component: TypesOfOilsForm,
      path: '/admin/types-of-oils/:id',
      access: [accessTypeEnum.admin, accessTypeEnum.handlingUatToParusOilType],
    },
    {
      component: PlannedFuelConsumption,
      path: '/admin/planned-fuel-consumption',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingBranchPlanFuelConsumption,
        accessTypeEnum.viewingAllPlanFuelConsumption,
      ],
    },
    {
      component: PlannedFuelConsumptionReport,
      path: '/admin/planned-fuel-consumption/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingBranchPlanFuelConsumption,
        accessTypeEnum.viewingAllPlanFuelConsumption,
      ],
    },
    {
      component: ActualFuelConsumption,
      path: '/admin/actual-fuel-consumption',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingBranchFactFuelConsumption,
        accessTypeEnum.viewingAllFactFuelConsumption,
      ],
    },
    {
      component: ActualFuelConsumptionReport,
      path: '/admin/actual-fuel-consumption/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingBranchFactFuelConsumption,
        accessTypeEnum.viewingAllFactFuelConsumption,
      ],
    },
    {
      component: OilConsumptionAccountingCard,
      path: '/admin/act/internal/oil-consumption-accounting/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingBranchFactFuelConsumption,
        accessTypeEnum.viewingAllFactFuelConsumption,
      ],
    },
    {
      component: SecuringContractorsList,
      path: '/admin/securing-contractors/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingContractorBranchLink,
        accessTypeEnum.handlingContractorBranchLink,
      ],
    },
    {
      component: SecuringContractorsForm,
      path: '/admin/securing-contractors/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingContractorBranchLink,
        accessTypeEnum.handlingContractorBranchLink,
      ],
    },
    {
      component: SecuringContractorsForm,
      path: '/admin/securing-contractors/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingContractorBranchLink,
        accessTypeEnum.handlingContractorBranchLink,
      ],
    },
    {
      component: RegisterContractTrips,
      path: '/report/register-contract-trips',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.viewingReports,
      ],
    },
    {
      component: CompletedWorksCard,
      path: '/admin/act/internal/completed-works-card/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInternalActs,
        accessTypeEnum.viewingAllInternalActs,
        accessTypeEnum.handlingInternalActs,
      ],
    },
    {
      component: DefectiveResourceStatementCard,
      path: '/admin/act/internal/defective-statement/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInternalActs,
        accessTypeEnum.viewingAllInternalActs,
        accessTypeEnum.handlingInternalActs,
      ],
    },
    {
      component: WriteOffFuelAct,
      path: '/admin/act/internal/write-off-fuels-act/:actId',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInternalActs,
        accessTypeEnum.viewingAllInternalActs,
        accessTypeEnum.handlingInternalActs,
      ],
    },
    {
      component: TypesOfRepairsList,
      path: '/admin/types-of-repairs/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingWorkDict,
        accessTypeEnum.editingWorkDict,
      ],
    },
    {
      component: TypesOfRepairsCard,
      path: '/admin/types-of-repairs/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingWorkDict,
        accessTypeEnum.editingWorkDict,
      ],
    },
    {
      component: TypesOfRepairsForm,
      path: '/admin/types-of-repairs/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingWorkDict,
        accessTypeEnum.editingWorkDict,
      ],
    },
    {
      component: TypesOfRepairsForm,
      path: '/admin/types-of-repairs/:id/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingWorkDict,
        accessTypeEnum.editingWorkDict,
      ],
    },
    {
      component: MaintenanceWorkList,
      path: '/admin/maintenance-works/',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWork,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.approvementMaintenanceWork,
      ],
    },
    {
      component: MaintenanceWorkCard,
      path: '/admin/maintenance-works/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWork,
        accessTypeEnum.changeStatusMaintenanceWork,
        accessTypeEnum.approvementMaintenanceWork,
      ],
    },
    {
      component: MaintenanceWorkForm,
      path: '/admin/maintenance-works/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWork,
        accessTypeEnum.changeStatusMaintenanceWork,
      ],
    },
    {
      component: MaintenanceWorkForm,
      path: '/admin/maintenance-works/:id/edit',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.handlingMaintenanceWork,
        accessTypeEnum.changeStatusMaintenanceWork,
      ],
    },
    {
      component: ModelMatchesList,
      path: '/admin/model-matches/',
      access: [accessTypeEnum.admin],
    },
    {
      component: ModelMatchesCard,
      path: '/admin/model-matches/:id',
      access: [accessTypeEnum.admin],
    },
    {
      component: ModelMatchesForm,
      path: '/admin/model-matches/new',
      access: [accessTypeEnum.admin],
    },
    {
      component: ModelMatchesForm,
      path: '/admin/model-matches/:id/edit',
      access: [accessTypeEnum.admin],
    },
    {
      component: CompareDatesList,
      path: '/admin/compare-dates',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInventoryCardCompareDate,
        accessTypeEnum.editingInventoryCardCompareDate,
      ],
    },
    {
      component: CompareDatesForm,
      path: '/admin/compare-dates/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInventoryCardCompareDate,
        accessTypeEnum.editingInventoryCardCompareDate,
      ],
    },
    {
      component: CompareDatesForm,
      path: '/admin/compare-dates/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingInventoryCardCompareDate,
        accessTypeEnum.editingInventoryCardCompareDate,
      ],
    }, {
      component: InventoryCardCompareList,
      path: '/admin/inventory-card-compare',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingAllCompareInventoryCardResult,
        accessTypeEnum.viewingBranchCompareInventoryCardResult,
      ],
    },

    {
      component: VehicleCategoriesList,
      path: '/admin/vehicle-categories/*',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingVehicleCategory,
        accessTypeEnum.handlingVehicleCategory,
      ],
    },
    {
      component: VehicleCategoriesForm,
      path: '/admin/vehicle-categories/:type/new',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingVehicleCategory,
        accessTypeEnum.handlingVehicleCategory,
      ],
    },
    {
      component: VehicleCategoriesForm,
      path: '/admin/vehicle-categories/:type/:id',
      access: [
        accessTypeEnum.admin,
        accessTypeEnum.viewingVehicleCategory,
        accessTypeEnum.handlingVehicleCategory,
      ],
    },
  ];

  render() {
    if (!this.token || this.state.appState === 'authError') {
      return <Redirect to="/login" noThrow />;
    }

    if (this.state.appState === 'loading') {
      return <AppLoader />;
    }

    const {profile, sidebarOpened} = this.props;
    const {access: userAccess} = profile;
    return (
      <StyledLayout>
        <StyledBurger
          expanded={sidebarOpened}
          onClick={this.props.toggleSidebar}
        />
        <StyledContent>
          <Sidebar profile={profile} expanded={sidebarOpened} />
          <StyledMain>
            <ErrorHandler>
              <Router>
                {userAccess.some(access =>
                  [
                    accessTypeEnum.admin,
                    accessTypeEnum.adminBranch,
                    accessTypeEnum.handlingOrder,
                    accessTypeEnum.creatingVehicle,
                    accessTypeEnum.approvingVehicle,
                    accessTypeEnum.viewingVehicleServiceOnly,
                    accessTypeEnum.viewingVehicleBranch,
                  ].includes(access),
                ) ? (
                  <Redirect from="/" to="/vehicles" noThrow />
                ) : (
                  <Redirect from="/" to="/orders" noThrow />
                )}
                {this.routes.map(
                  (item, index) =>
                    userAccess.some(access => item.access.includes(access)) &&
                    /* $FlowFixMe */
                    createElement(item.component, {
                      path: item.path,
                      key: index,
                    }),
                )}
                <NotFound default />
              </Router>
            </ErrorHandler>
          </StyledMain>
        </StyledContent>
      </StyledLayout>
    );
  }
}

export default connect(
  state => ({
    profile: state.auth.profile,
    sidebarOpened: state.sidebar.opened,
  }),
  {
    fetchProfile,
    toggleSidebar,
  },
)(AppLayout);

const AppLoader = () => (
  <Loader>
    <Spin size="large" />
  </Loader>
);

const Loader = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const StyledContent = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
`;
const StyledMain = styled.main`
  width: 100%;
  background: #e4ebf2;
  overflow: hidden;
`;
