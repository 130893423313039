// @flow
import React, { Component } from 'react';
import InputNumber from 'antd/lib/input-number';
import Popover from 'antd/lib/popover';
import Icon from 'antd/lib/icon';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import moment from 'moment';
import type { WashingPlanVehicle, ListState } from './../../../../lib/types';
import type { VehicleWithWashingPrices } from './../../../../lib/types/vehicle';
import { Section } from './../../../../components/layout';
import { Table } from './../../../../components/ui';
import Filter, { type VehicleFilterParams } from './../../../Vehicles/Filter';
import {
  getListInitialState,
  plus,
  multipliedBy,
  toLocalStringRu
} from './../../../../lib/helpers';
import { vehicleApi, type FetchListParams } from './../../../../lib/api';

// В филиале в среднем 100 машин
const PAGE_SIZE = 100;

// Попытка оптимизировать рендеринг таблички
class PureCountInput extends React.PureComponent<any, any> {
  render() {
    return <CountInput {...this.props} />;
  }
}

class PureFilter extends React.PureComponent<any, any> {
  render() {
    return <Filter {...this.props} />;
  }
}

const CountInput = styled(InputNumber)`
  margin-right: 5px;
  width: 50px;

  input {
    text-align: center;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

type Props = {
  vehicleWashings: WashingPlanVehicle[],
  // дата нужна чтобы вытянуть прайс за соответсвюущий месяц
  // если даты нет то возьмем текущий
  planDate?: ?string,
  onChange: Function
};

type State = {
  vehicles: ListState<VehicleWithWashingPrices> & {
    /**
     * Храним фильтры для того, чтобы не терять
     * фильтрацию при переходе между страницами
     */
    filter: VehicleFilterParams
  },
  vehicleWashings: WashingPlanVehicle[]
};

export default class VehicleWashingList extends Component<Props, State> {
  state = {
    vehicles: {
      ...getListInitialState(),
      filter: {}
    },
    vehicleWashings: this.props.vehicleWashings
  };

  static defaultProps = {
    vehicleWashings: []
  };

  componentDidMount() {
    this.fetchVehicles();
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.vehicleWashings, this.props.vehicleWashings)) {
      this.setState({
        vehicleWashings: this.props.vehicleWashings
      });
    }
    if (!isEqual(prevProps.planDate, this.props.planDate)) {
      this.fetchVehicles();
    }
  }

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate',
      className: 'vertical-align-top',
      width: 100
    },
    {
      title: 'Марка',
      className: 'vertical-align-top',
      dataIndex: 'vehicle.vehicleModel.brandName'
    },
    {
      title: 'Модель',
      className: 'vertical-align-top',
      dataIndex: 'vehicle.vehicleModel.name',
      breakByWidth: true
    },
    {
      title: 'Наружная',
      dataIndex: 'bodyCount',
      className: 'vertical-align-top',
      width: 100,
      render: (bodyCount: Number, record: WashingPlanVehicle) => (
        <>
          <PureCountInput
            placeholder="0"
            value={bodyCount}
            min={0}
            onChange={value =>
              this.updateVehicleWashing('bodyCount', value, record)
            }
          />
        </>
      )
    },
    {
      title: 'Салон',
      dataIndex: 'interiorCount',
      className: 'vertical-align-top',
      width: 100,
      render: (interiorCount: Number, record: WashingPlanVehicle) => (
        <PureCountInput
          placeholder="0"
          value={interiorCount}
          min={0}
          onChange={value =>
            this.updateVehicleWashing('interiorCount', value, record)
          }
        />
      )
    },
    {
      title: 'ДВС',
      dataIndex: 'engineCount',
      className: 'vertical-align-top',
      width: 100,
      render: (engineCount: Number, record: WashingPlanVehicle) => {
        return (
          <PureCountInput
            placeholder="0"
            value={engineCount}
            min={0}
            onChange={value =>
              this.updateVehicleWashing('engineCount', value, record)
            }
          />
        );
      }
    },
    {
      title: 'Стоимость',
      className: 'vertical-align-top',
      width: 100,
      render: (record: WashingPlanVehicle) => {
        let total = 0;

        let rows = [];
        let row;
        let rowTotal;

        if (record) {
          if (record.bodyPrice) {
            row = `Наружная ${toLocalStringRu(record.bodyPrice)}`;

            // проверки для слабаков
            // $FlowFixMe
            if (record.bodyCount && record.bodyPrice) {
              rowTotal = multipliedBy(record.bodyCount, record.bodyPrice);
              total = plus(total, rowTotal);
              row = `Наружная ${record?.bodyCount ?? 0} x ${toLocalStringRu(
                record.bodyPrice
              )} = ${toLocalStringRu(rowTotal)}`;
            }

            rows.push(row);
          }

          if (record.enginePrice) {
            row = `Двигатель ${toLocalStringRu(record.enginePrice)}`;

            //$FlowFixMe
            if (record.engineCount && record.enginePrice) {
              rowTotal = multipliedBy(record.engineCount, record.enginePrice);
              total = plus(total, rowTotal);

              row = `Двигатель ${record?.engineCount ?? 0} x ${toLocalStringRu(
                record.enginePrice
              )} = ${toLocalStringRu(rowTotal)}`;
            }

            rows.push(row);
          }

          if (record.interiorPrice) {
            row = `Салон ${toLocalStringRu(record.interiorPrice)}`;

            //$FlowFixMe
            if (record.interiorCount && record.interiorPrice) {
              rowTotal = multipliedBy(
                record.interiorCount,
                record.interiorPrice
              );
              total = plus(total, rowTotal);

              row = `Салон ${record?.interiorCount ?? 0} x ${toLocalStringRu(
                record.interiorPrice
              )} = ${toLocalStringRu(rowTotal)}`;
            }

            rows.push(row);
          }
        }

        let iconComponent = (
          <Icon style={{ color: '#0000FF' }} type="info-circle" />
        );
        if (rows.length === 0) {
          rows.push('Нет данных');
          iconComponent = <Icon type="warning" style={{ color: '#FAAD14' }} />;
        }

        let popoverContent = (
          <div>
            {rows.map((r, i) => (
              <p key={i}>{r}</p>
            ))}
          </div>
        );
        return (
          <div>
            {toLocalStringRu(total)}&nbsp;
            <Popover content={popoverContent} title="Прейскурант">
              {iconComponent}
            </Popover>
          </div>
        );
      }
    },
    {
      title: 'Подразделение',
      className: 'vertical-align-top',
      dataIndex: 'vehicle.orgUnitName',
      breakByWidth: true
    }
  ];

  fetchVehicles = async (
    page: number = 1,
    params: FetchListParams<VehicleFilterParams> = {}
  ) => {
    try {
      const { filter } = this.state.vehicles;
      this.updateVehiclesInfo({ loading: true });

      const { data, totalCount } = await vehicleApi.fetchVehiclesForWashingPlan(
        {
          ...filter,
          page,
          ...params,
          ownerType: 'self',
          pageSize: PAGE_SIZE
        },
        this.props.planDate
          ? moment(this.props.planDate).toISOString()
          : moment.utc().toISOString()
      );
      this.updateVehiclesInfo({ data, totalCount, page });
    } catch (err) {
    } finally {
      this.updateVehiclesInfo({ loading: false });
    }
  };

  syncData = debounce(
    () => this.props.onChange(this.state.vehicleWashings),
    500
  );

  cleanFilter = () =>
    this.updateVehiclesInfo({ filter: {} }, this.fetchVehicles);

  applyFilter = (filter: VehicleFilterParams) =>
    this.updateVehiclesInfo({ filter }, this.fetchVehicles);

  updateVehiclesInfo = (newValues: any, cb?: () => any) =>
    this.setState(
      (prevState: State) => ({
        vehicles: {
          ...prevState.vehicles,
          ...newValues
        }
      }),
      cb
    );

  updateVehicleWashing = async (
    key: string,
    value: any,
    washingVehicle: WashingPlanVehicle
  ) => {
    let vehicleWashings = [...this.state.vehicleWashings];
    const index = vehicleWashings.findIndex(
      item => item.vehicleId === washingVehicle.vehicleId
    );
    if (index >= 0) {
      vehicleWashings.splice(index, 1, {
        ...vehicleWashings[index],
        [key]: value
      });
    } else {
      vehicleWashings = [
        ...vehicleWashings,
        {
          ...washingVehicle,
          [key]: value
        }
      ];
    }

    this.setState(
      {
        vehicleWashings
      },
      () => {
        this.syncData();
      }
    );
  };

  mapVehicleToWashingPlan = (vehicles: VehicleWithWashingPrices[]) => {
    const { vehicleWashings } = this.state;
    return vehicles.map<WashingPlanVehicle>(
      (vehicle: VehicleWithWashingPrices) => ({
        // Специально выставил вперед, чтобы не джоинить ТС на сервере
        ...vehicleWashings.find(
          (item: WashingPlanVehicle) => item.vehicleId === vehicle.id
        ),
        vehicle,
        vehicleId: vehicle.id,
        bodyPrice: vehicle.bodyPrice,
        interiorPrice: vehicle.interiorPrice,
        enginePrice: vehicle.enginePrice
      })
    );
  };

  render() {
    const { vehicles } = this.state;
    const { filter, data, loading, page, totalCount } = vehicles;
    return (
      <Section>
        <PureFilter
          filter={filter}
          cleanFilter={this.cleanFilter}
          applyFilter={this.applyFilter}
        />
        <Table
          columns={this.columns}
          scroll={{ y: 800 }}
          fetch={this.fetchVehicles}
          rowKey="vehicleId"
          data={this.mapVehicleToWashingPlan(data)}
          loading={loading}
          pagination={{
            page,
            totalCount,
            pageSize: PAGE_SIZE
          }}
        />
      </Section>
    );
  }
}
