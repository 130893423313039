// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import type {AssignmentLimit, ContractVehicleBudget, EntityStatusType, UserAccess} from '../../../../../../lib/types';
import {AssignmentMonth} from '../../../../../../lib/types';
import {accessTypeEnum, contractVehiclePlanStatus, entityStatusEnum} from '../../../../../../lib/enum';
import {assignmentLimitApi, contractVehicleBudgetApi, fetchRequest} from './../../../../../../lib/api';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromRubToKop,
  formatRub,
  getPathWithHistoryParams,
  navigate,
} from '../../../../../../lib/helpers';
import {printNotification} from '../../../../../../lib/notificationWrapper';
import {Grid, Header, Section, SectionTitle, TopPanel} from './../../../../../../components/layout';
import {GridItem} from '../../../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';
import ListTable from './../../../../../../components/ui/ListTable';
import {notificationLoading} from '../../../../../../components/Notifications';
import {ButtonsRow} from '../../../../../../components/ui';
import {withUserAccess} from './../../../../../withUserAccess';
import Tabs from './../components/Tabs';
import {approveAccessRightContractVehicleBudget} from '../../accessRight';
import type {FixedRegistBudgetBudgetFilterParams} from './components/Filter';
import Filter from './components/Filter';
import {listHeaderBudgetList, listRowBudgetList} from './elements';
import {CognosType} from '../../../ContractVehicleBudget';
import {EditingModal} from './components/EditingModal';
import {COGNOS_DELTA} from '../../../../vehicle/lib';
import moment from 'moment/moment';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../lib';

export type SelectedLimits = {
  distance: number,
  hours: number
};
type Props = {
  contractVehiclePlanId: number,
  userAccess: UserAccess[],
  cognosType: $Keys<CognosType>
};
type State = {
  filter: FixedRegistBudgetBudgetFilterParams,
  contractVehicleBudget: ?ContractVehicleBudget,
  assignmentLimits: AssignmentLimit[],
  loading: boolean,
  modalVisible: boolean,
  monthData: ?AssignmentMonth,
  vehicleName: ?string,
  selectedLimits: ?SelectedLimits,
  openPeriod: ?any,
};

const Content = styled.div`
  padding: 16px;
`;
const StyledSpan = styled.div`
  color: ${({warning}) =>
          warning !== undefined ? (warning ? '#ff2020' : '#079607') : '#054dd9'};
  cursor: pointer;
`;
class FixedRegistrBudgetBudgetList extends Component<Props, State> {
  state = {
    filter: {},
    contractVehicleBudget: null,
    assignmentLimits: [],
    loading: false,
    modalVisible: false,
    monthData: null,
    vehicleName: null,
    selectedLimits: null,
    openPeriod: null,
  };

  componentDidMount() {
    this.get();
  }

  apllyFilter = (filter: FixedRegistBudgetBudgetFilterParams) => {
    this.setState({filter}, this.fetchAssignLimit);
  };

  cleanFilter = () => {
    this.setState({filter: {}}, this.fetchAssignLimit);
  };

  get = async () => {
    const {contractVehiclePlanId} = this.props;
    try {
      const contractVehicleBudget = await contractVehicleBudgetApi.getByContractVehiclePlan(
        contractVehiclePlanId,
      );

      const openPeriod = await getOpenPeriod(contractVehicleBudget.assignmentId, true);
      this.setState({contractVehicleBudget, openPeriod}, this.fetchAssignLimit);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    }
  };

  fetchAssignLimit = async () => {
    const {contractVehiclePlanId} = this.props;
    const {filter} = this.state;
    this.setState({loading: true});
    try {
      const data = await assignmentLimitApi.fetch({
        ...filter,
        'assignmentLimitGroup.assignment.contractVehiclePlanId': contractVehiclePlanId,
        page: 0,
        pageSize: undefined,
      })
      .then(({data}) => {
        data = data.map(item => ({
          ...item,
          cognosType: this.props.cognosType,
        }));
        return data;
      });
      this.setState({assignmentLimits: data});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      this.setState({loading: false});
    }
  };

  handleChangeStatus = (status: EntityStatusType) => async () => {
    const {contractVehicleBudget} = this.state;
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving',
      });
      if (contractVehicleBudget?.id) {
        const data = await contractVehicleBudgetApi.changeStatus(
          contractVehicleBudget.id,
          status,
        );
        this.setState({contractVehicleBudget: data});
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message,
      });
    } finally {
      notification.close('saving');
    }
  };

  canApproved = () =>
    this.props.userAccess.some(access =>
      approveAccessRightContractVehicleBudget.includes(access),
    );

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
        accessTypeEnum.editingBudgetCopy,
      ].includes(access),
    );

  handlePrint = () => {
    printNotification(
      async () =>
        await contractVehicleBudgetApi.print(this.props.contractVehiclePlanId),
    );
  };

  handleDetailedPrint = () => {
    printNotification(
      async () =>
        await contractVehicleBudgetApi.detailedPrint(
          this.props.contractVehiclePlanId,
        ),
    );
  };

  handleMonthDataUpdate = async monthData => {
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      this.setState({loading: true});
      await fetchRequest.put('/ContractVehicleBudget/correct', {
        ...monthData,
        sum: convertFromRubToKop(monthData.sum),
        correctedKilometrage: convertFromKmToM(monthData.correctedKilometrage),
        correctedHours: convertFromHourToSecond(monthData.correctedHours),
        correctedWeekendKilometrage: convertFromKmToM(monthData.correctedWeekendKilometrage),
        correctedWeekendHours: convertFromHourToSecond(monthData.correctedWeekendHours),
      });
      await this.fetchAssignLimit();
      await this.get();
    } catch (e) {
      notification.error({
        message: 'Ошибка',
        description: e.message,
      });
    } finally {
      notification.close('saving');
      this.handleModalCancel();
    }
  };

  handleModalCancel = () => {
    this.setState({
      modalVisible: false,
      monthData: null,
      vehicleName: null,
      loading: false,
      selectedLimits: null,
    });
  };

  handleMonthSelect = (monthData, vehicleData, limits) => {
    const {openPeriod} = this.state;
    const date = moment().month(monthData.month - 1).format();
    if (openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
      this.setState({
        modalVisible: true,
        monthData,
        vehicleName: vehicleData,
        selectedLimits: limits,
      });
    }
  };

  render() {
    const {contractVehiclePlanId, cognosType} = this.props;
    const {
      filter,
      contractVehicleBudget,
      assignmentLimits,
      loading,
      modalVisible,
      monthData,
      vehicleName,
      selectedLimits,
    } = this.state;
    if (contractVehicleBudget === null) {
      return null;
    }
    // дельты данных по направлениям (модуль разницы текущих данных и данных из когноса)
    const carServicesCostDelta = Math.abs(contractVehicleBudget.carServicesCost -
      contractVehicleBudget.cognosCarServicesCost);
    const otherServicesCostDelta = Math.abs(contractVehicleBudget.otherVehicleServicesCost -
      contractVehicleBudget.cognosOtherVehicleServicesCost);
    const acsServicesCostDelta = Math.abs(contractVehicleBudget.acsVehicleServicesCost -
      contractVehicleBudget.cognosAcsVehicleServicesCost);
    const cargoServicesCostDelta = Math.abs(contractVehicleBudget.cargoServicesCost -
      contractVehicleBudget.cognosCargoServicesCost);
    const mechanismServicesCostDelta = Math.abs(contractVehicleBudget.mechanismServicesCost -
      contractVehicleBudget.cognosMechanismServicesCost);
    const vehicleServicesFromProfitCostDelta = Math.abs(contractVehicleBudget.vehicleServicesFromProfitCost -
      contractVehicleBudget.cognosVehicleServicesFromProfitCost);
    // Блокируем кнопку утверждения бюджета, если пользователь не админ и сумма дельт по направлениям больше
    // COGNOS_DELTA
    const approveButtonIsDisabled = this.props.userAccess.includes(accessTypeEnum.admin)
      ? false
      : carServicesCostDelta + otherServicesCostDelta + acsServicesCostDelta + cargoServicesCostDelta +
      mechanismServicesCostDelta + vehicleServicesFromProfitCostDelta > COGNOS_DELTA;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(`/budget/contract-vehicle/fixed/${cognosType}`)}>
                Закрепленные НТС
              </Crumb>
              <Crumb>Бюджет</Crumb>
            </Breadcrumbs>
          }
          right={
            <ButtonsRow>
              {(contractVehicleBudget?.status === entityStatusEnum.approved ||
                contractVehicleBudget?.status === entityStatusEnum.approvedAfterCognos ||
                contractVehicleBudget?.status === entityStatusEnum.approvementAfterCognos) && (
                <>
                  <Button onClick={this.handlePrint}>Печать предложения</Button>
                  <Button onClick={this.handleDetailedPrint}>
                    Печать детальной выгрузки
                  </Button>
                </>
              )}
              {contractVehicleBudget?.status === entityStatusEnum.approvementAfterCognos && this.canApproved() && (
                <Button
                  type="primary"
                  onClick={this.handleChangeStatus(
                    entityStatusEnum.approvedAfterCognos,
                  )}
                  disabled={approveButtonIsDisabled}
                  title={
                    approveButtonIsDisabled
                      ? `Разница значений по направлениям с контрольными значеними БК не должна быть больше ${COGNOS_DELTA}`
                      : undefined
                  }
                >
                  Утвердить копию бюджета
                </Button>
              )}
            </ButtonsRow>
          }
        />
        <TopPanel>
          <h1>Расчет бюджета на закрепленные НТС</h1>
        </TopPanel>
        <Tabs
          cognosType={cognosType}
          contractVehiclePlanId={contractVehiclePlanId}
          assignmentApproved={true}
        />
        {contractVehicleBudget && (
          <>
            <Section>
              <SectionTitle divider>
                Расчет бюджета по направлениям расходов
              </SectionTitle>
              <Content>
                <Grid
                  gutter="16px"
                  cols={cognosType === CognosType.afterCognos ? 3 : 2}
                >
                  <GridItem>
                    <Field label="Услуги легкового транспорта, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/car-services`,
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.carServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Услуги легкового транспорта (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(
                              contractVehicleBudget.cognosCarServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan
                            warning={carServicesCostDelta > COGNOS_DELTA}
                          >
                            {formatRub(
                              contractVehicleBudget.carServicesCost -
                              contractVehicleBudget.cognosCarServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Прочие транспортные расходы, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/other-vehicle-services`,
                          )
                        }
                      >
                        {formatRub(
                          contractVehicleBudget.otherVehicleServicesCost,
                        )}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Прочие транспортные расходы (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(
                              contractVehicleBudget.cognosOtherVehicleServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan
                            warning={otherServicesCostDelta > COGNOS_DELTA}
                          >
                            {formatRub(
                              contractVehicleBudget.otherVehicleServicesCost -
                              contractVehicleBudget.cognosOtherVehicleServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Услуги автотранспорта для ОКС, без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/acs-vehicle-services`,
                          )
                        }
                      >
                        {formatRub(
                          contractVehicleBudget.acsVehicleServicesCost,
                        )}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Услуги автотранспорта для ОКС (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(
                              contractVehicleBudget.cognosAcsVehicleServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan
                            warning={acsServicesCostDelta > COGNOS_DELTA}
                          >
                            {formatRub(
                              contractVehicleBudget.acsVehicleServicesCost -
                              contractVehicleBudget.cognosAcsVehicleServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Услуги а/т со стороны по транспортировке грузов, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/cargo-services`,
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.cargoServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Услуги а/т со стороны по транспортировке грузов (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(
                              contractVehicleBudget.cognosCargoServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan
                            warning={cargoServicesCostDelta > COGNOS_DELTA}
                          >
                            {formatRub(
                              contractVehicleBudget.cargoServicesCost -
                              contractVehicleBudget.cognosCargoServicesCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Услуги механизмов, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/mechanism-services`,
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.mechanismServicesCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Услуги механизмов (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(contractVehicleBudget.cognosMechanismServicesCost)}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan warning={mechanismServicesCostDelta > COGNOS_DELTA}>
                            {formatRub(contractVehicleBudget.mechanismServicesCost -
                              contractVehicleBudget.cognosMechanismServicesCost)}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Транспортные расходы из прибыли, руб. без НДС">
                      <StyledSpan
                        onClick={() =>
                          navigate(
                            `/budget/contract-vehicle/fixed/${cognosType}/${contractVehiclePlanId}/budget/${contractVehicleBudget.id ||
                            0}/expense-direction/vehicle-services-from-profit`,
                          )
                        }
                      >
                        {formatRub(contractVehicleBudget.vehicleServicesFromProfitCost)}
                      </StyledSpan>
                    </Field>
                  </GridItem>
                  {cognosType === CognosType.afterCognos && (
                    <>
                      <GridItem>
                        <Field label="Транспортные расходы из прибыли (Контрольные значения БК)">
                          <StyledSpan>
                            {formatRub(contractVehicleBudget.cognosVehicleServicesFromProfitCost)}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дельта">
                          <StyledSpan warning={vehicleServicesFromProfitCostDelta > COGNOS_DELTA}>
                            {formatRub(
                              contractVehicleBudget.vehicleServicesFromProfitCost -
                              contractVehicleBudget.cognosVehicleServicesFromProfitCost,
                            )}
                          </StyledSpan>
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Текущий статус">
                      {contractVehiclePlanStatus[contractVehicleBudget.status] || '-'}
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
            </Section>

            <Section>
              <Content>
                <Filter
                  filter={filter}
                  applyFilter={this.apllyFilter}
                  cleanFilter={this.cleanFilter}
                />
              </Content>
              <ListTable
                isLoading={loading}
                style={{overflowX: 'auto'}}
                styleContent={{width: '2500px'}}
                data={assignmentLimits}
                header={listHeaderBudgetList()}
                renderRow={(assignmentLimit, index) =>
                  listRowBudgetList(
                    assignmentLimit,
                    index,
                    contractVehicleBudget?.status === entityStatusEnum.approvementAfterCognos && this.canEdit()
                      ? this.handleMonthSelect
                      : undefined,
                  )
                }
              />
              <EditingModal
                visible={modalVisible}
                handleCancel={this.handleModalCancel}
                handleSave={this.handleMonthDataUpdate}
                monthData={monthData}
                vehicleName={vehicleName}
                limits={selectedLimits}
              />
            </Section>

            {contractVehicleBudget &&
              this.canApproved() &&
              [
                entityStatusEnum.created,
                entityStatusEnum.approvement,
                entityStatusEnum.editing,
              ].includes(contractVehicleBudget.status) && (
                <Section>
                  <Content>
                    {[
                      entityStatusEnum.created,
                      entityStatusEnum.editing,
                    ].includes(contractVehicleBudget.status) && (
                      <Button
                        type="primary"
                        onClick={this.handleChangeStatus(entityStatusEnum.approvement)}
                      >
                        На согласование
                      </Button>
                    )}
                    {entityStatusEnum.approvement === contractVehicleBudget.status && (
                        <>
                          <Button
                            type="primary"
                            onClick={this.handleChangeStatus(entityStatusEnum.approved)}
                            style={{marginRight: '10px'}}
                          >
                            Утвердить
                          </Button>
                          <Button
                            onClick={this.handleChangeStatus(entityStatusEnum.declined)}
                            style={{marginRight: '10px'}}
                          >
                            Отклонить
                          </Button>
                        </>
                      )}
                  </Content>
                </Section>
              )}
          </>
        )}
      </>
    );
  }
}

export default withUserAccess(FixedRegistrBudgetBudgetList);
