// @flow
import React, {Component} from 'react';

import {driverInspectionCalculationApi} from '../../../../lib/api';
import {printNotification} from '../../../../lib/notificationWrapper';
import Common from './components/Common';
import {DriverInspectionCalculation, VehiclePlan} from '../../../../lib/types';
import {COLUMNS, MONTH} from '../lib';
import {toLocalStringRu} from '../../../../lib/helpers';
import {calculationStatusEnum} from '../../../../lib/enum';
import {dateIsIncludeInOpenPeriod, getOpenPeriod} from '../../contractVehicle/fixed/lib';
import moment from 'moment/moment';

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number
};

type State = {
  monthData: ?DriverInspectionCalculation,
  monthIndex: ?number,
  vehiclePlan: ?VehiclePlan,
  openPeriod?: {
    start: string,
    end: string,
  }
};

export default class extends Component<Props, State> {
  state = {
    monthData: null,
    monthIndex: null,
    openPeriod: null,
  };

  async componentDidMount() {
    if (this.props.vehiclePlanId) {
      this.setState({openPeriod: await getOpenPeriod(this.props.vehiclePlanId)});
    }
  }
  handleCancel = () => {
    this.setState({
      monthIndex: null,
      monthData: null,
    });
  };
  onCell = (monthData, monthIndex) => ({
    onClick: () => {
      const {openPeriod, vehiclePlan} = this.state;
      const date = moment().month(monthIndex).format();
      if (monthData.id && vehiclePlan?.driverInspectionCalculationStatus === calculationStatusEnum.draft &&
        openPeriod && dateIsIncludeInOpenPeriod(openPeriod, date)) {
        this.setState({monthData, monthIndex});
      }
    },
  });
  columns = [
    ...COLUMNS,
    ...MONTH.map((month, index) => ({
      title: month.title,
      width: '150',
      dataIndex: `months[${index}].cost`,
      render: (cost: number) =>
        toLocalStringRu(cost, {minimumFractionDigits: 2}),
      onCell: monthData => this.onCell(monthData, index),
    })),
    {
      title: 'Итог',
      dataIndex: 'sum',
      render: (sum: number) =>
        toLocalStringRu(sum, {minimumFractionDigits: 2}),
    },
  ];
  render() {
    const {location, vehiclePlanId} = this.props;
    const {monthData, monthIndex} = this.state;
    return (
      <Common
        columns={this.columns}
        location={location}
        vehiclePlanId={vehiclePlanId}
        crud={driverInspectionCalculationApi}
        calcScrollY={430}
        statusField={'driverInspectionCalculationStatus'}
        title="Расчет потребностей в предрейсовых/послерейсовых осмотров водителей"
        handlePrint={filter => {
          printNotification(async () => {
            await driverInspectionCalculationApi.print(vehiclePlanId, filter);
          });
        }}
        editingModalData={{
          monthData,
          monthIndex,
          handleCancel: this.handleCancel,
          setVehiclePlan: vehiclePlan => this.setState({vehiclePlan}),
        }}
        linksToDictionaries={{
          'Стоимость осмотров': '/admin/trip-inspection-cost/',
          'Периодичность осмотров': '/admin/trip-inspection-frequency/',
          'Производственный календарь для бюджета': '/admin/the-business-calendar-for-budget/',
        }}
      />
    );
  }
}
