// @flow
import { fetchRequest, downloadRequestWithToken } from './index';
import type { BudgetAnalysis } from '../types';
import CommonApi from './../commonApi';
import { convertFromKopToRub } from '../helpers';

const baseUrl = 'budgetAnalysis';
const budgetAnalysis = new CommonApi<BudgetAnalysis>(baseUrl, 'Анализ затрат');

export const getExpenseDirectionInfo = async (params: any) => {
  const expenseDirectionInfo = await fetchRequest.get(
    `/${baseUrl}/getExpenseDirectionInfo`,
    params
  );
  if (expenseDirectionInfo)
    return expenseDirectionInfo.map(value => ({
      ...value,
      sum: convertFromKopToRub(value.sum)
    }));
  throw new Error('Не удалось загрузить информацию');
};

export const print = async (id: number) => {
  const printed = await downloadRequestWithToken(`/${baseUrl}/print/${id}`);
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...budgetAnalysis,
  print,
  getExpenseDirectionInfo
};
