// @flow
import React, {
  Component,
  type Element,
  type ComponentType,
  type Node
} from 'react';
import styled, { css } from 'styled-components';
import { Link as ReachLink } from '@reach/router';
import classNames from 'classnames';

import Icon from './Icon';

// Содержимое меню
const Content = styled.div.attrs({
  className: 'menu__content'
})`
  height: 0;
  padding-left: 16px;
  &:not(.menu) {
    display: none;
  }
  ${props =>
    props.expanded &&
    css`
      height: 100%;
      &:not(.menu) {
        display: block;
      }
    `};
`;

type WrapperProps = {
  /**
   * Уровень меню
   * необходимо для контролирования размера отступов
   */
  level: number,
  children: Node,
  className: string
};
const Wrapper = (props: WrapperProps) => (
  <div className={props.className}>{props.children}</div>
);

// Обертка меню
const StyledWrapper = styled(Wrapper).attrs(props => ({
  className: classNames('menu', { 'menu--expanded': props.expanded })
}))`
  ${props =>
    props.level === 0
      ? css`
          overflow-x: hidden;
          & .menu__content {
            padding-left: 0;
          }
        `
      : css`
          & .menu__content {
            padding-left: 16px;
          }
        `};
  & > .menu {
    transition: height 0.3s;
    overflow-x: auto;
    & > .menu__label {
      padding: 10px 0;
    }
  }
`;

// Подпись меню
const Label = styled.li.attrs({
  className: 'menu__label'
})`
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 10px 16px;
  font-weight: 500;
  cursor: pointer;
`;

// Иконка у label
const StyledIcon = styled(Icon)`
  margin-left: 12px;
`;

type LinkProps = {
  // Ссылка в пункте меню
  to: string,
  replace?: boolean
};
const Link = (props: LinkProps) => (
  <ReachLink
    {...props}
    getProps={({ isPartiallyCurrent }) =>
      isPartiallyCurrent ? { active: 'active' } : null
    }
  />
);

/**
 * Пункт меню
 */
export const StyledLink: ComponentType<LinkProps> = styled(Link).attrs({
  className: 'menu__link'
})`
  color: #98a3ad;
  display: block;
  padding: 10px 16px;
  font-size: 14px;
  &:focus {
    text-decoration: none;
  }
  &[active] {
    color: white;
    font-weight: 500;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 2000px;
      top: 0;
      bottom: 0;
      left: -1000px;
      z-index: -1;
      background: #2770ff;
    }
  }
`;

export class MenuItem extends Component<
  LinkProps & { parentExpand?: Function }
> {
  /**
   * Если ссылка совпадает или входим в текущим location
   * то открываем родительский пункт меню
   */
  componentDidMount() {
    if (window.location.href.includes(this.props.to)) {
      this.props.parentExpand();
    }
  }

  render() {
    const { parentExpand, ...linkProps } = this.props;
    return <StyledLink {...linkProps} />;
  }
}

type MenuProps = {
  // Подпись меню
  label: string,
  // Пункты меню
  children: Element<any>,
  // Необходима для раскрытия родительского пунктка меню
  parentExpand?: Function,
  /**
   * Уровень меню
   * необходимо для контролирования размера отступов
   */
  level: number
};

type MenuState = {
  expanded: boolean
};

/**
 * Меню
 */
export default class Menu extends Component<MenuProps, MenuState> {
  state = {
    expanded: false
  };

  static defaultProps = {
    level: 0
  };

  toggleExpand = () =>
    this.setState(prev => ({
      expanded: !prev.expanded
    }));

  expandByUrl = () => {
    if (this.props.parentExpand) {
      this.props.parentExpand();
    }
    this.setState({
      expanded: true
    });
  };

  render() {
    const { children, label, level } = this.props;
    const { expanded } = this.state;
    return (
      <StyledWrapper level={level}>
        <Label onClick={this.toggleExpand}>
          {label}
          <StyledIcon
            className="menu__icon"
            type={expanded ? 'arrow-up' : 'arrow-down'}
          />
        </Label>
        <Content expanded={expanded}>
          {React.Children.map(children, (children, key) =>
            children
              ? React.cloneElement(children, {
                  ...children.props,
                  key,
                  parentExpand: this.expandByUrl,
                  level:
                    children.type === Menu ? this.props.level + 1 : undefined
                })
              : null
          )}
        </Content>
      </StyledWrapper>
    );
  }
}
