// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { SelectProps } from 'antd/lib/select';
import Icon from 'antd/lib/icon';

import { fetchBranchEmployeesPositions } from '../../ducks/branchEmployeesPositions';
import type { AppState } from '../../ducks/redux';
import TreeSelect from '../ui/TreeSelect';

import type { EmployeeBranchPosition } from '../../lib/types';

type Props = {
  branchId: number,
  filter?: any,
  optionKey?: string,
  data: ?(EmployeeBranchPosition[]),
  loading: boolean,
  fetchBranchEmployeesPositions: Function
} & SelectProps;

const EmployeePositionSelect = (props: Props) => {
  const [value] = useState();
  useEffect(() => {
    props.fetchBranchEmployeesPositions(props.branchId);
  }, [props]);

  return props.data ? (
    <TreeSelect
      treeDataSimpleMode
      value={value}
      placeholder="Выберите должность"
      treeData={props.data}
      treeNodeFilterProp="title"
      showSearch
      allowClear
      {...props}
    />
  ) : (
    <Icon type="loading" />
  );
};

export default connect(
  (state: AppState) => ({
    data: state.branchEmployeesPositions.data,
    loading: state.branchEmployeesPositions.fetching
  }),
  {
    fetchBranchEmployeesPositions
  }
)(EmployeePositionSelect);
