// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';

import type {MaintenanceWorkType} from '../../lib/types';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {Header, Panel} from '../../components/layout';
import InnerForm from './components/InnerForm';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';
import {maintenanceWorkTypeApi} from '../../lib/api';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  maintenanceWorkTypeId: number
};

type State = {
  maintenanceWorkType: ?MaintenanceWorkType
};

export class MaintenanceTypeWorkForm extends React.Component<Props, State> {
  state = {
    maintenanceWorkType: null
  };

  async componentDidMount() {
    const { maintenanceWorkTypeId } = this.props;
    try {
      if (maintenanceWorkTypeId) {
        const maintenanceWorkType = await maintenanceWorkTypeApi.getMaintenanceTypeWork(
          maintenanceWorkTypeId
        );
        this.setState({ maintenanceWorkType });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: MaintenanceWorkType) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await maintenanceWorkTypeApi.updateMaintenanceTypeWork(values);
        await navigate(`/admin/maintenance-work-type`, true);
      } else {
        await maintenanceWorkTypeApi.addMaintenanceTypeWork(values);
        await navigate(`/admin/maintenance-work-type`, true);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/maintenance-work-type', true);

  render() {
    const { maintenanceWorkType } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/maintenance-work-type')}
              >
                Справочник видов работ по обслуживанию ТС
              </Crumb>
              {maintenanceWorkType ? (
                <Crumb>Вид работ №{maintenanceWorkType.id}</Crumb>
              ) : (
                <Crumb>Новый вид работ</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {maintenanceWorkType
              ? `Виды работ №${maintenanceWorkType.id}`
              : 'Новый вид работ'}
          </h1>
        </StyledPanel>
        <InnerForm
          maintenanceWorkType={maintenanceWorkType}
          onSubmit={this.handleSubmit}
          onCancel={this.handleCancel}
        />
      </>
    );
  }
}

export default MaintenanceTypeWorkForm;
