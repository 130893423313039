// @flow

import React, {Component} from 'react';
import notification from 'antd/lib/notification';
import styled from 'styled-components';

import type {Maintenance} from './../../lib/types';
import {maintenanceStatusesEnum} from './../../lib/enum';
import {maintenanceApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from '../../lib/helpers';

import {Panel} from './../../components/layout';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../components/Notifications';
import InnerForm from './components/InnerForm';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

class MaintenanceForm extends Component<null> {
  onSubmit = async (values: Maintenance) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const maintenance = await maintenanceApi.addMaintenance({
        ...values,
        status: maintenanceStatusesEnum.created
      });
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });

      navigate(`/maintenances/${maintenance.id}`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/maintenances')}>
                Ремонт
              </Crumb>
              <Crumb to="/maintenances/new">Новая карточка работ</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Новая карточка работ</h1>
        </StyledPanel>
        <InnerForm onSubmit={this.onSubmit} />
      </>
    );
  }
}

export default MaintenanceForm;
