// @flow
import React from 'react';
import {withUserAccess} from '../../../withUserAccess';
import {GridItem, Header, Panel, Section, SectionTitle} from '../../../../components/layout';
import Breadcrumbs, {Crumb} from '../../../../components/layout/Breadcrumbs';
import Grid from '../../../../components/layout/Grid';
import {SectionContent} from '../../../services/elements';
import styled from 'styled-components';
import Field from '../../../../components/card/Field';
import moment from 'moment/moment';
import {Icon, Table} from '../../../../components/ui';
import type {UserAccess} from '../../../../lib/types';
import {FuelsAct} from '../../../../lib/types';
import Preloader from '../../../../components/ui/Preloader';
import {accessTypeEnum, fuelTypes, fuelTypesCalculation} from '../../../../lib/enum';
import {downloadRequestWithToken, fetchRequest, fuelsActApi} from '../../../../lib/api';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import {notificationError, notificationLoading} from '../../../../components/Notifications';
import Popconfirm from 'antd/lib/popconfirm';
import Modal from 'antd/lib/modal';
import {FormikProps} from 'formik';
import Form from '../../../../components/Form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import {convertFromKopToRub} from '../../../../lib/helpers';
import {EquipmentData} from '../../../../lib/types/fuelsAct';
import AutoGeneratedEnumSelect from '../../../../components/selects/AutoGeneratedEnumSelect';
import {fuelOilsTypes} from '../../../../lib/enum/fuelType';

const StyledPanel = styled(Panel)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
`;

const Cell = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${props => props.width + 'px' || '100%'};
`;

const StyledNumberInput = styled(InputNumber)`
  width: 100%;
`;

const FuelTypeSelect = AutoGeneratedEnumSelect({
  placeholder: 'Выберите вид топлива',
  autoType: {...fuelTypesCalculation, ai98: 'АИ-98', ...fuelOilsTypes},
});

type Props = {
  actId: number,
  userAccess: UserAccess[],
};

type State = {
  act: FuelsAct | null,
  loading: boolean,
  modalVisibility: boolean,
  selectedEquipment: EquipmentData,
  actColumns: any[],
  equipmentColumns: any[],
}

export class WriteOffFuelAct extends React.Component<Props, State> {
  async componentDidMount() {
    this.setState({loading: true});
    const act = await fuelsActApi.getFuelActById(this.props.actId);
    this.setState({act, loading: false});
  }
  canHandlingAct = () => {
    return [accessTypeEnum.admin, accessTypeEnum.handlingFuelsAct].some(i => this.props.userAccess.includes(i));
  };
  recalculation = async () => {
    try {
      notificationLoading({message: 'Осуществляется перерасчет акта списания топлива', key: 'recalculation'});
      const newAct = await fuelsActApi.recalculateAct(this.props.actId);
      this.setState({act: newAct});
      notification.success({message: 'Перерасчет успешно выполнен'});
    } catch (e) {
      notification.error(e);
    } finally {
      notification.close('recalculation');
    }
  };
  print = async () => {
    if (!this.state.act) {
      return;
    }
    if (this.state.act.file?.url) {
      await downloadRequestWithToken(this.state.act.file.url, null, null, true);
    } else {
      await downloadRequestWithToken(`/fuelsAct/${this.props.actId}/print`);
    }
  };
  deleteEquipment = async (id) => {
    try {
      notificationLoading({message: 'Удаление оборудования', key: 'delete'});
      const newAct = await fuelsActApi.deleteEquipment(id);
      setTimeout(() => {
        this.setState({selectedEquipment: {}, modalVisibility: false, act: newAct});
        notification.success({message: 'Оборудование удалено'});
      }, 100);
    } catch (e) {
      notificationError(e);
    } finally {
      notification.close('delete');
    }
  };
  state = {
    act: null,
    loading: false,
    modalVisibility: false,
    selectedEquipment: {},
    actColumns: [
      {
        title: '№ п/п',
        dataIndex: 'sequenceNumber',
        width: 35,
        fixed: true,
      },
      {
        title: 'Транспортное средство/механизм',
        fixed: true,
        children: [
          {
            title: 'Гос. №',
            dataIndex: 'licensePlate',
            width: 110,
          },
          {
            title: 'Тип ТС',
            dataIndex: 'vehicleType',
            width: 250,
            render: value => <Cell width={215}>{value}</Cell>,
          },
          {
            title: 'Марка ТС',
            dataIndex: 'brandName',
            width: 150,
            render: value => <Cell width={115}>{value}</Cell>,
          },
        ],
      },
      {
        title: <span>Количество<br />выписанных<br />ПЛ, шт.</span>,
        dataIndex: 'tripsCount',
        width: 100,
      },
      {
        title: 'Вид топлива',
        dataIndex: 'fuelType',
        width: 100,
      },
      {
        title: <span>Выдано<br />топлива<br />по ПЛ, л.</span>,
        dataIndex: 'issuedFuel',
        width: 80,
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: <span>Остаток<br />топлива<br />на начало<br />периода, л.</span>,
        dataIndex: 'fuelAtStart',
        width: 90,
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: 'Норматив расхода ГСМ на ТС',
        children: [
          {
            title: 'л/100 км',
            dataIndex: 'fuelConsumptionRateByMileage',
            width: 85,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
          {
            title: 'л/м.ч.',
            dataIndex: 'fuelConsumptionRateByEngineHours',
            width: 70,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
          {
            title: <span>Коэффициенты<br />повышающие, %</span>,
            dataIndex: 'fuelConsuptionMultiplier',
            width: 128,
          },
        ],
      },
      {
        title: <span>Норматив<br />расхода ГСМ<br />на оборудование</span>,
        dataIndex: 'fuelConsuptionRateByEquipmentWork',
        width: 90,
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: 'Фактические данные по эксплуатации',
        children: [
          {
            title: <span>Пробег<br />ТС, км</span>,
            dataIndex: 'totalMileage',
            width: 75,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
          {
            title: <span>Работа<br />ТС, м.ч.</span>,
            dataIndex: 'totalVehicleEngineHours',
            width: 80,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
          {
            title: <span>Работа<br />оборудования, м.ч.</span>,
            dataIndex: 'totalEquipmentHours',
            width: 143,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
        ],
      },
      {
        title: 'Истрачено топлива',
        children: [
          {
            title: <span>Истрачено<br />топлива по<br />ПЛ, л</span>,
            dataIndex: 'totalFuelConsumption',
            width: 100,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
          {
            title: <span>В том числе<br />истрачено<br />на работу<br />оборудования, л</span>,
            dataIndex: 'equipmentFuelConsumption',
            width: 130,
            render: value => Math.ceil((value || 0) * 100) / 100,
          },
        ],
      },
      {
        title: <span>Остаток<br />топлива<br />на конец<br />периода, л</span>,
        dataIndex: 'fuelAtEnd',
        width: 100,
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: <span>Цена за литр,<br />руб. без НДС</span>,
        dataIndex: 'fuelPrice',
        width: 110,
      },
      {
        title: <span>Сумма, руб.<br />без НДС</span>,
        dataIndex: 'sum',
        width: 90,
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
    ],
    equipmentColumns: [
      {
        title: '№ п/п',
        dataIndex: 'sequenceNumber',
      },
      {
        title: 'Прочее оборудование',
        dataIndex: 'name',
      },
      {
        title: 'Вид топлива',
        dataIndex: 'fuelType',
        render: type => fuelTypes[type],
      },
      {
        title: 'Выдано топлива по ПЛ, л.',
        dataIndex: 'issuedFuel',
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: <span>Остаток топлива<br />на начало периода, л.</span>,
        dataIndex: 'fuelAtStart',
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: <span>В том числе истрачено<br />на работу оборудования, л.</span>,
        dataIndex: 'totalFuelConsumption',
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: <span>Остаток топлива<br />на конец периода, л.</span>,
        dataIndex: 'fuelAtEnd',
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: 'Цена за литр, руб. без НДС',
        dataIndex: 'fuelPrice',
      },
      {
        title: 'Сумма, руб. без НДС',
        dataIndex: 'sum',
        render: value => Math.ceil((value || 0) * 100) / 100,
      },
      {
        title: '',
        dataIndex: 'id',
        render: (id, record) => id > 0 && this.canHandlingAct() && (
          <Popconfirm
            title={`Вы уверены, что хотите удалить оборудование ${record.name}?`}
            onConfirm={async () => await this.deleteEquipment(id)}
          >
            <Icon type="x" />
          </Popconfirm>
        ),
        onCell: () => ({onClick: (e) => e.stopPropagation()}),
      },
    ],
  };
  onSubmit = async (data) => {
    try {
      notificationLoading({message: 'Сохранение данных...', key: 'submit'});
      const request = data.id ? fuelsActApi.updateEquipment : fuelsActApi.addEquipment;
      if (!data.id) {
        data.fuelsActId = this.state.act.id;
      }
      setTimeout(async () => {
        const newAct = await request(data);
        this.setState({selectedEquipment: {}, modalVisibility: false, act: newAct});
        notification.success({message: data.id ? 'Изменения сохранены' : 'Оборудование добавлено'});
      }, 10);
    } catch (e) {
      notificationError(e);
    } finally {
      notification.close('submit');
    }
  };

  closeModal = () => {
    this.setState({selectedEquipment: {}, modalVisibility: false});
  };

  getFuelPrice = async (fuelType) => {
    let cost = 0;
    try {
      cost = await fetchRequest.get(`/FuelPrice/getPrice?date=${this.state.act.startDate}&type=${fuelType}`);
    } catch (e) {
      notificationError(e);
    }
    return convertFromKopToRub(cost);
  };

  onRowClick = (rowData) => {
    this.setState({selectedEquipment: rowData, modalVisibility: true});
  };

  render() {
    const {loading, act, actColumns, equipmentColumns, modalVisibility, selectedEquipment} = this.state;
    return loading || !act
      ? (<Preloader />)
      : (
        <>
          <Header
            left={
              <Breadcrumbs>
                <Crumb to={'/admin/act/internal/fuels-act'}>Реестр актов списания ГСМ</Crumb>
                <Crumb>Акт списания топлива № {act.documentNumber}</Crumb>
              </Breadcrumbs>
            }
            right={<Button type="primary" onClick={this.print}>Печать</Button>}
          />
          <StyledPanel>
            <h1>Акт списания топлива № {act.documentNumber}</h1>
          </StyledPanel>
          <Section>
            <SectionContent>
              <Grid cols={2} gutter="16px">
                <GridItem>
                  <Field label="Филиал">
                    {act.orgUnitName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата начала периода формирования">
                    {moment(act.startDate).utc().format('DD.MM.YYYY')}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Служба/РЭС">
                    {act.orgUnitMnemonic}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата окончания периода формирования">
                    {moment(act.endDate).utc().format('DD.MM.YYYY')}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle
              suffix={this.canHandlingAct() && (
                <Button type="primary" onClick={this.recalculation}>Перерасчет</Button>
              )}
            >
              Акт списания топлива
            </SectionTitle>
            <Table
              data={[
                ...act.vehicles
                .map((i, ind) => ({...i, sequenceNumber: ind + 1})),
                ...act.totals
                .filter(i => i.type !== 'equipments')
                .sort((a, b) => a.fuelType - b.fuelType)
                .map(i => ({
                  ...i,
                  sequenceNumber: i.type === 'vehicles' ? 'Итого по ТС' : 'Итоги с учетом расхода ГСМ на прочее оборудование',
                  id: Math.random() * 1000 * -1,
                  fuelType: fuelTypes[i.fuelType],
                  fuelAtStart: i.fuelAtStart,
                  fuelAtEnd: i.fuelAtEnd,
                })),
              ]}
              columns={actColumns}
              bordered
              rowKey="id"
              scroll={{
                y: '40vh',
                x: 'auto',
              }}
              rowClassName={i => i.id < 0 && 'write-off-fuel-act-total-row'}
            />
          </Section>
          <Section>
            <SectionTitle
              suffix={this.canHandlingAct && act.file === null && (
                <Button type="primary" onClick={() => this.setState({modalVisibility: true})}>Добавить</Button>
              )}>
              Расход ГСМ на прочее оборудование
            </SectionTitle>
            <Table
              data={[
                ...act.equipments.map((i, ind) => ({...i, sequenceNumber: ind + 1})),
                ...act.totals.map((i) => ({...i, totalFuelConsumption: i.equipmentFuelConsumption}))
                .filter(i => i.type === 'equipments')
                .map(i => ({
                  ...i,
                  sequenceNumber: 'Итоги по прочему оборудованию',
                  id: Math.random() * 10000 * -1,
                })),
              ]}
              columns={equipmentColumns}
              rowKey="id"
              rowClassName={i => i.id < 0 && 'write-off-fuel-act-total-row'}
              onRow={(rowData) => ({onClick: () => this.canHandlingAct() && this.onRowClick(rowData)})}
            />
          </Section>

          <Modal
            title="Расход ГСМ на прочее оборудование"
            visible={modalVisibility}
            onCancel={this.closeModal}
            width={800}
            destroyOnClose
            footer={[
              <Button key="back" onClick={this.closeModal}>
                Отменить
              </Button>,
              <Button key="submit" type="primary" form="modal-form" htmlType="submit">
                Сохранить
              </Button>,
            ]}
          >
            <Form initialValues={selectedEquipment} onSubmit={this.onSubmit}>
              {(FormField, formikProps: FormikProps) => {
                const {setFieldValue, values} = formikProps;
                return (
                  <form onSubmit={formikProps.handleSubmit} id="modal-form">
                    <Grid gutter="16px">
                      <GridItem>
                        <FormField label="Прочее оборудование" required name="name">
                          {({name, value}) => (
                            <Input onChange={ev => setFieldValue(name, ev.target.value)} value={value} />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Вид топлива" required name="fuelType">
                          {({name, value}) => (
                            <FuelTypeSelect
                              value={value}
                              onChange={async type => {
                                setFieldValue(name, type);
                                const fuelPrice = await this.getFuelPrice(type);
                                setFieldValue('fuelPrice', fuelPrice);
                                setFieldValue('sum', Math.round(((values.totalFuelConsumption || 0) * fuelPrice) * 100) / 100);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Выдано топлива по ПЛ, л." required name="issuedFuel">
                          {({name, value}) => (
                            <StyledNumberInput
                              value={value}
                              onChange={value => {
                                setFieldValue(name, value);
                                setFieldValue(
                                  'fuelAtEnd',
                                  Math.round(((values.fuelAtStart || 0) + value - (values.totalFuelConsumption || 0)) * 100) /
                                  100,
                                );
                              }}
                              min={0}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="В том числе истрачено на работу оборудования, л."
                          required
                          name="totalFuelConsumption"
                        >
                          {({name, value}) => (
                            <StyledNumberInput
                              value={value}
                              onChange={value => {
                                setFieldValue(name, value);
                                setFieldValue('sum', Math.round((value * (values.fuelPrice || 0)) * 100) / 100);
                                setFieldValue(
                                  'fuelAtEnd',
                                  Math.round(((values.fuelAtStart || 0) + (values.issuedFuel || 0) - value) * 100) / 100,
                                );
                              }}
                              max={(values.issuedFuel || 0) + (values.fuelAtStart || 0)}
                              min={0}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Остаток топлива на начало периода, л." name="fuelAtStart" required>
                          {({value, name}) => (
                            <StyledNumberInput
                              value={value}
                              onChange={value => {
                                setFieldValue(name, value);
                                const fuelDifference = Math.round(((values.issuedFuel || 0) - (values.totalFuelConsumption || 0)) * 100) / 100
                                setFieldValue(
                                  'fuelAtEnd',
                                  Math.round((value + fuelDifference) * 100) / 100,
                                );
                              }}
                              min={0}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Остаток топлива на конец периода, л." name="fuelAtEnd">
                          {({value}) => <p>{value}</p>}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Цена за литр, руб. без НДС" name="fuelPrice">
                          {({value}) => <p>{value}</p>}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField label="Сумма, руб. без НДС" name="sum">
                          {({value}) => <p>{value}</p>}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </form>
                );
              }}
            </Form>
          </Modal>
        </>
      );
  }
}

export default withUserAccess(WriteOffFuelAct);
