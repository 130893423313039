// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import notification from 'antd/lib/notification';
import {Link} from '@reach/router';
import Button from 'antd/lib/button';
import Upload from 'antd/lib/upload';
import Popconfirm from 'antd/lib/popconfirm';
import qs from 'query-string';

import type {BusinessDay, BusinessDayType, ListState} from '../../lib/types';
import {formatDateTimeToString, getListInitialState, navigate, setQueryParams} from '../../lib/helpers';
import {businessDayTypes} from '../../lib/enum';
import {apiUrl, businessDayApi} from './../../lib/api';
import {Section} from './../../components/layout';
import {Icon, Operations, Table} from './../../components/ui';
import Header from './../../components/layout/Header';
import type {FetchListParams} from '../../lib/api';
import {notificationLoading} from '../../components/Notifications';
import tokenManager from '../../lib/tokenManager';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledUpload = styled(Upload)`
  margin-left: 20px;
`;

type Props = {
  location: Location & { state: { page: number } }
};

type State = ListState<BusinessDay>;

class BusinessCalendar extends Component<Props, State> {
  state = getListInitialState();

  fetchBusinessDays = async (
    page: number = 1,
    params: FetchListParams<any> = {}
  ) => {
    this.setState({ loading: true });
    const { data, totalCount } = await businessDayApi.fetchBusinessDays({
      page,
      orderBy: 'date',
      byDescending: true,
      ...params
    });
    setQueryParams({ page });
    this.setState({ loading: false, data, totalCount, page });
  };

  onDelete = async (id: number) => {
    const { page } = this.state;
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await businessDayApi.deleteBusinessDay(id);
      await this.fetchBusinessDays(page);
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns = [
    {
      title: 'Год',
      dataIndex: 'date',
      render: (date: string | moment) => formatDateTimeToString(date, 'YYYY'),
      key: 'year'
    },
    {
      title: 'Месяц',
      dataIndex: 'date',
      render: (date: string | moment) => formatDateTimeToString(date, 'M'),
      key: 'month'
    },
    {
      title: 'День',
      dataIndex: 'date',
      render: (date: string | moment) => formatDateTimeToString(date, 'D'),
      key: 'day'
    },
    {
      title: 'Тип',
      dataIndex: 'dayType',
      sorter: true,
      render: (dayType: BusinessDayType) => businessDayTypes[dayType]
    },
    {
      title: 'Сумма часов',
      dataIndex: 'workHours'
    },
    {
      title: '',
      width: 30,
      render: (record: BusinessDay) =>
        isEmpty(record) ? null : (
          <Operations>
            <Popconfirm
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => this.onDelete(parseInt(record.id))}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        )
    }
  ];

  handleRowClick = (id: number) => navigate(`/admin/business-calendar/${id}`);

  async componentDidMount() {
    const { page } = qs.parse(window.location.search);
    await this.fetchBusinessDays(page);
  }

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading } = this.state;
    return (
      <>
        <Header
          left={<h1>Производственный календарь</h1>}
          right={
            <>
              <Link to={`/admin/business-calendar/new`}>
                <Button type="primary">Создать</Button>
              </Link>
              <StyledUpload
                accept=".xlsx"
                action={`${apiUrl}/businessday/import`}
                headers={{ Authorization: `Bearer ${tokenManager.getToken()}` }}
                showUploadList={false}
                beforeUpload={() => {
                  notificationLoading({
                    message: 'Отправка файла',
                    key: 'sending'
                  });
                }}
                onChange={({ file }) => {
                  const { status, response } = file;
                  switch (status) {
                    case 'done':
                      notification.success({
                        message: 'Файл отправлен',
                        description: response
                      });
                      break;
                    case 'error':
                      notification.error({
                        message: 'Ошибка',
                        description: response.title
                      });
                      break;
                    default:
                      break;
                  }
                  notification.close('sending');
                }}
              >
                <Button type="primary">Загрузить</Button>
              </StyledUpload>
            </>
          }
        />
        <Section>
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            columns={this.columns}
            fetch={this.fetchBusinessDays}
            loading={loading}
            data={data.map(item => ({ ...item, key: item.id }))}
            pagination={{
              page,
              totalCount,
              pageSize,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default BusinessCalendar;
