// @flow
import React, {useCallback, useState} from 'react';

import {inspectionGibddCostApi} from '../../lib/api';
import type {InspectionGibddCost, UserAccess} from '../../lib/types';
import {formatDateTimeToString, navigate} from '../../lib/helpers';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import Grid, {GridItem} from '../../components/layout/Grid';
import {Card} from '../../components';
import {withUserAccess} from './../withUserAccess';
import {addAccessRight} from './accessRight';
import {approveTypes} from '../../lib/enum';
import {getBreadCrumbsByUrlForCard} from '../../lib/autoBreadcrumbs';

const {Field} = Card;

type InnerProps = {
  data: InspectionGibddCost
};

type PageProps = {
  id: string,
  userAccess: UserAccess[]
};

const entryPointPath = '/admin/inspection-gibdd-cost/';

const InnerForm = (props: InnerProps) => {
  const isTruckCategory = category =>
    ['N2', 'N3', 'M1', 'M2', 'M3'].includes(category.name);
  return (
    <>
      <Grid gutter="16px">
        <GridItem>
          <Field label="Подразделение">{props.data.orgUnitName}</Field>
        </GridItem>
        <GridItem>
          <Field label="Стоимость за один осмотр (работа)">
            {props.data.cost}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Категория транспортного средства">
            {props.data.category?.name}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Подкатегория транспортного средства">
            {props.data.subCategory?.name}
          </Field>
        </GridItem>
        {!!props.data.category &&
          (isTruckCategory(props.data.category) ? (
            <>
              <GridItem>
                <Field
                  label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло до 5 лет (мес).">
                  {props.data.interval_0_5 ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field
                  label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло более 5 лет (мес).">
                  {props.data.interval_5_infinity ?? '-'}
                </Field>
              </GridItem>
            </>
          ) : (
            <>
              <GridItem>
                <Field
                  label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло до 4 лет (мес).">
                  {props.data.interval_0_4 ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field
                  label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло от 4 до 10 лет (мес).">
                  {props.data.interval_4_10 ?? '-'}
                </Field>
              </GridItem>
              <GridItem>
                <Field
                  label="Периодичность прохождения ТО в ГИБДД для Типа ТС, с года изготовления которых прошло более 10 лет (мес).">
                  {props.data.interval_10_infinity ?? '-'}
                </Field>
              </GridItem>
            </>
          ))}
        <GridItem>
          <Field label="Статус">{approveTypes[props.data.status]}</Field>
        </GridItem>
        <GridItem>
          <Field label="Дата начала">
            {formatDateTimeToString(props.data.startDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
        <GridItem>
          <Field label="Дата окончания">
            {formatDateTimeToString(props.data.endDate, 'DD.MM.YYYY')}
          </Field>
        </GridItem>
      </Grid>
    </>
  );
};

export default withUserAccess((props: PageProps) => {
  const [data: InspectionGibddCost, setData] = useState({
    id: props.id,
  });

  const breadCrumbs = getBreadCrumbsByUrlForCard(entryPointPath, data.id);

  const canEdit = props.userAccess.some(access =>
    addAccessRight.includes(access),
  );

  const access = {
    delete: canEdit
      ? async () => {
        await inspectionGibddCostApi.delete(data.id);
        await navigate(`${entryPointPath}`, true);
      }
      : false,
    edit: canEdit
      ? async () => await navigate(`${entryPointPath}edit/${data.id}`)
      : false,
  };

  const onFetch = useCallback(async () => {
    setData(await inspectionGibddCostApi.get(props.id));
  }, [props.id]);

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Стоимость ТО ГИБДД',
        access,
      }}
      onFetch={onFetch}
    >
      <InnerForm data={data} />
    </CommonCardPage>
  );
});
