// @flow

import React, {Component} from 'react';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';

import type {ListState, VehicleTaxPlan, VehicleTaxPlanVehicle} from '../../../lib/types';
import {vehicleTaxCalculationApi, vehicleTaxPlanApi} from '../../../lib/api';
import {formatDate, getListInitialState, getPathWithHistoryParams} from '../../../lib/helpers';

import {ButtonsRow} from '../../../components/ui';
import {Header, Section, TopPanel} from '../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';

import type {VehicleListFilterParams} from '../vehicle/components/FilterVehicleList';
import Filter from './../vehicle/components/FilterVehicleList';

import InnerTable from './components/InnerTableCard';

import {notificationLoading} from '../../../components/Notifications';

type Props = {
  location: Location & { state: { page: number } },
  vehicleTaxPlanId: number
};
type State = ListState<VehicleTaxPlanVehicle> & {
  filter: VehicleListFilterParams,
  vehicleTaxPlan: ?VehicleTaxPlan
};

export default class extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {},
    vehicleTaxPlan: null
  };

  componentDidMount() {
    this.getData();
  }

  filteredData = (data: any, filter: VehicleListFilterParams) =>
    data
      .filter(selfVehicle =>
        filter.vehicleId
          ? filter.vehicleId === selfVehicle?.vehicleTaxPlanVehicle.vehicleId
          : true
      )
      .filter(selfVehicle =>
        filter.type
          ? filter.type ===
            selfVehicle?.vehicleTaxPlanVehicle.vehicle.vehicleModel.type
          : true
      )
      .filter(selfVehicle =>
        filter.vehicleModelId
          ? filter.vehicleModelId ===
            selfVehicle?.vehicleTaxPlanVehicle.vehicle.vehicleModelId
          : true
      )
      .filter(selfVehicle =>
        filter.yearIssued
          ? filter.yearIssued ===
            selfVehicle?.vehicleTaxPlanVehicle.vehicle.yearIssued
          : true
      )
      .filter(selfVehicle => {
        return filter.hideEmpty === true ? selfVehicle.tax !== 0 : true;
      });

  getData = async () => {
    this.setState({ loading: true });
    try {
      await this.getVehicleTaxPlan();
      await this.fetch();
    } finally {
      this.setState({ loading: false });
    }
  };

  getVehicleTaxPlan = async () => {
    const { vehicleTaxPlanId } = this.props;
    try {
      const vehicleTaxPlan = await vehicleTaxPlanApi.get(vehicleTaxPlanId);
      this.setState({ vehicleTaxPlan });
    } catch (error) {
      notification.warning({ message: 'Не удалось запросить данные' });
    }
  };

  fetch = async () => {
    const { vehicleTaxPlanId } = this.props;
    try {
      let { data } = await vehicleTaxCalculationApi.fetch({
        vehicleTaxPlanId,
        page: undefined,
        pageSize: undefined
      });
      if (!data) {
        notification.warning({ message: 'Не удалось запросить данные' });
        return;
      }
      this.setState({ data });
    } catch (e) {
      notification.error({ message: 'Не удалось запросить данные' });
    }
  };

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing'
      });
      await vehicleTaxPlanApi.print(this.props.vehicleTaxPlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  applyFilter = (filter: VehicleListFilterParams) => this.setState({ filter });

  cleanFilter = () => this.setState({ filter: {} });

  render() {
    const { data, loading, filter, vehicleTaxPlan } = this.state;
    const filtered = this.filteredData(data, filter);
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/budget/tax')}>Налоги</Crumb>
              <Crumb>Транспортный налог</Crumb>
            </Breadcrumbs>
          }
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать</Button>
            </ButtonsRow>
          }
        />
        <TopPanel>
          <h1>
            Расчет транспортного налога{' '}
            {vehicleTaxPlan
              ? `для ${vehicleTaxPlan.orgUnitName} c ${formatDate(
                  vehicleTaxPlan.startDate
                )} по ${formatDate(vehicleTaxPlan.endDate)}`
              : ''}
          </h1>
        </TopPanel>
        <Section>
          <div style={{ padding: '16px 16px 0px' }}>
            <Filter
              vehicleType={true}
              filter={filter}
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
            />
          </div>
          <InnerTable data={filtered} loading={loading} />
        </Section>
      </>
    );
  }
}
