// @flow

import React from 'react';
import styled from 'styled-components';

import { vehicleStatuses } from '../lib/enum';
import type { VehicleStatus as VehicleStatusType } from '../lib/types';

const Circle = styled.span`
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-right: 5px;
  ${props => `background-color: ${statusColors[props.status]}`};
`;

const statusColors = {
  working: '#8DE04B',
  limitExceed: '#EE271A',
  onRepair: '#F7BA1B',
  isArchive: '#1AE2EE',
  draft: '#C0CCD7',
  onAgreeing: '#cc27bf',
  sendingToParus: '#cc27bf',
  busy: '#EE271A',
  blocked: '#1a2cee',
};

const Wrapper = styled.p`
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  vertical-align: middle;
  margin-bottom: 0;
  ${props => props.styles};
`;

type Props = {
  status: VehicleStatusType,
  className?: string,
  showLabel?: boolean
};

export default function VehicleStatus({
  status,
  className,
  showLabel = true
}: Props) {
  return (
    <Wrapper className={className} title={vehicleStatuses[status]}>
      <Circle {...{ status }} /> {showLabel && vehicleStatuses[status]}
    </Wrapper>
  );
}
