// @flow
import React, {Component} from 'react';

import type {FormikProps} from 'formik';
import styled from 'styled-components';
import moment from 'moment';

import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import DatePicker from 'antd/lib/date-picker';

import {Footer, Header, Panel, Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {Form} from '../../../components';
import type {FormFieldType} from './../../../components/Form';
import {notificationLoading} from '../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';

import type {OsagoKprMultiplier} from './../../../lib/types';
import {
  disabledDateMin,
  formatDateTimeToISOString,
  getPathWithHistoryParams,
  getValueObject,
  navigate,
} from './../../../lib/helpers';
import {osagoMultiplier} from '../../../lib/api';

type Props = {
  location: any,
  osagoKprMultiplierId: ?string
};
type State = {
  osagoKprMultiplier: ?$Shape<OsagoKprMultiplier>
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const Required = styled.span`
  color: red;
  position: relative;
  top: -3px;
  right: -2px;
  font-size: 12px;
`;

export default class OsagoKprMultiplierForm extends Component<Props, State> {
  state = {
    osagoKprMultiplier: null
  };

  onSubmit = async (values: OsagoKprMultiplier) => {
    const { osagoKprMultiplierId } = this.props;
    try {
      notificationLoading({
        message: 'Сохранение...',
        key: 'saving'
      });
      if (osagoKprMultiplierId) {
        await osagoMultiplier.updateOsagoKprMultiplier(values);
      } else {
        await osagoMultiplier.addOsagoKprMultiplier(values);
      }
      navigate('/admin/osago-multiplier/kpr', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  async componentDidMount() {
    let osagoKprMultiplierId = parseInt(this.props.osagoKprMultiplierId, 10);
    try {
      if (osagoKprMultiplierId) {
        try {
          const osagoKprMultiplier = await osagoMultiplier.getOsagoKprMultiplier(
            osagoKprMultiplierId
          );
          this.setState({ osagoKprMultiplier });
        } catch (error) {
          notification.error({
            message: 'Ошибка',
            description: error.message
          });
        }
      } else {
        let osagoKprMultiplier = {};
        osagoKprMultiplier.startDate = getValueObject(
          this.props,
          'location.state.startDate'
        );
        this.setState({ osagoKprMultiplier });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onCancel = () => navigate('/admin/osago-multiplier/kpr', true);

  render() {
    const { osagoKprMultiplierId } = this.props;
    const { osagoKprMultiplier } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/admin/osago-multiplier/kpr')}
              >
                КПР коэффициенты ОСАГО
              </Crumb>
              {osagoKprMultiplierId ? (
                <Crumb
                  to={`/admin/osago-multiplier/kpr/edit/${osagoKprMultiplierId}`}
                >
                  КПР коэффициенты ОСАГО №{osagoKprMultiplierId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/osago-multiplier/kpr/new`}>
                  Новые КПР коэффициенты ОСАГО
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {osagoKprMultiplierId
              ? `Редактируем запись №${osagoKprMultiplierId}`
              : `Новая запись`}
          </h1>
        </StyledPanel>
        <Form initialValues={osagoKprMultiplier} onSubmit={this.onSubmit}>
          {(FormField: FormFieldType, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              setFieldValue,
              dirty,
              isSubmitting,
              values
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <Section>
                  <SectionContent>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Прицепы к легковым автомобилям, принадлежащим
                        юридическим лицам, к мотоциклам и мотороллерам
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="trailerCar"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Прицепы к грузовым автомобилям с разрешенной
                        максимальной массой 16 тонн и менее, полуприцепы,
                        прицепы-роспуски
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="trailerTruckMax16"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Прицепы к грузовым автомобилям с разрешенной
                        максимальной массой более 16 тонн, полуприцепы,
                        прицепы-роспуски
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="trailerTruckMin16"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Прицепы к тракторам, самоходным дорожно-строительным и
                        иным машинам, за исключением транспортных средств, не
                        имеющих колесных движителей
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="trailerTractor"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid cols={2}>
                      <GridItem span={1}>
                        Прицепы к другим типам (категориям) и назначению ТС
                        <Required>*</Required>
                      </GridItem>
                      <GridItem span={1}>
                        <FormField
                          required
                          hasFeedback={false}
                          name="trailerOther"
                          fast
                        >
                          {({ name, value }) => (
                            <InputNumber
                              name={name}
                              min={0.01}
                              value={value}
                              onChange={(count: number) =>
                                setFieldValue(name, count)
                              }
                              onBlur={handleBlur}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid>
                      <GridItem>
                        Дата начала
                        <Required>*</Required>
                      </GridItem>
                      <GridItem>
                        <FormField
                          required
                          hasFeedback={false}
                          name="startDate"
                          fast
                          style={{ width: '140px' }}
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                                setFieldValue('endDate', undefined);
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                    <Grid>
                      <GridItem>Дата окончания</GridItem>
                      <GridItem>
                        <FormField
                          style={{ width: '140px' }}
                          hasFeedback={false}
                          name="endDate"
                        >
                          {({ name, value }) => (
                            <DatePicker
                              format="DD.MM.YYYY"
                              value={value ? moment.utc(value) : value}
                              disabled={values.startDate ? false : true}
                              disabledDate={(currentDate: moment) =>
                                disabledDateMin(
                                  currentDate,
                                  moment(values.startDate)
                                )
                              }
                              onChange={(value: Object, dateString: string) => {
                                setFieldValue(
                                  name,
                                  formatDateTimeToISOString(value, dateString)
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  </SectionContent>
                </Section>
                <Footer>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                    data-cy="save"
                  >
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.onCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}
