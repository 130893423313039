// @flow

import AntIcon from 'antd/lib/icon';
import moment from 'moment';
import React, { Component } from 'react';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';

import Table from 'antd/lib/table';
import { Card } from '../../../../components';
import { Section } from '../../../../components/layout';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { formatLicensePlateMask } from '../../../../components/masked-inputs/LicensePlateInput';
import { formatPTSMask } from '../../../../components/masked-inputs/PTSInput';
import { formatSTSMask } from '../../../../components/masked-inputs/STSInput';
import { Icon, Slider, Spoiler } from '../../../../components/ui';
import {
  STSTypes,
  driverQualifications,
  drivingLicenseCategories,
  engineTypes,
  fileTagEnum,
  inspectionTypesEnum,
  leasers,
  tractorDrivingLicenseCategories,
  vehicleInventoryStatuses
} from '../../../../lib/enum';
import { vehicleWorkMeterTypes, vehicleWorkMeterTypesEnum } from '../../../../lib/enum/vehicleTypes';
import {
  applyMaskToValue,
  convertEmployeeToString,
  formatDateTimeToString,
  isVehicleTypeElectric,
  navigate,
  toLocalStringRu
} from '../../../../lib/helpers';
import type { Vehicle } from '../../../../lib/types';
import { canShowGpmData } from '../../../VehicleModels/lib';
import { canShowDriverData, canShowWithTrailer, prepareSliderItem } from '../../lib';
import DriverInfoPopover from '../DriverInfoPopover';
import DriverWarningIndicator from '../DriverWarningIndicator';
import SectionTitle from '../SectionTitle';
import PassList from './../../containers/PassList';

const {Field} = Card;
const SectionContent = styled.div`
  padding: 16px;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Placeholder = styled.div`
  text-align: center;
  padding: 16px;
  color: #838d96;
`;
const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
`;

const formatDate = date => moment.utc(date).format('DD.MM.YYYY');

type Props = {
  vehicle: Vehicle,
  editable: boolean,
  anchor: ?string,
  canHandleMaintenanceServices: boolean
};

const licenseCategories = {
  ...drivingLicenseCategories,
  ...tractorDrivingLicenseCategories,
};

const isAccountantEmpty = function(vehicle: Vehicle) {
  const {
    initialCost,
    residualValue,
    residualValueUpdateDate,
    accountingRegistrationDate,
    accountingDeregistrationDate,
    restoreValue,
  } = vehicle;
  return (
    initialCost === null &&
    residualValue === null &&
    residualValueUpdateDate === null &&
    restoreValue === null &&
    accountingRegistrationDate === null &&
    accountingDeregistrationDate === null
  );
};

export default class MainTab extends Component<Props> {
  componentDidMount() {
    if (this.props.anchor) {
      scroller.scrollTo(this.props.anchor, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
      });
    }
  }

  render() {
    const {
      vehicle,
      editable,
      canHandleMaintenanceServices,
    }: Props = this.props;
    const isGpm = canShowGpmData(vehicle?.vehicleModel?.type);
    const isElectricVehicle = isVehicleTypeElectric(vehicle?.vehicleModel?.type);

    return (
      <>
        <Element name="files/album">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/files/album`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Изображения
            </SectionTitle>
            {vehicle && (
              <SectionContent>
                {vehicle.files &&
                  (vehicle.files.length === 0 ? (
                    <Placeholder>Изображения отсутствуют</Placeholder>
                  ) : (
                    <Slider
                      items={vehicle.files
                      .filter(v => v.tag === fileTagEnum.album)
                      .map(prepareSliderItem)}
                    />
                  ))}
              </SectionContent>
            )}
          </Section>
        </Element>
        <Element name="numbers">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/numbers`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Номера
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px" media={[{size: 'lg', cols: 3}]}>
                {vehicle.vin && (
                  <GridItem>
                    <Field label="VIN номер">{vehicle.vin}</Field>
                  </GridItem>
                )}
                {vehicle.serialNumber && (
                  <GridItem>
                    <Field label="Серийный номер">{vehicle.serialNumber}</Field>
                  </GridItem>
                )}
                {vehicle.licensePlate && (
                  <GridItem>
                    <Field label="Гос. номер">
                      {applyMaskToValue(
                        vehicle.licensePlate,
                        formatLicensePlateMask,
                      )}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Инвентарный номер">
                    {vehicle.inventoryNumber}
                  </Field>
                </GridItem>
                <GridItem fullWidth />
                <GridItem>
                  <Field label="Серия номер ПТС">
                    {applyMaskToValue(vehicle.pts, formatPTSMask)}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Дата выдачи ПТС">
                    {formatDate(vehicle.ptsDate)}
                  </Field>
                </GridItem>
                {vehicle.stsType && (
                  <GridItem>
                    <Field label={vehicle.isLease ? "Тип АТС" : "Тип СТС"}>{STSTypes[vehicle.stsType]}</Field>
                  </GridItem>
                )}
                {vehicle.sts && (
                  <GridItem>
                    <Field label={vehicle.isLease ? "Серия номер АТС" : "Серия номер СТС"}>
                      {applyMaskToValue(vehicle.sts, formatSTSMask)}
                    </Field>
                  </GridItem>
                )}
                {vehicle.stsDate && (
                  <GridItem>
                    <Field label={vehicle.isLease ? "Дата выдачи АТС" : "Дата выдачи СТС"}>
                      {formatDate(vehicle.stsDate)}
                    </Field>
                  </GridItem>
                )}
                {!!vehicle.deviceId && (
                  <GridItem>
                    <Field label="ГЛОНАСС/GPS-трекер">{vehicle.deviceId}</Field>
                  </GridItem>
                )}
                {!!vehicle.advantumId && (
                  <GridItem>
                    <Field label="Идентификатор ТС в Адвантум">
                      {vehicle.advantumId}
                    </Field>
                  </GridItem>
                )}
                {vehicle.configFileName && (
                  <GridItem>
                    <Field label="Имя конфигурационного файла для АвтоГРАФ">
                      {vehicle.configFileName}
                    </Field>
                  </GridItem>
                )}
                {vehicle.garageNumber && (
                  <GridItem>
                    <Field label="Гаражный номер">{vehicle.garageNumber}</Field>
                  </GridItem>
                )}
                {vehicle.deregistrationDate && (
                  <GridItem>
                    <Field label="Дата снятия с учета в ГИБДД">
                      {formatDate(vehicle.deregistrationDate)}
                    </Field>
                  </GridItem>
                )}
                {canShowWithTrailer(
                  vehicle.vehicleModel && vehicle.vehicleModel.type,
                ) && (
                  <GridItem>
                    <Field label="ТС с прицепом">
                      {vehicle.withTrailer ? 'Да' : 'Нет'}
                    </Field>
                  </GridItem>
                )}
                {vehicle.driverQualification && (
                  <GridItem>
                    <Field label="Необходимая квалификация водителя">
                      {vehicle.driverQualification}
                    </Field>
                  </GridItem>
                )}
                {vehicle.mnemonicStou && (
                  <GridItem>
                    <Field label="Мнемокод ТС по СТОУ">
                      {vehicle.mnemonicStou}
                    </Field>
                  </GridItem>
                )}
                {vehicle.typeStou && (
                  <GridItem>
                    <Field label="Тип ТС по СТОУ">{vehicle.typeStou}</Field>
                  </GridItem>
                )}
                {vehicle.vehicleModel && (
                  <>
                    {vehicle.vehicleModel.mnemonicMdm && (
                      <GridItem>
                        <Field label="Мнемокод ТС по МТР">
                          {vehicle.vehicleModel.mnemonicMdm}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.vehicleModel.typeMdm && (
                      <GridItem>
                        <Field label="Тип ТС 1С.МДМ">
                          {vehicle.vehicleModel.typeMdm}
                        </Field>
                      </GridItem>
                    )}
                    {!!vehicle.vehicleModel.rightsCategory &&
                      !!vehicle.vehicleModel.rightsCategory.length && (
                        <GridItem>
                          <Field label="Категория прав">
                            <ul>
                              {vehicle.vehicleModel.rightsCategory.map(
                                (category, key) => (
                                  <li key={key}>
                                    {licenseCategories[category]}
                                  </li>
                                ),
                              )}
                            </ul>
                          </Field>
                        </GridItem>
                      )}
                    <GridItem>
                      <Field label="Подлежит ОСАГО">
                        {vehicle.vehicleModel.osagoNeeded ? 'Да' : 'Нет'}
                      </Field>
                    </GridItem>
                    {vehicle.vehicleModel.osagoNeeded && (
                      <GridItem>
                        <Field label="Категория ТС для ОСАГО">
                          {licenseCategories[vehicle.osagoCategory]}
                        </Field>
                      </GridItem>
                    )}
                  </>
                )}
                <GridItem>
                  <Field label="Является арендованным ТС">
                    {vehicle.isLease ? 'Да' : 'Нет'}
                  </Field>
                </GridItem>
                {vehicle.isLease && (
                  <GridItem>
                    <Field label="Арендодатель">
                      {leasers[vehicle.leaser]}
                    </Field>
                  </GridItem>
                )}
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="primary">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/primary`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Первичные данные
            </SectionTitle>
            <SectionContent>
              {vehicle &&
              vehicle.vehicleModel &&
              (vehicle.vehicleModel.unladenMass ||
                vehicle.vehicleModel.grossWeight ||
                vehicle.vehicleModel.axlesNumber ||
                vehicle.vehicleModel.frontAxleLoad ||
                vehicle.vehicleModel.backAxleLoad ||
                vehicle.vehicleModel.dimensions ||
                vehicle.vehicleModel.maximumAuthorizedMass ||
                vehicle.chassisNumber ||
                vehicle.vehicleModel.enginePower ||
                vehicle.bodyNumber ||
                vehicle.vehicleModel.passengerSeatsNumber ||
                vehicle.cabColor ||
                vehicle.vehicleModel.carrying ||
                vehicle.engineNumber ||
                vehicle.vehicleModel.engineType ||
                vehicle.vehicleModel.engineCapacity ||
                vehicle.commissioningDate ||
                vehicle.decommissioningDate ||
                vehicle.descommissionReason ||
                vehicle.class ||
                vehicle.vehicleModel.ecologicClass ||
                vehicle.basicModel ||
                vehicle.vehicleModel.category) ? (
                <Grid gutter="16px">
                  {!!vehicle.chassisNumber && (
                    <GridItem>
                      <Field label="Шасси (рама)">
                        {vehicle.chassisNumber}
                      </Field>
                    </GridItem>
                  )}

                  {!!vehicle.bodyNumber && (
                    <GridItem>
                      <Field label="Кузов (прицеп)">{vehicle.bodyNumber}</Field>
                    </GridItem>
                  )}
                  {!!vehicle.cabColor && (
                    <GridItem>
                      <Field label="Цвет кузова кабины">
                        {vehicle.cabColor}
                      </Field>
                    </GridItem>
                  )}
                  {vehicle.vehicleModel && (
                    <>
                      {!!vehicle.vehicleModel.grossWeight && (
                        <GridItem>
                          <Field label="Полная масса ТС">
                            {vehicle.vehicleModel.grossWeight} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.unladenMass && (
                        <GridItem>
                          <Field label="Масса ТС без нагрузки">
                            {vehicle.vehicleModel.unladenMass} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.axlesNumber && (
                        <GridItem>
                          <Field label="Количество осей">
                            {vehicle.vehicleModel.axlesNumber}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.frontAxleLoad && (
                        <GridItem>
                          <Field label="Нагрузка на переднюю ось, тонн">
                            {vehicle.vehicleModel.frontAxleLoad} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.backAxleLoad && (
                        <GridItem>
                          <Field label="Нагрузка на заднюю ось, тонн">
                            {vehicle.vehicleModel.backAxleLoad} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.passengerSeatsNumber && (
                        <GridItem>
                          <Field label="Кол-во пассажирских мест">
                            {vehicle.vehicleModel.passengerSeatsNumber}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.carrying && (
                        <GridItem>
                          <Field label="Грузоподъемность">
                            {vehicle.vehicleModel.carrying} т
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.dimensions && (
                        <GridItem>
                          <Field label="Габариты ТС">
                            {vehicle.vehicleModel.dimensions} м
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.maximumAuthorizedMass && (
                        <GridItem>
                          <Field label="Разрешенная максимальная масса по ПТС">
                            {vehicle.vehicleModel.maximumAuthorizedMass} кг
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.engineType && (
                        <GridItem>
                          <Field label="Тип двигателя">
                            {engineTypes[vehicle.vehicleModel.engineType]}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.engineCapacity && (
                        <GridItem>
                          <Field label={isElectricVehicle ? "Емкость батареи" : "Рабочий объем двигателя"}>
                            {vehicle.vehicleModel.engineCapacity} {isElectricVehicle ? "кВт·ч" : "куб. см"}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.enginePower && (
                        <GridItem>
                          <Field label="Мощность двигателя">
                            {vehicle.vehicleModel.enginePower} л.с.
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.maxSpeed && (
                        <GridItem>
                          <Field label="Максимальная скорость">
                            {vehicle.vehicleModel.maxSpeed} км/ч
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.ecologicClass && (
                        <GridItem>
                          <Field label="Экологический класс ТС">
                            {vehicle.vehicleModel.ecologicClass}
                          </Field>
                        </GridItem>
                      )}
                      {!!vehicle.vehicleModel.category && (
                        <GridItem>
                          <Field label="Категория ТС">
                            {vehicle.vehicleModel.category.name}
                          </Field>
                        </GridItem>
                      )}
                    </>
                  )}
                  {!!vehicle.engineNumber && (
                    <GridItem>
                      <Field label="Двигатель №">{vehicle.engineNumber}</Field>
                    </GridItem>
                  )}

                  {!!vehicle.commissioningDate && (
                    <GridItem>
                      <Field label="Дата принятия ТС в эксплуатацию">
                        {formatDateTimeToString(
                          vehicle.commissioningDate,
                          'DD.MM.YYYY',
                        )}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.decommissioningDate && (
                    <GridItem>
                      <Field label="Дата списания ТС из эксплуатации">
                        {formatDateTimeToString(
                          vehicle.decommissioningDate,
                          'DD.MM.YYYY',
                        )}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.descommissionReason && (
                    <GridItem>
                      <Field label="Причина списания">
                        {vehicle.descommissionReason}
                      </Field>
                    </GridItem>
                  )}
                  {!!vehicle.class && (
                    <GridItem>
                      <Field label="Класс ТС">{vehicle.class}</Field>
                    </GridItem>
                  )}
                  {!!vehicle.basicModel && (
                    <GridItem>
                      <Field label="Базовая модель">{vehicle.basicModel}</Field>
                    </GridItem>
                  )}
                </Grid>
              ) : (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            </SectionContent>
          </Section>
        </Element>
        {isGpm && (
          <Element name="gpm">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable && (
                    <StyledIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/edit/gpm`)
                      }
                      type="edit"
                      size={16}
                    />
                  )
                }
              >
                ГПМ
              </SectionTitle>
              {vehicle.gpmType ||
              vehicle.gpmModel ||
              vehicle.gpmRegistrationNumber ||
              vehicle.gpmFactoryNumber ||
              vehicle.vehicleModel.gpmCarrying ||
              vehicle.vehicleModel.liftHeight ||
              vehicle.vehicleModel.gpmHandlingRadius ||
              vehicle.lastExpertiseDate ||
              vehicle.lastSafetyDeviceMaintenanceDate ||
              vehicle.lastExpertisePlannedDate ||
              vehicle.lastSafetyDeviceMaintenancePlannedDate ? (
                <SectionContent>
                  <Grid gutter="16px">
                    {vehicle.gpmType && (
                      <GridItem>
                        <Field label="Тип">{vehicle.gpmType}</Field>
                      </GridItem>
                    )}
                    {vehicle.gpmModel && (
                      <GridItem>
                        <Field label="Модель">{vehicle.gpmModel}</Field>
                      </GridItem>
                    )}
                    {vehicle.gpmRegistrationNumber && (
                      <GridItem>
                        <Field label="Регистрационный номер">
                          {vehicle.gpmRegistrationNumber}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.gpmFactoryNumber && (
                      <GridItem>
                        <Field label="Заводской номер">
                          {vehicle.gpmFactoryNumber}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.vehicleModel && (
                      <>
                        {parseFloat(vehicle.vehicleModel.gpmCarrying) > 0 && (
                          <GridItem>
                            <Field label="Грузоподъемность">
                              {vehicle.vehicleModel.gpmCarrying}
                            </Field>
                          </GridItem>
                        )}
                        {parseFloat(vehicle.vehicleModel.liftHeight) > 0 && (
                          <GridItem>
                            <Field label="Высота подъема">
                              {vehicle.vehicleModel.liftHeight}
                            </Field>
                          </GridItem>
                        )}
                        {parseFloat(vehicle.vehicleModel.gpmHandlingRadius) >
                          0 && (
                            <GridItem>
                              <Field label="Вылет стрелы">
                                {vehicle.vehicleModel.gpmHandlingRadius}
                              </Field>
                            </GridItem>
                          )}
                      </>
                    )}
                    {vehicle.lastExpertiseDate && (
                      <GridItem>
                        <Field label="Дата последней экспертизы">
                          {formatDate(vehicle.lastExpertiseDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastSafetyDeviceMaintenanceDate && (
                      <GridItem>
                        <Field label="Дата последнего ТО приборов безопасности">
                          {formatDate(vehicle.lastSafetyDeviceMaintenanceDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastExpertisePlannedDate && (
                      <GridItem>
                        <Field label="Планируемая дата экспертизы">
                          {formatDate(vehicle.lastExpertisePlannedDate)}
                        </Field>
                      </GridItem>
                    )}
                    {vehicle.lastSafetyDeviceMaintenancePlannedDate && (
                      <GridItem>
                        <Field label="Планируемая дата ТО приборов безопасности">
                          {formatDate(
                            vehicle.lastSafetyDeviceMaintenancePlannedDate,
                          )}
                        </Field>
                      </GridItem>
                    )}
                  </Grid>
                </SectionContent>
              ) : (
                <Placeholder>Данные не заполнены</Placeholder>
              )}
            </Section>
          </Element>
        )}
        {canShowDriverData(
          vehicle.vehicleModel && vehicle.vehicleModel.type,
        ) && (
          <Element name="driver">
            <Section>
              <SectionTitle
                divider
                suffix={
                  editable && (
                    <StyledIcon
                      onClick={() =>
                        navigate(`/vehicles/${vehicle.id}/edit/driver`)
                      }
                      type="edit"
                      size={16}
                    />
                  )
                }
              >
                Водитель
              </SectionTitle>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <Field label="Закрепленный водитель">
                      {convertEmployeeToString(
                        vehicle.driver && vehicle.driver.employee,
                        'Водитель не назначен',
                      )}
                      <DriverWarningIndicator
                        driver={vehicle.driver}
                        vehicle={vehicle}
                      />
                      <DriverInfoPopover
                        driver={vehicle.driver}
                        vehicle={vehicle}
                      />
                    </Field>
                  </GridItem>
                  {vehicle.driver && (
                    <>
                      {vehicle.driver.qualificationDocuments &&
                        vehicle.driver.qualificationDocuments.map(
                          (document, index) => (
                            <GridItem key={index}>
                              <Field
                                label={
                                  driverQualifications[document.qualification]
                                }
                              >
                                {document.documentNumber}
                              </Field>
                            </GridItem>
                          ),
                        )}
                    </>
                  )}
                </Grid>
              </SectionContent>
            </Section>
          </Element>
        )}
        <Element name="attachment">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/attachment`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Прикрепление
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Филиал владелец">
                    {vehicle.ownerOrgUnitName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Мнемокод филиала закрепления">
                    {vehicle.ownerOrgUnitMnemonic}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Служба закрепления/РЭС">
                    {vehicle.orgUnitName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Служба использования/РЭС">
                    {vehicle.usingNodeName}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Мнемокод службы закрепления">
                    {vehicle.orgUnitMnemonic}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Населенный пункт базирования ТС">
                    {vehicle.location
                      ? `${vehicle.location.name} ${vehicle.location.address}`
                      : '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Место нахождения ТС">
                    {vehicle.parusRegistrationPlace?.fullName ?? '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Место регистрации ТС">
                    {vehicle.registrationPlace?.name ?? '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Материально ответственное лицо">
                    {vehicle.financiallyResponsibleEmployee ? (
                      <>
                        {vehicle.financiallyResponsibleEmployee.lastname}
                        &nbsp;
                        {vehicle.financiallyResponsibleEmployee.firstname}
                        &nbsp;
                        {vehicle.financiallyResponsibleEmployee.middlename}
                        {vehicle.financiallyResponsibleEmployee.positionTitle &&
                          `, ${vehicle.financiallyResponsibleEmployee.positionTitle}`}
                      </>
                    ) : (
                      '-'
                    )}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Табельный номер материально ответственного лица">
                    {vehicle.financiallyResponsibleEmployee
                      ? vehicle.financiallyResponsibleEmployee.personNumber
                      : '-'
                    }
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Документ основание смены МОЛ">
                    {vehicle.documentChangeFinanciallyResponsibleEmployee ??
                      '-'}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="accountant">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/accountant`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Бухгалтерские данные
            </SectionTitle>
            <SectionContent>
              {isAccountantEmpty(vehicle) ? (
                <Placeholder>Данные не заполнены</Placeholder>
              ) : (
                <Grid gutter="16px">
                  {!vehicle.isLease && (
                    <>
                      <GridItem>
                        <Field label="Первоначальная стоимость">
                          {toLocalStringRu(vehicle.initialCost)}
                          &nbsp;
                          {vehicle.initialCost !== null && '₽ (Без НДС)'}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Остаточная стоимость">
                          {toLocalStringRu(vehicle.residualValue)}
                          &nbsp;
                          {vehicle.residualValue !== null && '₽ (Без НДС)'}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата обновления остаточной стоимости">
                          {formatDateTimeToString(
                            vehicle.residualValueUpdateDate,
                            'DD.MM.YYYY',
                          )}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Восстановительная стоимость">
                          {toLocalStringRu(vehicle.restoreValue)}
                          &nbsp;
                          {vehicle.restoreValue !== null && '₽ (Без НДС)'}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата принятия к бухгалтерскому учету">
                          {formatDateTimeToString(
                            vehicle.accountingRegistrationDate,
                            'DD.MM.YYYY',
                          )}
                        </Field>
                      </GridItem>
                      <GridItem>
                        <Field label="Дата списания ТС с бухгалтерского учета">
                          {formatDateTimeToString(
                            vehicle.accountingDeregistrationDate,
                            'DD.MM.YYYY',
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem>
                    <Field label="Rn инвентарной карточки">
                      {vehicle.inventoryCardParusId}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field label="Статус инвентарной карточки в ИС Парус">
                      {vehicle.inventoryStatus
                        ? vehicleInventoryStatuses[vehicle.inventoryStatus]
                        : '-'}
                    </Field>
                  </GridItem>
                </Grid>
              )}
            </SectionContent>
          </Section>
        </Element>
        {!vehicle.isLease && (
          <Element name="invoiceData">
            <Section>
              <SectionTitle divider>Данные накладной</SectionTitle>
              <SectionContent>
                {isAccountantEmpty(vehicle) ||
                (!vehicle.otherDocumentKind &&
                  !vehicle.otherDocumentIssuedDate &&
                  !vehicle.otherDocumentSerialNumber &&
                  !vehicle.nomenclatureNumber) ? (
                  <Placeholder>Данные не заполнены</Placeholder>
                ) : (
                  <Grid gutter="16px">
                    <GridItem>
                      <Field label="Префикс накладной">
                        {vehicle.otherDocumentKind}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Дата накладной">
                        {moment(vehicle.otherDocumentIssuedDate).format('DD.MM.YYYY')}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Номер накладной">
                        {vehicle.otherDocumentSerialNumber}
                      </Field>
                    </GridItem>
                    <GridItem>
                      <Field label="Мнемокод номенклатуры">
                        {vehicle.nomenclatureNumber}
                      </Field>
                    </GridItem>
                  </Grid>
                )}
              </SectionContent>
            </Section>
          </Element>
        )}
        <Element name="budget">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/budget`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Бюджет
            </SectionTitle>
            <SectionContent>
              <Grid gutter="16px">
                {!!vehicle.plannedPurchaseDate && (
                  <GridItem>
                    <Field label="Планируемая дата приобретения">
                      {formatDateTimeToString(vehicle.plannedPurchaseDate, 'DD.MM.YYYY')}
                    </Field>
                  </GridItem>
                )}
                {!!vehicle.plannedWriteoffDate && (
                  <GridItem>
                    <Field label="Планируемая дата списания">
                      {formatDateTimeToString(vehicle.plannedWriteoffDate, 'DD.MM.YYYY')}
                    </Field>
                  </GridItem>
                )}
                {isGpm && (
                  <GridItem>
                    <Field label="Исключить из затрат на ТО ПБ ГПМ">
                      {vehicle.excludeGpmMaintenanceCalculation ? 'Да' : 'Нет'}
                    </Field>
                  </GridItem>
                )}
                <GridItem>
                  <Field label="Учет регистрации ТС для бюджета">
                    {vehicle.inspectionType
                      ? inspectionTypesEnum[vehicle.inspectionType]
                      : '-'
                    }
                  </Field>
                </GridItem>
                {vehicle.vehicleSubCategoryId && (
                  <GridItem>
                    <Field label="Подкатегория ТС">
                      {vehicle.vehicleSubCategory.name}
                    </Field>
                  </GridItem>
                )}
              </Grid>
            </SectionContent>
          </Section>
        </Element>
        <Element name="pass">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable &&
                canHandleMaintenanceServices && (
                  <StyledAntIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/passes/new`)
                    }
                    type="plus"
                    size={16}
                  />
                )
              }
            >
              Пропуска
            </SectionTitle>
            <PassList
              vehicleId={vehicle.id}
              enableDelete={editable && canHandleMaintenanceServices}
            />
          </Section>
        </Element>
        <Element name="tariffs">
          <Section>
            <SectionTitle
              divider
              suffix={
                editable && (
                  <StyledIcon
                    onClick={() =>
                      navigate(`/vehicles/${vehicle.id}/edit/tariffs`)
                    }
                    type="edit"
                    size={16}
                  />
                )
              }
            >
              Тарифы
            </SectionTitle>

            <SectionContent>
              {!vehicle.costPerHourForCompanies ? (
                <Placeholder>Данные не заполнены</Placeholder>
              ) : (
                <Grid gutter="16px">
                  {parseFloat(vehicle.costPerHourForCompanies) >= 0 && (
                    <GridItem>
                      <Field label="Стоимость нормо-часа для сторонних организаций">
                        {vehicle.costPerHourForCompanies} ₽
                      </Field>
                    </GridItem>
                  )}
                </Grid>
              )}
            </SectionContent>
          </Section>
        </Element>
        <Element name="workMetersHistory">
          <Section>
            <Spoiler defaultExpanded={false} label='История приборов учета' labelStyles={{fontSize: '16px', margin: '11px 0'}}>
              {vehicle?.vehicleWorkMeters?.length > 0
                ? (
                  <Table
                    columns={[
                      {
                        title: '№ изменения',
                        dataIndex: 'id',
                        key: 'number',
                        render: (text, record, index) => index + 1,
                      },
                      {
                        title: 'Наименование',
                        dataIndex: 'type',
                        render: type => vehicleWorkMeterTypes[type],
                      },
                      {
                        title: 'Дата изменения',
                        dataIndex: 'created',
                        render: date => moment(date).utc().format('DD.MM.YYYY'),
                      },
                      {
                        title: 'ФИО пользователя',
                        dataIndex: 'employee',
                        render: employee => convertEmployeeToString(employee),
                      },
                      {
                        title: 'Дата установки нового прибора учета',
                        dataIndex: 'installDate',
                        render: date => moment(date).utc().format('DD.MM.YYYY'),
                      },
                      {
                        title: 'Показание нового прибора учета',
                        dataIndex: 'id',
                        key: 'newData',
                        render: (id, record) => record.type === vehicleWorkMeterTypesEnum.odometr
                          ? `${record.newOdometrIndications} км`
                          : `${record.newMachineHoursIndications} ч`,
                      },
                      {
                        title: 'Показание демонтируемого прибора учета',
                        dataIndex: 'id',
                        key: 'oldData',
                        render: (id, record) => record.type === vehicleWorkMeterTypesEnum.odometr
                          ? `${record.previosOdometrIndications} км`
                          : `${record.previosMachineHoursIndications} ч`,
                      },
                      {
                        title: 'Причина демонтажа',
                        dataIndex: 'demontageReason',
                      },
                    ]}
                    dataSource={vehicle.vehicleWorkMeters.sort((a, b) => a.id - b.id)}
                    pagination={false}
                    rowKey='id'
                  />
                )
                : <Placeholder>Нет данных для отображения</Placeholder>
              }
            </Spoiler>
          </Section>
        </Element>
      </>
    );
  }
}
