// @flow

import type { Journal } from './../types';

import {
  downloadRequestWithToken,
  fetchRequest,
  initialFetchListParams
} from './index';
import type { FetchListParams, ListResponse } from './index';
import type { JournalFilter } from '../../ducks/journals';

const fetchJournals = async (
  params: FetchListParams<JournalFilter> = initialFetchListParams
): Promise<ListResponse<Journal>> =>
  await fetchRequest.get('/journal', {
    ...initialFetchListParams,
    ...params
  });

export const fetchJournal = async (
  id: number,
  params: any = {}
): Promise<Journal> => {
  const journal = await fetchRequest.get(`/journal/${id}`, params);
  if (journal) return journal;
  throw new Error('Не удалось загрузить сотрудника');
};

export const printJournal = async (params: any = {}): Promise<boolean> => {
  const print = await downloadRequestWithToken(`/journal/print`, params);
  if (print) return print;
  throw new Error('Не удалось загрузить журнал');
};

export const getJournalTypes = async (params: any = {}): Promise<any> => {
  const journal = await fetchRequest.get(`/journal/getJournalTypes`, params);
  if (journal) return journal;
  throw new Error('Не удалось загрузить типы событий журнала');
};

export default {
  fetchJournals,
  fetchJournal,
  printJournal,
  getJournalTypes
};
