import React from 'react';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import type { BusinessCalendarForBudget } from '../../../../lib/types';
import { StyledInputNumber } from '../../../../components/hoc/common/components/elements';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';
import moment from 'moment';
import { DatePicker } from 'antd';

const { MonthPicker } = DatePicker;

export default (props: CommonFormProps<$Shape<BusinessCalendarForBudget>>) => {
  return (
    <CommonForm {...props}>
      {({ values, setFieldValue }) => {
        const maxDayCount =
          values.year && values.month + 1
            ? moment.utc(values).daysInMonth()
            : 0;
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Дата" required name="month" fast>
                  {() => (
                    <MonthPicker
                      value={
                        values.year && values.month + 1
                          ? moment(values)
                          : undefined
                      }
                      format={'MMMM YYYY'}
                      placeholder="Выберите дату"
                      onChange={value => {
                        setFieldValue(
                          'daysInMonth',
                          moment.utc(value).daysInMonth() || undefined
                        );
                        setFieldValue(
                          'year',
                          moment.utc(value).year() || undefined
                        );
                        setFieldValue(
                          'month',
                          moment.utc(value).month() + 1
                            ? moment.utc(value).month()
                            : undefined
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество дней в месяце"
                  name="daysInMonth"
                  fast
                >
                  {({ value, name }) => (
                    <StyledInputNumber disabled name={name} value={value} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество рабочих дней в месяце"
                  name="workingDaysInMonth"
                  fast
                >
                  {({ value, name }) => (
                    <StyledInputNumber disabled name={name} value={value} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество рабочих часов в месяце"
                  name="workingHoursInMonth"
                  fast
                >
                  {({ value, name }) => (
                    <StyledInputNumber disabled name={name} value={value} />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Количество выходных дней в месяце"
                  required
                  name="dayOffsInMonth"
                  fast
                >
                  {({ value, name }) => (
                    <StyledInputNumber
                      disabled={!maxDayCount}
                      name={name}
                      value={value}
                      max={maxDayCount}
                      min={0}
                      onChange={(value: number) => {
                        setFieldValue(name, value);
                        setFieldValue(
                          'workingDaysInMonth',
                          maxDayCount - (value || 0)
                        );
                        setFieldValue(
                          'workingHoursInMonth',
                          (maxDayCount - (value || 0)) * 8
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
