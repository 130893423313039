// @flow

import React from 'react';
import { serviceTypes } from '../../lib/enum/serviceTypes';
import { SelectProps } from 'antd/lib/select';
import Select from './../ui/Select';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const { Option } = Select;

export default ({ value, onChange, ...selectProps }: SelectProps) => {
  const { filter } = selectProps;
  const filtered =
    !isEmpty(filter) && isArray(filter.having)
      ? Object.keys(serviceTypes).filter(service =>
          filter.having.includes(service)
        )
      : Object.keys(serviceTypes);
  let val = [];
  if (isArray(value)) {
    val = (value || []).map(key => ({
      key: serviceTypes[key],
      label: serviceTypes[key]
    }));
  } else {
    val = value
      ? { key: serviceTypes[value], label: serviceTypes[value] }
      : undefined;
  }
  return (
    <Select
      placeholder="Выберите виды услуг"
      {...selectProps}
      labelInValue
      allowClear
      value={val}
      onChange={value => {
        const find = search =>
          Object.keys(serviceTypes).find(key => serviceTypes[key] === search);
        if (isArray(value)) {
          onChange(
            value.map(value => {
              const serviceType = find(value.key);
              if (serviceType) {
                return serviceType;
              }
              return value.key;
            })
          );
        } else {
          onChange(value ? find(value.key) : undefined);
        }
      }}
    >
      {filtered.map(key => (
        <Option key={key} value={serviceTypes[key]}>
          {serviceTypes[key]}
        </Option>
      ))}
    </Select>
  );
};
