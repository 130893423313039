import { accessTypeEnum } from './../../../lib/enum';
import type { UserAccess } from './../../../lib/types';

export const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.handlingSelfVehiclePlan,
  accessTypeEnum.editingBudgetCopy
];

export const approvingAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingSelfVehiclePlan,
  accessTypeEnum.approvingSelfVehiclePlanBudgetItog
];

export const approvingBudgetAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingSelfVehiclePlanBudgetItog
];

export const editBudget = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.editingBudgetCopy
];

export const approvingBudgetRepair = [
  accessTypeEnum.admin,
  accessTypeEnum.approvingSelfVehiclePlanBudgetRepair
];

// VEH-1279
// добавление/копирование/редактирование плана перечня стс
export const canAdd = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// утверждение предложения бюджета по ремонтам
export const canApprovingBudgetRepair = (userAccess: UserAccess[]) =>
  userAccess.some(access => approvingBudgetRepair.includes(access));

// редактирование вкладки кап. ремонта
export const canEditOverhaulRepair = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// отправление на утверждение перечня стс
export const canApprovementVehiclePlan = (userAccess: UserAccess[]) =>
  userAccess.some(access => addAccess.includes(access));

// утверждение плана перечня стс и формирование предложения
export const canApprovingVehiclePlan = (userAccess: UserAccess[]) =>
  userAccess.some(access => approvingAccess.includes(access));

// утверждение плана перечня стс и формирование предложения
export const canApprovingBudgetVehiclePlan = (userAccess: UserAccess[]) =>
  userAccess.some(access => approvingBudgetAccess.includes(access));

// Редактирование вкладок
export const canEditBudget = (userAccess: UserAccess[]) =>
  userAccess.some(access => editBudget.includes(access));

export default {
  canAdd,
  canApprovementVehiclePlan,
  canApprovingVehiclePlan,
  canEditOverhaulRepair,
  canApprovingBudgetRepair
};
