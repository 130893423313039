// @flow
import React from 'react';
import styled from 'styled-components';

// import notification from 'antd/lib/notification';
import Divider from 'antd/lib/divider';

import {Card} from './../../../../../components';
import {Table} from './../../../../../components/ui';
import {Header, Panel, Section, SectionTitle} from './../../../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../../../components/layout/Breadcrumbs';
import Grid, {GridItem} from './../../../../../components/layout/Grid';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;
const { Field } = Card;

type Props = {};
type State = {};

const OPERATION_COLUMN = [
  {
    title: 'Наименование работ',
    dataIndex: ''
  },
  {
    title: 'Код',
    dataIndex: ''
  },
  {
    title: 'Кол-во по данным подрядчика',
    dataIndex: ''
  },
  {
    title: 'Кол-во по данным учета',
    dataIndex: ''
  },
  {
    title: 'Сумма по данным подрядчика (без НДС)',
    dataIndex: ''
  },
  {
    title: 'Сумма по данным учета (без НДС)',
    dataIndex: ''
  }
];
const PARTS_COLUMNS = [
  {
    title: 'Наименование запчастей',
    dataIndex: ''
  },
  {
    title: 'Код',
    dataIndex: ''
  },
  {
    title: 'Кол-во по данным подрядчика',
    dataIndex: ''
  },
  {
    title: 'Кол-во по данным учета',
    dataIndex: ''
  },
  {
    title: 'Цена по данным подрядчика',
    dataIndex: ''
  },
  {
    title: 'Цена по данным учета',
    dataIndex: ''
  },
  {
    title: 'Сумма по данным подрядчика (без НДС)',
    dataIndex: ''
  },
  {
    title: 'Сумма по данным учета (без НДС)',
    dataIndex: ''
  }
];

export default class extends React.Component<Props, State> {
  render() {
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to="/budget/execution">Бюджет</Crumb>
              <Crumb>Сравнение данных</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>Сравнение данных по карточке работ</h1>
          <SectionContent>
            <Grid gutter="16px" cols={5}>
              <GridItem>
                <Field label="Статус">{null}</Field>
              </GridItem>
              <GridItem>
                <Field label="Вид работ">{null}</Field>
              </GridItem>
              <GridItem>
                <Field label="ТС">{null}</Field>
              </GridItem>
              <GridItem>
                <Field label="Подрядчик">{null}</Field>
              </GridItem>
              <GridItem>
                <Field label="Номер акта">{null}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </StyledPanel>
        <Section>
          <SectionTitle divider>Работы и запчасти</SectionTitle>
          <SectionContent>
            <Table columns={OPERATION_COLUMN} data={[]} />
          </SectionContent>
          <SectionContent>
            <Table columns={PARTS_COLUMNS} data={[]} />
          </SectionContent>
          <Divider
            style={{
              margin: '0px 16px',
              width: 'calc(100% - 32px)',
              minWidth: 'inherit'
            }}
          />
          <SectionContent>
            <Grid>
              <GridItem style={{ gridColumnStart: 2 }}>
                <Grid cols={7}>
                  <GridItem>Итого:</GridItem>
                  <GridItem span={3}>
                    <Field label="По данным подрядчика">{null}</Field>
                  </GridItem>
                  <GridItem span={3}>
                    <Field label="По плану">{null}</Field>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      </>
    );
  }
}
