// @flow
import {Link} from '@reach/router';
import Button from 'antd/lib/button';
import AntIcon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';

import Popover from 'antd/lib/popover';
import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {notificationLoading} from '../../../components/Notifications';
import {vehiclePlanApi} from '../../../lib/api';
import {calculationStatusEnum, vehiclePlanStatusEnum, vehicleTypeEnum, vehicleTypes} from '../../../lib/enum';
import {formatDate, getPathWithHistoryParams, minus, navigate, plus, toLocalStringRu} from '../../../lib/helpers';

import type {UserAccess, VehicleModel, VehiclePlan, VehicleType} from '../../../lib/types';

import {withUserAccess} from '../../withUserAccess';

import {Header, TopPanel} from './../../../components/layout';
import Breadcrumbs, {Crumb} from './../../../components/layout/Breadcrumbs';
import {canApprovingVehiclePlan} from './accessRight';

import type {VehicleListFilterParams} from './components/FilterVehicleList';
import MainTab from './components/Tab';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Model = styled.div`
  width: 268px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const LicensePlate = styled.div`
  flex: 1;
`;
const IconWarn = styled(AntIcon)`
  font-size: 13px;
  position: relative;
  margin-left: 10px;
  top: 4px;
`;

export const iconSorter = (sort?: ?boolean) => (
  <span>
    <div
      title="Сортировка"
      style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}
    >
      <i
        aria-label="icon: caret-up"
        style={{ color: sort === true ? '#1890ff' : '#bfbfbf' }}
        class="anticon anticon-caret-up ant-table-column-sorter-up on"
      >
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          class=""
          data-icon="caret-up"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path>
        </svg>
      </i>
      <i
        aria-label="icon: caret-down"
        class="anticon anticon-caret-down ant-table-column-sorter-down off"
        style={{ marginTop: -4, color: sort === false ? '#1890ff' : '#bfbfbf' }}
      >
        <svg
          viewBox="0 0 1024 1024"
          focusable="false"
          class=""
          data-icon="caret-down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
        </svg>
      </i>
    </div>
  </span>
);

export const DecomissioningDateWarn = (props: { date?: string }) => (
  <Popover
    content={<>Планируемая дата списания: {formatDate(props.date)}</>}
    placement="right"
  >
    <IconWarn type="exclamation-circle" theme="twoTone" twoToneColor="red" />
  </Popover>
);
export const OverhaulRepairCostWarn = () => (
  <Popover
    content={<>Нет цены у некоторых материалов для проведения капремонта</>}
    placement="right"
  >
    <IconWarn type="warning" theme="twoTone" twoToneColor="red" />
  </Popover>
);

export const toGibdd = (vehicleType?: VehicleType) => {
  return vehicleType
    ? ![
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaAvtomobyle,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.traktorTiagach,
        vehicleTypeEnum.traktorPum,
        vehicleTypeEnum.traktorBuldozer,
        vehicleTypeEnum.ekskavatorVKovshaDo05M3,
        vehicleTypeEnum.ekskavatorVKovshaSvyshe05M3,
        vehicleTypeEnum.gydrotsykl,
        vehicleTypeEnum.lodkaSMotorom,
        vehicleTypeEnum.lodkaVesla,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.snegoxod
      ].includes(vehicleType)
    : false;
};

export const toGtn = (vehicleType?: VehicleType) => {
  return vehicleType
    ? [
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaTraktore,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.traktorTiagach,
        vehicleTypeEnum.traktorPum,
        vehicleTypeEnum.traktorBuldozer,
        vehicleTypeEnum.ekskavatorVKovshaDo05M3,
        vehicleTypeEnum.ekskavatorVKovshaSvyshe05M3,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.snegoxod
      ].includes(vehicleType)
    : false;
};

export const toGpmAndExpertise = (vehicleType?: VehicleType) => {
  return vehicleType
    ? [
        vehicleTypeEnum.agpVyletStrelyDo28M,
        vehicleTypeEnum.agpVyletStrelySvyshe28M,
        vehicleTypeEnum.agpRpnVyletStrelyDo28M,
        vehicleTypeEnum.agpRpnVyletStrelySvyshe28M,
        vehicleTypeEnum.avtokranGPDo24T,
        vehicleTypeEnum.avtokranGPOt25TDo49T,
        vehicleTypeEnum.avtokranGPSvyshe50T,
        vehicleTypeEnum.bkmNaAvtomobyle,
        vehicleTypeEnum.bkmRpnNaAvtomobyle,
        vehicleTypeEnum.bkmNaTraktore,
        vehicleTypeEnum.bkmRpnNaTraktore,
        vehicleTypeEnum.bgmNaTraktore,
        vehicleTypeEnum.gruzovoiBortovoiN2Do7TnSKmu,
        vehicleTypeEnum.gruzovoiBortovoiN3Svyshe7TnSKmu,
        vehicleTypeEnum.avtomobylTiagachSKmu,
        vehicleTypeEnum.gruzopassazhyrskyiAvtomobylSKmu,
        vehicleTypeEnum.prytsepSKmu,
        vehicleTypeEnum.pPrytsepSKmu
      ].includes(vehicleType)
    : false;
};

export const gps = (vehicleType?: VehicleType) => {
  return vehicleType
    ? ![
        vehicleTypeEnum.lodkaSMotorom,
        vehicleTypeEnum.lodkaVesla,
        vehicleTypeEnum.gydrotsykl,
        vehicleTypeEnum.prytsepLegkovoiGPDo750Kg,
        vehicleTypeEnum.prytsepAvtomobylnyi,
        vehicleTypeEnum.prytsepTraktornyi,
        vehicleTypeEnum.prytsepSKmu,
        vehicleTypeEnum.pPrytsepBezKmu,
        vehicleTypeEnum.pPrytsepSKmu,
        vehicleTypeEnum.prytsepRospusk,
        vehicleTypeEnum.prytsepDliaDgu,
        vehicleTypeEnum.prytsepKabelnyi,
        vehicleTypeEnum.prytsepYzmelchytel,
        vehicleTypeEnum.prytsepKompressor,
        vehicleTypeEnum.prytsepTsysterna,
        vehicleTypeEnum.prytsepGenerator,
        vehicleTypeEnum.snegoxod,
        vehicleTypeEnum.snegoBolotoxod,
        vehicleTypeEnum.kvadrotsykl,
        vehicleTypeEnum.bolotoxod,
        vehicleTypeEnum.car,
        vehicleTypeEnum.legkovoiPolnopryvodnyi
      ].includes(vehicleType)
    : false;
};

export const measuringDevices = (vehicleType?: VehicleType) => {
  return [
    vehicleTypeEnum.avtomobylMaslovozVDo5M3,
    vehicleTypeEnum.avtomobylMaslovozVSvyshe5M3,
    vehicleTypeEnum.avtomobylAssenyzatorVDo5M3,
    vehicleTypeEnum.avtomobylAssenyzatorVSvyshe5M3
  ].includes(vehicleType);
};

export type VehicleTypeData = 'selfVehicles' | 'plannedVehicles';
export const MONTH = [
  { name: 'january', title: 'Январь' },
  { name: 'february', title: 'Февраль' },
  { name: 'march', title: 'Март' },
  { name: 'april', title: 'Апрель' },
  { name: 'may', title: 'Май' },
  { name: 'june', title: 'Июнь' },
  { name: 'july', title: 'Июль' },
  { name: 'august', title: 'Август' },
  { name: 'september', title: 'Сентябрь' },
  { name: 'october', title: 'Октябрь' },
  { name: 'november', title: 'Ноябрь' },
  { name: 'december', title: 'Декабрь' }
];

export const itogCalculation = (data: any) => {
  const itog = {
    // $FlowFixMe
    months: Array(12)
      .fill()
      .map(() => ({ cost: 0 })),
    sum: 0
  };
  data.forEach((item: any) => {
    itog.sum = plus(itog.sum, item.sum);
    item.months.forEach((month: any, index) => {
      if (index < 12) {
        itog.months[index].cost = plus(itog.months[index].cost, month.cost);
      }
    });
  });
  return itog;
};

export const COLUMNS = [
  {
    title: 'Марка и модель',
    dataIndex: 'selfVehiclePlanVehicle.vehicle.vehicleModel',
    width: 300,
    key: 'vehicleModel',
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.vehicleModel ?? false;
      const second = b?.selfVehiclePlanVehicle?.vehicle?.vehicleModel ?? false;
      return first && second
        ? `${first.brandName} ${first.name}`.localeCompare(
            `${second.brandName} ${second.name}`
          )
        : false;
    },
    fixed: 'left',
    render: (vehicleModel: VehicleModel) => {
      return vehicleModel ? (
        <Model>{`${vehicleModel.brandName} ${vehicleModel.name}`}</Model>
      ) : (
        'Итого: '
      );
    }
  },
  {
    title: 'Гос. номер',
    width: 200,
    key: 'licensePlate',
    fixed: 'left',
    dataIndex: 'selfVehiclePlanVehicle.vehicle.licensePlate',
    render: (licensePlate: string, record: any) => {
      return (
        licensePlate && (
          <Container>
            <Link to={`/vehicles/${record.selfVehiclePlanVehicle.vehicleId}`}>
              {licensePlate}
            </Link>
            {record?.selfVehiclePlanVehicle?.plannedWriteoffDate && (
              <DecomissioningDateWarn
                date={record.selfVehiclePlanVehicle.plannedWriteoffDate}
              />
            )}
          </Container>
        )
      );
    },
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.licensePlate ?? '0';
      const second = b?.selfVehiclePlanVehicle?.vehicle?.licensePlate ?? '0';
      return a.selfVehiclePlanVehicle !== undefined
        ? first.localeCompare(second)
        : false;
    }
  },
  {
    title: 'Тип ТС',
    width: 300,
    key: 'type',
    dataIndex: 'selfVehiclePlanVehicle.vehicle.vehicleModel.type',
    sorter: (a: any, b: any) => {
      const first =
        a?.selfVehiclePlanVehicle?.vehicle?.vehicleModel.type ?? false;
      const second =
        b?.selfVehiclePlanVehicle?.vehicle?.vehicleModel.type ?? false;
      return first && second
        ? vehicleTypes[first].localeCompare(vehicleTypes[second])
        : false;
    },
    render: (type: VehicleType) => type && vehicleTypes[type]
  },
  {
    title: 'Год выпуска',
    width: 130,
    key: 'yearIssued',
    sorter: (a: any, b: any) => {
      const first = a?.selfVehiclePlanVehicle?.vehicle?.yearIssued ?? 0;
      const second = b?.selfVehiclePlanVehicle?.vehicle?.yearIssued ?? 0;
      return a.selfVehiclePlanVehicle !== undefined
        ? minus(first, second)
        : false;
    },
    dataIndex: 'selfVehiclePlanVehicle.vehicle.yearIssued'
  }
];

export const COLUMNS_MONTH = [
  ...COLUMNS,
  ...MONTH.map((month, index) => ({
    title: month.title,
    width: '150',
    dataIndex: `months[${index}].cost`,
    render: (cost: number) =>
      toLocalStringRu(cost, { minimumFractionDigits: 2 })
  })),
  {
    title: 'Итог',
    dataIndex: 'sum',
    render: (sum: number) => toLocalStringRu(sum, { minimumFractionDigits: 2 })
  }
];

export const filteredData = (data: any, filter: VehicleListFilterParams) => {
  return data
    .filter(selfVehicle =>
      filter.vehicleId
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle?.vehicleId
        : true
    )
    .filter(selfVehicle =>
      filter.type
        ? filter.type ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.vehicleModel.type
        : true
    )
    .filter(selfVehicle =>
      filter.vehicleModelId
        ? filter.vehicleModelId ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.vehicleModelId
        : true
    )
    .filter(selfVehicle =>
      filter.yearIssued
        ? filter.yearIssued ===
          selfVehicle.selfVehiclePlanVehicle?.vehicle.yearIssued
        : true
    )
    .filter(selfVehicle => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });
};

export const filteredTaxData = (data: any, filter: VehicleListFilterParams) => {
  return data
    .filter(selfVehicle =>
      filter.vehicleId ? filter.vehicleId === selfVehicle.vehicleId : true
    )
    .filter(selfVehicle =>
      filter.type ? filter.type === selfVehicle.vehicle.vehicleModel.type : true
    )
    .filter(selfVehicle =>
      filter.vehicleModelId
        ? filter.vehicleModelId === selfVehicle.vehicle.vehicleModelId
        : true
    )
    .filter(selfVehicle =>
      filter.yearIssued
        ? filter.yearIssued === selfVehicle.vehicle.yearIssued
        : true
    )
    .filter(selfVehicle =>
      filter.hideEmpty === true ? selfVehicle.tax !== 0 : true
    );
};

type CreateBudgetProps = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};
export const CreateBudget = (props: CreateBudgetProps) => {
  const { vehiclePlanId, userAccess } = props;
  const [name, setName] = useState(null);
  const [vehiclePlan, setVehiclePlan] = useState(null);
  const isCopy =
    vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
    vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;

  const onCreateBudget = async () => {
    try {
      if (name || isCopy) {
        notificationLoading({
          message: 'Сформировываем предложение в бюджет...',
          key: 'createBudget'
        });
        await vehiclePlanApi.createBudget({ name, id: vehiclePlanId });
        setName(null);
        navigate(`/budget/vehicle/${vehiclePlanId}/itog`);
      } else {
        throw new Error('Введите наименование бюджета');
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('createBudget');
    }
  };

  const getVehiclePlan = useCallback(async () => {
    const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
    setVehiclePlan(vehiclePlan);
  }, [vehiclePlanId]);

  useEffect(() => {
    if (vehiclePlanId) {
      getVehiclePlan();
    }
  }, [getVehiclePlan, vehiclePlanId]);

  if (canApprovingVehiclePlan(userAccess)) {
    if (isCopy) {
      return vehiclePlan?.status !==
        vehiclePlanStatusEnum.approvedAfterCognos ? (
        <Button type="primary" onClick={onCreateBudget}>
          Утвердить копию бюджета
        </Button>
      ) : null;
    } else if (vehiclePlan?.budgetStatus === calculationStatusEnum.draft) {
      return (
        <Popconfirm
          overlayStyle={{ zIndex: 2000 }}
          placement="left"
          title={
            <Input
              placeholder="Введите наименование бюджета"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          }
          onConfirm={onCreateBudget}
        >
          <Button type="primary">Сформировать предложение</Button>
        </Popconfirm>
      );
    }
  }
  return null;
};

export type PropsHeader = {
  title: string,
  vehiclePlanId: number,
  vehiclePlan?: VehiclePlan,
  mainTab?: boolean,
  panel?: Function,
  right?: any
};

export const commonHeaderPanel = ({
  mainTab = true,
  right,
  ...props
}: PropsHeader) => {
  const { vehiclePlanId, panel, vehiclePlan } = props;
  const isCopy =
    vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
    vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
  const CreateBudgetComponent = withUserAccess(CreateBudget);
  return (
    <>
      <Header
        left={
          <Breadcrumbs>
            <Crumb to={getPathWithHistoryParams('/budget/vehicle')}>
              Бюджет СТС
            </Crumb>
            <Crumb>{props.title}</Crumb>
          </Breadcrumbs>
        }
        right={
          right ? (
            right
          ) : (
            <div style={{ minHeight: 32 }}>
              {mainTab && (
                <CreateBudgetComponent vehiclePlanId={vehiclePlanId} />
              )}
            </div>
          )
        }
      />
      <TopPanel>{panel ? panel() : <h1>{props.title}</h1>}</TopPanel>
      {mainTab && (
        <MainTab
          vehiclePlanId={vehiclePlanId}
          vehiclePlan={vehiclePlan}
          showAdditional={!isCopy}
        />
      )}
    </>
  );
};

// максимально допустимая дельта между текущими значениями бюджета и значениями из когнос
export const COGNOS_DELTA = 1000;
