// @flow
import CommonApi from './../commonApi';
import { DeadlinesForSendingDataToCognosItem } from '../types/deadlinesForSendingDataToCognos';

const deadlinesForSendingDataToCognos = new CommonApi<DeadlinesForSendingDataToCognosItem>(
  'CognosActualDataSendingTimeline',
  'Сроки отправки фактических данных в ИСУ «Бюджетирование»'
);

export default { ...deadlinesForSendingDataToCognos };
