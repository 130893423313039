// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import Button from 'antd/lib/button';

import {ButtonsRow} from '../../../../../../components/ui';
import {printNotification} from '../../../../../../lib/notificationWrapper';
import type {AssignmentLimit} from './../../../../../../lib/types';
import {assignmentLimitApi} from './../../../../../../lib/api';
import {
  convertContractVehicleToString,
  convertEmployeeToString,
  getPathWithHistoryParams,
  multiplePlus,
  toFixed,
} from './../../../../../../lib/helpers';

import {Grid, Header, Section, SectionTitle, TopPanel} from './../../../../../../components/layout';
import Table from './../../../../../../components/ui/Table';
import {GridItem} from './../../../../../../components/layout/Grid';
import Breadcrumbs, {Crumb} from './../../../../../../components/layout/Breadcrumbs';
import Field from './../../../../../../components/card/Field';

import {COLUMNS} from './elements';
import {CognosType} from '../../../ContractVehicleBudget';

type Props = {
  assignmentLimitId: number,
  cognosType: $Keys<CognosType>
};
type State = {
  assignmentLimit: ?AssignmentLimit,
  columns: any[]
};

const Content = styled.div`
  padding: 16px;
`;

export default class extends Component<Props, State> {
  state = {
    assignmentLimit: null,
    columns: COLUMNS
  };

  componentDidMount() {
    const { columns } = this.state;
    this.fetch();
    if (!columns.find(column => column.dataIndex === 'itog')) {
      columns.splice(2, 0, {
        title: 'Итого',
        dataIndex: 'itog',
        render: (sum: number) => toFixed(sum)
      });
    }
    this.setState({ columns });
  }

  fetch = async () => {
    const { assignmentLimitId } = this.props;
    const assignmentLimit = await assignmentLimitApi.get(assignmentLimitId);
    this.setState({ assignmentLimit });
  };

  handlePrint = () => {
    printNotification(
      async () => await assignmentLimitApi.print(this.props.assignmentLimitId)
    );
  };

  render() {
    const { cognosType } = this.props;
    const { assignmentLimit, columns } = this.state;
    const data =
      (assignmentLimit &&
        assignmentLimit.budgetDetails.map((budgetDetail: any) => {
          return {
            ...budgetDetail,
            itog: multiplePlus([
              budgetDetail.january,
              budgetDetail.february,
              budgetDetail.march,
              budgetDetail.april,
              budgetDetail.may,
              budgetDetail.june,
              budgetDetail.july,
              budgetDetail.august,
              budgetDetail.september,
              budgetDetail.october,
              budgetDetail.november,
              budgetDetail.december
            ])
          };
        })) ||
      [];
    if (assignmentLimit === null) {
      return null;
    }
    return (
      <>
        {assignmentLimit && (
          <>
            <Header
              left={
                <Breadcrumbs>
                  <Crumb
                    to={getPathWithHistoryParams(
                      `/budget/contract-vehicle/fixed/${cognosType}`
                    )}
                  >
                    Закрепленные НТС
                  </Crumb>
                  <Crumb
                    to={`/budget/contract-vehicle/fixed/${cognosType}/${assignmentLimit.contractVehiclePlanId}/budget`}
                  >
                    Бюджет
                  </Crumb>
                  <Crumb>Расшифровка затрат ТС</Crumb>
                </Breadcrumbs>
              }
              right={
                <ButtonsRow>
                  <Button onClick={this.handlePrint}>Печать</Button>
                </ButtonsRow>
              }
            />
            <TopPanel>
              <h1>
                {convertContractVehicleToString(
                  assignmentLimit.contractVehicle
                )}
              </h1>
            </TopPanel>
            <Section>
              <SectionTitle divider>Расшифровка затрат по ТС123</SectionTitle>
              <Content>
                <Grid gutter="16px" cols="5">
                  <GridItem>
                    <Field label="ФИО">
                      {convertEmployeeToString(
                        assignmentLimit.regulationLimit.employee
                      )}
                    </Field>
                  </GridItem>
                  {/* <GridItem>
                    <Field label="Должность">
                      <>Зам. директора по общим вопросам</>
                    </Field>
                  </GridItem> */}
                  <GridItem>
                    <Field label="Направление расходов">
                      {assignmentLimit.expenseDirectionName}
                    </Field>
                  </GridItem>
                </Grid>
              </Content>
              <Table columns={columns} data={data} />
            </Section>
          </>
        )}
      </>
    );
  }
}
