import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Section } from '../../../../components/layout';
import { notificationLoading } from '../../../../components/Notifications';

import { AntTable, Icon } from '../../../../components/ui';

import { additionalCalculationApi, vehiclePlanApi } from '../../../../lib/api';
import {
  calculationStatusEnum,
  vehiclePlanStatusEnum
} from '../../../../lib/enum';
import { plus, toLocalStringRu, withEmptyRow } from '../../../../lib/helpers';
import type {
  AdditionalCalculation,
  AdditionalCalculationMonth,
  CalculationStatus,
  UserAccess
} from '../../../../lib/types';

import { commonHeaderPanel, MONTH } from '../lib';
import { ModalAdditionalCalculation } from './Modal';
import { withUserAccess } from '../../../withUserAccess';
import { canEditBudget } from '../accessRight';

type ListAdditionalProps = {
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export default withUserAccess((props: ListAdditionalProps) => {
  const [data, setData] = useState([]);
  const [vehiclePlan, setVehiclePlan] = useState({});
  const [currentRowId, setCurrentRowId] = useState(-1);
  const [visibleData, setVisibleData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const columns = [
    {
      title: 'Направление расходов',
      dataIndex: 'expenseDirection',
      width: '400px',
      render: expenseDirection =>
        expenseDirection ? expenseDirection.name ?? expenseDirection : 'Итого: '
    },
    ...MONTH.map((month, index) => ({
      title: month.title,
      children: [
        {
          title: 'Сумма руб. без НДС',
          dataIndex: `months[${index}].cost`,
          render: (count, row) =>
            row.id ? toLocalStringRu(count, { minimumFractionDigits: 2 }) : null
        }
      ]
    })),
    {
      title: 'Итого',
      render: (record: AdditionalCalculation) => {
        let sum = 0;
        if (Array.isArray(record.months)) {
          record.months.forEach((month: AdditionalCalculationMonth) => {
            sum = plus(sum, month.cost);
          });
        }
        return toLocalStringRu(sum, { minimumFractionDigits: 2 });
      }
    },
    {
      key: 'delete',
      dataIndex: 'id',
      width: '50px',
      onCell: () => ({ onClick: (e: any) => e.stopPropagation() }),
      render: (id: number, record: AdditionalCalculation, rowIndex: number) => {
        return (
          Number.isInteger(id) &&
          !readOnly && (
            <Popconfirm
              placement="topRight"
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => onDelete(id, rowIndex)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          )
        );
      }
    }
  ];

  const fetch = useCallback(async () => {
    try {
      const { data } = await additionalCalculationApi.fetch({
        vehiclePlanId: props.vehiclePlanId
      });

      setData(data);
    } catch (e) {
      notification.error({ message: 'Ошибка', description: e.message });
    }
  }, [props.vehiclePlanId]);

  const fetchVehiclePlan = useCallback(async () => {
    try {
      const vehiclePlan = await vehiclePlanApi.get(props.vehiclePlanId);
      setVehiclePlan(vehiclePlan);
    } catch (e) {
      notification.error({ message: 'Ошибка', description: e.message });
    }
  }, [props.vehiclePlanId]);

  const update = useCallback(
    async (dataRow: AdditionalCalculation) => {
      try {
        let newData = [...data];
        if (dataRow.id) {
          const updatedData = await additionalCalculationApi.update(dataRow);
          newData[currentRowId] = updatedData;
        } else {
          const addedData = await additionalCalculationApi.add(dataRow);
          newData.push(addedData);
        }
        setData(newData.filter(data => !!data.id));
      } catch (e) {
        notification.error({ message: 'Ошибка', description: e.message });
      }
    },
    [currentRowId, data]
  );

  const onDelete = async (id: number, rowIndex: number) => {
    try {
      notificationLoading({
        message: 'Удаление записи...',
        key: 'delete'
      });
      await additionalCalculationApi.delete(id);
      data.splice(rowIndex, 1);
      setData([...data]);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('delete');
    }
  };

  useEffect(() => {
    fetchVehiclePlan();
    fetch();
  }, [fetch, fetchVehiclePlan]);

  useEffect(() => {
    setReadOnly(
      vehiclePlan?.additionalCalculationStatus ===
        calculationStatusEnum.calculationDone
    );
  }, [vehiclePlan]);

  const onAdd = useCallback(() => {
    setCurrentRowId(data.length);
    setData([
      ...data,
      {
        expenseDirectionId: null,
        months: MONTH.map((el, index) => {
          return {
            month: index + 1,
            count: 0,
            cost: 0
          };
        }),
        vehiclePlanId: props.vehiclePlanId
      }
    ]);
    setModalVisible(true);
  }, [data, props.vehiclePlanId]);

  const itog = (data: AdditionalCalculation[]) => {
    let itog = {
      id: true,
      name: 'Итого:',
      months: Array(12)
        .fill()
        .map(() => ({ cost: 0 }))
    };
    data.forEach(additional =>
      additional.months.forEach(
        (month: AdditionalCalculationMonth, index: number) => {
          itog.months[index].cost = plus(itog.months[index].cost, month.cost);
        }
      )
    );
    return itog;
  };

  useEffect(() => {
    setVisibleData(
      readOnly
        ? data.length === 0
          ? []
          : [...data, itog(data)]
        : withEmptyRow(data.length === 0 ? [] : [...data, itog(data)], {
            emptyRow: {
              expenseDirection: (
                <Button size="small" onClick={onAdd}>
                  Добавить
                </Button>
              )
            }
          })
    );
  }, [data, onAdd, readOnly]);

  const onAddCancel = () => {
    setModalVisible(false);
    let newData = [...data];
    newData[currentRowId].id ?? newData.pop();
    setData(newData);
    setCurrentRowId(-1);
  };

  const changeStatus = async (status: CalculationStatus) => {
    const { vehiclePlanId } = props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving'
      });
      await additionalCalculationApi.changeStatus({
        vehiclePlanId,
        status
      });
      fetch();
      fetchVehiclePlan();
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      notification.close('saving');
    }
  };

  const isDraft = () =>
    vehiclePlan?.additionalCalculationStatus === calculationStatusEnum.draft;

  const isRangeMonths = (monthIndex: number) => {
    if (vehiclePlan?.startDate && vehiclePlan?.endDate) {
      const start = moment(vehiclePlan.startDate).get('month');
      const end = moment(vehiclePlan.endDate).get('month');
      return start <= monthIndex && end >= monthIndex;
    }
    return false;
  };

  const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;

  return (
    <>
      {commonHeaderPanel({
        vehiclePlanId: props.vehiclePlanId,
        vehiclePlan,
        title: 'Доп. потребности'
      })}
      <Section>
        <AntTable
          onRow={(record, rowIndex) => ({
            onClick: async () => {
              if (Number.isInteger(record.id)) {
                await setCurrentRowId(rowIndex);
                setModalVisible(true);
              }
            }
          })}
          columns={columns}
          data={visibleData}
          pagination={false}
          rowKey="expenseDirection.name"
          bordered
        />
        <ModalAdditionalCalculation
          visible={modalVisible}
          readOnly={readOnly}
          isRangeMonths={isRangeMonths}
          vehiclePlan={vehiclePlan}
          handleSave={(data: AdditionalCalculation) => {
            setModalVisible(false);
            update(data);
            setCurrentRowId(-1);
          }}
          handleCancel={onAddCancel}
          data={visibleData[currentRowId]}
        />
      </Section>
      {canEditBudget(props.userAccess) && (
        <>
          {isDraft() && (
            <Section style={{ padding: '16px' }}>
              <Button
                type="primary"
                onClick={() =>
                  changeStatus(calculationStatusEnum.calculationDone)
                }
              >
                Утвердить
              </Button>
            </Section>
          )}
          {!planApproved && !isDraft() && (
            <Section style={{ padding: '16px' }}>
              <Button
                type="primary"
                onClick={() => changeStatus(calculationStatusEnum.draft)}
              >
                Редактировать
              </Button>
            </Section>
          )}
        </>
      )}
    </>
  );
});
