// @flow

import React, {Component} from 'react';
import Button from 'antd/lib/button';
import qs from 'query-string';
import {Link} from '@reach/router';
import isEmpty from 'lodash/isEmpty';
import {connect} from 'react-redux';

import type {
  ContractVehicle,
  ListState,
  OrgUnitNode,
  UserAccess,
  Vehicle,
  VehicleStatus as VehicleStatusType,
} from './../../lib/types';
import {accessTypeEnum, vehicleTypes} from '../../lib/enum';
import {Section} from './../../components/layout';
import VehicleStatus from '../../components/VehicleStatus';
import type {ContractVehicleFilterParams} from './Filter';
import Filter from './Filter';
import {withUserAccess} from './../withUserAccess';
import {TabItem, Table, Tabs} from '../../components/ui';
import Header from '../../components/layout/Header';
import {getListInitialState, navigate, setQueryParams} from '../../lib/helpers';
import type {FetchListParams} from '../../lib/api';
import {contractVehicleApi} from '../../lib/api';
import type {PersistFilterPayload} from '../../ducks/persistFilters';
import {setFilter} from '../../ducks/persistFilters';
import type {AppState} from '../../ducks/redux';
import {notificationLoading} from '../../components/Notifications';
import notification from 'antd/lib/notification';

type Props = {
  contractVehicles: Array<Vehicle>,
  deleteContractVehicle: Function,
  fetchContractVehicles: Function,
  userAccess: UserAccess[],
  orgUnitId: number,
  currentOrgUnit: OrgUnitNode,
  totalCount: number,
  pageSize: number,
  page: number,
  location: Location & { state: { page: number } },
  filter: ContractVehicleFilterParams,
  setFilter: (payload: PersistFilterPayload) => void
};

type State = ListState<ContractVehicle>;

export const canAddAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.adminBranch,
  accessTypeEnum.creatingVehicle,
];

const filterPath = window.location.pathname;

export class List extends Component<Props, State> {
  static defaultProps = {
    location: {},
  };
  state = {
    ...getListInitialState(),
    filter: {},
  };
  columns = [
    {
      title: 'Статус',
      dataIndex: 'vehicle.status',
      sorter: true,
      render: (status: VehicleStatusType) => <VehicleStatus status={status} />,
    },
    {
      title: 'Гос. номер',
      sorter: true,
      dataIndex: 'vehicle.licensePlate',
      // Нужно для того, чтобы не отрабатывал onRow
      onCell: () => ({onClick: (e: any) => e.stopPropagation()}),
      render: (licensePlate: string, record: Vehicle) => (
        <Link to={`/vehicles/contract/${record.id}`}>{licensePlate}</Link>
      ),
    },
    {
      title: 'Год выпуска',
      sorter: true,
      dataIndex: 'vehicle.yearIssued',
    },
    {
      title: 'Марка',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel.brandName',
    },
    {
      title: 'Модель',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel.name',
    },
    {
      title: 'Тип',
      sorter: true,
      dataIndex: 'vehicle.vehicleModel.type',
      render: (type: string): ?string => type && vehicleTypes[type],
    },
    {
      title: 'Подрядчик',
      sorter: true,
      dataIndex: 'contractor.company.name',
    },
    {
      title: 'Подразделение',
      sorter: true,
      dataIndex: 'vehicle.orgUnitName',
    },
  ];
  canAddVehicles = () =>
    this.props.userAccess.some(role => canAddAccess.includes(role));
  handleRowClick = (id: number) => navigate(`/vehicles/contract/${id}`);

  fetchContractVehicles = async (
    page: number = 1,
    params: FetchListParams<ContractVehicleFilterParams> = {},
  ) => {
    const {filter} = this.props;
    this.setState({loading: true});
    if (isEmpty(params)) { params = { orderBy: 'id', byDescending: true }; }

    const {data, totalCount} = await contractVehicleApi.fetchContractVehicles(
      {
        ...filter,
        page,
        ...params,
      },
    );
    setQueryParams({page});
    this.setState({loading: false, data, totalCount, page});
  };

  setPersistFilter = async (values: ContractVehicleFilterParams) => {
    await this.props.setFilter({
      path: filterPath,
      values,
    });
  };

  cleanFilter = async () => {
    await this.setPersistFilter({});
    await this.fetchContractVehicles();
  };

  applyFilter = async (values: ContractVehicleFilterParams) => {
    await this.setPersistFilter(values);
    await this.fetchContractVehicles();
  };

  handlePrint = async () => {
    const {filter} = this.props;
    const {page, pageSize} = this.state;
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing',
      });
      await contractVehicleApi.print({
        page,
        pageSize,
        ...filter,
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message,
      });
    } finally {
      notification.close('printing');
    }
  };

  async componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    if (!isEmpty(filter)) {
      await this.setPersistFilter(filter);
    }
    await this.fetchContractVehicles(page);
  }

  render() {
    const {location, filter} = this.props;
    const {data, totalCount, pageSize, page, loading} = this.state;
    return (
      <>
        <Header
          left={<h1>Транспорт</h1>}
          right={(
            <>
              <Button style={{marginRight: '20px'}} onClick={this.handlePrint}>
                Печать
              </Button>
              {
                this.canAddVehicles() && (
                  <Link to="/vehicles/contract/new">
                    <Button type="primary" data-cy="add">
                      Создать
                    </Button>
                  </Link>
                )
              }
            </>
          )
          }
        />
        <Tabs defaultTab="contract">
          <TabItem
            tabKey="own"
            label="Собственные"
            onClick={() => navigate('/vehicles', true)}
          />
          <TabItem tabKey="contract" label="Наемные" />
        </Tabs>
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id),
            })}
            defaultOrderBy={'id'}
            fetch={this.fetchContractVehicles}
            pagination={{
              page,
              pageSize,
              totalCount,
              location,
            }}
            loading={loading}
            columns={this.columns}
            data={data.map(item => ({
              ...item,
              key: item.id,
            }))}
          />
        </Section>
      </>
    );
  }
}

export default connect(
  (state: AppState) => ({
    filter: state.persistFilters[filterPath] || {},
  }),
  {
    setFilter,
  },
)(withUserAccess(List));
