// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikProps } from 'formik';
import moment from 'moment';

import Divider from 'antd/lib/divider';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import type { Contract } from '../../../lib/types';
import { contractDocumentTypeEnum } from '../../../lib/enum';

import { OrgUnitSelect } from './../../../components';
import type { FormFieldType } from './../../../components/Form';
import Form from './../../../components/Form';
import { Section } from './../../../components/layout';
import Grid, { GridItem } from './../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import Selects from './../../../components/selects';
import Field from './../../../components/card/Field';

const { ContractorSelect, EmployeeSelect, YesNoSelect } = Selects;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  contract: ?Contract,
  onCancel: Function,
  onSubmit: Function
};

export default ({ contract, onCancel, onSubmit }: Props) => {
  const [useAccountNumber, setUseAccountNumber] = useState(
    !!(contract && contract.accountNumber)
  );
  const handleSubmit = async (contract: Contract) => {
    contract[
      `${useAccountNumber ? 'documentNumber' : 'accountNumber'}`
    ] = undefined;
    contract.documentType = useAccountNumber
      ? contractDocumentTypeEnum.account
      : contractDocumentTypeEnum.contract;
    onSubmit(contract);
  };
  return (
    <Form initialValues={contract} onSubmit={handleSubmit}>
      {(
        FormField: FormFieldType,
        {
          setFieldValue,
          handleSubmit,
          handleBlur,
          values,
          dirty,
          isSubmitting
        }: FormikProps
      ) => (
        <form onSubmit={handleSubmit}>
          <StyledSection>
            <Grid gutter="16px" cols={4}>
              <GridItem fullWidth>
                <Field label="По номеру лицевого счета">
                  <YesNoSelect
                    value={useAccountNumber}
                    onChange={value => {
                      setUseAccountNumber(value);
                    }}
                  />
                </Field>
              </GridItem>
              <GridItem>
                <FormField
                  label={`Номер ${
                    useAccountNumber ? 'лицевого счета' : 'договора'
                  }`}
                  name={useAccountNumber ? 'accountNumber' : 'documentNumber'}
                  required
                >
                  {field => (
                    <Input
                      placeholder={`Введите номер ${
                        useAccountNumber ? 'лицевого счета' : 'договора'
                      }`}
                      {...field}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem span={2}>
                <FormField
                  label="Контрагент"
                  name="contractorId"
                  required={!useAccountNumber}
                >
                  {({ name, value }) => (
                    <ContractorSelect
                      value={value}
                      placeholder="Выберите контрагента"
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Номер протокола ЦЗК"
                  fast
                  name="protocolNumber"
                >
                  {field => (
                    <Input placeholder="Введите номер протокола" {...field} />
                  )}
                </FormField>
              </GridItem>

              <GridItem>
                <FormField
                  label="Дата регистрации"
                  fast
                  name="registrationDate"
                >
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата утверждения" name="approvalDate">
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.registrationDate}
                      disabledDate={(date: string) =>
                        values.registrationDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(
                                moment.utc(values.registrationDate)
                              )
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Дата закрытия" name="closingDate">
                  {({ name, value }) => (
                    <DatePicker
                      name={name}
                      value={value ? moment(value) : value}
                      format="DD MMMM YYYY"
                      disabled={!values.approvalDate}
                      disabledDate={(date: string) =>
                        values.approvalDate
                          ? moment
                              .utc(date)
                              .startOf('day')
                              .isSameOrBefore(moment.utc(values.approvalDate))
                          : false
                      }
                      onChange={(value: string) => {
                        setFieldValue(
                          name,
                          moment
                            .utc(value)
                            .startOf('day')
                            .toISOString()
                        );
                      }}
                    />
                  )}
                </FormField>
              </GridItem>

              <GridItem>
                <FormField label="Предмет договора" fast name="protocolSubject">
                  {field => (
                    <Input placeholder="Введите предмет договора" {...field} />
                  )}
                </FormField>
              </GridItem>

              <GridItem fullWidth>
                <Grid gutter="16px" cols={4}>
                  <GridItem>
                    <FormField
                      label="Дата начала действия"
                      name="startDate"
                      required={!useAccountNumber}
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Дата окончания действия"
                      name="endDate"
                      required={!useAccountNumber}
                    >
                      {({ name, value }) => (
                        <DatePicker
                          name={name}
                          value={value ? moment(value) : value}
                          format="DD MMMM YYYY"
                          disabled={!values.startDate}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value: string) => {
                            setFieldValue(
                              name,
                              moment
                                .utc(value)
                                .startOf('day')
                                .toISOString()
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </GridItem>
            </Grid>

            <Divider />

            <Grid gutter="16px" cols={4}>
              {!useAccountNumber && (
                <GridItem span={2}>
                  <FormField
                    label="Куратор договора"
                    name="employeeId"
                    fast
                    required
                  >
                    {({ name, value }) => (
                      <EmployeeSelect
                        onChange={(value, option) => {
                          setFieldValue(name, value);
                          setFieldValue(
                            'orgUnitId',
                            option ? option.props.employee.orgUnitId : undefined
                          );
                        }}
                        value={value}
                      />
                    )}
                  </FormField>
                </GridItem>
              )}
              <GridItem>
                <FormField label="Подразделение" name="orgUnitId" required>
                  {({ name, value }) => (
                    <OrgUnitSelect
                      disabled={!useAccountNumber}
                      onChange={value => setFieldValue(name, value)}
                      value={value}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </StyledSection>

          <Footer>
            <Button
              disabled={isSubmitting}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {values.id ? 'Сохранить' : 'Создать'}
            </Button>
            <CancelButton dirty={dirty} onClick={onCancel}>
              Отменить
            </CancelButton>
          </Footer>
        </form>
      )}
    </Form>
  );
};
