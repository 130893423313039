// @flow
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from '@reach/router';

import notification from 'antd/lib/notification';
import Popconfirm from 'antd/lib/popconfirm';
import AntIcon from 'antd/lib/icon';

import type { LicensePlateChange } from './../../../lib/types';
import { licensePlateChangeApi } from '../../../lib/api';

import { Icon, ListTable } from './../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

import { COMMON_MAINTENANCE_COLUMNS } from './../lib';

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const StyledAntIcon = styled(AntIcon)`
  cursor: pointer;
  color: #2b7cff;
  margin-right: 10px;
`;

const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

type Props = {
  vehicleId: number,
  enableDelete: boolean
};

type State = {
  loading: boolean,
  licensePlateChanges: LicensePlateChange[]
};

class LicensePlateChangeList extends Component<Props, State> {
  state = {
    loading: false,
    licensePlateChanges: []
  };

  async componentDidMount() {
    await this.fetchLicensePlateChanges();
  }

  fetchLicensePlateChanges = async () => {
    try {
      this.setState({
        loading: true
      });
      const licensePlateChanges = await licensePlateChangeApi.fetchVehicleLicensePlateChanges(
        this.props.vehicleId
      );
      this.setState({
        licensePlateChanges: licensePlateChanges.data
      });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  onDelete = async (licensePlateChange: LicensePlateChange) => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      if (licensePlateChange.id) {
        await licensePlateChangeApi.deleteLicensePlateChange(
          licensePlateChange.id
        );
        notification.success({
          message: `Замена гос. номера успешно удалена`
        });
        await this.fetchLicensePlateChanges();
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  columns = [
    ...COMMON_MAINTENANCE_COLUMNS,
    {
      width: '20px',
      render: (record: LicensePlateChange) =>
        record && this.props.enableDelete ? (
          <Operations>
            <Link
              to={`/vehicles/${
                this.props.vehicleId
              }/license-plate-changes/${parseInt(record.id)}`}
            >
              <StyledAntIcon type="edit" />
            </Link>
            <Popconfirm
              placement="topRight"
              title="Вы действительно хотите удалить?"
              okText="Да"
              cancelText="Нет"
              onConfirm={() => this.onDelete(record)}
            >
              <StyledIcon type="x" />
            </Popconfirm>
          </Operations>
        ) : null
    }
  ];

  render() {
    const { licensePlateChanges, loading } = this.state;
    return (
      <ListTable
        isLoading={loading}
        columns={this.columns}
        data={licensePlateChanges}
      />
    );
  }
}

export default LicensePlateChangeList;
