// @flow
import { convertFromKopToRub } from '../helpers';
import type {
  BranchBudgetSummaryLineItem,
  BudgetSummary,
  BudgetSummaryStatusType,
  RepairBudget
} from '../types';
import CommonApi from './../commonApi';
import { fetchRequest } from './index';

const convertDataFromServer = (
  budgetSummaryLineItems: BranchBudgetSummaryLineItem
) => {
  return {
    ...budgetSummaryLineItems,
    sum: convertFromKopToRub(budgetSummaryLineItems.sum),
    economyResidue: convertFromKopToRub(budgetSummaryLineItems.economyResidue),
    economy: convertFromKopToRub(budgetSummaryLineItems.economy),
    additionalNeed: convertFromKopToRub(budgetSummaryLineItems.additionalNeed),
    coveredByOpenPeriod: convertFromKopToRub(
      budgetSummaryLineItems.coveredByOpenPeriod
    ),
    coveredByClosedPeriod: convertFromKopToRub(
      budgetSummaryLineItems.coveredByClosedPeriod
    ),
    budgetChange: convertFromKopToRub(budgetSummaryLineItems.budgetChange),
    approvedPlan: convertFromKopToRub(budgetSummaryLineItems.approvedPlan),
    newCalculation: convertFromKopToRub(budgetSummaryLineItems.newCalculation),
    cognosDelta: convertFromKopToRub(budgetSummaryLineItems.cognosDelta),
    months:
      budgetSummaryLineItems.months?.map(month => ({
        ...month,
        plan: convertFromKopToRub(month.plan),
        need: convertFromKopToRub(month.need),
        fact: convertFromKopToRub(month.fact),
        reason: convertFromKopToRub(month.reason),
        economy: convertFromKopToRub(month.economy),
        economyResidue: convertFromKopToRub(month.economyResidue)
      })) ?? [],
    expenseDirections: budgetSummaryLineItems?.expenseDirections?.map(
      expenseDirection => ({
        ...expenseDirection,
        sum: convertFromKopToRub(expenseDirection.sum),
        additionalNeed: convertFromKopToRub(expenseDirection.additionalNeed),
        coveredByOpenPeriod: convertFromKopToRub(
          expenseDirection.coveredByOpenPeriod
        ),
        coveredByClosedPeriod: convertFromKopToRub(
          expenseDirection.coveredByClosedPeriod
        ),
        budgetChange: convertFromKopToRub(expenseDirection.budgetChange),
        approvedPlan: convertFromKopToRub(budgetSummaryLineItems.approvedPlan),
        newCalculation: convertFromKopToRub(
          budgetSummaryLineItems.newCalculation
        ),
        cognosDelta: convertFromKopToRub(expenseDirection.cognosDelta),
        months:
          expenseDirection.months?.map(month => ({
            ...month,
            plan: convertFromKopToRub(month.plan),
            need: convertFromKopToRub(month.need),
            fact: convertFromKopToRub(month.fact),
            reason: convertFromKopToRub(month.reason),
            economy: convertFromKopToRub(month.economy),
            economyResidue: convertFromKopToRub(month.economyResidue)
          })) ?? []
      })
    )
  };
};

const baseUrl = 'budgetSummary';
const budgetSummary = new CommonApi<BudgetSummary>(
  baseUrl,
  'Бюджет свод',
  undefined,
  convertDataFromServer
);

const getExpenseDirections = async (
  id: number,
  type: string
): Promise<RepairBudget> => {
  const expenseDirections = await fetchRequest.get(
    `/${baseUrl}/getExpenseDirections/${id}/${type}`
  );
  if (expenseDirections) return expenseDirections.map(convertDataFromServer);
  throw new Error('Не удалось получить бюджет');
};

const changeStatus = async (data: {
  id: number,
  status: BudgetSummaryStatusType
}): Promise<BudgetSummary> => {
  const updated = await fetchRequest.put(`/${baseUrl}/changeStatus`, data);
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};
// TODO : добавить uodate
export const addClarification = async (
  data: BudgetSummary
): Promise<BudgetSummary> => {
  const added = await fetchRequest.post(`/${baseUrl}/addClarification`, data);
  if (added) return added;
  throw new Error(`Не удалось создать уточнение в бюджет`);
};

export default {
  ...budgetSummary,
  getExpenseDirections,
  changeStatus,
  addClarification
};
