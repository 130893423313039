import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import DatePicker from 'antd/lib/date-picker';
import Menu from 'antd/lib/menu';
import AntIcon from 'antd/lib/icon';

import { incomeScheduleApi } from './../../../lib/api';
import type { IncomeSchedule } from './../../../lib/types';
import {
  withEmptyRow,
  withoutEmptyRow,
  isEmptyValue as isEmpty,
  formatDate,
  formatRub
} from './../../../lib/helpers';

import { ListTable, Dropdown } from '../../../components/ui';
import { notificationLoading } from './../../../components/Notifications';

const FormItem = Form.Item;
type Props = {
  stageId: number,
  incomeSchedules: IncomeSchedule[],
  fetchIncomeSchedules: Function
};
type State = {
  touched: boolean,
  handleSubmitting: boolean,
  incomeSchedules: IncomeSchedule[],
  deleteIncomeSchedule: IncomeSchedule[]
};

const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Footer = styled.div`
  margin-top: 10px;
  .ant-btn {
    margin-right: 10px;
  }
`;

export default class extends React.Component<Props, State> {
  state = {
    touched: false,
    handleSubmitting: false,
    incomeSchedules: this.props.incomeSchedules,
    deleteIncomeSchedules: []
  };

  componentDidUpdate(prevProps) {
    const { incomeSchedules } = this.props;
    if (this.props.stageId !== prevProps.stageId) {
      this.setState({ incomeSchedules });
    }
  }

  async onChange(index: number, key: $Keys<IncomeSchedule>, value: any) {
    let incomeSchedules = withEmptyRow([...this.state.incomeSchedules]);
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    incomeSchedules.splice(index, 1, {
      ...incomeSchedules[index],
      [(key: string)]: value
    });
    await this.setState({ incomeSchedules });
  }

  onDelete(index: number) {
    const { incomeSchedules, deleteIncomeSchedules } = this.state;
    if (this.state.touched === false) {
      this.setState({ touched: true });
    }
    this.setState({
      incomeSchedules: incomeSchedules.filter((value, id) => id !== index),
      deleteIncomeSchedules: [...deleteIncomeSchedules, incomeSchedules[index]]
    });
  }

  saveUpdateIncomeSchedule = async (incomeSchedule: IncomeSchedule) => {
    try {
      if (incomeSchedule.id) {
        return await incomeScheduleApi.updateIncomeSchedule(incomeSchedule);
      } else {
        return await incomeScheduleApi.addIncomeSchedule(incomeSchedule);
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
      return undefined;
    }
  };

  deleteIncomeSchedules = async () => {
    const { deleteIncomeSchedules } = this.state;
    try {
      await deleteIncomeSchedules.forEach(async incomeSchedule => {
        if (incomeSchedule.id) {
          await incomeScheduleApi.deleteIncomeSchedule(incomeSchedule.id);
        }
      });
      this.setState({ deleteIncomeSchedules: [] });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  // проверим заполнены ли все обязательные поля
  validate = (incomeSchedules: Stage[]) => {
    incomeSchedules.forEach((incomeSchedule, index) => {
      [
        'schedulePoint',
        'startDate',
        'endDate',
        'sum',
        'sumWithTax',
        'nomenclatureName',
        'count'
      ].forEach(key => {
        if (isEmpty(incomeSchedule[key])) {
          throw new Error('Заполните все поля в графиках');
        }
      });
    });
  };

  handleCancel = () => {
    this.setState({
      incomeSchedules: this.props.incomeSchedules,
      touched: false,
      handleSubmitting: false
    });
  };

  handleSubmit = async () => {
    const { stageId } = this.props;
    const incomeSchedules = withoutEmptyRow(this.state.incomeSchedules);
    let promises = [];
    this.setState({ handleSubmitting: true });
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      this.validate(incomeSchedules);
      // сохраним график
      incomeSchedules.forEach(incomeSchedule => {
        promises.push(
          this.saveUpdateIncomeSchedule({ ...incomeSchedule, stageId })
        );
      });
      const hasError = await Promise.all(promises).then(incomeSchedules => {
        return incomeSchedules.includes(undefined);
      });
      await this.deleteIncomeSchedules();

      this.props.fetchIncomeSchedules();
      this.setState({
        touched: hasError,
        handleSubmitting: hasError
      });
      if (hasError) {
        notification.error({
          message: 'Ошибка',
          description: 'Не удалось сохранить все этапы'
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  validateRequired(field: string, index: number) {
    const { handleSubmitting, submitting, incomeSchedules } = this.state;
    if (!submitting && handleSubmitting && !isEmpty(incomeSchedules[index])) {
      return isEmpty(incomeSchedules[index][field]) ? 'error' : 'validating';
    }
    return 'validating';
  }

  columns = [
    {
      title: 'Мнемокод',
      key: 'mnemocode',
      dataIndex: 'mnemocode',
      render: (mnemocode: string) => {
        const { readOnly } = this.props;
        return readOnly ? mnemocode : <Input disabled value={mnemocode} />;
      }
    },
    {
      title: 'Наименование номенклатуры',
      style: {
        overflow: 'hidden'
      },
      key: 'nomenclatureName',
      dataIndex: 'nomenclatureName',
      render: (nomenclatureName: string, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          nomenclatureName ?? '-'
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('nomenclatureName', index)}
          >
            <Input
              value={nomenclatureName}
              onChange={e =>
                this.onChange(index, 'nomenclatureName', e.target.value)
              }
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Количество',
      key: 'count',
      dataIndex: 'count',
      render: (count: number, offer: Offer, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          count
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('count', index)}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Количество"
              onChange={count => {
                this.onChange(index, 'count', count);
              }}
              value={count}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Ед. измерения',
      key: 'unit',
      dataIndex: 'unit'
    },
    {
      title: 'Сумма руб. (без НДС)',
      key: 'sum',
      dataIndex: 'sum',
      render: (sum: string, incomeSchedule: IncomeSchedule, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatRub(sum)
        ) : (
          <StyledFormItem validateStatus={this.validateRequired('sum', index)}>
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Цена"
              onChange={value => this.onChange(index, 'sum', value)}
              value={sum}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Сумма руб. (c НДС)',
      key: 'sumWithTax',
      dataIndex: 'sumWithTax',
      render: (
        sumWithTax: string,
        incomeSchedule: IncomeSchedule,
        index: number
      ) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatRub(sumWithTax)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('sumWithTax', index)}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder="Цена"
              onChange={value => this.onChange(index, 'sumWithTax', value)}
              value={sumWithTax}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Дата начала',
      key: 'startDate',
      dataIndex: 'startDate',
      render: (
        startDate: string,
        incomeSchedule: IncomeSchedule,
        index: number
      ) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatDate(startDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('startDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              value={startDate ? moment(startDate) : startDate}
              onChange={async value => {
                await this.onChange(
                  index,
                  'startDate',
                  moment
                    .utc(value)
                    .startOf('day')
                    .toISOString()
                );
                this.onChange(index, 'endDate', undefined);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Дата окончания',
      key: 'endDate',
      dataIndex: 'endDate',
      render: (
        endDate: string,
        incomeSchedule: IncomeSchedule,
        index: number
      ) => {
        const { readOnly } = this.props;
        return readOnly ? (
          formatDate(endDate)
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('endDate', index)}
          >
            <DatePicker
              placeholder="Дата"
              format="DD.MM.YYYY"
              disabled={incomeSchedule.startDate ? false : true}
              disabledDate={(date: string) =>
                incomeSchedule.startDate
                  ? moment
                      .utc(date)
                      .startOf('day')
                      .isSameOrBefore(moment.utc(incomeSchedule.startDate))
                  : false
              }
              value={endDate ? moment(endDate) : endDate}
              onChange={value =>
                this.onChange(
                  index,
                  'endDate',
                  moment
                    .utc(value)
                    .startOf('day')
                    .toISOString()
                )
              }
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Точка графика',
      key: 'schedulePoint',
      dataIndex: 'schedulePoint',
      render: (
        schedulePoint: string,
        incomeSchedule: IncomeSchedule,
        index: number
      ) => {
        const { readOnly } = this.props;
        return readOnly ? (
          schedulePoint
        ) : (
          <StyledFormItem
            validateStatus={this.validateRequired('schedulePoint', index)}
          >
            <Input
              value={schedulePoint}
              onChange={e =>
                this.onChange(index, 'schedulePoint', e.target.value)
              }
            />
          </StyledFormItem>
        );
      }
    },
    {
      width: '20px',
      renderRecord: (incomeSchedule: IncomeSchedule, index: number) => {
        const { readOnly } = this.props;
        return readOnly ? null : !isEmpty(incomeSchedule) ? (
          <Operations key={incomeSchedule.id}>
            <Dropdown
              overlayStyle={{ zIndex: '999' }}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => this.onDelete(index)}>
                    Удалить
                  </Menu.Item>
                </Menu>
              }
            >
              <AntIcon
                style={{ fontSize: 16, color: '#2770FF' }}
                type="ellipsis"
              />
            </Dropdown>
          </Operations>
        ) : null;
      }
    }
  ];

  render() {
    const { readOnly } = this.props;
    const { incomeSchedules, touched } = this.state;
    const data = readOnly
      ? withoutEmptyRow(incomeSchedules)
      : withEmptyRow(incomeSchedules);
    return (
      <>
        <ListTable columns={this.columns} data={data} />
        {touched && (
          <Footer>
            <Button onClick={this.handleSubmit} type="primary">
              Сохранить
            </Button>
            <Button onClick={this.handleCancel}>Отменить</Button>
          </Footer>
        )}
      </>
    );
  }
}
