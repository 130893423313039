// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { ISUBudgetingDataApi } from './../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any };

export default ({
  filter = {},
  ...selectProps
}: Props) => (
  <AutocompleteSelect
    fetch={async params => {
      return await ISUBudgetingDataApi.fetch({
        ...filter,
        ...params
      });
    }}
    refetchParams={{ filter }}
    fetchSingle={ISUBudgetingDataApi.get}
    notFoundText={`Данные не найдены`}
    placeholder={`Выберите данные`}
    renderOption={(ISUBudgeting: any, Option) => (
      <Option
        key={ISUBudgeting.id}
        value={ISUBudgeting.id}
        isubudgeting={ISUBudgeting}
      >
        {ISUBudgeting.name}
      </Option>
    )}
    {...selectProps}
  />
);
