//@flow
import React, {useState} from 'react';
import CommonListPage from '../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {maintenanceOperationApi} from '../../lib/api';
import {accessTypeEnum, newEntityStatuses} from '../../lib/enum';
import {navigate} from '../../lib/helpers';
import type {UserAccess} from '../../lib/types';
import {withUserAccess} from '../withUserAccess';
import {WorkSubTypes, WorkTypes} from '../../lib/types/maintenanceOperation';
import MaintenanceOperationFilter from './components/Filter';
import Popconfirm from 'antd/lib/popconfirm';
import {notificationLoading} from '../../components/Notifications';
import {deleteMaintenanceOperation} from '../../lib/api/maintenanceOperation';
import notification from 'antd/lib/notification';
import Icon from '../../components/ui/Icon';

const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Код работы',
    dataIndex: 'code',
  },
  {
    title: 'Наименование работы',
    dataIndex: 'name',
  },
  {
    title: 'Модель',
    dataIndex: 'vehicleModelMatch.modelName',
  },
  {
    title: 'Марка',
    dataIndex: 'vehicleModelMatch.brandName',
  },
  {
    title: 'Вид ремонта',
    dataIndex: 'repairKind.name',
  },
  {
    title: 'Тип работы',
    dataIndex: 'workType',
    render: type => WorkTypes[type],
  },
  {
    title: 'Подтип работы',
    dataIndex: 'workSubType',
    render: type => WorkSubTypes[type],
  },
  {
    title: 'Норма-часы',
    dataIndex: 'hours',
  },
  {
    title: 'Статус работы',
    dataIndex: 'status',
    render: status => newEntityStatuses[status],
  },
];
type Props = {
  userAccess: UserAccess[]
};
const addAccess = [
  accessTypeEnum.admin,
  accessTypeEnum.changeStatusMaintenanceWork,
  accessTypeEnum.handlingMaintenanceWork,
];

const MaintenanceOperationsTable = ({userAccess}: Props) => {
  const [reFetch, setReFetch] = useState(false);
  const onFetch = async (params: any) => {
    return await maintenanceOperationApi.fetchMaintenanceOperations(params);
  };
  const canAdd = () => userAccess.some(access => addAccess.includes(access));
  const onDelete = async (id) => {
    try {
      notificationLoading({message: 'Удаление записи', key: 'deleting'});
      await deleteMaintenanceOperation(id);
      setReFetch(true);
      notification.success({message: 'Запись удалена'});
    } catch (e) {
      notification.error({message: 'Ошибка удаления', description: e.title || e.message});
    }
    finally {
      notification.close('deleting');
      setReFetch(false);
    }
  };
  
  const access = {
    add: canAdd() ? () => navigate(`/admin/maintenance-operations/new/`) : null,
  };
  
  return (
    <Page>
      <CommonListPage
        pageHeaderProps={{
          mainHeader: 'Работы по ремонту ТС',
          access,
        }}
        tableListProps={{
          columns: canAdd()
            ? [
              ...columns,
              {
                stopPropagation: true,
                key: 'delete',
                dataIndex: 'id',
                render: (id: number) => {
                  return (
                    <Popconfirm
                      placement="topRight"
                      title="Вы действительно хотите удалить запись?"
                      okText="Да"
                      cancelText="Нет"
                      onConfirm={() => onDelete(id)}
                    >
                      <Icon type="x" />
                    </Popconfirm>
                  );
                },
              },
            ]
            : columns,
          onSelect: (id: number) => canAdd() ? navigate(`/admin/maintenance-operations/${id}`) : undefined,
        }}
        oldCrud={{onFetch}}
        filter={MaintenanceOperationFilter}
        reFetch={reFetch}
      />
    </Page>
  );
};

export default withUserAccess(MaintenanceOperationsTable);
