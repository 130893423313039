// @flow
import type {
  CalculationStatus,
  FederalHighwayCalculation,
  VehiclePlan
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import {
  convertDataFromServer as convertDataFromServerMonth,
  convertDataToServer as convertDataToServerMonth
} from './federalHighwayCalculationMonth';
import { fetchRequest, downloadRequestWithToken } from './index';

export const convertDataToServer = (
  federalHighwayCalculation: FederalHighwayCalculation
): FederalHighwayCalculation => ({
  ...federalHighwayCalculation,
  sum: convertFromRubToKop(federalHighwayCalculation.sum),
  months: federalHighwayCalculation.months.map(convertDataToServerMonth)
});

export const convertDataFromServer = (
  federalHighwayCalculation: FederalHighwayCalculation
): FederalHighwayCalculation => ({
  ...federalHighwayCalculation,
  sum: convertFromKopToRub(federalHighwayCalculation.sum),
  months: federalHighwayCalculation.months.map(convertDataFromServerMonth)
});

const basedUrl = 'federalHighwayCalculation';
const federalHighwayCalculation = new CommonApi<FederalHighwayCalculation>(
  basedUrl,
  'расчет проезда по федеральным трассам',
  convertDataToServer,
  convertDataFromServer
);

export const updateCalculations = async (
  vehiclePlanId: number
): Promise<VehiclePlan> => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/updateCalculations/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось отредактировать`);
};

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус`);
};

const print = async (vehiclePlanId: number) => {
  const printed = await downloadRequestWithToken(
    `/${basedUrl}/print?vehiclePlanId=${vehiclePlanId}`
  );
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

export default {
  ...federalHighwayCalculation,
  updateCalculations,
  print,
  changeStatus,
  calculate
};
