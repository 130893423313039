// @flow
import CommonApi from './../commonApi';
import type {CompareDate} from '../types';

const compareDates = new CommonApi<CompareDate>(
  'InventoryCardCompareDate',
  'Сроки сверки ИК'
);

export default { ...compareDates };
