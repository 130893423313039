// @flow
import React from 'react';
import { type SelectProps } from 'antd/lib/select';

import Select from './../../../../components/ui/Select';
import { quarters, quartersEnum } from '../../../../lib/enum';

const { Option } = Select;

type Props = SelectProps;

export default (props: Props) => {
  return (
    <Select allowClear mode="multiple" {...props}>
      {Object.keys(quartersEnum).map(quarter => {
        return (
          <Option key={quarter} value={quartersEnum[quarter]}>
            {quarters[quarter]}
          </Option>
        );
      })}
    </Select>
  );
};
