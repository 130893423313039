// @flow
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import qs from 'query-string';
import React, {Component} from 'react';
import {Section} from '../../../../../components/layout';
import {notificationLoading} from '../../../../../components/Notifications';
import {AntTable, ButtonsRow} from '../../../../../components/ui';
import {fuelsAndOilsCalculationApi, vehiclePlanApi} from '../../../../../lib/api';
import {
  accessTypeEnum,
  calculationStatusEnum,
  fuelTypeEnum,
  fuelTypes,
  vehiclePlanStatusEnum,
} from '../../../../../lib/enum';
import {getListInitialState, plus, toLocalStringRu} from '../../../../../lib/helpers';
import type {CalculationStatus, FuelAndOilCalculation, ListState, UserAccess, VehiclePlan} from '../../../../../lib/types';
import {FuelAndOilCalculationMonth} from '../../../../../lib/types';
import {withUserAccess} from '../../../../withUserAccess';
import {canEditBudget} from '../../accessRight';
import {headerPanel} from '../../components/Common';
import {COGNOS_DELTA, COLUMNS, MONTH} from '../../lib';
import type {ParamsFilter} from '../components/Filter';
import Filter from '../../components/FilterVehicleList';
import {ModalFuelAndOilCalculation} from './FuelsAndOilsModal';
import styled from 'styled-components';
import TotalBlock from '../../Budget/components/TotalBlock';
import {ModalFuelAndOilEditing} from './FuelsAndOilsEditingModal';
import InfoBlock from '../../components/InfoBlock';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FilterWrapper = styled.div`
  padding: 15px 15px 0;
`;

type Props = {
  location: Location & { state: { page: number } },
  vehiclePlanId: number,
  userAccess: UserAccess[]
};

type State = ListState<FuelAndOilCalculation> & {
  columns: any,
  vehiclePlan: VehiclePlan,
  calculationModalVisible: boolean,
  editingModalVisible: boolean,
  rowIndex?: number,
  monthIndex: number,
  data: ?(FuelAndOilCalculation[]),
  dataRow?: FuelAndOilCalculation,
  filter: ParamsFilter,
  totalValues: {
    petrol: number,
    diesel: number,
    oil: number
  },
  cognosData: ?any,
  typeOfModal: ?'fuel' | 'oil'
};

const FuelsAndOils = class extends Component<Props, State> {
  originalData: MaintenanceOperationContractCalculation;

  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({filter}, () => this.getData());
  }

  onCell = (
    fuelAndOilCalculation: FuelAndOilCalculation,
    rowIndex: number,
    monthIndex: number,
    type: 'fuel' | 'oil',
  ) => {
    if (!this.canEdit()) {
      return;
    }
    return {
      onClick: () => {
        const newState = {
          rowIndex,
          dataRow: {...fuelAndOilCalculation},
          monthIndex: monthIndex,
          typeOfModal: type,
        };
        if (
          this.state.vehiclePlan?.status ===
          vehiclePlanStatusEnum.approvementAfterCognos &&
          this.state.vehiclePlan?.fuelAndOilCalculationStatus ===
          calculationStatusEnum.draft &&
          rowIndex !== this.state.data.length - 1
        ) {
          newState.editingModalVisible = true;
        } else {
          newState.calculationModalVisible = true;
        }
        this.setState(newState);
      },
    };
  };

  state = {
    ...getListInitialState(),
    columns: [
      ...COLUMNS,
      {
        title: 'Наименование топлива',
        dataIndex: `months[0].fuel.type`,
        width: '160px',
        render: type => {
          return fuelTypes[type] || '-';
        },
      },
      ...MONTH.map((month, index) => (
        {
          title: month.title,
          children: [
            {
              title: 'Топливо',
              children: [
                {
                  title: 'Кол., л',
                  dataIndex: `months[${index}].fuel.count`,
                  width: '120px',
                  render: (count: number) => {
                    return toLocalStringRu(count) || '-';
                  },
                  onCell: (fuelAndOilCalculation, rowIndex) => {
                    return this.onCell(
                      fuelAndOilCalculation,
                      rowIndex,
                      index,
                      'fuel'
                    );
                  }
                },
                {
                  title: 'Стоимость',
                  dataIndex: `months[${index}].fuel.cost`,
                  width: '120px',
                  render: (cost: number) => {
                    return cost
                      ? toLocalStringRu(cost, { minimumFractionDigits: 2 })
                      : '-';
                  },
                  onCell: (fuelAndOilCalculation, rowIndex) => {
                    return this.onCell(
                      fuelAndOilCalculation,
                      rowIndex,
                      index,
                      'fuel'
                    );
                  }
                }
              ]
            },
            {
              title: 'Масло',
              children: [
                {
                  title: 'Стоимость',
                  dataIndex: `months[${index}].oilsSum`,
                  width: '120px',
                  onCell: (fuelAndOilCalculation, rowIndex) => {
                    return this.onCell(
                      fuelAndOilCalculation,
                      rowIndex,
                      index,
                      'oil'
                    );
                  },
                  render: oilsSum => {
                    const cost = toLocalStringRu(oilsSum, {
                      minimumFractionDigits: 2
                    });
                    return cost ? cost : '-';
                  }
                }
              ]
            }
          ]
        }
      )),
      {
        title: 'Итого, год',
        children: [
          {
            title: 'Топливо',
            children: [
              {
                title: 'Кол., л',
                dataIndex: `fuelCountSum`,
                width: '120px',
                render: (count: number) => {
                  return toLocalStringRu(count) || '-';
                },
              },
              {
                title: 'Стоимость',
                dataIndex: `fuelCostSum`,
                width: '120px',
                render: (cost: number) => {
                  return cost
                    ? toLocalStringRu(cost, {minimumFractionDigits: 2})
                    : '-';
                },
              },
            ],
          },
          {
            title: 'Масло',
            children: [
              {
                title: 'Стоимость',
                dataIndex: `oilsSum`,
                width: '120px',
                render: oilsSum => {
                  const cost = toLocalStringRu(oilsSum, {
                    minimumFractionDigits: 2,
                  });
                  return cost ? cost : '-';
                },
              },
            ],
          },
        ],
      },
    ],
    vehiclePlan: null,
    filter: {},
    calculationModalVisible: false,
    editingModalVisible: false,
    rowIndex: undefined,
    totalValues: {
      petrol: 0,
      diesel: 0,
      oil: 0,
    },
    typeOfModal: null,
  };

  getData = async () => {
    this.setState({loading: true});
    try {
      await this.getVehiclePlan();
      await this.fetch();
    } finally {
      this.setState({loading: false});
    }
  };

  getVehiclePlan = async () => {
    const {vehiclePlanId} = this.props;
    const vehiclePlan = await vehiclePlanApi.get(vehiclePlanId);
    this.setState({vehiclePlan});
  };

  itogCalculation = (data: any) => {
    const itog = {
      months: Array(data[0].months.length)
      .fill()
      .map(() => ({fuel: {count: 0, cost: 0}, oilsSum: 0})),
      sum: 0,
      fuelCountSum: 0,
      fuelCostSum: 0,
      oilsSum: 0,
    };
    data.forEach((item: any) => {
      itog.sum = plus(item.sum || 0, itog.sum);
      itog.fuelCountSum = plus(item.fuelCountSum || 0, itog.fuelCountSum);
      itog.fuelCostSum = plus(item.fuelCostSum || 0, itog.fuelCostSum);
      itog.oilsSum = plus(item.oilsSum || 0, itog.oilsSum);
      item.months.forEach((month: any, index) => {
        itog.months[index].fuel.cost = plus(
          itog.months[index].fuel.cost,
          month.fuel.cost || 0,
        );
        itog.months[index].fuel.count = plus(
          itog.months[index].fuel.count,
          month.fuel.count || 0,
        );
        itog.months[index].oilsSum = plus(
          itog.months[index].oilsSum,
          month.oilsSum || 0,
        );
      });
    });
    return itog;
  };

  getTotal = data => {
    let oil = 0,
      petrol = 0,
      diesel = 0;
    if (data.length) {
      data.forEach((vehicleData: FuelAndOilCalculation) => {
        vehicleData.months.forEach((monthData: FuelAndOilCalculationMonth) => {
          oil += monthData.oilsSum;
          if (
            monthData.fuel.type === fuelTypeEnum.ai80 ||
            monthData.fuel.type === fuelTypeEnum.ai92 ||
            monthData.fuel.type === fuelTypeEnum.ai95
          ) {
            petrol += monthData.fuel.cost;
          } else {
            if (monthData.fuel.type === fuelTypeEnum.dieselFuel) {
              diesel += monthData.fuel.cost;
            }
          }
        });
      });
    }
    return {
      oil: Math.round(oil * 100) / 100,
      petrol: Math.round(petrol * 100) / 100,
      diesel: Math.round(diesel * 100) / 100,
    };
  };

  fetch = async () => {
    const {vehiclePlanId} = this.props;

    try {
      let {data} = await fuelsAndOilsCalculationApi.fetch({
        'selfVehiclePlanVehicle.vehicle.vehicleModel.type': this.state.filter.type,
        'selfVehiclePlanVehicle.vehicle.vehicleModel.id': this.state.filter.vehicleModelId,
        'selfVehiclePlanVehicle.vehicle.id': this.state.filter.vehicleId,
        'selfVehiclePlanVehicle.vehicle.yearIssued': this.state.filter.yearIssued,
        'selfVehiclePlanVehicle.vehicle.hideEmpty': this.state.filter.hideEmpty,
        vehiclePlanId,
        page: undefined,
        pageSize: undefined,
      });
      if (!data) {
        notification.warning({message: 'Не удалось запросить данные'});
        return;
      }
      this.setState({totalValues: {...this.getTotal(data)}, data});
      this.originalData = this.state.data;
    } catch (e) {
      notification.error({message: 'Не удалось запросить данные'});
    }
  };

  handleSave = async (monthData: FuelAndOilCalculationMonth) => {
    const {rowIndex = 0, typeOfModal} = this.state;
    this.setState({loading: true});
    if (typeOfModal === 'fuel') {
      monthData.oils = null;
    }
    try {
      if (monthData && Number.isInteger(rowIndex)) {
        await fuelsAndOilsCalculationApi.updateMonth(monthData);
        await this.fetch();
      }
    } catch (error) {
      notification.warning({
        message: 'Не удалось обновить данные',
        description: error.message,
      });
    } finally {
      this.setState({
        loading: false,
        editingModalVisible: false,
        rowIndex: undefined,
        typeOfModal: null,
      });
    }
  };

  handleCancel = () => {
    this.setState({
      calculationModalVisible: false,
      editingModalVisible: false,
      rowIndex: undefined,
      typeOfModal: null,
    });
  };

  applyFilter = (filter: any) => {
    let noFilter = false;
    Object.values(filter).forEach(value => {
      if (value === undefined) noFilter = true;
    });

    if (noFilter) {
      this.cleanFilter();
      return;
    }

    const filtered = this.originalData
    .filter(selfVehicle =>
      !!filter.vehicleId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleId === selfVehicle.selfVehiclePlanVehicle.vehicleId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.vehicleModelId && selfVehicle.selfVehiclePlanVehicle
        ? filter.vehicleModelId ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModelId
        : true,
    )
    .filter(selfVehicle =>
      !!filter.type && selfVehicle.selfVehiclePlanVehicle
        ? filter.type ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.vehicleModel.type
        : true,
    )
    .filter(selfVehicle =>
      !!filter.yearIssued && selfVehicle.selfVehiclePlanVehicle
        ? filter.yearIssued ===
        selfVehicle.selfVehiclePlanVehicle.vehicle.yearIssued
        : true,
    )
    .filter(selfVehicle => {
      return filter.hideEmpty === true ? selfVehicle.sum !== 0 : true;
    });

    if (filtered.length !== 0) {
      this.setState({data: [...filtered]});
    } else {
      this.cleanFilter();
    }
  };

  cleanFilter = async () => {
    this.setState({filter: {}}, async () => {
      await this.fetch();
    });
  };

  isDraft = () =>
    this.state?.vehiclePlan?.fuelAndOilCalculationStatus ===
    calculationStatusEnum.draft;

  changeStatus = async (status: CalculationStatus) => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await fuelsAndOilsCalculationApi.changeStatus(vehiclePlanId, status);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные данные'});
    } finally {
      notification.close('saving');
    }
  };

  calculate = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Сохранение данных',
        key: 'saving',
      });
      await fuelsAndOilsCalculationApi.calculate(vehiclePlanId);
      this.getData();
    } catch (error) {
      notification.error({message: 'Не удалось обновить данные'});
    } finally {
      notification.close('saving');
    }
  };

  print = async () => {
    const {vehiclePlanId} = this.props;
    try {
      notificationLoading({
        message: 'Расчет данных...',
        key: 'print',
      });
      await fuelsAndOilsCalculationApi.print(vehiclePlanId);
    } catch (error) {
      notification.error({message: 'Не удалось выполнить'});
    } finally {
      notification.close('print');
    }
  };

  canEdit = () =>
    this.props.userAccess.some(access =>
      [
        accessTypeEnum.editingBudgetCopy,
        accessTypeEnum.admin,
        accessTypeEnum.adminBranch,
      ].includes(access),
    );

  render() {
    const {vehiclePlanId} = this.props;
    const {
      columns,
      calculationModalVisible,
      editingModalVisible,
      loading,
      data,
      dataRow,
      monthIndex,
      vehiclePlan,
      cognosData,
      totalValues,
      typeOfModal,
    } = this.state;
    const planApproved = vehiclePlan?.status === vehiclePlanStatusEnum.approved;
    const isCognosStatus =
      vehiclePlan?.status === vehiclePlanStatusEnum.approvedAfterCognos ||
      vehiclePlan?.status === vehiclePlanStatusEnum.approvementAfterCognos;
    const approveButtonIsDisabled = (!isCognosStatus && this.props.userAccess.includes(accessTypeEnum.admin))
      ? false
      : (Math.abs(totalValues.petrol + cognosData?.mechanismPetrolValue - cognosData?.petrolValue,) > COGNOS_DELTA ||
        Math.abs(totalValues.diesel + cognosData?.mechanismDieselValue - cognosData?.dieselValue,
        ) > COGNOS_DELTA ||
        Math.abs(totalValues.oil - cognosData?.oilValue) > COGNOS_DELTA);

    return (
      <>
        {headerPanel({
          vehiclePlanId,
          vehiclePlan,
          title: 'ГСМ',
          passTab: true,
        })}
        <Section>
          <InfoBlock links={{ГСМ: '/admin/fuel-price'}} />
          <FilterWrapper>
            <Filter
              applyFilter={this.applyFilter}
              cleanFilter={this.cleanFilter}
              filter={this.state.filter}
              vehiclePlanId={vehiclePlanId}
              vehicleType={true}
            />
          </FilterWrapper>
          <AntTable
            tableLayout="fixed"
            loading={loading}
            data={[...data]}
            columns={columns}
            bordered
            scroll={{
              x: '8000',
              y: 'calc(100vh - 560px)',
            }}
            pagination={{pageSize: 50}}
            rowClassName={record => record.selfVehiclePlanVehicle?.changed && this.isDraft()
              ? 'table-row-error'
              : null
            }
          />
          <ModalFuelAndOilCalculation
            visible={calculationModalVisible}
            handleCancel={this.handleCancel}
            data={dataRow}
            itogData={!dataRow?.id ? data.filter(row => !!row.id) : undefined}
            monthIndex={monthIndex}
          />
          <ModalFuelAndOilEditing
            visible={editingModalVisible}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            data={dataRow}
            monthIndex={monthIndex}
            type={typeOfModal}
          />
        </Section>
        <Section style={{padding: '16px'}}>
          <Wrapper>
            {isCognosStatus && (
              <TotalBlock
                controllerName="fuelAndOilCalculation"
                vehiclePlanId={vehiclePlanId}
                filteredDataTotalSum={totalValues}
                setCognosData={cognosData => this.setState({cognosData})}
              />
            )}
            <ButtonsRow>
              {canEditBudget(this.props.userAccess) && (
                <>
                  {this.isDraft() && (
                    <>
                      {!isCognosStatus && (
                        <Button type="primary" onClick={this.calculate}>
                          Рассчитать
                        </Button>
                      )}
                      <Button
                        style={{marginLeft: '16px'}}
                        type="primary"
                        onClick={() =>
                          this.changeStatus(
                            calculationStatusEnum.calculationDone,
                          )
                        }
                        disabled={approveButtonIsDisabled}
                        title={
                          approveButtonIsDisabled
                            ? `Итоговые данные и контрольные значения БК не должны различаться больше чем на ${COGNOS_DELTA}`
                            : undefined
                        }
                      >
                        Утвердить
                      </Button>
                    </>
                  )}
                  {!planApproved &&
                    !this.isDraft() &&
                    vehiclePlan?.status !==
                    vehiclePlanStatusEnum.approvedAfterCognos && (
                      <>
                        <Button
                          type="primary"
                          onClick={() =>
                            this.changeStatus(calculationStatusEnum.draft)
                          }
                        >
                          Редактировать
                        </Button>
                      </>
                    )}
                </>
              )}
              <Button style={{marginLeft: '16px'}} onClick={this.print}>
                Печать
              </Button>
            </ButtonsRow>
          </Wrapper>
        </Section>
      </>
    );
  }
};

export default withUserAccess(FuelsAndOils);
