// @flow
import React from 'react';
import {SelectProps} from 'antd/lib/select';
import isEqual from 'lodash/isEqual';



import type {VehicleModel, VehicleModelFilter} from '../../lib/types';
import {vehicleTypes} from '../../lib/enum';
import {vehicleModelApi} from '../../lib/api';
import AutocompleteSelect from './AutocompleteSelect';
import {VehicleOwnerTypesEnum} from '../../lib/types/vehicleModel';

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.filter, nextProps.filter) &&
  prevProps.value === nextProps.value &&
  isEqual(prevProps.search, nextProps.search) &&
  isEqual(prevProps.disabled, nextProps.disabled);

type SelectType = SelectProps & {
  filter?: VehicleModelFilter,
  optimal?: boolean,
  type?: $Keys<VehicleOwnerTypesEnum>,
};

export default React.memo<SelectType>(
  ({filter, type, ...selectProps}: SelectType) => (
    <AutocompleteSelect
      placeholder="Выберите модель ТС"
      fetch={async params =>
        await vehicleModelApi.fetchVehicleModels({
          ...params,
          ...filter,
          vehicleOwnerType: type ? type : VehicleOwnerTypesEnum.self,
        })
      }
      fetchSingle={vehicleModelApi.fetchVehicleModel}
      refetchParams={{filter}}
      renderOption={(model: VehicleModel, Option) => (
        <Option value={model.id} key={model.id} vehicleModel={model}>
          {model.brandName} {model.name} {model.vehicleOwnerType == VehicleOwnerTypesEnum.self ? vehicleTypes[model.type] : ""}
        </Option>
      )}
      {...selectProps}
    />
  ),
  areEqual,
);
