// @flow
import React from 'react';
import styled from 'styled-components';
import {FormikProps} from 'formik';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Button from 'antd/lib/button';

import type {MaintenanceWorkType} from '../../../lib/types';
import type {FormFieldType} from '../../../components/Form';
import Form from '../../../components/Form';
import {Section} from '../../../components/layout';
import Grid, {GridItem} from '../../../components/layout/Grid';
import CancelButton from './../../../components/CancelButton';
import Selects from './../../../components/selects';
import Select from 'antd/lib/select';
import {WorkKindEnum, WorkKinds} from '../../../lib/types/maintenanceWorkType';
import {maintenanceTypeOfWorkEnum} from '../../../lib/enum';

export const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const {MaintenanceTypeOfWorkSelect} = Selects;

const {Option} = Select;

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`;

type Props = {
  maintenanceWorkType: ?MaintenanceWorkType,
  onCancel: Function,
  onSubmit: Function
};

export default ({maintenanceWorkType, onCancel, onSubmit}: Props) => (
  <Form initialValues={maintenanceWorkType} onSubmit={onSubmit}>
    {(
      FormField: FormFieldType,
      {
        setFieldValue,
        handleSubmit,
        values,
        dirty,
        isSubmitting,
      }: FormikProps,
    ) => (
      <form onSubmit={handleSubmit}>
        <StyledSection>
          <Grid gutter="16px">
            <GridItem>
              <FormField label="Наименование" fast name="name" required>
                {field => <Input {...field} />}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Тип работ" fast name="workType" required>
                {({name, value}) => (
                  <MaintenanceTypeOfWorkSelect
                    value={value}
                    onChange={type => {
                      setFieldValue(name, type);
                        setFieldValue(
                          'workKind',
                          type === maintenanceTypeOfWorkEnum.akb || type === maintenanceTypeOfWorkEnum.tire ||
                            type === maintenanceTypeOfWorkEnum.common
                            ? WorkKindEnum.repair
                            : WorkKindEnum.to,
                        );
                    }}
                  />
                )}
              </FormField>
            </GridItem>
            <GridItem>
              <FormField label="Вид работ" fast name="workKind" required>
                {({name, value}) => (
                  <Select
                    value={value}
                    onChange={newValue => setFieldValue(name, newValue)}
                  >
                    {Object.keys(WorkKinds).map(key => (
                      <Option key={key} value={key}>{WorkKinds[key]}</Option>
                    ))}
                  </Select>
                )}
              </FormField>
            </GridItem>
          </Grid>
        </StyledSection>
        <Footer>
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="primary"
            htmlType="submit"
          >
            {values.id ? 'Сохранить' : 'Создать'}
          </Button>
          <CancelButton dirty={dirty} onClick={onCancel}>
            Отменить
          </CancelButton>
        </Footer>
      </form>
    )}
  </Form>
);
