// @flow
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';

import Button from 'antd/lib/button';

import {getPathWithHistoryParams, navigate} from '../../../lib/helpers';
import type {Tire, UserAccess, Vehicle} from '../../../lib/types';
import {accessTypeEnum} from '../../../lib/enum';
import type {AppState} from '../../../ducks/redux';
import {Panel} from './../../../components/layout';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {withUserAccess} from './../../withUserAccess';
import type {Profile} from '../../../ducks/auth';
import {tireApi, vehicleApi} from '../../../lib/api';
import InnerForm from './components/InnerForm';
import {notificationLoading} from '../../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  tireId: number,
  vehicleId?: number,
  userAccess: UserAccess[],
  profile: Profile
};

type State = {
  tire: ?Tire,
  vehicle: ?Vehicle,
  confirmationVisible: boolean,
  loading: boolean,
  isAdmin: boolean
};

class TiresForm extends Component<Props, State> {
  state = {
    tire: null,
    confirmationVisible: false,
    loading: false,
    vehicle: null,
    isAdmin: false
  };

  async componentDidMount() {
    if (this.props.vehicleId) {
      const vehicle = await vehicleApi.fetchVehicle(this.props.vehicleId);
      this.setState({ vehicle });
    }
    if (this.props.tireId) {
      try {
        this.setState({
          loading: true
        });
        const tire = await tireApi.fetchTire(this.props.tireId, 10);
        this.setState({
          tire
        });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/equipment/tires', true);
      } finally {
        this.setState({
          loading: false
        });
      }
    }
    if (this.props.userAccess) {
      this.setState({
        isAdmin: this.props.userAccess.includes(accessTypeEnum.admin)
      });
    }
  }

  onCancel = () => {
    if (this.props.vehicleId) {
      navigate(`/vehicles/${this.props.vehicleId}/equipment`);
    } else if (this.props.tireId) {
      navigate(`/equipment/tires/${this.props.tireId}`);
    } else navigate('/equipment/tires/', true);
  };

  canEditOrgUnit = () =>
    this.props.userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.adminBranch].includes(access)
    );

  addTire = async ({ installDate, normKilometrage, ...tire }: Tire) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'adding'
      });
      /**
       * Мы сначала создаем шину.
       * Затем, если нужно привязать ее, то привязываем
       * и обновляем данные по нормативному пробегу.
       *
       * Можно было и все одним запросом сделать, но так
       * проще понимать, что происходит
       */
      const addedTire = await tireApi.addTire({
        ...tire
      });
      const vehicleId = parseInt(this.props.vehicleId, 10);

      if (addedTire && vehicleId) {
        const tireWithVehicle = await tireApi.setTireVehicle(
          parseInt(addedTire.id, 10),
          vehicleId,
          installDate
        );
        await tireApi.updateTire({
          ...tireWithVehicle,
          normKilometrage
        });
      }
      const tireId = parseInt(addedTire.id, 10);

      notification.success({
        message: 'Успешное добавление',
        description: `Успешно добавлена шина`
      });
      if (this.props.vehicleId) {
        navigate(`/vehicles/${this.props.vehicleId}/equipment`);
      } else {
        navigate(`/equipment/tires/${tireId}`);
      }
    } finally {
      notification.close('adding');
    }
  };

  checkFactoryNumber = async () => {
    try {
      const { tire } = this.state;
      let hasSameFactoryNumber = false;
      if (tire) {
        if (tire.factoryNumber) {
          const existingTires = await tireApi.fetchTires({
            factoryNumber: tire.factoryNumber
          });
          hasSameFactoryNumber =
            existingTires &&
            existingTires.data &&
            existingTires.data.length > 0;
        }
        if (hasSameFactoryNumber) {
          this.setState({
            confirmationVisible: true
          });
        } else {
          await this.addTire(tire);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  onSubmit = async (tire: Tire) => {
    try {
      let tireId = parseInt(tire.id, 10);
      if (tireId > 0) {
        notificationLoading({
          message: 'Сохранение данных...',
          key: 'updating'
        });
        await tireApi.updateTire(tire);
        notification.success({
          message: 'Успешное обновление',
          description: 'Данные были успешно обновлены'
        });
        if (this.props.vehicleId) {
          navigate(`/vehicles/${this.props.vehicleId}/equipment`);
        } else {
          navigate(`/equipment/tires/${tireId}`);
        }
      } else {
        this.setState(
          {
            tire
          },
          this.checkFactoryNumber
        );
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('updating');
    }
  };

  closeConfirmation = () =>
    this.setState({
      confirmationVisible: false
    });

  render() {
    const { profile } = this.props;
    const { tire, loading, confirmationVisible, vehicle, isAdmin } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/equipment/tires')}>
                Шины
              </Crumb>
              {tire && tire.id ? (
                <>
                  <Crumb to={`/equipment/tires/${tire.id}`}>
                    {tire.brand} {tire.name}
                  </Crumb>
                  <Crumb>Редактирование</Crumb>
                </>
              ) : (
                <Crumb>Новая шина</Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{tire && tire.id ? `Шина №${tire.id}` : 'Новая шина'}</h1>
        </StyledPanel>
        <InnerForm
          vehicle={vehicle}
          tire={tire || { orgUnitId: profile.employeeBranchOrgUnitId }}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          canEditOrgUnit={this.canEditOrgUnit()}
          vehicleId={this.props.vehicleId}
          isAdmin={isAdmin}
        />
        {tire && (
          <Modal
            title="Добавление шины"
            visible={confirmationVisible}
            onOk={() => this.addTire(tire)}
            onCancel={this.closeConfirmation}
            footer={[
              <Button key="back" onClick={this.closeConfirmation}>
                Не добавлять
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={() => this.addTire(tire)}
              >
                Добавить
              </Button>
            ]}
          >
            <p>
              В системе найдена шина с заводским номером "{tire.factoryNumber}".
              Вы действительно хотите добавить шину?
            </p>
          </Modal>
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  tireId: parseInt(ownProps.tireId, 10),
  profile: state.auth.profile
}))(withUserAccess(TiresForm));
