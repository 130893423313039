import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import React, { useState } from 'react';

import { Selects } from '../../../../../../../components';
import { Grid, GridItem } from '../../../../../../../components/layout';
import {
  contractVehicleTypeEnum,
  fuelsAndGazEnum
} from '../../../../../../../lib/enum';
import type {
  VehicleTariff,
  VehicleTariffTypesAndModels
} from '../../../../../../../lib/types/vehicleTariff';

const { VehicleTariffByTypeSelect } = Selects;

type Props = {
  visible: boolean,
  onCancel: Function,
  addContractVehicle: Function,
  startDate: string,
  endDate: string
};

const fuelTypeToFuelPriceNameComparator = {
  [fuelsAndGazEnum.gaz]: 'gazPrice',
  [fuelsAndGazEnum.dieselFuel]: 'dieselPrice',
  [fuelsAndGazEnum.ai95]: 'ai95Price',
  [fuelsAndGazEnum.ai92]: 'ai92Price',
  [fuelsAndGazEnum.ai80]: 'ai80Price'
};
export default (props: Props) => {
  const { visible, onCancel, addContractVehicle, startDate, endDate } = props;
  const [contractVehicle, setContractVehicle] = useState(undefined);
  // отдельное хранение id необходимо, так как на сервер нельзя отправлять id
  const [contractVehicleId, setContractVehicleId] = useState(null);

  return (
    <Modal
      destroyOnClose
      centered
      width="40%"
      title="Добавление нового ТС"
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Grid gutter="16px" cols={1}>
        <GridItem>
          <VehicleTariffByTypeSelect
            value={contractVehicleId}
            filter={{
              startDate,
              endDate
            }}
            onChange={(contractVehicleId, option) => {
              const contractVehicle: VehicleTariffTypesAndModels =
                option.props.vehicletarifftype;
              const vehicleTariff: VehicleTariff = option.props.vehicletariff;
              setContractVehicle({
                type: contractVehicleTypeEnum.fixedPlan,
                operatingMode: contractVehicle.operatingMode,
                yearIssued: contractVehicle.productionYear,
                tariffFixed: contractVehicle.tariffFixed,
                tariffDynamic: contractVehicle.tariffDynamic,
                warningNorm: contractVehicle.warmingNorm,
                fuelPrice:
                  vehicleTariff[
                    fuelTypeToFuelPriceNameComparator[contractVehicle.fuelType]
                  ],
                vehicleTypeTariff: contractVehicle.vehicleTypeTariff,
                hasGBO: contractVehicle.hasGas,
                leasing: contractVehicle.leasing,
                contractorId: vehicleTariff.contractorId
              });
              setContractVehicleId(contractVehicle.id);
            }}
          />
        </GridItem>
        <GridItem>
          <Button
            type="primary"
            disabled={!contractVehicle}
            onClick={() => {
              addContractVehicle(contractVehicle);
              setContractVehicle(undefined);
            }}
          >
            Добавить
          </Button>
        </GridItem>
      </Grid>
    </Modal>
  );
};
