// @flow
import queryString from 'query-string';
import { fetchRequest, initialFetchListParams } from './index';
import type { FetchListParams, ListResponse } from './index';
import type { FuelCard, FuelCardVehicle } from '../types';
import type { FuelCardFilterParams } from './../../containers/FuelCards/Filter';
import { convertFromKopToRub, convertFromRubToKop } from './../../lib/helpers';
import { convertDataFromServer as actConvertDataFromServer } from './act';

export const convertDataFromServer = (fuelCard: FuelCard) => ({
  ...fuelCard,
  paymentAmount: convertFromKopToRub(fuelCard.paymentAmount),
  act: fuelCard.act ? actConvertDataFromServer(fuelCard.act) : undefined
});

export const convertDataToServer = (fuelCard: FuelCard) => ({
  ...fuelCard,
  paymentAmount: convertFromRubToKop(fuelCard.paymentAmount)
});

/**
 * Получение списка топливных карт
 */
export const fetchFuelCards = async (
  params: FetchListParams<FuelCardFilterParams> = initialFetchListParams
): Promise<ListResponse<FuelCard>> =>
  await fetchRequest.get('/fuelCard', {
    ...initialFetchListParams,
    ...params
  });

/**
 * Получение истории топливной карты
 */
export const fetchFuelCardHistory = async (
  fuelCardId: number,
  params: FetchListParams<any> = initialFetchListParams
): Promise<ListResponse<FuelCardVehicle>> =>
  await fetchRequest.get(`/fuelCardHistory/${fuelCardId}`, {
    ...initialFetchListParams,
    ...params
  });

/**
 * Получение топливной карты по идентификатору
 */
export const fetchFuelCard = async (
  id: number,
  params: any = {}
): Promise<FuelCard> => {
  const fuelCard = await fetchRequest.get(`/fuelCard/${id}`, params);
  if (fuelCard) return fuelCard;
  throw new Error('Не удалось загрузить топливную карту');
};

/**
 * Удаление топливной карты
 */
export const deleteFuelCard = async (id: number): Promise<FuelCard> => {
  const deleted = await fetchRequest.delete(`/fuelCard/${id}`);
  if (deleted) return deleted;
  throw new Error('Не удалось удалить топливную карту');
};

/**
 * Обновление топливной карты
 */
export const updateFuelCard = async (fuelCard: FuelCard): Promise<FuelCard> => {
  const updated = await fetchRequest.put('/fuelCard', fuelCard);
  if (updated) return updated;
  throw new Error('Не удалось обновить топливную карту');
};

/**
 * Добавление топливной карты
 */
export const addFuelCard = async (fuelCard: FuelCard): Promise<FuelCard> => {
  const added = await fetchRequest.post('/fuelCard', fuelCard);
  if (added) return added;
  throw new Error('Не удалось добавить топливную карту');
};

/**
 * Прикрепление топливной карты к ТС
 */
export const pinFuelCard = async (
  vehicleId: number,
  fuelCardId: number
): Promise<FuelCard> => {
  const pinned = await fetchRequest.post(
    `/fuelCard/${fuelCardId}/${vehicleId}`
  );
  if (pinned) return pinned;
  throw new Error('Не удалось прикрепить топливную карту');
};

export const fetchVehicleFuelCards = async (
  vehicleId: number
): Promise<FuelCard> =>
  await fetchRequest.get(`/fuelCard/${vehicleId}/vehicle`);

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<FuelCard>> => {
  const fuelCard = await fetchRequest.put(
    `/fuelCard/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (fuelCard) {
    return {
      ...fuelCard,
      data: fuelCard.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт за топливными картами');
};

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/fuelCard/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export default {
  getTotalSum,
  updateAct,
  fetchFuelCards,
  fetchFuelCardHistory,
  fetchFuelCard,
  deleteFuelCard,
  updateFuelCard,
  addFuelCard,
  pinFuelCard,
  fetchVehicleFuelCards
};
