// @flow
import React from 'react';

import type { BranchBudgetSummary, BudgetSummary } from '../../../../lib/types';

import { CalculationEconomy, DistributionEconomy, ProposalEconomy } from '.';
import Contracts from '../../summary/components/Contracts/Form';
import {branchBudgetSummaryCognosTypeEnum} from '../../../../lib/enum';

type Props = {
  id: number,
  orgUnitId: number,
  branchBudgetSummary: ?BranchBudgetSummary,
  type?: string,
  setBranchBudgetSummary: Function,
  expandedRows: string[],
  budgetSummary: ?BudgetSummary,
  onSubmit: Function,
  source?: $Keys<branchBudgetSummaryCognosTypeEnum>,
};
export default (props: Props) => {
  const {
    id,
    orgUnitId,
    branchBudgetSummary,
    setBranchBudgetSummary,
    expandedRows,
    budgetSummary,
    onSubmit,
    source,
  } = props;

  const getComponents = () => {
    switch (props.type) {
      case 'distribution-economy':
        return (
          <DistributionEconomy
            id={id}
            orgUnitId={orgUnitId}
            expandedRows={expandedRows}
            branchBudgetSummary={branchBudgetSummary}
            setBranchBudgetSummary={setBranchBudgetSummary}
            source={props.source}
          />
        );
      case 'proposal-economy':
        return (
          <ProposalEconomy
            id={id}
            orgUnitId={orgUnitId}
            expandedRows={expandedRows}
            branchBudgetSummary={branchBudgetSummary}
            setBranchBudgetSummary={setBranchBudgetSummary}
            source={props.source}
          />
        );
      case 'contracts':
        return (
          <Contracts
            id={id}
            orgUnitId={orgUnitId}
            budgetSummary={budgetSummary}
            branchBudgetSummary={branchBudgetSummary}
            source={source}
          />
        );
      default:
        return (
          <CalculationEconomy
            id={id}
            orgUnitId={orgUnitId}
            expandedRows={expandedRows}
            branchBudgetSummary={branchBudgetSummary}
            budgetSummary={budgetSummary}
            setBranchBudgetSummary={setBranchBudgetSummary}
            onSubmit={onSubmit}
            source={props.source}
          />
        );
    }
  };

  return getComponents();
};
