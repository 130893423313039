// @flow
import React from 'react';
import { SelectProps } from 'antd/lib/select';

import { employeeApi } from '../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';

export default (props: SelectProps) => (
  <AutocompleteSelect
    placeholder="Выберите должность"
    fetch={employeeApi.fetchSimpleEmployeesPositions}
    fetchSingle={employeeApi.fetchEmployee}
    notFoundText="Должность не найдена"
    renderOption={(position: string, Option) => (
      <Option value={position} key={position} position={position}>
        {position}
      </Option>
    )}
    {...props}
  />
);
