// @flow
import React, {memo, useCallback, useState} from 'react';
import styled from 'styled-components';
import {navigate} from '../../lib/helpers';
import {fetchRequest} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import {getBreadCrumbsByUrlForEdit} from '../../lib/autoBreadcrumbs';
import {Spin} from 'antd';
import InnerTable from './components/InnerTable';

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

const entryPointPath = '/admin/actual-fuel-consumption';

const Report = props => {
  const { id } = props;
  const [data, setData] = useState({ id });
  const [isLoading, setIsLoading] = useState(false);
  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);
  const onFetch = useCallback(async () => {
    if (!!id) {
      setIsLoading(true);
      setData(await fetchRequest.get(`/FactFuelConsumption/${data.id}`));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  return (
    <CommonCardPage
      pageHeaderProps={{
        breadCrumbs,
        mainHeader: 'Фактический расход ГСМ'
      }}
      onFetch={onFetch}
      onCancel={onCancel}
    >
      {isLoading ? (
        <Preloader>
          <Spin size={'large'} />
        </Preloader>
      ) : (
        <InnerTable
          data={data}
          useFooter
          noWrapMe
          setData={newData => setData(newData)}
        />
      )}
    </CommonCardPage>
  );
};

export default memo(Report);
