// @flow
import React from 'react';
import {Input} from 'antd';

import CommonForm, {type CommonFormProps} from '../../../components/hoc/common/handbook/CommonForm';
import type {ISUBudgetingDataType} from '../../../lib/types';
import Grid, {GridItem} from '../../../components/layout/Grid';
import {FormField} from '../../../components/Form';

export default (props: CommonFormProps<$Shape<ISUBudgetingDataType>>) => {
  return (
    <CommonForm {...props}>
      {({setFieldValue}) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Название" fast required name="name">
                  {({name, value}) => <Input value={value} onChange={e => setFieldValue(name, e.target.value)} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Статья калькуляции" fast required name="itemOfCalculationName">
                  {({name, value}) => <Input value={value} onChange={e => setFieldValue(name, e.target.value)} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Особая отметка" fast required name="specialMarkName">
                  {({name, value}) => <Input value={value} onChange={e => setFieldValue(name, e.target.value)} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Вид движения" fast required name="typeOfMovementByElementName">
                  {({name, value}) => <Input value={value} onChange={e => setFieldValue(name, e.target.value)} />}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
