// @flow
import CommonApi from './../commonApi';
import type { InspectionGibddCost } from '../types';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';

const convertDataToServer = (inspectionGibddCost: InspectionGibddCost) => {
  return {
    ...inspectionGibddCost,
    cost: convertFromRubToKop(inspectionGibddCost.cost),
    inspectionGibddCostInfos:
      inspectionGibddCost?.inspectionGibddCostInfos?.map(vehicleType => ({
        vehicleType
      })) ?? []
  };
};

const convertDataFromServer = (inspectionGibddCost: InspectionGibddCost) => {
  return {
    ...inspectionGibddCost,
    cost: convertFromKopToRub(inspectionGibddCost.cost),
    inspectionGibddCostInfos:
      inspectionGibddCost?.inspectionGibddCostInfos?.map(
        inspectionGibddCost => inspectionGibddCost.vehicleType
      ) ?? []
  };
};

const inspectionGibddCost = new CommonApi<InspectionGibddCost>(
  'inspectionGibddCost',
  'Стоимость ТО ГИБДД',
  convertDataToServer,
  convertDataFromServer
);

export default {
  ...inspectionGibddCost
};
