// @flow
import 'whatwg-fetch';

import type { Credentials } from './../types';

import { apiUrl } from './index';

export const login = async (credentials: Credentials): Promise<void> => {
  const response = await window.fetch(`${apiUrl}/auth/token`, {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  if (response.ok) {
    return await response.json();
  } else {
    if (response.status === 400 || response.status === 401) {
      throw new Error('Invalid credentials');
    } else {
      throw new Error('Server error');
    }
  }
};

export default { login };
