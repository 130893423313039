// @flow
import React from 'react';

import {navigate} from '../../../lib/helpers';
import {budgetAnalysisApi} from '../../../lib/api';
import CommonListPage from '../../../components/hoc/common/handbook/ListPage';
import Page from '../../../components/hoc/common/handbook/PageContext';
import {withUserAccess} from '../../withUserAccess';
import Filter from './components/Filter';

import {canAdd} from './accessRight';

const entryPointPath = '/budget/budget-analysis/';
const columns = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Филиал',
    dataIndex: 'mainBudget.orgUnitName'
  },
  {
    title: 'Расчетный годовой бюджет',
    dataIndex: 'mainBudget.name'
  },
  {
    title: 'Бюджет БК',
    dataIndex: 'budgetBk.name'
  },
  {
    title: 'Бюджет БКФ',
    dataIndex: 'budgetBkf.name'
  }
];

export default withUserAccess(({ userAccess }) => {
  const access = {
    add: canAdd(userAccess) ? () => navigate(`${entryPointPath}new`) : undefined
  };
  return (
    <Page>
      <CommonListPage
        crud={budgetAnalysisApi}
        pageHeaderProps={{
          mainHeader: 'Анализ затрат',
          access
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}${id}`)
        }}
        filter={Filter}
      />
    </Page>
  );
});
