// @flow
import React from 'react';
import Input from 'antd/lib/input';

import {
  Filter,
  FilterButtonsContainer,
  FilterContainer,
  FilterItem
} from './../../../components';
import type { FilterChildrenParams } from './../../../components/hoc/Filter';
import Field from './../../../components/card/Field';
import { SectionContent } from '../../../components/hoc/common/components/elements';
import type { MaintenanceTypeOfWorkType } from '../../../lib/types';
import Selects from '../../../components/selects';
import styled from 'styled-components';

let { MaintenanceTypeOfWorkSelect, YesNoSelect } = Selects;

YesNoSelect = styled(YesNoSelect)`
  min-width: 200px;
`;

export type MaintenanceWorkTypeFilterParams = {
  workType?: MaintenanceTypeOfWorkType,
  search?: string,
  isArchive?: boolean
};

type Props = {
  filter: MaintenanceWorkTypeFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const StockPartFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <SectionContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({
          values,
          changeValue,
          applyFilter,
          cleanFilter,
          showAdditional,
          handleAdditional
        }: FilterChildrenParams<MaintenanceWorkTypeFilterParams>) => (
          <>
            <FilterItem>
              <Field mBottomNone>
                <Input
                  placeholder="Название"
                  size="small"
                  onChange={e =>
                    changeValue('search', e.target.value || undefined)
                  }
                  value={values.search}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <MaintenanceTypeOfWorkSelect
                  placeholder="Вид работ"
                  size="small"
                  onChange={value => changeValue('workType', value)}
                  value={values.workType}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone>
                <YesNoSelect
                  placeholder="Признак архива"
                  size="small"
                  onChange={value => changeValue('isArchive', value)}
                  value={values['isArchive']}
                  reset
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SectionContent>
);

export default StockPartFilter;
