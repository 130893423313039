// @flow
import Modal from 'antd/lib/modal';
import React from 'react';
import type { TireCalculation } from '../../../../../lib/types';
import { Form } from '../../../../../components';
import Button from 'antd/lib/button';
import { Grid, GridItem } from '../../../../../components/layout';
import {
  monthsNamesTranslitEnum,
  monthsNumbersEnum
} from '../../../../../lib/enum';
import InputNumber from 'antd/lib/input-number';
import { TireDetails } from '../../../../../lib/types/tireCalculation';
import moment from 'moment';

type Props = {
  visible: boolean,
  handleCancel: () => void,
  handleSave: () => void,
  data: TireCalculation,
  monthIndex: number
};

export const TiresEditingModal = ({
  visible,
  handleCancel,
  handleSave,
  data,
  monthIndex
}: Props) => {
  const selectedMonth = data?.months[monthIndex];
  const initialValues = {
    month: selectedMonth,
    monthName: monthsNamesTranslitEnum[monthsNumbersEnum[selectedMonth?.month]],
    vehicleData: `${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.brandName}
		${data?.selfVehiclePlanVehicle?.vehicle?.vehicleModel?.name}`
  };
  return (
    <Modal
      destroyOnClose
      width={600}
      title="Редактирование"
      visible={visible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        initialValues={initialValues}
        onSubmit={data => handleSave(data.month)}
      >
        {(FormField, formikProps) => {
          const { handleSubmit, setFieldValue, values } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <Grid gutter="16px">
                <GridItem>
                  <FormField label="Месяц" name="monthName">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField label="Марка и модель ТС" name="vehicleData">
                    {({ value }) => value || '-'}
                  </FormField>
                </GridItem>
              </Grid>
              {values?.month?.details &&
                values.month.details.map(
                  (tireData: TireDetails, index: number) => (
                    <Grid key={index}>
                      <GridItem fullWidth>
                        <p
                          style={{
                            color: '#3d4042',
                            margin: '15px 0',
                            fontWeight: 'bold'
                          }}
                        >
                          {tireData.name}
                        </p>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Мнемокод шины"
                          name={`month.details[${index}].code`}
                        >
                          {({ value }) => value || '-'}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата установки на ТС"
                          name={`month.details[${index}].replaceDate`}
                        >
                          {({ value }) =>
                            moment(value).format('DD.MM.YYYY') || '-'
                          }
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Стоимость одной замены"
                          name={`month.details[${index}].costForOneTire`}
                        >
                          {({ value }) => value}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Количество шин, подлежащих замене"
                          name={`month.details[${index}].count`}
                        >
                          {({ value, name }) => (
                            <InputNumber
                              value={value}
                              onChange={value => setFieldValue(name, value)}
                              min={0}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </Grid>
                  )
                )}
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};
