// @flow

import notification from 'antd/lib/notification';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import moment from 'moment';
import qs from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import type { AppState } from '../../ducks/redux';
import { getPathWithHistoryParams, navigate } from '../../lib/helpers';
import type { BusinessDay, Company, Order } from '../../lib/types';
import InnerForm from './components/InnerForm';

import { Spin } from 'antd';
import type {
  UserAccess,
} from '../../../lib/types';
import { notificationLoading } from '../../components/Notifications';
import Breadcrumbs, { Crumb } from '../../components/layout/Breadcrumbs';
import Header from '../../components/layout/Header';
import type { Profile } from '../../ducks/auth';
import { businessDayApi, orderApi, orderGpmApi, routeApi } from '../../lib/api';
import { businessDayTypeEnum, orderStatusEnum } from '../../lib/enum';
import { accessTypeEnum } from '../../lib/enum/userAccess';
import type { FreeContractVehicle } from '../../lib/types/contractVehicle';
import { Panel } from './../../components/layout';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

const Preloader = styled.div`
  height: 80vh;
  width: 90vw;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
`;

type Props = {
  order: Order,
  profile: Profile,
  orgUnitId: ?number,
  locations: Array<Company>,
  orderId: ?number,
  copyOrderId?: number,
  userAccess: UserAccess[],
};

type State = {
  order: ?Order,
  freeContractVehicles: FreeContractVehicle[],
  businessCalendar: BusinessDay[]
};

class OrderForm extends Component<Props, State> {
  state = {
    freeContractVehicles: [],
    businessCalendar: [],
    order: null
  };

  async componentDidMount() {
    const { orderId, copyOrderId } = this.props;
    await this.fetchBusinessCalendar();
    try {
      if (orderId) {
        await this.fetchOrder(orderId);
      } else if (copyOrderId) {
        notificationLoading({
          message: 'Идет копирование заявки...',
          key: 'copyLoading'
        });
        const { date } = qs.parse(window.location.search);
        await this.copyOrder(copyOrderId, new Date(date));
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('copyLoading');
    }
  }

  fetchOrder = async (orderId: number) => {
    const order: Order = await orderApi.fetchOrder(orderId);
    const route = await routeApi.fetchRoute(order.routeId);

    this.setState({
      order: {
        ...order,
        route: {
          ...route,
          waypoints: orderBy(
            route.waypoints,
            ['arrivedDateTime', 'departureDateTime'],
            ['asc', 'asc']
          )
        }
      }
    });
  };

  copyOrder = async (orderId: number, date: Date) => {
    const order = await orderApi.fetchOrder(orderId);
    const copy = pick(order, [
      'type',
      'objective',
      'employeeId',
      'notation',
      'isBusinessTrip',
      'businessTripOrderNumber',
      'businessTripDecreeNumber',
      'vehicleType',
      'vehicleGroup',
      'workersCount',
      'withTrailer',
      'route.waypoints',
      // ГПМ поля
      'isGpm',
      'mainEngineerId',
      'gpmOwnerId',
      'vehicleModelId',
      'natureOfWork',
      'object',
      'safetyResponsibles',
      'riggers',
      'secondaryRiggers',
      'project',
      'powerLinesInfo',
      'voltage',
      'vhDistance',
      'safetyZone',
      'permitInfo',
      'admission'
    ]);

    this.setState({
      order: {
        ...copy,
        route: {
          waypoints: order.route?.waypoints
            ? order.route.waypoints.map(waypoint => {
                const month = moment(date).month();
                const day = moment(date).date();
                const year = moment(date).year();
                // Заменяем день, год и месяц
                waypoint.arrivedDateTime = moment
                  .utc(waypoint.arrivedDateTime)
                  .set({ month, date: day, year })
                  .toISOString();
                waypoint.departureDateTime = moment
                  .utc(waypoint.departureDateTime)
                  .set({ month, date: day, year })
                  .toISOString();
                return omit(waypoint, 'id');
              })
            : []
        }
      }
    });
  };

  addOrder = async (order: Order) => {
    const addedRoute = await routeApi.addRoute(order.route);
    const newOrder = {
      ...order,
      routeId: addedRoute.id
    };
    const addedOrder = order.isGpm
      ? await orderGpmApi.addGpmOrder(newOrder)
      : await orderApi.addOrder(newOrder);
    this.setState(
      { order: addedOrder },
      () => this.state.order && navigate(`/orders/${this.state.order.id}/card`)
    );
  };

  updateOrder = async (order: Order) => {
    const route = await routeApi.updateRoute(order.route);
    const updatedOrder: Order = order.isGpm
      ? await orderGpmApi.updateGpmOrder(order)
      : await orderApi.updateOrder(order);
    const newOrder = {
      ...updatedOrder,
      route
    };
    this.setState(
      { order: newOrder },
      () => this.state.order && navigate(`/orders/${this.state.order.id}/card`)
    );
  };

  submitForm = async (values: Order) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      let status;
      if (this.state.order?.status === orderStatusEnum.onReworkGPM) {
        status = orderStatusEnum.approvingByMainEngineer;
      } else if (this.state.order?.status === orderStatusEnum.onReworkVehicle) {
        status = this.state.order?.isGpm
          ? orderStatusEnum.approvedByMainEngineer
          : orderStatusEnum.created;
      } else {
        status = this.state.order?.status;
      }
      if (this.props.orderId) {
        await this.updateOrder({ ...values, status });
      } else {
        await this.addOrder(values);
      }

      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  redirect = () => {
    if (this.props.orderId) {
      navigate(`/orders/${this.props.orderId}/card`);
    } else navigate('/orders', true);
  };

  /**
   * Ограничение в количестве часов,
   * на которые мы можем задать маршрут
   */
  getOffsetHours = (isBusinessTrip: boolean): number => {
    if (isBusinessTrip) {
      return -1 * moment().diff(moment().add(-1, 'year'), 'hours');
    } else {
      // Вычисляем количество выходных дней перед текущим днем + 1 день - последний рабочий день перед выходными
      // на все эти даты должна быть возможность оформить заявку
      let countDaysOff = 0;
      const filteredCalendar = this.state.businessCalendar.filter(
        (day: BusinessDay) =>
          moment(day.date).isBetween(moment().subtract(2, 'months'), moment())
      );
      // Функция проверяет, является ли переданная дата выходным днем. Если это так,
      // увеличивается счетчик выходных дней и рекурсивно вызывается функция с датой, предыдущей переданной
      const dayIsDayOff = (date: string) => {
        const dateIsDayOff = filteredCalendar.some(day => {
          return (
            day.date === date && day.dayType === businessDayTypeEnum.dayOff
          );
        });
        if (dateIsDayOff) {
          countDaysOff += 1;
          dayIsDayOff(
            moment
              .utc()
              .startOf('day')
              .subtract(1 + countDaysOff, 'days')
              .format()
          );
        }
      };
      dayIsDayOff(
        moment
          .utc()
          .startOf('day')
          .subtract(1, 'days')
          .format()
      );
      // Один предыдущий день всегда открыт для выбора в заявке. Если были выходные, то ссумируем их с одним предыдущим
      return -(countDaysOff + 1) * 24;
    }
  };

  fetchBusinessCalendar = async () => {
    try {
      const {
        data: businessCalendar
      } = await businessDayApi.fetchBusinessDays({ pageSize: 0 });
      this.setState({ businessCalendar });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  render() {
    const { profile, orderId, userAccess } = this.props;
    const { freeContractVehicles, businessCalendar, order } = this.state;
    const canEditType = userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.adminBranch, accessTypeEnum.changingTypeOfVehicleGpmOrder].includes(access),
    );

    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/orders')}>Заявки</Crumb>
              <Crumb to="/orders/new">Новая заявка</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{orderId ? `Заявка №${orderId}` : 'Новая заявка'}</h1>
        </StyledPanel>
        {order || !orderId ? (
          <InnerForm
            freeContractVehicles={freeContractVehicles}
            order={order}
            profile={profile}
            getOffsetHours={this.getOffsetHours}
            onCancel={this.redirect}
            businessCalendar={businessCalendar}
            onSubmit={this.submitForm}
            allGpmDisabled={
              order?.status === orderStatusEnum.onReworkVehicle && order?.isGpm
            }
            canEditType={canEditType}
          />
        ) : (
          <Preloader>
            <Spin size={'large'} />
          </Preloader>
        )}
      </>
    );
  }
}

export default connect((state: AppState, ownProps: Props) => ({
  orderId: parseInt(ownProps.orderId),
  copyOrderId: parseInt(ownProps.copyOrderId),
  profile: state.auth.profile,
  orgUnitId: parseInt(ownProps.orgUnitId, 10),
  userAccess: state.auth.profile.access,
}))(OrderForm);
