// @flow
import React, {Component} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import sum from 'lodash/sum';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import Menu from 'antd/lib/menu';
import Popconfirm from 'antd/lib/popconfirm';
import Icon from 'antd/lib/icon';

import type {
  ListState,
  MonthlyWashingPlan,
  MonthlyWashingPlanStatus,
  UserAccess,
  WashingPlanVehicle,
  WashingPlanVehicleSummary,
} from '../../../lib/types';
import {monthlyWashingPlanApi} from '../../../lib/api';
import {monthlyWashingPlanStatusEnum, monthlyWashingPlanStatuses} from './../../../lib/enum';
import {
  convertContractorToString,
  formatDateTimeToString,
  formatRub,
  getListInitialState,
  getPathWithHistoryParams,
  minus,
  multipliedBy,
  navigate,
  plus,
} from './../../../lib/helpers';

import {Panel, Section, SectionTitle} from './../../../components/layout';
import Grid, {GridItem} from './../../../components/layout/Grid';
import type {DropdownType} from './../../../components/ui/Dropdown';
import {Card} from './../../../components';
import Header from '../../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../../components/layout/Breadcrumbs';
import {ButtonOperations, Dropdown, Table} from './../../../components/ui';
import {calculateSummaryPlan} from './lib';
import {notificationLoading} from './../../../components/Notifications';
import TalonPopover from './components/TalonPopover';
import type {MounthlyWashingPlansFilterParams} from './components/Filter';
import MonthlyWashingPlanFilter from './components/Filter';

import {withUserAccess} from './../../withUserAccess';
import {addPlanAccessRight, approvePlanAccessRight, formatPlanAccessRight, talonPlanAccessRight} from './accessRight';

const StyledPanel = styled(Panel)`
  padding-top: 0;

  h1 {
    margin-bottom: 24px;
  }
`;

const { Field } = Card;

type Props = {
  monthlyWashingPlanId: number,
  userAccess: UserAccess[]
};

type State = {
  monthlyWashingPlan: ?MonthlyWashingPlan,
  washingPlanVehicles: ListState<WashingPlanVehicle>,
  filters: MounthlyWashingPlansFilterParams,
  washingPlanVehicleSummary: WashingPlanVehicleSummary
};

class MonthlyWashingPlanForm extends Component<Props, State> {
  state = {
    washingPlanVehicles: getListInitialState(),
    monthlyWashingPlan: null,
    washingPlanVehicleSummary: calculateSummaryPlan([]),
    filters: {}
  };

  fetchMonthlyWashingPlan = async () => {
    const { monthlyWashingPlanId } = this.props;
    if (monthlyWashingPlanId) {
      try {
        const monthlyWashingPlan = await monthlyWashingPlanApi.fetchMonthlyWashingPlan(
          monthlyWashingPlanId
        );
        await this.fetchWashingPlanVehicles();
        this.setState({ monthlyWashingPlan });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
        navigate('/budget/monthly-washing-plans', true);
      }
    }
  };

  async componentDidMount() {
    await this.fetchMonthlyWashingPlan();
    this.addColumn();
  }

  dropdown: ?DropdownType;

  fetchWashingPlanVehicles = async (page: number = 1, params: any) => {
    try {
      const { monthlyWashingPlanId } = this.props;
      this.updateWashingPlanVehicles({ loading: true });
      const {
        data,
        totalCount
      } = await monthlyWashingPlanApi.fetchAllWashingPlanVehicles({
        ...this.state.filters,
        page,
        ...params,
        washingPlanId: monthlyWashingPlanId
      });
      const washingPlanVehicleSummary = calculateSummaryPlan(data);
      this.setState({
        washingPlanVehicleSummary
      });
      this.updateWashingPlanVehicles({ data, totalCount, page });
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    } finally {
      this.updateWashingPlanVehicles({ loading: false });
    }
  };

  updateWashingPlanVehicles = (newValues: any, cb?: () => any) =>
    this.setState(
      (prevState: State) => ({
        washingPlanVehicles: {
          ...prevState.washingPlanVehicles,
          ...newValues
        }
      }),
      cb
    );

  columns = [
    {
      title: 'Гос. номер',
      dataIndex: 'vehicle.licensePlate'
    },
    {
      title: 'Марка',
      dataIndex: 'vehicle.vehicleModel.brandName'
    },
    {
      title: 'Модель',
      dataIndex: 'vehicle.vehicleModel.name'
    },
    {
      title: 'Наружная',
      dataIndex: 'bodyCount',
      render: (bodyCount: ?number, record: WashingPlanVehicle) => {
        const count = bodyCount ? bodyCount : 0;
        const factCount = record.factBodyCount ? record.factBodyCount : 0;
        return `${count} /  ${factCount} (${count - factCount})`;
      }
    },
    {
      title: 'Салон',
      dataIndex: 'interiorCount',
      render: (interiorCount: ?number, record: WashingPlanVehicle) => {
        const count = interiorCount ? interiorCount : 0;
        const factCount = record.factInteriorCount
          ? record.factInteriorCount
          : 0;
        return `${count} /  ${factCount} (${count - factCount})`;
      }
    },
    {
      title: 'ДВС',
      dataIndex: 'engineCount',
      render: (engineCount: ?number, record: WashingPlanVehicle) => {
        const count = engineCount ? engineCount : 0;
        const factCount = record.factEngineCount ? record.factEngineCount : 0;
        return `${count} /  ${factCount} (${count - factCount})`;
      }
    },
    {
      title: 'Сумма',
      dataIndex: 'bodyPrice',
      render: (bodyPrice: ?number, record: WashingPlanVehicle) => (
        <>
          {plus(
            multipliedBy(
              parseFloat(record.bodyPrice),
              parseInt(record.bodyCount, 10)
            ) || 0,
            plus(
              multipliedBy(
                parseFloat(record.interiorPrice),
                parseInt(record.interiorCount, 10)
              ) || 0,
              multipliedBy(
                parseFloat(record.enginePrice),
                parseInt(record.engineCount, 10)
              ) || 0
            )
          ).toLocaleString('ru-RU')}
          &nbsp;&#8381;
        </>
      )
    },
    {
      title: 'Подразделение',
      dataIndex: 'vehicle.orgUnitName'
    }
  ];

  /*
   * Добавляем факт от водителя если статус в работе
   * и пользователь имеет доступ
   */
  addColumn = () => {
    const { monthlyWashingPlan } = this.state;
    if (
      (this.accessRight('add') || this.accessRight('talon')) &&
      monthlyWashingPlan &&
      monthlyWashingPlan.status === monthlyWashingPlanStatusEnum.inWork
    ) {
      this.columns.push({
        title: '',
        width: '30px',
        render: (record: any) => {
          const date = this.state.monthlyWashingPlan
            ? this.state.monthlyWashingPlan.date
            : null;
          return (
            date && (
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <TalonPopover
                        fetchMonthlyWashingPlan={this.fetchMonthlyWashingPlan}
                        washingPlanVehicle={{ ...record, date }}
                      />
                    </Menu.Item>
                  </Menu>
                }
              >
                <Icon
                  style={{ fontSize: 16, color: '#2770FF' }}
                  type="ellipsis"
                />
              </Dropdown>
            )
          );
        }
      });
      this.forceUpdate();
    }
  };

  deleteMonthlyWashingPlan = async () => {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await monthlyWashingPlanApi.deleteMonthlyWashingPlan(
        parseInt(this.props.monthlyWashingPlanId, 10)
      );
      navigate('/budget/monthly-washing-plans', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  };

  handlePrint = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing'
      });
      await monthlyWashingPlanApi.print(this.props.monthlyWashingPlanId);
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  handlePrintVehiclesWashings = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла для печати...',
        key: 'printing'
      });
      await monthlyWashingPlanApi.printVehiclesWashings(
        this.props.monthlyWashingPlanId,
        this.state.filters
      );
    } catch (error) {
      notification.error({
        message: 'Ошибка при получении файла',
        description: error.message
      });
    } finally {
      notification.close('printing');
    }
  };

  renderSummary = () => {
    const { washingPlanVehicleSummary } = this.state;
    const {
      bodyCount,
      interiorCount,
      engineCount,
      totalPrice
    } = washingPlanVehicleSummary;
    return (
      <strong>
        Итого: наружняя - {bodyCount} шт., салон - {interiorCount} шт., ДВС -{' '}
        {engineCount} шт., сумма - {totalPrice.toLocaleString('ru-RU')} &#8381;
      </strong>
    );
  };

  changeStatus = async (status: MonthlyWashingPlanStatus) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const { monthlyWashingPlan } = this.state;
      if (monthlyWashingPlan) {
        const updated = await monthlyWashingPlanApi.updateMonthlyWashingPlan({
          ...monthlyWashingPlan,
          status
        });
        this.setState(
          {
            monthlyWashingPlan: updated
          },
          this.addColumn
        );
      }
    } catch (err) {
      notification.error({
        message: 'Ошибка',
        description: err && err.message
      });
    } finally {
      notification.close('saving');
    }
  };

  toQueryStringVehicleFilter = (filters: MounthlyWashingPlansFilterParams) => {
    return Object.keys(filters).reduce(
      (obj, key) => ({
        ...obj,
        [`vehicle.${key}`]: filters[key]
      }),
      {}
    );
  };

  accessRight = (access: string = 'add'): boolean => {
    const { userAccess } = this.props;
    switch (access) {
      case 'add':
        return userAccess.some(access => addPlanAccessRight.includes(access));
      case 'approve':
        return userAccess.some(access =>
          approvePlanAccessRight.includes(access)
        );
      case 'format':
        return userAccess.some(access =>
          formatPlanAccessRight.includes(access)
        );
      case 'talon':
        return userAccess.some(access => talonPlanAccessRight.includes(access));
      default:
        break;
    }
    return false;
  };

  render() {
    const { monthlyWashingPlan, washingPlanVehicles } = this.state;

    const { monthlyWashingPlanId } = this.props;
    if (!monthlyWashingPlan) return null;
    const { data, loading, page, totalCount, pageSize } = washingPlanVehicles;
    const monthName = capitalize(
      formatDateTimeToString(moment(monthlyWashingPlan.date), 'MMMM YYYY')
    );
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams('/budget/monthly-washing-plans')}
              >
                Список планов моек
              </Crumb>
              {monthlyWashingPlan && <Crumb>План моек на {monthName}</Crumb>}
            </Breadcrumbs>
          }
          right={
            <ButtonOperations>
              <Button onClick={this.handlePrint}>Печать</Button>
              {monthlyWashingPlan.status ===
                monthlyWashingPlanStatusEnum.draft &&
                this.accessRight('format') && (
                  <Button
                    type="primary"
                    onClick={() =>
                      this.changeStatus(
                        monthlyWashingPlanStatusEnum.approvement
                      )
                    }
                  >
                    На согласование
                  </Button>
                )}
              {monthlyWashingPlan.status ===
                monthlyWashingPlanStatusEnum.approvement &&
                this.accessRight('approve') && (
                  <>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStatus(monthlyWashingPlanStatusEnum.inWork)
                      }
                    >
                      Утвердить
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.changeStatus(monthlyWashingPlanStatusEnum.draft)
                      }
                    >
                      Отклонить
                    </Button>
                  </>
                )}
              {this.accessRight('add') &&
                monthlyWashingPlan.status !==
                  monthlyWashingPlanStatusEnum.inWork && (
                  <Dropdown
                    ref={dropdown => (this.dropdown = dropdown)}
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() =>
                            navigate(
                              `/budget/monthly-washing-plans/edit/${monthlyWashingPlanId}`
                            )
                          }
                        >
                          Редактировать
                        </Menu.Item>
                        <Menu.Item>
                          <Popconfirm
                            title="Вы действительно хотите удалить?"
                            okText="Да"
                            cancelText="Нет"
                            placement="bottomRight"
                            onConfirm={this.deleteMonthlyWashingPlan}
                            onVisibleChange={flag =>
                              this.dropdown &&
                              this.dropdown.onVisibleChange(flag)
                            }
                          >
                            Удалить
                          </Popconfirm>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      className="openActionsDropdown"
                      type="primary"
                      icon="ellipsis"
                    />
                  </Dropdown>
                )}
            </ButtonOperations>
          }
        />
        <StyledPanel>
          <h1>План моек на {monthName}</h1>
          <Grid cols={3}>
            <GridItem>
              <Field label="Статус">
                {monthlyWashingPlanStatuses[monthlyWashingPlan.status]}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Филиал">{monthlyWashingPlan.orgUnitName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Период выделения">{monthName}</Field>
            </GridItem>
            <GridItem>
              <Field label="Количество ТС">
                {monthlyWashingPlan.vehicleCount}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Подрядчик">
                {convertContractorToString(monthlyWashingPlan.contractor)}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Договор">
                {(monthlyWashingPlan?.contract?.documentNumber ||
                  monthlyWashingPlan?.contract?.accountNumber) ??
                  '-'}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="План моек кузова">
                {monthlyWashingPlan.bodyPlan} шт -{' '}
                {formatRub(monthlyWashingPlan.bodyBalance)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Факт моек кузова">
                {minus(
                  monthlyWashingPlan.bodyPlan,
                  monthlyWashingPlan.bodyCount
                )}{' '}
                шт - {formatRub(monthlyWashingPlan.bodyFact)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Остаток моек кузова">
                {monthlyWashingPlan.bodyCount} шт -{' '}
                {formatRub(monthlyWashingPlan.bodyResidue)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="План моек салон">
                {monthlyWashingPlan.interiorPlan} шт -{' '}
                {formatRub(monthlyWashingPlan.interiorBalance)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Факт моек салона">
                {minus(
                  monthlyWashingPlan.interiorPlan,
                  monthlyWashingPlan.interiorCount
                )}{' '}
                шт - {formatRub(monthlyWashingPlan.interiorFact)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Остаток моек салона">
                {monthlyWashingPlan.interiorCount} шт -{' '}
                {formatRub(monthlyWashingPlan.interiorResidue)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="План моек двигателя">
                {monthlyWashingPlan.enginePlan} шт -{' '}
                {formatRub(monthlyWashingPlan.engineBalance)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Факт моек двигателя">
                {minus(
                  monthlyWashingPlan.enginePlan,
                  monthlyWashingPlan.engineCount
                )}{' '}
                шт - {formatRub(monthlyWashingPlan.engineFact)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Остаток моек двигателя">
                {monthlyWashingPlan.engineCount} шт -{' '}
                {formatRub(monthlyWashingPlan.engineResidue)}
              </Field>
            </GridItem>

            <GridItem>
              <Field label="Общий остаток моек">
                {sum([
                  monthlyWashingPlan.bodyCount,
                  monthlyWashingPlan.interiorCount,
                  monthlyWashingPlan.engineCount
                ])}
                &nbsp;шт -&nbsp;
                {formatRub(
                  sum([
                    monthlyWashingPlan.interiorResidue,
                    monthlyWashingPlan.bodyResidue,
                    monthlyWashingPlan.engineResidue
                  ]) ?? 0
                )}
                &nbsp;
              </Field>
            </GridItem>
            <GridItem />
            <GridItem />
            <GridItem>
              <Field label="Сумма по договору">
                {formatRub(monthlyWashingPlan?.incomeSchedule?.sum)}
              </Field>
            </GridItem>
            <GridItem>
              <Field label="Отклонение от договора">
                {formatRub(
                  minus(
                    monthlyWashingPlan.incomeSchedule?.sum,
                    monthlyWashingPlan.bodyBalance +
                      monthlyWashingPlan.interiorBalance +
                      monthlyWashingPlan.engineBalance
                  )
                )}
              </Field>
            </GridItem>
          </Grid>
        </StyledPanel>
        <Section />
        <Section>
          <SectionTitle
            suffix={
              <ButtonOperations>
                <Button onClick={this.handlePrintVehiclesWashings}>
                  Печать
                </Button>
              </ButtonOperations>
            }
          >
            Мойки
          </SectionTitle>
          <MonthlyWashingPlanFilter
            filter={this.state.filters}
            monthlyWashingPlanId={monthlyWashingPlanId}
            applyFilter={filters =>
              this.setState(
                {
                  filters: {
                    ...filters,
                    ...this.toQueryStringVehicleFilter(filters)
                  }
                },
                this.fetchWashingPlanVehicles
              )
            }
            cleanFilter={() =>
              this.setState(
                { filters: { vehicle: {} } },
                this.fetchWashingPlanVehicles
              )
            }
          />
          <Table
            columns={this.columns}
            fetch={this.fetchWashingPlanVehicles}
            rowKey="vehicleId"
            data={data}
            loading={loading}
            footer={this.renderSummary}
            pagination={{
              page,
              totalCount,
              pageSize
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MonthlyWashingPlanForm);
