// @flow

import moment from 'moment';
import React from 'react';
import isEqual from 'lodash/isEqual';
import { SelectProps } from 'antd/lib/select';

import { incomeScheduleApi } from './../../lib/api';

import AutocompleteSelect from './AutocompleteSelect';

type Props = SelectProps & { filter: any, selectByDate: string };

const areEqual = (prevProps, nextProps) =>
  isEqual(prevProps.filter, nextProps.filter) &&
  prevProps.value === nextProps.value;

export default React.memo<Props>(
  ({ filter = {}, ...selectProps }: Props) => (
    <AutocompleteSelect
      fetch={async params => {
        const data = await incomeScheduleApi.fetchIncomeSchedule({
          ...params,
          ...filter,
        });
        if (selectProps.selectByDate) {
          const is = data.data.find(el =>
            moment(selectProps.selectByDate).isBetween(
              moment(el.startDate).startOf('month'),
              moment(el.startDate).endOf('month')
            )
          );
          is &&
            !selectProps.value &&
            selectProps.onChange(is.id, { props: { incomeschedule: is } });
        }
        return data;
      }}
      refetchParams={{ filter }}
      fetchSingle={incomeScheduleApi.getIncomeSchedule}
      notFoundText="Графики поступлений не найдены"
      placeholder="Выберите график"
      renderOption={(incomeSchedule: any, Option) => (
        <Option
          key={incomeSchedule.id}
          value={incomeSchedule.id}
          incomeschedule={incomeSchedule}
        >
          <>
            {`${incomeSchedule.nomenclatureName} ${incomeSchedule.count} ${incomeSchedule.sum} руб.`}
            {incomeSchedule.schedulePoint ? (
              <span style={{ color: '#2770ff' }}>
                {' '}
                {incomeSchedule.schedulePoint}{' '}
              </span>
            ) : null}
          </>
        </Option>
      )}
      {...selectProps}
    />
  ),
  areEqual
);
