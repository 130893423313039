// @flow
import React from 'react';

import {navigate} from '../../lib/helpers';
import {debetCreditAccountsApi} from './../../lib/api';

import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';

const entryPointPath = '/admin/debet-credit-accounts';

const DebetCreditAccounts = () => {
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30
    },
    {
      title: 'Дебет',
      dataIndex: 'debet'
    },
    {
      title: 'Кредит',
      dataIndex: 'credit'
    }
  ];

  return (
    <Page>
      <CommonListPage
        crud={debetCreditAccountsApi}
        pageHeaderProps={{
          mainHeader: 'Дебет/кредит счетов',
          access: {
            add: () => {
              navigate(`${entryPointPath}/new`);
            }
          }
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}/${id}`),
          pageSize: 20
        }}
      />
    </Page>
  );
};

export default React.memo(DebetCreditAccounts);
