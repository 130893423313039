// @flow
import React from 'react';
import moment from 'moment';
import notification from 'antd/lib/notification';
import Table from '../../../components/ui/Table';
import {ContractTripApi} from '../../../lib/api';
import Header from '../../../components/layout/Header';
import {Section} from '../../../components/layout';
import {notificationLoading} from '../../../components/Notifications';
import Filter, {type RegisterContractTripsFilter} from './components/Filter';
import qs from 'query-string';
import Button from 'antd/lib/button';
import {ContractTrip} from '../../../lib/types/contractTrips';

const initialParams: RegisterContractTripsFilter = {
  startDate: moment.utc().startOf('month').toISOString(),
  endDate: moment.utc().endOf('month').toISOString(),
};

type State = {
  pageSize: number,
  page: number,
  filter: RegisterContractTripsFilter,
  data: ContractTrip[],
  loading: boolean,
  totalCount: number,
};

export default class extends React.Component<{}, State> {
  state = {
    pageSize: 100,
    page: 1,
    filter: initialParams,
    data: [],
    loading: false,
    totalCount: 0,
  };

  columns = [
    {
      title: '№',
      dataIndex: 'number',
    },
    {
      title: 'Дата путевого листа, начало',
      dataIndex: 'startDate',
      render: date => moment(date).format('DD.MM.YYYY'),
    },
    {
      title: 'Дата путевого листа, окончание',
      dataIndex: 'endDate',
      render: date => moment(date).format('DD.MM.YYYY'),
    },
    {
      title: 'Тип ТС',
      dataIndex: 'vehicleType',
    },
    {
      title: 'Модель ТС',
      dataIndex: 'vehicleModel',
    },
    {
      title: 'Марка ТС',
      dataIndex: 'vehicleBrand',
    },
    {
      title: 'Государственный регистрационной знак',
      dataIndex: 'licensePlate',
    },
    {
      title: 'Год выпуска',
      dataIndex: 'vehicleProductionYear',
    },
    {
      title: 'Вид топлива',
      dataIndex: 'fuelType',
    },
    {
      title: 'Время работы всего',
      dataIndex: 'totalWorkTime',
    },
    {
      title: 'Время в движении',
      dataIndex: 'movementTime',
    },
    {
      title: 'Тариф в движении',
      dataIndex: 'movementPrice',
    },
    {
      title: 'Время ожидания',
      dataIndex: 'waitingTime',
    },
    {
      title: 'Тариф ожидания',
      dataIndex: 'waitingPrice',
    },
    {
      title: 'Время простоя с включенным двигателем',
      dataIndex: 'waitingWithRunningEngineTime',
    },
    {
      title: 'Тариф простоя с включенным двигателем',
      dataIndex: 'waitingWithRunningEnginePrice',
    },
    {
      title: 'Время отопителя',
      dataIndex: 'heaterTime',
    },
    {
      title: 'Тариф отопителя',
      dataIndex: 'heaterPrice',
    },
    {
      title: 'Время подогревателя',
      dataIndex: 'preheaterTime',
    },
    {
      title: 'Тариф подогревателя',
      dataIndex: 'preheaterPrice',
    },
    {
      title: 'Пробег',
      dataIndex: 'distance',
    },
    {
      title: 'Тариф, км',
      dataIndex: 'distancePrice',
    },
    {
      title: 'Пробег с прицепом',
      dataIndex: 'distanceWithTrailer',
    },
    {
      title: 'Тариф, км. с прицепом',
      dataIndex: 'distanceWithTrailerPrice',
    },
    {
      title: 'Сумма без НДС',
      dataIndex: 'sum',
    },
  ];
  
  componentDidMount() {
    const {page, ...filter} = qs.parse(window.location.search);
    this.setState({page: page || 1, filter}, () => this.fetchContractTrips(page || 1));
  };
  
  fetchContractTrips = async (page) => {
    try {
      this.setState({loading: true});
      const {pageSize, filter} = this.state;
      const {data, totalCount} = await ContractTripApi.getReport({page, pageSize, ...filter});
      this.setState({data, page, totalCount});
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message || error.title,
      });
    }
    finally {
      this.setState({loading: false});
    }
  };
  
  applyFilter = (filter: RegisterContractTripsFilter) => this.setState({filter: {...filter}}, () => this.fetchContractTrips(1));
  
  cleanFilter = () => this.setState({filter: {}}, () => this.fetchContractTrips(1));
  
  handleExport = async () => {
    try {
      notificationLoading({
        message: 'Формирование файла...',
        key: 'printing',
      });
      await ContractTripApi.printReport(this.state.filter,);
    } catch (error) {
      notification.error({
        message: 'Ошибка экспорта',
        description: error.message,
      });
    }
    finally {
      notification.close('printing');
    }
  };
  
  render() {
    const {data, loading, page, pageSize, filter, totalCount} = this.state;
    return (
      <>
        <Header
          left={<h1>Реестр ПЛ НТС</h1>}
          right={<Button type='primary' onClick={this.handleExport}>Печать</Button> }
        />
        <Section>
          <Filter
            filter={filter}
            applyFilter={this.applyFilter}
            cleanFilter={this.cleanFilter}
          />
          <Table
            fetch={this.fetchContractTrips}
            pagination={{
              page,
              pageSize,
              totalCount,
            }}
            loading={loading}
            data={data}
            columns={this.columns}
          />
        </Section>
      </>
    );
  }
}
