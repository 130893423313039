// @flow
import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import notification from 'antd/lib/notification';

import type {
  WriteOffAct,
  WriteOffActPartAndConsumbles
} from '../../../../lib/types';
import { actStatusEnum } from '../../../../lib/enum';
import { writeOffActApi } from '../../../../lib/api';
import { navigate, multipliedBy } from '../../../../lib/helpers';

import CommonForm, {
  type CommonFormProps
} from '../../../../components/hoc/common/handbook/CommonForm';
import CommonCardPage from '../../../../components/hoc/common/handbook/CardPage';

import { Selects } from '../../../../components';
import { ListTable, Icon } from './../../../../components/ui';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import { FormField } from '../../../../components/Form';

const entryPointPath = '/admin/act/internal/mpz';
const FormItem = Form.Item;
const StyledFormItem = styled(FormItem)`
  margin-bottom: 0px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
`;
const Operations = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const { ExpenseDirectionSelect, StockPartSelect } = Selects;
type Props = CommonFormProps<$Shape<WriteOffAct>> & {
  action: 'create' | 'generate'
};
export default (props: Props) => {
  const [parts, setParts] = useState([]);
  //TODO: Убрать это, когда будет время
  const [documentNumber, setDocument] = useState(null);
  const columns = [
    {
      title: 'Материал',
      style: {
        overflow: 'hidden',
        minWidth: '250px'
      },
      key: 'stockPartId',
      dataIndex: 'stockPartId',
      render: (stockPartId: number, record: any, index: number) => {
        return (
          <StyledFormItem
            validateStatus={validateRequired('stockPartId', index)}
          >
            <StockPartSelect
              value={stockPartId}
              onChange={(stockPartId: number, option, any) => {
                onChange('stockPartId', stockPartId, index);
                onChange('stockPart', option?.props?.part, index);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Направление расходов',
      style: {
        overflow: 'hidden',
        minWidth: '250px'
      },
      key: 'expenseDirectionId',
      dataIndex: 'expenseDirectionId',
      render: (expenseDirectionId: number, record: any, index: number) => {
        return (
          <StyledFormItem
            validateStatus={validateRequired('expenseDirectionId', index)}
          >
            <ExpenseDirectionSelect
              value={expenseDirectionId}
              onChange={(expenseDirectionId: number, option: any) => {
                onChange('expenseDirectionId', expenseDirectionId, index);
                onChange(
                  'expenseDirection',
                  option?.props?.expenseDirection,
                  index
                );
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Количество',
      width: '100px',
      key: 'count',
      dataIndex: 'count',
      render: (count: string, record: any, index: number) => {
        return (
          <StyledFormItem validateStatus={validateRequired('count', index)}>
            <StyledInputNumber
              min={0}
              value={count}
              onBlur={e => {
                onChange('count', +e.target.value, index);
              }}
            />
          </StyledFormItem>
        );
      }
    },
    {
      title: 'Сумма',
      width: '100px',
      renderRecord: (record?: WriteOffActPartAndConsumbles) => {
        return (
          <StyledInputNumber
            disabled
            value={multipliedBy(record?.count, record?.stockPart?.cost)}
          />
        );
      }
    },
    {
      width: '20px',
      renderRecord: (record: any, index: number) => {
        return !isEmpty(record) ? (
          <Operations>
            <StyledIcon onClick={() => onDelete(index)} type="x" />
          </Operations>
        ) : null;
      }
    }
  ];

  const validateRequired = (field: string, index: number) => {
    if (!isEmpty(parts[index])) {
      return parts[index][field] ? 'validating' : 'error';
    }
    return 'validating';
  };

  const onChange = async (name: string, value: number, index: number) => {
    parts[index] = {
      ...parts[index],
      [name]: value
    };
    setParts([...parts]);
  };

  const onSubmit = async (payload: WriteOffAct) => {
    let emptyValue = false;
    if (props.action === 'generate') {
      parts.forEach(part => {
        if (
          !Number.isInteger(+part.stockPartId) ||
          !Number.isInteger(+part.expenseDirectionId) ||
          !Number.isInteger(+part.count)
        ) {
          emptyValue = true;
        }
      });
    }
    if (emptyValue) {
      notification.error({ message: 'Заполните все поля' });
    } else {
      let writeOffAct = null;
      if (props.action === 'generate') {
        writeOffAct = await writeOffActApi.addSeparateAct({
          ...payload,
          documentNumber,
          parts
        });
      } else {
        writeOffAct = await writeOffActApi.add({
          ...payload,
          parts
        });
      }
      writeOffAct &&
        (await writeOffActApi.update({
          ...writeOffAct,
          status: actStatusEnum.workedOut
        }));
      await navigate(entryPointPath);
    }
  };

  const onDelete = (deleteIndex: number) => {
    setParts(parts.filter((part, index) => index !== deleteIndex));
  };

  const onCancel = async () => {
    await navigate(entryPointPath, true);
  };

  return (
    <>
      <CommonCardPage
        pageHeaderProps={{
          mainHeader: 'Списание актов МПЗ'
        }}
        onFetch={async () => {}}
        onSubmit={onSubmit}
        onCancel={onCancel}
      >
        <CommonForm data={{}} useFooter noWrapMe>
          {({ setFieldValue }) => {
            return (
              <>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField label="Номер документа" name="documentNumber">
                      {({ name, value }) => (
                        <Input
                          value={
                            props.action === 'generate'
                              ? value || documentNumber
                              : value
                          }
                          onBlur={() =>
                            props.action === 'generate' && setDocument(value)
                          }
                          onChange={e => {
                            setFieldValue(name, e.target.value);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem />
                  {props.action === 'create' && (
                    <>
                      <GridItem>
                        <FormField
                          label="Дата начала"
                          name="startDate"
                          required
                        >
                          {({ name, value }) => (
                            <DatePicker
                              name={name}
                              value={value ? moment(value) : value}
                              format="DD MMMM YYYY"
                              onChange={(value: string) => {
                                setFieldValue(
                                  name,
                                  moment
                                    .utc(value)
                                    .startOf('day')
                                    .toISOString()
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                      <GridItem>
                        <FormField
                          label="Дата окончания"
                          name="endDate"
                          required
                        >
                          {({ name, value }) => (
                            <DatePicker
                              name={name}
                              value={value ? moment(value) : value}
                              format="DD MMMM YYYY"
                              onChange={(value: string) => {
                                setFieldValue(
                                  name,
                                  moment
                                    .utc(value)
                                    .startOf('day')
                                    .toISOString()
                                );
                              }}
                            />
                          )}
                        </FormField>
                      </GridItem>
                    </>
                  )}
                  {props.action === 'generate' && (
                    <>
                      <GridItem fullWidth>
                        <ListTable columns={columns} data={[...parts, {}]} />
                      </GridItem>
                    </>
                  )}
                </Grid>
              </>
            );
          }}
        </CommonForm>
      </CommonCardPage>
    </>
  );
};
