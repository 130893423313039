// @flow
import React from 'react';
import { Router } from '@reach/router';

import { Header, Section } from './../../../components/layout';
import { Tabs, TabItem } from './../../../components/ui';
import ArchiveTripList from './ArchiveTripList';
import IncomeTripList from './IncomeTripList';

export default () => {
  return (
    <>
      <Header left={<h1>Путевые листы собственных ТС</h1>} />
      <Tabs withRouter>
        <TabItem label="Входящие" url="/trips/self" />
        <TabItem label="Архив" url="/trips/self/archive" />
      </Tabs>
      <Section>
        <Router>
          <IncomeTripList path="/" />
          <ArchiveTripList path="archive" />
        </Router>
      </Section>
    </>
  );
};
