// @flow
import React, {useCallback, useState} from 'react';

import {navigate} from '../../lib/helpers';
import {ContractorBranchLinkApi} from '../../lib/api';
import CommonCardPage from '../../components/hoc/common/handbook/CardPage';
import CommonForm, {type CommonFormProps} from '../../components/hoc/common/handbook/CommonForm';
import {getBreadCrumbsByUrlForEdit} from '../../lib/autoBreadcrumbs';
import Grid, {GridItem} from '../../components/layout/Grid';
import {FormField} from '../../components/Form';
import ContractorSelect from '../../components/selects/ContractorSelect';
import type {ContractorBranchLink} from '../../lib/types/contractorBranchLink';
import InputNumber from 'antd/lib/input-number';
import {accessTypeEnum} from '../../lib/enum';
import {withUserAccess} from '../withUserAccess';

type PageProps = {
  id: number,
  canEdit: boolean
};

const entryPointPath = '/admin/securing-contractors/';

const InnerForm = (props: CommonFormProps<$Shape<ContractorBranchLink>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Филиал" name="branchName">
                  {({value}) => value}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Подрядчик"
                  fast
                  required
                  name="contractorId"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <ContractorSelect
                      disabled={!props.canEdit}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                      size="small"
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Коэффициент простоя"
                  fast
                  required
                  name="downtimeRatio"
                  hasFeedback={false}
                >
                  {({ name, value }) => (
                    <InputNumber
                      disabled={!props.canEdit}
                      value={value}
                      onChange={value => setFieldValue(name, value)}
                      size="small"
                      min={0}
                      precision={1}
                      step={0.1}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};

const Form = (props: PageProps) => {
  const [data: ContractorBranchLink, setData] = useState({id: props.id});

  const breadCrumbs = getBreadCrumbsByUrlForEdit(entryPointPath, data.id);

  const onFetch = useCallback(async () => {
    !!props.id && setData(await ContractorBranchLinkApi.get(props.id));
  }, [props.id]);

  const onSubmit = async (payload: ContractorBranchLink) => {
    let id = payload.id;
    if (id) {
      await ContractorBranchLinkApi.update(payload);
    } else {
      await ContractorBranchLinkApi.add(payload);
    }
    await navigate(`${entryPointPath}`, true);
  };

  const onCancel = async () => {
    await navigate(`${entryPointPath}`, true);
  };

  const canEdit = () => {
    return props.userAccess.some(access =>
      [
        accessTypeEnum.admin,
        accessTypeEnum.viewingContractorBranchLink,
        accessTypeEnum.handlingContractorBranchLink
      ].includes(access)
    );
  };

  return (
    <CommonCardPage
      pageHeaderProps={{breadCrumbs, mainHeader: 'Закрепление подрядчиков'}}
      onFetch={onFetch}
      onSubmit={onSubmit}
      onCancel={onCancel}
    >
      <InnerForm data={data} canEdit={canEdit()} useFooter noWrapMe />
    </CommonCardPage>
  );
};

export default withUserAccess(Form);
