import Input from 'antd/lib/input/Input';
import React from 'react';

import { FormField } from '../../../components/Form';
import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { MonthSelect } from '../../../components/selects/AutoGeneratedEnumSelects';
import YesNoSelect from '../../../components/selects/YesNoSelect';

import type { BudgetVersion } from '../../../lib/types';

export default (props: CommonFormProps<$Shape<BudgetVersion>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue }) => {
        return (
          <>
            <Grid gutter="16px">
              <GridItem>
                <FormField label="Наименование" required name="name" fast>
                  {field => <Input {...field} />}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField
                  label="Доступно для выбора пользователем при формирование предложений"
                  required
                  name="isAvailableForSelect"
                  fast
                >
                  {({ name, value }) => (
                    <YesNoSelect
                      name={name}
                      value={
                        value === undefined ? setFieldValue(name, false) : value
                      }
                      onChange={value => setFieldValue(name, value)}
                    />
                  )}
                </FormField>
              </GridItem>
              <GridItem>
                <FormField label="Начало открытого периода" name="periodStart">
                  {({ name, value }) => (
                    <MonthSelect
                      value={value}
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                    />
                  )}
                </FormField>
              </GridItem>
            </Grid>
          </>
        );
      }}
    </CommonForm>
  );
};
