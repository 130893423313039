// @flow
import React from 'react';

import notification from 'antd/lib/notification';
import { connect } from 'react-redux';
import { FormikProps } from 'formik';
import styled from 'styled-components';
import Button from 'antd/lib/button/button';
import moment from 'moment';

import { Header, Section } from '../../../components/layout';
import {
  cleanVehicle,
  fetchVehicle,
  updateVehicle
} from '../../../ducks/vehicle';
import { navigate } from '../../../lib/helpers';
import type { Vehicle } from '../../../lib/types';
import type { AppState } from '../../../ducks/redux';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Form from '../../../components/Form';
import { notificationLoading } from './../../../components/Notifications';
import CancelButton from '../../../components/CancelButton';
import VehicleDriverSelect from '../components/VehicleDriverSelect';

const StyledSection = styled(Section)`
  padding: 16px;
`;
const Footer = styled(Section)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type Props = {
  vehicle: Vehicle,
  vehicleId: string,
  fetchVehicle: Function,
  cleanVehicle: Function,
  updateVehicle: Function
};

class Driver extends React.Component<Props> {
  async componentDidMount() {
    const { vehicleId } = this.props;
    await this.props.cleanVehicle();
    try {
      await this.props.fetchVehicle(vehicleId);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  onSubmit = async (values: Vehicle) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await this.props.updateVehicle(values);
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate(`/vehicles/${values.id}/documentation/driver`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => {
    navigate(`/vehicles/${this.props.vehicleId}/documentation/driver`);
  };

  formatDate = value => {
    return value ? moment.utc(value) : value;
  };

  render() {
    const { vehicle } = this.props;
    return (
      <>
        <Header left={vehicle && <h1>Водитель {vehicle.licensePlate}</h1>} />
        <Form initialValues={vehicle || {}} onSubmit={this.onSubmit}>
          {(FormField, formikProps: FormikProps) => {
            const {
              handleSubmit,
              handleBlur,
              setFieldValue,
              dirty
            } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <StyledSection>
                  <Grid gutter="16px">
                    <GridItem>
                      <FormField label="Водитель" required name="driverId">
                        {({ value, name }) => (
                          <VehicleDriverSelect
                            vehicle={vehicle}
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            onBlur={() => handleBlur({ target: { name } })}
                            value={value}
                            name={name}
                          />
                        )}
                      </FormField>
                    </GridItem>
                  </Grid>
                </StyledSection>
                <Footer>
                  <Button type="primary" htmlType="submit">
                    Сохранить
                  </Button>
                  <CancelButton dirty={dirty} onClick={this.handleCancel}>
                    Отменить
                  </CancelButton>
                </Footer>
              </form>
            );
          }}
        </Form>
      </>
    );
  }
}

export default connect(
  (state: AppState, props: Props) => ({
    vehicle: state.vehicle,
    vehicleId: parseInt(props.vehicleId, 10)
  }),
  {
    fetchVehicle,
    cleanVehicle,
    updateVehicle
  }
)(Driver);
