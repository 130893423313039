// @flow

import React, {Component} from 'react';

import styled from 'styled-components';
import notification from 'antd/lib/notification';

import {compareExpenseDirectionApi} from './../../lib/api';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';

import {Panel} from './../../components/layout';
import Header from './../../components/layout/Header';
import Breadcrumbs, {Crumb} from './../../components/layout/Breadcrumbs';
import {notificationLoading} from './../../components/Notifications';

import InnerForm from './components/InnerForm';
import type {CompareExpenseDirection} from '../../lib/types';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  compareExpenseDirectionId: ?string
};

type State = {
  compareExpenseDirection: ?CompareExpenseDirection
};

class ExpenseDirectionForm extends Component<Props, State> {
  state = {
    compareExpenseDirection: null
  };

  async componentDidMount() {
    let id = parseInt(this.props.compareExpenseDirectionId, 10);
    if (id) {
      try {
        const compareExpenseDirection = await compareExpenseDirectionApi.getCompareExpenseDirection(
          id
        );
        this.setState({ compareExpenseDirection });
      } catch (error) {
        notification.error({
          message: 'Ошибка',
          description: error.message
        });
      }
    }
  }

  onSubmit = async (values: any) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (this.props.compareExpenseDirectionId) {
        await compareExpenseDirectionApi.updateCompareExpenseDirection(values);
      } else {
        await compareExpenseDirectionApi.addCompareExpenseDirection(values);
      }
      notification.success({
        message: 'Успешно сохранено',
        description: 'Изменения успешно сохранены'
      });
      navigate('/admin/compare-expense-direction', true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  onCancel = () => navigate('/admin/compare-expense-direction', true);

  render() {
    const { compareExpenseDirection } = this.state;
    const { compareExpenseDirectionId } = this.props;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb
                to={getPathWithHistoryParams(
                  '/admin/compare-expense-direction'
                )}
              >
                Список сопоставлений расходов и видов работ
              </Crumb>
              {compareExpenseDirectionId ? (
                <Crumb
                  to={`/admin/compare-expense-direction/${compareExpenseDirectionId}/edit`}
                >
                  Сопоставление расхода и вида работ №
                  {compareExpenseDirectionId}
                </Crumb>
              ) : (
                <Crumb to={`/admin/compare-expense-direction/new`}>
                  Новое сопоставление расходов и вида работ
                </Crumb>
              )}
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>
            {compareExpenseDirectionId
              ? `Сопоставление расходов №${compareExpenseDirectionId}`
              : `Новое сопоставление расхода и вида работ`}
          </h1>
        </StyledPanel>
        <InnerForm
          compareExpenseDirection={compareExpenseDirection}
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
        />
      </>
    );
  }
}

export default ExpenseDirectionForm;
