// @flow
import React, {useState} from 'react';

import {navigate} from '../../lib/helpers';
import CommonListPage from './../../components/hoc/common/handbook/ListPage';
import Page from '../../components/hoc/common/handbook/PageContext';
import {compareDatesApi} from '../../lib/api';
import {accessTypeEnum, monthsNamesTranslitEnum} from '../../lib/enum';
import Popconfirm from 'antd/lib/popconfirm';
import {Icon} from '../../components/ui';
import {withUserAccess} from '../withUserAccess';

const entryPointPath = '/admin/compare-dates';

const CompareDatesList = ({userAccess}) => {
  const [reFetch, setReFetch] = useState(false);
  
  const fetch = async (params) => {
    if (reFetch) {
      setReFetch(false);
    }
    const result = await compareDatesApi.fetch({...params, orderBy: 'month', byDescending: false, pageSize: undefined});
    result.data.sort((a, b) => {
      if (a.month === b.month) {
        return a.day - b.day;
      }
      return 0;
    })
    return result
  };
  
  const canEdit = () => [
    accessTypeEnum.admin,
    accessTypeEnum.editingInventoryCardCompareDate,
  ].some(access => userAccess.includes(access));
  
  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      width: 30,
    },
    {
      title: 'Месяц',
      dataIndex: 'month',
      render: month => monthsNamesTranslitEnum[month],
    },
    {
      title: 'Число',
      dataIndex: 'day',
    },
    {
      stopPropagation: true,
      dataIndex: 'id',
      key: 'delete',
      width: 30,
      render: id => {
        if (canEdit()) {
          return (
            <Popconfirm
              okText='Да'
              cancelText='Нет'
              title="Вы действительно хотите удалить запись?"
              onConfirm={async () => {
                await compareDatesApi.delete(id);
                setReFetch(true);
              }}
            >
              <Icon type="x" />
            </Popconfirm>
          );
        }
      },
    },
  ];
  
  return (
    <Page>
      <CommonListPage
        crud={{
          ...compareDatesApi,
          fetch: fetch,
        }}
        pageHeaderProps={{
          mainHeader: 'Сроки сверки ИК',
          access: {
            add: canEdit() ? () => navigate(`${entryPointPath}/new`) : undefined,
          },
        }}
        tableListProps={{
          columns,
          onSelect: (id: number) => navigate(`${entryPointPath}/${id}`),
          params: {
            pagination: false,
          }
        }}
        reFetch={reFetch}
      />
    </Page>
  );
};

export default withUserAccess(CompareDatesList);
