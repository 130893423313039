// @flow
import React, {Component} from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {Panel} from './../../components/layout';
import type {UserAccess, Washing, WashingPlanBalance} from '../../lib/types';
import {accessTypeEnum, washingTypeEnum} from './../../lib/enum';
import {monthlyWashingPlanApi, washingApi} from './../../lib/api';
import Header from '../../components/layout/Header';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import {getPathWithHistoryParams, navigate} from './../../lib/helpers';
import {notificationLoading} from './../../components/Notifications';

import InnerForm from './components/InnerForm';
import {connect} from 'react-redux';
import type {AppState} from '../../ducks/redux';

type WashingTicket = Washing & {
  actNumber?: string,
  amountPayment?: number
};

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;

type Props = {
  washingId: ?number,
  vehicleId: ?number,
  userAccess: UserAccess[]
};

type State = {
  washing: $Shape<Washing>,
  planData: ?WashingPlanBalance
};

class WashingForm extends Component<Props, State> {
  state = {
    washing: {},
    planData: null
  };
  async componentDidMount() {
    try {
      const washingId = parseInt(this.props.washingId, 10);
      const vehicleId = parseInt(this.props.vehicleId, 10);
      if (washingId) {
        const washing = await washingApi.fetchWashing(washingId);
        if (
          this.props.userAccess.includes(accessTypeEnum.viewingWashingPlans)
        ) {
          await this.fetchPlanData(washing);
        }
        this.setState({
          washing
        });
      } else if (vehicleId) {
        this.setState({
          washing: {
            vehicleId
          }
        });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  redirect = (id: ?number = this.props.washingId) => {
    if (id) {
      navigate(`/services/washings/${id}`);
    } else navigate(`/services/washings`, true);
  };

  handleSaveAndPrint = async (washing: WashingTicket) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const savedWashing = await this.saveWashing(washing);
      notification.close('saving');
      if (savedWashing) {
        await this.handlePrint(savedWashing.id);
        this.redirect(savedWashing.id);
      } else {
        this.redirect();
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  };

  fetchPlanData = async (washing: Washing) => {
    const { washing: existingWashing } = this.state;
    const { vehicleId, date, type } = washing;
    const parsedVehicleId = parseInt(vehicleId, 10);
    let needToFetchPlan = false;
    if (
      !isEmpty(existingWashing) &&
      ((parsedVehicleId > 0 && parsedVehicleId !== existingWashing.vehicleId) ||
        (date &&
          !moment(date).isBetween(
            moment(existingWashing.date).startOf('month'),
            moment(existingWashing.date).endOf('month')
          )) ||
        type !== existingWashing.type)
    ) {
      needToFetchPlan = true;
    } else if (parsedVehicleId > 0 && date && moment(date).isValid()) {
      needToFetchPlan = true;
    }

    if (needToFetchPlan) {
      const planData = await monthlyWashingPlanApi.fetchWashingPlanBalance(
        parsedVehicleId,
        date
      );
      if (planData) {
        this.setState({ planData });
      } else {
        this.setState({ planData: null });
        notification.error({
          message: 'Не удалось получить данные о плане моек'
        });
      }
    }
  };

  handlePrint = async (id: ?number) => {
    try {
      notificationLoading({
        message: 'Загрузка файла...',
        key: 'print'
      });
      if (id) await washingApi.printTicket(id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  saveWashing = async (washing: WashingTicket) => {
    const washingId = parseInt(this.props.washingId, 10);
    if (washingId) {
      const savedWashing = await washingApi.updateWashing(washing);
      notification.success({
        message: 'Успешное обновление',
        description: 'Данные были успешно обновлены'
      });
      return savedWashing;
    } else {
      const { planData } = this.state;
      if (planData) {
        switch (washing.type) {
          case washingTypeEnum.body:
            washing.paymentAmount = planData.vehicleBodyPrice;
            break;
          case washingTypeEnum.interior:
            washing.paymentAmount = planData.vehicleInteriorPrice;
            break;
          case washingTypeEnum.engine:
            washing.paymentAmount = planData.vehicleEnginePrice;
            break;
          default:
            break;
        }
      }
      const savedWashing = await washingApi.addWashing(washing);
      notification.success({
        message: 'Успешное добавление',
        description: `Мойка успешно добавлена`
      });
      return savedWashing;
    }
  };

  onSubmit = async (washing: WashingTicket) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      const saved = await this.saveWashing(washing);
      this.redirect(saved.id);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  render() {
    const washingId = parseInt(this.props.washingId, 10);
    const vehicleId = parseInt(this.props.vehicleId, 10);
    const { washing, planData } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams(`/services/washings`)}>
                Список талонов на мойку
              </Crumb>
              {washingId ? (
                <Crumb>{`Редактирование талона на мойку №${washingId}`}</Crumb>
              ) : (
                <Crumb>Новый талон на мойку</Crumb>
              )}
            </Breadcrumbs>
          }
          right={
            washingId ? (
              <Button onClick={async () => await this.handlePrint(washingId)}>
                Печать
              </Button>
            ) : null
          }
        />
        <StyledPanel>
          <h1>
            {washingId
              ? `Талон на мойку №${washingId}`
              : 'Новый талон на мойку'}
          </h1>
        </StyledPanel>
        <InnerForm
          washing={washing}
          onSubmit={this.onSubmit}
          onCancel={() => this.redirect(washing.id || washingId)}
          showVehicleSelect={!vehicleId}
          fetchPlanData={this.fetchPlanData}
          planData={planData}
          handleSaveAndPrint={this.handleSaveAndPrint}
        />
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access
}))(WashingForm);
