/* CalculationCard.js | Page | Расчёт стоимости ТС */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import type {ContractVehicleOrder, UserAccess, Vehicle, VehicleForSelection} from '../../lib/types';
import type {OrderStatusOptions} from '../../ducks/order';
import { changeOrderState, changeStatus, cleanOrder, fetchCalculation } from '../../ducks/order';
import { contractVehicleOrderApi } from './../../lib/api';
import { convertFromKopToRub, convertFromMToKm, formatDateRangeString, getPathWithHistoryParams, navigate } from '../../lib/helpers';
import { accessTypeEnum, orderObjectives, ownerTypes, trailerVehicleTypes, vehicleGroups, vehicleTypes } from '../../lib/enum';
import type {AppState} from '../../ducks/redux';
import { AccessDenied, Card as CalculationComponent, WaypointsViewer } from './../../components';
import { Header, Panel, Section, SectionTitle } from './../../components/layout';
import Breadcrumbs, { Crumb } from './../../components/layout/Breadcrumbs';
import Grid, { GridItem } from './../../components/layout/Grid';
import type { DropdownType } from '../../components/ui/Dropdown';
import { withUserAccess } from './../withUserAccess';
import { reasonsForRejectinfOfOrderApi } from '../../lib/api';
import type { OrderCalculation } from "../../lib/types/orderCalculation";
import {VehicleCalculationSelection} from "../../components/vehicleSelection/VehicleCalculationSelection";

const SectionContent = styled.div`
  padding: 16px;
`;
const StyledPanel = styled(Panel)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
`;
const {Field} = CalculationComponent;
const adminAccess = [accessTypeEnum.admin, accessTypeEnum.adminBranch];
// Список доступов, у которых есть разрешение на просмотр информации в карточке
const containerAccess = [
  ...adminAccess,
  accessTypeEnum.creatingOrder,
  accessTypeEnum.approvingGpmOrder,
  accessTypeEnum.handlingOrder,
  accessTypeEnum.viewingOrdersBranch,
  accessTypeEnum.viewingAllOrders,
  accessTypeEnum.viewingOrdersServiceOnly,
  accessTypeEnum.viewingOrderCalculation,
  accessTypeEnum.viewingAllOrderCalculation,
];

type Props = {
  fetchCalculation: Function,
  calculationId: number,
  employeeId: number,
  orderCalculation: OrderCalculation,
  changeStatus: (id: number, options: OrderStatusOptions) => void,
  cleanOrder: Function,
  userAccess: UserAccess[],
  currentEmployeeId: number,
  vehicles: VehicleForSelection[],
  changeOrderState: (orderData: any) => void
};

type State = {
  touched: boolean,
  vehicleId?: number,
  orderCalculation: ?OrderCalculation,
  freeVehicles: Vehicle[],
  freeTrailers: Vehicle[],
  showApproveByContractVehicleOrder: boolean,
  contractVehicleOrder: ?ContractVehicleOrder,
  copyOrderDate: ?string,
  vehiclesLoading: boolean,
  loading: boolean,
  contractDriverPhoneNumber: number,
  contractDriverFullName: string,
  keyContractDriverFullName: boolean,
  keyContractDriverPhoneNumber: boolean,
  showReworkReasonModal: boolean,
  typeOfModal: 'cancel' | 'rework',
  reworkReasons: []
};

export class CalculationCard extends Component<Props, State> {
  static defaultProps = {
    contractVehicleOrder: {},
  };
  dropdown: ?DropdownType;
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      orderCalculation: null,
      freeVehicles: [],
      freeTrailers: [],
      showApproveByContractVehicleOrder: false,
      contractVehicleOrder: null,
      copyOrderDate: null,
      vehiclesLoading: false,
      loading: false,
      filteredVehicles: this.props.vehicles,
      keyContractDriverPhoneNumber: false,
      keyContractDriverFullName: false,
      contractDriverPhoneNumber: '',
      contractDriverFullName: '',
      showReworkReasonModal: false,
      typeOfModal: 'cancel',
      reworkReasons: [],
    };
  }
  componentDidMount() {
    const {calculationId} = this.props;
    this.props.cleanOrder();
    reasonsForRejectinfOfOrderApi
    .fetch()
    .then(({data}) => this.setState({reworkReasons: data}));
    if (!this.hasAccess()) {
      return;
    }
    if (calculationId) {
      this.fetchCalculation(calculationId).then();
    }
  }

  // Получить расчёт стоимости ТС.
  fetchCalculation = async (calculationId: number) => {
    try {
      const orderCalculation = await this.props.fetchCalculation(calculationId);
      if (orderCalculation && orderCalculation.contractVehicleOrderId) {
        const contractVehicleOrder = await contractVehicleOrderApi.fetchContractVehicleOrder(
          orderCalculation.contractVehicleOrderId,
        );
        this.setState({contractVehicleOrder});
      }
      this.setState({orderCalculation});
      if (orderCalculation) {
        await this.fetchFreeVehicles();
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка при загрузке расчёта стоимости ТС',
        description: error.message || 'Расчёт стоимости ТС не найден',
      });
      await navigate('/orders/calculation', true);
    }
  };

  fetchFreeVehicles = async () => {
    const {orderCalculation} = this.state;
    if (!orderCalculation) return;
    this.setState({vehiclesLoading: true});
    const getVehicles = [];
    const getTrailers = [];
    // ТС.
    orderCalculation.vehicles.forEach((vehicle: VehicleForSelection) => {
      getVehicles.push({...vehicle, isBusy: 'free'});
    });
    // Трейлеры.
    orderCalculation.trailers.forEach((vehicle: VehicleForSelection) => {
      getTrailers.push({...vehicle, isBusy: 'free'});
      // Если понадобится проверять тип, включи это.
      // if ((trailerVehicleTypes.includes(vehicle.vehicleModel.type) || vehicle.vehicleModel.type === 'trailers')) { ... }
    });
    // Стейт.
    this.setState({
      vehiclesLoading: false,
      freeVehicles: getVehicles,
      freeTrailers: getTrailers,
    });
  };

  hasAccess = () =>
    this.props.userAccess.some(access => containerAccess.includes(access));

  emptyResult()
  {
    return (
      <div>
        <Header
          left={<Breadcrumbs>
            <Crumb to={getPathWithHistoryParams('/orders/calculation')}>Расчёт стоимости ТС</Crumb>
            <Crumb>Загрузка расчёта стоимости ТС</Crumb>
          </Breadcrumbs>}
          right={() => {}}
        />
        <StyledPanel>
          <h1>Загрузка расчёта стоимости ТС</h1>
        </StyledPanel>
        <Section>
          <SectionTitle divider>Назначение</SectionTitle>
          <SectionContent>
            <h1>Загрузка расчёта стоимости ТС</h1>
          </SectionContent>
        </Section>
      </div>
    );
  }

  render() {
    if (!this.hasAccess()) return <AccessDenied />;
    const { orderCalculation, vehiclesLoading, freeVehicles, freeTrailers }: State = this.state;
    if (!orderCalculation) return this.emptyResult();
    if (vehiclesLoading) return this.emptyResult();
    return (
      <div>
        <Header
          left={<Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/orders/calculation')}>Расчёт стоимости ТС</Crumb>
              <Crumb>Расчёт стоимости ТС №{orderCalculation.id}</Crumb>
            </Breadcrumbs>}
          right={() => {}}
        />
        <StyledPanel>
          <h1>Расчёт стоимости ТС №{orderCalculation.id}</h1>
        </StyledPanel>
        {/* Назначение */}
        <Section>
          <SectionTitle divider>Назначение</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Период выделения ТС">
                  {formatDateRangeString(orderCalculation.startDate, orderCalculation.endDate)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Цель поездки">
                  {orderObjectives[orderCalculation.objective]}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="С прицепом">
                  {orderCalculation.withTrailer ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="В распоряжение службы">{orderCalculation.orgUnitName}</Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {/* ТС */}
        <Section>
          <SectionTitle divider>Транспортное средство</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Тип ТС">
                  {vehicleTypes[orderCalculation.vehicleType] || vehicleGroups[orderCalculation.vehicleGroup]}
                </Field>
              </GridItem>
              {
                 <GridItem fullWidth>
                   <VehicleCalculationSelection
                     caption='Транспортные средства'
                     vehicles={freeVehicles}
                     loading={vehiclesLoading}
                   />
                 </GridItem>
              }
            </Grid>
          </SectionContent>
        </Section>
        {/* Прицеп */}
        {orderCalculation.withTrailer && (<Section>
            <SectionTitle divider>Прицеп</SectionTitle>
            <SectionContent>
              <Grid>
                <GridItem fullWidth>
                  <VehicleCalculationSelection
                    caption='Прицепы'
                    vehicles={freeTrailers}
                    loading={vehiclesLoading}
                    onlyType={ownerTypes.self}
                  />
                </GridItem>
              </Grid>
            </SectionContent>
          </Section>)}
        {/* Грузы */}
        <Section>
          <SectionTitle divider>Грузы</SectionTitle>
          <SectionContent>
            <Grid cols={1}>
              <GridItem>
                <Field label="Требуется перевозка груза">
                  {orderCalculation.shippingRequired ? 'Да' : 'Нет'}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
        {/* Маршрут */}
        <Section>
          <SectionTitle divider>Маршрут</SectionTitle>
          <SectionContent>
            <WaypointsViewer waypoints={orderCalculation.route && orderCalculation.route.waypoints} />
          </SectionContent>
        </Section>
        {/* Усреднённая стоимость ТС */}
        <Section>
          <SectionTitle divider>Усреднённая стоимость ТС</SectionTitle>
          <SectionContent>
            <Grid cols={2}>
              <GridItem>
                <Field label="Стоимость СТС">
                  {convertFromKopToRub(orderCalculation.vehiclePrice)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Стоимость НТС">
                  {convertFromKopToRub(orderCalculation.contractVehiclePrice)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Плановый пробег">
                  {convertFromMToKm(orderCalculation.estimatedKilometrage)}
                </Field>
              </GridItem>
              <GridItem>
                <Field label="Плановые часы работы">
                  {/*{convertFromSecondToHour(orderCalculation.estimatedHours)}*/}
                  {orderCalculation.estimatedHours}
                </Field>
              </GridItem>
            </Grid>
          </SectionContent>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, props: Props): Object => ({
  orderCalculation: state.orderCalculation,
  employeeId: state.auth.profile.employeeId,
  calculationId: props.calculationId,
  contractVehicleOrder: state.contractVehicleOrder,
  currentEmployeeId: state.auth.profile.employeeId,
});

export default connect(mapStateToProps, {
  fetchCalculation,
  changeStatus,
  cleanOrder,
  changeOrderState,
})(withUserAccess(CalculationCard));
