// @flow
import type {
  CalculationStatus,
  MeasuringDeviceCertificationCalculation,
  MeasuringDeviceCertificationCalculationMonth
} from './../types';
import CommonApi from '../commonApi';
import { convertFromKopToRub, convertFromRubToKop } from '../helpers';
import { downloadRequestWithToken, fetchRequest } from './index';

export const convertDataToServer = (
  measuringDeviceCertificationCalculation: MeasuringDeviceCertificationCalculation
): MeasuringDeviceCertificationCalculation => ({
  ...measuringDeviceCertificationCalculation,
  sum: convertFromRubToKop(measuringDeviceCertificationCalculation.sum),
  months: measuringDeviceCertificationCalculation.months.map(
    convertDataToServerMeasuringDeviceCertificationCalculationMonth
  )
});

export const convertDataFromServer = (
  measuringDeviceCertificationCalculation: MeasuringDeviceCertificationCalculation
): MeasuringDeviceCertificationCalculation => ({
  ...measuringDeviceCertificationCalculation,
  sum: convertFromKopToRub(measuringDeviceCertificationCalculation.sum),
  months: measuringDeviceCertificationCalculation.months.map(
    convertDataFromServerMeasuringDeviceCertificationCalculationMonth
  )
});

export const convertDataToServerMeasuringDeviceCertificationCalculationMonth = (
  measuringDeviceCertificationCalculationMonth: MeasuringDeviceCertificationCalculationMonth
): MeasuringDeviceCertificationCalculationMonth => ({
  ...measuringDeviceCertificationCalculationMonth,
  cost: convertFromRubToKop(measuringDeviceCertificationCalculationMonth.cost),
  price: convertFromRubToKop(measuringDeviceCertificationCalculationMonth.price)
});

export const convertDataFromServerMeasuringDeviceCertificationCalculationMonth = (
  measuringDeviceCertificationCalculationMonth: MeasuringDeviceCertificationCalculationMonth
): MeasuringDeviceCertificationCalculationMonth => ({
  ...measuringDeviceCertificationCalculationMonth,
  cost: convertFromKopToRub(measuringDeviceCertificationCalculationMonth.cost),
  price: convertFromKopToRub(measuringDeviceCertificationCalculationMonth.price)
});

const basedUrl = 'measuringDeviceCertificationCalculation';
const measuringDeviceCertificationCalculation = new CommonApi<MeasuringDeviceCertificationCalculation>(
  basedUrl,
  'расчет потребности в тарировке цистерн',
  convertDataToServer,
  convertDataFromServer
);

const calculate = async (vehiclePlanId: number) => {
  const updated = await fetchRequest.put(
    `/${basedUrl}/calculate/${vehiclePlanId}`
  );
  if (updated) return updated;
  throw new Error(`Не удалось произвести расчет`);
};

const changeStatus = async (
  vehiclePlanId: number,
  status: CalculationStatus
) => {
  const updated = await fetchRequest.put(`/${basedUrl}/changeStatus`, {
    vehiclePlanId,
    status
  });
  if (updated) return updated;
  throw new Error(`Не удалось сменить статус расчет`);
};

const print = async (vehiclePlanId: number, filter: any) => {
  const printed = await downloadRequestWithToken(`/${basedUrl}/print`, {
    ...filter,
    vehiclePlanId
  });
  if (printed) return printed;
  throw new Error('Не удалось загрузить файл');
};

const updateMonth = async monthData => {
  const updated = await fetchRequest.put(
    `/MeasuringDeviceCertificationCalculation/correct`,
    convertDataToServerMeasuringDeviceCertificationCalculationMonth(monthData)
  );
  if (!updated) {
    throw new Error(`Не удалось сменить статус расчет`);
  }
};

export default {
  ...measuringDeviceCertificationCalculation,
  calculate,
  changeStatus,
  print,
  updateMonth
};
