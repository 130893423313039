//@flow

import React, { Component } from 'react';
import notification from 'antd/lib/notification';

import type { Tire } from './../../../../lib/types';
import { tireApi } from './../../../../lib/api';
import { notificationLoading } from './../../../../components/Notifications';

type Props = {
  tire?: Tire,
  afterSubmit: () => void | Promise<void>
};

class CopyTireButton extends Component<Props> {
  copyTire = async () => {
    const { tire, afterSubmit } = this.props;
    try {
      if (!tire) {
        return;
      }
      notificationLoading({
        message: 'Копирование...',
        key: 'copying'
      });
      const newCopy = {
        batchNumber: tire.batchNumber,
        code: tire.code,
        name: tire.name,
        radius: tire.radius,
        normKilometrage: tire.normKilometrage,
        orgUnitId: tire.orgUnitId,
        brand: tire.brand,
        isArchive: tire.isArchive,
        width: tire.width
      };
      await tireApi.addTire(newCopy);

      notification.success({
        message: 'Шина скопирована',
        description: `Копия шины успешно создана`
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('copying');
      if (afterSubmit instanceof Function) {
        afterSubmit();
      }
    }
  };

  render() {
    return <div onClick={this.copyTire}>Копировать</div>;
  }
}

export default CopyTireButton;
