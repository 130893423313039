// @flow
import React from 'react';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {StockPart} from '../../lib/types';
import {getPathWithHistoryParams, multipliedBy, navigate} from '../../lib/helpers';
import {stockPartApi} from '../../lib/api';

import {Card} from './../../components';
import Breadcrumbs, {Crumb} from '../../components/layout/Breadcrumbs';
import Grid, {GridItem} from './../../components/layout/Grid';
import {Header, Panel, Section} from '../../components/layout';
import {notificationLoading} from '../../components/Notifications';

const StyledPanel = styled(Panel)`
  padding-top: 0;
`;
const SectionContent = styled.div`
  padding: 16px;
`;

const { Field } = Card;

type Props = {
  stockPartId: number
};

type State = {
  stockPart: ?StockPart
};

export class StockPartForm extends React.Component<Props, State> {
  state = {
    stockPart: null
  };

  async componentDidMount() {
    const { stockPartId } = this.props;
    try {
      if (stockPartId) {
        const stockPart = await stockPartApi.getStockPart(stockPartId);
        this.setState({ stockPart });
      }
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    }
  }

  handleSubmit = async (values: StockPart) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      if (values.id) {
        await stockPartApi.updateStockPart(values);
      } else {
        await stockPartApi.addStockPart(values);
      }
      navigate(`/admin/stock-part`, true);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  handleCancel = () => navigate('/admin/stock-part', true);

  render() {
    const { stockPart } = this.state;
    return (
      <>
        <Header
          left={
            <Breadcrumbs>
              <Crumb to={getPathWithHistoryParams('/admin/stock-part')}>
                Справочник материалов для проведения работ хоз.спопсобом
              </Crumb>
              <Crumb>Деталь №{stockPart && stockPart.id}</Crumb>
            </Breadcrumbs>
          }
        />
        <StyledPanel>
          <h1>{stockPart ? `Деталь №${stockPart.id}` : 'Новая деталь'}</h1>
        </StyledPanel>
        <Section>
          {stockPart && (
            <SectionContent>
              <Grid gutter="16px">
                <GridItem>
                  <Field label="Код МТР">{stockPart.mtr.mnemocode}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Наименование детали">
                    {stockPart.mtr.name}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Мнемокод модификации">
                    {stockPart.modification?.mnemocode || '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Модификация номеклатуры">
                    {stockPart.modification?.name || '-'}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Склад получателя">
                    {stockPart.stock.mnemocode}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="Номер расходной накладной на отпуск в подразделение">
                    {stockPart.invoiceNumber}
                  </Field>
                </GridItem>
                <GridItem>
                  <Field label="№ партии">{stockPart.batchNumber}</Field>
                </GridItem>
                <GridItem>
                  <Field label="№ серии">{stockPart.serialNumber}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Стоимость ед.">{stockPart.cost}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Количество">{stockPart.count}</Field>
                </GridItem>
                <GridItem>
                  <Field label="Сумма">
                    {multipliedBy(stockPart.cost, stockPart.count)}
                  </Field>
                </GridItem>
              </Grid>
            </SectionContent>
          )}
        </Section>
      </>
    );
  }
}

export default StockPartForm;
