// @flow
import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';

import {formatDateTimeToISOString} from '../../../../../lib/helpers';

import {Filter, FilterButtonsContainer, FilterContainer, FilterItem, OrgUnitSelect} from '../../../../../components';
import Field from '../../../../../components/card/Field';
import Select from '../../../../../components/ui/Select';
import {monthsNamesTranslitEnum} from '../../../../../lib/enum';

const { Option } = Select;

const { RangePicker } = DatePicker;

const SpoilerContent = styled.div`
  padding: 16px;
`;

type Props = {
  filter: any,
  cleanFilter: Function,
  applyFilter: Function
};

export const ListFilter = ({ filter, cleanFilter, applyFilter }: Props) => (
  <SpoilerContent>
    <FilterContainer>
      <Filter
        cleanFilter={cleanFilter}
        applyFilter={applyFilter}
        initialValues={filter}
      >
        {({ values, changeValue, applyFilter, cleanFilter }) => (
          <>
            <FilterItem>
              <Field mBottomNone label="Филиал">
                <OrgUnitSelect
                  size="small"
                  onChange={value => changeValue('nodeId', value)}
                  value={values.nodeId}
                  style={{ marginBottom: '-4px' }}
                  isFilter={true}
                />
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone label="Месяц">
                <Select
                  value={values.month}
                  onChange={value => changeValue('month', value)}
                  size="small"
                  style={{ marginBottom: '-4px' }}
                  allowClear
                >
                  {Object.keys(monthsNamesTranslitEnum).map(key => (
                    <Option key={key} value={key}>
                      {monthsNamesTranslitEnum[key]}
                    </Option>
                  ))}
                </Select>
              </Field>
            </FilterItem>
            <FilterItem>
              <Field mBottomNone label="Период создания документа">
                <RangePicker
                  size="small"
                  format="DD.MM.YYYY"
                  placeholder={['Начало', 'Конец']}
                  value={[
                    values.startDate ? moment.utc(values.startDate) : null,
                    values.endDate ? moment.utc(values.endDate) : null
                  ]}
                  onChange={(value, dateString) => {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                  }}
                />
              </Field>
            </FilterItem>
            <FilterButtonsContainer
              style={{ textAlign: 'left', marginTop: '32px' }}
              applyFilter={applyFilter}
              cleanFilter={cleanFilter}
            />
          </>
        )}
      </Filter>
    </FilterContainer>
  </SpoilerContent>
);

export default ListFilter;
