/* trip.js | API ПЛ НТС */
// @flow
import queryString from 'query-string';
import type {KilometrageInfo, Trip, TripRange, TripStatus as TripStatusType, Vehicle} from './../types';
import {downloadRequestWithToken, fetchRequest, initialFetchListParams} from './';
import type {FetchListParams, ListResponse} from './index';
import {
  convertFromHourToSecond,
  convertFromKmToM,
  convertFromKopToRub,
  convertFromMToKm,
  convertFromRubToKop,
  convertFromSecondToHour,
} from './../helpers';
import {convertDataFromServer as convertVehicleDataFromServer} from './vehicle';
import type {TripFilterParams} from '../../containers/Trips/components/Filter';

export const convertDataFromServer = (trip: Trip) => ({
  ...trip,
  odometer: convertFromMToKm(trip.odometer),
  odometerAtStart: convertFromMToKm(trip.odometerAtStart),
  odometerAtEnd: convertFromMToKm(trip.odometerAtEnd),
  distanceAtStart: convertFromMToKm(trip.distanceAtStart),
  distanceAtEnd: convertFromMToKm(trip.distanceAtEnd),
  totalWorkingTime: convertFromSecondToHour(trip.totalWorkingTime),
  movementTime: convertFromSecondToHour(trip.movementTime),
  movementPrice: convertFromKopToRub(trip.movementPrice),
  idleTime: convertFromSecondToHour(trip.idleTime),
  idlePrice: convertFromKopToRub(trip.idlePrice),
  onHoldTime: convertFromSecondToHour(trip.onHoldTime),
  onHoldPrice: convertFromKopToRub(trip.onHoldPrice),
  pricePerKm: convertFromKopToRub(trip.pricePerKm),
  totalPrice: convertFromKopToRub(trip.totalPrice),
  airHeaterTime: convertFromSecondToHour(trip.airHeaterTime),
  airHeaterRate: convertFromKopToRub(trip.airHeaterRate),
  liquidHeaterTime: convertFromSecondToHour(trip.liquidHeaterTime),
  liquidHeaterRate: convertFromKopToRub(trip.liquidHeaterRate),
  powerGeneratorTime: convertFromSecondToHour(trip.powerGeneratorTime),
  powerGeneratorRate: convertFromKopToRub(trip.powerGeneratorRate),
  winchTime: convertFromSecondToHour(trip.winchTime),
  winchRate: convertFromKopToRub(trip.winchRate),
  trailerRate: convertFromKopToRub(trip.trailerRate),
  vehicle: trip.vehicle
    ? convertVehicleDataFromServer(trip.vehicle)
    : trip.vehicle,
  tripRanges: trip.tripRanges
    ? (trip.tripRanges.map((tripRange: TripRange) => ({
        ...tripRange,
        amount: convertFromMToKm(tripRange.amount)
      })): TripRange[])
    : trip.tripRanges,
  vehicleMachineHoursAtStart: trip.vehicleMachineHoursAtStart
    ? convertFromSecondToHour(trip.vehicleMachineHoursAtStart)
    : 0,
  vehicleMachineHoursAtEnd: convertFromSecondToHour(
    trip.vehicleMachineHoursAtEnd
  ),
  attachedEquipmentMachineHours: convertFromSecondToHour(
    trip.attachedEquipmentMachineHours
  ),
  tripAttachedEquipmentLinks: trip.tripAttachedEquipmentLinks.map(link => ({
    ...link,
    machineHours: convertFromSecondToHour(link.machineHours)
  })),
  currentOdometerAtEnd: convertFromMToKm(trip.currentOdometerAtEnd),
  currentOdometerAtStart: convertFromMToKm(trip.currentOdometerAtStart),
  currentVehicleMachineHoursAtEnd: convertFromSecondToHour(trip.currentVehicleMachineHoursAtEnd),
  currentVehicleMachineHoursAtStart: convertFromSecondToHour(trip.currentVehicleMachineHoursAtStart),
});

const convertDataToServer = (trip: Trip) => ({
  ...trip,
  attachedEquipmentMachineHours: convertFromHourToSecond(
    trip.attachedEquipmentMachineHours
  ),
  odometer: convertFromKmToM(trip.odometer),
  odometerAtStart: convertFromKmToM(trip.odometerAtStart),
  odometerAtEnd: convertFromKmToM(trip.odometerAtEnd),
  distanceAtStart: convertFromKmToM(trip.distanceAtStart),
  distanceAtEnd: convertFromKmToM(trip.distanceAtEnd),
  vehicleMachineHoursAtStart: convertFromHourToSecond(
    trip.vehicleMachineHoursAtStart
  ),
  vehicleMachineHoursAtEnd: convertFromHourToSecond(
    trip.vehicleMachineHoursAtEnd
  ),
  totalWorkingTime: convertFromHourToSecond(trip.totalWorkingTime),
  movementTime: convertFromHourToSecond(trip.movementTime),
  movementPrice: convertFromRubToKop(trip.movementPrice),
  idleTime: convertFromHourToSecond(trip.idleTime),
  idlePrice: convertFromRubToKop(trip.idlePrice),
  onHoldTime: convertFromHourToSecond(trip.onHoldTime),
  onHoldPrice: convertFromRubToKop(trip.onHoldPrice),
  pricePerKm: convertFromRubToKop(trip.pricePerKm),
  totalPrice: convertFromRubToKop(trip.totalPrice),
  airHeaterTime: convertFromHourToSecond(trip.airHeaterTime),
  airHeaterRate: convertFromRubToKop(trip.airHeaterRate),
  liquidHeaterTime: convertFromHourToSecond(trip.liquidHeaterTime),
  liquidHeaterRate: convertFromRubToKop(trip.liquidHeaterRate),
  powerGeneratorTime: convertFromHourToSecond(trip.powerGeneratorTime),
  powerGeneratorRate: convertFromRubToKop(trip.powerGeneratorRate),
  winchTime: convertFromHourToSecond(trip.winchTime),
  winchRate: convertFromRubToKop(trip.winchRate),
  trailerRate: convertFromRubToKop(trip.trailerRate),
  tripAttachedEquipmentLinks: trip.tripAttachedEquipmentLinks.map(link => ({
    ...link,
    machineHours: convertFromHourToSecond(link.machineHours)
  })),
  currentOdometerAtEnd: convertFromKmToM(trip.currentOdometerAtEnd),
  currentOdometerAtStart: convertFromKmToM(trip.currentOdometerAtStart),
  currentVehicleMachineHoursAtEnd: convertFromHourToSecond(trip.currentVehicleMachineHoursAtEnd),
  currentVehicleMachineHoursAtStart: convertFromHourToSecond(trip.currentVehicleMachineHoursAtStart),
});

export const updateTrip = async (trip: Trip): Promise<Trip> => {
  const updated = await fetchRequest.put('/trip', convertDataToServer(trip));
  if (updated) return convertDataFromServer(updated);
  throw new Error('Не удалось обновить путевой лист');
};

export const updateAct = async (
  params: any,
  data: any
): Promise<ListResponse<Trip>> => {
  const tripList = await fetchRequest.put(
    `/trip/updateActId?${queryString.stringify(params)}`,
    data
  );
  if (tripList) {
    return {
      ...tripList,
      data: tripList.data.map(convertDataFromServer)
    };
  }
  throw new Error('Не удалось закрепить акт');
};

export const fetchTrips = async (
  params: FetchListParams<TripFilterParams> = initialFetchListParams
): Promise<ListResponse<Trip>> => {
  const tripList = await fetchRequest.get('/trip', {
    ...initialFetchListParams,
    ...params
  });
  return {
    ...tripList,
    data: tripList.data.map(convertDataFromServer)
  };
};

export const fetchTrip = async (
  tripId: number,
  params: any = {}
): Promise<Trip> => {
  const trip = await fetchRequest.get(`/trip/${tripId}`, params);
  if (trip) {
    return convertDataFromServer(trip);
  }
  throw new Error('Не удалось загрузить путевой лист');
};

export const deleteTrip = async (id: number): Promise<Trip> => {
  const deleted = await fetchRequest.delete(`/trip/${id}`);
  if (deleted) return convertDataFromServer(deleted);
  throw new Error('Не удалось удалить путевой лист');
};

export type TripStatus = {
  status: TripStatusType,
  id: number
};

export const changeStatus = async (tripStatus: TripStatus): Promise<Trip> => {
  const trip = await fetchRequest.post('/trip/changeStatus', tripStatus);
  if (trip) return convertDataFromServer(trip);
  throw new Error('Не удалось изменить статус');
};

export const fetchTripFreeVehicles = async (
  tripId: number
): Promise<Array<Vehicle>> => {
  const vehicles = await fetchRequest.get(`/trip/${tripId}/freeVehicles`);
  if (vehicles) {
    return vehicles;
  }
  throw new Error('Не удалось загрузить список доступных ТС');
};

export const printTrip = async (id: number): Promise<boolean> => {
  const print = await downloadRequestWithToken(`/trip/${id}/print`);
  if (print) return print;
  throw new Error('Не удалось загрузить путевой лист');
};

export const imprintTrip = async (id: number): Promise<boolean> => {
  const print = await downloadRequestWithToken(`/trip/${id}/imprint`);
  if (print) return print;
  throw new Error('Не удалось загрузить путевой лист');
};

/**
 * Обновление пробегов через АвтоГРАФ
 */
export const getTripKilometrage = async (
  id: number
): Promise<KilometrageInfo> =>
  await fetchRequest.get(`/trip/${id}/getKilometrage`);

export const getTotalSum = async (params: any = {}): Promise<number> => {
  const sum = await fetchRequest.get(`/trip/totalSum`, params);
  if (sum || sum === 0) return convertFromKopToRub(sum);
  throw new Error('Не удалось получить общую сумму');
};

export default {
  updateAct,
  getTotalSum,
  updateTrip,
  fetchTrips,
  deleteTrip,
  fetchTrip,
  changeStatus,
  fetchTripFreeVehicles,
  printTrip,
  imprintTrip,
  getTripKilometrage
};
