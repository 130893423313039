// @flow

import React, {Component} from 'react';
import qs from 'query-string';
import Popconfirm from 'antd/lib/popconfirm';
import Button from 'antd/lib/button';
import {Link} from '@reach/router';
import styled from 'styled-components';

import notification from 'antd/lib/notification';

import type {
  ListState,
  Maintenance,
  MaintenanceWorkType,
  OrderContractorType,
  RepairPlan,
  UserAccess,
} from './../../lib/types';
import {maintenanceStatusesTypes, orderContractorTypes, repairPlanTypes} from './../../lib/enum';
import {applyMaskToValue, formatDateTimeToString, getListInitialState, navigate} from './../../lib/helpers';
import {maintenanceApi} from './../../lib/api';

import {Filter} from './components';

import type {MaintenanceFilterParams} from './components/Filter';
import {ButtonsRow, Icon, Operations, Table} from './../../components/ui';
import {Section} from './../../components/layout';
import Header from '../../components/layout/Header';
import {notificationLoading} from './../../components/Notifications';
import {formatLicensePlateMask} from '../../components/masked-inputs/LicensePlateInput';

import {withUserAccess} from './../withUserAccess';

import {addAccessRight, editAccessRight} from './accessRight';

type Props = {
  userAccess: UserAccess[],
  location: Location & { state: { page: number } }
};

type State = ListState<Maintenance> & {
  filter: MaintenanceFilterParams
};
const StyledIcon = styled(Icon)`
  margin: 0 5px;
  color: #1890ff;
  cursor: pointer;
`;

export const COLUMNS = [
  {
    title: '№',
    dataIndex: 'id'
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => maintenanceStatusesTypes[status]
  },
  {
    title: 'Тип',
    dataIndex: 'maintenanceWorkType',
    key: 'maintenanceWorkType',
    render: (maintenanceWorkType: MaintenanceWorkType) =>
      maintenanceWorkType.name
  },
  {
    title: 'График работ',
    dataIndex: 'repairPlan',
    key: 'repairPlan',
    render: (type: RepairPlan) => repairPlanTypes[type]
  },
  {
    title: 'Способ выполнения',
    dataIndex: 'orderContractorType',
    key: 'orderContractorType',
    render: (type: OrderContractorType) => orderContractorTypes[type]
  },
  {
    title: 'Направление расходов',
    dataIndex: 'expenseDirection.name',
    key: 'expenseDirection'
  },
  {
    title: 'Гос. номер',
    dataIndex: 'vehicle.licensePlate',
    render: (licensePlate: ?string) =>
      licensePlate && applyMaskToValue(licensePlate, formatLicensePlateMask)
  },
  {
    title: 'Марка',
    dataIndex: 'vehicle.vehicleModel.brandName'
  },
  {
    title: 'Модель',
    dataIndex: 'vehicle.vehicleModel.name'
  },
  {
    title: 'Дата создания',
    dataIndex: 'created',
    render: (dateCreated: string) =>
      `${formatDateTimeToString(dateCreated, 'DD.MM.YYYY')}`
  }
];

class MaintenanceList extends Component<Props, State> {
  state = {
    ...getListInitialState(),
    filter: {}
  };

  columns = [...COLUMNS];

  componentDidMount() {
    const { page, ...filter } = qs.parse(window.location.search);
    this.setState(
      {
        filter: { ...filter },
        page
      },
      () => this.fetchMaintenances(page)
    );
    if (this.canAdd()) {
      this.columns.push({
        title: ' ',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: Maintenance): any => (
            <Operations>
              <Popconfirm
                title="Вы уверены, что хотите удалить карточку работы?"
                okText="Ок"
                cancelText="Отмена"
                onConfirm={async () => await this.deleteMaintenance(record.id)}
              >
                <StyledIcon type="x" />
              </Popconfirm>
            </Operations>
          )
      });
    }
  }

  fetchMaintenances = async (page: number) => {
    const { filter } = this.state;
    this.setState({ loading: true });
    const data = await maintenanceApi.fetchMaintenances({
      ...filter,
      page
    });
    this.setState({ ...data, loading: false, page });
  };

  async deleteMaintenance(id: number) {
    try {
      notificationLoading({
        message: 'Удаление...',
        key: 'deleting'
      });
      await maintenanceApi.deleteMaintenance(id);
      await this.fetchMaintenances(this.state.page);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('deleting');
    }
  }

  applyFilter = (filter: MaintenanceFilterParams) => {
    const { page } = this.state;
    this.setState({ filter, loading: true }, () =>
      this.fetchMaintenances(page)
    );
  };

  cleanFilter = () => {
    const { page } = this.state;
    this.setState({ filter: {}, loading: true }, () =>
      this.fetchMaintenances(page)
    );
  };

  handlePrint = async () => {
    const { filter } = this.state;
    try {
      notificationLoading({
        message: 'Загрузка файла отчета...',
        key: 'print'
      });
      await maintenanceApi.print(filter, `График проведения текущего то.xlsx`);
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('print');
    }
  };

  handleRowClick = (id: number) =>
    this.canEdit()
      ? navigate(`/maintenances/${id}`)
      : navigate(`/maintenances/read/${id}`);

  canAdd = () =>
    this.props.userAccess.some(access => addAccessRight.includes(access));

  canEdit = () =>
    this.props.userAccess.some(access => editAccessRight.includes(access));

  render() {
    const { location } = this.props;
    const { data, totalCount, pageSize, page, loading, filter } = this.state;
    const canAdd = this.canAdd();
    return (
      <>
        <Header
          left={<h1>Ремонт</h1>}
          right={
            <ButtonsRow>
              <Button onClick={this.handlePrint}>Печать графика ТО</Button>
              {canAdd && (
                <Link to={`/maintenances/new`}>
                  <Button type="primary" data-cy="addMaintenance">
                    Создать
                  </Button>
                </Link>
              )}
            </ButtonsRow>
          }
        />
        <Section>
          <Filter
            filter={filter}
            cleanFilter={this.cleanFilter}
            applyFilter={this.applyFilter}
          />
          <Table
            columns={this.columns}
            data={data}
            onRow={record => ({
              onClick: () => this.handleRowClick(record.id)
            })}
            rowKey="id"
            fetch={this.fetchMaintenances}
            loading={loading}
            pagination={{
              page,
              pageSize,
              totalCount,
              location
            }}
          />
        </Section>
      </>
    );
  }
}

export default withUserAccess(MaintenanceList);
