// @flow
import React from 'react';

import type { OverhaulType } from '../../../lib/types';
import {
  withEmptyRow,
  toLocalStringRu,
  multipliedBy
} from '../../../lib/helpers';

import CommonForm, {
  type CommonFormProps
} from '../../../components/hoc/common/handbook/CommonForm';
import Grid, { GridItem } from '../../../components/layout/Grid';
import { FormField } from '../../../components/Form';
import VehicleModelSelect from '../../../components/selects/VehicleModelSelect';
import VehicleIssueGroupSelect from '../../../components/selects/VehicleIssueGroupSelect';
import { Section, SectionTitle } from '../../../components/layout';
import {
  SectionContent,
  StyledInput,
  StyledInputNumber
} from '../../../components/hoc/common/components/elements';
import { Selects } from '../../../components';
import { ListTable } from '../../../components/ui';

const { MdmSelect, MaintenanceOperationSelect } = Selects;

export default (props: CommonFormProps<$Shape<OverhaulType>>) => {
  return (
    <CommonForm {...props}>
      {({ setFieldValue, values }) => {
        return (
          <>
            <Section noWrapMe>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      required
                      fast
                      label="Модель ТС"
                      name="vehicleModelId"
                    >
                      {({ value, name }) => (
                        <VehicleModelSelect
                          value={value}
                          name={name}
                          onChange={async (value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      required
                      fast
                      label="Вид работы по капитальному ремонту"
                      name="vehicleIssueGroupId"
                    >
                      {({ value, name }) => (
                        <VehicleIssueGroupSelect
                          value={value}
                          name={name}
                          onChange={async (value: number) =>
                            setFieldValue(name, value)
                          }
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Section noWrapMe>
              <SectionTitle divider>Операции по ремонту</SectionTitle>
              <ListTable
                showHeader={false}
                columns={[
                  {
                    key: 'operationId',
                    render: (id: number, data: any, index: number) => {
                      return (
                        <MaintenanceOperationSelect
                          value={id}
                          onChange={value => {
                            if (value) {
                              setFieldValue(
                                `operations[${index}].operationId`,
                                value
                              );
                            } else {
                              setFieldValue(
                                `operations`,
                                values.operations.filter(
                                  (val, indexRow) => index !== indexRow
                                )
                              );
                            }
                          }}
                        />
                      );
                    }
                  }
                ]}
                data={withEmptyRow(values.operations)}
              />
            </Section>
            <Section noWrapMe>
              <SectionTitle divider>
                Планируемый перечень запасных запчастей
              </SectionTitle>
              <SectionContent>
                <ListTable
                  columns={[
                    {
                      title: 'Наименование материала',
                      key: 'mnemocode',
                      width: '33%',
                      render: (mnemocode: string, data, index) => (
                        <MdmSelect
                          value={mnemocode}
                          hideMnemocode
                          disableNonePrice
                          onChange={(data, option) => {
                            if (option) {
                              setFieldValue(
                                `mtrs[${index}].mtrId`,
                                option.props.mdm.id
                              );
                              setFieldValue(
                                `mtrs[${index}].name`,
                                option.props.mdm.name
                              );
                              setFieldValue(
                                `mtrs[${index}].mnemocode`,
                                option.props.mdm.mnemocode
                              );
                              setFieldValue(
                                `mtrs[${index}].currentCost`,
                                option.props.mdm.currentCost
                              );
                            } else {
                              setFieldValue(
                                `mtrs`,
                                values.mtrs.filter(
                                  (val, indexRow) => index !== indexRow
                                )
                              );
                            }
                          }}
                        />
                      )
                    },
                    {
                      title: 'Мнемокод',
                      key: 'mnemocode',
                      render: (id: number) => {
                        return <StyledInput disabled value={id} />;
                      }
                    },
                    {
                      title: 'Кол-во',
                      key: 'count',
                      render: (count: number, data, index) => {
                        return (
                          <StyledInputNumber
                            value={count}
                            min={0}
                            onChange={value =>
                              setFieldValue(`mtrs[${index}].count`, value)
                            }
                          />
                        );
                      }
                    },
                    {
                      title: 'Цена',
                      key: 'currentCost',
                      render: currentCost => (
                        <StyledInput
                          value={toLocalStringRu(currentCost)}
                          disabled
                        />
                      )
                    },
                    {
                      title: 'Стоимость',
                      key: 'currentCost',
                      render: (currentCost: number, record: any) => (
                        <StyledInput
                          value={toLocalStringRu(
                            multipliedBy(currentCost, record.count)
                          )}
                          disabled
                        />
                      )
                    }
                  ]}
                  data={withEmptyRow(values.mtrs)}
                />
              </SectionContent>
            </Section>
          </>
        );
      }}
    </CommonForm>
  );
};
