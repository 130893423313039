// @flow
import React from 'react';
import type {FormikProps} from 'formik';
import styled from 'styled-components';
import moment from 'moment';

import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import Input from 'antd/lib/input';
import {accessTypeEnum} from '../../../../lib/enum';

import type {ExpenseDirection, UserAccess} from './../../../../lib/types';
import {formatDateTimeToISOString} from '../../../../lib/helpers';

import {Form, Selects} from '../../../../components';
import {Footer, Section} from '../../../../components/layout';
import Grid, {GridItem} from '../../../../components/layout/Grid';
import type {FormFieldType} from './../../../../components/Form';
import CancelButton from '../../../../components/CancelButton';
import Checkbox from 'antd/lib/checkbox';

const SectionContent = styled.div`
  padding: 16px;
`;
const {
  ExpenseDirectionTypeSelect,
  BudgetLineItemSelect,
  YesNoSelect
} = Selects;

type FormProps = {
  expenseDirection: ?ExpenseDirection,
  userAccess: UserAccess[],
  onSubmit: Function,
  onCancel: () => void
};

export default ({
  expenseDirection,
  userAccess,
  onSubmit,
  onCancel
}: FormProps) => {
  const canEditAdditionalNeed = userAccess.some(access =>
    [
      accessTypeEnum.admin,
      accessTypeEnum.adminBranch,
      accessTypeEnum.handlingPropertyIsAdditionalNeedOfExpenseDirections
    ].includes(access)
  );

  return (
    <Form initialValues={expenseDirection} onSubmit={onSubmit}>
      {(FormField: FormFieldType, formikProps: FormikProps) => {
        const { handleSubmit, dirty, setFieldValue, values } = formikProps;
        return (
          <form onSubmit={handleSubmit}>
            <Section>
              <SectionContent>
                <Grid gutter="16px">
                  <GridItem>
                    <FormField
                      label="Наименование расхода"
                      name="name"
                      required
                      fast
                    >
                      {field => (
                        <Input
                          {...field}
                          placeholder="Введите наименование расходов"
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Статьи бюдж. формы"
                      name="budgetLineItemId"
                      required
                    >
                      {({ name, value }) => (
                        <BudgetLineItemSelect
                          value={value}
                          onChange={value => setFieldValue(name, value)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      label="Мнемокод направления"
                      name="cognosId"
                      required
                      fast
                    >
                      {field => <Input {...field} />}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="type" required label="Тип направления">
                      {({ name, value }) => (
                        <ExpenseDirectionTypeSelect
                          value={value}
                          onChange={type => {
                            setFieldValue(name, type);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="startDate" required label="Дата начала">
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          placeholder="Введите дату"
                          value={value ? moment.utc(value) : value}
                          onChange={(value, dateString) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="endDate" label="Дата окончания">
                      {({ name, value }) => (
                        <DatePicker
                          format="DD.MM.YYYY"
                          placeholder="Введите дату"
                          value={value ? moment.utc(value) : value}
                          disabled={values.startDate ? false : true}
                          disabledDate={(date: string) =>
                            values.startDate
                              ? moment
                                  .utc(date)
                                  .startOf('day')
                                  .isSameOrBefore(moment.utc(values.startDate))
                              : false
                          }
                          onChange={(value, dateString) => {
                            setFieldValue(
                              name,
                              formatDateTimeToISOString(value, dateString)
                            );
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="receiveContractsData"
                      label="Получать данные по договорам"
                    >
                      {({ name, value }) => (
                        <YesNoSelect
                          value={value}
                          onChange={data => {
                            setFieldValue(name, data);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField
                      name="isAdditionalNeed"
                      label="Доп. потребность"
                      required
                    >
                      {({ name, value }) => (
                        <YesNoSelect
                          value={value}
                          disabled={!canEditAdditionalNeed}
                          onChange={data => {
                            setFieldValue(name, data);
                          }}
                        />
                      )}
                    </FormField>
                  </GridItem>
                  <GridItem>
                    <FormField name="isDisableForCognos" label="Не передавать в Когнос">
                      {({ name, value }) => (
                        <Checkbox
                          checked={value}
                          onChange={({target}) => setFieldValue(name, target.checked)}
                        />
                      )}
                    </FormField>
                  </GridItem>
                </Grid>
              </SectionContent>
            </Section>
            <Footer>
              <Button type="primary" htmlType="submit" data-cy="save">
                Сохранить
              </Button>
              <CancelButton dirty={dirty} onClick={onCancel}>
                Отменить
              </CancelButton>
            </Footer>
          </form>
        );
      }}
    </Form>
  );
};
