// @flow
import type { Place } from '../types';
import CommonApi from './../commonApi';

let basedUrl = 'cognosLog';

const cognosLog = new CommonApi<Place>(basedUrl, 'Отправка в Cognos');

export default {
  ...cognosLog
};
