// @flow
import React from 'react';
import Button from 'antd/lib/button';
import DatePicker from 'antd/lib/date-picker';
import moment from 'moment';
import InputNumber from 'antd/lib/input-number';

import { Filter } from '../../../../components';
import type { FilterChildrenParams } from '../../../../components/hoc/Filter';
import Grid, { GridItem } from '../../../../components/layout/Grid';
import Field from '../../../../components/card/Field';
import { formatDateTimeToISOString } from '../../../../lib/helpers';
import { isRangeInOneMonth } from '../../lib';
import { FilterButtons } from '../../elements';

const { MonthPicker, RangePicker } = DatePicker;

type FilterMode = 'byPeriod' | 'byMonth';

export type YearDailyBudgetFilterParams = $Shape<{
  startDate: string,
  endDate: string,
  mode: FilterMode
}>;

type Props = {
  filter: YearDailyBudgetFilterParams,
  cleanFilter: Function,
  applyFilter: Function
};

export const ContractVehicleDailyBudgetFilter = ({
  filter,
  cleanFilter,
  applyFilter
}: Props) => (
  <Grid
    gutter="16px"
    media={[
      {
        size: 'md',
        cols: 3
      },
      {
        size: 'lg',
        cols: ['1fr', '1fr', '100px', '1fr', '1fr']
      }
    ]}
  >
    <Filter
      cleanFilter={cleanFilter}
      applyFilter={applyFilter}
      initialValues={filter}
    >
      {({
        values,
        changeValue,
        applyFilter,
        cleanFilter
      }: FilterChildrenParams<any>) => (
        <>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="Месяц">
              <MonthPicker
                size="small"
                format="MMMM YYYY"
                placeholder="Выберите месяц"
                value={moment(values.startDate)}
                onChange={value => {
                  const startDate = moment
                    .utc(value || undefined)
                    .startOf('month');
                  const endDate = moment.utc(value || undefined).endOf('month');
                  changeValue('startDate', startDate.toISOString());
                  changeValue('endDate', endDate.toISOString());
                  changeValue('mode', 'byMonth');
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="Отчетный период">
              <RangePicker
                size="small"
                format="DD.MM.YYYY"
                placeholder={['Начало', 'Конец']}
                value={[
                  values.startDate ? moment.utc(values.startDate) : null,
                  values.endDate ? moment.utc(values.endDate) : null
                ]}
                onChange={(value, dateString) => {
                  if (value.length) {
                    const [startDate, endDate] = value;
                    const [startDateString, endDateString] = dateString;
                    changeValue(
                      'startDate',
                      formatDateTimeToISOString(startDate, startDateString)
                    );
                    changeValue(
                      'endDate',
                      formatDateTimeToISOString(endDate, endDateString)
                    );
                    if (isRangeInOneMonth(startDate, endDate)) {
                      changeValue('mode', 'byMonth');
                    } else {
                      changeValue('mode', 'byPeriod');
                    }
                  } else {
                    const startDate = moment.utc().startOf('month');
                    const endDate = moment.utc().endOf('month');
                    changeValue('startDate', startDate.toISOString());
                    changeValue('endDate', endDate.toISOString());
                    changeValue('mode', 'byMonth');
                  }
                }}
              />
            </Field>
          </GridItem>
          <GridItem>
            <Field valueStyle={{ marginBottom: '0px' }} label="Год">
              <InputNumber
                size="small"
                min={2018}
                // max={moment().year() + 1}
                value={moment.utc(values.endDate).year()}
                onChange={(value: number) => {
                  const year = moment.utc().year(value);
                  changeValue('startDate', year.startOf('year').toISOString());
                  changeValue('endDate', year.endOf('year').toISOString());
                }}
              />
            </Field>
          </GridItem>
          <FilterButtons>
            <Button type="primary" onClick={applyFilter}>
              Применить
            </Button>
            <Button type="plain" onClick={cleanFilter}>
              Очистить
            </Button>
          </FilterButtons>
        </>
      )}
    </Filter>
  </Grid>
);

export default ContractVehicleDailyBudgetFilter;
