// @flow
import React from 'react';

import type { AssignmentLimit } from './../../../../../../lib/types';
import {
  MONTH,
  convertContractVehicleToString,
  formatRub,
  toFixed,
  getValueObject,
  navigate
} from './../../../../../../lib/helpers';

import { Grid } from './../../../../../../components/layout';
import { GridItem } from './../../../../../../components/layout/Grid';

import { ListHeader, HeaderCell, HeaderBoldCell } from './../../elements';

const COLUMNS_STYLE = {
  alignSelf: 'center'
};

export const COLUMNS = [
  {
    title: 'Статья',
    dataIndex: 'budgetLine'
  },
  {
    title: 'Ед. измерения',
    dataIndex: 'measure'
  },
  {
    title: 'Январь',
    dataIndex: 'january',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Февраль',
    dataIndex: 'february',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Март',
    dataIndex: 'march',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Апрель',
    dataIndex: 'april',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Май',
    dataIndex: 'may',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Июнь',
    dataIndex: 'june',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Июль',
    dataIndex: 'july',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Август',
    dataIndex: 'august',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Сентябрь',
    dataIndex: 'september',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Октябрь',
    dataIndex: 'october',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Ноябрь',
    dataIndex: 'november',
    render: (sum: number) => toFixed(sum)
  },
  {
    title: 'Декабрь',
    dataIndex: 'december',
    render: (sum: number) => toFixed(sum)
  }
];

export const listHeaderBudgetList = () => (
  <ListHeader
    style={{ minWidth: '2500px' }}
    rows={2}
    cols={['300px', '300px', '200px', ...Array(13).fill('1fr')]}
  >
    <GridItem
      cellUnion={{
        gridColumn: '1',
        gridRow: '1 / span 2'
      }}
      style={COLUMNS_STYLE}
    >
      <HeaderBoldCell>ТС</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: '2',
        gridRow: '1 / span 2'
      }}
      style={{
        alignSelf: 'center'
      }}
    >
      <HeaderBoldCell>Закрепление</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: '3',
        gridRow: '1 / span 2'
      }}
      style={COLUMNS_STYLE}
    >
      <HeaderBoldCell>Направление расходов</HeaderBoldCell>
    </GridItem>
    <GridItem
      cellUnion={{
        gridColumn: '4 / span 12',
        gridRow: '1'
      }}
    >
      <HeaderBoldCell>Расчет Бюджета, руб. без НДС</HeaderBoldCell>
    </GridItem>
    {MONTH.map((mounth, index) => {
      return (
        <GridItem
          cellUnion={{
            gridColumn: `${4 + index}`,
            gridRow: '2'
          }}
          style={COLUMNS_STYLE}
        >
          <HeaderCell>{mounth}</HeaderCell>
        </GridItem>
      );
    })}
    <GridItem
      cellUnion={{
        gridColumn: `16`,
        gridRow: '1 / span 2'
      }}
      style={COLUMNS_STYLE}
    >
      <HeaderBoldCell>Итого, руб. без НДС</HeaderBoldCell>
    </GridItem>
  </ListHeader>
);

export const listRowBudgetList = (
  assignmentLimit: AssignmentLimit,
  index: number,
  handleMonthSelect?: monthData => void
) => {
  const {
    contractVehicle,
    assignmentMonths,
    regulationLimit,
    id,
    cognosType
  } = assignmentLimit;
  return (
    <Grid
      style={{ margin: '20px 0px', cursor: 'pointer' }}
      cols={['300px', '300px', '200px', ...Array(13).fill('1fr')]}
      onClick={() =>
        navigate(
          `/budget/contract-vehicle/fixed/${cognosType}/${id ||
            0}/budget/vehicle`
        )
      }
      key={index}
    >
      <GridItem cellUnion={{ gridColumn: '1' }} style={{ paddingLeft: '16px' }}>
        {convertContractVehicleToString(contractVehicle)}
      </GridItem>
      <GridItem cellUnion={{ gridColumn: '2' }} style={{ paddingLeft: '16px' }}>
        {getValueObject(regulationLimit, 'orgUnitName')
          ? getValueObject(regulationLimit, 'orgUnitName')
          : '-'}
      </GridItem>
      <GridItem cellUnion={{ gridColumn: '3' }} style={{ paddingLeft: '16px' }}>
        {assignmentLimit.expenseDirectionName}
      </GridItem>
      <GridItem
        cellUnion={{ gridColumn: '4 / span 12' }}
        onClick={e => {
          if (handleMonthSelect) {
            e.stopPropagation();
          }
        }}
      >
        <Grid cols="12">
          {assignmentMonths.map(month => {
            return (
              <GridItem
                style={{ paddingLeft: '16px' }}
                onClick={() => {
                  if (handleMonthSelect) {
                    handleMonthSelect(
                      month,
                      convertContractVehicleToString(contractVehicle),
                      {
                        distance: regulationLimit.distance,
                        hours: regulationLimit.hours
                      }
                    );
                  }
                }}
              >
                {formatRub(month.sum || 0)}
              </GridItem>
            );
          })}
        </Grid>
      </GridItem>
      <GridItem
        cellUnion={{ gridColumn: '16' }}
        style={{ paddingLeft: '16px' }}
      >
        {formatRub(
          assignmentMonths.reduce((sum, month) => (sum += month.sum || 0), 0)
        )}
      </GridItem>
    </Grid>
  );
};
