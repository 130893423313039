// @flow
import React from 'react';
import styled from 'styled-components';
import notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';

import { Section, SectionTitle } from '../../../components/layout';
import AssignmentGroups from './AssignmentGroups';
import {
  accessTypeEnum,
  operationLimitGroupStatusEnum,
  operationLimitTypeEnum
} from '../../../lib/enum';
import { contractVehicleDailyBudgetApi } from '../../../lib/api';
import { notificationLoading } from './../../../components/Notifications';
import * as moment from 'moment';
import CreateForYearButton from './components/CreateForYearButton';
import { ActionsHeader } from '../elements';
import type {
  OperationLimitGroup,
  OperationLimitType,
  UserAccess
} from '../../../lib/types';
import operationLimitGroupApi from '../../../lib/api/operationLimitGroup';
import { connect } from 'react-redux';
import type { AppState } from '../../../ducks/redux';
import Popover from '../../../components/ui/Popover';

const SectionContent = styled.div`
  padding: 16px;
`;

type Props = {
  // Доступы пользователей
  userAccess: UserAccess[],
  orgUnitId: number
};

type State = {
  // Группы лимитов служб
  orgUnit: OperationLimitGroup[],
  // Группы лимитов должностным лицам
  employee: OperationLimitGroup[],
  // Группа лимитов ОВБ
  ovb: OperationLimitGroup[],
  // Флаг состояния загрузки
  loading: boolean
};

class Assignments extends React.Component<Props, State> {
  state = {
    orgUnit: [],
    employee: [],
    ovb: [],
    loading: false
  };

  async componentDidMount() {
    await this.fetchGroups();
  }

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.orgUnitId !== this.props.orgUnitId) {
      await this.fetchGroups();
    }
  }

  /**
   * Создание годового плана
   * @param year Год
   */
  handleUpdateBudget = async (year: number) => {
    try {
      notificationLoading({
        message: 'Сохранение данных...',
        key: 'saving'
      });
      await contractVehicleDailyBudgetApi.createDailyBudgets(
        moment
          .utc()
          .year(year)
          .startOf('year')
          .toISOString(),
        moment
          .utc()
          .year(year)
          .endOf('year')
          .toISOString()
      );
      notification.success({
        message: 'Бюджет был успешно обновлен'
      });
    } catch (error) {
      notification.error({
        message: 'Ошибка',
        description: error.message
      });
    } finally {
      notification.close('saving');
    }
  };

  /**
   * Создает функцию изменения куска стейта по типу лимитов
   * @param type Тип лимитов
   */
  createChangeGroupsHandler = (type: OperationLimitType) => (
    groups: OperationLimitGroup[]
  ) => {
    this.setState({ [(type: string)]: groups });
  };

  /**
   * Подгрузка групп лимитов по типам
   */
  fetchGroups = async () => {
    const { orgUnitId } = this.props;
    this.setState({ loading: true });
    await Promise.all(
      Object.keys(operationLimitTypeEnum).map(async type => {
        const {
          data: groups
        } = await operationLimitGroupApi.fetchOperationLimitGroups({
          type,
          orgUnitId,
          pageSize: 0,
          includeOperationLimit: true
        });
        this.setState({ [(type: string)]: groups });
      })
    );
    this.setState({ loading: false });
  };

  /**
   * Имеет ли пользователь возможность создания годового плана
   */
  canCreateYearPlan = () => {
    return this.props.userAccess.some(access =>
      [accessTypeEnum.admin, accessTypeEnum.updatingDailyBudgets].includes(
        access
      )
    );
  };

  /**
   * Проверка на то, что все переданные группы утверждены
   * @param groups Группы на проверку
   */
  isGroupsApproved = (groups: OperationLimitGroup[]) => {
    return (
      groups.filter(
        group =>
          group.assignmentLimitStatus === operationLimitGroupStatusEnum.approved
      ).length === groups.length
    );
  };

  render() {
    const { ovb, employee, orgUnit, loading } = this.state;
    const { userAccess } = this.props;

    // Не показывать кнопку создания
    // годового плана, если нет заполненных групп
    const showCreateYearPlanButton =
      this.canCreateYearPlan() &&
      (!isEmpty(ovb) || !isEmpty(employee) || !isEmpty(orgUnit));

    // Заблокировать кнопку создания
    // годового плана, если есть неутвержденные группы
    const disabledCreateYearPlanButton = !(
      this.isGroupsApproved(ovb) &&
      this.isGroupsApproved(employee) &&
      this.isGroupsApproved(orgUnit)
    );

    return (
      <>
        {showCreateYearPlanButton && (
          <ActionsHeader
            right={
              disabledCreateYearPlanButton ? (
                <Popover
                  title="Внимание!"
                  content={<p>Необходимо утвердить все группы закреплений</p>}
                  width={180}
                >
                  <div>
                    <CreateForYearButton disabled />
                  </div>
                </Popover>
              ) : (
                <CreateForYearButton onClick={this.handleUpdateBudget} />
              )
            }
          />
        )}
        <Section>
          <SectionTitle divider>
            Должностные лица, за которыми закреплены ТС
          </SectionTitle>
          <SectionContent>
            <AssignmentGroups
              userAccess={userAccess}
              groups={employee}
              loading={loading}
              onChangeGroups={this.createChangeGroupsHandler(
                operationLimitTypeEnum.employee
              )}
              type={operationLimitTypeEnum.employee}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>
            ТС, закрепленный за ОВБ и бригадой
          </SectionTitle>
          <SectionContent>
            <AssignmentGroups
              userAccess={userAccess}
              groups={ovb}
              loading={loading}
              onChangeGroups={this.createChangeGroupsHandler(
                operationLimitTypeEnum.ovb
              )}
              type={operationLimitTypeEnum.ovb}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle divider>ТС, закрепленный за Службой</SectionTitle>
          <SectionContent>
            <AssignmentGroups
              userAccess={userAccess}
              groups={orgUnit}
              loading={loading}
              onChangeGroups={this.createChangeGroupsHandler(
                operationLimitTypeEnum.orgUnit
              )}
              type={operationLimitTypeEnum.orgUnit}
            />
          </SectionContent>
        </Section>
      </>
    );
  }
}

export default connect((state: AppState) => ({
  userAccess: state.auth.profile.access
}))(Assignments);
