import React from 'react';

import { Section, SectionTitle } from './../components/layout';

const AccessDenied = () => (
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
    <h1>
      <Section>
        <SectionTitle>Доступ к странице запрещен</SectionTitle>
      </Section>
    </h1>
  </div>
);

export default AccessDenied;
